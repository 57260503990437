import { Box } from "@material-ui/core";
import { Pagination, PaginationProps } from "@material-ui/lab";
import * as React from "react";

interface IBottomPaginationControlProps extends PaginationProps {
  styles?: React.CSSProperties;
}

const BottomPaginationControl: React.FunctionComponent<IBottomPaginationControlProps> =
  ({ styles, ...paginationProps }) => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          position: "fixed",
          bottom: 0,
          width: "100vw",
          backgroundColor: "white",
          ...styles,
        }}
      >
        <Pagination {...paginationProps} />
      </Box>
    );
  };

export default BottomPaginationControl;
