import * as React from "react";
import {
  Paper,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
} from "@material-ui/core";
import { Profile } from "@yardzen-inc/models";
import GenericConfirm, {
  GenericConfirmProps,
} from "../../Components/GenericConfirm";
import UploadLimitModField from "../../Components/UploadLimitModField";

const existingFeatureFlags = [
  {
    property: "postOnboardUiBeta",
    description: "Pizza Tracker / Post onboard UI beta",
  },
  {
    property: "propertyAnnotations",
    description: "property image annotation beta",
  },
  {
    property: "newDesignsTab",
    description: "new designs tab",
  },
  {
    property: "isInfluencer",
    description: "Influencer",
  },
];

interface Props {
  profile: Profile;
  isUserDisabled: boolean;
  toggleProfileFlag: (field: string | string[], val: boolean) => void;
  onCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void;
  handleDisabledStatusChange: () => void;
}

const ProfileFeatures = (props: Props) => {
  const { profile, isUserDisabled, handleDisabledStatusChange } = props;
  const [genConfirmProps, setGenConfirmProps] =
    React.useState<GenericConfirmProps | null>(null);

  return (
    <>
      {genConfirmProps && <GenericConfirm {...genConfirmProps} />}
      <Paper>
        <Typography variant="h5">Client Features and Functionality</Typography>
        {profile["featureFlags"] &&
          profile["featureFlags"].includes("postOnboardUiBeta") && (
            <div style={{ marginBottom: 50 }}>
              <div>
                <Link href="https://account.yardzen.com/tracker/">
                  https://account.yardzen.com/tracker/
                </Link>
              </div>
              <div>
                <Link href="https://account.yardzen.com/project/Media">
                  https://account.yardzen.com/project/Media
                </Link>
              </div>
            </div>
          )}
        <Typography variant="body1">
          <br />
          <span
            style={{
              backgroundColor: "red",
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            <em>Please use with caution.</em>
          </span>
          <br />
          <br /> These toggles represent major modifications to the client side
          experience. Please use with caution, contact engineering for more
          info.
        </Typography>

        {existingFeatureFlags.map((flag, index) => (
          <FormControlLabel
            key={`${flag.property}-${index}`}
            control={
              <Checkbox
                checked={
                  profile["featureFlags"] &&
                  profile["featureFlags"].includes(flag.property)
                }
                onChange={props.onCheckboxChange}
                value={flag.property}
              />
            }
            label={flag.description}
          />
        ))}
        <FormControlLabel
          control={
            <Checkbox
              checked={
                !!props.profile.funcflowAnnotated ||
                !!props.profile.funcFlowApproved
              }
              onChange={() =>
                setGenConfirmProps({
                  open: true,
                  onClose: () => setGenConfirmProps(null),
                  body: `Are you sure you want to ${
                    props.profile.funcflowAnnotated ||
                    !!props.profile.funcFlowApproved
                      ? "unlock"
                      : "lock"
                  } the function & flow diagram annotator for this client?`,
                  onSubmit: () => {
                    props.toggleProfileFlag(
                      ["funcFlowApproved", "funcflowAnnotated"],
                      !!props.profile.funcflowAnnotated ||
                        !!props.profile.funcFlowApproved
                        ? false
                        : true
                    );
                    setGenConfirmProps(null);
                  },
                })
              }
            />
          }
          label={"PLP Feedback is Locked"}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={!isUserDisabled}
              onChange={() =>
                setGenConfirmProps({
                  open: true,
                  onClose: () => setGenConfirmProps(null),
                  body: `Are you sure you want to ${
                    isUserDisabled ? "ACTIVATE" : "DEACTIVATE"
                  } this account`,
                  onSubmit: () => {
                    handleDisabledStatusChange();
                    setGenConfirmProps(null);
                  },
                })
              }
            />
          }
          label={"Account Active"}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={props.profile.isTestAccount}
              onChange={() =>
                props.toggleProfileFlag(
                  "isTestAccount",
                  !props.profile.isTestAccount
                )
              }
            />
          }
          label={"isTestAccount"}
        />
        <UploadLimitModField userId={props.profile.id} />
      </Paper>
    </>
  );
};

export default ProfileFeatures;
