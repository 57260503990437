class HTTPError extends Error {
  public type = "HTTPError";
  public status: number;
  public statusMessage: string;

  constructor(status: number, statusMessage: string, message?: string) {
    super(
      `${HTTPError}: ${status}/${statusMessage} ${
        message ? `- ${message}` : ""
      }`
    );

    this.status = status;
    this.statusMessage = statusMessage;
  }
}

export { HTTPError };
