import React, { FC, useState, ReactNode } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Button,
  DialogContentText,
  FormHelperText,
  CircularProgress,
  IconButton,
  Typography,
  Link,
} from "@material-ui/core";
import emailRegex from "../util/emailRegex";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import { Profile } from "@yardzen-inc/models";
import { Close } from "@material-ui/icons";
import { GenericOnCallFunctionAlert } from "../util/genericAlert";
import { YZTypography } from "@yardzen-inc/react-common";

export interface ChangeClientEmailModalProps {
  open: boolean;
  onClose: () => void;
  profile: Profile;
}

const ChangeClientEmailModal: FC<ChangeClientEmailModalProps> = ({
  open,
  onClose,
  profile,
}) => {
  const [newEmail, setNewEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  const [emailIsDuplicate, setEmailIsDuplicate] = useState(false);
  const [duplicateUserId, setDuplicateUserId] = useState<string | null>(null);

  const [emailError, setEmailError] = useState(false);
  const [clientError, setClientError] = useState(false);
  const [responseErrors, setResponseErrors] = useState<string[] | null>(null);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box position="relative" p={2}>
        <Box position="absolute" top={5} right={5}>
          <IconButton size="small" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <DialogTitle>Change Client Email Address</DialogTitle>
        <DialogContent>
          {loading && (
            <Box mt={2} display="flex" justifyContent="center">
              <CircularProgress color="primary" size={75} />
            </Box>
          )}
          {!loading && !requestSent && renderForm()}
          {!loading && requestSent && renderResults()}
        </DialogContent>
      </Box>
    </Dialog>
  );

  function renderForm() {
    return (
      <>
        <TextField
          label="New Email Address"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          error={emailError}
          disabled={confirm}
          fullWidth
        />
        {emailError && (
          <FormHelperText error={emailError}>
            Must be a valid email address
          </FormHelperText>
        )}
        <Box pt={2} p={1}>
          {!confirm ? (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={initialCheck}
            >
              Submit
            </Button>
          ) : (
            <>
              {emailIsDuplicate ? (
                <DialogContentText align="center">
                  <b>{newEmail}</b> is already used by{" "}
                  <Link href={"/client/" + duplicateUserId + "/detail"}>
                    another client's account
                  </Link>
                  . You must change the email for that account before you can
                  use it here.
                </DialogContentText>
              ) : (
                <DialogContentText align="center">
                  <b>Are you sure?</b>
                </DialogContentText>
              )}
              <Box display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "45%" }}
                  onClick={handleSubmit}
                  disabled={emailIsDuplicate}
                >
                  Confirm
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#e32f22",
                    color: "white",
                    width: "45%",
                  }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Box>
            </>
          )}
        </Box>
      </>
    );
  }

  function renderResults() {
    if (clientError) {
      return (
        <DialogContentText>
          Something went wrong trying to make the requests to the backend.
          Please close this modal and try again.
        </DialogContentText>
      );
    }

    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        {renderEmailUpdateResultStatus()}
        <Typography>
          New email - <b>{newEmail}</b>
        </Typography>
      </Box>
    );
  }

  function renderEmailUpdateResultStatus(): ReactNode {
    if (!responseErrors?.length) {
      return (
        <Box display="flex">
          <DialogContentText>Success </DialogContentText>
          <Close style={{ color: "green" }} />
        </Box>
      );
    }

    return (
      <>
        <Box display="flex" flexDirection="column" alignItems="center">
          <DialogContentText>
            There were issues updating the following services:
          </DialogContentText>
          <YZTypography variant="caption">
            This may or may not require action, please reach out to support
          </YZTypography>
        </Box>
        {responseErrors.map((key) => (
          <Box display="flex" key={key}>
            <DialogContentText>{key}</DialogContentText>
            <Close style={{ color: "red" }} />
          </Box>
        ))}
      </>
    );
  }

  function initialCheck() {
    if (!emailRegex.test(newEmail)) {
      return setEmailError(true);
    } else {
      checkDuplicateEmail();
      setEmailError(false);
      return setConfirm(true);
    }
  }

  async function checkDuplicateEmail() {
    setLoading(true);

    try {
      const response = await firebase
        .functions()
        .httpsCallable("getUserByEmail")(newEmail);

      // if email is already used by another client account
      if (response.data) {
        setEmailIsDuplicate(true);
        setDuplicateUserId(response.data.uid);
      }

      setLoading(false);
    } catch (err) {
      GenericOnCallFunctionAlert("getProfileByEmail", err.message);
      setClientError(true);
    }
  }

  async function handleSubmit() {
    if (!profile) {
      return;
    }
    setLoading(true);
    setRequestSent(true);
    try {
      const result = await firebase
        .functions()
        .httpsCallable("changeClientEmailAddress")({
        userId: profile.userId,
        oldEmail: profile.email,
        newEmail,
      });
      setResponseErrors(result.data as unknown as string[]);
      setLoading(false);
    } catch (err) {
      GenericOnCallFunctionAlert("changeClientEmailAddress", err.message);
      setClientError(true);
    }
  }
};

export { ChangeClientEmailModal };
export default ChangeClientEmailModal;
