import { MediaInclusion } from "../ClientDetail/Media/IncludeMediaInSharedLink";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Media } from "@yardzen-inc/models";

async function getRevisionMedia(
  inclusionObject: MediaInclusion
): Promise<any[] | void> {
  try {
    const revisionId = inclusionObject.revision.id;
    const includedCads = inclusionObject.revision.includedCads;

    if (!revisionId) {
      return [];
    }

    const allRevisionMedia = await firebase
      .firestore()
      .collection("media")
      .where("revisionId", "==", revisionId)
      .get();

    const filteredRevisionMedia = allRevisionMedia.docs.filter((doc) => {
      const data = doc.data();
      if (!data || !doc.exists) {
        return false;
      }

      return includedCads.includes(data.tag) || data.tag === "deliverable";
    });
    return Media.getMediaMetadataFromIdArray(
      filteredRevisionMedia.map((doc) => doc.id)
    );
  } catch (error) {
    console.error(
      `Error fetching revision media while including shared media: `,
      error
    );

    throw error;
  }
}

export { getRevisionMedia };
