import React, { FC } from "react";
import { Box, Tabs, Tab } from "@material-ui/core";

export interface StatsHeaderProps {
  index: number;
  setIndex: (n: number) => void;
}

const StatsHeader: FC<StatsHeaderProps> = (props) => {
  return (
    <>
      <Box width="100%">
        <Tabs
          onChange={(_, i) => props.setIndex(i as number)}
          value={props.index}
        >
          <Tab value={0} label="CDM/Modeler Assignments" />
          <Tab value={1} label="Design Assignments" />
        </Tabs>
      </Box>
    </>
  );
};

export { StatsHeader };
export default StatsHeader;
