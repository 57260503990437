import { Profile } from "@yardzen-inc/models";
import * as React from "react";
import { Link, Typography, IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import ChangeClientEmailModal from "../../Components/ChangeClientEmailModal";

interface IUpdateClientEmailProps {
  clientRecord: Profile;
}

const UpdateClientEmail: React.FunctionComponent<IUpdateClientEmailProps> = ({
  clientRecord,
}) => {
  const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false);

  return (
    <>
      {editModalOpen && (
        <ChangeClientEmailModal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          profile={clientRecord}
        />
      )}
      <Typography paragraph>
        <Link href={`mailto:${clientRecord["email"]}`}>
          {clientRecord["email"]}
        </Link>
        <IconButton onClick={() => setEditModalOpen(true)}>
          <Edit />
        </IconButton>
      </Typography>
    </>
  );
};

export default UpdateClientEmail;
