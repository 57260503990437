import React, { FC, useRef, ReactNode, CSSProperties, useMemo } from "react";
import GenericRouter, { GenericRouterProps } from "./GenericRouter";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { PageMapEntryWithHumanName } from "./types";
import { makeRRURL } from "./util";
import classnames from "classnames";

export interface GenericRouterWithTitleAndTabHeaderProps
  extends GenericRouterProps {
  pageMap: PageMapEntryWithHumanName[];
  title: string;
  tabsComponentStyles?: CSSProperties;
  spacerSize?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
  },
  tabs: {
    flexGrow: 1,
  },
  textButton: {
    pointerEvents: "all",
    cursor: "pointer",
    transition: "all 250",
    opacity: 0.8,
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
  selected: {
    opacity: 1,
    color: theme.palette.primary.main,
    borderBottom: `solid 1px ${theme.palette.primary.dark}`,
  },
}));

const GenericRouterWithTitleAndTabHeader: FC<GenericRouterWithTitleAndTabHeaderProps> =
  ({ spacerSize, title, ...props }) => {
    const classes = useStyles();

    const routeCacheRef = useRef<{
      [key: string]: { [shortName: string]: string };
    }>({});

    const activePath = useMemo(getCurrentPathString, [props.location.pathname]);

    return (
      <>
        {props.location.pathname !== props.match.url && (
          <Box className={classes.root}>
            <Box p={1} pr={4}>
              <Typography variant="h4">{title}</Typography>
            </Box>
            {renderButtons()}
          </Box>
        )}
        <Box py={spacerSize} display="inline-block" />
        <GenericRouter {...props} routeCacheRef={routeCacheRef} />
      </>
    );

    function getCurrentPathString(): string {
      const matchSplit = props.match.url.split("/");
      const matchSplitLen = matchSplit.length;

      const urlSplit = props.location.pathname.split("/");

      return urlSplit.slice(0, matchSplitLen + 1).join("/");
    }

    function renderButtons(): ReactNode[] {
      return props.pageMap.map(renderButton);
    }

    function renderButton(entry: PageMapEntryWithHumanName): ReactNode {
      const url = makeRRURL(
        entry.routeName,
        {
          location: props.location,
          history: props.history,
          match: props.match,
        },
        routeCacheRef
      );

      return (
        <Box key={`${entry.key}__tab`} p={1}>
          <Typography
            variant="h6"
            className={classnames(
              classes.textButton,
              activePath === url ? classes.selected : void 0
            )}
            onClick={() => {
              handleClick(url);
            }}
          >
            {entry.humanName ?? entry.routeName}
          </Typography>
        </Box>
      );
    }

    function handleClick(value: string) {
      props.history.push(value);
    }
  };

GenericRouterWithTitleAndTabHeader.defaultProps = {
  spacerSize: 2,
};

export { GenericRouterWithTitleAndTabHeader };
export default GenericRouterWithTitleAndTabHeader;
