import * as React from "react";
import MediaCard, { MediaCardProps } from "./MediaCard";
import { Typography, Avatar, TextField } from "@material-ui/core";
import { YZButton } from "@yardzen-inc/react-common";

export interface SortableCardProps extends MediaCardProps {
  _index: number;
  onExplicitMove: (current: number, destination: number) => void;
}

const SortableCard = ({ _index, ...props }: SortableCardProps) => {
  const [value, setValue] = React.useState("");

  return (
    <div
      style={{
        width: (props.style && props.style.width) || "100%",
        height: "fit-content",
      }}
    >
      {<Avatar>{(_index + 1).toString()}</Avatar>}
      <Typography>
        {props.media.title || props.media.originalFileName}
      </Typography>
      <MediaCard noTitle {...props} childrenToolBar={renderToolbarChildren()} />
    </div>
  );

  function renderToolbarChildren(): React.ReactNode {
    return (
      <form
        style={{ display: "flex", flexFlow: "row nowrap", width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault();
          props.onExplicitMove(_index, parseInt(value) - 1);
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <TextField
            fullWidth
            label={"Move to..."}
            value={value}
            style={{ backgroundColor: "white" }}
            variant="outlined"
            onChange={({ target: { value } }) => {
              if (isNaN(parseInt(value)) && value !== "") {
                return;
              }

              setValue(value);
            }}
          />
        </div>
        <YZButton variant="text" type="submit">
          Move
        </YZButton>
      </form>
    );
  }
};

export default React.memo(SortableCard);
