import React from "react";
import { Paper, Box } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import * as firebaseClient from "src/util/firebase/firebaseClient";
import moment from "moment";
import { Project, ProMatchProjectTag } from "@yardzen-inc/models";

interface Props {
  project: Project;
  fullWidth?: boolean;
}

function BuildIntentResponseCard(props: Props) {
  const [loading, setLoading] = React.useState(true);
  const [response, setResponse] = React.useState<Record<string, any> | null>(
    null
  );

  React.useEffect(() => {
    const fetchResponse = async () => {
      if (!props.project?.id) {
        return;
      }

      const projectId = props.project.id;

      try {
        const response = await firebaseClient.getBuildIntentResponse(projectId);
        setResponse(response);
      } catch (error) {
        window.newrelic.noticeError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchResponse();
  }, [props.project]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!response) {
    return null;
  }

  const projectTags = props.project.tags || [];

  const startDelayInMonths = [
    { name: ProMatchProjectTag.PostV2OneMonth, months: 1 },
    { name: ProMatchProjectTag.PostV2ThreeMonth, months: 3 },
    { name: ProMatchProjectTag.PostV2SixMonth, months: 6 },
  ].find((tag) => projectTags.includes(tag.name))?.months;

  const hopesToStart = moment(new Date(response.createdAt))
    .add(startDelayInMonths || 0, "M")
    .format("MMMM YYYY");

  const phasing = response.installationApproach?.toLowerCase();
  const features = response.featuresToInstall;
  const customerNotes = response.customerNotes;
  const reasonForDeclining = response.reasonForDeclining;

  const submittedAt = new Date(response.createdAt).toLocaleString("en-US", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneName: "short",
  });

  const installBudgetRangeMin =
    response.installBudgetRangeMin &&
    Intl.NumberFormat("en-US").format(response.installBudgetRangeMin / 100);
  const installBudgetRangeMax =
    response.installBudgetRangeMax &&
    Intl.NumberFormat("en-US").format(response.installBudgetRangeMax / 100);

  const displayWidth = props.fullWidth ? "100%" : "35vw";

  const noPostV2BuildIntent = projectTags.includes(ProMatchProjectTag.PostV2No);
  const noPostV2BuildIntentMsg = "No interest in contractor match at Post-V2";

  const requestedMatchMeNow = projectTags.includes(
    ProMatchProjectTag.PostV2MatchMeNow
  );
  const requestedMatchMeNowMsg = "Requested Match Me Now Post-V2";

  return (
    <div style={{ marginLeft: "2rem", width: displayWidth }}>
      <Paper elevation={4}>
        <Box m={2} p={1}>
          <YZTypography variant="h5">Client Installation Notes</YZTypography>
          <YZTypography variant="body2">Submitted {submittedAt}</YZTypography>
          <p>
            {noPostV2BuildIntent && (
              <YZTypography variant="body1">
                {noPostV2BuildIntentMsg}
              </YZTypography>
            )}
            {requestedMatchMeNow && (
              <YZTypography variant="body1">
                {requestedMatchMeNowMsg}
              </YZTypography>
            )}
            {!noPostV2BuildIntent && hopesToStart && (
              <YZTypography variant="body1">
                Hopes to start: {hopesToStart}
              </YZTypography>
            )}
            {reasonForDeclining && (
              <YZTypography variant="body1">
                Reason For Declining: {reasonForDeclining}
              </YZTypography>
            )}
            {phasing && (
              <YZTypography variant="body1">
                Phasing: Install{" "}
                <span style={{ textDecoration: "underline" }}>{phasing}</span>{" "}
                of design
              </YZTypography>
            )}
            {installBudgetRangeMin && installBudgetRangeMax && (
              <YZTypography variant="body1">
                Hopes to spend: ${installBudgetRangeMin} - $
                {installBudgetRangeMax}
              </YZTypography>
            )}
          </p>
          {features && (
            <p>
              <YZTypography variant="body1">
                Features client would like to install:
              </YZTypography>
              <YZTypography variant="body2">{features}</YZTypography>
            </p>
          )}
          {customerNotes && (
            <p>
              <YZTypography variant="body1">Client Notes:</YZTypography>
              <YZTypography variant="body2">{customerNotes}</YZTypography>
            </p>
          )}
        </Box>
      </Paper>
    </div>
  );
}

export { BuildIntentResponseCard };
