import React, { FC } from "react";
import { PageComponentProps } from "./CurrentJobPageMap";
import { DesignBriefPage } from "../Components/designBrief";
import FunctionAndFlowTab from "./FunctionFlowTab";

export interface DesignBriefPageMapWrapperProps extends PageComponentProps {}

const ClassicDesignBriefPage = FunctionAndFlowTab("brief");

const DesignBriefPageMapWrapper: FC<DesignBriefPageMapWrapperProps> = (
  props
) => {
  if (props.project.useAutomatedDesignBrief) {
    return (
      <DesignBriefPage
        pm={false}
        profile={props.profile}
        project={props.project}
      />
    );
  }

  return <ClassicDesignBriefPage {...props} />;
};

export { DesignBriefPageMapWrapper };
export default DesignBriefPageMapWrapper;
