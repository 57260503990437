import firebase from "firebase/compat/app";
import { getOnHoldProjectsByQAManagerId } from "../../util/firebase/firebaseClient";
import { ProjectForQA } from "./useProjectsUpForQa";

// creates a snapshot listener on the projects collections for pm assignments ready for
export function listenOnProjects(
  pmId: string,
  setProjects: React.Dispatch<React.SetStateAction<ProjectForQA[] | null>>
) {
  let query: firebase.firestore.CollectionReference | firebase.firestore.Query =
    getOnHoldProjectsByQAManagerId(pmId);
  return query.onSnapshot((snapshot) => {
    const mapped: ProjectForQA[] = [];

    for (const doc of snapshot.docs) {
      const data = doc.data();

      if (!data) {
        setProjects([]);
        continue;
      }
      mapped.push({ ...data, id: doc.id } as ProjectForQA);
    }

    setProjects(mapped);
  });
}
