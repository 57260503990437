import {
  Dialog,
  TextField,
  Box,
  Divider,
  List,
  LinearProgress,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Profile } from "@yardzen-inc/models";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import firebase from "firebase/compat/app";
import * as React from "react";
import validator from "validator";
import GenericSnackBar from "../../Components/GenericSnackBar";
import { SuggestedAddressListItem } from "../../EmployeeView/IncompleteAddressQueue/components/SuggestedAddressListItem";
import { geocodeAddress } from "../../EmployeeView/IncompleteAddressQueue/util/geocodeAddress";
import {
  IAddressObject,
  parseGeocodeLocationToAddress,
} from "../../EmployeeView/IncompleteAddressQueue/util/parseGeocodeToAddress";
import { formatAddress } from "../../util/functions/formatAddress";
import { AddressManualEntryForm } from "./AddressManualEntryForm";

interface IClientAddressUpdateModalProps {
  open: boolean;
  onClose: () => void;
  profile: Profile;
  onSuccessfulAddressUpdate?: () => void;
}

const useStyles = makeStyles(({ spacing }) => ({
  textField: {
    margin: spacing(2),
  },
  enterManuallyButton: {
    textAlign: "center",
    cursor: "pointer",
  },
  button: {
    marginLeft: spacing(1),
  },
  closeIcon: {
    position: "absolute",
    right: "1rem",
    top: "1rem",
  },
}));

const ClientAddressUpdateModal: React.FunctionComponent<IClientAddressUpdateModalProps> =
  ({ open, onClose, onSuccessfulAddressUpdate, profile }) => {
    const { textField, button, enterManuallyButton, closeIcon } = useStyles();
    const { id } = profile;
    const [phone, setPhone] = React.useState<string>(profile.phone || "");
    const [firstName, setFirstName] = React.useState<string>(
      profile.firstName || ""
    );
    const [lastName, setLastName] = React.useState<string>(
      profile.lastName || ""
    );
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [queryString, setQueryString] = React.useState<string>(
      formatAddress(profile)
    );
    const [results, setResults] = React.useState<google.maps.GeocoderResult[]>(
      []
    );
    const [manualEntry, setManualEntry] = React.useState(false);

    const phoneError = React.useMemo(() => {
      return !!phone.length && !validator.isMobilePhone(phone, "en-US");
    }, [phone]);

    return (
      <>
        <GenericSnackBar
          variant="error"
          message={error || "Unknown error ocurred"}
          onClose={() => setError(null)}
          in={!!error}
        />
        <GenericSnackBar
          variant="success"
          message="Successfully updated client details"
          onClose={() => setSuccess(false)}
          in={success}
        />
        <Dialog open={open} onClose={onClose}>
          <Box m={2} p={1}>
            <YZTypography variant="h4">Edit Client details</YZTypography>
            <IconButton
              className={closeIcon}
              size="small"
              onClick={() => {
                onClose();
              }}
            >
              <Close style={{ fontSize: 18 }} />
            </IconButton>
            <Box m={2} textAlign="center">
              <Box display="flex" justifyContent="space-around">
                <TextField
                  className={textField}
                  helperText="First Name"
                  error={!firstName.length}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  className={textField}
                  helperText="Last Name"
                  error={!lastName.length}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <TextField
                  className={textField}
                  helperText={
                    phoneError ? "Please enter valid phone number" : "Phone"
                  }
                  value={phone}
                  error={phoneError}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Box>
              <YZButton onClick={() => updateNameAndPhone(profile.id)}>
                Update name and phone
              </YZButton>
            </Box>
            <Divider />

            <Box m={1}>
              <YZTypography variant="h5">Client's address</YZTypography>
              <Box display="flex" justifyContent="space-between" m={1}>
                <TextField
                  value={queryString}
                  onChange={(e) => setQueryString(e.target.value)}
                  fullWidth
                  placeholder="Search"
                />
                <Box>
                  <YZButton
                    disabled={loading}
                    onClick={() => getSearchResults(queryString)}
                    className={button}
                  >
                    Search
                  </YZButton>
                </Box>
              </Box>
            </Box>
            {loading && <LinearProgress />}
            {results && (
              <>
                <Divider />
                <List>
                  {results.map((r) => (
                    <SuggestedAddressListItem
                      key={r.place_id}
                      geocodeResult={r}
                      setProfileAddress={(loc) =>
                        setProfileAddress(
                          parseGeocodeLocationToAddress(loc),
                          id
                        )
                      }
                    />
                  ))}
                </List>
              </>
            )}
            <YZTypography
              className={enterManuallyButton}
              color="primary"
              onClick={() => setManualEntry(!manualEntry)}
            >
              Enter address manually
            </YZTypography>
            {manualEntry && (
              <Box
                mt={1}
                width="100%"
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <YZTypography>
                  Only for use when a Google Geocoded address is not available.
                </YZTypography>
                <AddressManualEntryForm
                  onSubmit={(add) => setProfileAddress(add, id)}
                />
              </Box>
            )}
          </Box>
        </Dialog>
      </>
    );

    async function getSearchResults(query: string) {
      setLoading(true);
      try {
        setResults(await geocodeAddress(query));
      } catch (error) {
        setError(error.message || `${error}`);
      } finally {
        setLoading(false);
      }
    }

    async function updateNameAndPhone(profileId: string) {
      try {
        await firebase
          .firestore()
          .collection("profiles")
          .doc(profileId)
          .update({
            firstName,
            lastName,
            phone,
          });
        setSuccess(true);
      } catch (error) {
        setError(error.message || `${error}`);
      } finally {
        onClose();
      }
    }

    async function setProfileAddress(
      address: IAddressObject,
      profileId: string
    ) {
      setLoading(true);
      try {
        await firebase
          .firestore()
          .collection("profiles")
          .doc(profileId)
          .update(address);

        setSuccess(true);
        onSuccessfulAddressUpdate && onSuccessfulAddressUpdate();
      } catch (error) {
        setError(error.message || `${error}`);
      } finally {
        setLoading(false);
        onClose();
      }
    }
  };

export { ClientAddressUpdateModal };
