import { Box, makeStyles, Theme } from "@material-ui/core";
import React, { FC, useMemo } from "react";
import { formatNumToUSD, YZTypography } from "@yardzen-inc/react-common";
import { BudgetMetaData } from "../../util/hooks/useGetBudgetDataByProjectId";
import { YardDifficultyRating } from "@yardzen-inc/models";
import {
  AVERAGE_YARD_DIFFICULTY_BASE_COST_MULTIPLIER,
  EASY_YARD_DIFFICULTY_BASE_COST_MULTIPLIER,
  HARD_YARD_DIFFICULTY_BASE_COST_MULTIPLIER,
} from "../../ConstantValues/YardDifficultyMultipliers";
import { yardDifficultyRatingToString } from "../../util/functions/yardDifficultyRatingToString";
import { BudgetDisplayWrapperClasses } from "./BudgetDisplayWrapper";
import { useAllInRange } from "../../util/hooks/useAllInRange";
import { BOTANICAL_PACKAGE } from "../../util/packageTypeConstants";

export interface InternalBudgetDisplayProps {
  budgetMetadata?: BudgetMetaData;
  projectId: string;
  packageType?: string;
  yardDifficultyRating?: YardDifficultyRating | null;
  projectHasAdjustedBudgetOverride?: boolean;
  formattedAdjustedBudget?: string;
  wishlistEstimateRangeText: string;
  additionalCssClasses?: BudgetDisplayWrapperClasses;
}

const useStyles = makeStyles((theme: Theme) => ({
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "max-content",
  },
  budgetTextContainer: {
    display: "flex",
    margin: `${theme.spacing(1)}px 0px`,
  },
  budgetCaption: {
    marginRight: `${theme.spacing(1)}px`,
    textTransform: "capitalize",
  },
  budgetText: {
    marginRight: `${theme.spacing(1)}px`,
  },
}));

export const InternalBudgetDisplay: FC<InternalBudgetDisplayProps> = ({
  budgetMetadata,
  projectId,
  packageType,
  yardDifficultyRating,
  projectHasAdjustedBudgetOverride,
  formattedAdjustedBudget,
  wishlistEstimateRangeText,
  additionalCssClasses,
}) => {
  const [allInRangeLowValue, allInRangeHighValue] = useAllInRange(projectId);
  const allInRangeText =
    packageType === BOTANICAL_PACKAGE
      ? "Botanical clients do not have an all in range."
      : `${formatNumToUSD(allInRangeLowValue)} - ${formatNumToUSD(
          allInRangeHighValue
        )}`;

  const reservedBaseCostsText = useMemo(() => {
    if (budgetMetadata?.total_budget && yardDifficultyRating) {
      if (yardDifficultyRating === 1 || packageType === BOTANICAL_PACKAGE) {
        return formatNumToUSD(
          Math.floor(
            budgetMetadata.total_budget *
              EASY_YARD_DIFFICULTY_BASE_COST_MULTIPLIER
          )
        );
      }

      if (yardDifficultyRating === 2) {
        return formatNumToUSD(
          Math.floor(
            budgetMetadata.total_budget *
              AVERAGE_YARD_DIFFICULTY_BASE_COST_MULTIPLIER
          )
        );
      }

      if (yardDifficultyRating === 3) {
        return formatNumToUSD(
          Math.floor(
            budgetMetadata.total_budget *
              HARD_YARD_DIFFICULTY_BASE_COST_MULTIPLIER
          )
        );
      }
    }

    return (
      "Reserved base costs have not yet been set for this project." +
      " Verify the CDM step has been completed."
    );
  }, [budgetMetadata, yardDifficultyRating]);

  const classes = useStyles();

  const budgetTextContainerCssClass = `${classes.budgetTextContainer} ${
    additionalCssClasses?.budgetTextContainer ?? ""
  }`;

  const budgetCaptionCssClass = `${classes.budgetCaption} ${
    additionalCssClasses?.budgetCaptions ?? ""
  }`;

  const budgetTextCssClass = `${classes.budgetText} ${
    additionalCssClasses?.budgetText ?? ""
  }`;

  return (
    <Box>
      {
        // Some projects will have an adjusted budget. We should display
        // this only if it exists, and if it does exist it should be priority.
        projectHasAdjustedBudgetOverride && (
          <Box className={budgetTextContainerCssClass}>
            <YZTypography className={budgetCaptionCssClass}>
              Adjusted Budget Override:
            </YZTypography>
            <YZTypography className={budgetTextCssClass}>
              {formattedAdjustedBudget}
            </YZTypography>
          </Box>
        )
      }
      <Box className={budgetTextContainerCssClass}>
        <YZTypography className={budgetCaptionCssClass}>
          All In Budget:
        </YZTypography>
        <YZTypography className={budgetTextCssClass}>
          {getBudgetValueAsFormattedDollars(
            budgetMetadata?.total_budget as number
          )}
        </YZTypography>
      </Box>
      <Box className={budgetTextContainerCssClass}>
        <YZTypography className={budgetCaptionCssClass}>
          Design Budget:
        </YZTypography>
        <YZTypography className={budgetTextCssClass}>
          {getBudgetValueAsFormattedDollars(
            parseFloat(budgetMetadata?.designer_visible_budget ?? ""),
            "A design budget has not yet been set for this project." +
              " Verify the CDM step has been completed."
          )}
        </YZTypography>
      </Box>
      <Box className={budgetTextContainerCssClass}>
        <YZTypography className={budgetCaptionCssClass}>
          Reserved Base Costs:
        </YZTypography>
        <YZTypography className={budgetTextCssClass}>
          {reservedBaseCostsText}
        </YZTypography>
        {yardDifficultyRating && (
          <YZTypography>
            ({yardDifficultyRatingToString(yardDifficultyRating)})
          </YZTypography>
        )}
      </Box>
      <Box className={budgetTextContainerCssClass}>
        <YZTypography className={budgetCaptionCssClass}>
          Wishlist estimate:
        </YZTypography>
        <YZTypography className={budgetTextCssClass}>
          {wishlistEstimateRangeText}
        </YZTypography>
      </Box>
      <Box className={budgetTextContainerCssClass}>
        <YZTypography className={budgetCaptionCssClass}>
          All In Range:
        </YZTypography>
        <YZTypography className={budgetTextCssClass}>
          {allInRangeText}
        </YZTypography>
      </Box>
    </Box>
  );

  /**
   * Retrieves the text to display for a budget value - either the amount
   * formatted in dollars, or a message if the value is not a number.
   * @param budgetValue The budget value to get in dollars.
   * @param fallbackText The text to render if the budget value cannot be
   * converted into a dollar amount.
   * @returns A string to display for the budget value.
   */
  function getBudgetValueAsFormattedDollars(
    budgetValue: number,
    fallbackText?: string
  ) {
    if (isNaN(budgetValue)) {
      return fallbackText ?? "This budget value is unknown.";
    }

    return formatNumToUSD(budgetValue);
  }
};
