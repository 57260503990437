import { DesignBriefApi } from "./DesignBriefApi";
import DesignBriefEntry, {
  DesignBriefEntryProperties,
} from "./DesignBriefEntry";
import { DesignBriefEntryGroupMap, DesignBriefMeta } from "./types";
import firebase from "firebase/compat/app";
import environmentConstants from "../../../ConstantValues/environmentConstants";

const notAZ_ = /[^A-Z_]/g;
const hasSpace = / /g;

/*
  In our firestore model, the design brief checklist entries are
  located in a subcollection at projects->doc-><designBriefEntries>.
  The actual collection name is dictated by the subcollectionName
  argument passed to the constructor of FirestoreDesignBriefApi
*/
class FirestoreDesignBriefApi extends DesignBriefApi {
  private projectDocRef: firebase.firestore.DocumentReference;
  private subCollectionName: string;

  public async dropCollection(): Promise<void> {
    const collectionSnap = await this.subCollectionRef.get();

    if (collectionSnap.empty) {
      return;
    }

    const batch = firebase.firestore().batch();

    for (let doc of collectionSnap.docs) {
      batch.delete(doc.ref);
    }

    const batchProm = batch.commit();

    this.triggerEvent("DROP");

    await batchProm;
  }

  public constructor(
    subCollectionName: string,
    projectCollectionRef: firebase.firestore.DocumentReference
  ) {
    super();

    this.subCollectionName = subCollectionName;
    this.projectDocRef = projectCollectionRef;
  }

  public async updateEntry(entry: DesignBriefEntry<any>) {
    const { id, ...rest } = entry;

    this.getDoc(id).update(rest);
    return this.triggerEvent("UPDATE", entry);
  }

  public async deleteEntry(entry: DesignBriefEntry<any>) {
    this.getDoc(entry.id).delete();
    return this.triggerEvent("DELETE", entry);
  }

  public async createEntry(entryProps: DesignBriefEntryProperties<any>) {
    entryProps.group = entryProps.group
      .toUpperCase()
      .replace(hasSpace, "_")
      .replace(notAZ_, "");

    const sanitizedProps: DesignBriefEntryProperties<any> = {} as any;

    Object.keys(entryProps).forEach((key) => {
      // @ts-ignore
      sanitizedProps[key] = entryProps[key] ?? null;
    });

    let result: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;

    try {
      result = await this.subCollectionRef.add(sanitizedProps);
    } catch (err) {
      window.newrelic.noticeError(err);
    }

    const newEntry = new DesignBriefEntry<any>(result!.id, sanitizedProps);
    this.triggerEvent("CREATE", newEntry);

    return newEntry;
  }

  public async bulkCreateEntry(entryProps: DesignBriefEntryProperties<any>[]) {
    const entryPromises: Promise<DesignBriefEntry<any>>[] = entryProps.map(
      async (ep) => {
        const createResult = await this.createEntry(ep);

        const newEntry = new DesignBriefEntry<any>(createResult.id, ep);
        this.triggerEvent("CREATE", newEntry);

        return newEntry;
      }
    );

    return Promise.all(entryPromises);
  }

  /*
    Pull all of the entries on initial load from the firestore sub
    -collection the entries are located at projects->designBriefEntries->docs

    The entries returned are associated by group string
  */
  public async getAllEntries(): Promise<DesignBriefEntryGroupMap | null> {
    const subCollectionSnap = await this.subCollectionRef.get();

    if (process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT) {
      subCollectionSnap.docs.forEach((d) => {
        if (!d.data()["marked"] && d.id !== "__meta") {
          console.error("NOT MARKED", d.data());
        }
      });
    }

    if (subCollectionSnap.empty) {
      return null;
    }

    const map: DesignBriefEntryGroupMap = {};

    for (let doc of subCollectionSnap.docs) {
      if (doc.id === "__meta") {
        continue;
      }

      const data = doc.data();
      let entry = new DesignBriefEntry<any>(
        doc.id,
        data as DesignBriefEntryProperties<any>
      );

      let groupName = entry.group;

      if (notAZ_.test(groupName)) {
        groupName = groupName
          .toUpperCase()
          .replace(hasSpace, "_")
          .replace(notAZ_, "");
      }

      if (!(groupName in map)) {
        map[groupName] = [entry];
      } else {
        map[groupName].push(entry);
      }
    }

    return map;
  }

  public async getMetadata(): Promise<DesignBriefMeta | null> {
    const metaDoc = await this.projectDocRef
      .collection("designBrief")
      .doc("__meta")
      .get();

    if (!metaDoc.exists) {
      return null;
    }

    return metaDoc.data() as DesignBriefMeta;
  }

  public async setMetadata(meta: DesignBriefMeta): Promise<void> {
    this.projectDocRef.collection("designBrief").doc("__meta").set(meta);
  }

  public async updateMetadata(
    key: keyof DesignBriefMeta,
    val: any
  ): Promise<void> {
    if (val === void 0) {
      throw new Error("undefined passed as value to updateMetadata");
    }

    return this.subCollectionRef.doc("__meta").update({ [key]: val });
  }

  private getDoc(entryDocId: string) {
    return this.subCollectionRef.doc(entryDocId);
  }

  private get subCollectionRef(): firebase.firestore.CollectionReference {
    return this.projectDocRef.collection(this.subCollectionName);
  }
}

export { FirestoreDesignBriefApi };
export default FirestoreDesignBriefApi;
