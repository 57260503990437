import React, { FC, useContext } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import { CDMPageContext } from "../../EmployeeView/CDM/CDMContextProvider";

export interface WesleySummaryPageEstimateNoteProps {
  isBotanicalClient: boolean;
}

const WesleySummaryPageEstimateNote: FC<WesleySummaryPageEstimateNoteProps> = ({
  isBotanicalClient,
}) => {
  const { estimateEducationNote, setEstimateEducationNote } =
    useContext(CDMPageContext);

  return (
    <>
      <Box>
        <YZTypography variant="h5">
          Estimate Education Note (Optional)
        </YZTypography>
      </Box>
      <Box>
        <YZTypography variant="caption">
          <em>
            This customized text will be shown in addition to the default
            education note that is shown to the client. The default note is
            shown below.
          </em>
        </YZTypography>
        <YZTypography>
          Our job is to provide you with a design plan that balances your
          priorities and your budget. Now that we've had a chance to review all
          your materials, we can give you more insight into the estimate range
          we expect for your landscaping project.
        </YZTypography>
      </Box>
      <Box mt={2} textAlign="left">
        {isBotanicalClient && (
          <Typography color="error" variant="caption">
            Client has a botanical package, please avoid mentioning the Wish
            List or other services not included with the Botanical package
          </Typography>
        )}
        <TextField
          id="estimate-education-note"
          placeholder="Estimate education note"
          value={estimateEducationNote}
          onChange={(e) => setEstimateEducationNote(e.target.value)}
          multiline
          fullWidth
          variant="outlined"
          rows={4}
          inputProps={{
            "data-testid": "estimate-education-note-input",
          }}
        />
      </Box>
    </>
  );
};

export { WesleySummaryPageEstimateNote };
