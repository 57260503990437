import * as React from "react";
import {
  TextField,
  Button,
  makeStyles,
  Theme,
  LinearProgress,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider,
} from "@material-ui/core";
import firebase from "firebase/compat/app";
import GenericConfirm from "./GenericConfirm";
import GenericSnackBar from "./GenericSnackBar";

export interface IContactInfo {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  id: string;
  createdAt: any; // firestore fieldvalue
}

const formMap = {
  firstName: "First Name",
  lastName: "Last Name",
  phone: "Phone",
  email: "Email",
};

interface Props {
  profileId: string;
  contactDoc: IContactInfo;
  getContacts: (profileId: string) => void;
  newContact?: true;
}

function ContactPanel({
  profileId,
  contactDoc,
  getContacts,
  newContact,
}: Props): React.ReactElement {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [saved, setSaved] = React.useState<boolean>(true);
  const [working, setWorking] = React.useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<null | string>(null);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<boolean>(false);

  React.useEffect(() => {
    fillContacts(contactDoc);
    if (!newContact) {
      return;
    }
    setSaved(false);
    setExpanded(true);
  }, []);

  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={expanded}
        onClick={() => {
          setSaved(!saved);
          setExpanded(!expanded);
        }}
      >
        <ExpansionPanelSummary
          style={{ display: "flex", flexDirection: "column" }}
        >
          <em>{renderTitle()}</em>
        </ExpansionPanelSummary>
        {working && <LinearProgress />}
        <ExpansionPanelDetails
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className={classes.fields}>{renderTextFields()}</div>
          <div className={classes.buttons}>
            {saved ? (
              <div>
                <Button
                  onClick={() => setSaved(false)}
                  variant="contained"
                  color="primary"
                >
                  Edit
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  onClick={() => saveContact()}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
                <Button
                  onClick={(e) => {
                    setConfirmOpen(true);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  variant="text"
                  className={classes.errButton}
                >
                  DELETE
                </Button>
              </div>
            )}
          </div>
          <GenericConfirm
            onSubmit={deleteContact}
            open={confirmOpen}
            onClose={() => setConfirmOpen(false)}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {error && (
        <GenericSnackBar
          orientation={{
            vertical: "bottom",
            horizontal: "left",
          }}
          message={error}
          variant="error"
          onClose={() => {}}
        />
      )}
      {success && (
        <GenericSnackBar
          orientation={{
            vertical: "bottom",
            horizontal: "left",
          }}
          message={"Contact Saved!"}
          variant="success"
          onClose={() => {
            setSuccess(false);
          }}
        />
      )}
    </div>
  );

  function renderTitle() {
    if (newContact) return "NOT SAVED";
    if (!contactDoc.firstName.length && !contactDoc.lastName.length) {
      return "NO INFO";
    }
    return (
      <div>
        {contactDoc.lastName}, {contactDoc.firstName} <br />
        <Divider />
        {contactDoc.email} <br />
        {formatPhoneNumber(contactDoc.phone)}
      </div>
    );
  }

  function formatPhoneNumber(phoneNumberString: string) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  function renderTextFields() {
    const varArr: [string, React.Dispatch<React.SetStateAction<string>>][] = [
      [firstName, setFirstName],
      [lastName, setLastName],
      [phone, setPhone],
      [email, setEmail],
    ];

    return Object.keys(formMap).map((key, index) => {
      const val = varArr[index][0];
      const setVal = varArr[index][1];
      return (
        <TextField
          className={classes.tField}
          value={val}
          classes={{
            root: saved ? classes.em : classes.norm,
          }}
          InputLabelProps={
            saved
              ? {
                  focused: false,
                  shrink: val.length > 0,
                }
              : undefined
          }
          InputProps={{
            readOnly: saved ? true : false,
          }}
          onChange={(e) => setVal(e.target.value)}
          label={
            // @ts-ignore
            formMap[key]
          }
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      );
    });
  }

  async function deleteContact() {
    setWorking(true);
    await firebase
      .firestore()
      .collection("profiles")
      .doc(profileId)
      .collection("contacts")
      .doc(contactDoc.id)
      .delete()
      .then(() => {
        setWorking(false);
        getContacts(profileId);
      })
      .catch((e) => {
        console.log("Error deleting: ", e);
        setError("Error deleting contact");
      });
  }

  async function saveContact() {
    setWorking(true);
    const docData = {
      firstName,
      lastName,
      phone,
      email,
    };
    return firebase
      .firestore()
      .collection("profiles")
      .doc(profileId)
      .collection("contacts")
      .doc(contactDoc.id)
      .update(docData)
      .then(() => {
        setWorking(false);
        setSaved(true);
        setSuccess(true);
        getContacts(profileId);
      })
      .catch((e) => {
        console.log("Error saving contact: ", e);
        setError("Error saving contact!");
      });
  }

  function fillContacts(existingContact: IContactInfo) {
    Object.keys(existingContact).forEach((key) => {
      switch (key) {
        case "firstName":
          setFirstName(existingContact.firstName);
          break;
        case "lastName":
          setLastName(existingContact.lastName);
          break;
        case "phone":
          setPhone(existingContact.phone);
          break;
        case "email":
          setEmail(existingContact.email);
          break;
        default:
          break;
      }
    });
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "fit-content",
    padding: "1.5rem",
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      padding: "0.2rem",
      margin: 0,
    },
  },
  fields: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
  },
  tField: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
  },
  buttons: {
    marginTop: "1rem",
  },
  em: {
    fontStyle: "italic",
  },
  norm: {
    fontStyle: "default",
  },
  errButton: {
    color: theme.palette.error.main,
  },
}));

export default ContactPanel;
