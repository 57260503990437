import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import firebase from "firebase/compat/app";
import {
  CREATE_ACCOUNT_MANAGER,
  CREATE_BUILD_REGION,
  GET_ACCOUNT_MANAGER,
  GET_ACCOUNT_MANAGERS,
  GET_BUILD_REGIONS_BY_PROJECT_LOCATION,
  GET_BUILD_REGIONS_WITH_ACCOUNT_MANAGER_ID,
  GET_BUILD_REGION_BY_ID,
  SET_CONTRACTOR_USER_CLAIMS,
  UPDATE_ACCOUNT_MANAGER,
  UPDATE_BUILD_REGION_BY_ID,
} from "../EmployeeView/BuildView/routes/Contractors/util/BuildRegions/graphqlQueries";
import {
  AccountManager,
  BuildRegion,
  StatusObject,
} from "../EmployeeView/BuildView/routes/Contractors/util/BuildRegions/types";

export const BANYAN_BASE_URL =
  process.env["REACT_APP_BANYAN_BASE_URL"] || "http://localhost:3000/";

type FindOneBuildRegionInput = {
  id: string;
  internalName?: string;
};

type FindAllBuildRegionInput = {
  coordinates?: [number, number];
  isDefault?: boolean;
  archived?: boolean;
  accountManagerId?: string;
  contractorId?: string;
};

type UpdateBuildRegionInput = Required<Pick<BuildRegion, "id">> &
  Partial<Omit<BuildRegion, "id">>;

type CreateBuildRegionInput = Omit<BuildRegion, "id">;

type UpdateAccountManagerInput = Required<Pick<AccountManager, "id">> &
  Partial<Omit<AccountManager, "createdTs" | "updatedTs" | "buildRegions">> & {
    buildRegionIds?: string[];
  };

type CreateAccountManagerInput = Omit<
  AccountManager,
  "id" | "createdTs" | "updatedTs" | "buildRegions"
> & {
  buildRegionIds?: string[];
};

type SetContractorCustomUserClaimsInput = {
  contractorId: string;
  email: string;
};

enum BanyanApiTags {
  AccountManager = "ACCOUNT_MANAGER",
}

export const banyanAPI = createApi({
  reducerPath: "banyanAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: BANYAN_BASE_URL,
    prepareHeaders: async (headers) => {
      const user = firebase.auth()?.currentUser;
      if (user) {
        headers.set("Authorization", `Bearer ${await user.getIdToken()}`);
      }

      return headers;
    },
  }),
  tagTypes: [BanyanApiTags.AccountManager],
  endpoints: (builder) => ({
    getBuildRegionById: builder.query<BuildRegion, FindOneBuildRegionInput>({
      query: (input) => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: GET_BUILD_REGION_BY_ID,
          variables: {
            input,
          },
        },
      }),
      transformResponse: (response: any) => response.data.buildRegion,
    }),
    updateBuildRegion: builder.mutation<StatusObject, UpdateBuildRegionInput>({
      query: (input) => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: UPDATE_BUILD_REGION_BY_ID,
          variables: {
            input,
          },
        },
      }),
    }),
    createBuildRegion: builder.mutation<BuildRegion, CreateBuildRegionInput>({
      query: (input) => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: CREATE_BUILD_REGION,
          variables: {
            input,
          },
        },
      }),
      transformResponse: (response: any) => response.data.createBuildRegion,
    }),
    getBuildRegionsByProjectLocation: builder.query<
      BuildRegion[],
      FindAllBuildRegionInput
    >({
      query: (input) => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: GET_BUILD_REGIONS_BY_PROJECT_LOCATION,
          variables: {
            input,
          },
        },
      }),
      transformResponse: (response: any) => response.data.buildRegions,
    }),
    getAllAccountManagers: builder.query<
      AccountManager[],
      { refetch: boolean }
    >({
      query: () => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: GET_ACCOUNT_MANAGERS,
        },
      }),
      providesTags: [BanyanApiTags.AccountManager],
      transformResponse: (response: any) => response.data.accountManagers,
    }),
    getBuildRegionsWithAccountManager: builder.query<
      BuildRegion[],
      FindAllBuildRegionInput & { refetch: boolean }
    >({
      query: (input) => {
        const { refetch, ...rest } = input;
        return {
          url: "/graphql",
          method: "POST",
          body: {
            query: GET_BUILD_REGIONS_WITH_ACCOUNT_MANAGER_ID,
            variables: {
              input: rest,
            },
          },
        };
      },
      providesTags: [BanyanApiTags.AccountManager],
      transformResponse: (response: any) => response.data.buildRegions,
    }),
    getAccountManager: builder.query<AccountManager, { id: string }>({
      query: (input) => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: GET_ACCOUNT_MANAGER,
          variables: {
            input,
          },
        },
      }),
      providesTags: [BanyanApiTags.AccountManager],
      transformResponse: (response: any) => response.data.accountManager,
    }),
    updateAccountManager: builder.mutation<
      AccountManager,
      UpdateAccountManagerInput
    >({
      query: (input) => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: UPDATE_ACCOUNT_MANAGER,
          variables: {
            input,
          },
        },
      }),
      invalidatesTags: [BanyanApiTags.AccountManager],
      transformResponse: (response: any) => response.data.updateAccountManager,
    }),
    createAccountManager: builder.mutation<
      AccountManager,
      CreateAccountManagerInput
    >({
      query: (input) => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: CREATE_ACCOUNT_MANAGER,
          variables: {
            input,
          },
        },
      }),
      invalidatesTags: [BanyanApiTags.AccountManager],
      transformResponse: (response: any) => response.data.createAccountManager,
    }),
    setContractorCustomUserClaims: builder.mutation<
      String,
      SetContractorCustomUserClaimsInput
    >({
      query: (input) => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: SET_CONTRACTOR_USER_CLAIMS,
          variables: {
            input,
          },
        },
      }),
    }),
  }),
});
