import React, { useMemo } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { AssignmentProperties } from "@yardzen-inc/models";
import { Box, LinearProgress, Typography } from "@material-ui/core";
import { useQuerySnapshot, useLogError } from "../../util/hooks";
import MetricCard from "./MetricCard";

export interface MetricsProps {}

interface AssignmentPropsWithLength {
  size: number;
  assignments: AssignmentProperties[];
}

const Metrics: React.FC<MetricsProps> = (props) => {
  const [err, assignmentsWithSize] =
    useQuerySnapshot<AssignmentPropsWithLength>(
      useMemo(
        () =>
          firebase
            .firestore()
            .collectionGroup("assignments")
            .where("completed", "==", false),
        []
      ),
      handleAssignmentSnapshot,
      []
    );

  const metrics = useMetrics(assignmentsWithSize || null);

  useLogError(err);

  return (
    <>
      <LinearProgress
        style={{
          transition: "opacity 150ms",
          opacity: !assignmentsWithSize ? 1 : 0,
        }}
      />
      <Typography variant="h3" gutterBottom>
        Self Assign Metrics
      </Typography>
      <Box p={1} display="flex" flexDirection="row" flexWrap="wrap">
        {renderMetrics()}
      </Box>
    </>
  );

  function renderMetrics(): React.ReactNode[] {
    return metrics.map((m) => <MetricCard {...m} key={m.title} />);
  }
};

function handleAssignmentSnapshot(
  snap: firebase.firestore.QuerySnapshot
): AssignmentPropsWithLength {
  return {
    assignments: snap.docs.map((d) => d.data() as AssignmentProperties),
    size: snap.size,
  };
}

function useMetrics(
  assigns: AssignmentPropsWithLength | null
): { title: string; content: string }[] {
  return useMemo(() => {
    if (!assigns) {
      return [];
    }

    const wesleys = assigns.assignments.filter((a) => a.type === "wesley");
    const modelers = assigns.assignments
      .filter((a) => a.type === "modeling")
      .filter((a) => a.listed)
      .filter((a) => !a["isTestAssignment"]);

    return [
      {
        title: "Active Assignments",
        content: assigns.size.toString(),
      },
      {
        title: "Active CDM Assignments",
        content: wesleys.length.toString(),
      },
      {
        title: "Active Modeling Assignments",
        content: modelers.length.toString(),
      },
      {
        title: "Assigned CDM assignments",
        content: wesleys.filter((w) => !!w.assignedTo).length.toString(),
      },
      {
        title: "Assigned Modeling assignments",
        content: modelers.filter((m) => !!m.assignedTo).length.toString(),
      },
      {
        title: "Unassigned CDM assignments",
        content: wesleys.filter((w) => !w.assignedTo).length.toString(),
      },
      {
        title: "Unassigned Modeling assignments",
        content: modelers.filter((m) => !m.assignedTo).length.toString(),
      },
      {
        title: "Unlisted CDM assignments",
        content: wesleys
          .filter((w) => !w.assignedTo && !w.listed)
          .length.toString(),
      },
      {
        title: "Unlisted Modeling assignments",
        content: modelers
          .filter((m) => !m.assignedTo && !m.listed)
          .length.toString(),
      },
    ];
  }, [assigns]);
}

export { Metrics };
export default Metrics;
