import * as React from "react";

export const SearchTextCtx = React.createContext<any>("");

interface UserProviderProps {
  children: React.ReactNode[] | React.ReactNode;
}

export const SearchTextProvider = React.memo((props: UserProviderProps) => {
  const [searchText, setSearchText] = React.useState<string>("");

  return (
    <SearchTextCtx.Provider value={{ searchText, setSearchText }}>
      {props.children}
    </SearchTextCtx.Provider>
  );
});
