import * as React from "react";
import { Typography, Divider, ListItem, makeStyles } from "@material-ui/core";
import { Profile } from "@yardzen-inc/models";
import { YZButton } from "@yardzen-inc/react-common";

const useStyles = makeStyles({
  listItem: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-around",
  },
  listItemBox: {
    width: "25%",
  },
});
interface Props {
  p: Profile;
  setModalProps: (props: { open: boolean; profile: Profile }) => void;
}

export default ({ p, setModalProps }: Props) => {
  const classes = useStyles();

  return (
    <>
      <ListItem classes={{ root: classes.listItem }}>
        <div className={classes.listItemBox}>
          <Typography>{p.firstName}</Typography>
        </div>
        <div className={classes.listItemBox}>
          <Typography>{p.lastName}</Typography>
        </div>
        <div className={classes.listItemBox}>
          <Typography>{p.email}</Typography>
        </div>
        <YZButton
          variant="outlined"
          onClick={() => setModalProps({ open: true, profile: p })}
        >
          Assign
        </YZButton>
      </ListItem>
      <Divider />
    </>
  );
};
