import * as React from "react";
import { makeStyles, AppBar, Box } from "@material-ui/core";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { AgentType } from "@yardzen-inc/models";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import { YARDZEN_LOGO_BLACK_SRC } from "../util/logoConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    backgroundColor: "white",
    padding: "0rem 0.5rem",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));

interface Props {
  agentType: AgentType;
}

export default ({ agentType }: Props) => {
  const classes = useStyles();
  let title = "";

  if (agentType === "wesley") {
    title = "Design Support Dashboard";
  } else if (agentType === "modeling") {
    title = "Modeler Dashboard";
  } else if (agentType === "design") {
    title = "Designer Dashboard";
  }

  return (
    <AppBar
      id="self-assign-header-app-bar"
      position="static"
      title="Yardzen"
      className={classes.root}
      elevation={0}
    >
      <Box flex="1" display="flex" alignItems="center">
        <Box pr={3} display="flex">
          <img src={YARDZEN_LOGO_BLACK_SRC} height="30" />
        </Box>
        <YZTypography variant="body1" type="mono" style={{ fontWeight: 600 }}>
          {title}
        </YZTypography>
      </Box>
      <YZButton
        style={{
          marginLeft: "auto",
        }}
        onClick={() => firebase.auth().signOut()}
        variant="text"
      >
        Logout
      </YZButton>
    </AppBar>
  );
};
