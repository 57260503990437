import { configureStore } from "@reduxjs/toolkit";
import { sessionReducer as session } from "./slices/session/sessionSlice";
import { entitiesReducer as entities } from "./slices/entities/entitiesReducer";
import { pangaeaV1API } from "../api/pangaeaV1API";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { banyanAPI } from "../api/banyanV1API";

export const store = configureStore({
  reducer: {
    session,
    entities,
    [pangaeaV1API.reducerPath]: pangaeaV1API.reducer,
    [banyanAPI.reducerPath]: banyanAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(pangaeaV1API.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
