import * as React from "react";
import { Divider, makeStyles, Typography } from "@material-ui/core";
import { Profile } from "@yardzen-inc/models";
import { YZButton } from "@yardzen-inc/react-common";

const useStyles = makeStyles({
  mobileRoot: {
    display: "flex",
    flexFlow: "column nowrap",
    maxWidth: "100vw",
  },
  mobileListItem: {
    display: "flex",
    flexFlow: "column nowrap",
    // padding: "1rem",
  },
  mobileListRow: {
    display: "flex",
    flexFlow: "row wrap",
    margin: "0.8rem 0",
  },
  mobileListColumn: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "calc(50% - 1rem)",
    marginLeft: "1rem",
    maxWidth: "50%",
  },
});

interface Props {
  record: Profile;
  onClick: () => void;
}

export default ({ record, onClick }: Props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.mobileListItem}>
        <div className={classes.mobileListRow}>
          <div className={classes.mobileListColumn}>
            <Typography variant="caption">Client Name</Typography>
            <Typography variant="body1">{`${record.firstName} ${record.lastName}`}</Typography>
          </div>
        </div>
        <div className={classes.mobileListRow}>
          <div className={classes.mobileListColumn}>
            <Typography variant="caption">Email</Typography>
            <Typography variant="body1">{`${
              record.email || "unknown"
            }`}</Typography>
          </div>
        </div>
        <div className={classes.mobileListRow}>
          <div className={classes.mobileListColumn}>
            <Typography variant="caption">Func Flow Recieved</Typography>
            <Typography variant="body1">{`${!!record.funcFlowReady}`}</Typography>
          </div>
          <div className={classes.mobileListColumn}>
            <Typography variant="caption">Project State</Typography>
            <Typography variant="body1">{`${
              record.projectState || "unknown"
            }`}</Typography>
          </div>
        </div>
        <YZButton variant="text" onClick={onClick}>
          View
        </YZButton>
      </div>
      <Divider></Divider>
    </>
  );
};
