import * as React from "react";
import { Assignment } from "@yardzen-inc/models";
import AssignmentBrief from "../../ClientDetail/Assignments/AssignmentBrief";
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";

interface Props {
  assignments: Assignment[] | null;
  onClick: (a: Assignment) => void;
  listRef: React.RefObject<HTMLDivElement>;
  order: "asc" | "desc";
  useArchive?: boolean;
  syncing?: boolean;
}

const NUMBER_OF_RECORDS = 15;

export default (props: Props) => {
  const [offset, setOffset] = React.useState<number>(0);

  const len = React.useMemo(() => {
    setOffset(0);
    return props.assignments?.length ?? 0;
  }, [props.assignments]);

  const containerRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
      >
        {!props.syncing ? (
          <Typography variant="h5">
            {`${props.assignments?.length || 0} results`}
          </Typography>
        ) : (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Box pr={2}>
              <CircularProgress></CircularProgress>
            </Box>

            <Typography variant="h5"> Syncing Assignments</Typography>
          </Box>
        )}
      </Box>
      <div style={{ width: "inherit" }} ref={containerRef}>
        {renderAssignments()}
      </div>
    </>
  );

  function renderPageControls(
    start: number,
    end: number
  ): React.ReactNode | null {
    if (!containerRef.current || len <= NUMBER_OF_RECORDS) {
      return null;
    }

    const rect = containerRef.current.getBoundingClientRect();

    return (
      <Box
        height="50px"
        bgcolor="#EFEFEF"
        display="inline-flex"
        position="fixed"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        width={rect.width + 8}
        top={window.innerHeight - 50}
        left={rect.left - 4}
      >
        <IconButton
          onClick={() => setOffset(offset - 1)}
          disabled={offset <= 0}
        >
          <ArrowLeft></ArrowLeft>
        </IconButton>
        <Box px={2}>
          <Typography>
            {start} - {end} / {len}
          </Typography>
        </Box>
        <IconButton onClick={() => setOffset(offset + 1)} disabled={end >= len}>
          <ArrowRight></ArrowRight>
        </IconButton>
      </Box>
    );
  }

  function renderAssignments(): React.ReactNode {
    const start = Math.min(offset * NUMBER_OF_RECORDS, len - 1);
    const end = Math.min(start + NUMBER_OF_RECORDS, len);

    if (!props.assignments) {
      return null;
    }

    const records = props.assignments.slice(start, end);

    return (
      <>
        {records.map((m, i) => renderItem(m, i + start))}
        {renderPageControls(start + 1, end)}
      </>
    );
  }

  function renderItem(assignment: Assignment, index: number): React.ReactNode {
    return (
      <div
        ref={props.listRef}
        key={`breif-${assignment.id}`}
        style={{ pointerEvents: "all", cursor: "pointer" }}
        onClick={() => props.onClick(assignment)}
      >
        <AssignmentBrief
          useArchive={props.useArchive}
          short
          assignment={assignment}
          index={index + 1}
          key={assignment.id}
          showAddress
          showClientInfo
        />
      </div>
    );
  }
};
