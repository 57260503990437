import React from "react";
import AssetLibraryCreateItem from "../Shared/AssetLibraryItemCreate";
import AddNewPlant from "./AddNewPlant";

function PlantLibraryCreate() {
  return (
    <AssetLibraryCreateItem
      goBackLocation="/plants-library"
      addNewAssetComponent={(assetProps) => (
        <AddNewPlant {...assetProps} onSave={assetProps.onSave} />
      )}
    />
  );
}

export default PlantLibraryCreate;
