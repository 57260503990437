import { HasuraQueryHumanReadableOperatorEnum } from "@yardzen-inc/util";
import { IContactInfo } from "./Components/ContactPanel";
import { AssetLibraryTypeConstants } from "./ConstantValues/AssetLibraryTypeConstants";
import { Project as ProjectFromModel } from "@yardzen-inc/models";

export enum ModelFiletype {
  sketchup = "sketchup",
  vray = "vray",
  proxy = "proxy",
  lumion = "lumion",
}

export enum PackageType {
  Botanical = "botanical",
  FullYard = "full yard",
  FrontYard = "front yard",
  BackYard = "back yard",
  CurbAppeal = "curb appeal",
  OutdoorTransformation = "whole home",
  UberPremium = "uber premium",
  SmallSpace = "small space",
  FrontOrBackyardStarter = "front or backyard starter",
  EntireYardStarter = "entire yard starter",
}

export enum HoldStatus {
  ManualHold = "MANUAL_HOLD",
  Annotated = "ANNOTATED",
  BudgetWarning = "BUDGET_WARNING",
  RecentlyDelivered = "RECENTLY_DELIVERED",
  Vip = "VIP",
  PmQuestions = "PM_QUESTIONS",
}

export type YardPackages =
  | "botanical"
  | "full yard"
  | "front yard"
  | "back yard";

export interface SharedFormikProps {
  visibility: string;
}

export interface SharedFormikErrorProps {
  visibility: string;
}

export interface Profile {
  id: string;
  userId: string;
  firstName?: string;
  lastName?: string;
  answers?: object;
  email?: string;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  legacyStatus?: LegacyStatus;
  upworkName?: string;
  hardinessZone?: string;
  designArea?: string;
  delighted?: boolean;
  conciergeHours?: number;
  pinterestUrl?: string;
  elements?: object;
  featureFlags?: Array<string>;
  funcFlowReady?: boolean;
  funcFlowApproved?: boolean;
  wizardDone?: boolean | any;
  welcomeDismissed?: boolean;
  dateSubmittedForReview?: any;
  clientReviewModalDismissed?: boolean;
  finalDesignModalDismissed?: boolean;
  projectState?: string;
  installType?: string;
  reportedBudget?: string;
  secondaryContacts?: IContactInfo[];
}

export interface AirProfile {
  id: string;
  userId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  zone?: string;
}

export interface MediaRecord {
  readonly userId: string;
  readonly path: string; // <userId>.<uuid>.<unix timestamp in milliseconds>
  readonly originalFileName: string;
  readonly fileType: string;
  readonly downloadUrl?: string;
  readonly uploadedAt: string;
  readonly isDesignerVisible?: boolean;
  readonly isClientVisible?: boolean;
}

export enum UserRole {
  PM = "PM",
  Designer = "Designer",
}

export enum LegacyStatus {
  Onboarded = 0,
  MaterialsReceived = 1,
  V1DesignInProcess = 2,
  V1DesignDelivered = 3,
  FeedBackReceived = 4,
  V2DesignInProcess = 5,
  FinalDesignDelivered = 6,
  ReadyForInstall = 7,
}

export const airtableToFirebaseMap = {};

export enum SoilType {
  Clay = "clay",
  Loam = "loam",
  Sandy = "sandy",
}

export const SoilTypes = {
  clay: "Clay",
  loam: "Loam",
  sandy: "Sandy",
};

export const PaletteTypes = {
  bali: "Bali",
  copenhagen: "Copenhagen",
  crete: "Crete",
  joshuatree: "Joshua Tree",
  napa: "Napa",
  petaluma: "Petaluma",
  sonoma: "Sonoma",
  stinson: "Stinson",
  sussex: "Sussex",
  kyoto: "Kyoto",
};

export const legacyStatusMap = {
  Onboarded: LegacyStatus.Onboarded,
  "Materials Received": LegacyStatus.MaterialsReceived,
  "V1 design in process": LegacyStatus.V1DesignInProcess,
  "V1 design delivered": LegacyStatus.V1DesignDelivered,
  "Feedback Received": LegacyStatus.FeedBackReceived,
  "V2 design in process": LegacyStatus.V2DesignInProcess,
  "Final design delivered": LegacyStatus.FinalDesignDelivered,
  "Ready for install": LegacyStatus.ReadyForInstall,
};

var enumStatuses = {};
Object.keys(legacyStatusMap).map((key, index) => {
  // @ts-ignore
  enumStatuses[index] = key;
});

export const LegacyStatuses = enumStatuses;

// Assets join tables that have the exact same shape,
// so might as well just have one interface for them.
export interface IGenericAssetSupplement {
  name: string;
  id: string;
  archived: boolean;
}
export interface IProductBaseProductColorLink {
  product_color: IGenericAssetSupplement;
}
export interface IProductBaseProductStyleLink {
  product_style: IGenericAssetSupplement;
}
export interface IProductBaseRegionLink {
  product_region: IGenericAssetSupplement;
}
export interface IProductBaseProductMaterialLink {
  product_material: IGenericAssetSupplement;
}

export interface IProductBaseProductTypeLink {
  product_type: IGenericAssetSupplement;
}

export interface IMaterialBaseMaterialStyleLink {
  material_style: IGenericAssetSupplement;
}

export interface IMaterialBaseMaterialMaterialLink {
  material_material: IGenericAssetSupplement;
}

export interface IMaterialBaseMaterialTypeLink {
  material_type: IGenericAssetSupplement;
}

export interface IMaterialBaseRegionLink {
  product_region: IGenericAssetSupplement;
}

export interface IMaterialBaseMaterialColorLink {
  material_color: IGenericAssetSupplement;
}

export interface IMaterialBasePaverApplicationLink {
  paver_application: IGenericAssetSupplement;
}

export interface IPlantBasePlantAdaptedHabitatLink {
  plant_adapted_habitat: IGenericAssetSupplement;
}

export interface IPlantBasePlantGardenStyleLink {
  plant_garden_style: IGenericAssetSupplement;
}

export interface IPlantBasePlantTypeLink {
  plant_type: IGenericAssetSupplement;
}

export interface IPlantBasePlantSourceLink {
  plant_source: IGenericAssetSupplement;
}

export interface IPlantBasePlantBloomTimeLink {
  plant_bloom_time: IGenericAssetSupplement;
}

export interface IPlantBasePlantCadSizeLink {
  plant_cad_symbol_size: IGenericAssetSupplement;
}

export interface IPlantBasePlantCadSpacingLink {
  plant_cad_symbol_spacing: IGenericAssetSupplement;
}

export interface IPlantBasePlantContainerSizeLink {
  plant_container_size: IGenericAssetSupplement;
}

export interface IPlantBasePlantFlowerFruitColorLink {
  plant_flower_fruit_color: IGenericAssetSupplement;
}

export interface IPlantBasePlantFoliageColorLink {
  plant_foliage_color: IGenericAssetSupplement;
}

export interface IPlantBasePlantGrowthHabitLink {
  plant_growth_habit: IGenericAssetSupplement;
}

export interface IPlantBasePlantKeyFeatureLink {
  plant_key_feature: IGenericAssetSupplement;
}

export interface IPlantBasePlantLightNeedsLink {
  plant_light_needs: IGenericAssetSupplement;
}

export interface IPlantBasePlantNativeRangeLink {
  plant_native_range: IGenericAssetSupplement;
}

export interface IPlantBasePlantHeightClassLink {
  plant_height_class: IGenericAssetSupplement;
}

export interface IPlantBasePlantWidthClassLink {
  plant_width_class: IGenericAssetSupplement;
}

export interface IPlantBasePlantMatureSizeLink {
  plant_mature_size: IGenericAssetSupplement;
}

export interface IPlantBasePlantLeafColorLink {
  plant_leaf_color: IGenericAssetSupplement;
}

export interface IPlantBasePlantFlowerColorLink {
  plant_flower_color: IGenericAssetSupplement;
}

export interface IPlantBasePlantFruitColorLink {
  plant_fruit_color: IGenericAssetSupplement;
}

export interface IPlantBasePlantPaletteLink {
  plant_palette: IGenericAssetSupplement;
}

export interface IPlantBasePlantRedFlagLink {
  plant_red_flag: IGenericAssetSupplement;
}

export interface IPlantBasePlantUsdaZoneLink {
  plant_usda_zone: IGenericAssetSupplement;
}

export interface IPlantBasePlantVipLink {
  plant_vip: IGenericAssetSupplement;
}

export interface IFormikPlantProps extends SharedFormikProps {
  scientific_name: string;
  common_name: string;
  unitCost: string;
  icon_uri: string;
  lumion_file_name: string;
  lumion_file_link: string;
  max_file_link: string;
  sketchup_file_link: string;
  sketchup_proxy_file_link: string;
  vray_file_link: string;
  additional_info: string;
  plant_container_size: string[];
  design_tips: string;
  red_flag_info: string;
  plant_cad_symbol_size: string[];
  plant_cad_symbol_spacing: string[];
  plant_water_needs: string;
  plant_deciduous_or_evergreen: string;
  plant_type: string[];
  plant_adapted_habitat: string[];
  plant_bloom_time: string[];
  plant_width_class: string[];
  plant_mature_size: string[];
  plant_height_class: string[];
  plant_growth_habit: string[];
  plant_key_feature: string[];
  plant_light_needs: string[];
  plant_native_range: string[];
  plant_garden_style: string[];
  plant_leaf_color: string[];
  plant_flower_color: string[];
  plant_fruit_color: string[];
  plant_red_flag: string[];
  plant_usda_zone: string[];
  plant_vip: string[];
  companion_plants: string;
  similar_plants: string;
  plant_retailer: string;
}

export interface IFormikPlantErrorProps extends SharedFormikErrorProps {
  scientific_name: string;
  common_name: string;
  average_height: string;
  average_width: string;
  icon_uri: string;
  sketchup_file_link: string;
  vray_file_link: string;
  additional_info: string;
  plant_container_size: string;
  plant_type: string;
  design_tips: string;
  red_flag_info: string;
  plant_cad_symbol_size: string;
  plant_cad_symbol_spacing: string;
  plant_water_needs: string;
  plant_deciduous_or_evergreen: string;
  plant_adapted_habitat: string;
  plant_bloom_time: string;
  plant_flower_fruit_color: string;
  plant_foliage_color: string;
  plant_growth_habit: string;
  plant_key_feature: string;
  plant_light_needs: string;
  plant_native_range: string;
  plant_palette: string;
  plant_red_flag: string;
  plant_usda_zone: string;
  plant_vip: string;
  plant_garden_style: string;
  plant_source: string;
  plant_leaf_color: string;
  plant_flower_color: string;
  plant_fruit_color: string;
  plant_height_class: string;
  plant_width_class: string;
  plant_mature_size: string;
}

export interface IProductBase {
  id: string;
  archived: boolean;
  name: string;
  icon_uri: string;
  link: string;
  productTypeByProductType: IGenericAssetSupplement;
  vendorByProductVendor: IGenericAssetSupplement;
  retailerByProductRetailer: IGenericAssetSupplement;
  settingByProductSetting: IGenericAssetSupplement;
  priceTierByProductPriceTier: IGenericAssetSupplement;
  collectionNameByProductCollectionName: IGenericAssetSupplement;
  msrp: string;
  unit_cost: string;
  labor_cost: string;
  lumion_file_name: string;
  sketchup_file_link: string;
  vray_file_link: string;
  lumion_file_link: string;
  max_file_link: string;
  fbx_file_link: string;
  in_stock: boolean;
  product_type: string;
  product_collection_name: string;
  product_price_tier: string;
  product_vendor: string;
  product_retailer: string;
  product_setting: string;
  product_base_product_color_links: IProductBaseProductColorLink[];
  product_base_product_style_links: IProductBaseProductStyleLink[];
  product_base_product_material_links: IProductBaseProductMaterialLink[];
  product_base_product_type_links: IProductBaseProductTypeLink[];
}

export interface IMaterialBase {
  id: string;
  archived: boolean;
  name: string;
  icon_uri: string;
  link: string;
  materialTypeByMaterialType: IGenericAssetSupplement;
  vendorByMaterialVendor: IGenericAssetSupplement;
  retailerByMaterialRetailer: IGenericAssetSupplement;
  settingByProductSetting: IGenericAssetSupplement;
  collectionNameByMaterialCollectionName: IGenericAssetSupplement;
  priceTierByMaterialPriceTier: IGenericAssetSupplement;
  unit_cost: string;
  labor_cost: string;
  lumion_file_name: string;
  sketchup_file_link: string;
  vray_file_link: string;
  lumion_file_link: string;
  max_file_link: string;
  in_stock: boolean;
  product_type: string;
  product_vendor: string;
  material_retailer: string;
  product_setting: string;
  unitByUnit: { name: string };
  material_base_material_color_links: IMaterialBaseMaterialColorLink[];
  material_base_material_style_links: IMaterialBaseMaterialStyleLink[];
  material_base_material_material_links: IMaterialBaseMaterialMaterialLink[];
  material_base_material_type_links: IMaterialBaseMaterialTypeLink[];
  material_base_region_links: IMaterialBaseRegionLink[];
  material_base_paver_application_links: IMaterialBasePaverApplicationLink[];
  material_finish: { name: string };
  material_pattern: { name: string };
  material_size: { name: string };
}

export interface IPlantBase {
  id: string;
  archived: string;
  scientific_name: string;
  common_name: string;
  icon_uri: string;
  average_height: string;
  average_width: string;
  additional_info: string;
  plant_base_plant_container_size_links: IPlantBasePlantContainerSizeLink[];
  exact_size: string;
  design_tips: string;
  red_flag_info: string;
  unit_cost: string;
  plant_retailer: string;
  waterNeedsByPlantWaterNeeds: IGenericAssetSupplement;
  matureWidthByPlantMatureWidth: IGenericAssetSupplement;
  matureHeightByPlantMatureHeight: IGenericAssetSupplement;
  minimumSpaceToPlantByPlantminimumSpaceToPlant: IGenericAssetSupplement;
  deciduousOrEvergreenByPlantDeciduousOrEvergreen: IGenericAssetSupplement;
  retailerByPlantRetailer: IGenericAssetSupplement;
  plant_base_adapted_habitat_links: IPlantBasePlantAdaptedHabitatLink[];
  plant_base_plant_type_links: IPlantBasePlantTypeLink[];
  plant_base_plant_bloom_time_links: IPlantBasePlantBloomTimeLink[];
  plant_base_plant_flower_fruit_color_links: IPlantBasePlantFlowerFruitColorLink[];
  plant_base_plant_foliage_color_links: IPlantBasePlantFoliageColorLink[];
  plant_base_plant_growth_habit_links: IPlantBasePlantGrowthHabitLink[];
  plant_base_plant_key_feature_links: IPlantBasePlantKeyFeatureLink[];
  plant_base_plant_light_needs_links: IPlantBasePlantLightNeedsLink[];
  plant_base_plant_native_range_links: IPlantBasePlantNativeRangeLink[];
  plant_base_plant_palette_links: IPlantBasePlantPaletteLink[];
  plant_base_plant_red_flag_links: IPlantBasePlantRedFlagLink[];
  plant_base_plant_usda_zone_links: IPlantBasePlantUsdaZoneLink[];
  plant_base_plant_vip_links: IPlantBasePlantVipLink[];
  plant_base_plant_garden_style_links: IPlantBasePlantGardenStyleLink[];
  plant_base_plant_source_links: IPlantBasePlantSourceLink[];
  plant_base_plant_height_class_links: IPlantBasePlantHeightClassLink[];
  plant_base_plant_width_class_links: IPlantBasePlantWidthClassLink[];
  plant_base_plant_mature_size_links: IPlantBasePlantMatureSizeLink[];
  plant_base_plant_leaf_color_links: IPlantBasePlantLeafColorLink[];
  plant_base_plant_fruit_color_links: IPlantBasePlantFruitColorLink[];
  plant_base_plant_flower_color_links: IPlantBasePlantFlowerColorLink[];
  plant_base_plant_cad_symbol_size_links: IPlantBasePlantCadSizeLink[];
  plant_base_plant_cad_symbol_spacing_links: IPlantBasePlantCadSpacingLink[];
  lumion_file_link: string;
  lumion_file_name: string;
  max_file_link: string;
  sketchup_file_link: string;
  proxy_file_link: string;
  vray_file_link: string;
  zip_file_link: string;
  visibility: boolean;
  similar_plants: string;
  companion_plants: string;
}

export interface IFormikElementProps extends SharedFormikProps {
  name: string;
  inStock: boolean;
  iconUri: string;
  unitCost: string;
  laborCost: string;
  msrp: string;
  vendor: string;
  retailer: string;
  price_tier: string;
  collection_name: string;
  setting: string;
  type: string[];
  colors: string[];
  materials: string[];
  styles: string[];
  link: string;
  visibility: string;
  affiliate_link: string;
  lumion_file_name: string;
  sketchup_file_link: string;
  sketchup_proxy_file_link: string;
  lumion_file_link: string;
  vray_file_link: string;
  max_file_link: string;
}

export interface IFormikElementErrorProps extends SharedFormikErrorProps {
  name: string;
  inStock: boolean;
  iconUri: string;
  unitCost: string;
  laborCost: string;
  msrp: string;
  vendor: string;
  retailer: string;
  type: string;
  setting: string;
  colors: string;
  price_tier: string;
  collection_name: string;
  styles: string;
  link: string;
  materials: string;
  sketchup_file_link: string;
  vray_file_link: string;
}

export interface IFormikSurfaceProps extends SharedFormikProps {
  name: string;
  inStock: boolean;
  iconUri: string;
  unitCost: string;
  laborCost: string;
  vendor: string;
  retailer: string;
  unit: string;
  collection_name: string;
  price_tier: string;
  setting: string;
  styles: string[];
  materials: string[];
  types: string[];
  link: string;
  lumion_file_name: string;
  lumion_file_link: string;
  sketchup_file_link: string;
  sketchup_proxy_file_link: string;
  vray_file_link: string;
  max_file_link: string;
  description: string;
}

export interface IFormikSurfaceErrorProps extends SharedFormikErrorProps {
  name: string;
  inStock: boolean;
  iconUri: string;
  unitCost: string;
  laborCost: string;
  vendor: string;
  unit: string;
  type: string;
  setting: string;
  collection_name: string;
  price_tier: string;
  materials: string;
  styles: string;
  link: string;
  sketchup_file_link: string;
  vray_file_link: string;
  max_file_link: string;
}

export type IFormikHandleChange = (key: string) => any;

export interface IChipOption {
  mask: string;
  value: string;
  selected: boolean;
}

export type AssetLibraryRowType = React.ReactNode | Object;

export interface AssetLibraryTableColumn {
  name: string;
  id: string;
  sortable?: boolean;
  sortDirection?: string;
  queryName?: string;
}

export interface IFilterBoxOption {
  label: string;
  query: any;
  listResultName: string;
}

export interface AssetLibraryFilterState {
  archived: boolean;
  id: string;
  name: string;
  filterId: string;
}

export interface IAssetLibraryActiveFilter {
  filterBy: string;
  filterParameter: HasuraQueryHumanReadableOperatorEnum;
  value: AssetLibraryFilterState[];
  id: string;
}

export interface IAssetLibraryUpdateQueue {
  id: string;
  quantity: number;
  ackId: string;
  type?: AssetLibraryTypeConstants;
  isPaint?: boolean;
  location?: string;
}

export type IAssetLibraryFilterBoxOnSelect = (
  e: Partial<AssetLibraryFilterState>[],
  id: string
) => void;

export interface IAssignmentSoftscapeItemLink {
  softscape_item_id: string;
  quantity: number;
}

export interface IAssignmentMaterialBaseLink {
  amount: number;
  material_base_id: string;
}

export interface IAssignmentPlantBaseLink {
  plant_base_id: string;
}

export interface IAssignmentProductBaseLink {
  product_base_id: string;
}

export interface ISelectedAssetStateContext {
  assignment_material_base_links: IAssignmentMaterialBaseLink[];
  assignment_plant_base_links: IAssignmentPlantBaseLink[];
  assignment_product_base_links: IAssignmentProductBaseLink[];
  completedFirstCall: boolean;
}

export class Project extends ProjectFromModel {
  isProDesign?: boolean;
}
