import { getPropertyMedia } from "../../util/getPropertyMedia";
import { getRevisionMedia } from "../../util/getRevisionMedia";
import { upsertSharedMediaDoc } from "../../util/upsertSharedMediaDoc";
import {
  GetBudgetQADataArgs,
  GetBudgetQADataResult,
} from "./util/useGetBudgetQADataForSharedMedia";
import {
  MediaInclusion,
  PLANTS,
  MATERIALS,
  ELEMENTS,
  basePropertyObject,
} from "./IncludeMediaInSharedLink";
import { Profile } from "@yardzen-inc/models";

export async function createShareLink(
  revisionId: string,
  clientRecord: Profile,
  getBudgetQAData: (args: GetBudgetQADataArgs) => GetBudgetQADataResult
) {
  const inclusionObject: MediaInclusion = {
    profileId: clientRecord.id,
    revision: {
      id: revisionId,
      includedCads: ["cad-layout", "cad-landscape", "cad-legend"],
      includeGeneratedPDF: true,
    },
    property: { ...basePropertyObject },
    pdfList: {
      [PLANTS]: true,
      [MATERIALS]: true,
      [ELEMENTS]: true,
    },
  };

  return createSharedMedia(inclusionObject, getBudgetQAData);
}

export async function createSharedMedia(
  inclusionObject: MediaInclusion,
  getBudgetQAData: (args: GetBudgetQADataArgs) => GetBudgetQADataResult
) {
  const revisionMedia = (await getRevisionMedia(inclusionObject)) || [];
  const propertyMedia = (await getPropertyMedia(inclusionObject)) || [];
  const allMedia = [...revisionMedia, ...propertyMedia];

  const budgetQAData = getBudgetQAData({
    includePlants: inclusionObject?.pdfList?.[PLANTS] ?? false,
    includeMaterials: inclusionObject?.pdfList?.[MATERIALS] ?? false,
    includeElements: inclusionObject?.pdfList?.[ELEMENTS] ?? false,
  });

  await upsertSharedMediaDoc(
    inclusionObject.profileId,
    allMedia,
    true,
    budgetQAData
  );
}
