import React from "react";
import { TableCell } from "@material-ui/core";

interface Props {
  children: any;
  id?: string;
  align?: string;
  component?: string;
  style?: any;
  hidden?: boolean;
}

function AssetTableCellWithStyles(props: Props) {
  return (
    <TableCell
      style={{
        ...props.style,
        padding: "0 5px",
        border: "1px solid #cccccc",
        overflow: "hidden",
        display: props.hidden ? "none" : "table-cell",
      }}
    >
      <div
        style={{
          overflow: "hidden",
          width: "inherit",
          textOverflow: "ellipsis",
          display: "flex",
          maxWidth: "fit-content",
        }}
      >
        {props.children}
      </div>
    </TableCell>
  );
}

export default AssetTableCellWithStyles;
