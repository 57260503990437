import React, { FC, useEffect, useRef, useState } from "react";
import * as htmlToImage from "html-to-image";
import { Box, makeStyles } from "@material-ui/core";
import { useGetBudgetDataByProjectId } from "src/util/hooks/useGetBudgetDataByProjectId";
import { getDesignAssignments } from "src/util/firebase/functions/getDesignAssignments";
import { useLazyQuery } from "@yardzen-inc/graphql";
import { YZTypography } from "@yardzen-inc/react-common";
import { YardDifficultyRating } from "@yardzen-inc/models";
import { convertToCurrency } from "./util/budgetEstimateRenderUtils/convertToCurrency";
import { calculateTotalCostRange } from "./util/budgetEstimateRenderUtils/calculateTotalCostRange";
import { calculateBaseCostRange } from "./util/budgetEstimateRenderUtils/calculateBaseCostRange";
import { totalPrice } from "./util/budgetEstimateRenderUtils/totalPrice";
import { ceilPrice } from "./util/budgetEstimateRenderUtils/ceilPrice";
import { Results } from "./util/budgetEstimateRenderUtils/types";
import { YARDZEN_LOGO_BLACK_SRC } from "../../util/logoConstants";
import {
  elementsAndMaterialsTypeMapping,
  hardscapeAndSoftscapeNameMapping,
  typesToExclude,
} from "./util/budgetEstimateRenderUtils/budgetEstimateMappings";
import { QUERY_SELECTION_FOR_BUDGET_QA } from "../../../src/graphql/budgetQAQueries";

const useStyles = makeStyles({
  root: {
    // keep at a 1.5 ratio width to height
    width: "1500px",
    height: "1000px",
    backgroundColor: "white",
    padding: "30px",
    position: "relative",

    "& h3": {
      padding: 0,
      margin: 0,
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
  },
  costAssessmentHeader: {
    fontSize: "27px",
    fontWeight: 500,
    marginTop: "5px",
  },
  assetContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexWrap: "wrap",
    height: "650px",
    width: "290px",
    columnGap: "50px",
  },
  assetSubContainer: {
    marginBottom: "40px",
    width: "290px",
  },
  assetHeader: {
    fontSize: "20px",
  },
  lineItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  total: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    "& p": {
      fontWeight: "bold",
    },
  },
  baseCostsContainer: {
    width: "397px",
    position: "absolute",
    top: "331px",
    right: "30px",
  },
  baseCostExplanation: {
    backgroundColor: "#E8F0E8",
    padding: "20px",

    "& p": {
      fontSize: "14px",
    },
  },
});

interface BudgetEstimateRenderProps {
  handleClose: () => void;
  uploadBudgetEstimateImage: (imageSrc: string) => void;
  projectId: string;
  yardDifficultyRating: YardDifficultyRating | null;
  revisionId: string;
}

export const BudgetEstimateRender: FC<BudgetEstimateRenderProps> = ({
  handleClose,
  uploadBudgetEstimateImage,
  projectId,
  yardDifficultyRating,
  revisionId,
}) => {
  const {
    root,
    costAssessmentHeader,
    assetHeader,
    assetContainer,
    assetSubContainer,
    lineItemContainer,
    total,
    baseCostsContainer,
    baseCostExplanation,
  } = useStyles();
  const domEl = useRef(null);
  const budgetInfo = useGetBudgetDataByProjectId(projectId);
  const [currentAssignmentId, setCurrentAssignmentId] = useState<null | string>(
    null
  );
  const [results, setResults] = React.useState<Results>({
    elements: [],
    materials: [],
    plants: [],
    softscapeItems: [],
    hardscapeItems: [],
  });
  const combinedPlants = [...results.plants, ...results.softscapeItems];
  const combinedMaterials = [...results.materials, ...results.hardscapeItems];

  const prepareElements = (assetArray: any[]) => {
    const seenElements: any = {};

    for (const element of assetArray) {
      const elementIsRecreational = elementTypeIncludesRecreational(element);
      if (!elementIsRecreational) {
        continue;
      }

      const elementType = getElementType(element);
      const elementPrice =
        Number(element.product_base.msrp) +
        Number(element.product_base.labor_cost);
      if (seenElements[elementType]) {
        seenElements[elementType].price += elementPrice;
        seenElements[elementType].count += 1;
      } else {
        seenElements[elementType] = { price: elementPrice, count: 1 };
      }
    }

    const preparedElements = [];

    for (const seenElement in seenElements) {
      preparedElements.push({
        name: seenElement,
        price: ceilPrice(seenElements[seenElement].price),
        count: seenElements[seenElement].count,
      });
    }

    return preparedElements;
  };

  const elementTypeIncludesRecreational = (element: any) => {
    return element.product_base?.product_base_product_type_links?.some(
      (type: any) => {
        return type?.product_type.name === "Recreational";
      }
    );
  };

  const getElementType = (element: any) => {
    let elementType =
      element.product_base.product_base_product_type_links.filter(
        (type: any) => {
          return !typesToExclude.includes(type?.product_type.name);
        }
      )[0]?.product_type.name;

    // mapping to get clearer names for clients
    if (elementType in elementsAndMaterialsTypeMapping) {
      elementType =
        elementsAndMaterialsTypeMapping[
          elementType as keyof typeof elementsAndMaterialsTypeMapping
        ];
    }
    return elementType;
  };

  const prepareMaterials = (assetArray: any[]) => {
    const preparedMaterials = [];

    for (const material of assetArray) {
      const materialType = getMaterialType(material);
      const materialPrice = Number(material.material_base.unit_cost);
      const laborCost = Number(material.material_base.labor_cost);
      const amount = Number(material.amount);
      preparedMaterials.push({
        name: materialType,
        price: ceilPrice((materialPrice + laborCost) * amount),
        count: amount,
      });
    }

    return preparedMaterials;
  };

  const getMaterialType = (material: any) => {
    let materialType =
      material.material_base.material_base_material_type_links.filter(
        (type: any) => {
          return !typesToExclude.includes(type?.material_type.name);
        }
      )[0]?.material_type.name;

    // mapping to get clearer names for clients
    if (materialType in elementsAndMaterialsTypeMapping) {
      materialType =
        elementsAndMaterialsTypeMapping[
          materialType as keyof typeof elementsAndMaterialsTypeMapping
        ];
    }

    return materialType;
  };

  const preparePlants = (assetArray: any[]) => {
    const seenPlants: any = {};

    for (const plant of assetArray) {
      const plantName = plant.plant_base.common_name;
      const plantPrice = Number(plant.plant_base.unit_cost);
      if (seenPlants[plantName]) {
        seenPlants[plantName] += plantPrice;
      } else {
        seenPlants[plantName] = plantPrice;
      }
    }

    const preparedPlants = [];

    for (const seenPlant in seenPlants) {
      preparedPlants.push({
        name: seenPlant,
        price: ceilPrice(seenPlants[seenPlant]),
      });
    }

    return preparedPlants;
  };

  const prepareSoftscape = (assetArray: any[]) => {
    const preparedSoftscape = [];
    const preparedHardScape = [];

    for (const softscape of assetArray) {
      const price = Number(softscape.softscape_item.unit_cost);
      const amount = Number(softscape.quantity);
      const name = getSoftscapeName(softscape);

      if (softscape.softscape_item.type === "Softscape") {
        preparedSoftscape.push({
          name,
          price: ceilPrice(price * amount),
        });
      } else {
        preparedHardScape.push({
          name,
          price: ceilPrice(price * amount),
          count: amount,
        });
      }
    }

    return [preparedSoftscape, preparedHardScape];
  };

  const getSoftscapeName = (softscape: any) => {
    let name = softscape.softscape_item.name;

    // mapping to get clearer names for clients
    if (name in hardscapeAndSoftscapeNameMapping) {
      name =
        hardscapeAndSoftscapeNameMapping[
          name as keyof typeof hardscapeAndSoftscapeNameMapping
        ];
    }

    return name;
  };

  const [getSelection, { data: queryDataSelection, loading, error }] =
    useLazyQuery(QUERY_SELECTION_FOR_BUDGET_QA, {
      fetchPolicy: "network-only",
      onError: () => alert("Error fetching selections for Budget QA"),
      onCompleted: () => {
        setResults({
          elements: prepareElements(
            queryDataSelection?.assignment?.[0]?.assignment_product_base_links
          ),
          materials: prepareMaterials(
            queryDataSelection?.assignment?.[0]?.assignment_material_base_links
          ),
          plants: preparePlants(
            queryDataSelection?.assignment?.[0]?.assignment_plant_base_links
          ),
          softscapeItems: prepareSoftscape(
            queryDataSelection?.assignment?.[0]?.assignment_softscape_item_links
          )[0],
          hardscapeItems: prepareSoftscape(
            queryDataSelection?.assignment?.[0]?.assignment_softscape_item_links
          )[1],
        });
      },
    });

  useEffect(() => {
    const findAssignment = async () => {
      try {
        const assignments = await getDesignAssignments(projectId);
        const currentAssignment = assignments.find(
          (assignment) => assignment.revisionId === revisionId
        );
        setCurrentAssignmentId(currentAssignment?.id || null);
      } catch (err) {
        alert(
          "Something went wrong fetching this project's design assignments. Please refresh the screen or contact support."
        );
      }
    };

    findAssignment();
  }, [projectId, revisionId]);

  useEffect(() => {
    const fetchSelectedItems = async (designAssignmentId: string) => {
      await getSelection({
        variables: {
          id: designAssignmentId,
        },
      });
    };
    if (currentAssignmentId) {
      fetchSelectedItems(currentAssignmentId);
    }
  }, [currentAssignmentId]);

  useEffect(() => {
    const createImage = async () => {
      // @ts-ignore
      const dataUrl = await htmlToImage.toJpeg(domEl.current);
      uploadBudgetEstimateImage(dataUrl);
    };

    if (error) {
      handleClose();
      return;
    }

    if (
      queryDataSelection &&
      yardDifficultyRating &&
      budgetInfo?.budgetMetadata?.total_budget
    ) {
      createImage();
      setTimeout(() => {
        handleClose();
      }, 1000);
    }
  }, [
    queryDataSelection,
    error,
    yardDifficultyRating,
    budgetInfo?.budgetMetadata?.total_budget,
  ]);

  if (!yardDifficultyRating || !budgetInfo?.budgetMetadata?.total_budget) {
    return <Box>Please add yard difficulty rating and budget data</Box>;
  }

  return (
    <>
      {loading || budgetInfo.loading ? (
        <Box>creating image...</Box>
      ) : (
        <div id="domEl" className={root} ref={domEl}>
          <Box marginBottom="30px">
            <img src={YARDZEN_LOGO_BLACK_SRC} height="24" />
            <YZTypography className={costAssessmentHeader}>
              Cost Assessment
            </YZTypography>
            <YZTypography>
              This assessment is meant to give you an approximate cost for the
              elements in your design (excluding furniture). All costs quoted
              may vary based on your location, quantity, and final material
              selections. We have a network of Pros who are happy to help you
              guide you through this process including making final material
              selections when you're ready to install!
            </YZTypography>
          </Box>
          <Box marginBottom="35px">
            <YZTypography variant="h5">Feedback Instructions</YZTypography>
            <Box bgcolor="#E8F0E8" padding="20px">
              <YZTypography>
                During your design revision, please leave any feedback on this
                page using annotations. If you would like to speak with someone
                from the team, leave a note for us on this page and we’ll reach
                out.
              </YZTypography>
            </Box>
          </Box>
          <Box className={assetContainer}>
            <Box className={assetSubContainer}>
              <YZTypography className={assetHeader}>Elements</YZTypography>
              <hr />
              {results.elements.map((element: any) => {
                return (
                  <Box className={lineItemContainer}>
                    <YZTypography>{element.name}</YZTypography>
                    <YZTypography>
                      {convertToCurrency(element.price)}
                    </YZTypography>
                  </Box>
                );
              })}
              <Box className={total}>
                <YZTypography>Total Elements</YZTypography>
                <YZTypography>
                  {convertToCurrency(totalPrice(results.elements))}
                </YZTypography>
              </Box>
            </Box>
            <Box className={assetSubContainer}>
              <YZTypography className={assetHeader}>Materials</YZTypography>
              <hr />
              {combinedMaterials.map((material: any) => {
                return (
                  <Box className={lineItemContainer}>
                    <YZTypography>{material.name}</YZTypography>
                    <YZTypography>
                      {convertToCurrency(material.price)}
                    </YZTypography>
                  </Box>
                );
              })}
              <Box className={total}>
                <YZTypography>Total Materials</YZTypography>
                <YZTypography>
                  {convertToCurrency(totalPrice(combinedMaterials))}
                </YZTypography>
              </Box>
            </Box>
            <Box className={assetSubContainer}>
              <YZTypography className={assetHeader}>Planting</YZTypography>
              <hr />
              <Box className={total}>
                <YZTypography>Total Planting</YZTypography>
                <YZTypography>
                  {convertToCurrency(totalPrice(combinedPlants))}
                </YZTypography>
              </Box>
            </Box>
          </Box>
          <Box className={baseCostsContainer}>
            <Box className={lineItemContainer} padding="0px 20px">
              <YZTypography>Base Costs</YZTypography>
              <YZTypography>
                {convertToCurrency(
                  calculateBaseCostRange(
                    Number(budgetInfo.budgetMetadata?.total_budget) / 100,
                    yardDifficultyRating
                  )[0]
                )}{" "}
                -{" "}
                {convertToCurrency(
                  calculateBaseCostRange(
                    Number(budgetInfo.budgetMetadata?.total_budget) / 100,
                    yardDifficultyRating
                  )[1]
                )}
              </YZTypography>
            </Box>
            <Box
              className={total}
              bgcolor="#E8F0E8"
              padding="5px 20px"
              marginBottom="10px"
            >
              <YZTypography>Total</YZTypography>
              <YZTypography>
                {convertToCurrency(
                  calculateTotalCostRange(
                    Number(budgetInfo.budgetMetadata?.total_budget) / 100,
                    yardDifficultyRating,
                    results.elements,
                    results.materials,
                    results.plants,
                    results.softscapeItems,
                    results.hardscapeItems
                  )[0]
                )}{" "}
                -{" "}
                {convertToCurrency(
                  calculateTotalCostRange(
                    Number(budgetInfo.budgetMetadata?.total_budget) / 100,
                    yardDifficultyRating,
                    results.elements,
                    results.materials,
                    results.plants,
                    results.softscapeItems,
                    results.hardscapeItems
                  )[1]
                )}
              </YZTypography>
            </Box>
            <Box className={baseCostExplanation}>
              <YZTypography>
                <strong>Base Costs</strong> are installation costs such as
                permitting demo, drainage, grading, utilities, irrigation, and
                difficult property conditions. These are subject to change based
                on in market, in region estimates with your contractor.
              </YZTypography>
              <br />
              <YZTypography>
                *Furniture is excluded from Total Estimate
              </YZTypography>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};
