import { TabProps } from "@material-ui/core/Tab";
import * as React from "react";
import { Addy } from "../util/getAddress";
import ModelerSummaryPage from "./ModelerSummaryPage";
import VideoTab from "./VideoTab";
import ModelerPhotosTab from "./ModelerPhotosTab";
import WesleySummaryPage from "../pages/cdm/WesleySummaryPageWrapper";
import QuizTab from "./QuizTab";
import FeedbackTab from "./FeedbackTab";
import ElementsTab from "./ElementsTab";
import mediaTagGalleryPageFactory from "./mediaTagGalleryPageFactory";
import FunctionFlowTab from "./FunctionFlowTab";
import AnnotatorTab from "./AnnotatorTab";
import {
  Assignment,
  Profile,
  Project,
  DesignProfile,
  DesignAssignment,
} from "@yardzen-inc/models";
import InspirationPhotoTab from "./InspirationPhotoTab";
import DesignBriefPageMapWrapper from "./DesignBriefPageMapWrapper";
import PropertyPageWrapper from "./PropertyPageWrapper";
import BudgetElementsTab from "./BudgetElementsTab";
import ExteriorDesignTab from "./ExteriorDesignTab";
import { WrappedDesignerSummaryPageWithCallback } from "./WrappedDesignerSummaryPageWithCallback";
import { Agent } from "../ConstantValues/AgentConstants";

export interface PageComponentProps {
  agent: Agent;
  assignment: Assignment | DesignAssignment;
  project: Project;
  profile: Profile;
  designProfile?: DesignProfile | null;
  address: Addy | null;
  key: any;
  inLiisa?: boolean;
}

export interface CurrentJobPageConfig {
  tabProps: TabProps;
  component: React.FunctionComponent<PageComponentProps | any>;
}

export interface CurrentJobPageMap {
  modeling: CurrentJobPageConfig[];
  design: CurrentJobPageConfig[];
  wesley: CurrentJobPageConfig[];
}

export const config: CurrentJobPageMap = {
  modeling: [
    {
      tabProps: {
        label: "Summary",
      },
      component: ModelerSummaryPage,
    },
    {
      tabProps: {
        label: "Budget Elements",
      },
      component: BudgetElementsTab,
    },
    {
      tabProps: {
        label: "Videos",
      },
      component: VideoTab,
    },
    {
      tabProps: {
        label: "Photos",
      },
      component: ModelerPhotosTab,
    },
    {
      tabProps: {
        label: "Plans",
      },
      component: mediaTagGalleryPageFactory(["plans"], "withTextField"),
    },
  ],
  design: [
    {
      tabProps: {
        label: "Summary",
      },
      component: WrappedDesignerSummaryPageWithCallback,
    },
    {
      tabProps: {
        label: "Budget Elements",
      },
      component: BudgetElementsTab,
    },
    {
      tabProps: {
        label: "Revision Feedback",
      },
      component: AnnotatorTab,
    },
    {
      tabProps: {
        label: "V1 Design Notes",
      },
      component: mediaTagGalleryPageFactory(["revision-notes"]),
    },
    {
      tabProps: {
        label: "Feedback",
      },
      component: FeedbackTab,
    },
    {
      tabProps: {
        label: "Quiz",
      },
      component: QuizTab,
    },
    {
      tabProps: {
        label: "Elements",
      },
      component: ElementsTab,
    },
    {
      tabProps: {
        label: "Property",
      },
      component: PropertyPageWrapper,
    },
    {
      tabProps: {
        label: "Exterior",
      },
      component: ExteriorDesignTab,
    },
    {
      tabProps: {
        label: "Videos",
      },
      component: VideoTab,
    },
    {
      tabProps: {
        label: "Landscape Inspiration Images",
      },
      component: InspirationPhotoTab,
    },
    {
      tabProps: {
        label: "Plans",
      },
      component: mediaTagGalleryPageFactory(["plans"], "withTextField"),
    },
    {
      tabProps: {
        label: "Sitewalk annotations",
      },
      component: mediaTagGalleryPageFactory(["wesley-annotations"]),
    },
    {
      tabProps: {
        label: "Function and Flow Diagram",
      },
      component: FunctionFlowTab("function-and-flow"),
    },
    {
      tabProps: {
        label: "Design Brief",
      },
      component: DesignBriefPageMapWrapper,
    },
    {
      tabProps: {
        label: "Working Files",
      },
      component: mediaTagGalleryPageFactory(["working-files"]),
    },
  ],
  wesley: [
    {
      tabProps: {
        label: "Summary",
      },
      component: WesleySummaryPage,
    },
    {
      tabProps: {
        label: "Budget Elements",
      },
      component: BudgetElementsTab,
    },
    {
      tabProps: {
        label: "Property",
      },
      component: PropertyPageWrapper,
    },
    {
      tabProps: {
        label: "Plans",
      },
      component: mediaTagGalleryPageFactory(["plans"]),
    },
    {
      tabProps: {
        label: "Videos",
      },
      component: VideoTab,
    },

    {
      tabProps: {
        label: "Feedback",
      },
      component: FeedbackTab,
    },
    {
      tabProps: {
        label: "Quiz",
      },
      component: QuizTab,
    },
    {
      tabProps: {
        label: "Elements",
      },
      component: ElementsTab,
    },
  ],
};
