import {
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  Chip,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import {
  AssetLibraryFilterState,
  IAssetLibraryActiveFilter,
  IFilterBoxOption,
} from "../../../../Interfaces";
import { DocumentNode, useApolloClient } from "@apollo/client";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { HasuraQueryHumanReadableOperatorEnum } from "@yardzen-inc/util";

const useStyles = makeStyles((theme) => ({
  option: {
    padding: "0.25rem",
    display: "flex",
  },
  customSelect: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
    padding: "0.25rem",
    width: "100%",
    maxWidth: "300px",
    whiteSpace: "normal",
  },
  customChip: {
    margin: "0 2px 2px",
    fontSize: "10px",
  },
}));

interface Props {
  optionQueries: { [key: string]: DocumentNode };
  options: IFilterBoxOption[];
  onTriggerFilterChange: (
    filterBy: string,
    filterParameter: HasuraQueryHumanReadableOperatorEnum,
    chipValue: AssetLibraryFilterState[]
  ) => void;
  activeFilter: IAssetLibraryActiveFilter;
  onClose: (v: string) => void;
}

function AssetFilterBoxOption(props: Props) {
  const classes = useStyles();

  const apolloClient = useApolloClient();

  const [queryData, setQueryData] = React.useState<
    { id: string; name: string; archived: boolean }[]
  >([]);

  const [filterBy, setFilterBy] = React.useState(props.activeFilter.filterBy);
  const [filterParameter, setFilterParameter] = React.useState(
    props.activeFilter.filterParameter
  );

  const handleChangeFilterBy = async (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    if (
      event.target.value !== filterBy &&
      // only set this if chipValue already has data as
      // this action will trigger a new graphql query
      chipValue.length
    ) {
      setChipValue([]);
    }
    const newFilterBy: string = event.target.value;
    setFilterBy(newFilterBy);

    const { data } = await apolloClient.query({
      query: props.optionQueries[newFilterBy],
    });

    const newQueryData: typeof queryData = [...data[newFilterBy]].sort(
      (a, b) => {
        const lowerA = a["name"].toLowerCase();
        const lowerB = b["name"].toLowerCase();
        if (lowerA < lowerB) return -1;
        if (lowerA > lowerB) return 1;
        return 0;
      }
    );
    setQueryData(newQueryData);
  };

  const [chipValue, setChipValue] = React.useState(props.activeFilter.value);

  const [componentInitialized, setComponentInitialized] = React.useState(false);

  const fetchInitialChips = async () => {
    if (!props.activeFilter.filterBy) return;
    const { data } = await apolloClient.query({
      query: props.optionQueries[props.activeFilter.filterBy],
    });
    setQueryData(data[props.activeFilter.filterBy]);
  };
  React.useEffect(() => {
    setFilterBy(props.activeFilter.filterBy);
    setFilterParameter(props.activeFilter.filterParameter);
    setChipValue(props.activeFilter.value);
    fetchInitialChips();
  }, [props.activeFilter]);

  const handleChangeChipValue = (
    event: React.ChangeEvent<{ value: AssetLibraryFilterState[] }>,
    data: AssetLibraryFilterState[]
  ) => {
    setChipValue(data);
  };

  const handleChangeFilterParameter = (
    event: React.ChangeEvent<{ value: HasuraQueryHumanReadableOperatorEnum }>
  ) => {
    setFilterParameter(event.target.value);
  };

  React.useEffect(() => {
    if (!componentInitialized) return;
    props.onTriggerFilterChange(filterBy, filterParameter, chipValue);
  }, [chipValue]);

  React.useEffect(() => {
    if (!chipValue.length) return;
    props.onTriggerFilterChange(filterBy, filterParameter, chipValue);
  }, [filterParameter]);

  // We want to give the component a moment before
  // graphql queries start triggering to prevent
  // unnecessary graphql queries from triggering when the
  // user opens / closes the "Filters" box.
  React.useEffect(() => {
    setTimeout(() => {
      setComponentInitialized(true);
    }, 300);
  }, []);

  return (
    <Grid container spacing={2} className={classes.option}>
      <Grid item style={{ paddingRight: 0, paddingLeft: 0 }}>
        <Tooltip title="Remove Filter" arrow>
          <IconButton
            size="small"
            onClick={() => props.onClose(filterBy)}
            style={{ marginTop: 8 }}
          >
            <CloseIcon style={{ fontSize: "1rem" }} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item md={3}>
        <TextField
          id="filter-by"
          select
          fullWidth
          size="small"
          label="Filter By"
          variant="outlined"
          value={filterBy}
          onChange={handleChangeFilterBy}
        >
          {props.options.map((option: IFilterBoxOption) => (
            <MenuItem key={option.listResultName} value={option.listResultName}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3}>
        <TextField
          select
          fullWidth
          size="small"
          variant="outlined"
          id="demo-simple-select"
          value={filterParameter}
          // @ts-ignore
          onChange={handleChangeFilterParameter} // Typescript issue
        >
          <MenuItem value={HasuraQueryHumanReadableOperatorEnum.hasAnyOf}>
            has any of
          </MenuItem>
          <MenuItem value={HasuraQueryHumanReadableOperatorEnum.hasNoneOf}>
            is not
          </MenuItem>
          <MenuItem value={HasuraQueryHumanReadableOperatorEnum.hasAllOf}>
            is
          </MenuItem>
        </TextField>
      </Grid>
      <Grid item md={5}>
        <Autocomplete
          multiple
          data-testid="asset-filter-autocomplete"
          size="small"
          value={chipValue}
          // @ts-ignore
          onChange={handleChangeChipValue}
          options={queryData || []}
          getOptionLabel={(chip) => chip.name}
          // @ts-ignore
          renderTags={(selected: AssetLibraryFilterState[], getTagProps) => (
            <div>
              {selected.map((value: AssetLibraryFilterState, index) => (
                <Chip
                  size="small"
                  color="primary"
                  key={value.id}
                  label={value.name}
                  className={classes.customChip}
                  {...getTagProps({ index })}
                />
              ))}
            </div>
          )}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default AssetFilterBoxOption;
