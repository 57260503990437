import * as React from "react";
import { Dialog, TextField, DialogContent, Button } from "@material-ui/core";

interface Props {
  uid: string;
  revisionNo: number;
  onClose: () => void;
}

export default (props: Props) => {
  const url = `${window.location.host}/annotator/revisions/${props.uid}/${props.revisionNo}`;

  return (
    <Dialog open onClose={props.onClose}>
      <DialogContent>
        <TextField
          value={url}
          fullWidth
          inputProps={{
            id: "anno-rev-link-input",
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            var copyText = document.getElementById(
              "anno-rev-link-input"
            ) as HTMLInputElement;
            if (!copyText) return;

            copyText.select();
            copyText.setSelectionRange(0, 99999); /*For mobile devices*/

            document.execCommand("copy");

            alert("Copied link " + copyText.value);
          }}
        >
          copy
        </Button>
      </DialogContent>
    </Dialog>
  );
};
