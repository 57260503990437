import React, { FC, useState } from "react";

import { Box, Dialog, makeStyles, Theme } from "@material-ui/core";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import { Project } from "@yardzen-inc/models";
import { useRouteMatch } from "react-router";
import { HoldStatus } from "../../Interfaces";
import { addPLPHoldStatus } from "../../util/firebase/firebaseClient";
import firebase from "firebase/compat";

export interface ManualHoldToggleModalProps {
  open: boolean;
  onClose: () => void;
  project: Project;
  holdStatuses: string[];
  refreshHolds?: (userId: string) => Promise<void>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const NO_HOLD_COPY = "Would you like to place a manual hold on this project?";
const MANUAL_HOLD_COPY =
  "Would you like to remove the manual hold currently on this project?";

const ManualHoldToggleModal: FC<ManualHoldToggleModalProps> = ({
  open,
  onClose,
  project,
  holdStatuses,
  refreshHolds,
}) => {
  const classes = useStyles();
  const manualHold = holdStatuses.includes(HoldStatus.ManualHold);

  const match = useRouteMatch();
  // @ts-ignore
  const userId = match.params["userId"];

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const CALLABLE = firebase
    .functions()
    .httpsCallable("putPostPLPHoldStatusesByProjectId");

  return (
    <Dialog open={open} onClose={onClose}>
      <Box className={classes.root}>
        <YZTypography align="center">
          {manualHold ? MANUAL_HOLD_COPY : NO_HOLD_COPY}
        </YZTypography>

        <Box pt={4}>
          {error ? (
            <YZTypography color="error">
              An error has occurred. Please reload the page and try again
            </YZTypography>
          ) : (
            <YZButton
              onClick={
                manualHold
                  ? removeManualHoldFromProject
                  : placeManualHoldOnProject
              }
              disabled={loading}
            >
              {loading
                ? "Saving..."
                : manualHold
                ? "Remove manual hold"
                : "Place manual hold"}
            </YZButton>
          )}
        </Box>
      </Box>
    </Dialog>
  );

  async function placeManualHoldOnProject() {
    setLoading(true);

    try {
      await addPLPHoldStatus(HoldStatus.ManualHold, project.id);
      onClose();
      if (refreshHolds) {
        refreshHolds(userId);
      }
    } catch (err) {
      console.error(err);
      setError(true);
    }
    setLoading(false);
  }

  async function removeManualHoldFromProject() {
    setLoading(true);

    const manualHoldIndex = holdStatuses.findIndex(
      (status) => status === HoldStatus.ManualHold
    );
    const newHolds = [
      ...holdStatuses.slice(0, manualHoldIndex),
      ...holdStatuses.slice(manualHoldIndex + 1),
    ];

    try {
      await CALLABLE({ newHolds, projectId: project.id });
      onClose();
      if (refreshHolds) {
        refreshHolds(userId);
      }
    } catch (err) {
      console.error(err);
      setError(true);
    }

    setLoading(false);
  }
};

export { ManualHoldToggleModal };
export default ManualHoldToggleModal;
