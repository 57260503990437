import { useMutation } from "@apollo/client";
import { Box, makeStyles } from "@material-ui/core";
import {
  formatNumToUSD,
  YZAccordionChip,
  YZButton,
  YZTypography,
} from "@yardzen-inc/react-common";
import React, { useEffect } from "react";
import BudgetQASummary from "./BudgetQASummary";
import {
  DELETE_AND_INSERT_ASSIGNMENT_PLANT_BASES,
  DELETE_AND_INSERT_ASSIGNMENT_PRODUCT_BASES,
  UPSERT_ASSIGNMENT_MATERIAL_BASE_LINK,
  DELETE_ASSIGNMENT_MATERIAL_BASES,
} from "@yardzen-inc/graphql";
import { handleMutationError } from "../../EmployeeView/AssetLibraries/elementMutationErrorUtil";
import { Addy } from "../../util/getAddress";
import uuid from "uuid";
import SelectedItemsPDF from "./SelectedItemsPDF";
import { SelectedAssetsDispatchConstants } from "../../ConstantValues/SelectedAssetsDispatchConstants";
import {
  IAssetLibraryUpdateQueue,
  IAssignmentMaterialBaseLink,
  IAssignmentPlantBaseLink,
  IAssignmentProductBaseLink,
} from "../../Interfaces";
import { AssetLibraryTypeConstants } from "../../ConstantValues/AssetLibraryTypeConstants";
import BudgetCalculator from "./BudgetCalculator";
import {
  elementFields,
  elementsPDFColumns,
  materialsPDFColumns,
  plantFields,
  plantsPDFColumns,
  SelectedItemsElement,
  SelectedItemsMaterial,
  SelectedItemsPlant,
  materialFields,
} from "./constants/SelectedItemsConstants";
import { getSelectedElementsForBudgetQA } from "./getSelectedElementsForBudgetQA";
import { getSelectedMaterialsForBudgetQA } from "./getSelectedMaterialsForBudgetQA";
import { getSelectedPlantsForBudgetQA } from "./getSelectedPlantsForBudgetQA";
import { useSelectedAssetsForBudgetQA } from "../../services/useSelectedAssetsForBudgetQA";
import { useGetBudgetDataByProjectId } from "../../util/hooks/useGetBudgetDataByProjectId";
import { UPSERT_ASSIGNMENT_MATERIAL_BASE_PAINT_LINK } from "../../EmployeeView/AssetLibraries/Shared/graphqlQueries";
import { YZItemsAccordion } from "../YZItemsAccordion";
import { useGetGeoZones } from "../../util/hooks/useGetGeoZones";
import { Profile } from "@yardzen-inc/models";
import { useTreatment } from "@yardzen-inc/react-split";
import { HIDE_PLANT_PRICING } from "../../../src/util/split/splitTreatments";
import { filter } from "lodash";

interface Props {
  nonPm?: boolean;
  designerView?: boolean;
  projectId: string;
  designAssignmentId: string;
  isProcessingData: boolean;
  onChangingData: (v: boolean) => void;
  enableQuantityChange?: boolean;
  address: Addy | null;
  dispatch?: any;
  assignment_product_base_links?: IAssignmentProductBaseLink[];
  assignment_material_base_links?: IAssignmentMaterialBaseLink[];
  assignment_plant_base_links?: IAssignmentPlantBaseLink[];
  projectRegion?: string;
  profile: Profile;
}

const useStyles = makeStyles({
  quantityInputContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  downloadBtnContainer: {
    "& button": {
      marginLeft: 2,
      marginRight: 2,
    },
  },
});

function SelectedItems(props: Props) {
  const geoZones = useGetGeoZones(props.profile);
  const classes = useStyles();
  const {
    nonPm,
    projectId,
    designAssignmentId,
    isProcessingData,
    onChangingData,
    enableQuantityChange,
    address,
    dispatch,
    assignment_product_base_links,
    assignment_material_base_links,
    assignment_plant_base_links,
  } = props;

  const [idAndQuantityMap, setIdAndQuantityMap] = React.useState({});
  const [refetchSelection, setRefetchSelection] = React.useState(false);
  const hidePlantsPricing = useTreatment(HIDE_PLANT_PRICING);

  useEffect(() => {
    if (geoZones.geoZones) {
      setRefetchSelection(true);
    }
  }, [geoZones.geoZones]);

  const [updateQueue, setUpdateQueue] = React.useState<
    IAssetLibraryUpdateQueue[]
  >([]);
  const [inProgressQueueItem, setInProgressQueueItem] = React.useState("");

  const [cachedPlantChange, setCachedPlantChange] = React.useState({
    quantity: 0,
    plant_base_id: "",
  });

  const getElements = (queryData: any): SelectedItemsElement[] => {
    return getSelectedElementsForBudgetQA(
      queryData,
      nonPm,
      onUpdateElementQty,
      enableQuantityChange,
      classes.quantityInputContainer,
      assignment_product_base_links
    );
  };

  const getMaterials = (queryData: any): SelectedItemsMaterial[] => {
    return getSelectedMaterialsForBudgetQA(
      queryData,
      nonPm,
      onUpdateSurfaceQty,
      enableQuantityChange,
      classes.quantityInputContainer,
      assignment_material_base_links,
      props.projectRegion
    );
  };

  const getPlants = (queryData: any): SelectedItemsPlant[] => {
    return getSelectedPlantsForBudgetQA(
      queryData,
      nonPm,
      onUpdatePlantQty,
      enableQuantityChange,
      classes.quantityInputContainer,
      assignment_plant_base_links,
      new Set(geoZones.geoZones?.map?.((geo) => geo.name))
    );
  };

  const [deleteAndInsertAssignmentProductBases] = useMutation(
    DELETE_AND_INSERT_ASSIGNMENT_PRODUCT_BASES,
    {
      onError: handleMutationError,
      onCompleted: () => {
        console.log("completedProductUpdate for ", inProgressQueueItem);
        setUpdateQueue(
          updateQueue.filter((queue) => queue.ackId !== inProgressQueueItem)
        );
        setInProgressQueueItem("");
        setRefetchSelection(true);
      },
    }
  );

  const [deleteAndInsertAssignmentPlantBases] = useMutation(
    DELETE_AND_INSERT_ASSIGNMENT_PLANT_BASES,
    {
      onError: handleMutationError,
      onCompleted: () => {
        console.log("completedPlantUpdate for ", inProgressQueueItem);
        setUpdateQueue(
          updateQueue.filter((queue) => queue.ackId !== inProgressQueueItem)
        );
        if (Array.isArray(assignment_plant_base_links)) {
          const updatedLinks = [
            ...assignment_plant_base_links.filter(
              (link) => link.plant_base_id !== cachedPlantChange.plant_base_id
            ),
            ...Array(cachedPlantChange.quantity).fill({
              plant_base_id: cachedPlantChange.plant_base_id,
            }),
          ];

          dispatch({
            type: SelectedAssetsDispatchConstants.setPlantBaseLinks,
            val: updatedLinks,
          });
        }
        setInProgressQueueItem("");
        setRefetchSelection(true);
      },
    }
  );

  const [upsertAssignmentMaterialBaseLink] = useMutation(
    UPSERT_ASSIGNMENT_MATERIAL_BASE_LINK,
    {
      onError: handleMutationError,
      onCompleted: () => {
        console.log("completedMaterialUpdate for ", inProgressQueueItem);
        onChangingData(false);
        setUpdateQueue(
          updateQueue.filter((queue) => queue.ackId !== inProgressQueueItem)
        );
        setInProgressQueueItem("");
        setRefetchSelection(true);
      },
    }
  );

  const [upsertAssignmentMaterialBasePaintLink] = useMutation(
    UPSERT_ASSIGNMENT_MATERIAL_BASE_PAINT_LINK,
    {
      onError: handleMutationError,
      onCompleted: () => {
        onChangingData(false);
        setUpdateQueue(
          updateQueue.filter((queue) => queue.ackId !== inProgressQueueItem)
        );
        setInProgressQueueItem("");
        setRefetchSelection(true);
      },
    }
  );

  const [deleteAssignmentMaterialBaseLink] = useMutation(
    DELETE_ASSIGNMENT_MATERIAL_BASES,
    {
      onError: handleMutationError,
      onCompleted: () => {
        setUpdateQueue(
          updateQueue.filter((queue) => queue.ackId !== inProgressQueueItem)
        );
        setInProgressQueueItem("");
        setRefetchSelection(true);
      },
    }
  );

  const budgetInfo = useGetBudgetDataByProjectId(projectId);

  const [plantsPDFOpen, setPlantsPDFOpen] = React.useState(false);

  const [elementsPDFOpen, setElementsPDFOpen] = React.useState(false);

  const [materialsPDFOpen, setMaterialsPDFOpen] = React.useState(false);

  const { plants, materials, elements, softscapeItems } =
    useSelectedAssetsForBudgetQA(
      designAssignmentId,
      isProcessingData,
      refetchSelection,
      setRefetchSelection,
      getElements,
      getPlants,
      getMaterials
    );

  const renderChipText = (
    items: (SelectedItemsElement | SelectedItemsMaterial | SelectedItemsPlant)[]
  ): string => {
    if (!items.length) {
      return "None";
    }

    if (hidePlantsPricing && items.some((i) => i.category === "Plants")) {
      return "$0";
    }

    const totalCost = items.reduce(
      (sum, i) => sum + parseFloat(i.totalCost.replace(/[\$,]/g, "")) * 100,
      0
    );

    return formatNumToUSD(totalCost);
  };

  const onUpdateElementQty = React.useCallback(
    (quantity: number, id: string) => {
      console.log("adding element to update queue", updateQueue, quantity, id);
      setUpdateQueue([
        ...updateQueue,
        {
          id,
          quantity,
          ackId: uuid.v4(),
          type: AssetLibraryTypeConstants.product,
        },
      ]);
    },
    [updateQueue]
  );

  const processElementFromQueue = (item: IAssetLibraryUpdateQueue) => {
    const _quantity = Math.max(item.quantity, 0);
    const newMap = {
      ...idAndQuantityMap,
      [item.id]: _quantity,
    };
    setIdAndQuantityMap(() => newMap);

    const objects = [];
    for (let it = 0; it < _quantity; it++) {
      objects.push({
        product_base_id: item.id,
        assignment_id: designAssignmentId,
      });
    }
    deleteAndInsertAssignmentProductBases({
      variables: {
        assignment_id: designAssignmentId,
        product_base_id: item.id,
        objects,
      },
    });
    if (!Array.isArray(assignment_product_base_links)) return;
    const updatedLinks = [
      ...assignment_product_base_links.filter(
        (link) => link.product_base_id !== item.id
      ),
      ...Array(item.quantity).fill({ product_base_id: item.id }),
    ];
    dispatch({
      type: SelectedAssetsDispatchConstants.setProductBaseLinks,
      val: updatedLinks,
    });
  };

  const processPlantFromQueue = (item: IAssetLibraryUpdateQueue) => {
    const _quantity = Math.max(item.quantity, 0);
    const newMap = {
      ...idAndQuantityMap,
      [item.id]: _quantity,
    };
    setCachedPlantChange({
      quantity: _quantity,
      plant_base_id: item.id,
    });
    setIdAndQuantityMap(() => newMap);
    const objects = [];
    for (let it = 0; it < _quantity; it++) {
      objects.push({
        plant_base_id: item.id,
        assignment_id: designAssignmentId,
      });
    }
    deleteAndInsertAssignmentPlantBases({
      variables: {
        assignment_id: designAssignmentId,
        plant_base_id: item.id,
        objects,
      },
    });
  };

  const onUpdateSurfaceQty = React.useCallback(
    (quantity: number, id: string, isPaint: boolean) => {
      console.log("adding material to update queue", updateQueue, quantity, id);
      setUpdateQueue([
        ...updateQueue,
        {
          id,
          quantity,
          isPaint,
          ackId: uuid.v4(),
          type: AssetLibraryTypeConstants.surface,
        },
      ]);
    },
    [updateQueue]
  );

  const onUpdatePlantQty = React.useCallback(
    (quantity: number, id: string) => {
      console.log("adding plant to update queue", updateQueue, quantity, id);
      setUpdateQueue([
        ...updateQueue,
        {
          id,
          quantity,
          ackId: uuid.v4(),
          type: AssetLibraryTypeConstants.plant,
        },
      ]);
    },
    [updateQueue]
  );

  const processSurfaceFromQueue = (item: IAssetLibraryUpdateQueue) => {
    const _quantity = Math.max(item.quantity, 0);
    const newMap = {
      ...idAndQuantityMap,
      [item.id]: _quantity,
    };

    setIdAndQuantityMap(() => newMap);

    if (_quantity < 1) {
      deleteAssignmentMaterialBaseLink({
        variables: {
          assignment_id: designAssignmentId,
          material_base_id: item.id,
        },
      });
    } else if (item.isPaint) {
      upsertAssignmentMaterialBasePaintLink({
        variables: {
          assignment_id: designAssignmentId,
          material_base_id: item.id,
          amount: _quantity,
        },
      });
    } else {
      upsertAssignmentMaterialBaseLink({
        variables: {
          assignment_id: designAssignmentId,
          material_base_id: item.id,
          amount: _quantity,
        },
      });
    }
    if (!Array.isArray(assignment_material_base_links)) return;
    const updatedLinks = [
      ...assignment_material_base_links.filter(
        (link) => link.material_base_id !== item.id
      ),
      {
        amount: item.quantity,
        material_base_id: item.id,
      },
    ];
    dispatch({
      type: SelectedAssetsDispatchConstants.setMaterialBaseLinks,
      val: updatedLinks,
    });
  };

  const processFirstFromUpdateQueue = (item: IAssetLibraryUpdateQueue) => {
    console.log("processing top item from update queue", item);
    setInProgressQueueItem(item.ackId);
    switch (item.type) {
      case AssetLibraryTypeConstants.surface:
        processSurfaceFromQueue(item);
        break;
      case AssetLibraryTypeConstants.product:
        processElementFromQueue(item);
        break;
      case AssetLibraryTypeConstants.plant:
        processPlantFromQueue(item);
        break;
    }
  };

  React.useEffect(() => {
    if (!updateQueue.length) return;
    if (!!inProgressQueueItem) return;
    processFirstFromUpdateQueue(updateQueue[0]);
  }, [updateQueue, inProgressQueueItem]);

  const selectedItemsCategories = [
    { header: "Furniture", fields: elementFields },
    { header: "Recreational", fields: elementFields },
    { header: "Exterior Design", fields: elementFields },
    { header: "Materials", fields: materialFields },
    { header: "Plants", fields: plantFields },
  ];

  const allItems = [...elements, ...plants, ...materials];
  const calculatorTotals = softscapeItems?.reduce(
    (totals: { [type: string]: number }, i: any) => {
      const type = i.softscape_item.type;
      const value = i.quantity * i.softscape_item.unit_cost;
      totals[type] = totals[type] + value || value;

      return totals;
    },
    {}
  );

  const filteredCategories = ["Furniture"];
  if (hidePlantsPricing) {
    filteredCategories.push("Plants");
  }

  // Filter "Prop" plants from pdf
  const pdfPlants = plants.filter((plant) => !plant.type.includes("Prop"));

  return (
    <div style={{ width: "100%", padding: "1rem" }}>
      <SelectedItemsPDF
        title="Element List"
        open={elementsPDFOpen}
        address={address}
        onClose={() => setElementsPDFOpen(false)}
        rows={elements}
        columns={elementsPDFColumns}
      />
      <SelectedItemsPDF
        title="Plant List"
        open={plantsPDFOpen}
        address={address}
        onClose={() => setPlantsPDFOpen(false)}
        rows={pdfPlants}
        columns={plantsPDFColumns}
        includeAdditionalInfo={false}
        formatPDF={true}
      />
      <SelectedItemsPDF
        title="Material List"
        open={materialsPDFOpen}
        address={address}
        onClose={() => setMaterialsPDFOpen(false)}
        rows={materials}
        columns={materialsPDFColumns}
        includeAdditionalInfo={false}
        formatPDF={true}
      />
      <Box style={{ paddingTop: "5rem" }} p={3} display="flex">
        <YZTypography variant="h3" type="serif" style={{ flex: 1 }}>
          Budget QA
        </YZTypography>
        <Box className={classes.downloadBtnContainer}>
          <YZButton
            variant="outlined"
            onClick={() => setElementsPDFOpen(true)}
            size="small"
          >
            Download Element List
          </YZButton>
          <YZButton
            variant="outlined"
            onClick={() => setPlantsPDFOpen(true)}
            size="small"
          >
            Download Plant List
          </YZButton>
          <YZButton
            variant="outlined"
            onClick={() => setMaterialsPDFOpen(true)}
            size="small"
          >
            Download Material List
          </YZButton>
        </Box>
      </Box>
      <div>
        <BudgetQASummary
          budgetInfo={budgetInfo}
          projectId={projectId}
          itemCategories={selectedItemsCategories.filter(
            (c) => !filteredCategories.includes(c.header)
          )}
          items={allItems.filter(
            (i) => !filteredCategories.includes(i.category)
          )}
          calculatorTotals={calculatorTotals}
        />
        &nbsp;
        {selectedItemsCategories.map((c) => {
          const items = allItems.filter((i) => i.category === c.header);
          let fields = c.fields;
          if (hidePlantsPricing && c.header === "Plants") {
            fields = filter(fields, (f) => f.header != "Total Cost");
          }
          return (
            <YZItemsAccordion
              key={c.header}
              fields={fields}
              chip={<YZAccordionChip text={renderChipText(items)} />}
              rows={items}
              title={c.header}
            />
          );
        })}
        &nbsp;
        <BudgetCalculator
          designAssignmentId={designAssignmentId}
          isProcessingData={isProcessingData}
          onChangingQuantity={onChangingData}
          enableQuantityChange={enableQuantityChange}
        />
      </div>
    </div>
  );
}

export default SelectedItems;
