import {
  PLANT_ADAPTED_HABITAT_SELECT,
  PLANT_BLOOM_TIME_SELECT,
  PLANT_CAD_SYMBOL_SIZE_SELECT,
  PLANT_CAD_SYMBOL_SPACING_SELECT,
  PLANT_DECIDUOUS_OR_EVERGREEN_SELECT,
  PLANT_FLOWER_COLOR_SELECT,
  PLANT_FRUIT_COLOR_SELECT,
  PLANT_GARDEN_STYLE_SELECT,
  PLANT_GROWTH_HABIT_SELECT,
  PLANT_HEIGHT_CLASS_SELECT,
  PLANT_KEY_FEATURE_SELECT,
  PLANT_LEAF_COLOR_SELECT,
  PLANT_LIGHT_NEEDS_SELECT,
  PLANT_NATIVE_RANGE_SELECT,
  PLANT_RED_FLAG_SELECT,
  PLANT_TYPE_SELECT,
  PLANT_USDA_ZONE_SELECT,
  PLANT_VIP_SELECT,
  PLANT_WATER_NEEDS_SELECT,
  PLANT_WIDTH_CLASS_SELECT,
} from "@yardzen-inc/graphql";
import { IFilterBoxOption } from "../../../Interfaces";
import { PLANT_RETAILER_SELECT } from "../Shared/graphqlQueries";

const PlantLibraryFilterBoxOptions: IFilterBoxOption[] = [
  {
    label: "Geo",
    query: PLANT_ADAPTED_HABITAT_SELECT,
    listResultName: "plant_adapted_habitat",
  },
  {
    label: "Bloom Time",
    query: PLANT_BLOOM_TIME_SELECT,
    listResultName: "plant_bloom_time",
  },
  {
    label: "CAD Symbol ~Size~",
    query: PLANT_CAD_SYMBOL_SIZE_SELECT,
    listResultName: "plant_cad_symbol_size",
  },
  {
    label: "CAD Symbol ~Spacing~",
    query: PLANT_CAD_SYMBOL_SPACING_SELECT,
    listResultName: "plant_cad_symbol_spacing",
  },
  {
    label: "Deciduous or Evergreen",
    query: PLANT_DECIDUOUS_OR_EVERGREEN_SELECT,
    listResultName: "plant_deciduous_or_evergreen",
  },
  {
    label: "Flower Color",
    query: PLANT_FLOWER_COLOR_SELECT,
    listResultName: "plant_flower_color",
  },
  {
    label: "Fruit Color",
    query: PLANT_FRUIT_COLOR_SELECT,
    listResultName: "plant_fruit_color",
  },
  {
    label: "Planting Style",
    query: PLANT_GARDEN_STYLE_SELECT,
    listResultName: "plant_garden_style",
  },
  {
    label: "Growth Habit",
    query: PLANT_GROWTH_HABIT_SELECT,
    listResultName: "plant_growth_habit",
  },
  {
    label: "Height Class",
    query: PLANT_HEIGHT_CLASS_SELECT,
    listResultName: "plant_height_class",
  },
  {
    label: "Key Feature",
    query: PLANT_KEY_FEATURE_SELECT,
    listResultName: "plant_key_feature",
  },
  {
    label: "Leaf Color",
    query: PLANT_LEAF_COLOR_SELECT,
    listResultName: "plant_leaf_color",
  },
  {
    label: "Light Needs",
    query: PLANT_LIGHT_NEEDS_SELECT,
    listResultName: "plant_light_needs",
  },
  {
    label: "Native Range",
    query: PLANT_NATIVE_RANGE_SELECT,
    listResultName: "plant_native_range",
  },
  {
    label: "Red Flag",
    query: PLANT_RED_FLAG_SELECT,
    listResultName: "plant_red_flag",
  },
  {
    label: "Type",
    query: PLANT_TYPE_SELECT,
    listResultName: "plant_type",
  },
  {
    label: "Water needs",
    query: PLANT_WATER_NEEDS_SELECT,
    listResultName: "plant_water_needs",
  },
  {
    label: "USDA Zone",
    query: PLANT_USDA_ZONE_SELECT,
    listResultName: "plant_usda_zone",
  },
  {
    label: "VIP",
    query: PLANT_VIP_SELECT,
    listResultName: "plant_vip",
  },
  {
    label: "Width Class",
    query: PLANT_WIDTH_CLASS_SELECT,
    listResultName: "plant_width_class",
  },
  {
    label: "Retailer",
    query: PLANT_RETAILER_SELECT,
    listResultName: "plant_retailer",
  },
];

export default PlantLibraryFilterBoxOptions;
