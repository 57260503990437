interface Chunk {
  <t extends unknown>(batchSize: number, jobs: t[]): t[][];
}

/*
  chunk will partition an array into equal partitions of size <batchsize>,
  and return said partitions in a two dimensional array
*/
const chunk: Chunk = <t extends unknown>(batchSize: number, jobs: t[]) => {
  const chunks: t[][] = [];
  let counter = 0;
  let chunk: t[] = [];

  for (let job of jobs) {
    if (counter >= batchSize) {
      chunks.push(chunk);
      counter = 0;
      chunk = [];
    }

    chunk.push(job);
    counter += 1;
  }

  if (chunk.length) {
    chunks.push(chunk);
  }

  return chunks;
};

export { chunk };
export default chunk;
