import React, {
  FC,
  useRef,
  ReactNode,
  useEffect,
  MutableRefObject,
} from "react";
import { RouteComponentProps, Route, Switch } from "react-router-dom";
import { PageMapEntry } from "./types";
import { makeRRURL } from "./util";

export interface GenericRouterProps extends RouteComponentProps {
  CatchAllRouteComponent: React.FC<RouteComponentProps | any>;
  pageMap: PageMapEntry[];
  routeCacheRef?: MutableRefObject<{
    [key: string]: { [shortName: string]: string };
  }>;
}

const GenericRouter: FC<GenericRouterProps> = (props) => {
  const routeCacheRef = useRef<{
    [key: string]: { [shortName: string]: string };
  }>({});

  useEffect(() => {
    if (!props.pageMap.length) {
      throw new Error("Generic router page map must have atleast one entry");
    }
  }, []);

  return (
    <>
      <Switch>
        {renderRoutes()}
        <Route component={props.CatchAllRouteComponent}></Route>
      </Switch>
    </>
  );

  function renderRoutes(): ReactNode[] {
    return props.pageMap.map(makeRoute);
  }

  function makeRoute(entry: PageMapEntry): ReactNode {
    return (
      <Route
        path={makeRRURL(
          entry.routeName,
          {
            location: props.location,
            history: props.history,
            match: props.match,
          },
          routeCacheRef
        )}
        component={entry.component}
        key={`route_${entry.key}`}
        {...(entry.routeComponentProps ?? {})}
      />
    );
  }
};

export { GenericRouter };
export default GenericRouter;
