import React, { FC, useState } from "react";
import { Box, Dialog, makeStyles, Theme } from "@material-ui/core";
import { ProfileProperties } from "@yardzen-inc/models";
import { PostPLPHoldsType } from "./types";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import PostPLPHoldListItemIcons from "./PostPLPHoldListItemIcons";
import { Close } from "@material-ui/icons";
import firebase from "firebase/compat/app";

export interface ConfirmArchiveModalProps {
  open: boolean;
  onClose: () => void;
  holdId: string;
  profile: ProfileProperties;
  holdStatuses: PostPLPHoldsType[];
  triggerRefresh: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(4),
  },
  closeIcon: {
    position: "absolute",
    top: 5,
    right: 5,
    cursor: "pointer",
  },
}));

/**
 *
 * Modal used to confirm and archive a postPLPHolds record
 *
 */

const ConfirmArchiveModal: FC<ConfirmArchiveModalProps> = ({
  open,
  onClose,
  holdId,
  profile,
  holdStatuses,
  triggerRefresh,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box className={classes.root}>
        {error && (
          <YZTypography color="error">
            An error has occurred, please refresh and try again
          </YZTypography>
        )}

        <Close onClick={onClose} classes={{ root: classes.closeIcon }} />

        <YZTypography variant="h5" align="center">
          Are you sure you want to remove the hold on {profile.firstName}{" "}
          {profile.lastName}'s project?
        </YZTypography>

        <br />

        <Box p={2} pb={4}>
          <PostPLPHoldListItemIcons holdStatuses={holdStatuses} />
        </Box>

        <YZButton
          disabled={loading}
          style={{ backgroundColor: "#C05757" }}
          onClick={() => archivePostPLPHold(holdId)}
        >
          Remove
        </YZButton>

        {loading && (
          <Box mt={1}>
            <YZTypography>updating...</YZTypography>
          </Box>
        )}
      </Box>
    </Dialog>
  );

  async function archivePostPLPHold(id: string) {
    setLoading(true);

    const callable = firebase
      .functions()
      .httpsCallable("archivePostPLPHoldById");

    try {
      await callable({ id });
    } catch (err) {
      console.error(err);
      setError(true);
      setLoading(false);
    }
    return triggerRefresh();
  }
};

export { ConfirmArchiveModal };
export default ConfirmArchiveModal;
