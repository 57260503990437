import * as React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Profile, DesignAssignment } from "@yardzen-inc/models";
import { YZTypography } from "@yardzen-inc/react-common";

export interface DesignNotesProps {
  profile: Profile;
  assignment?: DesignAssignment;
}

const useStyles = makeStyles((theme) => ({
  item: {
    padding: "0.33rem 0",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
}));

const DesignNotes: React.FC<DesignNotesProps> = ({ profile, assignment }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.item}>
        <YZTypography type="mono" variant="caption" color="textSecondary">
          Design Notes
        </YZTypography>
        <YZTypography variant="body1">
          {profile.designerNotes || "none"}
        </YZTypography>
      </Box>
      {assignment && (
        <Box className={classes.item}>
          <YZTypography type="mono" variant="caption" color="textSecondary">
            Project Manager Notes
          </YZTypography>
          <YZTypography variant="body1">
            {assignment.projectManagerNotes || "none"}
          </YZTypography>
        </Box>
      )}
    </>
  );
};

export { DesignNotes };

export default DesignNotes;
