import React, { FC } from "react";
import ExteriorDesignInspirationContainer from "../../Components/ExteriorDesign/ExteriorDesignInspirationContainer";
import { Profile } from "@yardzen-inc/models";

export interface ExteriorDesignInspirationTabProps {
  profile: Profile;
}

const ExteriorDesignInspirationTab: FC<ExteriorDesignInspirationTabProps> = (
  props
) => {
  return <ExteriorDesignInspirationContainer {...props} />;
};

export { ExteriorDesignInspirationTab };
export default ExteriorDesignInspirationTab;
