import React from "react";
import FilterMenu from "./FilterMenu";
import { Visibility } from "@material-ui/icons";
import {
  Box,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Switch,
} from "@material-ui/core";
import { AssetLibraryTableColumn } from "../../../Interfaces";

const useStyles = makeStyles({
  root: {
    padding: "0 1rem 1rem",
    minWidth: 400,
  },
});

interface Props {
  columns: AssetLibraryTableColumn[];
  hiddenColumns: { [id: string]: boolean };
  onToggleColumnVisible: (id: string) => void;
}

const HideShowColumnsMenu = ({ ...props }: Props) => {
  const classes = useStyles();

  const handleToggleColumn = (id: string) => {
    // try to decouple UI from this event. Maybe move elsewhere?
    (async () => {
      props.onToggleColumnVisible(id);
    })();
  };

  return (
    <FilterMenu label="Hide/Show Columns" icon={<Visibility />}>
      <div className={classes.root}>
        <Box>
          <FormGroup>
            {props.columns.map((c) => (
              <FormControlLabel
                control={<Switch size="small" />}
                label={c.name}
                checked={!props.hiddenColumns[c.id]}
                onChange={() => handleToggleColumn(c.id)}
                key={c.id}
              />
            ))}
          </FormGroup>
        </Box>
      </div>
    </FilterMenu>
  );
};

export default HideShowColumnsMenu;
