import firebase from "firebase/compat/app";

async function handleToggleOverheadRender(mediaId: string, revisionId: string) {
  // First get the media record we're toggling to check its current state
  const mediaDoc = await firebase
    .firestore()
    .collection("media")
    .doc(mediaId)
    .get();

  const isCurrentlyOverhead = mediaDoc.data()?.isOverhead || false;

  // If we're setting this to false, just update this one record
  if (isCurrentlyOverhead) {
    await firebase
      .firestore()
      .collection("media")
      .doc(mediaId)
      .update({ isOverhead: false });
    return;
  }

  // If we're setting this to true, we need to unset any existing overhead media first
  const currentOverheadMedia = await firebase
    .firestore()
    .collection("media")
    .where("revisionId", "==", revisionId)
    .where("tag", "==", "deliverable")
    .where("isOverhead", "==", true)
    .get();

  // Create a batch to ensure all updates happen atomically
  const batch = firebase.firestore().batch();

  // Unset any existing overhead media
  for (const doc of currentOverheadMedia.docs) {
    batch.update(doc.ref, { isOverhead: false });
  }

  // Set the new media as overhead
  batch.update(firebase.firestore().collection("media").doc(mediaId), {
    isOverhead: true,
  });

  await batch.commit();
}

export { handleToggleOverheadRender };
