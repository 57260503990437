import * as React from "react";
import { AgentCtx } from "./AgentContext";
import { Agent } from "@yardzen-inc/models";
import { ModelerPageBody } from "./ModelerPageBody";
import { DesignerPageBody } from "./DesignerPageBody";

const PageBodyWrapper: React.FC = () => {
  const agent = React.useContext(AgentCtx) as Agent;
  const isDesigner = React.useMemo(() => agent.type === "design", [agent.type]);

  return isDesigner ? (
    <DesignerPageBody agent={agent} />
  ) : (
    <ModelerPageBody agent={agent} />
  );
};

export { PageBodyWrapper };
