import React, { createContext, FC, useState, useEffect, memo } from "react";
import firebase from "firebase/compat/app";
import { useDispatch } from "../../state/hooks";
import { setCurrentUser } from "../../state/slices/session/sessionSlice";

const FirebaseJWTContext = createContext<string | false | null>(null);

export interface FirebaseJwtProviderProps {
  noRenderUntilAuthResult?: boolean;
}

const FirebaseJwtProvider: FC<FirebaseJwtProviderProps> = memo(
  ({ children, noRenderUntilAuthResult }) => {
    const [token, setToken] = useState<string | false | null>(null);
    const dispatch = useDispatch();

    useEffect(handleAuthStateChange, []);

    return (
      <FirebaseJWTContext.Provider value={token || null}>
        {!!noRenderUntilAuthResult && token === null ? null : children}
      </FirebaseJWTContext.Provider>
    );

    function handleAuthStateChange(): () => void {
      return firebase.auth().onIdTokenChanged(async (user) => {
        if (user) {
          const token = await user.getIdToken();
          dispatch(setCurrentUser({ currentUser: user, token }));
          return setToken(token);
        }

        setToken(null);
        dispatch(setCurrentUser({ currentUser: null, token: null }));
      });
    }
  }
);

export { FirebaseJwtProvider, FirebaseJWTContext };
export default FirebaseJwtProvider;
