import React, { FC, useState, useRef, useMemo, useEffect } from "react";
import StatsHeader from "./StatsHeader";
import {
  AssignmentExportPropMap,
  ProjectExportPropMap,
  Assignments,
  Projects,
  DocProp,
  AssignmentExportProps,
  ProjectExportProps,
} from "./types";
import {
  getProperties,
  runAssignmentExport,
  runDesignAssignmentExport,
  setAssignmentDefaults,
} from "./util";
import ControlledStatsRow from "./ControlledStatsRow";
import { Divider, Box, Button, CircularProgress } from "@material-ui/core";
import GenericSnackBar from "../../Components/GenericSnackBar";

export interface StatsPageProps {}

/*
  Stats Page contains the client side logic for
  customizing and creating modular reports.

  TODO: elaborate
*/
const StatsPage: FC<StatsPageProps> = (props) => {
  const [index, setIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const exportProps = useMemo(
    () =>
      [
        setAssignmentDefaults(
          getProperties(translateIndexValueToPrimaryDocumentName(0))
        ) as AssignmentExportProps[],
        setAssignmentDefaults(
          getProperties(translateIndexValueToPrimaryDocumentName(1))
        ) as ProjectExportProps[],
      ] as [AssignmentExportProps[], ProjectExportProps[]],
    []
  );

  useEffect(onIndexChange, [index]);

  const dataReference = useRef<[AssignmentExportPropMap, ProjectExportPropMap]>(
    getDataReferenceDefaultValue()
  );

  return (
    <>
      {errorMessage && (
        <GenericSnackBar
          variant="error"
          onClose={() => setErrorMessage(null)}
          message={errorMessage}
        ></GenericSnackBar>
      )}
      <StatsHeader index={index} setIndex={setIndex} />
      {renderControlledStatsRows()}
      <Box
        p={1}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Box pr={1}>
          <Button
            color="primary"
            variant="outlined"
            disabled={loading}
            onClick={async () => {
              setLoading(true);

              try {
                if (index === 0) {
                  await runAssignmentExport({
                    properties: dataReference.current[0],
                  });
                } else if (index === 1) {
                  await runDesignAssignmentExport({
                    properties: dataReference.current[1],
                  });
                }
              } catch (err) {
                console.error(err);
                setErrorMessage("csv creation failed");
              } finally {
                setLoading(false);
              }
            }}
          >
            create csv
          </Button>
        </Box>
        {loading && <CircularProgress />}
      </Box>
    </>
  );

  function onIndexChange() {
    dataReference.current = getDataReferenceDefaultValue();
  }

  /*
    returns the exivilent "PrimaryDocument" value
    from the corresponding index value.
  */
  function translateIndexValueToPrimaryDocumentName(
    index: number
  ): Assignments | Projects {
    switch (index) {
      case 0:
        return "Assignments";
      case 1:
        return "Projects";
      default:
        throw new Error(`Illegal index value ${index}`);
    }
  }

  /*
    maps the keys from the property groups provided to
    Controlled stats row components, as well as a Divider
    below(except on the last row)
  */
  function renderControlledStatsRows(): React.ReactNode {
    const keys = Object.keys(exportProps[index][0]);

    const len = keys.length;

    return keys.map((key, i) => {
      // @ts-ignore
      const initSelected = exportProps[index][0][key];
      // @ts-ignore
      const initDeselected = exportProps[index][1][key];

      return (
        <Box key={key}>
          <ControlledStatsRow
            index={index}
            initialSelected={[...initSelected]}
            initialDeselected={[...initDeselected]}
            title={key}
            update={(props: DocProp[]) => {
              // @ts-ignore
              dataReference.current[index][key] = [...props];
            }}
          />
          <Box pt={1}>{i !== len - 1 && <Divider />}</Box>
        </Box>
      );
    });
  }

  /*
    returns the default selectrions for both reports
  */
  function getDataReferenceDefaultValue(): [
    AssignmentExportPropMap,
    ProjectExportPropMap
  ] {
    return [
      {
        ...exportProps[0][0],
      },
      {
        ...exportProps[1][0],
      },
    ];
  }
};

export { StatsPage };
export default StatsPage;
