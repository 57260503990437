import React, { FC, ReactNode } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Profile, Project } from "@yardzen-inc/models";
import { formatAddress } from "../../util/functions/formatAddress";
import { getPackageDisplayName } from "../../util/functions/getPackageDisplayName";
import { BudgetDisplayWrapper } from "../Budget/BudgetDisplayWrapper";
import { makeProjectSlug } from "../../util/makeProjectSlug";

export interface DesignBriefHeaderInfoProps {
  profile: Profile;
  project: Project;
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
  },
  budgetTextContainer: {
    margin: "0px",
    padding: "8px 0px",
  },
}));

const DesignBriefHeaderInfo: FC<DesignBriefHeaderInfoProps> = ({
  profile,
  project,
}) => {
  const classes = useStyles();

  return (
    <Box
      width="100%"
      boxShadow={2}
      bgcolor="#E5E5E5"
      display="flex"
      flexDirection="row"
    >
      {" "}
      <Box display="flex" flexDirection="column" p={1} mr={3}>
        {renderTypo(
          "Project ID:",
          makeProjectSlug(profile.lastName ?? "", profile.street ?? "")
        )}
        {renderTypo(
          "Client Name:",
          `${profile.firstName} ${profile.lastName ?? ""}`
        )}
        {renderTypo("Address:", `${formatAddress(profile)}`)}
        {renderTypo("Package Type:", getPackageDisplayName(profile.package))}
        {
          // @ts-ignore
          renderTypo("Lot Size:", profile["lotSize"] ?? "[Left blank]")
        }
      </Box>
      <Box p={1}>
        <BudgetDisplayWrapper
          projectId={project.id}
          packageType={profile.package}
          yardDifficultyRating={project.yardDifficultyRating}
          clientBudgetIntent={project.clientBudgetIntent}
          additionalCssClasses={{
            budgetCaptions: classes.title,
            budgetTextContainer: classes.budgetTextContainer,
          }}
        />
      </Box>
    </Box>
  );

  function renderTypo(title: string, value: string): ReactNode {
    return (
      <Box display="flex" flexDirection="row" p={1}>
        <Box pr={1}>
          <Typography className={classes.title}>{title}</Typography>
        </Box>
        <Box>
          <Typography>{value}</Typography>
        </Box>
      </Box>
    );
  }
};

export { DesignBriefHeaderInfo };
export default DesignBriefHeaderInfo;
