import React, {
  FC,
  useRef,
  ReactNode,
  CSSProperties,
  ChangeEvent,
  useMemo,
} from "react";
import GenericRouter, { GenericRouterProps } from "./GenericRouter";
import { Box, makeStyles, Select, MenuItem } from "@material-ui/core";
import { PageMapEntryWithHumanName } from "./types";
import { makeRRURL } from "./util";

export interface GenericRouterWithDropDownSelectProps
  extends GenericRouterProps {
  pageMap: PageMapEntryWithHumanName[];
  tabsComponentStyles?: CSSProperties;
  spacerSize?: number;
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: "100%",
  },
}));

const GenericRouterWithDropDownSelect: FC<GenericRouterWithDropDownSelectProps> =
  ({ spacerSize, ...props }) => {
    const classes = useStyles();

    const routeCacheRef = useRef<{
      [key: string]: { [shortName: string]: string };
    }>({});

    const activePath = useMemo(getCurrentPathString, [props.location.pathname]);

    return (
      <>
        {props.location.pathname !== props.match.url && (
          <Box
            className={classes.tabs}
            style={props.tabsComponentStyles}
            display="flex"
            flexDirection="row-reverse"
          >
            <Select
              value={activePath}
              // @ts-ignore
              onChange={handleSelectChange}
              style={{ minWidth: "200px" }}
            >
              {renderSelectMenuItems()}
            </Select>
          </Box>
        )}
        <Box py={spacerSize} display="inline-block" />
        <GenericRouter {...props} routeCacheRef={routeCacheRef} />
      </>
    );

    function renderSelectMenuItems(): ReactNode[] {
      return props.pageMap.map(renderSelectMenuItem);
    }

    // get the current match url + the next route component, but nothing after
    function getCurrentPathString(): string {
      const matchSplit = props.match.url.split("/");
      const matchSplitLen = matchSplit.length;

      const urlSplit = props.location.pathname.split("/");

      return urlSplit.slice(0, matchSplitLen + 1).join("/");
    }

    function renderSelectMenuItem(entry: PageMapEntryWithHumanName): ReactNode {
      return (
        <MenuItem
          value={makeRRURL(
            entry.routeName,
            {
              location: props.location,
              history: props.history,
              match: props.match,
            },
            routeCacheRef
          )}
          key={`${entry.key}__select`}
        >
          {entry.humanName ?? entry.routeName}
        </MenuItem>
      );
    }

    function handleSelectChange(e: ChangeEvent<HTMLSelectElement>) {
      console.log(e);
      props.history.push(e.target.value);
    }
  };

GenericRouterWithDropDownSelect.defaultProps = {
  spacerSize: 2,
};

export { GenericRouterWithDropDownSelect };
export default GenericRouterWithDropDownSelect;
