import firebase from "firebase/compat/app";

async function geocodeAddress(
  address: string
): Promise<google.maps.GeocoderResult[]> {
  const getSuggestedAddress = firebase
    .functions()
    .httpsCallable("getSuggestedAddress");

  const results = (await getSuggestedAddress({ address })).data
    .results as google.maps.GeocoderResult[];

  return results;
}

export { geocodeAddress };
