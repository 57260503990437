import { PackageType } from "../../Interfaces";

export const getIsExteriorPackage = (
  purchasedPackage: PackageType | string | undefined | null
): boolean => {
  if (!purchasedPackage) return false;
  return (
    purchasedPackage === PackageType.CurbAppeal ||
    purchasedPackage === PackageType.OutdoorTransformation ||
    purchasedPackage === PackageType.UberPremium
  );
};
