import { YardDifficultyRating } from "@yardzen-inc/models";
import { totalPrice } from "./totalPrice";
import { roundPrice } from "./roundPrice";
import { calculateBaseCostRange } from "./calculateBaseCostRange";
import { Asset } from "./types";

export const calculateTotalCostRange = (
  totalBudget: number,
  yardDifficultyRating: YardDifficultyRating,
  elements: Asset[],
  materials: Asset[],
  plants: Asset[],
  softscapeItems: Asset[],
  hardscapeItems: Asset[]
) => {
  const sum =
    totalPrice(elements) +
    totalPrice(materials) +
    totalPrice(plants) +
    totalPrice(softscapeItems) +
    totalPrice(hardscapeItems);

  const roundedTotal = roundPrice(sum, 500);

  return [
    roundedTotal + calculateBaseCostRange(totalBudget, yardDifficultyRating)[0],
    roundedTotal + calculateBaseCostRange(totalBudget, yardDifficultyRating)[1],
  ];
};
