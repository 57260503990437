import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";
import * as React from "react";

interface CompactExpansionProps {
  summary: React.ReactElement;
  summaryStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  detail: React.ReactElement[];
  detailParentStyle?: React.CSSProperties;
  expanded?: boolean;
}

const CompactExpansion: React.FunctionComponent<CompactExpansionProps> = ({
  summary,
  detail,
  style,
  detailParentStyle,
  expanded,
  summaryStyle,
}) => {
  return (
    <ExpansionPanel expanded={expanded} style={style}>
      <ExpansionPanelSummary
        style={{ minHeight: 0, height: "28px", ...summaryStyle }}
      >
        {summary}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        style={{
          display: "flex",
          flexDirection: "column",
          ...detailParentStyle,
        }}
      >
        {detail.map((component) => {
          return component;
        })}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default CompactExpansion;
