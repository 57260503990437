import * as React from "react";
import {
  Dialog,
  DialogContent,
  LinearProgress,
  Typography,
  makeStyles,
  Checkbox,
  Divider,
  IconButton,
  FormControlLabel,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { YZButton } from "@yardzen-inc/react-common";

const useStyles = makeStyles({
  root: {},
  padBot: {
    paddingBottom: "1rem",
  },
  bottomBox: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    margin: "1rem 0",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
  },
});

export interface GenericConfirmProps {
  onSubmit: () => void;
  onClose: () => void;
  open: boolean;
  submitting?: boolean;
  body?: string;
  buttonText?: string;
  checkLabel?: string;
  title?: string;
}

export default (props: GenericConfirmProps) => {
  const classes = useStyles();
  const [sure, setSure] = React.useState<boolean>(false);

  React.useEffect(() => {
    setSure(false);
  }, [props.open]);

  return (
    <Dialog
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      open={props.open}
      onClose={props.onClose}
    >
      {props.submitting && <LinearProgress variant="indeterminate" />}
      <IconButton onClick={props.onClose} className={classes.closeButton}>
        <Close></Close>
      </IconButton>
      <DialogContent>
        <Typography
          variant="h4"
          className={classes.padBot}
          style={{ paddingRight: "3rem" }}
        >
          {props.title || "Are you sure?"}
        </Typography>
        <Typography variant="body1" className={classes.padBot}>
          {props.body?.split("\n").map((i, key) => {
            return (
              <div className={classes.padBot} key={key}>
                {i}
              </div>
            );
          }) || ""}
        </Typography>
        <Divider />

        <div className={classes.bottomBox}>
          <FormControlLabel
            control={
              <Checkbox checked={sure} onChange={() => setSure(!sure)} />
            }
            label={props.checkLabel || "I'm sure"}
          />
          <YZButton
            variant="outlined"
            disabled={!sure || props.submitting}
            onClick={props.onSubmit}
            style={{ width: "fit-content", height: "fit-content" }}
          >
            {props.buttonText || "Do it"}
          </YZButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};
