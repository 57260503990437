import React, { FC, ReactNode } from "react";
import { DesignBriefEntry, DesignBriefApi, DesignBriefEntryType } from "./util";
import { Box, Typography, IconButton } from "@material-ui/core";
import DesignBriefCheckbox from "./DesignBriefCheckbox";
import DesignBriefChecklistItemTextComponent from "./DesignBriefChecklistItemTextComponent";
import DesignChecklistItemImages from "./DesignChecklistItemImages";
import DesignBriefListItemMultipleChoiceComponent from "./DesignBriefListItemMultipleChoiceComponent";
import MoveUpDownButtons from "./MoveUpDownButtons";
import { Link } from "@material-ui/icons";

export interface DesignBriefChecklistItemProps {
  entry: DesignBriefEntry<any>;
  api: DesignBriefApi;
  selected?: boolean;
  noCheck?: boolean;
  moveUp?: () => void;
  moveDown?: () => void;
  handleOpenContextMenu?: (
    e: React.MouseEvent<any>,
    entry: DesignBriefEntry<any>
  ) => void;
  pm?: boolean;
}

const DesignBriefChecklistItem: FC<DesignBriefChecklistItemProps> = (props) => {
  return (
    <Box
      flexDirection="column"
      display="flex"
      bgcolor={props.selected ? "rgb(229, 229, 229, 190)" : void 0}
      onContextMenu={(e) => {
        if (props.handleOpenContextMenu)
          props.handleOpenContextMenu(e, props.entry);
      }}
    >
      <Box display="flex" flexDirection="row" p={0.5} alignItems="center">
        {!props.noCheck && (
          <DesignBriefCheckbox
            update={(value) =>
              props.api.updateEntry({ id: props.entry.id, marked: value })
            }
            value={props.entry.marked}
          />
        )}
        {renderRefLink()}
        {renderLabel()}
        {renderEditableContent()}
        {props.pm && (
          <MoveUpDownButtons moveUp={props.moveUp} moveDown={props.moveDown} />
        )}
      </Box>
      {props.entry.referenceImages && (
        <DesignChecklistItemImages images={props.entry.referenceImages} />
      )}
    </Box>
  );

  function renderRefLink(): ReactNode | null {
    if (props.entry.referenceLink) {
      return (
        <Box pr={1}>
          <IconButton
            size="small"
            title={props.entry.referenceLink}
            onClick={() => window.open(props.entry.referenceLink, "_blank")}
          >
            <Link />
          </IconButton>
        </Box>
      );
    }

    return null;
  }

  function renderLabel(): ReactNode | null {
    if (props.entry.label) {
      return (
        <Box pr={2}>
          <Typography style={{ fontStyle: "italic", height: "min-content" }}>
            {props.entry.label}
          </Typography>
        </Box>
      );
    }

    return null;
  }

  function renderEditableContent() {
    switch (props.entry.type) {
      case DesignBriefEntryType.TEXT:
        return (
          <DesignBriefChecklistItemTextComponent
            pm={!!props.pm}
            value={props.entry.value}
            update={(value) =>
              props.api.updateEntry({ id: props.entry.id, value })
            }
          />
        );
      case DesignBriefEntryType.MULITPLE_CHOICE:
        if (!props.entry.options) {
          throw new Error("Multiple choice entry requires options prop");
        }
        return (
          <DesignBriefListItemMultipleChoiceComponent
            pm={!!props.pm}
            options={props.entry.options}
            value={props.entry.value}
            update={(value) =>
              props.api.updateEntry({ id: props.entry.id, value })
            }
          />
        );
      default:
        return <Typography>Not implemented</Typography>;
    }
  }
};

export { DesignBriefChecklistItem };
export default DesignBriefChecklistItem;
