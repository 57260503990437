import {
  profileProps,
  assignmentProps,
  projectProps,
  agentProps,
  PrimaryDocument,
  ProfileProps,
  AssignmentProps,
  AgentProps,
  ProjectProps,
  AssignmentExportRequest,
  designAssignmentProps,
  DesignAssignmentProps,
  ProjectExportProps,
  DesignAssignmentExportRequest,
  AssignmentExportPropMap,
  ProjectExportPropMap,
  AssignmentExportProps,
  ExportProps,
  revisionProperties,
  projectManagerProps,
} from "./types";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import { GenericOnCallFunctionAlert } from "../../util/genericAlert";

const assignmentDefaultSelected: AssignmentExportPropMap = {
  assignmentProps: ["type", "assignedAt", "title", "createdAt", "submittedAt"],
  profileProps: ["firstName", "lastName", "package"],
  agentProps: ["firstName", "lastName"],
  projectProps: [],
};

const designAssignmentDefaultProps: ProjectExportPropMap = {
  designAssignmentProps: [
    "type",
    "correctionFor",
    "dueDate",
    "submitted",
    "submittedAt",
    "createdAt",
  ],
  projectManagerProps: ["firstName", "lastName"],
  revisionProps: ["dateSentToClient", "dateSubmittedForReview"],
  profileProps: ["firstName", "lastName", "package"],
  agentProps: ["firstName", "lastName"],
  projectProps: ["assignedAt"],
};

export const getHumanReadableNameForPropKey = (key: string): string => {
  switch (key) {
    case "assignmentProps":
    case "designAssignmentProps":
      return "Assignment Data";
    case "agentProps":
      return "Agent Data";
    case "projectProps":
      return "Project Data";
    case "profileProps":
      return "Profile Data";
    case "revisionProps":
      return "Revision Data";
    case "projectManagerProps":
      return "Project Manager Data";
    default:
      throw new Error(
        "invalid key passed to getHumanReadableNameForPropKey: " + key
      );
  }
};

// returns all available properties for the prirmay doc type
export const getProperties = (
  type: PrimaryDocument | "Assignment" | "Projects"
) => {
  if (type === "Assignments") {
    return {
      profileProps: [...profileProps] as ProfileProps,
      assignmentProps: [...assignmentProps] as AssignmentProps,
      agentProps: [...agentProps] as AgentProps,
      projectProps: [...projectProps] as ProjectProps,
    } as AssignmentExportProps;
  } else if (type === "Projects") {
    return {
      profileProps: [...profileProps] as ProfileProps,
      projectManagerProps: [...projectManagerProps],
      designAssignmentProps: [
        ...designAssignmentProps,
      ] as DesignAssignmentProps,
      agentProps: [...agentProps] as AgentProps,
      projectProps: [...projectProps] as ProjectProps,
      revisionProps: [...revisionProperties],
    } as ProjectExportProps;
  }

  throw new Error("getProperies passed a value that is not a PrimaryDocument");
};

export const setAssignmentDefaults = <t extends ExportProps>(
  props: t
): [t, t] => {
  let selected: t;

  if ((props as AssignmentExportProps)?.assignmentProps) {
    selected = { ...assignmentDefaultSelected } as t;
  } else if ((props as ProjectExportProps)?.designAssignmentProps) {
    selected = { ...designAssignmentDefaultProps } as t;
  } else {
    throw new Error(
      "props passed to setAssignmentDefaults is not an extension of ExportProps"
    );
  }

  const deselected = { ...props };

  Object.keys(selected).forEach((key) => {
    // @ts-ignore
    (selected[key] as string[]).forEach((val) => {
      // @ts-ignore
      const index = (deselected[key] as string[]).findIndex(
        (value) => value === val
      );

      if (index >= 0) {
        // @ts-ignore
        (deselected[key] as string[]).splice(index, 1);
      }
    });
  });

  return [selected, deselected];
};

export const runAssignmentExport = async (
  req: AssignmentExportRequest
): Promise<any> => {
  const res = await getFunction()({ props: req, isDesignAssignment: false });

  const { data } = res.data;

  downloadCSV(data);
};

/*
  makes a fake a tag and sets the download to
  the url of the blob created from the csv string,
  then "fake clicks" it.
*/
export const downloadCSV = (CSV: string): void => {
  const blob = new Blob([CSV], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.setAttribute("download", `export_${new Date().getTime()}.csv`);

  document.body.appendChild(a);

  a.click();
};

export const runDesignAssignmentExport = async (
  req: DesignAssignmentExportRequest
) => {
  try {
    const res = await getFunction()({ props: req, isDesignAssignment: true });

    const { data } = res.data;

    downloadCSV(data);
  } catch (error) {
    GenericOnCallFunctionAlert("runAssignmentExport", error.message);
  }
};

function getFunction(): firebase.functions.HttpsCallable {
  return firebase.functions().httpsCallable("runAssignmentExport");
}

// leave this here for future testing

// export const LOCAL_runDesignAssignmentExport = async (
//   req: DesignAssignmentExportRequest
// ) => {
//   const headers = new Headers();
//   headers.set("Content-Type", "application/json");

//   const body = JSON.stringify(req);
//   const result = await fetch("http://10.0.0.125:8080/designAssignments", {
//     headers,
//     method: "POST",
//     body,
//   });

//   const csv = await result.text();

//   console.log("result \n\n", csv);
//   downloadCSV(csv);
// };

// // Remove this in prodiction
// export const LOCAL_runAssignmentExport = async (
//   req: AssignmentExportRequest
// ): Promise<any> => {
//   const headers = new Headers();
//   headers.set("Content-Type", "application/json");

//   const body = JSON.stringify(req);
//   const result = await fetch("http://10.0.0.125:8080/assignments", {
//     headers,
//     method: "POST",
//     body,
//   });

//   const csv = await result.text();

//   console.log("result \n\n", csv);
//   downloadCSV(csv);
// };
