import * as React from "react";
import { Dialog, DialogContent, Typography } from "@material-ui/core";
import { YZButton } from "@yardzen-inc/react-common";

interface Props {
  onClose: () => void;
  open: boolean;
}

export default ({ onClose, open }: Props) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant="h3" style={{ marginTop: "2rem" }} align="center">
          Success!
        </Typography>
        <br />

        <Typography variant="body1" align="center">
          This job has been submitted, you are now free to start another one!
        </Typography>
        <br />
        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "center",
          }}
        >
          <YZButton variant="contained" onClick={onClose}>
            Continue
          </YZButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};
