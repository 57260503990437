import React, { FC, useState, useEffect } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import FileUpload from "../Components/FileUpload";
import { Media } from "@yardzen-inc/models";
import {
  LinearProgress,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export interface GenericMultiUploadProps extends BoxProps {
  tag: string;
  userId: string;
  variant?: string;
  onUpload?: () => void;
}

const GenericMultiUpload: FC<GenericMultiUploadProps> = ({
  tag,
  userId,
  variant,
  onUpload,
  ...containerProps
}) => {
  const [media, setMedia] = useState<Media[] | null>(null);

  useEffect(listenOnMedia, [variant, tag, userId]);

  return (
    <Box {...containerProps}>
      <Box p={1} width="50%">
        <FileUpload
          fileTag={tag}
          variant={variant}
          fileVisibility={{
            isClientVisible: true,
            isDesignerContributed: true,
            isDesignerVisible: true,
          }}
          onUploadComplete={onUpload}
          clientUserId={userId}
        />
      </Box>
      <Box display="flex" flexDirection="column" marginLeft={1} width="50%">
        {!media && <LinearProgress variant="indeterminate" />}
        {media && !media.length && (
          <Typography variant="body1" align="center">
            No uploads
          </Typography>
        )}
        {media && !!media.length && (
          <>
            <Typography variant="body1" align="center">
              Uploaded Files:
            </Typography>
            <Divider></Divider>
          </>
        )}
        {media &&
          media.map((w) => {
            return (
              <Box display="flex" flexDirection="row" flexWrap="nowrap">
                <Typography variant="body1" style={{ flexGrow: 1 }}>
                  {w.originalFileName}
                </Typography>
                <IconButton
                  size="small"
                  onClick={async () => {
                    setMedia(null);
                    await w.delete();
                  }}
                >
                  <Delete></Delete>
                </IconButton>
              </Box>
            );
          })}
      </Box>
    </Box>
  );

  function listenOnMedia(): () => void {
    let query = firebase
      .firestore()
      .collection("media")
      .where("userId", "==", userId)
      .where("tag", "==", tag);

    if (!!variant) {
      query = query.where("variant", "==", variant);
    }

    return query.onSnapshot(async (snap) => {
      if (snap.empty) {
        return setMedia([]);
      }

      setMedia(
        await Promise.all(
          snap.docs.map((doc) => Media.createFromQuerySnapshot(doc))
        )
      );
    });
  }
};

export { GenericMultiUpload };
export default GenericMultiUpload;
