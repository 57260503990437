import React, { FC } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { Typography, Input } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";
import { InputProps } from "@material-ui/core/Input";

export interface DesignBriefEntryEditFormLabelFieldProps extends BoxProps {
  value: string;
  onChange: (value: any) => any;
  typographyContainerProps?: BoxProps;
  typographyProps?: TypographyProps;
  textFieldContainerProps?: BoxProps;
  textFieldProps?: InputProps;
  title?: string;
  error?: string | null;
}

const DesignBriefEntryEditFormLabelField: FC<DesignBriefEntryEditFormLabelFieldProps> =
  ({
    value,
    onChange,
    title,
    typographyContainerProps,
    typographyProps,
    textFieldContainerProps,
    textFieldProps,
    error,
    ...containerProps
  }) => {
    return (
      <Box display="flex" flexDirection="row" {...containerProps}>
        <Box pr={2} {...typographyContainerProps}>
          <Typography style={{ fontStyle: "italic" }} {...typographyProps}>
            {title || "Label"}:{" "}
          </Typography>
        </Box>
        <Box {...textFieldContainerProps}>
          <Input
            {...textFieldProps}
            error={!!error}
            value={value}
            onChange={({ currentTarget: { value } }) => onChange(value)}
          />
          {!!error && (
            <Typography variant="caption" style={{ color: "red" }}>
              {error}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

export { DesignBriefEntryEditFormLabelField };
export default DesignBriefEntryEditFormLabelField;
