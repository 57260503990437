import { makeStyles, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { IFormikElementProps } from "../../../Interfaces";
import { visibilityValues } from "./VisibilityTypes";

export interface VisibilitySelectProps {}

const useRowStyles = makeStyles({
  editItem: {
    marginTop: 5,
    flex: 1,
  },
  select: {
    width: "100%",
  },
});

/**
 * VisibilitySelect is a wrapper for the MUI Select element that consumes the formik context
 * to set the common 'visibility' column in the asset library.
 * @param props
 * @returns
 */
const VisibilitySelect: FC<VisibilitySelectProps> = (props) => {
  const classes = useRowStyles();

  const { values, setFieldValue } = useFormikContext<IFormikElementProps>();

  return (
    <div className={classes.editItem}>
      <InputLabel shrink>Visibility</InputLabel>
      <Select
        fullWidth
        className={classes.select}
        value={values.visibility}
        // @ts-ignore
        onChange={onChange}
      >
        {renderItems()}
      </Select>
    </div>
  );

  function onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    setFieldValue("visibility", ev.target.value);
  }

  function renderItems() {
    return visibilityValues.map((value) => (
      <MenuItem value={value} key={`visibility-input-${value}`}>
        {value}
      </MenuItem>
    ));
  }
};

export { VisibilitySelect };
export default VisibilitySelect;
