import React, { FC } from "react";
import { makeStyles } from "@material-ui/core";
import { ModelingAssignmentWithProfile } from "../../util/hooks/useOpenModelingAssignments";
import { ModelingAssignmentListItem } from "./ModelingAssignmentListItem";

export interface ModelingAssignmentListProps {
  openModelingAssignments: ModelingAssignmentWithProfile[];
}

const useStyles = makeStyles({
  listContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
});

const ModelingAssignmentList: FC<ModelingAssignmentListProps> = ({
  openModelingAssignments,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.listContainer}>
      {openModelingAssignments.map((assignmentWithProfile, index) => (
        <ModelingAssignmentListItem
          key={assignmentWithProfile.assignment.id}
          openModelingAssignment={assignmentWithProfile}
          position={index + 1}
        />
      ))}
    </div>
  );
};

export { ModelingAssignmentList };
