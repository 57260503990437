import { useState, useEffect, useMemo } from "react";
import firebase from "firebase/compat/app";

/* USE QUERY
TLDR: QUERY MUST BE MEMORIC;

The hook subscribes to a firebase firestore query "query",
and transforms the output through the provided handler.
the snapshotHandler should take a firebase query snaphot and
return either the generic type <t>, or a Promise that resolves
to the generic type <t>.

WARNING: DEPS MUST BE PROVIDED, IF YOU USE THE QUERY IN DEPS IT MUST BE MEMORIC.
Ignoring this will cause infinite rerender loops due to inevitable
reference inequality.
*/

export type QuerySnapshotHandler<t> = (
  snap: firebase.firestore.QuerySnapshot,
  currentData: t | null | false
) => t | Promise<t>;

interface UseQuerySnapshot {
  (query: firebase.firestore.Query, deps: any): [
    Error | null,
    firebase.firestore.QuerySnapshot | null | false
  ];
  <t>(
    query: firebase.firestore.Query,
    handler: QuerySnapshotHandler<t>,
    deps: any[]
  ): [Error | null, t | null | false];
}

const useQuerySnapshot: UseQuerySnapshot = <
  t = firebase.firestore.QuerySnapshot
>(
  query: firebase.firestore.Query,
  snapshotHandlerOrDeps?: QuerySnapshotHandler<t> | any[],
  deps?: any[]
): [Error | null, t | null | false] => {
  const [data, setData] = useState<t | null | false>(null);
  const [err, setErr] = useState<Error | null>(null);

  const [snapshotHandler, _deps] = useMemo(derriveDeps, [
    snapshotHandlerOrDeps,
    deps,
  ]);

  useEffect(listenOnQuery, [..._deps]);

  return [err, data];

  function derriveDeps(): [QuerySnapshotHandler<t> | undefined, any[]] {
    if (deps) {
      return [
        snapshotHandlerOrDeps as QuerySnapshotHandler<t> | undefined,
        deps,
      ];
    }
    return [undefined, snapshotHandlerOrDeps as any[]];
  }

  function listenOnQuery(): (() => void) | void {
    if (!query || !(query instanceof firebase.firestore.Query)) {
      return setErr(new Error("INVALID_QUERY"));
    }

    if (!(_deps instanceof Array)) {
      return setErr(new Error("NO_DEPS"));
    }

    return query.onSnapshot(async (snap) => {
      if (!snapshotHandler) {
        if (snap.empty && data !== false) {
          setData(false);
          return;
        }

        setData(snap as any);

        return;
      }

      const transformedData = await snapshotHandler(snap, data);

      if (transformedData) {
        return setData(transformedData);
      }

      if (!data && data !== false) {
        setData(false);
      }
    });
  }
};

export { useQuerySnapshot };
export default useQuerySnapshot;
