import Papa, { LocalFile } from "papaparse";

export async function parseCsvFile<T = Record<string, string>>(
  file: LocalFile,
  opts?: {
    lowercaseHeader?: boolean;
  }
) {
  const csv: T[] = [];

  await new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      step: (row) => csv.push(row.data as T),
      complete: resolve,
      error: reject,
      dynamicTyping: false,
    });
  });

  if (opts?.lowercaseHeader) {
    csv.forEach((row: any) => {
      Object.keys(row).forEach((key) => {
        const lowerCaseKey = key.toLowerCase();
        if (lowerCaseKey !== key) {
          row[lowerCaseKey] = row[key];
          delete row[key];
        }
      });
    });
  }

  return csv;
}
