import React, { useEffect, useState } from "react";
import useDaNotesCounter from ".././util/useDaNotesCounter";
import { Button, ButtonGroup } from "@material-ui/core";
import {
  Project,
  Agent,
  Revision,
  DesignAssignment,
  onHoldDesignAssignmentStatuses,
} from "@yardzen-inc/models";
import firebase from "firebase/compat/app";

export interface Props {
  assignments: DesignAssignment[] | null;
  activeAssignment: DesignAssignment | null | false;
  activeAssignmentRevision: Revision | null | false;
  designer: Agent | false | null;
  project: Project;
  disabled?: boolean;
  submitAssignment: () => void;
  createAssignment: () => void;
  removeDesigner: () => void;
  manuallyAssignDesigner: () => void;
  devReset?: () => any;
  editDueDate: () => void;
  assignCorrection: () => void;
  openNotesSidebar: () => void;
}

export const DesignAssignmentControlButtons: React.FC<Props> = ({
  assignments,
  activeAssignment,
  activeAssignmentRevision,
  project,
  designer,
  createAssignment,
  removeDesigner,
  manuallyAssignDesigner,
  devReset,
  editDueDate,
  assignCorrection,
  openNotesSidebar,
  submitAssignment,
  disabled = false,
}: Props) => {
  const noteCount = useDaNotesCounter(project);
  const [buttons, setButtons] = useState<React.ReactNode[]>([]);

  useEffect(() => {
    async function populateButtonsState() {
      const _buttons = await renderButtons();
      setButtons(_buttons);
    }
    populateButtonsState();
  }, [
    project,
    assignments,
    activeAssignment,
    activeAssignmentRevision,
    designer,
  ]);

  return (
    <ButtonGroup variant="outlined" color="primary">
      {!buttons.length ? <div>loading...</div> : buttons}
    </ButtonGroup>
  );

  async function renderButtons(): Promise<React.ReactNode[]> {
    const buttons: React.ReactNode[] = [];

    const noDesignerAndAssignment = !designer && !!activeAssignment;
    const listed = activeAssignment && activeAssignment.listed;

    if (assignments?.length) {
      buttons.push(
        <Button
          onClick={openNotesSidebar}
          disabled={!noteCount || disabled}
          key="see-designer-notes"
        >
          See Designer Notes ({noteCount ?? 0})
        </Button>
      );
    }

    if (devReset) {
      buttons.push(
        <Button
          disabled={disabled}
          onClick={devReset}
          key="dev-reset-design-flow"
        >
          **(DEV) Reset Design Flow**
        </Button>
      );
    }

    if (activeAssignment) {
      buttons.push(
        <Button disabled={disabled} onClick={editDueDate} key="edit-due-date">
          Edit Due Date
        </Button>
      );
    }

    if (project.designAssignmentStatus === null) {
      buttons.push(
        <Button
          disabled={disabled}
          onClick={createAssignment}
          key="create-initial-assignment"
        >
          Create Initial Assignment
        </Button>
      );
    }

    if (project.designerId) {
      buttons.push(
        <Button
          disabled={disabled}
          onClick={removeDesigner}
          key="unassign-designer"
        >
          Unassign Designer
        </Button>
      );
    } else if (!!project.designAssignmentStatus) {
      buttons.push(
        <Button
          disabled={disabled}
          onClick={manuallyAssignDesigner}
          key="man-assign-designer"
        >
          Manually Assign Designer
        </Button>
      );
    }

    if (
      project.designAssignmentStatus &&
      onHoldDesignAssignmentStatuses.includes(project.designAssignmentStatus)
    ) {
      const getNextSelfAssignState = firebase
        .functions()
        .httpsCallable("getNextSelfAssignState");

      try {
        const res = await getNextSelfAssignState({
          projectId: project.id,
          designAssignmentStatus: project.designAssignmentStatus,
        });

        const { type, designAssignmentStatus } = res.data;

        buttons.push(
          <Button
            onClick={createAssignment}
            key="startv-2"
            disabled={noDesignerAndAssignment || disabled}
          >
            {(function () {
              if (noDesignerAndAssignment) {
                if (listed) {
                  return "v2 job in queue";
                } else {
                  return "No designer and not in queue";
                }
              }

              return `Start ${type ?? designAssignmentStatus}`;
            })()}
          </Button>
        );

        buttons.push(
          <Button
            disabled={disabled}
            onClick={assignCorrection}
            key={"correction-request"}
          >
            Issue Correction Assignment
          </Button>
        );
      } catch (err) {
        console.log("ERROR --> ", err);
      }
    }

    if (
      project.designAssignmentStatus &&
      !onHoldDesignAssignmentStatuses.includes(project.designAssignmentStatus)
    ) {
      buttons.push(
        <Button
          disabled={noDesignerAndAssignment || disabled}
          onClick={submitAssignment}
          key="submit-for-designer"
        >
          Submit Assignment for designer
        </Button>
      );
    }
    return buttons;
  }
};

export default DesignAssignmentControlButtons;
