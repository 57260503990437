import { LinearProgress, Typography } from "@material-ui/core";
import * as React from "react";
import { ProfileProperties } from "@yardzen-inc/models";
import { getIdentifyingPaymentId } from "../util/getIdentifyingPaymentId";
import { Variant } from "@material-ui/core/styles/createTypography";

interface IdentifyingPaymentIdProps {
  profileOrId: ProfileProperties | string;
  style?: React.CSSProperties;
  titleVariant?: Variant;
  title?: string;
  orderIdVariant?: Variant;
  linearProgressStyle?: React.CSSProperties;
}

const IdentifyingPaymentId: React.FunctionComponent<IdentifyingPaymentIdProps> =
  (props) => {
    const [identifyingPaymentId, setIdentifyingPaymentId] =
      React.useState<string>("");

    React.useEffect(() => {
      fetchIdentfyingPaymentId(props.profileOrId);
    }, [props.profileOrId]);

    if (!identifyingPaymentId.length) {
      return (
        <>
          <LinearProgress style={{ width: "min-content" }} />
        </>
      );
    }

    return (
      <div style={props.style}>
        {props.title && (
          <Typography variant={props.titleVariant || "caption"}>
            {props.title}
          </Typography>
        )}
        <Typography variant={props.orderIdVariant || "h5"}>
          {identifyingPaymentId}
        </Typography>
        {!identifyingPaymentId.length && (
          <LinearProgress style={props.linearProgressStyle} />
        )}
      </div>
    );

    async function fetchIdentfyingPaymentId(
      profileOrId: ProfileProperties | string
    ) {
      setIdentifyingPaymentId(await getIdentifyingPaymentId(profileOrId));
    }
  };

export { IdentifyingPaymentId };
