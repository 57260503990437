import { useQuery } from "@yardzen-inc/graphql";
import React from "react";
import firebase from "firebase/compat/app";
import { Addy, getAddress } from "../getAddress";
import { Profile } from "@yardzen-inc/models";
import * as firebaseClient from "../firebase/firebaseClient";
import { DESIGNER_SUMMARY_DATA_QUERY } from "../../../src/graphql/budgetQAQueries";
import { getUSDAZone } from "../functions/getUSDAZone";
import { getIsExteriorPackage } from "../selfAssign/getIsExteriorPackage";

const EMPTY_ASSIGNMENT_METADATA = {
  id: null,
  assignment_material_base_links: [],
  assignment_plant_base_links: [],
  assignment_product_base_links: [],
  assignment_softscape_item_links: [],
};

/**
 * This functionality exists in client-account at: src/util/hooks/useGetGeoZones.tsx
 * Keep in mind in case this functionality is updated or firebase functions are migrated to Pangea
 */
export function useGetDesignerSummaryData(
  profile: Profile,
  zip: string,
  assignmentId: string
) {
  if (!profile)
    return {
      error: null,
      loading: false,
      geoZones: null,
      usdaZone: null,
      exteriorDesignInfo: { id: undefined, isExteriorDesign: false },
      metadata: EMPTY_ASSIGNMENT_METADATA,
      region: null,
    };

  const projectState = profile.state?.trim()?.toUpperCase?.() || "UNKNOWN";

  const getGeoZonesFuncRef = React.useRef(
    firebase.functions().httpsCallable("getGeoZonesForAssignment")
  );
  const { latLng } = profile;
  const [loadingZone, setLoadingZone] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [loadingId, setLoadingId] = React.useState(true);
  const [zones, setZones] = React.useState<null | string[]>(null);
  const [userGeoZones, setUserGeoZones] = React.useState<
    null | { id: string; name: string }[]
  >(null);
  const [geoZonesMapAsJson, setGeoZonesMapAsJson] =
    React.useState<GeoJSON.GeoJsonObject | null>(null);
  const [address, setAddress] = React.useState<Addy | null | undefined>(
    undefined
  );
  const [usdaZone, setUsdaZone] = React.useState<string | null>(null);
  const [usdaZoneId, setUsdaZoneId] = React.useState<string | null>(null);
  const [loadingUsdaZone, setLoadingUsdaZone] = React.useState(false);
  const [loadingUsdaZoneId, setLoadingUsdaZoneId] = React.useState(false);
  const [exteriorDesignId, setExteriorDesignId] = React.useState();
  const [loadingExteriorDesign, setLoadingExteriorDesign] =
    React.useState(true);
  const [metadata, setMetadata] = React.useState(EMPTY_ASSIGNMENT_METADATA);
  const [region, setRegion] = React.useState<any>(null);

  React.useEffect(() => {
    const fetchAndSetAddress = async () => {
      try {
        setAddress(await getAddress(profile.id));
      } catch (e) {
        setError(
          `Unable to fetch client address to get geozones\nProfile: ${profile.id}`
        );
      }
    };

    fetchAndSetAddress();
  }, []);

  const parseGeoZonesMapFile = async () => {
    const url = designSummaryData.geozone_file?.[0]?.url;
    if (!url) {
      alert("Geozones map file not found. Please contact support.");
      return;
    }

    const mapFile = await fetch(url);
    const mapAsJson = await mapFile.json();
    setGeoZonesMapAsJson(mapAsJson);
  };

  const fetchZone = async () => {
    setLoadingZone(true);
    setLoadingId(true);

    try {
      const result = await getUSDAZone(zip);
      setUsdaZone(result.replace(/[a-zA-Z]/g, ""));
      setLoadingUsdaZone(false);
    } catch (err) {
      setError("unable to fetch USDA Zone");
      setLoadingUsdaZone(false);
      setLoadingUsdaZoneId(false);
    }
  };

  const { data: designSummaryData } = useQuery(DESIGNER_SUMMARY_DATA_QUERY, {
    variables: {
      archived: false,
      assignmentId,
      regionName: projectState,
    },
    fetchPolicy: "network-only",
    onCompleted: parseGeoZonesMapFile,
    onError: (err) => alert(err),
  });

  const fetchZones = async () => {
    // lat/lng are always available, however, in the transition
    // process from address to lat/lng on the backend, we need to still
    // use both in order to prevent an unnecessary outage.
    const formattedAddress = !!address
      ? `${address?.street}, ${address?.city}, ${address?.state}, ${address?.zip}`
      : `${latLng?.lat}, ${latLng?.lng}`;
    const cachedMapFileUrl = window.localStorage.getItem(
      "yz-cached-geozones-map-file-url"
    );
    const stringifiedMapFileUrl = JSON.stringify(designSummaryData);
    if (designSummaryData !== cachedMapFileUrl) {
      window.localStorage.removeItem(formattedAddress);
      window.localStorage.setItem(
        "yz-cached-geozones-map-file-url",
        JSON.stringify(stringifiedMapFileUrl)
      );
    }

    const cachedZones = window.localStorage.getItem(formattedAddress);

    if (cachedZones) {
      setZones(JSON.parse(cachedZones));
      setLoadingZone(false);
      return;
    }
    try {
      const res = await getGeoZonesFuncRef.current({
        address: formattedAddress,
        lat: latLng?.lat,
        lng: latLng?.lng,
        geozones: geoZonesMapAsJson,
      });
      if (res.data["zones"] !== null) {
        window.localStorage.setItem(
          `${formattedAddress}`,
          JSON.stringify(res.data.zones)
        );
      } else {
        setLoadingId(false);
      }
      setZones(res.data.zones);
      setLoadingZone(false);
    } catch (err) {
      setError("unable to fetch GEO zone");
      setLoadingZone(false);
      setLoadingId(false);
      // handle fail
    }
  };

  React.useEffect(() => {
    if (!designSummaryData?.plant_adapted_habitat?.length) return;
    if (zones === null) {
      return;
    }
    let matches = [];
    for (let each of designSummaryData.plant_adapted_habitat) {
      if (zones.find((_zone: any) => _zone === each.name)) {
        matches.push({
          id: each.id,
          name: each.name,
        });
      }
    }
    setUserGeoZones(matches);
    setLoadingId(false);
  }, [designSummaryData, zones]);

  React.useEffect(() => {
    if (geoZonesMapAsJson && (address || latLng)) {
      fetchZones();
    }

    // edge case: client does not have address or latlng for whatever reason
    const addressNotFound = address === null;
    if (addressNotFound && !latLng) {
      setError("unable to fetch GEO zone");
      setLoadingZone(false);
      setLoadingId(false);
    }
  }, [address, geoZonesMapAsJson]);

  React.useEffect(() => {
    // Write the geoZone id to the user's profile the first time it's calculated.
    // We're storing this value for analytics only — we'll continue calculating the geozone dynamically
    // each time useGetGeoZones is called for the time being.
    if (profile.geoZoneId) return;
    if (!userGeoZones) return;

    const geoZoneId = userGeoZones[0].id;
    firebaseClient.saveGeoZoneIdToProfile(profile, geoZoneId);
  }, [userGeoZones]);

  React.useEffect(() => {
    if (!zip) return;
    fetchZone();
  }, [zip]);

  React.useEffect(() => {
    if (!designSummaryData?.plant_usda_zone?.length) return;
    if (!usdaZone) return;
    for (let each of designSummaryData.plant_usda_zone) {
      if (each.name === usdaZone) {
        setUsdaZoneId(each.id);
        break;
      }
    }
    setLoadingUsdaZoneId(false);
  }, [designSummaryData, usdaZone]);

  React.useEffect(() => {
    if (!designSummaryData?.material_type) return;
    for (let each of designSummaryData.material_type) {
      if (each.name === "Exterior Design") {
        setExteriorDesignId(each.id);
        break;
      }
    }
    setLoadingExteriorDesign(false);
  }, [designSummaryData]);

  React.useEffect(() => {
    if (designSummaryData?.assignment_by_pk) {
      setMetadata(designSummaryData.assignment_by_pk);
    }
  }, [designSummaryData]);

  React.useEffect(() => {
    if (designSummaryData?.product_region?.[0]) {
      setRegion(designSummaryData?.product_region?.[0]);
    }
  }, [designSummaryData]);

  return {
    error: error,
    loading:
      loadingZone ||
      loadingId ||
      loadingUsdaZone ||
      loadingUsdaZoneId ||
      loadingExteriorDesign,
    geoZones: userGeoZones,
    usdaZone: {
      usdaZone,
      usdaZoneError: error,
      loadingUsdaZone: loadingZone || loadingId,
      usdaZoneId,
    },
    exteriorDesignInfo: {
      id: exteriorDesignId,
      isExteriorDesign: getIsExteriorPackage(profile.package),
    },
    metadata,
    region,
  } as {
    error: string | null;
    loading: boolean;
    geoZones: { id: string; name: string }[] | null;
    usdaZone: {
      usdaZone: string | null;
      usdaZoneError: string | null;
      loadingUsdaZone: boolean;
      usdaZoneId: string | null;
    };
    exteriorDesignInfo: {
      id: string | undefined;
      isExteriorDesign: boolean;
    };
    metadata: any;
    region: { id: string; name: string } | null;
  };
}
