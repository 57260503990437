import * as React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Revision } from "@yardzen-inc/models";
import { green, red, yellow } from "@material-ui/core/colors";
import DesignPhase, { getPhase } from "./DesignPhase";

interface Props {
  revisions: Revision[];
  onSelect: (r: Revision) => void;
}

const useStyles = makeStyles({
  li: {
    pointerEvents: "all",
    cursor: "pointer",
    transition: "background-color 150ms",
    "&:hover": {
      backgroundColor: "#EEEEEE",
    },
  },
});

export const List = ({ revisions, onSelect }: Props) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" width="250px" mr={1}>
      {makeListItems()}
    </Box>
  );

  function makeListItems(): React.ReactNode[] {
    return revisions.map((r, i) => {
      const phase = getPhase(r);
      const color = getColor(phase);
      return (
        <Box
          key={`rli-${r.id}`}
          border="solid 1px gray"
          bgcolor={color}
          onClick={() => onSelect(r)}
          justifyContent="space-between"
          className={classes.li}
          p={1}
        >
          <Typography variant="body1">{`Version ${i + 1}`}</Typography>
          <Typography variant="caption"></Typography>
        </Box>
      );
    });
  }

  function getColor(phase: DesignPhase): string {
    if (phase === "clientReady") {
      return green[100];
    }
    if (phase === "qa") {
      return red[100];
    }
    if (phase === "upload") {
      return yellow[100];
    }

    throw new Error("phase is not a Design PHas3");
  }
};

export default List;
