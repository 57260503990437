import React, { FC, useMemo } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { Button } from "@material-ui/core";

export interface QaListPaginationBarProps extends BoxProps {
  offset: number;
  stepSize: number;
  listSize: number;
  onNext?: () => any;
  onPrevious?: () => any;
}

const QaListPaginationBar: FC<QaListPaginationBarProps> = ({
  offset,
  stepSize,
  listSize,
  onNext,
  onPrevious,
  ...containerProps
}) => {
  const text = useMemo(
    () =>
      `${offset * stepSize + 1 || 1} - ${Math.min(
        offset * stepSize + stepSize || stepSize,
        listSize
      )} / ${listSize}`,
    [offset, stepSize, listSize]
  );

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      {...containerProps}
    >
      <Box p={1}>
        <Button variant="text" disabled={!onPrevious} onClick={onPrevious}>
          Previous
        </Button>
      </Box>
      <Box p={1}>{text}</Box>
      <Box p={1}>
        <Button variant="text" disabled={!onNext} onClick={onNext}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export { QaListPaginationBar };
export default QaListPaginationBar;
