import React from "react";
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Agent,
  CorrectionType,
  DesignAssignmentStatuses,
  Revision,
} from "@yardzen-inc/models";
import { YZButton } from "@yardzen-inc/react-common";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import {
  DesignAssignmentWizardState,
  DSATReviewState,
} from "./DesignAssignmentCreationWizard";
import { Check } from "@material-ui/icons";

interface Props {
  wizardState: DesignAssignmentWizardState;
  setWizardState: React.Dispatch<
    React.SetStateAction<DesignAssignmentWizardState>
  >;
  revisions?: Revision[] | null;
  handleSubmitButtonOnClick: (paidUnpaidOptions: boolean) => void;
  correctionFor: CorrectionType | null | undefined;
  designAssignmentStatus: DesignAssignmentStatuses;
  dsatReviewState: React.MutableRefObject<DSATReviewState | undefined>;
  designer: Agent | null;
}

enum RevisionType {
  Correction,
  V1,
  V2,
  ExtraRevision,
}
interface RevisionValue {
  type: RevisionType;
  pageTitle: string;
  pageDescription: string;
  paidUnpaidOptions: boolean;
}

const SelectRevisionStep: React.FC<Props> = (props: Props) => {
  const {
    revisions,
    wizardState,
    setWizardState,
    handleSubmitButtonOnClick,
    correctionFor,
    designAssignmentStatus,
    designer,
    dsatReviewState,
  } = props;

  const revisionValues = [
    {
      type: RevisionType.Correction,
      pageTitle: `Correction Assignment for: ${correctionFor}`,
      pageDescription:
        "Create a minor correction assignment for the projects designer. This type of assignment is for minor (unpaid) corrections to a design(s) delivered for this project",
      paidUnpaidOptions: true,
    },
    {
      type: RevisionType.V1,
      pageTitle: "V1 Assignment:",
      pageDescription: "",
      paidUnpaidOptions: false,
    },
    {
      type: RevisionType.V2,
      pageTitle: "V2 Assignment:",
      pageDescription:
        'This will (hopefully) be the final major version of this design. You may also create a minor "correction" assignment from the previous page if that\'s what you intended to do.',
      paidUnpaidOptions: false,
    },
    {
      type: RevisionType.ExtraRevision,
      pageTitle: "Extra Revision:",
      pageDescription:
        'This intended to start an assignment to create a additional new major version of the design. You may also issue a "correction" assignment for minor updates on the previous page.',
      paidUnpaidOptions: true,
    },
  ];

  const getRevisionType = (): RevisionType => {
    if (correctionFor) {
      return RevisionType.Correction;
    } else if (designAssignmentStatus === null) {
      return RevisionType.V1;
    } else if (designAssignmentStatus === "postV1Hold") {
      return RevisionType.V2;
    } else {
      return RevisionType.ExtraRevision;
    }
  };

  const selectedRevisionType: RevisionValue = revisionValues.find(
    (r) => r.type === getRevisionType()
  ) as RevisionValue;

  const submitButtonText =
    dsatReviewState.current === undefined
      ? "Loading..."
      : dsatReviewState.current === DSATReviewState.NotReviewed
      ? "Review DSAT"
      : selectedRevisionType.paidUnpaidOptions
      ? "Next "
      : "Create";

  const renderMenuItems = (): React.ReactNode[] => {
    // reverse the revisions so they appear in order of creation descending, with the most recent revision first.
    return [...revisions!].reverse().map((r, i) => {
      return (
        <MenuItem value={r.id} key={"mi-" + r.id}>
          {r.title || `Revision ${revisions!.length - i}`}
        </MenuItem>
      );
    });
  };

  const renderDueDateInput = (): React.ReactNode => {
    return (
      <Box>
        <Box py={1}>
          <FormControlLabel
            label="Manually set due date"
            control={
              <Checkbox
                checked={wizardState.manuallyAssignDate}
                onChange={() =>
                  setWizardState({
                    ...wizardState,
                    manuallyAssignDate: !wizardState.manuallyAssignDate,
                  })
                }
              />
            }
          />
        </Box>
        {wizardState.manuallyAssignDate && (
          <TextField
            type="date"
            required
            fullWidth
            helperText="Due Date"
            value={wizardState.dueDate}
            onChange={({ currentTarget: { value } }) =>
              setWizardState({ ...wizardState, dueDate: value })
            }
          />
        )}
      </Box>
    );
  };

  const renderRevisionSelect = (): React.ReactNode | null => {
    if (!revisions || !revisions.length) {
      return null;
    }

    return (
      <Box width="100%" display="flex" flexDirection="column">
        <Typography variant="body1">
          (preferred) Copy assets from a previous revision?
        </Typography>
        <Select
          fullWidth
          value={wizardState.revisionToCopy}
          onChange={({ target: { value } }) => {
            setWizardState({
              ...wizardState,
              revisionToCopy: value as string,
            });
          }}
        >
          {renderMenuItems()}
          <MenuItem value="">No</MenuItem>
        </Select>
      </Box>
    );
  };

  const renderConfirmCreateControls = (): React.ReactNode => {
    return (
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Box pr={1} flexGrow={1}>
          <Box flexGrow={1}>{renderDueDateInput()}</Box>
          <FormControlLabel
            label="I'm sure I want to do this"
            disabled={wizardState.loading}
            control={
              <Checkbox
                disabled={wizardState.loading}
                checked={wizardState.confirm}
                onChange={() =>
                  setWizardState({
                    ...wizardState,
                    confirm: !wizardState.confirm,
                  })
                }
              />
            }
          />
        </Box>
        <Box>
          {dsatReviewState.current === DSATReviewState.Reviewed && (
            <YZButton
              variant="outlined"
              style={{ height: "fit-content", marginRight: "1rem" }}
              onClick={() =>
                setWizardState({ ...wizardState, dsatWizardOpen: true })
              }
            >
              DSAT
            </YZButton>
          )}
          <YZButton
            disabled={
              wizardState.loading ||
              dsatReviewState.current === undefined ||
              (dsatReviewState.current !== DSATReviewState.NotReviewed &&
                !wizardState.confirm)
            }
            variant="contained"
            style={{ height: "fit-content" }}
            color="primary"
            onClick={() =>
              handleSubmitButtonOnClick(selectedRevisionType.paidUnpaidOptions)
            }
          >
            {submitButtonText}
            {selectedRevisionType.paidUnpaidOptions && <ArrowRightAltIcon />}
          </YZButton>
        </Box>
      </Box>
    );
  };

  function renderDesignNotesTextfield(): React.ReactNode {
    return (
      <>
        <Box display="flex" flexDirection="row" alignItems="center">
          <YZButton
            onClick={() =>
              setWizardState({
                ...wizardState,
                designNoteDrawerOpen: true,
              })
            }
            variant="contained"
            color="primary"
          >
            Add notes for designer
          </YZButton>
          <Box pl={2}>
            {wizardState.designNoteContent && (
              <Typography>
                Note is {wizardState.designNoteContent.length} characters long
              </Typography>
            )}
          </Box>
        </Box>
        <Drawer
          open={wizardState.designNoteDrawerOpen}
          onClose={() => null}
          variant="temporary"
          anchor="bottom"
        >
          <Box
            width="100%"
            display="flex"
            minHeight="40vh"
            flexDirection="column"
            p={1}
          >
            <Box width="100%" display="flex" flexDirection="row-reverse">
              <IconButton
                onClick={() =>
                  setWizardState({
                    ...wizardState,
                    designNoteDrawerOpen: false,
                  })
                }
              >
                <Check></Check>
              </IconButton>
            </Box>
            <TextField
              variant="outlined"
              value={wizardState.designNoteContent}
              onChange={({ currentTarget: { value } }) =>
                setWizardState({
                  ...wizardState,
                  designNoteContent: value,
                })
              }
              multiline
              autoFocus
              fullWidth
              rows={12}
            ></TextField>
          </Box>
        </Drawer>
      </>
    );
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {selectedRevisionType.pageTitle}
      </Typography>
      <Typography
        variant="body1"
        style={{ height: "fit-content" }}
        gutterBottom
      >
        {selectedRevisionType.pageDescription}
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        {!designer && (
          <>
            <Typography variant="body1" style={{ height: "fit-content" }}>
              Would you like this assignment to be automatically assigned?
            </Typography>
            <Box pl={2}>
              <FormControlLabel
                label="Yes!"
                disabled={wizardState.loading}
                control={
                  <Checkbox
                    disabled={wizardState.loading}
                    checked={wizardState.listed}
                    onChange={() =>
                      setWizardState({
                        ...wizardState,
                        listed: !wizardState.listed,
                      })
                    }
                  />
                }
              />
            </Box>
          </>
        )}
      </Box>
      <Typography
        variant="body1"
        style={{ height: "fit-content" }}
        gutterBottom
      >
        <em>All new assignments will automatically create a new revision</em>
      </Typography>
      {renderRevisionSelect()}
      <Box width="100%" p={1}>
        <Divider />
      </Box>
      {renderConfirmCreateControls()}
      <Box py={1}>{renderDesignNotesTextfield()}</Box>
    </>
  );
};

export { SelectRevisionStep };
