import * as React from "react";
import { Profile } from "@yardzen-inc/models";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";

interface Props {
  clientRecord: Profile;
}

const Elements = (props: Props) => {
  const clientElements = props.clientRecord["elements"] || {};
  const elements: Array<string> = Object.keys(clientElements).filter((key) => {
    if (props.clientRecord.elements) {
      // @ts-ignore
      return props.clientRecord.elements[key] === true;
    }
    return false;
  });

  return (
    <List>
      {!elements.length && (
        <Typography paragraph>
          Homeowner has not selected any elements
        </Typography>
      )}
      {!!elements.length &&
        elements.map((element) => (
          <ListItem>
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            <ListItemText>{element}</ListItemText>
          </ListItem>
        ))}
    </List>
  );
};

export default Elements;
