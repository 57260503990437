import { useEffect } from "react";
import firebase from "firebase/compat/app";

export interface UseCleanOnEvnChange {
  (): void;
}

const keys = ["NODE_ENV", "REACT_APP_APP_ID", "REACT_APP_ENV"];

/**
 * useCleanOnEnvChange will clear browser data if a change in critical env configuration
 * is detected.
 */
const useCleanOnEnvChange: UseCleanOnEvnChange = () => {
  useEffect(onMount, []);
};

function onMount() {
  for (const key of keys) {
    const env = process.env[key];
    const previousEnv = localStorage.getItem(key);

    if (!env) {
      return;
    } else if (!previousEnv) {
      localStorage.setItem(key, env);
    } else if (env !== previousEnv) {
      clean();
    }
  }
}

async function clean() {
  try {
    await firebase.auth().signOut();
  } catch (error) {
    console.error(error);
  }

  try {
    // ts thinks .databases doesnt exist, which is strange
    const dbs = await (indexedDB as any).databases();

    for (let db of dbs) {
      if (db.name) {
        indexedDB.deleteDatabase(db.name);
      }
    }
  } catch (error) {
    console.error(error);
  }

  localStorage.clear();
  sessionStorage.clear();
  window.location.reload(); // reload needed to get firestore to reconstruct it's idb stuff
}

export { useCleanOnEnvChange };
