import React, { FC, ReactNode, useState } from "react";
import { AgentGroup } from "@yardzen-inc/util";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Box, CircularProgress } from "@material-ui/core";
import AgentGroupListItem from "./AgentGroupListItem";
import DeleteAgentGroupModal from "./DeleteAgentGroupModal";
import { Agent } from "@yardzen-inc/models";
import useAgentGroups from "./useAgentGroups";

export interface AgentGroupListProps {}

const AgentGroupList: FC<AgentGroupListProps> = (props) => {
  const [groupToDelete, setGroupToDelete] = useState<
    (AgentGroup & { id: string }) | null
  >(null);
  const groups = useAgentGroups();

  if (!groups) {
    return <CircularProgress />;
  }

  return (
    <>
      {groupToDelete && (
        <DeleteAgentGroupModal
          open
          onClose={() => setGroupToDelete(null)}
          onDelete={deleteGroup}
          groupToDelete={groupToDelete}
          groups={groups}
        />
      )}
      <Box display="flex" flexDirection="column" width="100%">
        {renderGroups()}
      </Box>
    </>
  );
  function renderGroups(): ReactNode {
    if (!groups) {
      return null;
    }

    return groups.map((group) => {
      return (
        <AgentGroupListItem
          {...group}
          key={"ag-" + group.id}
          onDelete={(id) =>
            setGroupToDelete(groups.find((g) => g.id === id) ?? null)
          }
          onToggleActive={toggleGroupActive}
        />
      );
    });
  }

  async function deleteGroup(id: string, replacementGroupId: string) {
    let agentSnap: firebase.firestore.QuerySnapshot;

    try {
      agentSnap = await firebase
        .firestore()
        .collection("agents")
        .where("groupId" as keyof Agent, "==", "id")
        .get();
    } catch (error) {
      error.message = error.message + " Failed to pul agents with group " + id;
      throw error;
    }

    const batch = firebase.firestore().batch();

    try {
      for (const agentDoc of agentSnap.docs) {
        batch.update(agentDoc.ref, { groupId: replacementGroupId });
      }

      batch.delete(firebase.firestore().collection("agent-groups").doc(id));

      await batch.commit();
    } catch (error) {
      error.message =
        error.message +
        " Failed to commit batch agent update + agent group delete";

      throw error;
    }
  }

  async function toggleGroupActive(id: string) {
    if (!confirm(`are you sure you would like to enable/disable this group?`)) {
      return;
    }

    await firebase.firestore().runTransaction(async (tx) => {
      const group = await tx.get(
        firebase.firestore().collection("agent-groups").doc(id)
      );

      if (!group.exists) {
        throw new Error(`Agent group with id ${id} does not exist`);
      }

      const { active: currentActiveState } = group.data() as AgentGroup;

      tx.update(group.ref, {
        active: !currentActiveState,
      } as Partial<AgentGroup>);
    });
  }
};

export { AgentGroupList };
export default AgentGroupList;
