import React, { useEffect, useState } from "react";
import {
  Dialog,
  Typography,
  DialogContent,
  IconButton,
  makeStyles,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Close from "@material-ui/icons/Close";
import { Agent } from "@yardzen-inc/models";
import GenericSnackBar from "../../Components/GenericSnackBar";
import { YZButton } from "@yardzen-inc/react-common";
import { setAgentAccountStatus } from "./apiCalls";
import { AgentPods } from "./AgentPods/types";
import { getAllDesignerPodsData } from "./AgentPods/apiCalls";

const useStyles = makeStyles({
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  textFieldContainer: {
    minHeight: 50,
    marginTop: 20,
    marginBottom: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  formError: {
    marginTop: 20,
  },
});

interface Props {
  onClose: () => void;
  agent: Agent;
}

const ConfirmAgentAccountStatusSet: React.FC<Props> = ({
  agent,
  onClose,
}: Props) => {
  const classes = useStyles();
  const [working, setWorking] = React.useState<boolean>(false);
  const [removed, setRemoved] = React.useState<boolean>(
    agent.disabled || false
  );
  const [error, setError] = React.useState<any>(null);
  const [selectedPod, setSelectedPod] = React.useState<any>("");
  const [pods, setPods] = React.useState<AgentPods[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string | null>(null);

  useEffect(() => {
    if (agent.type === "design") {
      setLoading(true);
      const fetchData = async () => {
        try {
          let response = await getAllDesignerPodsData(true);
          setPods(response);
        } catch (err) {
          console.log(err);
        }
        setLoading(false);
      };

      fetchData();
    }
  }, []);

  return (
    <>
      <GenericSnackBar
        onClose={() => setError(null)}
        variant="error"
        message={error}
        in={!!error}
      />
      <Dialog open onClose={onClose}>
        {working && <LinearProgress variant="indeterminate" />}
        <CloseButton />
        <DialogTitle id="form-dialog-title">Are You Sure?</DialogTitle>
        <DialogContent>
          {!removed ? (
            <Typography variant="body1">
              This Agent will no longer be able to take jobs on the self assign
              page. Are you sure you want to take them out of commission?
            </Typography>
          ) : (
            <>
              <Typography variant="body1">
                This Agent will be able to log in and take jobs from the queue.
              </Typography>
              <Typography variant="body1">
                Are you sure you want to recommission this Agent?
              </Typography>
              {formError && (
                <Alert severity="error" className={classes.formError}>
                  {formError}
                </Alert>
              )}
              {renderSelectPod()}
              {renderLoding()}
            </>
          )}
          <DialogActions>
            <YZButton
              inheritFont
              color="primary"
              fullWidth
              disabled={!!working}
              onClick={() => runOperation(!agent.disabled)}
            >
              {agent.disabled ? "Recommission" : "Decommission"}
            </YZButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );

  // Render loading when the pod is fetching
  function renderLoding() {
    if (loading) {
      return (
        <div className={classes.textFieldContainer}>
          <CircularProgress />
        </div>
      );
    }
    return;
  }

  // Render the dropdown to select team if the agent is a designer
  function renderSelectPod() {
    if (agent.type === "design" && !loading) {
      return (
        <div className={classes.textFieldContainer}>
          <FormControl fullWidth>
            <InputLabel htmlFor="pageLength">Select Team Assignment</InputLabel>
            <Select
              // @ts-ignore
              onChange={handleChange}
              value={selectedPod}
            >
              {pods &&
                pods.map((pod) => {
                  if (pod.id) {
                    return (
                      <MenuItem
                        key={pod.id}
                        value={pod.id ? pod.id : ""}
                        id="newPod"
                      >
                        {pod.name}
                      </MenuItem>
                    );
                  }
                  return;
                })}
            </Select>
          </FormControl>
        </div>
      );
    }
    return;
  }

  function CloseButton() {
    return (
      <IconButton onClick={onClose} className={classes.closeButton}>
        <Close />
      </IconButton>
    );
  }

  function handleChange(event: React.ChangeEvent<{ value: string }>) {
    const pod = event.target.value;
    setSelectedPod(pod);
  }

  async function runOperation(disable: boolean) {
    setWorking(true);
    setFormError(null);
    try {
      if (disable) {
        await setAgentAccountStatus(agent.id, true);
      } else {
        // If a pod isn't selected, set PodID to null
        if (agent.type === "design" && selectedPod === "") {
          setFormError("Please assign agent to pod from DESIGNERS tab");
        } else {
          await setAgentAccountStatus(agent.id, false, selectedPod);
          setRemoved(disable ? true : false);
        }
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setWorking(false);
      onClose();
    }
  }
};

export { ConfirmAgentAccountStatusSet };
export default ConfirmAgentAccountStatusSet;
