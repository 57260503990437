import { Location as RRLocation } from "history";

// extracts and parses a number from a property in the url's search parameter if exists
function getNumberFromQueryStringProperty(
  key: string,
  location?: Location | RRLocation
): number | null {
  const search = new window.URLSearchParams(
    (location ?? window.location).search
  );

  const value = search.get(key);

  if (value === null) {
    return null;
  }

  const parsedOffset = parseInt(value, 10);

  return isNaN(parsedOffset) ? 0 : parsedOffset;
}

export { getNumberFromQueryStringProperty };
export default getNumberFromQueryStringProperty;
