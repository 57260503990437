import React from "react";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  TableHead,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { ApolloError } from "@yardzen-inc/graphql";
import AssetTableHeader from "./AssetTableHeader";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import {
  formatNumToUSD,
  YZButton,
  YZTypography,
} from "@yardzen-inc/react-common";
import {
  AssetLibraryRowType,
  AssetLibraryTableColumn,
  ModelFiletype,
} from "../../../Interfaces";
import GetAppIcon from "@material-ui/icons/GetApp";
import LinearProgress from "@material-ui/core/LinearProgress";
import HideShowColumnsMenu from "./HideShowColumnsMenu";
import TableCellWithStyles from "../Shared/AssetTableCellWithStyles";
import debounce from "lodash/debounce";
import { ArrowDownward } from "@material-ui/icons";
import { ModelFileDownloadButton } from "../../../Components/ModelFileDownloadButton";
import cleanedDownloadableFileName from "./CleanedDownloadableFileName";

export interface AssetTableProps {
  hideArchive?: boolean;
  resultsCount: number;
  tableContainerRef: any;
  tableColumns: AssetLibraryTableColumn[];
  hiddenColumns: { [id: string]: boolean };
  onToggleColumnVisible: (id: string) => void;
  data: { [key: string]: any } | null;
  searchLabel?: string;
  title: string;
  initialTableCells: any;
  contentHeight: string;
  networkStatus: number;
  rowComponent: React.FC<{
    onBlur?: (id: string, quantity: number) => void;
    isEven: boolean;
    refetch: () => void;
    row: AssetLibraryRowType[][];
  }>; // jsx component
  listResultName: string;
  requestRefetch: () => void;
  onScroll: () => void;
  addNewAssetComponent: React.FC<{}>;
  error: ApolloError | undefined;
  requestMoreResults: () => void;
  filters: React.FC<{}>;
  onSearch: (term: string) => void;
  showArchived: boolean;
  toggleArchived: () => void;
  onSort: (v: number, queryName: string) => void;
  showLoadMoreButton: boolean;
  activeOrderBy?: { column: string; direction: string };
  onBlur?: (id: string, quantity: number) => void;
  onAddNew?: () => void;
  addNewButtonLabel?: string | null;
  search: string | null;
  fixedHeader?: boolean;
  onReorder: (oldIndex: number, newIndex: number) => void;
  fixedTop?: number;
  pmView?: boolean;
  tableHeaderMessage?: string;
}

const getSortDirection = (
  headerName: string,
  activeSortHeader?: string,
  activeSortDirection?: string
) => {
  if (headerName !== activeSortHeader) {
    return 0;
  }
  if (activeSortDirection === "asc") {
    return 1;
  }
  if (activeSortDirection === "desc") {
    return -1;
  }
  return 0;
};

const SortableHeader = SortableElement(
  ({ column, index, activeOrderBy, onResize, size, hidden }: any) => {
    return (
      <AssetTableHeader
        activeOrderBy={activeOrderBy}
        index={index}
        queryName={column.queryName}
        title={column.name}
        sortDirection={column.sortDirection}
        sortable={column.sortable}
        onResize={onResize}
        size={size}
        hidden={hidden}
      />
    );
  }
);

const SortableHeaderList = SortableContainer(
  ({
    tableColumns,
    hiddenColumns,
    activeOrderBy,
    onResize,
    resizedColumns,
  }: any) => {
    return (
      <div style={{ display: "contents" }}>
        {tableColumns.map(
          (column: { name: string; id: string }, index: number) => (
            <SortableHeader
              key={`item-${column.name}`}
              index={index}
              column={column}
              activeOrderBy={activeOrderBy}
              size={resizedColumns[column.id] || 0}
              onResize={(v: number) => onResize(v, column.id)}
              hidden={hiddenColumns[column.id]}
            />
          )
        )}
      </div>
    );
  }
);

function AssetTable(props: AssetTableProps) {
  const classes = useStyles({ pmView: props.pmView });
  const theme = useTheme();

  const { rowComponent } = props;

  const [modifiedColumns, setModifiedColumns] = React.useState(
    props.tableColumns.map((column) => {
      const sortDirection = getSortDirection(
        column.queryName || "",
        props.activeOrderBy?.column,
        props.activeOrderBy?.direction
      );

      return {
        ...column,
        sortDirection,
      };
    })
  );

  React.useEffect(() => {
    // TODO: add newRelic to this repository

    if (props.error) {
      console.error(props.error);
    }
  }, [props.error]);

  React.useEffect(() => {
    setModifiedColumns(() =>
      modifiedColumns.map((column) => {
        const sortDirection = getSortDirection(
          column.queryName || "",
          props.activeOrderBy?.column,
          props.activeOrderBy?.direction
        );
        return {
          ...column,
          sortDirection,
        };
      })
    );
  }, [props.activeOrderBy]);

  const [sortedColumns, setSortedColumns] = React.useState<React.ReactNode[][]>(
    []
  );

  const inStockText = (in_stock: any) => {
    if (typeof in_stock === "boolean") {
      return in_stock ? "Yes" : "No";
    }
    return "--";
  };

  const SlotTwoData = (data: Object, index: number) => {
    if (index !== 0) {
      return null;
    }
    return data;
  };

  // full list of statuses here
  // https://github.com/apollographql/apollo-client/blob/main/src/core/networkStatus.ts
  const isLoading = (): boolean => {
    return (
      props.networkStatus === 1 ||
      props.networkStatus === 3 ||
      props.networkStatus === 4
    );
  };

  const [searchText, setSearchText] = React.useState<string | null>(
    props.search
  );

  React.useEffect(() => {
    // this useEffect is in charge of updating sortedColumns.
    // if (!sortedColumns) return;
    if (!sortedColumns.length) return;
    // @ts-ignore
    if (!props.data?.[props.listResultName]) return;
    // @ts-ignore
    if (sortedColumns.length !== props.data[props.listResultName].length) {
      setSortedColumns(
        // @ts-ignore
        props.data?.[props.listResultName].map((row: any, index: number) =>
          props.initialTableCells(row)
        )
      );
      return;
    }
    setSortedColumns(() =>
      sortedColumns.map((column, index) => {
        // @ts-ignore
        return column.map((_row: { props: { id: string } }, _index: number) => {
          // @ts-ignore
          const row = _row[0];
          if (!row?.props.id) {
            return [null];
          }
          if (row.props.id.includes("_simplejoin")) {
            const query = row.props.id.split("_")[0];
            return [
              <TableCellWithStyles
                hidden={props.hiddenColumns?.[row.props.id]}
                id={row.props.id}
                key={row.props.id}
                align="right"
              >
                {
                  // @ts-ignore
                  props.data?.[props.listResultName][index][query]?.name
                }
              </TableCellWithStyles>,
              // @ts-ignore
              SlotTwoData(props.data?.[props.listResultName][index], _index),
            ];
          }
          if (row.props.id.includes("_links")) {
            // handles links
            const link =
              // @ts-ignore
              props.data?.[props.listResultName][index][row.props.id];
            const linkString = Array.isArray(link) ? link[0] : link;
            if (!linkString) {
              return [
                <TableCellWithStyles
                  hidden={props.hiddenColumns?.[row.props.id]}
                  id={row.props.id}
                  key={row.props.id}
                  align="right"
                >
                  &nbsp;
                </TableCellWithStyles>,
                // @ts-ignore
                SlotTwoData(props.data?.[props.listResultName][index], _index),
              ];
            }
            let key: string | undefined;
            key = Object.keys(linkString).find(
              (key) => !key.includes("__typename")
            );
            if (!key) {
              return [
                <TableCell />,
                // @ts-ignore
                SlotTwoData(props.data?.[props.listResultName][index], _index),
              ];
            }
            return [
              <TableCellWithStyles
                hidden={props.hiddenColumns?.[row.props.id]}
                id={row.props.id}
                key={row.props.id}
                align="right"
              >
                {
                  // @ts-ignore
                  props.data?.[props.listResultName][index][row.props.id]
                    // @ts-ignore
                    ?.map((style: { name?: string }) => style[key || 0]?.name)
                    .join(", ")
                }
              </TableCellWithStyles>,
              // @ts-ignore
              SlotTwoData(props.data?.[props.listResultName][index], _index),
            ];
          }
          if (row.props.id.includes("icon_uri")) {
            return [
              <TableCellWithStyles
                hidden={props.hiddenColumns?.[row.props.id]}
                id={row.props.id}
                key={row.props.id}
                align="right"
              >
                <img
                  // @ts-ignore
                  className={classes.iconUri}
                  // @ts-ignore
                  src={props.data?.[props.listResultName][index][row.props.id]}
                />
              </TableCellWithStyles>,
              // @ts-ignore
              SlotTwoData(props.data?.[props.listResultName][index], _index),
            ];
          }
          if (row.props.id.includes("in_stock")) {
            return [
              <TableCellWithStyles
                hidden={props.hiddenColumns?.[row.props.id]}
                style={{
                  color:
                    // @ts-ignore
                    props.data?.[props.listResultName][index][row.props.id] ===
                    false
                      ? "red"
                      : "",
                }}
                id={row.props.id}
                key={row.props.id}
                align="right"
              >
                {inStockText(
                  // @ts-ignore
                  props.data?.[props.listResultName][index][row.props.id]
                )}
              </TableCellWithStyles>,
              // @ts-ignore
              SlotTwoData(props.data?.[props.listResultName][index], _index),
            ];
          }
          if (
            row.props.id.includes("sketchup_file_link") ||
            row.props.id.includes("lumion_file_link") ||
            row.props.id.includes("proxy_file_link") ||
            row.props.id.includes("vray_file_link")
          ) {
            const filetypes: { [key: string]: ModelFiletype } = {
              sketchup_file_link: ModelFiletype.sketchup,
              lumion_file_link: ModelFiletype.lumion,
              proxy_file_link: ModelFiletype.proxy,
              vray_file_link: ModelFiletype.vray,
            };

            const filetype: ModelFiletype = filetypes[row.props.id];

            const filenameOrUrl =
              props.data?.[props.listResultName][index][row.props.id];

            const downloadableFileName = cleanedDownloadableFileName(
              props.data?.[props.listResultName][index]?.scientific_name ??
                props.data?.[props.listResultName][index]?.name
            );

            return [
              <TableCellWithStyles
                hidden={props.hiddenColumns?.[row.props.id]}
                id={row.props.id}
                key={row.props.id}
                align="right"
              >
                <ModelFileDownloadButton
                  {...{ filenameOrUrl, filetype, downloadableFileName }}
                />
              </TableCellWithStyles>,
              // @ts-ignore
              SlotTwoData(props.data?.[props.listResultName][index], _index),
            ];
          }
          if (row.props.id.includes("file_link")) {
            const _data =
              // @ts-ignore
              props.data?.[props.listResultName][index][row.props.id];
            return [
              <TableCellWithStyles
                hidden={props.hiddenColumns?.[row.props.id]}
                id={row.props.id}
                key={row.props.id}
                align="right"
              >
                {_data && (
                  <a href={_data} download={_data}>
                    <GetAppIcon />
                  </a>
                )}
              </TableCellWithStyles>,
              // @ts-ignore
              SlotTwoData(props.data?.[props.listResultName][index], _index),
            ];
          }

          if (row.props.id.includes("unit_cost")) {
            const useMSRP =
              props.listResultName === "product_base" &&
              !props.tableColumns.some((c) => c.name === "MSRP");

            const cost = useMSRP
              ? parseFloat(
                  // @ts-ignore
                  props.data?.[props.listResultName][index]["msrp"] || 0
                )
              : parseFloat(
                  // @ts-ignore
                  props.data?.[props.listResultName][index]["unit_cost"] || 0
                );

            return [
              <TableCellWithStyles
                hidden={props.hiddenColumns?.[row.props.id]}
                id={row.props.id}
                key={row.props.id}
                align="right"
              >
                {formatNumToUSD(cost)}
              </TableCellWithStyles>,
              // @ts-ignore
              SlotTwoData(props.data?.[props.listResultName][index], _index),
            ];
          }
          if (row.props.id.includes("msrp") || row.props.id === "labor_cost") {
            return [
              <TableCellWithStyles
                hidden={props.hiddenColumns?.[row.props.id]}
                id={row.props.id}
                key={row.props.id}
                align="right"
              >
                {formatNumToUSD(
                  parseFloat(
                    // @ts-ignore
                    props.data?.[props.listResultName][index][row.props.id] || 0
                  )
                )}
              </TableCellWithStyles>,
              // @ts-ignore
              SlotTwoData(props.data?.[props.listResultName][index], _index),
            ];
          }
          if (row.props.id === "installed_cost") {
            const itemCostColumn =
              props.listResultName === "product_base" ? "msrp" : "unit_cost";
            const itemCostData =
              parseFloat(
                props.data?.[props.listResultName][index][itemCostColumn]
              ) || 0;
            const laborCostData =
              parseFloat(
                props.data?.[props.listResultName][index]["labor_cost"]
              ) || 0;
            return [
              <TableCellWithStyles
                hidden={props.hiddenColumns?.[row.props.id]}
                id={row.props.id}
                key={row.props.id}
                align="right"
              >
                {formatNumToUSD(itemCostData + laborCostData)}
              </TableCellWithStyles>,
              // @ts-ignore
              SlotTwoData(props.data?.[props.listResultName][index], _index),
            ];
          }
          if (
            row.props.id === "link" ||
            row.props.id === "affiliate_link" ||
            row.props.id === "additional_info"
          ) {
            return [
              <TableCellWithStyles
                hidden={props.hiddenColumns?.[row.props.id]}
                id={row.props.id}
                key={row.props.id}
                align="right"
              >
                <div className={classes.longLink}>
                  <a
                    href={
                      // @ts-ignore
                      props.data?.[props.listResultName][index][row.props.id]
                    }
                    target="_blank"
                  >
                    {
                      // @ts-ignore
                      props.data?.[props.listResultName][index][row.props.id]
                    }
                  </a>
                </div>
              </TableCellWithStyles>,
              // @ts-ignore
              SlotTwoData(props.data?.[props.listResultName][index], _index),
            ];
          }
          return [
            <TableCellWithStyles
              hidden={props.hiddenColumns?.[row.props.id]}
              id={row.props.id}
              key={row.props.id}
              align="right"
            >
              {
                // @ts-ignore
                props.data?.[props.listResultName][index]?.[row.props.id]
              }
            </TableCellWithStyles>,
            // @ts-ignore
            SlotTwoData(props.data?.[props.listResultName][index], _index),
          ];
        });
      })
    );
    // no prob here
  }, [props.data, props.hiddenColumns]);

  React.useEffect(() => {
    // this useEffect initializes the table cells
    if (sortedColumns.length) return;
    // if (!props.data?.[props.listResultName]?.length) return;
    setSortedColumns(
      // @ts-ignore
      props.data?.[props.listResultName].map((row: any, index: number) =>
        props.initialTableCells(row)
      ) ?? []
    );
  }, [props.data]);

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex === newIndex) {
      // When it's the same index, that means the user just wants
      // to trigger a change of order direction.

      const header = modifiedColumns[newIndex];
      if (!header.sortable) {
        return;
      }
      const targetSort = ((header.sortDirection + 2) % 3) - 1;
      props.onSort(targetSort, header.queryName || "");
      setModifiedColumns(() =>
        modifiedColumns.map((_header) => {
          if (_header.name !== header.name) {
            return {
              ..._header,
              sortDirection: 0,
            };
          }
          return {
            ...header,
            sortDirection: targetSort,
          };
        })
      );
      return;
    }
    if (oldIndex !== newIndex) {
      setModifiedColumns(() => arrayMove(modifiedColumns, oldIndex, newIndex));
      props.onReorder(oldIndex, newIndex);
      const newOrdered = sortedColumns.map((row) => {
        return arrayMove(row, oldIndex, newIndex);
      });
      setSortedColumns(() => newOrdered);
    }
  };

  const [resizedColumns, setResizedColumns] = React.useState({});

  const onResize = (v: number, columnId: string) => {
    setResizedColumns({ ...resizedColumns, [columnId]: v });
  };

  const debounceDelay = 500;
  const debouncedSearch = React.useCallback(
    debounce((term: string) => props.onSearch(term), debounceDelay),
    []
  );

  const onChangeSearchTerm = (e: React.ChangeEvent<any>) => {
    debouncedSearch.cancel();
    setSearchText(e.currentTarget.value);
    debouncedSearch(e.currentTarget.value);
  };

  // we need this memoized copy in order to prevent
  //
  const memoizedContent = React.useMemo(
    () => (
      <TableContainer
        onScroll={props.onScroll}
        ref={props.tableContainerRef}
        className={classes.tableContainer}
        style={{ maxHeight: props.contentHeight }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell />
              <SortableHeaderList
                axis="x"
                useDragHandle
                onSortEnd={onSortEnd}
                activeOrderBy={props.activeOrderBy}
                tableColumns={modifiedColumns}
                hiddenColumns={props.hiddenColumns}
                onResize={onResize}
                resizedColumns={resizedColumns}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedColumns?.map((row: any, index: number) => {
              return (
                <React.Fragment key={row.id ?? `asset-table-row-${index}`}>
                  {React.createElement(rowComponent, {
                    onBlur: props.onBlur,
                    row: row,
                    key: Math.random().toString(),
                    isEven: index % 2 === 0,
                    refetch: props.requestRefetch,
                  })}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
        {props.showLoadMoreButton && (
          <Box p={3}>
            <Button onClick={props.requestMoreResults} variant="text" fullWidth>
              <YZTypography color="textSecondary" variant="body2">
                Load More
              </YZTypography>
              <ArrowDownward style={{ fontSize: 17 }} />
            </Button>
          </Box>
        )}
      </TableContainer>
    ),
    [sortedColumns]
  );

  return (
    <div className={classes.root}>
      <div className={classes.assetTableHeaderContent}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <YZTypography variant="h4" type="serif">
              {props.title}
            </YZTypography>
          </Grid>
          <Grid item md>
            <TextField
              className={classes.searchBar}
              label={props.searchLabel ?? "Search by name"}
              onChange={onChangeSearchTerm}
              variant="outlined"
              size="small"
              value={searchText}
              style={{
                background: searchText
                  ? theme.palette.secondary.light
                  : undefined,
              }}
            />
          </Grid>
          <Grid item>
            <HideShowColumnsMenu
              columns={props.tableColumns}
              hiddenColumns={props.hiddenColumns}
              onToggleColumnVisible={props.onToggleColumnVisible}
            />
          </Grid>
          <Grid item style={{ marginRight: "2rem" }}>
            <YZTypography variant="body2">
              {props.resultsCount} results found
            </YZTypography>
          </Grid>
          {!props.hideArchive && (
            <Grid item>
              <YZButton
                onClick={props.toggleArchived}
                variant="outlined"
                size="small"
              >
                {props.showArchived ? "Hide" : "Show"} Archived
              </YZButton>
            </Grid>
          )}
          {Boolean(props.onAddNew) && (
            <Grid item>
              <YZButton
                onClick={props.onAddNew}
                variant="outlined"
                size="small"
              >
                <YZTypography className={classes.addNewTxt}>
                  {props.addNewButtonLabel}
                </YZTypography>
              </YZButton>
            </Grid>
          )}
        </Grid>
        {props.filters({})}
      </div>
      {isLoading() && (
        <div className={classes.linearProgressRoot}>
          <LinearProgress
            className={classes.linearProgress}
            color="secondary"
          />
        </div>
      )}

      {props.tableHeaderMessage && (
        <Box display="flex" p={1} justifyContent="center">
          <Typography>{props.tableHeaderMessage}</Typography>
        </Box>
      )}
      {!props.error && memoizedContent}
      {props.error && (
        <Typography>Something went wrong. Please contact support.</Typography>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.7rem",
      height: "0.7rem",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "1rem",
    },
  },
  tableContainer: {},
  anchor: {
    // overflowAnchor: "auto",
    // height: "1px",
  },
  root: {},
  accordion: {
    maxWidth: "60rem",
  },
  searchBar: {
    minWidth: "300px",
  },
  itemBtns: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  iconUri: {
    width: "6.25rem",
    height: "6.25rem",
    objectFit: "contain",
  },
  editItems: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },
  editItem: {
    flex: 1,
    marginBottom: theme.spacing(1),
  },
  addNewTxt: {
    padding: "0.1625rem",
  },
  longLink: {
    width: "10rem",
    maxWidth: "10rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  saveProgress: {
    display: "flex",
    alignItems: "center",
  },
  noResultsText: {
    textAlign: "center",
    paddingTop: "0.6rem",
    paddingBottom: "0.6rem",
  },
  linearProgress: {
    height: 7,
  },
  linearProgressRoot: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 9999999,
  },
  assetTableHeaderContent: {
    padding: "0.5rem",
  },
}));

export default AssetTable;
