import {
  Color,
  ColorList,
  ColorResponse,
  ColorResponseList,
} from "@yardzen-inc/colors";
import { useEffect, useMemo, useState } from "react";
import {
  ExteriorDesignClient,
  useExteriorDesignClient,
} from "../contexts/ExteriorDesignContext";
import { ErrorWithHumanMessage } from "../errors/ErrorWithHumanMessage";

export interface ColorResponseListItem {
  color: Color;
  responses: ColorResponse[];
}

export type ColorResponseMap = Map<string, ColorResponseListItem>;

export interface UseSelectedColorsForProjectOptions {
  client?: ExteriorDesignClient;
  orderBy?: [string, "asc" | "dsc"];
  limit?: number;
  offset?: number;
  placement?: string;
}

export interface UseSelectedColorsForProject {
  (projectId?: string, options?: UseSelectedColorsForProjectOptions): [
    ColorResponseMap | null,
    ErrorWithHumanMessage | null
  ];
}

let _colorCache: Map<string, Color> = new Map();

const useSelectedColorsForProject: UseSelectedColorsForProject = (
  projectId,
  options
) => {
  const client = options?.client || useExteriorDesignClient();
  const orderBy = useMemo(formatOrderby, [options?.orderBy]);

  const [colorResponseMap, setColorResponseMap] = useState<Map<
    string,
    ColorResponseListItem
  > | null>(null);

  const [error, setError] = useState<ErrorWithHumanMessage | null>(null);
  useEffect(getColorsAndResponses, [projectId, client]);

  return [colorResponseMap, error];

  function formatOrderby(): string | undefined {
    return options?.orderBy
      ? options.orderBy.map((o) => o.trim()).join(",")
      : void 0;
  }

  function mapColorsToResponsesFromCache(
    responses: ColorResponseList,
    map: Map<string, ColorResponseListItem>
  ) {
    if (!projectId) {
      return;
    }

    const colorItemsThatNeedFetching: Map<string, ColorResponse[]> = new Map();

    for (let response of responses.responses) {
      const cachedColor = _colorCache.get(response.colorId);
      const currentEntry = map.get(response.colorId);

      if (cachedColor) {
        if (currentEntry) {
          currentEntry.responses.push(response);
          map.set(response.colorId, {
            color: cachedColor,
            responses: currentEntry.responses,
          });
        } else {
          map.set(response.colorId, {
            color: cachedColor,
            responses: [response],
          });
        }

        continue;
      }

      const responseList = colorItemsThatNeedFetching.get(response.colorId);

      if (responseList) {
        responseList.push(response);
      } else {
        colorItemsThatNeedFetching.set(response.colorId, [response]);
      }
    }

    return colorItemsThatNeedFetching;
  }

  async function fetchMissingColors(
    missingResponseMap: Map<string, ColorResponse[]>,
    map: Map<string, ColorResponseListItem>
  ) {
    let colorIdsParameter = "";
    missingResponseMap.forEach((_, id) => (colorIdsParameter += `,${id}`));
    colorIdsParameter = colorIdsParameter.substring(1);

    // TODO: figure out why colorBulk isn't returning a ColorList even though it's in spec
    let colors: ColorList;
    try {
      colors = await client.colorBulk.getBulkColors(colorIdsParameter);

      if (
        !(typeof colors.empty === "boolean") ||
        !Array.isArray(colors.colors)
      ) {
        throw new Error(
          "response from getBulkColors is not of type ColorList: " +
            JSON.stringify(colors)
        );
      }
    } catch (error) {
      throw new ErrorWithHumanMessage(
        error,
        "Something went wrong trying to look up colors referenced by client choices, please try again"
      );
    }

    if (colors.empty) {
      throw new ErrorWithHumanMessage(
        "Missing expected colors in colorResponse",
        "Some of the client's chosen colors were not found in the database, please try again"
      );
    }

    for (let color of colors.colors) {
      if (!color.id) {
        continue;
      }

      _colorCache.set(color.id, color);

      map.set(color.id, {
        color,
        responses: missingResponseMap.get(color.id) ?? [],
      });
    }
  }

  function getColorsAndResponses() {
    void (async function () {
      if (!projectId) {
        return;
      }

      let responses!: ColorResponseList;

      try {
        responses = await client.colorResponses.colorResponses(
          projectId,
          orderBy,
          options?.limit,
          options?.offset,
          options?.placement
        );
      } catch (error) {
        return setError(
          new ErrorWithHumanMessage(
            error,
            "Encountered an error trying to find exterior design data for this client, please try again"
          )
        );
      }

      if (responses.empty) {
        return setColorResponseMap(new Map());
      }

      const map = new Map<string, ColorResponseListItem>();

      const colorItemsThatNeedFetching = mapColorsToResponsesFromCache(
        responses,
        map
      );

      if (colorItemsThatNeedFetching?.size) {
        try {
          await fetchMissingColors(colorItemsThatNeedFetching, map);
        } catch (error) {
          return setError(
            error instanceof ErrorWithHumanMessage
              ? error
              : new ErrorWithHumanMessage(
                  error,
                  "Something went wrong trying to look up color data, please try again."
                )
          );
        }
      }

      setColorResponseMap(map);
    })();
  }
};

export { useSelectedColorsForProject };
export default useSelectedColorsForProject;
