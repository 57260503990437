import firebase from "firebase/compat/app";
import {
  Assignment,
  Media,
  onHoldDesignAssignmentStatuses,
  Profile,
  ProjectManagerProperties,
  ProjectProperties,
  Revision,
} from "@yardzen-inc/models";
import { HoldStatus, Project } from "../../Interfaces";
import { Agent } from "../../ConstantValues/AgentConstants";
import moment from "moment";

async function saveGeoZoneIdToProfile(
  profile: Profile,
  geoZoneId: string
): Promise<void> {
  return firebase
    .firestore()
    .collection("profiles")
    .doc(profile.id)
    .update({ geoZoneId });
}

async function getAllDesignerProfiles(): Promise<Profile[]> {
  const query = await firebase
    .firestore()
    .collection("profiles")
    .where("isDesigner", "==", true)
    .orderBy("createdAt", "desc")
    .get();
  const profilePromise = query.docs.map((doc) => Profile.get(doc.id));
  const result = await Promise.all(profilePromise);
  return result;
}

async function getAllAgentUserIds(): Promise<string[]> {
  const query = await firebase.firestore().collection("agents").get();
  const agentPromise = query.docs.map((doc) => doc.data().userId);
  const result = await Promise.all(agentPromise);
  return result;
}

async function setHasuraCustomClaims(
  targetUserId: string,
  role: string
): Promise<firebase.functions.HttpsCallableResult> {
  return firebase.functions().httpsCallable("setHasuraCustomClaims")({
    role,
    targetUserId,
  });
}

async function getUnassignedDesignProjects(): Promise<Project[]> {
  const query = await firebase
    .firestore()
    .collection("projects")
    .where("designerId", "==", null)
    .get();
  const projectsPromise = Project.hydrate(
    query.docs.filter((d) => !!d.data()?.currentDesignAssignmentId)
  );
  return await Promise.all(projectsPromise);
}

async function getProjectsByAgentId(agentId: string): Promise<Project[]> {
  const query = await firebase
    .firestore()
    .collection("projects")
    .where("designerId", "==", agentId)
    .get();
  const projectPromise = query.docs.map((p) =>
    new Project().setProperties({
      ...(p.data() as ProjectProperties),
      id: p.id,
    } as any)
  );
  return await Promise.all(projectPromise);
}

async function getProjectById(id: string): Promise<Project> {
  const doc = await firebase.firestore().collection("projects").doc(id).get();
  return Project.hydrate([doc])[0];
}

async function getAssignmentsByProjectId(id: string): Promise<Assignment[]> {
  const query = await firebase
    .firestore()
    .collectionGroup("assignments")
    .where("projectId", "==", id)
    .get();

  const assignmentPromise = query.docs.map((a) =>
    new Assignment().setProperties({
      ...(a.data() as Assignment),
      id: a.id,
    } as any)
  );
  return await Promise.all(assignmentPromise);
}

async function getProjectByProfileId(
  profileId: string
): Promise<Project | null> {
  console.log(
    `In firebaseClient/getProjectByProfileId. profileId: ${profileId}`
  );
  const query = await firebase
    .firestore()
    .collection("projects")
    .where("profileId", "==", profileId)
    .get();

  const doc = query.docs[0];
  console.log("In firebaseClient/getProjectByProfileId. doc:", doc);
  if (!doc) return null;
  console.log(
    "In firebaseClient/getProjectByProfileId. doc.data():",
    doc.data()
  );
  return { ...query.docs[0]?.data(), id: doc.id } as Project;
}

async function getIncompleteAssignmentsByPMOrderedByDate(
  pmId: string
): Promise<firebase.firestore.Query<firebase.firestore.DocumentData>> {
  return firebase
    .firestore()
    .collectionGroup("assignments")
    .where("assignedTo", "==", pmId)
    .where("completed", "==", false)
    .orderBy("createdAt", "asc");
}

async function getProjectManagerById(
  id: string
): Promise<
  firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
> {
  return await firebase.firestore().collection("projectManagers").doc(id).get();
}

type RevisionBlurb = {
  v1Blurb?: string;
  finalBlurb?: string;
};

async function sendRevisionToClient(
  revision: Revision,
  blurb?: RevisionBlurb,
  mediaToSend?: Media[],
  issuer: string | null = null
): Promise<boolean> {
  if (blurb) {
    Object.keys(blurb).forEach((key: string) => {
      const revKey = key as keyof RevisionBlurb;
      revision[revKey] = blurb[revKey] || null;
    });
  }

  revision.sentToClientBy = issuer;

  if (mediaToSend) {
    revision.deliverables = mediaToSend.map((rec) => rec.id);
    revision.hasBeenSent = true;
    revision.dateSentToClient = (moment as any)().toISOString();

    if (!revision.dateOriginallySentToClient) {
      revision.dateOriginallySentToClient = (moment as any)().toISOString();
    }

    await revision.save();
    await firebase
      .firestore()
      .collection("profiles")
      .doc(revision.profileId)
      .update({
        projectState: "clientReview",
      });
    return true;
  }

  revision.dateSentToClient = (moment as any)().toISOString();
  revision.hasBeenSent = true;
  await revision.save();

  await firebase
    .firestore()
    .collection("profiles")
    .doc(revision.profileId)
    .update({
      projectState: "clientReview",
    });

  return true;
}

async function getProjectManagerByUserId(userId: string) {
  return await firebase
    .firestore()
    .collection("projectManagers")
    .where("userId", "==", userId)
    .get();
}

async function getAgentByUserId(userId: string) {
  return await firebase
    .firestore()
    .collection("agent")
    .where("userId", "==", userId)
    .get();
}

async function getProjectManagerByEmail(email: string) {
  return await firebase
    .firestore()
    .collection("projectManagers")
    .where("email", "==", email)
    .get();
}

async function getProjectManagersList(): Promise<
  (ProjectManagerProperties & { id: string })[]
> {
  const projectSnap = await firebase
    .firestore()
    .collection("projectManagers")
    .get();

  return projectSnap.docs.map((doc) => ({
    ...(doc.data() as ProjectManagerProperties),
    id: doc.id,
  }));
}

async function getDesignBriefByProjectId(
  projectId: string
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  return await firebase
    .firestore()
    .collection("projects")
    .doc(projectId)
    .collection("designBrief")
    .get();
}

function getMediaByUserIdAndTags(
  userId: string,
  tags: string[]
): firebase.firestore.Query {
  return firebase
    .firestore()
    .collection("media")
    .where("userId", "==", userId)
    .where("tag", "in", tags);
}

async function updateLocationsAndSetContractorUser(contractorId: string) {
  return await firebase.functions().httpsCallable("renewContractorInfo")({
    contractorId,
  });
}

async function getProfileById(
  profileId: string
): Promise<firebase.firestore.DocumentData | undefined> {
  return (
    await firebase.firestore().collection("profiles").doc(profileId).get()
  ).data();
}

async function getDesignAssignmentByRevisionId(
  revisionId: string
): Promise<firebase.firestore.DocumentData | undefined | null> {
  const query = await firebase
    .firestore()
    .collectionGroup("designAssignments")
    .where("revisionId", "==", revisionId)
    .get();

  if (query.empty) {
    return null;
  }

  if (query.docs.length > 1) {
    console.error(`revision ${revisionId} has multiple design assignments`);
  }
  return query.docs[0];
}

async function getRevisionsByProfileId(
  profileId: string
): Promise<firebase.firestore.DocumentData[]> {
  const query = await firebase
    .firestore()
    .collection("revisions")
    .where("profileId", "==", profileId)
    .get();
  return query.docs;
}

function getOnHoldProjectsByQAManagerId(
  pmId: string
): firebase.firestore.Query<firebase.firestore.DocumentData> {
  const onHoldStatuses = onHoldDesignAssignmentStatuses.filter(
    (d) => d !== null
  );
  return firebase
    .firestore()
    .collection("projects")
    .where("QAManagerId", "==", pmId)
    .where("designAssignmentStatus", "in", onHoldStatuses);
}

async function updateAgentAuthEmail(userId: string, newEmail: string) {
  return await firebase.functions().httpsCallable("changeAgentAuthEmail")({
    userId,
    newEmail,
  });
}

async function addPLPHoldStatus(holdToAdd: HoldStatus, projectId: string) {
  let newHolds = [holdToAdd];
  const existingHolds = await firebase
    .firestore()
    .collection("postPLPHolds")
    .where("archived", "==", false)
    .where("projectId", "==", projectId)
    .get();

  if (existingHolds.docs.length) {
    const existingHold = existingHolds.docs[0];
    const holdData = existingHold.data();

    if (holdData.holdStatuses.includes(holdToAdd)) {
      return;
    }
    newHolds = holdData.holdStatuses.concat([holdToAdd]);
  }
  return await firebase
    .functions()
    .httpsCallable("putPostPLPHoldStatusesByProjectId")({
    newHolds,
    projectId,
  });
}

async function getActiveStripeProducts() {
  try {
    const res = await fetch(
      `https://us-central1-${process.env.REACT_APP_PROJECTID}.cloudfunctions.net/getStripeProducts`
    );
    const products = await res.json();

    return products.filter((product: any) => product.active);
  } catch (err) {
    console.error(err);
    throw new Error("Failed to retrieve stripe products");
  }
}

async function updateAgentProperties(
  agent: Agent,
  updatedProperties: Record<string, any>
) {
  agent.setProperties({
    ...agent,
    ...updatedProperties,
  });

  return await agent.save();
}

async function updateRevisionIssuedBy(revision: Revision, user: firebase.User) {
  const pmByIdSnap = await getProjectManagerByUserId(user.uid);
  const pmByEmailSnap = !pmByIdSnap.docs.length
    ? await getProjectManagerByEmail(user.email ?? "")
    : undefined;
  const projectManagerId =
    pmByIdSnap?.docs[0]?.id || pmByEmailSnap?.docs[0]?.id || null;
  revision.issuedBy = projectManagerId;
  await revision.save();
}

async function getBuildIntentResponse(
  projectId: string
): Promise<Record<string, any> | null> {
  const snapshot = await firebase
    .firestore()
    .collection("buildIntentResponses")
    .where("projectId", "==", projectId)
    .get();

  const snap = snapshot.docs[0];

  if (!snap.exists) {
    return null;
  }

  return (snap.data() as Record<string, any>) || null;
}

export {
  addPLPHoldStatus,
  getAllAgentUserIds,
  getAllDesignerProfiles,
  getAssignmentsByProjectId,
  getBuildIntentResponse,
  getDesignAssignmentByRevisionId,
  getDesignBriefByProjectId,
  getIncompleteAssignmentsByPMOrderedByDate,
  getMediaByUserIdAndTags,
  getOnHoldProjectsByQAManagerId,
  getProfileById,
  getProjectById,
  getProjectByProfileId,
  getProjectManagerById,
  getProjectManagerByUserId,
  getProjectManagersList,
  getProjectsByAgentId,
  getRevisionsByProfileId,
  getUnassignedDesignProjects,
  saveGeoZoneIdToProfile,
  sendRevisionToClient,
  setHasuraCustomClaims,
  updateAgentAuthEmail,
  updateAgentProperties,
  updateLocationsAndSetContractorUser,
  updateRevisionIssuedBy,
  getAgentByUserId,
  getActiveStripeProducts,
};
