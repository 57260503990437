import * as React from "react";
import {
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
  withStyles,
} from "@material-ui/core";

export interface Props {
  onChange: Function;
  value: string;
  searchTestAccounts: boolean;
  onToggleSearchTestAccounts: () => void;
  recordsDateRange: number;
  classes: any;
}

const useStyles = {
  labelRoot: {
    fontSize: "0.8rem",
  },
};

class ClientSearchBox extends React.Component<Props, object> {
  constructor(props: any) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  public render() {
    const { classes } = this.props;
    const value = this.props.value;
    return (
      <Box display="flex" flexDirection="row">
        <TextField
          label={`Search ${renderSearchBarText(this.props.recordsDateRange)}`}
          name="customer"
          value={value}
          onChange={this.handleOnChange}
          margin="normal"
          InputLabelProps={{
            classes: {
              root: classes.labelRoot,
            },
          }}
        />
        <Box
          pl={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <FormControlLabel
            label="Show test accounts"
            control={
              <Checkbox
                checked={this.props.searchTestAccounts}
                onChange={this.props.onToggleSearchTestAccounts}
              />
            }
          />
        </Box>
      </Box>
    );

    function renderSearchBarText(dateRange: number) {
      if (dateRange === 0) {
        return " all records";
      }
      if (dateRange === 12) {
        return " within last year";
      }
      return `within ${dateRange} month${dateRange > 1 ? "s" : ""}`;
    }
  }

  private handleOnChange(event: any) {
    this.props.onChange.call(this, event.target.value);
  }
}

export default withStyles(useStyles)(ClientSearchBox);
