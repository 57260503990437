import * as React from "react";
import {
  Assignment,
  AssignmentCreatePartial,
  AssignmentType,
  Profile,
  Project,
  FileTag,
  Agent,
} from "@yardzen-inc/models";
import LoadingAbsCenter from "../../Components/LoadingAbsCenter";
import StartCreateAssignment, { newAssignData } from "./StartCreateAssignment";
import { makeStyles, Theme } from "@material-ui/core";
import AssignmentBrief from "./AssignmentBrief";
import AssignmentDetail from "../../EmployeeView/Assignments/AssignmentDetail";
import { assignAssignment } from "../../util/firebaseTransactions";
import GenericConfirm from "../../Components/GenericConfirm";
import ManualAssignModal from "../../EmployeeView/Assignments/ManualAssignModal";
import { RouteComponentProps } from "react-router-dom";
import {
  ReopenAssignmentModal,
  ReopenAssignmentModalResult,
} from "../Detail/ReopenAssignmentModal";
import firebase from "firebase/compat/app";

interface Props extends RouteComponentProps {
  clientRecord: Profile;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    flexFlow: "column nowrap",
    alignItems: "center",
  },
  grid: {
    display: "flex",
    flexFlow: "column wrap",
    width: "100vw",
  },
}));

function Assignments({ clientRecord, history }: Props): React.ReactElement {
  const classes = useStyles();
  // const [projects, setProjects]: [Project[] | null, (project: Project[]) => void] = React.useState<Project[] | null>(null)
  // @ts-ignore
  const [assignments, setAssignments]: [
    Assignment[] | null,
    React.Dispatch<React.SetStateAction<Assignment[]>>
  ] = React.useState<Assignment[] | null>(null);
  const [selectedProject, setSelectedProject] = React.useState<null | Project>(
    null
  );
  const [selectedAssignment, setSelectedAssignment] =
    React.useState<Assignment | null>(null);
  const [onConfirmAction, setOnConfirmAction] = React.useState<
    (() => void) | null
  >(null);
  const [manualAssignOpen, setManualAssignOpen] =
    React.useState<boolean>(false);
  const [manualAssignSubmitting, setManualAssignSubmitting] =
    React.useState<boolean>(false);

  const [reopenAssignmentModalOpen, setReopenAssignmentModalOpen] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    getLatestProject();
  }, []);

  React.useEffect(() => {
    if (!selectedProject) return;
    fetchSelectedProjectAssignments(selectedProject);
  }, [selectedProject]);

  if (!selectedProject || !assignments) return <LoadingAbsCenter in />;

  return (
    <div className={classes.root}>
      <div className={classes.grid}>{renderAssignments(assignments)}</div>
      <ManualAssignModal
        open={!!(manualAssignOpen && selectedAssignment)}
        onClose={() => setManualAssignOpen(false)}
        onSubmit={assign}
        type={(selectedAssignment && selectedAssignment.type) || undefined}
        submitting={manualAssignSubmitting}
      />
      <ReopenAssignmentModal
        open={reopenAssignmentModalOpen}
        onClose={() => setReopenAssignmentModalOpen(false)}
        onSubmit={handleReopenAssignment}
        assignment={selectedAssignment}
      />
      <GenericConfirm
        open={!!onConfirmAction}
        onClose={() => setOnConfirmAction(null)}
        onSubmit={onConfirmAction || (() => setOnConfirmAction(null))}
      />
      <StartCreateAssignment
        project={selectedProject}
        createAssignment={createAssignment}
        clientRecord={clientRecord}
      />
      {!!selectedAssignment && (
        <AssignmentDetail
          open={!!selectedAssignment}
          buttonProps={[
            {
              label: "Unassign",
              disabled: !!(
                !selectedAssignment ||
                !selectedAssignment.assignedAt ||
                !selectedAssignment.assignedTo
              ),
              onClick: () =>
                setOnConfirmAction(
                  () => () => unasignAssingment(selectedAssignment)
                ),
            },
            {
              label: "Assign",
              disabled: !!(
                !selectedAssignment ||
                selectedAssignment.assignedAt ||
                selectedAssignment.assignedTo
              ),
              onClick: () => {
                setManualAssignOpen(true);
              },
            },
            {
              label: "Delete",
              disabled: false,
              onClick: () => {
                setOnConfirmAction(() => () => deleteAssignment());
              },
            },
            {
              label: "Reopen",
              disabled: !!(selectedAssignment && !selectedAssignment.completed),
              onClick: () => {
                setReopenAssignmentModalOpen(true);
              },
            },
            {
              label: "Mark as Complete",
              disabled: !!(selectedAssignment && selectedAssignment.completed),
              onClick: () => {
                setOnConfirmAction(() => () => markAsComplete());
              },
            },
            {
              label: "Go to CDM View",
              disabled: !selectedAssignment,
              onClick: () => {
                setOnConfirmAction(
                  () => () =>
                    history.push(
                      `/cdm/${selectedAssignment.projectId}/${selectedAssignment.id}`
                    )
                );
              },
            },
          ]}
          selectedAssignment={selectedAssignment || null}
          onClose={() => setSelectedAssignment(null)}
        />
      )}
    </div>
  );

  async function markAsComplete() {
    if (!selectedAssignment) {
      return;
    }

    selectedAssignment.completed = true;

    setOnConfirmAction(null);
    await selectedAssignment.save();

    // saves to last submitted at instead of overwriting
    // original submitted at date
    if (selectedAssignment.submittedAt) {
      await setLastSubmittedAtTimestamp();
    } else {
      selectedAssignment.submittedAt = new Date().getTime();
    }
    try {
      const updated = await Assignment.fetch(
        selectedAssignment.projectId,
        selectedAssignment.id
      );
      setSelectedAssignment(updated);
    } catch (err) {
      console.error(err);
    } finally {
      search();
    }
  }

  async function unasignAssingment(
    assignment: Assignment | null
  ): Promise<void> {
    if (!assignment) return;

    try {
      localStorage.removeItem(`ASSIGNMENT_BRIEF_${assignment.id}`);
    } catch (error) {
      console.error(error);
    }

    await assignment.unnassign();
    await search();
    setOnConfirmAction(null);
  }

  async function assign(a: Agent): Promise<void> {
    if (!selectedAssignment)
      throw new Error(
        "assignAssignment called while no selectedAssignment state"
      );
    setManualAssignSubmitting(true);

    await assignAssignment(a, selectedAssignment);
    await search();
    setManualAssignOpen(false);
    setOnConfirmAction(null);
    setManualAssignSubmitting(false);
  }

  async function deleteAssignment() {
    if (selectedAssignment) {
      await selectedAssignment.getDocRef().delete();
      setSelectedAssignment(null);
      setOnConfirmAction(null);
      search();
    }
  }

  async function reopenAssignment() {
    if (selectedAssignment) {
      selectedAssignment.completed = false;
      // selectedAssignment.assignedTo = null;
      // selectedAssignment.assignedAt = null;
      selectedAssignment.listed = false;

      selectedAssignment.paidCorrection =
        selectedAssignment.paidCorrection || null;
      selectedAssignment.lastSubmittedAt =
        selectedAssignment.lastSubmittedAt || null;
      selectedAssignment.reopenedCount =
        selectedAssignment.reopenedCount || null;
      setOnConfirmAction(null);
      setSelectedAssignment(selectedAssignment);
      await selectedAssignment.save();

      search();

      try {
        setSelectedAssignment(
          await Assignment.fetch(
            selectedAssignment.projectId,
            selectedAssignment.id
          )
        );
      } catch (err) {
        console.error(err);
      }
    }
  }

  async function setAssignmentPaidCorrection(isPaidCorrection: boolean) {
    if (!selectedAssignment || !selectedProject) {
      return;
    }
    try {
      await firebase
        .firestore()
        .collection("projects")
        .doc(selectedProject.id)
        .collection("assignments")
        .doc(selectedAssignment.id)
        .set(
          {
            paidCorrection: isPaidCorrection,
          },
          { merge: true }
        );
      selectedAssignment.paidCorrection = isPaidCorrection;
      setSelectedAssignment(selectedAssignment);
    } catch (error) {
      console.error(error);
    }
  }

  async function incrementReopenedCount() {
    if (!selectedAssignment || !selectedProject) {
      return;
    }
    try {
      await firebase
        .firestore()
        .collection("projects")
        .doc(selectedProject.id)
        .collection("assignments")
        .doc(selectedAssignment.id)
        .update({
          reopenedCount: firebase.firestore.FieldValue.increment(1),
        });
      selectedAssignment.reopenedCount = selectedAssignment.reopenedCount
        ? (selectedAssignment.reopenedCount += 1)
        : 1;
      setSelectedAssignment(selectedAssignment);
    } catch (error) {
      console.error(error);
    }
  }

  function handleReopenAssignment({
    isPaidCorrection,
  }: ReopenAssignmentModalResult) {
    setReopenAssignmentModalOpen(false);
    setOnConfirmAction(() => () => {
      reopenAssignment();
      setAssignmentPaidCorrection(isPaidCorrection);
      incrementReopenedCount();
      setOnConfirmAction(null);
      setSelectedAssignment(selectedAssignment);
    });
  }

  async function setLastSubmittedAtTimestamp() {
    if (!selectedAssignment || !selectedProject) {
      return;
    }
    try {
      let timestamp = new Date().getTime();
      await firebase
        .firestore()
        .collection("projects")
        .doc(selectedProject.id)
        .collection("assignments")
        .doc(selectedAssignment.id)
        .set(
          {
            lastSubmittedAt: timestamp,
          },
          { merge: true }
        );
      selectedAssignment.lastSubmittedAt = timestamp;
      setSelectedAssignment(selectedAssignment);
    } catch (error) {
      console.error(error);
    }
  }

  async function search() {
    if (selectedProject) {
      return fetchSelectedProjectAssignments(selectedProject);
    }
  }

  async function getLatestProject() {
    const profileId = clientRecord.id;
    const project = await Project.fetchWithProfileId(profileId).catch((e) => {
      console.log(e);
      return Project.create(profileId);
    });
    setSelectedProject(project);
  }

  async function fetchSelectedProjectAssignments(p: Project) {
    const assignments = await p.getAssignments(true, true);
    setAssignments(assignments);
  }

  async function createAssignment(
    projectId: string,
    data: newAssignData,
    revisionId?: string
  ) {
    if (data.type === "design" && !revisionId) {
      throw new Error("revision id is required to create a design assignment");
    }

    const isWesley = data.type === "wesley";

    let assignedTo: string | null = null;

    if (isWesley) {
      assignedTo = selectedProject?.projectManagerId ?? null;
    }

    let assignedAt: number | null = assignedTo ? new Date().getTime() : null;

    const asCreate: AssignmentCreatePartial = {
      revisionId: revisionId ?? null,
      title: data.title,
      pm: isWesley,
      listed: isWesley ? false : data.listed,
      type: data.type as AssignmentType,
      description: data.notes,
      materials: [],
      assignedTo,
      assignedAt,
      projectId,
    };

    const tagArray: FileTag[] = [];
    Object.keys(data.mediaTags).forEach((tag) => {
      if (!data.mediaTags[tag]) return;
      tagArray.push(tag);
    });

    const materials = await Assignment.getAllMediaIdsByFileTags(
      tagArray,
      clientRecord.id
    );

    asCreate.materials = materials;

    console.log("asCreate", asCreate);

    try {
      const newAssignment = await Assignment.create(projectId, asCreate);
      setAssignments((assignments) => assignments.concat(newAssignment));
    } catch (error) {
      ("Failed at assignment.create");
      console.error(error);
    }
  }

  function renderAssignments(assignments: Assignment[]) {
    if (!assignments.length) return null;
    return assignments.map((a: Assignment) => {
      return (
        <AssignmentBrief
          key={a.id}
          assignment={a as any}
          onAction={() => setSelectedAssignment(a)}
        />
      );
    });
  }
}

export default Assignments;
