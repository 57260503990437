import * as React from "react";
import { Box, Typography } from "@material-ui/core";

export interface MetricCardProps {
  title: string;
  content: string;
}

const MetricCard: React.FC<MetricCardProps> = ({ title, content }) => {
  return (
    <Box
      m={1}
      p={2}
      bgcolor="white"
      boxShadow={2}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="32%"
      minWidth="270px"
      maxWidth="98vw"
    >
      <Box mb={2}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box>
        <Typography variant="h5">{content}</Typography>
      </Box>
    </Box>
  );
};

export { MetricCard };
export default MetricCard;
