import { CorrectionType, DesignAssignmentStatuses } from "@yardzen-inc/models";

enum DesignAssignmentStatus {
  assignmentCreated = "assignmentCreated",
  v1InProgress = "v1InProgress",
  postV1Hold = "postV1Hold",
  v2InProgress = "v2InProgress",
  completed = "completed",
  v3InProgress = "v3InProgress",
  postV3Hold = "postV3Hold",
  extraInProgress = "extraInProgress",
  postExtraHold = "postExtraHold",
  correctionInProgress = "correctionInProgress",
  postCorrectionHold = "postCorrectionHold",
}

enum CorrectionTypeEnum {
  v1 = "v1",
  v2 = "v2",
  v3 = "v3",
  extra = "extra",
}

class DssStateMap extends Map<
  DesignAssignmentStatuses,
  { displayName?: string; type?: CorrectionType }
> {
  public indexOf(status: DesignAssignmentStatuses): number {
    let i = 0;
    // @ts-ignore
    for (const key of this.keys()) {
      if (key === status) {
        return i;
      }

      i += 1;
    }

    return -1;
  }
}

const dssStates: DssStateMap = new DssStateMap([
  [
    DesignAssignmentStatus.assignmentCreated,
    {
      displayName: "Waiting for assignment",
      type: CorrectionTypeEnum.v1,
    },
  ],
  [
    DesignAssignmentStatus.v1InProgress,
    {
      displayName: "V1 in progress",
      type: CorrectionTypeEnum.v1,
    },
  ],
  [DesignAssignmentStatus.postV1Hold, { displayName: "Version one delivered" }],
  [
    DesignAssignmentStatus.v2InProgress,
    { displayName: "V2 in progress", type: CorrectionTypeEnum.v2 },
  ],
  [DesignAssignmentStatus.completed, { displayName: "Version 2 delivered" }],
  [
    DesignAssignmentStatus.v3InProgress,
    { displayName: "Version 3 in progress", type: CorrectionTypeEnum.v3 },
  ],
  [DesignAssignmentStatus.postV3Hold, { displayName: "V3 delivered" }],
  [
    DesignAssignmentStatus.extraInProgress,
    {
      displayName: "Extra revision in progress",
      type: CorrectionTypeEnum.extra,
    },
  ],
  [
    DesignAssignmentStatus.postExtraHold,
    { displayName: "Extra revision delivered" },
  ],
]);

const dssCorrectionStates: DssStateMap = new DssStateMap([
  [
    DesignAssignmentStatus.correctionInProgress,
    { displayName: "Correction in progress" },
  ],
  [
    DesignAssignmentStatus.postCorrectionHold,
    { displayName: "Correction delivered" },
  ],
]);

const dssStatesWithCorrections = new DssStateMap([
  ...dssStates,
  ...dssCorrectionStates,
]);

export {
  DssStateMap,
  DesignAssignmentStatus,
  CorrectionTypeEnum,
  dssStates,
  dssStatesWithCorrections,
};
