import React, { FC, Fragment, useState } from "react";
import {
  Dialog,
  makeStyles,
  Theme,
  Box,
  Divider,
  Switch,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import firebase from "firebase/compat/app";
import { PostPLPHoldsRequiredActionType } from "./types";

export interface ManagePLPActionsModalProps {
  open: boolean;
  onClose: () => void;
  requiredActions: PostPLPHoldsRequiredActionType[];
  triggerRefresh: () => void;
  projectId: string;
}

const POST_PLP_HOLD_ACTIONS: PostPLPHoldsRequiredActionType[] = [
  "CX_OUTREACH",
  "MODEL_FIX",
  "VIP",
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(6),
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    top: 5,
    right: 5,
    cursor: "pointer",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
  },
  centerGridItem: {
    justifySelf: "center",
    alignSelf: "center",
  },
}));

const ManagePLPActionsModal: FC<ManagePLPActionsModalProps> = ({
  open,
  onClose,
  requiredActions,
  triggerRefresh,
  projectId,
}) => {
  const classes = useStyles();

  const [selectedActions, setSelectedActions] = useState<
    PostPLPHoldsRequiredActionType[]
  >(requiredActions ?? []);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box className={classes.root}>
        {error && (
          <YZTypography color="error">An error has occurred</YZTypography>
        )}

        <Close onClick={onClose} classes={{ root: classes.closeIcon }} />

        <Box className={classes.grid}>
          <Box>
            <YZTypography variant="h6">Actions:</YZTypography>
            <Divider />
          </Box>
          <Box>
            <YZTypography variant="h6">Awaiting:</YZTypography>
            <Divider />
          </Box>
          {POST_PLP_HOLD_ACTIONS.map((action) => (
            <Fragment key={action}>
              <Box className={classes.centerGridItem}>
                <YZTypography>{action}</YZTypography>
              </Box>
              <Box className={classes.centerGridItem}>
                <Switch
                  color="primary"
                  checked={selectedActions.includes(action)}
                  onChange={onChange}
                  name={`${action}`}
                  value={action}
                  inputProps={{
                    "aria-label": `${action} awaited action toggler`,
                  }}
                />
              </Box>
            </Fragment>
          ))}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          mt={2}
        >
          <YZButton
            disabled={loading}
            color="primary"
            onClick={updatePostPLPHoldActionsAsync}
          >
            Update
          </YZButton>
          {loading && (
            <Box mt={1}>
              <YZTypography>updating...</YZTypography>
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );

  async function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newActionsArr = getNewActionsArr(
      e.target.value as PostPLPHoldsRequiredActionType
    );
    setSelectedActions(newActionsArr);
  }

  function getNewActionsArr(action: PostPLPHoldsRequiredActionType) {
    // If actions array doesn't exist, create new array with newly added tag
    if (!selectedActions || !Array.isArray(selectedActions)) {
      return [action];

      // If actions array doesn't include toggled action, add it
    } else if (!selectedActions.includes(action)) {
      return [...selectedActions, action];

      // If actions array includes toggled action, remove it
    } else {
      const idx = selectedActions.indexOf(action);
      return [
        ...selectedActions.slice(0, idx),
        ...selectedActions.slice(idx + 1),
      ];
    }
  }

  async function updatePostPLPHoldActionsAsync() {
    setLoading(true);

    const callable = firebase
      .functions()
      .httpsCallable("updatePostPLPHoldRequiredActions");

    try {
      await callable({ newActionsArr: selectedActions, projectId });
    } catch (err) {
      console.error(err);
      setError(true);
      setLoading(false);
    }
    return triggerRefresh();
  }
};

export { ManagePLPActionsModal };
export default ManagePLPActionsModal;
