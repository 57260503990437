import React, { FC } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { Select, MenuItem } from "@material-ui/core";

export interface QaFilterProps extends BoxProps {
  type: "v1" | "v2" | "v3" | "all";
  setType: (type: string) => void;
  correctionStatus: "corrections" | "notcorrections" | "all";
  setCorrectionStatus: (type: string) => void;
}

const QaFilter: FC<QaFilterProps> = ({
  correctionStatus,
  setCorrectionStatus,
  type,
  setType,
  ...containerProps
}) => {
  return (
    <Box {...containerProps} display="flex" flexDirection="row">
      <Box p={1}>
        <Select
          value={type}
          onChange={({ target: { value } }) =>
            setType((value as string) ?? "all")
          }
        >
          <MenuItem value="all">any version</MenuItem>
          <MenuItem value="v1">v1</MenuItem>
          <MenuItem value="v2">v2</MenuItem>
          <MenuItem value="v3">v3</MenuItem>
        </Select>
      </Box>
      <Box p={1}>
        <Select
          value={correctionStatus}
          onChange={({ target: { value } }) =>
            setCorrectionStatus((value as string) ?? "all")
          }
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="correction">Corrections</MenuItem>
          <MenuItem value="notcorrections">Not Corrections</MenuItem>
        </Select>
      </Box>
    </Box>
  );
};

export { QaFilter };
export default QaFilter;
