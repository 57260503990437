import * as React from "react";
import { makeStyles, Box } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";

interface Props {
  title: string;
  children: JSX.Element;
  ellipsis?: boolean;
}

const useRowStyles = makeStyles((theme) => ({
  tableItem: {
    display: "flex",
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
  },
  tableItemTitle: {
    textAlign: "right",
    color: theme.palette.text.secondary,
    flex: 1,
    padding: "0.5rem",
    maxWidth: "180px",
  },
  tableItemContent: {
    fontWeight: 600,
    flex: 1,
    padding: "0.5rem",
    "& img": {
      borderRadius: "4px",
      maxHeight: "150px",
    },
    "& a": {},
  },
}));

function PopupTableItem(props: Props) {
  const classes = useRowStyles();

  return (
    <Box className={classes.tableItem}>
      <YZTypography className={classes.tableItemTitle}>
        {props.title}
      </YZTypography>
      <YZTypography
        className={classes.tableItemContent}
        style={
          props.ellipsis
            ? {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }
            : {}
        }
      >
        {props.children}
      </YZTypography>
    </Box>
  );
}

export default PopupTableItem;
