import { useBudgetChecklistResponseItemChoiceAndPriceItemPropertiesIdByProjectIdQuery } from "@yardzen-inc/graphql";
import { useLogError } from ".";

export type BudgetElementsPriceEstimate =
  | [number | undefined, number | undefined]
  | null;

export interface UseBudgetElementsPriceEstimate {
  (projectId: string): BudgetElementsPriceEstimate;
}

// returns the low - high price estimate for project's budget elements responses in the form of integers [low, high]
// all usd values are represented as integers, where the last two digits take the 'cents' place
// null return value === loading state
const useBudgetElementsPriceEstimate: UseBudgetElementsPriceEstimate = (
  projectId: string
) => {
  const { data, error, loading } =
    useBudgetChecklistResponseItemChoiceAndPriceItemPropertiesIdByProjectIdQuery(
      {
        variables: { projectId },
        fetchPolicy: "network-only",
      }
    );

  useLogError(error as Error);

  return aggregateSumResponsePriceRages();

  function aggregateSumResponsePriceRages(): BudgetElementsPriceEstimate {
    if (loading) {
      return null;
    }

    if (data?.budget_checklist_response) {
      let low = 0;
      let high = 0;

      for (const response of data.budget_checklist_response) {
        if (response.budget_checklist_item.quantitative_item) {
          low += parseInt(response.budget_checklist_price_option.low_price, 10);
          high += parseInt(
            response.budget_checklist_price_option.high_price,
            10
          );
        } else {
          let multiplier = 0;

          [
            response?.front_yard,
            response?.back_yard,
            response?.left_yard,
            response?.right_yard,
          ].forEach((yard) => {
            if (!!yard) {
              multiplier += 1;
            }
          });

          if (!multiplier) {
            multiplier = 1;
          }

          low +=
            parseInt(response.budget_checklist_price_option.low_price, 10) *
            multiplier;
          high +=
            parseInt(response.budget_checklist_price_option.high_price, 10) *
            multiplier;
        }
      }

      return [low, high];
    }

    return [undefined, undefined];
  }
};

export { useBudgetElementsPriceEstimate };
export default useBudgetElementsPriceEstimate;
