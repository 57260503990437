import { SelectedItemsPlant } from "./constants/SelectedItemsConstants";
import { groupBy } from "lodash";
import React from "react";
import { IAssignmentPlantBaseLink, IPlantBase } from "../../Interfaces";
import { DownloadUtil } from "../../util/downloadUtil";
import { formatNumToUSD } from "@yardzen-inc/react-common";
import uuid from "uuid";
import QuantityInput from "./QuantityInput";
import cleanedDownloadableFileName from "../../EmployeeView/AssetLibraries/Shared/CleanedDownloadableFileName";
import GetAppIcon from "@material-ui/icons/GetApp";

export const getSelectedPlantsForBudgetQA = (
  queryDataSelection: any,
  nonPm: boolean = false,
  onUpdatePlantQty: (qty: number, id: string) => void,
  enableQuantityChange: boolean = false,
  quantityInputContainer: string,
  assignment_plant_base_links?: IAssignmentPlantBaseLink[],
  projectGeos?: Set<string>
): SelectedItemsPlant[] => {
  const dataSelectionAssignment = queryDataSelection?.assignment[0];
  const noDataSelected = !queryDataSelection?.assignment?.length;
  if (
    noDataSelected ||
    !dataSelectionAssignment.assignment_plant_base_links?.length
  ) {
    return [];
  }
  const grouped = groupBy(
    dataSelectionAssignment.assignment_plant_base_links,
    "plant_base.id"
  );

  const idAndQuantityMap: any = groupBy(
    assignment_plant_base_links,
    "plant_base_id"
  );
  return Object.keys(grouped).map((key) => {
    const link: any = grouped[key][0];
    const plantBase: IPlantBase = link.plant_base;
    const quantity = nonPm ? idAndQuantityMap[key].length : grouped[key].length;
    const onUpdateQty = (qty: number) => {
      onUpdatePlantQty(qty, plantBase.id);
    };

    return {
      id: plantBase.id || uuid.v4(),
      totalCost: formatNumToUSD(
        quantity * parseFloat(plantBase.unit_cost || "0")
      ),
      quantity: enableQuantityChange ? (
        <div className={quantityInputContainer}>
          <QuantityInput onUpdate={onUpdateQty} value={quantity} />
        </div>
      ) : (
        quantity
      ),
      quantityNumber: quantity,
      name: plantBase.common_name,
      scientificName: plantBase.scientific_name,
      image: plantBase.icon_uri,
      containerSize: plantBase.plant_base_plant_container_size_links
        ?.map(
          (containerSizeLink: any) =>
            containerSizeLink.plant_container_size?.name
        )
        .join(", "),
      cadSymbolSize: plantBase.plant_base_plant_cad_symbol_size_links
        ?.map((cadLink: any) => cadLink.plant_cad_symbol_size?.name)
        .join(", "),
      cadSymbolSpacing: plantBase.plant_base_plant_cad_symbol_spacing_links
        ?.map((cadLink: any) => cadLink.plant_cad_symbol_spacing?.name)
        .join(", "),
      zone: plantBase.plant_base_plant_usda_zone_links
        ?.map((zoneLink: any) => zoneLink.plant_usda_zone?.name)
        .join(", "),
      lightNeeds: plantBase.plant_base_plant_light_needs_links
        ?.map((lightLink: any) => lightLink.plant_light_needs?.name)
        .join(", "),
      heightClass: plantBase.plant_base_plant_height_class_links
        ?.map((heightLink: any) => heightLink.plant_height_class?.name)
        .join(", "),
      waterNeeds: plantBase.waterNeedsByPlantWaterNeeds?.name,
      keyFeatures: plantBase.plant_base_plant_key_feature_links
        ?.map((lightLink: any) => lightLink.plant_key_feature?.name)
        .join(", "),
      type: plantBase.plant_base_plant_type_links
        ?.map((typeLink: any) => typeLink.plant_type?.name)
        .join(", "),
      redFlags: plantBase.plant_base_plant_red_flag_links
        ?.map((link: any) => link.plant_red_flag?.name)
        .join(", "),
      category: "Plants",
      lumionFileLink: plantBase.lumion_file_link ? (
        <div
          onClick={() =>
            DownloadUtil.downloadWithFirebaseCredentials(
              plantBase.lumion_file_link,
              undefined,
              cleanedDownloadableFileName(plantBase.scientific_name)
            )
          }
          style={{ pointerEvents: "all", display: "inline-block" }}
        >
          <GetAppIcon />
        </div>
      ) : null,
      lumionFileName: plantBase.lumion_file_name,
      sketchupFileLink: plantBase.sketchup_file_link ? (
        <div
          onClick={() =>
            DownloadUtil.downloadWithFirebaseCredentials(
              plantBase.sketchup_file_link,
              undefined,
              cleanedDownloadableFileName(plantBase.scientific_name)
            )
          }
          style={{ pointerEvents: "all", display: "inline-block" }}
        >
          <GetAppIcon />
        </div>
      ) : null,
      nativeGeoMatch: !!plantBase.plant_base_plant_native_range_links?.some?.(
        (range) => projectGeos?.has?.(range.plant_native_range?.name || "")
      ),
      plantGeoMatch: !!plantBase.plant_base_adapted_habitat_links?.some?.(
        (adaptedHabitat) =>
          projectGeos?.has?.(adaptedHabitat.plant_adapted_habitat?.name || "")
      ),
    };
  });
};
