import React, { FC, Fragment, useEffect, useState } from "react";

// Import MUI Components
import { makeStyles } from "@material-ui/core/styles";
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { YZButton } from "@yardzen-inc/react-common";
import { ProjectManagerProperties } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { AgentPods, editPodRequest } from "./types";
import { updateDesignerPod } from "./apiCalls";

export interface EditPodDetailsModalProps {
  open: boolean;
  podData: AgentPods;
  onClose: () => void;
  onEditPodSubmit: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  style: {},
  dialogContainer: {
    minWidth: 500,
    minHeight: 289,
  },
  progressContainer: {
    display: "flex",
  },
  innerButtonProgress: {
    position: "absolute",
    color: "#fff",
  },
  selectForm: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const EditPodDetailsModal: FC<EditPodDetailsModalProps> = ({
  open,
  podData,
  onClose,
  onEditPodSubmit,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [editPodData, setEditPodData] = useState({
    designerPodId: podData.id,
    name: "",
    projectManagerId: "",
  });
  const [projectManagers, setProjectManagers] = useState<
    (ProjectManagerProperties & { id: string })[]
  >([]);
  const [editPodLoading, setEditPodLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    // pulls in master list of project managers
    async function getProjectManagers(): Promise<
      (ProjectManagerProperties & {
        id: string;
      })[]
    > {
      setLoading(true);
      const projectSnap = await firebase
        .firestore()
        .collection("projectManagers")
        .get();

      const response = await projectSnap.docs
        .map((doc) => ({
          ...(doc.data() as ProjectManagerProperties),
          id: doc.id,
        }))
        .filter((pm) => !!pm);
      setProjectManagers(response);
      setLoading(false);
      return response;
    }
    getProjectManagers();
  }, []);

  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        classes={{ paper: classes.dialogContainer }}
      >
        <DialogTitle id="form-dialog-title">
          Edit {podData.name} team details
        </DialogTitle>
        {error && <Alert severity="error">{error}</Alert>}
        {loading ? (
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="New Team Name"
                type="text"
                name="name"
                fullWidth
                value={editPodData.name}
                onChange={handleChange}
              />
              <FormControl fullWidth className={classes.selectForm}>
                <InputLabel htmlFor="pageLength">New Team PM Lead</InputLabel>
                <Select
                  // @ts-ignore
                  onChange={handleChange}
                  value={editPodData.projectManagerId}
                  name="projectManagerId"
                >
                  {projectManagers &&
                    projectManagers.map((pm) => {
                      return (
                        <MenuItem
                          key={`edit-pods-detail-${pm.id}`}
                          value={pm.id}
                          id="projectManagerId"
                        >{`${pm.firstName} ${pm.lastName}`}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <YZButton
                inheritFont
                color="primary"
                onClick={handleSubmitButonClick}
                fullWidth
                disabled={editPodLoading}
              >
                {editPodLoading && (
                  <CircularProgress
                    size={30}
                    className={classes.innerButtonProgress}
                  />
                )}
                Submit
              </YZButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Fragment>
  );

  async function handleSubmitButonClick() {
    setError("");
    if (
      editPodData.name.trim() === "" &&
      editPodData.projectManagerId.trim() === ""
    ) {
      setError("Please fill out at least one field");
    } else {
      setEditPodLoading(true);
      let postData: editPodRequest = {
        designerPodId: editPodData.designerPodId,
        properties: {},
      };

      // Add name and/or project manager id to the postData if they are not empty
      if (editPodData.name !== "") postData.properties.name = editPodData.name;
      if (editPodData.projectManagerId !== "")
        postData.properties.projectManagerId = editPodData.projectManagerId;

      await updateDesignerPod(postData);
      setEditPodLoading(false);
      onClose();
      onEditPodSubmit();
    }
  }

  function handleChange(
    event: React.ChangeEvent<{ value: string; name: string }>
  ) {
    const { name, value } = event.target;
    setEditPodData({
      ...editPodData,
      [name]: value,
    });
  }
};

export { EditPodDetailsModal };
export default EditPodDetailsModal;
