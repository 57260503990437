import * as React from "react";
import { SearchResult } from "./SearchableTable";
import { TableRow, TableCell, Typography, Paper } from "@material-ui/core";
import moment from "moment";

interface Props {
  result: SearchResult;
  onClick: (clientId: string) => void;
}

const stateMap = {
  materialsRecieved: "Materials Recieved",
  reviewDesign: "Needs QA",
  clientReview: "Client Reviewing",
};

// const keyMap = {
//   email: "Email",
//   firstName: "First Name",
//   lastName: "Last Name",
//   street: "Street",
//   city: "City",
//   phone: "Phone",
// };

function SearchResultRow({ result, onClick }: Props): React.ReactElement {
  const clientRecord = result.record;
  const hasAddress =
    !!clientRecord.street && !!clientRecord.city && !!clientRecord.state;

  return (
    <TableRow
      key={clientRecord.id}
      onClick={() => {
        onClick(clientRecord.id);
      }}
      data-id={clientRecord.id}
      style={{ cursor: "pointer" }}
    >
      <TableCell>
        <Typography style={{ fontWeight: 500, width: "fit-content" }}>
          {clientRecord.firstName} {clientRecord.lastName}
        </Typography>
        {hasAddress && (
          <Typography variant="overline">
            {clientRecord.street} {clientRecord.city}, {clientRecord.state}{" "}
            {clientRecord.zip}
          </Typography>
        )}
        <div style={{ display: "flex" }}>
          <Typography>{clientRecord.email}</Typography>
          {clientRecord.email && clientRecord.phone && (
            <Typography
              style={{ fontWeight: 600, margin: "0 0.5rem 0 0.5rem" }}
            >
              -
            </Typography>
          )}
          <Typography>{clientRecord.phone}</Typography>
        </div>
      </TableCell>
      <TableCell>
        <div>
          <Typography>
            {
              // @ts-ignore
              !!clientRecord["createdAt"]
                ? // @ts-ignore
                  typeof clientRecord["createdAt"] === "object"
                  ? // @ts-ignore
                    moment(clientRecord["createdAt"].seconds).format(
                      "MM/DD/YY hh:mma"
                    )
                  : // @ts-ignore
                    moment(clientRecord["createdAt"]).format("MM/DD/YY hh:mma")
                : "No date"
            }{" "}
            ||{" "}
            {!!clientRecord.wizardDone
              ? moment(clientRecord.wizardDone).format("MM/DD/YY hh:mma")
              : "Not submitted"}
          </Typography>
        </div>
      </TableCell>
      <TableCell>
        {/* {(!!result.hitMap.length || !!result.matchedContacts.length) && ( */}
        <div>
          {!!clientRecord.secondaryContacts && (
            <div>
              <div style={{ display: "flex" }}>
                {clientRecord.secondaryContacts.map((contact, index) => {
                  if (index > 1) return;

                  // const contact = contactResult.contact;
                  return (
                    <Paper
                      key={`contact-${contact.id}`}
                      style={{
                        margin: "0.2rem",
                        display: "flex",
                        flexDirection: "column",
                        padding: "0.2rem",
                        cursor: "text",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      elevation={6}
                    >
                      <em>
                        {contact.lastName}
                        {contact.firstName && `, ${contact.firstName}`}
                      </em>
                      <em></em>
                      <em>{contact.phone}</em>
                      <em>{contact.email}</em>
                    </Paper>
                  );
                })}
                {clientRecord.secondaryContacts.length > 2 && (
                  <Typography
                    style={{
                      fontSize: "2rem",
                      color: "grey",
                      lineHeight: "100%",
                      margin: "auto 0.5rem",
                    }}
                  >
                    +{clientRecord.secondaryContacts.length - 2} more
                  </Typography>
                )}
              </div>
            </div>
          )}
        </div>
      </TableCell>
      <TableCell>
        {clientRecord.projectState ? (
          // @ts-ignore
          stateMap[clientRecord.projectState]
        ) : (
          <Typography>Unknown</Typography>
        )}
      </TableCell>
    </TableRow>
  );
}

export default SearchResultRow;
