// import genericAlert from "./genericAlert";

export async function asyncForEach(
  array: any,
  callback: any,
  errorMessage: string
) {
  for (let index = 0; index < array.length; index++) {
    try {
      await callback(array[index], index, array);
    } catch (error) {
      //TODO: We need to find a better solution rather than flashing the alert channel
      // const obj =
      //   typeof array[index] === "object"
      //     ? JSON.stringify(array[index])
      //     : array[index];
      // genericAlert(`${errorMessage}, object: ${obj}, Error: ${error}`);
    }
  }
}
