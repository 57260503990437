import {
  Agent,
  ProjectManager,
  AgentProperties,
  AgentTier,
} from "@yardzen-inc/models";

function makeAgentFromPm(pm: ProjectManager): Agent {
  const agentProps: Omit<AgentProperties, "assignedAt"> = {
    ...pm.properties,
    userId: pm.userId ?? "",
    disabled: false,
    type: "wesley",
    payRate: 66666,
    qualifications: [],
    tier: AgentTier.NewDesigner,
  };

  const agent = new Agent();
  agent.id = pm.id;
  agent.setProperties(agentProps as any);

  return agent;
}

export { makeAgentFromPm };
export default makeAgentFromPm;
