import { usePM } from "../../EmployeeView/MyProjects";
import { useLocalStorage } from ".";
import {
  ProjectManagerProperties,
  ModelProperties,
  ProjectManager,
} from "@yardzen-inc/models";
import React, { useEffect } from "react";
import firebase from "firebase/compat/app";

const useCachedPm = (
  projectManagerId?: string
): false | ProjectManager | null => {
  const _pm = usePM(projectManagerId);

  const [cache, updateCache] = useLocalStorage<
    ProjectManagerProperties & ModelProperties
  >(
    "PROJECT_MANAGER_PROPERTIES" + projectManagerId
      ? `_${projectManagerId}`
      : ""
  );

  const pm = React.useMemo(() => {
    if (_pm) {
      return _pm;
    }

    if (_pm === false && !cache) {
      return false;
    }

    if (!_pm && cache) {
      return new ProjectManager(cache);
    }

    return null;
  }, [cache, _pm, projectManagerId]);

  useEffect(() => {
    try {
      if (_pm && _pm.updatedAt.seconds !== cache?.updatedAt?.seconds) {
        updateCache({
          ..._pm.properties,
          updatedAt: _pm.updatedAt,
          createdAt: _pm.createdAt,
          id: _pm.id,
        });
      }
    } catch (error) {
      console.error("pm", _pm);
      console.error(error);

      if (_pm && !_pm.createdAt && !_pm.updatedAt) {
        (async function () {
          await _pm.docRef.update({
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        })();
      }
    }
  }, [_pm]);

  return pm;
};

export { useCachedPm };
export default useCachedPm;
