import { useFormikContext } from "formik";
import React, { FC } from "react";
import {
  IFormikElementProps,
  IFormikPlantProps,
  IFormikSurfaceProps,
} from "../../../Interfaces";
import UploadFormComponent, {
  UploadFormComponentProps,
} from "./UploadFormComponent";

export interface FormikUploadProps
  extends Omit<UploadFormComponentProps, "onUploadCompleted" | "initialValue"> {
  fieldName: string;
  mediaHost?: string;
}

const FormikUpload: FC<FormikUploadProps> = ({
  fieldName,
  ...uploadComponentProps
}) => {
  const { values, setFieldValue } = useFormikContext<
    IFormikElementProps | IFormikSurfaceProps | IFormikPlantProps
  >();

  return (
    <UploadFormComponent
      {...uploadComponentProps}
      onUploadCompleted={handleChange}
      initialValue={(values as any)[fieldName]}
    />
  );

  function handleChange(value: string): void {
    setFieldValue(fieldName, value);
  }
};

export { FormikUpload };
export default FormikUpload;
