import * as React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";

interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles({
  root: {
    padding: "0.5rem",
    paddingLeft: "0",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
  },
  icon: {
    height: "1.5rem",
    width: "1.5rem",
  },
  textField: {},
  input: {
    fontSize: "1.3rem",
  },
});

export default (props: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Search className={classes.icon}></Search>
      <div style={{ flexGrow: 1 }}>
        <TextField
          fullWidth
          value={props.value}
          onChange={props.onChange}
          placeholder="Filter by First name, Last name, or Email..."
          className={classes.textField}
          inputProps={{ className: classes.input }}
        ></TextField>
      </div>
    </div>
  );
};
