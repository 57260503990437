import * as React from "react";
import {
  Agent,
  Profile,
  Assignment,
  ProjectManager,
  ProjectManagerProperties,
} from "@yardzen-inc/models";
import {
  Typography,
  Divider,
  makeStyles,
  Theme,
  Box,
  Button,
  Tooltip,
} from "@material-ui/core";
import mmddyyyy from "../../SelfAssignPage/mmddyyyy";
import { getAddress, Addy } from "../../util/getAddress";
import moment from "moment";
import { useLocalStorage } from "../../util/hooks";
import makeAgentFromPm from "../../EmployeeView/CDM/makeAgentFromPm";
import GenericConfirm from "../../Components/GenericConfirm";
import { skipWeekend } from "../../util/dateTime";
import { Build, FlashOn } from "@material-ui/icons";
import {
  getProjectById,
  getProjectManagerById,
  getAssignmentsByProjectId,
} from "../../util/firebase/firebaseClient";
import { getExpeditedAssignmentStartByDate } from "../../util/selfAssign/getExpeditedAssignmentStartByDate";

interface Props {
  assignment: Assignment;
  showAddress?: boolean;
  showClientInfo?: boolean;
  short?: boolean;
  index?: number;
  useArchive?: boolean;
  onAction?: () => void;
  noCache?: boolean;
}

interface AssignmentBriefCache {
  clientInformation?: {
    firstName: string;
    lastName: string;
    wizardDone?: string | null;
  };
  address?: Addy;
  agentInformation?: {
    firstName: string;
    lastName: string;
    email: string;
    isPm?: boolean;
  };
}

const typeMap = {
  wesley: "Function & Flow",
  modeling: "Model",
  design: "Design",
};

const useStyles = makeStyles((theme: Theme) => ({
  fitContent: {
    width: "fit-content !important",
  },
  root: {
    display: "flex",
    flexFlow: "row",
    padding: "1rem 2rem",
    justifyContent: "space-between",
  },
  col: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "24%",
  },
  redButton: {
    backgroundColor: theme.palette.error.main,
    margin: "0 0.5rem 0 0.5rem",
  },
  actions: {
    padding: "0.5rem",
    display: "flex",
  },
  badActions: {
    marginLeft: "auto",
  },
  expeditedIcon: {
    fontSize: "2rem",
    color: "#f58b2f",
    paddingTop: "0.5rem",
  },
  proDesignIcon: {
    fontSize: "2rem",
    color: theme.palette.primary.dark,
    paddingTop: "0.5rem",
  },
  overdueDate: {
    backgroundColor: "red",
    width: "fit-content !important",
  },
  onTimeDate: {
    backgroundColor: "green",
    width: "fit-content !important",
  },
}));

function AssignmentBrief({
  assignment,
  showAddress,
  showClientInfo,
  short,
  index,
  useArchive,
  onAction,
  noCache,
}: Props): React.ReactElement {
  const classes = useStyles();
  const [agent, setAgent] = React.useState<Agent | false | null>(null);
  const [client, setClient] = React.useState<Profile | null>(null);
  const [address, setAddress] = React.useState<Addy | null | "none">(null);

  const [archiveConfirm, setArchiveConfirm] = React.useState<boolean>(false);
  const [isExpedited, setIsExpedited] = React.useState<boolean>(false);
  const [isProDesign, setIsProDesign] = React.useState<boolean>(false);
  const [houseModelStatus, setHouseModelStatus] =
    React.useState<string>("loading");
  const [expeditedStartByDate, setExpeditedStartByDate] =
    React.useState<Date | null>(null);
  const dueDateRef = React.useRef<Date | null>(null);
  const projectId = assignment.projectId;

  async function getProject(): Promise<void> {
    try {
      const project = await getProjectById(projectId);
      setIsExpedited(project.isExpedited ?? false);
      setIsProDesign(project.isProDesign ?? false);

      const assignments = await getAssignmentsByProjectId(projectId);
      // sort assignments to pull status of latest modeling assignment
      assignments.sort(
        (a: Assignment, b: Assignment) => b.createdAt - a.createdAt
      );

      const modelingAssignments =
        assignments.find(
          (modelingAssignment: Assignment) =>
            modelingAssignment.type === "modeling"
        ) ?? null;

      if (modelingAssignments?.completed) {
        setHouseModelStatus("Completed");

        modelingAssignments.submittedAt &&
          setExpeditedStartByDate(
            getExpeditedAssignmentStartByDate(
              modelingAssignments.submittedAt as number
            )
          );
      } else {
        setHouseModelStatus("Not Complete");
      }
    } catch (err) {
      console.error;
    }
  }

  let [cache, updateCache] = useLocalStorage<AssignmentBriefCache>(
    `ASSIGNMENT_BRIEF_${assignment.id}`
  );

  const isPm = React.useRef<boolean>(!!cache?.agentInformation?.isPm);

  if (noCache) {
    cache = null;
  }

  React.useEffect(() => {
    getAgent();
    getProject();
  }, [assignment.id, assignment.assignedTo]);

  React.useEffect((): void | (() => void) => {
    if (agent && !client) {
      const timeout = setTimeout(() => {
        getClient();
      }, 300);

      return () => {
        clearTimeout(timeout);
      };
    }

    return void 0;
  }, [agent]);

  React.useEffect(() => {
    if (client && !address) {
      getAddy();
    }
  }, [client]);

  const addy = React.useMemo(() => {
    if (!address) {
      if (cache?.address) {
        const address = cache.address;

        return `${address.street} ${address.city} ${address.state} ${address.zip}`;
      }
      return <em>loading...</em>;
    }
    if (!address || address === "none") return null;

    return `${address.street} ${address.city} ${address.state} ${address.zip}`;
  }, [address]);

  return (
    <>
      {archiveConfirm && (
        <GenericConfirm
          open={archiveConfirm}
          onClose={() => setArchiveConfirm(false)}
          onSubmit={archiveAssignment}
        />
      )}

      <div style={{ maxWidth: "100vw" }}>
        {showAddress && (
          <>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h4">
                {isExpedited && (
                  <Tooltip title="Client purchased an expedited design package">
                    <FlashOn className={classes.expeditedIcon} />
                  </Tooltip>
                )}
                {isProDesign && (
                  <Tooltip title="Pro design package">
                    <Build className={classes.proDesignIcon} />
                  </Tooltip>
                )}
                {addy || "Address not found"}
              </Typography>
              <Typography variant="h4">{index}</Typography>
            </Box>
            <br />
          </>
        )}
        <div className={classes.root}>
          <div className={classes.col}>
            <Typography variant="caption">Type</Typography>
            <Typography variant="body1">{typeMap[assignment.type]}</Typography>
            <br />
            <Typography variant="caption">Title</Typography>
            <Typography variant="body1">{assignment.title}</Typography>
            <br />
            {isExpedited && (
              <>
                <Typography variant="caption">
                  Expedited Must Start By
                </Typography>
                <Typography
                  variant="body1"
                  className={
                    expeditedStartByDate
                      ? getAssignmentDueDateClass(expeditedStartByDate)
                      : "onTimeDate"
                  }
                >
                  {expeditedStartByDate
                    ? expeditedStartByDate.toLocaleString("en-US", {
                        year: "2-digit",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })
                    : "Pending house model completion"}
                </Typography>
                <br />
              </>
            )}
            {!short && (
              <>
                <Typography variant="caption">Description</Typography>
                <Typography variant="body1">
                  {assignment.description}
                </Typography>
                <br />
              </>
            )}
          </div>
          <div className={classes.col}>
            {!!(agent || (agent !== false && cache?.agentInformation)) && (
              <>
                <Typography variant="caption">Agent Name</Typography>
                <Typography variant="body1">{`${
                  (agent || (agent !== false && cache?.agentInformation) || {})
                    .firstName
                } ${
                  (agent || (agent !== false && cache?.agentInformation) || {})
                    .lastName
                }`}</Typography>
                <br />
                {!short && (
                  <>
                    <Typography variant="caption">
                      {isPm ? "Project Manager" : "Agent"} Email
                    </Typography>
                    <Typography variant="body1">
                      {
                        (
                          agent ||
                          (agent !== false && cache?.agentInformation) ||
                          {}
                        ).email
                      }
                    </Typography>
                    <br />
                  </>
                )}
              </>
            )}
            <Typography variant="caption">Completed</Typography>
            <Typography
              variant="body1"
              classes={{ root: classes.fitContent }}
              style={{
                backgroundColor: assignment.completed ? "green" : "yellow",
              }}
            >
              {!!assignment.completed ? "True" : "False"}
            </Typography>
            <br />
            {!short && (
              <>
                <Typography variant="caption">Paid</Typography>
                <Typography variant="body1">
                  {!!assignment.paid ? "True" : "False"}
                </Typography>
                <br />
              </>
            )}
          </div>
          <div className={classes.col}>
            {!short && (
              <>
                <Typography variant="caption">Creation Date</Typography>
                <Typography variant="body1">
                  {(assignment.assignedAt && mmddyyyy(assignment.createdAt)) ||
                    "unset"}
                </Typography>
                <br />
                <Typography variant="caption">Assignment Date</Typography>
                <Typography variant="body1">
                  {(assignment.assignedAt && mmddyyyy(assignment.assignedAt)) ||
                    "unset"}
                </Typography>
                <br />
              </>
            )}
            <Typography variant="caption">Due Date</Typography>
            <Typography variant="body1" className={getAssignmentDueDateClass()}>
              {(assignment.assignedAt &&
                moment(getDueDate()).format("MM/DD/YYYY hh:mm")) ||
                "unset"}
            </Typography>
            <br />
            <Typography variant="caption">Submission Date</Typography>
            <Typography variant="body1">
              {(assignment.assignedAt && mmddyyyy(assignment.submittedAt)) ||
                "unset"}
            </Typography>
            <br />
            <Typography variant="caption">Last Submitted Date</Typography>
            <Typography variant="body1">
              {(assignment.assignedAt &&
                mmddyyyy(assignment.lastSubmittedAt)) ||
                "N/A"}
            </Typography>
          </div>
          <div className={classes.col}>
            <Typography variant="caption">Materials Received</Typography>
            <Typography variant="body1" classes={{ root: classes.fitContent }}>
              {(function () {
                const materialsReceived =
                  client?.wizardDone || cache?.clientInformation?.wizardDone;

                if (
                  !materialsReceived &&
                  !(client ?? cache?.clientInformation)
                ) {
                  return "Loading...";
                }

                if (materialsReceived) {
                  try {
                    return moment(materialsReceived).format("MM/DD/YYYY hh:mm");
                  } catch (err) {
                    console.error(err);
                    return "Error fetching date";
                  }
                }

                return "Not recieved";
              })()}
            </Typography>
            <br />
            {showClientInfo && (
              <>
                <Typography variant="caption">client name</Typography>
                <Typography variant="body1">
                  {client
                    ? `${client.firstName} ${client.lastName}`
                    : "Loading..."}
                </Typography>
                <br />
              </>
            )}
            <Typography variant="caption">House Model Status: </Typography>
            <Typography variant="body1">{houseModelStatus}</Typography>
            <br />

            {!short && (
              <>
                <Typography variant="caption">Agent Notes</Typography>
                <Typography variant="body1">
                  {assignment.assignmentDeliverables.notes.length
                    ? assignment.assignmentDeliverables.notes
                    : "[none]"}
                </Typography>
                <br />
                <Typography variant="caption">Reopened</Typography>
                <Typography variant="body1">
                  {assignment.reopenedCount && assignment.reopenedCount > 0
                    ? "True"
                    : "False"}
                </Typography>
                <br />
                <Typography variant="caption">Paid Correction</Typography>
                <Typography variant="body1">
                  {assignment.paidCorrection === undefined ||
                  assignment.paidCorrection === null
                    ? "N/A"
                    : assignment.paidCorrection
                    ? "True"
                    : "False"}
                </Typography>
              </>
            )}
          </div>
        </div>

        <Box display="flex" flexDirection="row-reverse" p={1}>
          {useArchive && (
            <Box p={1}>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  setArchiveConfirm(true);
                }}
              >
                Archive
              </Button>
            </Box>
          )}
          {onAction && (
            <Box p={1}>
              <Button variant="contained" onClick={onAction}>
                Actions
              </Button>
            </Box>
          )}
        </Box>
        <Divider></Divider>
      </div>
    </>
  );

  function getAssignmentDueDateClass(
    date: Date = getDueDate() as Date
  ): string {
    if (
      !assignment.completed &&
      assignment.assignedAt &&
      new Date().getTime() > (date?.getTime() || -Infinity)
    ) {
      return classes.overdueDate;
    } else {
      return classes.onTimeDate;
    }
  }

  function getDueDate(): Date | null {
    if (dueDateRef.current || !assignment?.assignedAt) {
      return dueDateRef.current;
    }

    const date = new Date(assignment.assignedAt);
    const timeGiven = assignment.type === "modeling" ? 2 : 4;
    date.setDate(date.getDate() + timeGiven);
    skipWeekend(date);

    return date;
  }

  async function archiveAssignment(): Promise<any> {
    assignment.completed = true;
    return assignment.save();
  }

  async function getClient(): Promise<void> {
    const client = await assignment.getProjectOwner();
    updateCache({
      ...(cache ?? {}),
      clientInformation: {
        firstName: client.firstName,
        lastName: client.lastName ?? "",
        wizardDone: client.wizardDone,
      },
    });

    setClient(client);
  }

  async function getAddy(): Promise<void> {
    if (!client) return;

    const addy = await getAddress(client.id);

    if (!addy) return setAddress("none");

    setAddress(addy);
    updateCache({
      ...(cache ?? {}),
      address: addy,
    });
  }

  async function getAgent(): Promise<void> {
    if (!assignment.assignedTo) {
      return setAgent(false);
    }

    do {
      let agent: Agent | null;

      try {
        agent = await Agent.fetch(assignment.assignedTo);
      } catch (error) {
        console.error(error);
        break;
      }

      if (!agent) {
        setAgent(false);
        break;
      }

      setAgent(agent);
      isPm.current = false;

      return updateCache({
        ...(cache ?? {}),
        agentInformation: {
          firstName: agent.firstName,
          lastName: agent.lastName,
          email: agent.email,
          isPm: true,
        },
      });
    } while (false);

    try {
      const pm = await getProjectManagerById(assignment.assignedTo);
      if (pm.exists && pm.data()) {
        const agent = makeAgentFromPm(
          new ProjectManager({
            ...(pm.data() as ProjectManagerProperties),
            id: pm.id,
          } as any)
        );

        isPm.current = true;
        setAgent(agent);

        updateCache({
          ...(cache ?? {}),
          agentInformation: {
            firstName: agent.firstName,
            lastName: agent.lastName,
            email: agent.email,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export default React.memo(AssignmentBrief);
