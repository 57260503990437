import * as React from "react";
import "firebase/compat/firestore";
import { makeStyles } from "@material-ui/core";
import { Agent, AgentType } from "@yardzen-inc/models";
import { AgentCard } from "./Agent/AgentCard";
import useAgentGroups from "./useAgentGroups";
import { AgentGroup } from "@yardzen-inc/util";
import LoadingAbsCenter from "../../Components/LoadingAbsCenter";
import { ErrorBoundary } from "../../util/ErrorBoundry";
import { filterMatchesAgentOrProfile } from "../../util/functions/filterMatchesAgentOrProfile";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "row wrap",
    padding: "0.5rem 0",
    justifyContent: "center",
  },
});

interface Props {
  type?: AgentType;
  limit?: number | false;
  filter: string;
  disabled?: boolean;
}

const agentCache: { [type: string]: Agent[] } = {};

export default React.memo(({ type, limit, filter, disabled }: Props) => {
  const classes = useStyles();
  const groups = useAgentGroups();
  const [agents, setAgents] = React.useState<Agent[] | null>(null);
  const [filteredAgents, setFilteredAgents] = React.useState<Agent[] | null>(
    null
  );

  const groupMap = React.useMemo(() => {
    if (!groups) {
      return null;
    }

    const map: { [id: string]: AgentGroup } = {};

    for (let group of groups) {
      map[group.id] = group;
    }

    return map;
  }, [groups]);

  React.useEffect(() => {
    if (agentCache[type ?? "_"]) {
      setAgents(agentCache[type ?? "_"]);
    } else {
      getAgents();
    }

    return () => {
      if (agents) {
        for (let agent of agents) {
          agent.destroy();
        }
      }
      if (filteredAgents) {
        for (let agent of filteredAgents) {
          agent.destroy();
        }
      }
    };
  }, [type]);

  const agentCards = React.useMemo(() => {
    if (!filteredAgents || !groupMap || !groups) {
      // Diagnostic log in lieu of proper error handling from `useAgentGroups`
      console.log(
        `filteredAgents: ${filteredAgents} or groupMap: ${groupMap} are missing`
      );

      return null;
    }

    return filteredAgents.map((agent) => (
      <ErrorBoundary key={agent.id}>
        <AgentCard groupMap={groupMap} groups={groups} agent={agent} />
      </ErrorBoundary>
    ));
  }, [filteredAgents]);

  React.useEffect(onFilterChange, [filter, agents, disabled]);

  return (
    <div className={classes.root}>
      {agentCards ? agentCards : <LoadingAbsCenter in />}
    </div>
  );

  function onFilterChange() {
    setFilteredAgents(null);

    const timeout = setTimeout(async () => {
      if (!agents) {
        return setFilteredAgents(null);
      }

      if (filter && disabled) {
        return setFilteredAgents(
          agents.filter(
            (agent) =>
              agent.disabled && filterMatchesAgentOrProfile(agent, filter)
          )
        );
      }

      if (disabled && !filter) {
        return setFilteredAgents(agents.filter((a) => a.disabled));
      }

      if (filter && !disabled) {
        return setFilteredAgents(
          agents.filter((agent) => filterMatchesAgentOrProfile(agent, filter))
        );
      }

      return setFilteredAgents(agents);
    }, 200);

    return () => clearTimeout(timeout);
  }

  function getAgents() {
    (async function () {
      const _agents = await Agent.fetchAllOfType(
        type,
        limit ? limit : void 0,
        null,
        disabled
      );
      agentCache[type ?? "_"] = _agents;
      setAgents(_agents);
    })();
  }
});
