import { BOTANICAL_PACKAGE } from "../../../util/packageTypeConstants";
import { BudgetWithLabel } from "../../../util/hooks/useGetBudgetDataByProjectId";
import { BudgetElementsPriceEstimate } from "../../../util/hooks/useBudgetElementsPriceEstimate";

interface DoesClientHaveBudgetArgs {
  budgetInfo: Pick<BudgetWithLabel, "budgetLabel">;
  wishlistPriceEstimate: BudgetElementsPriceEstimate;
  packageType?: string;
}

/**
 * Determines whether or not the client has a budget.
 * @param args The budget information, wishlist price estimate, and
 * package type for the client.
 * @returns Whether or not the client has a budget.
 */
export const doesClientHaveBudget = ({
  budgetInfo,
  wishlistPriceEstimate,
  packageType,
}: DoesClientHaveBudgetArgs): boolean => {
  // Since botanical clients do not go through the wishlist, we can just check
  // that they have a budget value.
  if (packageType === BOTANICAL_PACKAGE) {
    return budgetInfo.budgetLabel !== "No budget value found";
  }

  // If the client has a wishlist estimate at minimum, then we'll
  // consider them as having a budget.
  return clientHasWishlistEstimate(wishlistPriceEstimate);
};

/**
 * Determines whether or not a client has a wishlist estimate.
 * @param estimate The wishlist estimate to test against.
 * @returns A flag indicating whether or not the estimates exist.
 */
const clientHasWishlistEstimate = (
  estimate: [number | undefined, number | undefined] | null
): boolean => {
  return (
    // We need explicit checks otherwise ts throws a type error.
    estimate !== null &&
    estimateExists(estimate[0]) &&
    estimateExists(estimate[1])
  );
};

/**
 * Determines whether or not an estimate value exists and is non-zero.
 * @param estimate The estimate value to check for existence of.
 * @returns A flag indicating whether or not the estimate exists.
 */
const estimateExists = (estimate: number | void): boolean =>
  // We need explicit checks otherwise ts throws a type error.
  estimate !== undefined && estimate !== 0;
