import { Box, CircularProgress, makeStyles, Theme } from "@material-ui/core";
import {
  ClientBudgetIntent,
  Profile,
  YardDifficultyRating,
} from "@yardzen-inc/models";
import { YZTypography } from "@yardzen-inc/react-common";
import classNames from "classnames";
import React, { FC, useContext } from "react";
import ProfileCtx from "../../util/ProfileContext";
import { DesignerBudgetDisplay } from "./DesignerBudgetDisplay";
import { InternalBudgetDisplay } from "./InternalBudgetDisplay";
import { doesClientHaveBudget } from "./util/doesClientHaveBudget";
import { getClientBudgetIntentText } from "./util/getClientBudgetIntentText";
import { getWishlistEstimateRangeText } from "./util/getWishlistEstimateRangeText";
import { useGetBudgetDisplayData } from "../../../src/util/hooks/useGetBudgetDisplayData";

// This interface will allow us to provide additional css classes
// to our budget components. We'll use it to provide page specific styling
// for example, bolding the captions in the design brief.
export interface BudgetDisplayWrapperClasses {
  budgetCaptions?: string;
  budgetTextContainer?: string;
  budgetText?: string;
}

export interface BudgetDisplayWrapperProps {
  projectId: string;
  packageType?: string;
  yardDifficultyRating?: YardDifficultyRating | null;
  additionalCssClasses?: BudgetDisplayWrapperClasses;
  clientBudgetIntent?: ClientBudgetIntent | null;
}

type ProfileContextReturnValue =
  | false
  | null
  | (Profile & {
      isEmployee: boolean;
    });

const useStyles = makeStyles((theme: Theme) => ({
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "max-content",
  },
  captionText: {
    marginRight: theme.spacing(1),
  },
}));

export const BudgetDisplayWrapper: FC<BudgetDisplayWrapperProps> = ({
  projectId,
  packageType,
  yardDifficultyRating,
  additionalCssClasses,
  clientBudgetIntent,
}) => {
  const profile = useContext(ProfileCtx) as ProfileContextReturnValue;

  const budgetInformation = useGetBudgetDisplayData(projectId);
  const wishlistPriceEstimates = budgetInformation.budgetChecklist;

  const wishlistEstimateRangeText = getWishlistEstimateRangeText({
    wishlistEstimates: wishlistPriceEstimates,
    packageType,
  });

  const clientBudgetIntentText = getClientBudgetIntentText({
    intent: clientBudgetIntent,
    packageType,
  });

  const { loadingContainer, captionText } = useStyles();

  if (budgetInformation.loading) {
    return (
      <Box className={loadingContainer}>
        <CircularProgress />
        <YZTypography>Loading budget data...</YZTypography>
      </Box>
    );
  }

  if (didErrorOccurRetrievingBudget(budgetInformation.error)) {
    return (
      <Box>
        <YZTypography color="error">
          An error occurred fetching the budget data for this client. Please try
          again or contact support.
        </YZTypography>
      </Box>
    );
  }

  if (
    !doesClientHaveBudget({
      budgetInfo: budgetInformation,
      wishlistPriceEstimate: wishlistPriceEstimates,
      packageType,
    })
  ) {
    return (
      <Box>
        <YZTypography>
          There is no budget data for this project yet.
        </YZTypography>
      </Box>
    );
  }

  return (
    <Box>
      {profile && profile.isEmployee ? (
        <InternalBudgetDisplay
          budgetMetadata={budgetInformation.budgetMetadata}
          projectId={projectId}
          yardDifficultyRating={yardDifficultyRating}
          projectHasAdjustedBudgetOverride={
            budgetInformation.budgetLabel === "Adjusted Budget"
          }
          formattedAdjustedBudget={budgetInformation.formattedBudgetValue}
          wishlistEstimateRangeText={wishlistEstimateRangeText}
          additionalCssClasses={additionalCssClasses}
          packageType={packageType}
        />
      ) : (
        <DesignerBudgetDisplay
          // We pass in an empty string if no budget was found so that
          // the empty message is displayed.
          formattedDesignerVisibleBudget={
            budgetInformation.budgetLabel === "No budget value found"
              ? ""
              : budgetInformation.formattedBudgetValue
          }
          wishlistEstimateRangeText={wishlistEstimateRangeText}
          additionalCssClasses={additionalCssClasses}
        />
      )}
      <Box display="flex">
        <YZTypography
          className={classNames(
            captionText,
            additionalCssClasses?.budgetCaptions
          )}
        >
          Client Sentiment:
        </YZTypography>
        <YZTypography>{clientBudgetIntentText}</YZTypography>
      </Box>
    </Box>
  );

  /**
   * Checks whether or not an applicable error occurred when retrieving
   * the budget.
   * @param budgetError The error to check for existence of.
   * @returns A flag indicating whether or not an applicable error occurred.
   */
  function didErrorOccurRetrievingBudget(budgetError: string | null): boolean {
    // We want to ignore the case where the budget was not found because our
    // components have displays for when the budget is missing.
    return budgetError !== null && budgetError !== "No budget value found";
  }
};
