import React, { FC, ReactNode } from "react";
import { Box, Typography, useTheme, Theme } from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";
import { OnboardData } from "./OnboardDataProvider";
import capitalizeFirstLetter from "../../util/capitalizeFirstLetter";

export interface OnboardFunctionsDisplayProps extends BoxProps {
  onboardData: OnboardData;
  yard: string;
}

const OnboardFunctionsDisplay: FC<OnboardFunctionsDisplayProps> = ({
  onboardData,
  yard,
  ...containerProps
}) => {
  const theme = useTheme<Theme>();

  return (
    <Box {...containerProps} display="flex" flexDirection="row" flexWrap="wrap">
      <Box display="flex" flexDirection="row" m={1} p={1} bgcolor="whitesmoke">
        <Box pr={1} display="flex" justifyContent="center" alignItems="center">
          <Typography noWrap style={{ height: "min-content" }}>
            YARD FUNCTIONS:
          </Typography>
        </Box>
        {renderFunctions()}
      </Box>
    </Box>
  );

  function renderFunctions(): ReactNode | ReactNode[] {
    // @ts-ignore
    if (onboardData.priorities && onboardData.priorities[yard]) {
      // @ts-ignore
      return (onboardData.priorities[yard] as string[]).map((prio, i) => {
        return (
          <Box
            key={`${prio}-${i}`}
            bgcolor={theme.palette.secondary.light}
            p={1}
            m={1}
            width="min-content"
            height="min-content"
          >
            <Typography
              style={{
                width: "min-content",
                height: "min-content",
              }}
              noWrap
            >
              {capitalizeFirstLetter(prio)}
            </Typography>
          </Box>
        );
      });
    }

    return (
      <Typography style={{ padding: theme.spacing(1), fontStyle: "italic" }}>
        No Functions selected
      </Typography>
    );
  }
};

export { OnboardFunctionsDisplay };
export default OnboardFunctionsDisplay;
