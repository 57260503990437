import React, { FC } from "react";
import { DesignAssignmentList } from "./DesignAssignmentList";
import { Box, CircularProgress } from "@material-ui/core";
import { pangaeaV1API } from "../../api/pangaeaV1API";
import SnackbarError from "../../Components/SnackbarError";

const DssQueue: FC = () => {
  const {
    data: queue,
    isLoading,
    error,
  } = pangaeaV1API.endpoints.getSelfAssignQueue.useQuery({});

  return !isLoading ? (
    <DesignAssignmentList queue={queue ?? []}></DesignAssignmentList>
  ) : (
    <Box
      p={2}
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
      <SnackbarError
        errorText={error ? JSON.stringify(error) : null}
        handleSnackbarClose={() => void 0}
      />
    </Box>
  );
};

export { DssQueue };
export default DssQueue;
