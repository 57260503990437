import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField } from "@material-ui/core";
import firebase from "firebase/compat/app";

export default function CollectProfile({ authUser }: any) {
  const [open, setOpen] = React.useState(true);
  const [data, setData]: [any, any] = React.useState({
    firstName: "",
    lastName: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Hi! It looks you don't have a profile yet."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            We need some information so we can implement upcoming features.
          </DialogContentText>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              style={{ margin: "1rem" }}
              value={data.firstName}
              onChange={(e) => handleChange(e.target.value, "firstName")}
              label="First Name"
            />
            <TextField
              style={{ margin: "1rem" }}
              value={data.lastName}
              onChange={(e) => handleChange(e.target.value, "lastName")}
              label="Last Name"
            />
            <TextField
              style={{ margin: "1rem" }}
              value={data.email || authUser.email}
              onChange={(e) => handleChange(e.target.value, "email")}
              label="Email"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  function handleChange(value: string, name: any) {
    console.log(value);

    setData((prevData: any) => {
      return { ...prevData, [name]: value };
    });
  }

  function handleSubmit() {
    let docData = {
      ...data,
      email: authUser.email,
    };

    if (authUser.email.includes("@yardzen.com")) {
      docData = { ...docData, ...{ isEmployee: true } };
    } else {
      docData = { ...docData, ...{ isEmployee: false, isDesigner: true } };
    }

    firebase
      .firestore()
      .collection("profiles")
      .doc(authUser.uid)
      .set(docData)
      .then(() => {
        // @ts-ignore
        location.reload(true);
      })
      .catch((err) => {
        console.error("Error setting profile: ", err);
      });
  }
}
