import React from "react";
import {
  Box,
  Dialog,
  Typography,
  DialogContent,
  LinearProgress,
  makeStyles,
} from "@material-ui/core";
import CloseButton from "../../../Components/CloseButton";
import {
  Project,
  Agent,
  Revision,
  DesignAssignment,
  CADOrRenderOption,
  ReasonForCorrectionOrRevision,
} from "@yardzen-inc/models";
import moment from "moment";
import DSATModal from "../../TypeformDetail/DSATModal";
import { ProfileProperties } from "@yardzen-inc/models";
import { DssStateMap, getNextSelfAssignState } from "../../../util/selfAssign";
import firebase from "firebase/compat/app";
import { YZButton } from "@yardzen-inc/react-common";
import { useApolloClient } from "@apollo/client";
import insertAssignmentMetadataInPostgres from "../../../util/insertAssignmentMetadataInPostgres";
import getDesignAssignmentIdFromRevisionId from "../../../util/firebase/functions/getDesignAssignmentIdFromRevisionId";
import { ErrorWithHumanMessage } from "../../../util/errors/ErrorWithHumanMessage";
import { liisaErrorAlert } from "../../../util/genericAlert";
import { SelectRevisionStep } from "./SelectRevisionStep";
import { SelectPaidOrUnpaidOptionsStep } from "./SelectPaidOrUnpaidOptionsStep";
import { SelectReasonsForCorrectionsOrRevisionsStep } from "./SelectReasonsForCorrectionsOrRevisionsStep";
import { UserCtx } from "../../../util/UserContext";
import { updateRevisionIssuedBy } from "../../../util/firebase/firebaseClient";

const useStyles = makeStyles(() => ({
  paper: {
    width: "660px",
  },
}));
export interface Props {
  onClose: () => void;
  open: boolean;
  project: Project;
  designer: Agent | null;
  correction?: boolean;
  revisions?: Revision[] | null;
  sendNotificationToDesigner: (
    assign?: DesignAssignment | undefined
  ) => Promise<firebase.functions.HttpsCallableResult | undefined>;
  clientRecord: ProfileProperties;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
  dssStates: Readonly<DssStateMap> | null;
}

enum DesignAssignmentWizardStep {
  SelectRevision,
  SelectPaidOrUnpaid,
  SelectReasonsForCorrectionsOrRevisions,
  AssignmentCreated,
}

export enum DSATReviewState {
  NoDSAT = "no-dsat",
  Reviewed = "reviewed",
  NotReviewed = "not-reviewed",
}
export interface DesignAssignmentWizardState {
  loading: boolean;
  confirm: boolean;
  listed: boolean;
  creationInProgress: boolean;
  dueDate: string;
  revisionToCopy: string;
  designNoteDrawerOpen: boolean;
  designNoteContent: string;
  manuallyAssignDate: boolean;
  dsatWizardOpen: boolean;
  isPaidCorrection: boolean | undefined;
  cadOrRenderOption: CADOrRenderOption | undefined;
  reasonsForCorrectionOrRevision: ReasonForCorrectionOrRevision[];
  freeformOtherReasonForCorrectionOrRevision?: string;
  step: DesignAssignmentWizardStep;
}

const initialState: DesignAssignmentWizardState = {
  loading: false,
  confirm: false,
  listed: true,
  creationInProgress: false,
  dueDate: "",
  revisionToCopy: "",
  designNoteDrawerOpen: false,
  designNoteContent: "",
  manuallyAssignDate: false,
  dsatWizardOpen: false,
  step: DesignAssignmentWizardStep.SelectRevision,
  isPaidCorrection: undefined,
  cadOrRenderOption: undefined,
  reasonsForCorrectionOrRevision: [],
  freeformOtherReasonForCorrectionOrRevision: undefined,
};

let DesignAssignmentCreationWizard: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { dssStates } = props;

  const [wizardState, setWizardState] =
    React.useState<DesignAssignmentWizardState>(initialState);

  const dsatReviewState = React.useRef<DSATReviewState | undefined>(undefined);

  const user = React.useContext(UserCtx);

  const apolloClient = useApolloClient();
  window.onbeforeunload = assignmentCreationInProgressWarning;
  React.useEffect(resetOnCloseOpen, [props.open]);

  React.useEffect(() => {
    if (props.revisions?.length) {
      // select the most recent revision to copy by default
      setWizardState({
        ...wizardState,
        revisionToCopy: props.revisions[props.revisions.length - 1].id,
      });
    }
  }, [props.revisions]);

  return (
    <>
      <DSATModal
        project={props.project}
        open={wizardState.dsatWizardOpen}
        onClose={() =>
          setWizardState((oldState) => ({
            ...oldState,
            dsatWizardOpen: false,
          }))
        }
        clientRecord={props.clientRecord}
        onDsatOpened={() =>
          (dsatReviewState.current = DSATReviewState.Reviewed)
        }
        onDsatLoad={(dsatExists: boolean) => {
          if (dsatExists) {
            dsatReviewState.current = DSATReviewState.NotReviewed;
          } else {
            dsatReviewState.current = DSATReviewState.NoDSAT;
          }
        }}
      />
      <Dialog
        open={props.open}
        maxWidth="md"
        classes={classes}
        onClose={!wizardState.creationInProgress ? props.onClose : () => {}}
      >
        <LinearProgress
          variant="indeterminate"
          style={{ opacity: wizardState.loading ? 1 : 0 }}
        />
        <Box p={1}>
          {wizardState.step === DesignAssignmentWizardStep.SelectRevision && (
            <Typography variant="h4">Create Assignment</Typography>
          )}
          {!wizardState.creationInProgress && (
            <CloseButton onClick={props.onClose} top={8} right={8} />
          )}
        </Box>

        <DialogContent>
          {wizardState.creationInProgress ? (
            <Box p={2}>
              <Typography variant="h6" align="center">
                Warning: Don't leave or refresh the page until the assignment
                creation process has completed :)
              </Typography>
            </Box>
          ) : (
            <>{renderContent()}</>
          )}
        </DialogContent>
      </Dialog>
    </>
  );

  function renderContent(): React.ReactNode {
    const { designAssignmentStatus } = props.project;
    const handleNextButtonOnClick = () => {
      setWizardState({
        ...wizardState,
        step: DesignAssignmentWizardStep.SelectReasonsForCorrectionsOrRevisions,
      });
    };

    const { correctionFor } =
      props.correction && dssStates
        ? getNextSelfAssignState(
            props.project.designAssignmentStatus,
            dssStates,
            true
          )
        : { correctionFor: undefined };

    const handleSubmitButtonOnClick = (withDesignerPaidFlow: boolean): void => {
      dsatReviewState.current === DSATReviewState.NotReviewed
        ? setWizardState({ ...wizardState, dsatWizardOpen: true })
        : withDesignerPaidFlow
        ? setWizardState({
            ...wizardState,
            step: DesignAssignmentWizardStep.SelectPaidOrUnpaid,
          })
        : createAssignment();
    };

    return (
      <>
        {wizardState.step === DesignAssignmentWizardStep.SelectRevision && (
          <SelectRevisionStep
            wizardState={wizardState}
            setWizardState={setWizardState}
            revisions={props.revisions}
            handleSubmitButtonOnClick={handleSubmitButtonOnClick}
            correctionFor={correctionFor}
            designAssignmentStatus={designAssignmentStatus}
            designer={props.designer}
            dsatReviewState={dsatReviewState}
          />
        )}
        {wizardState.step === DesignAssignmentWizardStep.SelectPaidOrUnpaid && (
          <SelectPaidOrUnpaidOptionsStep
            wizardState={wizardState}
            setWizardState={setWizardState}
            handleNextButtonOnClick={handleNextButtonOnClick}
            clientRecord={props.clientRecord}
            correctionFor={correctionFor}
            project={props.project}
          />
        )}
        {wizardState.step ===
          DesignAssignmentWizardStep.SelectReasonsForCorrectionsOrRevisions && (
          <SelectReasonsForCorrectionsOrRevisionsStep
            wizardState={wizardState}
            setWizardState={setWizardState}
            createAssignment={createAssignment}
            correctionFor={correctionFor}
          />
        )}
        {wizardState.step === DesignAssignmentWizardStep.AssignmentCreated && (
          <>
            <Typography variant="h5" gutterBottom align="center">
              Assignment Created
            </Typography>
            <Box display="flex" flexDirection="row" justifyContent="center">
              <YZButton
                variant="contained"
                color="primary"
                onClick={props.onClose}
              >
                Go Back
              </YZButton>
            </Box>
          </>
        )}
      </>
    );
  }

  async function createAssignment() {
    setWizardState({ ...wizardState, loading: true, creationInProgress: true });

    const createDesignAssignment = firebase
      .functions()
      .httpsCallable("createDesignAssignment");

    let revision: Revision | undefined = undefined;
    let designAssignment: DesignAssignment | null = null;
    let clientErrorMessage: string | undefined = undefined;

    try {
      if (!dssStates) {
        clientErrorMessage = "No DSS STATES FOUND!";
        throw new Error(clientErrorMessage);
      }

      // TODO - move revision creation to firebase-functions
      try {
        revision = await Revision.create(
          props.project.profileId,
          props.project.id
        );
        if (user) updateRevisionIssuedBy(revision, user);
      } catch (error) {
        clientErrorMessage = "Failed to clone or create revision";
        throw new ErrorWithHumanMessage(error as Error, clientErrorMessage);
      }

      if (!revision.id || !revision.dateCreated) {
        clientErrorMessage = "Revision document creation failed";
        throw new ErrorWithHumanMessage(
          new Error(clientErrorMessage),
          clientErrorMessage
        );
      }

      let newDesignAssignmentId: string;

      try {
        const res = await createDesignAssignment({
          projectId: props.project.id,
          revisionId: revision.id,
          isCorrectionAssignment: props.correction,
          listedForAssignment: wizardState.listed,
          dueDate: getDueDate(),
          projectManagerNotes: wizardState.designNoteContent,
          isPaidCorrection: wizardState.isPaidCorrection,
          cadOrRenderOption: wizardState.cadOrRenderOption,
          reasonsForCorrectionOrRevision:
            wizardState.reasonsForCorrectionOrRevision,
          freeformOtherReasonForCorrectionOrRevision:
            wizardState.freeformOtherReasonForCorrectionOrRevision,
        });

        newDesignAssignmentId = res.data;
      } catch (error) {
        clientErrorMessage = "Failed to create design assignment";
        throw new ErrorWithHumanMessage(error as Error, clientErrorMessage);
      }

      try {
        designAssignment = await DesignAssignment.fetch(
          props.project.id,
          newDesignAssignmentId
        );
      } catch (error) {
        clientErrorMessage = "Failed to fetch design assignment";
        throw new ErrorWithHumanMessage(error as Error, clientErrorMessage);
      }

      if (!designAssignment) {
        clientErrorMessage =
          "Failed to fetch design assignment: does not exist";
        throw new ErrorWithHumanMessage(
          new Error(clientErrorMessage),
          clientErrorMessage
        );
      }

      let oldDesignAssignmentId: string | null = null;

      if (wizardState.revisionToCopy) {
        const oldRevisionId = wizardState.revisionToCopy;
        try {
          oldDesignAssignmentId = await getDesignAssignmentIdFromRevisionId(
            oldRevisionId
          );
        } catch (error) {
          clientErrorMessage =
            "Failed to get old design assignment id from revision id";
          throw new ErrorWithHumanMessage(error as Error, clientErrorMessage);
        }
      }

      try {
        await insertAssignmentMetadataInPostgres(
          oldDesignAssignmentId,
          newDesignAssignmentId,
          apolloClient
        );
      } catch (error) {
        clientErrorMessage =
          "Failed to create design assignment. Did you try to copy assets from a revision with no assets? If so, please select another revision to copy or select 'No'.";
        throw new ErrorWithHumanMessage(
          error as Error,
          "Failed to insert assignment metadata in postgres"
        );
      }

      if (designAssignment?.type !== "v1") {
        try {
          await props.sendNotificationToDesigner(designAssignment);
        } catch (error) {
          //do not throw here - we do not want to roll back if this email fails
          clientErrorMessage =
            "Failed to send notification to designer!! Design assignment was created successfully.";
        }
      }
    } catch (error) {
      // something failed in the creation process
      // initiate rollback sequence
      let rollbackFailed = false;

      try {
        await firebase
          .firestore()
          .collection("projects")
          .doc(props.project.id)
          .update({
            currentDesignAssignmentId: props.project.currentDesignAssignmentId,
            designAssignmentStatus: props.project.designAssignmentStatus,
          });
      } catch (error) {
        rollbackFailed = true;
        liisaErrorAlert(
          "DesignAssignmentCreationWizard: createAssignment",
          `Failed to roll back project document properties:
          currentDesignAssignmentId: ${props.project.currentDesignAssignmentId}
          designAssignmentStatus: ${props.project.designAssignmentStatus}`
        );
      }

      try {
        revision && (await revision.delete());
      } catch {
        rollbackFailed = true;
        liisaErrorAlert(
          "DesignAssignmentCreationWizard: createAssignment",
          `Failed to roll back revision document:
          revisionId: ${revision?.id}`
        );
      }

      try {
        designAssignment && (await designAssignment.delete());
      } catch {
        rollbackFailed = true;
        liisaErrorAlert(
          "DesignAssignmentCreationWizard: createAssignment",
          `Failed to roll back design assignment document:
          designAssignmentId: ${designAssignment?.id}`
        );
      }

      let alertMsg = `Failed to create new design assignment.\nprojectId: ${props.project.id}`;

      wizardState.revisionToCopy &&
        (alertMsg += `\nrevision copied from: ${wizardState.revisionToCopy}`);

      if (error instanceof ErrorWithHumanMessage) {
        alertMsg += `\nMessage: ${error.humanReadableMessage}`;
      }

      if (error instanceof Error || error instanceof ErrorWithHumanMessage) {
        alertMsg += `\nError: ${error.message}`;
      }

      liisaErrorAlert(
        "DesignAssignmentCreationWizard: createAssignment",
        alertMsg
      );

      if (rollbackFailed) {
        clientErrorMessage =
          "Failed to create design assignment. There was an error rolling back one or more changes. Please contact engineering.";
      }

      if (!clientErrorMessage) {
        clientErrorMessage =
          "Failed to create design assignment. Please try again. If this error persists, please contact engineering.";
      }
    } finally {
      if (clientErrorMessage) {
        props.setError(clientErrorMessage);
        props.onClose();
      }
      setWizardState({
        ...wizardState,
        loading: false,
        creationInProgress: false,
        step: clientErrorMessage
          ? DesignAssignmentWizardStep.SelectRevision
          : DesignAssignmentWizardStep.AssignmentCreated,
      });
    }
  }

  function resetOnCloseOpen(): void {
    setWizardState({
      ...wizardState,
      listed: true,
      confirm: false,
      loading: false,
      step: DesignAssignmentWizardStep.SelectRevision,
      dueDate: "",
      isPaidCorrection: undefined,
      cadOrRenderOption: undefined,
      reasonsForCorrectionOrRevision: [],
    });
  }

  function getDueDate(): number | null {
    return !!(wizardState.dueDate && wizardState.manuallyAssignDate)
      ? moment(wizardState.dueDate).unix() * 1000
      : null;
  }

  function assignmentCreationInProgressWarning() {
    if (wizardState.creationInProgress) {
      return "Assignment creation in process! Please wait a second until the assignment creation process finishes :)";
    }
    return undefined;
  }
};

DesignAssignmentCreationWizard.defaultProps = {
  correction: false,
};

DesignAssignmentCreationWizard = React.memo(
  DesignAssignmentCreationWizard,
  (n, p) => n.open === p.open
);

export default DesignAssignmentCreationWizard;
