import * as React from "react";
import { makeStyles, Typography, Tooltip } from "@material-ui/core";
import { Profile, Revision } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import GenericConfirm from "../../../Components/GenericConfirm";
import AllowAnnotationsSwitcher from "../AllowAnnotationsSwitcher";
import { YZButton } from "@yardzen-inc/react-common";

interface Props {
  isEmployee?: boolean;
  canEdit: boolean;
  setCanEdit: (canEdit: boolean) => void;
  conceptualRevisionId?: string;
  finalRevisionId?: string;
  revision: Revision;
  clientRecord: Profile;
  setBlurbModalOpen: (open: boolean) => void;
  v3RevisionId?: string;
}

function DesignerAfterSentToClient({
  isEmployee,
  canEdit,
  setCanEdit,
  conceptualRevisionId,
  finalRevisionId,
  revision,
  setBlurbModalOpen,
  clientRecord,
  v3RevisionId,
}: Props): React.ReactElement {
  const classes = useStyles();
  const isFinal = revision.id === finalRevisionId;
  const isConceptual = revision.id === conceptualRevisionId;
  const isV3 = revision.id === v3RevisionId;
  const isActive = isFinal || isConceptual || isV3;
  const oldAccount = !finalRevisionId && !conceptualRevisionId;
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  return (
    <div style={{ margin: "auto" }}>
      {isActive && (
        <Typography color={isActive ? "error" : "inherit"} variant="h5">
          This revision is active, and all changes made will be instantly
          viewable by the client.
        </Typography>
      )}
      {!oldAccount && isEmployee && !isActive && (
        <Typography>This revision is not visible to the client</Typography>
      )}
      {oldAccount && isEmployee && (
        <Typography>
          This profile is still using the old system, which means the last two
          revisions and the conceptual (v1) and final (v2), respectively. Please
          specify the v1 and v2 revisions, if applicable.
        </Typography>
      )}
      <div className={classes.actions}>
        {isEmployee && (
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "1rem" }}>
              {isActive && (
                <Tooltip
                  title={
                    isSafeToUnSend()
                      ? "Removes the revision from the client view"
                      : `Unable to unsend due to the existence of
                        a final revision. You would either need to first
                        unsend the final revision and then this one, or
                        replace this revision with another.`
                  }
                >
                  <span>
                    <YZButton
                      disabled={!isSafeToUnSend()}
                      onClick={() => setModalOpen(true)}
                      variant="outlined"
                    >
                      Unsend
                    </YZButton>
                  </span>
                </Tooltip>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
              }}
            >
              <YZButton
                variant="contained"
                onClick={() => {
                  if (canEdit && isActive) {
                    return setBlurbModalOpen(true);
                  }
                  setCanEdit(!canEdit);
                }}
              >
                {canEdit
                  ? "Done Editing"
                  : `Edit ${isActive ? "dangerously" : ""}`}
              </YZButton>
              {isActive && (
                <Typography variant="caption" color="error">
                  All changes are immediate!
                </Typography>
              )}
            </div>
            {isActive && (
              <AllowAnnotationsSwitcher
                pl={2}
                revisionId={revision.id}
                label="Allow new Annotations"
              />
            )}
          </div>
        )}

        {!!isEmployee && !isActive && (
          <div>
            <Tooltip title="Revision must include images">
              <span>
                <YZButton
                  onClick={() => setBlurbModalOpen(true)}
                  variant="contained"
                >
                  Set as active revision
                </YZButton>
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      <GenericConfirm
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={async () => {
          await unSend();
          setModalOpen(false);
        }}
        body={`
        This action will remove the client’s ability to view the ${
          isConceptual ? "conceptual" : "final"
        } revision, which includes all renderings and supplemental files.

          Example: if this is a final revision, the next time the client logs in,
          they will be dropped into the “conceptual” tab and the “finalize” tab will be greyed out.

          The client will not be notified of this action.

          Would you like to proceed?
        `}
      />
    </div>
  );

  function isSafeToUnSend() {
    if (
      clientRecord.package !== "botanical" &&
      !!finalRevisionId?.length &&
      isConceptual
    ) {
      return false;
    }

    if (isFinal && !!v3RevisionId?.length) {
      return false;
    }

    return true;
  }

  async function unSend() {
    const clientRef = firebase
      .firestore()
      .collection("profiles")
      .doc(clientRecord.id);

    if (isConceptual) {
      return clientRef.update({
        conceptualRevision: null,
      });
    }

    if (isFinal) {
      return clientRef.update({
        finalRevision: null,
      });
    }

    if (isV3) {
      return clientRef.update({
        v3Revision: null,
      });
    }
  }
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  submitButton: {
    margin: "1rem auto 1.5rem auto",
    textAlign: "center",
    maxWidth: "50%",
  },
  actions: {
    marginTop: "1.75rem",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
});

export default DesignerAfterSentToClient;
