import * as React from "react";
import { Snackbar } from "@material-ui/core";
import GenericSnackbarContent from "./GenericSnackbarContent";

export interface GenericSnackBarProps {
  message?: string;
  className?: string;
  in?: boolean;
  orientation?: {
    vertical: "bottom" | "top";
    horizontal: "right" | "left" | "center";
  };
  variant: "success" | "warning" | "error" | "info";
  onClose: () => void;
  autoHide?: boolean;
}

function GenericSnackBar({
  orientation = {
    vertical: "bottom",
    horizontal: "left",
  },
  message,
  className,
  variant,
  onClose,
  in: _in,
  autoHide = true,
}: GenericSnackBarProps): React.ReactElement {
  const [open, setOpen] = React.useState(_in ?? true);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
    setOpen(false);
  };

  React.useEffect(() => {
    if (_in !== null || _in !== undefined) {
      setOpen(!!_in);
    }
  }, [_in]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: orientation.vertical,
        horizontal: orientation.horizontal,
      }}
      open={!!open}
      autoHideDuration={autoHide ? 6000 : null}
      onClose={handleClose}
    >
      <GenericSnackbarContent
        message={message}
        variant={variant}
        className={className}
        onClose={handleClose}
      />
    </Snackbar>
  );
}

export { GenericSnackBar };
export default GenericSnackBar;
