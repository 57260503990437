import React, { FC, useMemo, useState, useEffect } from "react";
import { PageComponentProps } from "./CurrentJobPageMap";
import parseExperienceVersion from "../util/parseExperienceVersion";
import BasicOnboardPropertyDataSummary from "../ClientDetail/Onboarding/BasicOnboardPropertyDataSummary";
import { CircularProgress } from "@material-ui/core";
import { OnboardData } from "../ClientDetail/Onboarding/OnboardDataProvider";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import mediaTagGalleryPageFactory from "./mediaTagGalleryPageFactory";

export interface PropertyPageWrapperProps extends PageComponentProps {}

const OldPropertyPage = mediaTagGalleryPageFactory(["property"]);

const PropertyPageWrapper: FC<PropertyPageWrapperProps> = (props) => {
  const [forceOldPropertyView, setForceOldPropertyView] =
    useState<boolean>(false);
  const [onboardData, setOnboardData] = useState<OnboardData | null | false>(
    null
  );

  const [major, minor] = useMemo(
    () => parseExperienceVersion(props.project.experienceVersion),
    [props.project.experienceVersion]
  );

  const showMyyardData = useMemo(() => {
    return major >= 2 && minor >= 1;
  }, [major, minor]);

  useEffect(listenForOnboardData, [showMyyardData]);

  if (showMyyardData && !forceOldPropertyView) {
    if (!onboardData) {
      return <CircularProgress />;
    }

    return (
      <BasicOnboardPropertyDataSummary
        toolbarTopOffest={0}
        onboardData={onboardData}
        alignItems="center"
        pm={false}
        project={props.project}
      />
    );
  }

  return <OldPropertyPage {...props} />;

  function listenForOnboardData(): (() => void) | void {
    if (!props.project || !showMyyardData) {
      return void 0;
    }

    return firebase
      .firestore()
      .collection("onboardStates")
      .where("projectId", "==", props.project.id)
      .onSnapshot((snap) => {
        if (!snap.docs.length) {
          setOnboardData(false);
          setForceOldPropertyView(true);
          return;
        }

        const data = {
          ...(snap.docs[0].data() as any),
          id: snap.docs[0].id,
        };
        if (
          !data?.yardsStatusObj ||
          !Object.keys(data?.yardsStatusObj ?? {}).length
        ) {
          setOnboardData(false);
          setForceOldPropertyView(true);
          return;
        } else {
          setOnboardData(data);
        }
      });
  }
};

export { PropertyPageWrapper };
export default PropertyPageWrapper;
