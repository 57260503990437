import { makeStyles } from "@material-ui/core";

const useDesignBreifPageStyles = makeStyles((theme) => ({
  pageWidthContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    width: "100%",
    padding: theme.spacing(1),
  },
  checklistContainer: {
    maxWidth: "1200px",
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
  },
}));

export { useDesignBreifPageStyles };
export default useDesignBreifPageStyles;
