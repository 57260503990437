import * as React from "react";
import { TextField, Box, Typography } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { Media } from "@yardzen-inc/models";
import { CloudUpload } from "@material-ui/icons";
import { UserCtx } from "../../util/UserContext";
import { INSERT_GEOZONE_FILE } from "@yardzen-inc/graphql";
import { GenericLoadingSpinner, YZTypography } from "@yardzen-inc/react-common";

const toGeojson = require("@tmcw/togeojson");
const geojsonValidation = require("geojson-validation");

enum GeozoneFileType {
  geojson = "geojson",
  kml = "kml",
}

interface IReplaceGeoZoneFileProps {}

const ReplaceGeoZoneFile: React.FunctionComponent<IReplaceGeoZoneFileProps> = (
  props
) => {
  const inputComponentRef = React.useRef(null);

  const [insertGeozoneFile] = useMutation(INSERT_GEOZONE_FILE, {
    onError: (err) => console.error(err),
    onCompleted: () => {
      // use 2 to handle "Done" status
      setUploadProgress(2);
    },
  });

  // use -1 to handle "pending" status
  const [uploadProgress, setUploadProgress] = React.useState(-1);
  const [pageLoaded, setPageLoaded] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => setPageLoaded(true), 5000);
  }, []);

  const onUploadProgress = (
    file: () => any,
    snapshot: firebase.storage.UploadTaskSnapshot
  ) => {
    setUploadProgress(() => snapshot.bytesTransferred / snapshot.totalBytes);
    if (
      snapshot.ref.fullPath &&
      snapshot.bytesTransferred >= snapshot.totalBytes
    ) {
      onFileUploaded(
        `${process.env.REACT_APP_IMAGE_DOMAIN}/${snapshot.ref.fullPath}`
      );
    }
  };

  const [filename, setFilename] = React.useState("");
  const UserContext = React.useContext(UserCtx);

  const onFileUploaded = (url: string) => {
    setUploadProgress(0.75);
    insertGeozoneFile({ variables: { url } });
  };

  const readFileContent = (file: File) => {
    return new Promise((resolve, reject) => {
      let content = "";

      const reader = new FileReader();

      reader.onloadend = function (e: any) {
        content = e.target.result;
        resolve(content as string);
      };

      reader.onerror = function (e: any) {
        reject(e);
      };

      reader.readAsText(file);
    });
  };

  class GeozoneFile {
    file: File;
    content: string;

    constructor(file: File, content: string) {
      this.file = file;
      this.content = content;
    }

    get type(): GeozoneFileType | null {
      if (this.file.name.match(/\.[^\.]*json$/)) {
        return GeozoneFileType.geojson;
      } else if (this.file.name.match(/\.kml$/)) {
        return GeozoneFileType.kml;
      } else {
        return null;
      }
    }

    isValid = (): boolean => {
      if (this.type === GeozoneFileType.geojson) {
        try {
          JSON.parse(this.content);
        } catch {
          return false;
        }
      }

      return geojsonValidation.valid(this.contentAsJson);
    };

    get contentAsJson(): JSON | null {
      if (this.type === GeozoneFileType.geojson) {
        return JSON.parse(this.content);
      } else if (this.type === GeozoneFileType.kml) {
        const kml = new DOMParser().parseFromString(this.content, "text/xml");
        const json = toGeojson.kml(kml);

        json.features.forEach((feature: any) => {
          if (!feature.properties.Name && feature.properties.name) {
            feature.properties.Name = feature.properties.name;
            delete feature.properties.name;
          }
        });

        return json;
      } else {
        return null;
      }
    }

    toGeojsonFile = (): File => {
      const str = JSON.stringify(this.contentAsJson);
      const bytes = new TextEncoder().encode(str);
      const blob = new Blob([bytes], {
        type: "application/json; charset=utf-8",
      });

      return new File([blob], this.file.name.replace(/\.kml$/, ".json"), {
        type: "application/geo+json",
      });
    };
  }

  const handleUpload = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);

    const inputFile: File | undefined = ev.target.files?.[0];
    if (!inputFile) return;
    setFilename(inputFile.name);

    const inputFileContent = await readFileContent(inputFile);
    const geozoneFile = new GeozoneFile(inputFile, inputFileContent as string);

    if (!geozoneFile.isValid()) {
      alert(
        "Error: Failed to save file\n\nThe file you uploaded is not a valid GeoJson file, or couldn't be converted to one."
      );
      return;
    }

    const media = new Media({
      file: geozoneFile.toGeojsonFile(),
      // @ts-ignore
      userId: UserContext?.uid,
      visibility: {},
    });

    media.store(
      onUploadProgress as () => any,
      "",
      "geozone-file",
      // @ts-ignore
      UserContext?.uid,
      undefined
    );
  };

  const FileUploadInput = () => (
    <Box>
      <input
        ref={inputComponentRef}
        type="file"
        id="image-component"
        hidden
        accept={".json,.geojson,.kml"}
        onChange={(ev) => {
          handleUpload(ev);
        }}
      />
      <label ref={inputComponentRef} htmlFor={"image-component"}>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
        >
          <Box p={1}>
            <CloudUpload />
          </Box>
          <TextField
            value={filename || "No File Uploaded"}
            label="File"
            disabled
            onClick={() => {
              // @ts-ignore
              inputComponentRef.current?.click();
            }}
            fullWidth
          />
          {uploadProgress >= 0 && uploadProgress < 2 && (
            <YZTypography>{(uploadProgress * 100).toFixed(2)}%</YZTypography>
          )}
          {uploadProgress === 2 && (
            <YZTypography>Upload successful!</YZTypography>
          )}
        </Box>
      </label>
    </Box>
  );

  return (
    <Box>
      <Typography style={{ maxWidth: "90%", margin: 10 }}>
        This tool accepts geojson (.json, .geojson) and KML (.kml) files. If you
        upload a KML file, it will be converted into a geojson file.
      </Typography>
      <Typography style={{ maxWidth: "90%", margin: 10 }}>
        Note: this tool does not currently support an "undo" function.
      </Typography>
      {pageLoaded ? <FileUploadInput /> : <GenericLoadingSpinner />}
    </Box>
  );
};

export default ReplaceGeoZoneFile;
