const getObjectAndArrayRelationships = (
  targetFilters: any[],
  linksMap: Record<string, string>,
  objLinksMap?: Record<string, string>,
  arrayLinksSubfieldMap?: Record<string, string>
): any[] => {
  const hasAllOfObjectRelationshipGroups = new Map();
  const hasAllOfArrayRelationshipGroups = new Map();
  const hasAllOfObjectRelationshipFields: {
    fieldName: string;
    data: string[];
  }[] = [];
  const hasAllOfArrayRelationshipFields: {
    fieldName: string;
    links: string;
    data: string[];
  }[] = [];
  for (let each of targetFilters) {
    if (!linksMap[each.__typename]) {
      if (hasAllOfObjectRelationshipGroups.has(each.__typename)) {
        hasAllOfObjectRelationshipGroups.set(
          each.__typename,
          hasAllOfObjectRelationshipGroups.get(each.__typename).add(each.id)
        );
      } else {
        hasAllOfObjectRelationshipGroups.set(
          each.__typename,
          new Set().add(each.id)
        );
      }
    } else {
      if (hasAllOfArrayRelationshipGroups.has(each.__typename)) {
        hasAllOfArrayRelationshipGroups.set(
          each.__typename,
          hasAllOfArrayRelationshipGroups.get(each.__typename).add(each.id)
        );
      } else {
        hasAllOfArrayRelationshipGroups.set(
          each.__typename,
          new Set().add(each.id)
        );
      }
    }
  }

  for (let key of Array.from(hasAllOfArrayRelationshipGroups.keys())) {
    let fieldName = `id_${key}`;
    if (arrayLinksSubfieldMap && arrayLinksSubfieldMap[fieldName]) {
      fieldName = arrayLinksSubfieldMap[fieldName];
    }

    hasAllOfArrayRelationshipFields.push({
      fieldName: fieldName,
      links: linksMap[key] as string,
      data: Array.from(hasAllOfArrayRelationshipGroups.get(key)),
    });
  }
  for (let key of Array.from(hasAllOfObjectRelationshipGroups.keys())) {
    hasAllOfObjectRelationshipFields.push({
      fieldName: objLinksMap?.[key] ? objLinksMap[key] : `${key}`,
      data: Array.from(hasAllOfObjectRelationshipGroups.get(key)),
    });
  }
  return [hasAllOfObjectRelationshipFields, hasAllOfArrayRelationshipFields];
};

export { getObjectAndArrayRelationships };
export default getObjectAndArrayRelationships;
