import * as React from "react";
import { useState } from "react";
import { Profile } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import axios from "axios";

type Status =
  | "materialsReceived"
  | "designerActionable"
  | "drafting"
  | "reviewDesign"
  | "clientReview"
  | "feedbackReceived";

type SortDirection = 1 | -1;

export type GetClientsParams = {
  fromdt?: string;
  todt?: string;
  page?: number;
  testAccts?: boolean;
  pageSize?: number;
  status?: Status;
  sortDirection?: SortDirection;
  search?: string;
};
interface Props {
  children: React.ReactNode[] | React.ReactNode;
}

export type RecordsContext = {
  records: Profile[];
  totalRecords: number;
  loading: boolean;
  error?: string;
  getClients: (options: GetClientsParams) => void;
} | null;

export const RecordsCtx = React.createContext<RecordsContext>(null);

export default RecordsCtx;

export const RecordsProvider = React.memo((props: Props) => {
  const [records, setRecords] = useState<{
    records: Profile[];
    totalRecords: number;
  }>({ records: [], totalRecords: 0 });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);

  async function getClients(params: GetClientsParams) {
    setLoading(true);
    const authToken = await firebase.auth().currentUser?.getIdToken();
    const url = new URL(
      "/v1/client-profiles/list",
      process.env.REACT_APP_LIISA_API_URL
    );

    try {
      const response = await axios.get<{
        total: number;
        profiles: Profile[];
      }>(url.href, {
        params,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setRecords({
        records: response.data.profiles ?? [],
        totalRecords: response.data.total ?? 0,
      });
    } catch (e) {
      setError(e as string);
    }
    setLoading(false);
  }

  return (
    <RecordsCtx.Provider value={{ ...records, loading, error, getClients }}>
      {props.children}
    </RecordsCtx.Provider>
  );
});
