import * as React from "react";
import {
  Typography,
  LinearProgress,
  Tabs,
  Tab,
  Box,
  ButtonGroup,
  makeStyles,
  Theme,
} from "@material-ui/core";
import NewMediaGrid from "../Components/NewMediaGrid";
import { Profile, Media, Revision, Assignment } from "@yardzen-inc/models";
import { Agent } from "http";
import { PageComponentProps } from "./CurrentJobPageMap";
import FileUpload from "../Components/FileUpload";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { AnnotatorPage } from "@yardzen-inc/annotator";
import firebase from "firebase/compat/app";

interface Props {
  profile: Profile;
  user: Agent | firebase.User;
  clientRecord: Profile;
  assignment?: Assignment;
  buttons?: React.ReactNode[];
  pm?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  visible: {
    display: "block",
  },
  hidden: {
    display: "none",
  },
  mediaGrid: {
    display: "inline-grid",
    gap: "1rem",
    width: "100%",
    maxWidth: "98vw",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  privateNotes: {
    marginTop: "2rem",
  },
  labelDate: {
    backgroundColor: "#DEEDFF",
    marginLeft: ".5rem",
    padding: ".2rem .5rem .1rem",
    borderRadius: "4px",
  },
  labelNoFeedback: {
    backgroundColor: "#FFE0E0",
    marginLeft: ".5rem",
    padding: ".2rem .5rem .1rem",
    borderRadius: "4px",
  },
  noDesignsLabel: {
    margin: "3rem 1rem",
  },
  annotationsSubmittedLabel: {
    marginLeft: "1rem",
    marginTop: "1rem",
  },
  revisionTabs: {
    display: "flex",
    flexFlow: "column nowrap",
  },
}));

export function createTabList(
  revisions: Revision[],
  clientRecord: Profile,
  classNameMap?: ClassNameMap<string>
) {
  const revisionIndices = {
    conceptualRevision: revisions.findIndex(
      (revision) => revision.id === clientRecord.conceptualRevision
    ),
    finalRevision: revisions.findIndex(
      (revision) => revision.id === clientRecord.finalRevision
    ),
    v3Revision: revisions.findIndex(
      (revision) => revision.id === clientRecord.v3Revision
    ),
  };
  const tabs = revisions.map((revision, i) => {
    let labelText;
    switch (i) {
      case revisionIndices.conceptualRevision:
        labelText = "Conceptual";
        break;
      case revisionIndices.finalRevision:
        labelText = "Final";
        break;
      case revisionIndices.v3Revision:
        labelText = "V3";
        break;
      default:
        labelText = revision.title || `Revision ${i + 1}`;
    }

    const labelDate = new Intl.DateTimeFormat("default", {
      dateStyle: "short",
    }).format(new Date(revision.dateSentToClient as string));

    const label = (
      <div>
        <span className={classNameMap?.labelText}>{labelText}</span>
        <span className={classNameMap?.labelDate}>{labelDate}</span>
      </div>
    );
    return <Tab label={label} key={`t-${revision.id}`} wrapped={false} />;
  });
  return tabs;
}

export function AnnotatorTab(props: Props | PageComponentProps) {
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState<number>(0);
  const [revisions, setRevisions] = React.useState<Revision[] | null>(null);
  const [annotatedMedia, setAnnotatedMedia] = React.useState<Media[] | null>(
    null
  );
  const [annotatorIndex, setAnnotatorIndex] = React.useState<number>(-1);
  const fileTags = {
    client: "v1Feedback",
    private: "privateRevisionNote",
  };

  const userId = (props as Props).clientRecord
    ? (props as Props).clientRecord.id
    : (props as PageComponentProps).profile.id;

  const revisionId =
    revisions && revisions[tabValue] ? revisions[tabValue].id : "";

  const [revisionDetailsTabValue, setRevisionDetailsTabValue] =
    React.useState<number>(0);

  React.useEffect(() => {
    getRevisions();
  }, []);

  React.useEffect(() => {
    getMedia();
    setRevisionDetailsTabValue(0);
  }, [tabValue, revisions]);

  if (revisions === null) {
    return (
      <Typography
        variant="h3"
        align="center"
        className={classes.noDesignsLabel}
      >
        Loading...
      </Typography>
    );
  }

  if (revisions.length === 0) {
    return (
      <Typography
        variant="h3"
        align="center"
        className={classes.noDesignsLabel}
      >
        No designs have been sent to the client
      </Typography>
    );
  }
  return (
    <>
      {renderTabs(revisions)}
      {renderToolBar()}
      <br />
      {renderAnnotationsAndRevisionNotes()}
    </>
  );

  function renderAnnotationsAndRevisionNotes(): React.ReactNode {
    if (!annotatedMedia) {
      return <LinearProgress variant="indeterminate" color="primary" />;
    }
    let dateRevisionAnnotated: string | undefined = undefined;
    let dateFeedbackSubmitted: firebase.firestore.Timestamp | undefined =
      undefined;

    if (revisions && revisions[tabValue]?.dateAnnotated) {
      const dateAnnotated = new Date(
        revisions[tabValue].dateAnnotated as string
      );

      dateRevisionAnnotated = new Intl.DateTimeFormat("default", {
        dateStyle: "short",
        timeStyle: "long",
      }).format(dateAnnotated);
    }

    if (revisions && revisions[tabValue]?.feedbackSubmittedAt) {
      dateFeedbackSubmitted = revisions[tabValue].feedbackSubmittedAt;
    }

    if (annotatedMedia && !annotatedMedia.length) {
      return (
        <>
          <Tabs
            value={revisionDetailsTabValue}
            onChange={(event, value) => setRevisionDetailsTabValue(value)}
          >
            <Tab label="Revision Notes" />
          </Tabs>
          <Typography variant="h3" align="center">
            No annotations to review
          </Typography>
          <div>{showFeedbackPhotos()}</div>
        </>
      );
    }

    return (
      <>
        {annotatedMedia && annotatedMedia.length && (
          <>
            <Typography className={classes.annotationsSubmittedLabel}>
              Annotations Submitted by Client on:{" "}
              <span className={classes.labelDate}>
                {dateRevisionAnnotated ?? "No annotations for this revision"}
              </span>
            </Typography>
            <Typography className={classes.annotationsSubmittedLabel}>
              Feedback Submitted by Client on:{" "}
              <span
                className={
                  dateFeedbackSubmitted
                    ? classes.labelDate
                    : classes.labelNoFeedback
                }
              >
                {dateFeedbackSubmitted
                  ? dateFeedbackSubmitted.toDate().toLocaleString()
                  : "No feedback for this revision"}
              </span>
            </Typography>
            <Tabs
              value={revisionDetailsTabValue}
              onChange={(event, value) => setRevisionDetailsTabValue(value)}
            >
              <Tab label="Annotations" />
              <Tab label="Revision Notes" />
            </Tabs>
            <div
              className={
                revisionDetailsTabValue === 0 ? classes.visible : classes.hidden
              }
            >
              <NewMediaGrid
                cardType="annotator"
                user={
                  (props as Props).user || (props as PageComponentProps).agent
                }
                profile={props.profile}
                media={annotatedMedia}
                readonly={!(props as any).pm}
                onExpand={(index: number) => {
                  setAnnotatorIndex(index);
                }}
              />
              {annotatorIndex >= 0 && (
                <AnnotatorPage
                  readonly={!(props as any).pm}
                  media={annotatedMedia}
                  useAnnotationsFilter
                  user={
                    ((props as Props).user ||
                      (props as PageComponentProps).agent) as firebase.User
                  }
                  profile={props.profile}
                  onClose={() => {
                    setAnnotatorIndex(-1);
                  }}
                  open={true}
                  currentUser={(props as any).pm ? "pm" : "agent"}
                  openToIndex={annotatorIndex}
                />
              )}
            </div>
            <div>
              {revisionDetailsTabValue === 1 ? showFeedbackPhotos() : null}
            </div>
          </>
        )}
      </>
    );
  }

  function showFeedbackPhotos() {
    if (revisions === null) {
      return null;
    }
    return (
      <div className={classes.mediaGrid}>
        <div>
          <div>
            <Typography variant="h3" align="center">
              Client Notes
            </Typography>
            <NewMediaGrid
              cardType="categoryAndDescription"
              user={
                (props as Props).user || (props as PageComponentProps).agent
              }
              userId={userId}
              profile={props.profile}
              fileTag={fileTags.client}
              readonly={!(props as any).pm}
              revisionId={revisionId}
              columnsXl={3}
              columnsLg={3}
              columnsMd={3}
              columnsSm={3}
              columnsXs={3}
            />
          </div>
          <div>
            <Typography
              variant="h3"
              align="center"
              className={classes.privateNotes}
            >
              Private Notes
            </Typography>
            <Typography variant="subtitle1" align="center">
              These won't be visible to the client
            </Typography>
            <NewMediaGrid
              cardType="categoryAndDescription"
              user={
                (props as Props).user || (props as PageComponentProps).agent
              }
              userId={userId}
              profile={props.profile}
              fileTag={fileTags.private}
              readonly={!(props as any).pm}
              revisionId={revisionId}
              columnsXl={3}
              columnsLg={3}
              columnsMd={3}
              columnsSm={3}
              columnsXs={3}
            />
            {(props as Props).pm && (
              <FileUpload
                fileTag={fileTags.private}
                clientUserId={userId}
                revisionId={revisionId}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  function renderToolBar() {
    if (!(props as Props).buttons) {
      return null;
    }

    const buttons = (props as Props).buttons;

    return (
      <Box display="flex" flexDirection="row-reverse">
        <ButtonGroup>{buttons}</ButtonGroup>
      </Box>
    );
  }

  function renderTabs(revisions: Revision[]): React.ReactNode {
    const tabList = createTabList(
      revisions,
      (props as Props).clientRecord || (props as Props).profile,
      classes
    );

    return (
      <div className={classes.revisionTabs}>
        <Tabs
          value={tabValue}
          onChange={(e, val) => setTabValue(val)}
          variant="scrollable"
          scrollButtons="on"
        >
          {tabList}
        </Tabs>
        <br />
      </div>
    );
  }

  async function getMedia() {
    if (annotatedMedia) {
      setAnnotatedMedia(null);
    }

    if (!revisions) {
      return setAnnotatedMedia(null);
    }

    if (!revisions.length) {
      return setAnnotatedMedia([]);
    }

    const rev = revisions[tabValue];

    if (!rev.deliverables || !rev.deliverables.length) {
      return setAnnotatedMedia([]);
    }

    const slidesForAnnotation = rev.annotationSlides?.length
      ? rev.annotationSlides
      : rev.deliverables;

    setAnnotatedMedia(
      await (async function (): Promise<Media[]> {
        const media = await Promise.all(
          (slidesForAnnotation as string[]).map(Media.fetchSingle)
        );

        return (
          (
            (
              (await Promise.all(
                media.map(async (m) => [
                  (async () => {
                    try {
                      return await (m as Media)
                        .getDocRef()
                        .collection("threads")
                        .get();
                    } catch {
                      return null;
                    }
                  })(),
                  m,
                ])
              )) as unknown as [firebase.firestore.QuerySnapshot, Media]
            )
              // @ts-ignore
              .filter((m) => !!m[0] && !!m[1])
              .filter(
                (m) =>
                  // @ts-ignore
                  !(m[0] as firebase.firestore.QuerySnapshot).empty &&
                  // @ts-ignore
                  !(m[1] as Media).fileType.includes("pdf")
                // @ts-ignore
              ) as Media[]
          )
            // @ts-ignore
            .map((m) => m[1] as Media)
        );
      })()
    );
  }

  async function getRevisions() {
    let revs;

    if ((props as Props).clientRecord) {
      revs = await Revision.getSortedRevisions(
        (props as Props).clientRecord.id
      );
    } else {
      revs = await Revision.getSortedRevisions(
        (props as PageComponentProps).profile.id
      );
    }

    if (props.assignment && props.assignment.revisionId) {
      revs = revs.filter(
        (r) => r.id !== ((props.assignment as Assignment).revisionId as string)
      );
    }

    const sentRevisions = revs.filter((revision) => revision.hasBeenSent);
    setRevisions(sentRevisions);
  }
}

export default AnnotatorTab;
