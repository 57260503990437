import React, { FC, Fragment, useState } from "react";

// Import MUI Components
import { makeStyles } from "@material-ui/core/styles";
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { YZButton } from "@yardzen-inc/react-common";
import { batchUpdateAgentDesignerPodId } from "./apiCalls";
import { AgentPods } from "./types";

export interface EditPodAssignmentBatchModalProps {
  open: boolean;
  checkedAgents: string[];
  onClose: () => void;
  onSubmit: () => void;
  pods: AgentPods[];
}

const useStyles = makeStyles((theme: Theme) => ({
  style: {},
  dialogContainer: {
    minWidth: 500,
    minHeight: 289,
  },
  progressContainer: {
    display: "flex",
  },
  innerButtonProgress: {
    position: "absolute",
    color: "#fff",
  },
  selectForm: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const EditPodAssignmentBatchModal: FC<EditPodAssignmentBatchModalProps> = ({
  open,
  checkedAgents,
  onClose,
  onSubmit,
  pods,
}) => {
  const classes = useStyles();
  const [newPod, setNewPod] = useState<string>("");
  const [createPodLoading, setCreatePodLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        classes={{ paper: classes.dialogContainer }}
      >
        <DialogTitle id="form-dialog-title">
          Select new team assignment for agents
        </DialogTitle>
        {error && <Alert severity="error">{error}</Alert>}
        <>
          <DialogContent>
            <FormControl fullWidth className={classes.selectForm}>
              <InputLabel htmlFor="pageLength">New Team</InputLabel>
              <Select
                // @ts-ignore
                onChange={handleChange}
                value={newPod}
              >
                {pods &&
                  pods
                    .filter((pod) => pod.id)
                    .map((pod) => {
                      return (
                        <MenuItem
                          value={pod.id ? pod.id : ""}
                          id="newPod"
                          key={`edit-pod-modal-new-${pod.id}`}
                        >
                          {pod.name}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <YZButton
              inheritFont
              color="primary"
              onClick={handleSubmitButonClick}
              fullWidth
              disabled={createPodLoading}
            >
              {createPodLoading && (
                <CircularProgress
                  size={30}
                  className={classes.innerButtonProgress}
                />
              )}
              Submit
            </YZButton>
          </DialogActions>
        </>
      </Dialog>
    </Fragment>
  );

  async function handleSubmitButonClick() {
    setError("");
    if (newPod.trim() === "") {
      setError("Please select a team before submitting");
    } else {
      setCreatePodLoading(true);
      await batchUpdateAgentDesignerPodId(checkedAgents, newPod);
      setCreatePodLoading(false);
      onClose();
      onSubmit();
    }
  }

  function handleChange(event: React.ChangeEvent<{ value: string }>) {
    const pod = event.target.value;
    setNewPod(pod);
  }
};

export { EditPodAssignmentBatchModal };
export default EditPodAssignmentBatchModal;
