import * as React from "react";
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  PLANT_ADAPTED_HABITAT_SELECT,
  PLANT_BLOOM_TIME_SELECT,
  PLANT_CAD_SYMBOL_SIZE_SELECT,
  PLANT_CAD_SYMBOL_SPACING_SELECT,
  PLANT_DECIDUOUS_OR_EVERGREEN_SELECT,
  PLANT_GROWTH_HABIT_SELECT,
  PLANT_KEY_FEATURE_SELECT,
  PLANT_LIGHT_NEEDS_SELECT,
  PLANT_NATIVE_RANGE_SELECT,
  PLANT_RED_FLAG_SELECT,
  PLANT_USDA_ZONE_SELECT,
  PLANT_VIP_SELECT,
  PLANT_WATER_NEEDS_SELECT,
  INSERT_PLANT_BASE,
  PLANT_TYPE_SELECT,
  useMutation,
  PLANT_HEIGHT_CLASS_SELECT,
  PLANT_WIDTH_CLASS_SELECT,
  PLANT_LEAF_COLOR_SELECT,
  PLANT_FLOWER_COLOR_SELECT,
  PLANT_FRUIT_COLOR_SELECT,
  PLANT_MATURE_SIZE_SELECT,
  PLANT_GARDEN_STYLE_SELECT,
  UPSERT_PLANT_BASE_LINKS,
} from "@yardzen-inc/graphql";
import { Formik } from "formik";
import { IFormikPlantErrorProps, IFormikPlantProps } from "../../../Interfaces";
import AssetFeatureSelect from "../Shared/AssetFeatureSelect";
import useUnload from "../../../util/hooks/useUnload";
import SaveInProgressTextConstants from "../../../ConstantValues/SaveInProgressTextConstants";
import { handleMutationError } from "../elementMutationErrorUtil";
import AssetLibraryImageUpload from "../Shared/AssetLibraryImageUpload";
import {
  PLANT_CONTAINER_SIZE_SELECT,
  PLANT_RETAILER_SELECT,
} from "../Shared/graphqlQueries";

const useRowStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "50rem",
  },
  itemBtns: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  iconUri: {
    maxWidth: "7rem",
  },
  editItems: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },
  editItem: {
    flex: 1,
    marginBottom: theme.spacing(1),
  },
  longLink: {
    width: "10rem",
    maxWidth: "10rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  saveProgress: {
    display: "flex",
    alignItems: "center",
  },
}));

interface Props {
  onSave: () => any;
}

function AddNewPlant(props: Props) {
  const [cachedAdaptedHabitat, setCachedAdaptedHabitat] = React.useState<
    string[]
  >([]);
  const [cachedType, setCachedType] = React.useState<string[]>([]);
  const [cachedBloomTime, setCachedBloomTime] = React.useState<string[]>([]);
  const [cachedGrowthHabit, setCachedGrowthHabit] = React.useState<string[]>(
    []
  );
  const [cachedKeyFeature, setCachedKeyFeature] = React.useState<string[]>([]);
  const [cachedLightNeeds, setCachedLightNeeds] = React.useState<string[]>([]);
  const [cachedNativeRange, setCachedNativeRange] = React.useState<string[]>(
    []
  );
  const [cachedRedFlag, setCachedRedFlag] = React.useState<string[]>([]);
  const [cachedUsdazone, setCachedUsdazone] = React.useState<string[]>([]);
  const [cachedVip, setCachedVip] = React.useState<string[]>([]);
  const [cachedGardenStyle, setCachedGardenStyle] = React.useState<string[]>(
    []
  );
  const [cachedLeafColor, setCachedLeafColor] = React.useState<string[]>([]);
  const [cachedFlowerColor, setCachedFlowerColor] = React.useState<string[]>(
    []
  );
  const [cachedFruitColor, setCachedFruitColor] = React.useState<string[]>([]);
  const [cachedHeightClass, setCachedHeightClass] = React.useState<string[]>(
    []
  );
  const [cachedWidthClass, setCachedWidthClass] = React.useState<string[]>([]);
  const [cachedMatureSize, setCachedMatureSize] = React.useState<string[]>([]);
  const [cachedCadSize, setCachedCadSize] = React.useState<string[]>([]);
  const [cachedCadSpacing, setCachedCadSpacing] = React.useState<string[]>([]);
  const [saveProgress, setSaveProgress] = React.useState(0);
  const [imagePath, setImagePath] = React.useState("");

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue =
      saveProgress > 0
        ? SaveInProgressTextConstants.SAVE_IN_PROGRESS_WARNING
        : "";
  });

  const [insertRow] = useMutation(INSERT_PLANT_BASE, {
    onError: handleMutationError,
    update(cache, { data: { insert_plant_base_one } }) {
      const variables = {
        adapted_habitat_objects: cachedAdaptedHabitat.map((style) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_adapted_habitat: style,
          };
        }),
        bloom_time_objects: cachedBloomTime.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_bloom_time: color,
          };
        }),
        growth_habit_objects: cachedGrowthHabit.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_growth_habit: color,
          };
        }),
        key_feature_objects: cachedKeyFeature.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_key_feature: color,
          };
        }),
        light_needs_objects: cachedLightNeeds.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_light_needs: color,
          };
        }),
        native_range_objects: cachedNativeRange.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_native_range: color,
          };
        }),
        red_flag_objects: cachedRedFlag.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_red_flag: color,
          };
        }),
        usda_zone_objects: cachedUsdazone.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_usda_zone: color,
          };
        }),
        vip_objects: cachedVip.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_vip: color,
          };
        }),
        type_objects: cachedType.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_type: color,
          };
        }),
        garden_style_objects: cachedGardenStyle.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_garden_style: color,
          };
        }),
        height_class_objects: cachedHeightClass.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_height_class: color,
          };
        }),
        width_class_objects: cachedWidthClass.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_width_class: color,
          };
        }),
        mature_size_objects: cachedMatureSize.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_mature_size: color,
          };
        }),
        leaf_color_objects: cachedLeafColor.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_leaf_color: color,
          };
        }),
        flower_color_objects: cachedFlowerColor.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_flower_color: color,
          };
        }),
        fruit_color_objects: cachedFruitColor.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_fruit_color: color,
          };
        }),
        cad_symbol_size_objects: cachedCadSize.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_cad_symbol_size: color,
          };
        }),
        cad_symbol_spacing_objects: cachedCadSpacing.map((color) => {
          return {
            id_plant_base: insert_plant_base_one.id,
            id_plant_cad_symbol_spacing: color,
          };
        }),
      };
      insertLinks({
        variables,
      });
    },
  });

  const [insertLinks] = useMutation(UPSERT_PLANT_BASE_LINKS, {
    onError: handleMutationError,
    update(cache, { data: { insert_plant_base_one } }) {
      props.onSave();
    },
  });

  const classes = useRowStyles();

  const onImageUploaded = (path: string): void => {
    setImagePath(path);
  };

  const onSave = ({
    unitCost,
    scientific_name,
    common_name,
    icon_uri,
    max_file_link,
    sketchup_file_link,
    sketchup_proxy_file_link,
    vray_file_link,
    additional_info,
    plant_container_size,
    design_tips,
    red_flag_info,
    plant_cad_symbol_size,
    plant_cad_symbol_spacing,
    plant_water_needs,
    plant_deciduous_or_evergreen,
    plant_adapted_habitat,
    plant_bloom_time,
    plant_growth_habit,
    plant_key_feature,
    plant_light_needs,
    plant_native_range,
    lumion_file_name,
    plant_red_flag,
    plant_usda_zone,
    plant_vip,
    plant_type,
    visibility,
    plant_garden_style,
    plant_leaf_color,
    plant_flower_color,
    plant_fruit_color,
    plant_height_class,
    plant_width_class,
    plant_mature_size,
    similar_plants,
    companion_plants,
    plant_retailer,
  }: IFormikPlantProps) => {
    setCachedAdaptedHabitat(plant_adapted_habitat);
    setCachedBloomTime(plant_bloom_time);
    setCachedGrowthHabit(plant_growth_habit);
    setCachedKeyFeature(plant_key_feature);
    setCachedLightNeeds(plant_light_needs);
    setCachedNativeRange(plant_native_range);
    setCachedRedFlag(plant_red_flag);
    setCachedUsdazone(plant_usda_zone);
    setCachedVip(plant_vip);
    setCachedType(plant_type);
    setCachedGardenStyle(plant_garden_style);
    setCachedLeafColor(plant_leaf_color);
    setCachedFlowerColor(plant_flower_color);
    setCachedFruitColor(plant_fruit_color);
    setCachedHeightClass(plant_height_class);
    setCachedWidthClass(plant_width_class);
    setCachedMatureSize(plant_mature_size);
    setCachedCadSize(plant_cad_symbol_size);
    setCachedCadSpacing(plant_cad_symbol_spacing);
    setSaveProgress(1);
    const variables: any = {
      unit_cost: Math.round(parseFloat(unitCost) * 100),
      scientific_name,
      common_name,
      icon_uri: imagePath,
      lumion_file_name,
      max_file_link,
      sketchup_file_link,
      proxy_file_link: sketchup_proxy_file_link,
      vray_file_link,
      additional_info,
      plant_container_size,
      design_tips,
      red_flag_info,
      visibility,
      similar_plants,
      companion_plants,
      adapted_habitat_objects: plant_adapted_habitat,
      bloom_time_objects: plant_bloom_time,
      growth_habit_objects: plant_growth_habit,
      key_feature_objects: plant_key_feature,
      light_needs_objects: plant_light_needs,
      native_range_objects: plant_native_range,
      red_flag_objects: plant_red_flag,
      usda_zone_objects: plant_usda_zone,
      vip_objects: plant_vip,
      type_objects: plant_type,
      garden_style_objects: plant_garden_style,
      height_class_objects: plant_height_class,
      width_class_objects: plant_width_class,
      mature_size_objects: plant_mature_size,
      leaf_color_objects: plant_leaf_color,
      flower_color_objects: plant_flower_color,
      fruit_color_objects: plant_fruit_color,
    };
    if (plant_water_needs) {
      variables.water_needs = plant_water_needs;
    }
    if (plant_deciduous_or_evergreen) {
      variables.deciduous_or_evergreen = plant_deciduous_or_evergreen;
    }
    insertRow({
      variables,
    });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h4">
        Add new asset
      </Typography>
      <Formik
        initialValues={{
          unitCost: "",
          scientific_name: "",
          common_name: "",
          icon_uri: "",
          sketchup_file_link: "",
          sketchup_proxy_file_link: "",
          vray_file_link: "",
          lumion_file_name: "",
          lumion_file_link: "",
          max_file_link: "",
          zip_file_link: "",
          additional_info: "",
          plant_container_size: [],
          design_tips: "",
          red_flag_info: "",
          plant_cad_symbol_size: [],
          plant_cad_symbol_spacing: [],
          plant_water_needs: "",
          plant_deciduous_or_evergreen: "",
          plant_adapted_habitat: [],
          plant_bloom_time: [],
          plant_flower_fruit_color: [],
          plant_foliage_color: [],
          plant_height_class: [],
          plant_width_class: [],
          plant_growth_habit: [],
          plant_key_feature: [],
          plant_light_needs: [],
          plant_native_range: [],
          plant_mature_size: [],
          plant_red_flag: [],
          plant_usda_zone: [],
          plant_vip: [],
          plant_type: [],
          visibility: "ALL",
          plant_garden_style: [],
          plant_leaf_color: [],
          plant_flower_color: [],
          plant_fruit_color: [],
          similar_plants: "",
          companion_plants: "",
          plant_retailer: "",
        }}
        validate={(values) => {
          const errors: Partial<IFormikPlantErrorProps> = {};
          if (!values.scientific_name) {
            errors.scientific_name = "Required";
          }
          if (!values.common_name) {
            errors.common_name = "Required";
          }
          if (!values.icon_uri) {
            errors.icon_uri = "Required";
          }
          if (!values.visibility) {
            errors.visibility = "Required";
          }
          return errors;
        }}
        onSubmit={(values) => {
          onSave({
            companion_plants: values.companion_plants,
            similar_plants: values.similar_plants,
            unitCost: values.unitCost,
            scientific_name: values.scientific_name,
            common_name: values.common_name,
            icon_uri: values.icon_uri,
            lumion_file_name: values.lumion_file_name,
            max_file_link: values.max_file_link,
            sketchup_file_link: values.sketchup_file_link,
            sketchup_proxy_file_link: values.sketchup_proxy_file_link,
            vray_file_link: values.vray_file_link,
            additional_info: values.additional_info,
            plant_container_size: values.plant_container_size,
            design_tips: values.design_tips,
            red_flag_info: values.red_flag_info,
            plant_cad_symbol_size: values.plant_cad_symbol_size,
            plant_cad_symbol_spacing: values.plant_cad_symbol_spacing,
            plant_water_needs: values.plant_water_needs,
            plant_deciduous_or_evergreen: values.plant_deciduous_or_evergreen,
            plant_adapted_habitat: values.plant_adapted_habitat,
            plant_bloom_time: values.plant_bloom_time,
            plant_growth_habit: values.plant_growth_habit,
            plant_key_feature: values.plant_key_feature,
            plant_light_needs: values.plant_light_needs,
            plant_native_range: values.plant_native_range,
            plant_red_flag: values.plant_red_flag,
            plant_usda_zone: values.plant_usda_zone,
            plant_vip: values.plant_vip,
            plant_type: values.plant_type,
            visibility: values.visibility,
            plant_garden_style: values.plant_garden_style,
            plant_leaf_color: values.plant_leaf_color,
            plant_flower_color: values.plant_flower_color,
            plant_fruit_color: values.plant_fruit_color,
            plant_height_class: values.plant_height_class,
            plant_width_class: values.plant_width_class,
            plant_mature_size: values.plant_mature_size,
            lumion_file_link: values.lumion_file_link,
            plant_retailer: values.plant_retailer,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Box className={classes.editItems} margin={1}>
            <TextField
              error={!!(errors.common_name && touched.common_name)}
              className={classes.editItem}
              onChange={handleChange("common_name")}
              value={values.common_name}
              label="Common Name"
              color="primary"
              required
            />
            <TextField
              error={!!(errors.scientific_name && touched.scientific_name)}
              className={classes.editItem}
              onChange={handleChange("scientific_name")}
              value={values.scientific_name}
              label="Scientific Name"
              color="primary"
              required
            />
            <br />
            <AssetLibraryImageUpload
              formikName="icon_uri"
              onImageUploaded={onImageUploaded}
            />
            <br />
            <AssetFeatureSelect
              multiple
              query={PLANT_GARDEN_STYLE_SELECT}
              queryField="plant_garden_style"
              formikName="plant_garden_style"
              label="Planting Style"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_TYPE_SELECT}
              queryField="plant_type"
              formikName="plant_type"
              label="Type"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_ADAPTED_HABITAT_SELECT}
              queryField="plant_adapted_habitat"
              formikName="plant_adapted_habitat"
              label="Adapted Habitat"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_LIGHT_NEEDS_SELECT}
              queryField="plant_light_needs"
              formikName="plant_light_needs"
              label="Light Needs"
              optional
            />
            <AssetFeatureSelect
              query={PLANT_WATER_NEEDS_SELECT}
              queryField="plant_water_needs"
              formikName="plant_water_needs"
              label="Water Needs"
              optional
            />
            <AssetFeatureSelect
              query={PLANT_DECIDUOUS_OR_EVERGREEN_SELECT}
              queryField="plant_deciduous_or_evergreen"
              formikName="plant_deciduous_or_evergreen"
              label="Deciduous or Evergreen"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_HEIGHT_CLASS_SELECT}
              queryField="plant_height_class"
              formikName="plant_height_class"
              label="Height Class"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_WIDTH_CLASS_SELECT}
              queryField="plant_width_class"
              formikName="plant_width_class"
              label="Width Class"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_MATURE_SIZE_SELECT}
              queryField="plant_mature_size"
              formikName="plant_mature_size"
              label="Mature Size"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_NATIVE_RANGE_SELECT}
              queryField="plant_native_range"
              formikName="plant_native_range"
              label="Native Range"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_BLOOM_TIME_SELECT}
              queryField="plant_bloom_time"
              formikName="plant_bloom_time"
              label="Bloom Time"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_RED_FLAG_SELECT}
              queryField="plant_red_flag"
              formikName="plant_red_flag"
              label="Red Flag"
              optional
            />
            <TextField
              error={!!(errors.red_flag_info && touched.red_flag_info)}
              className={classes.editItem}
              onChange={handleChange("red_flag_info")}
              value={values.red_flag_info}
              label="Red Flag Info"
              color="primary"
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_VIP_SELECT}
              queryField="plant_vip"
              formikName="plant_vip"
              label="VIP"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_KEY_FEATURE_SELECT}
              queryField="plant_key_feature"
              formikName="plant_key_feature"
              label="Key Feature"
              optional
            />
            <TextField
              error={!!(errors.additional_info && touched.additional_info)}
              className={classes.editItem}
              onChange={handleChange("additional_info")}
              value={values.additional_info}
              label="Additional Info"
              color="primary"
            />
            <TextField
              error={!!(errors.design_tips && touched.design_tips)}
              className={classes.editItem}
              onChange={handleChange("design_tips")}
              value={values.design_tips}
              label="Design Tips"
              color="primary"
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_GROWTH_HABIT_SELECT}
              queryField="plant_growth_habit"
              formikName="plant_growth_habit"
              label="Growth Habit"
              optional
            />
            <TextField
              error={!!(errors.unitCost && touched.unitCost)}
              className={classes.editItem}
              onChange={handleChange("unitCost")}
              value={values.unitCost}
              label="Unit Cost"
              color="primary"
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_CONTAINER_SIZE_SELECT}
              queryField="plant_container_size"
              formikName="plant_container_size"
              label="Container Size"
            />
            <TextField
              className={classes.editItem}
              onChange={handleChange("lumion_file_name")}
              value={values.lumion_file_name}
              label="Lumion File Name"
              color="primary"
            />
            <TextField
              error={
                !!(errors.sketchup_file_link && touched.sketchup_file_link)
              }
              className={classes.editItem}
              onChange={handleChange("sketchup_file_link")}
              value={values.sketchup_file_link}
              label="Sketchup File Link"
              color="primary"
            />
            <TextField
              className={classes.editItem}
              onChange={handleChange("sketchup_proxy_file_link")}
              value={values.sketchup_proxy_file_link}
              label="Sketchup Proxy File Link"
              color="primary"
            />
            <TextField
              error={!!(errors.vray_file_link && touched.vray_file_link)}
              className={classes.editItem}
              onChange={handleChange("vray_file_link")}
              value={values.vray_file_link}
              label="V-Ray File Link"
              color="primary"
            />
            <TextField
              error={!!(errors.max_file_link && touched.max_file_link)}
              className={classes.editItem}
              onChange={handleChange("max_file_link")}
              value={values.max_file_link}
              label="MAX File Link"
              color="primary"
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_CAD_SYMBOL_SIZE_SELECT}
              queryField="plant_cad_symbol_size"
              formikName="plant_cad_symbol_size"
              label="CAD Symbol ~Size~"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_CAD_SYMBOL_SPACING_SELECT}
              queryField="plant_cad_symbol_spacing"
              formikName="plant_cad_symbol_spacing"
              label="CAD Symbol ~Spacing~"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_USDA_ZONE_SELECT}
              queryField="plant_usda_zone"
              formikName="plant_usda_zone"
              label="USDA Zone"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_LEAF_COLOR_SELECT}
              queryField="plant_leaf_color"
              formikName="plant_leaf_color"
              label="Leaf Color"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_FLOWER_COLOR_SELECT}
              queryField="plant_flower_color"
              formikName="plant_flower_color"
              label="Flower Color"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_FRUIT_COLOR_SELECT}
              queryField="plant_fruit_color"
              formikName="plant_fruit_color"
              label="Fruit Color"
              optional
            />
            <AssetFeatureSelect
              multiple
              query={PLANT_RETAILER_SELECT}
              queryField="plant_retailer"
              formikName="plant_retailer"
              label="Retailer"
              optional
            />
            <TextField
              className={classes.editItem}
              onChange={handleChange("companion_plants")}
              value={values.companion_plants}
              label="Companion Plants"
              color="primary"
            />
            <TextField
              className={classes.editItem}
              onChange={handleChange("similar_plants")}
              value={values.similar_plants}
              label="Similar Plants"
              color="primary"
            />
            <br />
            <br />
            <Button
              disabled={isSubmitting}
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
            {isSubmitting && (
              <div className={classes.saveProgress}>
                <Typography>
                  {Math.min(saveProgress, 100).toFixed(0)}%
                </Typography>
                <CircularProgress size={15} style={{ marginLeft: 5 }} />
              </div>
            )}
          </Box>
        )}
      </Formik>
    </div>
  );
}

export default AddNewPlant;
