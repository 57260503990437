import {
  Box,
  CircularProgress,
  Divider,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import {
  BackYardVariant,
  CadFileTag,
  CompleteVariants,
  FrontYardVariant,
  LeftYardVariant,
  Profile,
  Revision,
  RightYardVariant,
} from "@yardzen-inc/models";
import * as React from "react";
import GenericSnackBar from "../../Components/GenericSnackBar";
import { UserCtx } from "../../util/UserContext";
import CadsSelect from "./CadsSelect";
import { MemoizedPropertyMediaSelect } from "./PropertyMediaSelect";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import { useGetBudgetQADataForSharedMedia } from "./util/useGetBudgetQADataForSharedMedia";
import getDesignAssignmentIdFromRevisionId from "../../util/firebase/functions/getDesignAssignmentIdFromRevisionId";
import PdfListSelect from "./PdfListSelect";
import { createSharedMedia } from "./createSharedMedia";

interface IncludeMediaInSharedLinkProps {
  clientRecord: Profile;
  onCompletion?: () => void;
  getShareLink: (revisionId: string) => void;
}

export interface PropertyMediaInclusion {
  include: boolean;
  front: FrontYardVariant[];
  back: BackYardVariant[];
  left: LeftYardVariant[];
  right: RightYardVariant[];
}

export interface RevisionInclusion {
  id: string | null;
  includedCads: CadFileTag[];
  includeGeneratedPDF: boolean;
}

export const PLANTS = "plants";
export const MATERIALS = "materials";
export const ELEMENTS = "elements";

export type pdfTypes = typeof PLANTS | typeof MATERIALS | typeof ELEMENTS;

export interface PdfListInclusion {
  [PLANTS]: boolean;
  [MATERIALS]: boolean;
  [ELEMENTS]: boolean;
}

export interface MediaInclusion {
  profileId: string;
  revision: RevisionInclusion;
  property: PropertyMediaInclusion;
  pdfList: PdfListInclusion;
}

const IncludeMediaInSharedLink: React.FunctionComponent<IncludeMediaInSharedLinkProps> =
  ({ clientRecord, onCompletion, getShareLink }) => {
    const [conceptualRevisionId, finalRevisionId, v3RevisionId]: [
      string?,
      string?,
      string?
    ] = [
      clientRecord.conceptualRevision ?? undefined,
      clientRecord.finalRevision ?? undefined,
      clientRecord.v3Revision ?? undefined,
    ];
    const [revisions, setRevisions] = React.useState<Revision[] | null>(null);
    const [inclusionObject, setInclusionObject] =
      React.useState<MediaInclusion>();
    const [error, setError] = React.useState<boolean>(false);
    const [working, setWorking] = React.useState<boolean>(false);

    const user = React.useContext(UserCtx);

    const [loadingDesignAssignmentId, setLoadingDesignAssignmentId] =
      React.useState<boolean>(true);
    const [designAssignmentIdForRevision, setDesignAssignmentIdForRevision] =
      React.useState<string>("");

    const getBudgetQAData = useGetBudgetQADataForSharedMedia({
      designAssignmentId: designAssignmentIdForRevision,
      designAssignmentIdIsLoading: loadingDesignAssignmentId,
      profile: clientRecord,
    });
    React.useEffect(() => {
      if (!!inclusionObject?.revision.id) {
        getShareLink(inclusionObject?.revision.id);
      }
    }, [inclusionObject]);

    React.useEffect(() => {
      if (!!revisions) {
        setInclusionObject({
          profileId: clientRecord.id,
          revision: {
            id:
              v3RevisionId ||
              finalRevisionId ||
              conceptualRevisionId ||
              revisions[0]?.id ||
              null,
            includedCads: ["cad-layout", "cad-landscape", "cad-legend"],
            includeGeneratedPDF: true,
          },
          property: basePropertyObject,
          pdfList: {
            [PLANTS]: true,
            [MATERIALS]: true,
            [ELEMENTS]: true,
          },
        });
      }
    }, [revisions]);

    React.useEffect(() => {
      const getDesignAssignmentId = async () => {
        setLoadingDesignAssignmentId(true);

        if (inclusionObject?.revision.id) {
          try {
            const designAssignmentId =
              await getDesignAssignmentIdFromRevisionId(
                inclusionObject.revision.id
              );

            setDesignAssignmentIdForRevision(designAssignmentId);
          } catch (e) {
            console.error(e);
          }
        }

        setLoadingDesignAssignmentId(false);
      };

      getDesignAssignmentId();
    }, [inclusionObject?.revision.id]);

    React.useEffect(() => {
      getRevisions(clientRecord);
    }, [clientRecord]);

    if (!inclusionObject || loadingDesignAssignmentId) {
      return (
        <Box>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box m={2}>
        <GenericSnackBar
          variant="error"
          message="Error creating shared media"
          onClose={() => setError(false)}
          in={!!error}
        />
        <>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between">
              <Typography style={{ alignSelf: "center" }}>
                Selected Revision
              </Typography>
              <Select
                style={{ width: "30%" }}
                value={inclusionObject.revision.id}
                onChange={(e) => {
                  setInclusionObject({
                    ...inclusionObject,
                    revision: {
                      ...inclusionObject.revision,
                      id: e.target.value as string,
                    },
                  });
                }}
              >
                <MenuItem value="">
                  <em>None selected</em>
                </MenuItem>
                {!!v3RevisionId && (
                  <MenuItem style={{ display: "flex" }} value={v3RevisionId}>
                    <Typography
                      style={{
                        color: "green",
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      v3
                    </Typography>
                    <Typography
                      style={{ alignSelf: "center", paddingLeft: "0.7rem" }}
                    >
                      {(
                        revisions?.find(
                          (r) => r.id === v3RevisionId
                        ) as Revision
                      ).deliverables?.length || 0}{" "}
                      slides
                    </Typography>
                  </MenuItem>
                )}
                {!!finalRevisionId && (
                  <MenuItem style={{ display: "flex" }} value={finalRevisionId}>
                    <Typography
                      style={{
                        color: "green",
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      Final
                    </Typography>
                    <Typography
                      style={{ alignSelf: "center", paddingLeft: "0.7rem" }}
                    >
                      {(
                        revisions?.find(
                          (r) => r.id === finalRevisionId
                        ) as Revision
                      ).deliverables?.length || 0}{" "}
                      slides
                    </Typography>
                  </MenuItem>
                )}
                {!!conceptualRevisionId && (
                  <MenuItem
                    style={{ display: "flex", flexDirection: "column" }}
                    value={conceptualRevisionId}
                  >
                    <Typography
                      style={{
                        color: "green",
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      Conceptual
                    </Typography>
                    <Typography
                      style={{ alignSelf: "center", paddingLeft: "0.7rem" }}
                      variant="caption"
                    >
                      {(
                        revisions?.find(
                          (r) => r.id === conceptualRevisionId
                        ) as Revision
                      ).deliverables?.length || 0}{" "}
                      slides
                    </Typography>
                  </MenuItem>
                )}
                <Divider style={{ height: "3px" }} />

                {revisions?.map((revision, index) => {
                  if (
                    revision.id === conceptualRevisionId ||
                    revision.id === finalRevisionId
                  ) {
                    return null;
                  }
                  return (
                    <MenuItem
                      style={{ display: "flex", flexDirection: "column" }}
                      key={`${revision.id}`}
                      value={revision.id}
                    >
                      <Typography>
                        {revision.title || `Revision: ${index + 1}`}
                      </Typography>
                      <Typography
                        style={{ alignSelf: "center", paddingLeft: "0.7rem" }}
                        variant="caption"
                      >
                        {revision.deliverables?.length || 0} slides
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              padding="20px 0"
            >
              <Box>
                <Box>
                  <YZTypography>CAD</YZTypography>
                  <CadsSelect
                    currentCads={inclusionObject.revision.includedCads}
                    updateCads={updateCads}
                  />
                </Box>
                <Box>
                  <YZTypography>PDF List</YZTypography>
                  <PdfListSelect
                    pdfList={inclusionObject.pdfList}
                    handlePdfListUpdate={handlePdfListUpdate}
                  />
                </Box>
              </Box>
              <YZButton
                variant="contained"
                color="primary"
                onClick={() => submitInclusion(inclusionObject)}
                style={{ height: "min-content" }}
                disabled={working}
              >
                Submit
              </YZButton>
            </Box>

            <MemoizedPropertyMediaSelect
              updatePropertyInclusion={handlePropertyUpdate}
              propertyInclusion={inclusionObject.property}
            />
          </Box>
        </>
      </Box>
    );

    function handlePropertyUpdate(
      key: string,
      value: CompleteVariants | boolean
    ) {
      setInclusionObject(
        // @ts-ignore
        (inclusionObject: MediaInclusion) =>
          // @ts-ignore
          ({
            ...inclusionObject,
            property: {
              ...inclusionObject?.property,
              [key]: value,
            },
          } as MediaInclusion)
      );
    }

    function handlePdfListUpdate(key: string, value: boolean) {
      setInclusionObject(
        // @ts-ignore
        (inclusionObject: MediaInclusion) =>
          // @ts-ignore
          ({
            ...inclusionObject,
            pdfList: {
              ...inclusionObject?.pdfList,
              [key]: value,
            },
          } as MediaInclusion)
      );
    }

    async function submitInclusion(inclusionObject: MediaInclusion) {
      if (!user) return;

      setWorking(true);
      try {
        await createSharedMedia(inclusionObject, getBudgetQAData);
      } catch (error) {
        console.error("Error creating shared media: ", error);
        setError(true);
      } finally {
        setWorking(false);

        if (!!onCompletion) {
          return onCompletion();
        }
      }
    }

    async function getRevisions(clientRecord: Profile) {
      const revisions = (
        await firebase
          .firestore()
          .collection("revisions")
          .where("profileId", "==", clientRecord.id)
          .get()
      ).docs;

      setRevisions(Revision.hydrate(revisions || []));
    }

    function updateCads(cad: CadFileTag, value: boolean) {
      // @ts-ignore
      setInclusionObject((inclusionObject: MediaInclusion) => {
        const currentCads = new Set(inclusionObject?.revision.includedCads);

        if (value) {
          currentCads.add(cad);
        } else {
          currentCads.delete(cad);
        }

        return {
          ...inclusionObject,
          revision: {
            ...inclusionObject?.revision,
            // @ts-ignore
            includedCads: [...currentCads],
          },
        } as MediaInclusion;
      });
    }
  };

export const basePropertyObject: PropertyMediaInclusion = {
  include: true,
  front: [
    "front-yard-chatty",
    "front-yard-keep",
    "front-yard-remove",
    "front-yard-slow-pan",
    "front-yard-wide-angle",
    "front-yard-slope",
  ] as FrontYardVariant[],
  back: [
    "back-yard-chatty",
    "back-yard-keep",
    "back-yard-remove",
    "back-yard-slow-pan",
    "back-yard-wide-angle",
    "back-yard-slope",
  ] as BackYardVariant[],
  right: [
    "right-yard-chatty",
    "right-yard-keep",
    "right-yard-remove",
    "right-yard-slow-pan",
    "right-yard-wide-angle",
    "right-yard-slope",
  ] as RightYardVariant[],
  left: [
    "left-yard-chatty",
    "left-yard-keep",
    "left-yard-remove",
    "left-yard-slow-pan",
    "left-yard-wide-angle",
    "left-yard-slope",
  ] as LeftYardVariant[],
};

export default IncludeMediaInSharedLink;
