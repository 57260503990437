import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { AgentGroup, AgentGroupFunctions } from "@yardzen-inc/util";
import {
  AgentGroupFunctionsError,
  AgentGroupFunctionsErrorCode,
} from "@yardzen-inc/util/dist/errors/AgentGroupFunctionsError";
import { YZButton } from "@yardzen-inc/react-common";
import { useSkipMount } from "../../util/hooks";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export interface AddGroupModalProps extends DialogProps {}

const AddGroupModal: FC<AddGroupModalProps> = (props) => {
  const mountRunComplete = useSkipMount();
  const [creating, setCreating] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [identifier, setIdentifier] = useState<string>("");

  const [nameErrorMessage, setNameErrorMessage] = useState<string>("");
  const [identifierErrorMessage, setIdentifierErrorMessage] =
    useState<string>("");

  useEffect(validateFieldValues, [name, identifier]);

  return (
    <Dialog {...props}>
      <DialogContent style={{ width: "500px", maxWidth: "100vw" }}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            createAgentGroup();
          }}
        >
          <Box p={1}>
            <TextField
              value={name}
              fullWidth
              label="name"
              disabled={creating}
              onChange={({ currentTarget: { value } }) => setName(value)}
              error={!!nameErrorMessage}
              helperText={nameErrorMessage}
            />
          </Box>
          <Box p={1}>
            <TextField
              value={identifier}
              fullWidth
              label="identifier (FORMATTED_LIKE_THIS)"
              disabled={creating}
              onChange={({ currentTarget: { value } }) => setIdentifier(value)}
              error={!!identifierErrorMessage}
              helperText={identifierErrorMessage}
            />
          </Box>
          <Box display="flex" width="100%" flexDirection="row-reverse">
            <YZButton disabled={!valid || !identifier || !name} type={"submit"}>
              Create
            </YZButton>
          </Box>
        </form>
        {creating && (
          <LinearProgress variant="indeterminate" style={{ width: "100%" }} />
        )}
      </DialogContent>
    </Dialog>
  );

  async function createAgentGroup() {
    if (!valid || !identifier || !name) {
      return;
    }

    setCreating(true);

    try {
      await firebase
        .firestore()
        .collection("agent-groups")
        .add({ name, identifier, active: false } as AgentGroup);

      setName("");
      setIdentifier("");
      setNameErrorMessage("");

      setIdentifierErrorMessage("");
      if (props.onClose) {
        props.onClose({}, "backdropClick");
      }
    } catch (error) {
      console.error(error);
      setNameErrorMessage("Failed to create new agent group");
      window.newrelic.noticeError(error);
    } finally {
      setCreating(false);
    }
  }

  function validateFieldValues(): (() => void) | void {
    if (!mountRunComplete) {
      return;
    }

    const timeout = setTimeout(() => {
      try {
        AgentGroupFunctions.validateAgentGroup({
          name,
          identifier,
        });

        setNameErrorMessage("");
        setIdentifierErrorMessage("");
        return setValid(true);
      } catch (error) {
        setValid(false);

        if (error instanceof AgentGroupFunctionsError) {
          if (
            identifier &&
            error.code === AgentGroupFunctionsErrorCode.INVALID_IDENTIFIER
          ) {
            setIdentifierErrorMessage(error.uiString);
          } else if (
            name &&
            error.code === AgentGroupFunctionsErrorCode.INVALID_NAME
          ) {
            setNameErrorMessage(error.uiString);
          }
          return;
        }

        throw error;
      }
    }, 300);

    return () => clearTimeout(timeout);
  }
};

export { AddGroupModal };
export default AddGroupModal;
