import { useState, useMemo, useEffect } from "react";
import { Profile, Project } from "@yardzen-inc/models";
import { DesignBriefEntryGroupMap, DesignBriefMeta } from "./types";
import DesignBriefApi from "./DesignBriefApi";
import FirestoreDesignBriefApi from "./FirestoreDesignBriefApi";
import firebase from "firebase/compat/app";
import generateDefaultDesignBriefV1, {
  V1ORDER,
} from "./generateDefaultDesignBriefV1";
import parseExperienceVersion from "../../../util/parseExperienceVersion";
import generateDefaultDesignBriefV2_1, {
  EXTERIOR_INSPIRATION,
  V2_1ORDER,
} from "./generateDefaultDesignBriefV2_1";
import { useApolloClient } from "@yardzen-inc/graphql";
import { getIsExteriorPackage } from "../../../util/selfAssign/getIsExteriorPackage";

export enum UseDesignBriefLoadingState {
  FETCHING,
  CREATING,
  DONE,
}

export interface DesignBriefContextValue {
  data: DesignBriefEntryGroupMap | null;
  meta: DesignBriefMeta | null;
  api: DesignBriefApi;
  loadingState: UseDesignBriefLoadingState;
  createNewDesignBrief: () => void;
  reloadDesignBrief: () => void;
}

export interface UseDesignBrief {
  (profile: Profile, project: Project): DesignBriefContextValue;
}

const defaultMeta: DesignBriefMeta = {
  acknowledges: {
    "Video and property photos": { value: false },
    "Inspiration Images": { value: false },
    "Video annotations": { value: false },
    "Function and Flow comments": { value: false },
    "Property plans": { value: false },
  },
  additionalNotes: "",
  order: V1ORDER,
};

const exteriorDefaultMeta: DesignBriefMeta = {
  acknowledges: {
    "Video and property photos": { value: false },
    "Inspiration Images": { value: false },
    "Exterior Inspiration Images": { value: false },
    "Video annotations": { value: false },
    "Function and Flow comments": { value: false },
    "Property plans": { value: false },
    "Exterior Paint Colors": { value: false },
  },
  additionalNotes: "",
  order: V1ORDER,
};

const useDesignBriefV1: UseDesignBrief = (profile, project) => {
  const client = useApolloClient();

  const [data, setData] = useState<DesignBriefEntryGroupMap | null>(null);
  const [meta, setMeta] = useState<DesignBriefMeta | null>(null);
  const [loadingState, setLoadingState] = useState<UseDesignBriefLoadingState>(
    UseDesignBriefLoadingState.FETCHING
  );

  const api = useMemo(createApiInstance, [project.id]);

  useEffect(handleLoadDesignBrief, []);

  return {
    data,
    api,
    meta,
    loadingState,
    createNewDesignBrief,
    reloadDesignBrief: handleLoadDesignBrief,
  };

  function createNewDesignBrief(): void {
    void (async function () {
      let metaOrder: string[];
      let useV2_1: boolean;

      const [major, minor] = parseExperienceVersion(project.experienceVersion);

      if (major >= 2 && minor >= 1) {
        metaOrder = getV2_1Order(V2_1ORDER, profile);
        useV2_1 = true;
      } else {
        metaOrder = V1ORDER;
        useV2_1 = false;
      }

      setLoadingState(UseDesignBriefLoadingState.CREATING);

      const newData = await (useV2_1
        ? generateDefaultDesignBriefV2_1
        : generateDefaultDesignBriefV1)(api, profile, true, client);

      const newMeta = getNewMetadata(metaOrder);

      await api.setMetadata(newMeta);

      setData(newData);
      setMeta(newMeta);
      setLoadingState(UseDesignBriefLoadingState.DONE);

      await handleLoadDesignBrief();
    })();
  }

  function getNewMetadata(metaOrder: string[]) {
    if (getIsExteriorPackage(profile.package)) {
      return {
        ...exteriorDefaultMeta,
        order: metaOrder,
      };
    }

    return { ...defaultMeta, order: metaOrder };
  }

  function handleLoadDesignBrief(): void {
    void (async function () {
      if (!(loadingState === UseDesignBriefLoadingState.FETCHING)) {
        setLoadingState(UseDesignBriefLoadingState.FETCHING);
      }

      await Promise.all([
        (async function () {
          const entries = await api.getAllEntries();

          if (entries) {
            setData(entries);
          }
        })(),
        (async function () {
          const meta = await api.getMetadata();

          if (meta) {
            setMeta(meta);
          }
        })(),
      ]);

      setLoadingState(UseDesignBriefLoadingState.DONE);
    })();
  }

  function createApiInstance(): FirestoreDesignBriefApi {
    return new FirestoreDesignBriefApi(
      "designBrief",
      firebase.firestore().collection("projects").doc(project.id)
    );
  }
};

function getV2_1Order(defaultOrder: string[], profile: Profile) {
  if (getIsExteriorPackage(profile.package)) {
    const newOrder = defaultOrder.slice();
    newOrder.push(EXTERIOR_INSPIRATION);
    return newOrder;
  }

  return defaultOrder;
}

export { useDesignBriefV1 };
export default useDesignBriefV1;
