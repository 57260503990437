import React, { useEffect } from "react";
import { reduce } from "lodash";
import {
  formatNumToUSD,
  YZAccordion,
  YZAccordionChip,
} from "@yardzen-inc/react-common";
import { CalculatorTypeConstants } from "../../ConstantValues/CalculatorTypeConstants";
import {
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { BudgetWithLabel } from "../../util/hooks/useGetBudgetDataByProjectId";
import SnackbarError from "../SnackbarError";
import { BudgetDisplayWrapper } from "../Budget/BudgetDisplayWrapper";
import { useSelector } from "../../state/hooks";
import { selectProjectById } from "../../state/slices/entities/projectsSlice";
import { selectProfileById } from "../../state/slices/entities/profilesSlice";
import { useTreatment } from "@yardzen-inc/react-split";
import { HIDE_PLANT_PRICING } from "../../../src/util/split/splitTreatments";

const useStyles = makeStyles((theme: Theme) => ({
  overBudgetWarningContainer: {
    padding: "0.5rem",
    marginLeft: "1rem",
    marginBottom: "1rem",
    width: "35%",
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    borderRadius: "0.5rem",
  },
  overBudgetIcon: {
    fontSize: "1.5rem",
    color: theme.palette.error.contrastText,
    verticalAlign: "middle",
    marginRight: "0.5rem",
  },
  budgetTextContainers: {
    marginTop: "0px",
  },
}));
interface Props {
  projectId: string;
  budgetInfo: BudgetWithLabel;
  items: { category: string; totalCost: string }[];
  itemCategories: { header: string }[];
  calculatorTotals: { [type: string]: number };
}

function BudgetQASummary({
  budgetInfo,
  items,
  calculatorTotals,
  itemCategories,
  projectId,
}: Props) {
  const classes = useStyles();
  const [showError, setShowError] = React.useState<boolean>(false);
  const hidePlantsPricing = useTreatment(HIDE_PLANT_PRICING);

  const project = useSelector((state) => selectProjectById(state, projectId));

  const profile = useSelector((state) =>
    selectProfileById(state, project?.profileId ?? "")
  );

  const itemsTotalCostInCents = (items: any) =>
    items.reduce(
      (sum: number, i: { totalCost: string }) =>
        sum + parseFloat(i.totalCost.replace(/[\$,]/g, "")) * 100,
      0
    );

  let filteredCategories = itemCategories;
  if (hidePlantsPricing) {
    filteredCategories = itemCategories.filter((cat) => cat.header != "Plants");
  }
  const categoryContent = filteredCategories.map((c) => {
    const categoryItems = items.filter((i) => i.category === c.header);

    const totalCost = itemsTotalCostInCents(categoryItems);

    return {
      heading: c.header,
      content: totalCost ? formatNumToUSD(totalCost) : "--",
    };
  });

  let totalDesignCost, totalDesignCostAsText;

  if (!calculatorTotals) {
    // display loading text if totalSoftscapeCost is undefined
    // (which it will be while the parent component is fetching data)
    totalDesignCost = totalDesignCostAsText = "...";
  } else {
    const calculatorsTotal = reduce(
      calculatorTotals,
      (sum, v, _) => sum + v,
      0
    );
    totalDesignCost = itemsTotalCostInCents(items) + calculatorsTotal;
    totalDesignCostAsText = formatNumToUSD(totalDesignCost);
  }

  // https://yardzen.atlassian.net/browse/DOT-534
  // re-enable when ready to show this budget warning
  const isOverBudget = false; //totalDesignCost > budgetInfo.budgetValue;

  const contentLeft = [
    {
      heading: "Total Design Cost",
      content: totalDesignCostAsText,
      highlight: true,
    },
    {
      heading: "Furniture",
      content: "Furniture is not included in Yardzen Design Costs",
    },
    ...categoryContent,
  ];

  if (hidePlantsPricing) {
    contentLeft.push({
      heading: "Plants",
      content:
        'Remember to calculate your planting costs in the "Softscaping Calculator"',
    });
  }
  contentLeft.push(
    ...[
      {
        heading: "Softscaping",
        content:
          calculatorTotals &&
          calculatorTotals[CalculatorTypeConstants.Softscape]
            ? formatNumToUSD(
                calculatorTotals[CalculatorTypeConstants.Softscape]
              )
            : "--",
      },
      {
        heading: "Hardscaping",
        content:
          calculatorTotals &&
          calculatorTotals[CalculatorTypeConstants.Hardscape]
            ? formatNumToUSD(
                calculatorTotals[CalculatorTypeConstants.Hardscape]
              )
            : "--",
      },
    ]
  );

  const contentRight = [
    {
      heading: "Client Budget",
      // This content is set to a component which will render all the budget
      // data for a client. We'll need to ignore the type check later on
      // since the expected content is a string.
      content:
        project && profile ? (
          <BudgetDisplayWrapper
            projectId={projectId}
            yardDifficultyRating={project.yardDifficultyRating}
            packageType={profile.package}
            additionalCssClasses={{
              budgetTextContainer: classes.budgetTextContainers,
            }}
            clientBudgetIntent={project.clientBudgetIntent}
          />
        ) : (
          "Unable to retrieve the client's project to get the budget" +
          " information from. Try again or contact support"
        ),
      highlight: true,
    },
    {
      heading: "",
      content:
        "Your Total Design Cost must be at or below the Client Budget, unless the client has noted to design to their full design brief instead",
    },
  ];

  useEffect(() => {
    if (budgetInfo.error) {
      setShowError(true);
    }
  }, [budgetInfo.error]);

  return budgetInfo.loading ? (
    <CircularProgress />
  ) : (
    <div>
      {isOverBudget && (
        <Typography className={classes.overBudgetWarningContainer}>
          <ErrorIcon className={classes.overBudgetIcon} /> The cost of the
          design is over the client budget!
        </Typography>
      )}
      <YZAccordion
        contentLeft={contentLeft}
        chip={<YZAccordionChip text={totalDesignCostAsText} />}
        // We ignore here because one of our entries in this array uses a
        // component for its content. We should update the YZAccordion
        // component to allow for components to be passed as content at
        // some point.
        //@ts-ignore
        contentRight={contentRight}
        title="Budget"
      />
      {showError && (
        <SnackbarError
          errorText={budgetInfo.error}
          handleSnackbarClose={() => {
            setShowError(false);
          }}
        />
      )}
    </div>
  );
}

export default BudgetQASummary;
