import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import firebase from "firebase/compat/app";

interface SessionState {
  currentUser: firebase.User | null;
  token: string | null;
}

const initialState: SessionState = { currentUser: null, token: null };

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<Partial<SessionState>>) => {
      Object.assign(state, action.payload);
    },
    logOut: (state) => {
      state.currentUser = null;
    },
  },
});

export const { setCurrentUser, logOut } = sessionSlice.actions;
export const selectCurrentUser = (state: RootState) =>
  state.session.currentUser;
export const sessionReducer = sessionSlice.reducer;
