import * as React from "react";

const revisionContext: any = React.createContext({});

// export interface SelectedRevision {
//   projectId: string;
// }

// export interface Revisions {
//   projectId: string,

// }

// export interface RevisionState {
//   selectedRevision: SelectedRevision;
// }

const initialState: any = {
  revisions: {
    // [projectId]: {
    // [revisionId]: {revision},
    // ...
    // }
  },
  activeRevision: {
    // [projectId]: { ...{revision}, }
  },
  mediaMetaData: {
    // revisionId: [...{mediaThing}, ]
  },
  projects: {
    // [projectId]: { project }
  },
};

const reducer = (state: any, action: any) => {
  const {
    profileId,
    mediaMetadataObject,
    revisionId,
    mediaMetaDataArray,
    revisionObject,
    profileObj,
  } = action.payload;
  switch (action.type) {
    case "TEST":
      return {
        ...state,
        success: action.payload.success,
      };
      break;
    case "SET_REVISIONS":
      // console.log("Rev Obj CONTEXT: ", revisionObject);

      return {
        ...state,
        revisions: {
          ...state.revisions,
          [profileId]: {
            ...state.revisions[profileId],
            ...revisionObject,
          },
        },
      };

    case "SET_MEDIA_METADATA": {
      return {
        ...state,
        mediaMetaData: {
          ...state.mediaMetaData,
          [revisionId]: mediaMetaDataArray,
        },
      };
    }
    case "SET_MEDIA_METADATA_OBJECT": {
      // console.log("MEDIA OBJ: ", mediaMetadataObject);

      return {
        ...state,
        mediaMetaData: {
          ...state.mediaMetaData,
          ...mediaMetadataObject,
        },
      };
    }
    case "SET_ACTIVE_REVISION": {
      return {
        ...state,
        activeRevision: {
          ...state.activeRevision,
          [profileId]: revisionObject,
        },
      };
    }
    case "SET_PROJECT": {
      return {
        ...state,
        projects: {
          ...state.projects,
          [profileId]: profileObj,
        },
      };
    }
    default:
      console.log("Reducer Defaulted");
      console.log("Action", action);

      return console.log("State", state);

      break;
  }
};

export function RevisionProvider(props: any) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <revisionContext.Provider value={{ state, dispatch }}>
      {props.children}
    </revisionContext.Provider>
  );
}

export default revisionContext;
