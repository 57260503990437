import { InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import { useFormikContext } from "formik";
import * as React from "react";
import { IFormikElementProps } from "../../../Interfaces";

const useRowStyles = makeStyles({
  editItem: {
    marginTop: 5,
    flex: 1,
  },
  select: {
    width: "100%",
  },
});

export default function InStockSelect() {
  const classes = useRowStyles();

  const { values, setFieldValue } = useFormikContext() as {
    values: Partial<IFormikElementProps>;
    setFieldValue: (target: string, value: any) => any;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue("inStock", e.target.value === "true");
  };

  return (
    <div className={classes.editItem}>
      <InputLabel shrink>In Stock</InputLabel>
      <Select
        className={classes.select}
        value={values.inStock}
        // @ts-ignore
        onChange={onChange}
      >
        <MenuItem value={"true"}>Yes</MenuItem>
        <MenuItem value={"false"}>No</MenuItem>
      </Select>
    </div>
  );
}
