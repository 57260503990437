import { Formik } from "formik";
import React, { FC } from "react";
import { PromotionalCodeCreateFormFieldsProps } from "./PromotionalCodeCreateFormFields";
import { validatePromoCodeCreate } from "./util/validatePromoCodeCreate";

export interface PromotionalCodeCreateFormControllerProps {
  editing: boolean;
  defaultValues: PromotionalCodeCreateFormFieldsProps["values"];
  handleSubmit: (
    values: PromotionalCodeCreateFormFieldsProps["values"]
  ) => void;
  children: (props: any) => React.ReactNode;
}

export const PromotionalCodeCreateFormController: FC<PromotionalCodeCreateFormControllerProps> =
  (props) => {
    return (
      <Formik
        initialValues={props.defaultValues}
        onSubmit={(values, actions) => {
          actions.resetForm();
          props.handleSubmit(values);
        }}
        validate={validatePromoCodeCreate}
      >
        {props.children}
      </Formik>
    );
  };
