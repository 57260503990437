import React, { FC, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Profile,
  Project,
  AssignmentProperties,
  Assignment,
} from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import CdmAssignmentList from "./CdmAssignmentList";
import CdmFeedbackPage from "../../SelfAssignPage/CdmFeedbackPage";
import GenericMultiUpload from "../../Components/GenericMultiUpload";
import { Box, Typography } from "@material-ui/core";
import environmentConstants from "../../ConstantValues/environmentConstants";

export interface CdmClientDetailPageProps extends RouteComponentProps {
  profile: Profile;
}

const CdmClientDetailPage: FC<CdmClientDetailPageProps> = ({
  profile,
  ...routeProps
}) => {
  const [cdmAssignments, setCdmAssignments] = useState<Assignment[] | null>(
    null
  );
  const [project, setProject] = useState<Project | null | false>(null);

  useEffect(fetchProject, [profile.id]);
  useEffect(listenForCdmAssignments, [project && project.id]);

  return (
    <>
      {project && (
        <CdmFeedbackPage
          profile={profile}
          project={project}
          width="960px"
          maxWidth="100%"
          margin="auto"
        />
      )}
      <Box width="960px" margin="auto" maxWidth="100%">
        <Box py="2">
          <Typography align="center" variant="h4" gutterBottom>
            Cdm working files
          </Typography>
        </Box>
        <GenericMultiUpload
          title="cdm working files"
          width="960px"
          margin="auto"
          maxWidth="100%"
          pb="2"
          userId={profile.id || profile.userId}
          tag="function-and-flow-raw"
        />
      </Box>
      <CdmAssignmentList
        {...(routeProps as RouteComponentProps)}
        project={project}
        cdmAssignments={cdmAssignments}
      />
    </>
  );

  function fetchProject(): void {
    void (async function () {
      try {
        const project = await Project.fetchWithProfileId(profile.id);

        setProject(project);
      } catch (error) {
        if (process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT) {
          throw error;
        }

        setProject(false);
      }
    })();
  }

  function listenForCdmAssignments(): (() => void) | void {
    if (!project) {
      return;
    }

    return firebase
      .firestore()
      .collection("projects")
      .doc(project.id)
      .collection("assignments")
      .onSnapshot(async (snap) => {
        setCdmAssignments(
          (
            await Promise.all(
              snap.docs.map(async (doc) => {
                const data = doc.data() as AssignmentProperties;

                if (data.type !== "wesley") {
                  return null;
                }

                return await Assignment.createFromQuerySnapshot(doc);
              })
            )
          ).filter((assign) => !!assign) as Assignment[]
        );
      });
  }
};

export { CdmClientDetailPage };
export default CdmClientDetailPage;
