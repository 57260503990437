import { Box } from "@material-ui/core";
import React, { FC } from "react";
import BudgetInformationDisplay from "../Components/Budget/BudgetInformationDisplay";
import { PageComponentProps } from "./CurrentJobPageMap";

export interface BudgetElementsTabProps extends PageComponentProps {}

const BudgetElementsTab: FC<BudgetElementsTabProps> = (props) => {
  return (
    <Box width="100%">
      <BudgetInformationDisplay
        noIconImage={true}
        projectId={props.project.id}
        profileId={props.profile.id}
        yardDifficultyRating={props.project.yardDifficultyRating}
        packageType={props.profile.package}
        clientBudgetIntent={props.project.clientBudgetIntent}
        designerMetaView
      />
    </Box>
  );
};

export { BudgetElementsTab };
export default BudgetElementsTab;
