import React, { FC, useState, useEffect, useMemo, useContext } from "react";
import { Typography, Divider, TextField, Box } from "@material-ui/core";
import { Addy } from "../../util/getAddress";
import { PageComponentProps } from "../../SelfAssignPage/CurrentJobPageMap";
import {
  Assignment,
  WesleyAssignmentDeliverables,
  Media,
  Project,
  YardDifficultyRating,
} from "@yardzen-inc/models";
import SelfAssignSubmitModal from "../../SelfAssignPage/SelfAssignSubmitModal";
import SuccessfulSubmitModal from "../../SelfAssignPage/SuccessfulSubmitModal";
import NewMediaGrid from "../../Components/NewMediaGrid";
import RevisionAPI from "../../util/RevisionAPI";
import genericLiisaNotification from "../../util/genericLiisaNotification";
import DesignNotes from "../../SelfAssignPage/DesignNotes";
import { Redirect } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { WesleySummaryPageProjectInformation } from "./WesleySummaryPageProjectInformation";
import GenericMultiUpload from "../../Components/GenericMultiUpload";
import environmentConstants from "../../ConstantValues/environmentConstants";
import { GenericOnCallFunctionAlert } from "../../util/genericAlert";
import { YZButton } from "@yardzen-inc/react-common";
import { usePostPLPHoldStatuses } from "../../util/hooks/usePostPLPHoldStatuses";
import { WesleySummaryPageProjectDifficulty } from "./WesleySummaryPageProjectDifficulty";
import { WesleySummaryPageEstimateNote } from "./WesleySummaryPageEstimateNote";
import {
  BUDGET_METADATA_BY_PROJECT_ID,
  UPDATE_BUDGET_METADATA,
  useMutation,
  useQuery,
} from "@yardzen-inc/graphql";
import { calculateDesignerVisibleBudget } from "../../util/functions/calculateDesignerVisibleBudget";
import {
  CDMPageContext,
  initialEstimateEducationNote,
} from "../../EmployeeView/CDM/CDMContextProvider";
import { HouseModelManager } from "./HouseModelManager";
import { isStarterPackage } from "../../util/isStarterPackage";

// TODO: replace one, two... with actual variant names.
export type ConceptBoardUploadVariants =
  | "house-model"
  | "budget-warning"
  | "three";

// function-and-flow tag now aplies to concept board, the tag name remains
export type WesleyFileTags =
  | "function-and-flow"
  | "brief"
  | "function-and-flow-raw"
  | "brief-raw";

let saveTimeout: any = null;

export interface PCP {
  assignment: Assignment;
}

export interface UploadState {
  [key: string]: "loading" | Media | null;
}

const WesleySummaryPage: FC<PageComponentProps & PCP> = (
  props: PageComponentProps & PCP
) => {
  const [notes, setNotes] = useState<string>(
    props.assignment.assignmentDeliverables.notes || ""
  );
  const [uploadState, setuploadState] = useState<UploadState>(
    getInitialUploadState
  );
  const [wesleyAnnotations, setWesleyAnnotations] = useState<Media[] | null>(
    null
  );
  const { data: budgetMetadata, loading: loadingBudgetMetadata } = useQuery(
    BUDGET_METADATA_BY_PROJECT_ID,
    {
      variables: {
        projectId: props.project.id,
      },
      fetchPolicy: "network-only",
    }
  );
  const [updateBudgetMetadata] = useMutation(UPDATE_BUDGET_METADATA);

  const {
    projectDifficulty,
    setProjectDifficulty,
    estimateEducationNote,
    setEstimateEducationNote,
  } = useContext(CDMPageContext);

  const [showSubmit, setShowSubmit] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [redirectoToCDM, setRedirectToCDM] = useState<boolean>(false);
  const { holds: postPLPHoldStatuses } = usePostPLPHoldStatuses(
    props.project.id
  );

  const hasStarterPackage = isStarterPackage(props.profile.package);
  const designerVisibleBudget = useMemo(() => {
    if (projectDifficulty && !loadingBudgetMetadata) {
      const clientBudget = getClientBudget();

      return calculateDesignerVisibleBudget(
        clientBudget,
        projectDifficulty
      ).toString();
    }
  }, [projectDifficulty, loadingBudgetMetadata]);
  const submittable = !isButtonDisabled();
  const address = props.address ? formatAddress(props.address) : null;

  useEffect(() => {
    (async function () {
      let newUploadState = { ...uploadState };

      await Promise.all(
        Object.keys(uploadState).map(async (key) => {
          const [tag, variant] = key.split("~");
          newUploadState[key] = await getSingleUploadedDocument(tag, variant);

          return;
        })
      );

      setuploadState(newUploadState);
    })();
  }, []);

  useEffect(() => {
    (async function () {
      setWesleyAnnotations(await getAllVideoAnnotationUploads());
    })();
  }, []);

  useEffect(() => {
    saveTimeout = setTimeout(async () => {
      props.assignment.assignmentDeliverables.notes = notes;
      await props.assignment.save();
    }, 300);

    return () => clearTimeout(saveTimeout);
  }, [notes]);

  useEffect(() => {
    const currentYardDifficultyForProject = props.project.yardDifficultyRating;

    const clientHasAnExistingYardDifficulty =
      currentYardDifficultyForProject !== undefined &&
      currentYardDifficultyForProject !== null;

    // This means that the CDM agent is viewing an assignment that has
    // already been submitted. In this case, we should set the project
    // difficulty to what was set when previoulsy submitted, unless there
    // has been a change since then.
    if (clientHasAnExistingYardDifficulty && projectDifficulty === null) {
      // We need the cast otherwise TS will complain about null or
      // undefined values. Our check above ensures that will not be the value
      setProjectDifficulty(
        currentYardDifficultyForProject as YardDifficultyRating
      );
    }
  }, [props.project.yardDifficultyRating, projectDifficulty]);

  useEffect(() => {
    const currentEstimateNotesForProject = props.project.estimateEducationNote;

    const clientHasAnExistingNote =
      currentEstimateNotesForProject !== undefined &&
      currentEstimateNotesForProject !== null;

    if (
      clientHasAnExistingNote &&
      estimateEducationNote === initialEstimateEducationNote
    ) {
      // Again this cast is necessary otherwise TS will complain about the
      // value being null or undefined. We know it's not from our check above.
      setEstimateEducationNote(currentEstimateNotesForProject as string);
    }
  }, [props.project.estimateEducationNote, estimateEducationNote]);

  const submissionContents = hasStarterPackage
    ? "Budget Estimation"
    : "Function & Flow Image and Budget Estimation";

  const submitMessageWarning = `Once you submit, your ${submissionContents} will be sent
                directly to the client!`;

  return (
    <>
      {redirectoToCDM && <Redirect to="/cdm?=invalidate_cache" />}
      {showSubmit && (
        <SelfAssignSubmitModal
          onClose={() => setShowSubmit(false)}
          onSubmit={async () => {
            await handleSubmit();
            setShowSubmit(false);
          }}
          message={
            <span>
              <em style={{ fontWeight: "bold" }}>{submitMessageWarning}</em>
              <br />
              <br />
              Please double check to make sure all materials are uploaded and
              that your submission meets all requirements of the project. You
              will no longer be able to view or modify your submission.
            </span>
          }
          submitting={submitting}
        />
      )}
      <SuccessfulSubmitModal
        onClose={() => {
          if (props.inLiisa) {
            return setRedirectToCDM(true);
          }

          window.location.reload();
        }}
        open={submitted}
      />
      <WesleySummaryPageProjectInformation {...props} addy={address} />
      <Box my={3}>
        <Divider></Divider>
      </Box>
      {props.profile.designerNotes && (
        <>
          <DesignNotes profile={props.profile} />
          <Box my={3}>
            <Divider></Divider>
          </Box>
        </>
      )}
      <Box>
        <Box mb={2}>
          <Typography variant="h5">Notes</Typography>
        </Box>
        <TextField
          variant="outlined"
          multiline
          fullWidth
          rows={4}
          value={notes}
          onChange={({ target: { value } }) => setNotes(value)}
        />

        {!hasStarterPackage && (
          <HouseModelManager
            profile={props.profile}
            assignment={props.assignment}
            uploadState={uploadState}
            setuploadState={setuploadState}
            getSingleUploadedDocument={getSingleUploadedDocument}
          />
        )}
      </Box>

      <Box my={3}>
        <Divider></Divider>
      </Box>
      <Box display="flex">
        <Box flexBasis="100%" textAlign="center">
          <WesleySummaryPageProjectDifficulty
            isBotanicalClient={props.profile.package === "botanical"}
            designerVisibleBudget={designerVisibleBudget}
          />
        </Box>
        <Box flexBasis="100%" textAlign="center">
          <WesleySummaryPageEstimateNote
            isBotanicalClient={props.profile.package === "botanical"}
          />
        </Box>
      </Box>
      <Box my={3}>
        <Divider></Divider>
      </Box>
      <Box>
        <Typography variant="h4" align="center">
          CDM working files (optional)
        </Typography>
        <Box display="flex">
          <GenericMultiUpload
            width="100%"
            display="flex"
            userId={props.profile.userId}
            tag="function-and-flow-raw"
          />
        </Box>
      </Box>
      <Box my={3}>
        <Divider></Divider>
      </Box>
      <Box>
        <Typography variant="h4" align="center">
          Video Annotations (optional)
        </Typography>
        <Box display="flex">
          <GenericMultiUpload
            width="100%"
            display="flex"
            userId={props.profile.userId}
            tag="wesley-annotations"
          />
        </Box>
      </Box>
      <Box my={3}>
        <Divider></Divider>
      </Box>
      {(wesleyAnnotations && wesleyAnnotations.length && (
        <NewMediaGrid
          cardType="readOnly"
          deletable
          userId={props.profile.id}
          media={"wesley-annotations"}
        />
      )) ||
        null}
      <Box display="flex" style={{ flexFlow: "row-reverse nowrap" }}>
        <YZButton
          variant="outlined"
          disabled={!submittable || props.assignment.completed || showSubmit}
          onClick={() => setShowSubmit(true)}
        >
          {getSubmitButtonText()}
        </YZButton>
      </Box>
    </>
  );

  function getSubmitButtonText(): string {
    if (props.assignment.completed) {
      return "Already Submitted";
    }

    return !submittable ? "Requirements missing" : "Send directly to client";
  }

  function isButtonDisabled() {
    if (hasStarterPackage) {
      return !projectDifficulty;
    }
    const firstUpload = uploadState["function-and-flow~house-model"];

    return !firstUpload || firstUpload === "loading" || !projectDifficulty;
  }

  function getInitialUploadState(): UploadState {
    // TODO: put experience version switching logic here

    return {
      "function-and-flow~house-model": "loading",
      "function-and-flow~budget-warning": "loading",
      "function-and-flow~design-team-questions": "loading",
    };
  }

  function formatAddress(addy: Addy): string {
    return `${addy.street} ${addy.city}, ${addy.state}, ${addy.zip}`;
  }

  async function getSingleUploadedDocument(
    tag: WesleyFileTags | string,
    variant?: string | WesleyFileTags
  ): Promise<Media | null> {
    try {
      let query = firebase
        .firestore()
        .collection("media")
        .where("userId", "==", props.profile.id)
        .where("tag", "==", tag);

      if (variant) {
        query = query.where("variant", "==", variant);
      }

      const mediaSnap = await query.get();

      if (mediaSnap.empty) {
        return null;
      }

      if (
        (process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT,
        mediaSnap.docs.length > 1)
      ) {
        console.error(
          `tag ${tag} with variant ${
            variant ?? "<none>"
          } has multiple media objects associated with it`
        );
      }

      return await Media.createFromQuerySnapshot(mediaSnap.docs[0]);
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async function getAllVideoAnnotationUploads(): Promise<Media[]> {
    const media = await Assignment.getAllMediaIdsByFileTags(
      ["wesley-annotations"],
      props.profile.id
    );

    if (media.length) {
      let mp = await Promise.all(media.map(Media.fetchSingle));
      mp = mp.filter((m) => !!m && !(m as any).deletedAt) as Media[];

      return mp as Media[];
    }

    return [];
  }

  async function handleSubmit() {
    const { assignment } = props;
    if (!submitting) setSubmitting(true);

    const _budgetMetadata = budgetMetadata.budget_metadata[0];
    const delivs: WesleyAssignmentDeliverables = {
      notes: notes,
      designBriefRawId: null,
      designBriefId: null,
      functionAndFlowId: "DEPRECATED",
      functionAndFlowRawId: "DEPRECATED",
      videoAnnotations:
        wesleyAnnotations && wesleyAnnotations.length
          ? (wesleyAnnotations as Media[]).map((m) => m.id)
          : [],
    };

    assignment.assignmentDeliverables = delivs;
    assignment.submittedAt = new Date().getTime();
    const project = await assignment.getProject();

    if (!project) {
      throw new Error("PROJECT WAS NOT FOUND");
    }

    if (!_budgetMetadata) {
      throw new Error("NO BUDGET METADATA");
    }

    if (!projectDifficulty) {
      throw new Error("NO PROJECT DIFFICULTY");
    }

    try {
      await firebase
        .firestore()
        .collection("projects")
        .doc(project.id)
        .update({
          useAutomatedDesignBrief: true,
          yardDifficultyRating: Number(projectDifficulty),
          estimateEducationNote: estimateEducationNote,
        });

      await updateBudgetMetadata({
        variables: {
          id: _budgetMetadata.id,
          project_id: project.id,
          total_budget: _budgetMetadata.total_budget,
          designer_visible_budget: calculateDesignerVisibleBudget(
            _budgetMetadata.total_budget,
            projectDifficulty
          ).toString(),
        },
      });

      if (!!project) {
        try {
          await RevisionAPI.sendFuncFlowToClient(project.profileId);
        } catch (e) {
          console.error(
            `Failed to send FF to client on assignment ${assignment.id}: `,
            e
          );
          await genericLiisaNotification({
            message: `Failed to send FF to client on assignment ${assignment.id}`,
            icon: ":rotating_light:",
          });
        }

        await createOrUpdatePostPLPHold(project);

        assignment.completed = true;
      } else if (!project) {
        await genericLiisaNotification({
          message: `No Project returned from assignment.getProject() on assignment ${assignment.id}`,
          icon: ":rotating_light:",
        });
      }

      const res = await assignment.save();

      setSubmitting(false);
      setSubmitted(true);

      return res;
    } catch (error) {
      console.error(error);
    }
  }

  async function createOrUpdatePostPLPHold(project: Project) {
    const putPostPLPHold = firebase
      .functions()
      .httpsCallable("putPostPLPHoldStatusesByProjectId");

    const newHolds = postPLPHoldStatuses ? postPLPHoldStatuses.slice() : [];
    newHolds.push("RECENTLY_DELIVERED");

    try {
      await putPostPLPHold({ projectId: project.id, newHolds });
    } catch (err) {
      GenericOnCallFunctionAlert(
        "createInitialPostPLPHold",
        `postPLPHolds record failed to be created \n projectId: ${project.id} \n intended holdStatuses propert: ["RECENTLY_DELIVERED"]`
      );
    }
  }

  function getClientBudget() {
    const _budgetMetadata = budgetMetadata?.budget_metadata[0];

    if (props.profile.adjustedBudget) {
      return props.profile.adjustedBudget;
    }

    if (_budgetMetadata?.total_budget) {
      return _budgetMetadata.total_budget;
    }

    /**
     * `reportedBudget` is deprecated, but we check it here to catch
     * any stragglers
     */
    if (props.profile.reportedBudget) {
      return props.profile.reportedBudget;
    }

    throw new Error("No client budget available");
  }
};

export { WesleySummaryPage };
