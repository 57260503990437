import React, { useState } from "react";
import MediaCard, { MediaCardProps } from "./MediaCard";
import { Pages, Delete, CloudDownload } from "@material-ui/icons";
import "firebase/compat/database";
import {
  makeStyles,
  IconButton,
  Typography,
  Box,
  LinearProgress,
} from "@material-ui/core";
import FullScreenImage from "./FullScreenImage";
import downloadAndZip from "../util/downloadAndZip";
import ConfirmDeleteDialog from "./modals/ConfirmDeleteDialog";

export interface AnnotatorCardProps extends MediaCardProps {
  deleteable?: boolean;
  readonly?: boolean;
  downloadable?: boolean;
}

const useStyles = makeStyles((theme) => ({
  openAnnotatorButtonContainer: {
    padding: "0.5rem",
    display: "flex",
    flexFlow: "row-reverse nowrap",
    alignItems: "center",
    flexGrow: 1,
    height: "100%",
    width: "100%",
    minHeight: "fit-content",
  },
}));

const AnnotatorCard = ({ readonly, ...props }: AnnotatorCardProps) => {
  const classes = useStyles();
  const [annotatorOpen, setAnnotatorOpen] = useState<boolean>(false);
  const [fadeOut, setFadeOut] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);

  return (
    <>
      {annotatorOpen && (
        <FullScreenImage
          open
          media={props.media as any}
          onClose={() => setAnnotatorOpen(false)}
        />
      )}

      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        prompt={`Are you sure you want to delete ${props.media.originalFileName}`}
        deleteFn={deleteMedia}
      />

      <div style={{ width: "100%", height: "fit-content" }}>
        <Typography variant="body1" noWrap>
          {props.media.title || props.media.originalFileName}
        </Typography>
        <MediaCard
          {...props}
          style={props.style}
          media={props.media}
          childrenToolBar={<>{renderButtons()}</>}
          noTitle
          out={fadeOut}
        />
      </div>
    </>
  );

  function renderButtons(): React.ReactNode {
    return (
      <Box width="100%" height="100%">
        <div className={classes.openAnnotatorButtonContainer}>
          <IconButton
            onClick={() => setAnnotatorOpen(true)}
            title="Open Fullscreen"
          >
            <Pages style={{ color: "white" }} />
          </IconButton>
          {props.deleteable && (
            <IconButton
              onClick={() => setConfirmDeleteOpen(true)}
              title="Delete"
            >
              <Delete style={{ color: "white" }} />
            </IconButton>
          )}
          {props.downloadable && (
            <IconButton
              onClick={async () => {
                setDownloading(true);
                try {
                  await downloadAndZip(
                    [props.media],
                    `${props.media.originalFileName}.zip`
                  );
                } catch (err) {
                  console.error(err);
                } finally {
                  setDownloading(false);
                }
              }}
              title="Download"
            >
              <CloudDownload style={{ color: "white" }} />
            </IconButton>
          )}
        </div>
        {downloading && <LinearProgress variant={"indeterminate"} />}
      </Box>
    );
  }

  function deleteMedia() {
    setFadeOut(true);
    props.media.delete();
  }
};

AnnotatorCard.defaultProps = {
  mediaCardProps: {},
};

export default AnnotatorCard;
