import * as React from "react";
import { TextField, Box } from "@material-ui/core";
import { Revision } from "@yardzen-inc/models";

export interface Props {
  selectedRevision: Revision;
  disabled?: boolean;
}

export const RevisionTitleRenamer = ({ selectedRevision, disabled }: Props) => {
  const [title, setTitle] = React.useState(selectedRevision.title ?? "");

  React.useEffect(onTitleChange, [title]);
  React.useEffect(onRevisionChange, [selectedRevision?.id ?? "none"]);

  return (
    <Box p={1}>
      <TextField
        label="Revision Name"
        value={title}
        disabled={!!disabled}
        onChange={({ currentTarget: { value } }) => setTitle(value)}
      />
    </Box>
  );

  function onRevisionChange() {
    if (title !== selectedRevision.title) {
      setTitle(selectedRevision.title);
    }
  }

  function onTitleChange(): (() => void) | void {
    const timeout = setTimeout(updateTitle, 300);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }

  async function updateTitle() {
    if (selectedRevision.title !== title) {
      selectedRevision.title = title;
      await selectedRevision.save();
    }
  }
};

export default RevisionTitleRenamer;
