import React from "react";
import AssetLibraryCreateItem from "../Shared/AssetLibraryItemCreate";
import AddNewSurface from "./AddNewSurface";

function SurfaceLibraryCreate() {
  return (
    <AssetLibraryCreateItem
      goBackLocation="/materials-library"
      addNewAssetComponent={(assetProps) => (
        <AddNewSurface {...assetProps} onSave={assetProps.onSave} />
      )}
    />
  );
}

export default SurfaceLibraryCreate;
