import * as React from "react";
import { Box, Grid } from "@material-ui/core";
import { visibilityValues } from "../Shared/VisibilityTypes";
import FilterMenu from "../Shared/FilterMenu";
import ChipSingleSelect from "../Shared/ChipSingleSelect";
import ChipPriceFilterMenu from "../Shared/ChipPriceFilterMenu";

interface Props {
  onPriceChange: (v: string, prop: string) => void;
  onMsrpChange?: (v: string, prop: string) => void;
  onVisibilityChange?: (v: string) => void;
  visibility?: string;
  minPrice: null | number;
  maxPrice: null | number;
  minMsrp?: null | number;
  maxMsrp?: null | number;
}

function ElementsTableFilters(props: Props) {
  return (
    <Box pl={1}>
      <Grid container spacing={1}>
        <Grid item>
          <ChipPriceFilterMenu
            assetType="Cost"
            minPrice={props.minPrice ?? null}
            maxPrice={props.maxPrice ?? null}
            onPriceChange={props.onPriceChange}
          />
        </Grid>
        {!!props.onMsrpChange && (
          <Grid item>
            <ChipPriceFilterMenu
              assetType="MSRP"
              minPrice={props.minMsrp ?? null}
              maxPrice={props.maxMsrp ?? null}
              onPriceChange={props.onMsrpChange}
            />
          </Grid>
        )}
        {!!(
          props.onVisibilityChange && typeof props.visibility === "string"
        ) && (
          <Grid item>
            <FilterMenu label="Visibility">
              <ChipSingleSelect
                onChange={props.onVisibilityChange}
                initialValue={props.visibility}
                options={visibilityValues}
                title="Filter by visibility:"
                shouldDisplay
              />
            </FilterMenu>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default ElementsTableFilters;
