import { Box } from "@material-ui/core";
import { ClientBudgetIntent, YardDifficultyRating } from "@yardzen-inc/models";
import React, { FC } from "react";
import { BudgetDisplayWrapper } from "./BudgetDisplayWrapper";
import BudgetItemSelectionList from "./BudgetItemSelectionList";
import BudgetOrderCopySlab from "./BudgetOrderCopySlab";

export interface BudgetInformationDisplayProps {
  projectId: string;
  profileId: string;
  noIconImage?: boolean;
  designerMetaView?: boolean;
  yardDifficultyRating: YardDifficultyRating | null | undefined;
  packageType?: string;
  clientBudgetIntent?: ClientBudgetIntent | null;
}

const BudgetInformationDisplay: FC<BudgetInformationDisplayProps> = (props) => {
  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box p={1}>
        <Box boxShadow={2} p={2} borderRadius={"12px"} bgcolor="whitesmoke">
          <BudgetDisplayWrapper
            projectId={props.projectId}
            packageType={props.packageType}
            yardDifficultyRating={props.yardDifficultyRating}
            clientBudgetIntent={props.clientBudgetIntent}
          />
        </Box>
      </Box>
      <BudgetOrderCopySlab />
      <Box p={2}>
        <BudgetItemSelectionList
          noIconImage={props.noIconImage}
          projectId={props.projectId}
        />
      </Box>
    </Box>
  );
};

export { BudgetInformationDisplay };
export default BudgetInformationDisplay;
