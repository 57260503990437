import * as React from "react";
import NewMediaGrid from "../Components/NewMediaGrid";
import FileUpload from "../Components/FileUpload";
import { Media } from "@yardzen-inc/models";
import { Box, useTheme } from "@material-ui/core";

export interface UploadAndMediaGridProps {
  revisionId: string;
  userId: string;
  onUpload: (media: Media | null) => void;
  fileTag: string;
  accept?: string[];
  downloadable?: boolean;
  variant?: string;
  useVariant?: boolean; // Overrides tag-based filtering
}

export function UploadAndMediaGrid({
  revisionId,
  userId,
  onUpload,
  fileTag,
  accept,
  downloadable,
  variant,
  useVariant,
}: UploadAndMediaGridProps) {
  const theme = useTheme();

  return (
    <div>
      <NewMediaGrid
        cardType="readOnly"
        downloadable={downloadable}
        noNoItems
        fileTag={fileTag}
        revisionId={revisionId}
        onUpdate={(meds: Media[] | null) => {
          if (!meds) {
            return onUpload(null);
          }

          onUpload(meds[0]);
        }}
        deletable
        noLazyLoad
        userId={userId}
        variant={variant}
        useVariant={useVariant}
      />
      <Box style={{ marginTop: theme.spacing(2) }}>
        <FileUpload
          clientUserId={userId}
          revisionId={revisionId}
          fileTag={fileTag}
          fileVisibility={{}}
          variant={variant}
          accept={accept || ["image/*", ".pdf"]}
        />
      </Box>
    </div>
  );
}
