import * as React from "react";
import "firebase/compat/database";
import { makeStyles } from "@material-ui/core";
import { AgentCard } from "./Agent/AgentCard";
import { Assignment, Agent } from "@yardzen-inc/models";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "row wrap",
    padding: "0.5rem 0",
  },
});

interface Props {
  filter: string;
}

type AgentAssignmentMap = {
  [key: string]: {
    agent: Agent;
    assignments: Assignment[];
  };
};

export default React.memo((props: Props) => {
  const classes = useStyles();
  const [agents, setAgents] = React.useState<AgentAssignmentMap | null>(null);

  React.useEffect(() => {
    (async function () {
      setAgents(await Agent.getUnpaidAgents());
    })();

    return () => {
      if (!agents) return;
      for (let agent of Object.values(agents)) {
        agent.agent.destroy();
      }
    };
  }, []);

  let nofilter = false;

  if (!props.filter.length) {
    nofilter = true;
  }

  return (
    <div className={classes.root}>
      {Object.values(agents || {})
        .filter((agent) => {
          if (nofilter) {
            return true;
          } else return agent.agent.email.includes(props.filter);
        })
        .map((agent) => (
          <AgentCard
            key={agent.agent.id}
            agent={agent.agent}
            unpaid={agent.assignments.length}
          />
        ))}
    </div>
  );
});
