import React, { FC, useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { useSkipMount } from "../../util/hooks";

export interface DesignBriefCheckboxProps extends CheckboxProps {
  update: (value: boolean) => void;
  value: boolean;
}

const DesignBriefCheckbox: FC<DesignBriefCheckboxProps> = (props) => {
  const [checked, setChecked] = useState<boolean>(props.value);

  const firstRunComplete = useSkipMount();

  useEffect(handleCheckedChange, [checked]);
  useEffect(handleValueChange, [props.value]);

  return (
    <Box p={1}>
      <Checkbox
        {...props}
        color="primary"
        checked={checked}
        onChange={() => setChecked(!checked)}
      />
    </Box>
  );

  function handleValueChange(): void {
    if (props.value !== checked) {
      setChecked(props.value);
    }
  }

  function handleCheckedChange(): (() => void) | void {
    if (!firstRunComplete) {
      return void 0;
    }

    const timeout = setTimeout(async () => {
      await props.update(checked);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }
};

export { DesignBriefCheckbox };
export default DesignBriefCheckbox;
