import React, { FC, useState, useMemo } from "react";
import { Box, Typography, Tooltip, makeStyles } from "@material-ui/core";
import { Home, FlashOn, Build } from "@material-ui/icons";
import moment from "moment";
import { Redirect } from "react-router";
import { getTimeSinceDate } from "../../util/functions/getTimeSinceDate";
import { VIPIcon } from "@yardzen-inc/react-common";
import isProduction from "../../util/isProduction";
import { getIsExteriorPackage } from "../../util/selfAssign/getIsExteriorPackage";
import { PackageType } from "../../Interfaces";
import { getExpeditedAssignmentStartByDate } from "../../util/selfAssign/getExpeditedAssignmentStartByDate";
import { getPackageDisplayName } from "../../util/functions/getPackageDisplayName";
import { SelfAssignQueueItems } from "src/api/pangaeaTypes";

const useStyles = makeStyles({
  expeditedIcon: {
    fontSize: "2.2rem",
    color: "#f58b2f",
  },
  proIcon: {
    fontSize: "2rem",
    color: "#9234eb",
  },
  exteriorIcon: {
    fontSize: "2.5rem",
  },
  expeditedOverdue: {
    color: "red",
  },
  timeInQueue: { color: "black" },
});
export interface DesignAssignmentListItemProps {
  queueItem: SelfAssignQueueItems[0];
  queuePosition: number;
}

const DesignAssignmentListItem: FC<DesignAssignmentListItemProps> = ({
  queueItem: { profile, project, designAssignment },
  queuePosition,
}) => {
  const classes = useStyles();
  const [redirect, setRedirect] = useState<boolean>(false);
  const isVIP = project.tags?.includes("VIP");
  const isExterior = getIsExteriorPackage(profile.package as PackageType);
  const isExpedited = project.isExpedited;
  const isProDesign = project.isProDesign;

  const dates = useMemo(() => {
    const v1Created = new Date(designAssignment.createdAt).getTime();
    const expeditedStartBy = getExpeditedAssignmentStartByDate(v1Created);
    const timeInQueue = getTimeSinceDate(v1Created);

    return {
      timeInQueue,
      expeditedStartBy,
    };
  }, [designAssignment]);

  const profilePath: string = `client/${profile.id}`;

  const fullProfileLink: string = isProduction()
    ? `https://liisa.office.yardzen.com/${profilePath}`
    : `https://liisa.dogfood.yardzen.com/${profilePath}`;

  const expeditedOverdue =
    isExpedited && new Date().getTime() > dates.expeditedStartBy.getTime();

  return (
    <>
      {redirect && <Redirect to={profilePath} />}
      <Box display="flex" flexDirection="column" my={1} boxShadow={1} p={2}>
        <Box display="flex" flexDirection="row">
          {isExpedited && (
            <Tooltip title="Client purchased an expedited design package">
              <FlashOn className={classes.expeditedIcon} />
            </Tooltip>
          )}
          {isProDesign && (
            <Tooltip title="Pro design package">
              <Build className={classes.proIcon} />
            </Tooltip>
          )}
          {isVIP && (
            <Tooltip title="This is a VIP client!">
              <div>
                <VIPIcon size={40} />
              </div>
            </Tooltip>
          )}
          {isExterior && (
            <Tooltip title="Client purchased an exterior design package">
              <Home className={classes.exteriorIcon} />
            </Tooltip>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          justifyContent="space-between"
          pb={1}
        >
          <Typography variant="h4">
            {profile.firstName} {profile.lastName}
            {" - "}
            {getPackageDisplayName(profile.package)}
          </Typography>
          <Typography variant="h4">{queuePosition}</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          justifyContent="space-between"
          pb={1}
        >
          <Typography variant={"h5"} gutterBottom>
            {profile.street} {profile.city} {profile.state} {profile.zip}
          </Typography>
          <Typography variant={"body1"} gutterBottom>
            Materials submitted:{" "}
            {moment(profile.wizardDone).format("MM/DD/YYYY hh:mm:ss")}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          justifyContent="space-between"
          pb={1}
        >
          <Typography
            variant="body1"
            component="a"
            style={{ pointerEvents: "all", cursor: "pointer", color: "blue" }}
            onClick={() => setRedirect(true)}
          >
            {fullProfileLink}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={
              expeditedOverdue ? classes.expeditedOverdue : classes.timeInQueue
            }
          >
            {expeditedOverdue && "Expedited should be started - "}
            Time in queue: {dates.timeInQueue}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export { DesignAssignmentListItem };
