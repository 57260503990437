import * as React from "react";
import { DesignAssignment, Project } from "@yardzen-inc/models";
import moment from "moment";

export interface DueDateFetcherProps {
  project: Project;
}

export const DueDateFetcher = (props: DueDateFetcherProps) => {
  const [dueDate, setDueDate] = React.useState<string>("loading...");

  React.useEffect(getDueDate, [props.project]);

  return <span>{dueDate}</span>;

  function getDueDate(): void {
    if (!props.project.currentDesignAssignmentId) {
      return setDueDate("unknown");
    }

    void (async function () {
      const da = await DesignAssignment.fetch(
        props.project.id,
        props.project.currentDesignAssignmentId as string
      );

      if (da && da.dueDate) {
        return setDueDate(moment(da.dueDate).format("MM/DD/YY"));
      }

      setDueDate("unknown");
    })();
  }
};

export default DueDateFetcher;
