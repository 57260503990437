import React, { FC, useMemo } from "react";
import { Box, TextField } from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";

export interface DesignBriefEntryFormTextValueProps extends BoxProps {
  value: string;
  onChange: (value: any) => any;
}

const DesignBriefEntryFormTextValue: FC<DesignBriefEntryFormTextValueProps> = ({
  value,
  onChange,
  ...containerProps
}) => {
  const valueIsString = useMemo(() => typeof value === "string", [value]);

  if (!valueIsString) {
    return null;
  }

  return (
    <Box>
      <TextField
        value={value}
        fullWidth
        multiline
        label="value"
        onChange={({ currentTarget: { value } }) => onChange(value)}
      />
    </Box>
  );
};

export { DesignBriefEntryFormTextValue };
export default DesignBriefEntryFormTextValue;
