import * as React from "react";
import { Box, Checkbox, Typography } from "@material-ui/core";
import {
  PLANTS,
  MATERIALS,
  ELEMENTS,
  pdfTypes,
  PdfListInclusion,
} from "./IncludeMediaInSharedLink";

interface PdfListSelectProps {
  pdfList: PdfListInclusion;
  handlePdfListUpdate: (key: pdfTypes, value: boolean) => void;
}

const PdfListSelect: React.FunctionComponent<PdfListSelectProps> = ({
  pdfList,
  handlePdfListUpdate,
}) => {
  return (
    <Box display="flex" ml={2}>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={pdfList[PLANTS]}
          onChange={(e, checked) => handlePdfListUpdate(PLANTS, checked)}
        />
        <Typography>Plants</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={pdfList[MATERIALS]}
          onChange={(e, checked) => handlePdfListUpdate(MATERIALS, checked)}
        />
        <Typography>Materials</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={pdfList[ELEMENTS]}
          onChange={(e, checked) => handlePdfListUpdate(ELEMENTS, checked)}
        />
        <Typography>Elements</Typography>
      </Box>
    </Box>
  );
};

export default PdfListSelect;
