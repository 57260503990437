import { useRef, useEffect } from "react";

// returns false for the first render, true for any after
const useSkipMount = (): boolean => {
  const initialRunRef = useRef<boolean>(false);

  useEffect(onMount, []);

  return initialRunRef.current;

  function onMount(): void {
    initialRunRef.current = true;
  }
};

export { useSkipMount };
export default useSkipMount;
