import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { Close, Delete } from "@material-ui/icons";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import {
  IFormikElementProps,
  IFormikPlantProps,
  IFormikSurfaceProps,
} from "../../../Interfaces";

export interface FormikDeleteFileProps {
  fieldName: string;
  mediaHost?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.error.light,
  },
  dialogContent: {
    display: "flex",
    justifyContent: "center",
  },
}));

const FormikDeleteFile: FC<FormikDeleteFileProps> = ({
  fieldName,
  mediaHost,
  ...uploadComponentProps
}) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext<
    IFormikElementProps | IFormikSurfaceProps | IFormikPlantProps
  >();

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const [message, setMessage] = React.useState<string>("");

  return (
    <>
      <Dialog open={confirmDialogOpen}>
        <Box position="relative" p={2}>
          <Box position="absolute" top={5} right={5}>
            <IconButton
              size="small"
              onClick={() => setConfirmDialogOpen(false)}
            >
              <Close />
            </IconButton>
          </Box>
          <DialogTitle>
            Are you sure you want to delete this file? This cannot be undone.
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setConfirmDialogOpen(false);
                handleDelete();
              }}
            >
              Confirm
            </Button>
          </DialogContent>
        </Box>
      </Dialog>
      <div className={classes.container}>
        {message || (
          <IconButton>
            <Delete onClick={() => setConfirmDialogOpen(true)} />
          </IconButton>
        )}
      </div>
    </>
  );

  function handleDelete(): void {
    setFieldValue(fieldName, null);
    setMessage("Save changes to complete deletion.");
  }
};

export { FormikDeleteFile };
export default FormikDeleteFile;
