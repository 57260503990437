import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import { DesignAssignment } from "@yardzen-inc/models";
import makeDesignAssignmentSlug from "./util/makeDesignAssignmentSlug";

export interface NoteListNoteProps {
  da: DesignAssignment;
  lastName: string;
  street: string;
}

export const NoteListNote = (props: NoteListNoteProps) => {
  const display = React.useMemo(() => {
    if (!props.da.notes) {
      return "none";
    }

    return "flex";
  }, [props.da.notes]);

  return (
    <Box display={display} flexDirection="column">
      <Typography variant="h5" gutterBottom>
        {makeDesignAssignmentSlug(props.lastName, props.street, props.da.type)}
      </Typography>
      <Box m={1} bgcolor="#E3E3E3" p={1} mt={2}>
        <Typography variant="body1">{props.da.notes}</Typography>
      </Box>
    </Box>
  );
};

export default NoteListNote;
