import React, { FC, ReactNode } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { EntryValueType } from "./DesignBriefEntryEditForm";
import { DesignBriefEntryType } from "./util";
import DesignBriefEntryFormTextValue from "./util/DesignBriefEntryFormTextValue";
import DesignBriefEntryFormArrayValue from "./DesignBriefEntryFormArrayValue";

export interface DesignBriefEntryEditFormValueFieldProps extends BoxProps {
  type: DesignBriefEntryType;
  value: EntryValueType;
  options?: string[];
  onChange: (value: any) => void;
  onOptionsChange: (options: string[]) => void;
}

const DesignBriefEntryEditFormValueField: FC<DesignBriefEntryEditFormValueFieldProps> =
  ({ value, onChange, type, onOptionsChange, options, ...props }) => {
    return <Box {...props}>{render()}</Box>;

    function render(): ReactNode {
      if (type === DesignBriefEntryType.TEXT) {
        return (
          <DesignBriefEntryFormTextValue
            value={value as string}
            onChange={onChange}
          />
        );
      }

      if (type === DesignBriefEntryType.MULITPLE_CHOICE) {
        return (
          <DesignBriefEntryFormArrayValue
            onChange={onChange}
            onOptionsChange={onOptionsChange}
            value={{ value: value as string, options: options ?? [] }}
          />
        );
      }

      throw new Error("type does not exist or is not implemented");
    }
  };

export { DesignBriefEntryEditFormValueField };
export default DesignBriefEntryEditFormValueField;
