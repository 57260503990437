import * as React from "react";
import { Tooltip, Avatar, Typography, Link } from "@material-ui/core";
import { WootricDocument } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import moment from "moment";
import { WootricDetailModal } from "./WootricDetailModal";

export interface Props {
  profileId: string;
  style?: any;
  className?: string;
}

export function WootricDisplay({ profileId, style }: Props) {
  React.useEffect(subscribeToWootric, []);
  const [scores, setScores] = React.useState<WootricDocument[]>([]);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const nonDeclinedScores = scores.filter((score) => {
    return !!score.score;
  });

  if (!!nonDeclinedScores.length) {
    const currentScore = nonDeclinedScores[nonDeclinedScores.length - 1];

    return (
      <div style={style}>
        <WootricDetailModal
          wootricSurveys={scores}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
        <Tooltip
          leaveDelay={800}
          interactive
          title={
            <div>
              {currentScore.text && (
                <div>
                  <Typography
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span style={{ fontSize: "0.8rem" }}>
                      {moment(currentScore.docCreatedAt.toMillis()).format(
                        "MM/DD/YY"
                      )}
                    </span>
                  </Typography>
                  <Typography>
                    <em>{currentScore.text}</em>
                  </Typography>
                </div>
              )}
              {scores.length > 1 && (
                <Link
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    fontSize: "0.8rem",
                  }}
                  onClick={() => setModalOpen(!modalOpen)}
                >
                  <em>see more</em>
                </Link>
              )}
            </div>
          }
        >
          <Avatar
            style={{
              backgroundColor: getBackgroundColor(currentScore.score as string),
              width: "32px",
              height: "32px",
            }}
          >
            {currentScore.score}
          </Avatar>
        </Tooltip>
      </div>
    );
  }

  return <></>;

  function getBackgroundColor(score: string) {
    const num = Number(score);

    if (num >= 9) {
      return "#76cd1e";
    }
    if (num >= 7) {
      return "#40a7e4";
    }
    return "#cb7a63";
  }

  function subscribeToWootric() {
    return firebase
      .firestore()
      .collection("wootricSurveys")
      .where("userId", "==", profileId)
      .orderBy("docCreatedAt", "asc")
      .onSnapshot((snap) => {
        if (snap.empty) return;
        const arr: WootricDocument[] = [];
        snap.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          arr.push(data as WootricDocument);
        });
        setScores(arr);
      });
  }
}
