import { Agent } from "@yardzen-inc/models";
import { assignAssignment } from "../firebaseTransactions";
import genericAlert from "../genericAlert";
import { fetchPrioritizedOpenModelingAssignments } from "../hooks/useOpenModelingAssignments";

async function assignNextProjectToModeler(
  agent: Agent,
  setSnackbarText: (value: React.SetStateAction<string | null>) => void,
  getAssigned: () => Promise<void>
): Promise<void> {
  const assignmentsWithProfiles =
    await fetchPrioritizedOpenModelingAssignments();
  const assignments = assignmentsWithProfiles.map(
    (modelingAssignment) => modelingAssignment.assignment
  );

  for (let a of assignments) {
    try {
      if (!a.listed) {
        await genericAlert(
          `Please confirm unlisted assignment ${a.id} was blocked from assign`
        );
        setSnackbarText(
          "Encountered an unlisted assignment! Please let a PM know you got this error, thank you"
        );
        continue;
      }

      await assignAssignment(agent, a, false);
      await getAssigned();
      break;
    } catch (err) {
      console.error(err);
      await genericAlert(
        `
          Unknown error occured in self-assign: ${err}
          Agent: ${agent.id}
          ${agent.firstName} ${agent.lastName}
          ${agent.email}
        `
      );
      setSnackbarText(
        "An unknown error has occurred, please try again. If you continue to have this issue, please contact the Yardzen support team."
      );
      continue;
    }
  }
}

export { assignNextProjectToModeler };
