import {
  Dialog,
  DialogContent,
  Box,
  DialogProps,
  Checkbox,
  Select,
  MenuItem,
  LinearProgress,
  makeStyles,
  TextField,
  Divider,
  InputLabel,
  FormHelperText,
  Theme,
} from "@material-ui/core";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import { AgentGroup } from "@yardzen-inc/util";
import * as React from "react";
import GenericSnackBar from "../../../Components/GenericSnackBar";
import {
  AGENT_TIERS,
  AGENT_TYPES,
  getAgentQualificationOptionsByTier,
  AgentQualification,
  AgentType,
  AgentTier,
  Agent,
} from "../../../ConstantValues/AgentConstants";
import * as firebaseClient from "../../../util/firebase/firebaseClient";

const useStyles = makeStyles((theme: Theme) => ({
  disabledCheckbox: {
    color: "lightgray",
  },
  qualifications: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: theme.spacing(2),
  },
}));

interface IUpdateAgentModalProps {
  agent: Agent;
  groups: (AgentGroup & {
    id: string;
  })[];
  onClose: () => void;
  open: boolean;
  dialogProps?: DialogProps;
}

const UpdateAgentModal: React.FunctionComponent<IUpdateAgentModalProps> = ({
  dialogProps,
  agent,
  onClose,
  open,
  groups,
}) => {
  const classes = useStyles();
  const [agentQualifications, setAgentQualifications] = React.useState<
    AgentQualification[]
  >((agent.qualifications as AgentQualification[]) || []);
  const [agentGroupId, setAgentGroupId] = React.useState<string | null>(
    agent.groupId || null
  );
  const [agentType, setAgentType] = React.useState<AgentType>(agent.type);
  const [agentTier, setAgentTier] = React.useState<AgentTier>(
    agent.tier ?? AgentTier.NewDesigner
  );
  const [agentFirstName, setAgentFirstName] = React.useState<string>(
    agent.firstName
  );
  const [agentLastName, setAgentLastName] = React.useState<string>(
    agent.lastName
  );
  const [agentEmail, setAgentEmail] = React.useState<string>(agent.email);
  const [agentPaymentEmail, setAgentPaymentEmail] = React.useState<
    string | null
  >(agent.paymentEmail || null);
  const [agentSlackEmail, setAgentSlackEmail] = React.useState<string | null>(
    agent.slackEmail || null
  );

  const [working, setWorking] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const isDesigner = agentType === "design";

  const needsGroup = isDesigner && agentGroupId === null;

  const qualificationOptions = getAgentQualificationOptionsByTier(agentTier);

  return (
    <>
      <GenericSnackBar
        variant="success"
        in={success}
        message={`${agent.firstName}'s properties successfully updated`}
        onClose={() => setSuccess(false)}
      />
      <GenericSnackBar
        variant="error"
        in={!!error}
        message={error || ""}
        onClose={() => setError(null)}
      />
      <Dialog
        open={open}
        {...dialogProps}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Box p={1}>
            <YZTypography variant="h4">
              {agent.firstName} {agent.lastName}
            </YZTypography>
          </Box>
          <Box p={2}>
            <Divider />
          </Box>
          <YZTypography variant="h5" align="center">
            Agent Settings
          </YZTypography>
          <Box p={1}>
            <InputLabel>Agent Type</InputLabel>
            <Select
              value={agentType}
              onChange={({ target: { value } }) => {
                setAgentType(value as AgentType);
              }}
            >
              {AGENT_TYPES.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box p={1}>
            <InputLabel>Group</InputLabel>
            <Select
              value={agentGroupId === null ? "none" : agentGroupId}
              disabled={working}
              onChange={({ target: { value } }) =>
                setAgentGroupId(value === "none" ? null : (value as string))
              }
            >
              <MenuItem value="none">
                <em>None</em>
              </MenuItem>
              {groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.identifier}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {isDesigner && (
            <Box p={1}>
              <InputLabel>Tier</InputLabel>
              <Select
                value={agentTier}
                onChange={({ target: { value } }) => {
                  setAgentTier(value as AgentTier);
                }}
                inputProps={{ "data-testid": "select-tier" }}
              >
                {AGENT_TIERS.map((t) => (
                  <MenuItem key={t.tier} value={t.tier}>
                    {t.tier}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          {isDesigner && qualificationOptions?.length > 0 && (
            <>
              <Box p={1}>
                <InputLabel>Agent Qualifications:</InputLabel>
              </Box>
              <Box p={1} className={classes.qualifications}>
                {qualificationOptions.map((option) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    key={option.qualification}
                  >
                    <Checkbox
                      value={option.qualification}
                      checked={
                        agentQualifications.includes(option.qualification) ||
                        option.isCheckedByDefault
                      }
                      onChange={handleCheckboxChange}
                    />
                    <YZTypography
                      className={
                        option.isCheckedByDefault
                          ? classes.disabledCheckbox
                          : ""
                      }
                    >
                      {option.qualification}
                      {option.isCheckedByDefault ? (
                        <FormHelperText>
                          *auto-selected based on Tier
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                      {option.qualification ===
                      AgentQualification.StarterExclusive ? (
                        <FormHelperText>only starter packages</FormHelperText>
                      ) : (
                        ""
                      )}
                      {option.qualification ===
                      AgentQualification.StarterInclusive ? (
                        <FormHelperText>
                          starter + traditional packages
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </YZTypography>
                  </Box>
                ))}
              </Box>
            </>
          )}
          <Box>
            {needsGroup && (
              <YZTypography color="error" variant="caption">
                Please assign Agent to Group
              </YZTypography>
            )}
            <Box p={2}>
              <Divider />
            </Box>
            <YZTypography variant="h5" align="center">
              Agent Details
            </YZTypography>
            <Box p={1}>
              <TextField
                value={agentFirstName}
                label="First Name"
                onChange={(e) => setAgentFirstName(e.currentTarget.value)}
                inputProps={{ "data-testid": "agent-first-name-input" }}
              />
            </Box>
            <Box p={1}>
              <TextField
                value={agentLastName}
                label="Last Name"
                onChange={(e) => setAgentLastName(e.currentTarget.value)}
              />
            </Box>
            <Box p={1}>
              <TextField
                value={agentEmail}
                label="Email"
                onChange={(e) => setAgentEmail(e.currentTarget.value)}
                fullWidth
                inputProps={{ "data-testid": "agent-email-input" }}
              />
              <TextField
                value={agentPaymentEmail}
                label="Payment Email"
                onChange={(e) => setAgentPaymentEmail(e.currentTarget.value)}
                fullWidth
              />
              <TextField
                value={agentSlackEmail}
                label="Slack Email"
                onChange={(e) => setAgentSlackEmail(e.currentTarget.value)}
                fullWidth
              />
            </Box>
            <Box p={1}>
              <YZButton
                fullWidth
                onClick={updateAgentProperties}
                disabled={working || needsGroup}
                color="primary"
              >
                Update Agent
              </YZButton>
            </Box>
            {working && <LinearProgress />}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );

  function handleCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    if (checked) {
      let currentAgentQualifications = [...agentQualifications];

      if (event.target.value === AgentQualification.StarterExclusive) {
        currentAgentQualifications = currentAgentQualifications.filter((q) => {
          return q !== AgentQualification.StarterInclusive;
        });
      }

      if (event.target.value === AgentQualification.StarterInclusive) {
        currentAgentQualifications = currentAgentQualifications.filter((q) => {
          return q !== AgentQualification.StarterExclusive;
        });
      }

      setAgentQualifications([
        ...currentAgentQualifications,
        event.target.value as AgentQualification,
      ]);
    } else {
      setAgentQualifications((agentQualifications) =>
        agentQualifications.filter((item) => item !== event.target.value)
      );
    }
  }

  function getUpdatedAgentQualificationsBasedOnTier() {
    //this is to handle legacy data from before Tiers were in place
    //and to add/remove default selected qualifications when tiers change

    if (agentTier === AgentTier.NewDesigner || !isDesigner) {
      //New Designers and non-designers have no qualifications
      return [];
    } else {
      const defaultQualifications = qualificationOptions
        .filter((o) => o.isCheckedByDefault)
        .map((o) => o.qualification);

      const allowedAndSelectedQualifications = agentQualifications.filter((q) =>
        qualificationOptions.map((o) => o.qualification).includes(q)
      );

      return [
        ...new Set(
          defaultQualifications.concat(allowedAndSelectedQualifications)
        ),
      ];
    }
  }

  async function updateAgentProperties() {
    setWorking(true);
    try {
      const updatedAgentQualifications =
        getUpdatedAgentQualificationsBasedOnTier();
      setAgentQualifications(updatedAgentQualifications);

      const shouldUpdateAuthEmail = agent.email !== agentEmail;
      if (shouldUpdateAuthEmail) {
        await firebaseClient.updateAgentAuthEmail(agent.userId, agentEmail);
      }

      await firebaseClient.updateAgentProperties(agent, {
        ...agent,
        type: agentType,
        qualifications: updatedAgentQualifications,
        groupId: agentGroupId,
        tier: agentTier,
        firstName: agentFirstName,
        lastName: agentLastName,
        email: agentEmail,
        paymentEmail: agentPaymentEmail,
        slackEmail: agentSlackEmail,
      });

      setSuccess(true);
      onClose();
    } catch (error) {
      console.error(error);
      setError((error as Error).message || JSON.stringify(error));
    } finally {
      setWorking(false);
    }
  }
};

export { UpdateAgentModal };
