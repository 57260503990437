import * as React from "react";
import firebase from "firebase/compat/app";
import { Drawer, Box, IconButton } from "@material-ui/core";
import ReadOnlyCard from "../../Components/ReadOnlyCard";
import {
  useQuerySnapshot,
  QuerySnapshotHandler,
  useLogError,
} from "../../util/hooks";
import { Media, MediaProperties } from "@yardzen-inc/models";
import { Close } from "@material-ui/icons";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";

export interface DesignChecklistDrawerProps {
  userId: string;
  revisionId: string;
}

const handleSnapshot: QuerySnapshotHandler<Media[]> = (snap) => {
  if (!snap.docs.length) {
    return [];
  }

  const docs = snap.docs.filter((d) => {
    const data = d.data() as MediaProperties;

    if (data.tag?.includes("design-checklist")) {
      return true;
    }

    return false;
  });

  return Promise.all(docs.map((d) => Media.createFromQuerySnapshot(d)));
};

const getQuery = (
  userId: string,
  revisionId: string
): firebase.firestore.Query => {
  return firebase
    .firestore()
    .collection("media")
    .where("userId", "==", userId)
    .where("revisionId", "==", revisionId);
};

const DesignChecklistDrawer: React.FC<DesignChecklistDrawerProps> = React.memo(
  ({ userId, revisionId }: DesignChecklistDrawerProps) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [err, data] = useQuerySnapshot(
      getQuery(userId, revisionId),
      handleSnapshot,
      [revisionId]
    );

    useLogError(err);

    const cadLength = React.useMemo(() => {
      if (!!data) {
        return data.length.toString();
      }
      if (data === null) {
        return "loading";
      } else {
        return 0;
      }
    }, [data]);

    return (
      <>
        <YZButton onClick={() => setOpen(!open)} variant="outlined">
          <YZTypography
            type="mono"
            style={{ padding: "7.5px 12px", fontSize: "14.4px" }}
          >
            View Checklist ({cadLength})
          </YZTypography>
        </YZButton>
        <Drawer variant="temporary" open={open} onClose={() => setOpen(false)}>
          <Box
            display="flex"
            flexDirection="column"
            p={1}
            minWidth="500px"
            maxWidth="100vw"
          >
            <Box display="flex" flexDirection="row-reverse">
              <IconButton onClick={() => setOpen(false)}>
                <Close></Close>
              </IconButton>
            </Box>

            {open && renderCads(data || [])}
          </Box>
        </Drawer>
      </>
    );
  },
  (p, n) => p.revisionId === n.revisionId && open === open
);

function renderCads(cads: Media[]): React.ReactNode[] {
  return cads.map((c) => {
    return (
      <Box mt={1} key={`${c}-rii`}>
        <ReadOnlyCard downloadable media={c} deleteable={false} />
      </Box>
    );
  });
}

export { DesignChecklistDrawer };

export default DesignChecklistDrawer;
