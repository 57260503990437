import { LinearProgress, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";
import firebase from "firebase/compat/app";
import { Media } from "@yardzen-inc/models";

import FileUpload from "../Components/FileUpload";
import downloadAndZip from "../util/downloadAndZip";
import { YZButton } from "@yardzen-inc/react-common";

interface Props {
  clientId: string;
  tag: string;
  onUploadComplete?: () => void;
}

const useStyles = makeStyles((theme) => ({
  info: {
    display: "flex",
    flexFlow: "row nowrap",
  },
  col: {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: 1,
  },
  fuCol: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "50%",
    maxWidth: "50%",
  },
  textBox: {
    // height: "100px",
    // flexGrow: 1,
  },
  rowColResponsive: {
    display: "flex",
    flexFlow: "column nowrap",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column nowrap",
    },
  },
}));

export const SingleUploader = (props: Props) => {
  const classes = useStyles();

  const [item, setItem] = React.useState<Media | null | "loading">(null);

  React.useEffect(() => {
    return firebase
      .firestore()
      .collection("media")
      .where("tag", "==", props.tag)
      .where("userId", "==", props.clientId)
      .onSnapshot(async (snap) => {
        setItem("loading");

        if (snap.docs.length) {
          setItem(await Media.createFromQuerySnapshot(snap.docs[0]));
        } else {
          setItem(null);
        }
      });
  }, [props.tag + props.clientId]);

  return <>{renderUpload(props.tag, props.clientId)}</>;

  function renderUpload(tag: string, clientId: string): React.ReactNode {
    if (item === "loading") {
      return <LinearProgress variant="indeterminate" />;
    } else if (!item) {
      let accept: string[] | undefined = undefined;

      if (tag === "function-and-flow-raw") {
        accept = [".ai"];
      } else if (tag === "brief-raw") {
        accept = [".docx"];
      }

      return (
        <FileUpload
          fileTag={tag}
          clientUserId={clientId}
          accept={accept}
          onUploadComplete={props.onUploadComplete}
          fileVisibility={{
            isClientVisible: true,
            isDesignerContributed: true,
            isDesignerVisible: true,
          }}
        />
      );
    } else {
      let uploadMessage = "";

      switch (tag) {
        case "brief":
          uploadMessage = "Design Brief";
          break;
        case "brief-raw":
          uploadMessage = "Design Brief Document";
          break;
        case "function-and-flow":
          uploadMessage = "Flow Diagram";
          break;
        case "function-and-flow-raw":
          uploadMessage = "Illustrator File";
          break;
      }
      return (
        <div
          className={classes.col}
          style={{
            padding: "2rem",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Typography align="center" variant="h5">
            {uploadMessage}
          </Typography>
          <Typography align="center" variant="body1">
            "{item.originalFileName}"
          </Typography>
          <div
            className={classes.info}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <YZButton
              variant="contained"
              style={{ margin: "1rem" }}
              onClick={() => {
                downloadAndZip([item as any], tag);
              }}
            >
              Download
            </YZButton>
            <YZButton
              variant="contained"
              style={{ margin: "1rem" }}
              onClick={() => deleteAndResetItem()}
            >
              Replace
            </YZButton>
          </div>
        </div>
      );
    }

    async function deleteAndResetItem() {
      if (item instanceof Media) {
        setItem("loading");
        await item.delete();
      }
    }
  }
};

export default SingleUploader;
