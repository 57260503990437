import { Media } from "@yardzen-inc/models";
import { getMediaByUserIdAndTags } from "../firebaseClient";

/*
conditionally listens for the uploaded media, based on tag and/or variant
*/
export function listenOnMedia(
  userId: string,
  tag: string,
  variant: string | undefined,
  mediaSnapRef: React.MutableRefObject<Media | Media[] | null>,
  setMediaName: React.Dispatch<React.SetStateAction<string | false | null>>
): () => void {
  let query = getMediaByUserIdAndTags(userId, [tag]);

  if (variant) {
    query = query.where("variant", "==", variant);
  }

  return query.onSnapshot(async (snap) => {
    if (snap.empty) {
      mediaSnapRef.current = null;
      return setMediaName(false);
    } else if (snap.docs.length > 1) {
      mediaSnapRef.current = await Promise.all(
        snap.docs.map(Media.createFromQuerySnapshot)
      );
      return setMediaName("Multiple files uploaded");
    } else {
      mediaSnapRef.current = await Media.createFromQuerySnapshot(snap.docs[0]);
      return setMediaName(`${mediaSnapRef.current.originalFileName} uploaded`);
    }
  });
}
