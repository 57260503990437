import React, { FC, useMemo, useState } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import {
  formatNumToUSD,
  PriceOptionSelectItem,
  StyleSelectItem,
} from "@yardzen-inc/react-common";
import {
  Budget_Checklist_Response,
  useBudgetChecklistResponsePriceAndStyleOptionsOnlybyResponseIdQuery,
  useSingleBudgetChecklistItemMinimalQuery,
} from "@yardzen-inc/graphql";
import { useLogError } from "../../util/hooks";
import { CircularProgress, Typography, makeStyles } from "@material-ui/core";
import FullScreenImage from "../FullScreenImage";

export interface BudgetItemSelectionListItemProps extends BoxProps {
  budgetChecklistResponse: Pick<
    Budget_Checklist_Response,
    "context" | "id" | "budget_checklist_item_id"
  >;
  idx: number;
  noIconImage?: boolean;
}

const useStyles = makeStyles({
  centeredItemContainer: {
    display: "grid",
    justifyContent: "center",
    justifyItems: "center",
    rowGap: "10px",
  },
  errorText: {
    color: "red",
  },
});

const BudgetItemSelectionListItem: FC<BudgetItemSelectionListItemProps> = ({
  budgetChecklistResponse,
  idx,
  noIconImage,
  ...containerProps
}) => {
  const { data: checklistItemData, error: checklistItemError } =
    useSingleBudgetChecklistItemMinimalQuery({
      variables: {
        id: budgetChecklistResponse.budget_checklist_item_id,
      },
      fetchPolicy: "cache-and-network",
    });

  const [fullScreenImage, setFullScreenImage] = useState<string | null>(null);

  const { data: priceStyleData, error: priceStyleError } =
    useBudgetChecklistResponsePriceAndStyleOptionsOnlybyResponseIdQuery({
      variables: { responseId: budgetChecklistResponse.id },
      fetchPolicy: "cache-and-network",
    });

  const [priceStyleExtracted, checklistItemExtracted] = useMemo(() => {
    return [
      priceStyleData?.budget_checklist_response?.[0],
      checklistItemData?.budget_checklist_item?.[0],
    ];
  }, [priceStyleData, checklistItemData]);

  const [lowPrice, highPrice, multiplier] = useMemo(() => {
    const { low_price, high_price } =
      priceStyleData?.budget_checklist_response?.[0]
        ?.budget_checklist_price_option ?? {};

    if (!low_price || !high_price) {
      return [void 0, void 0, 0];
    }

    let multiplier = 0;

    void [
      priceStyleExtracted?.front_yard,
      priceStyleExtracted?.back_yard,
      priceStyleExtracted?.left_yard,
      priceStyleExtracted?.right_yard,
    ].forEach((yard) => {
      if (!!yard) {
        multiplier += 1;
      }
    });

    if (!multiplier) {
      multiplier = 1;
    }

    if (checklistItemExtracted?.quantitative_item) {
      return [low_price, high_price, multiplier];
    }

    return [low_price * multiplier, high_price * multiplier, multiplier];
  }, [priceStyleData, priceStyleExtracted, checklistItemExtracted]);

  useLogError(priceStyleError as Error);
  useLogError(checklistItemError as Error);

  const { centeredItemContainer, errorText } = useStyles();

  if (priceStyleError || checklistItemError) {
    return (
      <Box className={centeredItemContainer}>
        <Typography variant="h6" className={errorText}>
          An error occurred while loading this budget element. Please try again
          or contact support.
        </Typography>
      </Box>
    );
  }

  if (!priceStyleExtracted || !checklistItemExtracted) {
    return (
      <Box className={centeredItemContainer}>
        <CircularProgress />
        Loading Element...
      </Box>
    );
  }

  const {
    budget_checklist_price_option: priceOption,
    budget_checklist_style_option: styleOption,
  } = priceStyleExtracted;

  return (
    <>
      <Box {...containerProps} border="solid 1px black">
        {" "}
        <Box display="flex" flexDirection="column" p={2}>
          <Box>
            <Box display="flex" alignItems="center">
              {checklistItemExtracted.medium?.public_uri && (
                <Box p={1}>
                  {" "}
                  <img
                    src={checklistItemExtracted.medium?.public_uri}
                    style={{ height: "30px" }}
                    alt="checklist item"
                  />
                </Box>
              )}
              <Box pr={1}>
                <Typography variant="h5">
                  {(idx + 1).toString() + ". "}
                </Typography>
              </Box>
              <Typography variant="h5">
                {checklistItemExtracted.name}{" "}
                {!!!checklistItemExtracted.quantitative_item &&
                  ` x ${multiplier || 1}`}
              </Typography>
            </Box>
            <Box pl={2}>
              {noIconImage && (
                <Typography variant="h6">
                  {formatNumToUSD(lowPrice)} - {formatNumToUSD(highPrice)}
                </Typography>
              )}
              <Typography>{priceOption.description ?? ""}</Typography>
            </Box>
            <Box pl={2}>
              {multiplier ? (
                <Typography>
                  Place in yard(s): {getFormattedYardList()}
                </Typography>
              ) : (
                <Typography>
                  Client did not specify where to place item(s).
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent={noIconImage ? "center" : void 0}
            flexWrap="wrap"
            maxWidth="100vw"
          >
            {!noIconImage && (
              <Box display="flex" flexDirection="column" p={1}>
                <PriceOptionSelectItem
                  highPrice={highPrice}
                  lowPrice={lowPrice}
                  imageUri={
                    priceOption.image?.image_variants?.[0].medium.public_uri ??
                    ""
                  }
                  itemId="none"
                  selected={false}
                  onSelect={() =>
                    setFullScreenImage(
                      priceOption.image?.image_variants?.[0].medium
                        .public_uri ?? ""
                    )
                  }
                />
              </Box>
            )}
            {styleOption?.image?.image_variants?.[0] && (
              <Box display="flex" flexDirection="column" p={1}>
                <StyleSelectItem
                  imageUri={
                    styleOption?.image?.image_variants?.[0].medium.public_uri ??
                    ""
                  }
                  itemId="none"
                  selected={false}
                  name={styleOption?.budget_checklist_style.name ?? ""}
                  onSelect={() =>
                    setFullScreenImage(
                      styleOption?.image?.image_variants?.[0].medium
                        .public_uri ?? ""
                    )
                  }
                />
              </Box>
            )}
          </Box>
          {budgetChecklistResponse.context && (
            <Box p={2}>
              <Box pb={1}>
                <Typography style={{ fontWeight: "bold" }}>
                  Additional client notes:
                </Typography>
              </Box>
              <Box pb={1}>
                <Typography>{budgetChecklistResponse.context}</Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {fullScreenImage && (
        <FullScreenImage
          bgColor="white"
          open
          media={fullScreenImage}
          onClose={() => setFullScreenImage(null)}
        />
      )}
    </>
  );

  function getFormattedYardList(): string | null {
    if (!priceStyleExtracted) {
      return null;
    }

    const { front_yard, right_yard, left_yard, back_yard } =
      priceStyleExtracted;

    const yards: string[] = [];

    if (front_yard) yards.push("FRONT");
    if (right_yard) yards.push("RIGHT");
    if (back_yard) yards.push("BACK");
    if (left_yard) yards.push("LEFT");

    return yards.join(", ");
  }
};

export { BudgetItemSelectionListItem };
export default BudgetItemSelectionListItem;
