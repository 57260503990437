import firebase from "firebase/compat/app";
import {
  InstallQuestionnaire,
  InstallQuestionnaireProperties,
} from "@yardzen-inc/models/dist/models/InstallQuestionnaire";

/**
 * Retrieves the onboarding questionnaire for a project with the given id.
 * @param projectId The project id for the project to retrieve the questionnaire for.
 * @returns An onboarding questionnaire for the project.
 */
async function getOnboardingQuestionnaireForProject(
  projectId: string
): Promise<InstallQuestionnaire> {
  if (!projectId || typeof projectId !== "string") {
    throw new Error(
      `A string project id was expected when fetching the onboarding questionnaire for the project. Instead received: ${projectId}`
    );
  }

  const questionnaireSnapshot = await firebase
    .firestore()
    .collection("projects")
    .doc(projectId)
    .collection("installQuestionnaires")
    .where("surveyLocation", "==", "clientOnboarding")
    .get();

  if (questionnaireSnapshot.empty) {
    return {} as InstallQuestionnaire;
  }

  const rawQuestionnaireDoc = questionnaireSnapshot.docs[0].data();

  return new InstallQuestionnaire(
    rawQuestionnaireDoc as InstallQuestionnaireProperties
  );
}

export { getOnboardingQuestionnaireForProject };
