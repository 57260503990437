import DesignBriefEntry, {
  DesignBriefEntryProperties,
} from "./DesignBriefEntry";
import { DesignBriefEntryGroupMap, DesignBriefMeta } from "./types";

export type DesignBriefApiEventTypes =
  | "CREATE"
  | "READ"
  | "UPDATE"
  | "DELETE"
  | "DROP";

abstract class DesignBriefApi {
  private eventHandlers: {
    [key: string]: ((entry?: DesignBriefEntry<any>) => void)[];
  } = {
    CREATE: [],
    DELETE: [],
    UPDATE: [],
    DROP: [],
  };

  // this method should also delete the associated metadata
  public abstract dropCollection(): Promise<void>;

  public abstract updateEntry(
    entry: DesignBriefEntry<any> | any // HACK: this should be DesignBriefEntry but all fields optional
  ): Promise<void>;

  public abstract deleteEntry(entry: DesignBriefEntry<any>): Promise<void>;

  public abstract createEntry<t>(
    props: DesignBriefEntryProperties<t>
  ): Promise<DesignBriefEntry<t>>;

  public abstract bulkCreateEntry<t>(
    props: DesignBriefEntryProperties<t>[]
  ): Promise<DesignBriefEntry<t>[]>;

  /*
    null returns from getAllEntries indicate that the collection has
    not yet been created
  */
  public abstract getAllEntries(): Promise<DesignBriefEntryGroupMap | null>;

  public abstract getMetadata(): Promise<DesignBriefMeta | null>;

  public abstract setMetadata(meta: DesignBriefMeta): Promise<void>;

  public abstract updateMetadata(
    key: keyof DesignBriefMeta,
    value: any
  ): Promise<void>;

  public addEventListener(
    eventName: DesignBriefApiEventTypes,
    handler: (entry?: DesignBriefEntry<any>) => void
  ): () => void {
    this.eventHandlers[eventName].push(handler);

    return () => this.removeEventListener(eventName, handler);
  }

  public removeEventListener(
    eventName: DesignBriefApiEventTypes,
    handler: (entry?: DesignBriefEntry<any>) => void
  ): void {
    const handlerIndex = this.eventHandlers[eventName].findIndex(
      (h) => handler === h
    );

    if (handlerIndex < 0) {
      return console.error(`could not remove ${eventName} handler, not found`);
    }

    return void this.eventHandlers[eventName].splice(handlerIndex, 1);
  }

  public removeAllEventListeners() {
    Object.keys(this.eventHandlers).forEach((eventName) => {
      this.eventHandlers[eventName] = [];
    });
  }

  /*
    All subclasses must call this method within the implementation of each
    abstract crud method.
  */
  protected triggerEvent(
    eventName: DesignBriefApiEventTypes,
    entry?: DesignBriefEntry<any>
  ): void {
    return void (async () => {
      for (let handler of this.eventHandlers[eventName]) {
        handler(entry);
      }
    })();
  }
}

export { DesignBriefApi };
export default DesignBriefApi;
