import React, { FC } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Profile } from "@yardzen-inc/models";
import OnboardRouter from "./OnboardRouter";
import OnboardDataProvider from "./OnboardDataProvider";

export interface OnboardPageProps extends RouteComponentProps {
  clientRecord: Profile;
}

const OnboardPage: FC<OnboardPageProps> = ({ clientRecord, ...routeProps }) => {
  return (
    <>
      <OnboardDataProvider clientRecord={clientRecord}>
        <OnboardRouter {...routeProps} />
      </OnboardDataProvider>
    </>
  );
};

export { OnboardPage };
export default OnboardPage;
