import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography, Divider, TextField } from "@material-ui/core";
import { Profile } from "@yardzen-inc/models";

interface Props {
  profile: Profile | null;
  style?: any;
  showContractorNotes?: boolean;
  showSharedViewPII?: boolean | null;
}

function ProfileMetaDisplay({
  profile,
  style,
  showSharedViewPII = false,
}: Props) {
  if (profile === null) return <></>;
  const { street, state, city, zip, firstName, lastName, email } = profile;
  const phone = profile["phone"];
  // @ts-ignore
  const notes = profile["contractorNotes"];
  const classes = useStyles();
  //   const hasFullAddress = state && street && city && zip;

  return (
    <div className={classes.root}>
      <div style={style ? style : {}} className={classes.address}>
        <Typography variant="h6">
          {firstName} {lastName}
        </Typography>
        <Divider />
        {showSharedViewPII && (
          <>
            {renderAddress()}
            <Divider />
            <Typography variant="h6">
              {phone ? phone : "No phone number found"}
            </Typography>
            <Typography style={{ marginTop: "0.5rem" }} variant="h6">
              {email ? email : "No email found"}
            </Typography>
          </>
        )}
      </div>
      <TextField
        value={notes}
        InputProps={{
          readOnly: true,
        }}
        className={classes.notes}
        // fullWidth
        multiline
        variant="outlined"
        label="Provided Notes"
      />
    </div>
  );

  function renderAddress() {
    return (
      <div className={classes.address}>
        <Typography variant="h6">{street}</Typography>
        <Typography variant="h6">
          {city}, {state}
        </Typography>
        <Typography variant="h6">{zip}</Typography>
      </div>
    );
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  address: {
    width: "fit-content",
    marginTop: "1rem",
  },
  notes: {
    margin: "auto",
    marginTop: "1.75rem",
    width: "600px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      margin: "auto",
      marginTop: "1.75rem",
    },
  },
}));
export default ProfileMetaDisplay;
