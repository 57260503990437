import * as React from "react";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { Media } from "@yardzen-inc/models";
import { CloudDownload } from "@material-ui/icons";
import downloadAndZip from "../util/downloadAndZip";
import makeDesignAssignmentSlug from "../ClientDetail/Revisions/util/makeDesignAssignmentSlug";
import {
  HouseModel,
  HouseModelDeliverable,
} from "../ConstantValues/fileuploadConstants";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import { getMediaByUserIdAndTags } from "../util/firebase/firebaseClient";

interface Props {
  userId: string;
  lastName: string;
  street: string;
  version: string;
}

export const DesignerSummaryDownloads = ({
  userId,
  lastName,
  street,
  version,
}: Props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [startingDownload, setStartingDownload] =
    React.useState<boolean>(false);
  const [modelMedia, setModelMedia] = React.useState<Media[] | null>(null);

  React.useEffect(listenForModelMedia, [userId]);

  return (
    <Grid container>
      <Grid item md={3}>
        <YZTypography
          variant="h5"
          type="serif"
          style={{ marginBottom: "1rem" }}
        >
          Downloads
        </YZTypography>
      </Grid>
      <Grid item md={9}>
        <YZButton
          onClick={downloadHouseModel}
          variant="text"
          disabled={loading || !modelMedia}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Box
            flex="1"
            textAlign="left"
            style={{ padding: "0.25rem", paddingBottom: 0 }}
          >
            <YZTypography
              type="serif"
              variant="body1"
              style={{ textTransform: "initial" }}
            >
              {modelMedia ? modelMedia[0].originalFileName : null}
            </YZTypography>
            <YZTypography
              color="textSecondary"
              variant="body2"
              style={{ textTransform: "initial" }}
            >
              {!startingDownload ? "House Model" : "Downloading..."}
            </YZTypography>
          </Box>
          <Box pl={2} pr={1} display="flex">
            {!loading ? (
              <CloudDownload style={{ fontSize: 17 }} />
            ) : (
              <CircularProgress style={{ fontSize: 17 }} />
            )}
          </Box>
        </YZButton>
      </Grid>
    </Grid>
  );

  async function downloadHouseModel(): Promise<void> {
    setStartingDownload(true);

    try {
      if (!modelMedia) {
        throw new Error("No house model to download");
      }

      await downloadAndZip(
        modelMedia,
        `${makeDesignAssignmentSlug(lastName, street, version)}_house-model.zip`
      );
    } catch (err) {
      alert("Could not start download");
      console.error(err);
    } finally {
      setStartingDownload(false);
    }
  }

  function listenForModelMedia(): () => void {
    return getMediaByUserIdAndTags(userId, [
      HouseModel.tag,
      HouseModelDeliverable.tag,
    ]).onSnapshot(async (snap) => {
      setLoading(true);

      try {
        do {
          if (!snap.docs.length) {
            if (modelMedia !== null) {
              setModelMedia(null);
            }

            break;
          }

          const media = await Promise.all(
            snap.docs.map((doc) => Media.createFromQuerySnapshot(doc))
          );

          setModelMedia(media);
        } while (false);
      } catch (err) {
        console.error(err);
        alert("Failed to fetch house model for project");
      } finally {
        setLoading(false);
      }
    });
  }
};

export default DesignerSummaryDownloads;
