import firebase from "firebase/compat/app";
import * as React from "react";
import Moment from "moment";
import {
  Paper,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  Button,
  Dialog,
  DialogTitle,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Email, Drafts } from "@material-ui/icons";
import { GenericOnCallFunctionAlert } from "../util/genericAlert";

export interface Props {
  open: boolean;
  onCloseClick: () => void;
  email: string;
}

const MailchimpActivity = ({ open, onCloseClick, email }: Props) => {
  const classes = useStyles();
  const [mailchimpActivity, setMailchimpActivity] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (open === false) {
      return () => {};
    }

    const fetchActivity = async () => {
      if (!email) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      const activity = await _fetchMailchimpActivity(email);
      if (!activity) {
        setIsLoading(false);
        return;
      }

      setMailchimpActivity(activity);
      setIsLoading(false);
    };

    fetchActivity();

    return () => {};
  }, [open]);

  const ActionIconAndText = (action: string) => {
    switch (action) {
      case "sent":
        return (
          <>
            <ListItemIcon>
              <Email />
            </ListItemIcon>
            <Typography noWrap>
              <span>
                <b>Was sent </b> the&nbsp;
              </span>
            </Typography>
          </>
        );
      case "open":
        return (
          <>
            <ListItemIcon>
              <Drafts />
            </ListItemIcon>

            <Typography noWrap>
              <span>
                <b>Opened</b> the&nbsp;
              </span>
            </Typography>
          </>
        );
      case "click":
        return (
          <>
            <ListItemIcon>
              <Drafts />
            </ListItemIcon>

            <Typography noWrap>
              <span>
                <b>Clicked a link</b> in the&nbsp;
              </span>
            </Typography>
          </>
        );
    }

    return (
      <div style={{ display: "inline" }}>
        <ListItemIcon>
          <Email />
        </ListItemIcon>
        <Typography display="inline">
          <span style={{ fontWeight: "bold" }}>{action}</span>
        </Typography>
      </div>
    );
  };

  return (
    <Dialog
      onClose={() => {
        onCloseClick();
      }}
      open={open}
      fullWidth
      classes={{
        paper: classes.dialogRoot,
      }}
    >
      <Paper style={{ padding: 15 }}>
        {isLoading && (
          <div>
            <CircularProgress />
            <Typography>Loading...</Typography>

            <Button variant="outlined" onClick={() => onCloseClick()}>
              Close
            </Button>
          </div>
        )}

        {!isLoading && !Object.keys(mailchimpActivity).length && (
          <div>
            <Typography>No Activity Found</Typography>
            <Button variant="outlined" onClick={() => onCloseClick()}>
              Close
            </Button>
          </div>
        )}

        {!isLoading && !!Object.keys(mailchimpActivity).length && (
          <div>
            <DialogTitle>Mailchimp Email Activity</DialogTitle>
            <Button variant="outlined" onClick={() => onCloseClick()}>
              Close
            </Button>
            <List>
              {Object.keys(mailchimpActivity).map((dateKey) => {
                return (
                  <div key={dateKey}>
                    <Typography>{dateKey}</Typography>
                    <List>
                      {
                        // @ts-ignore
                        mailchimpActivity[dateKey].map(
                          (item: any, itemIndex: number) => (
                            <ListItem key={itemIndex}>
                              {ActionIconAndText(item["action"])}
                              <Typography component="span">
                                <b>{item["title"]}</b>&nbsp;email&nbsp;@&nbsp;
                                {Moment(item["timestamp"]).format("h:mm a")}
                              </Typography>
                            </ListItem>
                          )
                        )
                      }
                    </List>
                  </div>
                );
              })}
            </List>
          </div>
        )}
      </Paper>
    </Dialog>
  );
};

const _fetchMailchimpActivity = async (email: string) => {
  const mailChimpActivity = firebase
    .functions()
    .httpsCallable("getClientEmailActivity");

  try {
    const data = await mailChimpActivity({ email: email });

    if (!data["data"] || !data["data"]["activity"]) {
      return false;
    }

    let activity = {};

    data["data"]["activity"].forEach((item: any) => {
      const dateFormatted = Moment(item["timestamp"]).format("MMMM Do YYYY");
      // @ts-ignore
      if (!activity[dateFormatted]) {
        // @ts-ignore
        activity[dateFormatted] = [];
      }
      // @ts-ignore
      activity[dateFormatted].push(item);
    });

    return activity;
  } catch (error) {
    // @ts-ignore
    GenericOnCallFunctionAlert("getClientEmailActivity", error.message);
    return false;
  }
};

export default MailchimpActivity;

const useStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    maxWidth: "max-content",
  },
}));
