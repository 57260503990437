import React, { FC, useState } from "react";
import {
  Box,
  IconButton,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { YZButton } from "@yardzen-inc/react-common";
import { Close, OpenInNew } from "@material-ui/icons";
import ManagePLPActionsModal from "./ManagePLPActionsModal";
import PostPLPHoldListItemIcons from "./PostPLPHoldListItemIcons";
import { PostPLPHoldData } from ".";
import ConfirmArchiveModal from "./ConfirmArchiveModal";
import { ProfileProperties } from "@yardzen-inc/models";

export interface PostPLPHoldListItemProps extends RouteComponentProps {
  holdData: PostPLPHoldData;
  triggerRefresh: () => void;
}

enum ActionNameMap {
  CX_OUTREACH = "CX Outreach",
  MODEL_FIX = "Model Fix",
  VIP = "VIP Assignment",
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const PostPLPHoldListItem: FC<PostPLPHoldListItemProps> = ({
  holdData: { profile, projectId, holdStatuses, requiredActions, id },
  triggerRefresh,
}) => {
  const classes = useStyles();

  const [actionsModalOpen, setActionsModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);

  return <ListItem divider>{renderContent()}</ListItem>;

  function renderContent() {
    if (!profile) {
      return <></>;
    }

    return (
      <>
        <ManagePLPActionsModal
          open={actionsModalOpen}
          onClose={() => setActionsModalOpen(false)}
          requiredActions={requiredActions}
          triggerRefresh={triggerRefresh}
          projectId={projectId}
        />

        <ConfirmArchiveModal
          open={archiveModalOpen}
          onClose={() => setArchiveModalOpen(false)}
          holdId={id}
          profile={profile as ProfileProperties}
          holdStatuses={holdStatuses}
          triggerRefresh={triggerRefresh}
        />

        {/* CONTAINER */}
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {/* ICONS AND TEXT */}
          <Box display="flex" alignItems="center">
            <PostPLPHoldListItemIcons holdStatuses={holdStatuses} />

            <Box px={2}>
              <ListItemText
                primary={`${profile.firstName} ${profile.lastName}`}
                secondary={`Materials Submitted: ${getHumanReadableDate()}`}
              />
              {!!requiredActions?.length && (
                <ListItemText secondary={`Awating: ${printActionsAwaited()}`} />
              )}
            </Box>
          </Box>

          {/* BUTTONS */}
          <Box display="flex" pr={1}>
            <YZButton
              className={classes.button}
              onClick={() => setActionsModalOpen(true)}
            >
              Adjust Actions
            </YZButton>

            <YZButton
              className={classes.button}
              onClick={handleClick}
              color="primary"
            >
              Client Detail
              <Box display="flex" alignItems="center" pl={1}>
                <OpenInNew />
              </Box>
            </YZButton>

            <IconButton
              onClick={() => setArchiveModalOpen(true)}
              style={{ padding: "4px" }}
            >
              <Close style={{ color: "red" }} />
            </IconButton>
          </Box>
        </Box>
      </>
    );
  }

  function printActionsAwaited() {
    if (!requiredActions || !requiredActions.length) return null;

    let returnStr = "";
    requiredActions.forEach((action, idx) => {
      returnStr += ActionNameMap[action];
      if (idx + 1 < requiredActions.length) {
        returnStr += ", ";
      }
    });

    return returnStr;
  }

  function getHumanReadableDate() {
    if (profile?.wizardDone) {
      const dateObj = new Date(profile.wizardDone);
      return dateObj.toLocaleDateString();
    }
    return "Date Not found";
  }

  function handleClick() {
    if (!profile) return;

    return window.open(`${window.origin}/client/${profile.id}`, "_blank");
  }
};

export { PostPLPHoldListItem };
export default PostPLPHoldListItem;
