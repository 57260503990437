import React, { FC, useState, useEffect } from "react";
import { Box, Input, Typography } from "@material-ui/core";
import { useSkipMount } from "../../util/hooks";

export interface DesignBriefChecklistItemTextComponentProps {
  value: string;
  update: (value: string) => void;
  pm: boolean;
}

const DesignBriefChecklistItemTextComponent: FC<DesignBriefChecklistItemTextComponentProps> =
  (props) => {
    const [text, setText] = useState<string>(props.value);

    const firstRunComplete = useSkipMount();

    useEffect(handleValueChange, [props.value]);
    useEffect(handleTextChange, [text]);

    if (props.pm) {
      return (
        <Box flexGrow={1} p={1}>
          <Input
            value={text}
            fullWidth
            multiline
            onChange={({ currentTarget: { value } }) => setText(value)}
          ></Input>
        </Box>
      );
    }

    return (
      <Box flexGrow={1} p={1}>
        <Typography>{text}</Typography>
      </Box>
    );

    function handleValueChange(): void {
      if (props.value !== text) {
        setText(props.value);
      }
    }

    function handleTextChange(): (() => void) | void {
      if (!firstRunComplete) {
        return void 0;
      }

      const timeout = setTimeout(async () => {
        await props.update(text);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
  };

export { DesignBriefChecklistItemTextComponent };
export default DesignBriefChecklistItemTextComponent;
