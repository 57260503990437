import DesignBriefEntry from "./DesignBriefEntry";

export enum DesignBriefEntryType {
  TEXT,
  SELECT,
  MULITPLE_CHOICE,
  MULTIPLE_CHECK,
  CUSTOM,
  TAG_CLOUD,
}

export interface DesignBriefEntryGroupMap {
  [groupName: string]: DesignBriefEntry<any>[];
}

export enum EntryMediaType {
  URL,
  MEDIA_ID,
}

export interface EntryImage {
  type: EntryMediaType;
  value: string;
  annotator: boolean;
}

export interface DesignBriefMeta {
  acknowledges: {
    [key: string]: {
      value: boolean;
    };
  };
  additionalNotes: string;
  order: string[] | null;
}
