import {
  Agent,
  DesignAssignment,
  Project,
  Revision,
} from "@yardzen-inc/models";
import { getDesignAssignmentByRevisionId } from "../../../util/firebase/firebaseClient";

export async function getAssignedDesigner(
  revisionId: Revision["id"],
  projectDesignerId?: Project["designerId"]
) {
  let designAssignment: DesignAssignment | undefined;
  try {
    designAssignment = (
      await getDesignAssignmentByRevisionId(revisionId)
    )?.data();
  } catch (e) {
    console.error(
      `Unable to fetch design assignment for revision ${revisionId}: ${e}`
    );
    return;
  }

  const designerId = designAssignment?.assignedTo || projectDesignerId;
  if (!designerId) {
    return;
  }

  let assignedDesigner: Agent | undefined;
  try {
    assignedDesigner = await Agent.fetch(designerId);
  } catch (e) {
    console.error(`Unable to fetch Agent by ID ${designerId}: ${e}`);
    return;
  }
  return assignedDesigner;
}
