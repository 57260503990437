import JSZip from "jszip";
import { Media } from "@yardzen-inc/models";
const FileSaver = require("file-saver");

const fetchFiles = async (media: Array<Media>) => {
  const seenFileNames = {};
  return await Promise.all(
    media.map((record) => {
      return fetch(record["downloadURL"], { method: "GET" }).then(
        (response) => {
          return new Promise((resolve, reject) => {
            response.blob().then((data) => {
              // @ts-ignore
              const wholeFileName = record["updatedFileName"]
                ? // @ts-ignore
                  record["updatedFileName"]
                : record["originalFileName"];
              const fileType = wholeFileName.slice(-4);
              const fileName = wholeFileName.slice(
                0,
                wholeFileName.indexOf(fileType)
              );
              // @ts-ignore
              if (seenFileNames[fileName]) {
                // @ts-ignore
                const newFileName = `${fileName}(${seenFileNames[fileName]})${fileType}`;
                // @ts-ignore
                seenFileNames[fileName]++;
                console.log(newFileName);
                fileName + fileType;
                resolve({ fileName: newFileName, data: data });
                return;
              }
              // @ts-ignore
              seenFileNames[fileName] = 1;
              resolve({ fileName: wholeFileName, data: data });
            });
          });
        }
      );
    })
  );
};

const downloadAndZip = async (media: Media[], zipFileName: string) => {
  const last4Chars = zipFileName.slice(zipFileName.length - 4);
  if (last4Chars !== ".zip") {
    zipFileName = zipFileName + ".zip";
  }

  let zip = new JSZip();
  const files = await fetchFiles(media);
  media.forEach((med) => console.log(med));
  files.forEach((file) => console.log(file));
  files.forEach((file: any) => {
    zip.file(file["fileName"], file["data"]);
  });

  const blob = await zip.generateAsync({ type: "blob" });

  return FileSaver.saveAs(blob, zipFileName);
};

export default downloadAndZip;
