import { Box, Container } from "@material-ui/core";
import React, { FC } from "react";
import { PromotionalCode } from "src/api/pangaeaTypes";
import { PromotionalCodeCard } from "./PromotionalCodeCard";

export interface PromotionalCodeViewerTableProps {
  promotionalCodes: PromotionalCode[];
  disabled: boolean;
  handleToggleActivation: (code: string) => void;
  handleDelete: (code: string) => void;
  handleEdit: (code: string) => void;
  products: { id: string; name: string }[];
}

export const PromotionalCodeViewerTable: FC<PromotionalCodeViewerTableProps> = (
  props
) => {
  return (
    <Box>
      <Container maxWidth="md">
        {props.promotionalCodes.map((promotionalCode) => (
          <Box p={1} key={promotionalCode.code}>
            <PromotionalCodeCard
              handleEdit={() => props.handleEdit(promotionalCode.code)}
              disabled={props.disabled}
              handleDelete={() => props.handleDelete(promotionalCode.code)}
              handleToggleActivation={() =>
                props.handleToggleActivation(promotionalCode.code)
              }
              products={props.products}
              {...promotionalCode}
            />
          </Box>
        ))}
      </Container>
    </Box>
  );
};
