import { Box, Typography, makeStyles, Tooltip } from "@material-ui/core";
import moment from "moment";
import React, { FC } from "react";
import getTimeSinceDate from "../../util/functions/getTimeSinceDate";
import { ModelingAssignmentWithProfile } from "../../util/hooks/useOpenModelingAssignments";
import makeLiisaLink from "../../util/makeLiisaLink";
import { Build, FlashOn } from "@material-ui/icons";
import { getExpeditedAssignmentStartByDate } from "../../util/selfAssign/getExpeditedAssignmentStartByDate";

export interface ModelingAssignmentListItemProps {
  openModelingAssignment: ModelingAssignmentWithProfile;
  position: number;
}

const useStyles = makeStyles({
  expeditedIcon: {
    fontSize: "2rem",
    color: "#f58b2f",
  },
  proIcon: {
    fontSize: "2rem",
    color: "#9234eb",
  },

  expeditedOverdue: {
    color: "red",
  },
  timeInQueue: {
    color: "black",
  },
});

const ModelingAssignmentListItem: FC<ModelingAssignmentListItemProps> = ({
  openModelingAssignment,
  position,
}) => {
  const classes = useStyles();
  const { assignment, profile, project } = openModelingAssignment;
  const createdAtDate = moment(assignment.createdAt).format(
    "MM/DD/YYYY hh:mm:ss"
  );
  const isExpedited = project.isExpedited;
  const isProDesign = project.isProDesign;
  const expeditedStartByDate = getExpeditedAssignmentStartByDate(
    assignment.createdAt as number
  );
  const expeditedOverdue =
    isExpedited && new Date().getTime() > expeditedStartByDate.getTime();

  return (
    <div>
      <Box display="flex" flexDirection="column" my={1} boxShadow={1} p={2}>
        <Typography>
          {isExpedited && (
            <Tooltip title="Client purchased an Expedited package">
              <FlashOn className={classes.expeditedIcon} />
            </Tooltip>
          )}
          {isProDesign && (
            <Tooltip title="Pro design package">
              <Build className={classes.proIcon} />
            </Tooltip>
          )}
        </Typography>

        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          justifyContent="space-between"
          pb={1}
        >
          <Typography variant="h4">
            {profile.firstName} {profile.lastName}
          </Typography>
          <Typography variant="h4">{position}</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          justifyContent="space-between"
          pb={1}
        >
          <Typography variant={"h5"} gutterBottom>
            {profile.street} {profile.city} {profile.state} {profile.zip}
          </Typography>
          <Typography variant={"body1"} gutterBottom>
            Assignment Created: {createdAtDate}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          justifyContent="space-between"
          pb={1}
        >
          <Typography variant="body1">
            <a href={makeLiisaLink(profile.id)}> {makeLiisaLink(profile.id)}</a>
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={
              expeditedOverdue ? classes.expeditedOverdue : classes.timeInQueue
            }
          >
            {expeditedOverdue && "Expedited should be started - "}
            Time in queue: {getTimeSinceDate(createdAtDate)}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export { ModelingAssignmentListItem };
