import firebase from "firebase/compat/app";
import { ModelFiletype } from "../Interfaces";
import { DownloadUtil } from "../util/downloadUtil";

type Action = "read" | "write" | "delete";

const extR = /\..*$/;
const illegalFilenameCharacters = /[^A-Za-z0-9-_]/gi;

const getSignedUrl = async (
  filename: string,
  filetype: string,
  action: Action
): Promise<{ url: string; filename: string }> => {
  const url = `${process.env.REACT_APP_ASSET_LIBRARY_API_URL}/signed-url/${filetype}-file/${filename}?action=${action}`;
  const token = await firebase.auth().currentUser?.getIdToken();

  const headers = new Headers();

  headers.append("Authorization", `Bearer ${token}`);
  let res;

  try {
    res = await fetch(url, {
      method: "GET",
      headers,
    });
  } catch {
    throw new Error("Failed to fetch signed url");
  }

  const json = await res.json();
  return json;
};

const uploadFile = async (
  file: File,
  filetype: ModelFiletype
): Promise<string> => {
  const { url, filename } = await getSignedUrl(file.name, filetype, "write");

  let ext: string = "";
  const extIdx = file.name.search(extR);
  if (extIdx > 0) {
    ext = file.name.substring(extIdx);
  }

  const headers = new Headers();

  headers.append("Content-Type", "application/octet-stream");
  headers.append(
    "Content-Disposition",
    `attachment; filename="${
      file.name.replace(illegalFilenameCharacters, "") +
      Date.now().toString() +
      ext
    }"`
  );

  const res = await fetch(url, {
    method: "PUT",
    body: file,
    headers,
  });

  if (res.status < 200 || res.status >= 300) {
    throw new Error("Upload failed: " + res.status);
  }

  await `${res.status} - ${res.statusText}`;

  return filename;
};

const downloadFile = async (
  filename: string,
  filetype: ModelFiletype,
  downloadableFileName?: string
) => {
  const { url } = await getSignedUrl(filename, filetype, "read");

  if (!url) {
    throw new Error(
      `Failed to retrieve signed url for ${filetype} file '${filename}'`
    );
  }

  await DownloadUtil.downloadFileWithHeaders(
    url,
    new Headers({ "Content-Type": "application/octet-stream" }),
    downloadableFileName
  );
};

export { uploadFile, downloadFile };
