import * as React from "react";
import PaperButton from "./button";
import FileUpload from "../../Components/FileUpload";
import { MediaVisibility } from "@yardzen-inc/models";

interface ComponentProperty {
  userId: any;
  fileTag: any;
  fileVisibility: MediaVisibility;
  accept?: any;
  history: any;
  urlMatch: any;
  onUploadStart?: Function | undefined;
  onUploadProgress?: Function | undefined;
  onUploadComplete?: Function | undefined;
}

export default function DeprecatingMediaUploadComponent(
  prop: ComponentProperty
) {
  function redirectToOnboard() {
    const onboardURL = prop.urlMatch.url.replace("/media", "/onboard");
    prop.history.push(onboardURL);
  }

  const [showDeprecatedComponent, setShowDeprecatedComponent] =
    React.useState<boolean>(false);

  if (!showDeprecatedComponent) {
    return (
      <>
        <PaperButton
          message='Media should be upload to the "OnBoard" section'
          buttonName="Take me to onboard"
          onClickHandler={redirectToOnboard}
        />
        <PaperButton
          message="Alternatively, if this is a legacy account, you can enable the uploader"
          buttonName="I know what I'm doing, enable uploader"
          onClickHandler={() => setShowDeprecatedComponent(true)}
        />
      </>
    );
  }

  return (
    <FileUpload
      clientUserId={prop.userId}
      fileTag={prop.fileTag}
      fileVisibility={prop.fileVisibility}
      accept={prop.accept}
      onUploadStart={prop.onUploadStart ?? (() => null)}
      onUploadProgress={prop.onUploadProgress ?? (() => null)}
      onUploadComplete={prop.onUploadComplete ?? (() => null)}
    />
  );
}
