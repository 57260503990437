import * as React from "react";
import {
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Grid,
} from "@material-ui/core";
import FileUpload from "../Components/FileUpload";
import firebase from "firebase/compat/app";
import { Media } from "@yardzen-inc/models";
import { Delete } from "@material-ui/icons";
import { YZTypography } from "@yardzen-inc/react-common";

interface Props {
  userId: string;
  revisionId: string;
}

export const WorkingFilesUpload = ({ userId, revisionId }: Props) => {
  const [files, setFiles] = React.useState<Media[] | null>(null);

  React.useEffect(subscribeToWorkingFiles, [`${userId}-${revisionId}`]);

  return (
    <Grid container>
      <Grid item md={3}>
        <YZTypography type="serif" variant="h5">
          Working Files
        </YZTypography>
      </Grid>
      <Grid item md={9}>
        {renderUploadedFiles()}
      </Grid>
    </Grid>
  );

  function renderUploadedFiles(): React.ReactNode {
    return (
      <>
        {renderUploadedItems()}
        <Box mt={3}>{renderUpload()}</Box>
      </>
    );
  }

  function renderUploadedItems(): React.ReactNode {
    if (!files) {
      return <CircularProgress />;
    }

    if (!files.length) {
      return "";
    }

    return files.map(makeFileListItem);
  }

  function makeFileListItem(m: Media): React.ReactNode {
    return (
      <Box
        display="flex"
        padding={2}
        style={{
          background: "#fff",
          boxShadow: "0 3px 6px rgba(0, 0, 0, 0.5)",
        }}
      >
        <Box flexGrow={1}>
          <Typography
            variant="body1"
            noWrap
            style={{ textOverflow: "elipsis" }}
          >
            {m.originalFileName}
          </Typography>
        </Box>
        <IconButton size={"small"} onClick={del}>
          <Delete></Delete>
        </IconButton>
      </Box>
    );

    async function del(): Promise<any> {
      return firebase.firestore().collection("media").doc(m.id).delete();
    }
  }

  function renderUpload(): React.ReactNode {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <FileUpload
          clientUserId={userId}
          fileTag={"working-files"}
          revisionId={revisionId}
          fileVisibility={{}}
        />
      </Box>
    );
  }
  function subscribeToWorkingFiles(): () => void {
    return firebase
      .firestore()
      .collection("media")
      .where("revisionId", "==", revisionId)
      .where("userId", "==", userId)
      .where("tag", "==", "working-files")
      .onSnapshot(async (snap) => {
        if (snap.empty) {
          setFiles([]);
        }

        setFiles(
          await Promise.all(snap.docs.map(Media.createFromQuerySnapshot))
        );
      });
  }
};

export default WorkingFilesUpload;
