import React from "react";
import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import {
  CADOrRenderOption,
  CorrectionType,
  ProfileProperties,
  Project,
} from "@yardzen-inc/models";
import { YZButton } from "@yardzen-inc/react-common";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { DesignAssignmentWizardState } from "./DesignAssignmentCreationWizard";
import { ClientPaidRevisionsMessage } from "./ClientPaidRevisionsMessage";

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: theme.spacing(2),
  },
  button: {
    margin: "1rem",
    width: "30%",
  },
  toggledButton: {
    margin: "1rem",
    width: "30%",
    background: `${theme.palette.primary.main} !important`,
    color: "#fff",
  },
}));

interface Props {
  wizardState: DesignAssignmentWizardState;
  setWizardState: React.Dispatch<
    React.SetStateAction<DesignAssignmentWizardState>
  >;
  handleNextButtonOnClick: () => void;
  clientRecord: ProfileProperties;
  correctionFor: CorrectionType | null | undefined;
  project: Project;
}

const SelectPaidOrUnpaidOptionsStep: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    wizardState,
    setWizardState,
    handleNextButtonOnClick,
    clientRecord,
    correctionFor,
    project,
  } = props;

  const correctionOrRevisionText = correctionFor ? "correction" : "revision";

  return (
    <>
      <Typography variant="h5" gutterBottom align="center">
        Should the designer be paid for this {correctionOrRevisionText}?
      </Typography>
      <Typography variant="body1" align="center">
        If the designer is at-fault, select “unpaid”. If the designer should be
        paid for this {correctionOrRevisionText}, select “paid”.
      </Typography>
      <Box>
        <ClientPaidRevisionsMessage
          clientRecord={clientRecord}
          project={project}
        />
        <Box pt={4}>
          <div className={classes.buttonContainer}>
            <YZButton
              className={
                wizardState.isPaidCorrection
                  ? classes.toggledButton
                  : classes.button
              }
              onClick={() =>
                setWizardState({ ...wizardState, isPaidCorrection: true })
              }
            >
              Paid
            </YZButton>
            <YZButton
              onClick={() =>
                setWizardState({
                  ...wizardState,
                  isPaidCorrection: false,
                  cadOrRenderOption: undefined,
                })
              }
              className={
                wizardState.isPaidCorrection == false
                  ? classes.toggledButton
                  : classes.button
              }
            >
              Unpaid
            </YZButton>
          </div>
        </Box>
        <Box pt={4}>
          {wizardState.isPaidCorrection && (
            <>
              <Typography variant="h5" gutterBottom align="center">
                What is the designer fixing?
              </Typography>
              <div className={classes.buttonContainer}>
                <YZButton
                  onClick={() =>
                    setWizardState({
                      ...wizardState,
                      cadOrRenderOption: CADOrRenderOption.CADAndRender,
                    })
                  }
                  className={
                    wizardState.cadOrRenderOption ===
                    CADOrRenderOption.CADAndRender
                      ? classes.toggledButton
                      : classes.button
                  }
                >
                  {"CAD + Render"}
                </YZButton>
                <YZButton
                  onClick={() =>
                    setWizardState({
                      ...wizardState,
                      cadOrRenderOption: CADOrRenderOption.CADOnly,
                    })
                  }
                  className={
                    wizardState.cadOrRenderOption === CADOrRenderOption.CADOnly
                      ? classes.toggledButton
                      : classes.button
                  }
                >
                  {"CAD Only"}
                </YZButton>
                <YZButton
                  onClick={() =>
                    setWizardState({
                      ...wizardState,
                      cadOrRenderOption: CADOrRenderOption.RenderOnly,
                    })
                  }
                  className={
                    wizardState.cadOrRenderOption ===
                    CADOrRenderOption.RenderOnly
                      ? classes.toggledButton
                      : classes.button
                  }
                >
                  {"Render Only"}
                </YZButton>
              </div>
            </>
          )}
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <YZButton
            disabled={
              wizardState.isPaidCorrection === undefined ||
              (wizardState.isPaidCorrection && !wizardState.cadOrRenderOption)
            }
            variant="contained"
            style={{ height: "fit-content" }}
            color="primary"
            onClick={handleNextButtonOnClick}
          >
            Next
            <ArrowRightAltIcon />
          </YZButton>
        </Box>
      </Box>
    </>
  );
};

export { SelectPaidOrUnpaidOptionsStep };
