import * as React from "react";
import {
  Dialog,
  DialogContent,
  Button,
  TextField,
  Box,
  IconButton,
  Typography,
  LinearProgress,
  Avatar,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import * as uuid from "uuid";
import firebase from "firebase/compat/app";
import {
  Media,
  MediaProperties,
  ProjectManagerProperties,
} from "@yardzen-inc/models";
import environmentConstants from "../../ConstantValues/environmentConstants";

export interface AddPmModalProps {
  open?: boolean;
  onClose: () => void;
  onSubmit: (data: ProjectManagerProperties) => Promise<any>;
  userId: string;
}

export const AddPmModal = (props: AddPmModalProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [createFailure, setCreateFailure] = React.useState<boolean>(false);
  const [thisIsMe, setThisIsMe] = React.useState<boolean>(false);
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [avatarImage, setAvatarImage] = React.useState<File | null>(null);
  const [imageUrl, setImageUrl] = React.useState<string | null>(null);

  React.useEffect(onAvatarImageChange, [avatarImage]);
  React.useEffect(openCloseCleanup, [props.open]);

  return (
    <Dialog open={!!props.open} onClose={props.onClose}>
      <Box p={1} style={{ display: "flex", flexFlow: "row nowrap" }}>
        <Box p={1} display="flex" flexDirection="row" flexWrap="nowrap">
          <Typography variant="h4">Add Project Manager</Typography>
          <Box pl={1}>
            <IconButton onClick={props.onClose} size="small">
              <Close></Close>
            </IconButton>
          </Box>
        </Box>
      </Box>
      <DialogContent>
        {renderError()}
        {renderForm()}
      </DialogContent>
      {renderLoading()}
    </Dialog>
  );

  function renderLoading(): React.ReactNode | null {
    if (loading) {
      return <LinearProgress variant="indeterminate" />;
    }

    return null;
  }

  function renderForm(): React.ReactNode {
    return (
      <Box display="flex" flexDirection="column" p={1}>
        <Box m={1}>
          <TextField
            label="First Name"
            color="primary"
            required
            fullWidth
            value={firstName}
            onChange={({ currentTarget: { value } }) => setFirstName(value)}
          />
        </Box>
        <Box m={1}>
          <TextField
            label="Last Name"
            color="primary"
            required
            fullWidth
            value={lastName}
            onChange={({ currentTarget: { value } }) => setLastName(value)}
          />
        </Box>
        <Box m={1}>
          <TextField
            label="Email"
            color="primary"
            required
            fullWidth
            value={email}
            onChange={({ currentTarget: { value } }) => setEmail(value)}
          />
        </Box>
        {renderAvatar()}
        <Box m={1}>{renderUploader()}</Box>
        <Box m={1}>
          <FormControlLabel
            label="This is me"
            control={
              <Checkbox
                checked={thisIsMe}
                onChange={() => setThisIsMe(!thisIsMe)}
              />
            }
          />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button variant="contained" onClick={_onSubmit} disabled={loading}>
            Add
          </Button>
        </Box>
      </Box>
    );
  }

  function renderAvatar(): React.ReactNode | null {
    if (!imageUrl) {
      return null;
    }

    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        m={1}
        p={1}
        bgcolor="#e5e5e5"
      >
        <Avatar src={imageUrl} style={{ height: "150px", width: "150px" }} />
      </Box>
    );
  }

  function renderUploader(): React.ReactNode {
    return (
      <>
        <input
          type="file"
          onChange={(e) => {
            const file = e.currentTarget?.files?.item(0);
            if (!file) {
              return console.error("no file");
            }

            return setAvatarImage(file);
          }}
        />
      </>
    );
  }

  function onAvatarImageChange(): () => void {
    if (!avatarImage) {
      return () => null;
    }

    const url = URL.createObjectURL(avatarImage);
    setImageUrl(url);

    return () => {
      URL.revokeObjectURL(url);
    };
  }

  async function _onSubmit(): Promise<void> {
    setLoading(true);

    const imageMedia = await storeMedia().catch(() => false);

    if (!(imageMedia instanceof Media)) {
      setLoading(false);
      setCreateFailure(true);
      throw imageMedia;
    }

    const res = await props.onSubmit({
      firstName,
      lastName,
      email,
      bio: "",
      pictureMediaId: imageMedia.id,
      userId: thisIsMe ? props.userId : null,
    });

    if (res instanceof Error) {
      if (process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT) {
        throw res;
      }

      setLoading(false);
      setCreateFailure(true);
    }

    props.onClose();
  }

  function renderError(): React.ReactNode | null {
    if (createFailure && avatarImage === null) {
      return (
        <Typography variant="body1" style={{ color: "red" }}>
          An avatar image is required!
        </Typography>
      );
    }

    if (createFailure) {
      return (
        <Typography variant="body1" style={{ color: "red" }}>
          Something went wrong :( try again later.
        </Typography>
      );
    }

    return null;
  }

  function storeMedia(): Promise<Media | Error> {
    return new Promise(async (resolve, reject) => {
      if (!avatarImage) {
        return reject(
          new Error(
            "store media should not be called if there is not current file upload"
          )
        );
      }

      const storagePath = `media/${props.userId}.${uuid.v4()}.${moment()
        .utc()
        .valueOf()}${avatarImage.type}`;

      const uploadTask = await firebase
        .storage()
        .ref(storagePath)
        .put(avatarImage);

      if (process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT) {
        console.info(uploadTask);
      }

      const meta: MediaProperties = {
        userId: props.userId,
        fileType: avatarImage.type,
        uploadedAt: moment().toISOString(),
        path: storagePath,
        tag: "pm-avatar",
        visibility: {},
      };

      let res: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;

      try {
        res = await firebase.firestore().collection("media").add(meta);
      } catch (e) {
        window.newrelic.noticeError(e);
      }

      try {
        const media = await Media.fetchSingle(res!.id);

        if (!media) {
          return reject(
            new Error("could not fetch newly created media object")
          );
        }

        return resolve(media);
      } catch (err) {
        return reject(err);
      }
    });
  }

  function openCloseCleanup() {
    if (loading) {
      setLoading(false);
    }

    if (createFailure) {
      setCreateFailure(false);
    }

    if (firstName) {
      setFirstName("");
    }

    if (lastName) {
      setLastName("");
    }

    if (email) {
      setEmail("");
    }

    if (imageUrl) {
      setImageUrl(null);
    }

    if (thisIsMe) {
      setThisIsMe(false);
    }
  }
};

export default AddPmModal;
