import React, { FC, useState, useEffect } from "react";
import { Profile, Project, OnboardStateProperties } from "@yardzen-inc/models";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
export interface OnboardDataProviderProps {
  clientRecord: Profile;
}

// TODO: fill out def for OnboardData and put somewhere elese.
export interface OnboardData extends OnboardStateProperties {
  id: string;
}

const ProjectCtx = React.createContext<Project>({} as any);
const OnboardDataCtx = React.createContext<OnboardData>({} as any);

const OnboardDataProvider: FC<OnboardDataProviderProps> = (props) => {
  const [project, setProject] = useState<Project | null | false>(null);
  const [onboardData, setOnboardData] = useState<OnboardData | null | false>(
    null
  );

  console.log("onboards", onboardData);
  console.log("project", project);

  useEffect(listenForProjectData, [props.clientRecord.id]);
  useEffect(listenForOnboardData, [project && project.id]);

  if (project && onboardData) {
    console.log("onboard", onboardData);
    return (
      <ProjectCtx.Provider value={project}>
        <OnboardDataCtx.Provider value={onboardData}>
          {props.children}
        </OnboardDataCtx.Provider>
      </ProjectCtx.Provider>
    );
  }

  if (project === false || onboardData === false) {
    return (
      <Box p={1} width="100%">
        <Typography variant="h3" align="center">
          Could not find project or onboarding data for client
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      p={2}
      display="flex"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );

  function listenForOnboardData(): (() => void) | void {
    if (!project) {
      return void 0;
    }

    return firebase
      .firestore()
      .collection("onboardStates")
      .where("projectId", "==", project.id)
      .onSnapshot((snap) => {
        if (!snap.docs.length) {
          return setOnboardData(false);
        }

        setOnboardData({
          ...(snap.docs[0].data() as any),
          id: snap.docs[0].id,
        });
      });
  }

  function listenForProjectData(): (() => void) | void {
    if (!props.clientRecord.id) {
      throw new Error(
        "clientRecord Id passed to OnboardDataProvider was falsey"
      );
    }

    return firebase
      .firestore()
      .collection("projects")
      .where("profileId", "==", props.clientRecord.id)
      .onSnapshot((snap) => {
        if (!snap.docs.length) {
          return setProject(false);
        }

        setProject(Project.hydrate(snap.docs)[0]);
      });
  }
};

export { OnboardDataProvider, ProjectCtx as OnboardProjectCtx, OnboardDataCtx };
export default OnboardDataProvider;
