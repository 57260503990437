import * as React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

interface SnackbarErrorProps {
  errorText: string | null;
  handleSnackbarClose: () => void;
  severity?: "error" | "warning" | "info" | "success";
}

const SnackbarError: React.FC<SnackbarErrorProps> = ({
  errorText,
  handleSnackbarClose,
  severity,
}) => {
  return (
    <Snackbar
      open={!!errorText}
      onClose={handleSnackbarClose}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Alert severity={severity || "error"} onClose={handleSnackbarClose}>
        {errorText}
      </Alert>
    </Snackbar>
  );
};

export { SnackbarError };
export default SnackbarError;
