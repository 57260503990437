import * as React from "react";
import { Box } from "@material-ui/core";
import DesignPhase from "./DesignPhase";
import { Revision } from "@yardzen-inc/models";

interface Props {
  phase: DesignPhase | null;
  revision: Revision | null;
}

export const Controls = ({ phase, revision }: Props) => {
  if (!revision || !phase) {
    return null;
  }

  return <Box></Box>;
};

export default Controls;
