import * as React from "react";
import {
  Dialog,
  makeStyles,
  Typography,
  Divider,
  TextField,
  DialogContent,
  LinearProgress,
  IconButton,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { Agent, AgentType, Profile } from "@yardzen-inc/models";
import { Close } from "@material-ui/icons";
import useAgentGroups from "./useAgentGroups";
import { YZButton } from "@yardzen-inc/react-common";
import { getAllDesignerPodsData } from "./AgentPods/apiCalls";
import { AgentPods } from "./AgentPods/types";
import classnames from "classnames";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    padding: "1rem",
  },
  formFieldContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  selectError: {
    borderBottom: "2px solid red",
  },
});

interface Props {
  open?: boolean;
  profile?: Profile;
  done?: boolean;

  creating: boolean;
  onClick: (
    type: AgentType,
    rate: number,
    groupId: string | null,
    designerPodId: string | undefined,
    paymentEmail: string | null
  ) => any;
  onClose: () => void;
}

const payReg = /^\d+.\d{2}$/;

export default (props: Props) => {
  const groups = useAgentGroups();
  const classes = useStyles();
  const [payRate, setPayRate] = React.useState<string>("");
  const [payError, setPayError] = React.useState<boolean>(false);
  const [agentGroupId, setAgentGroupId] = React.useState<string | null>(
    groups?.[0]?.id ?? null
  );
  const [type, setType] = React.useState<AgentType>("modeling");
  const [podOptions, setPodOptions] = React.useState<AgentPods[]>();
  const [selectedPod, setSelectedPod] = React.useState<string>("_none");
  const [podError, setPodError] = React.useState<boolean>(false);
  const paymentEmail = props.profile?.designerPaymentEmail || null;

  const submit = () => {
    setPodError(false);
    const valid = payReg.test(payRate);

    if (!valid) {
      setPayError(true);
      return;
    } else {
      if (payError) {
        setPayError(false);
      }
    }

    if (type === "design" && !selectedPod) {
      setPodError(true);
    } else {
      const rate = parseInt(payRate.split(".").join(""));
      props.onClick(
        type,
        rate,
        agentGroupId,
        selectedPod === "_none" ? undefined : selectedPod,
        paymentEmail
      );
    }
  };

  React.useEffect(() => {
    onGroupsChange();

    void (async function () {
      try {
        const response = await getAllDesignerPodsData(true);

        setPodOptions(response);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [groups]);

  return (
    <Dialog open={!!(props.open && Agent)} onClose={props.onClose}>
      {props.creating && <LinearProgress variant="indeterminate" />}

      <IconButton
        style={{ position: "absolute", top: 10, right: 10 }}
        onClick={props.onClose}
      >
        <Close></Close>
      </IconButton>
      <div className={classes.root}>
        {(!props.done && (
          <>
            <DialogContent>
              <Typography variant="h3">Assign Role</Typography>
              <Divider></Divider>
              <br />
              <Typography variant="body1">
                Once assigned, the owner of the account will be able to log in
                to liisa and self assign open jobs from their dashboard.
              </Typography>
              <br />
              <Typography variant="body1">
                Name: {props.profile && props.profile.firstName}{" "}
                {props.profile && props.profile.lastName}
              </Typography>
              <Typography variant="body1">
                Email: {props.profile && props.profile.email}
              </Typography>
              <Typography variant="body1">
                Payment Email:{" "}
                {props.profile && props.profile.designerPaymentEmail}
              </Typography>
              <TextField
                className={classes.formFieldContainer}
                fullWidth
                label="pay rate"
                value={payRate}
                helperText={"like this: 55.00"}
                error={payError}
                onChange={({ target: { value } }) => {
                  setPayRate(value);
                }}
                inputProps={{ "aria-label": "pay rate" }}
              />
              <br />
              {renderGroupSelect()}
              {renderTypeSelect()}
              {type === "design" && renderPodSelect()}
              <div
                style={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  justifyContent: "space-evenly",
                  marginTop: "1rem",
                }}
              >
                <YZButton
                  disabled={props.creating}
                  onClick={() => submit()}
                  variant="outlined"
                  fullWidth
                >
                  Submit
                </YZButton>
              </div>
            </DialogContent>
          </>
        )) || (
          <>
            <DialogContent>
              <Typography>Role Assigned!</Typography>
              <YZButton variant="outlined" onClick={() => props.onClose()}>
                Close
              </YZButton>
            </DialogContent>
          </>
        )}
      </div>
    </Dialog>
  );

  function onGroupsChange(): void {
    if (!groups) {
      return;
    }

    if (!agentGroupId || !groups.find((group) => group.id === agentGroupId)) {
      setAgentGroupId(groups?.[0]?.id ?? null);
    }
  }

  function renderGroupSelect(): React.ReactNode {
    if (groups && !groups.length) {
      return (
        <Box>
          <Typography style={{ color: "red" }}>
            No agent groups found...
          </Typography>
        </Box>
      );
    }

    return (
      <Box>
        <FormControl fullWidth className={classes.formFieldContainer}>
          <InputLabel htmlFor="pageLength">Select Group</InputLabel>
          <Select
            fullWidth
            disabled={groups === null}
            value={agentGroupId ?? ""}
            onChange={({ target: { value } }) =>
              setAgentGroupId((value as string) || null)
            }
            className={classes.formFieldContainer}
          >
            {groups &&
              groups.map((group) => {
                return (
                  <MenuItem value={group.id} key={group.id}>
                    {group.identifier}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Box>
    );
  }

  function renderTypeSelect(): React.ReactNode {
    return (
      <Box>
        <FormControl fullWidth className={classes.formFieldContainer}>
          <InputLabel htmlFor="pageLength">Select Agent Type</InputLabel>
          <Select
            value={type}
            onChange={({ target: { value } }) => setType(value as AgentType)}
            fullWidth
            className={classes.formFieldContainer}
          >
            <MenuItem value={"modeling"}>Modeler</MenuItem>
            <MenuItem value={"wesley"}>Wesley</MenuItem>
            <MenuItem value={"design"}>Designer</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  }

  function renderPodSelect(): React.ReactNode {
    return (
      <Box>
        <FormControl fullWidth className={classes.formFieldContainer}>
          <InputLabel htmlFor="pageLength">Select Team</InputLabel>
          <Select
            value={selectedPod}
            onChange={({ target: { value } }) =>
              setSelectedPod(value as string)
            }
            classes={{ root: classnames(podError && classes.selectError) }}
          >
            {podOptions &&
              podOptions
                .filter((pod) => pod.id)
                .map((pod) => {
                  return (
                    <MenuItem
                      value={pod.id ?? ""}
                      key={`pod-select-mi-${pod.id}`}
                    >
                      {pod.name}
                    </MenuItem>
                  );
                })}
            <MenuItem value={"_none"}>No team assignment</MenuItem>
          </Select>
          {podError && (
            <FormHelperText error>Please select a team</FormHelperText>
          )}
        </FormControl>
      </Box>
    );
  }
};
