import * as React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  ApolloQueryResult,
  DELETE_PRODUCT_BASE_COLOR_LINKS,
  DELETE_PRODUCT_BASE_MATERIAL_LINKS,
  DELETE_PRODUCT_BASE_STYLE_LINKS,
  DELETE_PRODUCT_BASE_TYPE_LINKS,
  INSERT_PRODUCT_BASE_COLOR_LINKS,
  INSERT_PRODUCT_BASE_MATERIAL_LINKS,
  INSERT_PRODUCT_BASE_STYLE_LINKS,
  INSERT_PRODUCT_BASE_TYPE_LINKS,
  PRODUCT_COLLECTION_NAME_SELECT,
  PRODUCT_COLOR_SELECT,
  PRODUCT_MATERIAL_SELECT,
  PRODUCT_PRICE_TIER_SELECT,
  PRODUCT_SETTING_SELECT,
  PRODUCT_STYLE_SELECT,
  PRODUCT_TYPE_SELECT,
  PRODUCT_VENDOR_SELECT,
} from "@yardzen-inc/graphql";
import { useMutation } from "@apollo/client";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import InStockSelect from "./InStockSelect";
import { Formik } from "formik";
import {
  AssetLibraryRowType,
  IFormikElementErrorProps,
  IFormikElementProps,
  IProductBaseProductColorLink,
  IProductBaseProductMaterialLink,
  IProductBaseProductStyleLink,
  IProductBaseProductTypeLink,
  IProductBaseRegionLink,
} from "../../../Interfaces";
import useUnload from "../../../util/hooks/useUnload";
import SaveInProgressTextConstants from "../../../ConstantValues/SaveInProgressTextConstants";
import AssetFeatureSelect from "../Shared/AssetFeatureSelect";
import VisibilitySelect from "../Shared/VisibilitySelect";
import getAssetLibraryRow from "../getRowUtil";
import AssetLibraryImageUpload from "../Shared/AssetLibraryImageUpload";
import { ModelFileUploadForm } from "../ModelFileUploadForm";
import {
  PRODUCT_REGION_SELECT,
  PRODUCT_RETAILER_SELECT,
  UPDATE_PRODUCT_BASE,
} from "../Shared/graphqlQueries";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  itemBtns: {
    display: "flex",
    width: "100%",
  },
  iconUri: {
    maxWidth: "7rem",
  },
  editItems: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },
  editItem: {
    flex: 1,
    marginBottom: theme.spacing(1),
  },
  longLink: {
    width: "10rem",
    maxWidth: "10rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  saveProgress: {
    display: "flex",
    alignItems: "center",
  },
  "3DFileContainer": {
    display: "flex",
    flexDirection: "row",
  },
  uploadContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    marginTop: "1rem",
  },
}));

interface Props {
  isEven: boolean;
  refetch:
    | ((
        variables?: Partial<Record<string, any>> | undefined
      ) => Promise<ApolloQueryResult<any>>)
    | undefined;
  row: AssetLibraryRowType[][];
}

const SaveProgressPoint = 100 / 10;

function ElementTableRow(props: Props) {
  const [isArchiving, setIsArchiving] = React.useState(false);
  const [saveProgress, setSaveProgress] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [cachedStyles, setCachedStyles] = React.useState<string[]>([]);
  const [cachedColors, setCachedColors] = React.useState<string[]>([]);
  const [cachedMaterials, setCachedMaterials] = React.useState<string[]>([]);
  const [cachedTypes, setCachedTypes] = React.useState<string[]>([]);

  const row = getAssetLibraryRow(props.row);

  const [deleteStyleLinks] = useMutation(DELETE_PRODUCT_BASE_STYLE_LINKS, {
    onCompleted: () => {
      setSaveProgress(() => saveProgress + SaveProgressPoint); // 1
      insertStyleLinks({
        variables: {
          objects: cachedStyles.map((style) => {
            return {
              id_product_base: row.id,
              id_product_style: style,
            };
          }),
        },
      });
    },
  });

  const [deleteTypeLinks] = useMutation(DELETE_PRODUCT_BASE_TYPE_LINKS, {
    onCompleted: () => {
      setSaveProgress(() => saveProgress + SaveProgressPoint); // 2
      insertTypeLinks({
        variables: {
          objects: cachedTypes.map((type) => {
            return {
              id_product_base: row.id,
              id_product_type: type,
            };
          }),
        },
      });
    },
  });

  const [deleteColorLinks] = useMutation(DELETE_PRODUCT_BASE_COLOR_LINKS, {
    onCompleted: () => {
      setSaveProgress(() => saveProgress + SaveProgressPoint); // 3
      insertColorLinks({
        variables: {
          objects: cachedColors.map((color) => {
            return {
              id_product_base: row.id,
              id_product_color: color,
            };
          }),
        },
      });
    },
  });

  const [deleteMaterialLinks] = useMutation(
    DELETE_PRODUCT_BASE_MATERIAL_LINKS,
    {
      onCompleted: () => {
        setSaveProgress(() => saveProgress + SaveProgressPoint); // 4
        insertMaterialLinks({
          variables: {
            objects: cachedMaterials.map((material) => {
              return {
                id_product_base: row.id,
                id_product_material: material,
              };
            }),
          },
        });
      },
    }
  );

  const [insertStyleLinks] = useMutation(INSERT_PRODUCT_BASE_STYLE_LINKS, {
    onCompleted: () => {
      setSaveProgress(() => saveProgress + SaveProgressPoint); // 5
    },
  });

  const [insertTypeLinks] = useMutation(INSERT_PRODUCT_BASE_TYPE_LINKS, {
    onCompleted: () => {
      setSaveProgress(() => saveProgress + SaveProgressPoint); // 6
    },
  });

  const [imagePath, setImagePath] = React.useState(row.icon_uri);

  React.useEffect(() => {
    if (saveProgress < 100) {
      return;
    }
    setOpen(false);
    if (!props.refetch) {
      return;
    }
    props.refetch();
  }, [saveProgress]);

  const [insertMaterialLinks] = useMutation(
    INSERT_PRODUCT_BASE_MATERIAL_LINKS,
    {
      onCompleted: () => {
        setSaveProgress(() => saveProgress + SaveProgressPoint); // 7
      },
    }
  );

  const [insertColorLinks] = useMutation(INSERT_PRODUCT_BASE_COLOR_LINKS, {
    onCompleted: () => {
      setSaveProgress(() => saveProgress + SaveProgressPoint); // 8
    },
  });

  const [updateRow] = useMutation(UPDATE_PRODUCT_BASE, {
    onCompleted: () => {
      setSaveProgress(() => saveProgress + SaveProgressPoint); // 9
      const variables = {
        id: row.id,
      };
      deleteStyleLinks({
        variables,
      });
      deleteMaterialLinks({
        variables,
      });
      deleteColorLinks({
        variables,
      });
      deleteTypeLinks({
        variables,
      });
    },
  });

  const [archiveRow] = useMutation(UPDATE_PRODUCT_BASE, {
    onCompleted: () => {
      setOpen(false);
      if (!props.refetch) {
        return null;
      }
      props.refetch();
      return;
    },
  });

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue =
      saveProgress > 0
        ? SaveInProgressTextConstants.SAVE_IN_PROGRESS_WARNING
        : "";
  });

  const classes = useRowStyles();

  const onUpdateRow = ({
    name,
    inStock,
    iconUri,
    unitCost,
    laborCost,
    msrp,
    vendor,
    retailer,
    type,
    setting,
    colors,
    materials,
    collection_name,
    price_tier,
    styles,
    link,
    lumion_file_name,
    affiliate_link,
    sketchup_file_link,
    sketchup_proxy_file_link,
    lumion_file_link,
    vray_file_link,
    max_file_link,
    visibility,
  }: IFormikElementProps) => {
    setCachedColors(colors);
    setCachedStyles(styles);
    setCachedMaterials(materials);
    setCachedTypes(type);
    setSaveProgress(() => saveProgress + SaveProgressPoint); // 10
    const variables = {
      id: row.id,
      name,
      icon_uri: imagePath,
      unit_cost: Math.round(parseFloat(unitCost) * 100),
      labor_cost: laborCost ? Math.round(parseFloat(laborCost) * 100) : 0,
      msrp: msrp ? Math.round(parseFloat(msrp) * 100) : 0,
      archived: row.archived,
      product_setting: setting,
      in_stock: inStock,
      link,
      sketchup_file_link,
      proxy_file_link: sketchup_proxy_file_link,
      lumion_file_name,
      lumion_file_link,
      max_file_link,
      vray_file_link,
      product_collection_name: collection_name,
      product_price_tier: price_tier,
      product_vendor: vendor,
      product_retailer: retailer,
      affiliate_link,
      visibility,
    };
    updateRow({
      variables,
    });
  };

  const onImageUploaded = (path: string): void => {
    setImagePath(path);
  };

  const onArchiveToggle = () => {
    setIsArchiving(true);
    archiveRow({
      variables: {
        id: row.id,
        name: row.name,
        icon_uri: row.icon_uri,
        unit_cost: Math.round(parseFloat(row.unit_cost)),
        labor_cost: row.labor_cost ? Math.round(parseFloat(row.labor_cost)) : 0,
        archived: !row.archived,
        product_setting: row.settingByProductSetting.id,
        in_stock: row.in_stock,
        link: row.link,
        visibility: row.visibility || "ALL",
        lumion_file_name: row.lumion_file_name,
        sketchup_file_link: row.sketchup_file_link,
        proxy_file_link: row.proxy_file_link,
        vray_file_link: row.vray_file_link,
        lumion_file_link: row.lumion_file_link,
        fbx_file_link: row.fbx_file_link,
        max_file_link: row.max_file_link,
        product_vendor: row.vendorByProductVendor.id,
        product_retailer: row.retailerByProductRetailer.id,
      },
    });
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        style={{ background: props.isEven ? "#eeeeee" : "" }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {props.row.map((_row) => _row?.[0])}
      </TableRow>
      <TableRow style={{ background: props.isEven ? "#eeeeee" : "" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Formik
              initialValues={{
                name: row.name,
                iconUri: row.icon_uri,
                unitCost: (parseFloat(row.unit_cost) / 100).toFixed(2),
                laborCost: row.labor_cost
                  ? (parseFloat(row.labor_cost) / 100).toFixed(2)
                  : "0.00",
                msrp: row.msrp
                  ? (parseFloat(row.msrp) / 100).toFixed(2)
                  : "0.00",
                link: row.link,
                affiliate_link: row.affiliate_link,
                sketchup_file_link: row.sketchup_file_link,
                sketchup_proxy_file_link: row.proxy_file_link || "",
                visibility: row.visibility || "ALL",
                lumion_file_name: row.lumion_file_name,
                vray_file_link: row.vray_file_link,
                lumion_file_link: row.lumion_file_link,
                max_file_link: row.max_file_link,
                fbx_file_link: row.fbx_file_link,
                inStock: row.in_stock,
                type: row.product_base_product_type_links?.map(
                  (link: IProductBaseProductTypeLink) => link.product_type.id
                ),
                price_tier: row.priceTierByProductPriceTier?.id,
                collection_name: row.collectionNameByProductCollectionName?.id,
                vendor: row.product_vendor,
                retailer: row.product_retailer,
                setting: row.product_setting,
                materials: row.product_base_product_material_links?.map(
                  (link: IProductBaseProductMaterialLink) =>
                    link.product_material.id
                ),
                colors: row.product_base_product_color_links?.map(
                  (link: IProductBaseProductColorLink) => link.product_color.id
                ),
                styles: row.product_base_product_style_links?.map(
                  (link: IProductBaseProductStyleLink) => link.product_style.id
                ),
                regions: row.product_base_region_links?.map(
                  (link: IProductBaseRegionLink) => link.product_region.id
                ),
              }}
              validate={(values) => {
                const errors: Partial<IFormikElementErrorProps> = {};
                if (!values.name) {
                  errors.name = "Required";
                }
                if (!values.iconUri) {
                  errors.iconUri = "Required";
                }
                if (!values.unitCost) {
                  errors.unitCost = "Required";
                } else if (isNaN(parseFloat(values.unitCost))) {
                  errors.unitCost = "Invalid Number";
                }
                if (!values.msrp) {
                  errors.msrp = "Required";
                } else if (isNaN(parseFloat(values.msrp))) {
                  errors.msrp = "Invalid Number";
                }
                if (!values.vendor) {
                  errors.vendor = "Required";
                }
                if (!values.setting) {
                  errors.setting = "Required";
                }
                if (!values.materials.length) {
                  errors.materials = "At least one required";
                }
                if (!values.colors.length) {
                  errors.colors = "At least one required";
                }
                if (!values.type.length) {
                  errors.type = "At least one required";
                }
                if (!values.styles.length) {
                  errors.styles = "Visibility modifier required";
                }

                return errors;
              }}
              onSubmit={(values) => {
                onUpdateRow({
                  name: values.name,
                  inStock: values.inStock,
                  iconUri: values.iconUri,
                  unitCost: values.unitCost,
                  laborCost: values.laborCost,
                  msrp: values.msrp,
                  vendor: values.vendor,
                  retailer: values.retailer,
                  materials: values.materials,
                  type: values.type,
                  setting: values.setting,
                  colors: values.colors,
                  price_tier: values.price_tier,
                  collection_name: values.collection_name,
                  styles: values.styles,
                  link: values.link,
                  lumion_file_name: values.lumion_file_name,
                  affiliate_link: values.affiliate_link,
                  sketchup_file_link: values.sketchup_file_link,
                  sketchup_proxy_file_link: values.sketchup_proxy_file_link,
                  lumion_file_link: values.lumion_file_link,
                  vray_file_link: values.vray_file_link,
                  max_file_link: values.max_file_link,
                  visibility: values.visibility,
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <>
                  <Box className={classes.editItems} margin={1}>
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <TextField
                          error={!!(errors.name && touched.name)}
                          className={classes.editItem}
                          onChange={handleChange("name")}
                          value={values.name}
                          label="Name"
                          color="primary"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetLibraryImageUpload
                          formikName="iconUri"
                          onImageUploaded={onImageUploaded}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          multiple
                          query={PRODUCT_TYPE_SELECT}
                          queryField="product_type"
                          formikName="type"
                          label="Type"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          error={!!(errors.unitCost && touched.unitCost)}
                          className={classes.editItem}
                          onChange={handleChange("unitCost")}
                          value={values.unitCost}
                          label="Retail Cost"
                          color="primary"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          error={!!(errors.laborCost && touched.laborCost)}
                          className={classes.editItem}
                          onChange={handleChange("laborCost")}
                          value={values.laborCost}
                          label="Labor Cost"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          error={!!(errors.msrp && touched.msrp)}
                          className={classes.editItem}
                          onChange={handleChange("msrp")}
                          value={values.msrp}
                          label="MSRP"
                          color="primary"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          multiple
                          query={PRODUCT_STYLE_SELECT}
                          queryField="product_style"
                          formikName="styles"
                          label="Style"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          className={classes.editItem}
                          onChange={handleChange("link")}
                          value={values.link}
                          label="Link"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          query={PRODUCT_PRICE_TIER_SELECT}
                          queryField="product_price_tier"
                          formikName="price_tier"
                          label="Price Tier"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          query={PRODUCT_VENDOR_SELECT}
                          queryField="product_vendor"
                          formikName="vendor"
                          label="Vendor"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          query={PRODUCT_RETAILER_SELECT}
                          queryField="product_retailer"
                          formikName="retailer"
                          label="Retailer"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          query={PRODUCT_COLLECTION_NAME_SELECT}
                          queryField="product_collection_name"
                          formikName="collection_name"
                          label="Collection Name"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <InStockSelect />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          query={PRODUCT_SETTING_SELECT}
                          queryField="product_setting"
                          formikName="setting"
                          label="Setting"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          multiple
                          query={PRODUCT_MATERIAL_SELECT}
                          queryField="product_material"
                          formikName="materials"
                          label="Material"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          multiple
                          query={PRODUCT_COLOR_SELECT}
                          queryField="product_color"
                          formikName="colors"
                          label="Color"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          className={classes.editItem}
                          onChange={handleChange("affiliate_link")}
                          value={values.affiliate_link}
                          label="Affiliate Link"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          className={classes.editItem}
                          onChange={handleChange("lumion_file_name")}
                          value={values.lumion_file_name}
                          label="Lumion File Name"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          className={classes.editItem}
                          onChange={handleChange("max_file_link")}
                          value={values.max_file_link}
                          label="MAX File Link"
                          color="primary"
                          fullWidth
                        />
                      </Grid>

                      <Grid item md={4}>
                        <VisibilitySelect />
                      </Grid>
                      <Grid item md={12}>
                        <AssetFeatureSelect
                          multiple
                          optional
                          readOnly
                          query={PRODUCT_REGION_SELECT}
                          queryField="product_region"
                          formikName="regions"
                          label="Region"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      className={classes.uploadContainer}
                    >
                      <ModelFileUploadForm />
                    </Grid>
                  </Box>
                  <div className={classes.itemBtns}>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                      onClick={() => handleSubmit()}
                      style={{ marginRight: "0.5rem" }}
                    >
                      Save Changes
                    </Button>
                    {isSubmitting && (
                      <div className={classes.saveProgress}>
                        <Typography>
                          {Math.min(saveProgress, 100).toFixed(0)}%
                        </Typography>
                        <CircularProgress size={15} style={{ marginLeft: 5 }} />
                      </div>
                    )}
                    {row.archived ? (
                      <Button
                        variant="contained"
                        disabled={isSubmitting || isArchiving}
                        onClick={onArchiveToggle}
                      >
                        Un-archive
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        disabled={isSubmitting || isArchiving}
                        onClick={onArchiveToggle}
                      >
                        Archive
                      </Button>
                    )}
                  </div>
                  <br />
                  <br />
                </>
              )}
            </Formik>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default ElementTableRow;
