import React, { FC, useState, useMemo, ReactNode, useEffect } from "react";
import { EntryImage, EntryMediaType } from "./util";
import { Box, Typography } from "@material-ui/core";
import { Media } from "@yardzen-inc/models";
import OnboardImageCard from "../OnboardImageCard";
import FullScreenImage from "../FullScreenImage";

export interface DesignChecklistItemImagesProps {
  images: EntryImage[];
}

const DesignChecklistItemImages: FC<DesignChecklistItemImagesProps> = (
  props
) => {
  const [imageURLS, setImageURLS] = useState<string[] | null>(null);

  const [selectedUrl, setSelectedUrl] = useState<string | null>(null);

  const len = useMemo<number>(() => imageURLS?.length ?? 0, [imageURLS]);

  useEffect(getImageUrls, [props.images]);

  if (!imageURLS)
    return (
      <Box p={1}>
        <Typography style={{ fontStyle: "italic" }}>
          loading Images...
        </Typography>
      </Box>
    );

  if (!len) {
    return null;
  }

  return (
    <>
      {selectedUrl && (
        <FullScreenImage
          media={selectedUrl}
          open={true}
          onClose={() => setSelectedUrl(null)}
        />
      )}
      <Box px={2} display="flex" flexDirection="row">
        {renderImages(imageURLS, setSelectedUrl)}
      </Box>
    </>
  );

  function getImageUrls() {
    void (async function () {
      setImageURLS(
        (
          await Promise.all(
            props.images.map(async (i) => {
              if (i.type === EntryMediaType.URL) {
                return i.value;
              }

              return (await Media.fetchSingle(i.value))?.downloadURL;
            })
          )
        ).filter((m) => !!m) as string[]
      );
    })();
  }
};

function renderImages(
  uris: string[],
  expand: (url: string) => void
): ReactNode[] {
  return uris.map((imageUri) => {
    return (
      <Box p={1} key={imageUri}>
        <OnboardImageCard
          width={300}
          height={168}
          imageURL={imageUri}
          expand={() => expand(imageUri)}
        />
      </Box>
    );
  });
}

export { DesignChecklistItemImages };
export default DesignChecklistItemImages;
