import * as React from "react";
import { makeStyles } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Theme } from "@material-ui/core/styles";
import SupplementaryTableForAssets from "../Shared/SupplementaryTableForAssets";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  HasuraQueryFunctions,
  HasuraQueryHumanReadableOperatorEnum,
} from "@yardzen-inc/util";
import AssetTable from "../Shared/AssetTable";
import {
  INSERT_PLANT_TYPE,
  UPDATE_PLANT_TYPE,
  INSERT_PLANT_CAD_SYMBOL_SIZE,
  INSERT_PLANT_ADAPTED_HABITAT,
  INSERT_PLANT_BLOOM_TIME,
  INSERT_PLANT_CAD_SYMBOL_SPACING,
  INSERT_PLANT_DECIDUOUS_OR_EVERGREEN,
  INSERT_PLANT_GROWTH_HABIT,
  INSERT_PLANT_KEY_FEATURE,
  INSERT_PLANT_LIGHT_NEEDS,
  INSERT_PLANT_NATIVE_RANGE,
  INSERT_PLANT_RED_FLAG,
  INSERT_PLANT_USDA_ZONE,
  INSERT_PLANT_VIP,
  PLANT_ADAPTED_HABITAT,
  PLANT_BLOOM_TIME,
  PLANT_CAD_SYMBOL_SIZE,
  PLANT_CAD_SYMBOL_SPACING,
  PLANT_DECIDUOUS_OR_EVERGREEN,
  PLANT_GROWTH_HABIT,
  PLANT_KEY_FEATURE,
  PLANT_LIGHT_NEEDS,
  PLANT_NATIVE_RANGE,
  PLANT_RED_FLAG,
  PLANT_USDA_ZONE,
  PLANT_VIP,
  PLANT_WATER_NEEDS,
  UPDATE_PLANT_ADAPTED_HABITAT,
  UPDATE_PLANT_BLOOM_TIME,
  UPDATE_PLANT_CAD_SYMBOL_SIZE,
  UPDATE_PLANT_CAD_SYMBOL_SPACING,
  UPDATE_PLANT_DECIDUOUS_OR_EVERGREEN,
  UPDATE_PLANT_GROWTH_HABIT,
  UPDATE_PLANT_KEY_FEATURE,
  UPDATE_PLANT_LIGHT_NEEDS,
  UPDATE_PLANT_NATIVE_RANGE,
  UPDATE_PLANT_RED_FLAG,
  UPDATE_PLANT_USDA_ZONE,
  UPDATE_PLANT_VIP,
  useLazyQuery,
  PLANT_TYPE,
  INSERT_PLANT_WATER_NEEDS,
  UPDATE_PLANT_WATER_NEEDS,
  INSERT_PLANT_GARDEN_STYLE,
  UPDATE_PLANT_GARDEN_STYLE,
  PLANT_GARDEN_STYLE,
  INSERT_PLANT_HEIGHT_CLASS,
  UPDATE_PLANT_HEIGHT_CLASS,
  PLANT_HEIGHT_CLASS,
  INSERT_PLANT_WIDTH_CLASS,
  UPDATE_PLANT_WIDTH_CLASS,
  PLANT_WIDTH_CLASS,
  PLANT_MATURE_SIZE,
  UPDATE_PLANT_MATURE_SIZE,
  INSERT_PLANT_MATURE_SIZE,
  INSERT_PLANT_LEAF_COLOR,
  UPDATE_PLANT_LEAF_COLOR,
  PLANT_LEAF_COLOR,
  INSERT_PLANT_FLOWER_COLOR,
  UPDATE_PLANT_FLOWER_COLOR,
  PLANT_FLOWER_COLOR,
  PLANT_FRUIT_COLOR,
  UPDATE_PLANT_FRUIT_COLOR,
  INSERT_PLANT_FRUIT_COLOR,
  NetworkStatus,
} from "@yardzen-inc/graphql";
import PlantsTableRow from "./PlantsTableRow";
import AddNewPlant from "./AddNewPlant";
import {
  IPlantBasePlantAdaptedHabitatLink,
  IPlantBasePlantBloomTimeLink,
  IPlantBasePlantGrowthHabitLink,
  IPlantBasePlantKeyFeatureLink,
  IPlantBasePlantLightNeedsLink,
  IPlantBasePlantNativeRangeLink,
  IPlantBasePlantRedFlagLink,
  IPlantBasePlantTypeLink,
  IPlantBasePlantUsdaZoneLink,
  IPlantBasePlantVipLink,
  IPlantBasePlantHeightClassLink,
  IPlantBasePlantWidthClassLink,
  IPlantBasePlantMatureSizeLink,
  IPlantBasePlantLeafColorLink,
  IPlantBasePlantFlowerColorLink,
  IPlantBasePlantFruitColorLink,
  IPlantBasePlantGardenStyleLink,
  IPlantBasePlantCadSizeLink,
  IPlantBasePlantCadSpacingLink,
  ModelFiletype,
  IPlantBasePlantContainerSizeLink,
} from "../../../Interfaces";
import { Visibility, visibilityValues } from "../Shared/VisibilityTypes";
import { formatNumToUSD } from "@yardzen-inc/react-common";
import FilterMenu from "../Shared/FilterMenu";
import PlantLibraryFilterBoxOptions from "./PlantLibraryFilterBoxOptions";
import ChipSingleSelect from "../Shared/ChipSingleSelect";
import { useHistory } from "react-router";
import { GraphqlConstants } from "../../../ConstantValues/graphqlConstants";
import TableCellWithStyles from "../Shared/AssetTableCellWithStyles";
import { arrayMove } from "react-sortable-hoc";
import AssetLibraryTabPanel from "../Shared/AssetLibraryTabPanel";
import getAssetLibraryOrderBy from "../getAssetLibraryOrderBy";
import cleanedDownloadableFileName from "../Shared/CleanedDownloadableFileName";
import usePreloadedAssetTableFilters from "../../../util/hooks/usePreloadedAssetTableFilters";
import AssetLibraryFilterMenu from "../Shared/AssetLibraryFilterMenu";
import { AssetLibraryFetchMoreStatus } from "../../../ConstantValues/AssetLibraryFetchMoreStatusConstants";
import getObjectAndArrayRelationships from "../Shared/getObjectAndArrayRelationships";
import {
  plantArrayLinksMap,
  plantArrayLinksSubfieldMap,
  plantObjLinksMap,
} from "../Shared/FilterLinkMaps";
import { ModelFileDownloadButton } from "../../../Components/ModelFileDownloadButton";
import { PLANT_BASE, PLANT_BASE_AGGREGATE } from "../Shared/graphqlQueries";

// type QueryOrderByType = "field" | "column" | "direction";

type QueryByRecord = Record<"field" | "direction", string>;
type ActiveOrderByRecord = Record<"column" | "direction", string>;

let saveTimeout: any = null;

const listResultName = "plant_base";

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  assetFilters: {
    display: "flex",
    alignItems: "center",
  },
  tabs: {
    background: "#fafafa",
    borderBottom: "1px solid #f2f2f2",
    minHeight: "0 !important",
    position: "fixed",
    zIndex: theme.zIndex.drawer + 1,
    width: "calc(100vw - 240px)",
    top: 0,
  },
  tab: {
    minWidth: 0,
    textTransform: "none",
    height: 43,
    minHeight: "0 !important",
    fontSize: 15.3,
    "&.Mui-selected": {
      fontWeight: 600,
    },
  },
  iconUri: {
    width: "6.25rem",
    height: "6.25rem",
    objectFit: "contain",
  },
  longLink: {
    width: "10rem",
    maxWidth: "10rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

function PlantLibrary() {
  const history = useHistory();
  const classes = useStyles();

  const [
    neqFilterState,
    setNeqFilterState,
    hasAnyOfFilterState,
    setHasAnyOfFilterState,
    hasAllOfFilters,
    setHasAllOfFilters,
    search,
    setSearch,
  ] = usePreloadedAssetTableFilters("plants");

  const [visibilityFilter, setVisibilityFilter] = React.useState<string>("");

  const [tabValue, setTabValue] = React.useState<number>(0);
  const [showArchived, setShowArchived] = React.useState(false);
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const getInitialQueryBy = (): QueryByRecord => {
    const urlParams = new URLSearchParams(window.location.search);
    const column = urlParams.get("sortColumn");
    const direction = urlParams.get("sortDirection");
    if (!column || !direction) {
      return {
        field: "updated_at",
        direction: "desc",
      } as QueryByRecord;
    }
    return {
      field: column || "",
      direction,
    } as QueryByRecord;
  };

  const getInitialActiveOrderBy = (): ActiveOrderByRecord => {
    const urlParams = new URLSearchParams(window.location.search);
    const column = urlParams.get("sortColumn");
    const direction = urlParams.get("sortDirection");
    if (!column || !direction) {
      return {
        column: "updated_at",
        direction: "desc",
      } as ActiveOrderByRecord;
    }
    return {
      column: column || "",
      direction,
    } as ActiveOrderByRecord;
  };

  const [queryBy, setQueryBy] = React.useState(getInitialQueryBy());

  const [activeOrderBy, setActiveOrderBy] = React.useState(
    getInitialActiveOrderBy()
  );

  const onSearch = (term: string) => {
    setSearch(term);
  };

  const [getBases, { data, refetch, error, fetchMore, networkStatus }] =
    useLazyQuery(PLANT_BASE, {
      notifyOnNetworkStatusChange: true,
    });

  const [getCount, { data: dataCount }] = useLazyQuery(PLANT_BASE_AGGREGATE, {
    fetchPolicy: "cache-and-network",
  });

  const [fetchMoreStatus, setFetchMoreStatus] =
    React.useState<AssetLibraryFetchMoreStatus>(
      AssetLibraryFetchMoreStatus.standby
    );

  // if the user has scrolled to the bottom
  // but the network status is not in "ready" state,
  // we want this useEffect to listten for the network status to be ready
  // and as soon as ready, fetch more results.
  React.useEffect(() => {
    if (networkStatus !== NetworkStatus.ready) return;
    if (fetchMoreStatus !== AssetLibraryFetchMoreStatus.shouldFetch) return;
    fetchMoreResults();
    setFetchMoreStatus(AssetLibraryFetchMoreStatus.fetching);
  }, [fetchMoreStatus, networkStatus]);

  React.useEffect(() => {
    if (data === undefined) return;
    setFetchMoreStatus(AssetLibraryFetchMoreStatus.standby);
  }, [data]);

  React.useEffect(() => {
    getBases({
      variables: getVariables(),
    });
    getCount({
      variables: getVariables(),
    });
  }, [getBases]);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const column = urlParams.get("sortColumn");
    const direction = urlParams.get("sortDirection");
    if (column && direction) {
      setActiveOrderBy({
        column,
        direction,
      });
    } else {
      setActiveOrderBy({ column: "updated_at", direction: "desc" });
    }
  }, [window.location.search]);

  const getVariables = (useOffset?: boolean, forceShowArchived?: boolean) => {
    const _search = search ? `%${search}%` : "%%";
    const condition: any = {};
    if (search) {
      condition._or = [
        { scientific_name: { _ilike: _search } },
        { common_name: { _ilike: _search } },
      ];
    }
    if (typeof forceShowArchived === "boolean") {
      condition.archived = {
        _eq: forceShowArchived,
      };
    } else {
      condition.archived = {
        _eq: showArchived,
      };
    }
    if (visibilityFilter) {
      condition.visibility = {
        _eq: visibilityFilter,
      };
    }

    const [hasAllOfObjectRelationships, hasAllOfArrayRelationships] =
      getObjectAndArrayRelationships(
        hasAllOfFilters,
        plantArrayLinksMap,
        plantObjLinksMap,
        plantArrayLinksSubfieldMap
      );
    HasuraQueryFunctions.processAndConditionsNoLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAllOf,
      fields: hasAllOfObjectRelationships,
    });
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAllOf,
      fields: hasAllOfArrayRelationships,
    });
    const [hasAnyOfObjectRelationships, hasAnyOfArrayRelationships] =
      getObjectAndArrayRelationships(
        hasAnyOfFilterState,
        plantArrayLinksMap,
        plantObjLinksMap,
        plantArrayLinksSubfieldMap
      );
    HasuraQueryFunctions.processAndConditionsNoLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAnyOf,
      fields: hasAnyOfObjectRelationships,
    });
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAnyOf,
      fields: hasAnyOfArrayRelationships,
    });
    const [hasNoneOfObjectRelationships, hasNoneOfArrayRelationships] =
      getObjectAndArrayRelationships(
        neqFilterState,
        plantArrayLinksMap,
        plantObjLinksMap,
        plantArrayLinksSubfieldMap
      );
    HasuraQueryFunctions.processAndConditionsNoLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasNoneOf,
      fields: hasNoneOfObjectRelationships,
    });
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasNoneOf,
      fields: hasNoneOfArrayRelationships,
    });
    return {
      condition,
      offset: useOffset ? data[listResultName].length : 0,
      orderBy: getAssetLibraryOrderBy(queryBy.field, queryBy.direction),
    };
  };

  React.useEffect(() => {
    if (!refetch) {
      return;
    }
    saveTimeout = setTimeout(async () => {
      refetch(getVariables());
      getCount({
        variables: getVariables(),
      });
    }, 300);

    return () => clearTimeout(saveTimeout);
  }, [
    refetch,
    search,
    hasAllOfFilters,
    neqFilterState,
    hasAllOfFilters,
    hasAnyOfFilterState,
    visibilityFilter,
  ]);

  const requestRefetch = () => {
    if (!refetch) {
      return;
    }
    return refetch({ ...getVariables() });
  };

  const onVisibilityFilterChange = (value: string) => {
    if (visibilityValues.includes(value as Visibility)) {
      return setVisibilityFilter(value);
    }

    return setVisibilityFilter("");
  };

  const requestMoreResults = () => {
    setFetchMoreStatus(AssetLibraryFetchMoreStatus.shouldFetch);
  };

  const fetchMoreResults = async () => {
    if (!fetchMore) {
      return alert(
        "Something went wrong. Please reload the screen and try again."
      );
    }
    try {
      fetchMore({
        variables: getVariables(true),
        updateQuery: (prev: any, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const iterableProductBase = Array.isArray(prev[listResultName])
            ? prev[listResultName]
            : [];
          const newArray = Array.from(
            new Set([
              ...iterableProductBase,
              ...fetchMoreResult[listResultName],
            ])
          );
          // it's possible that the apollo caching mechanism may
          // provide the fetchMoreResult from the previous iteration
          // if the user is moving quickly, so let's adjust for that
          // and ensure we're not duplicating any data
          let uniqueArray: any[] = [];
          for (let it = 0; it < newArray.length; it++) {
            if (uniqueArray.some((line) => line.id === newArray[it].id)) {
              continue;
            }
            uniqueArray.push(newArray[it]);
          }
          const resultToSend = Object.assign({}, prev, {
            [listResultName]: uniqueArray,
          });
          return resultToSend;
        },
      });
    } catch (err) {
      // pretty sure an error is thrown only in development
      // but this is just to be sure
      console.error("fetchMore issue 1");
    }
  };

  const onSort = (v: number, queryName: string): any => {
    if (!refetch) {
      return;
    }
    let stateObj = {};
    if (v === 0) {
      window.history.replaceState(stateObj, "", `/plants-library?`);
      return refetch({ ...getVariables() });
    }
    const direction = v === -1 ? "desc" : "asc";
    if (window.history.replaceState) {
      //prevents browser from storing history with each change:
      window.history.replaceState(
        stateObj,
        "",
        `/plants-library?sortColumn=${queryName}&sortDirection=${direction}`
      );
    }
    setQueryBy({ field: queryName, direction });
    refetch({
      ...getVariables(),
      orderBy: getAssetLibraryOrderBy(queryName, direction),
    });
  };

  const toggleArchived = () => {
    if (!refetch) {
      return alert(
        "Something went wrong. Please reload the screen and try again."
      );
    }
    const newArchived = !showArchived;
    refetch({ ...getVariables(undefined, newArchived) });
    getCount({
      variables: { ...getVariables(undefined, newArchived) },
    });
    setShowArchived(newArchived);
  };

  const onSaveNewAsset = () => {
    if (refetch) {
      refetch();
    }
  };

  const tableContainerRef = React.useRef(null);

  const onScroll = () => {
    const target: any = tableContainerRef.current;
    if (!target) return;
    let pos = target.scrollTop + target.offsetHeight;
    let max = target.scrollHeight;
    // fetching before user hits the bottom of the screen so by
    // the time the user gets to the bottom, stuff will have loaded.
    if (
      pos >= max - 700 &&
      data.plant_base.length % GraphqlConstants.resultsPerQuery === 0 &&
      data.plant_base.length !== 0 &&
      data.plant_base.length !== dataCount?.plant_base_aggregate.aggregate.count
    ) {
      requestMoreResults();
    }
  };
  const initialColumns = [
    {
      name: "Scientific Name",
      id: "scientific_name",
      queryName: "scientific_name",
      sortable: true,
      renderCellContent: (row: any) => row.scientific_name,
    },
    {
      name: "Common Name",
      id: "common_name",
      queryName: "common_name",
      sortable: true,
      renderCellContent: (row: any) => row.common_name,
    },
    {
      name: "Planting Style",
      id: "plant_base_plant_garden_style_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_garden_style_links
          ?.map(
            (style: IPlantBasePlantGardenStyleLink) =>
              style.plant_garden_style.name
          )
          .join(", "),
    },
    {
      name: "Image",
      id: "icon_uri",
      renderCellContent: (row: any) => (
        <img className={classes.iconUri} src={row.icon_uri} />
      ),
    },
    {
      name: "USDA Zone",
      id: "plant_base_plant_usda_zone_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_usda_zone_links
          ?.map(
            (style: IPlantBasePlantUsdaZoneLink) => style.plant_usda_zone.name
          )
          .join(", "),
    },
    {
      name: "Geo",
      id: "plant_base_adapted_habitat_links",
      renderCellContent: (row: any) =>
        row.plant_base_adapted_habitat_links
          ?.map(
            (style: IPlantBasePlantAdaptedHabitatLink) =>
              style.plant_adapted_habitat.name
          )
          .join(", "),
    },
    {
      name: "VIP",
      id: "plant_base_plant_vip_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_vip_links
          ?.map((style: IPlantBasePlantVipLink) => style.plant_vip.name)
          .join(", "),
    },
    {
      name: "Key Feature",
      id: "plant_base_plant_key_feature_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_key_feature_links
          ?.map(
            (style: IPlantBasePlantKeyFeatureLink) =>
              style.plant_key_feature.name
          )
          .join(", "),
    },
    {
      name: "Type",
      id: "plant_base_plant_type_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_type_links
          ?.map((style: IPlantBasePlantTypeLink) => style.plant_type.name)
          .join(", "),
    },
    {
      name: "Light Needs",
      id: "plant_base_plant_light_needs_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_light_needs_links
          ?.map(
            (style: IPlantBasePlantLightNeedsLink) =>
              style.plant_light_needs.name
          )
          .join(", "),
    },
    {
      name: "Water Needs",
      sortable: true,
      queryName: "waterNeedsByPlantWaterNeeds.name",
      id: "waterNeedsByPlantWaterNeeds_simplejoin",
      renderCellContent: (row: any) => row.waterNeedsByPlantWaterNeeds?.name,
    },
    {
      name: "Mature Width",
      id: "matureWidthByPlantMatureWidth_simplejoin",
      sortable: true,
      queryName: "matureWidthByPlantMatureWidth.name",
      renderCellContent: (row: any) => row.matureWidthByPlantMatureWidth?.name,
    },
    {
      name: "Mature Height",
      id: "matureHeightByPlantMatureHeight_simplejoin",
      sortable: true,
      queryName: "matureHeightByPlantMatureHeight.name",
      renderCellContent: (row: any) =>
        row.matureHeightByPlantMatureHeight?.name,
    },
    {
      name: "Minimum Space Needed to Plant",
      id: "minimumSpaceToPlantByPlantminimumSpaceToPlant_simplejoin",
      sortable: true,
      queryName: "minimumSpaceToPlantByPlantminimumSpaceToPlant.name",
      renderCellContent: (row: any) =>
        row.minimumSpaceToPlantByPlantminimumSpaceToPlant?.name,
    },
    {
      name: "Height Class",
      id: "plant_base_plant_height_class_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_height_class_links
          ?.map(
            (style: IPlantBasePlantHeightClassLink) =>
              style.plant_height_class.name
          )
          .join(", "),
    },
    {
      name: "Width Class",
      id: "plant_base_plant_width_class_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_width_class_links
          ?.map(
            (style: IPlantBasePlantWidthClassLink) =>
              style.plant_width_class.name
          )
          .join(", "),
    },
    {
      name: "Mature Size",
      id: "plant_base_plant_mature_size_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_mature_size_links
          ?.map(
            (style: IPlantBasePlantMatureSizeLink) =>
              style.plant_mature_size.name
          )
          .join(", "),
    },
    {
      name: "Red Flag",
      id: "plant_base_plant_red_flag_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_red_flag_links
          ?.map(
            (style: IPlantBasePlantRedFlagLink) => style.plant_red_flag.name
          )
          .join(", "),
    },
    {
      name: "Red Flag Info",
      id: "red_flag_info",
      sortable: true,
      queryName: "red_flag_info",
      renderCellContent: (row: any) => row.red_flag_info,
    },
    {
      name: "Deciduous or Evergreen",
      sortable: true,
      queryName: "deciduousOrEvergreenByPlantDeciduousOrEvergreen.name",
      id: "deciduousOrEvergreenByPlantDeciduousOrEvergreen_simplejoin",
      renderCellContent: (row: any) =>
        row.deciduousOrEvergreenByPlantDeciduousOrEvergreen?.name,
    },
    {
      name: "Retailer",
      sortable: true,
      queryName: "retailerByPlantRetailer.name",
      id: "retailerByPlantRetailer_simplejoin",
      renderCellContent: (row: any) => row.retailerByPlantRetailer?.name,
    },
    {
      name: "Leaf Color",
      id: "plant_base_plant_leaf_color_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_leaf_color_links
          ?.map(
            (style: IPlantBasePlantLeafColorLink) => style.plant_leaf_color.name
          )
          .join(", "),
    },
    {
      name: "Flower Color",
      id: "plant_base_plant_flower_color_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_flower_color_links
          ?.map(
            (style: IPlantBasePlantFlowerColorLink) =>
              style.plant_flower_color.name
          )
          .join(", "),
    },
    {
      name: "Bloom Time",
      id: "plant_base_plant_bloom_time_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_bloom_time_links
          ?.map(
            (style: IPlantBasePlantBloomTimeLink) => style.plant_bloom_time.name
          )
          .join(", "),
    },
    {
      name: "Fruit Color",
      id: "plant_base_plant_fruit_color_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_fruit_color_links
          ?.map(
            (style: IPlantBasePlantFruitColorLink) =>
              style.plant_fruit_color.name
          )
          .join(", "),
    },
    {
      name: "Additional Info",
      id: "additional_info",
      sortable: true,
      queryName: "additional_info",
      renderCellContent: (row: any) => (
        <div className={classes.longLink}>
          <a href={row.additional_info} target="_blank">
            {row.additional_info}
          </a>
        </div>
      ),
    },
    {
      name: "Native Range",
      id: "plant_base_plant_native_range_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_native_range_links
          ?.map(
            (style: IPlantBasePlantNativeRangeLink) =>
              style.plant_native_range.name
          )
          .join(", "),
    },
    {
      name: "Price",
      id: "unit_cost",
      queryName: "unit_cost",
      renderCellContent: (row: any) =>
        row.unit_cost ? formatNumToUSD(parseFloat(row.unit_cost)) : "--",
    },
    {
      name: "Design Tips",
      id: "design_tips",
      sortable: true,
      queryName: "design_tips",
      renderCellContent: (row: any) => row.design_tips,
    },
    {
      name: "Companion Plants",
      id: "companion_plants",
      queryName: "companion_plants",
      sortable: true,
      renderCellContent: (row: any) => row.companion_plants,
    },
    {
      name: "Similar Plants",
      id: "similar_plants",
      queryName: "similar_plants",
      sortable: true,
      renderCellContent: (row: any) => row.similar_plants,
    },
    {
      name: "Growth Habit",
      sortable: true,
      id: "plant_base_plant_growth_habit_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_growth_habit_links
          ?.map(
            (style: IPlantBasePlantGrowthHabitLink) =>
              style.plant_growth_habit.name
          )
          .join(", "),
    },
    {
      name: "Sketchup File",
      id: "sketchup_file_link",
      queryName: "sketchup_file_link",
      sortable: true,
      renderCellContent: (row: any) => (
        <ModelFileDownloadButton
          filetype={ModelFiletype.sketchup}
          filenameOrUrl={row.sketchup_file_link}
          downloadableFileName={cleanedDownloadableFileName(
            row.scientific_name
          )}
        />
      ),
    },
    {
      name: "Proxy File",
      id: "proxy_file_link",
      queryName: "proxy_file_link",
      sortable: true,
      renderCellContent: (row: any) => (
        <ModelFileDownloadButton
          filetype={ModelFiletype.proxy}
          filenameOrUrl={row.proxy_file_link}
          downloadableFileName={cleanedDownloadableFileName(
            row.scientific_name
          )}
        />
      ),
    },
    {
      name: "V-Ray File",
      id: "vray_file_link",
      queryName: "vray_file_link",
      sortable: true,
      renderCellContent: (row: any) => (
        <ModelFileDownloadButton
          filetype={ModelFiletype.vray}
          filenameOrUrl={row.vray_file_link}
          downloadableFileName={cleanedDownloadableFileName(
            row.scientific_name
          )}
        />
      ),
    },
    {
      name: "MAX File",
      id: "max_file_link",
      queryName: "max_file_link",
      sortable: true,
      renderCellContent: (row: any) =>
        row.max_file_link && (
          <a href={row.max_file_link} download={row.max_file_link}>
            <GetAppIcon />
          </a>
        ),
    },
    {
      name: "Lumion File",
      id: "lumion_file_link",
      queryName: "lumion_file_link",
      sortable: true,
      renderCellContent: (row: any) => (
        <ModelFileDownloadButton
          filetype={ModelFiletype.lumion}
          filenameOrUrl={row.lumion_file_link}
          downloadableFileName={cleanedDownloadableFileName(
            row.scientific_name
          )}
        />
      ),
    },
    {
      name: "Lumion File Name",
      id: "lumion_file_name",
      queryName: "lumion_file_name",
      sortable: true,
      renderCellContent: (row: any) => row.lumion_file_name,
    },
    {
      name: "CAD Symbol ~Size~",
      id: "plant_base_plant_cad_symbol_size_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_cad_symbol_size_links
          ?.map(
            (style: IPlantBasePlantCadSizeLink) =>
              style.plant_cad_symbol_size.name
          )
          .join(", "),
    },
    {
      name: "CAD Symbol ~Spacing~",
      id: "plant_base_plant_cad_symbol_spacing_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_cad_symbol_spacing_links
          ?.map(
            (style: IPlantBasePlantCadSpacingLink) =>
              style.plant_cad_symbol_spacing.name
          )
          .join(", "),
    },
    {
      name: "Container Size",
      id: "plant_base_plant_container_size_links",
      sortable: true,
      renderCellContent: (row: any) =>
        row.plant_base_plant_container_size_links
          ?.map(
            (style: IPlantBasePlantContainerSizeLink) =>
              style.plant_container_size.name
          )
          .join(", "),
    },
    {
      name: "Visibility",
      id: "visibility_col",
      renderCellContent: (row: any) => (
        <div
          style={{
            color: row.visibility === Visibility.ADMIN_ONLY ? "red" : "green",
          }}
        >
          {row.visibility}
        </div>
      ),
    },
  ];

  const [columns, setColumns] = React.useState(initialColumns);

  const InitialTableCells = (row: any) => {
    const cells = columns.map((column, idx) => {
      const innerContent = column.renderCellContent(row);

      // EXPLANATION OF WHAT'S GOING ON HERE:
      // Yup, these are matrices. What we're trying to do
      // is keep the state of the data in the table (columns x rows)
      // working while we do manipulations such as re-ordering columns
      // or sorting data. Sorting data triggers the database, so the
      // specific data in each row changes, while we keep the shape of
      // the table intact. We store "row" data at [0][1] and we know to
      // look for it there. This is used so we can use the "update" feature
      // which needs the non-html version of the data.
      return [
        <TableCellWithStyles
          id={column.id}
          key={`${row.id}-${column.id}`}
          align="right"
          // @ts-ignore
          hidden={hiddenColumns?.[column.id]}
        >
          {innerContent}
        </TableCellWithStyles>,
      ];
    });

    cells[1].push(row);
    return cells;
  };

  const [hiddenColumns, setHiddenColumns] = React.useState({});

  const handleToggleColumnVisible = (id: string) => {
    const updatedHiddenColumns = { ...hiddenColumns };
    // @ts-ignore
    updatedHiddenColumns[id] = !updatedHiddenColumns[id];
    setHiddenColumns(updatedHiddenColumns);
  };

  const onReorder = (oldIndex: number, newIndex: number) => {
    setColumns(arrayMove(columns, oldIndex, newIndex));
  };

  const hasMoreResults = React.useRef(
    data?.plant_base.length !== dataCount?.plant_base_aggregate.aggregate.count
  );

  const renderFilters = () => {
    const secondaryFilters = (
      <FilterMenu label="Visibility">
        <ChipSingleSelect
          onChange={onVisibilityFilterChange}
          initialValue={visibilityFilter}
          options={visibilityValues}
          title="Filter by visibility:"
          shouldDisplay
        />
      </FilterMenu>
    );

    return (
      <AssetLibraryFilterMenu
        options={PlantLibraryFilterBoxOptions}
        neqFilterState={neqFilterState}
        setNeqFilterState={setNeqFilterState}
        hasAnyOfFilterState={hasAnyOfFilterState}
        setHasAnyOfFilterState={setHasAnyOfFilterState}
        hasAllOfFilters={hasAllOfFilters}
        setHasAllOfFilters={setHasAllOfFilters}
        secondaryFilters={secondaryFilters}
      />
    );
  };

  const memoizedAssetTable = React.useMemo(() => {
    return (
      <AssetTable
        showLoadMoreButton={hasMoreResults.current}
        onScroll={onScroll}
        tableContainerRef={tableContainerRef}
        contentHeight="calc(100vh - 9.7rem)"
        pmView
        onReorder={onReorder}
        tableColumns={columns}
        fixedHeader
        hiddenColumns={hiddenColumns}
        onToggleColumnVisible={handleToggleColumnVisible}
        search={search}
        onAddNew={() => history.push("/plants-library/create")}
        addNewButtonLabel="Add New"
        resultsCount={dataCount?.plant_base_aggregate.aggregate.count || 0}
        initialTableCells={InitialTableCells}
        activeOrderBy={activeOrderBy}
        searchLabel="Search by common or scientific name"
        toggleArchived={toggleArchived}
        showArchived={showArchived}
        onSearch={onSearch}
        requestMoreResults={requestMoreResults}
        networkStatus={networkStatus}
        data={data}
        error={error}
        requestRefetch={requestRefetch}
        addNewAssetComponent={(assetProps) => (
          <AddNewPlant {...assetProps} onSave={onSaveNewAsset} />
        )}
        rowComponent={(rowProps) => (
          <PlantsTableRow {...rowProps} refetch={refetch} />
        )}
        filters={renderFilters}
        onSort={onSort}
        title="Plants"
        listResultName={listResultName}
      />
    );
  }, [
    data,
    dataCount,
    hiddenColumns,
    hasAllOfFilters,
    neqFilterState,
    hasAnyOfFilterState,
    visibilityFilter,
    search,
  ]);

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        variant="scrollable"
        scrollButtons="auto"
        value={tabValue}
        onChange={handleChangeTab}
        aria-label="simple tabs example"
      >
        <Tab className={classes.tab} label="Plants" {...a11yProps(0)} />
        <Tab className={classes.tab} label="Planting Style" {...a11yProps(1)} />
        <Tab className={classes.tab} label="Type" {...a11yProps(2)} />
        <Tab className={classes.tab} label="USDA Zone" {...a11yProps(3)} />
        <Tab
          className={classes.tab}
          label="Adapted Habitat (GEO)"
          {...a11yProps(4)}
        />
        <Tab className={classes.tab} label="Light Needs" {...a11yProps(5)} />
        <Tab className={classes.tab} label="Water Needs" {...a11yProps(6)} />
        <Tab
          className={classes.tab}
          label="Deciduous or Evergreen"
          {...a11yProps(7)}
        />
        <Tab className={classes.tab} label="Height Class" {...a11yProps(8)} />
        <Tab className={classes.tab} label="Width Class" {...a11yProps(9)} />
        <Tab className={classes.tab} label="Mature Size" {...a11yProps(10)} />
        <Tab className={classes.tab} label="Native Range" {...a11yProps(11)} />
        <Tab className={classes.tab} label="Bloom Time" {...a11yProps(12)} />
        <Tab className={classes.tab} label="VIP" {...a11yProps(13)} />
        <Tab className={classes.tab} label="Key Feature" {...a11yProps(14)} />
        <Tab className={classes.tab} label="Growth Habit" {...a11yProps(15)} />
        <Tab
          className={classes.tab}
          label="CAD Symbol ~Size~"
          {...a11yProps(16)}
        />
        <Tab
          className={classes.tab}
          label="CAD Symbol ~SPACING~"
          {...a11yProps(17)}
        />
        <Tab className={classes.tab} label="Red Flag" {...a11yProps(18)} />
        <Tab className={classes.tab} label="Leaf Color" {...a11yProps(19)} />
        <Tab className={classes.tab} label="Flower Color" {...a11yProps(20)} />
        <Tab className={classes.tab} label="Fruit Color" {...a11yProps(21)} />
      </Tabs>
      <AssetLibraryTabPanel value={tabValue} index={0}>
        {memoizedAssetTable}
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={1}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_GARDEN_STYLE}
          updateQuery={UPDATE_PLANT_GARDEN_STYLE}
          listQuery={PLANT_GARDEN_STYLE}
          listResultName="plant_garden_style"
          title="Planting Style"
          assetType="planting style"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={2}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_TYPE}
          updateQuery={UPDATE_PLANT_TYPE}
          listQuery={PLANT_TYPE}
          listResultName="plant_type"
          title="Type"
          assetType="type"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={3}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_USDA_ZONE}
          updateQuery={UPDATE_PLANT_USDA_ZONE}
          listQuery={PLANT_USDA_ZONE}
          listResultName="plant_usda_zone"
          title="USDA Zone"
          assetType="USDA zone"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={4}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_ADAPTED_HABITAT}
          updateQuery={UPDATE_PLANT_ADAPTED_HABITAT}
          listQuery={PLANT_ADAPTED_HABITAT}
          listResultName="plant_adapted_habitat"
          title="Adapted Habitat (GEO)"
          assetType="adapted habitat"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={5}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_LIGHT_NEEDS}
          updateQuery={UPDATE_PLANT_LIGHT_NEEDS}
          listQuery={PLANT_LIGHT_NEEDS}
          listResultName="plant_light_needs"
          title="Light Needs"
          assetType="light needs"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={6}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_WATER_NEEDS}
          updateQuery={UPDATE_PLANT_WATER_NEEDS}
          listQuery={PLANT_WATER_NEEDS}
          listResultName="plant_water_needs"
          title="Water Needs"
          assetType="water needs"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={7}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_DECIDUOUS_OR_EVERGREEN}
          updateQuery={UPDATE_PLANT_DECIDUOUS_OR_EVERGREEN}
          listQuery={PLANT_DECIDUOUS_OR_EVERGREEN}
          listResultName="plant_deciduous_or_evergreen"
          title="Deciduous or Evergreen"
          assetType="deciduous or evergreen"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={8}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_HEIGHT_CLASS}
          updateQuery={UPDATE_PLANT_HEIGHT_CLASS}
          listQuery={PLANT_HEIGHT_CLASS}
          listResultName="plant_height_class"
          title="Height Class"
          assetType="height class"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={9}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_WIDTH_CLASS}
          updateQuery={UPDATE_PLANT_WIDTH_CLASS}
          listQuery={PLANT_WIDTH_CLASS}
          listResultName="plant_width_class"
          title="Width Class"
          assetType="width class"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={10}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_MATURE_SIZE}
          updateQuery={UPDATE_PLANT_MATURE_SIZE}
          listQuery={PLANT_MATURE_SIZE}
          listResultName="plant_mature_size"
          title="Mature Size"
          assetType="mature size"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={11}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_NATIVE_RANGE}
          updateQuery={UPDATE_PLANT_NATIVE_RANGE}
          listQuery={PLANT_NATIVE_RANGE}
          listResultName="plant_native_range"
          title="Native Range"
          assetType="native range"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={12}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_BLOOM_TIME}
          updateQuery={UPDATE_PLANT_BLOOM_TIME}
          listQuery={PLANT_BLOOM_TIME}
          listResultName="plant_bloom_time"
          title="Bloom Time"
          assetType="bloom time"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={13}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_VIP}
          updateQuery={UPDATE_PLANT_VIP}
          listQuery={PLANT_VIP}
          listResultName="plant_vip"
          title="VIP"
          assetType="VIP"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={14}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_KEY_FEATURE}
          updateQuery={UPDATE_PLANT_KEY_FEATURE}
          listQuery={PLANT_KEY_FEATURE}
          listResultName="plant_key_feature"
          title="Key Feature"
          assetType="key feature"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={15}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_GROWTH_HABIT}
          updateQuery={UPDATE_PLANT_GROWTH_HABIT}
          listQuery={PLANT_GROWTH_HABIT}
          listResultName="plant_growth_habit"
          title="Growth Habit"
          assetType="growth habit"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={16}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_CAD_SYMBOL_SIZE}
          updateQuery={UPDATE_PLANT_CAD_SYMBOL_SIZE}
          listQuery={PLANT_CAD_SYMBOL_SIZE}
          listResultName="plant_cad_symbol_size"
          title="CAD Symbol ~Size~"
          assetType="CAD symbol size"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={17}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_CAD_SYMBOL_SPACING}
          updateQuery={UPDATE_PLANT_CAD_SYMBOL_SPACING}
          listQuery={PLANT_CAD_SYMBOL_SPACING}
          listResultName="plant_cad_symbol_spacing"
          title="CAD Symbol ~SPACING~"
          assetType="CAD symbol spacing"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={18}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_RED_FLAG}
          updateQuery={UPDATE_PLANT_RED_FLAG}
          listQuery={PLANT_RED_FLAG}
          listResultName="plant_red_flag"
          title="Red Flag"
          assetType="red flag"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={19}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_LEAF_COLOR}
          updateQuery={UPDATE_PLANT_LEAF_COLOR}
          listQuery={PLANT_LEAF_COLOR}
          listResultName="plant_leaf_color"
          title="Leaf Color"
          assetType="leaf color"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={20}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_FLOWER_COLOR}
          updateQuery={UPDATE_PLANT_FLOWER_COLOR}
          listQuery={PLANT_FLOWER_COLOR}
          listResultName="plant_flower_color"
          title="Flower Color"
          assetType="flower color"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={21}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_PLANT_FRUIT_COLOR}
          updateQuery={UPDATE_PLANT_FRUIT_COLOR}
          listQuery={PLANT_FRUIT_COLOR}
          listResultName="plant_fruit_color"
          title="Fruit Color"
          assetType="fruit color"
        />
      </AssetLibraryTabPanel>
    </div>
  );
}

export default PlantLibrary;
