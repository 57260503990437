import { RouteComponentProps } from "react-router-dom";
import { RouteCacheRef } from "./types";

export function makeRRURL(
  name: string,
  routerProps: RouteComponentProps,
  routeCacheRef: RouteCacheRef
): string {
  let url: string | undefined = void 0;

  if (!routeCacheRef.current[routerProps.match.url]) {
    routeCacheRef.current[routerProps.match.url] = {};
  } else {
    url = routeCacheRef.current[routerProps.match.url][name];
  }

  if (!url) {
    url = `${routerProps.match.url}/${name}`;
    routeCacheRef.current[routerProps.match.url][name] = url;
  }

  return url;
}
