import * as React from "react";
import { makeStyles, LinearProgress, Typography } from "@material-ui/core";
import { Media } from "@yardzen-inc/models";
import AnnotatorCard from "./AnnotatorCard";
import QaCard from "./QaCard";
import MediaCard from "./MediaCard";
import firebase from "firebase/compat/app";
import NotationCard from "./NotationCard";
import CheckableCard from "./CheckableCard";
import ReadOnlyCard from "./ReadOnlyCard";
import SortableCard from "./SortableCard";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import usePrevious from "../util/hooks/usePrevious";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-grid",
    width: "100%",
    maxWidth: "98vw",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
}));

interface ColumnConfig {
  columnsXl?: number;
  columnsLg?: number;
  columnsMd?: number;
  columnsSm?: number;
  columnsXs?: number;
}

export type NewMediaGridCardType =
  | "annotator"
  | "basic"
  | "withTextField"
  | "readOnly"
  | "qa"
  | "checkable"
  | "categoryAndDescription";

export interface MediaGridProps {
  media?: Media[] | string[] | string;
  cardType: NewMediaGridCardType;
  readonly?: boolean;
  id?: string;
  user?: any; // must provide for annotator card
  profile?: any; // must provide for annotator card
  userId?: string; // must provide for tag matches
  toggleChecked?: (id: string) => void;
  showOverheadSelection?: boolean;
  setIsOverheadMediaSelected?: (isOverheadMediaSelected: boolean) => void;
  checked?: string[];
  variant?: string;
  sortingfunction?: (media: Media[]) => Media[];
  deletable?: boolean;
  fileTag?: string; // if fileTag is provided
  revisionId?: boolean | string; //use revision id to pull in media
  onSort?: (media: Media[]) => void;
  columnsXl?: number;
  columnsLg?: number;
  columnsMd?: number;
  columnsSm?: number;
  columnsXs?: number;
  sortMode?: boolean;
  onLeaveSortMode?: (media: Media[]) => void;
  downloadable?: boolean;
  noNoItems?: boolean;
  onUpdate?: (meds: Media[] | null) => void;
  noLazyLoad?: boolean;
  disableThumbnail?: boolean;
  useVariant?: boolean;
  onExpand?: (index: number) => void;
}

const NewMediaGrid = (props: MediaGridProps) => {
  const classes = useStyles();
  const ref = React.useRef<HTMLDivElement>(null);
  const [columns, setColumns] = React.useState<number>(0);
  const [cardHeight, setCardHeight] = React.useState<number>(
    getCardHeight() || 0
  );
  const [media, setMedia] = React.useState<Media[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const prevCardHeight = usePrevious(cardHeight);
  const prevGridHeight = usePrevious(
    (ref.current && ref.current.clientHeight) || 0
  );

  React.useEffect(() => {
    if (props.setIsOverheadMediaSelected) {
      // check if any media is overhead
      // @ts-ignore
      const isOverheadMediaSelected = media?.some((m) => m.isOverhead) ?? false;
      props.setIsOverheadMediaSelected(isOverheadMediaSelected);
    }
  }, [media, props.setIsOverheadMediaSelected]);

  React.useEffect(() => {
    let timeout: any = null;

    if (props.sortMode) {
      setMedia([...(media || [])]);

      return () => {
        if (props.onLeaveSortMode) {
          props.onLeaveSortMode([...(media || [])]);
        }
      };
    }

    if (!props.sortMode) {
      return () => null;
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [props.sortMode]);

  React.useEffect(() => {
    const handleSnap = async (snap: firebase.firestore.QuerySnapshot) => {
      do {
        if (props.sortMode) {
          break;
        }
        let meds = await Promise.all(
          snap.docs.map((doc) =>
            Media.createFromQuerySnapshot(
              doc as any as firebase.firestore.QueryDocumentSnapshot
            )
          )
        );
        if (
          !meds.some((m) => {
            if (!Object.keys(m).includes("sortedIndex")) {
              return true;
            }
            return false;
          })
        ) {
          setMedia(
            meds.sort((a, b) => {
              if (!a && !b) {
                return 0;
              } else if (!(typeof a.sortedIndex === "number")) {
                return -1;
              } else if (!(typeof b.sortedIndex === "number")) {
                return 1;
              }

              return a.sortedIndex - b.sortedIndex;
            })
          );

          return setLoading(false);
        }

        if (props.sortingfunction) {
          meds = props.sortingfunction(meds);
          if (props.onSort) {
            console.log("running props.onSort");

            props.onSort(meds);
          }
        }

        if (!props.sortMode) {
          setMedia(meds);
          setLoading(false);
        }
      } while (false);
    };

    let useFileTags = false;

    if (props.sortMode) {
      return () => null;
    }

    if (media) setLoading(true);

    if (
      !!props.media &&
      (typeof props.media === "string" ||
        ((props.media as any[]).find((m) => typeof m === "string") &&
          !props.revisionId))
    ) {
      useFileTags = true;
    } else if (!props.fileTag && !!props.media) {
      setMedia(props.media as Media[]);
    }

    let unsubs: (() => void)[] = [];

    if (!!props.fileTag || useFileTags) {
      if (!(props.userId || (props.user && props.user.uid))) {
        throw new Error("must provide a userId for tag matches");
      }

      if (
        !!props.fileTag &&
        typeof props.revisionId === "string" &&
        !!props.useVariant
      ) {
        unsubs = [
          (() =>
            firebase
              .firestore()
              .collection("media")
              .where("tag", "==", props.fileTag)
              .where("variant", "==", props.variant)
              .where("revisionId", "==", props.revisionId))().onSnapshot(
            handleSnap
          ),
        ];
      } else if (!!props.fileTag && typeof props.revisionId === "string") {
        unsubs = [
          (() =>
            firebase
              .firestore()
              .collection("media")
              .where("tag", "==", props.fileTag)
              .where("revisionId", "==", props.revisionId))().onSnapshot(
            handleSnap
          ),
        ];
      } else {
        const med =
          typeof props.media === "string" ? [props.media] : props.media;

        if (!props.variant) {
          unsubs = (med as string[]).map((tag) => {
            return (
              !props.revisionId
                ? () =>
                    firebase
                      .firestore()
                      .collection("media")
                      .where("tag", "==", tag)
                      .where("userId", "==", props.userId)
                : () =>
                    firebase
                      .firestore()
                      .collection("media")
                      .where("tag", "==", "deliverable")
                      .where("revisionId", "==", tag)
            )().onSnapshot(handleSnap);
          });
        } else {
          unsubs = (med as string[]).map((tag) => {
            return (
              !props.revisionId
                ? () =>
                    firebase
                      .firestore()
                      .collection("media")
                      .where("tag", "==", tag)
                      .where("variant", "==", props.variant)
                      .where("userId", "==", props.userId)
                : () =>
                    firebase
                      .firestore()
                      .collection("media")
                      .where("tag", "==", "deliverable")
                      .where("variant", "==", props.variant)
                      .where("revisionId", "==", tag)
            )().onSnapshot(handleSnap);
          });
        }
      }
    }

    return () => {
      if (unsubs) unsubs.forEach((u) => u());
    };
  }, [props.sortMode, props.media, props.fileTag, props.revisionId]);

  React.useEffect(() => {
    let observer: IntersectionObserver | null = null;
    let startupTimeout: any = null;

    if (props.onUpdate) {
      props.onUpdate(media);
    }

    do {
      if (!media || !media.length || !ref.current || props.sortMode) {
        break;
      }

      startupTimeout = setTimeout(() => {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach(async (e) => {
              // const visible = (e as any).isVisisble;
              const intersecting = e.isIntersecting;
              const target = e.target as HTMLDivElement;

              if (intersecting) {
                if (target.style.opacity !== "1") {
                  target.style.opacity = "1";
                }

                try {
                  const elem = document.getElementById(
                    target.id + "-paper"
                  ) as HTMLDivElement;

                  elem.style.display = "inline-flex";
                  const url = elem.getAttribute("data-url");
                  if (url !== elem.style.backgroundImage) {
                    elem.style.backgroundImage =
                      elem.getAttribute("data-url") || "";
                  }
                } catch (err) {
                  console.error(err);
                }
              } else if (!intersecting) {
                if (target.style.opacity !== "0") {
                  target.style.opacity = "0";
                }

                try {
                  const elem = document.getElementById(
                    target.id + "-paper"
                  ) as HTMLDivElement;

                  elem.style.display = "none";
                  // elem.style.backgroundImage = "none";
                } catch (err) {}
              }
            });
          },
          { threshold: 0.01 }
        );

        const len = media.length;

        for (let i = 0; i < len; i += 1) {
          const elem = document.getElementById(`${props.id as string}-${i}`);

          if (!elem) {
            continue;
          }

          observer.observe(elem);
        }
      }, 150);
    } while (false);

    return () => {
      if (observer) {
        observer.disconnect();
      }
      if (startupTimeout) {
        clearTimeout(startupTimeout);
      }
    };
  }, [props.sortMode || media]);

  React.useEffect(() => {
    const [onResize, clear] = _onResize();

    window.addEventListener("resize", onResize);
    onResize();
    setColumnsFromWidth();

    const timeout = setTimeout(onResize, 300);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      window.removeEventListener("resize", onResize);
      clear();
    };
  }, [ref.current]);

  const gridStyles = getGridCssProperties();

  if (!media) return <LinearProgress variant="indeterminate" />;

  if (!media.length) {
    if (props.noNoItems) {
      return null;
    }

    return <Typography variant="h3">No Items to show</Typography>;
  }

  if (props.sortMode) {
    const cards = ((renderCards() as React.ReactNode[]) || []).map((c, i) => {
      const Elem = SortableElement((() => c) as any);
      return <Elem index={i} key={"sortable-" + i} />;
    });

    const SortGrid = SortableContainer(() => (
      <div className={classes.root} style={{ ...gridStyles }} ref={ref}>
        {cards}
      </div>
    ));

    return <SortGrid onSortEnd={onSortEnd} axis="xy" />;
  }

  return (
    <div>
      {!media && (
        <LinearProgress
          variant="indeterminate"
          style={{ opacity: loading ? 1 : 0 }}
        />
      )}
      <div className={classes.root} style={gridStyles} ref={ref}>
        {renderCards()}
      </div>
    </div>
  );

  function onSortEnd({
    newIndex,
    oldIndex,
  }: {
    newIndex: number;
    oldIndex: number;
  }) {
    if (!media || newIndex > media.length - 1) {
      return;
    }
    const newMedia = [...media];
    const [old] = newMedia.splice(oldIndex, 1);
    newMedia.splice(newIndex, 0, old);
    if (props.onSort) {
      props.onSort(newMedia);
    }
    setMedia(newMedia);
  }

  function getCardHeight() {
    if (!ref.current) {
      return undefined;
    }

    const height = (ref.current.clientWidth / (columns || 1)) * (9 / 16);

    return height;
  }

  function renderCards(): React.ReactNode[] | React.ReactNode {
    if (!columns || !media) {
      return <LinearProgress variant="indeterminate" />;
    }

    if (!media.length) {
      return <Typography variant="h3">No items found</Typography>;
    }

    const cards: React.ReactNode[] = [];

    let ticker = 0;

    const height = cardHeight || prevCardHeight;

    media.forEach((m, i) => {
      if (ticker >= columns) {
        ticker = 0;
      }

      cards.push(makeCard(m, ticker, i, height));
    });

    return cards;
  }

  function makeCard(
    m: Media,
    gridColumn: number,
    i: number,
    height?: number | string
  ) {
    const id = (props.id as string) + "-" + i;
    const paperId = id + "-" + "paper";

    if (props.sortMode) {
      return (
        <SortableCard
          onExplicitMove={(oldIndex, newIndex) =>
            onSortEnd({ oldIndex, newIndex })
          }
          _index={i}
          media={m}
          paperProps={{ id: paperId }}
          key={`${m.id}-sCard`}
          style={{
            justifySelf: "center",
            gridColumn,
            // width: `calc(100% / ${columns})`,
          }}
        />
      );
    }

    if (props.cardType === "checkable") {
      if (!props.checked || !props.toggleChecked) {
        throw new Error(
          "cardType checked requires the checked and toggleChecked props"
        );
      }

      return (
        <CheckableCard
          checked={props.checked.includes(m.id)}
          toggleChecked={props.toggleChecked}
          showOverheadSelection={props.showOverheadSelection}
          noLoad={!props.noLazyLoad}
          id={id}
          media={m}
          paperProps={{
            id: paperId,
          }}
          key={`${m.id}-naCard`}
          style={{
            justifySelf: "center",
            gridColumn,
          }}
        />
      );
    }

    if (props.cardType === "withTextField") {
      return (
        <NotationCard
          id={id}
          media={m}
          disableThumbnail={props.disableThumbnail}
          noLoad={!props.noLazyLoad}
          paperProps={{
            id: paperId,
            style: !props.noLazyLoad ? { display: "none" } : undefined,
          }}
          readonly={props.readonly}
          key={`${m.id}-naCard`}
          style={{
            justifySelf: "center",
            gridColumn,
          }}
        />
      );
    } else if (props.cardType === "readOnly") {
      return (
        <ReadOnlyCard
          id={id}
          deleteable={!!props.deletable}
          media={m}
          disableThumbnail={props.disableThumbnail}
          noLoad={!props.noLazyLoad}
          downloadable={props.downloadable ?? undefined}
          paperProps={{
            id: paperId,
            style: !props.noLazyLoad ? { display: "none" } : undefined,
          }}
          readonly={props.readonly}
          key={`${m.id}-naCard`}
          style={{
            justifySelf: "center",
            gridColumn,
          }}
        />
      );
    } else if (props.cardType === "qa") {
      return (
        <QaCard
          id={id}
          deleteable={!!props.deletable}
          media={m}
          disableThumbnail={props.disableThumbnail}
          noLoad={!props.noLazyLoad}
          paperProps={{
            id: paperId,
            style: !props.noLazyLoad ? { display: "none" } : undefined,
          }}
          readonly={props.readonly}
          key={`${m.id}-naCard`}
          style={{
            justifySelf: "center",
            gridColumn,
          }}
        />
      );
    } else if (props.cardType === "annotator") {
      if (!props.user || !props.profile) {
        throw new Error(
          "When using annotator cards, you must pass a" +
            " user object and a profile object." +
            " The profile object should be the clients."
        );
      }
      return (
        <AnnotatorCard
          id={id}
          noLoad={!props.noLazyLoad}
          media={m}
          disableThumbnail={props.disableThumbnail}
          slideName={(i + 1).toString()}
          deleteable={!!props.deletable}
          paperProps={{
            id: paperId,
            style: !props.noLazyLoad ? { display: "none" } : undefined,
          }}
          readonly={props.readonly}
          key={`${m.id}-anCard`}
          style={{
            justifySelf: "center",
            gridColumn,
            flexGrow: 1,
            opacity: 0,
          }}
          user={props.user}
          profile={props.profile}
          onExpand={props.onExpand && (() => props.onExpand!(i))}
        />
      );
    } else if (props.cardType === "basic") {
      return (
        <MediaCard
          id={id}
          media={m}
          disableThumbnail={props.disableThumbnail}
          noLoad={!props.noLazyLoad}
          paperProps={{
            id: paperId,
            style: !props.noLazyLoad ? { display: "none" } : undefined,
          }}
          key={`${m.id}-baCard`}
          style={{
            justifySelf: "center",
            gridColumn,
            opacity: 0,
          }}
        />
      );
    } else if (props.cardType === "categoryAndDescription") {
      return (
        <NotationCard
          id={id}
          media={m}
          disableThumbnail={props.disableThumbnail}
          paperProps={{
            id: paperId,
          }}
          readonly={props.readonly}
          key={`${m.id}-cdCard`}
          style={{
            justifySelf: "center",
            gridColumn,
          }}
        />
      );
    }

    throw new Error("invalid card type");
  }

  function getGridCssProperties(): React.CSSProperties {
    if (!columns || !media) return {};

    return {
      display: "inline-grid",
      gridTemplateColumns: `repeat(${columns || 1}, 1fr)`,
      gridColumnGap: "1rem",
      gridRowGap: "1rem",
      width: "100%",
      minHeight: (() =>
        cardHeight && media
          ? cardHeight * Math.round(media.length / (columns || 1))
          : prevGridHeight)(),

      //   minHeight: (cardHeight
      //     ? () => cardHeight.toString() + "px"
      //     : () =>
      //         (
      //           (Math.round(prevCardHeight || 0) * media.length) /
      //           columns
      //         ).toString() + "px")(),
      // };
    };
  }

  function setColumnsFromWidth(width: number = window.outerWidth) {
    setColumns(
      (function () {
        if (width > 1920) {
          return props.columnsXl;
        } else if (width > 1280) {
          return props.columnsLg;
        } else if (width > 960) {
          return props.columnsMd;
        } else if (width > 600) {
          return props.columnsSm;
        }
        return props.columnsXs;
      })() as number
    );
  }

  function _onResize() {
    let timeout: any = null;

    const clear = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };

    return [
      () => {
        if (timeout) {
          clear();
        }

        timeout = setTimeout(() => {
          if (!ref.current) {
            return;
          }

          const { width } = ref.current.getBoundingClientRect();
          setColumnsFromWidth(width);
          const height = getCardHeight();
          setCardHeight(height || 0);
        }, 200);
      },
      clear,
    ];
  }
};

NewMediaGrid.defaultProps = {
  columnsXl: 3,
  columnsLg: 3,
  columnsMd: 2,
  columnsSm: 1,
  columnsXs: 1,
  id: "new-media-grid",
};

export const bigCardDefaultProps = {
  columnsXl: 2,
  columnsLg: 2,
  columnsMd: 1,
  columnsSm: 1,
  columnsXs: 1,
};

export const singleColumnConfig = {
  columnsXl: 1,
  columnsLg: 1,
  columnsMd: 1,
  columnsSm: 1,
  columnsXs: 1,
} as ColumnConfig | any;

export default React.memo(NewMediaGrid);
