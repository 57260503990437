import {
  CorrectionType,
  DesignAssignmentStatuses,
  DesignAssignmentType,
} from "@yardzen-inc/models";
import { DssStateMap } from "./dssStatesOrderedMap";

interface GetNextSelfAssignStateReturnValue {
  designAssignmentStatus: DesignAssignmentStatuses;
  type: DesignAssignmentType | null;
  correctionFor: CorrectionType | null;
}
interface GetNextSelfAssignState {
  (
    designAssignmentStatus: DesignAssignmentStatuses,
    dssStates: Readonly<DssStateMap>,
    forCorrection?: boolean
  ): GetNextSelfAssignStateReturnValue;
}

const getNextSelfAssignState: GetNextSelfAssignState = (
  designAssignmentStatus: DesignAssignmentStatuses | null,
  dssStates: Readonly<DssStateMap>,
  forCorrection: boolean = false
) => {
  if (designAssignmentStatus?.includes("correction")) {
    throw new Error(
      "getNextSelfAssignState does not transform correction states"
    );
  }

  if (!designAssignmentStatus) {
    if (forCorrection) {
      throw new Error(
        "cannot issue a correction if no assignment has been assigned"
      );
    }

    const entry = dssStates.entries().next().value as [
      DesignAssignmentStatuses,
      { type: CorrectionType }
    ];

    return {
      designAssignmentStatus: entry[0] as DesignAssignmentStatuses,
      type: entry[1].type as CorrectionType,
      correctionFor: null,
    };
  }

  let prev: DesignAssignmentStatuses | null = null;
  let current: DesignAssignmentStatuses | null = null;
  let next: DesignAssignmentStatuses | null = null;
  // @ts-ignore
  for (const status of dssStates.keys()) {
    if (current) {
      next = status;
      break;
    }

    if (status === designAssignmentStatus) {
      current = status;
      continue;
    }

    prev = status;
  }

  if (forCorrection) {
    const prevValue = dssStates.get(prev);

    if (!prevValue?.type) {
      console.error({
        prev,
        current,
        prevValue,
      });
      console.error(prevValue);
      throw Error("previous value has no type");
    }

    return {
      designAssignmentStatus:
        "correctionInProgress" as DesignAssignmentStatuses,
      correctionFor: prevValue.type,
      type: "correction" as DesignAssignmentType,
    };
  }

  if (!current) {
    throw new Error(
      `invalid design assignment status ${designAssignmentStatus}`
    );
  }

  if (!next) {
    const prevValue = dssStates.get(prev);

    return {
      designAssignmentStatus: prev,
      type: prevValue?.type ?? null,
      correctionFor: null,
    };
  }

  const nextValue = dssStates.get(next);

  return {
    designAssignmentStatus: next,
    type: nextValue?.type ?? null,
    correctionFor: null,
  };
};

export { getNextSelfAssignState };
export default getNextSelfAssignState;
