import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";
import React, { FC, useEffect, useState } from "react";
import { Project } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { BOTANICAL_PACKAGE } from "src/util/packageTypeConstants";
export interface NeedsQACheckboxProps extends BoxProps {
  project: Project;
  product?: string;
}

const NeedsQACheckbox: FC<NeedsQACheckboxProps> = ({
  project,
  product,
  ...containerProps
}) => {
  const [checked, setChecked] = useState<boolean>(
    // @ts-ignore
    !!(project && project["needsRevisionQA"])
  );

  const isBotanical = product === BOTANICAL_PACKAGE;

  // @ts-ignore
  useEffect(onProjectChange, [project && project["needsRevisionQA"]]);

  useEffect(() => {
    if (isBotanical) {
      if (checked) {
        return;
      }
      // @ts-ignore
      if (project && project["needsRevisionQA"] === undefined) {
        handleChange();
      }
    }
  }, [isBotanical, project]);

  if (!project) {
    return null;
  }

  return (
    <Box {...containerProps}>
      <FormControlLabel
        label="Needs QA for Revision"
        checked={checked}
        onChange={handleChange}
        control={<Checkbox />}
      ></FormControlLabel>
    </Box>
  );

  function onProjectChange(): void {
    // @ts-ignore
    setChecked(!!(project && project["needsRevisionQA"]));
  }

  async function handleChange() {
    const prom = firebase
      .firestore()
      .collection("projects")
      .doc(project.id)
      .update({ needsRevisionQA: !checked });

    setChecked(!checked);

    await prom;
  }
};

export { NeedsQACheckbox };
export default NeedsQACheckbox;
