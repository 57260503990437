import { CorrectionType, DesignAssignmentStatuses } from "@yardzen-inc/models";
import {
  CorrectionTypeEnum,
  DesignAssignmentStatus,
  DssStateMap,
  dssStates,
} from "./dssStatesOrderedMap";

interface GetPreviousDesignAssignmentStatus {
  (
    status: DesignAssignmentStatuses,
    dssStates?: Readonly<DssStateMap>
  ): DesignAssignmentStatuses | null;
}

const getPreviousDesignAssignmentStatus: GetPreviousDesignAssignmentStatus = (
  status,
  dssStatuses = dssStates
) => {
  let prev: DesignAssignmentStatuses | null = null;
  // @ts-ignore
  for (let s of dssStatuses.keys()) {
    if (s === status) {
      break;
    }

    prev = s;
  }

  return prev;
};

const getPreviousDesignAssignmentStatusForCorrection = (
  correctionFor: CorrectionType | null
): DesignAssignmentStatus => {
  if (correctionFor === CorrectionTypeEnum.v1) {
    return DesignAssignmentStatus.postV1Hold;
  } else if (correctionFor === CorrectionTypeEnum.v2) {
    return DesignAssignmentStatus.completed;
  } else if (correctionFor === CorrectionTypeEnum.v3) {
    return DesignAssignmentStatus.postV3Hold;
  } else if (correctionFor === CorrectionTypeEnum.extra) {
    return DesignAssignmentStatus.postExtraHold;
  }
  return DesignAssignmentStatus.postCorrectionHold;
};

export {
  getPreviousDesignAssignmentStatus,
  getPreviousDesignAssignmentStatusForCorrection,
};
