import * as React from "react";
import { Paper, Box, IconButton } from "@material-ui/core";
import { Profile } from "@yardzen-inc/models";
import { YZTypography } from "@yardzen-inc/react-common";
import { Edit } from "@material-ui/icons";
import { formatAddress } from "../../util/functions/formatAddress";
import { ClientAddressUpdateModal } from "./ClientAddressUpdateModal";
import formatPhoneNumber from "../../util/formatPhoneNumber";

interface Props {
  clientRecord: Profile;
  isEmployee: boolean;
  fetchProfile: (clientId: string) => void;
}

function UpdateClientInfo({ clientRecord, isEmployee, fetchProfile }: Props) {
  const { id, phone } = clientRecord;
  const [edit, setEdit] = React.useState(false);

  return (
    <>
      <ClientAddressUpdateModal
        open={edit}
        onClose={() => setEdit(false)}
        profile={clientRecord}
        onSuccessfulAddressUpdate={() => fetchProfile(id)}
      />
      <Paper elevation={4}>
        <Box m={2} p={1}>
          <YZTypography variant="h5">
            {formatAddress(clientRecord)}
            <IconButton onClick={() => setEdit(true)}>
              <Edit />
            </IconButton>
          </YZTypography>
          <YZTypography variant="h6">
            {formatPhoneNumber(phone || "")}
          </YZTypography>
        </Box>
      </Paper>
    </>
  );
}

export { UpdateClientInfo };
