import React, { FC, useMemo } from "react";
import { Box } from "@material-ui/core";
import { DesignAssignmentListItem } from "./DesignAssignmentListItem";
import { SelfAssignQueueItems } from "src/api/pangaeaTypes";

export interface DesignAssignmentListProps {
  queue: SelfAssignQueueItems;
}

// TODO: [ENG-371] paginate results

const DesignAssignmentList: FC<DesignAssignmentListProps> = ({ queue }) => {
  if (queue?.length === 0) {
    return (
      <Box
        p={2}
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        There are currently no projects to assign. Please check back later.
      </Box>
    );
  }
  const listItems = useMemo(() => renderList(queue), [queue]);

  return (
    <Box display="flex" flexDirection="column">
      {listItems}
    </Box>
  );
};

function renderList(queue: SelfAssignQueueItems) {
  return queue.map(renderItem);
}

function renderItem(item: SelfAssignQueueItems[0], index: number) {
  return (
    <DesignAssignmentListItem
      queueItem={item}
      queuePosition={index + 1}
      key={item.profile.id}
    />
  );
}

export { DesignAssignmentList };
