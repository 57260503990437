import React, { FC, useContext, ReactNode } from "react";
import { UserCtx } from "../../util/UserContext";
import { SplitProvider, TrafficTypes } from "@yardzen-inc/react-split";

interface SplitProviderWrapperProps {
  children: ReactNode;
}

export const SplitProviderWrapper: FC<SplitProviderWrapperProps> = ({
  children,
}) => {
  const userCtx = useContext(UserCtx);
  const userId = !!userCtx ? userCtx.uid : "";

  return userId.length ? (
    <SplitProvider id={userId} trafficType={TrafficTypes.LIISA}>
      {children}
    </SplitProvider>
  ) : (
    <>{children}</>
  );
};
