import * as React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { Box, Typography, Button, ButtonGroup } from "@material-ui/core";
import { Profile, Project, ProjectManager } from "@yardzen-inc/models";
import AddPmToProjectModal from "../../Components/AddPmToProjectModal";

export type ProjectManagerType = "CDM" | "PRIMARY_CONTACT" | "QA";

export interface Props {
  profile: Profile;
  type: ProjectManagerType;
}

export default (props: Props) => {
  const [project, setProject] = React.useState<Project | null>(null);
  const [projectManager, setProjectManager] =
    React.useState<ProjectManager | null>(null);
  const [assignModalOpen, setAssignModalOpen] = React.useState<boolean>(false);

  const [title, projectPropertyName] = React.useMemo(
    () => reverseProjectManagerType(props.type),
    [props.type]
  );

  React.useEffect(listenForManager, [project]);
  React.useEffect(fetchProject, [props.profile.id]);

  return (
    <Box display="flex" flexDirection="column" my={2}>
      {renderLabel()}
      {renderButtons()}
      {renderAssignModal()}
    </Box>
  );

  function renderAssignModal(): React.ReactNode | null {
    if (!project) {
      return null;
    }

    return (
      <AddPmToProjectModal
        open={assignModalOpen}
        propertyName={projectPropertyName}
        title={title}
        contractorsIdsOnJob={[]}
        p={project}
        onClose={() => {
          fetchProject();
          setAssignModalOpen(false);
        }}
      />
    );
  }

  function renderButtons(): React.ReactNode {
    return (
      <ButtonGroup
        variant="contained"
        color="primary"
        style={{ width: "fit-content" }}
      >
        <Button onClick={() => setAssignModalOpen(true)}>Assign {title}</Button>
        {
          // @ts-ignore
          !!(project && project[projectPropertyName]) && (
            <Button onClick={unassign}>remove {title}</Button>
          )
        }
      </ButtonGroup>
    );
  }

  function renderLabel(): React.ReactNode {
    function getText(title: string, agentData: ProjectManager | null) {
      if (!agentData) {
        return `No ${title} assigned`;
      }

      return `${title}: ${agentData.firstName} ${agentData.lastName} is assigned to this project.`;
    }

    const text = getText(title, projectManager);

    return (
      <Box>
        <Typography style={undefined} variant="body1" gutterBottom>
          {text}
        </Typography>
      </Box>
    );
  }

  async function unassign(): Promise<any> {
    if (!project) {
      throw new Error("unnasign called before project fetched");
    }
    // @ts-ignore
    project[projectPropertyName] = null;
    await firebase
      .firestore()
      .collection("projects")
      .doc(project.id)
      .set(project.getProperties(), { merge: true });

    return fetchProject();
  }

  function fetchProject(): void {
    void (async function () {
      setProject(await Project.fetchWithProfileId(props.profile.id));
    })();
  }

  function listenForManager(): (() => void) | void {
    do {
      // @ts-ignore
      if (!project || !project[projectPropertyName]) {
        if (projectManager) {
          setProjectManager(null);
        }

        break;
      }

      return (
        firebase
          .firestore()
          .collection("projectManagers")
          // @ts-ignore
          .doc(project[projectPropertyName])
          .onSnapshot(async (snap) => {
            if (snap.exists) {
              return setProjectManager(ProjectManager.hydrate([snap])[0]);
            }

            if (projectManager !== null) {
              return setProjectManager(null);
            }
          })
      );
    } while (false);
  }
};

// returns the human name and relational property name of the pm type;
function reverseProjectManagerType(type: ProjectManagerType): [string, string] {
  switch (type) {
    case "CDM":
      return ["CDM Handler", "projectManagerId"];
    case "PRIMARY_CONTACT":
      return ["Account Manager", "primaryContactId"];
    case "QA":
      return ["Quality Assurance Handler", "QAManagerId"];
    default:
      throw new Error("Invalid Project manager type");
  }
}
