import React, { FC } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Theme,
  Toolbar,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { YZButton } from "@yardzen-inc/react-common";
import ClearIcon from "@material-ui/icons/Clear";

interface EnhancedTableToolbarProps {
  numSelected: number;
  handleChangePodClick: () => void;
  handleClearSelectionClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  toolbarSelected: {
    backgroundColor: "#e1e6e1",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  editPodButton: {
    paddingTop: 5,
    paddingBotton: 5,
  },
  rightNav: {
    float: "right",
  },
  leftNav: {
    display: "flex",
  },
  clearSelectionIcon: {
    marginLeft: 10,
  },
}));

const EnhancedTableToolbar: FC<EnhancedTableToolbarProps> = ({
  numSelected,
  handleChangePodClick,
  handleClearSelectionClick,
}) => {
  const classes = useStyles();

  return (
    <Toolbar className={clsx(numSelected > 0 && classes.toolbarSelected)}>
      <div>
        {numSelected > 0 ? (
          <div className={classes.leftNav}>
            <Typography color="inherit" variant="subtitle1" component="div">
              {numSelected} selected
            </Typography>
            <Tooltip title="Clear selection">
              <IconButton
                aria-label="dropdown-button"
                size="small"
                onClick={handleClearSelectionClick}
                className={classes.clearSelectionIcon}
              >
                <ClearIcon style={{ color: "grey" }} />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <Typography variant="h6" id="tableTitle" component="div">
            Designer Teams
          </Typography>
        )}
      </div>
      <div className={classes.rightNav}>
        {numSelected > 0 && (
          <YZButton
            inheritFont
            color="primary"
            className={classes.editPodButton}
            onClick={handleChangePodClick}
          >
            Change Team
          </YZButton>
        )}
      </div>
    </Toolbar>
  );
};

export { EnhancedTableToolbar };
export default EnhancedTableToolbar;
