import * as React from "react";
import firebase from "firebase/compat/app";
import Leaflet from "./Components/Leaflet";
import { Profile } from "@yardzen-inc/models";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { Typography, Divider, Link } from "@material-ui/core";
import { Marker, Popup } from "react-leaflet";

interface Props {}

function MapPage(props: Props): React.ReactElement {
  const [projects, setProjects]: [Array<Profile>, CallableFunction] =
    React.useState([]);
  const provider = new OpenStreetMapProvider();

  const [markers, setMarkers]: [Array<any>, CallableFunction] = React.useState(
    []
  );

  React.useEffect(() => {
    getProfilesWithAddresses();
  }, []);

  React.useEffect(() => {
    getMarkers();
  }, [projects]);

  if (!!markers.length) {
    return (
      <div>
        <Leaflet markers={markers} />
      </div>
    );
  }

  return <h1>LOADING</h1>;

  function getProfilesWithAddresses() {
    firebase
      .firestore()
      .collection("profiles")
      .get()
      .then((resp) => {
        const arr: Array<Profile> = [];
        resp.forEach((doc) => {
          if (!doc.exists) return;
          const data: any = doc.data();

          if (!data.street || !data.state || !data.city || !data.zip) return;
          data.id = doc.id;
          arr.push(data);
        });
        setProjects(arr);
      });
  }

  function returnMarker(proj: Profile) {
    const { firstName, lastName, state, city, zip, street, email } = proj;
    return (
      // @ts-ignore
      <Marker key={proj.id} position={proj["latLng"]}>
        <Popup>
          <Typography>
            {firstName} {lastName}
          </Typography>
          <Link
            target="_blank"
            href={`https://liisa.office.yardzen.com/client/${proj.id}/detail`}
          >
            Liisa profile
          </Link>
          <Divider />
          <Typography>
            {street} <br />
            {city} <br />
            {state} <br />
            {zip} <br />
          </Typography>
          <Divider />
          <Typography>{email}</Typography>
        </Popup>
      </Marker>
    );
  }

  async function getMarkers() {
    const mkrArr: any = [];
    projects.forEach(async (project) => {
      // @ts-ignore
      if (project["latLng"] === null || !!project["isTestAccount"]) return;
      // @ts-ignore
      if (project["latLng"] !== null && project["latLng"] === undefined) {
        const coords: any = await getCoords(project);
        console.log(coords);
        await setLatLngOnProfile(project.id, coords);
        if (!coords) return;
        // @ts-ignore
        project["latLng"] = coords;
      }
      return mkrArr.push(returnMarker(project));
    });
    console.log("setting markers");

    setMarkers(mkrArr);
  }

  async function getCoords(prj: any) {
    const { city, state, street, zip } = prj;

    console.log("no coords found, hitting API");

    const searchString = `${street} ${city} ${state} ${zip}`;
    const results = await provider.search({ query: searchString });

    if (results.length === 1) {
      const latLng = [Number(results[0].y), Number(results[0].x)];
      console.log(latLng);

      return latLng;
    }
    return null;
  }

  async function setLatLngOnProfile(profileID: string, latLng: number[]) {
    console.log("setting LATLNG on profile");

    return firebase
      .firestore()
      .collection("profiles")
      .doc(profileID)
      .update({ latLng })
      .then((resp) => {
        console.log("UPDATED");
      });
  }
}

export default MapPage;
