import * as React from "react";
import { LinearProgress } from "@material-ui/core";
import { PageComponentProps } from "./CurrentJobPageMap";
import { Media, Assignment } from "@yardzen-inc/models";
import ImageGrid from "./ImageGrid";
import { asyncForEach } from "../util/asyncForEach";

const videoR = /video\//i;

export default (props: PageComponentProps) => {
  const [videos, setVideos] = React.useState<Media[] | null>([]);

  React.useEffect(() => {
    getVideos();
  }, []);

  if (!videos) {
    return <LinearProgress variant="indeterminate" />;
  }

  return <ImageGrid media={videos as Media[]} video setLoading={() => null} />;

  async function getVideos() {
    const media = await Assignment.getAllMediaIdsByFileTags(
      ["property", "inspiration", "plans"],
      props.profile.id
    );

    (async () => {
      await asyncForEach(
        media,
        async (mediaId: string) => {
          const data = await Media.fetchSingle(mediaId);
          if (!!data && videoR.test(data.fileType)) {
            setVideos((videos) => [...(videos || []), data]);
          }
        },
        `User (Profile - ${props.profile.id}) was unable to fetch media from Firebase Storage`
      );
    })();
  }
};
