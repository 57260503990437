import * as React from "react";
import { Typography, Theme, makeStyles } from "@material-ui/core";
import { CropOriginal } from "@material-ui/icons";

interface HEICPlaceHolderProps {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    textAlign: "center",
    // position: "relative",
  },
  icon: {
    width: "60px",
    height: "60px",
    color: theme.palette.primary.main,
  },
  text: {},
}));

const HEICPlaceHolder: React.FunctionComponent<HEICPlaceHolderProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CropOriginal className={classes.icon} />
      <Typography className={classes.text}>
        HEIC image files are not supported by your browser
      </Typography>
    </div>
  );
};

export default HEICPlaceHolder;
