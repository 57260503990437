import React, { FC, useContext, useState, useEffect } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { Input, Typography } from "@material-ui/core";
import { useSkipMount } from "../../util/hooks";
import { UseDesignBriefLoadingState, DesignBriefCtx } from "./util";

export interface DesignBriefNotesProps extends BoxProps {
  pm: boolean;
}

const DesignBriefNotes: FC<DesignBriefNotesProps> = ({
  pm,
  ...containerProps
}) => {
  const initialRunComplete = useSkipMount();

  const { meta, api, loadingState } = useContext(DesignBriefCtx);

  const [value, setValue] = useState<string>(meta ? meta.additionalNotes : "");

  useEffect(hanldeMetaUpdate, [meta?.acknowledges]);
  useEffect(handleLocalUpdate, [value]);

  if (loadingState !== UseDesignBriefLoadingState.DONE || (pm && !value)) {
    return null;
  }

  return (
    <Box {...containerProps} display="flex" flexDirection="column">
      <Box pb={2}>
        <Typography>DESIGNER NOTES</Typography>
      </Box>
      <Box px={2}>
        <Input
          disabled={pm}
          multiline
          fullWidth
          value={value}
          onChange={({ currentTarget: { value } }) => setValue(value)}
        />
      </Box>
    </Box>
  );

  function hanldeMetaUpdate(): void {
    if (!initialRunComplete) {
      return;
    }

    setValue(meta?.additionalNotes ?? "");
  }

  function handleLocalUpdate(): void | (() => void) {
    if (!initialRunComplete) {
      return;
    }

    const timeout = setTimeout(async () => {
      await api.updateMetadata("additionalNotes", value);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }
};

export { DesignBriefNotes };
export default DesignBriefNotes;
