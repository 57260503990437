import { useMemo } from "react";
import {
  ALL_IN_RANGE_HIGH_ESTIMATE_MULTIPLIER,
  ALL_IN_RANGE_LOW_ESTIMATE_MULTIPLIER,
} from "../../ConstantValues/AllInBudgetRangeMultipliers";
import { useBudgetElementsPriceEstimate } from "./useBudgetElementsPriceEstimate";

/**
 * Returns a tuple representing the clients all in range (upper and lower values)
 *
 * @param {string} projectId
 * @returns {[number, number]} all in range values
 */

const useAllInRange = (projectId: string) => {
  const priceEstimate = useBudgetElementsPriceEstimate(projectId);
  const allInRange = useMemo(() => {
    if (priceEstimate && priceEstimate[0] && priceEstimate[1]) {
      return [
        Math.floor(priceEstimate[0] * ALL_IN_RANGE_LOW_ESTIMATE_MULTIPLIER),
        Math.floor(priceEstimate[1] * ALL_IN_RANGE_HIGH_ESTIMATE_MULTIPLIER),
      ];
    }

    return [0, 0];
  }, [priceEstimate]);

  return allInRange;
};

export { useAllInRange };
