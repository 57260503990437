import * as React from "react";
import {
  makeStyles,
  Divider,
  Tooltip,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import FileUpload from "../../Components/FileUpload";
import MediaGrid from "../../Components/MediaGrid/index";
import {
  Profile,
  Revision,
  Media,
  Project,
  ProfileProperties,
  ProjectManager,
} from "@yardzen-inc/models";
import ProfileContext from "../../util/ProfileContext";
import AfterSubmission from "./RevisionActions/AfterSubmission";
import AfterSentToClient from "./RevisionActions/AfterSentToClient";
import MessageModal, { DeliverableRevisionIdentifier } from "./MessageModal";
import sendUpdatedRevisionNotification from "../../util/sendUpdatedRevisionNotification";
import GenericSnackBar from "../../Components/GenericSnackBar";
import GeneratePDF from "../../Components/GeneratePDF";
import moment from "moment";
import { UserCtx } from "../../util/UserContext";
import DesignAssigmentControls from "./DesignAssignment/DesignAssignmentControls";
import firebase from "firebase/compat/app";
import { Link } from "react-router-dom";
import RevisionTitleRenamer from "./RevisionTitleRenamer";
import sendDesignDeliveryNotification from "../../util/sendDesignDeliveryNotification";
import GenericConfirm from "../../Components/GenericConfirm";
import CadsDrawer from "./CadsDrawer";
import DesignChecklistDrawer from "./DesignChecklistDrawer";
import isOldBotanical from "./util/isOldBotanical";
import HideRevisionCheckbox from "./HideRevisionCheckbox";
import DSATModal from "../TypeformDetail/DSATModal";
import { GenericOnCallFunctionAlert } from "../../util/genericAlert";
import PMBudgetQASummary from "./PMBudgetQASummary";
import { YZButton } from "@yardzen-inc/react-common";
import { Addy } from "../../util/getAddress";
import isActiveRevision from "./util/isActiveRevision";
import { YardPackages } from "../../Interfaces";
import sortMediaByName from "../../util/sortMediaByName";
import { getAssignedDesigner } from "./util/getAssignedDesigner";
import { getProjectManagerById } from "../../util/firebase/firebaseClient";
import { useGetDesignAssignmentIdFromRevisionId } from "src/util/firebase/functions/getDesignAssignmentIdFromRevisionId";
import { useGetBudgetQADataForSharedMedia } from "../Media/util/useGetBudgetQADataForSharedMedia";
import {
  ELEMENTS,
  MATERIALS,
  MediaInclusion,
  PLANTS,
  basePropertyObject,
} from "../Media/IncludeMediaInSharedLink";
import { createSharedMedia } from "../Media/createSharedMedia";
import { dataURLtoFile } from "./util/dataURLtoFile";
import { BUDGET_ESTIMATE_VARIANT } from "src/ConstantValues/fileuploadConstants";
import { mediaIncludesBudgetEstimateRender } from "./util/budgetEstimateRenderUtils/mediaIncludesBudgetEstimateRender";
import { sendToClientRpc } from "src/common/revision-submit";
import NeedsQACheckbox from "./NeedsQACheckbox";
import genericLiisaNotification from "src/util/genericLiisaNotification";
import makeLiisaLink from "src/util/makeLiisaLink";

interface Props {
  revision: Revision;
  clientRecord: Profile;
  onDelete: () => void;
  conceptualRevisionId?: string;
  finalRevisionId?: string;
  v3RevisionId?: string;
  revisions: Revision[];
  project: Project | null;
}

const fileVisibility = {
  isDesignerVisible: true,
  isClientVisible: false,
  isDesignerContributed: true,
};

export const BUDGET_FILENAME = "budget-estimate.jpg";

function QAMerge(props: Props): React.ReactElement {
  const classes = useStyles();
  const { project, revision, clientRecord } = props;
  const { id: designAssignmentId, isLoading: loadingDesignAssignmentId } =
    useGetDesignAssignmentIdFromRevisionId(revision.id);

  const getBudgetQAData = useGetBudgetQADataForSharedMedia({
    designAssignmentId: designAssignmentId,
    designAssignmentIdIsLoading: loadingDesignAssignmentId,
    profile: clientRecord,
  });
  const [canEdit, setCanEdit] = React.useState<boolean>(false);
  const [showAnnotationSelection, setShowAnnotationSelection] =
    React.useState<boolean>(false);
  const [media, setMedia] = React.useState<Media[]>([]);
  const [cads, setCads] = React.useState<Media[]>([]);
  const userProfile = React.useContext(ProfileContext) as Profile;
  // @ts-ignore
  const isEmployee = userProfile["isEmployee"];
  const designerCanEdit =
    (canEdit || !revision.dateSubmittedForReview) && !revision.hasBeenSent;
  const canDangerouslyEdit = canEdit && isEmployee;
  const [blurbModalOpen, setBlurbModalOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<false | string>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const user = React.useContext(UserCtx);
  const [invalidRevisionModalOpen, setInvalidRevisionModalOpen] =
    React.useState<boolean>(false);
  const [dsatModalOpen, setDsatModalOpen] = React.useState<boolean>(false);
  const [assignedDesigner, setAssignedDesigner] = React.useState<
    string | undefined
  >(undefined);
  const [issuedByPM, setIssuedByPM] = React.useState<
    ProjectManager | undefined
  >(undefined);
  const [sentToClientByPM, setSentToClientByPM] = React.useState<
    ProjectManager | undefined
  >(undefined);

  React.useEffect(() => {
    setCanEdit(false);
  }, [props.revision?.id ?? "none"]);

  React.useEffect(() => {
    setMedia([]);
    getMedia();
    if (
      showAnnotationSelection &&
      (!revision.annotationSlides || !revision.annotationSlides.length)
    ) {
      setShowAnnotationSelection(false);
    }
  }, [revision.id]);

  React.useEffect(() => {
    getAssignedDesigner(revision.id, project?.designerId).then((designer) =>
      setAssignedDesigner(
        designer ? `${designer.firstName} ${designer.lastName}` : undefined
      )
    );
  }, [revision.id, project]);

  React.useEffect(() => {
    if (revision.sentToClientBy) {
      getProjectManagerById(revision.sentToClientBy)
        .then((doc) => {
          if (doc.exists && doc.data()) {
            return setSentToClientByPM(
              new ProjectManager({
                ...doc.data(),
                id: doc.id,
              } as any)
            );
          }
        })
        .catch(
          (e) =>
            `Error fetching projectManager ID ${revision.sentToClientBy}: ${e}`
        );
    }
    setSentToClientByPM(undefined);
  }, [revision.sentToClientBy]);

  React.useEffect(() => {
    if (revision.issuedBy) {
      getProjectManagerById(revision.issuedBy)
        .then((doc) => {
          if (doc.exists && doc.data()) {
            return setIssuedByPM(
              new ProjectManager({
                ...doc.data(),
                id: doc.id,
              } as any)
            );
          }
        })
        .catch(
          (e) => `Error fetching projectManager ID ${revision.issuedBy}: ${e}`
        );
    }
    setIssuedByPM(undefined);
  }, [revision.issuedBy]);

  const getAddress = (): Addy => {
    return {
      city: props.clientRecord.city || "",
      state: props.clientRecord.state || "",
      zip: props.clientRecord.zip || "",
      street: props.clientRecord.street || "",
    };
  };

  if (!props.revision) {
    return <div></div>;
  }

  const selectSlidesMediaGridProps = getSelectedMediaGridProps();
  const annotationSelection = getAnnotationSelectionMedia();

  async function uploadBudgetEstimateImage(budgetEstimateImageSrc: string) {
    const file = dataURLtoFile(budgetEstimateImageSrc, BUDGET_FILENAME);

    const media = new Media({
      file: file,
      userId: props.clientRecord.id,
      visibility: fileVisibility ?? {},
    });

    await media.store(
      undefined,
      props.revision.id,
      getFileTag(),
      undefined,
      BUDGET_ESTIMATE_VARIANT
    );

    await onUploadComplete();
  }

  return (
    <div className={classes.root}>
      <DSATModal
        allowReset
        open={dsatModalOpen}
        clientRecord={props.clientRecord as ProfileProperties}
        revisionId={revision.id}
        project={props.project as Project}
        onDsatLoad={() => {}}
        onDsatOpened={() => {}}
        onClose={() => setDsatModalOpen(false)}
      />
      <div>
        {isEmployee && (
          <Box width={"100%"} p={1} mb={2}>
            {project ? (
              <>
                <DesignAssigmentControls
                  selectedRevision={revision}
                  clientRecord={props.clientRecord}
                  project={project}
                  revisions={props.revisions}
                />
                <Divider />
              </>
            ) : (
              <CircularProgress></CircularProgress>
            )}
          </Box>
        )}
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <Tooltip title="Makes a new revision and clones all media into it">
              <YZButton
                style={{ height: "min-content" }}
                onClick={() => cloneRevision(revision)}
              >
                Clone into new revision
              </YZButton>
            </Tooltip>
          </Box>
          <Box pr={1}>
            <YZButton onClick={() => setDsatModalOpen(true)} variant="outlined">
              DSAT
            </YZButton>
          </Box>
          <Box pr={1}>
            <Tooltip title="Opens project design brief">
              <Link
                target="_blank"
                to={`/design-brief/${props.clientRecord["id"]}/${revision.id}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <YZButton variant="outlined">Open Brief</YZButton>
              </Link>
            </Tooltip>
          </Box>
          <Box pr={1}>
            <CadsDrawer
              userId={props.clientRecord.id}
              revisionId={revision.id}
            />
          </Box>
          <Box pr={1}>
            <DesignChecklistDrawer
              userId={props.clientRecord.id}
              revisionId={revision.id}
            />
          </Box>
          <Box pr={1}>
            <Tooltip title="Generates a PDF of images in their current order">
              <GeneratePDF
                fileName={revision.id}
                clientRecord={props.clientRecord}
                media={media}
                revision={revision}
              />
            </Tooltip>
          </Box>
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0 1.25rem",
          }}
        >
          <br />
          <Typography>
            {`Assigned designer: ${assignedDesigner ?? "No Designer Assigned"}`}
          </Typography>
          <Typography>
            {issuedByPM &&
              `Revision created by: ${issuedByPM.firstName} ${issuedByPM.lastName}`}
          </Typography>
          <Typography>
            {sentToClientByPM &&
              `Sent to client by: ${sentToClientByPM.firstName} ${sentToClientByPM.lastName}`}
          </Typography>
          <Typography>
            {revision.dateCreated &&
              `Created: ${moment(revision.dateCreated).format(
                "MM/DD/YY hh:mma"
              )}`}
          </Typography>
          <Typography>
            {revision.dateSubmittedForReview &&
              `Submitted: ${moment(
                revision.dateSubmittedForReview as string
              ).format("MM/DD/YY hh:mma")}`}
          </Typography>
          <Typography>
            {revision.dateSentToClient &&
              `Sent to client: ${moment(
                revision.dateSentToClient as string
              ).format("MM/DD/YY hh:mma")}`}
          </Typography>
          <Typography>
            {`Originally sent to client: ${
              revision.dateOriginallySentToClient
                ? moment(revision.dateOriginallySentToClient).format(
                    "MM/DD/YY hh:mma"
                  )
                : "unknown"
            }`}
          </Typography>
          {revision.dateAnnotated && (
            <>
              <Typography
                className={
                  revision.dateAnnotated ? classes.greenSpan : classes.redSpan
                }
              >
                {`Client Has Annotated: ${
                  revision.dateAnnotated ? "Yes" : "No"
                }`}
              </Typography>
              <Typography
                className={
                  revision.feedbackSubmittedAt
                    ? classes.greenSpan
                    : classes.redSpan
                }
              >
                {`Client Has Clicked Submit Feedback: ${
                  revision.feedbackSubmittedAt ? "Yes" : "No"
                }`}
              </Typography>
            </>
          )}
          <HideRevisionCheckbox revision={revision} />
          {project && (
            <NeedsQACheckbox project={project} product={clientRecord.package} />
          )}
          <RevisionTitleRenamer
            selectedRevision={props.revision}
            disabled={!isEmployee}
          />
        </div>
      </div>

      <div className={classes.actions}>{renderActions()}</div>
      <Divider variant="middle" />
      <div className={classes.mediaGrid}>
        <MediaGrid
          onSortEnd={
            !!designerCanEdit || (isEmployee && canEdit) ? onSortEnd : undefined
          }
          revisionId={revision.id}
          userId={props.clientRecord.id}
          mediaToIncludeInDownloadAll={cads}
          fileTag="deliverable"
          clientRecord={props.clientRecord}
          annotatedMediaCount={annotationSelection.length}
          permissions={{
            media: {
              access:
                (!!isEmployee && canEdit) || designerCanEdit
                  ? "update"
                  : "not update",
            },
          }}
          onDeleteComplete={onDelete}
          onUploadComplete={() => {
            setMedia([...media]);
          }}
          media={showAnnotationSelection ? annotationSelection : media}
          {...selectSlidesMediaGridProps}
        />

        {(designerCanEdit || canDangerouslyEdit) && (
          <FileUpload
            accept={["image/*", ".pdf"]}
            fileVisibility={fileVisibility}
            clientUserId={props.clientRecord.id}
            onUploadComplete={onUploadComplete}
            revisionId={props.revision.id}
            fileTag={getFileTag()}
          />
        )}

        {!!blurbModalOpen && (
          <MessageModal
            // @ts-ignore
            sendToClient={sendToClient}
            open={blurbModalOpen}
            onClose={() => setBlurbModalOpen(false)}
            conceptualRevisionId={props.conceptualRevisionId}
            finalRevisionId={props.finalRevisionId}
            v3RevisionId={props.v3RevisionId}
            clientRecord={props.clientRecord}
            currentRevision={revision}
            defaultSettings={{
              notifyClient: true,
            }}
          />
        )}

        <GenericSnackBar
          orientation={{ vertical: "bottom", horizontal: "left" }}
          onClose={() => setSuccess(false)}
          in={!!success}
          variant="success"
          message={"Revision successfully sent!"}
        />

        {!!error && (
          <GenericSnackBar
            orientation={{ vertical: "bottom", horizontal: "left" }}
            onClose={() => setError(false)}
            variant="error"
            message={error}
          />
        )}
        {!!invalidRevisionModalOpen && (
          <GenericConfirm
            open={invalidRevisionModalOpen}
            onClose={() => setInvalidRevisionModalOpen(false)}
            onSubmit={() => setInvalidRevisionModalOpen(false)}
            body="Revision must include at least one slide, in .jpg file
             format, in order to be delivered to the client

            The Client app currently assumes that all revision deliverables
            contain at least one rendering slide in addition to any supplemental
            files. If you need to deliver CAD files or other supplemental materials
            without renders, we suggest adding a single “cover sheet” or dummy slide,
            in .jpg format."
            title="Revision Invalid! Must resolve"
            buttonText="Cancel"
            checkLabel="I understand"
          />
        )}
      </div>
      <PMBudgetQASummary
        profile={props.clientRecord}
        address={getAddress()}
        revisionId={props.revision?.id}
        designAssignmentId={props.project?.currentDesignAssignmentId || ""}
        projectId={props.project?.id || ""}
      />
    </div>
  );

  function isValidRevision() {
    return media.every((media: Media) => media.fileType.includes("image"));
  }

  function getFileTag(): any {
    return "deliverable";
  }

  async function onUploadComplete() {
    if (!revision) {
      return;
    }

    const deliverables = revision.deliverables || [];

    const allMedia = await revision.getAllMedia(true);
    const newMedia = allMedia.filter((m: any) => !deliverables.includes(m.id));
    const sortedNewMedia = sortMediaByName(newMedia);

    const newIDs = sortedNewMedia.map((m: any) => m.id);
    revision.deliverables = [...(revision.deliverables || []), ...newIDs];

    await revision.save();
    await getMedia();
  }

  async function getMedia() {
    const fetchedMedia = await revision.getMediaForQA();
    setMedia(
      fetchedMedia.sort((a, b) => {
        if (!a && !b) {
          return 0;
        } else if (!(typeof a.sortedIndex === "number")) {
          return -1;
        } else if (!(typeof b.sortedIndex === "number")) {
          return 1;
        }

        return a.sortedIndex - b.sortedIndex;
      })
    );

    await getCads();
  }

  function getAnnotationSelectionMedia(): Media[] {
    const { annotationSlides } = revision;

    if (!annotationSlides || !annotationSlides.length) {
      return [];
    }

    return media.filter((m) => annotationSlides.includes(m.id));
  }

  async function getCads() {
    const cads = [
      ...(await Media.get(
        props.clientRecord.id,
        null,
        "cad-layout",
        props.revision.id
      )),
      ...(await Media.get(
        props.clientRecord.id,
        null,
        "cad-landscape",
        props.revision.id
      )),
      ...(await Media.get(
        props.clientRecord.id,
        null,
        "cad-legend",
        props.revision.id
      )),
      ...(await Media.get(
        props.clientRecord.id,
        null,
        "cad-dwg",
        props.revision.id
      )),
    ];

    setCads(cads);
  }

  async function sendRevisionEditedNotification() {
    const body = JSON.stringify({
      clientName:
        props.clientRecord.firstName + " " + props.clientRecord.lastName,
      userName: (user as firebase.User)["displayName"],
    });

    return fetch("https://hooks.zapier.com/hooks/catch/3530042/otepmib/", {
      method: "POST",
      body,
    });
  }

  function toggleShowAnnotationSelection(): void {
    setShowAnnotationSelection(!showAnnotationSelection);
  }

  async function selectForAnnotation(ids: string[]): Promise<void> {
    const { revision } = props;
    revision.annotationSlides = getSortedshowAnnotationSelection(ids);

    await revision.save();
  }

  function getSortedshowAnnotationSelection(ids: string[]): string[] {
    const {
      revision: { sortedDeliverables },
    } = props;

    if (sortedDeliverables.length) {
      return ids;
    }

    return sortedDeliverables.filter(_filterDelivsById);

    function _filterDelivsById(id: string) {
      return ids.includes(id);
    }
  }

  async function handleSort(sortedMedia: Media[]) {
    await firebase
      .firestore()
      .runTransaction((t) => {
        const mediaRefs = sortedMedia.map((m) =>
          firebase.firestore().collection("media").doc(m.id)
        );

        return Promise.all(
          mediaRefs.map((ref, index) => {
            return new Promise<void>((resolve) => {
              t.get(ref).then((doc) => {
                if (!doc.exists) throw "Document does not exist!";
                t.update(ref, { sortedIndex: index });
                resolve();
              });
            });
          })
        );
      })
      .then(() => {
        console.log("SUCCESS");
      })
      .catch((e) => {
        console.error("sort transaction error: ", e);
        setError("Sort failed! Please try again or contact engineering");
      });
  }

  async function onSortEnd(sortedMedia: Media[]) {
    // Prevents unintended updates to live revisions
    debugger;
    if (!isActiveRevision(revision, clientRecord)) {
      // Update deliverables media sortedIndex
      await handleSort(sortedMedia);
    }

    setMedia(sortedMedia);
  }

  async function onDelete(mediaToDelete: Media[]) {
    if (revision.hasBeenSent && !!revision.deliverables) {
      const idsToDelete = mediaToDelete.map((m) => m.id);

      revision.deliverables = revision.deliverables.filter(
        (id) => !idsToDelete.includes(id)
      );
      revision.save();
    }

    revision.save();
    await getMedia();
  }

  function renderActions() {
    if (!revision.dateSubmittedForReview && !revision.hasBeenSent) {
      return null;
    }
    if (!!revision.dateSubmittedForReview && !revision.hasBeenSent) {
      return (
        <AfterSubmission
          revision={revision}
          isEmployee={isEmployee}
          canEdit={canEdit}
          setCanEdit={setCanEdit}
          conceptualRevisionId={props.conceptualRevisionId}
          finalRevisionId={props.finalRevisionId}
          clientRecord={props.clientRecord}
          projectId={props.project?.id || ""}
          yardDifficultyRating={props.project?.yardDifficultyRating || null}
          setBlurbModalOpen={() => handleMessageModal()}
          sendRevisionEditedNoti={sendRevisionEditedNotification}
          uploadBudgetEstimateImage={uploadBudgetEstimateImage}
          mediaIncludesBudgetEstimateRender={mediaIncludesBudgetEstimateRender(
            media
          )}
        />
      );
    }

    if (!!revision.hasBeenSent) {
      return (
        <AfterSentToClient
          canEdit={canDangerouslyEdit}
          setCanEdit={setCanEdit}
          isEmployee={isEmployee}
          revision={revision}
          conceptualRevisionId={props.conceptualRevisionId}
          finalRevisionId={props.finalRevisionId}
          v3RevisionId={props.v3RevisionId}
          clientRecord={props.clientRecord}
          setBlurbModalOpen={() => handleMessageModal()}
        />
      );
    }

    return (
      <>
        <Typography>
          Uhoh! Theres an issue with the design state filtering. We've logged
          the revision object to the console. Heres some diag info on whats
          going on:
        </Typography>
        <Typography>
          !revision.dateSubmittedForReview && !revision.hasBeenSent:{" "}
          {!revision.dateSubmittedForReview && !revision.hasBeenSent}
          <br />
          !!revision.dateSubmittedForReview && !revision.hasBeenSent:{" "}
          {!!revision.dateSubmittedForReview && !revision.hasBeenSent}
          <br />
          !!revision.hasBeenSent: {!!revision.hasBeenSent}
        </Typography>
      </>
    );
  }

  function getSelectedMediaGridProps() {
    return {
      showAnnotationSelection: showAnnotationSelection,
      toggleAnnotationSelection:
        revision.annotationSlides && revision.annotationSlides.length
          ? toggleShowAnnotationSelection
          : undefined,
      selectForAnnotation: selectForAnnotation,
      revision,
    };
  }

  async function cloneRevision(toBeCloned: Revision) {
    const newRevision = await Revision.create(props.clientRecord.id);
    const oldMedia = await toBeCloned.getMediaForQA();
    const newMedia = (oldMedia as Media[]).map((med) => {
      med.revisionId = newRevision.id;
      // @ts-ignore
      med["isCloned"] = true;
      return med;
    });

    const proms = newMedia.map((m) => {
      return newMediaProm(m);
    });

    const newMediaRecIds = await Promise.all(proms);

    newRevision.deliverables = newMediaRecIds;
    newRevision.allowAnnotation = false;
    newRevision.annotatedAt = null;
    newRevision.issuedBy = user ? user?.uid : null;

    await newRevision.save();
    return newRevision;
  }

  async function createShareLink(revisionId: string) {
    const inclusionObject: MediaInclusion = {
      profileId: clientRecord.id,
      revision: {
        id: revisionId,
        includedCads: ["cad-layout", "cad-landscape", "cad-legend"],
        includeGeneratedPDF: true,
      },
      property: { ...basePropertyObject },
      pdfList: {
        [PLANTS]: true,
        [MATERIALS]: true,
        [ELEMENTS]: true,
      },
    };

    return createSharedMedia(inclusionObject, getBudgetQAData);
  }

  async function sendToClient(
    sendToDesigns: boolean,
    deliverableType: DeliverableRevisionIdentifier,
    notifyClient: boolean,
    allowAnnotations: boolean,
    blurb?: string
  ): Promise<any> {
    if (canEdit) {
      setCanEdit(false);
    }

    // this pulls a ton of data, do in background
    const sharedMediaPromise = createShareLink(revision.id);

    try {
      const res = await sendToClientRpc(
        user,
        revision,
        media,
        deliverableType,
        allowAnnotations,
        blurb
      );
      if (!res.success) {
        setError(res.message);
        setBlurbModalOpen(false);
        return;
      }
    } catch (error) {
      console.error(error);
      setBlurbModalOpen(false);
      return;
    }

    // Notifications
    if (sendToDesigns) {
      const {
        revision: { id: revisionId },
        clientRecord: { id: clientId },
      } = props;

      try {
        await sendDesignDeliveryNotification({ revisionId, clientId });
      } catch (error) {
        console.error(error);
        console.error("FAILED TO SEND CLIENT BUILD INTRO");
        setError(
          "FAILED TO SEND CLIENT BUILD INTRO, please send email mainually and contact engineering"
        );
      }
    }

    try {
      await sharedMediaPromise;
    } catch (error) {
      console.error(error);
      setError(
        "Failed to create share link. Please try to recreate on the media tab -> shared media"
      );
    }

    if (!notifyClient) {
      const userDisplay =
        (user as firebase.User)["displayName"] ??
        (user as firebase.User)["email"];
      await genericLiisaNotification({
        message: `Client ${clientRecord.firstName} ${
          clientRecord.lastName
        } has been sent deliverables for revision ${
          revision.id
        } - ${deliverableType} by ${userDisplay}, but was *not notified via email.*
        ${makeLiisaLink(clientRecord.id)}/deliverables`,
        icon: ":no_entry_sign:",
      });

      setSuccess(true);
      return;
    }

    if (
      props.conceptualRevisionId === revision.id ||
      props.finalRevisionId === revision.id ||
      props.v3RevisionId === revision.id
    ) {
      try {
        return await sendUpdatedRevisionNotification(props.clientRecord.id);
      } catch (error) {
        GenericOnCallFunctionAlert(
          "pingClientForUpdatedRevision",
          // @ts-ignore
          error.message
        );
        return;
      }
    }

    if (
      isOldBotanical(
        clientRecord?.package as YardPackages,
        clientRecord?.createdAt as string
      )
    ) {
      const sendConceptual = firebase
        .functions()
        .httpsCallable("sendBotanicalRevisionEmail");
      return sendConceptual(props.clientRecord)
        .then(() => {
          setSuccess(true);
        })
        .catch((error) => {
          GenericOnCallFunctionAlert(
            "sendBotanicalRevisionEmail",
            error.message
          );
          setError(
            "Error sending email! Please try again or send email manually"
          );
        });
    }

    if (deliverableType === "conceptual") {
      const sendConceptual = firebase
        .functions()
        .httpsCallable("sendConceptualRevisionEmail");
      return sendConceptual(props.clientRecord)
        .then(() => {
          setSuccess(true);
        })
        .catch((error) => {
          GenericOnCallFunctionAlert(
            "sendConceptualRevisionEmail",
            error.message
          );
          setError("Please send email manually and contact engineering");
        });
    } else {
      const sendFinal = firebase
        .functions()
        .httpsCallable("sendFinalRevisionEmail");
      return sendFinal(props.clientRecord)
        .then(() => {
          setSuccess(true);
        })
        .catch((error) => {
          GenericOnCallFunctionAlert("sendFinalRevisionEmail", error.message);
          setError("Please send email manually and contact engineering");
        });
    }
  }

  function handleMessageModal() {
    if (!isValidRevision()) {
      setInvalidRevisionModalOpen(true);
      return;
    }
    setBlurbModalOpen(!blurbModalOpen);
  }

  function newMediaProm(mediaToClone: Media): Promise<string> {
    return new Promise((resolve) => {
      firebase
        .firestore()
        .collection("media")
        .add(mediaToClone)
        .then((resp) => {
          resolve(resp.id);
        })
        .catch((err) => {
          window.newrelic.noticeError(err);
        });
    });
  }
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "100%",
  },
  mediaGrid: {
    margin: "0 auto",
    width: "100%",
  },
  submitButton: {
    margin: "1rem auto 1.5rem auto",
    textAlign: "center",
    maxWidth: "50%",
  },
  actions: {
    margin: "1.5rem",
    display: "flex",
  },
  redSpan: {
    color: "red",
  },
  greenSpan: {
    color: "green",
  },
});

export default QAMerge;
