import React from "react";
import AssetLibraryCreateItem from "../Shared/AssetLibraryItemCreate";
import AddNewElement from "./AddNewElement";

function ElementsLibraryCreate() {
  return (
    <AssetLibraryCreateItem
      goBackLocation="/elements-library"
      addNewAssetComponent={(assetProps) => (
        <AddNewElement {...assetProps} onSave={assetProps.onSave} />
      )}
    />
  );
}

export default ElementsLibraryCreate;
