import React, { FC } from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import { DialogContent } from "@material-ui/core";
import { DesignBriefEntry } from "./util";
import { DesignBriefEntryEditForm } from "./DesignBriefEntryEditForm";

export interface DesignBriefEntryEditModalProps extends DialogProps {
  entry: DesignBriefEntry<any>;
  handleUpdate: (updatedEntry: DesignBriefEntry<any>) => any;
}

const DesignBriefEntryEditModal: FC<DesignBriefEntryEditModalProps> = ({
  entry,
  handleUpdate,
  ...dialogProps
}) => {
  return (
    <Dialog {...dialogProps}>
      <DialogContent>
        <DesignBriefEntryEditForm
          entry={entry}
          update={handleUpdate}
          onClose={dialogProps.onClose as () => void}
        />
      </DialogContent>
    </Dialog>
  );
};

export { DesignBriefEntryEditModal };
export default DesignBriefEntryEditModal;
