import * as React from "react";
import { LinearProgress, Button } from "@material-ui/core";
import AnnotatorTab from "../../SelfAssignPage/AnnotatorTab";
import { UserCtx } from "../../util/UserContext";
import { ProfileCtx } from "../../util/ProfileContext";
import { Profile } from "@yardzen-inc/models";
import PingClientModal from "./PingClientModal";

interface Props {
  clientRecord: Profile;
}

export default (props: Props) => {
  const user = React.useContext(UserCtx);
  const profile = React.useContext(ProfileCtx);

  const [pingClientModalOpen, setPingClientModalOpen] =
    React.useState<boolean>(false);

  if (!user || !profile) return <LinearProgress variant="indeterminate" />;

  return (
    <>
      <PingClientModal
        clientId={props.clientRecord.id}
        open={pingClientModalOpen}
        onClose={() => setPingClientModalOpen(false)}
      />
      <AnnotatorTab
        user={user}
        profile={profile as Profile}
        clientRecord={props.clientRecord}
        pm
        buttons={[
          <Button
            color="primary"
            variant="contained"
            onClick={() => setPingClientModalOpen(true)}
          >
            Ping client
          </Button>,
        ]}
      />
    </>
  );
};
