import * as React from "react";
import { Profile } from "@yardzen-inc/models";
import NewMediaGrid from "../../Components/NewMediaGrid";
import { UserCtx } from "../../util/UserContext";
import { LinearProgress } from "@material-ui/core";

interface Props {
  clientRecord: Profile;
}

export default (props: Props) => {
  const { clientRecord } = props;
  const user = React.useContext(UserCtx);

  if (!user || !clientRecord) {
    return <LinearProgress variant="indeterminate" />;
  }

  return (
    <>
      <NewMediaGrid
        cardType="annotator"
        userId={clientRecord.id}
        user={user}
        profile={clientRecord}
        media={["property"]}
      />
    </>
  );
};
