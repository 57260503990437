import * as React from "react";
import { Box } from "@material-ui/core";
import { Media, Profile } from "@yardzen-inc/models";
import downloadAndZip from "../../util/downloadAndZip";
import SingleFileUpload from "../../SelfAssignPage/SingleFileUpload";
import { YZButton } from "@yardzen-inc/react-common";

interface Props {
  clientRecord: Profile;
}

function FunctionAndFlow({ clientRecord }: Props) {
  const [dockx, setDocx] = React.useState<Media | null>(null);

  React.useEffect(() => {
    (async function () {
      const ffsai = await Media.get(clientRecord.id, null, "brief-raw");
      if (!ffsai.length) return;
      setDocx(ffsai[0]);
    })();
  }, [clientRecord]);

  return (
    <>
      <div
        style={{
          float: "right",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <YZButton
          variant="contained"
          disabled={!dockx}
          onClick={() => downloadAndZip([dockx as any], "design_brief_docx")}
        >
          {dockx
            ? "Download Design Brief Document File"
            : "No Document File Found"}
        </YZButton>
      </div>
      <Box p={1} width={"40%"} minWidth={"fit-content"}>
        <SingleFileUpload
          fileTag="design-brief-raw"
          title="Design brief docx"
          userId={clientRecord.id}
          uploadMessage={".docx upload successful"}
        />
      </Box>
    </>
  );
}

export default FunctionAndFlow;
