import React, { FC, useMemo } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { useProjectsFromUserId } from "../util/hooks";
import {
  CircularProgress,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";

export interface UploadLimitModFieldProps extends BoxProps {
  userId: string;
}

const UploadLimitModField: FC<UploadLimitModFieldProps> = ({
  userId,
  ...containerProps
}) => {
  const projects = useProjectsFromUserId(userId);

  const numberOfProjects = useMemo(
    () => (projects ? projects.length : 0),
    [projects]
  );

  if (!projects) {
    return <CircularProgress />;
  }

  if (!numberOfProjects) {
    return (
      <Box {...containerProps}>
        <Typography variant="caption">no projects found for client</Typography>
      </Box>
    );
  }

  const proj = projects[0];

  return (
    <Box p={1} {...containerProps}>
      <FormControlLabel
        label="Upload limit multiplier"
        onChange={async ({ target: { value } }: any) => {
          proj.uploadLimitModifier = value ?? 1;
          await proj.save();
        }}
        value={
          proj.uploadLimitModifier ? proj.uploadLimitModifier.toString() : 1
        }
        control={
          <Select>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="1.5">1.5</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="2.5">2.5</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
          </Select>
        }
      />
    </Box>
  );
};

export { UploadLimitModField };
export default UploadLimitModField;
