import * as React from "react";
import {
  Typography,
  makeStyles,
  Paper,
  Divider,
  // useMediaQuery,
} from "@material-ui/core";

interface Props {
  designProfile: object;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem",
    marginBottom: "1.75rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      maxWidth: "100vw",
      margin: "0",
    },
    width: "fit-content ",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "0.75rem",
  },
  content: {
    // marginLeft: "2.25rem",
  },
  subContent: {
    margin: "0.5rem",
    marginTop: 0,
  },
}));

function DesignProfileInfo(props: Props) {
  const { designProfile } = props;

  const classes = useStyles();

  // const smDown = useMediaQuery(theme =>
  //   (theme as any).breakpoints.down("sm")
  // );

  return (
    <Paper elevation={6} className={classes.root}>
      <Typography variant="h5">Design Profile Information</Typography>
      <Divider />
      <div className={classes.flexContainer}>
        <div className={classes.content}>
          <Typography variant="h6">Client Info: </Typography>
          {/* <Divider /> */}
          <div className={classes.subContent}>
            <Typography>
              {
                // @ts-ignore
                designProfile["firstName"]
              }
            </Typography>
            <Typography>
              {
                // @ts-ignore
                designProfile["lastName"]
              }
            </Typography>
            {
              // @ts-ignore
              designProfile["phone"] ? (
                <Typography>
                  {
                    // @ts-ignore
                    designProfile["phone"]
                  }
                </Typography>
              ) : null
            }
          </div>
        </div>
        <div className={classes.content}>
          <Typography variant="h6">Address: </Typography>
          {/* <Divider /> */}
          <div className={classes.subContent}>
            <Typography>
              {
                // @ts-ignore
                designProfile["street"]
              }
            </Typography>
            {
              // @ts-ignore
              designProfile["city"] && designProfile["state"] ? (
                <Typography>
                  {
                    // @ts-ignore
                    designProfile["city"]
                  }
                  ,{" "}
                  {
                    // @ts-ignore
                    designProfile["state"]
                  }
                </Typography>
              ) : null
            }
            <Typography>
              {
                // @ts-ignore
                designProfile["zip"]
              }
            </Typography>
          </div>
        </div>
        {/* <div className={classes.content}>
          <Typography>Budget: </Typography>
          <Typography variant="h5">{designProfile["budget"]}</Typography>
        </div>
        <div className={classes.content}>
          <Typography>Design Request: </Typography>
          <Typography>{designProfile["designingFor"]}</Typography>
          <Typography>{designProfile["interestedIn"]}</Typography>
          <Typography>{designProfile["newOrExistingConstruction"]}</Typography>
        </div> */}
      </div>
    </Paper>
  );
}

export default DesignProfileInfo;
