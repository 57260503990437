import { YardDifficultyRating } from "@yardzen-inc/models";
import React, { createContext, FC, ReactNode, useMemo, useState } from "react";

export interface ICDMPageContext {
  projectDifficulty: YardDifficultyRating | null;
  setProjectDifficulty: (projectDifficulty: YardDifficultyRating) => void;
  estimateEducationNote: string;
  setEstimateEducationNote: (estimateEducationNote: string) => void;
}

export interface ICDMContextProviderProps {
  children: ReactNode;
}

export const initialEstimateEducationNote =
  "We noticed that your yard has (slope, limited access, heavy tree cover" +
  ", small work area, large property size, etc.) Thank you!";

// We must create the context with an initial value. We'll set the
// value for the provider later on in our component.
export const CDMPageContext = createContext<ICDMPageContext>({
  projectDifficulty: null,
  setProjectDifficulty: (projectDifficulty: YardDifficultyRating) => {},
  estimateEducationNote: initialEstimateEducationNote,
  setEstimateEducationNote: (estimateEducationNote: string) => {},
});

export const CDMContextProvider: FC<ICDMContextProviderProps> = ({
  children,
}) => {
  const [projectDifficulty, setProjectDifficultyState] =
    useState<YardDifficultyRating | null>(null);
  const [estimateEducationNote, setEstimateEducationNoteState] =
    useState<string>(initialEstimateEducationNote);

  const setProjectDifficulty = (newProjectDifficulty: YardDifficultyRating) =>
    setProjectDifficultyState(newProjectDifficulty);

  const setEstimateEducationNote = (newEstimateEducationNote: string) =>
    setEstimateEducationNoteState(newEstimateEducationNote);

  const contextValue = useMemo(
    () => ({
      projectDifficulty,
      setProjectDifficulty,
      estimateEducationNote,
      setEstimateEducationNote,
    }),
    [
      projectDifficulty,
      setProjectDifficulty,
      estimateEducationNote,
      setEstimateEducationNote,
    ]
  );

  return (
    <CDMPageContext.Provider value={contextValue}>
      {children}
    </CDMPageContext.Provider>
  );
};
