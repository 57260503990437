import * as React from "react";
import { LinearProgress } from "@material-ui/core";
import { PageComponentProps } from "./CurrentJobPageMap";
import ImageGrid from "./ImageGrid";
import { Assignment, Media } from "@yardzen-inc/models";

const imageR = /(jpg|png|gif|svg|jpeg|svg\+xml)$/i;

export default (props: PageComponentProps) => {
  const [media, setMedia] = React.useState<Media[] | null>(null);

  React.useEffect(() => {
    (async function () {
      setMedia(await getMedia());
    })();
  }, []);

  if (!media) return <LinearProgress variant="indeterminate" />;

  return <ImageGrid media={media as any} setLoading={() => null}></ImageGrid>;

  async function getMedia(): Promise<Media[]> {
    // const { assignment } = props;

    const media = await Promise.all(
      (
        await Assignment.getAllMediaIdsByFileTags(
          ["inspiration", "property"],
          props.profile.id
        )
      ).map(Media.fetchSingle)
    );

    return media.filter(
      // @ts-ignore
      (m: Media) => imageR.test(m.fileType) && m.tag !== "plans"
    ) as Media[];
  }
};
