import * as React from "react";
import {
  Input,
  Typography,
  FormControl,
  Button,
  InputLabel,
} from "@material-ui/core";
import { Redirect } from "react-router";
import firebase from "firebase/compat/app";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import "url-search-params-polyfill";
import genericAlert from "./util/genericAlert";

export interface Props {}

export interface State {
  loading: boolean;
  firstName: string;
  lastName: string;
  email: string;
  designerPaymentEmail: string;
  password: string;
  error: string;
  errorText: string;
}

export default class AccountRegistration extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      firstName: "",
      lastName: "",
      email: "",
      designerPaymentEmail: "",
      password: "",
      error: "",
      errorText: "",
    };

    this.onFirstNameChange = this.onFirstNameChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPaymentEmailChange = this.onPaymentEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onCreateSubmit = this.onCreateSubmit.bind(this);
  }

  public render() {
    const loading = this.state.loading;

    if (this.state.isLoggedIn === true) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    return (
      <main className="main-login">
        <div className="login-paper">
          <form onSubmit={this.onCreateSubmit}>
            <Typography component="h1" variant="h5">
              Create a Yardzen Account
            </Typography>
            <Typography paragraph>
              Or <RouterLink to="/login">Log In</RouterLink>
            </Typography>
            <FormControl
              margin="normal"
              required
              fullWidth
              error={this.state.error === "firstName" ? true : false}
            >
              <InputLabel htmlFor="firstName">First Name</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                inputProps={{ autoCorrect: "off", spellCheck: false }}
                placeholder="First Name"
                autoFocus
                onChange={this.onFirstNameChange}
              />
              {this.state.error === "firstName" ? (
                <Typography variant="caption" color="error">
                  Please provide a first name
                </Typography>
              ) : (
                <div></div>
              )}
            </FormControl>
            <FormControl
              margin="normal"
              required
              fullWidth
              error={this.state.error === "lastName" ? true : false}
            >
              <InputLabel htmlFor="lastName">Last Name</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                inputProps={{ autoCorrect: "off", spellCheck: false }}
                placeholder="Last Name"
                onChange={this.onLastNameChange}
              />
              {this.state.error === "lastName" ? (
                <Typography variant="caption" color="error">
                  Invalid email address
                </Typography>
              ) : (
                <div></div>
              )}
            </FormControl>
            <FormControl
              margin="normal"
              required
              fullWidth
              error={this.state.error === "email" ? true : false}
            >
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                id="email"
                name="email"
                inputProps={{
                  type: "email",
                  autoCapitalize: "off",
                  autoCorrect: "off",
                  spellCheck: false,
                }}
                placeholder="Email Address"
                value={this.state.email}
                onChange={this.onEmailChange}
              />
              {this.state.error === "email" ? (
                <Typography variant="caption" color="error">
                  {this.state.errorText}
                </Typography>
              ) : (
                <div></div>
              )}
            </FormControl>
            <FormControl
              margin="normal"
              required
              fullWidth
              error={this.state.error === "email" ? true : false}
            >
              <InputLabel htmlFor="designerPaymentEmail">
                Payment Email Address
              </InputLabel>
              <Input
                id="designerPaymentEmail"
                name="designerPaymentEmail"
                inputProps={{
                  type: "email",
                  autoCapitalize: "off",
                  autoCorrect: "off",
                  spellCheck: false,
                }}
                placeholder="Payment Email Address"
                value={this.state.designerPaymentEmail}
                onChange={this.onPaymentEmailChange}
              />
              <Typography variant="caption">
                The email associated with your Paypal or Payoneer
              </Typography>

              {/* This displays all email related errors. If either the
              payment email or account email is used by another account,
              the error message will show for both fields. */}

              {this.state.error === "email" ? (
                <Typography variant="caption" color="error">
                  {this.state.errorText}
                </Typography>
              ) : (
                <div></div>
              )}
            </FormControl>

            <FormControl
              margin="normal"
              required
              fullWidth
              error={this.state.error === "password" ? true : false}
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                onChange={this.onPasswordChange}
              />
              {this.state.error === "password" ? (
                <Typography variant="caption" color="error">
                  {this.state.errorText}
                </Typography>
              ) : (
                <div></div>
              )}
            </FormControl>
            <div>
              <Button
                type="submit"
                value="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
              >
                {!loading
                  ? "Create Modeler/Designer Account"
                  : "Creating Account..."}
              </Button>
            </div>
            <div className="spacer"></div>
          </form>
        </div>
      </main>
    );
  }

  public componentWillMount() {
    this.prePopulateEmailFromUrl();
  }

  protected prePopulateEmailFromUrl() {
    var params = new URLSearchParams(document.location.search.substring(1));

    if (!params.has("email")) {
      return;
    }

    let email = params.get("email") as string;

    if (!email.trim() || email.indexOf("@") === -1) {
      return;
    }

    this.setState({ email: email });
  }

  protected onFirstNameChange(e: any) {
    this.setState({
      firstName: e.target.value,
      error: "",
    });
  }

  protected onLastNameChange(e: any) {
    this.setState({
      lastName: e.target.value,
      error: "",
    });
  }

  protected onEmailChange(e: any) {
    this.setState({
      email: e.target.value,
      error: "",
    });
  }

  protected onPaymentEmailChange(e: any) {
    this.setState({
      designerPaymentEmail: e.target.value,
      error: "",
    });
  }

  protected onPasswordChange(e: any) {
    this.setState({
      password: e.target.value,
      error: "",
    });
  }

  protected onCreateSubmit(e: React.FormEvent) {
    e.preventDefault();

    this.setState({ loading: true });

    const email = this.state.email;
    const designerPaymentEmail = this.state.designerPaymentEmail;
    const password = this.state.password;
    const firstName = this.state.firstName;
    const lastName = this.state.lastName;

    // FIXME: Solution should be implemented on the backend;
    if (!email || email.includes("yardzen")) {
      return this.setState({
        error: "email",
        errorText: "Contact engineering head to create an employee account",
      });
    }

    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        if (result && result.user && result.user.uid) {
          this.createProfile(
            result.user.uid,
            email,
            designerPaymentEmail,
            firstName,
            lastName
          );
        } else {
          this.setState({ isLoggedIn: true });
        }
      })
      .catch((reason) => {
        return this.handleCreateAccountError(reason.code, reason.message);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  protected createProfile(
    userId: string,
    email: string,
    designerPaymentEmail: string,
    firstName: string,
    lastName: string
  ) {
    const docPath = userId;
    const docData = {
      email: email,
      designerPaymentEmail: designerPaymentEmail,
      firstName: firstName,
      lastName: lastName,
      createdAt: moment().toISOString(),
      isDesigner: true,
    };

    return firebase
      .firestore()
      .collection("profiles")
      .doc(docPath)
      .set(docData, { merge: true })
      .then((result) => {
        this.setState({ isLoggedIn: true });
      })
      .catch((reason) => {
        return genericAlert(
          `Error creating liisa account: ${JSON.stringify(
            reason
          )}. docData: ${JSON.stringify(docData)}`
        );
      });
  }

  protected handleCreateAccountError(errorCode: string, errorText: string) {
    let error: string;

    switch (errorCode) {
      case "auth/email-already-in-use":
      case "auth/invalid-email":
        error = "email";
        break;
      case "auth/operation-not-allowed":
      case "auth/weak-password":
        error = "password";
        break;
      default:
        error = "password";
    }

    return this.setState({
      error: error,
      errorText: errorText,
    });
  }
}
