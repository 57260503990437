import {
  Box,
  BoxProps,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import React, { FC, useEffect, useRef, useState } from "react";
import { Revision, RevisionProperties } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export interface AllowAnnotationsSwitcherProps extends BoxProps {
  revisionId: string;
  cacheOnly?: boolean;
  label?: string;
}

const AllowAnnotationsSwitcher: FC<AllowAnnotationsSwitcherProps> = ({
  cacheOnly,
  revisionId,
  label,
  ...containerProps
}) => {
  const [checked, setChecked] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const initialCheckSetCompleteRef = useRef<boolean>(false);

  useEffect(onRevisionChange, [revisionId]);
  useEffect(onCheckedChanged, [checked]);

  return (
    <Box {...containerProps} display="flex" flexDirection="column">
      <FormControlLabel
        checked={!!checked}
        label={label || "Allow Annotations for this revision"}
        disabled={checked === null}
        onChange={toggleCheckboxState}
        control={<Checkbox disabled={checked === null} />}
      />
      {errorMessage && (
        <Typography variant="caption" style={{ color: "red" }}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );

  function toggleCheckboxState(): void {
    setChecked(!checked);
  }

  function onRevisionChange(): void {
    if (checked !== null) {
      setChecked(null);
      initialCheckSetCompleteRef.current = false;
    }

    if (!revisionId) {
      return void 0;
    }

    void (async function () {
      const revision = await getRevision();

      if (!revision) {
        console.error(`Revision ${revisionId} not found`);
        return;
      }

      setChecked(!!revision.allowAnnotation);
    })();
  }

  function onCheckedChanged(): void | (() => void) {
    if (checked === null) {
      return;
    }

    if (!initialCheckSetCompleteRef.current) {
      initialCheckSetCompleteRef.current = true;
      return;
    }

    const timeout = setTimeout(setAllowAnnotation, 300);
    return () => clearTimeout(timeout);
  }

  async function setAllowAnnotation(): Promise<void> {
    try {
      await firebase
        .firestore()
        .collection("revisions")
        .doc(revisionId)
        .update({ allowAnnotation: !!checked } as Partial<RevisionProperties>);

      if (errorMessage) {
        setErrorMessage("");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(
        "Failed to enable annotations in the database, please try again."
      );
    }
  }

  async function getRevision(): Promise<Revision | null> {
    try {
      const snap = await firebase
        .firestore()
        .collection("revisions")
        .doc(revisionId)
        .get({ source: cacheOnly ? "cache" : "default" });
      if (snap.exists) {
        const revision = Revision.initializefromDoc(snap);
        if (errorMessage) {
          setErrorMessage("");
        }

        return revision;
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Failed to get annotations status from the database.");
    }

    return null;
  }
};

export { AllowAnnotationsSwitcher };
export default AllowAnnotationsSwitcher;
