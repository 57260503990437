import * as React from "react";
import {
  Dialog,
  makeStyles,
  Theme,
  List,
  ListItem,
  Typography,
  Checkbox,
  Avatar,
  FormControlLabel,
  DialogTitle,
} from "@material-ui/core";
import { WootricDocument } from "@yardzen-inc/models";
import moment from "moment";

export interface Props {
  open: boolean;
  wootricSurveys: WootricDocument[];
  onClose: () => void;
}

export function WootricDetailModal({ open, onClose, wootricSurveys }: Props) {
  const classes = useStyles();
  const [showDeclined, setShowDeclined] = React.useState<boolean>(false);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      className={classes.root}
      open={open}
      onClose={onClose}
    >
      <FormControlLabel
        style={{ paddingLeft: "1.25rem" }}
        control={
          <Checkbox
            checked={showDeclined}
            title="Show dismissed surveys"
            onChange={(e) => setShowDeclined(e.target.checked)}
          />
        }
        label="Show dismissed surveys"
      />
      <DialogTitle>Wootric Surveys</DialogTitle>
      <List>
        {wootricSurveys.map((survey) => {
          if (!showDeclined && !survey.score) {
            return <div key={survey.id}></div>;
          }
          return (
            <ListItem
              divider
              style={{ display: "flex", flexDirection: "column" }}
              key={survey.id}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", lineHeight: "2" }}>
                  {!!survey.score && (
                    <Avatar
                      style={{
                        width: "25px",
                        height: "25px",
                        backgroundColor: getBackgroundColor(survey.score),
                      }}
                    >
                      {survey.score}
                    </Avatar>
                  )}
                  {survey.type === "decline" && (
                    <Typography>{survey.type.toUpperCase()}</Typography>
                  )}
                  <Typography
                    style={{
                      fontSize: "0.8rem",
                      lineHeight: "25px",
                      marginLeft: "0.75rem",
                    }}
                  >
                    {survey.originURL}
                  </Typography>
                </div>
                <Typography>
                  <b>
                    {moment(survey.docCreatedAt.toMillis()).format(
                      "MM/DD/YY hh:mma"
                    )}
                  </b>
                </Typography>
              </div>
              <div style={{ width: "100%" }}>
                <Typography
                  style={{ textAlign: "center", marginTop: "1.5rem" }}
                >
                  {!!survey.text?.length ? (
                    <em>{survey.text}</em>
                  ) : (
                    <em>No response</em>
                  )}
                </Typography>
              </div>
            </ListItem>
          );
        })}
      </List>
    </Dialog>
  );

  function getBackgroundColor(score: string) {
    const num = Number(score);

    if (num >= 9) {
      return "#76cd1e";
    }
    if (num >= 7) {
      return "#40a7e4";
    }
    return "#cb7a63";
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "1.25rem",
    width: "",
  },
}));
