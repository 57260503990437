import * as React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  Checkbox,
  Divider,
  IconButton,
  FormControlLabel,
  TextField,
  Fade,
  LinearProgress,
} from "@material-ui/core";
import { Close, ArrowDownward } from "@material-ui/icons";
import SearchDropdown, {
  SearchDropdownItem,
} from "../EmployeeView/Assignments/SearchDropdown";
import { Project, ProjectManager } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import GenericSnackBar from "./GenericSnackBar";
import { YZButton } from "@yardzen-inc/react-common";

const useStyles = makeStyles({
  root: {},
  padBot: {
    paddingBottom: "1rem",
  },
  bottomBox: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    margin: "1rem 0",
  },
  closeButton: {
    position: "absolute",
    top: 6,
    right: 8,
  },
});

interface Props {
  onClose: () => void;
  open: boolean;
  p: Project;
  contractorsIdsOnJob: string[];
  propertyName: string;
  title: string;
}

let searchTimeout: any = null;

export default (props: Props) => {
  const classes = useStyles();
  const [sure, setSure] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [searchResults, setSearchResults] = React.useState<ProjectManager[]>(
    []
  );
  const [focused, setFocused] = React.useState<boolean>(false);
  const [selectedAgent, setSelectedAgent] =
    React.useState<ProjectManager | null>();
  const [searching, setSearching] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<false | string>(false);

  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!searching) {
      setSearching(true);
    }

    searchTimeout = setTimeout(() => {
      search(searchTerm);
    }, 200);

    return () => clearTimeout(searchTimeout);
  }, [searchTerm]);

  React.useEffect(() => {
    if (selectedAgent) {
      setSearchTerm(`${selectedAgent.firstName} ${selectedAgent.lastName}`);
    }
  }, [selectedAgent]);

  React.useEffect(() => {
    if (!props.open) {
      setSearchTerm("");
      setSelectedAgent(null);
      setSure(false);
    }
  }, [props.open]);

  return (
    <>
      <GenericSnackBar
        onClose={() => setError(false)}
        variant="error"
        in={!!error}
        message={error || ""}
      />
      <Dialog maxWidth="md" open={props.open} onClose={props.onClose}>
        <SearchDropdown
          anchor={ref.current}
          selectItem={(a: any) => setSelectedAgent(a)}
          listItems={getListItems()}
          open={focused}
          fixed
          searching={searching}
          onClose={() => setFocused(false)}
        />
        <IconButton onClick={props.onClose} className={classes.closeButton}>
          <Close></Close>
        </IconButton>
        <DialogContent>
          <Typography variant="h4" noWrap style={{ paddingRight: "4rem" }}>
            Add {props.title} to Project
          </Typography>
          {submitting && <LinearProgress variant="indeterminate" />}
          <Typography
            variant="body1"
            className={classes.padBot}
            style={{ paddingRight: "3rem" }}
          ></Typography>
          <Divider />
          <br />
          <TextField
            label={`Search ${props.title}s`}
            value={searchTerm}
            fullWidth
            disabled={submitting}
            onChange={({ target: { value } }) => setSearchTerm(value)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            inputRef={ref}
          />
          <br />
          <Fade in={!!selectedAgent} mountOnEnter unmountOnExit>
            <div>
              <br />
              <Typography align="center" variant="h4" noWrap>
                Assign To
              </Typography>
              <br />
              <div
                style={{
                  margin: "auto",
                  width: "fit-content",
                }}
              >
                <ArrowDownward />
              </div>
              <br />
              {selectedAgent && (
                <>
                  <Typography align="center" variant="h5">
                    {`${selectedAgent.firstName} ${selectedAgent.lastName}`}
                  </Typography>
                  <Typography align="center" variant="body1">
                    {selectedAgent.email}
                  </Typography>
                </>
              )}
            </div>
          </Fade>
          <br />
          <Fade in={!!selectedAgent} mountOnEnter unmountOnExit>
            <div className={classes.bottomBox}>
              <FormControlLabel
                disabled={!selectedAgent || submitting}
                control={
                  <Checkbox
                    disabled={!selectedAgent || submitting}
                    value={sure}
                    onChange={() => setSure(!sure)}
                  />
                }
                label="Forsure"
              />
              <YZButton
                variant="outlined"
                disabled={!sure || !selectedAgent || submitting}
                onClick={() => submit()}
                style={{ width: "fit-content", height: "fit-content" }}
              >
                Do it
              </YZButton>
            </div>
          </Fade>
        </DialogContent>
      </Dialog>
    </>
  );

  async function submit() {
    setSubmitting(true);
    try {
      if (!selectedAgent || !selectedAgent.id) {
        throw new Error("Missing selected agent!");
      }

      await assignAllOthers(selectedAgent.id);
    } catch (error) {
      console.error(error);
      // @ts-ignore
      setError(error.message || "Unkown error");
    } finally {
      setSubmitting(false);
      props.onClose();
    }
  }

  async function assignAllOthers(selectedAgentId: string) {
    // @ts-ignore
    props.p[props.propertyName] = selectedAgentId;

    await firebase
      .firestore()
      .collection("projects")
      .doc(props.p.id)
      .set(props.p.getProperties(), { merge: true });

    await firebase
      .firestore()
      .collection("profiles")
      .doc(props.p.profileId)
      .update({ [props.propertyName]: selectedAgentId });
  }

  async function search(term: string) {
    if (!term.length) {
      setSearching(false);
      return setSearchResults([]);
    }

    const _term = term.trim().toLowerCase();

    const base = await getAllProjectManagers();

    let res = (base as any[]).filter((a: ProjectManager) => {
      return (
        `${a.firstName.toLowerCase()} ${a.lastName.toLowerCase()} ${
          a.email.toLowerCase
        }`.includes(_term) && !props.contractorsIdsOnJob.includes(a.id)
      );
    });

    if (res.length > 10) {
      res = res.slice(0, 10);
    }

    setSearchResults(res);
    setSearching(false);
  }

  async function getAllProjectManagers(): Promise<ProjectManager[]> {
    return ProjectManager.hydrate(
      (await firebase.firestore().collection("projectManagers").get()).docs
    );
  }

  function getListItems(): SearchDropdownItem[] {
    return searchResults.map((s) => ({
      label: `${s.firstName} ${s.lastName} <${s.email}>`,
      value: s,
      key: `${s.id}-sr`,
    }));
  }
};
