import { Tooltip } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import React from "react";
import { YZItemsAccordionField } from "src/Components/YZItemsAccordion";

const materialFields: YZItemsAccordionField[] = [
  {
    header: "Total Cost",
    fieldName: "totalCost",
  },
  {
    header: "Quantity",
    headerAlign: "center",
    fieldName: "quantity",
  },
  {
    header: "Unit",
    fieldName: "unit",
  },
  {
    header: "Image",
    fieldName: "image",
  },
  {
    header: "Material Name",
    fieldName: "collectionName",
  },
  {
    header: "Vendor",
    fieldName: "vendor",
  },
  {
    header: "Type",
    fieldName: "type",
  },
  {
    header: "Regional Availability",
    fieldName: "regionalAvailability",
    customRenderer: (value: any) =>
      !!value ? (
        <Tooltip title="This item is available in the client's region">
          <Check style={{ color: "green" }}></Check>
        </Tooltip>
      ) : (
        <Tooltip title="This item is not available in the client's region">
          <Close style={{ color: "red" }}></Close>
        </Tooltip>
      ),
  },
  {
    header: "Color",
    fieldName: "color",
  },
  {
    header: "Pattern",
    fieldName: "pattern",
  },
  {
    header: "Size",
    fieldName: "size",
  },
  {
    header: "Sketchup File",
    fieldName: "sketchupFileLink",
  },
  {
    header: "Lumion File",
    fieldName: "lumionFileLink",
  },
  {
    header: "Lumion File Name",
    fieldName: "lumionFileName",
  },
];

const elementFields: YZItemsAccordionField[] = [
  {
    header: "Total Cost",
    fieldName: "totalCost",
  },
  {
    header: "Quantity",
    headerAlign: "center",
    fieldName: "quantity",
  },
  {
    header: "Name",
    fieldName: "name",
  },
  {
    header: "Type",
    fieldName: "type",
  },
  {
    header: "Image",
    fieldName: "image",
  },
  {
    header: "Vendor",
    fieldName: "vendor",
  },
  {
    header: "Style",
    fieldName: "style",
  },
  {
    header: "Price Tier",
    fieldName: "priceTier",
  },
  {
    header: "Sketchup File",
    fieldName: "sketchupFileLink",
  },
  {
    header: "Lumion File",
    fieldName: "lumionFileLink",
  },
  {
    header: "Lumion File Name",
    fieldName: "lumionFileName",
  },
];

const plantFields: YZItemsAccordionField[] = [
  {
    header: "Total Cost",
    fieldName: "totalCost",
  },
  {
    header: "Quantity",
    headerAlign: "center",
    fieldName: "quantity",
  },
  {
    header: "Scientific Name",
    fieldName: "scientificName",
  },
  {
    header: "Name",
    fieldName: "name",
  },
  {
    header: "Type",
    fieldName: "type",
  },
  {
    header: "Zone",
    fieldName: "zone",
  },
  {
    header: "Plant Geo Match",
    fieldName: "plantGeoMatch",
    customRenderer: (value: any) =>
      !!value ? (
        <Tooltip title="This plant matches the client's GeoZone">
          <Check style={{ color: "green" }}></Check>
        </Tooltip>
      ) : (
        <Tooltip title="This plant doesn't match the client's GeoZone">
          <Close style={{ color: "red" }}></Close>
        </Tooltip>
      ),
  },
  {
    header: "Native Geo Match",
    fieldName: "nativeGeoMatch",
    customRenderer: (value: boolean) =>
      value ? (
        <img
          style={{ height: "25px" }}
          src="https://public-assets.yardzen.com/NativeBadge.png"
          alt="Native match badge"
        />
      ) : (
        <div></div>
      ),
  },
  {
    header: "Image",
    fieldName: "image",
  },
  {
    header: "Lighting",
    fieldName: "lightNeeds",
  },
  {
    header: "Water Needs",
    fieldName: "waterNeeds",
  },
  {
    header: "Key Features",
    fieldName: "keyFeatures",
  },
  {
    header: "Red Flags",
    fieldName: "redFlags",
  },
  {
    header: "Height Class",
    fieldName: "heightClass",
  },
  {
    header: "Container Size",
    fieldName: "containerSize",
  },
  {
    header: "Cad Symbol ~SIZE~",
    fieldName: "cadSymbolSize",
  },
  {
    header: "Cad Symbol ~SPACING~",
    fieldName: "cadSymbolSpacing",
  },
  {
    header: "Sketchup File",
    fieldName: "sketchupFileLink",
  },
  {
    header: "Lumion File",
    fieldName: "lumionFileLink",
  },
  {
    header: "Lumion File Name",
    fieldName: "lumionFileName",
  },
];

export enum PlantPDFHeaders {
  TYPE = "Type",
  SCIENTIFIC_NAME = "Scientific Name",
  COMMON_NAME = "Common Name",
  QUANTITY = "Quantity",
  CONTAINER_SIZE = "Container Size",
  WATER_NEEDS = "Water Needs",
  LIGHTING = "Lighting",
}

export enum MaterialPDFHeaders {
  VENDOR = "Vendor",
  MATERIAL_NAME = "Material Name",
  COLOR = "Color",
  PATTERN = "Pattern",
  SIZE = "Size",
  QUANTITY = "Quantity",
}

export const plantPDFTypeOrder = [
  "tree",
  "palm",
  "fruit tree",
  "fruit tree / edible",
  "shrub",
  "bamboo",
  "vine - requires support",
  "vine - self-clinging",
  "perennial",
  "ornamental grass",
  "fern",
  "xerophyte",
  "bromeliad",
  "groundcover, large scale",
  "groundcover, medium scale",
  "groundcover, small scale",
  "bulb",
  "none",
];

// Pixel values are set for CAD. Don't change unless given permission
const plantsPDFColumns = [
  {
    header: "extra col 1",
    fieldName: "",
    width: "76px",
  },
  {
    header: "extra col 2",
    fieldName: "",
    width: "76px",
  },
  {
    header: PlantPDFHeaders.TYPE,
    fieldName: "type",
    width: "112px",
  },
  {
    header: PlantPDFHeaders.SCIENTIFIC_NAME,
    fieldName: "scientificName",
    width: "218px",
  },
  {
    header: PlantPDFHeaders.COMMON_NAME,
    fieldName: "name",
    width: "242px",
  },
  {
    header: PlantPDFHeaders.QUANTITY,
    fieldName: "quantityNumber",
    width: "80px",
  },
  {
    header: PlantPDFHeaders.CONTAINER_SIZE,
    fieldName: "containerSize",
    width: "112px",
  },
  {
    header: PlantPDFHeaders.WATER_NEEDS,
    fieldName: "waterNeeds",
    width: "112px",
  },
  {
    header: PlantPDFHeaders.LIGHTING,
    fieldName: "lightNeeds",
    width: "112px",
  },
];

const elementsPDFColumns = [
  {
    header: "Quantity",
    fieldName: "quantityNumber",
  },
  {
    header: "Name",
    fieldName: "name",
  },
  {
    header: "Vendor",
    fieldName: "vendor",
  },
  {
    header: "Total Cost",
    fieldName: "totalCost",
  },
];

const materialsPDFColumns = [
  {
    header: "extra col 1",
    fieldName: "",
    width: "76px",
  },
  {
    header: "extra col 2",
    fieldName: "",
    width: "76px",
  },
  {
    header: MaterialPDFHeaders.VENDOR,
    fieldName: "vendor",
    width: "126px",
  },
  {
    header: MaterialPDFHeaders.MATERIAL_NAME,
    fieldName: "collectionName",
    width: "200px",
  },
  {
    header: MaterialPDFHeaders.COLOR,
    fieldName: "color",
    width: "200px",
  },
  {
    header: MaterialPDFHeaders.PATTERN,
    fieldName: "pattern",
    width: "200px",
  },
  {
    header: MaterialPDFHeaders.SIZE,
    fieldName: "size",
    width: "112px",
  },
  {
    header: MaterialPDFHeaders.QUANTITY,
    fieldName: "quantityPdfDisplay",
    width: "78px",
  },
];

export type SelectedItemsElement = {
  category: string;
  id: string;
  image: string;
  lumionFileLink: Element | JSX.Element | string | null;
  lumionFileName: string;
  name: string;
  priceTier: string;
  quantity: number | any;
  quantityNumber: number | any;
  sketchupFileLink: Element | JSX.Element | string | null;
  style: string;
  totalCost: string;
  type: string;
  vendor: string;
};

export type SelectedItemsMaterial = SelectedItemsElement & {
  unit: string;
  locations: string;
  regions: string[];
  finish: string;
  pattern: string;
  size: string;
  material: string;
};

export type SelectedItemsPlant = {
  category: string;
  id: string;
  image: string;
  lumionFileLink: Element | JSX.Element | string | null;
  lumionFileName: string;
  name: string;
  priceTier?: string;
  quantity: number | any;
  quantityNumber: number | any;
  sketchupFileLink: Element | JSX.Element | string | null;
  style?: string;
  totalCost: string;
  type: string;
  vendor?: string;
  scientificName: string;
  containerSize: string;
  cadSymbolSize: string;
  cadSymbolSpacing: string;
  zone: string;
  lightNeeds: string;
  heightClass: string;
  waterNeeds: string;
  keyFeatures: string;
  nativeGeoMatch: boolean;
};

export {
  materialFields,
  elementFields,
  plantFields,
  plantsPDFColumns,
  elementsPDFColumns,
  materialsPDFColumns,
};
