import React, { FC, ReactNode, useEffect } from "react";
import { Profile } from "@yardzen-inc/models";
import { Redirect } from "react-router-dom";
import { Tabs, Tab } from "@material-ui/core";
import { Route, Switch, HashRouter } from "react-router-dom";
import ExteriorDesignInspirationTab from "./ExteriorDesignInspirationTab";
import PaintSelectionTab from "./PaintSelectionTab";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { YZTypography } from "@yardzen-inc/react-common";
import { getIsExteriorPackage } from "../../util/selfAssign/getIsExteriorPackage";

export interface ExteriorDesignTabRouterProps {
  clientRecord: Profile;
  useHashRouter?: boolean;
}

export interface ExteriorDesignRouteInfo {
  label: string;
  value: string;
  Component: React.FC<{ profile: Profile }>;
}

const EXTERIOR_DESIGN_ROUTES: ExteriorDesignRouteInfo[] = [
  {
    label: "Inspiration Images",
    value: `/inspiration`,
    Component: ExteriorDesignInspirationTab,
  },
  {
    label: "Paint Selection",
    value: `/paint-selection`,
    Component: PaintSelectionTab,
  },
];

// Regex to check if the url is the base exterior-design url
const baseUrlRegex = /exterior-design(\/?)((\?.*)|(#.*))?$/i;

const ExteriorDesignRouterTab: FC<ExteriorDesignTabRouterProps> = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  useEffect(handleUnmount, []);

  return renderExteriorDesignRouterIfCorrectPackageType();

  function handleUnmount() {
    return () => {
      if (props.useHashRouter && window.location.hash) {
        // silly workaround to remove anchor
        window.location.hash = "";
        window.history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
      }
    };
  }

  function renderExteriorDesignRouterIfCorrectPackageType() {
    if (getIsExteriorPackage(props.clientRecord.package)) {
      return (
        <>
          {renderTabs()}
          {renderRoutes()}
        </>
      );
    } else {
      return (
        <YZTypography>
          Client package does not include Exterior Design
        </YZTypography>
      );
    }
  }

  function handleTabChange(event: React.ChangeEvent<{}>, value: any) {
    if (props.useHashRouter) {
      window.location.hash = value;
    } else {
      history.push(value);
    }
  }

  function renderTabs() {
    return (
      <Tabs
        value={`${
          props.useHashRouter
            ? window.location.hash.substring(1)
            : location.pathname
        }`}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="on"
      >
        {EXTERIOR_DESIGN_ROUTES.map((tab) => {
          return (
            <Tab
              value={`${match.url}${tab.value}`}
              label={tab.label}
              key={`ext-design-tab-${tab.label}`}
            />
          );
        })}
      </Tabs>
    );
  }

  function renderRoutes() {
    const routes = EXTERIOR_DESIGN_ROUTES.map((route) => {
      return (
        <Route
          path={`${match.url}${route.value}`}
          key={`ext-design-tab-${route.label}`}
        >
          <route.Component profile={props.clientRecord} />
        </Route>
      );
    });

    if (props.useHashRouter) {
      if (!(window.location.hash.length > 2)) {
        window.location.hash = "/" + EXTERIOR_DESIGN_ROUTES?.[0].value;
      }
      return <HashRouter>{routes}</HashRouter>;
    }

    return (
      <Switch>
        {routes}
        {redirectBaseUrl()}
        <Route />
      </Switch>
    );
  }

  // When the user hits the base url '~/exterior-design', auto-redirect them to '~/exterior-design/style-quiz'
  function redirectBaseUrl(): ReactNode {
    if (baseUrlRegex.test(location.pathname)) {
      return <Redirect to={`${match.url}/inspiration`} />;
    }
    return null;
  }
};

export { ExteriorDesignRouterTab };
export default ExteriorDesignRouterTab;
