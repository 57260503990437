import {
  Box,
  FormControl,
  InputLabel,
  List,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Profile } from "@yardzen-inc/models";
import { YZTypography } from "@yardzen-inc/react-common";
import * as React from "react";
import GenericSnackBar from "../../Components/GenericSnackBar";
import LoadingAbsCenter from "../../Components/LoadingAbsCenter";
import { IncompleteProfileListItem } from "./components/IncompleteProfileListItem";
import { getProfilesWithMissingCoordinates } from "./util/getProfilesWithMissingCoordinates";

interface IIncompleteAddressQueueProps {}

const IncompleteAddressQueue: React.FunctionComponent<IIncompleteAddressQueueProps> =
  (props) => {
    const [error, setError] = React.useState<null | string>(null);
    const [loading, setLoading] = React.useState(true);
    const [profilesInQueue, setProfilesInQueue] = React.useState<Profile[]>([]);
    const [profilesMonthsAgo, setProfilesMonthsAgo] = React.useState<number>(6);

    React.useEffect(() => {
      (async function () {
        setLoading(true);
        try {
          setProfilesInQueue(
            await getProfilesWithMissingCoordinates(profilesMonthsAgo)
          );
        } catch (e) {
          console.error(e);

          setError(e.message);
        } finally {
          setLoading(false);
        }
      })();
    }, [profilesMonthsAgo]);

    return (
      <>
        {loading ? (
          <LoadingAbsCenter in={loading} />
        ) : (
          <Box width="100%" display="flex" justifyContent="center">
            <GenericSnackBar
              variant="error"
              message={error || ""}
              in={!!error}
              onClose={() => setError(null)}
            />

            {!profilesInQueue.length && (
              <Box m="auto">
                <YZTypography variant="h5">
                  No clients missing addresses
                </YZTypography>
              </Box>
            )}
            <Box p={2}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Timeframe</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={profilesMonthsAgo}
                  onChange={(event) => {
                    setProfilesMonthsAgo(event.target.value as number);
                  }}
                >
                  <MenuItem value={6}>Six Months</MenuItem>
                  <MenuItem value={12}>Year</MenuItem>
                  <MenuItem value={24}>Two Years</MenuItem>
                </Select>
              </FormControl>

              <List>
                {profilesInQueue.length && (
                  <YZTypography variant="h5">
                    {profilesInQueue.length} Profiles with incomplete addresses
                  </YZTypography>
                )}
                {profilesInQueue.map((p) => (
                  <IncompleteProfileListItem
                    removeProfileFromQueue={removeProfileFromQueue}
                    key={p.id}
                    profile={p}
                  />
                ))}
              </List>
            </Box>
          </Box>
        )}
      </>
    );

    function removeProfileFromQueue(profile: Profile) {
      setProfilesInQueue(profilesInQueue.filter((p) => p.id !== profile.id));
    }
  };

export { IncompleteAddressQueue };
