import * as React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  Checkbox,
  Divider,
  IconButton,
  FormControlLabel,
  TextField,
  Fade,
  LinearProgress,
} from "@material-ui/core";
import { Close, ArrowDownward } from "@material-ui/icons";
import { AssignmentType, Agent, ProjectManager } from "@yardzen-inc/models";
import SearchDropdown, { SearchDropdownItem } from "./SearchDropdown";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import makeAgentFromPm from "../CDM/makeAgentFromPm";
import { YZButton } from "@yardzen-inc/react-common";

const useStyles = makeStyles({
  root: {},
  padBot: {
    paddingBottom: "1rem",
  },
  bottomBox: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    margin: "1rem 0",
  },
  closeButton: {
    position: "absolute",
    top: 6,
    right: 8,
  },
});

interface Props {
  onSubmit: (a: Agent) => void;
  onClose: () => void;
  open: boolean;
  type?: AssignmentType;
  submitting?: boolean;
}

let searchTimeout: any = null;

export default (props: Props) => {
  const classes = useStyles();
  const [sure, setSure] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [searchResults, setSearchResults] = React.useState<Agent[]>([]);
  const [focused, setFocused] = React.useState<boolean>(false);
  const [selectedAgent, setSelectedAgent] = React.useState<Agent | null>();
  const [searching, setSearching] = React.useState<boolean>(false);

  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!searching) {
      setSearching(true);
    }

    searchTimeout = setTimeout(() => {
      search(searchTerm);
    }, 200);

    return () => clearTimeout(searchTimeout);
  }, [searchTerm]);

  React.useEffect(() => {
    if (selectedAgent) {
      setSearchTerm(`${selectedAgent.firstName} ${selectedAgent.lastName}`);
    }
  }, [selectedAgent]);

  React.useEffect(() => {
    if (!props.open) {
      setSearchTerm("");
      setSelectedAgent(null);
      setSure(false);
    }
  }, [props.open]);

  if (!props.type) return null;

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <SearchDropdown
        anchor={ref.current}
        selectItem={(a: Agent) => setSelectedAgent(a)}
        listItems={getListItems()}
        open={focused}
        fixed
        searching={searching}
        onClose={() => setFocused(false)}
      />
      <IconButton onClick={props.onClose} className={classes.closeButton}>
        <Close></Close>
      </IconButton>
      <DialogContent>
        <Typography variant="h4" noWrap style={{ paddingRight: "4rem" }}>
          Manually Assign Assignment
        </Typography>
        {props.submitting && <LinearProgress variant="indeterminate" />}
        <Typography
          variant="body1"
          className={classes.padBot}
          style={{ paddingRight: "3rem" }}
        ></Typography>
        <Divider />
        <br />
        <TextField
          label={`Search ${getAgentTypeRealName()}`}
          value={searchTerm}
          fullWidth
          disabled={props.submitting}
          onChange={({ target: { value } }) => setSearchTerm(value)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          inputRef={ref}
        />
        <br />
        <Fade in={!!selectedAgent} mountOnEnter unmountOnExit>
          <div>
            <br />
            <Typography align="center" variant="h4" noWrap>
              Assign To
            </Typography>
            <br />
            <div
              style={{
                margin: "auto",
                width: "fit-content",
              }}
            >
              <ArrowDownward />
            </div>
            <br />
            {selectedAgent && (
              <>
                <Typography align="center" variant="h5">
                  {`${selectedAgent.firstName} ${selectedAgent.lastName}`}
                </Typography>
                <Typography align="center" variant="body1">
                  {selectedAgent.email}
                </Typography>
              </>
            )}
          </div>
        </Fade>
        <br />
        <Fade in={!!selectedAgent} mountOnEnter unmountOnExit>
          <div className={classes.bottomBox}>
            <FormControlLabel
              disabled={!selectedAgent || props.submitting}
              control={
                <Checkbox
                  disabled={!selectedAgent || props.submitting}
                  value={sure}
                  onChange={() => setSure(!sure)}
                />
              }
              label="Forsure"
            />
            <YZButton
              variant="outlined"
              disabled={!sure || !selectedAgent || props.submitting}
              onClick={() => props.onSubmit(selectedAgent as Agent)}
              style={{ width: "fit-content", height: "fit-content" }}
            >
              Do it
            </YZButton>
          </div>
        </Fade>
      </DialogContent>
    </Dialog>
  );

  function getAgentTypeRealName(): string {
    switch (props.type) {
      case "wesley":
        return "wesleys";
      case "modeling":
        return "modelers";
      case "design":
        return "designers";
      default:
        return "";
    }
  }

  async function search(term: string) {
    if (!props.type) {
      return setSearching(false);
    }
    if (!term.length) {
      setSearching(false);
      return setSearchResults([]);
    }

    const _term = term.trim().toLowerCase();

    const agents = await Agent.fetchAllOfType(props.type, null, null, false);

    if (props.type === "wesley") {
      agents.push(
        ...ProjectManager.hydrate(
          (await firebase.firestore().collection("projectManagers").get()).docs
        ).map((pm) => makeAgentFromPm(pm))
      );
    }

    let res = agents.filter((a) => {
      return `${a.firstName?.toLowerCase()} ${a.lastName?.toLowerCase()} ${a.email?.toLowerCase()}`.includes(
        _term
      );
    });

    if (res.length > 10) {
      res = res.slice(0, 10);
    }

    setSearchResults(res);
    setSearching(false);
  }

  function getListItems(): SearchDropdownItem[] {
    return searchResults.map((s) => ({
      label: `${s.firstName} ${s.lastName} <${s.email}>`,
      value: s,
      key: `${s.id}-sr`,
    }));
  }
};
