import React from "react";
import AssetLibraryBack from "../Shared/AssetLibraryBack";
import { useHistory } from "react-router";
import GenericSnackBar from "../../../Components/GenericSnackBar";
import { YZButton } from "@yardzen-inc/react-common";

interface Props {
  goBackLocation: string;
  addNewAssetComponent: React.FC<{ onSave: () => void }>;
}

function ElementsLibraryCreate(props: Props) {
  const history = useHistory();
  const [snackBarMessage, setSnackBarMessage] = React.useState<string | null>(
    null
  );
  const [snackBarVariant, setSnackBarVariant] = React.useState<
    "success" | "warning" | "error" | "info" | null
  >(null);
  const [showAdded, setShowAdded] = React.useState(false);

  return (
    <div>
      <AssetLibraryBack back={() => history.push(props.goBackLocation)} />
      <br />
      <br />
      {!showAdded &&
        props.addNewAssetComponent({
          onSave: () => {
            setSnackBarMessage("Element added successfully!");
            setSnackBarVariant("success");
            setShowAdded(true);
          },
        })}
      {showAdded && (
        <YZButton color="primary" onClick={() => setShowAdded(false)}>
          Add Another
        </YZButton>
      )}
      <GenericSnackBar
        onClose={() => {
          setSnackBarMessage(null);
          setSnackBarVariant(null);
        }}
        message={snackBarMessage || ""}
        in={!!snackBarMessage && !!snackBarVariant}
        variant={snackBarVariant || "info"}
      />
    </div>
  );
}

export default ElementsLibraryCreate;
