import React, { FC, memo } from "react";
import { DocProp } from "./types";
import { Box, Typography } from "@material-ui/core";
import StatTagCloudSelector from "./StatTagCloudSelector";

export interface StatsRowProps<t> {
  title: string;
  selected: t[];
  deselected: t[];
  onChange: (type: "additive" | "subtractive", tag: DocProp) => void;
}

/*
  Horizontal row across the page holding two tag clouds:
    - the cloud on the left will "add" the property to the csv
      to be generated on Click
    - the cloud on the right will "subtract" the property from
      the csv to be generated on Click
*/
const StatsRow: FC<StatsRowProps<DocProp>> = memo(
  (props) => {
    return (
      <Box>
        <Box p={1}>
          <Typography variant="h4">{props.title}</Typography>
        </Box>
        <Box width="100%" display="flex" flexDirection="row">
          <Box width="50%">
            <Typography variant="h4" align="center">
              Available
            </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="h4" align="center">
              Included
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="nowrap">
          <Box
            width="50%"
            p={1}
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            alignItems="flex-start"
            justifyContent="flex-start"
            height="min-content"
          >
            <StatTagCloudSelector
              type="additive"
              tags={props.deselected}
              onSelect={getSelectHandler("additive")}
            />
          </Box>
          <Box
            width="50%"
            p={1}
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            alignItems="flex-start"
            height="min-content"
          >
            <StatTagCloudSelector
              type="subtractive"
              tags={props.selected}
              onSelect={getSelectHandler("subtractive")}
            />
          </Box>
        </Box>
      </Box>
    );

    function getSelectHandler(
      type: "additive" | "subtractive"
    ): (tag: DocProp) => void {
      // Closure returning onSelect handler with bound action type
      return (tag: DocProp) => props.onChange(type, tag);
    }
  },
  (prev, next) => {
    return (
      next.selected === prev.selected && next.deselected === prev.deselected
    );
  }
);

export { StatsRow };
export default StatsRow;
