import React from "react";
import debounce from "lodash/debounce";
import { IconButton, makeStyles, TextField } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { Add, Remove } from "@material-ui/icons";

interface Props {
  value: number;
  onUpdate: (qty: number) => void;
  unit?: string;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "9rem",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  textField: {
    width: "4rem",
  },
}));

function QuantityInput(props: Props) {
  const classes = useStyles();

  const [displayValue, setDisplayValue] = React.useState<number>(props.value);

  React.useEffect(() => {
    if (typeof props.value !== "number") return;
    setDisplayValue(props.value);
  }, [props.value]);

  // debounce delay is not exposed to designers. 600ms seems acceptable
  const debounceDelay = 600;
  const debouncedUpdate = React.useCallback(
    debounce((qty) => props.onUpdate(qty), debounceDelay),
    []
  );

  const onChangeQuantity = (e: React.ChangeEvent<any>, type: string) => {
    debouncedUpdate.cancel();

    let updatedDisplayValue: number;
    if (type === "PLUS_ONE") {
      updatedDisplayValue = displayValue + 1;
    } else if (type === "MINUS_ONE") {
      updatedDisplayValue = Math.max(0, displayValue - 1);
    } else {
      updatedDisplayValue = +e.currentTarget.value.replace(/\D/g, "");
    }

    setDisplayValue(updatedDisplayValue);
    debouncedUpdate(updatedDisplayValue);
  };

  return (
    <div className={classes.root}>
      <IconButton
        disabled={props.disabled}
        size="small"
        onClick={(e) => onChangeQuantity(e, "MINUS_ONE")}
      >
        <Remove fontSize="small" />
      </IconButton>
      <TextField
        disabled={props.disabled}
        type="number"
        onChange={(e) => onChangeQuantity(e, "INPUT")}
        label={props.unit ? props.unit : "Quantity"}
        value={displayValue.toString()}
        variant="outlined"
        className={classes.textField}
      />
      <IconButton
        onClick={(e) => onChangeQuantity(e, "PLUS_ONE")}
        disabled={props.disabled}
        size="small"
      >
        <Add fontSize="small" />
      </IconButton>
    </div>
  );
}

export default QuantityInput;
