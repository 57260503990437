import * as React from "react";
import { Typography, Link } from "@material-ui/core";
import firebase from "firebase/compat/app";

export interface Props {}

class ThirdPartyLogin extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.onFacebookLoginButtonClick =
      this.onFacebookLoginButtonClick.bind(this);
  }

  public render() {
    return (
      <React.Fragment>
        <Typography>
          Or Log In with{" "}
          <Link href="" onClick={this.onFacebookLoginButtonClick}>
            Facebook
          </Link>
        </Typography>
      </React.Fragment>
    );
  }

  protected onFacebookLoginButtonClick(e: any) {
    e.preventDefault();

    const provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .catch((reason) => {
        // TODO XXX
        // Handling account-exists-with-different-credential Errors
        // https://firebase.google.com/docs/auth/web/facebook-login
      });
  }
}

export default ThirdPartyLogin;
