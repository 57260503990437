import * as React from "react";
import firebase from "firebase/compat/app";
import LoadingAbsCenter from "./Components/LoadingAbsCenter";
import { Box, Typography } from "@material-ui/core";
import ProfileMetaDisplay from "./Components/ProfileMetaDisplay";
import { Profile, Media, ProMatchProjectTag } from "@yardzen-inc/models";
import { SharedMediaGrids } from "./SharedMediaGrids";
import { GenericOnCallFunctionAlert } from "./util/genericAlert";
import SelectedItemsPDF from "./Components/NonPmAssetSelection/SelectedItemsPDF";
import {
  elementsPDFColumns,
  materialsPDFColumns,
  plantsPDFColumns,
} from "./Components/NonPmAssetSelection/constants/SelectedItemsConstants";
import { Addy } from "./util/getAddress";
import { YZButton } from "@yardzen-inc/react-common";
import { BuildIntentResponseCard } from "./Components/BuildIntentResponseCard";
import useProjectsFromUserId from "./util/hooks/useProjectFromUserId";
import { useTreatment } from "@yardzen-inc/react-split";

const SHOW_SHARED_VIEW_PII = "SHOW_SHARED_VIEW_PII";

interface Props {
  match: any;
}

function SharedMediaView(props: Props) {
  const showSharedViewPII = useTreatment(SHOW_SHARED_VIEW_PII);
  const [loading, setLoading] = React.useState<boolean | null>(true);
  const [media, setMedia] = React.useState<Media[]>([]);
  const [profile, setProfile] = React.useState<Profile | null>(null);

  const [selectedElements, setSelectedElements] = React.useState<[] | null>(
    null
  );
  const [selectedPlants, setSelectedPlants] = React.useState<[] | null>(null);
  const [selectedMaterials, setSelectedMaterials] = React.useState<[] | null>(
    null
  );

  const [elementsOpen, setElementsOpen] = React.useState<boolean>(false);
  const [plantsOpen, setPlantsOpen] = React.useState<boolean>(false);
  const [materialsOpen, setMaterialsOpen] = React.useState<boolean>(false);

  const clientId = props.match.params.clientId;
  const project = (useProjectsFromUserId(clientId || "") ?? [])[0];
  const answeredNoForPostV2BuildSurvey = project?.tags?.includes(
    ProMatchProjectTag.PostV2No
  );

  const address: Addy | null = profile
    ? {
        city: profile.city as string,
        street: profile.street as string,
        state: profile.state as string,
        zip: profile.zip as string,
      }
    : null;

  React.useEffect(() => {
    fetchMedia();
  }, []);

  if (loading) return <LoadingAbsCenter in={loading} />;

  if (loading === null || !profile) {
    return (
      <div>
        <Typography>
          Either this media is no longer available or there was an error
        </Typography>
      </div>
    );
  }
  const printWindowIsOpen = elementsOpen || plantsOpen || materialsOpen;

  return (
    <>
      {selectedElements && (
        <SelectedItemsPDF
          title="Element List"
          open={elementsOpen}
          address={address}
          onClose={() => {
            setElementsOpen(false);
          }}
          rows={selectedElements}
          columns={elementsPDFColumns}
        />
      )}
      {selectedPlants && (
        <SelectedItemsPDF
          title="Plant List"
          open={plantsOpen}
          address={address}
          onClose={() => {
            setPlantsOpen(false);
          }}
          rows={selectedPlants}
          columns={plantsPDFColumns}
        />
      )}
      {selectedMaterials && (
        <SelectedItemsPDF
          title="Material List"
          open={materialsOpen}
          address={address}
          onClose={() => {
            setMaterialsOpen(false);
          }}
          rows={selectedMaterials}
          columns={materialsPDFColumns}
        />
      )}
      {!printWindowIsOpen && (
        <>
          <Box mt={4} display="flex" justifyContent="center" flexWrap="wrap">
            <ProfileMetaDisplay
              showSharedViewPII={showSharedViewPII}
              profile={profile}
            />
            {answeredNoForPostV2BuildSurvey ? null : (
              <BuildIntentResponseCard project={project} />
            )}
          </Box>
          <Box mt={4} display="flex" justifyContent="center" flexWrap="wrap">
            {selectedElements && (
              <Box m={1}>
                <YZButton
                  variant="outlined"
                  onClick={() => setElementsOpen(true)}
                  size="small"
                >
                  Download Element List
                </YZButton>
              </Box>
            )}
            {selectedPlants && (
              <Box m={1}>
                <YZButton
                  variant="outlined"
                  onClick={() => setPlantsOpen(true)}
                  size="small"
                >
                  Download Plant List
                </YZButton>
              </Box>
            )}
            {selectedMaterials && (
              <Box m={1}>
                <YZButton
                  variant="outlined"
                  onClick={() => setMaterialsOpen(true)}
                  size="small"
                >
                  Download Material List
                </YZButton>
              </Box>
            )}
          </Box>
          <Box mt={4}>
            <SharedMediaGrids
              onDeleteComplete={(media: Media[]) => {}}
              onUploadComplete={() => {}}
              media={media}
              permissions={{
                media: {
                  access: "no update",
                },
              }}
              noShare
              noDelete
              userId={clientId}
            />
          </Box>{" "}
        </>
      )}
    </>
  );

  async function fetchMedia() {
    const getMedia = firebase
      .functions()
      .httpsCallable("contractorShareableLinkHook");
    getMedia({ docID: clientId })
      .then((res) => {
        setMedia(res.data.shared.media);
        delete res.data.shared.media;
        setProfile(res.data.shared);
        setLoading(false);
        setSelectedMaterials(res.data.shared.selectedMaterials);
        setSelectedElements(res.data.shared.selectedElements);
        setSelectedPlants(res.data.shared.selectedPlants);
      })
      .catch((err) => {
        GenericOnCallFunctionAlert("contractorShareableLinkHook", err.message);
        setLoading(null);
      });
  }
}

export default SharedMediaView;
