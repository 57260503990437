import {
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  DocumentNode,
  Product_Type,
  TypedDocumentNode,
  useQuery,
} from "@yardzen-inc/graphql";
import { useFormikContext } from "formik";
import * as React from "react";
import {
  IFormikElementErrorProps,
  IFormikElementProps,
  IFormikHandleChange,
} from "../../../Interfaces";

const useRowStyles = makeStyles({
  editItem: {
    flex: 1,
  },
  select: {
    width: "100%",
  },
});

interface Props {
  query: DocumentNode | TypedDocumentNode<any, {}>;
  label: string;
  queryField: string;
  formikName: string;
  multiple?: boolean;
  optional?: boolean;
  readOnly?: boolean;
}

export default function AssetFeatureSelect(props: Props) {
  const classes = useRowStyles();

  const {
    values,
    errors: formikErrors,
    touched,
    handleChange,
  } = useFormikContext() as {
    values: Partial<IFormikElementProps>;
    errors: Partial<IFormikElementErrorProps>;
    touched: Partial<IFormikElementProps>;
    handleChange: IFormikHandleChange;
  };

  const { loading, error, data } = useQuery(props.query, {
    variables: {},
    fetchPolicy: "network-only",
  });

  const [options, setOptions] = React.useState<any[]>(
    data?.[props.queryField]?.map((each: Product_Type) => {
      return {
        id: each.id,
        name: each.name,
      };
    })
  );

  React.useEffect(() => {
    if (!data) {
      return;
    }
    setOptions(
      data[props.queryField].map((each: Product_Type) => {
        return {
          id: each.id,
          name: each.name,
        };
      })
    );
  }, [data]);

  const sortByName = (a: Product_Type, b: Product_Type): 1 | 0 | -1 => {
    if (typeof a?.name !== "string" || typeof b?.name !== "string") {
      return 0;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (b.name < a.name) {
      return 1;
    }
    return 0;
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return (
      <Typography>
        Something went wrong. Please contact support or try again later.
      </Typography>
    );
  }
  // @ts-ignore
  if (!Array.isArray(values[props.formikName]) && props.multiple) {
    return (
      <Typography>
        ERROR: Missing options for {props.label}. Please inform IT.
      </Typography>
    );
  }

  if (!Array.isArray(options)) {
    return <Typography>Please wait...</Typography>;
  }

  return (
    <div className={classes.editItem}>
      <InputLabel shrink>{`${props.label} ${
        !props.optional ? "*" : ""
      }`}</InputLabel>
      <Select
        multiple={props.multiple}
        // @ts-ignore
        error={!!(formikErrors[props.formikName] && touched[props.formikName])}
        className={classes.select}
        // @ts-ignore
        value={values[props.formikName]}
        onChange={handleChange(props.formikName)}
        disabled={props.readOnly}
      >
        {options.sort(sortByName).map((type: Product_Type) => (
          <MenuItem key={type.id} value={type.id}>
            {type.name}
          </MenuItem>
        ))}
      </Select>
      {
        // @ts-ignore
        formikErrors[props.formikName] && touched[props.formikName] && (
          <FormHelperText style={{ color: "red" }}>
            {
              // @ts-ignore
              formikErrors[props.formikName]
            }
          </FormHelperText>
        )
      }
    </div>
  );
}
