import React, { FC } from "react";

// Import MUI Components
import { makeStyles } from "@material-ui/core/styles";
import { TableRow, TableCell, Theme, Checkbox } from "@material-ui/core";
import clsx from "clsx";
import { Agent } from "./types";

export interface AgentRow {
  agent: Agent;
  setSelectedAgents: (userId: string) => void;
  isItemSelected: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  checkedRow: {
    backgroundColor: "#e1e6e1",
  },
}));

const AgentRow: FC<AgentRow> = ({
  agent,
  setSelectedAgents,
  isItemSelected,
}) => {
  const classes = useStyles();

  return (
    <>
      <TableRow
        key={agent.id}
        className={clsx(isItemSelected && classes.checkedRow)}
      >
        <TableCell component="th" scope="row" width="40%">
          {agent.firstName} {agent.lastName}
        </TableCell>
        <TableCell align="left" width="45%">
          {agent.email}
        </TableCell>
        <TableCell>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            onChange={handleCheckBoxClick}
          />
        </TableCell>
      </TableRow>
    </>
  );

  function handleCheckBoxClick(event: React.ChangeEvent<HTMLInputElement>) {
    setSelectedAgents(agent.id);
  }
};

export { AgentRow };
export default AgentRow;
