import React, { FC } from "react";
import { DocProp } from "./types";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";

export interface StatTagProps {
  tag: DocProp;
  type: "additive" | "subtractive";
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  colorAndHover: {
    pointerEvents: "all",
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    transition: "background-color 250ms",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

/*
  Presentational Representation of the Model property to be
  added or removed from the generated report. "onClick"  functionality
  derrived trom StatTagCloudSelector and StatsRow.
*/
const StatTag: FC<StatTagProps> = (props) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.colorAndHover}
      p={0.5}
      px={1}
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      height="min-content"
      m={1}
      borderRadius={"3px"}
      boxShadow={2}
      alignItems="center"
      onClick={props.onClick}
    >
      <Box pr={2} display="flex" flexDirection="row">
        <Typography variant="body1">{props.tag}</Typography>
      </Box>
      <Box height="min-content">
        {props.type === "additive" ? <Add /> : <Remove />}
      </Box>
    </Box>
  );
};

export { StatTag };
export default StatTag;
