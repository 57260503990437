import { formatNumToUSD } from "@yardzen-inc/react-common";
import { BudgetElementsPriceEstimate } from "../../../util/hooks/useBudgetElementsPriceEstimate";
import { BOTANICAL_PACKAGE } from "../../../util/packageTypeConstants";

interface GetWishlistEstimateRangeTextArgs {
  wishlistEstimates: BudgetElementsPriceEstimate;
  packageType?: string;
}

/**
 * Gets a string of text with the wishlist range formatted to dollars.
 * @param wishlistEstimates An array with the low and high values for the
 * wishlist estimate, in pennies.
 * @returns A string of text with the wishlist range formatted to dollars.
 */
export const getWishlistEstimateRangeText = ({
  wishlistEstimates,
  packageType,
}: GetWishlistEstimateRangeTextArgs): string => {
  if (packageType === BOTANICAL_PACKAGE) {
    return "Wishlist estimates do not exist for clients with botanical packages.";
  }

  if (!wishlistEstimates || !wishlistEstimates[0] || !wishlistEstimates[1]) {
    return "Unable to get wishlist estimate for this project.";
  }

  const lowWishlistEstimate = wishlistEstimates[0];
  const highWishlistEstimate = wishlistEstimates[1];

  return `${formatNumToUSD(lowWishlistEstimate)} - ${formatNumToUSD(
    highWishlistEstimate
  )}`;
};
