import React, {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { UserCtx } from "./UserContext";
import { LinearProgress, Typography } from "@material-ui/core";

export interface HasuraAdminsOnlyProps {
  loadingMarkup?: ReactNode;
  children: ReactElement<any, any> | any;
}

const HasuraAdminsOnly = ({
  loadingMarkup,
  children,
}: HasuraAdminsOnlyProps) => {
  const user = useContext(UserCtx);
  const [isHasuraAdmin, setIsHasuraAdmin] = useState<boolean | null>(false);
  useEffect(onUserChange, [user]);

  if (isHasuraAdmin === null) {
    return !!loadingMarkup ? (
      <>{loadingMarkup ?? null}</>
    ) : (
      <LinearProgress variant="indeterminate" />
    );
  }

  if (!isHasuraAdmin) {
    return <Typography variant="h3">Access Denied</Typography>;
  }
  return children ?? null;

  function onUserChange(): void {
    void (async function () {
      setIsHasuraAdmin(await checkIsHasuraAdmin());
    })();
  }

  async function checkIsHasuraAdmin(): Promise<boolean | null> {
    if (user) {
      try {
        const userClaimsDocument = await firebase
          .firestore()
          .collection("userClaims")
          .doc(user.uid)
          .get();

        const data = userClaimsDocument.data();
        const allowedRoles = data?.hasura?.["x-hasura-allowed-roles"];

        return Array.isArray(allowedRoles) && allowedRoles.includes("admin");
      } catch (error) {
        console.error(error);

        alert(
          "Failed to get permissions for user, please reload the page and try again"
        );
      }
    }

    return null;
  }
};

export { HasuraAdminsOnly };
export default HasuraAdminsOnly;
