import React, { FC, useEffect, useState } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import classnames from "classnames";
import { useDesignBreifPageStyles } from "./useDesignBriefPageStyles";
import { Profile, Project } from "@yardzen-inc/models";
import { DesignBriefProvider } from "./util/DesignBriefContext";
import DesignBriefHeaderInfo from "./DesignBriefHeaderInfo";
import DesignBriefChecklistToolbar from "./DesignBriefChecklistToolbar";
import DesignBriefChecklist from "./DesignBriefChecklist";
import DesignBriefLoadingSnackbar from "./DesignBriefLoadingSnackbar";
import ChecklistInstructions from "./ChecklistInstructions";
import ChecklistAwknowledgements from "./ChecklistAwknowledgements";
import { DesignBriefNotes } from "./DesignBriefNotes";
import LoadingAbsCenter from "../LoadingAbsCenter";
import { useGetGeoZones } from "../../util/hooks/useGetGeoZones";
import SnackbarError from "../SnackbarError";

export interface DesignBriefPageProps {
  pageWidthContainerProps?: BoxProps;
  checklistContainerProps?: BoxProps;
  profile: Profile;
  project: Project;
  pm: boolean;
}

const DesignBriefPage: FC<DesignBriefPageProps> = (props) => {
  const classes = useDesignBreifPageStyles();

  const { loadingGeoZone, geoZones, geoZoneError } = useGetGeoZones(
    props.profile
  );

  const [errorSnackbarText, setErrorSnackbarText] = useState<string | null>(
    null
  );

  useEffect(handleGeoZoneError, [geoZoneError]);

  const prettyGeoZones = (): string => {
    if (!geoZones || !geoZones.length) return "";
    return geoZones.map((zone) => zone.name).join(", ");
  };

  if (loadingGeoZone) {
    return <LoadingAbsCenter in={true} />;
  }

  return (
    <DesignBriefProvider profile={props.profile} project={props.project}>
      <SnackbarError
        errorText={errorSnackbarText}
        handleSnackbarClose={() => setErrorSnackbarText(null)}
        severity="error"
      />
      <DesignBriefLoadingSnackbar />
      <Box
        pb={2}
        {...props.pageWidthContainerProps}
        className={classnames(
          classes.pageWidthContainer,
          props?.pageWidthContainerProps?.className
        )}
      >
        <Box
          boxShadow={1}
          {...props.checklistContainerProps}
          className={classnames(
            classes.checklistContainer,
            props.checklistContainerProps?.className
          )}
        >
          <Box p={2} py={2} width="100%">
            <DesignBriefHeaderInfo
              profile={props.profile}
              project={props.project}
            />
          </Box>
          <DesignBriefChecklistToolbar pm={props.pm} />
          {!props.pm && (
            <ChecklistInstructions p={1} pb={3} mx={2} mb={3} boxShadow={1} />
          )}
          <ChecklistAwknowledgements px={2} pb={3} />
          <DesignBriefChecklist pm={props.pm} geoZones={prettyGeoZones()} />
          <DesignBriefNotes pm={props.pm} p={2}></DesignBriefNotes>
        </Box>
      </Box>
    </DesignBriefProvider>
  );

  function handleGeoZoneError() {
    if (geoZoneError) {
      // TODO: change to sentry error once sentry is installed in liisa
      console.error(new Error(geoZoneError));
    }
  }
};

export { DesignBriefPage };
export default DesignBriefPage;
