import { makeStyles, Chip, Switch } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import {
  HasuraQueryFunctions,
  HasuraQueryHumanReadableOperatorEnum,
} from "@yardzen-inc/util";
import { arrayMove } from "react-sortable-hoc";

import { useLazyQuery, NetworkStatus } from "@yardzen-inc/graphql";

import {
  IPlantBasePlantAdaptedHabitatLink,
  IPlantBasePlantBloomTimeLink,
  IPlantBasePlantCadSizeLink,
  IPlantBasePlantCadSpacingLink,
  IPlantBasePlantContainerSizeLink,
  IPlantBasePlantFlowerColorLink,
  IPlantBasePlantFruitColorLink,
  IPlantBasePlantGardenStyleLink,
  IPlantBasePlantGrowthHabitLink,
  IPlantBasePlantHeightClassLink,
  IPlantBasePlantKeyFeatureLink,
  IPlantBasePlantLeafColorLink,
  IPlantBasePlantLightNeedsLink,
  IPlantBasePlantMatureSizeLink,
  IPlantBasePlantNativeRangeLink,
  IPlantBasePlantRedFlagLink,
  IPlantBasePlantTypeLink,
  IPlantBasePlantUsdaZoneLink,
  IPlantBasePlantVipLink,
  IPlantBasePlantWidthClassLink,
  ModelFiletype,
} from "../../Interfaces";
import AssetTable, {
  AssetTableProps,
} from "../../EmployeeView/AssetLibraries/Shared/AssetTable";
import PlantSelectionTableRow from "./PlantSelectionTableRow";
import { YZTypography, formatNumToUSD } from "@yardzen-inc/react-common";
import PlantLibraryFilterBoxOptions from "../../EmployeeView/AssetLibraries/PlantLibrary/PlantLibraryFilterBoxOptions";
import TableCellWithStyles from "../../EmployeeView/AssetLibraries/Shared/AssetTableCellWithStyles";
import { GraphqlConstants } from "../../ConstantValues/graphqlConstants";
import getAssetLibraryOrderBy from "../../EmployeeView/AssetLibraries/getAssetLibraryOrderBy";
import SelectionTableBox from "./Shared/SelectionTableBox";
import cleanedDownloadableFileName from "../../EmployeeView/AssetLibraries/Shared/CleanedDownloadableFileName";
import usePreloadedAssetTableFilters from "../../util/hooks/usePreloadedAssetTableFilters";
import AssetLibraryFilterMenu from "../../EmployeeView/AssetLibraries/Shared/AssetLibraryFilterMenu";
import { AssetLibraryFetchMoreStatus } from "../../ConstantValues/AssetLibraryFetchMoreStatusConstants";
import uuid from "uuid";
import getObjectAndArrayRelationships from "../../EmployeeView/AssetLibraries/Shared/getObjectAndArrayRelationships";
import {
  plantArrayLinksMap,
  plantArrayLinksSubfieldMap,
  plantObjLinksMap,
} from "../../EmployeeView/AssetLibraries/Shared/FilterLinkMaps";
import { AssetLibraryTypeConstants } from "../../ConstantValues/AssetLibraryTypeConstants";
import { ModelFileDownloadButton } from "../ModelFileDownloadButton";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  PLANT_BASE,
  PLANT_BASE_AGGREGATE,
} from "../../../src/EmployeeView/AssetLibraries/Shared/graphqlQueries";
import { useGetLowesRetailer } from "../../util/hooks/useGetLowesRetailer";

let saveTimeout: ReturnType<typeof setTimeout> | null = null;

const listResultName = "plant_base";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    width: "calc(100vw - 20rem)",
  },
  iconUri: {
    width: "6.25rem",
    height: "6.25rem",
    objectFit: "contain",
  },
  longLink: {
    width: "10rem",
    maxWidth: "10rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  errorMessage: {
    position: "relative",
    top: 75,
    left: 10,
  },
  chipFilter: {
    fontSize: 10,
    letterSpacing: 0.5,
    marginRight: "0.1rem",
  },
  filterSwitch: {
    display: "flex",
    alignItems: "center",
    paddingRight: "0.5rem",
  },
}));

interface Props {
  designAssignmentId: string;
  userId: string;
  projectId: string;
  onChangingQuantity: (v: boolean) => void;
  usdaZone: string | null;
  loadingUsdaZone: boolean;
  usdaZoneError: string | null;
  usdaZoneId: string | null;
  geoZoneError: string | null;
  loadingGeoZone: boolean;
  geoZones: { id: string; name: string }[] | null;
  projectGeo?: string;
  links: {
    plant_base_id: string;
  }[];
  dispatch: any;
  onUpdateQuantity: (
    id: string,
    quantity: number,
    ackId: string,
    type: AssetLibraryTypeConstants
  ) => void;
  assetTableProps?: Partial<AssetTableProps>;
  isLowesProject: boolean;
}

function PlantSelectionTable(props: Props) {
  const classes = useStyles();
  const geoZones = useMemo(
    () => new Set(props.geoZones?.map?.((z) => z.name)),
    [props.geoZones]
  );

  const [
    neqFilterState,
    setNeqFilterState,
    hasAnyOfFilterState,
    setHasAnyOfFilterState,
    hasAllOfFilters,
    setHasAllOfFilters,
    search,
    setSearch,
  ] = usePreloadedAssetTableFilters("plants", props.designAssignmentId);

  const [showArchived, setShowArchived] = useState(false);

  const getIdAndQuantityMap = () => {
    const assignment = props.links;
    if (assignment.length) {
      const idMap = {};
      assignment.forEach((link: any) => {
        // @ts-ignore
        if (!idMap[link.plant_base_id]) {
          // @ts-ignore
          idMap[link.plant_base_id] = 1;
        } else {
          // @ts-ignore
          idMap[link.plant_base_id] += 1;
        }
      });
      return idMap;
    } else {
      return {};
    }
  };

  const [idAndQuantityMap] = useState<null | {
    [v: string]: number;
  }>(getIdAndQuantityMap());
  const [queryBy, setQueryBy] = useState({
    field: "updated_at",
    direction: "desc",
  });

  const [activeOrderBy, setActiveOrderBy] = useState({
    column: "updated_at",
    direction: "desc",
  });
  const [useDefaultUsdaZone, setUseDefaultUsdaZone] = useState(true);
  const [useDefaultGeoZones, setUseDefaultGeoZones] = useState(true);
  const [showNativesOnly, setShowNativesOnly] = useState(false);

  const onRemoveDefaultGeoZones = () => {
    setUseDefaultGeoZones(false);
  };

  const onSearch = (term: string) => {
    setSearch(term);
  };

  const [getBases, { data, refetch, error, fetchMore, networkStatus }] =
    useLazyQuery(PLANT_BASE, {
      notifyOnNetworkStatusChange: true,
    });

  const applyUsdaZoneCondition = (condition: any) => {
    if (props.usdaZoneError) return;
    if (props.loadingUsdaZone) return;
    if (!props.usdaZone) return;
    if (!props.usdaZoneId) return;
    if (!useDefaultUsdaZone) return;
    condition.plant_base_plant_usda_zone_links = {
      id_plant_usda_zone: {
        _eq: props.usdaZoneId,
      },
    };
  };

  const applyGeoZoneCondition = (condition: any) => {
    if (props.geoZoneError) return;
    if (props.loadingGeoZone) return;
    if (!props.geoZones) return;
    if (!useDefaultGeoZones) return;
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAllOf,
      fields: [
        {
          data: onlyIdsFromFilters(props.geoZones),
          links: "plant_base_adapted_habitat_links",
          fieldName: "id_plant_adapted_habitat",
        },
      ],
    });
  };

  const applyNativesCondition = (condition: any) => {
    if (!showNativesOnly) return;
    if (props.geoZoneError) return;
    if (props.loadingGeoZone) return;
    if (!props.geoZones) return;
    if (!useDefaultGeoZones) return;
    condition.plant_base_plant_native_range_links = {
      plant_native_range: {
        name: {
          _in: onlyNamesFromFilters(props.geoZones),
        },
      },
    };
  };

  const applyRetailerCondition = (condition: object) => {
    if (!filterByRetailer) {
      return;
    }

    if (typeof lowesRetailer === "object") {
      HasuraQueryFunctions.processAndConditionsNoLinks({
        condition,
        operator: HasuraQueryHumanReadableOperatorEnum.hasAnyOf,
        fields: [
          {
            data: [lowesRetailer.id],
            fieldName: "plant_retailer",
          },
        ],
      });
    }
  };

  const [getCount, { data: dataCount }] = useLazyQuery(PLANT_BASE_AGGREGATE, {
    fetchPolicy: "cache-and-network",
  });

  const [fetchMoreStatus, setFetchMoreStatus] =
    useState<AssetLibraryFetchMoreStatus>(AssetLibraryFetchMoreStatus.standby);

  // if the user has scrolled to the bottom
  // but the network status is not in "ready" state,
  // we want this useEffect to listten for the network status to be ready
  // and as soon as ready, fetch more results.
  useEffect(() => {
    if (networkStatus !== NetworkStatus.ready) return;
    if (fetchMoreStatus !== AssetLibraryFetchMoreStatus.shouldFetch) return;
    fetchMoreResults();
    setFetchMoreStatus(AssetLibraryFetchMoreStatus.fetching);
  }, [fetchMoreStatus, networkStatus]);

  useEffect(() => {
    if (data === undefined) return;
    setFetchMoreStatus(AssetLibraryFetchMoreStatus.standby);
  }, [data]);

  useEffect(() => {
    getBases({
      variables: getVariables(),
    });
    getCount({
      variables: getVariables(),
    });
  }, [getBases]);

  const onlyIdsFromFilters = (v: any[]): string[] => {
    return v.map((each) => each.id);
  };

  const onlyNamesFromFilters = (v: any[]): string[] => {
    return v.map((each) => each.name);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const column = urlParams.get("sortColumn");
    const direction = urlParams.get("sortDirection");
    if (column && direction) {
      setActiveOrderBy({
        column,
        direction,
      });
    } else {
      setActiveOrderBy({ column: "updated_at", direction: "desc" });
    }
  }, [window.location.search]);

  const [loadingRetailer, lowesRetailer] =
    useGetLowesRetailer("plant_retailer");

  const [filterByRetailer, setFilterByRetailer] = useState<Boolean>(false);

  useEffect(() => {
    if (props.isLowesProject) {
      if (!loadingRetailer && typeof lowesRetailer === "object") {
        setFilterByRetailer(true);
      }
    }
  }, [props.isLowesProject, lowesRetailer, loadingRetailer]);

  const getVariables = (useOffset?: boolean, forceShowArchived?: boolean) => {
    const _search = search ? `%${search}%` : "%%";
    const condition: any = {};
    if (search) {
      condition._or = [
        { scientific_name: { _ilike: _search } },
        { common_name: { _ilike: _search } },
      ];
    }
    if (typeof forceShowArchived === "boolean") {
      condition.archived = {
        _eq: forceShowArchived,
      };
    } else {
      condition.archived = {
        _eq: showArchived,
      };
    }

    applyUsdaZoneCondition(condition);
    applyGeoZoneCondition(condition);
    applyNativesCondition(condition);
    applyRetailerCondition(condition);

    // Designers should only see released assets, ie. visibility === "ALL"
    condition.visibility = {
      _eq: "ALL",
    };

    const [hasAllOfObjectRelationships, hasAllOfArrayRelationships] =
      getObjectAndArrayRelationships(
        hasAllOfFilters,
        plantArrayLinksMap,
        plantObjLinksMap,
        plantArrayLinksSubfieldMap
      );
    HasuraQueryFunctions.processAndConditionsNoLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAllOf,
      fields: hasAllOfObjectRelationships,
    });
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAllOf,
      fields: hasAllOfArrayRelationships,
    });
    const [hasAnyOfObjectRelationships, hasAnyOfArrayRelationships] =
      getObjectAndArrayRelationships(
        hasAnyOfFilterState,
        plantArrayLinksMap,
        plantObjLinksMap,
        plantArrayLinksSubfieldMap
      );
    HasuraQueryFunctions.processAndConditionsNoLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAnyOf,
      fields: hasAnyOfObjectRelationships,
    });
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAnyOf,
      fields: hasAnyOfArrayRelationships,
    });
    const [hasNoneOfObjectRelationships, hasNoneOfArrayRelationships] =
      getObjectAndArrayRelationships(
        neqFilterState,
        plantArrayLinksMap,
        plantObjLinksMap,
        plantArrayLinksSubfieldMap
      );
    HasuraQueryFunctions.processAndConditionsNoLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasNoneOf,
      fields: hasNoneOfObjectRelationships,
    });
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasNoneOf,
      fields: hasNoneOfArrayRelationships,
    });
    return {
      condition,
      offset: useOffset ? data[listResultName].length : 0,
      orderBy: getAssetLibraryOrderBy(queryBy.field, queryBy.direction),
    };
  };

  useEffect(() => {
    if (!refetch) {
      return;
    }
    saveTimeout = setTimeout(async () => {
      refetch(getVariables());
      getCount({
        variables: getVariables(),
      });
    }, 300);

    return () => {
      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }
    };
  }, [
    refetch,
    search,
    neqFilterState,
    hasAllOfFilters,
    hasAnyOfFilterState,
    useDefaultUsdaZone,
    useDefaultGeoZones,
    showNativesOnly,
    filterByRetailer,
  ]);

  const requestRefetch = () => {
    if (!refetch) {
      return;
    }
    return refetch({ ...getVariables() });
  };

  const requestMoreResults = () => {
    setFetchMoreStatus(AssetLibraryFetchMoreStatus.shouldFetch);
  };

  const fetchMoreResults = async () => {
    if (!fetchMore) {
      return alert(
        "Something went wrong. Please reload the screen and try again."
      );
    }
    fetchMore({
      variables: getVariables(true),
      updateQuery: (prev: any, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const iterableProductBase = Array.isArray(prev[listResultName])
          ? prev[listResultName]
          : [];
        const newArray = Array.from(
          new Set([...iterableProductBase, ...fetchMoreResult[listResultName]])
        );
        // it's possible that the apollo caching mechanism may
        // provide the fetchMoreResult from the previous iteration
        // if the user is moving quickly, so let's adjust for that
        // and ensure we're not duplicating any data
        let uniqueArray: any[] = [];
        for (let it = 0; it < newArray.length; it++) {
          if (uniqueArray.some((line) => line.id === newArray[it].id)) {
            continue;
          }
          uniqueArray.push(newArray[it]);
        }
        const resultToSend = Object.assign({}, prev, {
          [listResultName]: uniqueArray,
        });
        return resultToSend;
      },
    });
  };

  const onSort = (v: number, queryName: string): any => {
    if (!refetch) {
      return;
    }
    let stateObj = {};
    if (v === 0) {
      window.history.replaceState(stateObj, "", `/plants-library?`);
      return refetch({ ...getVariables() });
    }
    const direction = v === -1 ? "desc" : "asc";
    if (window.history.replaceState) {
      //prevents browser from storing history with each change:
      window.history.replaceState(
        stateObj,
        "",
        `/plants-library?sortColumn=${queryName}&sortDirection=${direction}`
      );
    }
    setQueryBy({ field: queryName, direction });
    refetch({
      ...getVariables(),
      orderBy: getAssetLibraryOrderBy(queryName, direction),
    });
  };

  const toggleArchived = () => {
    if (!refetch) {
      return alert(
        "Something went wrong. Please reload the screen and try again."
      );
    }
    const newArchived = !showArchived;
    refetch({ ...getVariables(undefined, newArchived) });
    getCount({
      variables: { ...getVariables(undefined, newArchived) },
    });
    setShowArchived(newArchived);
  };

  const initialColumns = [
    {
      name: "Scientific Name",
      queryName: "scientific_name",
      sortable: true,
      id: "scientific_name",
      renderCellContent: (row: any) => row.scientific_name,
    },
    {
      name: "Common Name",
      id: "common_name",
      queryName: "common_name",
      sortable: true,
      renderCellContent: (row: any) => row.common_name,
    },
    {
      name: "Planting Style",
      id: "plant_base_plant_garden_style_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_garden_style_links
          ?.map(
            (style: IPlantBasePlantGardenStyleLink) =>
              style.plant_garden_style.name
          )
          .join(", "),
    },
    {
      name: "Image",
      id: "icon_uri",
      renderCellContent: (row: any) => (
        <img className={classes.iconUri} src={row.icon_uri} />
      ),
    },
    {
      name: "USDA Zone",
      id: "plant_base_plant_usda_zone_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_usda_zone_links
          ?.map(
            (style: IPlantBasePlantUsdaZoneLink) => style.plant_usda_zone.name
          )
          .join(", "),
    },
    {
      name: "Geo",
      id: "plant_base_adapted_habitat_links",
      renderCellContent: (row: any) =>
        row.plant_base_adapted_habitat_links
          ?.map(
            (style: IPlantBasePlantAdaptedHabitatLink) =>
              style.plant_adapted_habitat.name
          )
          .join(", "),
    },
    {
      name: "Native Geo Match",
      id: "plant_base_native_geo_match",
      renderCellContent: (row: any) =>
        row.plant_base_plant_native_range_links?.some?.(
          (range: IPlantBasePlantNativeRangeLink) => {
            return geoZones.has(range.plant_native_range.name);
          }
        ) ? (
          <img
            style={{ height: "25px" }}
            src="https://public-assets.yardzen.com/NativeBadge.png"
            alt="Native match badge"
          />
        ) : (
          ""
        ),
    },
    {
      name: "Native Range",
      id: "plant_base_plant_native_range_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_native_range_links
          ?.map(
            (style: IPlantBasePlantNativeRangeLink) =>
              style.plant_native_range.name
          )
          .join(", "),
    },
    {
      name: "VIP",
      id: "plant_base_plant_vip_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_vip_links
          ?.map((style: IPlantBasePlantVipLink) => style.plant_vip.name)
          .join(", "),
    },
    {
      name: "Key Feature",
      id: "plant_base_plant_key_feature_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_key_feature_links
          ?.map(
            (style: IPlantBasePlantKeyFeatureLink) =>
              style.plant_key_feature.name
          )
          .join(", "),
    },
    {
      name: "Type",
      id: "plant_base_plant_type_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_type_links
          ?.map((style: IPlantBasePlantTypeLink) => style.plant_type.name)
          .join(", "),
    },
    {
      name: "Light Needs",
      id: "plant_base_plant_light_needs_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_light_needs_links
          ?.map(
            (style: IPlantBasePlantLightNeedsLink) =>
              style.plant_light_needs.name
          )
          .join(", "),
    },
    {
      name: "Water Needs",
      id: "waterNeedsByPlantWaterNeeds_simplejoin",
      sortable: true,
      queryName: "waterNeedsByPlantWaterNeeds.name",
      renderCellContent: (row: any) => row.waterNeedsByPlantWaterNeeds?.name,
    },
    {
      name: "Mature Width",
      id: "matureWidthByPlantMatureWidth_simplejoin",
      sortable: true,
      queryName: "matureWidthByPlantMatureWidth.name",
      renderCellContent: (row: any) => row.matureWidthByPlantMatureWidth?.name,
    },
    {
      name: "Mature Height",
      id: "matureHeightByPlantMatureHeight_simplejoin",
      sortable: true,
      queryName: "matureHeightByPlantMatureHeight.name",
      renderCellContent: (row: any) =>
        row.matureHeightByPlantMatureHeight?.name,
    },
    {
      name: "Minimum Space Needed to Plant",
      id: "minimumSpaceToPlantByPlantminimumSpaceToPlant_simplejoin",
      sortable: true,
      queryName: "minimumSpaceToPlantByPlantminimumSpaceToPlant.name",
      renderCellContent: (row: any) =>
        row.minimumSpaceToPlantByPlantminimumSpaceToPlant?.name,
    },
    {
      name: "Height Class",
      id: "plant_base_plant_height_class_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_height_class_links
          ?.map(
            (style: IPlantBasePlantHeightClassLink) =>
              style.plant_height_class.name
          )
          .join(", "),
    },
    {
      name: "Width Class",
      id: "plant_base_plant_width_class_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_width_class_links
          ?.map(
            (style: IPlantBasePlantWidthClassLink) =>
              style.plant_width_class.name
          )
          .join(", "),
    },
    {
      name: "Mature Size",
      id: "plant_base_plant_mature_size_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_mature_size_links
          ?.map(
            (style: IPlantBasePlantMatureSizeLink) =>
              style.plant_mature_size.name
          )
          .join(", "),
    },
    {
      name: "Red Flag",
      id: "plant_base_plant_red_flag_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_red_flag_links
          ?.map(
            (style: IPlantBasePlantRedFlagLink) => style.plant_red_flag.name
          )
          .join(", "),
    },
    {
      name: "Red Flag Info",
      id: "red_flag_info",
      sortable: true,
      queryName: "red_flag_info",
      renderCellContent: (row: any) => row.red_flag_info,
    },
    {
      name: "Deciduous or Evergreen",
      sortable: true,
      queryName: "deciduousOrEvergreenByPlantDeciduousOrEvergreen.name",
      id: "deciduousOrEvergreenByPlantDeciduousOrEvergreen_simplejoin",
      renderCellContent: (row: any) =>
        row.deciduousOrEvergreenByPlantDeciduousOrEvergreen?.name,
    },
    {
      name: "Retailer",
      sortable: true,
      queryName: "retailerByPlantRetailer.name",
      id: "retailerByPlantRetailer_simplejoin",
      renderCellContent: (row: any) => row.retailerByPlantRetailer?.name,
    },
    {
      name: "Leaf Color",
      id: "plant_base_plant_leaf_color_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_leaf_color_links
          ?.map(
            (style: IPlantBasePlantLeafColorLink) => style.plant_leaf_color.name
          )
          .join(", "),
    },
    {
      name: "Flower Color",
      id: "plant_base_plant_flower_color_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_flower_color_links
          ?.map(
            (style: IPlantBasePlantFlowerColorLink) =>
              style.plant_flower_color.name
          )
          .join(", "),
    },
    {
      name: "Bloom Time",
      id: "plant_base_plant_bloom_time_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_bloom_time_links
          ?.map(
            (style: IPlantBasePlantBloomTimeLink) => style.plant_bloom_time.name
          )
          .join(", "),
    },
    {
      name: "Fruit Color",
      id: "plant_base_plant_fruit_color_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_fruit_color_links
          ?.map(
            (style: IPlantBasePlantFruitColorLink) =>
              style.plant_fruit_color.name
          )
          .join(", "),
    },
    {
      name: "Additional Info",
      id: "additional_info",
      sortable: true,
      queryName: "additional_info",
      renderCellContent: (row: any) => (
        <div className={classes.longLink}>
          <a href={row.additional_info} target="_blank">
            {row.additional_info}
          </a>
        </div>
      ),
    },
    {
      name: "Price",
      id: "unit_cost",
      queryName: "unit_cost",
      renderCellContent: (row: any) =>
        row.unit_cost ? formatNumToUSD(parseFloat(row.unit_cost)) : "--",
    },
    {
      name: "Growth Habit",
      id: "plant_base_plant_growth_habit_links",
      sortable: true,
      renderCellContent: (row: any) =>
        row.plant_base_plant_growth_habit_links
          ?.map(
            (style: IPlantBasePlantGrowthHabitLink) =>
              style.plant_growth_habit.name
          )
          .join(", "),
    },
    {
      name: "Sketchup File",
      id: "sketchup_file_link",
      queryName: "sketchup_file_link",
      sortable: true,
      renderCellContent: (row: any) => (
        <ModelFileDownloadButton
          filetype={ModelFiletype.sketchup}
          filenameOrUrl={row.sketchup_file_link}
          downloadableFileName={cleanedDownloadableFileName(
            row.scientific_name
          )}
        />
      ),
    },
    {
      name: "Lumion File",
      id: "lumion_file_link",
      queryName: "lumion_file_link",
      sortable: true,
      renderCellContent: (row: any) => (
        <ModelFileDownloadButton
          filetype={ModelFiletype.lumion}
          filenameOrUrl={row.lumion_file_link}
          downloadableFileName={cleanedDownloadableFileName(
            row.scientific_name
          )}
        />
      ),
    },
    {
      name: "Lumion File Name",
      id: "lumion_file_name",
      renderCellContent: (row: any) => row.lumion_file_name,
    },
    {
      name: "CAD Symbol ~Size~",
      id: "plant_base_plant_cad_symbol_size_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_cad_symbol_size_links
          ?.map(
            (style: IPlantBasePlantCadSizeLink) =>
              style.plant_cad_symbol_size.name
          )
          .join(", "),
    },
    {
      name: "CAD Symbol ~Spacing~",
      id: "plant_base_plant_cad_symbol_spacing_links",
      renderCellContent: (row: any) =>
        row.plant_base_plant_cad_symbol_spacing_links
          ?.map(
            (style: IPlantBasePlantCadSpacingLink) =>
              style.plant_cad_symbol_spacing.name
          )
          .join(", "),
    },
    {
      name: "Container Size",
      id: "plant_base_plant_container_size_links",
      sortable: true,
      renderCellContent: (row: any) =>
        row.plant_base_plant_container_size_links
          ?.map(
            (style: IPlantBasePlantContainerSizeLink) =>
              style.plant_container_size.name
          )
          .join(", "),
    },
  ];

  const [columns, setColumns] = useState(initialColumns);

  const InitialTableCells = (row: any) => {
    // EXPLANATION OF INITIAL TABLE CELLS:
    // Yup, these are matrices. What we're trying to do
    // is keep the state of the data in the table (columns x rows)
    // working while we do manipulations such as re-ordering columns
    // or sorting data. Sorting data triggers the database, so the
    // specific data in each row changes, while we keep the shape of
    // the table intact. We store "row" data at [0][1] and we know to
    // look for it there. This is used so we can use the "update" feature
    // which needs the non-html version of the data.
    const cells = columns.map((column) => {
      let innerContent = column.renderCellContent(row);

      return [
        <TableCellWithStyles
          id={column.id}
          key={`${row.id}-${column.id}`}
          align="right"
          // @ts-ignore
          hidden={hiddenColumns?.[column.id]}
        >
          {innerContent}
        </TableCellWithStyles>,
      ];
    });

    cells[0].push(row);
    return cells;
  };

  const [hiddenColumns, setHiddenColumns] = useState({});

  const onReorder = (oldIndex: number, newIndex: number) => {
    setColumns(arrayMove(columns, oldIndex, newIndex));
  };

  const handleToggleColumnVisible = (id: string) => {
    const updatedHiddenColumns = { ...hiddenColumns };
    // @ts-ignore
    updatedHiddenColumns[id] = !updatedHiddenColumns[id];
    setHiddenColumns(updatedHiddenColumns);
  };

  const onUpdateQuantity = (id: string, quantity: number) => {
    props.onUpdateQuantity(
      id,
      quantity,
      uuid.v4(),
      AssetLibraryTypeConstants.plant
    );
  };

  const tableContainerRef = useRef(null);

  const onScroll = () => {
    const target: any = tableContainerRef.current;
    if (!target) return;
    let pos = target.scrollTop + target.offsetHeight;
    let max = target.scrollHeight;
    // fetching before user hits the bottom of the screen so by
    // the time the user gets to the bottom, stuff will have loaded.
    if (
      pos >= max - 700 &&
      data.plant_base.length % GraphqlConstants.resultsPerQuery === 0 &&
      data.plant_base.length !== 0 &&
      data.plant_base.length !== dataCount?.plant_base_aggregate.aggregate.count
    ) {
      requestMoreResults();
    }
  };

  const onRemoveDefaultUsdaZone = () => {
    setUseDefaultUsdaZone(false);
  };

  useEffect(() => {
    if (!props.usdaZone) {
      alert(
        "We were unable to auto-detect the USDA Zone. Please apply it manually."
      );
    }
  }, []);

  const hasMoreResults = useRef(
    data?.plant_base.length !== dataCount?.plant_base_aggregate.aggregate.count
  );

  const handleNativesOnlyChange = () => {
    setShowNativesOnly(!showNativesOnly);
  };

  const renderFilters = () => {
    const secondaryFilters = [];
    if (props.usdaZone && useDefaultUsdaZone) {
      secondaryFilters.push(
        <Chip
          size="small"
          color="primary"
          label={`USDA ZONE: ${props.usdaZone}`}
          onDelete={onRemoveDefaultUsdaZone}
          className={classes.chipFilter}
        />
      );
    }

    if (props.geoZones && useDefaultGeoZones) {
      secondaryFilters.push(
        <Chip
          size="small"
          color="primary"
          label={`Geo: ${props.geoZones
            .map((geoZone) => geoZone.name)
            .join(", ")}`}
          onDelete={onRemoveDefaultGeoZones}
          className={classes.chipFilter}
        />
      );
      secondaryFilters.push(
        <div className={classes.filterSwitch}>
          <Switch
            checked={showNativesOnly}
            onChange={handleNativesOnlyChange}
            color="primary"
            name="nativesCheck"
            inputProps={{ "aria-label": "Show Only Native Plants" }}
          />
          <YZTypography variant="caption">Show Only Native Plants</YZTypography>
        </div>
      );
    }

    if (filterByRetailer) {
      secondaryFilters.push(
        <Chip
          size="small"
          color="primary"
          label="Retailer: Lowe's"
          onDelete={() => setFilterByRetailer(false)}
          className={classes.chipFilter}
        />
      );
    }

    return (
      <AssetLibraryFilterMenu
        options={PlantLibraryFilterBoxOptions}
        neqFilterState={neqFilterState}
        setNeqFilterState={setNeqFilterState}
        hasAnyOfFilterState={hasAnyOfFilterState}
        setHasAnyOfFilterState={setHasAnyOfFilterState}
        hasAllOfFilters={hasAllOfFilters}
        setHasAllOfFilters={setHasAllOfFilters}
        secondaryFilters={secondaryFilters}
      />
    );
  };

  const memoizedAssetTable = useMemo(
    () => (
      <AssetTable
        {...props.assetTableProps}
        showLoadMoreButton={hasMoreResults.current}
        contentHeight="calc(100vh - 15.6rem)"
        onScroll={onScroll}
        tableContainerRef={tableContainerRef}
        onReorder={onReorder}
        tableColumns={columns}
        hiddenColumns={hiddenColumns}
        onToggleColumnVisible={handleToggleColumnVisible}
        search={search}
        hideArchive
        resultsCount={dataCount?.plant_base_aggregate.aggregate.count || 0}
        initialTableCells={InitialTableCells}
        activeOrderBy={activeOrderBy}
        searchLabel="Search by common or scientific name"
        toggleArchived={toggleArchived}
        showArchived={showArchived}
        onSearch={onSearch}
        requestMoreResults={requestMoreResults}
        networkStatus={networkStatus}
        data={data}
        fixedHeader
        fixedTop={66}
        error={error}
        requestRefetch={requestRefetch}
        addNewAssetComponent={(assetProps) => (
          <div>
            This feature is disabled. Please contact support for more
            information.
          </div>
        )}
        rowComponent={(rowProps) => (
          <PlantSelectionTableRow
            {...rowProps}
            onUpdateQuantity={onUpdateQuantity}
            idAndQuantityMap={idAndQuantityMap as object}
            refetch={refetch}
          />
        )}
        filters={renderFilters}
        onSort={onSort}
        title="Plants"
        listResultName={listResultName}
      />
    ),
    [
      data,
      dataCount,
      hiddenColumns,
      idAndQuantityMap,
      networkStatus,
      neqFilterState,
      hasAnyOfFilterState,
      hasAllOfFilters,
      search,
      useDefaultUsdaZone,
      useDefaultGeoZones,
      showNativesOnly,
      filterByRetailer,
    ]
  );

  return <SelectionTableBox>{memoizedAssetTable}</SelectionTableBox>;
}

export default PlantSelectionTable;
