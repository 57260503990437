import {
  CreatePromotionalCodeDto,
  PromotionalCodeCategory,
} from "src/api/pangaeaTypes";
import { PromotionalCodeCreateCardProps } from "../PromotionalCodeCreateCard";

export function promotionalCodeCreateToDefaultValues(
  dto: CreatePromotionalCodeDto
) {
  const discountType = /\%/g.test(dto.discount) ? "PERCENTAGE" : "FIXED";
  return {
    ...dto,
    maxRedemptions: dto.maxRedemptions.toString(),
    applicationSuccessMessage: dto.applicationSuccessMessage ?? undefined,
    discountType,
    discount:
      discountType === "PERCENTAGE"
        ? dto.discount.replace(/\%/g, "")
        : (parseInt(dto.discount) / 100).toFixed(2),
    category: dto.category,
    additionalDiscounts: dto.additionalDiscounts?.map((d) => ({
      ...d,
      discount:
        d.discountType === "PERCENTAGE"
          ? d.discount.replace(/\%/g, "")
          : (parseInt(d.discount) / 100).toFixed(2),
    })),
  };
}
export function defaultValuesToPromotionalCodeCreate(
  values: PromotionalCodeCreateCardProps["defaultValues"]
) {
  const transformed: CreatePromotionalCodeDto = {
    ...values,
    maxRedemptions: values.maxRedemptions ? parseInt(values.maxRedemptions) : 0,
    discount:
      values.discountType === "PERCENTAGE"
        ? `%${parseFloat(values.discount ?? "0").toFixed(2)}`
        : Math.floor(parseFloat(values.discount ?? "0") * 100).toString(),
    deactivated: false,
    code: values.code ?? "",
    endTime: values.endTime ?? new Date().toISOString(),
    startTime: values.startTime ?? new Date().toISOString(),
    applicationSuccessMessage: values.applicationSuccessMessage || null,
    category: (values.category as PromotionalCodeCategory) ?? undefined,
    additionalDiscounts: values.additionalDiscounts?.map((d) => ({
      ...d,
      discount:
        d.discountType === "PERCENTAGE"
          ? `%${parseFloat(d.discount ?? "0").toFixed(2)}`
          : Math.floor(parseFloat(d.discount ?? "0") * 100).toString(),
    })),
  };
  return transformed;
}
