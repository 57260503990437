import { PackageType } from "../../Interfaces";
import capitalizeFirstLetter from "../capitalizeFirstLetter";

export function getPackageDisplayName(packageType: string | undefined): string {
  // Whole Home package has been renamed Outdoor Transformation but is still saved as 'whole home'
  if (packageType === PackageType.OutdoorTransformation) {
    return "Outdoor Transformation";
  }
  if (!packageType) {
    return "Unknown";
  }
  return capitalizeFirstLetter(packageType);
}
