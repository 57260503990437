import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@material-ui/core";
import * as React from "react";

interface IOverrideReasonRadioSelectProps {
  overrideReason: string | null;
  overrideReasonCustomText: string;
  overideReasonOptions: string[];
  setOverrideReason: (reason: string) => void;
  setOverrideReasonCustomText: (customText: string) => void;
  disabled: boolean;
}

const OverrideReasonRadioSelect: React.FunctionComponent<IOverrideReasonRadioSelectProps> =
  ({
    overideReasonOptions,
    overrideReason,
    overrideReasonCustomText,
    setOverrideReason,
    setOverrideReasonCustomText,
    disabled,
  }) => {
    return (
      <Box>
        <RadioGroup
          value={overrideReason}
          onChange={(e) =>
            disabled ? null : setOverrideReason(e.target.value)
          }
        >
          {overideReasonOptions.map((reason) => {
            return (
              <Box display="flex" key={reason}>
                <FormControlLabel
                  value={reason}
                  control={<Radio />}
                  label={reason}
                />
                {reason === "Other" && (
                  <Box ml={2}>
                    <TextField
                      disabled={overrideReason !== "Other"}
                      value={overrideReasonCustomText}
                      placeholder="Override reason"
                      onChange={(e) =>
                        setOverrideReasonCustomText(e.target.value)
                      }
                    />
                  </Box>
                )}
              </Box>
            );
          })}
        </RadioGroup>
      </Box>
    );
  };

export { OverrideReasonRadioSelect };
