import React, { createContext, FC, useContext, useMemo } from "react";
import * as colors from "@yardzen-inc/colors";
import { ServerConfiguration } from "@yardzen-inc/colors";

export interface ExteriorDesignProviderProps {
  getBearerToken: () => string | Promise<string>;
}

const SERVER = process.env["REACT_APP_COLORS_API_URL"];

class ExteriorDesignClient {
  private _colors: colors.ColorsApi;
  private _color: colors.ColorApi;
  private _colorResponse: colors.ColorResponseApi;
  private _colorResponses: colors.ColorResponsesApi;
  private _colorBulk: colors.ColorBulkApi;

  public get colors(): colors.ColorsApi {
    return this._colors;
  }

  public get color(): colors.ColorApi {
    return this._color;
  }

  public get colorResponse(): colors.ColorResponseApi {
    return this._colorResponse;
  }

  public get colorResponses(): colors.ColorResponsesApi {
    return this._colorResponses;
  }

  public get colorBulk(): colors.ColorBulkApi {
    return this._colorBulk;
  }

  constructor(getBearerToken: ExteriorDesignProviderProps["getBearerToken"]) {
    const config = colors.createConfiguration({
      baseServer: SERVER ? new ServerConfiguration(SERVER, {}) : void 0,
      authMethods: {
        BearerAuth: {
          tokenProvider: {
            getToken: async () => {
              try {
                const token = await getBearerToken();
                return `${token}`;
              } catch (error) {
                console.error("Failed to get bearer token");
                console.error(error);
                return "";
              }
            },
          },
        },
      },
    });

    this._colors = new colors.ColorsApi(config);
    this._color = new colors.ColorApi(config);
    this._colorResponse = new colors.ColorResponseApi(config);
    this._colorResponses = new colors.ColorResponsesApi(config);
    this._colorBulk = new colors.ColorBulkApi(config);
  }
}
const ExteriorDesignContext = createContext<ExteriorDesignClient>({} as any);

const ExteriorDesignProvider: FC<ExteriorDesignProviderProps> = ({
  getBearerToken,
  children,
}) => {
  const client = useMemo(createClient, [getBearerToken]);
  return (
    <ExteriorDesignContext.Provider value={client}>
      {children}
    </ExteriorDesignContext.Provider>
  );
  function createClient() {
    return new ExteriorDesignClient(getBearerToken);
  }
};

const useExteriorDesignClient = (): ExteriorDesignClient => {
  return useContext(ExteriorDesignContext);
};

export {
  ExteriorDesignContext,
  ExteriorDesignProvider,
  ExteriorDesignClient,
  useExteriorDesignClient,
};
