import * as React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  TextField,
  Button,
} from "@material-ui/core";
import { DesignAssignment } from "@yardzen-inc/models";
import CloseButton from "../../Components/CloseButton";
import moment from "moment";
import environmentConstants from "../../ConstantValues/environmentConstants";

export interface Props {
  activeAssignment: DesignAssignment;
  open: boolean;
  onClose: () => void;
}

export const EditDueDateModal = ({
  open,
  activeAssignment,
  onClose,
}: Props) => {
  const [newDate, setNewDate] = React.useState<string>(
    getDomDateStringFromActiveAssignment
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(reset, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box p={1} pr={8} component="form" onSubmit={handleSubmit}>
        <Typography variant="h5" gutterBottom>
          Edit Due Date
        </Typography>
        <CloseButton onClick={onClose} top={8} right={8} />
      </Box>
      <DialogContent>
        <Box p={1}>
          <TextField
            fullWidth
            type="date"
            value={newDate}
            onChange={({ currentTarget: { value } }) => setNewDate(value)}
          />
        </Box>
        <Button
          color="primary"
          style={{ width: "100%" }}
          onClick={updateDueDate}
          disabled={loading}
        >
          Change
        </Button>
      </DialogContent>
    </Dialog>
  );

  function getDomDateStringFromActiveAssignment(): string {
    if (!activeAssignment.dueDate) {
      return "";
    }

    const date = moment(activeAssignment.dueDate);

    if (!date.isValid()) {
      if (process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT) {
        throw new Error("assignment has invalid date");
      }

      return "";
    }

    return date.format("YYYY-MM-DD") ?? "";
  }

  function handleSubmit(e: React.FormEvent): void {
    e.preventDefault();
    updateDueDate();
  }

  async function updateDueDate(): Promise<void> {
    const date = moment(newDate);

    if (!date.isValid()) {
      alert("please enter a valid due date");
      return setNewDate("");
    }

    activeAssignment.dueDate = date.unix() * 1000;

    await activeAssignment.save();
    onClose();
  }

  function reset(): void {
    setNewDate(getDomDateStringFromActiveAssignment());
    setLoading(false);
  }
};

export default EditDueDateModal;
