import { useEffect, useState } from "react";
import { DssStateMap } from "./dssStatesOrderedMap";
import firebase from "firebase/compat/app";

export interface UseDssStatesOrderedMap {
  (): Readonly<DssStateMap> | null;
}

const useDssStatesOrderedMap: UseDssStatesOrderedMap = () => {
  const [dssStatesOrderedMapWithV3, setDssStatesOrderedMapWithV3] =
    useState<Readonly<DssStateMap> | null>(null);

  useEffect(() => {
    // Fetches dss state data from firebase-functions, transforms the responses
    // into Readonly DssStateMaps (instantiateDssMap function), and sets the
    // state for both the default map and the map with v3 stages included
    async function _getDssStatesFromFirebase() {
      const response = await firebase
        .functions()
        .httpsCallable("getDssStatesOrderedMapData")();

      const { dssStatesOrderedMapWithV3Data } = response.data;

      setDssStatesOrderedMapWithV3(
        instantiateDssMap(dssStatesOrderedMapWithV3Data)
      );
    }

    _getDssStatesFromFirebase();
  }, []);

  return dssStatesOrderedMapWithV3;
};

// Takes dss states data array and turns it into a readonly DssStateMap instance
function instantiateDssMap(data: any[]) {
  return Object.freeze(new DssStateMap(data));
}

export { useDssStatesOrderedMap };
export default useDssStatesOrderedMap;
