import * as React from "react";
import { makeStyles, Typography, Tooltip, Dialog } from "@material-ui/core";
import { Profile, Revision, YardDifficultyRating } from "@yardzen-inc/models";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import { BudgetEstimateRender } from "../BudgetEstimateRender";

interface Props {
  canEdit: boolean;
  setCanEdit: (canEdit: boolean) => void;
  isEmployee?: true;
  revision: Revision;
  conceptualRevisionId?: string;
  finalRevisionId?: string;
  clientRecord: Profile;
  projectId: string;
  yardDifficultyRating: YardDifficultyRating | null;
  setBlurbModalOpen: (open: boolean) => void;
  sendRevisionEditedNoti: () => void;
  uploadBudgetEstimateImage: (imageSrc: string) => void;
  mediaIncludesBudgetEstimateRender: boolean;
}

function AfterSubmission({
  canEdit,
  setCanEdit,
  isEmployee,
  projectId,
  yardDifficultyRating,
  revision,
  setBlurbModalOpen,
  sendRevisionEditedNoti,
  uploadBudgetEstimateImage,
  mediaIncludesBudgetEstimateRender,
}: Props): React.ReactElement {
  const classes = useStyles();
  const [budgetEstimateModalOpen, setBudgetEstimateModalOpen] =
    React.useState(false);
  const handleBudgetEstimateModalClose = () => {
    setBudgetEstimateModalOpen(false);
  };
  const [budgetCheckModalOpen, setBudgetCheckModalOpen] =
    React.useState<boolean>(false);

  return (
    <div style={{ width: "100%" }}>
      <Typography
        style={{ marginBottom: "2rem", padding: "0 1.25rem" }}
        variant="h4"
      >
        Submitted for review
      </Typography>
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <YZButton
            color="primary"
            variant="contained"
            onClick={() => {
              setCanEdit(!canEdit);
              if (isEmployee || canEdit) return;
              sendRevisionEditedNoti();
            }}
          >
            {canEdit ? "Done editing" : "edit"}
          </YZButton>
          {canEdit && (
            <Typography variant="caption" color="error">
              Changes are saved automatically
            </Typography>
          )}
        </div>

        {isEmployee && (
          <div>
            <YZButton
              onClick={() => setBudgetEstimateModalOpen(true)}
              style={{ marginRight: "15px" }}
              disabled={mediaIncludesBudgetEstimateRender}
            >
              Add Budget Estimate Render
            </YZButton>
            <Dialog
              PaperProps={{
                style: { padding: "1rem" },
              }}
              open={budgetEstimateModalOpen}
              onClose={handleBudgetEstimateModalClose}
            >
              <BudgetEstimateRender
                handleClose={handleBudgetEstimateModalClose}
                uploadBudgetEstimateImage={uploadBudgetEstimateImage}
                projectId={projectId}
                yardDifficultyRating={yardDifficultyRating}
                revisionId={revision.id}
              />
            </Dialog>
            <Tooltip title="Write blurb, select revision, and send">
              <span>
                <YZButton
                  onClick={() => {
                    if (mediaIncludesBudgetEstimateRender) {
                      setBlurbModalOpen(true);
                    } else {
                      setBudgetCheckModalOpen(true);
                    }
                  }}
                  variant="contained"
                >
                  Send To Client
                </YZButton>
                <Dialog
                  PaperProps={{
                    style: { padding: "2rem" },
                  }}
                  open={budgetCheckModalOpen}
                  onClose={() => setBudgetCheckModalOpen(false)}
                >
                  <>
                    <YZTypography style={{ marginBottom: "10px" }}>
                      Are you sure you don't want to include a Budget Estimate
                      Render?
                    </YZTypography>
                    <YZButton
                      onClick={() => {
                        setBudgetCheckModalOpen(false);
                        setBudgetEstimateModalOpen(true);
                      }}
                      style={{ margin: "15px 0" }}
                      variant="contained"
                    >
                      Add Budget Estimate Render
                    </YZButton>
                    <YZButton
                      onClick={() => {
                        setBudgetCheckModalOpen(false);
                        setBlurbModalOpen(true);
                      }}
                    >
                      Proceed without adding
                    </YZButton>
                  </>
                </Dialog>
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0 2rem 0 2rem",
  },
});

export default AfterSubmission;
