import {
  Box,
  Card,
  makeStyles,
  Typography,
  CardContent,
} from "@material-ui/core";
import { Add, GetApp, CloudUpload } from "@material-ui/icons";
import * as React from "react";
import { useHistory } from "react-router";
import { ACTIVE_GEOZONE_FILE, useQuery } from "@yardzen-inc/graphql";

interface IZipCodeManagementOptionsProps {
  baseRoute: string;
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridAutoColumns: "auto",
    gridColumnGap: spacing(4),
    gridRowGap: spacing(4),
    padding: spacing(3),
  },
  card: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: "5rem",
    height: "5rem",
  },
}));

const ZipCodeManagementOptions: React.FunctionComponent<IZipCodeManagementOptionsProps> =
  ({ baseRoute }) => {
    const classes = useStyles();
    const history = useHistory();

    const { data: activeGeozoneFile, loading } = useQuery(ACTIVE_GEOZONE_FILE, {
      fetchPolicy: "network-only",
    });

    const downloadActiveGeozoneFile = () => {
      if (loading) {
        setTimeout(downloadActiveGeozoneFile, 300);
        return;
      }

      if (!activeGeozoneFile.geozone_file[0]) {
        alert("Error: No geozone file to download.");
        return;
      }

      const link = document.createElement("a");
      link.download = "yardzen-active-geozone-file.json";
      link.href = activeGeozoneFile.geozone_file[0].url;
      link.click();
    };

    return (
      <Box className={classes.root}>
        <Card
          onClick={() => {
            history.push(`${baseRoute}/add-new-zip`);
          }}
          className={classes.card}
        >
          <CardContent className={classes.card}>
            <Add className={classes.icon} />
            <Typography>Add new zip code entry</Typography>
          </CardContent>
        </Card>
        <Card onClick={downloadActiveGeozoneFile} className={classes.card}>
          <CardContent className={classes.card}>
            <GetApp className={classes.icon} />
            <Typography>Download current GeoZone file</Typography>
          </CardContent>
        </Card>
        <Card
          onClick={() => {
            history.push(`${baseRoute}/replace-geozone-file`);
          }}
          className={classes.card}
        >
          <CardContent className={classes.card}>
            <CloudUpload className={classes.icon} />
            <Typography>Replace current GeoZone file</Typography>
          </CardContent>
        </Card>
      </Box>
    );
  };

export default ZipCodeManagementOptions;
