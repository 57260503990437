import * as React from "react";
import {
  Box,
  useMediaQuery,
  Typography,
  Theme,
  Divider,
} from "@material-ui/core";
import { Media } from "@yardzen-inc/models";
import { UploadAndMediaGrid } from "./UploadAndMediaGrid";

interface Props {
  userId: string;
  revisionId: string;
  onUpload: (m: Media | null) => void;
}

export const CadDeliverableUploadArea = React.memo(
  ({ userId, revisionId, onUpload }: Props) => {
    const smDown = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("sm")
    );

    const [uploadWidth, flexDir] = React.useMemo(
      () => (smDown ? ["100%", "column"] : ["50%", "row"]),
      [smDown]
    );

    return (
      <Box display="flex" flexDirection="column" alignItems="center" py={2}>
        <Typography align="center" variant="h4">
          CAD Deliverables
        </Typography>
        <Box display="flex" flexDirection={flexDir} width="100%">
          <Box p={1} width={uploadWidth} display="flex" flexDirection="column">
            <Typography variant="h5">Dimensions &amp; Materials</Typography>
            <Divider />
            <UploadAndMediaGrid
              onUpload={onUpload}
              revisionId={revisionId}
              userId={userId}
              fileTag="cad-layout"
              accept={[".pdf"]}
            />
          </Box>
          <Box p={1} width={uploadWidth} display="flex" flexDirection="column">
            <Typography variant="h5">Planting Plan</Typography>
            <Divider />
            <UploadAndMediaGrid
              onUpload={onUpload}
              revisionId={revisionId}
              userId={userId}
              fileTag="cad-landscape"
              accept={[".pdf"]}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection={flexDir} width="100%">
          <Box p={1} width={uploadWidth} display="flex" flexDirection="column">
            <Typography variant="h5">Legend</Typography>
            <Divider />
            <UploadAndMediaGrid
              onUpload={onUpload}
              revisionId={revisionId}
              userId={userId}
              fileTag="cad-legend"
              accept={[".pdf"]}
            />
          </Box>
          <Box p={1} width={uploadWidth} display="flex" flexDirection="column">
            <Typography variant="h5">CAD File</Typography>
            <Divider />
            <UploadAndMediaGrid
              onUpload={onUpload}
              revisionId={revisionId}
              userId={userId}
              accept={[".dwg"]}
              fileTag="cad-dwg"
            />
          </Box>
        </Box>
      </Box>
    );
  },
  (prev, next) => prev.revisionId === next.revisionId
);

export default CadDeliverableUploadArea;
