import {
  Button,
  Dialog,
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Typography,
  Link,
} from "@material-ui/core";
import * as pangaeaClient from "../../../src/api/vanillaPangaeaClient";
import React from "react";
import { Profile, Project } from "@yardzen-inc/models";
import { Close } from "@material-ui/icons";
import { YZTypography } from "@yardzen-inc/react-common";
import { PIPEFY_CARD_BASE_URL } from "../../../src/ConstantValues/pipefyConstants";

interface Props {
  profile: Profile;
  project: Project;
}

function PipefyProjectCardCreateButton(props: Props) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState("");
  const [working, setWorking] = React.useState<boolean>(false);
  const [createdCardId, setCreatedCardId] = React.useState("");
  const title = error
    ? "Oops..."
    : working
    ? "Creating Card..."
    : createdCardId
    ? "Success!"
    : "Create Pipefy Project Card";

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        style={{ marginTop: 16 }}
      >
        Create Pipefy Project Card
      </Button>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            onClose();
          }
        }}
      >
        <Box position="relative" p={2}>
          <Box position="absolute" top={5} right={5}>
            <IconButton size="small" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <DialogTitle
            disableTypography
            style={{ textAlign: "center", padding: 0 }}
          >
            <h2>{title}</h2>
          </DialogTitle>
          <DialogContent>
            {error
              ? renderError()
              : working
              ? renderWorking()
              : createdCardId
              ? renderSuccess()
              : renderConfirm()}
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );

  function renderSuccess() {
    return (
      <Box
        mt={2}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <YZTypography variant="body1" paragraph>
          Created a new{" "}
          <Link
            target="_blank"
            href={`${PIPEFY_CARD_BASE_URL}/${createdCardId}`}
            key={createdCardId}
          >
            Project Card
          </Link>{" "}
          in Pipefy.
        </YZTypography>
        <YZTypography variant="body1" color="secondary">
          Important: Please follow the link above to pipefy and add a creation
          reason!
        </YZTypography>
      </Box>
    );
  }

  function renderWorking() {
    return (
      <Box mt={2} display="flex" justifyContent="center">
        <CircularProgress color="primary" size={75} />
      </Box>
    );
  }

  function renderConfirm() {
    return (
      <Box
        mt={2}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {props.project.pipefyProjectCardIds?.length && (
          <Typography variant="body1" paragraph>
            Note: This client already has the following project cards in Pipefy:
            <ul>
              {props.project.pipefyProjectCardIds.map((id) => (
                <li>
                  {" "}
                  <Link
                    target="_blank"
                    href={`${PIPEFY_CARD_BASE_URL}/${id}`}
                    key={id}
                  >
                    Pipefy Project Card # {id}
                  </Link>{" "}
                </li>
              ))}
            </ul>
          </Typography>
        )}
        {!props.profile.pipefyClientCardId && (
          <Typography variant="body1" paragraph>
            Note: This will also create a Pipefy client card, since none was
            found.
          </Typography>
        )}
        <Typography variant="body1" paragraph>
          Are you sure you want to continue?
        </Typography>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpen(false)}
            style={{ marginRight: 16 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={createCard}
            style={{ marginLeft: 16 }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    );
  }

  function renderError() {
    return (
      <Box
        mt={2}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" paragraph>
          There was an error creating the card:
        </Typography>
        <Typography color="error" variant="body1" paragraph>
          {error}
        </Typography>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            style={{ marginLeft: 16 }}
          >
            Close
          </Button>
        </Box>
      </Box>
    );
  }

  async function createCard() {
    setWorking(true);
    let res;

    try {
      res = await pangaeaClient.createPipefyProjectCard({
        profileId: props.profile.id,
      });
    } catch (e) {
      return setError(e as string);
    }

    if (res.status !== 200) {
      const body = await res.json();
      return setError(body.message);
    }

    const { cards } = await res.json();
    setCreatedCardId(cards.projectCardId);
    setWorking(false);
  }

  function onClose() {
    setOpen(false);
    setError("");
    setWorking(false);
    setCreatedCardId("");
  }
}

export { PipefyProjectCardCreateButton };
