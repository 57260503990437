import * as React from "react";
import { Switch, Route } from "react-router-dom";
import {
  Tabs,
  Tab,
  CircularProgress,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import MediaGrid from "../../Components/MediaGrid";
import { Media, Profile, MediaVisibility } from "@yardzen-inc/models";
import RevisionAPI from "../../util/RevisionAPI";
import SharedMedia from "./SharedMedia";
import FunctionAndFlow from "./FunctionAndFlow";
import Brief from "./Brief";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Inspiration from "./Inspiration";
import WesUploaderComponent from "../../Components/WesUploaderComponent";
import DeprecatingMediaUploader from "../../Components/deprecationWidget/fileUploader";
import FileUpload from "../../Components/FileUpload";
import { ClientTableMediaRoute as MediaRoute } from "../../ConstantValues/routesConstants";
import PropertyMediaAnnotator from "./PropertyMediaAnnotator";

export interface Props {
  match: any;
  history: any;
  location: any;
  permissions: any;
  fileVisibility: MediaVisibility;
  fetchUser: CallableFunction;
  clientRecord: Profile;
  isEmployee: boolean;
}

export default function MediaRouter(props: Props) {
  const handleTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    props.history.replace(value);
  };
  const userId = props.match.params.userId;
  const [funcButtonDisabled, setFuncButtonDisabled] = React.useState(true);
  const [mediaCounts, setMediaCounts] = React.useState<{
    [key: string]: number;
  }>({});

  React.useEffect(listenOnMediaDocuments, [props.clientRecord.id]);

  const sendFuncFlowToClient = () => {
    RevisionAPI.sendFuncFlowToClient(userId);
    props.fetchUser();
  };

  return (
    <div>
      <Tabs
        value={`${props.location.pathname}`}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="on"
      >
        <Tab
          value={`${props.match.url}`}
          label={`Property (${mediaCounts["property"] ?? 0})`}
        />
        <Tab
          value={`${props.match.url}/${MediaRoute.inspiration}`}
          label={`Inspiration (${mediaCounts["inspiration"] ?? 0})`}
        />
        <Tab
          value={`${props.match.url}/${MediaRoute.plans}`}
          label={`Plans (${mediaCounts["plans"] ?? 0})`}
        />
        <Tab
          value={`${props.match.url}/${MediaRoute.functionAndFlow}`}
          label={`Function & Flow (${
            mediaCounts["function-and-flow"] ??
            0 + mediaCounts["function-and-flow-raw"] ??
            0
          })`}
        />
        <Tab
          value={`${props.match.url}/${MediaRoute.brief}`}
          label={`Design Brief (${
            mediaCounts["brief"] ?? 0 + mediaCounts["brief-raw"] ?? 0
          })`}
        />
        <Tab
          value={`${props.match.url}/${MediaRoute.v1DesignNotes}`}
          label={`V1 Design Notes (${mediaCounts["revision-notes"] ?? 0})`}
        />
        <Tab
          value={`${props.match.url}/${MediaRoute.wesleyAnnotations}`}
          label={`Video Annotations (${
            mediaCounts["wesley-annotations"] ?? 0
          })`}
        />
        <Tab
          value={`${props.match.url}/${MediaRoute.model}`}
          label={`Models (${mediaCounts["model"] ?? 0})`}
        />
        <Tab
          value={`${props.match.url}/${MediaRoute.workingFiles}`}
          label={`Working Files (${mediaCounts["working-files"] ?? 0})`}
        />
        {props.isEmployee && (
          <Tab
            value={`${props.match.url}/${MediaRoute.sharedMedia}`}
            label={`Shared Media (${mediaCounts["shared-media"] ?? 0})`}
          />
        )}
        {props.isEmployee && (
          <Tab
            value={`${props.match.url}/${MediaRoute.takeOffs}`}
            label={`Take offs (${mediaCounts["take-offs"] ?? 0})`}
          />
        )}
        {props.isEmployee && (
          <Tab
            value={`${props.match.url}/${MediaRoute.propertyAnnotations}`}
            label={`Property Annotations (${mediaCounts["property"] ?? 0})`}
          />
        )}
      </Tabs>
      <Switch>
        <Route
          path={`${props.match.path}/:fileTag?`}
          render={(routeProps) => {
            const { fileTag } = routeProps.match.params;
            switch (fileTag) {
              case MediaRoute.sharedMedia:
                return (
                  <>
                    <SharedMedia clientRecord={props.clientRecord as any} />
                  </>
                );
              case MediaRoute.propertyAnnotations:
                return (
                  <>
                    <PropertyMediaAnnotator
                      clientRecord={props.clientRecord as any}
                    />
                  </>
                );
              // @ts-ignore
              case MediaRoute.brief:
                if (fileTag === "brief") {
                  return (
                    <>
                      <Brief clientRecord={props.clientRecord} />
                      <MediaComponent
                        {...routeProps}
                        // variant="db"
                        permissions={props.permissions}
                        fileVisibility={props.fileVisibility}
                        setButtonDisabled={setFuncButtonDisabled}
                      />
                    </>
                  );
                }

              case MediaRoute.inspiration:
                return (
                  <>
                    <Inspiration
                      tag={fileTag}
                      clientRecord={props.clientRecord as any}
                    />
                    <DeprecatingMediaUploader
                      userId={userId}
                      fileTag={fileTag}
                      fileVisibility={props.fileVisibility}
                      history={props.history}
                      urlMatch={props.match}
                    />
                  </>
                );
              case MediaRoute.plans:
                return (
                  <>
                    <Inspiration
                      tag={fileTag}
                      clientRecord={props.clientRecord as any}
                    />
                    <DeprecatingMediaUploader
                      userId={userId}
                      fileTag={fileTag}
                      fileVisibility={props.fileVisibility}
                      history={props.history}
                      urlMatch={props.match}
                    />
                  </>
                );
              // @ts-ignore
              case MediaRoute.functionAndFlow:
                if (fileTag === "function-and-flow") {
                  return (
                    <>
                      <FunctionAndFlow
                        clientRecord={props.clientRecord}
                        sendFuncFlowToClient={sendFuncFlowToClient}
                        funcButtonDisabled={funcButtonDisabled}
                      />
                      <MediaComponent
                        {...routeProps}
                        accept={
                          fileTag === "function-and-flow"
                            ? ["image/*"]
                            : undefined
                        }
                        permissions={props.permissions}
                        // variant="ff"
                        fileVisibility={props.fileVisibility}
                        setButtonDisabled={setFuncButtonDisabled}
                      />
                    </>
                  );
                }
              default:
                return (
                  <MediaComponent
                    key={fileTag}
                    {...routeProps}
                    accept={
                      fileTag === "function-and-flow" ? ["image/*"] : undefined
                    }
                    permissions={props.permissions}
                    fileVisibility={props.fileVisibility}
                    setButtonDisabled={setFuncButtonDisabled}
                    useLegacyUploader={
                      fileTag === MediaRoute.v1DesignNotes ? true : false
                    }
                  />
                );
            }
          }}
        />
      </Switch>
    </div>
  );

  function listenOnMediaDocuments(): () => void {
    return firebase
      .firestore()
      .collection("media")
      .where("userId", "==", props.clientRecord.id)
      .onSnapshot((snap) => {
        const mediaCounts = {};

        for (let doc of snap.docs) {
          const data = doc.data();

          if (!data.tag) {
            continue;
          }
          // @ts-ignore
          if (!mediaCounts[data.tag]) {
            // @ts-ignore
            mediaCounts[data.tag] = 0;
          }
          // @ts-ignore
          mediaCounts[data.tag] += 1;
        }

        setMediaCounts(mediaCounts);
      });
  }
}

interface GridProps {
  history: any;
  match: any;
  location: any;
  permissions: object;
  fileVisibility: MediaVisibility;
  setButtonDisabled: CallableFunction;
  variant?: "ff" | "db" | null;
  accept?: Array<string>;
  useLegacyUploader?: boolean;
}

export const MediaComponent = (props: GridProps) => {
  const userId = props.match.params.userId;
  const accept = props.accept;
  const permissions = props.permissions;
  const fileVisibility = props.fileVisibility;
  let fileTag = props.match.params.fileTag;
  if (!props.match.params.fileTag) {
    fileTag = "property";
  }

  const smDown = useMediaQuery((theme) =>
    (theme as any).breakpoints.down("sm")
  );

  const [isLoading, setIsLoading] = React.useState(true);

  const [media, setMedia]: [Array<Media>, CallableFunction] = React.useState(
    []
  );

  const fetchMedia = async () => {
    setIsLoading(true);

    const records: Array<Media> = await Media.get(
      userId,
      // @ts-ignore
      permissions["media"]["visibility"],
      null,
      undefined,
      true
    );

    setMedia(records);
    setIsLoading(false);
    records.forEach((rec) => {
      if (rec["tag"] === "function-and-flow") {
        props.setButtonDisabled(false);
      }
    });
  };

  React.useEffect(() => {
    fetchMedia();
  }, []);

  const onUploadStart = () => {};

  const onUploadProgress = () => {};

  const onUploadComplete = () => {
    fetchMedia();
  };

  const onDeleteComplete = async (media: Array<Media>) => {
    fetchMedia();
  };

  return (
    <>
      {isLoading && <CircularProgress />}

      {!isLoading && (
        <>
          <MediaGrid
            key={fileTag}
            media={media}
            userId={userId}
            permissions={permissions}
            fileTag={fileTag}
            onDeleteComplete={onDeleteComplete}
            onUploadComplete={onUploadComplete}
          />
          {renderUploader()}
        </>
      )}
    </>
  );

  function renderUploader(): React.ReactNode {
    if (props.variant === "ff" || props.variant === "db") {
      return (
        <Box display="flex" flexDirection={smDown ? "column" : "row"}>
          <Box width={smDown ? "100%" : "50%"}>
            <WesUploaderComponent
              clientId={userId}
              tag={fileTag}
              onUploadComplete={onUploadComplete}
            />
          </Box>
          <Box width={smDown ? "100%" : "50%"}>
            <WesUploaderComponent clientId={userId} tag={fileTag + "-raw"} />
          </Box>
        </Box>
      );
    }

    if (!!props.useLegacyUploader) {
      return (
        <FileUpload
          clientUserId={userId}
          fileTag={fileTag}
          fileVisibility={fileVisibility}
          accept={accept}
          onUploadStart={onUploadStart}
          onUploadProgress={onUploadProgress}
          onUploadComplete={onUploadComplete}
        />
      );
    }

    return (
      <DeprecatingMediaUploader
        userId={userId}
        fileTag={fileTag}
        fileVisibility={fileVisibility}
        accept={accept}
        history={props.history}
        urlMatch={props.match}
        onUploadStart={onUploadStart}
        onUploadProgress={onUploadProgress}
        onUploadComplete={onUploadComplete}
      />
    );
  }
};
