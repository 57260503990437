import {
  SelectedItemsPlant,
  plantPDFTypeOrder,
} from "../../Components/NonPmAssetSelection/constants/SelectedItemsConstants";

export const sortPlantsForPDF = (
  plants: SelectedItemsPlant[]
): SelectedItemsPlant[] => {
  // first sort alphabetically
  const sortedAlphabetically = plants.sort(function (a, b) {
    return a.scientificName.localeCompare(b.scientificName);
  });

  // then sort by type
  return sortedAlphabetically.sort(typeCompare);
};

function typeCompare(a: SelectedItemsPlant, b: SelectedItemsPlant) {
  const aTypeValue = plantPDFTypeOrder.indexOf(a.type.toLowerCase());
  const bTypeValue = plantPDFTypeOrder.indexOf(b.type.toLowerCase());

  if (aTypeValue < bTypeValue) {
    return -1;
  }
  if (aTypeValue > bTypeValue) {
    return 1;
  }
  return 0;
}
