import firebase from "firebase/compat/app";
import Moment from "moment";
import { Media } from "@yardzen-inc/models";

class RevisionAPI {
  public async loadProjectRevisionData(context: any, profileId: string) {
    const data = await this._fetchRevisionsByProfileId(context, profileId);
    if (!data) return false;
    await this._fetchMediaByRevIDArray(context, profileId, data.revisionIds);
    return true;
  }

  private async _fetchRevisionsByProfileId(context: any, profileId: string) {
    let revisionObject = {};
    const profile = await firebase
      .firestore()
      .collection("profiles")
      .doc(profileId)
      .get()
      .then((resp) => {
        const data: any = resp.data();
        context.dispatch({
          type: "SET_PROJECT",
          payload: {
            profileId,
            profileObj: data,
          },
        });
        return data;
      });

    const revs = await firebase
      .firestore()
      .collection("revisions")
      .where("profileId", "==", profileId)
      .get()
      .then((resp) => {
        const returnArr: any = [];
        resp.forEach((doc) => {
          revisionObject = {
            ...revisionObject,
            [doc.id]: doc.data(),
          };
          const data = doc.data();
          data["id"] = doc.id;
          returnArr.push(data);
        });
        context.dispatch({
          type: "SET_REVISIONS",
          payload: {
            profileId: profileId,
            revisionObject,
          },
        });
        return returnArr;
      });

    const sortedArray: any = revs.sort((a: any, b: any) => {
      return Moment(a.dateCreated).unix() - Moment(b.dateCreated).unix();
    });

    const actRev = sortedArray[sortedArray.length - 1];

    if (actRev && actRev.length != 0) {
      await this._setActiveRevision(context, profileId, actRev);
    }

    return {
      revisionIds: Object.keys(revisionObject),
      activeRevisionId: profile.activeRevision,
    };
  }

  allowCreateDesignFlow(context: any, profileId: string) {
    firebase
      .firestore()
      .collection("profiles")
      .doc(profileId)
      .update({
        projectState: "materialsRecieved",
      })
      .then((resp) => {
        console.log("updated");
      });
  }

  sendFuncFlowToClient(profileId: string) {
    firebase
      .firestore()
      .collection("profiles")
      .doc(profileId)
      .update({
        funcFlowReady: true,
      })
      .then((resp) => {
        console.log("updated");
      });
  }

  public async createRevision(context: any, profileId: string) {
    const revDoc = {
      profileId: profileId,
      state: "draft",
      dateCreated: Moment().utc().toISOString(),
    };

    await firebase
      .firestore()
      .collection("revisions")
      .add(revDoc)
      .then((resp) => {
        return resp.id;
      })
      .catch((e) => {
        window.newrelic.noticeError(e);
        console.log(e);
      });
    this._setActiveRevision(context, profileId, revDoc);
    await firebase
      .firestore()
      .collection("profiles")
      .doc(profileId)
      .update({
        projectState: "materialsRecieved",
      })
      .then((resp) => {
        this.loadProjectRevisionData(context, profileId);
      });
  }

  private async _setActiveRevision(
    context: any,
    profileId: string,
    revisionObject: object
  ) {
    context.dispatch({
      type: "SET_ACTIVE_REVISION",
      payload: {
        revisionObject,
        profileId,
      },
    });
  }

  private async _fetchMediaByRevIDArray(
    context: any,
    profileId: string,
    revisionIdArray: Array<string>
  ) {
    const mediaMetadataObject = {};
    // @ts-ignore
    revisionIdArray.forEach((id) => (mediaMetadataObject[id] = []));

    const records = await Media.get(profileId, null, "deliverable");
    const revisionMediaRecords = records.filter(
      // @ts-ignore
      (record) => record["revisionId"] && mediaMetadataObject[record.revisionId]
    );
    revisionMediaRecords.forEach(
      (record) =>
        // @ts-ignore
        (mediaMetadataObject[record.revisionId] = [
          // @ts-ignore
          ...mediaMetadataObject[record.revisionId],
          record,
        ])
    );

    context.dispatch({
      type: "SET_MEDIA_METADATA_OBJECT",
      payload: {
        mediaMetadataObject,
      },
    });
  }

  public fetchMedia(context: any, profileId: string, revisionId: string) {
    Media.get(profileId, null, null).then((records) => {
      const matches = records.filter((rec: any) => {
        if (
          rec["tag"] === "deliverable" &&
          rec["revisionId"] &&
          rec["revisionId"] === revisionId
        ) {
          return true;
        }
        return false;
      });
      context.dispatch({
        type: "SET_MEDIA_METADATA",
        payload: {
          revisionId,
          mediaMetaDataArray: matches,
        },
      });
    });
  }

  public async submitForReview(
    context: any,
    profileId: string,
    revisionId: string
  ) {
    await firebase
      .firestore()
      .collection("revisions")
      .doc(revisionId)
      .update({
        dateSubmittedForReview: Moment().utc().toISOString(),
      })
      .then((resp) => {});

    firebase
      .firestore()
      .collection("profiles")
      .doc(profileId)
      .update({
        projectState: "reviewDesign",
        dateSubmittedForReview: Moment().utc().toISOString(),
      })
      .then((resp) => {
        this._fetchRevisionsByProfileId(context, profileId);
      });
  }

  public async saveSortedDeliverables(
    context: any,
    projectId: string,
    revisionId: string,
    sortedMediaArray: any
  ) {
    await firebase.firestore().collection("revisions").doc(revisionId).update({
      deliverables: sortedMediaArray,
    });
    this.loadProjectRevisionData(context, projectId);
  }

  public async sendDesignToClient(
    context: any,
    projectId: string,
    revisionId: string,
    mediaArray: Media[],
    blurb: string,
    final?: boolean
  ) {
    await firebase.firestore().collection("profiles").doc(projectId).update({
      projectState: "clientReview",
    });

    const mediaIDs = mediaArray.map((record: Media) => {
      return record.id;
    });

    await firebase
      .firestore()
      .collection("revisions")
      .doc(revisionId)
      .update({
        deliverables: mediaIDs,
        dateSentToClient: Moment().utc().toISOString(),
        ...(final ? { finalBlurb: blurb } : { v1Blurb: blurb }),
      });
    this.loadProjectRevisionData(context, projectId);
  }

  public async _deleteRevision(
    context: any,
    revisionId: string,
    profileId: string
  ) {
    /*
        DEV USE ONLY
        REAL REVISIONS CANNOT BE DELETED
    */

    let docId: string | undefined;

    // Broken, "activeRevision" is no longer a property
    await firebase
      .firestore()
      .collection("profiles")
      .where("activeRevision", "==", revisionId)
      .limit(1)
      .get()
      .then((resp) => {
        resp.forEach((doc) => {
          docId = doc.id;
        });
      });

    if (docId && docId.length != 0) {
      await firebase.firestore().collection("profiles").doc(docId).update({
        activeRevision: "",
      });
    }

    await firebase
      .firestore()
      .collection("revisions")
      .doc(revisionId)
      .delete()
      .then((success) => {
        this._fetchRevisionsByProfileId(context, profileId);
      })
      .catch((e) => console.log("ERROR DELETING: ", e));
  }
}

export default new RevisionAPI();
