import React, { FC, useState, ReactNode, useEffect } from "react";
import { PageComponentProps } from "./CurrentJobPageMap";
import {
  Box,
  Tabs,
  Tab,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { DesignBriefPage } from "../Components/designBrief";
import DesignerSummaryPageV2 from "./DesignerSummaryPageV2";
import { DesignAssignment } from "@yardzen-inc/models";
import SketchupDesignBrief from "../Components/SketchupDesignBrief";
import NonPmAssetSelection from "../Components/NonPmAssetSelection";
import { YZTypography } from "@yardzen-inc/react-common";
import SelectedAssetsDispatchContext from "../util/contexts/SelectedAssetsDispatchContext";
import SelectedAssetsStateContext from "../util/contexts/SelectedAssetsStateContext";
import useGetPastAssignmentData from "../util/hooks/useGetPastAssignmentData";
import SnackbarError from "../Components/SnackbarError";
import { getDesignBriefByProjectId } from "../util/firebase/firebaseClient";
import { useGetDesignerSummaryData } from "../../src/util/hooks/useGetDesignerSummaryData";

const useStyles = makeStyles({
  tabs: {
    background: "#fafafa",
    borderBottom: "1px solid #f2f2f2",
    minHeight: "0 !important",
    position: "fixed",
    top: 0,
    width: "100%",
    "& .MuiTab-root": {
      minWidth: 0,
      textTransform: "none",
      height: 32,
      minHeight: "0 !important",
      fontSize: 13,
      "&.Mui-selected": {
        fontWeight: 600,
      },
    },
  },
  loadingAssetSelection: {
    display: "flex",
    alignItems: "center",
  },
});

export type WrappedDesignerSummaryPageProps = PageComponentProps & {
  assignment: DesignAssignment;
};

const WrappedDesignerSummaryPageTabsAndContent: FC<WrappedDesignerSummaryPageProps> =
  (props) => {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState<string>("uploads");
    const [hasDesignBriefCollection, setHasDesignBriefCollection] =
      useState<boolean>(false);
    const [snackbarErrorText, setSnackbarErrorText] =
      React.useState<string>("");

    useEffect(checkDesignBriefStatus, [props.assignment.id]);

    const {
      loadingCarryoverRequirements,
      carryoverRequired,
      carryoverCompleted,
    } = useGetPastAssignmentData(
      props.project.id,
      props.project.currentDesignAssignmentId || ""
    );

    React.useEffect(() => {
      if (carryoverRequired && carryoverCompleted) {
        setSnackbarErrorText(
          "Populated items from a previous revision. Please refresh your browser to continue."
        );
      }
    }, [carryoverCompleted]);

    const {
      error,
      loading,
      geoZones,
      usdaZone,
      exteriorDesignInfo,
      metadata: assignmentMetadata,
      region,
    } = useGetDesignerSummaryData(
      props.profile,
      props.address?.zip || "",
      props.assignment.id
    );

    const renderAssetSelectionTab =
      !loadingCarryoverRequirements && !carryoverRequired && !loading;

    return (
      <>
        <Box width="100%" position="sticky" top={0} zIndex={1000}>
          <Tabs
            value={tabValue}
            onChange={(_, value) => {
              setTabValue(value);
            }}
            indicatorColor="primary"
            className={classes.tabs}
          >
            <Tab label="Uploads" value="uploads" />
            {!shouldHideDesignBriefTab() && (
              <Tab label="Design Brief" value="db" />
            )}
            {renderAssetSelectionTab && (
              <Tab label="Asset Selection" value="assetSelection" />
            )}
            {!renderAssetSelectionTab && (
              <Tab>
                <div className={classes.loadingAssetSelection}>
                  <CircularProgress size={20} />
                  <YZTypography>&nbsp;Loading Asset Selection...</YZTypography>
                </div>
              </Tab>
            )}
          </Tabs>
        </Box>
        <Box minWidth="100%">{render()}</Box>
        <SnackbarError
          errorText={snackbarErrorText}
          handleSnackbarClose={() => {}}
        />
      </>
    );

    function shouldHideDesignBriefTab() {
      return (
        !(props.project.useAutomatedDesignBrief || hasDesignBriefCollection) ||
        !useChecklist()
      );
    }

    function useChecklist() {
      return (
        props.assignment.type === "v1" ||
        (props.assignment.type === "correction" &&
          props.assignment.correctionFor === "v1")
      );
    }

    function render(): ReactNode {
      if (tabValue === "uploads") {
        return (
          <DesignerSummaryPageV2
            {...props}
            hasNewDesignBrief={
              !shouldHideDesignBriefTab() && hasDesignBriefCollection
            }
          />
        );
      } else if (tabValue === "db") {
        if (shouldHideDesignBriefTab()) {
          return <></>;
        }

        return (
          <DesignBriefPage
            profile={props.profile}
            project={props.project}
            pm={false}
          />
        );
      } else if (tabValue === "renders") {
        return (
          <SketchupDesignBrief
            // @ts-ignore until can figure out the best type interface for it
            sketchupProducts={props.assignment?.sketchupProducts}
          />
        );
      } else if (tabValue === "assetSelection") {
        return (
          <SelectedAssetsDispatchContext.Consumer>
            {(dispatch) => (
              <SelectedAssetsStateContext.Consumer>
                {(_state) => (
                  <NonPmAssetSelection
                    loadingExteriorDesignInfo={loading}
                    profile={props.profile}
                    exteriorDesignInfo={exteriorDesignInfo}
                    geoZoneError={error}
                    loadingGeoZone={loading}
                    geoZones={geoZones}
                    stateAlreadyFetched={_state.completedFirstCall}
                    dispatch={dispatch}
                    assignmentData={assignmentMetadata}
                    usdaZone={usdaZone?.usdaZone || null}
                    loadingUsdaZone={loading}
                    usdaZoneError={error}
                    usdaZoneId={usdaZone?.usdaZoneId || null}
                    address={props.address}
                    userId={props.profile?.id || ""}
                    projectId={props.project.id}
                    projectRegion={region || undefined}
                    loadingProjectRegion={loading}
                    designAssignmentId={
                      props.project.currentDesignAssignmentId || ""
                    }
                  />
                )}
              </SelectedAssetsStateContext.Consumer>
            )}
          </SelectedAssetsDispatchContext.Consumer>
        );
      }

      throw new Error(`tab value ${tabValue} is illegal`);
    }

    function checkDesignBriefStatus() {
      void (async function () {
        const designBrief = await getDesignBriefByProjectId(props.project.id);
        const designBriefExists = !designBrief.empty;

        setHasDesignBriefCollection(designBriefExists);
      })();
    }
  };

export { WrappedDesignerSummaryPageTabsAndContent };
