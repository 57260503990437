import * as React from "react";
import { Grid } from "@material-ui/core";
import { SingleFileUpload } from "./SingleFileUpload";
import { Media } from "@yardzen-inc/models";
import { YZTypography } from "@yardzen-inc/react-common";

interface Props {
  userId: string;
  revisionId: string;
  onUpload: (m: Media | null) => void;
}

export const CadDeliverableUploadArea = ({
  userId,
  revisionId,
  onUpload,
}: Props) => {
  return (
    <Grid container>
      <Grid item md={3}>
        <YZTypography type="serif" variant="h5">
          Design Documents
        </YZTypography>
      </Grid>
      <Grid item md={9}>
        <SingleFileUpload
          flexGrow={1}
          userId={userId}
          revisionId={revisionId}
          onHasFileChange={onUpload}
          fileTag="design-checklist"
          title="Design Checklist"
          uploadMessage="Design Checklist Uploaded!"
        />
      </Grid>
    </Grid>
  );
};

export default CadDeliverableUploadArea;
