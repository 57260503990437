import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";

export const useUser = (): [Error | null, firebase.User | null | false] => {
  const [user, setUser] = useState<firebase.User | null | false>(null);
  const [err, setErr] = useState<Error | null>(null);

  useEffect(listenForAuthEvents, []);

  return [err, user];

  function listenForAuthEvents(): (() => void) | void {
    if (!firebase.apps.length) {
      return setErr(new Error("APP_NOT_INITIALIZED"));
    } else
      return firebase.auth().onAuthStateChanged((user) => {
        if (!user) {
          return setUser(false);
        }

        return setUser(user);
      });
  }
};
