import * as React from "react";
import { Box, TextField, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { YZTypography, YZButton } from "@yardzen-inc/react-common";
import { IAddressObject } from "../../EmployeeView/IncompleteAddressQueue/util/parseGeocodeToAddress";
import { formatAddress } from "../../util/functions/formatAddress";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "450px",
    width: "60vw",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "98vw",
    },
  },
  title: {
    marginBotton: theme.spacing(2),
  },
  errorBox: {
    marginTop: theme.spacing(2),
  },
  flexFieldContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  threeQuarterField: {
    width: "72.5%",
  },
  oneQuarterField: {
    width: "22.5%",
  },
  halfField: {
    width: "47.5%",
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

export interface AddressManualEntryFormProps {
  onSubmit: (contactInformation: IAddressObject) => void;
}

const AddressManualEntryForm: React.FC<AddressManualEntryFormProps> = ({
  onSubmit,
}) => {
  const classes = useStyles();
  const [street, setStreet] = React.useState<string>("");
  const [city, setCity] = React.useState<string>("");
  const [state, setState] = React.useState<string>("");
  const [zip, setZip] = React.useState<string>("");
  const [latitude, setLatitude] = React.useState<number | null>(null);
  const [longitude, setLongitude] = React.useState<number | null>(null);
  const [error, setError] = React.useState<false | string>(false);
  const [hasTried, setHasTried] = React.useState<boolean>(false);

  return (
    <Box className={classes.root}>
      <Box mb={2}>
        <YZTypography
          className={classes.title}
          align="center"
          variant="h3"
          type="serif"
        >
          Enter Client address
        </YZTypography>
        {error === "missing_fields" && (
          <YZTypography align="center" color="error">
            Please fill out all required fields
          </YZTypography>
        )}
      </Box>
      <TextField
        error={hasTried && !street.length}
        value={street}
        label="Street"
        autoComplete="street-address"
        onChange={(e) => setStreet(e.target.value)}
      />
      <Box className={classes.flexFieldContainer}>
        <TextField
          error={hasTried && !city.length}
          className={classes.halfField}
          value={city}
          label="City"
          autoComplete="locality"
          onChange={(e) => setCity(e.target.value)}
        />
        <TextField
          error={hasTried && !state.length}
          className={classes.halfField}
          value={state}
          label="State"
          autoComplete="state"
          onChange={(e) => setState(e.target.value)}
        />
      </Box>
      <Box className={classes.flexFieldContainer} mb={2}>
        <TextField
          error={
            (hasTried && !zip.length) ||
            (hasTried && error === "incomplete_zip")
          }
          helperText={
            error === "incomplete_zip" && "Please enter a 5 digit zip code"
          }
          inputProps={{ maxLength: "5" }}
          className={classes.halfField}
          value={zip}
          label="Zip code"
          autoComplete="postal-code"
          variant="standard"
          onChange={(e) => onZipChange(e.target.value)}
        />
      </Box>
      <Box className={classes.flexFieldContainer} mb={2}>
        <TextField
          error={hasTried && !latitude}
          type="number"
          label="Latitude"
          value={latitude}
          onChange={(e) => setLatitude(Number(e.target.value.trim()))}
          className={classes.halfField}
        />
        <TextField
          error={hasTried && !longitude}
          type="number"
          label="Longitude"
          value={longitude}
          onChange={(e) => setLongitude(Number(e.target.value.trim()))}
          className={classes.halfField}
        />
      </Box>
      <YZButton
        className={classes.button}
        color="primary"
        onClick={handleSubmit}
      >
        Submit
      </YZButton>
    </Box>
  );

  function onZipChange(zip: string) {
    const onlyNums = zip.replace(/[^0-9]/g, "");
    if (onlyNums.length < 6) {
      setZip(onlyNums);
    }
  }

  function hasRequiredFields() {
    return !!street.length && !!city.length && !!state.length && !!zip.length;
  }

  async function handleSubmit() {
    setHasTried(true);

    if (!hasRequiredFields()) {
      return setError("missing_fields");
    }

    if (zip.length < 5) {
      return setError("incomplete_zip");
    }

    const addressObject: IAddressObject = {
      street,
      city,
      state,
      zip,
      latLng: {
        lat: latitude,
        lng: longitude,
      },
      country: "",
      formattedAddress: "",
    };

    onSubmit({
      ...addressObject,
      formattedAddress: formatAddress(addressObject),
    });
  }
};

export { AddressManualEntryForm };
