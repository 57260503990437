import { Box, Checkbox, Typography } from "@material-ui/core";
import { CompleteVariants } from "@yardzen-inc/models";
import * as React from "react";
import CompactExpansion from "../../Components/CompactExpansion";
import capitalizeFirstLetter from "../../util/capitalizeFirstLetter";

interface PropertyMediaExpansionProps {
  yard: string;
  variantArr: CompleteVariants;
  updateVariantArr: (key: string, value: CompleteVariants | boolean) => void;
}

const PropertyMediaExpansion: React.FunctionComponent<PropertyMediaExpansionProps> =
  ({ yard, variantArr, updateVariantArr }) => {
    const variants = [
      "chatty",
      "wide-angle",
      "keep",
      "remove",
      "slow-pan",
      "slope",
    ];
    const yardSelected = !!variantArr?.length;
    return (
      <CompactExpansion
        style={{ margin: "1rem", cursor: "pointer" }}
        expanded={!!variantArr.length}
        summary={
          <Box
            onClick={() => handleYardCheck(undefined, !yardSelected)}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Checkbox onChange={handleYardCheck} checked={yardSelected} />
            <Typography>{capitalizeFirstLetter(yard)} Yard</Typography>
          </Box>
        }
        detail={variants.map((variant, i) => {
          const currVal = (variantArr as string[])?.includes(
            `${yard}-yard-${variant}`
          );
          return (
            <Box
              display="flex"
              alignItems="center"
              ml={2}
              justifyContent="flex-start"
              key={`${variant}-${i}-variant`}
              bgcolor="grey"
              height="25px"
              pt={1}
              pb={1}
              onClick={() => handleIndividualVariantCheck(!currVal, variant)}
            >
              <Checkbox
                onChange={(e, checked) => {
                  handleIndividualVariantCheck(checked, variant);
                }}
                checked={currVal}
              />
              <Typography style={{ fontSize: "0.8rem" }}>{variant}</Typography>
            </Box>
          );
        })}
      />
    );

    function handleIndividualVariantCheck(
      checked: boolean,
      variantString: string
    ) {
      const variant = `${yard}-yard-${variantString}` as any;
      if (checked) {
        if (variantArr.includes(variant)) return;
        updateVariantArr(yard, [...variantArr, variant]);
      } else {
        updateVariantArr(
          yard,
          variantArr.filter((filtVar) => {
            if (filtVar !== variant) {
              return true;
            }
            return false;
          })
        );
      }
    }

    function handleYardCheck(
      e: React.ChangeEvent<HTMLInputElement> | undefined,
      checked: boolean
    ): void {
      !checked
        ? updateVariantArr(yard, [])
        : updateVariantArr(yard, assembleVariantArray());
    }

    function assembleVariantArray(): CompleteVariants {
      const newA = variants.map(
        (variant) => `${yard}-yard-${variant}`
      ) as CompleteVariants;
      console.log(newA);
      return newA;
    }
  };

export const MemoizedPropertyMediaExpansion = React.memo(
  PropertyMediaExpansion
);
