import firebase from "firebase/compat/app";
import { GenericOnCallFunctionAlert } from "./genericAlert";

export interface GenericLiisaNotificationArgs {
  message: string;
  icon: string;
}

export const genericLiisaNotification = async (
  opts: GenericLiisaNotificationArgs
): Promise<firebase.functions.HttpsCallableResult | undefined> => {
  try {
    const func = firebase.functions().httpsCallable("genericLiisaNotification");
    return await func(opts);
  } catch (error) {
    GenericOnCallFunctionAlert("genericLiisaNotification", error.message);
    return Promise.resolve(undefined);
  }
};

export default genericLiisaNotification;
