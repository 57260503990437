import React, { FC, useRef, ReactNode, CSSProperties, useMemo } from "react";
import GenericRouter, { GenericRouterProps } from "./GenericRouter";
import { Box, Tabs, Tab, makeStyles } from "@material-ui/core";
import { PageMapEntryWithHumanName } from "./types";
import { makeRRURL } from "./util";

export interface GenericRouterWithTabHeaderProps extends GenericRouterProps {
  pageMap: PageMapEntryWithHumanName[];
  tabsComponentStyles?: CSSProperties;
  spacerSize?: number;
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: "100%",
  },
}));

const GenericRouterWithTabHeader: FC<GenericRouterWithTabHeaderProps> = ({
  spacerSize,
  ...props
}) => {
  const classes = useStyles();

  const routeCacheRef = useRef<{
    [key: string]: { [shortName: string]: string };
  }>({});

  const activePath = useMemo(getCurrentPathString, [props.location.pathname]);

  return (
    <>
      {props.location.pathname !== props.match.url && (
        <Tabs
          value={activePath}
          onChange={handleTabChange}
          className={classes.tabs}
          style={props.tabsComponentStyles}
        >
          {renderTabs()}
        </Tabs>
      )}
      <Box py={spacerSize} display="inline-block" />
      <GenericRouter {...props} routeCacheRef={routeCacheRef} />
    </>
  );

  function renderTabs(): ReactNode[] {
    return props.pageMap.map(renderTab);
  }

  function getCurrentPathString(): string {
    const matchSplit = props.match.url.split("/");
    const matchSplitLen = matchSplit.length;

    const urlSplit = props.location.pathname.split("/");

    return urlSplit.slice(0, matchSplitLen + 1).join("/");
  }

  function renderTab(entry: PageMapEntryWithHumanName): ReactNode {
    return (
      <Tab
        label={entry.humanName ?? entry.routeName}
        value={makeRRURL(
          entry.routeName,
          {
            location: props.location,
            history: props.history,
            match: props.match,
          },
          routeCacheRef
        )}
        key={`${entry.key}__tab`}
      />
    );
  }

  function handleTabChange(_: any, value: string) {
    props.history.push(value);
  }
};

GenericRouterWithTabHeader.defaultProps = {
  spacerSize: 2,
};

export { GenericRouterWithTabHeader };
export default GenericRouterWithTabHeader;
