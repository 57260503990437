import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Media } from "@yardzen-inc/models";
import {
  MediaInclusion,
  PropertyMediaInclusion,
} from "../ClientDetail/Media/IncludeMediaInSharedLink";

async function getPropertyMedia(
  inclusionObject: MediaInclusion
): Promise<any[] | void> {
  try {
    const usePropertyMedia = inclusionObject.property.include;
    const propertyInclusion = inclusionObject.property;
    const profileId = inclusionObject.profileId;

    if (!usePropertyMedia) {
      return [];
    }

    const variants: (string[] | undefined)[] = Object.keys(
      propertyInclusion
    ).map((key) => {
      const vKey = key as keyof PropertyMediaInclusion;

      if (key === "include" || !propertyInclusion[vKey]) {
        return;
      }
      return propertyInclusion[vKey] as string[];
    });

    var merged: string[] = Array.prototype.concat.apply([], variants); // Flattens array (no Array.flat() :( )

    const allPropertyMedia = await firebase
      .firestore()
      .collection("media")
      .where("tag", "==", "property")
      .where("userId", "==", profileId)
      .get();

    const selectedVariants = allPropertyMedia.docs.filter((doc) => {
      const data = doc.data();
      if (!doc.exists || !data) {
        return false;
      }
      return merged.includes(data.variant);
    });

    return Media.getMediaMetadataFromIdArray(
      selectedVariants.map((doc) => doc.id)
    );
  } catch (error) {
    console.error(
      `Error fetching property media while including shared media: ${error}`
    );
    throw error;
  }
}

export { getPropertyMedia };
