import React from "react";
import {
  Box,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  CorrectionType,
  ReasonForCorrectionOrRevision,
} from "@yardzen-inc/models";
import { YZButton } from "@yardzen-inc/react-common";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { DesignAssignmentWizardState } from "./DesignAssignmentCreationWizard";
import { kebabCaseToUpperCase } from "../../../util/formatters/kebabCaseToUpperCase";

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    paddingBottom: theme.spacing(2),
  },
  button: {
    margin: "0.5rem",
    width: "28%",
  },
  toggledButton: {
    margin: "0.5rem",
    width: "28%",
    background: `${theme.palette.primary.main} !important`,
    color: "#fff",
  },
  freeformOtherText: {
    marginBottom: "1rem",
    marginRight: "2rem",
    width: "60%",
  },
}));

interface Props {
  wizardState: DesignAssignmentWizardState;
  setWizardState: React.Dispatch<
    React.SetStateAction<DesignAssignmentWizardState>
  >;
  createAssignment: () => Promise<void>;
  correctionFor: CorrectionType | null | undefined;
}

const SelectReasonsForCorrectionsOrRevisionsStep: React.FC<Props> = (
  props: Props
) => {
  const classes = useStyles();
  const { wizardState, setWizardState, createAssignment, correctionFor } =
    props;

  const correctionOrRevisionText = correctionFor ? "correction" : "revision";

  const reasons = Object.values(ReasonForCorrectionOrRevision);
  const otherIsSelected = wizardState.reasonsForCorrectionOrRevision.includes(
    ReasonForCorrectionOrRevision.Other
  );

  const addOrRemoveSelectedCorrectionType = (
    reason: ReasonForCorrectionOrRevision
  ): void => {
    const isAlreadySelected =
      wizardState.reasonsForCorrectionOrRevision.includes(reason);

    if (isAlreadySelected) {
      setWizardState((wizardState) => ({
        ...wizardState,
        reasonsForCorrectionOrRevision:
          wizardState.reasonsForCorrectionOrRevision.filter(
            (c) => c !== reason
          ),
        freeformOtherReasonForCorrectionOrRevision: otherIsSelected
          ? undefined
          : wizardState.freeformOtherReasonForCorrectionOrRevision,
      }));
    } else {
      setWizardState((wizardState) => ({
        ...wizardState,
        reasonsForCorrectionOrRevision: [
          ...wizardState.reasonsForCorrectionOrRevision,
          reason,
        ],
      }));
    }
  };

  return (
    <>
      <Typography variant="h5" gutterBottom align="center">
        Why are you issuing this {correctionOrRevisionText}?
      </Typography>
      <Typography variant="body1" align="center">
        Select all issues that apply
      </Typography>
      <Box>
        <Box pt={4}>
          <>
            <div className={classes.buttonContainer}>
              {reasons.map((reason: ReasonForCorrectionOrRevision) => {
                return (
                  <YZButton
                    key={reason}
                    onClick={() => addOrRemoveSelectedCorrectionType(reason)}
                    className={
                      wizardState.reasonsForCorrectionOrRevision.includes(
                        reason
                      )
                        ? classes.toggledButton
                        : classes.button
                    }
                  >
                    {kebabCaseToUpperCase(reason)}
                  </YZButton>
                );
              })}
            </div>
          </>
        </Box>
        {otherIsSelected && (
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <TextField
              className={classes.freeformOtherText}
              variant="outlined"
              value={
                wizardState.freeformOtherReasonForCorrectionOrRevision ?? ""
              }
              onChange={({ currentTarget: { value } }) =>
                setWizardState({
                  ...wizardState,
                  freeformOtherReasonForCorrectionOrRevision: value,
                })
              }
              autoFocus
              placeholder="Enter Other reason"
            ></TextField>
          </Box>
        )}

        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <YZButton
            disabled={
              wizardState.reasonsForCorrectionOrRevision.length < 1 ||
              (otherIsSelected &&
                !wizardState.freeformOtherReasonForCorrectionOrRevision)
            }
            variant="contained"
            style={{ height: "fit-content" }}
            color="primary"
            onClick={createAssignment}
          >
            Create
            <ArrowRightAltIcon />
          </YZButton>
        </Box>
      </Box>
    </>
  );
};

export { SelectReasonsForCorrectionsOrRevisionsStep };
