import React from "react";
import { Box, Modal, Fade, Backdrop } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

interface IDashboardModalProps {
  open: boolean;
  onClose: () => void;
  ariaLabelledby: string;
  animate?: boolean;
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      width: 600,
      maxWidth: "100%",
      maxHeight: "100%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      outline: 0,
      borderRadius: "5px",
      overflowY: "auto",
    },
  })
);

const DashboardModal: React.FC<IDashboardModalProps> = (
  props: IDashboardModalProps
) => {
  const classes = useStyles();

  const { open, onClose, ariaLabelledby, animate, children } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby={ariaLabelledby}
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={animate ? Backdrop : undefined}
      BackdropProps={
        animate
          ? {
              timeout: 500,
            }
          : undefined
      }
    >
      {animate ? (
        <Fade in={open}>
          <Box p={4} className={classes.paper} textAlign="center">
            {children}
          </Box>
        </Fade>
      ) : (
        <Box p={4} className={classes.paper} textAlign="center">
          {children}
        </Box>
      )}
    </Modal>
  );
};

DashboardModal.defaultProps = {
  animate: true,
};

export default DashboardModal;
export { DashboardModal };
