import React, { FC } from "react";
import ExteriorDesignRouterTab from "../ClientDetail/ExteriorDesign/ExteriorDesignRouterTab";
import { PageComponentProps } from "./CurrentJobPageMap";

export interface ExteriorDesignTabProps extends PageComponentProps {}

const ExteriorDesignTab: FC<ExteriorDesignTabProps> = (props) => {
  return <ExteriorDesignRouterTab clientRecord={props.profile} useHashRouter />;
};

export { ExteriorDesignTab };
export default ExteriorDesignTab;
