import * as React from "react";
import { makeStyles } from "@material-ui/core";
import CurrentJobDash from "./CurrentJobDash";
import { Assignment, Agent } from "@yardzen-inc/models";
import SelfAssignSidebar from "./SelfAssignSidebar";
import SnackbarError from "../Components/SnackbarError";
import LoadingAbsCenter from "../Components/LoadingAbsCenter";
import { useOpenModelingAssignments } from "../util/hooks/useOpenModelingAssignments";
import { assignNextProjectToModeler } from "../util/selfAssign/assignModelerSelfAssignAssignment";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    height: "calc(100vh - 4rem)",
    maxHeight: "calc(100vh - 4rem)",
  },
  body: {
    display: "flex",
    width: "100%",
  },
});

type Props = {
  agent: Agent;
};

const ModelerPageBody: React.FC<Props> = ({ agent }: Props) => {
  const classes = useStyles();

  const [locked, setLocked] = React.useState<boolean>(false);
  const [snackbarText, setSnackbarText] = React.useState<string | null>(null);

  const [availableAssignmentCount, setAvailableAssignmentCount] =
    React.useState<number | null>(null);
  const [selectedAssignment, setSelectedAssignment] =
    React.useState<Assignment | null>(null);

  const [assignedModels, setAssignedModels] = React.useState<
    Assignment[] | null | "none"
  >(null);

  const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);

  const { openModelingAssignmentsWithProfile, error, loading } =
    useOpenModelingAssignments();

  function assignedHook() {
    if (assignedModels && assignedModels !== "none" && !selectedAssignment) {
      setSelectedAssignment(assignedModels[0]);
    } else if (!assignedModels) {
      getAssigned();
    }
  }

  React.useEffect(assignedHook, [assignedModels]);

  React.useEffect(() => {
    setAvailableAssignmentCount(openModelingAssignmentsWithProfile.length);
  }, [openModelingAssignmentsWithProfile]);

  React.useEffect(() => {
    if (error) {
      setSnackbarText(
        "There was an error retrieving your open modeling assignments."
      );
    }
  }, [error]);

  React.useEffect(() => {
    if (!loading) {
      setHasLoaded(assignedModels !== null);
    }
  }, [loading, assignedModels]);

  return (
    <>
      <div id="self-assign-page-body-root" className={classes.root}>
        <SelfAssignSidebar
          assigned={
            assignedModels === "none" || !assignedModels ? [] : assignedModels
          }
          selectAssignment={(a: Assignment) => setSelectedAssignment(a)}
          selectedAssignment={selectedAssignment}
          index={0} // this prop should be deleted but requires some refactoring first
          setIndex={(i: number) => {}} // this prop should be deleted but requires some refactoring first
        />
        <div className={classes.body}>
          {hasLoaded && !error ? (
            <CurrentJobDash
              disabled={locked}
              assignment={
                assignedModels?.length ? (selectedAssignment as any) : "none"
              }
              openAssignmentCount={availableAssignmentCount}
              requestNextJob={assign}
            />
          ) : (
            !hasLoaded && <LoadingAbsCenter in />
          )}
        </div>
      </div>
      <SnackbarError
        errorText={snackbarText}
        handleSnackbarClose={() => setSnackbarText(null)}
      />
    </>
  );

  async function getAssigned(): Promise<void> {
    try {
      const assignments = await agent.getAssignments(false, true);
      return setAssignedModels(assignments);
    } catch (err) {
      console.error(err);
      setSnackbarText("There was an error getting the assigned projects.");
    }
  }

  async function assign(): Promise<void> {
    try {
      setLocked(true);
      // modeling queue data is not fetched until button is clicked
      await assignNextProjectToModeler(agent, setSnackbarText, getAssigned);
    } finally {
      setLocked(false);
    }
  }
};

export { ModelerPageBody };
