import React, { createContext, FC } from "react";
import { DesignBriefContextValue, useDesignBriefV1 } from "./useDesignBriefV1";
import { Project, Profile } from "@yardzen-inc/models";

const DesignBriefCtx = createContext<DesignBriefContextValue>(
  {} as DesignBriefContextValue
);

export interface DesignBriefProviderProps {
  profile: Profile;
  project: Project;
}

const DesignBriefProvider: FC<DesignBriefProviderProps> = (props) => (
  <DesignBriefCtx.Provider
    value={useDesignBriefV1(props.profile, props.project)}
  >
    <>{props.children}</>
  </DesignBriefCtx.Provider>
);

export { DesignBriefProvider, DesignBriefCtx };
export default DesignBriefCtx;
