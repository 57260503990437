import * as React from "react";
import { makeStyles, LinearProgress, Typography } from "@material-ui/core";
import { PageComponentProps } from "./CurrentJobPageMap";
import { Media } from "@yardzen-inc/models";
import downloadAndZip from "../util/downloadAndZip";
import { AnnotatorPage } from "@yardzen-inc/annotator";
import MediaGrid from "../Components/MediaGrid";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { YZButton } from "@yardzen-inc/react-common";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
  },
  topBar: {
    display: "flex",
    flexFlow: "row-reverse nowrap",
    padding: "1rem 0",
  },
  ffContainer: {
    display: "flex",
    marginTop: "1rem",
    padding: "l.5rem",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default (variant: "function-and-flow" | "brief") => {
  const raw = variant + "-raw";
  return (props: PageComponentProps) => {
    const classes = useStyles();

    const [annoOpen, setAnnoOpen] = React.useState<boolean>(false);
    const [FF, setFF] = React.useState<null | {
      functionFlow: Media | null;
      functionFlowRaw: Media | null;
    }>(null);

    console.log("FF", FF);

    React.useEffect(() => {
      getFFandRaw();
    }, []);

    if (!FF) {
      return <LinearProgress variant="indeterminate" />;
    }

    if (!FF.functionFlow) {
      return (
        <Typography variant="h3" align="center">
          Media Not Found
        </Typography>
      );
    }

    return (
      <div className={classes.root}>
        {variant === "function-and-flow" && (
          <AnnotatorPage
            readonly
            media={[FF.functionFlow]}
            open={annoOpen}
            profile={props.profile}
            user={
              {
                uid: props.agent.userId,
                ...props.agent,
              } as unknown as firebase.User
            }
            onClose={() => setAnnoOpen(false)}
            currentUser="agent"
          />
        )}
        <div className={classes.topBar}>
          <YZButton
            variant="outlined"
            disabled={!FF || !FF.functionFlowRaw}
            onClick={() => {
              if (FF) {
                downloadAndZip([FF.functionFlowRaw as any], raw);
              }
            }}
          >
            Download Raw File
          </YZButton>
          {variant === "function-and-flow" && (
            <YZButton
              variant="outlined"
              onClick={() => setAnnoOpen(true)}
              disabled={!FF || !FF.functionFlow}
              style={{ marginRight: "1rem" }}
            >
              View Annotations
            </YZButton>
          )}
        </div>

        <div className={classes.ffContainer}>
          <MediaGrid
            userId={props.profile.id}
            permissions={{
              media: {
                access: "not update",
              },
            }}
            media={[FF.functionFlow] as Media[]}
            onDeleteComplete={() => {}}
            onUploadComplete={() => {}}
          />
        </div>
      </div>
    );

    async function getFFandRaw(): Promise<void> {
      const functionFlow = await getSingleMedia(props.profile.id, variant);
      const functionFlowRaw = await getSingleMedia(props.profile.id, raw);

      setFF({
        functionFlow: functionFlow,
        functionFlowRaw: functionFlowRaw,
      });
    }
  };

  async function getSingleMedia(userId: string, tag: string) {
    try {
      return await Media.createFromQuerySnapshot(
        (
          await firebase
            .firestore()
            .collection("media")
            .where("userId", "==", userId)
            .where("tag", "==", tag)
            .get()
        ).docs[0]
      );
    } catch {
      return null;
    }
  }
};
