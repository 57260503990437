import { CircularProgress } from "@material-ui/core";
import { ApolloAuthenticationCtx } from "@yardzen-inc/graphql";
import { Project } from "@yardzen-inc/models";
import React, { FC, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import BudgetInformationDisplay from "./BudgetInformationDisplay";

export interface BudgetInformationDisplayTabProps extends RouteComponentProps {
  userId: string;
  packageType?: string;
}

const BudgetInformationDisplayTab: FC<BudgetInformationDisplayTabProps> = (
  props
) => {
  const [project, setProject] = useState<Project | null>(null);

  const ApolloAuthenticated = useContext(ApolloAuthenticationCtx);

  useEffect(onMount, []);

  return project && ApolloAuthenticated ? (
    <BudgetInformationDisplay
      projectId={project?.id}
      profileId={props.userId}
      yardDifficultyRating={project?.yardDifficultyRating}
      clientBudgetIntent={project?.clientBudgetIntent}
      packageType={props.packageType}
    />
  ) : (
    <CircularProgress />
  );

  function onMount() {
    void (async function () {
      try {
        const project = await Project.fetchWithProfileId(props.userId);

        setProject(project);
      } catch (error) {
        console.error(error);
      }
    })();
  }
};

export { BudgetInformationDisplayTab };
export default BudgetInformationDisplayTab;
