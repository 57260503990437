import { ApolloQueryResult, useLazyQuery } from "@apollo/client";
import { Chip, makeStyles, Theme } from "@material-ui/core";
import { PRODUCT_BASE_AGGREGATE, NetworkStatus } from "@yardzen-inc/graphql";
import { formatNumToUSD } from "@yardzen-inc/react-common";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { arrayMove } from "react-sortable-hoc";
import AssetTable from "../../EmployeeView/AssetLibraries/Shared/AssetTable";
import ElementSelectionTableRow from "./ElementSelectionTableRow";
import {
  HasuraQueryFunctions,
  HasuraQueryHumanReadableOperatorEnum,
} from "./Shared/HasuraQueryFunctions";
import ProductLibraryFilterBoxOptions from "../../EmployeeView/AssetLibraries/ElementLibrary/ElementLibraryFilterBoxOptions";
import TableCellWithStyles from "../../EmployeeView/AssetLibraries/Shared/AssetTableCellWithStyles";
import ElementsTableFilters from "../../EmployeeView/AssetLibraries/ElementLibrary/ElementsTableFilters";
import { GraphqlConstants } from "../../ConstantValues/graphqlConstants";
import getAssetLibraryOrderBy from "../../EmployeeView/AssetLibraries/getAssetLibraryOrderBy";
import SelectionTableBox from "./Shared/SelectionTableBox";
import cleanedDownloadableFileName from "../../EmployeeView/AssetLibraries/Shared/CleanedDownloadableFileName";
import usePreloadedAssetTableFilters from "../../util/hooks/usePreloadedAssetTableFilters";
import AssetLibraryFilterMenu from "../../EmployeeView/AssetLibraries/Shared/AssetLibraryFilterMenu";
import { AssetLibraryFetchMoreStatus } from "../../ConstantValues/AssetLibraryFetchMoreStatusConstants";
import uuid from "uuid";
import getObjectAndArrayRelationships from "../../EmployeeView/AssetLibraries/Shared/getObjectAndArrayRelationships";
import {
  elementArrayLinksMap,
  elementObjLinksMap,
} from "../../EmployeeView/AssetLibraries/Shared/FilterLinkMaps";
import { AssetLibraryTypeConstants } from "../../ConstantValues/AssetLibraryTypeConstants";
import { ModelFileDownloadButton } from "../ModelFileDownloadButton";
import { ModelFiletype } from "../../Interfaces";
import { applyAndNullFiltering } from "./applyAndNullFiltering";
import { PRODUCT_BASE } from "../../EmployeeView/AssetLibraries/Shared/graphqlQueries";
import { useGetLowesRetailer } from "../../util/hooks/useGetLowesRetailer";

let saveTimeout: ReturnType<typeof setTimeout> | null = null;

const listResultName = "product_base";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  longLink: {
    width: "10rem",
    maxWidth: "10rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  iconUri: {
    width: "6.25rem",
    height: "6.25rem",
    objectFit: "contain",
  },
  assetFilters: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(1),
  },
  errorMessage: {
    position: "relative",
    top: 75,
    left: 10,
  },
  chipFilter: {
    fontSize: 10,
    letterSpacing: 0.5,
    marginRight: "0.1rem",
  },
}));

interface Props {
  designAssignmentId: string;
  userId: string;
  onChangingQuantity: (v: boolean) => void;
  projectId: string;
  projectRegion?: {
    id: string;
    name: string;
  };
  loadingProjectRegion?: boolean;
  links: {
    product_base_id: string;
  }[];
  dispatch: any;
  onUpdateQuantity: (
    id: string,
    quantity: number,
    ackId: string,
    type: AssetLibraryTypeConstants
  ) => void;
  isLowesProject: boolean;
}

function ElementSelectionTable(props: Props) {
  const classes = useStyles();

  const [
    neqFilterState,
    setNeqFilterState,
    hasAnyOfFilterState,
    setHasAnyOfFilterState,
    hasAllOfFilters,
    setHasAllOfFilters,
    search,
    setSearch,
  ] = usePreloadedAssetTableFilters("elements", props.designAssignmentId);

  const [filterByProjectRegion, setFilterByProjectRegion] = useState(
    !!props.projectRegion && props.loadingProjectRegion === false
  );

  const [filterByRetailer, setFilterByRetailer] = useState<Boolean>(false);

  const [showArchived, setShowArchived] = useState(false);
  const [minPrice, setMinPrice] = useState<number | null>(null);
  const [maxPrice, setMaxPrice] = useState<number | null>(null);

  const onSearch = (term: string) => {
    setSearch(term);
  };

  const [queryBy, setQueryBy] = useState({
    field: "updated_at",
    direction: "desc",
  });

  const [getBases, { data, refetch, error, fetchMore, networkStatus }] =
    useLazyQuery(PRODUCT_BASE, {
      notifyOnNetworkStatusChange: true,
    });

  if (error) {
    throw error;
  }

  const [getCount, { data: dataCount }] = useLazyQuery(PRODUCT_BASE_AGGREGATE, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!refetch) {
      return;
    }
    saveTimeout = setTimeout(async () => {
      refetch(getVariables());
      getCount({
        variables: getVariables(),
      });
    }, 300);

    return () => {
      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }
    };
  }, [
    refetch,
    search,
    hasAllOfFilters,
    neqFilterState,
    hasAnyOfFilterState,
    filterByProjectRegion,
    filterByRetailer,
    minPrice,
    maxPrice,
  ]);

  useEffect(() => {
    getBases({
      variables: getVariables(),
    });
    getCount({
      variables: getVariables(),
    });
  }, [getBases]);

  const [loadingRetailer, lowesRetailer] =
    useGetLowesRetailer("product_retailer");

  useEffect(() => {
    if (props.isLowesProject) {
      if (!loadingRetailer && typeof lowesRetailer === "object") {
        setFilterByRetailer(true);
      }
    }
  }, [props.isLowesProject, lowesRetailer, loadingRetailer]);

  const applyRetailerCondition = (condition: object) => {
    if (!filterByRetailer) {
      return;
    }

    if (typeof lowesRetailer === "object") {
      HasuraQueryFunctions.processAndConditionsNoLinks({
        condition,
        operator: HasuraQueryHumanReadableOperatorEnum.hasAnyOf,
        fields: [
          {
            data: [lowesRetailer.id],
            fieldName: "product_retailer",
          },
        ],
      });
    }
  };

  const applyRegionCondition = (condition: object) => {
    if (
      !filterByProjectRegion ||
      !props.projectRegion ||
      props.loadingProjectRegion !== false
    ) {
      return;
    }

    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAllOf,
      fields: [
        {
          data: [props.projectRegion.id],
          links: "product_base_region_links",
          fieldName: "id_product_region",
        },
      ],
    });
  };

  const getIdAndQuantityMap = () => {
    const assignment = props.links;
    if (assignment.length) {
      const idMap = {};
      assignment.forEach((link: any) => {
        // @ts-ignore
        if (!idMap[link.product_base_id]) {
          // @ts-ignore
          idMap[link.product_base_id] = 1;
        } else {
          // @ts-ignore
          idMap[link.product_base_id] += 1;
        }
      });
      return idMap;
    } else {
      return {};
    }
  };

  const getVariables = (useOffset?: boolean, forceShowArchived?: boolean) => {
    const condition: any = {};
    if (search) {
      condition.name = { _ilike: `%${search}%` };
    }
    if (typeof forceShowArchived === "boolean") {
      condition.archived = {
        _eq: forceShowArchived,
      };
    } else {
      condition.archived = {
        _eq: showArchived,
      };
    }

    if (typeof minPrice === "number") {
      condition.msrp = {};
      condition.msrp._gte = minPrice * 100;
    }
    if (typeof maxPrice === "number") {
      if (!condition.msrp) {
        condition.msrp = {};
      }
      condition.msrp._lte = maxPrice * 100;
    }

    applyRegionCondition(condition);
    applyRetailerCondition(condition);

    const [hasAllOfObjectRelationships, hasAllOfArrayRelationships] =
      getObjectAndArrayRelationships(
        hasAllOfFilters,
        elementArrayLinksMap,
        elementObjLinksMap
      );

    HasuraQueryFunctions.processAndConditionsNoLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAllOf,
      fields: hasAllOfObjectRelationships,
    });
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAllOf,
      fields: hasAllOfArrayRelationships,
    });
    const [hasAnyOfObjectRelationships, hasAnyOfArrayRelationships] =
      getObjectAndArrayRelationships(
        hasAnyOfFilterState,
        elementArrayLinksMap,
        elementObjLinksMap
      );

    HasuraQueryFunctions.processAndConditionsNoLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAnyOf,
      fields: hasAnyOfObjectRelationships,
    });
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAnyOf,
      fields: hasAnyOfArrayRelationships,
    });
    const [hasNoneOfObjectRelationships, hasNoneOfArrayRelationships] =
      getObjectAndArrayRelationships(
        neqFilterState,
        elementArrayLinksMap,
        elementObjLinksMap
      );
    HasuraQueryFunctions.processAndConditionsNoLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasNoneOf,
      fields: hasNoneOfObjectRelationships,
    });
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasNoneOf,
      fields: hasNoneOfArrayRelationships,
    });

    // Designers should only see released assets, ie. visibility === "ALL"
    condition.visibility = {
      _eq: "ALL",
    };

    applyAndNullFiltering(condition, "product_base_region_links");

    return {
      condition,
      orderBy: getAssetLibraryOrderBy(queryBy.field, queryBy.direction),
      offset: useOffset ? data[listResultName].length : 0,
    };
  };

  const tableContainerRef = useRef(null);

  const onScroll = () => {
    const target: any = tableContainerRef.current;
    if (!target) return;
    let pos = target.scrollTop + target.offsetHeight;
    let max = target.scrollHeight;
    // fetching before user hits the bottom of the screen so by
    // the time the user gets to the bottom, stuff will have loaded.
    if (
      pos >= max - 700 &&
      data.product_base.length % GraphqlConstants.resultsPerQuery === 0 &&
      data.product_base.length !== 0 &&
      data.product_base.length !==
        dataCount?.product_base_aggregate.aggregate.count
    ) {
      requestMoreResults();
    }
  };

  const hasMoreResults = useRef(
    data?.product_base.length !==
      dataCount?.product_base_aggregate.aggregate.count
  );

  const toggleArchived = () => {
    if (!refetch) {
      return alert(
        "Something went wrong. Please reload the screen and try again."
      );
    }
    const newArchived = !showArchived;
    refetch({ ...getVariables(undefined, newArchived) });
    getCount({
      variables: { ...getVariables(undefined, newArchived) },
    });
    setShowArchived(newArchived);
  };

  const onPriceChange = (value: string, prop: "min" | "max") => {
    let targetValue: number | null = parseFloat(value);
    if (isNaN(targetValue)) {
      targetValue = null;
    }
    if (prop === "min") {
      return setMinPrice(targetValue);
    }
    setMaxPrice(targetValue);
  };

  const requestRefetch = () => {
    if (!refetch) {
      return;
    }
    return refetch({ ...getVariables() });
  };

  const inStockText = (row: any) => {
    if (typeof row.in_stock === "boolean") {
      return row.in_stock ? "Yes" : "No";
    }
    return "--";
  };

  const requestMoreResults = async () => {
    setFetchMoreStatus(AssetLibraryFetchMoreStatus.shouldFetch);
  };

  const fetchMoreResults = async () => {
    if (!fetchMore) {
      return alert(
        "Something went wrong. Please reload the screen and try again."
      );
    }
    await fetchMore({
      variables: getVariables(true),
      updateQuery: (prev: any, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const iterableProductBase = Array.isArray(prev[listResultName])
          ? prev[listResultName]
          : [];
        const newArray = Array.from(
          new Set([...iterableProductBase, ...fetchMoreResult[listResultName]])
        );
        // it's possible that the apollo caching mechanism may
        // provide the fetchMoreResult from the previous iteration
        // if the user is moving quickly, so let's adjust for that
        // and ensure we're not duplicating any data
        let uniqueArray: any[] = [];
        for (let it = 0; it < newArray.length; it++) {
          if (uniqueArray.some((line) => line.id === newArray[it].id)) {
            continue;
          }
          uniqueArray.push(newArray[it]);
        }
        const resultToSend = Object.assign({}, prev, {
          [listResultName]: uniqueArray,
        });
        return resultToSend;
      },
    });
  };

  const [fetchMoreStatus, setFetchMoreStatus] =
    useState<AssetLibraryFetchMoreStatus>(AssetLibraryFetchMoreStatus.standby);

  // if the user has scrolled to the bottom
  // but the network status is not in "ready" state,
  // we want this useEffect to listten for the network status to be ready
  // and as soon as ready, fetch more results.
  useEffect(() => {
    if (networkStatus !== NetworkStatus.ready) return;
    if (fetchMoreStatus !== AssetLibraryFetchMoreStatus.shouldFetch) return;
    fetchMoreResults();
    setFetchMoreStatus(AssetLibraryFetchMoreStatus.fetching);
  }, [fetchMoreStatus, networkStatus]);

  useEffect(() => {
    if (data === undefined) return;
    setFetchMoreStatus(AssetLibraryFetchMoreStatus.standby);
  }, [data]);

  const onSort = (
    v: number,
    queryName: string
  ): void | Promise<ApolloQueryResult<any>> => {
    if (!refetch) {
      return;
    }
    let stateObj = {};
    if (v === 0) {
      window.history.replaceState(stateObj, "", `/elements-library?`);
      return refetch({ ...getVariables() });
    }
    const direction = v === -1 ? "desc" : "asc";
    if (window.history.replaceState) {
      //prevents browser from storing history with each change:
      window.history.replaceState(
        stateObj,
        "",
        `/elements-library?sortColumn=${queryName}&sortDirection=${direction}`
      );
    }
    setQueryBy({ field: queryName, direction });
    refetch({
      ...getVariables(),
      orderBy: getAssetLibraryOrderBy(queryName, direction),
    });
  };

  // for designers, we will only show them the MSRP to make filtering a little
  // easier. In the future, we'll want to re-examine the ability to show unit cost before MSRP and filter
  // accordingly.
  const getCost = (row: any): number => {
    return parseFloat(row.msrp || "0");
  };

  const initialColumns = [
    {
      name: "Name",
      id: "name",
      renderCellContent: (row: any) => row.name,
    },
    {
      name: "Image",
      id: "icon_uri",
      renderCellContent: (row: any) => (
        <img className={classes.iconUri} src={row.icon_uri} />
      ),
    },
    {
      name: "Type",
      id: "product_base_product_type_links",
      renderCellContent: (row: any) =>
        row.product_base_product_type_links
          ?.map((style: any) => style.product_type.name)
          .join(", "),
    },
    {
      name: "Cost",
      id: "unit_cost",
      renderCellContent: (row: any) => formatNumToUSD(getCost(row)),
    },
    {
      name: "Labor Cost",
      id: "labor_cost",
      renderCellContent: (row: any) =>
        formatNumToUSD(parseFloat(row.labor_cost || 0)),
    },
    {
      name: "Installed Cost",
      id: "installed_cost",
      renderCellContent: (row: any) =>
        formatNumToUSD(parseFloat(row.labor_cost || 0) + getCost(row)),
    },
    {
      name: "Style",
      id: "product_base_product_style_links",
      renderCellContent: (row: any) =>
        row.product_base_product_style_links
          ?.map((style: any) => style.product_style.name)
          .join(", "),
    },
    {
      name: "Link",
      id: "link",
      renderCellContent: (row: any) => (
        <div className={classes.longLink}>
          <a href={row.link} target="_blank">
            {row.link}
          </a>
        </div>
      ),
    },
    {
      name: "Price Tier",
      id: "priceTierByProductPriceTier_simplejoin",
      sortable: true,
      queryName: "priceTierByProductPriceTier.name",
      renderCellContent: (row: any) => row.priceTierByProductPriceTier?.name,
    },
    {
      name: "Vendor",
      id: "vendorByProductVendor_simplejoin",
      sortable: true,
      queryName: "vendorByProductVendor.name",
      renderCellContent: (row: any) => row.vendorByProductVendor?.name,
    },
    {
      name: "Retailer",
      id: "retailerByProductRetailer_simplejoin",
      sortable: true,
      queryName: "retailerByProductRetailer.name",
      renderCellContent: (row: any) => row.retailerByProductRetailer?.name,
    },
    {
      name: "Collection Name",
      id: "collectionNameByProductCollectionName_simplejoin",
      sortable: true,
      queryName: "collectionNameByProductCollectionName.name",
      renderCellContent: (row: any) =>
        row.collectionNameByProductCollectionName?.name,
    },
    {
      name: "In Stock",
      id: "in_stock",
      renderCellContent: (row: any) => (
        <div style={{ color: row.in_stock === false ? "red" : "" }}>
          {inStockText(row)}
        </div>
      ),
    },
    {
      name: "Setting",
      id: "settingByProductSetting_simplejoin",
      sortable: true,
      queryName: "settingByProductSetting.name",
      renderCellContent: (row: any) => row.settingByProductSetting?.name,
    },
    {
      name: "Material",
      id: "product_base_product_material_links",
      renderCellContent: (row: any) =>
        row.product_base_product_material_links
          ?.map((style: any) => style.product_material.name)
          .join(", "),
    },
    {
      name: "Color",
      id: "product_base_product_color_links",
      renderCellContent: (row: any) =>
        row.product_base_product_color_links
          ?.map((style: any) => style.product_color.name)
          .join(", "),
    },
    {
      name: "Sketchup File",
      id: "sketchup_file_link",
      queryName: "sketchup_file_link",
      sortable: true,
      renderCellContent: (row: any) => (
        <ModelFileDownloadButton
          filetype={ModelFiletype.sketchup}
          filenameOrUrl={row.sketchup_file_link}
          downloadableFileName={cleanedDownloadableFileName(row.name)}
        />
      ),
    },
    {
      name: "Lumion File",
      id: "lumion_file_link",
      queryName: "lumion_file_link",
      sortable: true,
      renderCellContent: (row: any) => (
        <ModelFileDownloadButton
          filetype={ModelFiletype.lumion}
          filenameOrUrl={row.lumion_file_link}
          downloadableFileName={cleanedDownloadableFileName(row.name)}
        />
      ),
    },
    {
      name: "Lumion File Name",
      id: "lumion_file_name",
      renderCellContent: (row: any) => row.lumion_file_name,
    },
  ];

  const [columns, setColumns] = useState(initialColumns);

  const InitialTableCells = (row: any) => {
    const cells = columns.map((column) => {
      let innerContent = column.renderCellContent(row);

      return [
        <TableCellWithStyles
          key={`${row.id}-${column.id}`}
          id={column.id}
          align="right"
          hidden={
            // @ts-ignore
            hiddenColumns?.[column.id]
          }
        >
          {innerContent}
        </TableCellWithStyles>,
      ];
    });

    cells[0].push(row);
    return cells;
  };

  const [hiddenColumns, setHiddenColumns] = useState({});

  const onReorder = (oldIndex: number, newIndex: number) => {
    setColumns(arrayMove(columns, oldIndex, newIndex));
  };

  const handleToggleColumnVisible = (id: string) => {
    const updatedHiddenColumns = { ...hiddenColumns };
    // @ts-ignore
    updatedHiddenColumns[id] = !updatedHiddenColumns[id];
    setHiddenColumns(updatedHiddenColumns);
  };

  // todo: this can probably just be useRef since not changing its value
  const [idAndQuantityMap] = useState<null | {
    [v: string]: number;
  }>(getIdAndQuantityMap());

  const onUpdateQuantity = (id: string, quantity: number) => {
    props.onUpdateQuantity(
      id,
      quantity,
      uuid.v4(),
      AssetLibraryTypeConstants.product
    );
  };

  const renderFilters = () => {
    const secondaryFilters = [];

    secondaryFilters.push(
      <ElementsTableFilters
        // @ts-ignore
        onPriceChange={onPriceChange}
        minPrice={minPrice}
        maxPrice={maxPrice}
      />
    );

    if (filterByProjectRegion) {
      secondaryFilters.push(
        <Chip
          size="small"
          color="primary"
          label={`Region: ${props.projectRegion?.name}`}
          onDelete={() => {
            setFilterByProjectRegion(false);
          }}
          className={classes.chipFilter}
        />
      );
    }

    if (filterByRetailer) {
      secondaryFilters.push(
        <Chip
          size="small"
          color="primary"
          label="Retailer: Lowe's"
          className={classes.chipFilter}
          onDelete={() => {
            setFilterByRetailer(false);
          }}
        />
      );
    }

    return (
      <AssetLibraryFilterMenu
        options={ProductLibraryFilterBoxOptions}
        neqFilterState={neqFilterState}
        setNeqFilterState={setNeqFilterState}
        hasAnyOfFilterState={hasAnyOfFilterState}
        setHasAnyOfFilterState={setHasAnyOfFilterState}
        hasAllOfFilters={hasAllOfFilters}
        setHasAllOfFilters={setHasAllOfFilters}
        secondaryFilters={secondaryFilters}
      />
    );
  };

  const memoizedAssetTable = useMemo(
    () => (
      <AssetTable
        showLoadMoreButton={hasMoreResults.current}
        onScroll={onScroll}
        tableContainerRef={tableContainerRef}
        contentHeight="calc(100vh - 15.6rem)"
        onReorder={onReorder}
        tableColumns={columns}
        hiddenColumns={hiddenColumns}
        onToggleColumnVisible={handleToggleColumnVisible}
        search={search}
        hideArchive
        resultsCount={dataCount?.product_base_aggregate.aggregate.count || 0}
        onSort={onSort}
        initialTableCells={InitialTableCells}
        toggleArchived={toggleArchived}
        showArchived={showArchived}
        onAddNew={() =>
          window.open("https://airtable.com/shrwJygwkHzq7bTP3", "_blank")
        }
        addNewButtonLabel="Request Element"
        onSearch={onSearch}
        requestMoreResults={requestMoreResults}
        networkStatus={networkStatus}
        data={data}
        error={error}
        requestRefetch={requestRefetch}
        addNewAssetComponent={(assetProps) => (
          <div>
            This feature is disabled. Please contact support for more
            information.
          </div>
        )}
        rowComponent={(rowProps) => (
          <ElementSelectionTableRow
            {...rowProps}
            onUpdateQuantity={onUpdateQuantity}
            idAndQuantityMap={idAndQuantityMap as object}
            refetch={refetch}
          />
        )}
        filters={renderFilters}
        title="Elements"
        listResultName="product_base"
        fixedHeader
        fixedTop={66}
      />
    ),
    [
      data,
      dataCount,
      filterByProjectRegion,
      filterByRetailer,
      hiddenColumns,
      idAndQuantityMap,
      neqFilterState,
      hasAnyOfFilterState,
      hasAllOfFilters,
      search,
      networkStatus,
      minPrice,
      maxPrice,
      props.projectRegion,
    ]
  );

  return <SelectionTableBox>{memoizedAssetTable}</SelectionTableBox>;
}

export default ElementSelectionTable;
