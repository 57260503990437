import React, { FC, useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";
import { DesignBriefEntry, DesignBriefEntryType } from "./util";
import DesignBriefEntryEditFormTypeSelect from "./DesignBriefEntryEditFormTypeSelects";
import DesignBriefEntryEditFormLabelField from "./DesignBriefEntryEditFormLabelField";
import { useSkipMount } from "../../util/hooks";
import DesignBriefEntryEditFormValueField from "./DesignBriefEntryEditFormValueField";
import DesignBriefEntryFormActions from "./DesignBriefEntryFormActions";
import regs from "http-url-regex";

export interface DesignBriefEntryEditFormProps extends BoxProps {
  entry: DesignBriefEntry<any>;
  update: (updatedEntry: DesignBriefEntry<any>) => any;
  onClose: () => void;
}

export type EntryValueType = string | string[];

const DesignBriefEntryEditForm: FC<DesignBriefEntryEditFormProps> = ({
  entry,
  onClose,
  update,
  ...containerProps
}) => {
  const [label, setLabel] = useState<string>(entry.label ?? "");
  const [link, setLink] = useState<string>(entry.referenceLink ?? "");
  const [type, setType] = useState<DesignBriefEntryType>(
    entry.type ?? DesignBriefEntryType.TEXT
  );
  const [value, setValue] = useState<EntryValueType>(getDefaultValue);
  const [options, setOptions] = useState<string[] | undefined>(entry.options);
  const [loading, setLoading] = useState<boolean>(false);

  const [urlInvalid, setUrlInvalid] = useState<boolean>(false);

  const firstRunComplete = useSkipMount();

  useEffect(handleEntryTypeChange, [type]);

  return (
    <Box display="flex" flexDirection="column" {...containerProps}>
      <DesignBriefEntryEditFormTypeSelect
        value={type}
        onChange={setType}
        p={1}
      />
      <DesignBriefEntryEditFormLabelField
        value={label}
        onChange={setLabel}
        p={1}
      />
      <DesignBriefEntryEditFormLabelField
        error={urlInvalid ? "Invalid Link" : null}
        title="Link"
        value={link}
        onChange={setLink}
        p={1}
      />
      <DesignBriefEntryEditFormValueField
        onChange={setValue}
        options={options}
        onOptionsChange={setOptions}
        type={type}
        value={value}
      />
      <DesignBriefEntryFormActions
        onApply={handleApply}
        onCancel={onClose}
        disabled={loading}
      />
    </Box>
  );

  async function handleApply(): Promise<void> {
    if (!!link && !regs.url.test(link)) {
      return setUrlInvalid(true);
    }

    setLoading(true);
    await update({
      ...entry,
      label,
      type,
      value,
      options,
      referenceLink: link,
    } as any);

    setLoading(false);
    onClose();
  }

  function handleEntryTypeChange(): void {
    if (!firstRunComplete) {
      return;
    }

    setValue(getDefaultValue());
  }

  function getDefaultValue(): EntryValueType {
    if (type === DesignBriefEntryType.TEXT) {
      if (entry.type === DesignBriefEntryType.TEXT && entry.value) {
        return entry.value ?? "";
      }

      return "";
    }

    if (type === DesignBriefEntryType.MULITPLE_CHOICE) {
      if (entry.type === DesignBriefEntryType.MULITPLE_CHOICE) {
        return entry.value ?? "they're";
      }

      return "";
    }

    throw new Error("entry type does not exist or is not implemented");
  }
};

export { DesignBriefEntryEditForm };
export default DesignBriefEntryEditForm;
