import * as React from "react";
import { Profile } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { UserCtx } from "./UserContext";

interface Props {
  children: React.ReactNode[] | React.ReactNode;
}

export const ProfileCtx = React.createContext<Profile | null | false>(null);

export default ProfileCtx;

export const ProfileProvider = React.memo((props: Props) => {
  let user: firebase.User | null | false = React.useContext(UserCtx);
  const [profile, setProfile] = React.useState<Profile | null | false>();

  React.useEffect(() => {
    do {
      if (!user) {
        setProfile(null);
        break;
      }
      if (!!profile) break;

      user = user as firebase.User;

      firebase
        .firestore()
        .collection("profiles")
        .doc(user.uid)
        .get()
        .then((doc: any) => {
          if (!doc.exists) return setProfile(false);
          const data: Profile = doc.data();

          if (!user || !isAuthorizedAccountType(user, data)) {
            return firebase.auth().signOut();
          }

          data["id"] = doc.id;
          return setProfile(data);
        });
    } while (false);
  }, [user]);

  return (
    <ProfileCtx.Provider value={profile as Profile | null}>
      {props.children}
    </ProfileCtx.Provider>
  );
});

const isAuthorizedAccountType = (user: firebase.User, profile: Profile) => {
  if (!user.email) {
    return false;
  }

  if (user.email.indexOf("@yardzen.co") !== -1) {
    return true;
  }

  if (
    // @ts-ignore
    profile["isEmployee"] ||
    // @ts-ignore
    profile["isDesigner"] ||
    // @ts-ignore
    profile["isModeler"] ||
    // @ts-ignore
    profile["isWesley"]
  ) {
    return true;
  }

  return false;
};
