import React, { FC } from "react";
import { IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { IconButtonProps } from "@material-ui/core/IconButton";

export interface AddButtonProps extends IconButtonProps {}

const AddButton: FC<AddButtonProps> = (props) => {
  return (
    <IconButton {...props}>
      <Add></Add>
    </IconButton>
  );
};

export { AddButton };
export default AddButton;
