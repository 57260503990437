import React, { FC, useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";
import { DesignBriefCtx, UseDesignBriefLoadingState } from "./util";

export interface ChecklistInstructionsProps extends BoxProps {}

const commonBoxProps: BoxProps = {
  pb: 1,
  pl: 2,
};

const ChecklistInstructions: FC<ChecklistInstructionsProps> = ({
  ...containerProps
}) => {
  const { loadingState } = useContext(DesignBriefCtx);

  if (loadingState !== UseDesignBriefLoadingState.DONE) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="#E5E5E5"
      {...containerProps}
    >
      <Box pb={2}>
        <Typography>DESIGNER INSTRUCTIONS:</Typography>
      </Box>
      <Box {...commonBoxProps}>
        <Typography>
          1. Check the box next to each item below to indicate that you have
          addressed it in your design process.
        </Typography>
      </Box>
      <Box {...commonBoxProps}>
        <Typography>
          2. If you are not able to include an item in your design, use the
          notes section at the bottom to explain why.
        </Typography>
      </Box>
      <Box {...commonBoxProps}>
        <Typography>
          3. You may list any additional notes to help explain your design
          decisions in the notes section as well.
        </Typography>
      </Box>
      <Box {...commonBoxProps}>
        <Typography>
          4. Each planting notes seciton includes the plant database filters to
          use when selecting plants.
        </Typography>
      </Box>
    </Box>
  );
};

export { ChecklistInstructions };
export default ChecklistInstructions;
