import { SelectedItemsElement } from "./constants/SelectedItemsConstants";
import { groupBy } from "lodash";
import React from "react";
import { IAssignmentProductBaseLink, IProductBase } from "../../Interfaces";
import { DownloadUtil } from "../../util/downloadUtil";
import { formatNumToUSD } from "@yardzen-inc/react-common";
import uuid from "uuid";
import QuantityInput from "./QuantityInput";
import cleanedDownloadableFileName from "../../EmployeeView/AssetLibraries/Shared/CleanedDownloadableFileName";
import GetAppIcon from "@material-ui/icons/GetApp";

export const getSelectedElementsForBudgetQA = (
  queryDataSelection: any,
  nonPm: boolean = false,
  onUpdateElementQty: (qty: number, id: string) => void,
  enableQuantityChange: boolean = false,
  quantityInputContainer: string,
  assignment_product_base_links?: IAssignmentProductBaseLink[]
): SelectedItemsElement[] => {
  const dataSelectionAssignment = queryDataSelection?.assignment[0];
  const noDataSelected = !queryDataSelection?.assignment?.length;
  if (
    noDataSelected ||
    !dataSelectionAssignment.assignment_product_base_links?.length
  ) {
    return [];
  }
  const idAndQuantityMap = groupBy(
    assignment_product_base_links,
    "product_base_id"
  );

  const grouped = groupBy(
    dataSelectionAssignment.assignment_product_base_links,
    "product_base.id"
  );

  return Object.keys(grouped).map((key) => {
    const link: any = grouped[key][0];
    const productBase: IProductBase = link.product_base;
    const quantity = nonPm
      ? idAndQuantityMap[key]?.length || 0
      : grouped[key]?.length || 0;
    const onUpdateQty = (qty: number) => {
      onUpdateElementQty(qty, productBase.id);
    };

    const types = productBase.product_base_product_type_links?.map(
      (typeLink: any) => typeLink.product_type?.name
    );

    const category = types.includes("Exterior Design")
      ? "Exterior Design"
      : types.includes("Recreational")
      ? "Recreational"
      : "Furniture";

    return {
      id: productBase.id || uuid.v4(),
      totalCost: formatNumToUSD(
        parseFloat(productBase.msrp || "0") * quantity +
          parseFloat(productBase.labor_cost || "0") * quantity
      ),
      quantity: enableQuantityChange ? (
        <div className={quantityInputContainer}>
          <QuantityInput onUpdate={onUpdateQty} value={quantity} />
        </div>
      ) : (
        quantity
      ),
      quantityNumber: quantity,
      name: productBase.name,
      type: types.join(", "),
      image: productBase.icon_uri,
      vendor: productBase.vendorByProductVendor?.name,
      style: productBase.product_base_product_style_links
        ?.map((styleLink: any) => styleLink.product_style?.name)
        .join(", "),
      priceTier: productBase.priceTierByProductPriceTier?.name,
      category,
      lumionFileLink: productBase.lumion_file_link ? (
        <div
          onClick={() =>
            DownloadUtil.downloadWithFirebaseCredentials(
              productBase.lumion_file_link,
              undefined,
              cleanedDownloadableFileName(productBase.name)
            )
          }
          style={{ pointerEvents: "all", display: "inline-block" }}
        >
          <GetAppIcon />
        </div>
      ) : null,
      lumionFileName: productBase.lumion_file_name,
      sketchupFileLink: productBase.sketchup_file_link ? (
        <div
          onClick={() =>
            DownloadUtil.downloadWithFirebaseCredentials(
              productBase.sketchup_file_link,
              undefined,
              cleanedDownloadableFileName(productBase.name)
            )
          }
          style={{ pointerEvents: "all", display: "inline-block" }}
        >
          <GetAppIcon />
        </div>
      ) : null,
    };
  });
};
