import React, { FC, useState, ReactNode } from "react";
import { PageComponentProps } from "../../SelfAssignPage/CurrentJobPageMap";
import { WesleySummaryPage, PCP } from "./WesleySummaryPage";
import { Box, Tabs, Tab } from "@material-ui/core";
import { DesignBriefPage } from "../../Components/designBrief";

export type WesleySummaryPageWrapperProps = PageComponentProps &
  PCP & { pm?: boolean };

const WesleySummaryPageWrapper: FC<WesleySummaryPageWrapperProps> = (props) => {
  const [tabValue, setTabValue] = useState<string>("uploads");

  return (
    <>
      <Box width="100%">
        <Tabs
          value={tabValue}
          onChange={(_, value) => {
            setTabValue(value);
          }}
        >
          <Tab label="Uploads" value="uploads" />
          <Tab label="Design Brief" value="db" />
        </Tabs>
      </Box>
      <Box px={2} minWidth="100%">
        {render()}
      </Box>
    </>
  );

  function render(): ReactNode {
    if (tabValue === "uploads") {
      return <WesleySummaryPage {...props} />;
    } else if (tabValue === "db") {
      return (
        <DesignBriefPage profile={props.profile} project={props.project} pm />
      );
    }

    throw new Error(`tab value ${tabValue} is illegal`);
  }
};

export { WesleySummaryPageWrapper };
export default WesleySummaryPageWrapper;
