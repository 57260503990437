import React, { FC, useState, useMemo, memo, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { DesignProfileWithId } from "./useDesignProfiles";
import DesignProfileListItem from "./DesignProfileListItem";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";

const useStyles = makeStyles(() =>
  createStyles({
    fixedBar: {
      width: "calc(100vw -240px)",
    },
  })
);

export interface DesignProfileListProps {
  DesignProfiles: DesignProfileWithId[];
}

const ITEMS_PER_PAGE = 10;

let DesignProfileList: FC<DesignProfileListProps> = (props) => {
  const [offset, setOffset] = useState<number>(0);

  const classes = useStyles();

  const len = useMemo(
    () => props.DesignProfiles.length,
    [props.DesignProfiles]
  );

  useEffect(() => window.scrollTo({ top: 0 }), [offset]);

  const itemsOnPage: DesignProfileWithId[] = useMemo(() => {
    const start = offset * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;

    const ds = props.DesignProfiles.slice(start, end);
    return ds;
  }, [offset, props.DesignProfiles]);

  return (
    <Box position="relative">
      <Box display="flex" flexDirection="column">
        {itemsOnPage.map(renderListItem)}
      </Box>
      <Box
        display="flex"
        position="fixed"
        bottom={0}
        right={0}
        bgcolor="white"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        className={classes.fixedBar}
      >
        <Box p={1}>
          <IconButton disabled={offset <= 0} onClick={previousPage}>
            <ArrowLeft></ArrowLeft>
          </IconButton>
        </Box>
        <Box p={1} px={2}>
          <Typography>{`${offset * ITEMS_PER_PAGE + 1} - ${Math.min(
            (offset + 1) * ITEMS_PER_PAGE,
            len
          )} / ${len}`}</Typography>
        </Box>
        <Box p={1}>
          <IconButton
            disabled={(offset + 1) * ITEMS_PER_PAGE > len}
            onClick={nextPage}
          >
            <ArrowRight></ArrowRight>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );

  function nextPage() {
    if (offset + 1 * ITEMS_PER_PAGE > len) {
      return;
    }

    setOffset(offset + 1);
  }

  function previousPage() {
    if (offset <= 0) {
      return;
    }

    setOffset(offset - 1);
  }

  function renderListItem(d: DesignProfileWithId): React.ReactNode {
    return (
      <DesignProfileListItem key={`dp-${d.id}`} record={d} onClick={() => {}} />
    );
  }
};

DesignProfileList = memo(
  DesignProfileList,
  (a, b) => a.DesignProfiles === b.DesignProfiles
);

export { DesignProfileList };
export default DesignProfileList;
