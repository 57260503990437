import { YardDifficultyRating } from "@yardzen-inc/models";
import {
  AVERAGE_YARD_DIFFICULTY_BASE_COST_MULTIPLIER,
  EASY_YARD_DIFFICULTY_BASE_COST_MULTIPLIER,
  HARD_YARD_DIFFICULTY_BASE_COST_MULTIPLIER,
} from "../../../../ConstantValues/YardDifficultyMultipliers";
import { roundPrice } from "./roundPrice";

export const calculateBaseCostRange = (
  totalBudget: number,
  yardDifficultyRating: YardDifficultyRating
): [number, number] => {
  let baseCost = 0;

  if (yardDifficultyRating === 1 || yardDifficultyRating === null) {
    baseCost = totalBudget * EASY_YARD_DIFFICULTY_BASE_COST_MULTIPLIER;
  }

  if (yardDifficultyRating === 2) {
    baseCost = totalBudget * AVERAGE_YARD_DIFFICULTY_BASE_COST_MULTIPLIER;
  }

  if (yardDifficultyRating === 3) {
    baseCost = totalBudget * HARD_YARD_DIFFICULTY_BASE_COST_MULTIPLIER;
  }

  const roundedBaseCost = roundPrice(baseCost, 500);

  return [
    roundPrice(roundedBaseCost * 0.9, 500),
    roundPrice(roundedBaseCost * 1.1, 500),
  ];
};
