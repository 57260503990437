import moment from "moment";
import { YardPackages } from "../../../Interfaces";
// This function determines whether or not a project is an
// old botanical package or not.

const launchDate = moment("2020-06-03T00:10:00.000Z").unix();

export default function isOldBotanical(
  yzPackage: YardPackages,
  createdAt: string | undefined | firebase.firestore.Timestamp
): boolean {
  if (yzPackage !== "botanical") {
    return false;
  }

  if (moment(createdAt).unix() > launchDate) {
    return false;
  }

  return true;
}
