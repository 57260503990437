import React, { FC } from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import MyYardPage from "./MyYardPage";
import {
  GenericRouterWithTabHeader,
  PageMapEntryWithHumanName,
} from "../../Components/GenericRouter";

export interface OnboardRouterProps extends RouteComponentProps {}

const pageMap: PageMapEntryWithHumanName[] = [
  {
    component: MyYardPage,
    key: "my-yard-page-key",
    routeName: "my-yard",
    humanName: "My Yard",
  },
];

const OnboardRouter: FC<OnboardRouterProps> = (props) => {
  return (
    <>
      <GenericRouterWithTabHeader
        {...props}
        pageMap={pageMap}
        CatchAllRouteComponent={() => (
          <Redirect to={`${props.match.url}/my-yard`} />
        )}
      ></GenericRouterWithTabHeader>
    </>
  );
};

export { OnboardRouter };
export default OnboardRouter;
