import React, { FC } from "react";

export interface AppErrorBoundaryProps {
  onError: (error: unknown) => void;
  ErrorPageComponent: FC<{ error: unknown }>;
}

export interface AppErrorBoundaryState {
  error: unknown;
}

/**
 * AppErrorBoundary is imported in src/index.tsx and used as a global catch-all
 * error boundary.
 */
export class AppErrorBoundary extends React.Component<
  AppErrorBoundaryProps,
  AppErrorBoundaryState
> {
  state = {
    error: null,
  };

  componentDidCatch(error: unknown) {
    this.props.onError(error);
  }

  static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  render() {
    return this.state.error ? (
      <this.props.ErrorPageComponent
        error={this.state.error}
      ></this.props.ErrorPageComponent>
    ) : (
      this.props.children
    );
  }
}
