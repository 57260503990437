import * as React from "react";

export default function withRatio(opts: {
  dimension: "width" | "height";
  ref: React.RefObject<any>;
  ratio: [number, number];
  delay?: number;
}) {
  const [returnVal, setReturnVal] = React.useState<ClientRect | null>(null);
  const [val, setVal] = React.useState<ClientRect | null>(null);

  React.useEffect(() => {
    const onResize = _onResize();

    let timeout: any = null;

    if (opts.ref.current) {
      window.addEventListener("resize", () => {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(onResize, 200);
      });
      onResize();
    }

    return () => {
      window.removeEventListener("resize", onResize);

      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [opts.ref.current]);

  React.useEffect(() => {
    let timeout: any;

    do {
      if (!opts.ref || !opts.ref.current || !returnVal) {
        break;
      }

      const cur = opts.ref.current as HTMLDivElement;

      if (
        cur.clientWidth > returnVal.width - 1 &&
        cur.clientWidth < returnVal.width + 1
      ) {
        break;
      }

      timeout = setTimeout(_onResize(), 200);
    } while (false);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  });

  React.useEffect(() => {
    let timeout: any = null;

    if (returnVal === null) {
      setReturnVal(val);
    } else {
      timeout = setTimeout(() => {
        setReturnVal(val);
      }, opts.delay || 50);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [val]);

  function _onResize() {
    return () => {
      if (!opts.ref.current) {
        return;
      }

      const rect = (opts.ref.current as HTMLDivElement).getBoundingClientRect();

      const result = (
        opts.dimension === "height"
          ? () => ({
              height: rect.width * (opts.ratio[1] / opts.ratio[0]),
              width: rect.width,
              top: rect.top,
              bottom: rect.bottom,
              right: rect.right,
              left: rect.left,
            })
          : () => ({
              height: rect.height,
              top: rect.top,
              bottom: rect.bottom,
              right: rect.right,
              left: rect.left,
              width: rect.height * (opts.ratio[0] / opts.ratio[1]),
            })
      )();
      // @ts-ignore
      setVal(result);
    };
  }

  return returnVal;
}
