import * as React from "react";
import {
  Card,
  Grid,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Checkbox,
  FormControlLabel,
  Switch,
  TextField,
  Button,
  Badge,
  Link,
  Box,
} from "@material-ui/core";
import VideoCamera from "@material-ui/icons/Videocam";
import { Media } from "@yardzen-inc/models";
import LaunchIcon from "@material-ui/icons/Launch";
import { Refresh, Warning } from "@material-ui/icons";
import moment from "moment";

const visibiltyOptions = [
  {
    name: "Client",
    property: "isClientVisible",
  },
  {
    name: "Designer",
    property: "isDesignerVisible",
  },
];

export interface State {
  media: Array<Media>;
}

export interface Props {
  media: Array<Media>;
  noDownload?: boolean;
  noVisibiliyControlls?: boolean;
  onViewClick: Function;
  onItemSelectChange: Function;
  permissions: object;
  selectedMedia?: any;
  submitFileName: CallableFunction;
  handleFileNameChange: CallableFunction;
  fileNameHolding: any;
  oneColumn?: boolean;
  noCheck?: boolean;
  selectForClientFeedback?: boolean;
}

export default class MediaGrid extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      media: props.media,
    };

    this.onVisibilityChange = this.onVisibilityChange.bind(this);
  }

  checkAndHandleInvalidThumbnail = (record: Media) => {
    // Sometimes a media's thumbnail URL points to a nonexistent file.
    // In this case, we want to use the image's URL as the thumbnail URL.
    const img = new Image();
    img.onerror = () => {
      if (record.thumbnailURL === record.downloadURL) return;

      record.thumbnailURL = record.downloadURL;
      const index = this.state.media.indexOf(record);

      this.setState((state: State) => ({
        media: [
          ...state.media.slice(0, index),
          record,
          ...state.media.slice(index + 1),
        ],
      }));
    };

    img.src = record.thumbnailURL;
  };

  public render() {
    const props = this.props;

    const oneColumnStyles: React.CSSProperties = props.oneColumn
      ? {
          maxWidth: "100%",
        }
      : { maxWidth: "100%" };

    return (
      <div>
        <Grid justify="center" container>
          {this.state.media.map((record: Media, index: number) => {
            // @ts-ignore
            const downloadURL = record.downloadURL || record["signedURL"];
            this.checkAndHandleInvalidThumbnail(record);

            return (
              <Grid
                style={{
                  margin: "1rem",
                  width: "370px",
                  maxWidth: "100%",
                  height: "100%",
                }}
                item
                key={`${record.id}-grid-item`}
              >
                <Badge
                  style={oneColumnStyles}
                  invisible={
                    !Object.keys(this.props.selectedMedia).includes(
                      record["id"]
                    ) || !this.props.selectForClientFeedback
                  }
                  badgeContent={
                    Object.keys(this.props.selectedMedia).indexOf(
                      record["id"]
                    ) + 1
                  }
                >
                  <Card style={oneColumnStyles} key={`${record.id}-card`}>
                    {record.fileType === "application/pdf" ? (
                      this.renderPDFCard(downloadURL)
                    ) : (
                      <CardMedia
                        onClick={(e) => {
                          props.onViewClick(index);
                        }}
                        key={`${record.id}-card-media`}
                        style={{
                          maxWidth: "100%",
                          // width: "370px",
                          height: "auto",
                          paddingTop: "80%",
                          cursor: "pointer",
                          backgroundSize: "contain",
                        }}
                        image={this.getImage(record)}
                        title={record.originalFileName}
                      />
                    )}

                    <CardContent
                      className="card-content"
                      key={`${record.id}-card-content`}
                    >
                      <Box display="flex" flexDirection="column">
                        {(record as any)?.jobId && (
                          <Box p={1} display="flex" flexDirection="row">
                            <Refresh />
                            <Typography style={{ paddingLeft: "8px" }}>
                              conversion started
                            </Typography>
                          </Box>
                        )}
                        {(record as any)?.retryCount && (
                          <Box p={1} display="flex" flexDirection="row">
                            <Warning />
                            <Typography style={{ paddingLeft: "8px" }}>
                              retried conversion {(record as any)?.retryCount}{" "}
                              times
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      <Typography gutterBottom>
                        <div>
                          <FormControlLabel
                            label={this.renderLabel(record)}
                            disabled={props.noCheck}
                            control={
                              <Checkbox
                                disabled={props.noCheck}
                                checked={Object.keys(
                                  this.props.selectedMedia
                                ).includes(record["id"])}
                                onChange={(e) => {
                                  props.onItemSelectChange(
                                    record,
                                    e.target.checked
                                  );
                                }}
                              />
                            }
                          />
                          {record.fileType === "application/pdf" ? (
                            <a
                              rel="noopener noreferrer"
                              style={{ margin: "auto" }}
                              target="_blank"
                              href={downloadURL}
                            >
                              <LaunchIcon style={{ color: "gray" }} />
                            </a>
                          ) : null}
                        </div>
                        {!!downloadURL && (
                          <div>
                            <Link href={downloadURL}>Download File</Link>
                          </div>
                        )}
                        <div>
                          {!!record.originalDownloadURL && (
                            <Link href={record.originalDownloadURL}>
                              Download Original File
                            </Link>
                          )}
                        </div>

                        {
                          // @ts-ignore
                          !!record["uploadedAt"] && (
                            <div>
                              <Typography>
                                Uploaded on{" "}
                                {
                                  // @ts-ignore
                                  moment(record["uploadedAt"]).format(
                                    "MM-DD-YYYY"
                                  )
                                }{" "}
                                at{" "}
                                {
                                  // @ts-ignore
                                  moment(record["uploadedAt"]).format(
                                    "HH:mm:ss a"
                                  )
                                }
                              </Typography>
                            </div>
                          )
                        }
                      </Typography>
                    </CardContent>
                    {!props.noVisibiliyControlls && (
                      <CardActions key={`${record.id}-card-actions`}>
                        <Grid
                          container
                          direction="column"
                          key={`${record.id}-grid-container-1`}
                        >
                          <Grid item key={`${record.id}-grid-item-4`}>
                            {
                              // @ts-ignore
                              this.props.permissions["media"]["access"] ===
                              "update" ? (
                                visibiltyOptions.map((option) => (
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        key={`${record.id}-switch-${option}`}
                                        checked={
                                          record.visibility &&
                                          // @ts-ignore
                                          record.visibility[option.property]
                                        }
                                        onChange={(e) => {
                                          this.onVisibilityChange(
                                            record.id,
                                            option.property,
                                            e.target.checked
                                          );
                                        }}
                                        color="primary"
                                      />
                                    }
                                    label={`${option.name} visible`}
                                  />
                                ))
                              ) : (
                                <div></div>
                              )
                            }
                          </Grid>
                        </Grid>
                      </CardActions>
                    )}
                  </Card>
                </Badge>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }

  protected renderLabel(mediaRecord: any) {
    const selectedMediaIDs = Object.keys(this.props.selectedMedia);
    if (this.props.noVisibiliyControlls) {
      if (mediaRecord["updatedFileName"]) {
        return mediaRecord["updatedFileName"];
      }
      return mediaRecord["originalFileName"];
    }
    if (
      selectedMediaIDs.includes(mediaRecord["id"]) &&
      !mediaRecord["updatedFileName"]
    ) {
      return (
        <div>
          <TextField
            fullWidth
            placeholder={mediaRecord["originalFileName"]}
            value={this.props.fileNameHolding[mediaRecord.id]}
            onChange={(e) =>
              this.props.handleFileNameChange(mediaRecord, e.target.value)
            }
          />
          <Button onClick={() => this.props.submitFileName(mediaRecord)}>
            Set
          </Button>
        </div>
      );
    } else if (
      selectedMediaIDs.includes(mediaRecord["id"]) &&
      mediaRecord["updatedFileName"]
    ) {
      return (
        <div style={{ display: "flex" }}>
          <TextField
            fullWidth
            placeholder={mediaRecord["updatedFileName"]}
            value={this.props.fileNameHolding[mediaRecord.id]}
            onChange={(e) =>
              this.props.handleFileNameChange(mediaRecord, e.target.value)
            }
          />
          <Button onClick={() => this.props.submitFileName(mediaRecord)}>
            Set
          </Button>
        </div>
      );
    } else if (mediaRecord["updatedFileName"]) {
      return mediaRecord["updatedFileName"];
    }
    return mediaRecord["originalFileName"];
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ media: nextProps.media });
  }

  protected renderPDFCard(downloadURL: string) {
    return (
      <div>
        <iframe style={{ width: "100%", height: "296px " }} src={downloadURL} />
      </div>
    );
  }

  protected getImage(record: Media) {
    if (record.fileType.indexOf("video") !== -1) {
      return VideoCamera;
    }

    const imageURL = !!record.thumbnailURL
      ? record.thumbnailURL
      : record.downloadURL;
    // @ts-ignore
    return imageURL || record["signedURL"];
  }

  protected onVisibilityChange(
    documentId: string,
    property: string,
    enabled: boolean
  ) {
    let mediaDocs = this.state.media;

    Object.keys(mediaDocs).forEach((key) => {
      if (mediaDocs[key].id === documentId) {
        let visibility = mediaDocs[key].visibility;
        visibility[property] = enabled;
        mediaDocs[key].setVisibility(visibility);

        this.setState({ media: mediaDocs });
      }
    });
  }
}
