import { useState, useEffect, useMemo } from "react";
import { DesignProfileState } from "./types";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
export interface DesignProfileWithId extends DesignProfileState {
  id: string;
}

export interface DesignProfileFilterFunction {
  (docs: DesignProfileWithId[]): DesignProfileWithId[];
}

export interface UseDesignProfiles {
  (numberOfRecords: number, filterFunction?: DesignProfileFilterFunction):
    | DesignProfileWithId[]
    | null;
}

const useDesignProfiles: UseDesignProfiles = (
  numberOfRecords,
  filterFunction
) => {
  const [designProfiles, setDesignProfiles] = useState<
    DesignProfileWithId[] | null
  >(null);

  const [_numberOfRecords, _setNumberOfRecords] =
    useState<number>(numberOfRecords);

  const filtered = useMemo(() => {
    if (!filterFunction || !designProfiles) {
      return designProfiles;
    }

    return [...filterFunction(designProfiles)];
  }, [designProfiles, filterFunction]);

  useEffect(listenOnDesignProfile, [_numberOfRecords]);
  useEffect(onNumberOfRecordsChange, [numberOfRecords]);

  return filtered;

  function listenOnDesignProfile(): void | (() => void) {
    if (!_numberOfRecords) {
      return setDesignProfiles([]);
    }

    return firebase
      .firestore()
      .collection("designProfiles")
      .where("isTestAccount", "==", false)
      .orderBy("createdAt", "desc")
      .limit(_numberOfRecords)
      .onSnapshot((snap) => {
        setDesignProfiles(
          snap.docs.map((d) => {
            return { ...d.data(), id: d.id } as DesignProfileWithId;
          })
        );
      });
  }

  function onNumberOfRecordsChange(): () => void {
    const timeout = setTimeout(() => {
      _setNumberOfRecords(numberOfRecords);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }
};

export { useDesignProfiles };
export default useDesignProfiles;
