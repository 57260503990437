import React, { FC, FormEvent, useState, ReactNode, ChangeEvent } from "react";
import {
  Box,
  Input,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";
import { Close } from "@material-ui/icons";

export interface DesignBriefEntryFormArrayValueProps extends BoxProps {
  value: {
    value?: string;
    options: string[];
  };
  onChange: (value: any) => any;
  onOptionsChange: (value: string[]) => any;
}

const DesignBriefEntryFormArrayValue: FC<DesignBriefEntryFormArrayValueProps> =
  ({ value, onChange, onOptionsChange, ...containerProps }) => {
    const [inputValue, setInputValue] = useState<string>("");

    return (
      <Box {...containerProps}>
        <Box
          display="flex"
          flexDirection="row"
          component="form"
          onSubmit={handleSubmit}
        >
          <Box pr={1} flexGrow={1}>
            <Input
              required
              fullWidth
              value={inputValue}
              onChange={({ currentTarget: { value: val } }) =>
                setInputValue(val)
              }
            />
          </Box>
          <Button variant="text" type="submit">
            Add Option
          </Button>
        </Box>
        <Box p={1}>
          {value.value && (
            <Box bgcolor="rgb(229, 229, 229, 190)" p={1}>
              <RadioGroup value={value.value} onChange={onRadioGroupChange}>
                {renderOptions()}
              </RadioGroup>
            </Box>
          )}
        </Box>
      </Box>
    );

    function onRadioGroupChange({
      target: { value: val },
    }: ChangeEvent<HTMLInputElement>) {
      onChange(val);
    }

    function renderOptions(): ReactNode[] {
      return value.options.map((val) => {
        return (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <FormControlLabel
              label={val}
              key={`designbriefentryformarrayvalue_${val}`}
              value={val}
              control={<Radio />}
            />
            <Box>
              <IconButton size="small" onClick={removeValue}>
                <Close></Close>
              </IconButton>
            </Box>
          </Box>
        );

        function removeValue(): void {
          const { options } = value;

          const oIndex = options.findIndex((o) => o === val);

          if (oIndex < 0) {
            throw new Error(`option ${val} does not exist`);
          }

          options.splice(oIndex, 1);

          onOptionsChange([...options]);

          if (!options.includes(value.value ?? "")) {
            if (options.length) {
              onChange(options[0]);
            } else {
              onChange("");
            }
          }
        }
      });
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
      event.preventDefault();

      if (!inputValue || value.options.includes(inputValue)) {
        return;
      }

      setInputValue("");
      onOptionsChange([...value.options, inputValue]);

      if (!value.value) {
        onChange(inputValue);
      }

      setInputValue("");
    }
  };

export { DesignBriefEntryFormArrayValue };
export default DesignBriefEntryFormArrayValue;
