import { Box, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    overflow: "hidden",
  },
}));

interface Props {
  children: React.ReactNode;
}
function SelectionTableBox(props: Props) {
  const classes = useStyles();

  return (
    <Box className={classes.root} mt={8}>
      {props.children}
    </Box>
  );
}

export default SelectionTableBox;
