import * as React from "react";
import { TextField, Button, Box, Typography } from "@material-ui/core";
import firebase from "firebase/compat/app";
import GenericSnackBar from "../../Components/GenericSnackBar";

interface IAddNewZipCodeProps {}

const AddNewZipCode: React.FunctionComponent<IAddNewZipCodeProps> = (props) => {
  const [zip, setZip] = React.useState<string>("");
  const [state, setState] = React.useState<string>("");
  const [city, setCity] = React.useState<string>("");
  const [snackBarMessage, setSnackBarMessage] = React.useState<string | null>(
    null
  );
  const [snackBarVariant, setSnackBarVariant] = React.useState<
    "success" | "warning" | "error" | "info" | null
  >(null);

  return (
    <Box>
      <Typography style={{ maxWidth: "25%" }}>
        This tool is meant as a last-ditch way to insert a zip record that
        otherwise couldn't be found. This tool does not currently support an
        "undo" function. Please be very deliberate with your entries and use
        this sparingly. If you have inserted an incorrect record, please contact
        eng ASAP.
      </Typography>
      <Box display="flex" flexDirection="column" width="max-content">
        <GenericSnackBar
          onClose={() => {
            setSnackBarMessage(null);
            setSnackBarVariant(null);
          }}
          message={snackBarMessage || ""}
          in={!!snackBarMessage && !!snackBarVariant}
          variant={snackBarVariant || "info"}
        />
        <TextField
          value={zip}
          onChange={(e) => onChange("zip", e.target.value.trim())}
          placeholder="zip"
          inputProps={{
            maxlength: 5,
          }}
        />
        <TextField
          value={state}
          onChange={(e) => onChange("state", e.target.value.trim())}
          placeholder="state eg 'CA'"
          inputProps={{
            maxlength: 2,
          }}
        />
        <TextField
          value={city}
          onChange={(e) => onChange("city", e.target.value.trim())}
          placeholder="city"
        />

        <Button onClick={submit}>Add Zip</Button>
      </Box>
    </Box>
  );

  function onChange(key: string, value: string) {
    switch (key) {
      case "zip":
        if (isFinite(Number(value))) {
          return setZip(value);
        } else {
          break;
        }
      case "state":
        return setState(value);
      case "city":
        return setCity(value);
      default:
        break;
    }
  }

  async function submit() {
    const createZipCodeEntry = firebase
      .functions()
      .httpsCallable("createManagingAssociates");

    try {
      await createZipCodeEntry({
        stateName: state,
        cityName: city,
        zipCode: zip,
        assignedAccountManagerIds: [],
        assignedContractorIds: [],
      });

      setSnackBarMessage(`Successfully added zip ${zip}`);
      setSnackBarVariant("success");
      clearState();
    } catch (error) {
      console.error("Error adding zip: ", error);

      setSnackBarMessage(error.message);
      setSnackBarVariant("error");
    }
  }

  function clearState() {
    setState("");
    setZip("");
    setCity("");
  }
};

export default AddNewZipCode;
