import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import AssetFilterBoxOption from "./AssetFilterBoxOption";
import {
  AssetLibraryFilterState,
  IAssetLibraryActiveFilter,
  IAssetLibraryFilterBoxOnSelect,
  IFilterBoxOption,
} from "../../../../Interfaces";
import uuid from "uuid";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import { HasuraQueryHumanReadableOperatorEnum } from "@yardzen-inc/util";

const useStyles = makeStyles(() => ({
  root: {
    padding: "0 1rem 1rem",
    minWidth: 700,
  },
}));

interface Props {
  options: IFilterBoxOption[];
  eqFilterState: any;
  neqFilterState: any;
  hasAnyOfFilterState: any;
  hasAllOfFilters: any[];
  onFilterBoxSelectHasAllOf: IAssetLibraryFilterBoxOnSelect;
  onFilterBoxSelectNeq: IAssetLibraryFilterBoxOnSelect;
  onFilterBoxSelectHasAnyOf: IAssetLibraryFilterBoxOnSelect;
  onFilterBoxSelectNone: (id: string) => void;
}

const EmptyActiveFilter = (): IAssetLibraryActiveFilter => {
  return {
    filterBy: "",
    filterParameter: HasuraQueryHumanReadableOperatorEnum.hasAllOf,
    value: [],
    id: uuid.v4(),
  };
};

function AssetLibraryFilterBox(props: Props) {
  const classes = useStyles();

  const [activeFilters, setActiveFilters] = React.useState<
    IAssetLibraryActiveFilter[]
  >([EmptyActiveFilter()]);

  const getPresetFiltersByType = (
    type: any[],
    parameter: HasuraQueryHumanReadableOperatorEnum
  ): any[] => {
    const result: any[] = [];
    const hasAllOfFilterMap = new Map();
    for (let each of type) {
      if (hasAllOfFilterMap.has(each.filterId)) {
        hasAllOfFilterMap.set(
          each.filterId,
          hasAllOfFilterMap.get(each.filterId).add(each)
        );
      } else {
        hasAllOfFilterMap.set(each.filterId, new Set().add(each));
      }
    }
    for (let key of Array.from(hasAllOfFilterMap.keys())) {
      result.push({
        // @ts-ignore
        filterBy: Array.from(hasAllOfFilterMap.get(key))[0].__typename,
        filterParameter: parameter,
        value: Array.from(hasAllOfFilterMap.get(key)),
        id: key,
      });
    }
    return result;
  };

  const handleEq = () => {
    const presetFilters: IAssetLibraryActiveFilter[] = [];
    presetFilters.push(
      ...getPresetFiltersByType(
        props.hasAllOfFilters,
        HasuraQueryHumanReadableOperatorEnum.hasAllOf
      ),
      ...getPresetFiltersByType(
        props.hasAnyOfFilterState,
        HasuraQueryHumanReadableOperatorEnum.hasAnyOf
      ),
      ...getPresetFiltersByType(
        props.neqFilterState,
        HasuraQueryHumanReadableOperatorEnum.hasNoneOf
      )
    );
    if (presetFilters.length) {
      setActiveFilters([...presetFilters, ...activeFilters]);
    }
  };

  React.useEffect(() => {
    handleEq();
  }, []);

  const onAddAnother = () => {
    setActiveFilters([...activeFilters, EmptyActiveFilter()]);
  };

  const getSelectedValues = (
    values: Partial<AssetLibraryFilterState>[],
    parameter: string,
    id: string
  ): Partial<AssetLibraryFilterState>[] => {
    return values.map((value) => {
      return {
        ...value,
        filterId: id,
      };
    });
  };

  // We are deferring maintaining state of all the active filters to the top level, ie. PlantLibrary/index.tsx.
  // This is done to avoid redundancy and minimize openings for bugs to appear.
  const onTriggerFilterChange = (
    filterBy: string,
    filterParameter: HasuraQueryHumanReadableOperatorEnum,
    value: AssetLibraryFilterState[],
    id: string
  ) => {
    const selectedValues = getSelectedValues(value, filterParameter, id);
    setActiveFilters(
      activeFilters.map((activeFilter) => {
        if (activeFilter.id !== id) {
          return activeFilter;
        }
        activeFilter.filterBy = filterBy;
        activeFilter.filterParameter = filterParameter;
        activeFilter.value = value;
        return activeFilter;
      })
    );
    switch (filterParameter) {
      case HasuraQueryHumanReadableOperatorEnum.hasNoneOf:
        return props.onFilterBoxSelectNeq(selectedValues, id);
      case HasuraQueryHumanReadableOperatorEnum.hasAllOf:
        return props.onFilterBoxSelectHasAllOf(selectedValues, id);
      case HasuraQueryHumanReadableOperatorEnum.hasAnyOf:
        return props.onFilterBoxSelectHasAnyOf(selectedValues, id);
      default:
        return props.onFilterBoxSelectNone(id);
    }
  };

  const optionsMap = () => {
    const result = {};
    props.options.forEach((option: IFilterBoxOption) => {
      // @ts-ignore
      result[option.listResultName] = option.query;
    });
    return result;
  };

  const onClose = (id: string, filterBy: string) => {
    const newFilters = activeFilters.filter(
      (_activeFilter: IAssetLibraryActiveFilter) => _activeFilter.id !== id
    );

    if (!newFilters.length) {
      newFilters.push(EmptyActiveFilter());
    }
    setActiveFilters(newFilters);
    props.onFilterBoxSelectNone(id);
  };

  const renderAddFilterButton = props.options.length > activeFilters.length;
  return (
    <div className={classes.root}>
      {activeFilters.map((activeFilter) => {
        return (
          <AssetFilterBoxOption
            key={activeFilter.id}
            optionQueries={optionsMap()}
            activeFilter={activeFilter}
            onClose={(filterBy: string) => onClose(activeFilter.id, filterBy)}
            onTriggerFilterChange={(a, b, c) =>
              onTriggerFilterChange(a, b, c, activeFilter.id)
            }
            options={props.options.sort((a, b) => {
              if (a.label < b.label) return -1;
              if (a.label > b.label) return 1;
              return 0;
            })}
          />
        );
      })}
      {renderAddFilterButton ? (
        <Box mt={1}>
          <YZButton size="small" onClick={onAddAnother}>
            <YZTypography variant="body2" type="uppercase">
              + Add Filter
            </YZTypography>
          </YZButton>
        </Box>
      ) : null}
    </div>
  );
}

export default AssetLibraryFilterBox;
