import React, { FC, useState, useRef, useEffect } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import {
  Typography,
  Divider,
  LinearProgress,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";
import { Media } from "@yardzen-inc/models";
import downloadAndZip from "../util/downloadAndZip";
import { YZButton } from "@yardzen-inc/react-common";
import MediaCard from "../Components/MediaCard";
import FullScreenImage from "../Components/FullScreenImage";
import { Pages } from "@material-ui/icons";
import FileUpload from "../Components/FileUpload";
import { listenOnMedia } from "../util/firebase/functions/listenOnMediaSnapshot";

interface MediaUploaderProps extends BoxProps {
  tag: string;
  variant?: string;
  userId: string;
  onUploadComplete: () => any;
  accept?: string[];
}
export interface MediaUploaderWithTitleProps extends BoxProps {
  uploaderProps: MediaUploaderProps;
  title: string;
  typographyProps?: TypographyProps;
  onDelete?: () => void;
}
const useStyles = makeStyles((theme) => ({
  openFullScreenButtonContainer: {
    padding: "0.5rem",
    display: "flex",
    flexFlow: "row-reverse nowrap",
    alignItems: "center",
    flexGrow: 1,
    height: "100%",
    width: "100%",
    minHeight: "fit-content",
  },
  parentMediaContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "2rem",
    justifyContent: "center",
    alignContent: "center",
  },
  childMediaContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexFlow: "row wrap",
  },
}));

const MediaUploaderWithTitleAndPreview: FC<MediaUploaderWithTitleProps> = ({
  uploaderProps,
  typographyProps,
  title,
  onDelete,
  ...containerProps
}) => {
  const classes = useStyles();
  const [mediaName, setMediaName] = useState<string | false | null>(null);
  const mediaSnapRef = useRef<Media | Media[] | null>(null);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const { tag, variant, userId, onUploadComplete, accept } = uploaderProps;

  const mostRecentMedia = Array.isArray(mediaSnapRef.current)
    ? mediaSnapRef.current.sort(
        (a: Media, b: Media) =>
          new Date(b.uploadedAt as string).getTime() -
          new Date(a.uploadedAt as string).getTime()
      )[0]
    : mediaSnapRef.current;

  useEffect(listenOnMedia(userId, tag, variant, mediaSnapRef, setMediaName), [
    tag,
    variant,
  ]);

  return (
    <Box {...containerProps}>
      <Typography variant="h5" {...typographyProps}>
        {title}
      </Typography>
      <Divider />
      {isFullScreen && (
        <FullScreenImage
          open
          media={mostRecentMedia as Media}
          onClose={() => setIsFullScreen(false)}
        />
      )}
      {renderContent()}
    </Box>
  );

  /* render switch for progressbar / uploader / uploaded content */
  function renderContent(): React.ReactNode {
    if (mediaName === null) {
      return <LinearProgress variant="indeterminate" />;
    }

    if (mediaName === false) {
      return (
        <Box {...containerProps} display="flex" flexDirection="column">
          <FileUpload
            fileTag={tag}
            variant={variant}
            clientUserId={userId}
            accept={accept}
            onUploadComplete={onUploadComplete}
            fileVisibility={{
              isClientVisible: true,
              isDesignerContributed: true,
              isDesignerVisible: true,
            }}
          />
        </Box>
      );
    }

    return renderMediaUploadedView();
  }

  function downloadMedia(): void {
    if (!mediaSnapRef.current) {
      throw new Error("cannot download because no media has been loaded in");
    }

    downloadAndZip(
      Array.isArray(mediaSnapRef.current)
        ? (mediaSnapRef.current as Media[])
        : [mediaSnapRef.current as Media],
      `${tag}${variant ? `~${variant}` : ""}`
    );
  }

  function renderMediaUploadedView(): React.ReactNode {
    return (
      <div className={classes.parentMediaContainer}>
        <MediaCard
          media={mostRecentMedia as Media}
          noTitle
          childrenToolBar={renderImagePreviewToolbarButtons()}
        />
        <div className={classes.childMediaContainer}>
          <Typography align="center" variant="body1">
            {mediaName}
          </Typography>
          <YZButton
            variant="contained"
            color="primary"
            style={{ margin: "1rem" }}
            onClick={downloadMedia}
          >
            Download
          </YZButton>
          <YZButton
            variant="contained"
            color="primary"
            style={{ margin: "1rem" }}
            onClick={deleteAndResetMedia}
          >
            Replace
          </YZButton>
        </div>
      </div>
    );

    /*
      Destroy uploaded media unless it contains annotations.
    */
    async function deleteAndResetMedia(): Promise<any> {
      if (!mediaSnapRef.current) {
        throw new Error("no media to delete");
      }

      const currentMediaSnapRefArray: Media[] = Array.isArray(
        mediaSnapRef.current
      )
        ? mediaSnapRef.current
        : [mediaSnapRef.current];

      const mediaPromises = currentMediaSnapRefArray.map(async (media) => {
        if (
          (await media.getDocRef().collection("threads").get()).empty === false
        ) {
          return (mediaSnapRef.current as Media).getDocRef().update({
            tag: "annotated-house-model",
          });
        } else {
          return media.delete();
        }
      });

      await Promise.all(mediaPromises);

      if (onDelete) {
        onDelete();
      }
    }
  }

  function renderImagePreviewToolbarButtons(): React.ReactNode {
    return (
      <Box width="100%" height="100%">
        <div className={classes.openFullScreenButtonContainer}>
          <IconButton
            onClick={() => setIsFullScreen(true)}
            title="Open Fullscreen"
          >
            <Pages style={{ color: "white" }} />
          </IconButton>
        </div>
      </Box>
    );
  }
};

export { MediaUploaderWithTitleAndPreview };
