import React from "react";
import {
  SelectedItemsElement,
  SelectedItemsMaterial,
  SelectedItemsPlant,
} from "../Components/NonPmAssetSelection/constants/SelectedItemsConstants";
import { debounce } from "lodash";
import { sortPlantsForPDF } from "../util/functions/sortPlantsForPDF";
import { useLazyQuery } from "@yardzen-inc/graphql";
import { QUERY_SELECTION_FOR_BUDGET_QA } from "../graphql/budgetQAQueries";

type SelectedItemsResult = {
  elements: SelectedItemsElement[];
  materials: SelectedItemsMaterial[];
  plants: SelectedItemsPlant[];
  softscapeItems: [];
};

export function useSelectedAssetsForBudgetQA(
  designAssignmentId: string,
  isProcessingData: boolean,
  refetch: boolean,
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>,
  getElements: (queryData: any) => SelectedItemsElement[],
  getPlants: (queryData: any) => SelectedItemsPlant[],
  getMaterials: (queryData: any) => SelectedItemsMaterial[]
) {
  const [results, setResults] = React.useState<SelectedItemsResult>({
    elements: [],
    materials: [],
    plants: [],
    softscapeItems: [],
  });

  const debounceDelay = 600;
  const debouncedUpdate = React.useCallback(
    debounce(
      () =>
        getSelection({
          variables: {
            id: designAssignmentId,
          },
        }),
      debounceDelay
    ),
    []
  );

  const debouncedRefetchSelection = () => {
    debouncedUpdate.cancel();
    debouncedUpdate();
  };

  const fetchSelectedItems = () => {
    getSelection({
      variables: {
        id: designAssignmentId,
      },
    });
  };

  const [getSelection, { data: queryDataSelection }] = useLazyQuery(
    QUERY_SELECTION_FOR_BUDGET_QA,
    {
      fetchPolicy: "network-only",
      onError: () => alert("Error fetching selections for Budget QA"),
      onCompleted: () => {
        setResults({
          elements: getElements(queryDataSelection),
          materials: getMaterials(queryDataSelection),
          plants: getPlants(queryDataSelection),
          softscapeItems:
            queryDataSelection?.assignment?.[0]
              ?.assignment_softscape_item_links,
        });
        setRefetch(false);
      },
    }
  );

  React.useEffect(() => {
    // The user may have moved quickly from changing
    // an item's quantity to coming to this page
    // so let's wait until processing is complete
    // before fetching data to not have out-of-sync
    // data.
    if (isProcessingData || refetch) return;
    fetchSelectedItems();
  }, [isProcessingData, designAssignmentId]);

  React.useEffect(() => {
    debouncedRefetchSelection();
  }, [refetch]);

  return {
    plants: sortPlantsForPDF(results.plants),
    materials: results.materials,
    elements: results.elements,
    softscapeItems: results.softscapeItems,
  };
}
