import React, { FC } from "react";
import {
  Dialog,
  makeStyles,
  Theme,
  Box,
  Typography,
  Divider,
  Switch,
} from "@material-ui/core";

import { ProjectTags } from "@yardzen-inc/models";
import { Close } from "@material-ui/icons";
import { ProjectFunctions } from "@yardzen-inc/util";
import { pangaeaV1API } from "../../api/pangaeaV1API";
import { UpdateProjectDto } from "../../api/pangaeaTypes";
import { addPLPHoldStatus } from "../../util/firebase/firebaseClient";
import { HoldStatus, Project } from "../../Interfaces";

export interface ManageProjectTagsModalProps {
  open: boolean;
  onClose: () => void;
  clientProject: Project;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(6),
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    top: 5,
    right: 5,
    cursor: "pointer",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
  },
  centerGridItem: {
    justifySelf: "center",
    alignSelf: "center",
  },
  savingMessage: {
    justifySelf: "center",
    alignSelf: "center",
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

const ManageProjectTagsModal: FC<ManageProjectTagsModalProps> = ({
  open,
  onClose,
  clientProject,
}) => {
  const classes = useStyles();
  const projectTags = clientProject?.tags;
  const [updateProject, { isError, error: updateError, isLoading }] =
    pangaeaV1API.useUpdateProjectMutation();

  const ADDITIONAL_REVISION_TAGS = [
    {
      tag: "PURCHASED-ADDITIONAL-REVISION",
      label: "Purchased Revision",
    },
    {
      tag: "COMPED-ADDITIONAL-REVISION",
      label: "Comped Revision",
    },
  ];

  if (!clientProject) {
    return <></>;
  }

  const handleProjectUpdate = async (body: Partial<UpdateProjectDto>) => {
    await updateProject({
      id: clientProject.id,
      ...body,
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box className={classes.root}>
        {isError && <Typography color="error">{updateError}</Typography>}
        <Close onClick={onClose} classes={{ root: classes.closeIcon }} />
        <Box className={classes.grid}>
          <Box>
            <Typography variant="h6">Tags:</Typography>
            <Divider />
          </Box>
          <Box>
            <Typography variant="h6">Enabled:</Typography>
            <Divider />
          </Box>
          <React.Fragment>
            <Box className={classes.centerGridItem}>
              <Typography>VIP</Typography>
            </Box>
            <Box className={classes.centerGridItem}>
              <Switch
                data-testid={`toggle-VIP`}
                color="primary"
                checked={ProjectFunctions.hasTag(clientProject, "VIP")}
                onChange={async () => {
                  const updatedTags = getNewTagsArr("VIP");
                  handleProjectUpdate({
                    tags: updatedTags,
                  } as UpdateProjectDto);
                  if (updatedTags.includes("VIP")) {
                    await addPLPHoldStatus(
                      HoldStatus.ManualHold,
                      clientProject.id
                    );
                  }
                }}
                name={"VIP"}
                inputProps={{ "aria-label": `VIP tag checkbox` }}
              />
            </Box>
          </React.Fragment>
          {ADDITIONAL_REVISION_TAGS.map(({ tag, label }) => (
            <React.Fragment key={tag}>
              <Box className={classes.centerGridItem}>
                <Typography>{label}</Typography>
              </Box>
              <Box className={classes.centerGridItem}>
                <Switch
                  data-testid={`toggle-${tag}`}
                  color="primary"
                  checked={ProjectFunctions.hasTag(clientProject, tag)}
                  onChange={() =>
                    handleProjectUpdate({
                      tags: getNewTagsArr(tag),
                    } as UpdateProjectDto)
                  }
                  name={`${tag}`}
                  inputProps={{ "aria-label": `${tag} tag checkbox` }}
                />
              </Box>
            </React.Fragment>
          ))}
          <Box className={classes.centerGridItem}>
            <Typography>Expedited</Typography>
          </Box>
          <Box className={classes.centerGridItem}>
            <Switch
              data-testid="toggle-is-expedited"
              color="primary"
              checked={!!clientProject.isExpedited}
              onChange={() =>
                handleProjectUpdate({ isExpedited: !clientProject.isExpedited })
              }
              name="Is Expedited"
              inputProps={{ "aria-label": "is-expedited flag checkbox" }}
            />
          </Box>
          <Box className={classes.centerGridItem}>
            <Typography>Pro Design</Typography>
          </Box>
          <Box className={classes.centerGridItem}>
            <Switch
              data-testid="toggle-is-pro-design"
              color="primary"
              checked={!!clientProject.isProDesign}
              onChange={() =>
                handleProjectUpdate({
                  isProDesign: !clientProject.isProDesign,
                  isExpedited: !clientProject.isProDesign, //enable and disable expedited when pro design is toggled
                })
              }
              name="Is Pro Design"
              inputProps={{ "aria-label": "is-pro-design flag checkbox" }}
            />
          </Box>

          <Box className={classes.centerGridItem}>
            <Typography>HOA Extra Revision</Typography>
          </Box>
          <Box className={classes.centerGridItem}>
            <Switch
              data-testid="toggle-is-hoa-extra-revision"
              color="primary"
              checked={!!clientProject.isHOA}
              onChange={() =>
                handleProjectUpdate({ isHOA: !clientProject.isHOA })
              }
              name="Is HOA"
              inputProps={{ "aria-label": "is-hoa flag checkbox" }}
            />
          </Box>
        </Box>
      </Box>
      {isLoading && (
        <Typography className={classes.savingMessage}>Saving...</Typography>
      )}
    </Dialog>
  );

  // Returns a brand new array with modification based on the tag that was toggled
  function getNewTagsArr(tag: ProjectTags): ProjectTags[] {
    // If project tags doesn't exist, create new array with newly added tag
    if (!projectTags || !Array.isArray(projectTags)) {
      return [tag];

      // If project tags doesn't include toggled tag, add it
    } else if (!projectTags.includes(tag as ProjectTags)) {
      return [...projectTags, tag];

      // If project tags includes toggled tag, remove it
    } else {
      const idx = projectTags.indexOf(tag as ProjectTags);
      return [...projectTags.slice(0, idx), ...projectTags.slice(idx + 1)];
    }
  }
};

export { ManageProjectTagsModal };
export default ManageProjectTagsModal;
