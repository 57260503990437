import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import { Email } from "../Components/EmailTemplatingSystem";
import { GenericOnCallFunctionAlert } from "./genericAlert";

export const sendGenericEmail = async (
  e: Email
): Promise<firebase.functions.HttpsCallableResult | undefined> => {
  try {
    const func = firebase.functions().httpsCallable("genericEmailCall");
    return func(e);
  } catch (error) {
    // @ts-ignore
    GenericOnCallFunctionAlert("genericEmailCall", error.message);
    return Promise.resolve(undefined);
  }
};

export default sendGenericEmail;
