import * as React from "react";
import { usePM } from "./usePM";
import { LinearProgress, Typography, Box, TextField } from "@material-ui/core";
import useProjectManagerAssigned from "./useProjectManagerAssigned";
import SearchResultRow from "../../ClientTable/SearchResultRow";
import { Profile } from "@yardzen-inc/models";
import { Redirect } from "react-router-dom";

export interface MyProjectsProps {}

const MyProjects: React.FC<MyProjectsProps> = (props) => {
  const pm = usePM();
  const projects = useProjectManagerAssigned((pm || null)?.id ?? null);
  const [redirect, setRedirect] = React.useState<string | null>(null);
  const [query, setQuery] = React.useState<string>("");

  const loading = !pm || !projects;

  return (
    <Box p={3}>
      {redirect && <Redirect to={redirect} />}
      <LinearProgress
        style={{
          width: "100%",
          transition: "opacity 200ms",
          opacity: loading ? 1 : 0,
        }}
      />
      <Box p={1} width="100%">
        <TextField
          fullWidth
          label="filter"
          value={query}
          onChange={({ currentTarget: { value } }) => setQuery(value)}
        ></TextField>
      </Box>
      {render()}
    </Box>
  );

  function render(): React.ReactNode | null {
    if (!pm) {
      if (pm === false) {
        return (
          <Typography variant="h3">You are not a project manager</Typography>
        );
      }

      return null;
    }

    if (!projects?.length) {
      return null;
    }

    return (
      <Box width="100%" p={1} component="table">
        <Box
          width="inherit"
          p={1}
          display="flex"
          flexDirection="column"
          component="tbody"
        >
          {fileter().map((p: Profile) => (
            <SearchResultRow
              key={`pm-result-${p.id}`}
              onClick={() => setRedirect(`/client/${p.id}`)}
              result={{ record: p, hitMap: [], matchedContacts: [] }}
            />
          ))}
        </Box>
      </Box>
    );
  }

  function fileter(): Profile[] {
    if (!projects) {
      return [];
    }

    if (!query) {
      return projects;
    }

    return projects.filter((p) => {
      const addy = `${p.street} ${p.city} ${p.state} ${p.zip}`.toLowerCase();
      const name = `${p.firstName} ${p.lastName}`.toLowerCase();
      const q = query.toLowerCase();

      return (
        (p.email && p.email.includes(q)) ||
        (name && name.toLowerCase().includes(q)) ||
        (addy && addy.toLowerCase().includes(q))
      );
    });
  }
};

export { MyProjects };
export default MyProjects;
