import React, { FC, Fragment, useState } from "react";

// Import MUI Components
import { makeStyles } from "@material-ui/core/styles";
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { YZButton } from "@yardzen-inc/react-common";
import { AgentPods } from "./types";
import { deleteDesignerPod } from "./apiCalls";

export interface DeletePodModalProps {
  open: boolean;
  podData: AgentPods;
  onClose: () => void;
  onDeleteSubmit: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  style: {},
  dialogContainer: {
    minWidth: 500,
  },
  progressContainer: {
    display: "flex",
  },
  innerButtonProgress: {
    position: "absolute",
    color: "#fff",
  },
  selectForm: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const DeletePodModal: FC<DeletePodModalProps> = ({
  open,
  podData,
  onClose,
  onDeleteSubmit,
}) => {
  const classes = useStyles();
  const [deletePodLoading, setDeletePodLoading] = useState<boolean>(false);

  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        classes={{ paper: classes.dialogContainer }}
      >
        <DialogTitle id="form-dialog-title">
          Are you sure you want to delete {podData.name}?
        </DialogTitle>
        <DialogActions>
          <YZButton
            inheritFont
            color="primary"
            onClick={handleSubmitButonClick}
            fullWidth
            disabled={deletePodLoading}
          >
            {deletePodLoading && (
              <CircularProgress
                size={30}
                className={classes.innerButtonProgress}
              />
            )}
            Delete
          </YZButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );

  async function handleSubmitButonClick() {
    setDeletePodLoading(true);
    await deleteDesignerPod(podData.id);
    setDeletePodLoading(false);
    onClose();
    onDeleteSubmit();
  }
};

export { DeletePodModal };
export default DeletePodModal;
