import React, { FC } from "react";

import { Box, Dialog, makeStyles, Theme } from "@material-ui/core";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import { Close } from "@material-ui/icons";
import { Assignment } from "@yardzen-inc/models";

export interface ReopenAssignmentModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (result: ReopenAssignmentModalResult) => void;
  assignment: Assignment | null;
}

export interface ReopenAssignmentModalResult {
  isPaidCorrection: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  closeIcon: {
    position: "absolute",
    top: 5,
    right: 5,
    cursor: "pointer",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: "1rem",
  },
}));

const ReopenAssignmentModal: FC<ReopenAssignmentModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();

  function handlePaidCorrection() {
    onSubmit({ isPaidCorrection: true });
  }

  function handleUnpaidCorrection() {
    onSubmit({ isPaidCorrection: false });
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box className={classes.root}>
        <Close onClick={onClose} classes={{ root: classes.closeIcon }} />
        <YZTypography align="center">
          Should this be a paid correction?
        </YZTypography>

        <Box pt={4}>
          <div className={classes.buttonContainer}>
            <YZButton
              className={classes.button}
              onClick={handleUnpaidCorrection}
            >
              {"Unpaid Correction"}
            </YZButton>

            <YZButton className={classes.button} onClick={handlePaidCorrection}>
              {"Paid Correction"}
            </YZButton>
          </div>
        </Box>
      </Box>
    </Dialog>
  );
};

export { ReopenAssignmentModal };
export default ReopenAssignmentModal;
