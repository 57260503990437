import { useEffect, useState } from "react";
import { useFuse } from "../EmployeeView/PromotionalCodeManager/util/useFuse";

/**
 * Handles _fuzzy search_ for a collection of items on specified keys
 *
 * **IT'S REALLY IMPORTANT THAT THE COLLECTION AND KEYS ARGUMENTS ARE MEMOIZED**
 * else the search index will be recreated too frequently which is a bad time
 * @param collection The collection to be searched
 * @param keys Keys of the input collection items to create the index
 * @param term The search term
 * @param debounce The debounce time in milliseconds - defaults to 350 milliseconds
 * @returns
 */

const stableReferenceEmptyArray: unknown[] = [];

const useFuzzySearch = <T>(args: {
  collection: T[];
  keys: (keyof T | string)[];
  term?: string;
  debounce?: number;
}) => {
  const collection = args.collection?.length
    ? args.collection
    : (stableReferenceEmptyArray as T[]);

  const fuse = useFuse(collection, args.keys);
  const search = () => {
    if (!args.term) {
      return [];
    }
    return fuse.search(args.term).map((result) => result.item);
  };

  const [results, setResults] = useState<T[]>(search);

  useEffect(() => {
    let timeout: any;
    timeout = setTimeout(() => {
      setResults(search());
    }, Math.max(args.debounce ?? 350, 0));

    return () => {
      clearTimeout(timeout);
    };
  }, [args.term, fuse]);

  if (!args.term) {
    return collection;
  }

  return results;
};

export { useFuzzySearch };
