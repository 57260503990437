import * as React from "react";
import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  makeStyles,
  Box,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Grid,
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Formik } from "formik";
import {
  ApolloQueryResult,
  DELETE_MATERIAL_BASE_MATERIAL_LINKS,
  DELETE_MATERIAL_BASE_STYLE_LINKS,
  DELETE_MATERIAL_BASE_TYPE_LINKS,
  INSERT_MATERIAL_BASE_MATERIAL_LINKS,
  INSERT_MATERIAL_BASE_STYLE_LINKS,
  INSERT_MATERIAL_BASE_TYPE_LINKS,
  MATERIAL_COLLECTION_NAME_SELECT,
  MATERIAL_MATERIAL_SELECT,
  MATERIAL_PRICE_TIER_SELECT,
  MATERIAL_SETTING_SELECT,
  MATERIAL_STYLE_SELECT,
  MATERIAL_TYPE_SELECT,
  MATERIAL_UNIT_SELECT,
  MATERIAL_VENDOR_SELECT,
  useMutation,
} from "@yardzen-inc/graphql";
import {
  AssetLibraryRowType,
  IFormikSurfaceErrorProps,
  IFormikSurfaceProps,
  IMaterialBaseMaterialMaterialLink,
  IMaterialBaseMaterialStyleLink,
  IMaterialBaseMaterialTypeLink,
  IMaterialBaseRegionLink,
} from "../../../Interfaces";
import InStockSelect from "../ElementLibrary/InStockSelect";
import AssetFeatureSelect from "../Shared/AssetFeatureSelect";
import useUnload from "../../../util/hooks/useUnload";
import SaveInProgressTextConstants from "../../../ConstantValues/SaveInProgressTextConstants";
import { handleMutationError } from "../elementMutationErrorUtil";
import VisibilitySelect from "../Shared/VisibilitySelect";
import getAssetLibraryRow from "../getRowUtil";
import AssetLibraryImageUpload from "../Shared/AssetLibraryImageUpload";
import { ModelFileUploadForm } from "../ModelFileUploadForm";
import {
  MATERIAL_RETAILER_SELECT,
  PRODUCT_REGION_SELECT,
  UPDATE_MATERIAL_BASE,
} from "../Shared/graphqlQueries";

const SaveProgressPoint = Math.ceil(100 / 4);

interface Props {
  isEven: boolean;
  refetch:
    | ((
        variables?: Partial<Record<string, any>> | undefined
      ) => Promise<ApolloQueryResult<any>>)
    | undefined;
  row: AssetLibraryRowType[][];
}

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  itemBtns: {
    display: "flex",
    width: "100%",
  },
  iconUri: {
    maxWidth: "7rem",
  },
  editItems: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },
  saveProgress: {
    display: "flex",
    alignItems: "center",
  },
  editItem: {
    flex: 1,
    marginBottom: theme.spacing(1),
  },
  longLink: {
    width: "10rem",
    maxWidth: "10rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  "3DFileContainer": {
    display: "flex",
    flexDirection: "row",
  },
  uploadContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    marginTop: "1rem",
  },
}));

function SurfacesTableRow(props: Props) {
  const [isArchiving, setIsArchiving] = React.useState(false);
  const [saveProgress, setSaveProgress] = React.useState(0);

  const row = getAssetLibraryRow(props.row);

  const [imagePath, setImagePath] = React.useState(row.icon_uri);

  const onImageUploaded = (path: string): void => {
    setImagePath(path);
  };

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue =
      saveProgress > 0
        ? SaveInProgressTextConstants.SAVE_IN_PROGRESS_WARNING
        : "";
  });

  const [deleteStyleLinks] = useMutation(DELETE_MATERIAL_BASE_STYLE_LINKS, {
    onError: handleMutationError,
    onCompleted: () => {
      setSaveProgress(() => saveProgress + SaveProgressPoint); // 1
      insertStyleLinks({
        variables: {
          objects: cachedStyles.map((style) => {
            return {
              id_material_base: row.id,
              id_material_style: style,
            };
          }),
        },
      });
    },
  });

  const [insertStyleLinks] = useMutation(INSERT_MATERIAL_BASE_STYLE_LINKS, {
    onError: handleMutationError,
    onCompleted: () => {
      setSaveProgress(() => saveProgress + SaveProgressPoint); // 2
    },
  });

  const [deleteMaterialLinks] = useMutation(
    DELETE_MATERIAL_BASE_MATERIAL_LINKS,
    {
      onError: handleMutationError,
      onCompleted: () => {
        setSaveProgress(() => saveProgress + SaveProgressPoint); // 3
        insertMaterialLinks({
          variables: {
            objects: cachedMaterials.map((color) => {
              return {
                id_material_base: row.id,
                id_material_material: color,
              };
            }),
          },
        });
      },
    }
  );

  const [insertMaterialLinks] = useMutation(
    INSERT_MATERIAL_BASE_MATERIAL_LINKS,
    {
      onError: handleMutationError,
      onCompleted: () => {
        setSaveProgress(() => saveProgress + SaveProgressPoint); // 4
      },
    }
  );

  const [deleteTypeLinks] = useMutation(DELETE_MATERIAL_BASE_TYPE_LINKS, {
    onError: handleMutationError,
    onCompleted: () => {
      setSaveProgress(() => saveProgress + SaveProgressPoint); // 5
      insertTypeLinks({
        variables: {
          objects: cachedTypes.map((type) => {
            return {
              id_material_base: row.id,
              id_material_type: type,
            };
          }),
        },
      });
    },
  });

  const [insertTypeLinks] = useMutation(INSERT_MATERIAL_BASE_TYPE_LINKS, {
    onError: handleMutationError,
    onCompleted: () => {
      setSaveProgress(() => saveProgress + SaveProgressPoint); // 6
    },
  });

  const [updateRow] = useMutation(UPDATE_MATERIAL_BASE, {
    onError: handleMutationError,
    onCompleted: () => {
      deleteStyleLinks({
        variables: {
          id: row.id,
        },
      });
      deleteMaterialLinks({
        variables: {
          id: row.id,
        },
      });
      deleteTypeLinks({
        variables: {
          id: row.id,
        },
      });
    },
  });

  React.useEffect(() => {
    if (saveProgress < 100) {
      return;
    }
    setOpen(false);
    if (!props.refetch) {
      return;
    }
    props.refetch();
  }, [saveProgress]);

  const [archiveRow] = useMutation(UPDATE_MATERIAL_BASE, {
    onError: handleMutationError,
    onCompleted: () => {
      setOpen(false);
      if (!props.refetch) {
        return null;
      }
      props.refetch();
      return;
    },
  });

  const [open, setOpen] = React.useState(false);
  const [cachedStyles, setCachedStyles] = React.useState<string[]>([]);
  const [cachedMaterials, setCachedMaterials] = React.useState<string[]>([]);
  const [cachedTypes, setCachedTypes] = React.useState<string[]>([]);

  const classes = useRowStyles();

  const onUpdateRow = ({
    name,
    inStock,
    iconUri,
    unitCost,
    laborCost,
    vendor,
    retailer,
    unit,
    types,
    setting,
    materials,
    styles,
    link,
    lumion_file_name,
    lumion_file_link,
    sketchup_file_link,
    sketchup_proxy_file_link,
    vray_file_link,
    max_file_link,
    price_tier,
    collection_name,
    visibility,
    description,
  }: IFormikSurfaceProps) => {
    setCachedStyles(styles);
    setCachedMaterials(materials);
    setCachedTypes(types);
    const variables = {
      id: row.id,
      material_price_tier: price_tier,
      material_collection_name: collection_name,
      name,
      icon_uri: imagePath,
      unit,
      unit_cost: Math.round(parseFloat(unitCost) * 100),
      labor_cost: laborCost ? Math.round(parseFloat(laborCost) * 100) : 0,
      archived: row.archived,
      material_setting: setting,
      in_stock: inStock,
      link,
      lumion_file_name,
      lumion_file_link,
      sketchup_file_link,
      proxy_file_link: sketchup_proxy_file_link,
      max_file_link,
      vray_file_link,
      material_vendor: vendor,
      material_retailer: retailer,
      visibility,
      description,
    };
    updateRow({
      variables,
    });
  };

  const onArchiveToggle = () => {
    setIsArchiving(true);
    archiveRow({
      variables: {
        id: row.id,
        name: row.name,
        icon_uri: row.icon_uri,
        unit: row.unitByUnit.id,
        unit_cost: Math.round(parseFloat(row.unit_cost)),
        archived: !row.archived,
        material_setting: row.settingByMaterialSetting.id,
        in_stock: row.in_stock,
        link: row.link,
        lumion_file_name: row.lumion_file_name,
        sketchup_file_link: row.sketchup_file_link,
        proxy_file_link: row.sketchup_proxy_file_link,
        vray_file_link: row.vray_file_link,
        lumion_file_link: row.lumion_file_link,
        max_file_link: row.max_file_link,
        material_vendor: row.material_vendor,
        material_retailer: row.material_retailer,
        material_price_tier: row.material_price_tier,
        material_collection_name: row.material_collection_name,
        visibility: row.visibility,
        description: row.description,
      },
    });
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        style={{ background: props.isEven ? "#eeeeee" : "" }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {props.row.map((_row) => _row?.[0])}
      </TableRow>
      <TableRow style={{ background: props.isEven ? "#eeeeee" : "" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Formik
              initialValues={{
                name: row.name,
                iconUri: row.icon_uri,
                unitCost: (parseFloat(row.unit_cost) / 100).toFixed(2),
                laborCost: row.labor_cost
                  ? (parseFloat(row.labor_cost) / 100).toFixed(2)
                  : "0.00",
                unit: row.unitByUnit?.id,
                link: row.link,
                lumion_file_name: row.lumion_file_name,
                max_file_link: row.max_file_link,
                lumion_file_link: row.lumion_file_link,
                sketchup_file_link: row.sketchup_file_link,
                sketchup_proxy_file_link: row.proxy_file_link || "",
                vray_file_link: row.vray_file_link,
                inStock: row.in_stock,
                description: row.description,
                vendor: row.material_vendor,
                retailer: row.material_retailer,
                price_tier: row.priceTierByMaterialPriceTier?.id,
                collection_name: row.collectionNameByMaterialCollectionName?.id,
                setting: row.settingByMaterialSetting?.id,
                visibility: row.visibility || "ALL",
                materials: row.material_base_material_material_links?.map(
                  (link: IMaterialBaseMaterialMaterialLink) =>
                    link.material_material.id
                ),
                styles: row.material_base_material_style_links?.map(
                  (link: IMaterialBaseMaterialStyleLink) =>
                    link.material_style.id
                ),
                types: row.material_base_material_type_links?.map(
                  (link: IMaterialBaseMaterialTypeLink) => link.material_type.id
                ),
                regions: row.material_base_region_links?.map(
                  (link: IMaterialBaseRegionLink) => link.product_region.id
                ),
              }}
              validate={(values) => {
                const errors: Partial<IFormikSurfaceErrorProps> = {};
                if (!values.name) {
                  errors.name = "Required";
                }
                if (!values.iconUri) {
                  errors.iconUri = "Required";
                }
                if (!values.unitCost) {
                  errors.unitCost = "Required";
                } else if (isNaN(parseFloat(values.unitCost))) {
                  errors.unitCost = "Invalid Number";
                }
                if (!values.unit) {
                  errors.unit = "Required";
                }
                if (!values.vendor) {
                  errors.vendor = "Required";
                }
                if (!values.setting) {
                  errors.setting = "Required";
                }
                if (!values.materials.length) {
                  errors.materials = "At least one required";
                }
                if (!values.styles.length) {
                  errors.styles = "At least one required";
                }
                if (!values.types.length) {
                  errors.styles = "At least one required";
                }
                return errors;
              }}
              onSubmit={(values) => {
                onUpdateRow({
                  name: values.name,
                  inStock: values.inStock,
                  iconUri: values.iconUri,
                  laborCost: values.laborCost,
                  unitCost: values.unitCost,
                  vendor: values.vendor,
                  retailer: values.retailer,
                  unit: values.unit,
                  lumion_file_name: values.lumion_file_name,
                  max_file_link: values.max_file_link,
                  types: values.types,
                  setting: values.setting,
                  materials: values.materials,
                  price_tier: values.price_tier,
                  collection_name: values.collection_name,
                  styles: values.styles,
                  link: values.link,
                  lumion_file_link: values.lumion_file_link,
                  sketchup_file_link: values.sketchup_file_link,
                  sketchup_proxy_file_link: values.sketchup_proxy_file_link,
                  vray_file_link: values.vray_file_link,
                  visibility: values.visibility,
                  description: values.description,
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <>
                  <Box className={classes.editItems} margin={1}>
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <TextField
                          error={!!(errors.name && touched.name)}
                          className={classes.editItem}
                          onChange={handleChange("name")}
                          value={values.name}
                          label="Name"
                          color="primary"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetLibraryImageUpload
                          formikName="iconUri"
                          onImageUploaded={onImageUploaded}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          multiple
                          query={MATERIAL_MATERIAL_SELECT}
                          queryField="material_material"
                          formikName="materials"
                          label="Material"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          multiple
                          query={MATERIAL_TYPE_SELECT}
                          queryField="material_type"
                          formikName="types"
                          label="Type"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          query={MATERIAL_SETTING_SELECT}
                          queryField="material_setting"
                          formikName="setting"
                          label="Setting"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          query={MATERIAL_PRICE_TIER_SELECT}
                          queryField="material_price_tier"
                          formikName="price_tier"
                          label="Price Tier"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          query={MATERIAL_UNIT_SELECT}
                          queryField="material_unit"
                          formikName="unit"
                          label="Unit"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          error={!!(errors.unitCost && touched.unitCost)}
                          className={classes.editItem}
                          onChange={handleChange("unitCost")}
                          value={values.unitCost}
                          label="Unit Cost"
                          color="primary"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          error={!!(errors.laborCost && touched.laborCost)}
                          className={classes.editItem}
                          onChange={handleChange("laborCost")}
                          value={values.laborCost}
                          label="Labor Cost"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          multiple
                          query={MATERIAL_STYLE_SELECT}
                          queryField="material_style"
                          formikName="styles"
                          label="Style"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          className={classes.editItem}
                          onChange={handleChange("link")}
                          value={values.link}
                          label="Link"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          query={MATERIAL_VENDOR_SELECT}
                          queryField="material_vendor"
                          formikName="vendor"
                          label="Vendor"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          query={MATERIAL_RETAILER_SELECT}
                          queryField="material_retailer"
                          formikName="retailer"
                          label="Retailer"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <AssetFeatureSelect
                          query={MATERIAL_COLLECTION_NAME_SELECT}
                          queryField="material_collection_name"
                          formikName="collection_name"
                          label="Collection Name"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <InStockSelect />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          multiline
                          className={classes.editItem}
                          onChange={handleChange("description")}
                          value={values.description}
                          label="Description"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          className={classes.editItem}
                          onChange={handleChange("lumion_file_name")}
                          value={values.lumion_file_name}
                          label="Lumion File Name"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          className={classes.editItem}
                          onChange={handleChange("max_file_link")}
                          value={values.max_file_link}
                          label="MAX File Link"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <VisibilitySelect />
                      </Grid>
                      <Grid item md={12}>
                        <AssetFeatureSelect
                          multiple
                          optional
                          readOnly
                          query={PRODUCT_REGION_SELECT}
                          queryField="product_region"
                          formikName="regions"
                          label="Region"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      className={classes.uploadContainer}
                    >
                      <ModelFileUploadForm />
                    </Grid>
                  </Box>
                  <div className={classes.itemBtns}>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                      onClick={() => handleSubmit()}
                      style={{ marginRight: "0.5rem" }}
                    >
                      Save Changes
                    </Button>
                    {isSubmitting && (
                      <div className={classes.saveProgress}>
                        <Typography>
                          {Math.min(saveProgress, 100).toFixed(0)}%
                        </Typography>
                        <CircularProgress size={15} style={{ marginLeft: 5 }} />
                      </div>
                    )}
                    {row.archived ? (
                      <Button
                        variant="contained"
                        disabled={isSubmitting || isArchiving}
                        onClick={onArchiveToggle}
                      >
                        Un-archive
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        disabled={isSubmitting || isArchiving}
                        onClick={onArchiveToggle}
                      >
                        Archive
                      </Button>
                    )}
                  </div>
                  <br />
                  <br />
                </>
              )}
            </Formik>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default SurfacesTableRow;
