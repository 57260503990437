import * as React from "react";
import {
  Box,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
import { PropertyMediaInclusion } from "./IncludeMediaInSharedLink";
import { MemoizedPropertyMediaExpansion } from "./PropertyMediaExpansion";
import { CompleteVariants } from "@yardzen-inc/models";

interface PropertyMediaSelectProps {
  propertyInclusion: PropertyMediaInclusion;
  updatePropertyInclusion: (
    key: string,
    value: CompleteVariants | boolean
  ) => void;
}
const PropertyMediaSelect: React.FunctionComponent<PropertyMediaSelectProps> =
  ({ propertyInclusion, updatePropertyInclusion }) => {
    const handleUpdate = React.useCallback((key, value) => {
      updatePropertyInclusion(key, value);
    }, []);

    return (
      <div>
        <ExpansionPanel expanded={propertyInclusion.include}>
          <ExpansionPanelSummary
            onClick={() => handleUpdate("include", !propertyInclusion.include)}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <Checkbox
                checked={propertyInclusion.include}
                onChange={() => {
                  handleUpdate("include", !propertyInclusion.include);
                }}
              />
              <Typography>Include Property Media</Typography>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Box display="flex">
              <MemoizedPropertyMediaExpansion
                yard="front"
                key={`property-variant-front`}
                updateVariantArr={handleUpdate}
                variantArr={propertyInclusion.front}
              />
              <MemoizedPropertyMediaExpansion
                yard="back"
                key={`property-variant-back`}
                updateVariantArr={handleUpdate}
                variantArr={propertyInclusion.back}
              />

              <MemoizedPropertyMediaExpansion
                yard="right"
                key={`property-variant-right`}
                updateVariantArr={handleUpdate}
                variantArr={propertyInclusion.right}
              />
              <MemoizedPropertyMediaExpansion
                yard="left"
                key={`property-variant-left`}
                updateVariantArr={handleUpdate}
                variantArr={propertyInclusion.left}
              />
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  };

export const MemoizedPropertyMediaSelect = PropertyMediaSelect;
