import * as React from "react";
import { makeStyles, Typography, Divider, Box } from "@material-ui/core";
import classnames from "classnames";
import {
  CheckCircle,
  ExpandLess,
  ExpandMore,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import DueDateFetcher from "./DueDateFetcher";
import { Project } from "@yardzen-inc/models";
import { YZTypography } from "@yardzen-inc/react-common";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    pointerEvents: "all",
    transition: "background-color 250ms",
    cursor: "pointer",
  },
  listTitle: {
    backgroundColor: "#fff",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    padding: "0.5rem",
    display: "flex",
    alignItems: "center",
  },
  sublistItem: {
    flexGrow: 1,
    transition: "background-color 250ms",
  },
  subIcon: {
    fontSize: 18,
    color: theme.palette.grey[200],
  },
  selectedSubIcon: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  hoverable: {
    "&:hover": {
      pointerEvents: "all",
      backgroundColor: theme.palette.grey[100],
      cursor: "pointer",
    },
  },
  sublistItemSelected: {
    backgroundColor: "#DFE6DF !important",
    cursor: "default !important",
  },
  expando: {
    display: "flex",
    width: "100%",
    flexFlow: "row nowrap",
  },
  expandoCol: {
    display: "flex",
    flexFlow: "column nowrap",
  },
}));

interface Props {
  children: string;
  onClick: () => void;
  selected?: boolean;
  sublistItems: {
    label: string | React.ReactNode;
    p?: Project;
    key: string;
    onClick?: () => void;
  }[];
  selectedSublist?: string;
}

export default (props: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root} onClick={props.onClick}>
      <Box className={classes.listTitle}>
        <YZTypography type="serif" variant="h6" style={{ flex: 1 }}>
          {props.children}
        </YZTypography>
        {props.selected ? (
          <ExpandLess style={{ fontSize: 17 }} />
        ) : (
          <ExpandMore style={{ fontSize: 17 }} />
        )}
      </Box>
      {props.selected && (
        <div className={classes.expando} style={{ flexGrow: 1 }}>
          <div className={classes.expandoCol} style={{ flexGrow: 1 }}>
            {renderSublistItems()}
          </div>
        </div>
      )}
      <Divider />
    </div>
  );

  function renderSublistItems(): React.ReactNode[] {
    const components: React.ReactNode[] = [];
    for (let si of props.sublistItems) {
      components.push(
        <div
          key={si.key}
          className={classnames(
            props.selectedSublist === si.key ? classes.sublistItemSelected : "",
            classes.sublistItem,
            si.onClick ? classes.hoverable : ""
          )}
          onClick={si.onClick}
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            padding: "0.5rem",
            alignItems: "center",
          }}
        >
          {si.key === props.selectedSublist ? (
            <CheckCircle className={classes.selectedSubIcon} />
          ) : (
            <RadioButtonUnchecked className={classes.subIcon} />
          )}
          <Box flex="1" pl={1}>
            <Typography
              style={{ flexGrow: 1, textOverflow: "ellipsis" }}
              variant="body2"
              key={si.key}
            >
              {si.label}
            </Typography>
            {si.p && (
              <>
                <Typography
                  style={{
                    flexGrow: 1,
                    textOverflow: "ellipsis",
                    marginTop: "-2px",
                    display: "block",
                  }}
                  variant="caption"
                  color="textSecondary"
                  onClick={si.onClick}
                  key={si.key + "-dd"}
                >
                  Due: <DueDateFetcher project={si.p} />
                </Typography>
              </>
            )}
          </Box>
        </div>
      );
    }

    return components;
  }
};
