import {
  Box,
  Card,
  CardContent,
  Chip,
  Switch,
  Typography,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { PromotionalCode } from "src/api/pangaeaTypes";
import { NamedField } from "../BuildView/routes/Contractors/components/NamedField";
import { formatDateTime } from "./util/formatDateTime";
import { Delete, Edit } from "@material-ui/icons";

export interface PromotionalCodeCardProps extends PromotionalCode {
  handleToggleActivation: () => void;
  handleDelete: () => void;
  handleEdit: () => void;
  disabled?: boolean;
  products: { id: string; name: string }[];
}

export const PromotionalCodeCard: FC<PromotionalCodeCardProps> = (props) => {
  const [deactivated, setDeactivated] = React.useState(props.deactivated);

  useEffect(() => {
    // show deactivation slider state change immediately, but protect state on
    // request failure
    if (deactivated !== props.deactivated) {
      setDeactivated(props.deactivated);
    }
  }, [props.deactivated]);

  return (
    <Card>
      <CardContent>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h4" component="p">
              {props.code}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box>
              <IconButton onClick={props.handleEdit} disabled={props.disabled}>
                <Edit />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                onClick={props.handleDelete}
                disabled={props.disabled}
              >
                <Delete />
              </IconButton>
            </Box>
            <Box>
              <FormControlLabel
                label={!deactivated ? "ACTIVATED" : "Deactivated"}
                control={
                  <Switch
                    checked={!deactivated}
                    disabled={props.disabled}
                    onChange={() => {
                      setDeactivated(!deactivated);
                      props.handleToggleActivation();
                    }}
                  />
                }
              />
            </Box>
            <Chip
              color={props.status === "USABLE" ? "primary" : "secondary"}
              label={props.status}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          p={2}
          px={4}
          alignItems="flex-start"
        >
          <Box display="flex" flexDirection="column" p={2}>
            <NamedField label="Category" value={props.category ?? ""} />
          </Box>
          <Box display="flex" flexDirection="column" p={2}>
            <NamedField label="Type" value={props.discountType} />
            <NamedField
              label="Max Redemptions"
              value={`${props.maxRedemptions ?? "∞"}`}
            />
          </Box>
          <Box display="flex" flexDirection="column" p={2}>
            <NamedField
              label="Discount"
              value={
                props.discountType === "FIXED"
                  ? "$" + (parseInt(props.discount) / 100).toFixed(2)
                  : props.discount.replace("%", "") + "%"
              }
            />
            <NamedField
              label="Redemptions"
              value={props?.redemptions?.toString?.() ?? "0"}
            />
          </Box>
          <Box display="flex" flexDirection="column" p={2}>
            <NamedField
              label="Starts At"
              value={formatDateTime(new Date(props.startTime))}
            />
            <NamedField
              label="Created At"
              value={formatDateTime(new Date(props.createdAt))}
            />
          </Box>
          <Box display="flex" flexDirection="column" p={2}>
            <NamedField
              label="Ends At"
              value={formatDateTime(new Date(props.endTime))}
            />
            <NamedField
              label="Updated At"
              value={formatDateTime(new Date(props.updatedAt))}
            />
          </Box>
        </Box>
        {props.applicationSuccessMessage && (
          <Box px={2}>
            <Box p={2}>
              <NamedField
                label="Success Message"
                value={props.applicationSuccessMessage}
              />
            </Box>
          </Box>
        )}

        {props.additionalDiscounts && (
          <Box>
            {props.additionalDiscounts.length > 0 ? (
              <Box py={1}>
                <Typography>Additional Discounts</Typography>
              </Box>
            ) : null}
            <Box
              display="flex"
              flexDirection="row"
              px={4}
              alignItems="flex-start"
            >
              {props.additionalDiscounts.map((discount, index) => (
                <Box
                  sx={{
                    border: "1px solid #000",
                    borderRadius: ".5rem",
                    marginRight: "1rem",
                  }}
                  key={index}
                >
                  <Box display="flex" flexDirection="column" p={2}>
                    <NamedField
                      label="For Product"
                      value={
                        discount.forProductId &&
                        discount.forProductId?.length > 0
                          ? props.products.find(
                              (product) => product.id === discount.forProductId
                            )?.name ?? "Unknown Product"
                          : "All"
                      }
                    />
                    <NamedField
                      label="Type"
                      value={
                        discount.discountType ? discount.discountType : "error"
                      }
                    />
                    <NamedField
                      label="Amount"
                      value={
                        discount.discountType === "FIXED"
                          ? "$" + (parseInt(discount.discount) / 100).toFixed(2)
                          : discount.discount.replace("%", "") + "%"
                      }
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
