import { makeStyles, Grid, Box, Button } from "@material-ui/core";
import * as React from "react";
import DesignAssignmentSlug from "../Components/DesignAssignmentSlug";
import getMapUrl from "../util/googleMapSearch";
import mmddyyyy from "./mmddyyyy";
import { PageComponentProps } from "./CurrentJobPageMap";
import {
  Project,
  DesignAssignment,
  ProjectManagerProperties,
} from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { IdentifyingPaymentId } from "./IdentifyingPaymentId";
import useDssStatesOrderedMap from "../util/selfAssign/useDssStatesOrderedMap";
import { YZTypography } from "@yardzen-inc/react-common";
import DesignNotes from "./DesignNotes";
import { formatAddress } from "../util/functions/formatAddress";
import { getPackageDisplayName } from "../util/functions/getPackageDisplayName";
import { BudgetDisplayWrapper } from "../Components/Budget/BudgetDisplayWrapper";
interface Props extends PageComponentProps {
  assignment: DesignAssignment;
}

const useStyles = makeStyles((theme) => ({
  item: {
    padding: "0.33rem 0",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    "& h5": {
      fontSize: "0.9rem",
    },
  },
  itemNoBorder: {
    padding: "0.33rem 0",
    "& h5": {
      fontSize: "0.9rem",
    },
  },
}));

export const DesignerSummaryInfo = (props: Props) => {
  const dssStates = useDssStatesOrderedMap();
  const classes = useStyles();
  const address = props.address ? formatAddress(props.address) : null;
  const [pmName, setPmName] = React.useState<string | null>(null);

  React.useEffect(getPm, [props.project.projectManagerId]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md>
          <Box className={classes.item}>
            <YZTypography type="mono" variant="caption" color="textSecondary">
              Job Title
            </YZTypography>
            <YZTypography variant="body1">
              <DesignAssignmentSlug
                type={getType(props.project) as any}
                profile={props.profile}
              />
            </YZTypography>
          </Box>

          <Box className={classes.item}>
            <YZTypography type="mono" variant="caption" color="textSecondary">
              Client Name
            </YZTypography>
            <YZTypography variant="body1">
              {`${props.profile.firstName} ${props.profile.lastName}`}
            </YZTypography>
          </Box>

          <Box className={classes.item}>
            <YZTypography type="mono" variant="caption" color="textSecondary">
              Address
            </YZTypography>
            <Box display="flex" alignItems="center">
              <Box pr={1}>
                <YZTypography variant="body1">
                  {address || "Unknown"}
                </YZTypography>
              </Box>
              {address && (
                <Button
                  variant="outlined"
                  href={getMapUrl(address)}
                  size="small"
                  style={{
                    padding: "6px 6px 4px",
                  }}
                >
                  <YZTypography variant="body1" style={{ fontSize: 10 }}>
                    View on map
                  </YZTypography>
                </Button>
              )}
            </Box>
          </Box>
          <DesignNotes profile={props.profile} assignment={props.assignment} />

          {props.assignment.dueDate && (
            <Box className={classes.item}>
              <YZTypography type="mono" variant="caption" color="textSecondary">
                Due Date
              </YZTypography>
              <YZTypography variant="body1">
                {mmddyyyy(props.assignment.dueDate)}
              </YZTypography>
            </Box>
          )}
          <Box className={classes.itemNoBorder}>
            <YZTypography type="mono" variant="caption" color="textSecondary">
              Project Manager
            </YZTypography>
            <YZTypography variant="body1">
              {pmName ? pmName : "Not assigned"}
            </YZTypography>
          </Box>
        </Grid>
        <Grid item md>
          <Box className={classes.item}>
            <YZTypography type="mono" variant="caption" color="textSecondary">
              Package
            </YZTypography>
            <YZTypography variant="body1">
              {props.profile.lotSize?.includes("Lowes")
                ? "Lowe's " + getPackageDisplayName(props.profile.package)
                : getPackageDisplayName(props.profile.package)}
            </YZTypography>
          </Box>
          <Box className={classes.item}>
            <YZTypography type="mono" variant="caption" color="textSecondary">
              Lot Size
            </YZTypography>
            <YZTypography variant="body1">
              {props.profile["lotSize"] || "Unknown"}
            </YZTypography>
          </Box>
          <Box className={classes.item}>
            <YZTypography type="mono" variant="caption" color="textSecondary">
              Segment
            </YZTypography>
            <YZTypography variant="body1">
              {(props.designProfile && props.designProfile.designingFor) ||
                "Unknown"}
            </YZTypography>
          </Box>

          <Box className={classes.item}>
            <YZTypography type="mono" variant="caption" color="textSecondary">
              Budget
            </YZTypography>
            <BudgetDisplayWrapper
              projectId={props.project.id}
              packageType={props.profile.package}
              yardDifficultyRating={props.project.yardDifficultyRating}
              clientBudgetIntent={props.project.clientBudgetIntent}
            />
          </Box>

          <Box className={classes.item}>
            <YZTypography type="mono" variant="caption" color="textSecondary">
              Order ID
            </YZTypography>
            <IdentifyingPaymentId
              linearProgressStyle={{ maxWidth: "40%" }}
              profileOrId={props.profile}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );

  function getType(p: Project): string | null {
    if (!dssStates) return null;

    const { designAssignmentStatus: status } = p;

    if (status === "correctionInProgress" || status === "postCorrectionHold") {
      return "correction";
    }

    return dssStates.get(status)?.type ?? null;
  }
  function getPm(): void | (() => void) {
    const pmId = props.project.QAManagerId || props.project.projectManagerId;
    if (!pmId) {
      return;
    }

    return firebase
      .firestore()
      .collection("projectManagers")
      .doc(pmId)
      .onSnapshot(async (snap) => {
        const data = snap.data();

        if (!data) {
          throw new Error(`project manager ${pmId} does not exist`);
        }

        const { firstName, lastName } = data as ProjectManagerProperties;

        setPmName(`${firstName} ${lastName}`);
      });
  }
};
