import React, { FC } from "react";
import { Box } from "@material-ui/core";
import NotationCard from "../Components/NotationCard";
import "firebase/compat/firestore";
import { PageComponentProps } from "./CurrentJobPageMap";
import { Media, Assignment } from "@yardzen-inc/models";
import { asyncForEach } from "../util/asyncForEach";

export interface InspirationPhotoTabProps extends PageComponentProps {}

const InspirationPhotoTab: FC<InspirationPhotoTabProps> = (props) => {
  const [media, setMedia] = React.useState<Media[] | null>([]);

  React.useEffect(() => {
    getInspirationMedia();
  }, []);

  return (
    <Box p={1} display="flex" flexDirection="column">
      {media &&
        media
          .filter((m) => !!m)
          .map((m) => (
            <Box py={1}>
              <NotationCard
                readonly
                key={`not-${m.id}`}
                media={m}
                disableThumbnail={true}
              />
            </Box>
          ))}
    </Box>
  );

  async function getInspirationMedia() {
    const media = await Assignment.getAllMediaIdsByFileTags(
      ["inspiration"],
      props.profile.id
    );

    (async () => {
      await asyncForEach(
        media,
        async (mediaId: string) => {
          const data = await Media.fetchSingle(mediaId);
          if (!!data) {
            setMedia((media) => [...(media || []), data]);
          }
        },
        `User (Profile - ${props.profile.id}) was unable to fetch media from Firebase Storage`
      );
    })();
  }
};

export { InspirationPhotoTab };
export default InspirationPhotoTab;
