import React, { FC } from "react";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { useOpenModelingAssignments } from "../../util/hooks/useOpenModelingAssignments";
import { ModelingAssignmentList } from "./ModelingAssignmentList";

const useStyles = makeStyles({
  root: {},
  message: {
    paddingTop: "5rem",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  refresh: {
    width: "100%",
    textAlign: "right",
  },
  button: {
    marginLeft: "2rem",
    marginRight: "2rem",
  },
});

const MssQueue: FC = () => {
  const classes = useStyles();
  const {
    loading,
    openModelingAssignmentsWithProfile,
    error,
    refresh,
    lastRefresh,
  } = useOpenModelingAssignments();

  return (
    <div className={classes.root}>
      <div className={classes.refresh}>
        {lastRefresh && `last refresh: ${lastRefresh.toLocaleTimeString()}`}
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="outlined"
            onClick={refresh}
            className={classes.button}
          >
            Refresh
          </Button>
        )}
      </div>

      <div className={classes.message}>
        {!loading && error
          ? "There was an error loading open modeling assignments. Please contact Engineering."
          : null}
        {!loading && !openModelingAssignmentsWithProfile.length
          ? "There are currently no modeling assignments to assign. Please check back later."
          : null}
      </div>
      <ModelingAssignmentList
        openModelingAssignments={openModelingAssignmentsWithProfile}
      ></ModelingAssignmentList>
    </div>
  );
};

export { MssQueue };
