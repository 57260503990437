import { Box, CircularProgress, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useAgentGroupStanding } from "./useAgentGroupStanding";

export interface AgentGroupGateProps {
  groupId: string | null;
}

const AgentGroupGate: FC<AgentGroupGateProps> = ({ groupId, children }) => {
  const groupActive = useAgentGroupStanding(groupId);

  if (groupActive) {
    return children;
  }

  if (groupActive === null) {
    return (<CircularProgress />) as any;
  }

  return (
    <Box p={2} width={"100%"} display="flex" justifyContent="center">
      <Typography style={{ fontWeight: "bold" }}>
        There are no assignments available.
      </Typography>
    </Box>
  );
};

export { AgentGroupGate };
export default AgentGroupGate;
