/**
 * `applyAndNullFiltering` _helps_ applying default null filtering behavior to
 * the condition to be sent to hasura
 *
 * By _default null filtering_ we mean that for specific relations, the absence of
 * related objects should be treated as a match.
 * @param condition Condition object to mutate with null filtering
 */
export function applyAndNullFiltering(
  condition: { [key: string]: any },
  ...nullInclusiveRelations: string[]
) {
  let currentAndGroup = condition._and;

  while (currentAndGroup) {
    for (const relation of nullInclusiveRelations) {
      if (currentAndGroup?.[relation]) {
        currentAndGroup._or = [
          {
            [relation]: currentAndGroup[relation],
          },
          {
            [relation + "_aggregate"]: {
              count: { predicate: { _lt: 1 } },
            },
          },
        ];

        currentAndGroup[relation] = undefined;
        break;
      }
    }

    currentAndGroup = currentAndGroup?._and;
  }
}
