import React, { FC, ReactElement, ReactNode } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { ColorResponseMap } from "../util/hooks/useSelectedColorsForProject";
import { YZTypography } from "@yardzen-inc/react-common";
import {
  PaintColorDisplayCard,
  PaintColorDisplayCardProps,
} from "@yardzen-inc/react-common";

export interface ColorResponseListProps extends BoxProps {
  colorResponseMap: ColorResponseMap;
}

const ColorResponseList: FC<ColorResponseListProps> = ({
  colorResponseMap,
  ...containerProps
}) => {
  return (
    <Box
      {...containerProps}
      display="flex"
      flexDirection="row"
      p={1}
      alignSelf="center"
      width="fit-content"
      maxWidth="100%"
      flexWrap="wrap"
      bgcolor="whitesmoke"
    >
      {renderListItems()}
    </Box>
  );

  function renderListItems(): ReactNode {
    if (!colorResponseMap.size) {
      return (
        <Box width="100%" display="flex" justifyContent="center">
          <YZTypography align="center" variant="h4">
            No Paint selections found
          </YZTypography>
        </Box>
      );
    }

    const elements: ReactElement<PaintColorDisplayCardProps>[] = [];
    // @ts-ignore
    for (let { color, responses } of colorResponseMap.values()) {
      elements.push(
        // @ts-ignore
        ...responses.map((response) => (
          <Box key={`color-block-${color.id}-${response.id}`} p={1}>
            <PaintColorDisplayCard
              colorHex={(color as any).hex_code ?? ""}
              isDark={false}
              name={color.name ?? ""}
              selectedPlacement={response.placement ?? ""}
            />
          </Box>
        ))
      );
    }

    return elements;
  }
};

export { ColorResponseList };
export default ColorResponseList;
