import * as React from "react";
import firebase from "firebase/compat/app";
import NoteList from "./NoteList";
import { Drawer, Box, makeStyles, IconButton } from "@material-ui/core";
import { Project, Profile } from "@yardzen-inc/models";
import { Close } from "@material-ui/icons";

export interface AssignmentNotesSidebarProps {
  project: Project | string;
  open?: boolean;
  onClose: () => void;
  clientRecord: Profile;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    maxWidth: "450px",
  },
});

export const AssignmentNotesSidebar = (props: AssignmentNotesSidebarProps) => {
  const [project, setProject] = React.useState<Project | null>(
    setInitialProjectState
  );

  React.useEffect(projectChangeHook, [props.project]);

  const { root: paper } = useStyles();

  return (
    <Drawer
      classes={{ paper }}
      anchor="left"
      variant="persistent"
      onClose={props.onClose}
      open={!!props.open}
    >
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        p={1}
      >
        {renderTopBar()}
        <NoteList
          project={project}
          lastName={props.clientRecord.lastName as string}
          street={props.clientRecord.street as string}
        />
      </Box>
    </Drawer>
  );

  function renderTopBar(): React.ReactNode {
    return (
      <Box
        p={1}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <IconButton onClick={props.onClose} style={{ marginLeft: "auto" }}>
          <Close></Close>
        </IconButton>
      </Box>
    );
  }

  function setInitialProjectState(): Project | null {
    if (props.project instanceof Project) {
      return props.project;
    }

    return null;
  }

  function projectChangeHook(): void | (() => void) {
    if (props.project instanceof Project) {
      if (props.project === project) {
        return;
      }

      return setProject(props.project);
    }

    if (typeof props.project === "string") {
      return firebase
        .firestore()
        .collection("projects")
        .doc(props.project)
        .onSnapshot(async (snap) => {
          if (!snap.exists) {
            return setProject(null);
          }

          setProject(Project.hydrate([snap])[0]);
        });
    }

    throw new Error(
      "AssignmentNotesSidebarProps.project should be a Project or a string"
    );
  }
};

export default AssignmentNotesSidebar;
