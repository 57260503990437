import * as React from "react";
import {
  Input,
  Typography,
  FormControl,
  Button,
  InputLabel,
} from "@material-ui/core";
import { Redirect } from "react-router";
import firebase from "firebase/compat/app";
import { Link as RouterLink } from "react-router-dom";

export interface State {
  email: string;
  loading: boolean;
  error: string;
}

export interface Props {}

class SignIn extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoggedIn: false,
      email: "",
      loading: false,
      error: "",
      errorText: "",
      success: false,
    };

    this.onRecoverSubmit = this.onRecoverSubmit.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
  }

  public render() {
    if (this.state.isLoggedIn === true) {
      return <Redirect to={{ pathname: "/get-started" }} />;
    }

    const loading = this.state.loading;

    return (
      <main className="main-login">
        <div className="login-paper">
          <form onSubmit={this.onRecoverSubmit}>
            <Typography component="h1" variant="h5">
              Recover Account
            </Typography>
            <Typography paragraph>
              Enter the email address you used to create your acccount.
            </Typography>
            <Typography paragraph>
              Or <RouterLink to="/create-account">Create Account</RouterLink>
            </Typography>
            <FormControl
              margin="normal"
              required
              fullWidth
              error={this.state.error === "email" ? true : false}
            >
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                id="email"
                name="email"
                placeholder="Email Address"
                inputProps={{
                  type: "email",
                  autoCapitalize: "off",
                  autoCorrect: "off",
                  spellCheck: false,
                }}
                autoFocus
                onChange={this.onEmailChange}
                value={this.state.email}
              />
              {this.state.error === "email" ? (
                <Typography variant="caption" color="error">
                  {this.state.errorText}
                </Typography>
              ) : (
                <div></div>
              )}
              {this.state.success === true ? (
                <Typography paragraph>Email sent</Typography>
              ) : (
                <div></div>
              )}
            </FormControl>
            <div>
              <Button
                type="submit"
                value="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
              >
                {!loading ? "Send Email" : "Sending..."}
              </Button>
            </div>
            <div className="spacer"></div>
          </form>
        </div>
      </main>
    );
  }

  public componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        this.setState({ isLoggedIn: false });
        return false;
      }

      this.setState({ isLoggedIn: true });

      return true;
    });
  }

  protected onRecoverSubmit(e: React.FormEvent) {
    e.preventDefault();

    this.setState({ loading: true });

    firebase
      .auth()
      .sendPasswordResetEmail(this.state.email)
      .then(() => {
        this.setState({ success: true, email: "" });
      })
      .catch((error) => {
        console.log(error);
        return this.handleSubmitError(error.code, error.message);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  protected handleSubmitError(errorCode: string, errorText: string) {
    let error: string;

    switch (errorCode) {
      default:
        error = "email";
    }

    return this.setState({
      error: error,
      errorText: errorText,
    });
  }

  protected onEmailChange(e: any) {
    this.setState({
      email: e.target.value,
      error: "",
    });
  }
}

export default SignIn;
