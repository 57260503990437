import React from "react";
import { Link, makeStyles } from "@material-ui/core";
import { Profile, Project } from "@yardzen-inc/models";
import { PIPEFY_CARD_BASE_URL } from "../../../src/ConstantValues/pipefyConstants";
import { PipefyProjectCardCreateButton } from "./PipefyProjectCardCreateButton";
export interface Props {
  profile: Profile;
  project: Project;
}

const useStyles = makeStyles((theme) => ({
  pipefyLink: {
    fontSize: "1rem",
  },
}));

function PipefyCardLinksAndCreate(props: Props) {
  const { project, profile } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      {project.pipefyProjectCardIds && project.pipefyProjectCardIds.length > 0
        ? project.pipefyProjectCardIds.map((projectCard, index) => (
            <ul>
              <Link
                target="_blank"
                className={classes.pipefyLink}
                href={`${PIPEFY_CARD_BASE_URL}/${
                  project.pipefyProjectCardIds![index]
                }`}
              >
                View Pipefy Project card {project.pipefyProjectCardIds![index]}
              </Link>
            </ul>
          ))
        : null}
      {/* Project Card Create Button should always be visible because duplicate project
      cards may exist*/}

      <PipefyProjectCardCreateButton profile={profile} project={project} />
    </React.Fragment>
  );
}

export { PipefyCardLinksAndCreate };
