import "firebase/compat/database";
import { Profile, DesignProfile } from "@yardzen-inc/models";
export interface Addy {
  city: string;
  street: string;
  state: string;
  zip: string;
}

const getAddress = async (uid: string): Promise<Addy | null> => {
  try {
    const profile = await Profile.get(uid);
    if (profile.street) {
      const { street, city, state, zip } = profile;

      return {
        street,
        city,
        state,
        zip,
      } as Addy;
    }

    const { email } = profile;

    if (!email) return null;

    const dProfile = await DesignProfile.fetchByEmail(email);
    const { street, city, state, zip } = dProfile;
    return {
      street,
      city,
      state,
      zip,
    } as Addy;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export { getAddress };
