export default (phoneNumber: string | number): string => {
  let num: number;

  if (typeof phoneNumber === "number") {
    num = phoneNumber;
  } else {
    if (phoneNumber === "") {
      return "";
    }

    num = parseInt(phoneNumber);
  }

  if (isNaN(num)) {
    return `${phoneNumber}`;
  }

  const strNum = num.toString();
  const len = strNum.length;

  if (!len) {
    return "";
  }

  const firstThree = strNum.substring(0, Math.min(3, strNum.length));

  if (len <= 3) {
    return `(${firstThree})`;
  }

  const secondThree = strNum.substring(3, Math.min(6, strNum.length));

  if (len <= 6) {
    return `(${firstThree}) ${secondThree}`;
  }

  const lastFour = strNum.substring(6);

  return `(${firstThree}) ${secondThree}-${lastFour}`;
};
