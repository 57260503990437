import isProduction from "./isProduction";

export default async function genericAlert(message: string) {
  //sends to #eng-alerts channel
  if (isProduction()) {
    return fetch("https://hooks.zapier.com/hooks/catch/3530042/o6x6kxh/", {
      method: "POST",
      body: JSON.stringify({
        body: message,
      }),
    });
  }
}

function GenericOnCallFunctionAlert(
  functionName: string,
  errorMessage: string
) {
  genericAlert(
    `Service: Liisa, \nFunction: ${functionName}, \nError: ${errorMessage}, \nDetail: visit Logs in Firebase / Google for detail`
  );
}

// Use this function to send error messages to #liisa-error-alerts in Slack
function liisaErrorAlert(message: string, details: string) {
  const ZAP_URL = isProduction()
    ? "https://hooks.zapier.com/hooks/catch/3530042/ojezit2/"
    : "https://hooks.zapier.com/hooks/catch/3530042/okks9nx/";

  return fetch(ZAP_URL, {
    method: "POST",
    body: JSON.stringify({
      body: `\nError: ${message}\nDetails: ${details}`,
    }),
  });
}

export { GenericOnCallFunctionAlert, liisaErrorAlert };
