import {
  LinearProgress,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { Media } from "@yardzen-inc/models";
import downloadAndZip from "../util/downloadAndZip";
import FileUpload from "../Components/FileUpload";
import { YZButton } from "@yardzen-inc/react-common";

interface ModelUploadProps {
  media: Media | null | "loading";
  profileId: string;
  onUploadComplete: () => void;
  onReplace: () => void;
  accept: string[];
  variant: string | null;
  successMessage: string;
  tag?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    display: "flex",
    flexFlow: "row nowrap",
  },
  col: {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: 1,
  },
}));

const ModelUpload: React.FunctionComponent<ModelUploadProps> = ({
  media,
  onReplace,
  onUploadComplete,
  profileId,
  accept,
  variant,
  successMessage,
  tag,
}) => {
  const classes = useStyles();

  if (media === "loading") {
    return <LinearProgress variant="indeterminate" />;
  } else if (!media) {
    return (
      <FileUpload
        accept={accept}
        fileTag={!!tag ? tag : "model"}
        clientUserId={profileId}
        onUploadComplete={onUploadComplete}
        variant={variant}
        fileVisibility={{
          isClientVisible: true,
          isDesignerContributed: true,
          isDesignerVisible: true,
        }}
      />
    );
  } else {
    return (
      <div
        className={classes.col}
        style={{
          padding: "2rem",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Typography align="center" variant="h3">
          {successMessage}
        </Typography>
        <Typography align="center" variant="body1">
          "{media.originalFileName}"
        </Typography>
        <div
          className={classes.info}
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <YZButton
            variant="contained"
            color="primary"
            style={{ margin: "1rem" }}
            onClick={() => {
              downloadAndZip([media as any], "model");
            }}
          >
            Download
          </YZButton>
          <YZButton
            variant="contained"
            color="primary"
            style={{ margin: "1rem" }}
            onClick={onReplace}
          >
            Replace
          </YZButton>
        </div>
      </div>
    );
  }
};

export { ModelUpload };
export default ModelUpload;
