import React, { FC } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

export interface DesignBriefEntryFromActionsProps extends BoxProps {
  onApply: () => void;
  onCancel: () => void;
  disabled?: boolean;
}

const DesignBriefEntryFromActions: FC<DesignBriefEntryFromActionsProps> = ({
  onApply,
  onCancel,
  disabled,
  ...containerProps
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      justifyContent="flex-end"
      {...containerProps}
    >
      <Box pr={1}>
        <Button variant="text" onClick={onApply} disabled={!!disabled}>
          Apply
        </Button>
      </Box>
      <Box>
        <Button variant="text" onClick={onCancel} disabled={!!disabled}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export { DesignBriefEntryFromActions };
export default DesignBriefEntryFromActions;
