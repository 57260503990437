import * as React from "react";
import { RouteChildrenProps } from "react-router";
import { Switch, Route } from "react-router-dom";
import ZipCodeManagementOptions from "./ZipCodeManagementOptions";
import ReplaceGeoZoneFile from "./ReplaceGeoZoneFile";
import AddNewZipCode from "./AddNewZipCode";

const Index: React.FunctionComponent<RouteChildrenProps> = (props) => {
  const baseRoute = "/zip-code-geo-management";

  return (
    <Switch>
      <Route
        exact
        path={baseRoute}
        render={() => <ZipCodeManagementOptions baseRoute={baseRoute} />}
      />
      <Route
        path={`${baseRoute}/add-new-zip`}
        render={() => <AddNewZipCode />}
      />
      <Route
        path={`${baseRoute}/replace-geozone-file`}
        render={() => <ReplaceGeoZoneFile />}
      />
    </Switch>
  );
};

export default Index;
