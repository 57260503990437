import * as React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import {
  DesignAssignment,
  Revision,
  Agent,
  Project,
} from "@yardzen-inc/models";

export interface Props {
  assignments: DesignAssignment[];
  activeAssignment: DesignAssignment;
  activeAssignmentRevision: Revision;
  designer: Agent | false | null;
  project: Project;
}

export const DesignAssignmentCheckBoxes = (props: Props) => {
  const [listedChecked, setListedChecked] = React.useState<boolean>(
    props.activeAssignment.listed
  );

  React.useEffect(handleActiveAssignmentChange);

  return (
    <>
      <FormControlLabel
        label="Listed for auto assignment"
        control={<Checkbox checked={listedChecked} onChange={toggleListed} />}
      />
    </>
  );

  function handleActiveAssignmentChange() {
    if (listedChecked !== props.activeAssignment.listed) {
      setListedChecked(props.activeAssignment.listed);
    }
  }

  async function toggleListed() {
    const newValue = !listedChecked;
    setListedChecked(newValue);
    props.activeAssignment.listed = newValue;
    return props.activeAssignment.save();
  }
};

export default DesignAssignmentCheckBoxes;
