import firebase from "firebase/compat/app";

export interface SubmitClientOnboardingMaterialsProps {
  profileId: string;
  materialsRequirementOverride: {
    overridePerformedByAgentId: string;
    detailedOverrideReason: string;
  };
}

async function submitClientOnboardingMaterials({
  profileId,
  materialsRequirementOverride,
}: SubmitClientOnboardingMaterialsProps) {
  const _submitClientOnboardMaterials = firebase
    .functions()
    .httpsCallable("submitClientOnboardMaterials");

  await _submitClientOnboardMaterials({
    profileId,
    materialsRequirementOverride,
  });
}

export { submitClientOnboardingMaterials };
