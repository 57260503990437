import React, {
  FC,
  useContext,
  useMemo,
  ReactNode,
  useEffect,
  useState,
} from "react";
import Box from "@material-ui/core/Box";
import { QaTabContext } from "./QaTabContext";
import { useProjectsUpForQa } from "./useProjectsUpForQa";
import { CircularProgress, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { QaListItem } from "./QaListItem";
import QaListPaginationBar from "./QaListPaginationBar";
import { ProjectProperties } from "@yardzen-inc/models";
import getNumberFromQueryStringProperty from "../../util/getNumberFromQueryStringProperty";
import QaFilter from "./QaFilter";

export interface QaQueueProps extends RouteComponentProps {}

const STEP_SIZE = 10;

const QaQueue: FC<QaQueueProps> = ({ history, location }) => {
  const [typeFilter, setTypeFilter] = useState<string>("all");
  const [correctionFilter, setCorrectionFilter] = useState<string>("all");
  const { pm } = useContext(QaTabContext);
  const [projects, projectsLoading] = useProjectsUpForQa(pm.id);
  const filteredProjects = useMemo(filterProjects, [
    projects,
    typeFilter,
    correctionFilter,
  ]);
  const projLen = useMemo(() => filteredProjects?.length, [filterProjects]);
  const maxOffset = useMemo(
    () => Math.floor((projLen ?? 0) / STEP_SIZE),
    [projLen]
  );
  const offset = useMemo(
    () =>
      Math.max(
        0,
        Math.min(
          getNumberFromQueryStringProperty("offset", location) ?? 0,
          maxOffset
        )
      ),
    [location, maxOffset]
  );
  const projectSlice = useMemo(getCurrentProjectsPage, [
    offset,
    filteredProjects,
  ]);

  useEffect(scrollBack, [location]);
  useEffect(() => setOffset(0), [correctionFilter, typeFilter]);

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box width="100%" display="flex">
        <QaFilter
          correctionStatus={correctionFilter as any}
          setCorrectionStatus={setCorrectionFilter}
          type={typeFilter as any}
          setType={setTypeFilter}
        />
        <Box>{renderLoadingNotifier()}</Box>
      </Box>
      {renderList()}
      {renderPaginationControls()}
    </Box>
  );

  // filter projects based on projectFilter & correctionFilter
  function filterProjects() {
    if (!projects) {
      return projects;
    }

    let _projects = [...projects];

    if (typeFilter !== "all") {
      const regex = new RegExp(typeFilter, "i");
      _projects = _projects.filter((proj) =>
        regex.test(proj.versionString as string)
      );
    }

    if (correctionFilter !== "all") {
      const regex = new RegExp(
        correctionFilter === "correction" ? "C" : "[^C]",
        "i"
      );
      _projects = _projects.filter((proj) =>
        regex.test(proj.versionString as string)
      );
    }

    return _projects;
  }

  function scrollBack() {
    window.scrollTo({ top: 0 });
    window.document.documentElement.scrollTo({ top: 0 });
  }

  function setOffset(newOffset: number): void {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("offset", newOffset.toString());
    history.push(`${location.pathname}?${searchParams.toString()}`);
  }

  // slice current 'page' from master project list and render list items
  function renderList(): ReactNode {
    if (projects?.length === 0) {
      return <Typography>No projects found</Typography>;
    }

    return projectSlice.map((project) => (
      <QaListItem
        project={project as any}
        key={"qa_list_" + project.id}
        onClick={() => {
          history.push(
            `/client/${project.profileId}/deliverables${
              // @ts-ignore
              project["revisionId"] ? `?ir=${project["revisionId"]}` : ""
            }`
          );
        }}
      />
    ));
  }

  // slice current 'page' from master project list
  function getCurrentProjectsPage(): (ProjectProperties & { id: string })[] {
    if (!filteredProjects) {
      return [];
    }

    const from = offset * STEP_SIZE;
    return filteredProjects.slice(from, from + STEP_SIZE);
  }

  // if derrived loading status, return a spinner
  function renderLoadingNotifier(): ReactNode {
    if (projectsLoading) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent=""
          p={2}
        >
          <Box pr={2}>
            <Typography>{projects ? "Updating List" : "Loading"}</Typography>
          </Box>
          <CircularProgress />
        </Box>
      );
    }

    return null;
  }

  // conditionally render pagination controls dependant on projects fetched
  function renderPaginationControls(): ReactNode {
    if (!projLen) {
      return null;
    }

    // declare pagination controls given room in the master list to shift set left or right
    const onPrevious = offset < 1 ? void 0 : () => setOffset(offset - 1);
    const onNext =
      (offset || 1) * (STEP_SIZE + 1) >= projLen
        ? void 0
        : () => setOffset(offset + 1);

    return (
      <QaListPaginationBar
        listSize={projLen}
        offset={offset}
        stepSize={STEP_SIZE}
        onNext={onNext}
        onPrevious={onPrevious}
        height="30px"
        bgcolor="whitesmoke"
        boxShadow={1}
        position="fixed"
        top={window.innerHeight - 30}
      />
    );
  }
};

export { QaQueue };
export default QaQueue;
