import firebase from "firebase/compat/app";

const setAgentAccountStatus = async (
  agentId: string,
  disabled: boolean,
  designerPodId?: string
): Promise<void> => {
  const setAgentAccountStatus = firebase
    .functions()
    .httpsCallable("setAgentAccountStatus");

  await setAgentAccountStatus({ agentId, disabled, designerPodId });
};

export { setAgentAccountStatus };
