import * as React from "react";
import { PageComponentProps } from "./CurrentJobPageMap";
import ClientFeedbackTab from "../ClientDetail/Feedback/ClientFeedbackTab";

export default (props: PageComponentProps) => {
  return (
    <ClientFeedbackTab
      clientRecord={props.profile}
      permissions={{
        media: { access: "update" },
        profile: { access: "update" },
      }}
    />
  );
};
