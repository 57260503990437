import { SelectedItemsMaterial } from "./constants/SelectedItemsConstants";
import React from "react";
import { IAssignmentMaterialBaseLink, IMaterialBase } from "../../Interfaces";
import { DownloadUtil } from "../../util/downloadUtil";
import { formatNumToUSD } from "@yardzen-inc/react-common";
import uuid from "uuid";
import QuantityInput from "./QuantityInput";
import cleanedDownloadableFileName from "../../EmployeeView/AssetLibraries/Shared/CleanedDownloadableFileName";
import GetAppIcon from "@material-ui/icons/GetApp";
import { AssetLibraryItemTypeConstants } from "../../ConstantValues/AssetLibraryItemTypeConstants";

export const getSelectedMaterialsForBudgetQA = (
  queryDataSelection: any,
  nonPm: boolean = false,
  onUpdateSurfaceQty: (qty: number, id: string, isPaint: boolean) => void,
  enableQuantityChange: boolean = false,
  quantityInputContainer: string,
  assignment_material_base_links?: IAssignmentMaterialBaseLink[],
  projectRegion?: string
): SelectedItemsMaterial[] => {
  const dataSelectionAssignment = queryDataSelection?.assignment[0];
  const noDataSelected = !queryDataSelection?.assignment?.length;
  if (
    noDataSelected ||
    !dataSelectionAssignment.assignment_material_base_links?.length
  ) {
    return [];
  }
  const getSurfaceIdAndQuantityMap = () => {
    const assignment = assignment_material_base_links;
    if (assignment?.length) {
      const idMap = {};

      assignment.forEach((link: any) => {
        // @ts-ignore
        idMap[link.material_base_id] = link.amount;
      });
      return idMap;
    } else {
      return {};
    }
  };
  const idAndQuantityMap: any = getSurfaceIdAndQuantityMap();

  return dataSelectionAssignment.assignment_material_base_links.map(
    (link: any) => {
      const materialBase: IMaterialBase = link.material_base;
      const isPaint = materialBase.material_base_material_type_links.some(
        (l: { material_type: { name: string } }) =>
          l.material_type.name === "Paint"
      );
      const onUpdateQty = (qty: number) => {
        onUpdateSurfaceQty(qty, materialBase.id, isPaint);
      };
      const quantity = nonPm ? idAndQuantityMap[materialBase.id] : link.amount;

      const types = materialBase.material_base_material_type_links?.map(
        (link: any) => link.material_type?.name
      );
      const category = types.includes(
        AssetLibraryItemTypeConstants.ExteriorDesign
      )
        ? AssetLibraryItemTypeConstants.ExteriorDesign
        : AssetLibraryItemTypeConstants.Materials;

      let quantityDisplay = enableQuantityChange ? (
        <div className={quantityInputContainer}>
          <QuantityInput onUpdate={onUpdateQty} value={quantity} />
        </div>
      ) : (
        quantity
      );

      return {
        id: materialBase.id || uuid.v4(),
        unit: materialBase.unitByUnit?.name || "--",
        totalCost: formatNumToUSD(
          parseFloat(materialBase.unit_cost || "0") * quantity +
            parseFloat(materialBase.labor_cost || "0") * quantity
        ),
        name: materialBase.name,
        type: types.join(", "),
        locations: isPaint ? link.location?.split(",").join(", ") : null,
        image: materialBase.icon_uri,
        vendor: materialBase.vendorByMaterialVendor?.name,
        collectionName:
          materialBase.collectionNameByMaterialCollectionName?.name,
        quantity: quantityDisplay,
        quantityNumber: quantity,
        quantityPdfDisplay: `${quantity} ${materialBase.unitByUnit?.name}`,
        priceTier: materialBase.priceTierByMaterialPriceTier?.name,
        finish: materialBase.material_finish?.name,
        pattern: materialBase.material_pattern?.name,
        size: materialBase.material_size?.name,
        material: materialBase.material_base_material_material_links
          ?.map((materialLink: any) => materialLink.material_material?.name)
          .join(", "),
        color: materialBase.material_base_material_color_links
          ?.map((colorLink: any) => colorLink.material_color?.name)
          .join(", "),
        regionalAvailability:
          !materialBase?.material_base_region_links?.length || // no linked regions means good for all regions
          (projectRegion &&
            !!materialBase?.material_base_region_links?.some?.(
              (link) =>
                link.product_region.name.toLowerCase() ===
                projectRegion?.toLowerCase()
            )),
        category,
        lumionFileLink: materialBase.lumion_file_link ? (
          <div
            onClick={() =>
              DownloadUtil.downloadWithFirebaseCredentials(
                materialBase.lumion_file_link,
                undefined,
                cleanedDownloadableFileName(materialBase.name)
              )
            }
            style={{ pointerEvents: "all", display: "inline-block" }}
          >
            <GetAppIcon />
          </div>
        ) : null,
        lumionFileName: materialBase.lumion_file_name,
        sketchupFileLink: materialBase.sketchup_file_link ? (
          <div
            onClick={() =>
              DownloadUtil.downloadWithFirebaseCredentials(
                materialBase.sketchup_file_link,
                undefined,
                cleanedDownloadableFileName(materialBase.name)
              )
            }
            style={{ pointerEvents: "all", display: "inline-block" }}
          >
            <GetAppIcon />
          </div>
        ) : null,
      };
    }
  );
};
