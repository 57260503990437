import { useState, useEffect } from "react";
import { Profile, ProfileProperties } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
export interface UseProfileFromId {
  (profileId: string): null | Profile;
}

const useProfileFromId: UseProfileFromId = (profileId) => {
  const [profile, setProfile] = useState<Profile | null>(null);

  useEffect(listenForProfile, [profileId]);

  return profile;

  function listenForProfile() {
    void (async function () {
      firebase
        .firestore()
        .collection("profiles")
        .doc(profileId)
        .onSnapshot((snap) => {
          const data = snap.data();

          if (!snap.exists || !data) {
            setProfile(null);
          }

          const prof = new Profile(data as ProfileProperties);
          prof.id = profileId;

          setProfile(prof);
        });
    })();
  }
};

export { useProfileFromId };
