import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";

/**
 * Hook used to get an array of client's postPLPHold statuses. Returns null if none found.
 *
 * @param projectId Id of client's projects document
 * @returns An array of holdStatuses from the client's postPLPHolds document
 */
const usePostPLPHoldStatuses = (
  projectId: string
): { holds: string[] | null; refresh: () => void } => {
  const [holds, setHolds] = useState<string[] | null>(null);
  const [refreshHolds, setRefreshHolds] = useState(true);

  const refresh = () => {
    setRefreshHolds(true);
  };

  useEffect(() => {
    // If no project id, exit early
    if (!projectId) {
      return;
    }

    // If refreshHolds === false, exit early
    if (!refreshHolds) {
      return;
    }

    async function _getHolds() {
      const querySnapshot = await firebase
        .firestore()
        .collection("postPLPHolds")
        .orderBy("createdAt", "desc")
        .where("archived", "==", false)
        .where("projectId", "==", projectId)
        .get();

      // If no postPLPHolds record, return null
      if (querySnapshot.empty) {
        return;
      }

      const doc = querySnapshot.docs[0];

      const data = doc.data();
      if (!data || !data.holdStatuses) {
        return;
      }

      setHolds(data.holdStatuses as string[]);
    }

    _getHolds();
    setRefreshHolds(false);
  }, [projectId, refreshHolds]);

  return { holds, refresh };
};

export { usePostPLPHoldStatuses };
