const dayDuration = 24 * 60 * 60 * 1000;

function skipWeekend(date: Date): Date {
  const day = date.getDay();

  if (day === 0) {
    return new Date(date.getTime() + dayDuration);
  } else if (day === 6) {
    return new Date(date.getTime() + dayDuration * 2);
  }

  return date;
}

export { skipWeekend };
