const getCleanedAssetLibraryOrderBy = (field: string, direction: string) => {
  if (field.indexOf(".") !== -1) {
    const split = field.split(".");
    return [
      {
        [split[0]]: { [split[1]]: direction },
      },
    ];
  }
  const orderBy = [{ [field]: direction }];

  if (field === "updated_at") {
    // updated_at is not unique enough, so we're adding a second sort
    // to guarantee lack of duplicates
    orderBy[0].id = "desc";
  }
  return orderBy;
};

export default getCleanedAssetLibraryOrderBy;
