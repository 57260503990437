import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";
import React, { FC, useEffect, useState } from "react";
import { Revision } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
export interface HideRevisionCheckboxProps extends BoxProps {
  revision: Revision;
}

const HideRevisionCheckbox: FC<HideRevisionCheckboxProps> = ({
  revision,
  ...containerProps
}) => {
  const [checked, setChecked] = useState<boolean>(
    // @ts-ignore
    !!(revision && revision["hiddenFromQa"])
  );

  // @ts-ignore
  useEffect(onRevisionChange, [revision && revision["hiddenFromQa"]]);

  if (!revision) {
    return null;
  }

  return (
    <Box {...containerProps}>
      <FormControlLabel
        label="Hide revision in Qa Tab"
        checked={checked}
        onChange={handleChange}
        control={<Checkbox />}
      ></FormControlLabel>
    </Box>
  );

  function onRevisionChange(): void {
    // @ts-ignore
    setChecked(!!(revision && revision["hiddenFromQa"]));
  }

  async function handleChange() {
    const prom = firebase
      .firestore()
      .collection("revisions")
      .doc(revision.id)
      .update({ hiddenFromQa: !checked });

    setChecked(!checked);

    await prom;
  }
};

export { HideRevisionCheckbox };
export default HideRevisionCheckbox;
