import * as React from "react";
import * as ReactDOM from "react-dom";
import AppRouter from "./AppRouter";
import "./index.css";
import { CssBaseline } from "@material-ui/core";
import { RevisionProvider } from "./util/RevisionContext";
import { UserProvider } from "./util/UserContext";
import { ProfileProvider } from "./util/ProfileContext";
import { SearchTextProvider } from "./util/SearchContext";
import { RecordsProvider } from "./util/RecordsContext";
import { AssignmentsProvider } from "./util/AssignmentsContext";
import FirebaseClientUtil from "@yardzen-inc/firebase-client-util";
import {
  FirebaseJwtProvider,
  FirebaseJwtApolloProvider,
} from "./util/contexts";
import "./util/firebase/init";
import { YZThemeProvider } from "@yardzen-inc/react-common";
import { ExteriorDesignProvider } from "./util/contexts/ExteriorDesignContext";
import firebase from "firebase/compat/app";
import { useCleanOnEnvChange } from "./util/useCleanOnEnvChange";
import { store } from "./state/store";
import { Provider } from "react-redux";
import { SplitProviderWrapper } from "./util/split/SplitProviderWrapper";
import { AppErrorBoundary } from "./util/errors/AppErrorBoundary";
import CrashPage from "./pages/CrashPage";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

FirebaseClientUtil.setUserClaimListener({
  autoSetWebRoles: true,
});

function App() {
  useCleanOnEnvChange();

  return (
    <AppErrorBoundary
      onError={(err) => {
        try {
          window?.newrelic?.noticeError(err, { "ErrorBoundary Exception": 1 });
        } catch (error) {
          console.error(error);
        }

        console.error(err);
      }}
      ErrorPageComponent={CrashPage}
    >
      <Provider store={store}>
        <YZThemeProvider>
          <CssBaseline>
            <FirebaseJwtProvider>
              <FirebaseJwtApolloProvider>
                <ExteriorDesignProvider
                  getBearerToken={() =>
                    firebase.auth().currentUser?.getIdToken() as Promise<string>
                  }
                >
                  <UserProvider>
                    <SplitProviderWrapper>
                      <ProfileProvider>
                        <RevisionProvider>
                          <SearchTextProvider>
                            <RecordsProvider>
                              <AssignmentsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <AppRouter />
                                </MuiPickersUtilsProvider>
                              </AssignmentsProvider>
                            </RecordsProvider>
                          </SearchTextProvider>
                        </RevisionProvider>
                      </ProfileProvider>
                    </SplitProviderWrapper>
                  </UserProvider>
                </ExteriorDesignProvider>
              </FirebaseJwtApolloProvider>
            </FirebaseJwtProvider>
          </CssBaseline>
        </YZThemeProvider>
      </Provider>
    </AppErrorBoundary>
  );
}

ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);

// unregister();
