import React, { FC } from "react";
import { Switch, Route } from "react-router-dom";
import { AssignmentPage } from "./AssignmentPage";
import { CDMQueue } from "./CDMQueue";
import { CircularProgress, Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { useCachedPm } from "../../util/hooks";
import "firebase/compat/firestore";

const CDM: FC<RouteComponentProps> = () => {
  const pm = useCachedPm();

  return (
    <>
      {!!pm && (
        <Switch>
          <Route
            path="/:routeName/:projectId/:assignmentId"
            render={(routeProps) => <AssignmentPage pm={pm} {...routeProps} />}
          />
          <Route
            path="/cdm"
            exact
            render={(routeProps) => <CDMQueue {...routeProps} pmId={pm.id} />}
          />
        </Switch>
      )}
      {pm === null && (
        <Box
          display="flex"
          flexDirection="row"
          p={2}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export { CDM };
export default CDM;
