import * as React from "react";

function usePrevious<t = any>(value: t, rememberFalsey?: boolean): t {
  const ref = React.useRef<t>(value);

  React.useEffect(() => {
    if ((value || rememberFalsey) && ref.current !== value) {
      ref.current = value;
    }
  });

  return ref.current;
}

export default usePrevious;
