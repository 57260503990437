import { DesignAssignment } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";

const getDesignAssignments = (
  projectId: string
): Promise<DesignAssignment[]> => {
  return new Promise((resolve, reject) => {
    try {
      firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("designAssignments")
        .onSnapshot(async (snap) => {
          if (snap.empty) {
            resolve([]);
            return;
          }
          if (snap.metadata.hasPendingWrites) {
            // don't do anything but don't resolve yet either.
            // auto-timeout will trigger if needed.
          } else {
            const hydrated = await DesignAssignment.hydrate(snap.docs);
            resolve(hydrated);
          }
          setTimeout(() => {
            // we'll timeout after 30 seconds.
            resolve([]);
          }, 30000);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export { getDesignAssignments };
