import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";

export interface AssetLibDownloadErrorModalProps extends DialogProps {
  statusCode?: string;
  statusMessage?: string;
  onClose: () => void;
}

const AssetLibDownloadErrorModal: FC<AssetLibDownloadErrorModalProps> = ({
  statusCode,
  statusMessage,
  onClose,
  ...props
}) => {
  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h4">Download Failed</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" p={1}>
          <Typography>
            Your location may be preventing you from accessing this file. Please
            try accessing this file using a VPN.{" "}
            <a
              href="https://yardzendesigners.slack.com/archives/C02G22H4LUE/p1646249801212299"
              target="_blank"
            >
              Click here
            </a>{" "}
            for details, and reach out on #liisa-help if this issue persists.
            Thank you!
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" p={1}>
          {renderStatusInformation()}
        </Box>
        <Box p={1} display="flex" justifyContent={"flex-end"}>
          <Button onClick={onClose}>OK</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );

  function renderStatusInformation() {
    return (
      <>
        {statusCode && <Typography>Status Code: {statusCode}</Typography>}
        {statusMessage && (
          <Typography>Status Message: {statusMessage}</Typography>
        )}
      </>
    );
  }
};

export { AssetLibDownloadErrorModal };
