import { useState, useEffect } from "react";
import {
  ProjectProperties,
  ProfileProperties,
  DesignAssignmentProperties,
  RevisionProperties,
} from "@yardzen-inc/models";
import moment from "moment";
import { sortByFlagAndDate } from "../../util/functions/sortByFlagAndDate";
import {
  getDesignAssignmentByRevisionId,
  getProfileById,
  getRevisionsByProfileId,
} from "../../util/firebase/firebaseClient";
import { listenOnProjects } from "./listenOnProjectSnapshot";

export type ProjectForQA = ProjectProperties & {
  id: string;
  revisionId?: string;
  materialsRecieved?: string | null;
  versionString?: string;
};

export interface UseProjectsUpForQa {
  (pmId: string): [null | ProjectForQA[], boolean];
}

const dateMapCache: { [key: string]: number } = {};

const useProjectsUpForQa: UseProjectsUpForQa = (pmId = "") => {
  const [projects, setProjects] = useState<ProjectForQA[] | null>(null);
  const [sortedProjects, setSortedProjects] = useState<ProjectForQA[] | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    onPmIdChange();
    listenOnProjects(pmId, setProjects);
  }, [pmId]);

  useEffect(() => {
    if (!projects) {
      //projects still loading
      return;
    }
    if (!projects?.length) {
      //projects empty
      setSortedProjects([]);
      setIsLoading(false);
      return;
    }
    getSortedFilteredProjects();
  }, [projects]);

  return [sortedProjects, isLoading];

  async function getSortedFilteredProjects(): Promise<void> {
    try {
      const sorted = await defaultProjectFilter(projects as ProjectForQA[]);
      setSortedProjects(sorted);
    } catch (e) {
      console.error(e, "There was an error sorting and filtering projects.");
    } finally {
      setIsLoading(false);
    }
  }

  // reset handler if project manager id or property changes
  function onPmIdChange(): void {
    setProjects(null);
    setSortedProjects(null);
    setIsLoading(true);
  }
};

// filters projects based on having a date submitted for Qa, but not a date sent to client
// batches relations fetchers to avoid out of resource http errors
async function defaultProjectFilter(
  projects: ProjectForQA[]
): Promise<ProjectForQA[]> {
  let filteredProjects: ProjectForQA[] = [];

  const filteredProjectFetchers: (() => Promise<ProjectForQA | null>)[] = [];

  // filter out obvious non matches and prep fetcher lambdas
  for (let proj of projects) {
    if (!proj.designAssignmentStatus) {
      continue;
    }

    filteredProjectFetchers.push(
      async function (): Promise<ProjectForQA | null> {
        const revisions = await getRevisionsByProfileId(proj.profileId);

        const docs = revisions.sort((docA, docB) => {
          const dataA = docA.data() as RevisionProperties;
          const dataB = docB.data() as RevisionProperties;
          const timeStampA = moment(dataA.dateCreated);
          const timeStampB = moment(dataB.dateCreated);

          return timeStampB.unix() - timeStampA.unix();
        });

        for (const revisionDoc of docs) {
          const data = revisionDoc.data() as RevisionProperties;
          const profile = await getProfileProps(proj.profileId);

          if (!data) {
            continue;
          }

          if (
            data.id === profile?.conceptualRevision ||
            data.id === profile?.finalRevision
          ) {
            return null;
          }

          if (
            (data.dateSubmittedForReview || data.designAnnotated) &&
            !data["dateOriginallySentToClient"] &&
            !data.dateSentToClient &&
            !data.v1Blurb &&
            !data.finalBlurb &&
            // @ts-ignore
            !data["hiddenFromQa"]
          ) {
            const designAssignment = await getDesignAssignmentFromRevision(
              revisionDoc.id
            );

            let versionString: string;

            if (!designAssignment) versionString = "?";
            else versionString = getVersionString(designAssignment);

            return {
              ...proj,
              materialsRecieved: profile?.wizardDone ?? null,
              versionString,
              revisionId: revisionDoc.id,
            };
          }
        }

        return null;
      }
    );

    // batch fetching of relations in groups of ten to avoid insufficient resource errors
  }

  const batchSize = 50;
  let iterations!: number;

  do {
    iterations = Math.min(filteredProjectFetchers.length, batchSize);

    const executors: (() => Promise<ProjectForQA | null>)[] = [];

    for (let i = 0; i < iterations; i += 1) {
      const fetcher = filteredProjectFetchers.pop();

      if (!fetcher) {
        break;
      }

      executors.push(fetcher);
    }

    filteredProjects.push(
      ...((await Promise.all(executors.map((f) => f()))).filter(
        (res) => !!res
      ) as ProjectForQA[])
    );

    if (iterations < batchSize) {
      break;
    }
  } while (!!filteredProjectFetchers.length);

  return sortProjects(filteredProjects);
}

async function getProfileProps(
  profileId: string
): Promise<(ProfileProperties & { id: string }) | null> {
  const data = await getProfileById(profileId);

  if (!data) {
    console.error(`profile with id ${profileId} seems to have no data`);
    return null;
  }

  return { ...(data as ProfileProperties), id: profileId };
}

function sortProjects(projects: ProjectForQA[]): ProjectForQA[] {
  return projects.sort((a, b) => {
    return sortByFlagAndDate(
      {
        flag: a.isExpedited ?? false,
        date: getISODate(a.materialsRecieved ?? ""),
      },
      {
        flag: b.isExpedited ?? false,
        date: getISODate(b.materialsRecieved ?? ""),
      }
    );
  });
}

function getISODate(date: string): number {
  const cachedDate = dateMapCache[date];

  if (!cachedDate) {
    const unix = moment(date).unix();
    dateMapCache[date] = unix;
    return unix;
  }

  return cachedDate;
}

function getVersionString(assignment: DesignAssignmentProperties): string {
  if (
    !assignment.type ||
    (assignment.type === "correction" && !assignment.correctionFor)
  )
    return "?";
  if (assignment.type === "correction") {
    const correctionFor =
      assignment.correctionFor === "extra" ? "V3+" : assignment.correctionFor;
    return `${correctionFor}C`;
  } else if (assignment.type === "extra") return "V3+";
  else return assignment.type.toUpperCase();
}

async function getDesignAssignmentFromRevision(
  revisionId?: string | null
): Promise<(DesignAssignmentProperties & { id: string }) | null> {
  if (!revisionId) return null;

  const doc = await getDesignAssignmentByRevisionId(revisionId);

  if (!doc) return null;

  const data = doc.data();

  if (!data) return null;

  const designAssignment = {
    ...(data as DesignAssignmentProperties),
    id: doc.id,
  };

  return designAssignment;
}

export { useProjectsUpForQa };
