import {
  Box,
  Button,
  Dialog,
  DialogContent,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";
import { Profile } from "@yardzen-inc/models";
import { YZTypography } from "@yardzen-inc/react-common";
import * as React from "react";
import GenericSnackBar from "../../../Components/GenericSnackBar";
import { submitClientOnboardingMaterials } from "../../../util/firebase/functions/submitClientOnboardingMaterials";
import { useUser } from "../../../util/hooks";
import { OverrideReasonRadioSelect } from "./OverrideReasonRadioSelect/OverrideReasonRadioSelect";
import { SubmitMaterialsForClientModalButtonGroup } from "./SubmitMaterialsForClientModalButtonGroup/SubmitMaterialsForClientModalButtonGroup";

interface ISubmitMaterialsForClientModalProps {
  clientProfile: Profile;
}

const overrideReasons = [
  "Materials are complete",
  "Materials are not complete but are sufficient for design",
  "New Build",
  "Other",
];

const SubmitMaterialsForClientModal: React.FunctionComponent<ISubmitMaterialsForClientModalProps> =
  ({ clientProfile }) => {
    const [userError, user] = useUser();
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
    const [overrideReason, setOverrideReason] = React.useState<string>("");
    const [working, setWorking] = React.useState<boolean>(false);
    const [overrideReasonCustomText, setOverrideReasonCustomText] =
      React.useState<string>("");
    const [result, setResult] = React.useState<"success" | "error" | null>(
      null
    );

    return (
      <>
        <GenericSnackBar
          in={!!result}
          message={
            result === "success"
              ? "Successfully submitted client materials"
              : "Error submitting materials"
          }
          onClose={() => setResult(null)}
          variant={result || "success"}
        />
        <Dialog open={dialogOpen} onClose={handleClose}>
          <DialogContent>
            <Box>
              <Box textAlign="center">
                <YZTypography variant="h4">
                  Why are you issuing this correction?
                </YZTypography>
              </Box>
              <br />
              <OverrideReasonRadioSelect
                overideReasonOptions={overrideReasons}
                overrideReason={overrideReason}
                overrideReasonCustomText={overrideReasonCustomText}
                setOverrideReason={setOverrideReason}
                setOverrideReasonCustomText={setOverrideReasonCustomText}
                disabled={working}
              />
              <SubmitMaterialsForClientModalButtonGroup
                onCancel={handleClose}
                onSubmit={submitClientMaterials}
                submitDisabled={working || !readyToSubmit()}
              />
              <Box mt={1}>{working && <LinearProgress />}</Box>
            </Box>
          </DialogContent>
        </Dialog>
        <Box m={2}>
          <Tooltip
            title={
              clientProfile.wizardDone
                ? "Client materials already submitted"
                : "Submit onboarding materials for clien"
            }
          >
            <span>
              <Button
                disabled={!!clientProfile.wizardDone}
                variant="outlined"
                color="default"
                onClick={() => setDialogOpen(true)}
              >
                Submit Materials
              </Button>
            </span>
          </Tooltip>
        </Box>
      </>
    );

    async function submitClientMaterials() {
      setWorking(true);

      try {
        await submitClientOnboardingMaterials(
          compileSubmitClientOnboardMaterialsArguments()
        );

        setResult("success");
      } catch (error) {
        console.log("Error submitting client materials: ", error);

        setResult("error");
      } finally {
        setWorking(false);
      }
    }

    function compileSubmitClientOnboardMaterialsArguments() {
      return {
        profileId: clientProfile.id || clientProfile.userId,
        materialsRequirementOverride: {
          overridePerformedByAgentId:
            user && !userError ? user.uid : "Error retrieving uid",
          detailedOverrideReason:
            overrideReason === "Other"
              ? overrideReason + ": " + overrideReasonCustomText.trim()
              : overrideReason,
        },
      };
    }

    function handleClose() {
      if (working) {
        return;
      }

      setDialogOpen(false);
    }

    function readyToSubmit(): boolean {
      if (overrideReason === "Other") {
        return !!overrideReason && !!overrideReasonCustomText;
      }

      return !!overrideReason;
    }
  };

export { SubmitMaterialsForClientModal };
