import { useEffect, useState } from "react";

const usePageDimensions = (
  delay: number = 100,
  deps: any[] = [],
  keepFullScreenHeight: boolean = false
): [number, number] => {
  const [dims, setDims] = useState<[number, number]>(getDimensions);

  useEffect(listenForResize, [delay]);
  useEffect(resizeOnDepChange, [...deps, keepFullScreenHeight]);

  return dims;

  function listenForResize() {
    let timeout: any = null;
    let currentEvent: UIEvent | null;

    window.addEventListener("resize", resizeHandler);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
        currentEvent = null;
      }
      window.removeEventListener("resize", resizeHandler);
    };

    function resizeHandler(ev: UIEvent) {
      if (timeout) {
        clearTimeout(timeout);
      }

      currentEvent = ev;

      setTimeout(() => {
        if (currentEvent !== ev) {
          return;
        }

        _setDims();

        clearTimeout(timeout);
      }, delay);
    }
  }

  function _setDims() {
    const newDims = getDimensions();

    if (!(dims[0] === newDims[0] && dims[1] === newDims[1])) {
      if (keepFullScreenHeight) {
        const [w, h] = newDims;
        return setDims([w, Math.max(h, dims[1])]);
      }

      setDims(newDims);
    }
  }

  function resizeOnDepChange() {
    _setDims();
  }
};

function getDimensions(): [number, number] {
  return [
    window.innerWidth,

    Math.min(window.innerHeight, window.screen.availHeight ?? 0),
  ];
}

export { usePageDimensions };
export default usePageDimensions;
