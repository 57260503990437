import React, { FC, useState, useEffect, useRef, useMemo } from "react";
import {
  Box,
  TextField,
  CircularProgress,
  Typography,
  Button,
} from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";
import { ProjectManagerProperties } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export interface ProjectManagerSelectProps extends BoxProps {
  pm?: ProjectManagerProperties;
  setPmId: (val: string) => void;
  showPmNameWhenClosed?: boolean;
}

const ProjectManagerSelect: FC<ProjectManagerSelectProps> = ({
  pm,
  setPmId,
  showPmNameWhenClosed,
  ...containerProps
}) => {
  const [value, setValue] = useState<string>("");
  const [projectManagers, setProjectManagers] = useState<
    | Promise<(ProjectManagerProperties & { id: string })[]>
    | (ProjectManagerProperties & { id: string })[]
  >(() => getProjectManagers());
  const [suggested, setSuggested] = useState<
    (ProjectManagerProperties & { id: string })[]
  >([]);
  const [searching, setSearching] = useState<boolean>(false);
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [popoverFocused, setPopoverFocused] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);

  const buttonTrackingRef = useRef<{ [key: string]: boolean }>({});

  const numberOfSuggested = useMemo(() => suggested.length, [suggested]);
  useEffect(handleProjectManagerChange, [projectManagers]);
  useEffect(handleValueChange, [value, projectManagers]);

  return (
    <>
      <div ref={containerRef}>
        <Box
          bgcolor="whiteSmoke"
          p={1}
          boxShadow={2}
          width="300px"
          maxWidth="100%"
          display="flex"
          flexDirection="row"
          {...containerProps}
        >
          <Box flexGrow={1}>
            <TextField
              ref={inputRef}
              value={value}
              onFocus={() => setInputFocused(true)}
              autoFocus
              onBlur={() => setTimeout(() => setInputFocused(false))}
              fullWidth
              placeholder="Change selected pm"
              onChange={({ currentTarget: { value } }) => setValue(value)}
              helperText={
                pm ? `currently selected: ${pm.firstName} ${pm.lastName}` : ""
              }
            />
          </Box>
          <Box pl={1}>
            <CircularProgress
              style={{
                transition: "opacity 300ms",
                opacity: !!(searching || !projectManagers) ? 1 : 0,
              }}
            />
          </Box>
        </Box>
      </div>
      {!!(
        containerRef.current &&
        !!(
          inputFocused ||
          popoverFocused ||
          !!Object.values(buttonTrackingRef).find((v) => v === true)
        )
      ) && (
        <Box
          zIndex={3}
          boxShadow={2}
          bgcolor="whitesmoke"
          style={{
            width: "300px",
            maxWidth: "100vw",
            position: "fixed",
            ...getPopupPosition(),
          }}
        >
          {!!numberOfSuggested ? (
            suggested.map((s) => {
              const key = `pb-${s.id}`;
              return (
                <Button
                  key={key}
                  variant="text"
                  onFocus={() => {
                    buttonTrackingRef.current[key] = true;
                    setTimeout(() => {
                      setPopoverFocused(true);
                    });
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      buttonTrackingRef.current[key] = false;
                      setPopoverFocused(false);
                    }, 200);
                  }}
                  style={{ width: "300px", maxWidth: "100vw" }}
                  onMouseDown={() => handleSubmit(s.id)}
                  onClick={() => handleSubmit(s.id)}
                >{`${s.firstName} ${s.lastName}`}</Button>
              );
            })
          ) : (
            <Box p={2}>
              <Typography style={{ width: "300px", maxWidth: "100vw" }}>
                No Search Results
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );

  function getPopupPosition(): React.CSSProperties {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      return {
        top: rect.top + rect.height - window.scrollY,
        left: "rect.left",
      };
    }

    return {};
  }

  // force input unfocus
  function unfocus() {
    if (inputRef.current) inputRef.current.blur();
  }

  // ensure that input and popover are closed and unfocused after submitting
  function handleSubmit(id: string): void {
    console.log("submit");
    setPmId(id);
    unfocus();
  }

  // Debounce change events and run get Suggestions on no events for 500ms
  function handleValueChange(): void | (() => void) {
    if (!value) {
      return setSuggested([]);
    }

    const timeout = setTimeout(() => {
      getSuggestions();
    }, 200);

    return () => clearTimeout(timeout);
  }

  // await resolution of project managers promise and set result to state
  function handleProjectManagerChange(): void {
    void (async function () {
      if (projectManagers instanceof Promise) {
        setProjectManagers(await projectManagers);
      }
    })();
  }

  // asynchronously filter project managers by value and set to state
  function getSuggestions(): void {
    void (async function () {
      setSearching(true);

      try {
        const pms = (await projectManagers).map((pm) => ({
          pm,
          searchString: `${pm.firstName} ${pm.lastName} ${pm.email}`
            .trim()
            .toLowerCase(),
        }));

        const terms = value.split(" ");

        setSuggested(
          pms
            .filter(({ searchString }) => {
              return !terms.find(
                (t) => !searchString.includes(t.trim().toLowerCase())
              );
            })
            .map(({ pm }) => pm)
        );
      } catch (error) {
        console.error(error);
      } finally {
        setSearching(false);
      }
    })();
  }

  // pulls in master list of project managers
  async function getProjectManagers(): Promise<
    (ProjectManagerProperties & { id: string })[]
  > {
    const projectSnap = await firebase
      .firestore()
      .collection("projectManagers")
      .get();

    return projectSnap.docs
      .map((doc) => ({
        ...(doc.data() as ProjectManagerProperties),
        id: doc.id,
      }))
      .filter((pm) => !!pm);
  }
};

export { ProjectManagerSelect };
export default ProjectManagerSelect;
