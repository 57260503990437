import { Revision } from "@yardzen-inc/models";

export type DesignPhase = "upload" | "qa" | "clientReady";

export const getPhase = (r: Revision): DesignPhase => {
  // TODO: implement conditional logic that determines revision phase

  return "upload";
};

export default DesignPhase;
