import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";

export interface BudgetOrderCopySlabProps {}

const BudgetOrderCopySlab: FC<BudgetOrderCopySlabProps> = (props) => {
  return (
    <Box p={1}>
      <Box p={2} bgcolor="whitesmoke">
        <Typography>
          These elements are listed in the order of the client’s priorities. The
          30% labor cost is already included in prices.
        </Typography>
      </Box>
    </Box>
  );
};

export { BudgetOrderCopySlab };
export default BudgetOrderCopySlab;
