import * as React from "react";
import firebase from "firebase/compat/app";
import { Media } from "@yardzen-inc/models";
import {
  makeStyles,
  List,
  ListItem,
  Typography,
  CircularProgress,
} from "@material-ui/core";

interface Props {
  match: {
    params: any;
  };
}

function BriefMaterials(props: Props) {
  const classes = useStyles();
  const { profileID } = props.match.params;
  const [media, setMedia]: [Array<Media>, CallableFunction] = React.useState(
    []
  );
  const [clientRecord, setClientRecord]: [any, any] = React.useState({});

  React.useEffect(() => {
    loadMedia();
    loadClientRecord();
  }, []);

  if (!media.length) {
    return (
      <div style={{ margin: "auto", width: "20%", marginTop: "2rem" }}>
        <Typography variant="h5">No design briefs found</Typography>
        <Typography>
          Have the files been uploaded under the "Design Brief" media tab?
        </Typography>
      </div>
    );
  }

  if (!!media.length && !!Object.keys(clientRecord).length) {
    return (
      <div>
        <div className={classes.textContainer}>
          <List>
            <Typography variant="h6">Elements:</Typography>
            {Object.entries(clientRecord.elements).map((el) => {
              if (el[1]) {
                return <ListItem key={el[0]}>{el[0]}</ListItem>;
              }
              return null;
            })}
          </List>
          <List>
            <Typography variant="h6">Questionaire answers:</Typography>
            {answerFields.map((field: any) => {
              if (clientRecord[field]) {
                return (
                  <ListItem>
                    {field}: {clientRecord[field]}
                  </ListItem>
                );
              }
              return null;
            })}
          </List>
        </div>
        {media.map((record) => {
          return (
            <div key={record.id} className={classes.imgContainer}>
              <img
                className={classes.image}
                src={record.downloadURL}
                alt="video annotation"
              />
            </div>
          );
        })}
      </div>
    );
  }
  return <CircularProgress />;

  async function loadMedia() {
    const records = await Media.get(profileID, null, "brief");
    setMedia(records);
  }

  async function loadClientRecord() {
    const db = firebase.firestore();
    const profilesRef = db.collection("profiles");

    profilesRef
      .doc(profileID)
      .get()
      .then((resp: any) => {
        setClientRecord(resp.data());
      });
  }
}

const useStyles = makeStyles({
  imgContainer: { maxWidth: "100%", margin: "0.5rem" },
  image: {
    maxWidth: "100%",
  },
  textContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
});

const answerFields = [
  "priority1",
  "priority2",
  "priority3",
  "priority4",
  "priority5",
  "answer2",
  "answer3",
];

export default BriefMaterials;
