import React, { FC, useEffect, useState } from "react";

// Import MUI Components
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Theme,
  Tooltip,
  Fab,
} from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import AddIcon from "@material-ui/icons/Add";
import AgentPodsTableRow from "./AgentPodsTableRow";
import CreateAgentPodModal from "./CreateAgentPodModal";
import { getAllDesignerPodsData } from "./apiCalls";
import { AgentPods } from "./types";
import TableToolbar from "./TableToolbar";
import EditPodAssignmentBatchModal from "./EditPodAssignmentBatchModal";
import TableSkeletonLoadRow from "./TableSkeletonLoadRow";

const useStyles = makeStyles((theme: Theme) => ({
  podsComponentContainer: {
    marginTop: 30,
  },
  addButtonContainer: {
    marginTop: 30,
    marginRight: 10,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const AgentPodsContainer: FC = () => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [pods, setPods] = useState<AgentPods[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);
  const [checkedAgents, setCheckedAgents] = useState<string[]>([]);
  const [editPodAssignmentBatchModalOpen, setEditPodAssignmentBatchModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (!loading) return;
    setPods(null);
    setCheckedAgents([]);
    const fetchData = async () => {
      try {
        let response = await getAllDesignerPodsData(true);
        response = [
          ...response,
          {
            id: null,
            name: "Unassigned",
            pmName: "",
            projectManagerId: "",
          },
        ];
        setPods(response);
      } catch (err) {
        parseErrors(err);
      }
      setLoading(false);
    };

    fetchData();
  }, [loading]);

  return (
    <div className={classes.podsComponentContainer}>
      {renderError()}
      <Paper>
        <TableToolbar
          numSelected={checkedAgents.length}
          handleChangePodClick={() => setEditPodAssignmentBatchModalOpen(true)}
          handleClearSelectionClick={handleClearSelectionClick}
        />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            {renderLoading()}
            {renderPods()}
          </Table>
        </TableContainer>
      </Paper>
      <EditPodAssignmentBatchModal
        open={editPodAssignmentBatchModalOpen}
        onSubmit={() => setLoading(true)}
        onClose={() => setEditPodAssignmentBatchModalOpen(false)}
        checkedAgents={checkedAgents}
        pods={pods ?? []}
      />
      <div className={classes.addButtonContainer}>
        <Tooltip title="Create New Team" aria-label="add">
          <Fab color="primary" onClick={() => setModalOpen(true)}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
      <CreateAgentPodModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={() => setLoading(true)}
      />
    </div>
  );

  function renderLoading() {
    if (loading) {
      return <TableSkeletonLoadRow rows={3} />;
    }
    return;
  }

  function renderPods() {
    if (pods && pods.length === 0) {
      return <YZTypography>No pods found.</YZTypography>;
    }
    if (pods && pods.length > 0) {
      return (
        <>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="left">Team Name</TableCell>
              <TableCell align="left">Team PM</TableCell>
              <TableCell align="left">Edit Team</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pods &&
              pods.map((rowData) => (
                <AgentPodsTableRow
                  onSubmit={() => setLoading(true)}
                  key={rowData.name}
                  rowData={rowData}
                  checkedAgents={checkedAgents}
                  setAllSelectedAgent={setAllSelectedAgent}
                />
              ))}
          </TableBody>
        </>
      );
    }
    return;
  }

  function renderError() {
    if (error) {
      return (
        <YZTypography>
          Internal error. Please try refreshing the page. If the issue persists,
          contact engineering.
        </YZTypography>
      );
    }
    return;
  }

  function parseErrors(err: any): void {
    if (err.details?.code === "000012") {
      setPods([
        {
          id: null,
          name: "Unassigned",
          pmName: "",
          projectManagerId: "",
        },
      ]);
    } else {
      setError(true);
    }
  }

  function setAllSelectedAgent(agentArray: string[]) {
    setCheckedAgents(agentArray);
  }

  function handleClearSelectionClick() {
    setCheckedAgents([]);
  }
};

export { AgentPodsContainer };
export default AgentPodsContainer;
