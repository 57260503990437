import { Media } from "@yardzen-inc/models";

export default (media: Media[]): Media[] => {
  const mediaToSort = media;
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });

  const sortedMedia = mediaToSort.sort((a, b) =>
    collator.compare(a.originalFileName, b.originalFileName)
  );

  return sortedMedia;
};
