import firebase from "firebase/compat/app";
import { ErrorWithHumanMessage } from "../../errors/ErrorWithHumanMessage";
import { useEffect, useState } from "react";

const getDesignAssignmentIdFromRevisionId = async (
  revisionId: string
): Promise<string> => {
  let oldDesignAssignmentSnapshot: firebase.firestore.QuerySnapshot;
  try {
    oldDesignAssignmentSnapshot = await firebase
      .firestore()
      .collectionGroup("designAssignments")
      .where("revisionId", "==", revisionId)
      .get();
  } catch (error) {
    throw new ErrorWithHumanMessage(
      error as Error,
      "Failed to get design assignment id from revision id"
    );
  }

  if (!oldDesignAssignmentSnapshot) {
    throw new Error(
      `Failed to get design assignment snpashot from revision id: ${revisionId}`
    );
  }

  if (oldDesignAssignmentSnapshot.docs.length > 1) {
    throw new Error(
      `Multiple designAssignments found for revision ${revisionId}`
    );
  } else if (oldDesignAssignmentSnapshot.docs.length === 0) {
    throw new Error(`No designAssignments found for revision ${revisionId}`);
  }

  return oldDesignAssignmentSnapshot.docs[0].id;
};

export const useGetDesignAssignmentIdFromRevisionId = (
  revisionId?: string
): {
  id?: string;
  isLoading: boolean;
  error?: any;
} => {
  const [id, setId] = useState<string | undefined>();
  const [error, setError] = useState<any>();

  useEffect(() => {
    void (async function () {
      if (revisionId) {
        try {
          if (error) {
            setError(undefined);
          }

          const id = await getDesignAssignmentIdFromRevisionId(revisionId);
          setId(id);
        } catch (error) {
          if (
            /No designAssignments found/.test((error as any)?.message ?? "")
          ) {
            setId(undefined);
          } else {
            setError(error);
          }
        }
      }
    })();
  }, [revisionId]);

  return {
    id,
    isLoading: !id && !error,
    error,
  };
};

export default getDesignAssignmentIdFromRevisionId;
