import * as React from "react";
import { Profile } from "@yardzen-inc/models";
import NewMediaGrid, {
  bigCardDefaultProps,
} from "../../Components/NewMediaGrid";
import { UserCtx } from "../../util/UserContext";
import { LinearProgress, Container } from "@material-ui/core";

interface Props {
  clientRecord: Profile;
  tag: string;
}

export default (props: Props) => {
  const { clientRecord } = props;
  const user = React.useContext(UserCtx);

  if (!user || !clientRecord) {
    return <LinearProgress variant="indeterminate" />;
  }

  return (
    <>
      <Container style={{ paddingTop: "1rem" }}>
        <NewMediaGrid
          cardType="withTextField"
          userId={clientRecord.id}
          profile={clientRecord}
          readonly={
            !!(user && user.email && !user.email.includes("yardzen.co"))
          }
          media={[props.tag]}
          disableThumbnail={true}
          {...bigCardDefaultProps}
        />
      </Container>
    </>
  );
};
