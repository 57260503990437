import { useEffect } from "react";
import environmentConstants from "../../ConstantValues/environmentConstants";

const useLogError = (
  error: Error | null,
  throwsError: boolean = false,
  devOnly: boolean = false
) => {
  useEffect(logError);

  function logError(): void {
    if (error) {
      if (
        devOnly &&
        !(process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT)
      ) {
        return;
      }

      if (throwsError) {
        throw error;
      }

      console.error(error);
    }
  }
};

export { useLogError };
export default useLogError;
