import firebase from "firebase/compat/app";
import axios, { AxiosResponse } from "axios";
import { HARDINESS_ZONE_BY_ZIP_CODE } from "@yardzen-inc/graphql";

/*
  TODO: Use google maps geocoding api to backfill zip codes to increase success
*/
async function getUSDAZone(zipCode: string) {
  const token = await firebase.auth().currentUser?.getIdToken();
  let result: AxiosResponse;
  try {
    result = await axios.post(
      process.env.REACT_APP_HASURA_ENDPOINT ?? "",
      {
        query: HARDINESS_ZONE_BY_ZIP_CODE.loc?.source.body,
        variables: { zipCode },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw new Error(
      `Failed to fetch USDA zone for ZIP code ${zipCode}. ${error}`
    );
  }

  const { data: response } = result;
  if (response.data.zipcode_hardiness_zone.length === 0) {
    throw new Error(
      `Failed to fetch USDA zone for ZIP code ${zipCode}. Error: ZIP code not found`
    );
  }

  return response.data.zipcode_hardiness_zone[0].zone;
}

export { getUSDAZone };
