import * as React from "react";
import { makeStyles } from "@material-ui/core";
import { Media } from "@yardzen-inc/models";
import downloadAndZip from "../util/downloadAndZip";
import { NewMediaGridCardType } from "../Components/NewMediaGrid";
import NewMediaGrid from "../Components/NewMediaGrid";
import { YZButton } from "@yardzen-inc/react-common";

interface Props {
  media: Media[];
  video?: boolean;
  setLoading: (y: boolean) => void;
  hideDownload?: boolean;
  noEmptyMessage?: boolean;
  cardType?: NewMediaGridCardType;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-evenly",
    marginTop: "1rem",
  },
  topBar: {
    display: "flex",
    flexFlow: "row-reverse nowrap",
    padding: 8,
  },
  column: {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: 1,
  },
  imageContainer: {
    margin: "1rem",
  },
});

export default (props: Props) => {
  const classes = useStyles();

  // React.useEffect(() => {
  //   props.setLoading(true);

  //   const urls: string[] = [];

  //   for (let m of props.media) {
  //     urls.push(m.downloadURL);
  //   }

  //   setUrls(urls);
  //   props.setLoading(false);
  // }, [props.media]);

  // if (props.video) {
  //   return <div className={classes.root}>{renderColumns()}</div>;
  // }

  return (
    <>
      {!props.hideDownload && (
        <div className={classes.topBar}>
          <YZButton
            variant="outlined"
            onClick={() => downloadAndZip(props.media as any[], "media")}
          >
            Download
          </YZButton>
        </div>
      )}
      {renderGrid()}
    </>
  );

  function renderGrid() {
    if (props.noEmptyMessage && !props.media.length) {
      return null;
    }

    return (
      <NewMediaGrid
        cardType={props.cardType || "readOnly"}
        readonly
        media={props.media}
        downloadable
      />
    );
  }
};
