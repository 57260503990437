import * as React from "react";
import {
  TableCell,
  TableRow,
  makeStyles,
  IconButton,
  Dialog,
  DialogContent,
  Box,
} from "@material-ui/core";
import { ApolloQueryResult } from "@yardzen-inc/graphql";
import {
  AssetLibraryRowType,
  IPlantBasePlantAdaptedHabitatLink,
  IPlantBasePlantCadSizeLink,
  IPlantBasePlantCadSpacingLink,
  IPlantBasePlantContainerSizeLink,
  IPlantBasePlantFlowerColorLink,
  IPlantBasePlantFruitColorLink,
  IPlantBasePlantGardenStyleLink,
  IPlantBasePlantGrowthHabitLink,
  IPlantBasePlantHeightClassLink,
  IPlantBasePlantKeyFeatureLink,
  IPlantBasePlantLeafColorLink,
  IPlantBasePlantLightNeedsLink,
  IPlantBasePlantMatureSizeLink,
  IPlantBasePlantNativeRangeLink,
  IPlantBasePlantRedFlagLink,
  IPlantBasePlantTypeLink,
  IPlantBasePlantUsdaZoneLink,
  IPlantBasePlantVipLink,
  IPlantBasePlantWidthClassLink,
  ModelFiletype,
} from "../../Interfaces";
import getAssetLibraryRow from "../../EmployeeView/AssetLibraries/getRowUtil";
import QuantityInput from "./QuantityInput";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { formatNumToUSD, YZTypography } from "@yardzen-inc/react-common";
import cleanedDownloadableFileName from "../../EmployeeView/AssetLibraries/Shared/CleanedDownloadableFileName";
import { Close } from "@material-ui/icons";
import PopupTableItem from "./Shared/PopupTableItem";
import { ModelFileDownloadButton } from "../ModelFileDownloadButton";

interface Props {
  isEven: boolean;
  refetch:
    | ((
        variables?: Partial<Record<string, any>> | undefined
      ) => Promise<ApolloQueryResult<any>>)
    | undefined;
  row: AssetLibraryRowType[][];
  onUpdateQuantity: (id: string, quantity: number) => void;
  idAndQuantityMap: Object;
}

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  itemBtns: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  iconUri: {
    maxWidth: "10rem",
  },
  editItems: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },
  editItem: {
    flex: 1,
    marginBottom: theme.spacing(1),
  },
  saveProgress: {
    display: "flex",
    alignItems: "center",
  },
}));

function PlantSelectionTableRow(props: Props) {
  const classes = useRowStyles();

  const row = getAssetLibraryRow(props.row);

  const [open, setOpen] = React.useState(false);
  // @ts-ignore
  const quantity = props.idAndQuantityMap[row.id] || 0;
  const onUpdateQuantity = (qty: number) => {
    props.onUpdateQuantity(row.id, qty);
  };

  const onExpand = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <Box
          display="flex"
          alignItems="center"
          style={{ padding: "0.5rem 0.5rem 0.5rem 1rem" }}
        >
          <YZTypography variant="h6" style={{ flex: "1" }}>
            {row.scientific_name}
          </YZTypography>
          <IconButton onClick={() => handleClose()}>
            <Close style={{ fontSize: 17 }} />
          </IconButton>
        </Box>
        <DialogContent>
          <PopupTableItem title="Image">
            <img className={classes.iconUri} src={row.icon_uri} />
          </PopupTableItem>
          <PopupTableItem title="Scientific Name">
            {row.scientific_name}
          </PopupTableItem>
          <PopupTableItem title="Common Name">{row.common_name}</PopupTableItem>
          <PopupTableItem title="Planting Style">
            {row.plant_base_plant_garden_style_links
              ?.map(
                (style: IPlantBasePlantGardenStyleLink) =>
                  style.plant_garden_style.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="USDA ZONE">
            {row.plant_base_plant_usda_zone_links
              ?.map(
                (style: IPlantBasePlantUsdaZoneLink) =>
                  style.plant_usda_zone.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="GEO">
            {row.plant_base_adapted_habitat_links
              ?.map(
                (style: IPlantBasePlantAdaptedHabitatLink) =>
                  style.plant_adapted_habitat.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="VIP">
            {row.plant_base_plant_vip_links
              ?.map((style: IPlantBasePlantVipLink) => style.plant_vip.name)
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Key Feature">
            {row.plant_base_plant_key_feature_links
              ?.map(
                (style: IPlantBasePlantKeyFeatureLink) =>
                  style.plant_key_feature.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Type">
            {row.plant_base_plant_type_links
              ?.map((style: IPlantBasePlantTypeLink) => style.plant_type.name)
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Light Needs">
            {row.plant_base_plant_light_needs_links
              ?.map(
                (style: IPlantBasePlantLightNeedsLink) =>
                  style.plant_light_needs.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Water Needs">
            {row.waterNeedsByPlantWaterNeeds?.name}
          </PopupTableItem>
          <PopupTableItem title="Mature Width">
            {row.matureWidthByPlantMatureWidth?.name}
          </PopupTableItem>
          <PopupTableItem title="Mature Height">
            {row.matureHeightByPlantMatureHeight?.name}
          </PopupTableItem>
          <PopupTableItem title="Minimum Space Needed to Plant">
            {row.minimumSpaceToPlantByPlantminimumSpaceToPlant?.name}
          </PopupTableItem>
          <PopupTableItem title="Height Class">
            {row.plant_base_plant_height_class_links
              ?.map(
                (style: IPlantBasePlantHeightClassLink) =>
                  style.plant_height_class.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Width Class">
            {row.plant_base_plant_width_class_links
              ?.map(
                (style: IPlantBasePlantWidthClassLink) =>
                  style.plant_width_class.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Mature Size">
            {row.plant_base_plant_mature_size_links
              ?.map(
                (style: IPlantBasePlantMatureSizeLink) =>
                  style.plant_mature_size.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Red Flag">
            {row.plant_base_plant_red_flag_links
              ?.map(
                (style: IPlantBasePlantRedFlagLink) => style.plant_red_flag.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Red Flag Info">
            {row.red_flag_info}
          </PopupTableItem>
          <PopupTableItem title="Deciduous or Evergreen">
            {row.deciduousOrEvergreenByPlantDeciduousOrEvergreen?.name}
          </PopupTableItem>
          <PopupTableItem title="Retailer">
            {row.retailerByPlantRetailer?.name}
          </PopupTableItem>
          <PopupTableItem title="Leaf Color">
            {row.plant_base_plant_leaf_color_links
              ?.map(
                (style: IPlantBasePlantLeafColorLink) =>
                  style.plant_leaf_color.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Flower Color">
            {row.plant_base_plant_flower_color_links
              ?.map(
                (style: IPlantBasePlantFlowerColorLink) =>
                  style.plant_flower_color.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Bloom Time">
            {row.plant_bloom_time}
          </PopupTableItem>
          <PopupTableItem title="Fruit Color">
            {row.plant_base_plant_fruit_color_links
              ?.map(
                (style: IPlantBasePlantFruitColorLink) =>
                  style.plant_fruit_color.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Additional Info">
            <a href={row.additional_info} target="_blank">
              {row.additional_info}
            </a>
          </PopupTableItem>
          <PopupTableItem title="Native Range">
            {row.plant_base_plant_native_range_links
              ?.map(
                (style: IPlantBasePlantNativeRangeLink) =>
                  style.plant_native_range.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Price">
            <>
              {row.unit_cost ? formatNumToUSD(parseFloat(row.unit_cost)) : "--"}
            </>
          </PopupTableItem>
          <PopupTableItem title="Growth Habit">
            {row.plant_base_plant_growth_habit_links
              ?.map(
                (style: IPlantBasePlantGrowthHabitLink) =>
                  style.plant_growth_habit.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Sketchup File" ellipsis>
            <ModelFileDownloadButton
              filetype={ModelFiletype.sketchup}
              filenameOrUrl={row.sketchup_file_link}
              downloadableFileName={cleanedDownloadableFileName(
                row.scientific_name
              )}
            />
          </PopupTableItem>
          <PopupTableItem title="Lumion File" ellipsis>
            <ModelFileDownloadButton
              filetype={ModelFiletype.lumion}
              filenameOrUrl={row.lumion_file_link}
              downloadableFileName={cleanedDownloadableFileName(
                row.scientific_name
              )}
            />
          </PopupTableItem>
          <PopupTableItem title="Lumion File Name">
            {row.lumion_file_name}
          </PopupTableItem>
          <PopupTableItem title="CAD Symbol ~Size~">
            {row.plant_base_plant_cad_symbol_size_links
              ?.map(
                (style: IPlantBasePlantCadSizeLink) =>
                  style.plant_cad_symbol_size.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="CAD Symbol ~Spacing~">
            {row.plant_base_plant_cad_symbol_spacing_links
              ?.map(
                (style: IPlantBasePlantCadSpacingLink) =>
                  style.plant_cad_symbol_spacing.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Container Size">
            {row.plant_base_plant_container_size_links
              ?.map(
                (style: IPlantBasePlantContainerSizeLink) =>
                  style.plant_container_size.name
              )
              .join(", ")}
          </PopupTableItem>
        </DialogContent>
      </Dialog>
      <TableRow
        className={classes.root}
        style={{ background: props.isEven ? "#eeeeee" : "" }}
      >
        <TableCell style={{ display: "flex", alignItems: "center" }}>
          <QuantityInput value={quantity} onUpdate={onUpdateQuantity} />
          <IconButton onClick={onExpand} size="small">
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        </TableCell>
        {props.row.map((_row) => _row?.[0])}
      </TableRow>
      <TableRow style={{ background: props.isEven ? "#eeeeee" : "" }}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        ></TableCell>
      </TableRow>
    </>
  );
}

export default PlantSelectionTableRow;
