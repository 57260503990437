import {
  ProjectProperties,
  Agent,
  DesignAssignment,
} from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import React from "react";
import { getNextSelfAssignState, DssStateMap } from ".";
import environmentConstants from "../../ConstantValues/environmentConstants";
import genericAlert, { GenericOnCallFunctionAlert } from "../genericAlert";
import {
  MutationDefinition,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";

import { ProjectWithProfile } from "../sortProjectsIntoDssQueue";

enum AssignDesignerSelfAssignAssignmentErrorTypes {
  ALREADY_ASSIGNED = "ALREADY_ASSIGNED",
  PROJECT_NOT_FOUND = "PROJECT_NOT_FOUND",
  DESIGN_ASSIGNMENT_NOT_FOUND = "DESIGN_ASSIGNMENT_NOT_FOUND",
  NO_ACTIVE_ASSIGNMENT = "NO_ACTIVE_ASSIGNMENT",
  ASSIGNMENT_NOT_FOUND = "ASSIGNMENT_NOT_FOUND",
}

const HOLD_STATES = [
  "assignmentCreated",
  "postV1Hold",
  "completed",
  "postV3Hold",
  "postCorrectionHold",
  "postExtraHold",
];

class AssignDesignerSelfAssignAssignmentError extends Error {
  public code: AssignDesignerSelfAssignAssignmentErrorTypes;
  constructor(
    type: AssignDesignerSelfAssignAssignmentErrorTypes,
    message?: string
  ) {
    super(message);
    this.code = type;
  }
}

type SetDesignAssignmentDueDateMutationTrigger = MutationTrigger<
  MutationDefinition<
    {
      designAssignmentId: string;
      projectId: string;
    },
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    any,
    DesignAssignment,
    "pangaeaAPI"
  >
>;

async function assignNextProjectToDesigner(
  agent: Agent,
  setSnackbarText: (value: React.SetStateAction<string | null>) => void,
  dssStates: Readonly<DssStateMap> | null,
  setDesignAssignmentDueDate: SetDesignAssignmentDueDateMutationTrigger,
  fetchNextProject: () => Promise<ProjectWithProfile | undefined>,
  retryCount: number = 0
): Promise<void> {
  //re-fetch in case data is stale since loading the page
  const nextProject = await fetchNextProject();
  if (!nextProject) {
    if (process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT) {
      throw new Error("no project to assign...");
    }
    await genericAlert(`No project to assign in designer self assign!`);
    return setSnackbarText(
      "Something went wrong. No job to assign. Please contact tech support with this error."
    );
  }

  if (!dssStates) {
    if (process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT) {
      throw new Error("Cannot fetch DSS States for project assignment");
    }
    GenericOnCallFunctionAlert(
      "assignDesignAssignment",
      "Cannot fetch DSS States for project assignemnt"
    );
    return setSnackbarText(
      "Something went wrong. Please refresh the page and try again."
    );
  }

  try {
    await assignDesignerAssignment(
      nextProject.project.id,
      agent.id,
      true,
      true,
      dssStates,
      false,
      setDesignAssignmentDueDate,
      true
    );
  } catch (error) {
    if (error instanceof AssignDesignerSelfAssignAssignmentError) {
      if (
        error.code ===
        AssignDesignerSelfAssignAssignmentErrorTypes.ALREADY_ASSIGNED
      ) {
        if (retryCount >= 10) {
          await genericAlert(
            `agent ${agent.id} ${agent.email} stuck in infinite assignment loop`
          );
          return setSnackbarText(
            "Something went wrong with the assignment process, please contact tech support"
          );
        }
        return assignNextProjectToDesigner(
          agent,
          setSnackbarText,
          dssStates,
          setDesignAssignmentDueDate,
          fetchNextProject,
          ++retryCount
        );
      }
    }
    return setSnackbarText(
      "Something went wrong with the assignment process, please contact tech support"
    );
  }
}

async function assignDesignerAssignment(
  projectId: string,
  designerAgentId: string,
  bailIfAlreadyAssigned: boolean = false,
  setNewDueDate: boolean,
  dssStates: Readonly<DssStateMap>,
  forCorrection: boolean = false,
  setDesignAssignmentDueDate: SetDesignAssignmentDueDateMutationTrigger,
  selfAssignedDesigner: boolean = false
): Promise<void> {
  return firebase
    .firestore()
    .runTransaction(async (tx) => {
      const projectUpdateObject: Partial<
        ProjectProperties & { selfAssignedDesigner: boolean }
      > = {
        designerId: designerAgentId,
        assignedAt: new Date().getTime(),
        selfAssignedDesigner,
      };
      const projectSnap = await tx.get(
        firebase.firestore().collection("projects").doc(projectId)
      );

      if (!projectSnap.exists) {
        await genericAlert(`No project to assign in designer self assign!`);
        throw new AssignDesignerSelfAssignAssignmentError(
          AssignDesignerSelfAssignAssignmentErrorTypes.PROJECT_NOT_FOUND,
          "project not found"
        );
      }

      const projectData = projectSnap.data() as ProjectProperties;

      if (bailIfAlreadyAssigned && projectData.designerId) {
        throw new AssignDesignerSelfAssignAssignmentError(
          AssignDesignerSelfAssignAssignmentErrorTypes.ALREADY_ASSIGNED,
          "Assignment already assigned"
        );
      }

      if (!projectData.currentDesignAssignmentId) {
        await genericAlert(
          `Trying to assign to project with no open assignments ${projectId}`
        );
        throw new AssignDesignerSelfAssignAssignmentError(
          AssignDesignerSelfAssignAssignmentErrorTypes.NO_ACTIVE_ASSIGNMENT,
          "Project does not have an active assignment to assign"
        );
      }

      const assignmentSnap = await tx.get(
        firebase
          .firestore()
          .collection("projects")
          .doc(projectId)
          .collection("designAssignments")
          .doc(projectData.currentDesignAssignmentId)
      );

      if (!assignmentSnap.exists) {
        await genericAlert(
          `Trying to assign to project with no open assignments: ${projectId}`
        );
        throw new AssignDesignerSelfAssignAssignmentError(
          AssignDesignerSelfAssignAssignmentErrorTypes.ASSIGNMENT_NOT_FOUND,
          `Design assignment for project ${projectId} does not exist`
        );
      }

      if (
        // If the project is in a hold state, move to an in progress state
        HOLD_STATES.includes(projectData.designAssignmentStatus as string)
      ) {
        const { designAssignmentStatus } = getNextSelfAssignState(
          projectData.designAssignmentStatus,
          dssStates,
          forCorrection
        );

        projectUpdateObject.designAssignmentStatus = designAssignmentStatus;
      }

      await tx.update(
        firebase.firestore().collection("projects").doc(projectId),
        projectUpdateObject
      );

      return assignmentSnap.ref.id;
    })
    .then(async (designAssignmentId) => {
      if (setNewDueDate) {
        await setDesignAssignmentDueDate({
          projectId,
          designAssignmentId,
        });
      }
    })
    .catch((error) => {
      console.error(error);
      throw "There was an error assigning the project to the designer";
    });
}

export {
  assignDesignerAssignment,
  assignNextProjectToDesigner,
  AssignDesignerSelfAssignAssignmentError,
  AssignDesignerSelfAssignAssignmentErrorTypes,
};
