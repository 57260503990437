import * as React from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { UserCtx } from "../util/UserContext";
import { Agent } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import SelfAssignHeader from "./SelfAssignHeader";
import LoadingAbsCenter from "../Components/LoadingAbsCenter";
import { AgentCtx } from "./AgentContext";
import { SelectedAssetsDispatchConstants } from "../ConstantValues/SelectedAssetsDispatchConstants";
import SelectedAssetsDispatchContext from "../util/contexts/SelectedAssetsDispatchContext";
import SelectedAssetsStateContext from "../util/contexts/SelectedAssetsStateContext";
import { useLogrocket } from "../util/hooks";
import environmentConstants from "../ConstantValues/environmentConstants";
import { UserRole } from "../Interfaces";
import { PageBodyWrapper } from "./PageBodyWrapper";

const useStyles = makeStyles({
  root: {},
  container: {
    height: "calc(100vh - 4rem)",
    maxheight: "calc(100vh - 4rem)",
  },
});

function reducer(state: any, action: any) {
  switch (action.type) {
    case SelectedAssetsDispatchConstants.setSingleMaterialBaseLink:
      return {
        // this is to prevent re-setting the state with outdated data
        completedFirstCall: true,
        assignment_material_base_links:
          state.assignment_material_base_links.map((link: any) => {
            if (link.material_base_id !== action.val.material_base_id) {
              return link;
            }
            return action.val;
          }),
        assignment_plant_base_links: state.assignment_plant_base_links,
        assignment_product_base_links: state.assignment_product_base_links,
      };
    case SelectedAssetsDispatchConstants.setMaterialBaseLinks:
      return {
        completedFirstCall: true,
        assignment_material_base_links: action.val,
        assignment_plant_base_links: state.assignment_plant_base_links,
        assignment_product_base_links: state.assignment_product_base_links,
      };
    case SelectedAssetsDispatchConstants.setPlantBaseLinks:
      return {
        completedFirstCall: true,
        assignment_material_base_links: state.assignment_material_base_links,
        assignment_plant_base_links: action.val,
        assignment_product_base_links: state.assignment_product_base_links,
      };
    case SelectedAssetsDispatchConstants.setProductBaseLinks:
      return {
        completedFirstCall: true,
        assignment_material_base_links: state.assignment_material_base_links,
        assignment_plant_base_links: state.assignment_plant_base_links,
        assignment_product_base_links: action.val,
      };
    case SelectedAssetsDispatchConstants.setAssignmentLinks:
      return {
        completedFirstCall: true,
        assignment_material_base_links:
          action.val.assignment_material_base_links,
        assignment_plant_base_links: action.val.assignment_plant_base_links,
        assignment_product_base_links: action.val.assignment_product_base_links,
      };
    default:
      // leave unchanged
      return {
        completedFirstCall: state.completedFirstCall,
        assignment_material_base_links: state.assignment_material_base_links,
        assignment_plant_base_links: state.assignment_plant_base_links,
        assignment_product_base_links: state.assignment_product_base_links,
      };
  }
}

const initialReducerState = {
  assignment_material_base_links: [],
  assignment_plant_base_links: [],
  assignment_product_base_links: [],
  completedFirstCall: false,
};

const SelfAssignPage: React.FC = () => {
  const user = React.useContext(UserCtx);
  const classes = useStyles();

  useLogrocket(true, UserRole.Designer, environmentConstants.PRODUCTION);

  const [initializing, setInitializing] = React.useState<
    boolean | "noAgent" | "failedFetch"
  >(true);
  const [agent, setAgent] = React.useState<Agent | null>(null);
  const [state, dispatch] = React.useReducer(reducer, initialReducerState);

  React.useEffect(onUserChange, [user]);
  React.useEffect(onMount, []);

  if (initializing === true) {
    return <LoadingAbsCenter in={initializing === true} />;
  } else if (initializing === "noAgent" || initializing === "failedFetch") {
    return (
      <>
        <Typography>
          Please wait for a yardzen staff member to approve your account
        </Typography>
        <Button variant="text" onClick={() => firebase.auth().signOut()}>
          logout
        </Button>
      </>
    );
  }

  return (
    <AgentCtx.Provider value={agent as any}>
      <SelectedAssetsDispatchContext.Provider value={dispatch}>
        <SelectedAssetsStateContext.Provider value={state}>
          <div className={classes.root}>
            <SelfAssignHeader agentType={(agent as Agent).type} />
            <PageBodyWrapper />
          </div>
        </SelectedAssetsStateContext.Provider>
      </SelectedAssetsDispatchContext.Provider>
    </AgentCtx.Provider>
  );

  function onMount() {
    return () => {
      if (agent) {
        agent.destroy();
      }
    };
  }

  function onUserChange() {
    void (async function () {
      if (user) {
        try {
          (async function () {
            do {
              let agent: Agent | null = null;

              try {
                agent = await Agent.fetchWithUserId(user.uid);

                if (agent && !agent.disabled) {
                  setAgent(agent as Agent);
                  setInitializing(false);
                  break;
                }
              } catch (err) {
                if (err) console.error(err);
                setInitializing("failedFetch");
                break;
              }

              setInitializing("noAgent");
            } while (false);
          })();
        } catch (err) {
          console.error(err);
        }
      }
    })();
  }
};

export { SelfAssignPage };
export default SelfAssignPage;
