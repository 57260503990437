import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getProjectManagersList } from "../../util/firebase/firebaseClient";
import { ProjectManagerProperties } from "@yardzen-inc/models";
import { banyanAPI } from "../../api/banyanV1API";
import { BuildRegion } from "../BuildView/routes/Contractors/util/BuildRegions/types";
import { Edit } from "@material-ui/icons";
import { EditAccountManagerMarketsModal } from "./EditAccountManagerMarketsModal";

type AccountManager = ProjectManagerProperties & {
  id: string;
  buildRegions: BuildRegion[];
  banyanAccountManagerId: string | undefined;
};

export const MarketManagerPage = () => {
  const [projectManagers, setProjectManagers] = useState<
    AccountManager[] | undefined
  >();
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [selectedProjectManager, setSelectedProjectManager] =
    useState<AccountManager | null>(null);
  const [allBuildRegions, setAllBuildRegions] = useState<BuildRegion[]>([]);
  const [refetch, setRefetch] = useState<boolean>(false);

  const {
    data: buildRegionsData,
    isLoading: buildRegionsDataLoading,
    error: buildRegionsDataError,
  } = banyanAPI.useGetBuildRegionsWithAccountManagerQuery(
    { refetch },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: accountManagersData,
    isLoading: accountManagersLoading,
    error: accountManagersError,
  } = banyanAPI.useGetAllAccountManagersQuery(
    { refetch },
    { refetchOnMountOrArgChange: true }
  );

  if (buildRegionsDataError) {
    console.log("error", buildRegionsDataError);
  }

  if (accountManagersError) {
    console.log("error", accountManagersError);
  }

  const openEditModal = (pm: AccountManager) => {
    setSelectedProjectManager(pm);
    setEditModalOpen(true);
  };

  useEffect(() => {
    if (buildRegionsDataLoading || accountManagersLoading) {
      return;
    }
    const sortedBuildRegions =
      buildRegionsData && buildRegionsData?.length > 0
        ? [...buildRegionsData]
            .filter((br) => br.isDefault && !br.archived)
            .sort((a, b) => {
              if (a.displayName < b.displayName) {
                return -1;
              }
              if (a.displayName > b.displayName) {
                return 1;
              }
              return 0;
            })
        : [];
    setAllBuildRegions(sortedBuildRegions);

    async function getProjectManagers() {
      const pmList = await getProjectManagersList();
      const sortedPmList =
        pmList &&
        [...pmList]
          .filter((pm) => pm.userId)
          .sort((a, b) => {
            if (a.lastName.toUpperCase() < b.lastName.toUpperCase()) {
              return -1;
            }
            if (a.lastName.toUpperCase() > b.lastName.toUpperCase()) {
              return 1;
            }
            return 0;
          });
      //add buildRegions to project managers list
      const list = sortedPmList.map((pm) => {
        const buildRegions =
          sortedBuildRegions.filter(
            (b) => b.accountManager?.projectManagerId === pm.id
          ) || [];
        const banyanAccountManagerId = accountManagersData?.find(
          (am) => am.projectManagerId === pm.id
        )?.id;

        return { ...pm, buildRegions: buildRegions, banyanAccountManagerId };
      });

      setProjectManagers(list);
    }
    getProjectManagers();
  }, [
    buildRegionsData,
    buildRegionsDataLoading,
    accountManagersData,
    accountManagersLoading,
  ]);

  const cellStyle = {
    border: "1px solid black",
    padding: "8px",
  };
  return (
    <Box p={3} display="flex" alignItems="center">
      {projectManagers ? (
        <Box>
          <Typography variant="h3" style={{ paddingBottom: "8px" }}>
            Markets for Account Managers
          </Typography>
          <Typography variant="subtitle1" style={{ paddingBottom: "8px" }}>
            Displaying Project Managers linked to a user. To add, go to{" "}
            <Link href="/pms">Project Managers</Link> and create or have the
            user select "This is Me" to link the Project Manager to a user.
          </Typography>
          <Box>
            <table style={{ border: "1px solid", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={cellStyle}>
                    <Typography variant="h5">Name</Typography>
                  </th>

                  <th style={cellStyle}>
                    <Typography variant="h5">Email</Typography>
                  </th>
                  <th style={cellStyle}>
                    <Typography variant="h5">Assigned Markets</Typography>
                  </th>
                  <th style={cellStyle}>
                    <Typography variant="h5">Edit</Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {projectManagers.map((pm) => (
                  <tr key={pm.id}>
                    <td style={{ ...cellStyle, minWidth: "200px" }}>
                      <Typography variant="h6">
                        {pm.firstName + " " + pm.lastName}
                      </Typography>
                    </td>

                    <td style={cellStyle}>
                      <Typography variant="h6">{pm.email}</Typography>
                    </td>
                    <td style={cellStyle}>
                      <Typography variant="h6">
                        {pm.buildRegions.map((br) => br.displayName).join(", ")}
                      </Typography>
                    </td>
                    <td style={cellStyle}>
                      <IconButton onClick={() => openEditModal(pm)}>
                        <Edit />
                      </IconButton>
                      {editModalOpen && selectedProjectManager === pm && (
                        <EditAccountManagerMarketsModal
                          open={editModalOpen}
                          onClose={() => setEditModalOpen(false)}
                          accountManager={selectedProjectManager}
                          allBuildRegions={allBuildRegions || []}
                          refreshData={setRefetch}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
