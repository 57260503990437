import React, { FC, useRef, useState, useEffect, useMemo } from "react";
import AssignmentTable from "../Assignments/AssignmentTable";
import { Assignment, AssignmentProperties } from "@yardzen-inc/models";
import { Redirect } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { useLocalStorage } from "../../util/hooks";
import {
  getIncompleteAssignmentsByPMOrderedByDate,
  getProjectById,
} from "../../util/firebase/firebaseClient";
import { sortByFlagAndDate } from "../../util/functions/sortByFlagAndDate";

export interface CDMQueueProps {
  pmId: string;
}

type AssignmentTable = AssignmentProperties & {
  id: string;
  isExpedited: boolean;
};

const CDMQueue: FC<CDMQueueProps> = (props) => {
  const listRef = useRef<HTMLDivElement>(null);
  const firstRunComplete = useRef<boolean>(false);

  const [cache, updateCache] = useLocalStorage<{
    assignments: AssignmentTable[];
  }>("CDM_ASSIGNMENT_CACHE");

  const [syncing, setSyncing] = React.useState<boolean>(true);
  const [redirect, setRedirect] = useState<{
    projectId: string;
    assignmentId: string;
  } | null>(null);

  const assignments = useMemo(() => hydrateCache(), [cache]);
  const len = useMemo(() => assignments?.length ?? 0, [assignments]);

  useEffect(() => {
    listenForAssignments();
  }, [props.pmId]);

  return (
    <>
      {redirect && (
        <Redirect to={`/cdm/${redirect.projectId}/${redirect.assignmentId}`} />
      )}
      {!!(len !== null && len !== undefined && len > 0) && (
        <AssignmentTable
          useArchive
          syncing={syncing}
          order="asc"
          assignments={assignments}
          listRef={listRef}
          onClick={handleAssignmentClick}
        />
      )}
      {!!(len == 0) && (
        <Box
          width="100%"
          p={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography align="center" variant="h4">
            no open CDM Assignments
          </Typography>
        </Box>
      )}
    </>
  );

  function hydrateCache(): Assignment[] | null {
    if (!firstRunComplete.current) {
      if (hasForceRefresh()) {
        updateCache(null);
        firstRunComplete.current = true;
        return null;
      }

      firstRunComplete.current = true;
    }

    if (!cache) {
      return null;
    }

    return cache.assignments.map(({ id, ...props }) => {
      const hydrated = new Assignment();
      hydrated.setProperties(props as AssignmentProperties);
      hydrated.id = id;
      return hydrated;
    });
  }

  function handleAssignmentClick(assignment: Assignment): void {
    return setRedirect({
      projectId: assignment.projectId,
      assignmentId: assignment.id,
    });
  }

  async function listenForAssignments(): Promise<() => void> {
    const incompleteAssignments =
      await getIncompleteAssignmentsByPMOrderedByDate(props.pmId);
    return incompleteAssignments.onSnapshot(async (snap) => {
      if (!syncing) {
        setSyncing(true);
      }

      try {
        const assignments = (await Promise.all(
          snap.docs.map(async (d) => {
            if (!d.data()) {
              return null;
            }
            const project = await getProjectById(d.data().projectId);
            const isExpedited = project.isExpedited ?? false;
            return { ...d.data(), id: d.id, isExpedited } as AssignmentTable;
          })
        )) as AssignmentTable[];

        assignments.filter((d) => !!d);

        const sortedAssignments = assignments.sort((a, b) =>
          sortByFlagAndDate(
            { flag: a.isExpedited, date: a.createdAt },
            { flag: b.isExpedited, date: b.createdAt }
          )
        );
        updateCache({ assignments: sortedAssignments });
      } catch (error) {
        console.error(error);
      } finally {
        setSyncing(false);
      }
    });
  }

  function hasForceRefresh(): boolean {
    return new URLSearchParams(window.location.search).has("invalidate_cache");
  }
};

export { CDMQueue };
export default CDMQueue;
