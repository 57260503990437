import { AgentGroup } from "@yardzen-inc/util";
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
export interface UseAgentGroups {
  (): (AgentGroup & { id: string })[] | null;
}

let lastResults: ReturnType<UseAgentGroups> = null;

const useAgentGroups: UseAgentGroups = () => {
  const [groups, setGroups] = useState<ReturnType<UseAgentGroups>>(lastResults);

  useEffect(listenForAgentGroups, []);

  return groups;

  function listenForAgentGroups(): () => void {
    return (
      firebase
        .firestore()
        .collection("agent-groups")
        // .limit(50) we may want to use this later
        .onSnapshot((snap) => {
          if (snap.empty) {
            return setGroups([]);
          }

          const _groups = snap.docs
            .map((doc) => ({
              ...(doc.data() as AgentGroup),
              id: doc.id,
            }))
            .sort((a, b) => {
              if (a.identifier < b.identifier) return -1;
              if (a.identifier > b.identifier) return 1;
              return 0;
            });

          lastResults = _groups;
          setGroups(_groups);
        })
    );
  }
};

export { useAgentGroups };
export default useAgentGroups;
