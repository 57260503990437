import { Box, IconButton, Tooltip } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import { BulkPromotionalCodeCreator } from "./BulkPromotionalCodeCreator";
import { PromotionalCodeViewer } from "./PromotionalCodeViewer";
import { List, Create } from "@material-ui/icons";
import { getActiveStripeProducts } from "src/util/firebase/firebaseClient";

export const PromotionalCodeManager: FC = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const [products, setProducts] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const products = await getActiveStripeProducts();
      const sortedProducts = [...products].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setProducts(
        sortedProducts.map((product) => ({
          name: product.name,
          id: product.id,
        }))
      );
    };

    fetchProducts();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        position="fixed"
        top={0}
        left={239}
      >
        <Tooltip title="View Promotional Codes">
          <IconButton onClick={() => history.push("/promotional-codes")}>
            <List />
          </IconButton>
        </Tooltip>
        <Tooltip title="Create Promotional Codes">
          <IconButton onClick={() => history.push(`/promotional-codes/create`)}>
            <Create />
          </IconButton>
        </Tooltip>
      </Box>
      <Switch>
        <Route path={`${match.url}/create`}>
          <BulkPromotionalCodeCreator products={products} />
        </Route>
        <Route>
          <PromotionalCodeViewer products={products} />
        </Route>
      </Switch>
    </Box>
  );
};
