import * as React from "react";
import firebase from "firebase/compat/app";
import { Profile, Media } from "@yardzen-inc/models";
import LoadingAbsCenter from "../../Components/LoadingAbsCenter";
import {
  TextField,
  Typography,
  Theme,
  LinearProgress,
  Box,
} from "@material-ui/core";
import { YZButton } from "@yardzen-inc/react-common";
import { makeStyles } from "@material-ui/styles";
import IncludeMediaInSharedLink from "./IncludeMediaInSharedLink";
import { SharedMediaGrids } from "../../SharedMediaGrids";
import { BUDGET_ESTIMATE_VARIANT } from "src/ConstantValues/fileuploadConstants";

interface Props {
  clientRecord: Profile;
}

export default function SharedMedia({ clientRecord }: Props) {
  const classes = useStyles();
  const [media, setMedia] = React.useState<null | Media[]>(null);
  const [loading, setLoading] = React.useState(false);
  const [notes, setNotes] = React.useState("");
  const [updating, setUpdating] = React.useState(false);
  const [shareableLink, setShareableLink] = React.useState("");
  const yzEnv =
    window.location.origin.includes("dogfood") ||
    window.location.origin.includes("localhost")
      ? "staging"
      : "prod";
  const accountShareURL =
    yzEnv === "prod"
      ? "https://account.yardzen.com/shared/"
      : "https://account.dogfood.yardzen.com/shared/";
  const liisaShareURL =
    yzEnv === "prod"
      ? "https://liisa.office.yardzen.com/shared/"
      : "https://liisa.dogfood.yardzen.com/shared/";

  async function getShareableLink(revisionId: string) {
    if (revisionId) {
      const shareableLink = await firebase
        .firestore()
        .collection("shareableLinks")
        .where("revisionId", "==", revisionId)
        .get();
      setShareableLink(shareableLink.docs[0].id);
    }
  }

  React.useEffect(() => {
    fetchPublicMedia();
    // @ts-ignore
    if (clientRecord["contractorNotes"]) {
      // @ts-ignore
      setNotes(clientRecord["contractorNotes"]);
    }
  }, []);

  React.useEffect(() => {
    setUpdating(true);
    const timer = setTimeout(() => updateContractorNotes(), 600);
    return () => clearTimeout(timer);
  }, [notes]);

  if (loading || media === null)
    return <LoadingAbsCenter in={loading || media === null} />;

  return (
    <div className={classes.root}>
      <Box display="flex">
        <div className={classes.metaContainer}>
          {shareableLink && (
            <>
              <YZButton
                variant="contained"
                color="primary"
                onClick={() => {
                  window.open(`${accountShareURL}${shareableLink}`, "_blank");
                }}
              >
                Client View Share Link
              </YZButton>
            </>
          )}
          <YZButton
            variant="contained"
            color="primary"
            style={{ marginTop: "1rem" }}
            onClick={() => {
              window.open(`${liisaShareURL}${clientRecord.id}`, "_blank");
            }}
          >
            Contractor View Share Link
          </YZButton>
          <div
            style={{ margin: "1rem", display: "flex", flexDirection: "column" }}
          >
            {renderMetaData()}
            <TextField
              value={notes}
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
              onChange={(e) => setNotes(e.target.value)}
              label="Contractor Notes"
              multiline
              variant="outlined"
            />
            {updating ? <LinearProgress /> : null}
          </div>
        </div>
        <Box display="flex" justifyContent="center" width="100%">
          <IncludeMediaInSharedLink
            onCompletion={() => fetchPublicMedia()}
            getShareLink={getShareableLink}
            clientRecord={clientRecord}
          />
        </Box>
      </Box>
      <SharedMediaGrids
        onDeleteComplete={(media: Media[]) => {
          unShare(media);
          fetchPublicMedia();
        }}
        onUploadComplete={() => {}}
        media={media}
        userId={clientRecord.id}
        permissions={{
          media: {
            access: "update",
          },
        }}
        noShare
        noDelete
        unShare={unShare}
      />
    </div>
  );

  async function fetchPublicMedia() {
    const sharedDoc = await Media.getPublicMedia(clientRecord.id);
    if (!sharedDoc) return setMedia([]);
    const media = sharedDoc.media;
    const filteredMedia = removeBudgetEstimateRender(media);
    setMedia(filteredMedia);
    setLoading(false);
  }

  async function unShare(mediaToUnShare: Media[]) {
    setLoading(true);
    const mediaIds = mediaToUnShare.map((rec) => rec.id);
    await Media.removeFromPublicMedia(clientRecord.id, mediaIds);
    fetchPublicMedia();
  }

  function renderMetaData() {
    const { street, city, state, zip, email, phone } = clientRecord;
    if (!clientRecord || !street || !city || !email || !phone)
      return (
        <Typography>
          <em>No client info found! Please update in the DETAIL tab</em>
        </Typography>
      );

    return (
      <>
        <Typography>{email}</Typography>
        <Typography>{street}</Typography>
        <Typography>
          {city}, {state}
        </Typography>
        <Typography>{zip}</Typography>
        <Typography>{phone}</Typography>
      </>
    );
  }

  function updateContractorNotes() {
    const contractorNotes = notes;
    firebase
      .firestore()
      .collection("profiles")
      .doc(clientRecord.id)
      .update({
        contractorNotes,
      })
      .then(() => {
        setUpdating(false);
      });
  }

  function removeBudgetEstimateRender(media: Media[]) {
    return media.filter((doc) => doc.variant !== BUDGET_ESTIMATE_VARIANT);
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  metaContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    margin: "auto",
    alignItems: "center",
  },
}));
