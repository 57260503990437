import React, { FC } from "react";
import { Dialog, makeStyles, Theme, useTheme } from "@material-ui/core";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";

export interface ConfirmDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  deleteFn: () => void;
  prompt: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  btnContainer: {
    width: "100%",
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
}));

const ConfirmDeleteDialog: FC<ConfirmDeleteDialogProps> = ({
  open,
  onClose,
  deleteFn,
  prompt,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={classes.root}>
        <YZTypography>{prompt}</YZTypography>

        <div className={classes.btnContainer}>
          <YZButton
            style={{ backgroundColor: theme.palette.error.main }}
            onClick={deleteFn}
          >
            Delete
          </YZButton>

          <YZButton
            style={{ backgroundColor: theme.palette.grey[500] }}
            onClick={onClose}
          >
            Cancel
          </YZButton>
        </div>
      </div>
    </Dialog>
  );
};

export { ConfirmDeleteDialog };
export default ConfirmDeleteDialog;
