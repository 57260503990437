import * as React from "react";
import revisionContext from "../../util/RevisionContext";
import RevisionAPI from "../../util/RevisionAPI";
import RevisionContainer from "./RevisionContainer";
import { CircularProgress } from "@material-ui/core";

interface Props {
  clientRecord: object;
  isEmployee: boolean;
  fetchProfile: CallableFunction;
}

function RevisionAPIWrapper(props: Props) {
  const RevisionContext: any = React.useContext(revisionContext);
  const [isLoading, setIsLoading] = React.useState(false);

  if (
    // @ts-ignore
    !RevisionContext.state.revisions[props.clientRecord["id"]] &&
    !isLoading
  ) {
    setIsLoading(true);
    loadData();
  }

  // @ts-ignore
  if (!RevisionContext.state.revisions[props.clientRecord["id"]]) {
    return <CircularProgress />;
  } else {
    return (
      <RevisionContainer
        isEmployee={props.isEmployee}
        clientRecord={props.clientRecord as any}
        fetchProfile={props.fetchProfile}
      />
    );
  }

  async function loadData() {
    await RevisionAPI.loadProjectRevisionData(
      RevisionContext,
      // @ts-ignore
      props.clientRecord["id"]
    );
    setIsLoading(false);
  }
}

export default RevisionAPIWrapper;
