import * as React from "react";
import { Dialog } from "@material-ui/core";
import ExitButton from "./ExitButton";
import { Media } from "@yardzen-inc/models";

interface Props {
  open?: boolean;
  media: string | Media;
  onClose: () => void;
  bgColor?: string;
}

const FullScreenImage = (props: Props) => {
  const url = React.useMemo(
    () =>
      typeof props.media === "string" ? props.media : props.media.downloadURL,
    [props.media]
  );

  return (
    <Dialog onClose={props.onClose} open={!!props.open} style={{}} fullScreen>
      {render()}
    </Dialog>
  );

  function render(): React.ReactNode {
    if (
      props.media instanceof Media &&
      props.media.fileType.toLowerCase().includes("pdf")
    ) {
      return renderPdf();
    } else if (
      props.media instanceof Media &&
      props.media.fileType.toLowerCase().includes("video")
    ) {
      return renderVideo();
    }

    return renderImage();
  }

  function renderVideo(): React.ReactNode {
    return (
      <>
        <ExitButton
          onClick={props.onClose}
          style={{
            position: "absolute",
            top: 15,
            right: 15,
            backgroundColor: "black",
            pointerEvents: "all",
            zIndex: 99,
          }}
        />
        <video
          src={(props.media as Media).downloadURL}
          height={"100%"}
          width={"100%"}
          controls
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: props.bgColor ?? "black",
          }}
        />
      </>
    );
  }

  function renderPdf(): React.ReactNode {
    return (
      <>
        <ExitButton
          onClick={props.onClose}
          style={{
            position: "absolute",
            top: 45,
            right: 30,
            backgroundColor: props.bgColor ?? "black",
          }}
        />
        <iframe
          src={(props.media as Media).downloadURL}
          height={"100%"}
          width={"100%"}
          style={{ width: "100%", height: "100%" }}
        />
      </>
    );
  }

  function renderImage(): React.ReactNode {
    return (
      <div
        style={{
          backgroundColor: props.bgColor ?? "black",
          backgroundImage: `url("${url}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          height: "100%",
          width: "100%",
          display: "inline-flex",
        }}
      >
        <ExitButton
          onClick={props.onClose}
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            backgroundColor: "black",
          }}
        />
      </div>
    );
  }
};

export default FullScreenImage;
