/*
  These types will be used in function
  overloads to profide specific return type
  annotations.
*/
export type Projects = "Projects";
export type Assignments = "Assignments";

/*
  The following should be every property from every model that would be
  used in the compilation of the two common data exports.


*/

export type ProjectManagerProp = "firstName" | "lastName" | "email";
export type ProjectManagerProps = ["firstName", "lastName", "email"];

export const projectManagerProps = ["firstName", "lastName", "email"];

export type RevisionProp =
  | "title"
  | "profileId"
  | "dateCreated"
  | "dateSubmittedForReview"
  | "dateSentToClient"
  | "isComplete"
  | "hasBeenSent"
  | "deliverables"
  | "projectId"
  | "designAnnotated"
  | "isCurrent"
  | "v1Blurb"
  | "finalBlurb";

export type RevisionProps = [
  "title",
  "profileId",
  "dateCreated",
  "dateSubmittedForReview",
  "dateSentToClient",
  "isComplete",
  "hasBeenSent",
  "deliverables",
  "projectId",
  "designAnnotated",
  "isCurrent",
  "v1Blurb",
  "finalBlurb"
];

export const revisionProperties = [
  "id",
  "title",
  "profileId",
  "dateSubmittedForReview",
  "dateSentToClient",
  "isComplete",
  "hasBeenSent",
  "deliverables",
  "projectId",
  "designAnnotated",
  "isCurrent",
  "v1Blurb",
  "finalBlurb",
];

export type AssignmentProp =
  | "type"
  | "isTestAssignment"
  | "pm"
  | "title"
  | "listed"
  | "completed"
  | "description"
  | "progress"
  | "assignedTo"
  | "createdAt"
  | "assignedAt"
  | "submittedAt"
  | "projectId"
  | "paid"
  | "revisionId";

export type AssignmentProps = [
  "type",
  "isTestAssignment",
  "pm",
  "title",
  "listed",
  "completed",
  "description",
  "progress",
  "assignedTo",
  "createdAt",
  "assignedAt",
  "submittedAt",
  "projectId",
  "paid",
  "revisionId"
];

export const assignmentProps = [
  "type",
  "isTestAssignment",
  "pm",
  "title",
  "listed",
  "completed",
  "description",
  "progress",
  "assignedTo",
  "createdAt",
  "assignedAt",
  "submittedAt",
  "projectId",
  "paid",
  "revisionId",
];

export type ProfileProp =
  | "id"
  | "package"
  | "userId"
  | "firstName"
  | "lastName"
  | "answers"
  | "email"
  | "street"
  | "city"
  | "state"
  | "phone"
  | "zip"
  | "upworkName"
  | "hardinessZone"
  | "designArea"
  | "isTestAccount"
  | "delighted"
  | "conciergeHours"
  | "pinterestUrl"
  | "elements"
  | "airProfile"
  | "consultNotes"
  | "designerNotes"
  | "otherNotes"
  | "funcFlowReady"
  | "funcFlowApproved"
  | "v1Annotated"
  | "funcflowAnnotated"
  | "projectState"
  | "installType"
  | "wizardDone"
  | "featureFlags"
  | "finalDesignModalDismissed"
  | "reportedBudget"
  | "welcomeDismissed"
  | "conceptualRevision"
  | "finalRevision"
  | "projectManager"
  | "dateSubmittedForReview"
  | "clientReviewModalDismissed"
  | "referralSource";

export type ProfileProps = [
  "package",
  "firstName",
  "lastName",
  "answers",
  "email",
  "street",
  "city",
  "state",
  "phone",
  "zip",
  "hardinessZone",
  "designArea",
  "isTestAccount",
  "delighted",
  "conciergeHours",
  "pinterestUrl",
  "elements",
  "airProfile",
  "consultNotes",
  "designerNotes",
  "otherNotes",
  "funcFlowReady",
  "funcFlowApproved",
  "v1Annotated",
  "funcflowAnnotated",
  "projectState",
  "installType",
  "wizardDone",
  "featureFlags",
  "finalDesignModalDismissed",
  "reportedBudget",
  "welcomeDismissed",
  "conceptualRevision",
  "finalRevision",
  "projectManager",
  "dateSubmittedForReview",
  "clientReviewModalDismissed",
  "referralSource"
];

export const profileProps = [
  "package",
  "firstName",
  "lastName",
  "answers",
  "email",
  "street",
  "city",
  "state",
  "phone",
  "zip",
  "hardinessZone",
  "designArea",
  "isTestAccount",
  "delighted",
  "conciergeHours",
  "pinterestUrl",
  "elements",
  "airProfile",
  "consultNotes",
  "designerNotes",
  "otherNotes",
  "funcFlowReady",
  "funcFlowApproved",
  "v1Annotated",
  "funcflowAnnotated",
  "projectState",
  "installType",
  "wizardDone",
  "featureFlags",
  "finalDesignModalDismissed",
  "reportedBudget",
  "welcomeDismissed",
  "conceptualRevision",
  "finalRevision",
  "projectManager",
  "dateSubmittedForReview",
  "clientReviewModalDismissed",
  "referralSource",
];

export type ProjectProp =
  | "createdAt"
  | "assignedAt"
  | "projectManagerId"
  | "designAssignmentStatus"
  | "currentDesignAssignmentId"
  | "designerId";

export type ProjectProps = [
  "createdAt",
  "projectManagerId",
  "assignedAt",
  "designAssignmentStatus",
  "currentDesignAssignmentId",
  "designerId"
];

export const projectProps = [
  "createdAt",
  "projectManagerId",
  "assignedAt",
  "designAssignmentStatus",
  "currentDesignAssignmentId",
  "designerId",
];

export type AgentProp =
  | "userId"
  | "type"
  | "firstName"
  | "lastName"
  | "email"
  | "payRate"
  | "disabled";

export type AgentProps = [
  "userId",
  "type",
  "firstName",
  "lastName",
  "email",
  "payRate",
  "disabled"
];

export const agentProps = [
  "userId",
  "type",
  "firstName",
  "lastName",
  "email",
  "payRate",
  "disabled",
];

export type DesignAssignmentProps = [
  "listed",
  "revisionId",
  "projectId",
  "projectManagerNotes",
  "status",
  "type",
  "submitted",
  "submittedAt",
  "dueDate",
  "correctionFor",
  "notes",
  "createdAt"
];

export type DesignAssignmentProp =
  | "listed"
  | "revisionId"
  | "projectId"
  | "projectManagerNotes"
  | "status"
  | "type"
  | "submitted"
  | "submittedAt"
  | "dueDate"
  | "correctionFor"
  | "createdAt"
  | "notes";

export const designAssignmentProps = [
  "version",
  "listed",
  "revisionId",
  "projectId",
  "projectManagerNotes",
  "status",
  "type",
  "submitted",
  "submittedAt",
  "dueDate",
  "correctionFor",
  "notes",
];

export type DocProp = ProfileProp | AgentProp | AssignmentProp | ProjectProp;

export type PrimaryDocument = Projects | Assignments;

/*
  Options and filters for the stat request
 */
export interface Opts {
  filterByCreationDate?: CreatedAtFilter | null;
}

/*
  Parameters for filtering the _primary document_
  by creation date.
 */
export interface CreatedAtFilter {
  operator: "<" | "<=" | "==" | ">" | ">=";
  date: number;
}

export interface ExportRequest {
  opts?: Opts;
}

export interface AssignmentExportRequest extends ExportRequest {
  properties: AssignmentExportPropMap;
}

export interface DesignAssignmentExportRequest extends ExportRequest {
  properties: ProjectExportPropMap;
}

export interface AssignmentExportProps {
  profileProps: ProfileProps;
  projectProps: ProjectProps;
  assignmentProps: AssignmentProps;
  agentProps: AgentProps;
}

export interface AssignmentExportPropMap {
  profileProps: ProfileProp[];
  projectProps: ProjectProp[];
  assignmentProps: AssignmentProp[];
  agentProps: AgentProp[];
}

export interface ProjectExportProps {
  profileProps: ProfileProps;
  projectProps: ProjectProps;
  revisionProps: RevisionProps;
  designAssignmentProps: DesignAssignmentProps;
  projectManagerProps: ProjectManagerProps;
  agentProps: AgentProps;
}

export interface ProjectExportPropMap {
  profileProps: ProfileProp[];
  revisionProps: RevisionProp[];
  projectProps: ProjectProp[];
  designAssignmentProps: DesignAssignmentProp[];
  projectManagerProps: ProjectManagerProp[];
  agentProps: AgentProp[];
}

export type PropMap = ProjectExportPropMap | AssignmentExportPropMap;

export type ExportProps = ProjectExportProps | AssignmentExportProps;

/*
  returns all tag types relating to the
  specified models
*/
export interface GetProperties {
  (type: PrimaryDocument): ExportProps;
  (type: Assignments): AssignmentExportProps;
  (type: Projects): ProjectExportProps;
}
