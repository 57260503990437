import { useState, useEffect } from "react";
import { Profile, Project } from "@yardzen-inc/models";

interface ProjectAndProfileQuery {
  data: {
    project: Partial<Project>;
    profile: Partial<Profile>;
  } | null;
  loading: boolean;
  error: string | null;
}

/**
 * Hook for retrieving the project and profile
 * for a given project.
 *
 * Usage: `const { data, loading, error } = useProjectAndProfile(projectId);`
 * Usage: `const { data: projectAndProfile, loading: projectAndProfileLoading, error: projectAndProfileError } = useProjectAndProfile(projectId);`
 *
 * `data`: contains the project and profile data once loaded, null if an error or loading
 * `loading`: true if the data is currently being fetched, false otherwise
 * `error`: contains a description of the issue if an error occurred, null otherwise
 *
 *
 * @param projectId The project id for the project and profile you want to load.
 * @returns ProjectAndProfileQuery
 */
const useProjectAndProfile = (projectId: string): ProjectAndProfileQuery => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [project, setProject] = useState<Project | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(loadProject, [projectId]);
  useEffect(loadProfile, [project]);

  return {
    data:
      project && profile
        ? {
            profile,
            project,
          }
        : null,
    loading,
    error,
  };

  function loadProject() {
    setLoading(true);
    setProject(null);
    setProfile(null);
    void (async function () {
      const fetchedProject = await Project.fetch(projectId);
      if (fetchedProject) {
        setProject(fetchedProject);
        setError(null);
      } else {
        setProject(null);
        setLoading(false);
        setError("Project not found.");
      }
    })();
  }

  function loadProfile() {
    void (async function () {
      if (project !== null) {
        const fetchedProfile = await Profile.get(project.profileId, false);
        if (fetchedProfile) {
          setProfile(fetchedProfile);
          setError(null);
        } else {
          setProfile(null);
          setLoading(false);
          setError("Profile not found.");
        }
      } else {
        setProfile(null);
        setLoading(false);
        setError("Project is empty so profile cannot be found.");
      }
    })();
  }
};

export { useProjectAndProfile };
