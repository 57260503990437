import * as React from "react";
import MediaCard, { MediaCardProps } from "./MediaCard";
import firebase from "firebase/compat/app";
import {
  SerializedThread,
  getIcon,
  AnnotatorPage,
} from "@yardzen-inc/annotator";
import { Pages, Delete } from "@material-ui/icons";
import "firebase/compat/database";
import { Avatar, makeStyles, Typography, IconButton } from "@material-ui/core";

export interface AnnotatorCardProps extends MediaCardProps {
  user: any;
  profile: any;
  readonly?: boolean;
  deleteable?: boolean;
  slideName?: string;
  onExpand?: () => void;
}

type ThreadCounts = number | null;

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    width: "fit-content",
    maxWidth: "20%",
    padding: "0.75rem",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    height: "100%",
  },
  threadCount: {
    color: "white",
    fontSize: "2rem",
    marginLeft: "0.5rem",
  },
  avatar: {
    height: "2.3rem",
    width: "2.3rem",
    [theme.breakpoints.down("md")]: {
      height: "2rem",
      width: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "1.5rem",
      width: "1.5rem",
    },
  },

  openAnnotatorButtonContainer: {
    padding: "0.5rem",
    display: "flex",
    flexFlow: "row-reverse nowrap",
    alignItems: "center",
    flexGrow: 1,
    height: "100%",
  },
}));

const AnnotatorCard = ({
  user,
  profile,
  readonly,
  deleteable,
  ...props
}: AnnotatorCardProps) => {
  const classes = useStyles();

  const [annotatorOpen, setAnnotatorOpen] = React.useState<boolean>(false);
  const [fadeOut, setFadeOut] = React.useState<boolean>(false);
  const [threadCounts, setThreadCounts] = React.useState<ThreadCounts[]>([
    null,
    null,
    null,
    null,
    null,
  ]);

  React.useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection("media")
      .doc(props.media.id)
      .collection("threads")
      .onSnapshot((snap) => {
        setThreadCounts([null, null, null, null, null]);
        const _threadCounts = [0, 0, 0, 0, 0];

        if (snap.empty) {
          return setThreadCounts(_threadCounts as ThreadCounts[]);
        }

        snap.docs
          .map((d) => d.data() as SerializedThread)
          .forEach((t) => (_threadCounts[t.type] = _threadCounts[t.type] + 1));

        return setThreadCounts(_threadCounts as ThreadCounts[]);
      });

    return () => unsub();
  }, [props.media]);

  const [loading, threadIndicators] = React.useMemo(
    () => [
      threadCounts.findIndex((c) => c === null) >= 0,
      threadCounts.map((_, i) => renderIcon(i)),
    ],
    [threadCounts]
  );

  const usePdf = React.useMemo(
    () => props.media.fileType.toLowerCase().includes("pdf"),
    [props.media]
  );

  return (
    <>
      <div style={{ width: "100%", height: "fit-content" }}>
        {!usePdf && renderAnnotator()}
        {!usePdf && props.slideName && (
          <div
            style={{
              padding: "0.8rem",
              backgroundColor: "black",
              width: "fit-content",
            }}
          >
            <Typography variant="h4" style={{ color: "white" }}>
              slide {props.slideName}
            </Typography>
          </div>
        )}
        <MediaCard
          {...props}
          style={props.style}
          media={props.media}
          noTitle
          out={fadeOut}
          loading={loading}
          childrenToolBar={
            <>
              {!usePdf && threadIndicators}
              {renderGoToAnnotatorButton()}
            </>
          }
        />
      </div>
    </>
  );

  function renderAnnotator(): React.ReactNode | null {
    if (!annotatorOpen) return null;

    let title: string | undefined = undefined;

    if (props.slideName) {
      title = isNaN(parseInt(props.slideName))
        ? props.slideName
        : `slide ${props.slideName}`;
    }

    return (
      <AnnotatorPage
        open
        user={user}
        profile={profile}
        readonly={readonly}
        currentUser={user.email.includes("yardzen.co") ? "pm" : "agent"}
        title={title}
        media={[props.media]}
        onClose={() => setAnnotatorOpen(false)}
      />
    );
  }

  function renderIcon(i: number) {
    return (
      <div
        className={classes.iconContainer}
        key={props.media.id + "--thread" + i}
      >
        <Avatar src={getIcon(i)} className={classes.avatar} />
        <Typography className={classes.threadCount}>
          {threadCounts[i] !== null ? threadCounts[i] : "..."}
        </Typography>
      </div>
    );
  }

  function renderGoToAnnotatorButton(): React.ReactNode {
    return (
      <div className={classes.openAnnotatorButtonContainer}>
        {deleteable && (
          <IconButton
            onClick={() => deleteMedia()}
            disabled={usePdf}
            style={{ opacity: usePdf ? 0.5 : 1 }}
          >
            <Delete style={{ color: "white" }} />
          </IconButton>
        )}
        <IconButton
          disabled={usePdf}
          onClick={props.onExpand || (() => setAnnotatorOpen(true))}
          title="View Annotations"
          style={{ opacity: usePdf ? 0.5 : 1 }}
        >
          <Pages style={{ color: "white" }} />
        </IconButton>
      </div>
    );
  }

  function deleteMedia() {
    setFadeOut(true);
    props.media.delete();
  }
};

AnnotatorCard.defaultProps = {
  mediaCardProps: {},
};

export default AnnotatorCard;
