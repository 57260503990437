const parseExperienceVersion = (
  versionString: string | null,
  dontThrowOnInvalid?: boolean
): [number, number] => {
  if (!versionString) {
    return [1, 0];
  }

  const majorMinor = versionString.split(".");

  if (majorMinor.length !== 2) {
    if (dontThrowOnInvalid) {
      console.error(`Invalid Version String: ${versionString}`);
      return [1, 0];
    }

    throw new Error(`Invalid Version String: ${versionString}`);
  }

  try {
    return majorMinor.map((stringedInt) => {
      const parsed = parseInt(stringedInt, 10);

      if (isNaN(parsed)) {
        throw new Error(`version component value is NaN: ${stringedInt}`);
      }

      return parsed;
    }) as [number, number];
  } catch (error) {
    if (dontThrowOnInvalid) {
      console.error(error);
      return [1, 0];
    }

    throw error;
  }
};

export { parseExperienceVersion };
export default parseExperienceVersion;
