import * as React from "react";
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  DocumentNode,
  TypedDocumentNode,
  useMutation,
  useQuery,
} from "@yardzen-inc/graphql";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { IGenericAssetSupplement } from "../../../Interfaces";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  itemBtns: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
});

interface RowProps {
  row: IGenericAssetSupplement;
  updateQuery:
    | DocumentNode
    | TypedDocumentNode<any, { archived: boolean; id: string; name: string }>;
}

function Row(props: RowProps) {
  const [updateRow] = useMutation(props.updateQuery);
  const { row } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const classes = useRowStyles();
  const [name, setName] = React.useState<string>(row.name);

  const onSave = () => {
    updateRow({
      variables: {
        id: row.id,
        name,
        archived: row.archived,
      },
    });
  };

  const onArchiveToggle = () => {
    updateRow({
      variables: {
        id: row.id,
        name: row.name,
        archived: !row.archived,
      },
    });
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Update Row
              </Typography>
              <TextField
                onChange={({ currentTarget: { value } }) => setName(value)}
                value={name}
                label="Name"
                color="primary"
                required
              />
            </Box>
            <div className={classes.itemBtns}>
              <Button
                color="primary"
                variant="contained"
                disabled={!name}
                onClick={onSave}
              >
                Save Changes
              </Button>
              {row.archived ? (
                <Button
                  variant="contained"
                  disabled={!name}
                  onClick={onArchiveToggle}
                >
                  Un-archive
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled={!name}
                  onClick={onArchiveToggle}
                >
                  Archive
                </Button>
              )}
            </div>
            <br />
            <br />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  listQuery: DocumentNode | TypedDocumentNode<any, { archived: boolean }>;
  insertQuery:
    | DocumentNode
    | TypedDocumentNode<any, { archived: boolean; name: string }>;
  updateQuery:
    | DocumentNode
    | TypedDocumentNode<any, { archived: boolean; id: string; name: string }>;
  listResultName: string;
  title: string;
  assetType: string;
}

function SupplementaryTableForAssets(props: Props) {
  const classes = useStyles();

  const [newName, setNewName] = React.useState("");
  const [showArchived, setShowArchived] = React.useState(false);

  const { loading, error, data, refetch } = useQuery(props.listQuery, {
    variables: {
      archived: showArchived,
    },
    fetchPolicy: "network-only",
  });

  const [insertRow] = useMutation(props.insertQuery, {
    update() {
      refetch();
    },
  });

  const onCreate = () => {
    insertRow({
      variables: {
        name: newName,
        archived: false,
      },
    });
  };

  const toggleArchived = () => {
    const newArchived = !showArchived;
    refetch({
      archived: newArchived,
    });
    setShowArchived(newArchived);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return (
      <Typography>Something went wrong. Please contact support.</Typography>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleHeader}>
        <Typography variant="h4" component="h4">
          {props.title}
        </Typography>
        <Button onClick={toggleArchived} variant="contained">
          {showArchived ? "Hide" : "Show"} Archived
        </Button>
      </div>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            {data[props.listResultName].map((row: IGenericAssetSupplement) => (
              <Row key={row.id} row={row} updateQuery={props.updateQuery} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <br />
      <Divider />
      <br />
      <Typography variant="h4" component="h4">
        Add new {props.assetType}
      </Typography>
      <TextField
        onChange={({ currentTarget: { value } }) => setNewName(value)}
        value={newName}
        label="Name"
        color="primary"
        required
      />
      <br />
      <br />
      <Button variant="contained" disabled={!newName} onClick={onCreate}>
        Save Changes
      </Button>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    marginTop: 43,
    padding: "1rem",
  },
  titleHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default SupplementaryTableForAssets;
