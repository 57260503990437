import React, { FC, useState, useEffect, useMemo } from "react";
import {
  Box,
  FormControlLabel,
  Checkbox,
  TextField,
  Divider,
} from "@material-ui/core";
import { Filter } from "./types";

export interface FilterBarProps {
  filters: Filter;
  numberOfRecords: number;
  setNumberOfRecords: (n: number) => void;
  updateFilters: (filter: Filter) => void;
}

const FilterBar: FC<FilterBarProps> = ({
  filters,
  updateFilters,
  numberOfRecords,
  setNumberOfRecords,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [address, setAddress] = useState<string>("");

  useEffect(updatePhoneNumber, [phoneNumber]);
  useEffect(updateAddress, [address]);

  const numberOfRecordsStringified = useMemo(
    () => numberOfRecords.toString(),
    [numberOfRecords]
  );

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row">
        {renderCheckbox("Has Phone Number", filters.hasPhoneNumber, (value) =>
          updateFilters({ ...filters, hasPhoneNumber: value })
        )}
        {renderCheckbox("Has Address", filters.hasAddress, (value) =>
          updateFilters({ ...filters, hasAddress: value })
        )}
        {renderCheckbox("Answered Questions", filters.hasQuestions, (value) =>
          updateFilters({ ...filters, hasQuestions: value })
        )}
        {renderCheckbox(
          "Provided Name (only valid for new design profiles after direct checkout launch)",
          filters.hasName,
          (value) => updateFilters({ ...filters, hasName: value })
        )}
      </Box>
      <Divider></Divider>
      <Box display="flex" flexDirection="row" p={1}>
        <Box px={1}>
          <TextField
            label="Phone Number"
            type="number"
            value={phoneNumber}
            onChange={({ currentTarget: { value } }) => setPhoneNumber(value)}
          />
        </Box>
        <Box px={1}>
          <TextField
            label="Address"
            value={address}
            onChange={({ currentTarget: { value } }) => setAddress(value)}
          />
        </Box>
        <Box px={1}>
          <TextField
            label="Number of records"
            type="number"
            value={numberOfRecordsStringified}
            onChange={({ currentTarget: { value } }) => {
              if (!value) {
                return setNumberOfRecords(0);
              }

              const number = parseInt(value, 10);
              if (isNaN(number)) {
                throw new Error("number of recors input is nan");
              }

              setNumberOfRecords(Math.max(number, 0));
            }}
          />
        </Box>
      </Box>
      <Divider></Divider>
    </Box>
  );

  function updatePhoneNumber(): () => void {
    const timeout = setTimeout(() => {
      updateFilters({ ...filters, phoneNumber });
    }, 400);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }

  function updateAddress(): () => void {
    const timeout = setTimeout(() => {
      updateFilters({ ...filters, address });
    }, 400);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }
};

function renderCheckbox(
  label: string,
  value: boolean,
  updateFunction: (bool: boolean) => void
) {
  return (
    <Box>
      <FormControlLabel
        label={label}
        control={
          <Checkbox checked={value} onClick={() => updateFunction(!value)} />
        }
      />
    </Box>
  );
}

export { FilterBar };
export default FilterBar;
