import * as React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

interface Props {
  handleSetOrder: (order: "asc" | "desc") => void;
  order: string;
  setRecordsDateRange: (dateRange: number) => void;
  recordsDateRange: number;
}

function ClientStateFilter(props: Props) {
  return (
    <div style={{ display: "flex" }}>
      <FormControl
        style={{ minWidth: "120px", marginLeft: "1.5rem" }}
        margin="normal"
      >
        <InputLabel htmlFor="order">Order</InputLabel>
        <Select
          onChange={(e) =>
            props.handleSetOrder(e.target.value as "asc" | "desc")
          }
          value={props.order}
          inputProps={{
            id: "order",
          }}
        >
          <MenuItem value="desc">Newest First</MenuItem>
          <MenuItem value="asc">Oldest First</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        style={{ minWidth: "120px", marginLeft: "1.5rem" }}
        margin="normal"
      >
        <InputLabel htmlFor="dateRange">Date Range</InputLabel>
        <Select
          onChange={(e) => {
            props.setRecordsDateRange(e.target.value as number);
          }}
          value={props.recordsDateRange}
          inputProps={{
            id: "dateRange",
          }}
        >
          <MenuItem value={0}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={3}>Three months</MenuItem>
          <MenuItem value={6}>Six months</MenuItem>
          <MenuItem value={8}>Eight months</MenuItem>
          <MenuItem value={12}>One year</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default ClientStateFilter;
