import React, { FC, useState } from "react";
import { Box } from "@material-ui/core";
import { AgentGroupList } from "./AgentGroupList";
import { YZButton } from "@yardzen-inc/react-common";
import AddGroupModal from "./AddGroupModal";
import HasuraAdminsOnly from "../../util/HasuraAdminsOnly";

export interface AgentGroupsPanelProps {}

const AgentGroupsPanel: FC<AgentGroupsPanelProps> = (props) => {
  const [createGroupOpen, setCreateGroupOpen] = useState<boolean>(false);
  return (
    <>
      <HasuraAdminsOnly>
        <AddGroupModal
          open={createGroupOpen}
          onClose={() => setCreateGroupOpen(false)}
        />
        <Box
          width="100%"
          p={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            p={1}
            width="100%"
            display="flex"
            flexDirection="row-reverse"
            onClick={() => setCreateGroupOpen(true)}
          >
            <YZButton onClick={() => setCreateGroupOpen(true)}>
              Create new group
            </YZButton>
          </Box>
          <AgentGroupList />
        </Box>
      </HasuraAdminsOnly>
    </>
  );
};

export { AgentGroupsPanel };
export default AgentGroupsPanel;
