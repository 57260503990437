import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { HTTPError } from "./errors";

const extRegex = /\.[^\.]*$/;
const fileNameStartRegex = /^.*filename="/;
const fileNameEndRegex = /".*/;
const baseNameRegex = /\/[^\/\\]*$/;

// refactored to static class to make sinon easier
class DownloadUtil {
  private constructor() {}

  /**
   * **downloadFileWithHeaders** uses the fetch api to request a file, save it as a blob,
   * and then save it to disk.
   * @param uri - The location of the resource to be downloaded
   * @param headers - Additional headers to add to the request
   * @param overrideFileName - The file name to store the downloaded data as.
   */
  public static async downloadFileWithHeaders(
    uri: string,
    headers: HeadersInit = new Headers(),
    overrideFileName?: string
  ): Promise<void> {
    const res = await DownloadUtil.makeDownloadRequest(uri, headers);
    await DownloadUtil.downloadContent(
      res,
      DownloadUtil.deriveFilename(res, overrideFileName)
    );
  }

  /**
   * downloadContent triggers a file download from the body of a fetch response using the
   * 'robot clicks on an anchor tag' method.
   *
   * @param res fetch response with body of content to download
   * @param fileName The desired file name of the file to be downloaded
   * @param options Options for the anchor tag and whether or not it is automatically loaded/removed
   */
  public static async downloadContent(
    res: Response,
    fileName: string,
    options = {
      a: document.createElement("a"),
      addAnchorToDom: true,
      removeAnchorFromDom: true,
    }
  ) {
    let { a, addAnchorToDom, removeAnchorFromDom } = options;

    const blob = await res.blob();
    const blobURL = URL.createObjectURL(blob);
    a.href = blobURL;
    a.download = fileName;

    if (addAnchorToDom) {
      document.body.appendChild(a);
    }
    a.click();
    if (removeAnchorFromDom) {
      document.body.removeChild(a);
    }
  }

  /**
   * deriveFilename takes a download fetch request response, and uses the content disposition
   * header to figure out the content's intended file name. If overrideFileName is provided, that name
   * will be returned with an appropriate file extension
   * @param res fetch response for download request
   * @param overrideFileName optionally specified filename
   * @returns
   */
  public static deriveFilename(res: Response, overrideFileName?: string) {
    let fileName = DownloadUtil.getFilenameFromContentDisposition(res);
    if (overrideFileName) {
      fileName = DownloadUtil.handleFilenameOverride(
        overrideFileName,
        fileName
      );
    }

    return fileName;
  }

  /**
   * handleFilenameOverride will test that overrideFileName has an appropriate file extension, and if
   * not append the extension from fileName to it.
   * @param overrideFileName The overriding file name
   * @param fileName the original file name
   * @returns The overide file name with the correct file extension appended
   */
  public static handleFilenameOverride(
    overrideFileName: string,
    fileName: string
  ) {
    if (!extRegex.test(overrideFileName)) {
      const ext = fileName.substring(fileName.search(extRegex));
      fileName = overrideFileName + ext;
    } else {
      fileName = overrideFileName;
    }
    return fileName;
  }

  /**
   * getFilenameFromContentDisposition will strip the response's Content-Disposition header
   * for it's intended filename
   * @param res The download request response
   * @returns
   */
  public static getFilenameFromContentDisposition(res: Response): string {
    const contentDisposition = res.headers.get("Content-Disposition");
    let fileName: string;

    if (contentDisposition && fileNameStartRegex.test(contentDisposition)) {
      fileName = contentDisposition
        .replace(fileNameStartRegex, "")
        .replace(fileNameEndRegex, "");
    } else {
      const { url } = res;
      fileName = url.substring(url.search(baseNameRegex)).replace(/\//g, "");
    }
    return fileName;
  }

  /**
   * makeDownloadRequest makes a request using fetch to an internet resource. Will throw
   * on non-200 status code
   * @param uri The resource location
   * @param headers http headers to be appended to request
   * @returns A fetch Response object
   * @throws {HTTPError}
   */
  public static async makeDownloadRequest(
    uri: string,
    headers: HeadersInit | undefined
  ) {
    const res = await fetch(uri, {
      method: "GET",
      headers: headers,
    });

    if (res.status !== 200) {
      throw new HTTPError(res.status, res.statusText, `GET failed to ${uri}`);
    }

    return res;
  }

  /**
   * **downloadWithFirebaseCredentials** is a convenience wrapper for downloadFileWithHeaders that will
   * add an Authorization header with the firebase id token to the request for download.
   * @param uri - The location of the resource to download
   * @param additionalHeaders - Headers to be set on request. Authorization header will always be overridden regardless of what this object holds.
   * @param overrideFileName - The file name to store the download data as
   */
  public static async downloadWithFirebaseCredentials(
    uri: string,
    additionalHeaders?: { [key: string]: string },
    overrideFileName?: string
  ) {
    const token = await firebase.auth().currentUser?.getIdToken();

    const headers = new Headers();
    if (additionalHeaders) {
      for (let key in additionalHeaders) {
        headers.set(key, additionalHeaders[key]);
      }
    }

    headers.set("Authorization", `Bearer ${token}`);

    return DownloadUtil.downloadFileWithHeaders(uri, headers, overrideFileName);
  }
}

export { DownloadUtil };
