import React, { FC, useState, useContext } from "react";
import { UseDesignBriefLoadingState, DesignBriefCtx } from "./util";
import { Box, Button } from "@material-ui/core";
import GenericConfirm from "../GenericConfirm";

export interface DesignBriefChecklistToolbarProps {
  pm?: boolean;
}

const DesignBriefChecklistToolbar: FC<DesignBriefChecklistToolbarProps> = (
  props
) => {
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);

  const dbCtxValue = useContext(DesignBriefCtx);

  return (
    <>
      <GenericConfirm
        title="Are you sure you wan't to reset the design brief?"
        body="All progress will be lost"
        open={resetConfirmOpen}
        onClose={() => setResetConfirmOpen(false)}
        onSubmit={async () => {
          await dbCtxValue.api.dropCollection();
          await dbCtxValue.createNewDesignBrief();
          setResetConfirmOpen(false);
        }}
      />
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        position="sticky"
        p={1}
      >
        {props.pm && (
          <Box p={1}>
            <Button
              variant="text"
              disabled={
                dbCtxValue.loadingState !== UseDesignBriefLoadingState.DONE
              }
              onClick={handleResetOrCreate}
            >
              {dbCtxValue.data ? "Reset brief" : "Create brief"}
            </Button>
          </Box>
        )}
        <Box p={1}>
          <Button
            variant="text"
            disabled={
              dbCtxValue.loadingState !== UseDesignBriefLoadingState.DONE
            }
            onClick={() => dbCtxValue.reloadDesignBrief()}
          >
            Reload brief
          </Button>
        </Box>
      </Box>
    </>
  );

  async function handleResetOrCreate(): Promise<void> {
    if (!dbCtxValue.data) {
      return dbCtxValue.createNewDesignBrief();
    }

    setResetConfirmOpen(true);
  }
};

export { DesignBriefChecklistToolbar };
export default DesignBriefChecklistToolbar;
