import * as React from "react";
import {
  List,
  withStyles,
  ListItem,
  Tooltip,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";
import HowToReg from "@material-ui/icons/HowToRegOutlined";
import SentButNotActive from "@material-ui/icons/SendOutlined";
import SubmittedForReview from "@material-ui/icons/MoveToInboxOutlined";
import V1 from "@material-ui/icons/LooksOneOutlined";
import V2 from "@material-ui/icons/LooksTwoOutlined";
import V3 from "@material-ui/icons/Looks3Outlined";
import NotSubmitted from "@material-ui/icons/CreateOutlined";
import AnnotationLinkModal from "./AnnotatonLinkModal";
import {
  Revision,
  Project,
  DesignAssignmentProperties,
} from "@yardzen-inc/models";
import GenericConfirm from "../../Components/GenericConfirm";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import moment from "moment";
import { firebaseConfig } from "../../util/firebase/init";
import { YZButton } from "@yardzen-inc/react-common";
import { UserCtx } from "../../util/UserContext";
import { updateRevisionIssuedBy } from "../../util/firebase/firebaseClient";
interface Props {
  classes: any;
  selectedRevision?: Revision;
  profileId: string;
  activeRevision: object;
  revisionList: Revision[];
  handleSelectRevision: (revision: Revision | null) => void;
  isEmployee?: boolean;
  projectState: string;
  conceptualRevisionId?: string;
  finalRevisionId?: string;
  v3RevisionId?: string;
}

function RevisionList(props: Props) {
  const { classes } = props;
  const [annoLink, setAnnoLink] = React.useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
  const revisionDeleteButtonDisabled =
    (props.selectedRevision && !!props.selectedRevision.dateSentToClient) ||
    !props.selectedRevision;
  // Looks like firebase sends them in order anyway, maybe dont need to check?

  const user = React.useContext(UserCtx);

  const [conceptualRev, finalRev, v3Rev] = React.useMemo(() => {
    const finalRev = props.revisionList.find(
      (r) => r.id === props.finalRevisionId
    );
    const conceptualRev = props.revisionList.find(
      (r) => r.id === props.conceptualRevisionId
    );
    const v3Rev = props.revisionList.find((r) => r.id === props.v3RevisionId);

    return [conceptualRev, finalRev, v3Rev];
  }, [
    props.revisionList,
    props.conceptualRevisionId,
    props.v3RevisionId,
    props.finalRevisionId,
  ]);

  return (
    <div>
      <GenericConfirm
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={() => deleteSelectedRevision()}
      />
      {annoLink && (
        <AnnotationLinkModal
          uid={props.profileId}
          revisionNo={
            !!props.selectedRevision
              ? (() => {
                  return (
                    props.revisionList.length -
                    1 -
                    props.revisionList.findIndex(
                      (r) => r.id === (props.selectedRevision as Revision).id
                    )
                  );
                })()
              : 0
          }
          onClose={() => setAnnoLink(false)}
        />
      )}
      <List className={classes.root}>
        {props.revisionList.map((rev: Revision, index: number) => {
          if (rev !== conceptualRev && rev !== finalRev && rev !== v3Rev) {
            return (
              <ListItem
                className={classes.listItem}
                style={{ display: "flex", justifyContent: "space-between" }}
                selected={props.selectedRevision?.id === rev.id}
                key={rev.id}
                divider
                onClick={() => props.handleSelectRevision(rev)}
              >
                {!!rev.title ? rev.title : "Revision" + (index + 1)}
                {renderInfoIcon(index, rev)}
              </ListItem>
            );
          }

          return null;
        })}
        {renderNamedRevisions()}
        {!!props.isEmployee && (
          <>
            {firebaseConfig?.projectId !== "yardzen-5f8e9" && (
              <>
                <ListItem
                  style={{ display: "flex", flexFlow: "column nowrap" }}
                >
                  <YZButton
                    variant="contained"
                    color="primary"
                    // disabled={setButtonDisabled()}
                    onClick={() => createRevision()}
                  >
                    New revision
                  </YZButton>
                </ListItem>
                <br />
              </>
            )}
            <ListItem>
              <YZButton
                variant="contained"
                onClick={() => {
                  setAnnoLink(true);
                }}
              >
                annotations link
              </YZButton>
            </ListItem>
            <ListItem>
              <Tooltip
                title={
                  revisionDeleteButtonDisabled
                    ? "This revision cannot be deleted as it has been delivered to the client"
                    : "Delete revision"
                }
              >
                <span>
                  <YZButton
                    variant="contained"
                    disabled={revisionDeleteButtonDisabled}
                    onClick={() => setConfirmDelete(true)}
                  >
                    Delete Revision
                  </YZButton>
                </span>
              </Tooltip>
            </ListItem>
          </>
        )}
      </List>
    </div>
  );

  function renderNamedRevisions(): React.ReactNode[] {
    return [
      finalRev || conceptualRev ? (
        <ListItem style={{ display: "flex", flexFlow: "column nowrap" }}>
          <Box display="inline-block" py={2}>
            <Typography variant="body1" align="center">
              Client visible:
            </Typography>
            <Divider></Divider>
          </Box>
        </ListItem>
      ) : null,
      ([conceptualRev, finalRev, v3Rev].filter((r) => !!r) as Revision[]).map(
        (rev, index) => {
          return (
            <ListItem
              className={classes.listItem}
              style={{ display: "flex", justifyContent: "space-between" }}
              selected={props.selectedRevision?.id === rev.id}
              key={rev.id}
              divider
              onClick={() => props.handleSelectRevision(rev)}
            >
              {getRevisionName(rev.id)}
              {renderInfoIcon(index, rev)}
            </ListItem>
          );
        }
      ),
    ];
  }

  function getRevisionName(rev: string | Revision): string {
    const id = typeof rev === "string" ? rev : rev?.id;

    if (id === conceptualRev?.id) {
      return "Conceptual";
    }
    if (id === finalRev?.id) {
      return "Final";
    }
    if (id === v3Rev?.id) {
      return "V3";
    }

    return "";
  }

  function renderInfoIcon(index: number, revision: Revision) {
    if (revision.id === props.conceptualRevisionId) {
      return (
        <>
          <Tooltip title="Seen by client since">
            <Typography
              style={{
                color: "green",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                fontSize: "1.1rem",
              }}
            >
              <div style={{ display: "flex" }}>
                <HowToReg style={{ margin: "auto" }} /> <V1 />
              </div>
              {moment(revision.dateSentToClient as string).format("MM/DD")}
            </Typography>
          </Tooltip>
        </>
      );
    }

    if (revision.id === props.finalRevisionId) {
      return (
        <>
          <Tooltip title="Seen by client since">
            <Typography
              style={{
                color: "green",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex" }}>
                <HowToReg style={{ margin: "auto" }} /> <V2 />
              </div>
              {moment(revision.dateSentToClient as string).format("MM/DD")}
            </Typography>
          </Tooltip>
        </>
      );
    }

    if (revision.id === props.v3RevisionId) {
      return (
        <>
          <Tooltip title="Seen by client since">
            <Typography
              style={{
                color: "green",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex" }}>
                <HowToReg style={{ margin: "auto" }} /> <V3 />
              </div>
              {moment(revision.dateSentToClient as string).format("MM/DD")}
            </Typography>
          </Tooltip>
        </>
      );
    }

    if (revision.dateSentToClient) {
      return (
        <>
          <Tooltip title="Last sent to client">
            <Typography
              style={{
                color: "#f57c00",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SentButNotActive style={{ margin: "auto" }} />{" "}
              {moment(revision.dateSentToClient as string).format("MM/DD")}
            </Typography>
          </Tooltip>
        </>
      );
    }

    if (revision.dateSubmittedForReview) {
      return (
        <>
          <Tooltip title="Submitted for QA">
            <Typography
              style={{ color: "red", display: "flex", flexDirection: "column" }}
            >
              <SubmittedForReview style={{ margin: "auto" }} />{" "}
              {moment(revision.dateSubmittedForReview as string).format(
                "MM/DD"
              )}
            </Typography>
          </Tooltip>
        </>
      );
    }

    return (
      <>
        <Tooltip title="Created">
          <Typography style={{ display: "flex", flexDirection: "column" }}>
            <NotSubmitted style={{ margin: "auto" }} />{" "}
            {moment(revision.dateCreated as string).format("MM/DD")}
          </Typography>
        </Tooltip>
      </>
    );
  }

  async function deleteSelectedRevision() {
    if (!props.selectedRevision) return;

    const designAssignSnap = await firebase
      .firestore()
      .collectionGroup("designAssignments")
      .where("revisionId", "==", props.selectedRevision.id)
      .get();

    if (designAssignSnap.docs.length) {
      alert(
        "Cannot delete the selected revision as it is tied to the designer self assign workflow"
      );

      return setConfirmDelete(false);
    }

    const revDoc = firebase
      .firestore()
      .collection("revisions")
      .doc(props.selectedRevision.id);

    if (!revDoc) return;

    const designAssigns = await firebase
      .firestore()
      .collectionGroup("designAssignments")
      .where("revisionId", "==", revDoc.id)
      .get();

    if (!designAssigns.empty) {
      const projectId = (
        designAssigns.docs[0].data() as DesignAssignmentProperties
      ).projectId;
      const ids = designAssigns.docs.map((d) => d.id);

      if (ids.includes(projectId)) {
        try {
          const project = await Project.fetch(projectId);
          project.currentDesignAssignmentId = null;
          project.save();
        } catch {}
      }

      await Promise.all(
        designAssigns.docs.map(async (d) => {
          return d.ref.delete();
        })
      );
    }

    const data = (await revDoc.get()).data();
    if (!data) return;

    await firebase
      .firestore()
      .collection("deletedRevisions")
      .doc(revDoc.id)
      .set(data);

    await revDoc.delete();
    props.handleSelectRevision(
      props.revisionList[props.revisionList.length - 2]
    );
    setConfirmDelete(false);
  }

  async function createRevision() {
    const rev = await Revision.create(props.profileId);
    if (user) updateRevisionIssuedBy(rev, user);
    props.handleSelectRevision(rev);
  }

  // function setButtonDisabled() {
  //   if (props.projectState && props.projectState !== "materialsRecieved") {
  //     return true;
  //   }
  //   return false;
  // }

  // async function allowCreateNewDesignFlow() {
  //   await RevisionAPIModel.allowCreateDesignFlow(
  //     RevisionContext,
  //     props.projectId,
  //   );
  //   props.fetchProfile(props.projectId);
  // }
}

const styles = ({}) => ({
  root: {},
  listItem: {
    cursor: "pointer",
  },
});

export function getRevisionName(i: number): string {
  switch (i) {
    case 0:
      return "Conceptual";
    case 1:
      return "Final";
    default:
      return `Extra ${i + 1}`;
  }
}

export default withStyles(styles)(RevisionList);
