import { useState, useEffect, useRef } from "react";

interface UseLocalStorage {
  <t extends {}>(key: string): [t | null /* val */, (val: t | null) => void];
}

// @ts-ignore
export const useLocalStorage: UseLocalStorage = <t>(key: string) => {
  const [value, setValue] = useState<t | null>(getVal);
  const firstRunComplete = useRef<boolean>(false);

  useEffect(() => {
    if (!firstRunComplete.current) {
      firstRunComplete.current = true;
      return;
    }

    return setValue(getVal());
  }, [key]);

  return [value, updateValue];

  function updateValue(val: t): void {
    setValue(val);

    localStorage.setItem(
      key,
      typeof val === "string" ? val : JSON.stringify(val)
    );
  }

  function getVal(): t | null {
    const item = localStorage.getItem(key);

    if (!item) {
      return null;
    }

    return JSON.parse(item) as t;
  }
};

export default useLocalStorage;
