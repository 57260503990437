import {
  ListItem,
  Paper,
  Box,
  Divider,
  Link,
  LinearProgress,
  makeStyles,
} from "@material-ui/core";
import { Profile } from "@yardzen-inc/models";
import { YZTypography, YZButton } from "@yardzen-inc/react-common";
import firebase from "firebase/compat/app";
import * as React from "react";
import GenericSnackBar from "../../../Components/GenericSnackBar";
import { formatAddress } from "../../../util/functions/formatAddress";
import { geocodeAddress } from "../util/geocodeAddress";
import { parseGeocodeLocationToAddress } from "../util/parseGeocodeToAddress";
import { SuggestedAddressListItem } from "./SuggestedAddressListItem";

interface IIncompleteProfileListItemProps {
  profile: Profile;
  removeProfileFromQueue: (profile: Profile) => void;
}

const useStyles = makeStyles(({ spacing }) => ({
  padding: {
    padding: spacing(1),
  },
  margin: {
    margin: spacing(1),
  },
}));

const IncompleteProfileListItem: React.FunctionComponent<IIncompleteProfileListItemProps> =
  ({ profile, removeProfileFromQueue }) => {
    const { padding, margin } = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<null | string>(null);
    const [successfullySetAddress, setSuccessfullySetAddress] =
      React.useState(false);

    const [geocodeResults, setGeocodeResults] = React.useState<
      google.maps.GeocoderResult[]
    >([]);

    return (
      <>
        <GenericSnackBar
          variant="success"
          message={`${profile.firstName} ${profile.lastName}'s address updated`}
          onClose={() => {
            setSuccessfullySetAddress(false);
            removeProfileFromQueue(profile);
          }}
          in={successfullySetAddress}
        />
        <GenericSnackBar
          variant="error"
          in={!!error}
          message={error || "Error performing operation"}
          onClose={() => setError(null)}
        />
        <ListItem
          key={profile.id}
          style={{ display: successfullySetAddress ? "none" : undefined }}
        >
          <Paper className={padding}>
            <Box>
              <Link color="initial" href={`/client/${profile.id}/detail`}>
                <YZTypography variant="h6">
                  {profile.firstName} {profile.lastName}
                </YZTypography>
              </Link>

              <YZTypography variant="caption">{profile.id}</YZTypography>
              <YZTypography>{formatAddress(profile)}</YZTypography>
              <Divider />
              <YZButton
                disabled={loading}
                onClick={() => getSuggestedAddresses()}
                className={margin}
              >
                Get suggested addresses
              </YZButton>
              {loading && <LinearProgress />}
              {geocodeResults.map((result) => {
                return (
                  <SuggestedAddressListItem
                    key={result.place_id}
                    geocodeResult={result}
                    setProfileAddress={(loc) => setProfileAddress(loc, profile)}
                  />
                );
              })}
            </Box>
          </Paper>
        </ListItem>
      </>
    );

    async function getSuggestedAddresses() {
      setLoading(true);
      try {
        setGeocodeResults(await geocodeAddress(formatAddress(profile)));
      } catch (error) {
        console.error(error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    async function setProfileAddress(
      geocodeLoc: google.maps.GeocoderResult,
      profile: Profile
    ) {
      setLoading(true);
      try {
        await firebase
          .firestore()
          .collection("profiles")
          .doc(profile.id)
          .update(parseGeocodeLocationToAddress(geocodeLoc));
        setSuccessfullySetAddress(true);
      } catch (error) {
        console.error(error);
        setError(error.message || error);
      } finally {
        setLoading(false);
      }
    }
  };

export { IncompleteProfileListItem };
