import { BUDGET_METADATA_BY_PROJECT_ID, useQuery } from "@yardzen-inc/graphql";
import React from "react";
import { Profile, ProfileProperties } from "@yardzen-inc/models";
import { ReactNode, useEffect, useState } from "react";
import useBudgetElementsPriceEstimate from "./useBudgetElementsPriceEstimate";
import useSkipMount from "./useSkipMount";
import { formatNumToUSD } from "@yardzen-inc/react-common";
import useLogError from "./useLogError";

export interface UseBudgetResponseText {
  (
    profileOrId:
      | string
      | Pick<ProfileProperties, "reportedBudget" | "adjustedBudget">,
    projectId: string
  ): string | ReactNode | null;
}

/*
  Hook returns either in this priority order:
    1. a client's `designer_visible_budget`, or;
    2. a client's reported budget / phase budget from budget elements, or;
    3. the old budget response from profile, or;
    4. null if loading
*/
const useBudgetResponseText: UseBudgetResponseText = (
  profileOrId,
  projectId
) => {
  const firstRunComplete = useSkipMount();
  const priceEstimate = useBudgetElementsPriceEstimate(projectId);
  const {
    loading: budgetMetaLoading,
    data: budgetMetaData,
    error,
  } = useQuery(BUDGET_METADATA_BY_PROJECT_ID, {
    variables: { projectId },
    fetchPolicy: "network-only",
  });

  useLogError(error as Error);

  const [profile, setProfile] = useState<Pick<
    ProfileProperties,
    "reportedBudget" | "adjustedBudget"
  > | null>(() => (typeof profileOrId === "string" ? null : profileOrId));

  useEffect(getProfile, [profileOrId, budgetMetaData]);

  return getBudgetString();

  function getBudgetString(): ReactNode {
    if (profile?.adjustedBudget) {
      return `Adjusted Budget: ${formatNumToUSD(profile.adjustedBudget)}`;
    }

    if (budgetMetaLoading || (!priceEstimate && !profile)) {
      return null;
    }

    if (budgetMetaData && !!budgetMetaData.budget_metadata.length) {
      const strs = getBudgetMetadataBudgetResponseString();
      if (Array.isArray(strs)) {
        return strs.map((str) => (
          <div key={str}>
            {str}
            <br />
          </div>
        ));
      }
      return strs;
    }

    return getOldBudgetResponseString();
  }

  function getOldBudgetResponseString() {
    if (!profile) {
      return null;
    }

    return profile.reportedBudget ? profile.reportedBudget : "Did not respond";
  }

  function getBudgetMetadataBudgetResponseString(): string[] | null {
    if (
      !priceEstimate ||
      !priceEstimate.find((value) => !!value) ||
      !budgetMetaData
    ) {
      return null;
    }

    const metadata = budgetMetaData.budget_metadata[0];

    let responseStrings: string[] = [];

    if (metadata.designer_visible_budget) {
      responseStrings.push(
        `Design Budget: ${formatNumToUSD(
          parseInt(metadata.designer_visible_budget, 10)
        )}`
      );
    } else {
      responseStrings.push(
        `Design Budget: ${formatNumToUSD(parseInt(metadata.total_budget, 10))}`
      );

      if (!metadata.phasing) {
        return responseStrings;
      }

      const phaseMetadata = metadata.budget_phase_metadata[0];

      if (!phaseMetadata) {
        return responseStrings;
      }

      responseStrings.push(
        `Phased Budget: ${formatNumToUSD(parseInt(phaseMetadata.budget, 10))}`
      );
      responseStrings.push(
        `Design Project for ${
          metadata.design_project_for_single_phase
            ? "Design Budget"
            : "Phased budget"
        }`
      );
    }

    return responseStrings;
  }

  function getProfile(): void {
    if (!firstRunComplete || !!budgetMetaData?.budget_metadata.length) {
      return;
    }

    void (async function () {
      if (typeof profileOrId !== "string") {
        return setProfile(profileOrId);
      }

      try {
        const profile = await Profile.get(profileOrId);
        setProfile(profile);
      } catch (error) {
        console.error(error);
      }
    })();
  }
};

export { useBudgetResponseText };
export default useBudgetResponseText;
