import React, { FC } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { OnboardData } from "./OnboardDataProvider";
import BasicOnboardMediaGrid from "./BasicOnboardMediaGrid";
import { Divider, Typography } from "@material-ui/core";
import capitalizeFirstLetter from "../../util/capitalizeFirstLetter";

export interface YardInfoGroupProps extends BoxProps {
  userId: string;
  yard: string;
  onboardData: OnboardData;
  privileged?: boolean;
  showOnly: string;
}

export const imageVariantTags = ["wide-angle", "slope", "keep", "remove"];
export const videoVariantTags = ["slow-pan", "chatty"];

export const uploadVariantTags = Object.assign([], imageVariantTags);
uploadVariantTags.splice(1, 0, ...videoVariantTags);

const YardInfoGroup: FC<YardInfoGroupProps> = ({
  userId,
  yard,
  privileged,
  onboardData,
  showOnly,
  ...containerProps
}) => {
  return (
    <Box display="flex" flexDirection="column" {...containerProps}>
      <Box my={1} px={2} bgcolor="#E5E5E5">
        {renderMedia()}
      </Box>
      <Divider />
    </Box>
  );

  function renderMedia() {
    return (
      <>
        {showOnly === "" ? (
          uploadVariantTags.map((variant, i) => (
            <Box key={`${yard}-${variant}-imageGroup`}>
              <Box py={1}>
                <Typography variant="h5">
                  {capitalizeFirstLetter(variant)}
                </Typography>
              </Box>
              <BasicOnboardMediaGrid
                key={`${variant}-${i}`}
                privileged={privileged}
                userId={userId}
                variant={variant}
                yard={yard}
                py={1}
              />
            </Box>
          ))
        ) : (
          <BasicOnboardMediaGrid
            privileged={privileged}
            userId={userId}
            variant={showOnly}
            yard={yard}
            py={1}
          />
        )}
      </>
    );
  }
};

export { YardInfoGroup };
export default YardInfoGroup;
