import * as React from "react";
import {
  TableCell,
  TableRow,
  makeStyles,
  Box,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { ApolloQueryResult } from "@yardzen-inc/graphql";
import { AssetLibraryRowType, ModelFiletype } from "../../Interfaces";
import getAssetLibraryRow from "../../EmployeeView/AssetLibraries/getRowUtil";
import QuantityInput from "./QuantityInput";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import IconButton from "@material-ui/core/IconButton";
import { formatNumToUSD, YZTypography } from "@yardzen-inc/react-common";
import cleanedDownloadableFileName from "../../EmployeeView/AssetLibraries/Shared/CleanedDownloadableFileName";
import PopupTableItem from "./Shared/PopupTableItem";
import { Close } from "@material-ui/icons";
import { ModelFileDownloadButton } from "../ModelFileDownloadButton";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  itemBtns: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  iconUri: {
    maxWidth: "10rem",
  },
  editItems: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },
  editItem: {
    flex: 1,
    marginBottom: theme.spacing(1),
  },
  saveProgress: {
    display: "flex",
    alignItems: "center",
  },
  expandBtn: {},
}));

interface Props {
  isEven: boolean;
  refetch:
    | ((
        variables?: Partial<Record<string, any>> | undefined
      ) => Promise<ApolloQueryResult<any>>)
    | undefined;
  row: AssetLibraryRowType[][];
  onUpdateQuantity: (id: string, quantity: number) => void;
  idAndQuantityMap: Object;
}

function ElementSelectionTableRow(props: Props) {
  const classes = useRowStyles();

  const row = getAssetLibraryRow(props.row);

  const [open, setOpen] = React.useState(false);

  // @ts-ignore
  const quantity = props.idAndQuantityMap[row.id] || 0;
  const onUpdateQuantity = (qty: number) => {
    props.onUpdateQuantity(row.id, qty);
  };

  const inStockText = (row: any) => {
    if (typeof row.in_stock === "boolean") {
      return row.in_stock ? "Yes" : "No";
    }
    return "--";
  };

  const onExpand = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getCost = (row: any): number => {
    return parseFloat(row.msrp || "0");
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <Box
          display="flex"
          alignItems="center"
          style={{ padding: "0.5rem 0.5rem 0.5rem 1rem" }}
        >
          <YZTypography variant="h6" style={{ flex: "1" }}>
            {row.name}
          </YZTypography>
          <IconButton onClick={() => handleClose()}>
            <Close style={{ fontSize: 17 }} />
          </IconButton>
        </Box>
        <DialogContent>
          <PopupTableItem title="Image">
            <img className={classes.iconUri} src={row.icon_uri} />
          </PopupTableItem>
          <PopupTableItem title="Type">
            {row.product_base_product_type_links
              ?.map((style: any) => style.product_type.name)
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Cost">
            <>{formatNumToUSD(getCost(row))}</>
          </PopupTableItem>
          <PopupTableItem title="Style" ellipsis>
            {row.product_base_product_style_links
              ?.map((style: any) => style.product_style.name)
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Link" ellipsis>
            <a href={row.link} target="_blank">
              {row.link}
            </a>
          </PopupTableItem>
          <PopupTableItem title="Price Tier">
            {row.priceTierByProductPriceTier?.name}
          </PopupTableItem>
          <PopupTableItem title="Vendor">
            {row.vendorByProductVendor?.name}
          </PopupTableItem>
          <PopupTableItem title="Retailer">
            {row.retailerByProductRetailer?.name}
          </PopupTableItem>
          <PopupTableItem title="Collection Name">
            {row.collectionNameByProductCollectionName?.name}
          </PopupTableItem>
          <PopupTableItem title="In Stock">
            <div style={{ color: row.in_stock === false ? "red" : "" }}>
              {inStockText(row)}
            </div>
          </PopupTableItem>
          <PopupTableItem title="Setting">
            {row.settingByProductSetting?.name}
          </PopupTableItem>
          <PopupTableItem title="Material" ellipsis>
            {row.product_base_product_material_links
              ?.map((style: any) => style.product_material.name)
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Color" ellipsis>
            {row.product_base_product_color_links
              ?.map((style: any) => style.product_color.name)
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Sketchup File" ellipsis>
            <ModelFileDownloadButton
              filetype={ModelFiletype.sketchup}
              filenameOrUrl={row.sketchup_file_link}
              downloadableFileName={cleanedDownloadableFileName(row.name)}
            />
          </PopupTableItem>
          <PopupTableItem title="Lumion File" ellipsis>
            <ModelFileDownloadButton
              filetype={ModelFiletype.lumion}
              filenameOrUrl={row.lumion_file_link}
              downloadableFileName={cleanedDownloadableFileName(row.name)}
            />
          </PopupTableItem>
          <PopupTableItem title="Lumion File Name">
            {row.lumion_file_name}
          </PopupTableItem>
        </DialogContent>
      </Dialog>
      <TableRow
        className={classes.root}
        style={{ background: props.isEven ? "#eeeeee" : "" }}
      >
        <TableCell style={{ display: "flex", alignItems: "center" }}>
          <QuantityInput value={quantity} onUpdate={onUpdateQuantity} />
          <IconButton
            className={classes.expandBtn}
            onClick={onExpand}
            size="small"
          >
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        </TableCell>
        {props.row.map((_row) => _row?.[0])}
      </TableRow>
      <TableRow style={{ background: props.isEven ? "#eeeeee" : "" }}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        ></TableCell>
      </TableRow>
    </>
  );
}

export default ElementSelectionTableRow;
