import React, { useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { ModelFiletype } from "../Interfaces";
import { makeStyles } from "@material-ui/core";
import { downloadFile } from "../util/assetLibraryClient";
import { HTTPError } from "../util/errors";
import {
  AssetLibDownloadErrorModal,
  AssetLibDownloadErrorModalProps,
} from "./AssetLibDownloadErrorModal";

interface Props {
  filenameOrUrl: string | undefined;
  filetype: ModelFiletype;
  downloadableFileName: string;
}

const useStyles = makeStyles({
  container: {
    cursor: "pointer",
  },
});

const ModelFileDownloadButton = ({
  filenameOrUrl,
  filetype,
  downloadableFileName,
}: Props) => {
  const [downloadError, setDownloadError] = useState<HTTPError | Error | null>(
    null
  );
  const classes = useStyles();

  if (!filenameOrUrl) {
    return null;
  }
  const filename = (filenameOrUrl.match(/[^\/]+$/) || [])[0];
  // we store references to 3d model files as the filename in postgres. some old files store a url reference to them instead, so we need to check for that

  return (
    <>
      <div className={classes.container} onClick={handleClick}>
        <GetAppIcon />
      </div>
      {renderErrorModal()}
    </>
  );

  function renderErrorModal() {
    if (downloadError) {
      const { status, statusMessage } = downloadError as HTTPError;
      const statusProps: Pick<
        AssetLibDownloadErrorModalProps,
        "statusCode" | "statusMessage"
      > = {
        statusCode: typeof status === "number" ? status.toString() : status,
        statusMessage: statusMessage,
      };

      return (
        <AssetLibDownloadErrorModal
          open
          onClose={() => setDownloadError(null)}
          {...statusProps}
        />
      );
    }
  }

  async function handleClick() {
    if (!filename) {
      console.error(
        `Error downloading ${filetype} file`,
        "Could not parse filename"
      );
      return alert(
        `Error downloading ${filetype} file: could not parse filename`
      );
    }

    try {
      await downloadFile(filename, filetype, downloadableFileName);
    } catch (error) {
      console.error(
        `Error downloading ${filetype} file '${filename}'`,
        error.message
      );
      if (error instanceof Error) {
        setDownloadError(error);
      } else {
        alert(error); // this should really not happen
      }
    }
  }
};

export { ModelFileDownloadButton };
