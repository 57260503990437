import {
  DELETE_ALL_ASSIGNMENT_METADATA,
  INSERT_ASSIGNMENT_ID,
  INSERT_ASSIGNMENT_METADATA_LINKS,
  QUERY_ASSIGNMENT_ALL_METADATA,
  useLazyQuery,
  useMutation,
} from "@yardzen-inc/graphql";
import { DesignAssignment } from "@yardzen-inc/models";
import React from "react";
import { handleMutationError } from "../../EmployeeView/AssetLibraries/elementMutationErrorUtil";
import { getDesignAssignments } from "../firebase/functions/getDesignAssignments";

function useGetPastAssignmentData(projectId: string, assignmentId: string) {
  const [cachedAssignment, setCachedAssignment] = React.useState<any | null>(
    null
  );
  const [candidateAssignments, setCandidateAssignments] = React.useState<
    DesignAssignment[]
  >([]);
  const [readyToCreateAssignmentData, setReadyToCreateAssignmentData] =
    React.useState(false);
  const [creatingAssignmentData, setCreatingAssignmentData] =
    React.useState(false);
  const [carryoverRequired, setCarryoverRequired] = React.useState(false);
  const [carryoverCompleted, setCarryoverCompleted] = React.useState(false);
  const [loadingCarryoverRequirements, setLoadingCarryoverRequirements] =
    React.useState(true);

  const createAssignmentData = () => {
    if (creatingAssignmentData) return;
    setCreatingAssignmentData(true);
    const products = cachedAssignment.assignment_product_base_links.map(
      (link: any) => {
        return {
          product_base_id: link.product_base_id,
          assignment_id: assignmentId,
        };
      }
    );
    const plants = cachedAssignment.assignment_plant_base_links.map(
      (link: any) => {
        return {
          plant_base_id: link.plant_base_id,
          assignment_id: assignmentId,
        };
      }
    );
    const surfaces = cachedAssignment.assignment_material_base_links.map(
      (link: any) => {
        return {
          material_base_id: link.material_base_id,
          assignment_id: assignmentId,
          amount: link.amount,
        };
      }
    );
    const softscapes = cachedAssignment.assignment_softscape_item_links.map(
      (link: any) => {
        return {
          softscape_item_id: link.softscape_item_id,
          assignment_id: assignmentId,
          quantity: link.quantity,
        };
      }
    );

    insertMetadata({
      variables: {
        products,
        plants,
        surfaces,
        softscapes,
      },
    });

    setCarryoverCompleted(true);
  };

  const [insertAssignmentId] = useMutation(INSERT_ASSIGNMENT_ID, {
    onError: handleMutationError,
    update() {
      setReadyToCreateAssignmentData(true);
    },
  });

  React.useEffect(() => {
    if (!readyToCreateAssignmentData || !cachedAssignment) return;
    // without id, and if the graphql strong check fail
    // we'll lose all the metadata in the DB. So better to just fail
    // silently and provide the user an error.
    if (!assignmentId) {
      alert("Something went wrong. Please refresh your screen.");
      return;
    }
    deleteMetadata({
      variables: {
        assignment_id: assignmentId,
      },
    });
  }, [readyToCreateAssignmentData, cachedAssignment]);

  const [insertMetadata] = useMutation(INSERT_ASSIGNMENT_METADATA_LINKS, {
    onError: handleMutationError,
    onCompleted: () => {
      markAssignmentAsChecked();
    },
  });

  const [deleteMetadata] = useMutation(DELETE_ALL_ASSIGNMENT_METADATA, {
    onError: handleMutationError,
    onCompleted: () => {
      createAssignmentData();
    },
  });

  const [getAssignmentAllMetadata, { data }] = useLazyQuery(
    QUERY_ASSIGNMENT_ALL_METADATA,
    {
      fetchPolicy: "network-only",
    }
  );

  const [getPastAssignmentMetadata, { data: pastData }] = useLazyQuery(
    QUERY_ASSIGNMENT_ALL_METADATA,
    {
      fetchPolicy: "network-only",
    }
  );

  const markAssignmentAsChecked = () => {
    window.localStorage.setItem(
      `assignment-metadata-${projectId}-${assignmentId}`,
      "true"
    );
  };

  const checkCurrentAssignmentData = () => {
    if (
      window.localStorage.getItem(
        `assignment-metadata-${projectId}-${assignmentId}`
      )
    ) {
    }
    getAssignmentAllMetadata({
      variables: {
        id: assignmentId,
      },
    });
  };

  const metadataExists = (assignment: any) => {
    return (
      assignment.assignment_material_base_links.length ||
      assignment.assignment_plant_base_links.length ||
      assignment.assignment_product_base_links.length ||
      assignment.assignment_softscape_item_links.length
    );
  };

  const fetchPriorData = async () => {
    try {
      const assignments = await getDesignAssignments(projectId);
      let _candidateAssignments = assignments
        .filter((assignment) => assignment.submitted)
        .sort(
          (a, b) =>
            (b.submittedAt?.seconds ?? 0) - (a.submittedAt?.seconds ?? 0)
        );
      if (!_candidateAssignments.length) {
        // in this scenario, there are no previous revisions, so let's
        // call it quits.
        return markAssignmentAsChecked();
      }
      const targetId = _candidateAssignments[0].id;
      getPastAssignmentMetadata({
        variables: {
          id: targetId,
        },
      });
      // we are slicing it because we just tried the first candidate.
      setCandidateAssignments(_candidateAssignments.slice(1));
    } catch (err) {
      alert("Something went wrong. Please refresh the page.");
    }
  };

  React.useEffect(() => {
    if (!data) return;
    setLoadingCarryoverRequirements(false);
    if (data.assignment_by_pk === null) {
      setCarryoverRequired(true);
      insertAssignmentId({
        variables: {
          id: assignmentId,
        },
      });
    } else {
      setReadyToCreateAssignmentData(true);
    }
    if (data.assignment_by_pk && metadataExists(data.assignment_by_pk)) {
      // in this scenario, designer has logged in
      // to Liisa from a browser that is missing
      // the localStorage metadata, but the assignment
      // is clearly already in progress, so let's not change anything.
      markAssignmentAsChecked();
      return;
    }
    fetchPriorData();
  }, [data]);

  /**
   * Sometimes two revisions get triggered before the designer
   * accesses Liisa. In that case, we want to try to find the last
   * design assignment that had asset selection metadata.
   */
  const fetchMetadataForNextCandidate = () => {
    if (!candidateAssignments.length) {
      markAssignmentAsChecked();
      return;
    }
    const targetId = candidateAssignments[0].id;
    getPastAssignmentMetadata({
      variables: {
        id: targetId,
      },
    });
    // we are slicing it because we just tried the first candidate.
    setCandidateAssignments(candidateAssignments.slice(1));
  };

  React.useEffect(() => {
    if (!pastData) return;
    if (!pastData.assignment_by_pk) {
      fetchMetadataForNextCandidate();
      return;
    }
    const assignment = pastData.assignment_by_pk;
    if (metadataExists(assignment)) {
      setCachedAssignment(assignment);
      return;
    }
    fetchMetadataForNextCandidate();
  }, [pastData]);

  React.useEffect(() => {
    if (!projectId || !assignmentId) return;
    checkCurrentAssignmentData();
  }, [projectId, assignmentId]);

  const getAssignmentData = () => {
    if (pastData?.assignment_by_pk) {
      return pastData.assignment_by_pk;
    }
    if (data?.assignment_by_pk) {
      return data.assignment_by_pk;
    }
    return {
      assignment_material_base_links: [],
      assignment_plant_base_links: [],
      assignment_product_base_links: [],
      assignment_softscape_item_links: [],
    };
  };

  return {
    loadingCarryoverRequirements,
    carryoverRequired,
    carryoverCompleted,
    assignmentData: getAssignmentData(),
  };
}

export default useGetPastAssignmentData;
