import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { ModelFiletype } from "../../Interfaces";
import FormikDeleteFile from "./Shared/FormikDeleteFile";
import FormikUpload from "./Shared/FormikUpload";
import { uploadFile } from "../../util/assetLibraryClient";

const useStyles = makeStyles({
  "3DFileContainer": {
    display: "flex",
    flexDirection: "row",
  },
});

const ModelFileUploadForm = () => {
  const classes = useStyles();

  const filetypes = [
    {
      type: ModelFiletype.vray,
      title: "V-Ray File",
      fieldName: "vray_file_link",
    },
    {
      type: ModelFiletype.lumion,
      title: "Lumion File",
      fieldName: "lumion_file_link",
    },
    {
      type: ModelFiletype.sketchup,
      title: "Sketchup File",
      fieldName: "sketchup_file_link",
    },
    {
      type: ModelFiletype.proxy,
      title: "Proxy File",
      fieldName: "sketchup_proxy_file_link",
    },
  ];

  const handleUpload =
    (filetype: ModelFiletype) =>
    async (file: File): Promise<string> => {
      return await uploadFile(file, filetype);
    };

  return (
    <>
      {filetypes.map(({ type, title, fieldName }) => (
        <Box className={classes["3DFileContainer"]}>
          <FormikUpload
            handleUpload={handleUpload(type)}
            title={title}
            fieldName={fieldName}
            accept={["application/zip"]}
          />
          <FormikDeleteFile fieldName={fieldName} />
        </Box>
      ))}
    </>
  );
};
export { ModelFileUploadForm };
