export const surfaceArrayLinksMap: Record<string, string> = {
  material_style: "material_base_material_style_links",
  material_material: "material_base_material_material_links",
  material_type: "material_base_material_type_links",
  product_region: "material_base_region_links",
};

export const surfaceObjLinksMap: Record<string, string> = {
  material_setting: "setting",
  material_collection_name: "material_collection_name",
  material_price_tier: "material_price_tier",
  material_unit: "unit",
  material_vendor: "material_vendor",
  material_retailer: "material_retailer",
};

export const elementArrayLinksMap: Record<string, string> = {
  product_style: "product_base_product_style_links",
  product_type: "product_base_product_type_links",
  product_material: "product_base_product_material_links",
  product_color: "product_base_product_color_links",
  product_region: "product_base_region_links",
};

export const elementObjLinksMap: Record<string, string> = {
  product_collection_name: "product_collection_name",
  product_image: "image",
  product_manufacturer: "manufacturer",
  product_price_tier: "product_price_tier",
  product_setting: "product_setting",
  product_vendor: "product_vendor",
  product_retailer: "product_retailer",
};

export const plantArrayLinksMap: Record<string, string> = {
  plant_cad_symbol_size: "plant_base_plant_cad_symbol_size_links",
  plant_cad_symbol_spacing: "plant_base_plant_cad_symbol_spacing_links",
  plant_bloom_time: "plant_base_plant_bloom_time_links",
  plant_adapted_habitat: "plant_base_adapted_habitat_links",
  plant_garden_style: "plant_base_plant_garden_style_links",
  plant_height_class: "plant_base_plant_height_class_links",
  plant_width_class: "plant_base_plant_width_class_links",
  plant_leaf_color: "plant_base_plant_leaf_color_links",
  plant_flower_color: "plant_base_plant_flower_color_links",
  plant_fruit_color: "plant_base_plant_fruit_color_links",
  plant_growth_habit: "plant_base_plant_growth_habit_links",
  plant_key_feature: "plant_base_plant_key_feature_links",
  plant_light_needs: "plant_base_plant_light_needs_links",
  plant_native_range: "plant_base_plant_native_range_links",
  plant_red_flag: "plant_base_plant_red_flag_links",
  plant_usda_zone: "plant_base_plant_usda_zone_links",
  plant_vip: "plant_base_plant_vip_links",
  plant_type: "plant_base_plant_type_links",
};

export const plantArrayLinksSubfieldMap: Record<string, string> = {
  id_plant_light_needs: "id_light_needs",
};

export const plantObjLinksMap: Record<string, string> = {
  plant_deciduous_or_evergreen: "deciduous_or_evergreen",
  plant_water_needs: "water_needs",
};
