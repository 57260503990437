import React, { FC } from "react";
import { RClickContextMenuAction } from "./RClickContextMenu";
import { Box, makeStyles, Typography } from "@material-ui/core";

export interface RClickContextMenuItemProps {
  action: RClickContextMenuAction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    border: "solid black 1px",
    borderBottom: "none",
    pointerEvents: "all",
    transition: "background-color 150ms",
    [theme.breakpoints.up("md")]: {
      "&:hover": {
        backgroundColor: "rgba(229, 229, 229, 190)",
      },
    },
  },
}));

const RClickContextMenuItem: FC<RClickContextMenuItemProps> = (props) => {
  const classes = useStyles();

  return (
    <Box
      width="100%"
      className={classes.root}
      p={1}
      onClick={props.action.handler}
    >
      <Typography>{props.action.label}</Typography>
    </Box>
  );
};

export { RClickContextMenuItem };
export default RClickContextMenuItem;
