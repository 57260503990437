import * as React from "react";
import MediaCard, { MediaCardProps } from "./MediaCard";
import { Pages, Delete } from "@material-ui/icons";
import "firebase/compat/database";
import { makeStyles, IconButton, Typography } from "@material-ui/core";
import FullScreenImage from "./FullScreenImage";

export interface AnnotatorCardProps extends MediaCardProps {
  deleteable?: boolean;
  readonly?: boolean;
}

const useStyles = makeStyles((theme) => ({
  openAnnotatorButtonContainer: {
    padding: "0.5rem",
    display: "flex",
    flexFlow: "row-reverse nowrap",
    alignItems: "center",
    flexGrow: 1,
    height: "100%",
    minHeight: "fit-content",
  },
}));

const AnnotatorCard = ({ readonly, ...props }: AnnotatorCardProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const [fadeOut, setFadeOut] = React.useState<boolean>(false);

  return (
    <>
      {open && (
        <FullScreenImage
          open
          media={props.media}
          onClose={() => setOpen(false)}
        />
      )}
      <div style={{ width: "100%", maxWidth: "100%", height: "fit-content" }}>
        <Typography variant="h5" noWrap>
          {props.media.title || props.media.originalFileName}
        </Typography>
        <MediaCard
          {...props}
          style={props.style}
          media={props.media}
          childrenToolBar={<>{renderButtons()}</>}
          noTitle
          out={fadeOut}
        />
      </div>
    </>
  );

  function renderButtons(): React.ReactNode {
    return (
      <div className={classes.openAnnotatorButtonContainer}>
        <IconButton onClick={() => setOpen(true)} title="Open Fullscreen">
          <Pages style={{ color: "white" }} />
        </IconButton>
        {props.deleteable && (
          <IconButton onClick={() => deleteMedia()} title="Open Fullscreen">
            <Delete style={{ color: "white" }} />
          </IconButton>
        )}
      </div>
    );
  }

  function deleteMedia() {
    setFadeOut(true);
    props.media.delete();
  }
};

AnnotatorCard.defaultProps = {
  mediaCardProps: {},
};

export default AnnotatorCard;
