import { DesignAssignmentStatuses } from "@yardzen-inc/models";

const closedStatuses: DesignAssignmentStatuses[] = [
  "completed",
  "postCorrectionHold",
  "postV1Hold",
  "postExtraHold",
];

export function assignmentIsClosed(
  designAssignmentStatus: DesignAssignmentStatuses
): boolean {
  return closedStatuses.includes(designAssignmentStatus);
}
