import React, { useState, useEffect, FC } from "react";
import { Box, Typography } from "@material-ui/core";
import { Profile, Project, Assignment, Agent } from "@yardzen-inc/models";
import moment from "moment";

export interface AssignmentInfoProps {
  clientRecord: Profile;
}

const AssignmentInfo: FC<AssignmentInfoProps> = ({ clientRecord }) => {
  const [modelingAssignment, setModelingAssignment] =
    useState<Assignment | null>(null);
  const [CDMAssignment, setCDMAssignment] = useState<Assignment | null>(null);
  const [modeler, setModeler] = useState<Agent | null>(null);
  const [wesley, setWesley] = useState<Agent | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(onProfileChange, [clientRecord.id]);

  return (
    <>
      {renderModelingCard()} {renderCDMCard()}
    </>
  );

  function onProfileChange(): void {
    getAssignmentStatus();
  }

  async function getAssignmentStatus(): Promise<void> {
    const { id } = clientRecord;
    const project = await Project.fetchWithProfileId(id);
    const assignments = (await project.getAssignments(true)).sort(
      (a, b) => b.createdAt - a.createdAt
    );

    const modelingAssignment =
      assignments.find((assign) => !!assign && assign.type === "modeling") ??
      null;

    let modeler = null;

    if (modelingAssignment?.assignedTo) {
      modeler = await Agent.fetch(modelingAssignment?.assignedTo);
    }

    setModelingAssignment(modelingAssignment);
    setModeler(modeler);

    const CDMAssignment =
      assignments.find((assign) => !!assign && assign.type === "wesley") ??
      null;

    let wesley = null;

    if (CDMAssignment?.assignedTo) {
      wesley = await Agent.fetch(CDMAssignment.assignedTo);
    }

    setCDMAssignment(CDMAssignment);
    setWesley(wesley);

    setLoading(false);
  }

  function renderCDMCard() {
    if (loading) {
      return null;
    }

    let component: React.ReactNode | null = null;

    if (!CDMAssignment) {
      return (
        <Box boxShadow={2} p={2}>
          <Typography variant="h5">CDM assignment not created</Typography>
        </Box>
      );
    }

    if (CDMAssignment.completed) {
      component = (
        <>
          <Typography variant="body1">
            {getAgentLine()}
            Submitted:
            {moment(CDMAssignment.submittedAt).format("MM/DD/YYYY mm:ss")}
          </Typography>
        </>
      );
    } else {
      {
      }
      component = (
        <>
          <Typography variant="body1">{getAgentLine()}</Typography>
          <Typography variant="body1">In progress</Typography>
        </>
      );
    }

    return (
      <Box boxShadow={2} p={2}>
        <Typography variant="h5">CDM Assignment:</Typography>
        {component}
      </Box>
    );

    function getAgentLine() {
      if (!wesley) {
        return null;
      }

      return (
        <Typography variant="body1">{`Assigned to: ${wesley.firstName} ${wesley.lastName}`}</Typography>
      );
    }
  }

  function renderModelingCard() {
    if (loading) {
      return null;
    }

    let component: React.ReactNode | null = null;

    if (!modelingAssignment) {
      return (
        <Box boxShadow={2} p={2}>
          <Typography variant="h5">Modeling assignment not created</Typography>
        </Box>
      );
    }

    if (modelingAssignment.completed) {
      component = (
        <>
          <Typography variant="body1">
            {getAgentLine()}
            Submitted:
            {moment(modelingAssignment.submittedAt).format("MM/DD/YYYY mm:ss")}
          </Typography>
        </>
      );
    } else {
      {
        component = (
          <>
            <Typography variant="body1">{getAgentLine()}</Typography>
            <Typography variant="body1">In progress</Typography>
          </>
        );
      }
    }

    return (
      <Box boxShadow={2} p={2}>
        <Typography variant="h5">Modeling Assignment:</Typography>
        {component}
      </Box>
    );

    function getAgentLine() {
      if (!modeler) {
        return null;
      }

      return (
        <Typography variant="body1">{`Assigned to: ${modeler.firstName} ${modeler.lastName}`}</Typography>
      );
    }
  }
};

export { AssignmentInfo };
export default AssignmentInfo;
