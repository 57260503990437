// row data is stored at index-1 so
// we go through the rows until we find
// a row that has 2 sub-rows
const getAssetLibraryRow = (row: any[]): any => {
  for (let it = 0; it < row.length; it++) {
    if (row[it].length === 2) {
      return row[it]?.[1] || {};
    }
  }
  return {};
};

export default getAssetLibraryRow;
