import * as React from "react";
import { TypeformModel, TypeformUIObject } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export const useTypeforms = (profileId: string): TypeformUIObject[] | null => {
  const [typeforms, setTypeforms] = React.useState<TypeformUIObject[] | null>(
    null
  );

  React.useEffect(subscribeToTypeforms, [profileId]);

  return typeforms;

  function subscribeToTypeforms(): (() => void) | void {
    return firebase
      .firestore()
      .collection("typeforms")
      .where("profileId", "==", profileId)
      .onSnapshot(async (snap) => {
        setTypeforms(await TypeformModel.hydrate(snap.docs));
      });
  }
};

export default useTypeforms;
