import firebase from "firebase/compat/app";
import { Profile, ProfileProperties } from "@yardzen-inc/models";

/**
 *
 * @param cutOffTimeframe `number` of months to go back
 * @returns Profiles missing `latLng`
 */
async function getProfilesWithMissingCoordinates(cutOffTimeframe: number = 6) {
  const selectedCutoffDate = new Date();
  selectedCutoffDate.setMonth(selectedCutoffDate.getMonth() - cutOffTimeframe);
  const profilesWithMissingProperty = await firebase
    .firestore()
    .collection("profiles")
    .where("createdAt", ">=", selectedCutoffDate.toISOString())
    .get();

  return profilesWithMissingProperty.docs
    .map((d) => new Profile({ ...d.data(), id: d.id } as ProfileProperties))
    .filter(filterProfiles);
}

function filterProfiles(profile: Profile) {
  return !(profile as any).isDesigner && !(profile as any).latLng;
}

export { getProfilesWithMissingCoordinates };
