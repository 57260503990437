import React, { FC, useState, ReactNode, useMemo, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import CurrentJobDash from "../../SelfAssignPage/CurrentJobDash";
import { Dialog, Box, CircularProgress, Typography } from "@material-ui/core";
import {
  ProjectManager,
  Assignment,
  AssignmentProperties,
} from "@yardzen-inc/models";
import makeAgentFromPm from "./makeAgentFromPm";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { CDMContextProvider } from "./CDMContextProvider";

export interface AssignementPageProps extends RouteComponentProps {
  pm: ProjectManager;
}

const AssignmentPage: FC<AssignementPageProps> = (props) => {
  const [assignment, setAssignment] = useState<Assignment | null | false>(null);

  const { projectId, assignmentId } = props.match.params as {
    assignmentId?: string;
    projectId?: string;
  };

  const agent = useMemo(() => makeAgentFromPm(props.pm), [props.pm]);

  useEffect(getAssignment, [assignmentId, projectId]);

  return (
    <Dialog open fullScreen>
      <CDMContextProvider>{renderPage()}</CDMContextProvider>
    </Dialog>
  );

  function renderPage(): ReactNode {
    if (assignment) {
      return (
        <CurrentJobDash
          inLiisa={true}
          assignment={assignment}
          agent={agent}
          pm
          disabled={false}
          openAssignmentCount={0}
          requestNextJob={() => {}}
        />
      );
    }

    if (assignment === null) {
      return (
        <Box
          width="100%"
          p={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box
        width="100%"
        p={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography align="center" variant="h4">
          Could not fetch assignment
        </Typography>
      </Box>
    );
  }

  function getAssignment(): void {
    if (!assignmentId || !projectId) {
      console.error("no id");

      return setAssignment(false);
    }

    (async function () {
      try {
        const assignmentSnap = await firebase
          .firestore()
          .collection("projects")
          .doc(projectId)
          .collection("assignments")
          .doc(assignmentId)
          .get();

        const assignment = new Assignment();

        assignment.setProperties(assignmentSnap.data() as AssignmentProperties);
        assignment.id = assignmentSnap.id;

        setAssignment(assignment);
      } catch (err) {
        console.error(err);
        return setAssignment(false);
      }
    })();
  }
};

export { AssignmentPage };
export default AssignmentPage;
