import * as React from "react";
import {
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  INSERT_PRODUCT_BASE_COLOR_LINKS,
  INSERT_PRODUCT_BASE_MATERIAL_LINKS,
  INSERT_PRODUCT_BASE_STYLE_LINKS,
  INSERT_PRODUCT_BASE_TYPE_LINKS,
  PRODUCT_COLLECTION_NAME_SELECT,
  PRODUCT_COLOR_SELECT,
  PRODUCT_MATERIAL_SELECT,
  PRODUCT_PRICE_TIER_SELECT,
  PRODUCT_SETTING_SELECT,
  PRODUCT_STYLE_SELECT,
  PRODUCT_TYPE_SELECT,
  PRODUCT_VENDOR_SELECT,
  useMutation,
} from "@yardzen-inc/graphql";
import InStockSelect from "./InStockSelect";
import { Formik } from "formik";
import {
  IFormikElementErrorProps,
  IFormikElementProps,
} from "../../../Interfaces";
import useUnload from "../../../util/hooks/useUnload";
import SaveInProgressTextConstants from "../../../ConstantValues/SaveInProgressTextConstants";
import AssetFeatureSelect from "../Shared/AssetFeatureSelect";
import { handleMutationError } from "../elementMutationErrorUtil";
import VisibilitySelect from "../Shared/VisibilitySelect";
import { YZButton } from "@yardzen-inc/react-common";
import AssetLibraryImageUpload from "../Shared/AssetLibraryImageUpload";
import {
  PRODUCT_RETAILER_SELECT,
  INSERT_PRODUCT_BASE,
} from "../Shared/graphqlQueries";

const useRowStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "50rem",
  },
  itemBtns: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  saveProgress: {
    display: "flex",
    alignItems: "center",
  },
  iconUri: {
    maxWidth: "7rem",
  },
  editItems: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },
  editItem: {
    flex: 1,
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  longLink: {
    width: "10rem",
    maxWidth: "10rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

interface Props {
  onSave: () => any;
}

const SaveProgressPoint = 100 / 4;

function AddNewAsset(props: Props) {
  const [cachedStyles, setCachedStyles] = React.useState<string[]>([]);
  const [cachedColors, setCachedColors] = React.useState<string[]>([]);
  const [cachedMaterials, setCachedMaterials] = React.useState<string[]>([]);
  const [cachedTypes, setCachedTypes] = React.useState<string[]>([]);
  const [saveProgress, setSaveProgress] = React.useState(0);
  const [imagePath, setImagePath] = React.useState("");

  // NOTE: The reason these "done" states are being used rather
  // than the saveProgress one is because the functions are finishing
  // too close together. The event loop groups them together, causing
  // the saveProgress state to not actually say the right value.
  // We still keep the saveProgress though for the useUnload hook and
  // to give the user a bit of visibility as to the progress.
  const [colorDone, setColorDone] = React.useState(false);
  const [styleDone, setStyleDone] = React.useState(false);
  const [materialDone, setMaterialDone] = React.useState(false);
  const [typeDone, setTypeDone] = React.useState(false);

  React.useEffect(() => {
    if (!materialDone || !styleDone || !colorDone || !typeDone) {
      return;
    }
    props.onSave();
  }, [materialDone, styleDone, colorDone, typeDone]);

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue =
      saveProgress > 0
        ? SaveInProgressTextConstants.SAVE_IN_PROGRESS_WARNING
        : "";
  });

  const [insertColorLinks] = useMutation(INSERT_PRODUCT_BASE_COLOR_LINKS, {
    onError: handleMutationError,
    update(cache, { data: { insert_product_base_product_color_link } }) {
      setSaveProgress(() => saveProgress + SaveProgressPoint);
      setColorDone(true);
    },
  });

  const [insertMaterialLinks] = useMutation(
    INSERT_PRODUCT_BASE_MATERIAL_LINKS,
    {
      onError: handleMutationError,
      onCompleted: () => {
        setMaterialDone(true);
        setSaveProgress(() => saveProgress + SaveProgressPoint);
      },
    }
  );

  const [insertTypeLinks] = useMutation(INSERT_PRODUCT_BASE_TYPE_LINKS, {
    onError: handleMutationError,
    onCompleted: () => {
      setTypeDone(true);
      setSaveProgress(() => saveProgress + SaveProgressPoint);
    },
  });

  const [insertStyleLinks] = useMutation(INSERT_PRODUCT_BASE_STYLE_LINKS, {
    onError: handleMutationError,
    update(cache, { data: { insert_product_base_product_style_link } }) {
      setStyleDone(true);
      setSaveProgress(() => saveProgress + SaveProgressPoint);
    },
  });

  const [insertRow] = useMutation(INSERT_PRODUCT_BASE, {
    onError: handleMutationError,
    update(cache, { data: { insert_product_base_one } }) {
      setSaveProgress(2);
      insertStyleLinks({
        variables: {
          objects: cachedStyles.map((style) => {
            return {
              id_product_base: insert_product_base_one.id,
              id_product_style: style,
            };
          }),
        },
      });
      insertColorLinks({
        variables: {
          objects: cachedColors.map((color) => {
            return {
              id_product_base: insert_product_base_one.id,
              id_product_color: color,
            };
          }),
        },
      });
      insertMaterialLinks({
        variables: {
          objects: cachedMaterials.map((material) => {
            return {
              id_product_base: insert_product_base_one.id,
              id_product_material: material,
            };
          }),
        },
      });
      insertTypeLinks({
        variables: {
          objects: cachedTypes.map((type) => {
            return {
              id_product_base: insert_product_base_one.id,
              id_product_type: type,
            };
          }),
        },
      });
    },
  });

  const classes = useRowStyles();

  const onImageUploaded = (path: string): void => {
    setImagePath(path);
  };

  const onSave = ({
    name,
    inStock,
    iconUri,
    unitCost,
    laborCost,
    msrp,
    vendor,
    retailer,
    type,
    setting,
    colors,
    styles,
    materials,
    link,
    affiliate_link,
    lumion_file_name,
    lumion_file_link,
    sketchup_file_link,
    sketchup_proxy_file_link,
    vray_file_link,
    max_file_link,
    price_tier,
    collection_name,
    visibility,
  }: IFormikElementProps) => {
    setCachedColors(colors);
    setCachedStyles(styles);
    setCachedMaterials(materials);
    setCachedTypes(type);
    setSaveProgress(1);
    insertRow({
      variables: {
        name,
        archived: false,
        in_stock: inStock,
        icon_uri: imagePath,
        description: "",
        unit_cost: Math.round(parseFloat(unitCost) * 100),
        labor_cost: Math.round(parseFloat(laborCost) * 100),
        msrp: msrp ? Math.round(parseFloat(msrp) * 100) : 0,
        product_vendor: vendor,
        product_retailer: retailer,
        product_type: type,
        product_price_tier: price_tier,
        product_collection_name: collection_name,
        product_setting: setting,
        link,
        affiliate_link,
        lumion_file_name,
        lumion_file_link,
        sketchup_file_link,
        proxy_file_link: sketchup_proxy_file_link,
        vray_file_link,
        max_file_link,
        visibility,
      },
    });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h4">
        Add new asset
      </Typography>
      <Formik
        initialValues={{
          name: "",
          iconUri: "",
          unitCost: "",
          laborCost: "",
          msrp: "",
          link: "",
          lumion_file_name: "",
          lumion_file_link: "",
          sketchup_file_link: "",
          sketchup_proxy_file_link: "",
          vray_file_link: "",
          max_file_link: "",
          inStock: true,
          type: [],
          vendor: "",
          retailer: "",
          affiliate_link: "",
          collection_name: "",
          price_tier: "",
          setting: "",
          materials: [],
          colors: [],
          styles: [],
          visibility: "ALL",
        }}
        validate={(values) => {
          const errors: Partial<IFormikElementErrorProps> = {};
          if (!values.name) {
            errors.name = "Required";
          }
          if (!values.iconUri) {
            errors.iconUri = "Required";
          }
          if (!values.unitCost) {
            errors.unitCost = "Required";
          } else if (isNaN(parseFloat(values.unitCost))) {
            errors.unitCost = "Invalid Number";
          }
          if (!values.msrp) {
            errors.msrp = "Required";
          } else if (isNaN(parseFloat(values.msrp))) {
            errors.msrp = "Invalid Number";
          }
          if (!values.vendor) {
            errors.vendor = "Required";
          }
          if (!values.setting) {
            errors.setting = "Required";
          }
          if (!values.collection_name) {
            errors.collection_name = "Required";
          }
          if (!values.price_tier) {
            errors.price_tier = "Required";
          }
          if (!values.materials.length) {
            errors.materials = "At least one required";
          }
          if (!values.colors.length) {
            errors.colors = "At least one required";
          }
          if (!values.styles.length) {
            errors.styles = "At least one required";
          }
          if (!values.type.length) {
            errors.type = "At least one required";
          }
          if (!values.visibility) {
            errors.styles = "Required";
          }

          return errors;
        }}
        onSubmit={(values) => {
          onSave({
            name: values.name,
            inStock: values.inStock,
            iconUri: values.iconUri,
            unitCost: values.unitCost,
            laborCost: values.laborCost,
            msrp: values.msrp,
            vendor: values.vendor,
            retailer: values.retailer,
            type: values.type,
            setting: values.setting,
            materials: values.materials,
            colors: values.colors,
            collection_name: values.collection_name,
            price_tier: values.price_tier,
            styles: values.styles,
            link: values.link,
            affiliate_link: values.affiliate_link,
            lumion_file_name: values.lumion_file_name,
            lumion_file_link: values.lumion_file_link,
            sketchup_file_link: values.sketchup_file_link,
            sketchup_proxy_file_link: values.sketchup_proxy_file_link,
            vray_file_link: values.vray_file_link,
            max_file_link: values.max_file_link,
            visibility: values.visibility,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleReset,
          isSubmitting,
        }) => (
          <div>
            <VisibilitySelect />
            <TextField
              error={!!(errors.name && touched.name)}
              className={classes.editItem}
              onChange={handleChange("name")}
              value={values.name}
              label="Name"
              color="primary"
              required
            />
            <br />
            <AssetLibraryImageUpload
              formikName="iconUri"
              onImageUploaded={onImageUploaded}
            />
            <br />
            <AssetFeatureSelect
              multiple
              query={PRODUCT_TYPE_SELECT}
              queryField="product_type"
              formikName="type"
              label="Type"
            />
            <TextField
              error={!!(errors.unitCost && touched.unitCost)}
              className={classes.editItem}
              onChange={handleChange("unitCost")}
              value={values.unitCost}
              label="Retail Cost"
              color="primary"
              required
            />
            <br />
            <TextField
              error={!!(errors.msrp && touched.msrp)}
              className={classes.editItem}
              onChange={handleChange("msrp")}
              value={values.msrp}
              label="MSRP"
              color="primary"
              required
            />
            <TextField
              error={!!(errors.laborCost && touched.laborCost)}
              className={classes.editItem}
              onChange={handleChange("laborCost")}
              value={values.laborCost}
              label="Labor Cost"
              color="primary"
            />
            <br />
            <AssetFeatureSelect
              query={PRODUCT_STYLE_SELECT}
              queryField="product_style"
              formikName="styles"
              label="Style"
              multiple
            />
            <TextField
              className={classes.editItem}
              onChange={handleChange("link")}
              value={values.link}
              label="Link"
              color="primary"
            />
            <AssetFeatureSelect
              query={PRODUCT_PRICE_TIER_SELECT}
              queryField="product_price_tier"
              formikName="price_tier"
              label="Price Tier"
            />
            <AssetFeatureSelect
              query={PRODUCT_VENDOR_SELECT}
              queryField="product_vendor"
              formikName="vendor"
              label="Vendor"
            />
            <AssetFeatureSelect
              query={PRODUCT_RETAILER_SELECT}
              queryField="product_retailer"
              formikName="retailer"
              label="Retailer"
            />
            <AssetFeatureSelect
              query={PRODUCT_COLLECTION_NAME_SELECT}
              queryField="product_collection_name"
              formikName="collection_name"
              label="Collection Name"
            />
            <InStockSelect />
            <AssetFeatureSelect
              query={PRODUCT_SETTING_SELECT}
              queryField="product_setting"
              formikName="setting"
              label="Setting"
            />
            <AssetFeatureSelect
              query={PRODUCT_MATERIAL_SELECT}
              queryField="product_material"
              formikName="materials"
              label="Material"
              multiple
            />
            <AssetFeatureSelect
              query={PRODUCT_COLOR_SELECT}
              queryField="product_color"
              formikName="colors"
              label="Color"
              multiple
            />
            <br />
            <TextField
              className={classes.editItem}
              onChange={handleChange("affiliate_link")}
              value={values.affiliate_link}
              label="Affiliate Link"
              color="primary"
            />
            <br />
            <TextField
              className={classes.editItem}
              onChange={handleChange("lumion_file_name")}
              value={values.lumion_file_name}
              label="Lumion File Name"
              color="primary"
            />
            <br />
            <TextField
              className={classes.editItem}
              onChange={handleChange("max_file_link")}
              value={values.max_file_link}
              label="MAX File Link"
              color="primary"
            />
            <br />
            <br />
            <br />
            <YZButton
              disabled={isSubmitting}
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
            >
              Submit
            </YZButton>
            &nbsp;&nbsp;
            <YZButton
              disabled={isSubmitting}
              variant="outlined"
              onClick={handleReset}
            >
              Reset
            </YZButton>
            {isSubmitting && (
              <div className={classes.saveProgress}>
                <Typography>{saveProgress.toFixed(0)}%</Typography>
                <CircularProgress size={15} style={{ marginLeft: 5 }} />
              </div>
            )}
          </div>
        )}
      </Formik>
    </div>
  );
}

export default AddNewAsset;
