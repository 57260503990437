import * as React from "react";
import {
  Typography,
  Divider,
  makeStyles,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Addy } from "../util/getAddress";
import { PageComponentProps } from "./CurrentJobPageMap";
import getMapUrl from "../util/googleMapSearch";
import { Map } from "@material-ui/icons";
import mmddyyyy from "./mmddyyyy";
import {
  Assignment,
  ModelingAssignmentDeliverables,
  Media,
} from "@yardzen-inc/models";
import SelfAssignSubmitModal from "./SelfAssignSubmitModal";
import SuccessfulSubmitModal from "./SuccessfulSubmitModal";
import DesignNotes from "./DesignNotes";
import { IdentifyingPaymentId } from "./IdentifyingPaymentId";
import ModelUpload from "./ModelUpload";
import {
  HouseModelDeliverable,
  HouseModel,
} from "../ConstantValues/fileuploadConstants";
import { YZButton } from "@yardzen-inc/react-common";

const useStyles = makeStyles((theme) => ({
  info: {
    display: "flex",
    flexFlow: "row nowrap",
  },
  col: {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: 1,
  },
  textBox: {
    // height: "100px",
    // flexGrow: 1,
  },
  rowColResponsive: {
    display: "flex",
    flexFlow: "column nowrap",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column nowrap",
    },
  },
}));

let saveTimeout: any = null;

interface Props extends PageComponentProps {
  assignment: any;
}

export default (props: Props) => {
  const classes = useStyles();
  const [notes, setNotes] = React.useState<string>("");
  const [model, setModel] = React.useState<Media | null | "loading">("loading");
  const [modelScreenshot, setModelScreenshot] = React.useState<
    Media | null | "loading"
  >("loading");
  const [showSubmit, setShowSubmit] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const address = props.address ? formatAddress(props.address) : null;

  React.useEffect(() => {
    (async function () {
      setModel(await getUploadedModel());
    })();
  }, []);

  React.useEffect(() => {
    (async function () {
      setModelScreenshot(await getUploadedModelScreenshot());
    })();
  }, []);

  React.useEffect(() => {
    saveTimeout = setTimeout(async () => {
      props.assignment.assignmentDeliverables.notes = notes;
      await props.assignment.save();
    }, 300);

    return () => clearTimeout(saveTimeout);
  }, [notes]);

  return (
    <>
      {showSubmit && (
        <SelfAssignSubmitModal
          onClose={() => setShowSubmit(false)}
          onSubmit={() => handleSubmit()}
          submitting={submitting}
        />
      )}
      <SuccessfulSubmitModal
        onClose={() => window.location.reload()}
        open={submitted}
      />
      <br />
      <div className={classes.col} style={{ padding: "1rem" }}>
        <Typography variant="caption">Job Title</Typography>
        <Typography variant="h5">{props.assignment.title}</Typography>
        <br />
        <div className={classes.info}>
          <div className={classes.col}>
            <Typography variant="caption">Client Name</Typography>
            <Typography variant="h5">{`${props.profile.firstName} ${props.profile.lastName}`}</Typography>
            <br />
            <Typography variant="caption">Address</Typography>
            <Typography variant="h5">{address || "Unknown"}</Typography>
            <br />
            <Typography variant="caption">Job Description</Typography>
            <Typography variant="h5">{props.assignment.description}</Typography>
          </div>
          <div className={classes.col}>
            <Typography variant="caption">Package</Typography>
            <Typography variant="h5">
              {props.profile.package || "Unknown"}
            </Typography>
            <br />
            <Typography variant="caption">Lot Size</Typography>
            <Typography variant="h5">
              {props.profile.lotSize || "Unknown"}
            </Typography>
            <br />
            <IdentifyingPaymentId
              profileOrId={props.profile}
              linearProgressStyle={{ maxWidth: "40%" }}
            />
            <br />
            <Typography variant="caption">Segment</Typography>
            <Typography variant="h5">
              {(props.designProfile && props.designProfile.designingFor) ||
                "Unknown"}
            </Typography>
            <br />
            <Typography variant="caption">Due Date</Typography>
            <Typography variant="h5">
              {props.assignment.assignedAt &&
                mmddyyyy(props.assignment.assignedAt + 1000 * 24 * 60 * 60 * 2)}
            </Typography>
            <br />
            {address && (
              <>
                <Typography variant="caption">Google Maps</Typography>
                <IconButton
                  href={getMapUrl(address)}
                  target="_blank"
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  <Map></Map>
                </IconButton>
              </>
            )}
          </div>
        </div>
      </div>
      <br />
      <Divider></Divider>
      <br />
      {props.profile.designerNotes && (
        <>
          <DesignNotes profile={props.profile} />
          <Divider></Divider>
          <br />
        </>
      )}
      <div className={classes.rowColResponsive}>
        <Typography variant="h5">Notes</Typography>
        <br />
        <TextField
          inputProps={{
            style: {
              minHeight: "100px",
            },
          }}
          variant="outlined"
          multiline
          value={notes}
          onChange={({ target: { value } }) => setNotes(value)}
          className={classes.textBox}
        />
        <br />
        <Divider></Divider>
        <br />
        <Typography variant="h5">Model Upload</Typography>
        <br />
        <ModelUpload
          profileId={props.profile.id}
          onReplace={() => deleteAndResetModel(HouseModel.tag)}
          onUploadComplete={async () => {
            await updateStateAction(setModel, getUploadedModel);
          }}
          media={model}
          tag={HouseModel.tag}
          variant={null}
          accept={[".skp"]}
          successMessage="Model Upload Successful"
        />
        <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
        <Typography variant="h5">Model Screenshot Upload</Typography>
        <br />
        <ModelUpload
          profileId={props.profile.id}
          onReplace={() => deleteAndResetModel(HouseModelDeliverable.tag)}
          onUploadComplete={async () => {
            await updateStateAction(
              setModelScreenshot,
              getUploadedModelScreenshot
            );
          }}
          media={modelScreenshot}
          tag={HouseModelDeliverable.tag}
          variant={HouseModelDeliverable.variant}
          accept={[".jpg"]}
          successMessage="Screenshot Upload Successful"
        />
      </div>
      <br />
      <Divider></Divider>
      <br />
      {(!model || !modelScreenshot) && (
        <>
          <ul style={{ color: "red" }}>
            {!model && <li key="requireModel">Model is required</li>}
            {!modelScreenshot && (
              <li key="requireScreenshot">Model screenshot is required</li>
            )}
          </ul>
          <br />
        </>
      )}
      <div className={classes.info} style={{ flexFlow: "row-reverse nowrap" }}>
        <YZButton
          variant="outlined"
          disabled={IsMediaMissing(model) || IsMediaMissing(modelScreenshot)}
          onClick={() => setShowSubmit(true)}
        >
          Submit For Review
        </YZButton>
      </div>
    </>
  );

  async function updateStateAction(
    stateSetter: (
      value: React.SetStateAction<Media | null | "loading">
    ) => void,
    handler: () => Promise<Media | null>
  ) {
    stateSetter("loading");
    stateSetter(await handler());
  }

  function formatAddress(addy: Addy): string {
    return `${addy.street} ${addy.city}, ${addy.state}, ${addy.zip}`;
  }

  function IsMediaMissing(media: Media | null | "loading") {
    return !media && !(media === "loading");
  }

  async function deleteAndResetModel(
    variant: HouseModel.tag | HouseModelDeliverable.tag
  ) {
    if (variant === HouseModel.tag) {
      if (!model || model === "loading") return;

      await model.delete();
      setModel(null);
    } else {
      if (!modelScreenshot || modelScreenshot === "loading") return;

      if (
        (await modelScreenshot.getDocRef().collection("threads").get())
          .empty === false
      ) {
        await modelScreenshot.getDocRef().update({
          tag: "annotated-house-model",
        });
      } else {
        await modelScreenshot.delete();
      }
      setModelScreenshot(null);
    }
  }

  async function getUploadedModelScreenshot() {
    const media = await Assignment.getAllMediaIdsByFileTags(
      [HouseModelDeliverable.tag],
      props.profile.id
    );

    if (media.length) {
      let mp = await Promise.all(media.map(Media.fetchSingle));

      mp = mp.filter(
        (m) =>
          !(m as any).deletedAt && m?.variant === HouseModelDeliverable.variant
      );
      if (mp.length) return mp[0];
    }

    return null;
  }

  async function getUploadedModel(): Promise<Media | null> {
    const media = await Assignment.getAllMediaIdsByFileTags(
      [HouseModel.tag],
      props.profile.id
    );

    if (media.length) {
      // return Media.fetchSingle(media[0]);
      let mp = await Promise.all(media.map(Media.fetchSingle));
      mp = mp.filter(
        (m) =>
          !(m as any).deletedAt && m?.variant !== HouseModelDeliverable.variant
      );

      if (mp.length) return mp[0];
    }

    return null;
  }

  async function handleSubmit() {
    const { assignment } = props;
    if (!submitting) setSubmitting(true);

    const delivs: ModelingAssignmentDeliverables = {
      notes: notes,
      modelId: (model as Media).id,
      modelScreenshotId:
        !!modelScreenshot && typeof modelScreenshot !== "string"
          ? (modelScreenshot as Media).id
          : null,
    };

    // FIXME: conflicts between use of the old models and models from repo are still a problem.
    // casting as any is quick and dirty solution, but not ideal.
    (assignment as any).assignmentDeliverables = delivs;
    (assignment as any).completed = true;

    // saves to lastSubmittedAt instead of overwriting
    // original submitted at date
    if ((assignment as any).submittedAt) {
      (assignment as any).lastSubmittedAt = new Date().getTime();
    } else {
      (assignment as any).submittedAt = new Date().getTime();
    }

    const res = await assignment.save();

    setSubmitting(false);
    setSubmitted(true);

    return res;
  }
};
