/**
 * The amount to multiply by to reserve the base costs from the total budget if
 * yard difficulty is easy.
 */
export const EASY_YARD_DIFFICULTY_BASE_COST_MULTIPLIER = 0.1;

/**
 * The amount to multiply by to reserve the base costs from the total budget if
 * yard difficulty is average.
 */
export const AVERAGE_YARD_DIFFICULTY_BASE_COST_MULTIPLIER = 0.15;

/**
 * The amount to multiply by to reserve the base costs from the total
 * budget if yard difficulty is hard.
 */
export const HARD_YARD_DIFFICULTY_BASE_COST_MULTIPLIER = 0.2;
