import * as React from "react";
import Table from "@material-ui/core/Table";
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Profile } from "@yardzen-inc/models";
import { makeStyles } from "@material-ui/styles";
import MobileListItem from "./MobileListItem";
import { SearchResult } from "./SearchableTable";
import SearchResultRow from "./SearchResultRow";
import { ErrorBoundary } from "../util/ErrorBoundry";

export interface Props {
  records: SearchResult[];
  onRowClick: (clientId: string) => void;
  searchString: string;
}

const useStyles = makeStyles({
  mobileRoot: {
    display: "flex",
    flexFlow: "column nowrap",
    maxWidth: "100vw",
    paddingBottom: "4rem",
  },
  mobileListItem: {
    display: "flex",
    flexFlow: "column nowrap",
    padding: "1rem",
  },
  mobileListRow: {
    display: "flex",
    flexFlow: "row wrap",
    margin: "0.8rem 0",
  },
  mobileListColumn: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "50%",
    maxWidth: "50%",
  },
  mobileFooter: {
    display: "flex",
    flexFlow: "column nowrap",
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  mobileFooterContent: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

function ClientTable(props: Props) {
  const classes = useStyles();
  const smDown = useMediaQuery((theme) =>
    (theme as any).breakpoints.down("sm")
  );

  if (smDown) {
    return (
      <>
        <div className={classes.mobileRoot}>
          <Typography variant="h6">
            {!!props.records.length ? "Clients" : "No Clients Found"}
          </Typography>
          {props.records.map((result: SearchResult) => {
            const record = result.record;
            return (
              <MobileListItem
                onClick={() => props.onRowClick(record.id)}
                record={record as Profile}
                key={record.id}
              />
            );
          })}
        </div>
      </>
    );
  }

  return (
    <div style={{ paddingBottom: "4rem" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Customer name</TableCell>
            <TableCell>Profile Created On || Materials Submitted</TableCell>
            <TableCell>Additional Contacts</TableCell>
            <TableCell>Project Stage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.records.map((result: SearchResult) => {
            return (
              <ErrorBoundary key={`result-${result.record.id}`}>
                <SearchResultRow onClick={props.onRowClick} result={result} />
              </ErrorBoundary>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default ClientTable;
