import firebase from "firebase/compat/app";

const setUserDisabledStatus = firebase
  .functions()
  .httpsCallable("setUserDisabledStatus");

const setUserEnabledApi = (userId: string, disabled: boolean) => {
  setUserDisabledStatus({
    userId,
    disabled,
  });
};

const setUserDisabledApi = (userId: string, disabled: boolean) => {
  setUserDisabledStatus({
    userId,
    disabled,
  });
};

export { setUserEnabledApi, setUserDisabledApi };
