import React, { FC } from "react";
import { Media } from "@yardzen-inc/models";
import { makeStyles, Theme, Grid } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import HEICPlaceHolder from "../HEICPlaceHolder";

export interface ExteriorDesignInspirationMediaProps {
  media: Media;
}

const useStyles = makeStyles((theme: Theme) => ({
  uploadedMedia: {
    width: "100%",
    height: 300,
    objectFit: "cover",
    border: "2px solid #B8C7B8",
    borderRadius: "5px",
  },
}));

const ExteriorDesignInspirationMedia: FC<ExteriorDesignInspirationMediaProps> =
  (props) => {
    const classes = useStyles();
    return (
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={7}>
          {props.media.fileType.includes("hei") ? (
            <div className={classes.uploadedMedia}>
              <HEICPlaceHolder />
            </div>
          ) : (
            <img
              className={classes.uploadedMedia}
              src={props.media.downloadURL}
              alt="Inspiration"
            />
          )}
        </Grid>
        <Grid item xs={5}>
          <YZTypography variant="h5">{props.media.description}</YZTypography>
        </Grid>
      </Grid>
    );
  };

export { ExteriorDesignInspirationMedia };
export default ExteriorDesignInspirationMedia;
