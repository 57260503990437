import * as React from "react";
import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  makeStyles,
  Box,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Grid,
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Formik } from "formik";
import {
  ApolloQueryResult,
  useMutation,
  PLANT_CAD_SYMBOL_SIZE_SELECT,
  PLANT_CAD_SYMBOL_SPACING_SELECT,
  PLANT_KEY_FEATURE_SELECT,
  PLANT_NATIVE_RANGE_SELECT,
  PLANT_RED_FLAG_SELECT,
  PLANT_VIP_SELECT,
  PLANT_GROWTH_HABIT_SELECT,
  PLANT_BLOOM_TIME_SELECT,
  PLANT_DECIDUOUS_OR_EVERGREEN_SELECT,
  PLANT_LEAF_COLOR_SELECT,
  PLANT_FLOWER_COLOR_SELECT,
  PLANT_FRUIT_COLOR_SELECT,
  PLANT_HEIGHT_CLASS_SELECT,
  PLANT_WIDTH_CLASS_SELECT,
  PLANT_MATURE_SIZE_SELECT,
  PLANT_TYPE_SELECT,
  PLANT_ADAPTED_HABITAT_SELECT,
  PLANT_LIGHT_NEEDS_SELECT,
  PLANT_WATER_NEEDS_SELECT,
  PLANT_GARDEN_STYLE_SELECT,
  PLANT_USDA_ZONE_SELECT,
} from "@yardzen-inc/graphql";
import {
  IFormikPlantErrorProps,
  IPlantBasePlantAdaptedHabitatLink,
  IPlantBasePlantBloomTimeLink,
  IPlantBasePlantFlowerFruitColorLink,
  IPlantBasePlantFoliageColorLink,
  IPlantBasePlantGrowthHabitLink,
  IPlantBasePlantKeyFeatureLink,
  IPlantBasePlantLightNeedsLink,
  IPlantBasePlantNativeRangeLink,
  IPlantBasePlantRedFlagLink,
  IPlantBasePlantUsdaZoneLink,
  IPlantBasePlantTypeLink,
  IPlantBasePlantVipLink,
  AssetLibraryRowType,
  IPlantBasePlantGardenStyleLink,
  IPlantBasePlantHeightClassLink,
  IPlantBasePlantWidthClassLink,
  IPlantBasePlantMatureSizeLink,
  IPlantBasePlantLeafColorLink,
  IPlantBasePlantFlowerColorLink,
  IPlantBasePlantFruitColorLink,
  IPlantBasePlantCadSizeLink,
  IPlantBasePlantCadSpacingLink,
  IFormikPlantProps,
  IPlantBasePlantContainerSizeLink,
} from "../../../Interfaces";
import AssetFeatureSelect from "../Shared/AssetFeatureSelect";
import useUnload from "../../../util/hooks/useUnload";
import SaveInProgressTextConstants from "../../../ConstantValues/SaveInProgressTextConstants";
import { handleMutationError } from "../elementMutationErrorUtil";
import VisibilitySelect from "../Shared/VisibilitySelect";
import getAssetLibraryRow from "../getRowUtil";
import { AssetLibraryAlertMessages } from "../Shared/ConstantValues/AssetLibraryAlertMessages";
import AssetLibraryImageUpload from "../Shared/AssetLibraryImageUpload";
import { ModelFileUploadForm } from "../ModelFileUploadForm";
import {
  PLANT_CONTAINER_SIZE_SELECT,
  UPDATE_PLANT_BASE,
  DELETE_PLANT_BASE_PLANT_LINKS,
  UPSERT_PLANT_BASE_LINKS,
  PLANT_RETAILER_SELECT,
} from "../Shared/graphqlQueries";

interface Props {
  isEven: boolean;
  refetch:
    | ((
        variables?: Partial<Record<string, any>> | undefined
      ) => Promise<ApolloQueryResult<any>>)
    | undefined;
  row: AssetLibraryRowType[][];
}

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  itemBtns: {
    display: "flex",
    width: "100%",
  },
  iconUri: {
    maxWidth: "7rem",
  },
  editItem: {
    flex: 1,
    marginBottom: theme.spacing(1),
  },
  longLink: {
    width: "10rem",
    maxWidth: "10rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  saveProgress: {
    display: "flex",
    alignItems: "center",
  },

  "3DFileContainer": {
    display: "flex",
    flexDirection: "row",
  },
}));

function PlantsTableRow(props: Props) {
  const [isArchiving, setIsArchiving] = React.useState(false);
  const [cachedAdaptedHabitat, setCachedAdaptedHabitat] = React.useState<
    string[]
  >([]);

  const [cachedBloomTime, setCachedBloomTime] = React.useState<string[]>([]);
  const [cachedGrowthHabit, setCachedGrowthHabit] = React.useState<string[]>(
    []
  );
  const [cachedType, setCachedType] = React.useState<string[]>([]);
  const [cachedKeyFeature, setCachedKeyFeature] = React.useState<string[]>([]);
  const [cachedLightNeeds, setCachedLightNeeds] = React.useState<string[]>([]);
  const [cachedNativeRange, setCachedNativeRange] = React.useState<string[]>(
    []
  );
  const [cachedRedFlag, setCachedRedFlag] = React.useState<string[]>([]);
  const [cachedUsdazone, setCachedUsdazone] = React.useState<string[]>([]);
  const [cachedContainerSize, setCachedContainerSize] = React.useState<
    string[]
  >([]);
  const [cachedVip, setCachedVip] = React.useState<string[]>([]);
  const [cachedGardenStyle, setCachedGardenStyle] = React.useState<string[]>(
    []
  );
  const [cachedLeafColor, setCachedLeafColor] = React.useState<string[]>([]);
  const [cachedFlowerColor, setCachedFlowerColor] = React.useState<string[]>(
    []
  );
  const [cachedFruitColor, setCachedFruitColor] = React.useState<string[]>([]);
  const [cachedHeightClass, setCachedHeightClass] = React.useState<string[]>(
    []
  );
  const [cachedWidthClass, setCachedWidthClass] = React.useState<string[]>([]);
  const [cachedMatureSize, setCachedMatureSize] = React.useState<string[]>([]);
  const [cachedCadSize, setCachedCadSize] = React.useState<string[]>([]);
  const [cachedCadSpacing, setCachedCadSpacing] = React.useState<string[]>([]);
  const [saveProgress, setSaveProgress] = React.useState(0);

  const row = getAssetLibraryRow(props.row);

  const [imagePath, setImagePath] = React.useState(row.icon_uri);

  React.useEffect(() => {
    if (saveProgress < 100) {
      return;
    }
    setOpen(false);
    if (!props.refetch) {
      return;
    }
    props.refetch();
  }, [saveProgress]);

  const [insertLinks] = useMutation(UPSERT_PLANT_BASE_LINKS, {
    onError: handleMutationError,
    update(cache, { data: { insert_plant_base_one } }) {
      setOpen(false);
      if (!props.refetch) {
        return;
      }
      props.refetch();
    },
  });

  const [deletePlantBaseLinks] = useMutation(DELETE_PLANT_BASE_PLANT_LINKS, {
    onError: handleMutationError,
    onCompleted: () => {
      const variables = {
        adapted_habitat_objects: cachedAdaptedHabitat.map((style) => {
          return {
            id_plant_base: row.id,
            id_plant_adapted_habitat: style,
          };
        }),
        bloom_time_objects: cachedBloomTime.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_bloom_time: color,
          };
        }),
        growth_habit_objects: cachedGrowthHabit.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_growth_habit: color,
          };
        }),
        key_feature_objects: cachedKeyFeature.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_key_feature: color,
          };
        }),
        light_needs_objects: cachedLightNeeds.map((color) => {
          return {
            id_plant_base: row.id,
            id_light_needs: color,
          };
        }),
        native_range_objects: cachedNativeRange.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_native_range: color,
          };
        }),
        red_flag_objects: cachedRedFlag.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_red_flag: color,
          };
        }),
        usda_zone_objects: cachedUsdazone.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_usda_zone: color,
          };
        }),
        container_size_objects: cachedContainerSize.map((containerSize) => {
          return {
            id_plant_base: row.id,
            id_plant_container_size: containerSize,
          };
        }),
        vip_objects: cachedVip.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_vip: color,
          };
        }),
        type_objects: cachedType.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_type: color,
          };
        }),
        garden_style_objects: cachedGardenStyle.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_garden_style: color,
          };
        }),
        height_class_objects: cachedHeightClass.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_height_class: color,
          };
        }),
        width_class_objects: cachedWidthClass.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_width_class: color,
          };
        }),
        mature_size_objects: cachedMatureSize.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_mature_size: color,
          };
        }),
        leaf_color_objects: cachedLeafColor.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_leaf_color: color,
          };
        }),
        flower_color_objects: cachedFlowerColor.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_flower_color: color,
          };
        }),
        fruit_color_objects: cachedFruitColor.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_fruit_color: color,
          };
        }),
        cad_symbol_size_objects: cachedCadSize.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_cad_symbol_size: color,
          };
        }),
        cad_symbol_spacing_objects: cachedCadSpacing.map((color) => {
          return {
            id_plant_base: row.id,
            id_plant_cad_symbol_spacing: color,
          };
        }),
      };
      insertLinks({
        variables,
      });
    },
  });

  const [updateRow] = useMutation(UPDATE_PLANT_BASE, {
    onError: handleMutationError,
    onCompleted: () => {
      deletePlantBaseLinks({
        variables: {
          id: row.id,
        },
      });
      setSaveProgress(2);
    },
  });

  const [archiveRow] = useMutation(UPDATE_PLANT_BASE, {
    onError: handleMutationError,
    onCompleted: () => {
      setOpen(false);
      if (!props.refetch) {
        return null;
      }
      props.refetch();
      return;
    },
  });

  const [open, setOpen] = React.useState(false);

  const classes = useRowStyles();

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue =
      saveProgress > 0
        ? SaveInProgressTextConstants.SAVE_IN_PROGRESS_WARNING
        : "";
  });

  const onUpdateRow = ({
    scientific_name,
    common_name,
    unitCost,
    icon_uri,
    lumion_file_name,
    lumion_file_link,
    sketchup_file_link,
    sketchup_proxy_file_link,
    vray_file_link,
    max_file_link,
    additional_info,
    plant_container_size,
    design_tips,
    red_flag_info,
    plant_cad_symbol_size,
    plant_cad_symbol_spacing,
    plant_water_needs,
    plant_type,
    plant_deciduous_or_evergreen,
    plant_adapted_habitat,
    plant_bloom_time,
    plant_growth_habit,
    plant_key_feature,
    plant_light_needs,
    plant_native_range,
    plant_red_flag,
    plant_usda_zone,
    plant_vip,
    plant_garden_style,
    plant_leaf_color,
    plant_flower_color,
    plant_fruit_color,
    plant_height_class,
    plant_width_class,
    plant_mature_size,
    companion_plants,
    similar_plants,
    visibility,
    plant_retailer,
  }: IFormikPlantProps) => {
    setCachedAdaptedHabitat(plant_adapted_habitat);
    setCachedBloomTime(plant_bloom_time);
    setCachedGrowthHabit(plant_growth_habit);
    setCachedKeyFeature(plant_key_feature);
    setCachedLightNeeds(plant_light_needs);
    setCachedNativeRange(plant_native_range);
    setCachedRedFlag(plant_red_flag);
    setCachedUsdazone(plant_usda_zone);
    setCachedContainerSize(plant_container_size);
    setCachedVip(plant_vip);
    setCachedType(plant_type);
    setCachedGardenStyle(plant_garden_style);
    setCachedLeafColor(plant_leaf_color);
    setCachedFlowerColor(plant_flower_color);
    setCachedFruitColor(plant_fruit_color);
    setCachedHeightClass(plant_height_class);
    setCachedWidthClass(plant_width_class);
    setCachedMatureSize(plant_mature_size);
    setCachedCadSize(plant_cad_symbol_size);
    setCachedCadSpacing(plant_cad_symbol_spacing);
    if (!row.id) {
      // there is an edge case where the ID is undefined and causes
      // unexpected behavior.
      return alert(AssetLibraryAlertMessages.noID);
    }
    const variables: any = {
      id: row.id,
      scientific_name,
      common_name,
      icon_uri: imagePath,
      unit_cost: unitCost ? Math.round(parseFloat(unitCost) * 100) : unitCost,
      sketchup_file_link,
      proxy_file_link: sketchup_proxy_file_link,
      lumion_file_name,
      lumion_file_link,
      max_file_link,
      vray_file_link,
      additional_info,
      design_tips,
      red_flag_info,
      companion_plants,
      similar_plants,
      archived: row.archived,
      visibility,
      plant_retailer,
    };
    if (plant_water_needs) {
      variables.water_needs = plant_water_needs;
    }
    if (plant_deciduous_or_evergreen) {
      variables.deciduous_or_evergreen = plant_deciduous_or_evergreen;
    }
    updateRow({
      variables,
    });
  };

  const onArchiveToggle = () => {
    setIsArchiving(true);
    archiveRow({
      variables: {
        id: row.id,
        scientific_name: row.scientific_name,
        common_name: row.common_name,
        icon_uri: row.icon_uri,
        unit_cost: row.unit_cost,
        lumion_file_name: row.lumion_file_name,
        lumion_file_link: row.lumion_file_link,
        sketchup_file_link: row.sketchup_file_link,
        proxy_file_link: row.sketchup_proxy_file_link,
        max_file_link: row.max_file_link,
        visibility: row.visibility || "ALL",
        vray_file_link: row.vray_file_link,
        additional_info: row.additional_info,
        container_size: row.containerSizeByPlantContainerSize?.id,
        design_tips: row.design_tips,
        red_flag_info: row.red_flag_info,
        cad_symbol_size: row.cadSymbolSizeByPlantCadSymbolSize?.id,
        cad_symbol_spacing: row.cadSymbolSpacingByPlantCadSymbolSpacing?.id,
        water_needs: row.waterNeedsByPlantWaterNeeds?.id,
        mature_width: row.matureWidthByPlantMatureWidth?.id,
        mature_height: row.matureHeightByPlantMatureHeight?.id,
        minimum_space_to_plant:
          row.minimumSpaceToPlantByPlantminimumSpaceToPlant?.id,
        deciduous_or_evergreen:
          row.deciduousOrEvergreenByPlantDeciduousOrEvergreen?.id,
        archived: !row.archived,
        plant_retailer: row.plant_retailer,
      },
    });
  };

  const onImageUploaded = (path: string): void => {
    setImagePath(path);
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        style={{ background: props.isEven ? "#eeeeee" : "" }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {props.row.map((_row) => _row?.[0])}
      </TableRow>
      <TableRow style={{ background: props.isEven ? "#eeeeee" : "" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Formik
              initialValues={{
                unitCost: row.unit_cost
                  ? (parseFloat(row.unit_cost) / 100).toFixed(2)
                  : "0",
                scientific_name: row.scientific_name,
                common_name: row.common_name,
                icon_uri: row.icon_uri,
                lumion_file_name: row.lumion_file_name,
                lumion_file_link: row.lumion_file_link,
                sketchup_file_link: row.sketchup_file_link,
                sketchup_proxy_file_link: row.proxy_file_link,
                max_file_link: row.max_file_link,
                vray_file_link: row.vray_file_link,
                additional_info: row.additional_info,
                plant_container_size:
                  row.plant_base_plant_container_size_links?.map(
                    (link: IPlantBasePlantContainerSizeLink) =>
                      link.plant_container_size.id
                  ),
                plant_type: row.plant_base_plant_type_links?.map(
                  (link: IPlantBasePlantTypeLink) => link.plant_type.id
                ),
                design_tips: row.design_tips,
                red_flag_info: row.red_flag_info,
                plant_cad_symbol_size:
                  row.plant_base_plant_cad_symbol_size_links?.map(
                    (link: IPlantBasePlantCadSizeLink) =>
                      link.plant_cad_symbol_size.id
                  ),
                plant_cad_symbol_spacing:
                  row.plant_base_plant_cad_symbol_spacing_links?.map(
                    (link: IPlantBasePlantCadSpacingLink) =>
                      link.plant_cad_symbol_spacing.id
                  ),
                plant_water_needs: row.waterNeedsByPlantWaterNeeds?.id,
                plant_deciduous_or_evergreen:
                  row.deciduousOrEvergreenByPlantDeciduousOrEvergreen?.id,
                plant_adapted_habitat:
                  row.plant_base_adapted_habitat_links?.map(
                    (link: IPlantBasePlantAdaptedHabitatLink) =>
                      link.plant_adapted_habitat.id
                  ),
                plant_bloom_time: row.plant_base_plant_bloom_time_links?.map(
                  (link: IPlantBasePlantBloomTimeLink) =>
                    link.plant_bloom_time.id
                ),
                plant_flower_fruit_color:
                  row.plant_base_plant_flower_fruit_color_links?.map(
                    (link: IPlantBasePlantFlowerFruitColorLink) =>
                      link.plant_flower_fruit_color.id
                  ),
                plant_foliage_color:
                  row.plant_base_plant_foliage_color_links?.map(
                    (link: IPlantBasePlantFoliageColorLink) =>
                      link.plant_foliage_color.id
                  ),
                plant_growth_habit:
                  row.plant_base_plant_growth_habit_links?.map(
                    (link: IPlantBasePlantGrowthHabitLink) =>
                      link.plant_growth_habit.id
                  ),
                plant_key_feature: row.plant_base_plant_key_feature_links?.map(
                  (link: IPlantBasePlantKeyFeatureLink) =>
                    link.plant_key_feature.id
                ),
                plant_light_needs: row.plant_base_plant_light_needs_links?.map(
                  (link: IPlantBasePlantLightNeedsLink) =>
                    link.plant_light_needs.id
                ),
                plant_native_range:
                  row.plant_base_plant_native_range_links?.map(
                    (link: IPlantBasePlantNativeRangeLink) =>
                      link.plant_native_range.id
                  ),
                plant_red_flag: row.plant_base_plant_red_flag_links?.map(
                  (link: IPlantBasePlantRedFlagLink) => link.plant_red_flag.id
                ),
                plant_usda_zone: row.plant_base_plant_usda_zone_links?.map(
                  (link: IPlantBasePlantUsdaZoneLink) => link.plant_usda_zone.id
                ),
                plant_vip: row.plant_base_plant_vip_links?.map(
                  (link: IPlantBasePlantVipLink) => link.plant_vip.id
                ),
                plant_garden_style:
                  row.plant_base_plant_garden_style_links?.map(
                    (link: IPlantBasePlantGardenStyleLink) =>
                      link.plant_garden_style.id
                  ),
                plant_height_class:
                  row.plant_base_plant_height_class_links?.map(
                    (link: IPlantBasePlantHeightClassLink) =>
                      link.plant_height_class.id
                  ),
                plant_width_class: row.plant_base_plant_width_class_links?.map(
                  (link: IPlantBasePlantWidthClassLink) =>
                    link.plant_width_class.id
                ),
                plant_mature_size: row.plant_base_plant_mature_size_links?.map(
                  (link: IPlantBasePlantMatureSizeLink) =>
                    link.plant_mature_size.id
                ),
                plant_leaf_color: row.plant_base_plant_leaf_color_links?.map(
                  (link: IPlantBasePlantLeafColorLink) =>
                    link.plant_leaf_color.id
                ),
                plant_flower_color:
                  row.plant_base_plant_flower_color_links?.map(
                    (link: IPlantBasePlantFlowerColorLink) =>
                      link.plant_flower_color.id
                  ),
                plant_fruit_color: row.plant_base_plant_fruit_color_links?.map(
                  (link: IPlantBasePlantFruitColorLink) =>
                    link.plant_fruit_color.id
                ),
                similar_plants: row.similar_plants,
                companion_plants: row.companion_plants,
                visibility: row.visibility || "ALL",
                plant_retailer: row.plant_retailer?.id,
              }}
              validate={(values) => {
                const errors: Partial<IFormikPlantErrorProps> = {};
                if (!values.scientific_name) {
                  errors.scientific_name = "Required";
                }
                if (!values.common_name) {
                  errors.common_name = "Required";
                }
                if (!values.icon_uri) {
                  errors.icon_uri = "Required";
                }
                if (!values.visibility) {
                  errors.visibility = "Required";
                }
                return errors;
              }}
              onSubmit={(values) => {
                onUpdateRow({
                  unitCost: values.unitCost,
                  scientific_name: values.scientific_name,
                  common_name: values.common_name,
                  lumion_file_link: values.lumion_file_link,
                  max_file_link: values.max_file_link,
                  icon_uri: values.icon_uri,
                  lumion_file_name: values.lumion_file_name,
                  sketchup_file_link: values.sketchup_file_link,
                  sketchup_proxy_file_link: values.sketchup_proxy_file_link,
                  vray_file_link: values.vray_file_link,
                  additional_info: values.additional_info,
                  plant_container_size: values.plant_container_size,
                  design_tips: values.design_tips,
                  plant_type: values.plant_type,
                  red_flag_info: values.red_flag_info,
                  plant_cad_symbol_size: values.plant_cad_symbol_size,
                  plant_cad_symbol_spacing: values.plant_cad_symbol_spacing,
                  plant_water_needs: values.plant_water_needs,
                  plant_deciduous_or_evergreen:
                    values.plant_deciduous_or_evergreen,
                  plant_adapted_habitat: values.plant_adapted_habitat,
                  plant_bloom_time: values.plant_bloom_time,
                  plant_growth_habit: values.plant_growth_habit,
                  plant_key_feature: values.plant_key_feature,
                  plant_light_needs: values.plant_light_needs,
                  plant_native_range: values.plant_native_range,
                  plant_red_flag: values.plant_red_flag,
                  plant_usda_zone: values.plant_usda_zone,
                  plant_vip: values.plant_vip,
                  companion_plants: values.companion_plants,
                  similar_plants: values.similar_plants,
                  visibility: values.visibility,
                  plant_garden_style: values.plant_garden_style,
                  plant_leaf_color: values.plant_leaf_color,
                  plant_flower_color: values.plant_flower_color,
                  plant_fruit_color: values.plant_fruit_color,
                  plant_height_class: values.plant_height_class,
                  plant_width_class: values.plant_width_class,
                  plant_mature_size: values.plant_mature_size,
                  plant_retailer: values.plant_retailer,
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <>
                  <Box margin={1}>
                    <Grid container spacing={2}>
                      <Grid item md={3}>
                        <TextField
                          error={!!(errors.common_name && touched.common_name)}
                          className={classes.editItem}
                          onChange={handleChange("common_name")}
                          value={values.common_name}
                          label="Common Name"
                          color="primary"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          error={
                            !!(
                              errors.scientific_name && touched.scientific_name
                            )
                          }
                          className={classes.editItem}
                          onChange={handleChange("scientific_name")}
                          value={values.scientific_name}
                          label="Scientific Name"
                          color="primary"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetLibraryImageUpload
                          formikName="icon_uri"
                          onImageUploaded={onImageUploaded}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_GARDEN_STYLE_SELECT}
                          queryField="plant_garden_style"
                          formikName="plant_garden_style"
                          label="Planting Style"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_TYPE_SELECT}
                          queryField="plant_type"
                          formikName="plant_type"
                          label="Type"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_ADAPTED_HABITAT_SELECT}
                          queryField="plant_adapted_habitat"
                          formikName="plant_adapted_habitat"
                          label="Adapted Habitat"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_LIGHT_NEEDS_SELECT}
                          queryField="plant_light_needs"
                          formikName="plant_light_needs"
                          label="Light Needs"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          query={PLANT_WATER_NEEDS_SELECT}
                          queryField="plant_water_needs"
                          formikName="plant_water_needs"
                          label="Water Needs"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          query={PLANT_DECIDUOUS_OR_EVERGREEN_SELECT}
                          queryField="plant_deciduous_or_evergreen"
                          formikName="plant_deciduous_or_evergreen"
                          label="Deciduous or Evergreen"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          query={PLANT_RETAILER_SELECT}
                          queryField="plant_retailer"
                          formikName="plant_retailer"
                          label="Retailer"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_HEIGHT_CLASS_SELECT}
                          queryField="plant_height_class"
                          formikName="plant_height_class"
                          label="Height Class"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_WIDTH_CLASS_SELECT}
                          queryField="plant_width_class"
                          formikName="plant_width_class"
                          label="Width Class"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_MATURE_SIZE_SELECT}
                          queryField="plant_mature_size"
                          formikName="plant_mature_size"
                          label="Mature Size"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_NATIVE_RANGE_SELECT}
                          queryField="plant_native_range"
                          formikName="plant_native_range"
                          label="Native Range"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_BLOOM_TIME_SELECT}
                          queryField="plant_bloom_time"
                          formikName="plant_bloom_time"
                          label="Bloom Time"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_RED_FLAG_SELECT}
                          queryField="plant_red_flag"
                          formikName="plant_red_flag"
                          label="Red Flag"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          error={
                            !!(errors.red_flag_info && touched.red_flag_info)
                          }
                          className={classes.editItem}
                          onChange={handleChange("red_flag_info")}
                          value={values.red_flag_info}
                          label="Red Flag Info"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_VIP_SELECT}
                          queryField="plant_vip"
                          formikName="plant_vip"
                          label="VIP"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_KEY_FEATURE_SELECT}
                          queryField="plant_key_feature"
                          formikName="plant_key_feature"
                          label="Key Feature"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          error={
                            !!(
                              errors.additional_info && touched.additional_info
                            )
                          }
                          className={classes.editItem}
                          onChange={handleChange("additional_info")}
                          value={values.additional_info}
                          label="Additional Info"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          error={!!(errors.design_tips && touched.design_tips)}
                          className={classes.editItem}
                          onChange={handleChange("design_tips")}
                          value={values.design_tips}
                          label="Design Tips"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_GROWTH_HABIT_SELECT}
                          queryField="plant_growth_habit"
                          formikName="plant_growth_habit"
                          label="Growth Habit"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          error={!!(errors.unitCost && touched.unitCost)}
                          className={classes.editItem}
                          onChange={handleChange("unitCost")}
                          value={values.unitCost}
                          label="Unit Cost"
                          color="primary"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_CONTAINER_SIZE_SELECT}
                          queryField="plant_container_size"
                          formikName="plant_container_size"
                          label="Container Size"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          className={classes.editItem}
                          onChange={handleChange("lumion_file_name")}
                          value={values.lumion_file_name}
                          label="Lumion File Name"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          error={
                            !!(errors.max_file_link && touched.max_file_link)
                          }
                          className={classes.editItem}
                          onChange={handleChange("max_file_link")}
                          value={values.max_file_link}
                          label="MAX File"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_CAD_SYMBOL_SIZE_SELECT}
                          queryField="plant_cad_symbol_size"
                          formikName="plant_cad_symbol_size"
                          label="CAD Symbol ~Size~"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_CAD_SYMBOL_SPACING_SELECT}
                          queryField="plant_cad_symbol_spacing"
                          formikName="plant_cad_symbol_spacing"
                          label="CAD Symbol ~Spacing~"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_USDA_ZONE_SELECT}
                          queryField="plant_usda_zone"
                          formikName="plant_usda_zone"
                          label="USDA Zone"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_LEAF_COLOR_SELECT}
                          queryField="plant_leaf_color"
                          formikName="plant_leaf_color"
                          label="Leaf Color"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_FLOWER_COLOR_SELECT}
                          queryField="plant_flower_color"
                          formikName="plant_flower_color"
                          label="Flower Color"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <AssetFeatureSelect
                          multiple
                          query={PLANT_FRUIT_COLOR_SELECT}
                          queryField="plant_fruit_color"
                          formikName="plant_fruit_color"
                          label="Fruit Color"
                          optional
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          className={classes.editItem}
                          onChange={handleChange("companion_plants")}
                          value={values.companion_plants}
                          label="Companion Plants"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          className={classes.editItem}
                          onChange={handleChange("similar_plants")}
                          value={values.similar_plants}
                          label="Similar Plants"
                          color="primary"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={3}>
                        <VisibilitySelect />
                      </Grid>
                    </Grid>
                    <ModelFileUploadForm />
                  </Box>
                  <div className={classes.itemBtns}>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                      onClick={() => handleSubmit()}
                      style={{ marginRight: "0.5rem" }}
                    >
                      Save Changes
                    </Button>
                    {isSubmitting && (
                      <div className={classes.saveProgress}>
                        <CircularProgress size={15} style={{ marginLeft: 5 }} />
                        <Typography>
                          {Math.min(saveProgress, 100).toFixed(0)}%
                        </Typography>
                      </div>
                    )}
                    {row.archived ? (
                      <Button
                        variant="contained"
                        disabled={isSubmitting || isArchiving}
                        onClick={onArchiveToggle}
                      >
                        Un-archive
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        disabled={isSubmitting || isArchiving}
                        onClick={onArchiveToggle}
                      >
                        Archive
                      </Button>
                    )}
                  </div>
                  <br />
                  <br />
                </>
              )}
            </Formik>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default PlantsTableRow;
