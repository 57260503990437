export enum ClientTableMediaRoute {
  inspiration = "inspiration",
  plans = "plans",
  functionAndFlow = "function-and-flow",
  brief = "brief",
  v1DesignNotes = "revision-notes",
  wesleyAnnotations = "wesley-annotations",
  model = "model",
  workingFiles = "working-files",
  sharedMedia = "shared-media",
  takeOffs = "take-offs",
  propertyAnnotations = "propertyAnnotations",
}
