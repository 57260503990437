import React, { FC } from "react";
import { PageComponentProps } from "./CurrentJobPageMap";
import { DesignAssignment } from "@yardzen-inc/models";
import { WrappedDesignerSummaryPageTabsAndContent } from "./WrappedDesignerSummaryPageTabsAndContent";

export type WrappedDesignerSummaryPageProps = PageComponentProps & {
  assignment: DesignAssignment;
};

const WrappedDesignerSummaryPageWithCallback: FC<WrappedDesignerSummaryPageProps> =
  (props) => {
    const ComponentPlusCallback = React.useCallback(
      () => <WrappedDesignerSummaryPageTabsAndContent {...props} />,
      [props.assignment]
    );

    return <ComponentPlusCallback />;
  };

export { WrappedDesignerSummaryPageWithCallback };
