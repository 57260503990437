import React, {
  FC,
  memo,
  useState,
  ReactNode,
  useEffect,
  useRef,
  useMemo,
} from "react";
import DesignBriefEntry, {
  DesignBriefEntryProperties,
} from "./util/DesignBriefEntry";
import { Box, Typography } from "@material-ui/core";
import DesignBriefChecklistItem from "./DesignBriefChecklistItem";
import { DesignBriefApi, DesignBriefEntryType } from "./util";
import RClickContextMenu from "./RClickContextMenu";
import GenericConfirm from "../GenericConfirm";
import AddButton from "./AddButton";
import DesignBriefEntryEditModal from "./DesignBriefEntryEditModal";

export interface DesignBriefChecklistGroupProps {
  title: string;
  entries: DesignBriefEntry<any>[];
  api: DesignBriefApi;
  pm?: boolean;
}

export interface ContextMenuPropsPartial {
  coords: {
    top: number;
    left: number;
  };
  entry: DesignBriefEntry<any>;
}

const DesignBriefChecklistGroup: FC<DesignBriefChecklistGroupProps> = memo(
  (props) => {
    const [entries, setEntries] = useState<DesignBriefEntry<any>[]>(() =>
      sortEntries(props.entries)
    );

    const [contextMenuPartial, setContextMenuPartial] =
      useState<ContextMenuPropsPartial | null>(null);

    useEffect(handleContextMenuValueChange, [contextMenuPartial]);

    const [confirmDeletion, setConfirmDeletion] =
      useState<DesignBriefEntry<any> | null>(null);
    const [editEntry, setEditEntry] = useState<DesignBriefEntry<any> | null>(
      null
    );

    const [disableAdd, setDisableAdd] = useState<boolean>(false);

    const amountOfEntries = useMemo(() => entries.length, [entries]);

    const lastRightClickedEntryRef = useRef<DesignBriefEntry<any> | null>(null);

    useEffect(handleEntriesChange, [props.entries]);

    if (!props.pm && !amountOfEntries) {
      return null;
    }

    return (
      <>
        {!!(props.pm && confirmDeletion) && (
          <GenericConfirm
            open
            title="Are you sure you would like to delete this checklist item?"
            onClose={() => setConfirmDeletion(null)}
            onSubmit={async () => {
              await deleteEntry(confirmDeletion);
              setConfirmDeletion(null);
            }}
          />
        )}
        {!!(props.pm && editEntry) && (
          <DesignBriefEntryEditModal
            open
            entry={editEntry}
            handleUpdate={handleEditUpdate}
            onClose={() => setEditEntry(null)}
          />
        )}
        {props.pm && (
          <RClickContextMenu
            open={!!contextMenuPartial?.coords}
            anchorReference="anchorPosition"
            anchorPosition={contextMenuPartial?.coords}
            onClose={() => setContextMenuPartial(null)}
            actions={[
              {
                handler: () =>
                  setConfirmDeletion(
                    lastRightClickedEntryRef.current as DesignBriefEntry<any>
                  ),
                label: "delete",
                key: "dbr_context-delete",
              },
              {
                handler: () =>
                  setEditEntry(
                    lastRightClickedEntryRef.current as DesignBriefEntry<any>
                  ),
                label: "edit",
                key: "dbr_context-edit",
              },
            ]}
          />
        )}
        <Box display="flex" flexDirection="column" py={1}>
          <Box pb={1}>
            <Typography>{props.title}</Typography>
          </Box>
          <Box>{renderEntries()}</Box>
          {props.pm && (
            <Box>
              <AddButton onClick={addNewEntry} disabled={disableAdd} />
            </Box>
          )}
        </Box>
      </>
    );

    async function handleEditUpdate(updatedEntry: DesignBriefEntry<any>) {
      // to avoid firestore crying about undefined values
      const updatePromise = new Promise<void>(async (resolve, reject) => {
        const sanitizdEntryProperties = {};
        try {
          Object.keys(updatedEntry).forEach((key) => {
            // @ts-ignore
            sanitizdEntryProperties[key] = updatedEntry[key] ?? null;
          });

          await props.api.updateEntry(
            sanitizdEntryProperties as DesignBriefEntry<any>
          );

          resolve();
        } catch (error) {
          reject(error);
        }
      });

      const entryIndex = entries.findIndex((e) => e.id === updatedEntry.id);

      entries[entryIndex] = updatedEntry;
      setEntries([...entries]);

      return updatePromise;
    }

    function sortEntries(
      entries: DesignBriefEntry<any>[]
    ): DesignBriefEntry<any>[] {
      if (entries?.sort) {
        return entries.sort((a, b) => a.order - b.order);
      }

      return [];
    }

    function renderEntries(): ReactNode[] {
      return entries.map((e, i) => {
        return (
          <DesignBriefChecklistItem
            selected={e.id === contextMenuPartial?.entry?.id}
            handleOpenContextMenu={props.pm ? handleOpenContextMenu : void 0}
            moveDown={
              i < amountOfEntries - 1 ? () => reorder(i, i + 1) : void 0
            }
            moveUp={i > 0 ? () => reorder(i, i - 1) : void 0}
            entry={e}
            key={e.id}
            api={props.api}
            pm={props.pm}
          />
        );
      });
    }

    async function reorder(indexA: number, indexB: number): Promise<void> {
      if (indexA === indexB) {
        throw new Error(
          "tried to reorder Checklist items, but got identical index values"
        );
      }

      const entryA = entries[indexA];
      const entryB = entries[indexB];

      if (!entryA) {
        throw new Error(`entry at index: ${indexA} does not exist`);
      }

      if (!entryB) {
        throw new Error(`entry at index: ${indexB} does not exist`);
      }

      if (indexA > indexB) {
        if (indexB === 0) {
          entryA.order = 0.5 * entryB.order;
        } else {
          const entryC = entries[indexB - 1];
          entryA.order = entryC.order + 0.5 * (entryB.order - entryC.order);
        }
      } else {
        if (indexB === amountOfEntries - 1) {
          entryA.order = entryB.order + 100;
        } else {
          const entryC = entries[indexB + 1];
          entryA.order = entryB.order + 0.5 * (entryC.order - entryB.order);
        }
      }

      const updatePromise = props.api.updateEntry({
        id: entryA.id,
        order: entryA.order,
      });

      setEntries(sortEntries([...entries]));

      return updatePromise;
    }

    function handleEntriesChange(): void {
      if (entries !== props.entries) {
        setEntries(sortEntries(props.entries));
      }
    }

    async function deleteEntry(entry: DesignBriefEntry<any>) {
      const deletionPromise = props.api.deleteEntry(entry);

      const eIndex = entries.findIndex(
        (stateEntry) => stateEntry.id === entry.id
      );

      if (eIndex < 0) {
        throw new Error(`
          tried to delete a design brief entry not present in state:
          ${JSON.stringify(entry, null, 2)}
        `);
      }

      entries.splice(eIndex, 1);
      setEntries([...entries]);
      return deletionPromise;
    }

    function handleOpenContextMenu(
      e: React.MouseEvent<HTMLElement>,
      entry: DesignBriefEntry<any>
    ): void {
      e.preventDefault();
      e.stopPropagation();

      lastRightClickedEntryRef.current = entry;

      setContextMenuPartial({
        coords: { left: e.clientX, top: e.clientY },
        entry,
      });
    }

    function handleContextMenuValueChange(): (() => void) | void {
      if (!!contextMenuPartial?.coords) {
        document.body.addEventListener("click", clickAwayListener);
        document.body.addEventListener("contextmenu", clickAwayCtxMenuListener);

        return () => {
          document.body.removeEventListener("click", clickAwayListener);
          document.body.removeEventListener(
            "contextmenu",
            clickAwayCtxMenuListener
          );
        };
      }

      function clickAwayListener(e: MouseEvent): void {
        setContextMenuPartial(null);
      }

      function clickAwayCtxMenuListener(e: MouseEvent): void {
        e.preventDefault();
        setContextMenuPartial(null);
      }
    }

    async function addNewEntry(): Promise<void> {
      const entryProps: DesignBriefEntryProperties<any> = {
        group: transformTitle(props.title),
        type: DesignBriefEntryType.TEXT,
        value: "",
        marked: false,
        order: amountOfEntries ? entries[amountOfEntries - 1].order + 100 : 0,
      };

      setDisableAdd(true);

      const newEntry = await props.api.createEntry(entryProps);

      setEntries([...entries, newEntry]);

      setDisableAdd(false);
    }

    function transformTitle(title: string) {
      return title.split(" ").join("_");
    }
  }
);

export { DesignBriefChecklistGroup };
export default DesignBriefChecklistGroup;
