import { gql } from "@yardzen-inc/graphql";

export const PRODUCT_REGION_SELECT = gql`
  query {
    product_region {
      id
      name
      archived
    }
  }
`;

export const PRODUCT_REGION_BY_NAME = gql`
  query ProductRegionByName($name: String) {
    product_region(where: { name: { _eq: $name } }) {
      id
      name
    }
  }
`;

export const PRODUCT_BASE = gql`
  query ProductBase(
    $offset: Int
    $archived: Boolean
    $condition: product_base_bool_exp!
    $orderBy: [product_base_order_by!]
    $limit: Int = 100
  ) {
    product_base(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: $condition
    ) {
      name
      id
      in_stock
      icon_uri
      description
      archived
      unit_cost
      labor_cost
      msrp
      priceTierByProductPriceTier {
        name
        id
      }
      collectionNameByProductCollectionName {
        name
        id
      }
      product_vendor
      product_type
      product_setting
      product_vendor
      product_retailer
      product_base_product_type_links {
        product_type {
          name
          id
        }
      }
      product_base_product_style_links {
        product_style {
          name
          id
        }
      }
      product_base_product_material_links {
        product_material {
          name
          id
        }
      }
      settingByProductSetting {
        id
        name
      }
      product_base_product_color_links {
        product_color {
          name
          id
        }
      }
      fbx_file_link
      link
      lumion_file_link
      lumion_file_name
      max_file_link
      vendorByProductVendor {
        id
        name
      }
      retailerByProductRetailer {
        id
        name
      }
      affiliate_link
      sketchup_file_link
      proxy_file_link
      vray_file_link
      visibility
      product_base_region_links {
        product_region {
          id
          name
          archived
        }
      }
    }
  }
`;

export const MATERIAL_BASE = gql`
  query MaterialBase(
    $offset: Int
    $orderBy: [material_base_order_by!]
    $condition: material_base_bool_exp!
    $limit: Int = 100
  ) {
    material_base(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: $condition
    ) {
      description
      name
      id
      in_stock
      icon_uri
      archived
      unit_cost
      labor_cost
      priceTierByMaterialPriceTier {
        name
        id
      }
      collectionNameByMaterialCollectionName {
        name
        id
      }
      materialTypeByMaterialType {
        name
        id
      }
      material_base_material_type_links {
        material_type {
          name
          id
        }
      }
      material_vendor
      material_retailer
      type
      setting
      material_base_material_style_links {
        material_style {
          name
          id
        }
      }
      material_base_material_material_links {
        material_material {
          name
          id
        }
      }
      unitByUnit {
        name
        id
      }
      settingByMaterialSetting {
        id
        name
      }
      link
      vendorByMaterialVendor {
        id
        name
      }
      retailerByMaterialRetailer {
        id
        name
      }
      lumion_file_name
      lumion_file_link
      sketchup_file_link
      proxy_file_link
      vray_file_link
      max_file_link
      visibility
      material_base_region_links {
        product_region {
          id
          name
          archived
        }
      }
      material_base_paver_application_links {
        paver_application {
          id
          name
        }
      }
      material_size {
        id
        name
      }
      material_finish {
        id
        name
      }
      material_base_material_color_links {
        material_color {
          id
          name
        }
      }
      material_pattern {
        id
        name
      }
    }
  }
`;

export const UPSERT_ASSIGNMENT_MATERIAL_BASE_PAINT_LINK = gql`
  mutation InsertAssignmentMaterialBasePaintLink(
    $assignment_id: String
    $material_base_id: uuid
    $amount: Int
  ) {
    insert_assignment_material_base_link(
      objects: [
        {
          assignment_id: $assignment_id
          material_base_id: $material_base_id
          amount: $amount
        }
      ]
      on_conflict: {
        constraint: assignment_material_base_link_assignment_id_material_base_id_ke
        update_columns: [amount]
      }
    ) {
      affected_rows
      returning {
        assignment_id
        material_base_id
        amount
      }
    }
  }
`;

export const PLANT_CONTAINER_SIZE_SELECT = gql`
  query {
    plant_container_size {
      id
      name
      archived
    }
  }
`;

export const PLANT_BASE = gql`
  query PlantBase(
    $offset: Int
    $orderBy: [plant_base_order_by!]
    $condition: plant_base_bool_exp!
    $limit: Int = 100
  ) {
    plant_base(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: $condition
    ) {
      id
      archived
      scientific_name
      common_name
      icon_uri
      average_height
      average_width
      additional_info
      exact_size
      design_tips
      red_flag_info
      unit_cost
      waterNeedsByPlantWaterNeeds {
        name
        id
      }
      matureWidthByPlantMatureWidth {
        name
        id
      }
      matureHeightByPlantMatureHeight {
        name
        id
      }
      minimumSpaceToPlantByPlantminimumSpaceToPlant {
        name
        id
      }
      deciduousOrEvergreenByPlantDeciduousOrEvergreen {
        name
        id
      }
      retailerByPlantRetailer {
        name
        id
      }
      plant_base_adapted_habitat_links {
        plant_adapted_habitat {
          name
          id
        }
      }
      plant_base_plant_type_links {
        plant_type {
          name
          id
        }
      }
      plant_base_plant_bloom_time_links {
        plant_bloom_time {
          name
          id
        }
      }
      plant_base_plant_flower_fruit_color_links {
        plant_flower_fruit_color {
          name
          id
        }
      }
      plant_base_plant_foliage_color_links {
        plant_foliage_color {
          name
          id
        }
      }
      plant_base_plant_growth_habit_links {
        plant_growth_habit {
          name
          id
        }
      }
      plant_base_plant_key_feature_links {
        plant_key_feature {
          name
          id
        }
      }
      plant_base_plant_light_needs_links {
        plant_light_needs {
          name
          id
        }
      }
      plant_base_plant_native_range_links {
        plant_native_range {
          name
          id
        }
      }
      plant_base_plant_palette_links {
        plant_palette {
          name
          id
        }
      }
      plant_base_plant_red_flag_links {
        plant_red_flag {
          name
          id
        }
      }
      plant_base_plant_usda_zone_links {
        plant_usda_zone {
          name
          id
        }
      }
      plant_base_plant_vip_links {
        plant_vip {
          name
          id
        }
      }
      plant_base_plant_garden_style_links {
        plant_garden_style {
          name
          id
        }
      }
      plant_base_plant_source_links {
        plant_source {
          name
          id
        }
      }
      plant_base_plant_height_class_links {
        plant_height_class {
          name
          id
        }
      }
      plant_base_plant_width_class_links {
        plant_width_class {
          name
          id
        }
      }
      plant_base_plant_mature_size_links {
        plant_mature_size {
          name
          id
        }
      }
      plant_base_plant_leaf_color_links {
        plant_leaf_color {
          name
          id
        }
      }
      plant_base_plant_fruit_color_links {
        plant_fruit_color {
          name
          id
        }
      }
      plant_base_plant_flower_color_links {
        plant_flower_color {
          name
          id
        }
      }
      plant_base_plant_cad_symbol_size_links {
        plant_cad_symbol_size {
          name
          id
        }
      }
      plant_base_plant_cad_symbol_spacing_links {
        plant_cad_symbol_spacing {
          name
          id
        }
      }
      plant_base_plant_container_size_links {
        plant_container_size {
          name
          id
        }
      }
      lumion_file_link
      lumion_file_name
      max_file_link
      sketchup_file_link
      proxy_file_link
      vray_file_link
      zip_file_link
      visibility
      similar_plants
      companion_plants
      plant_retailer
    }
  }
`;

export const PLANT_BASE_AGGREGATE = gql`
  query PlantBaseAggregate(
    $archived: Boolean
    $condition: plant_base_bool_exp!
  ) {
    plant_base_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
`;

export const UPDATE_PLANT_BASE = gql`
  mutation UpdatePlantBase(
    $id: uuid
    $scientific_name: String
    $common_name: String
    $average_height: String
    $average_width: String
    $icon_uri: String
    $sketchup_file_link: String
    $proxy_file_link: String
    $vray_file_link: String
    $lumion_file_link: String
    $lumion_file_name: String
    $max_file_link: String
    $zip_file_link: String
    $additional_info: String
    $exact_size: String
    $design_tips: String
    $red_flag_info: String
    $cad_symbol_size: uuid
    $cad_symbol_spacing: uuid
    $water_needs: uuid
    $deciduous_or_evergreen: uuid
    $archived: Boolean
    $unit_cost: bigint
    $similar_plants: String
    $companion_plants: String
    $visibility: String
    $plant_retailer: uuid
  ) {
    update_plant_base(
      where: { id: { _eq: $id } }
      _set: {
        companion_plants: $companion_plants
        similar_plants: $similar_plants
        scientific_name: $scientific_name
        common_name: $common_name
        unit_cost: $unit_cost
        average_height: $average_height
        average_width: $average_width
        icon_uri: $icon_uri
        sketchup_file_link: $sketchup_file_link
        proxy_file_link: $proxy_file_link
        vray_file_link: $vray_file_link
        lumion_file_link: $lumion_file_link
        lumion_file_name: $lumion_file_name
        max_file_link: $max_file_link
        zip_file_link: $zip_file_link
        additional_info: $additional_info
        exact_size: $exact_size
        design_tips: $design_tips
        red_flag_info: $red_flag_info
        cad_symbol_size: $cad_symbol_size
        cad_symbol_spacing: $cad_symbol_spacing
        water_needs: $water_needs
        deciduous_or_evergreen: $deciduous_or_evergreen
        archived: $archived
        visibility: $visibility
        plant_retailer: $plant_retailer
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_PLANT_BASE_PLANT_LINKS = gql`
  mutation DeletePlantBaseLinks($id: uuid) {
    delete_plant_base_adapted_habitat_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_bloom_time_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_growth_habit_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_key_feature_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_light_needs_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_native_range_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_red_flag_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_usda_zone_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_container_size_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_vip_link(where: { id_plant_base: { _eq: $id } }) {
      affected_rows
    }
    delete_plant_base_plant_type_link(where: { id_plant_base: { _eq: $id } }) {
      affected_rows
    }
    delete_plant_base_plant_garden_style_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_width_class_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_height_class_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_mature_size_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_leaf_color_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_flower_color_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_fruit_color_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_cad_symbol_size_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_plant_base_plant_cad_symbol_spacing_link(
      where: { id_plant_base: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const UPSERT_PLANT_BASE_LINKS = gql`
  mutation InsertPlantBaseLinks(
    $adapted_habitat_objects: [plant_base_adapted_habitat_link_insert_input!]!
    $bloom_time_objects: [plant_base_plant_bloom_time_link_insert_input!]!
    $growth_habit_objects: [plant_base_plant_growth_habit_link_insert_input!]!
    $key_feature_objects: [plant_base_plant_key_feature_link_insert_input!]!
    $light_needs_objects: [plant_base_plant_light_needs_link_insert_input!]!
    $native_range_objects: [plant_base_plant_native_range_link_insert_input!]!
    $red_flag_objects: [plant_base_plant_red_flag_link_insert_input!]!
    $usda_zone_objects: [plant_base_plant_usda_zone_link_insert_input!]!
    $container_size_objects: [plant_base_plant_container_size_link_insert_input!]!
    $vip_objects: [plant_base_plant_vip_link_insert_input!]!
    $type_objects: [plant_base_plant_type_link_insert_input!]!
    $garden_style_objects: [plant_base_plant_garden_style_link_insert_input!]!
    $height_class_objects: [plant_base_plant_height_class_link_insert_input!]!
    $width_class_objects: [plant_base_plant_width_class_link_insert_input!]!
    $mature_size_objects: [plant_base_plant_mature_size_link_insert_input!]!
    $leaf_color_objects: [plant_base_plant_leaf_color_link_insert_input!]!
    $flower_color_objects: [plant_base_plant_flower_color_link_insert_input!]!
    $fruit_color_objects: [plant_base_plant_fruit_color_link_insert_input!]!
    $cad_symbol_spacing_objects: [plant_base_plant_cad_symbol_spacing_link_insert_input!]!
    $cad_symbol_size_objects: [plant_base_plant_cad_symbol_size_link_insert_input!]!
  ) {
    insert_plant_base_adapted_habitat_link(
      objects: $adapted_habitat_objects
      on_conflict: {
        constraint: plant_base_adapted_habitat_link_pkey
        update_columns: id_plant_adapted_habitat
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_bloom_time_link(
      objects: $bloom_time_objects
      on_conflict: {
        constraint: plant_base_plant_bloom_time_link_pkey
        update_columns: id_plant_bloom_time
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_growth_habit_link(
      objects: $growth_habit_objects
      on_conflict: {
        constraint: plant_base_plant_growth_habit_link_pkey
        update_columns: id_plant_growth_habit
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_key_feature_link(
      objects: $key_feature_objects
      on_conflict: {
        constraint: plant_base_plant_key_feature_link_pkey
        update_columns: id_plant_key_feature
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_light_needs_link(
      objects: $light_needs_objects
      on_conflict: {
        constraint: plant_base_light_needs_link_pkey
        update_columns: id_light_needs
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_native_range_link(
      objects: $native_range_objects
      on_conflict: {
        constraint: plant_base_plant_native_range_link_pkey
        update_columns: id_plant_native_range
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_red_flag_link(
      objects: $red_flag_objects
      on_conflict: {
        constraint: plant_base_plant_red_flag_pkey
        update_columns: id_plant_red_flag
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_usda_zone_link(
      objects: $usda_zone_objects
      on_conflict: {
        constraint: plant_base_plant_usda_zone_link_pkey
        update_columns: id_plant_usda_zone
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_container_size_link(
      objects: $container_size_objects
      on_conflict: {
        constraint: plant_base_plant_container_size_link_pkey
        update_columns: id_plant_container_size
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_vip_link(
      objects: $vip_objects
      on_conflict: {
        constraint: plant_base_plant_vip_link_pkey
        update_columns: id_plant_vip
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_type_link(
      objects: $type_objects
      on_conflict: {
        constraint: plant_base_plant_type_link_pkey
        update_columns: id_plant_type
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_garden_style_link(
      objects: $garden_style_objects
      on_conflict: {
        constraint: plant_base_plant_garden_style_link_pkey
        update_columns: id_plant_garden_style
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_height_class_link(
      objects: $height_class_objects
      on_conflict: {
        constraint: plant_base_plant_height_class_link_pkey
        update_columns: id_plant_height_class
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_width_class_link(
      objects: $width_class_objects
      on_conflict: {
        constraint: plant_base_plant_width_class_link_pkey
        update_columns: id_plant_width_class
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_mature_size_link(
      objects: $mature_size_objects
      on_conflict: {
        constraint: plant_base_plant_mature_size_link_pkey
        update_columns: id_plant_mature_size
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_leaf_color_link(
      objects: $leaf_color_objects
      on_conflict: {
        constraint: plant_base_plant_leaf_color_link_pkey
        update_columns: id_plant_leaf_color
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_flower_color_link(
      objects: $flower_color_objects
      on_conflict: {
        constraint: plant_base_plant_flower_color_link_pkey
        update_columns: id_plant_flower_color
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_fruit_color_link(
      objects: $fruit_color_objects
      on_conflict: {
        constraint: plant_base_plant_fruit_color_link_pkey
        update_columns: id_plant_fruit_color
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_cad_symbol_size_link(
      objects: $cad_symbol_size_objects
      on_conflict: {
        constraint: plant_base_plant_cad_symbol_size_link_pkey
        update_columns: id_plant_cad_symbol_size
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
    insert_plant_base_plant_cad_symbol_spacing_link(
      objects: $cad_symbol_spacing_objects
      on_conflict: {
        constraint: plant_base_plant_cad_symbol_spacing_link_pkey
        update_columns: id_plant_cad_symbol_spacing
      }
    ) {
      affected_rows
      returning {
        id_plant_base
      }
    }
  }
`;

export const MATERIAL_RETAILER_SELECT = gql`
  query MaterialRetailer($archived: Boolean) {
    material_retailer(where: { archived: { _eq: false } }) {
      id
      name
      archived
    }
  }
`;

export const MATERIAL_RETAILER_BY_NAME = gql`
  query MaterialRetailerByName($name: String) {
    material_retailer(
      where: { name: { _eq: $name }, archived: { _eq: false } }
    ) {
      id
      name
    }
  }
`;

export const PRODUCT_RETAILER_SELECT = gql`
  query ProductRetailer($archived: Boolean) {
    product_retailer(where: { archived: { _eq: false } }) {
      id
      name
      archived
    }
  }
`;

export const PRODUCT_RETAILER_BY_NAME = gql`
  query ProductRetailerByName($name: String) {
    product_retailer(
      where: { name: { _eq: $name }, archived: { _eq: false } }
    ) {
      id
      name
    }
  }
`;

export const PLANT_RETAILER_SELECT = gql`
  query PlantRetailer($archived: Boolean) {
    plant_retailer(where: { archived: { _eq: false } }) {
      id
      name
      archived
    }
  }
`;

export const PLANT_RETAILER_BY_NAME = gql`
  query PlantRetailerByName($name: String) {
    plant_retailer(where: { name: { _eq: $name }, archived: { _eq: false } }) {
      id
      name
    }
  }
`;

export const INSERT_PRODUCT_BASE = gql`
  mutation InsertProductBase(
    $name: String
    $archived: Boolean
    $in_stock: Boolean
    $icon_uri: String
    $description: String
    $unit_cost: bigint
    $labor_cost: bigint
    $msrp: bigint
    $product_vendor: uuid
    $product_retailer: uuid
    $product_price_tier: uuid
    $product_collection_name: uuid
    $product_setting: uuid
    $link: String
    $sketchup_file_link: String
    $proxy_file_link: String
    $vray_file_link: String
    $affiliate_link: String
    $lumion_file_link: String
    $lumion_file_name: String
    $fbx_file_link: String
    $max_file_link: String
    $visibility: String
  ) {
    insert_product_base_one(
      object: {
        name: $name
        archived: $archived
        in_stock: $in_stock
        icon_uri: $icon_uri
        description: $description
        unit_cost: $unit_cost
        labor_cost: $labor_cost
        msrp: $msrp
        product_vendor: $product_vendor
        product_retailer: $product_retailer
        product_price_tier: $product_price_tier
        product_collection_name: $product_collection_name
        product_setting: $product_setting
        link: $link
        affiliate_link: $affiliate_link
        lumion_file_name: $lumion_file_name
        sketchup_file_link: $sketchup_file_link
        proxy_file_link: $proxy_file_link
        vray_file_link: $vray_file_link
        lumion_file_link: $lumion_file_link
        fbx_file_link: $fbx_file_link
        max_file_link: $max_file_link
        visibility: $visibility
      }
    ) {
      id
      name
      archived
    }
  }
`;

export const UPDATE_PRODUCT_BASE = gql`
  mutation UpdateProductBase(
    $id: uuid
    $name: String
    $icon_uri: String
    $unit_cost: bigint
    $labor_cost: bigint
    $msrp: bigint
    $archived: Boolean
    $product_vendor: uuid
    $product_retailer: uuid
    $product_price_tier: uuid
    $product_collection_name: uuid
    $product_setting: uuid
    $link: String
    $sketchup_file_link: String
    $proxy_file_link: String
    $affiliate_link: String
    $vray_file_link: String
    $lumion_file_link: String
    $lumion_file_name: String
    $fbx_file_link: String
    $max_file_link: String
    $in_stock: Boolean
    $visibility: String
  ) {
    update_product_base(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        archived: $archived
        icon_uri: $icon_uri
        unit_cost: $unit_cost
        labor_cost: $labor_cost
        msrp: $msrp
        product_vendor: $product_vendor
        product_retailer: $product_retailer
        product_price_tier: $product_price_tier
        product_collection_name: $product_collection_name
        product_setting: $product_setting
        link: $link
        affiliate_link: $affiliate_link
        sketchup_file_link: $sketchup_file_link
        proxy_file_link: $proxy_file_link
        vray_file_link: $vray_file_link
        lumion_file_link: $lumion_file_link
        lumion_file_name: $lumion_file_name
        fbx_file_link: $fbx_file_link
        max_file_link: $max_file_link
        in_stock: $in_stock
        visibility: $visibility
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_MATERIAL_BASE = gql`
  mutation UpdateMaterialBase(
    $id: uuid
    $name: String
    $icon_uri: String
    $unit_cost: bigint
    $labor_cost: bigint
    $archived: Boolean
    $material_type: uuid
    $material_vendor: uuid
    $material_retailer: uuid
    $material_price_tier: uuid
    $material_collection_name: uuid
    $material_setting: uuid
    $unit: uuid
    $link: String
    $lumion_file_name: String
    $sketchup_file_link: String
    $proxy_file_link: String
    $vray_file_link: String
    $lumion_file_link: String
    $max_file_link: String
    $in_stock: Boolean
    $description: String
    $visibility: String
  ) {
    update_material_base(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        description: $description
        archived: $archived
        icon_uri: $icon_uri
        unit_cost: $unit_cost
        labor_cost: $labor_cost
        unit: $unit
        type: $material_type
        material_vendor: $material_vendor
        material_retailer: $material_retailer
        material_collection_name: $material_collection_name
        material_price_tier: $material_price_tier
        setting: $material_setting
        link: $link
        lumion_file_name: $lumion_file_name
        sketchup_file_link: $sketchup_file_link
        proxy_file_link: $proxy_file_link
        vray_file_link: $vray_file_link
        lumion_file_link: $lumion_file_link
        max_file_link: $max_file_link
        in_stock: $in_stock
        visibility: $visibility
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const INSERT_MATERIAL_BASE = gql`
  mutation InsertMaterialBase(
    $name: String
    $archived: Boolean
    $in_stock: Boolean
    $icon_uri: String
    $unit_cost: bigint
    $labor_cost: bigint
    $unit: uuid
    $material_vendor: uuid
    $material_retailer: uuid
    $material_price_tier: uuid
    $material_collection_name: uuid
    $material_type: uuid
    $material_setting: uuid
    $link: String
    $lumion_file_name: String
    $sketchup_file_link: String
    $proxy_file_link: String
    $vray_file_link: String
    $lumion_file_link: String
    $max_file_link: String
    $description: String
    $visibility: String
  ) {
    insert_material_base_one(
      object: {
        name: $name
        description: $description
        archived: $archived
        in_stock: $in_stock
        icon_uri: $icon_uri
        unit: $unit
        unit_cost: $unit_cost
        labor_cost: $labor_cost
        material_vendor: $material_vendor
        material_retailer: $material_retailer
        material_price_tier: $material_price_tier
        material_collection_name: $material_collection_name
        type: $material_type
        setting: $material_setting
        link: $link
        lumion_file_name: $lumion_file_name
        sketchup_file_link: $sketchup_file_link
        proxy_file_link: $proxy_file_link
        vray_file_link: $vray_file_link
        lumion_file_link: $lumion_file_link
        max_file_link: $max_file_link
        visibility: $visibility
      }
    ) {
      id
      name
      archived
    }
  }
`;

export const INSERT_PLANT_BASE = gql`
  mutation InsertPlantBase(
    $scientific_name: String
    $common_name: String
    $average_height: String
    $average_width: String
    $icon_uri: String
    $sketchup_file_link: String
    $proxy_file_link: String
    $vray_file_link: String
    $lumion_file_link: String
    $lumion_file_name: String
    $max_file_link: String
    $zip_file_link: String
    $additional_info: String
    $container_size: String
    $exact_size: String
    $design_tips: String
    $red_flag_info: String
    $cad_symbol_size: uuid
    $cad_symbol_spacing: uuid
    $water_needs: uuid
    $deciduous_or_evergreen: uuid
    $unit_cost: bigint
    $visibility: String
    $similar_plants: String
    $companion_plants: String
    $plant_retailer: uuid
  ) {
    insert_plant_base_one(
      object: {
        scientific_name: $scientific_name
        common_name: $common_name
        unit_cost: $unit_cost
        average_height: $average_height
        average_width: $average_width
        icon_uri: $icon_uri
        sketchup_file_link: $sketchup_file_link
        proxy_file_link: $proxy_file_link
        vray_file_link: $vray_file_link
        lumion_file_link: $lumion_file_link
        lumion_file_name: $lumion_file_name
        max_file_link: $max_file_link
        zip_file_link: $zip_file_link
        additional_info: $additional_info
        container_size: $container_size
        exact_size: $exact_size
        design_tips: $design_tips
        red_flag_info: $red_flag_info
        cad_symbol_size: $cad_symbol_size
        cad_symbol_spacing: $cad_symbol_spacing
        water_needs: $water_needs
        deciduous_or_evergreen: $deciduous_or_evergreen
        archived: false
        visibility: $visibility
        similar_plants: $similar_plants
        companion_plants: $companion_plants
        plant_retailer: $plant_retailer
      }
    ) {
      id
      scientific_name
      archived
    }
  }
`;
