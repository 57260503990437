import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { ReactElement } from "react";
import { YZTypography, YZAccordionChipProps } from "@yardzen-inc/react-common";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: "80%",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    fontWeight: 800,
  },
  noDataChip: {
    display: "flex",
    alignItems: "center",
    background: "#ededed",
    padding: "0.5rem",
    borderRadius: theme.typography.pxToRem(5),
  },
  noDataChipIcon: {
    color: "#f0dab8",
    marginRight: "0.2rem",
  },
  accordionDetails: {},
  mainGrid: {
    background: "#FAFAFA",
  },
  subHeading: {
    color: "#808080",
    textAlign: "right",
  },
  standoutText: {
    background: "#F2F2F2",
  },
  text: {},

  table: {
    minWidth: 650,
  },
  image: {
    maxHeight: "8rem",
  },
}));

export interface YZItemsAccordionField {
  header: string;
  fieldName: string;
  headerAlign?: "left" | "center" | "right";
  customRenderer?: (row: any) => ReactElement;
}

interface Props {
  title: string;
  chip: ReactElement<YZAccordionChipProps>;
  rows: any[];
  fields: YZItemsAccordionField[];
  loading?: boolean;
}

function YZItemsAccordion(props: Props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = () => {
    setExpanded(() => !expanded);
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        disabled={props.loading}
      >
        <AccordionSummary
          className={classes.accordionDetails}
          expandIcon={props.loading ? <CircularProgress /> : <ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <YZTypography type="serif" className={classes.heading}>
            {props.title}
          </YZTypography>
          {props.loading || props.chip}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <TableContainer>
            <Table
              size="small"
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {props.fields.map((field, index) => (
                    <TableCell
                      key={field.header}
                      align={
                        index === 0 ? "inherit" : field.headerAlign || "right"
                      }
                    >
                      {field.header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.rows.map((row) => (
                  <TableRow key={row.id}>
                    {props.fields.map((field, _index) => {
                      if (field.fieldName === "image") {
                        return (
                          <TableCell align="right">
                            <img
                              className={classes.image}
                              src={row[field.fieldName]}
                            />
                          </TableCell>
                        );
                      }

                      if (field.customRenderer) {
                        return (
                          <TableCell
                            align={_index === 0 ? "inherit" : "right"}
                            component="th"
                            scope="row"
                          >
                            {(function () {
                              try {
                                return field.customRenderer(
                                  row[field.fieldName]
                                );
                              } catch (error) {
                                console.error(error);
                                return `Failed to render: ${
                                  row[field.fieldName]
                                }`;
                              }
                            })()}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell
                          align={_index === 0 ? "inherit" : "right"}
                          component="th"
                          scope="row"
                        >
                          {row[field.fieldName]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export { YZItemsAccordion };
export default YZItemsAccordion;
