import { chunk } from "./chunk";
import { runAsyncBatch } from "./runAsyncBatch";

interface RunQueuedAsyncBatchJobs {
  <t extends unknown>(
    batchSize: number,
    jobs: (() => Promise<t>)[],
    batchCompleteCallback?: (
      results: t[]
    ) => void | null | boolean | Promise<void | null | boolean>
  ): Promise<t[]>;
}

/*
  runQueuedAsyncBatchJobs will run asynchronous functions of type <() => Promise<t extends unknown>>
  in batches of size <batchSize>, and return a flattened array of type <t exttends unknown>.

  On completion of each job, the function will try to execute the  optional batchCompleteCallback.
  If the callback explitly returns false, the rest of the queued job will not be processed, and the
  function will return immediately.

  note: error handling should be done within your jobs.
*/
const runQueuedAsyncBatchJobs: RunQueuedAsyncBatchJobs = async <t>(
  batchSize: number,
  jobs: (() => Promise<t>)[],
  batchCompleteCallback?: (results: t[]) => false | any
) => {
  const chunks = chunk(batchSize, jobs);
  const result: t[] = [];

  for (let c of chunks) {
    const resultingChunk = await runAsyncBatch(c);
    result.push(...resultingChunk);

    if (!!batchCompleteCallback) {
      // if the callback exists and returns an explicit false, abort
      const shouldContinue = await batchCompleteCallback(resultingChunk);
      if (shouldContinue === false) {
        break;
      }
    }
  }

  return result;
};

export default runQueuedAsyncBatchJobs;
export { runQueuedAsyncBatchJobs };
