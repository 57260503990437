import * as React from "react";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

interface Props extends IconButtonProps {
  iconProps?: SvgIconProps;
}

const ExitButton = ({ iconProps, ...props }: Props) => (
  <IconButton {...props}>
    <Close
      {...(iconProps as SvgIconProps)}
      style={{ color: "white", ...((iconProps as SvgIconProps).style || {}) }}
    />
  </IconButton>
);

ExitButton.defaultProps = {
  iconProps: {
    style: {},
  },
};

export default ExitButton;
