import React, { FC, ReactNodeArray, useMemo } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { useBudgetChecklistResponseItemChoiceIdByProjectIdQuery } from "@yardzen-inc/graphql";
import {
  CircularProgress,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import BudgetItemSelectionListItem from "./BudgetItemSelectionListItem";

export interface BudgetItemSelectionListProps extends BoxProps {
  projectId: string;
  noIconImage?: boolean;
}

const BudgetItemSelectionList: FC<BudgetItemSelectionListProps> = ({
  projectId,
  noIconImage,
  ...containerProps
}) => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const { data, loading, error } =
    useBudgetChecklistResponseItemChoiceIdByProjectIdQuery({
      variables: {
        projectId,
      },
      fetchPolicy: "network-only",
    });

  const responses = useMemo(() => {
    if (data?.budget_checklist_response) {
      return [...data.budget_checklist_response].sort(
        (a, b) => (a.order ?? 0) - (b.order ?? 0)
      );
    }

    return null;
  }, [data]);

  if ((loading || !data) && !error) {
    return (
      <Box width="100%" display="flex" justifyContent="center" p={2}>
        <CircularProgress></CircularProgress>
      </Box>
    );
  }

  if (!responses?.length) {
    return <Typography>No budget checklist responses found</Typography>;
  }

  return (
    <Box
      {...containerProps}
      display="grid"
      gridTemplateColumns={getGridTemplateColumns()}
    >
      {renderResponseSelection()}
    </Box>
  );

  function getGridTemplateColumns() {
    if (mdDown) {
      return "100%";
    }

    if (lgDown) {
      return "50% 50%";
    }

    // return "33% 33% 33%";
    // TODO: add prop that disables three column grid
    return "50% 50%";
  }

  function renderResponseSelection(): ReactNodeArray | null {
    if (!responses) {
      return null;
    }

    return responses.map((item, i) => {
      return (
        <Box width="100%" p={2} key={item.id}>
          <BudgetItemSelectionListItem
            budgetChecklistResponse={item}
            idx={i}
            noIconImage={noIconImage}
          />
        </Box>
      );
    });
  }
};

export { BudgetItemSelectionList };
export default BudgetItemSelectionList;
