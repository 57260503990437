import * as React from "react";
import {
  makeStyles,
  Divider,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";
import { Filter, FilterPartial } from "./Filter";

const useStyles = makeStyles({
  root: { display: "flex", flexFlow: "column nowrap", flexShrink: 0 },
  row: {
    display: "flex",
    flexFlow: "row wrap",
  },
  input: {
    marginRight: "1rem",
  },
});

interface Props {
  filter: Filter;
  onChange: (f: FilterPartial) => void;
}

export default ({ filter, onChange }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <form className={classes.row} onSubmit={handleSubmit}>
        <FormControl style={{ marginLeft: "1rem", marginRight: "1rem" }}>
          <Tooltip title="Completed assignments are not ovedue">
            <InputLabel>Overdue</InputLabel>
          </Tooltip>
          <Select
            id="demo-simple-select-helper"
            value={filter.overdue}
            onChange={({ target: { value: overdue } }) =>
              onChange({ overdue: overdue as any })
            }
          >
            <MenuItem value={"any"}>
              <em>Either</em>
            </MenuItem>
            <MenuItem value={"true"}>Overdue</MenuItem>
            <MenuItem value={"false"}>Not overdue</MenuItem>
          </Select>
          <FormHelperText></FormHelperText>
        </FormControl>
        <FormControl style={{ marginLeft: "1rem", marginRight: "1rem" }}>
          <InputLabel>Assignment Status</InputLabel>
          <Select
            id="demo-simple-select-helper"
            value={filter.assigned}
            onChange={({ target: { value: assigned } }) =>
              onChange({ assigned: assigned as any })
            }
          >
            <MenuItem value={"any"}>
              <em>Either</em>
            </MenuItem>
            <MenuItem value={"true"}>Assigned</MenuItem>
            <MenuItem value={"false"}>Unassigned</MenuItem>
          </Select>
          <FormHelperText></FormHelperText>
        </FormControl>
        <FormControl style={{ marginLeft: "1rem", marginRight: "1rem" }}>
          <InputLabel>Completed</InputLabel>
          <Select
            id="demo-simple-select-helper"
            value={filter.completed}
            onChange={({ target: { value: completed } }) =>
              onChange({ completed: completed as any })
            }
          >
            <MenuItem value={"any"}>
              <em>Either</em>
            </MenuItem>
            <MenuItem value={"true"}>Complete</MenuItem>
            <MenuItem value={"false"}>Incomplete</MenuItem>
          </Select>
          <FormHelperText></FormHelperText>
        </FormControl>
        <FormControl style={{ marginLeft: "1rem", marginRight: "1rem" }}>
          <InputLabel>Listed</InputLabel>
          <Select
            id="demo-simple-select-helper"
            value={filter.listed}
            onChange={({ target: { value: listed } }) =>
              onChange({ listed: listed as any })
            }
          >
            <MenuItem value={"any"}>
              <em>Either</em>
            </MenuItem>
            <MenuItem value={"true"}>Listed</MenuItem>
            <MenuItem value={"false"}>Unlisted</MenuItem>
          </Select>
          <FormHelperText></FormHelperText>
        </FormControl>
        <FormControl style={{ marginLeft: "1rem", marginRight: "1rem" }}>
          <InputLabel>Type</InputLabel>
          <Select
            id="demo-simple-select-helper"
            value={filter.type}
            onChange={({ target: { value: type } }) =>
              onChange({ type: type as any })
            }
          >
            <MenuItem value={"none"}>
              <em>Any</em>
            </MenuItem>
            <MenuItem value={"wesley"}>Wesley</MenuItem>
            <MenuItem value={"modeling"}>Modeling</MenuItem>
            <MenuItem value={"design"}>Design</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ marginLeft: "1rem", marginRight: "1rem" }}>
          <InputLabel>Order</InputLabel>
          <Select
            style={{ width: "max-content" }}
            value={filter.order}
            onChange={({ target: { value: order } }) =>
              onChange({ order: order as any })
            }
          >
            <MenuItem value={"desc"}>Newest First</MenuItem>
            <MenuItem value={"asc"}>Oldest First</MenuItem>
          </Select>
          <FormHelperText></FormHelperText>
        </FormControl>
      </form>
      <br />
      <Divider />
      <br />
    </div>
  );

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }
};
