/* This is going to be a half baked early release like usual 😢,
  so here are some things to NOT forget about

  - The new structured upload release will fundamentally change the
    way that we are collection information from the clients, whilst still
    having to generate design brief for clients that upload the old way.
    Preliminarily I think the solution is to have multiple versions of the
    'default design brief'. To make that work we will need to be able to identify
    wheich version of the materials gathering process the client went through,
    more on that later

  - The mocks show front and back yard segmented feedback, which we don't have yet,
    so that will not be included in this version. This may change as early as 5/26/2020.

  - Some components such as the plant database tag filter have not been thoroughly thought
    out and will require product.
*/

import { Profile, Media } from "@yardzen-inc/models";
import {
  DesignBriefEntryGroupMap,
  DesignBriefEntryType,
  EntryMediaType,
} from "./types";
import DesignBriefEntry, {
  DesignBriefEntryProperties,
} from "./DesignBriefEntry";
import DesignBriefApi from "./DesignBriefApi";
import firebase from "firebase/compat/app";
import { getUSDAZone } from "../../../util/functions/getUSDAZone";

export const PLANT_PREFERENCES = "PLANT_PREFERENCES";
export const PRIORITIES = "PRIORITIES";
export const CLIENT_FEEDBACK = "CLIENT_FEEDBACK";
export const INSPIRATION = "INSPIRATION";
export const ELEMENTS = "ELEMENTS";
export const PROJECT_MANAGER_NOTES = "PROJECT_MANAGER_NOTES";

export const V1ORDER = [
  PLANT_PREFERENCES,
  PRIORITIES,
  CLIENT_FEEDBACK,
  ELEMENTS,
  INSPIRATION,
  PROJECT_MANAGER_NOTES,
];

async function generateDefaultDesignBriefV1(
  api: DesignBriefApi,
  profile: Profile,
  dropCollection?: boolean
): Promise<DesignBriefEntryGroupMap> {
  if (dropCollection) {
    await api.dropCollection();
  }

  const map: DesignBriefEntryGroupMap = {
    [PLANT_PREFERENCES]: await generatePlantPreferences(),
    [PRIORITIES]: await generatePriorities(),
    [CLIENT_FEEDBACK]: await generateClientFeedback(),
    [ELEMENTS]: await generateElements(),
    [INSPIRATION]: await generateInspiration(),
    [PROJECT_MANAGER_NOTES]: [],
  };

  return map;

  async function generatePlantPreferences(): Promise<DesignBriefEntry<any>[]> {
    const genericProps = {
      group: PLANT_PREFERENCES,
      marked: false,
    };

    const getPosition = createOrderIncrementGenerator();

    let usdaZone = "";
    try {
      usdaZone = await getUSDAZone(profile?.zip || "");
    } catch (e) {
      console.error(
        `Unable to fetch USDA zone for generating V1 design brief: ${e.message}`
      );
    }

    /*
      The plant database filters will be a multi select / tag cloud
      type of deal, with an interface with a search/select component.
      This is out of the scope of the v1 i believe, and I (Ben) do not have
      the required info for the plant database / tags to figure that out.
      For now they will be text inputs, here's a todo comment:

      TODO: implent searchable plant database tag interface for auto-genned design brief.
    */
    const entryProperties: DesignBriefEntryProperties<any>[] = [
      {
        ...genericProps,
        order: getPosition(),
        type: DesignBriefEntryType.TEXT,
        value: usdaZone,
        label: "USDA ZONE:",
        referenceLink: "https://planthardiness.ars.usda.gov",
      },
      {
        ...genericProps,
        order: getPosition(),
        type: DesignBriefEntryType.TEXT,
        value: "",
        label: "Adapted Habitat (geo):",
      },
      {
        ...genericProps,
        order: getPosition(),
        type: DesignBriefEntryType.TEXT,
        value:
          "All mature trees and shrubs to remain unless specified otherwise below",
      },
    ];

    return api.bulkCreateEntry(entryProperties);
  }

  async function generatePriorities(): Promise<DesignBriefEntry<any>[]> {
    const genericProps = {
      group: PRIORITIES,
      marked: false,
    };

    const getPosition = createOrderIncrementGenerator();

    const entryProperties = [
      // @ts-ignore
      profile["priority1"] as string,
      // @ts-ignore
      profile["priority2"] as string,
      // @ts-ignore
      profile["priority3"] as string,
      // @ts-ignore
      profile["priority4"] as string,
      // @ts-ignore
      profile["priority5"] as string,
    ]
      .filter((priority) => !!priority)
      .map((priority, i) => ({
        ...genericProps,
        order: getPosition(),
        type: DesignBriefEntryType.TEXT,
        label: `Priority ${i + 1}:`,
        value: `${priority}`,
      }));

    return api.bulkCreateEntry(entryProperties);
  }

  async function generateClientFeedback(): Promise<DesignBriefEntry<any>[]> {
    const genericProps = {
      group: CLIENT_FEEDBACK,
      marked: false,
    };

    const getPosition = createOrderIncrementGenerator();

    const entryProperties = [
      // @ts-ignore
      `Likes and dislikes: ${profile["answer2"] || "[left blank]"}`,
      // @ts-ignore
      `Additional feedback: ${profile["answer3"] || "[left blank]"}`,
    ].map((priority, i) => ({
      ...genericProps,
      order: getPosition(),
      type: DesignBriefEntryType.TEXT,
      value: priority,
    }));

    return api.bulkCreateEntry(entryProperties);
  }

  async function generateInspiration(): Promise<DesignBriefEntry<any>[]> {
    const genericProps = {
      group: INSPIRATION,
      marked: false,
    };

    const getPosition = createOrderIncrementGenerator();

    const inspoMedia = await Promise.all(
      (
        await firebase
          .firestore()
          .collection("media")
          .where("userId", "==", profile.id)
          .where("tag", "==", "inspiration")
          .get()
      ).docs.map(Media.createFromQuerySnapshot)
    );

    const entryProperties = inspoMedia.map(
      (med) =>
        ({
          ...genericProps,
          type: DesignBriefEntryType.TEXT,
          value: med.description ?? "[no description]",
          order: getPosition(),
          referenceImages: [
            { type: EntryMediaType.MEDIA_ID, value: med.id, annotator: false },
          ],
        } as DesignBriefEntryProperties<any>)
    );

    return api.bulkCreateEntry(entryProperties);
  }

  async function generateElements(): Promise<DesignBriefEntry<any>[]> {
    const genericProps = {
      group: ELEMENTS,
      marked: false,
    };

    const getPosition = createOrderIncrementGenerator();

    const elements = profile.elements;

    if (!elements) {
      return [];
    }

    const entryProperties = Object.entries(elements)
      .filter(([key, val]) => !!val)
      .map(([key]) => ({
        ...genericProps,
        type: DesignBriefEntryType.TEXT,
        value: key,
        order: getPosition(),
      }));

    return api.bulkCreateEntry(entryProperties);
  }
}

function createOrderIncrementGenerator(): () => number {
  let currentOrder = 0;

  return () => {
    return (currentOrder += 100);
  };
}

export { generateDefaultDesignBriefV1 };
export default generateDefaultDesignBriefV1;
