import { ChangeEvent } from "react";

export function datePickToChangeEvent(date: Date | null, name: string) {
  const target = {
    value: date?.toISOString?.(),
    name,
  } as any;

  const ev: ChangeEvent<HTMLFormElement> = {
    target,
    nativeEvent: target,
    currentTarget: target,
    bubbles: false,
    cancelable: false,
    defaultPrevented: false,
    eventPhase: 0,
    isTrusted: false,
    preventDefault: function (): void {},
    isDefaultPrevented: function (): boolean {
      return true;
    },
    stopPropagation: function (): void {},
    isPropagationStopped: function (): boolean {
      return true;
    },
    persist: function (): void {},
    timeStamp: 0,
    type: "",
  };

  return ev;
}
