import * as React from "react";
import firebase from "firebase/compat/app";
import { ProjectManager, ProjectManagerProperties } from "@yardzen-inc/models";
import "firebase/compat/database";
import "firebase/compat/auth";
import { PmList } from "./PmList";
import { Fab, Box } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import AddPmModal from "./AddPmModal";

export default (props: any) => {
  const [pms, setPms] = React.useState<ProjectManager[] | null>(null);
  const [user, setUser] = React.useState<firebase.User | null>(null);
  const [addModalOpen, setAddModalOpen] = React.useState<boolean>(false);

  React.useEffect(listenForUser, []);
  React.useEffect(listenForPms, []);

  return (
    <Box p={3}>
      <PmList pms={pms} user={user} />
      {user && pms && (
        <>
          <AddPmModal
            userId={user.uid}
            onClose={() => setAddModalOpen(false)}
            open={addModalOpen}
            onSubmit={handleSubmit}
          />
          {renderAddButton()}
        </>
      )}
    </Box>
  );

  function listenForUser(): () => void {
    return firebase.auth().onAuthStateChanged((u) => {
      if (u) {
        setUser(u);
      }
    });
  }

  function listenForPms(): () => void {
    return firebase
      .firestore()
      .collection("projectManagers")
      .onSnapshot((snap) => {
        if (snap.empty) {
          setPms([]);
        }

        setPms(ProjectManager.hydrate(snap.docs));
      });
  }

  function renderAddButton(): React.ReactNode {
    return (
      <Box position="fixed" bottom="16px" right="16px">
        <Fab color="primary" onClick={() => setAddModalOpen(true)}>
          <Add></Add>
        </Fab>
      </Box>
    );
  }

  async function handleSubmit(
    data: ProjectManagerProperties
  ): Promise<ProjectManager | Error> {
    try {
      const pm = await ProjectManager.create(data);

      return pm;
    } catch (err) {
      return err;
    }
  }
};
