import * as React from "react";
import Dropzone from "react-dropzone";
import { Paper, Link, Grid, Typography } from "@material-ui/core";
import { AssignmentType, Media, MediaVisibility } from "@yardzen-inc/models";

export interface Props {
  fileTag: string;
  clientUserId: string;
  fileVisibility?: MediaVisibility;
  revisionId?: string;
  onUploadStart?: Function;
  onUploadComplete?: Function;
  onUploadProgress?: Function;
  accept?: Array<string>;
  uploadedBy?: AssignmentType;
  variant?: string | null;
}

export interface State {
  files: Array<File>;
  progress: object;
  isUploading: boolean;
}

export default class Basic extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      files: [],
      isUploading: false,
      progress: {},
    };

    this.onDrop = this.onDrop.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
  }

  public render() {
    const files = this.state.files.map((file) => (
      <Grid
        container
        key={file.name}
        direction="row"
        spacing={4}
        alignItems="flex-start"
      >
        <Grid item sm={4}>
          <Typography noWrap style={{ maxWidth: 80 }}>
            {file.name}
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <Typography>
            {
              // @ts-ignore
              Math.round(this.state.progress[file.name])
            }
            %
          </Typography>
        </Grid>
      </Grid>
    ));

    return (
      <Dropzone
        onDropAccepted={this.onDrop}
        accept={
          this.props.accept || [
            "image/*",
            "video/*",
            ".pdf",
            ".dwg",
            ".skp",
            ".ls7",
            ".ls8",
            ".ls9",
            ".ls10",
            ".ls11",
            ".ls12",
            ".docx",
            ".ai",
            ".zip",
            ".plan",
            ".xlsx",
            ".3dm",
            ".bimx",
          ]
        }
      >
        {({ getRootProps, getInputProps }) => (
          <section className="container">
            <Paper
              {...getRootProps({ className: "dropzone" })}
              style={{ padding: 50 }}
            >
              {!this.state.isUploading && (
                <div>
                  <input {...getInputProps()} />
                  <p>
                    <Link href="#">
                      Drag and drop some files here, or click to select files to
                      upload.
                    </Link>
                  </p>
                </div>
              )}

              {files.length > 0 && (
                <div>
                  <h4>Uploading</h4>
                  <Grid container direction="column">
                    {files}
                  </Grid>
                </div>
              )}
            </Paper>
          </section>
        )}
      </Dropzone>
    );
  }

  protected async onDrop(files: Array<File>) {
    const props = this.props;
    this.setState({
      files: files,
      isUploading: true,
    });

    if (props.onUploadStart) {
      props.onUploadStart.call(this, files);
    }

    const filesUploaded = await Promise.all(
      this.uploadFiles(files, this.props.fileTag)
    );

    this.setState({
      files: [],
      isUploading: false,
    });

    if (props.onUploadComplete) {
      props.onUploadComplete.call(this, filesUploaded);
    }
  }

  protected onUploadProgress(file: File, snapshot: any) {
    if (this.props.onUploadProgress) {
      this.props.onUploadProgress(file, snapshot);
    }

    const fileProgress = {};
    // @ts-ignore
    fileProgress[file.name] =
      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    let state = this.state;
    this.setState({ progress: Object.assign(state, fileProgress) });
  }

  protected uploadFiles(files: Array<File>, tag: string): Array<Promise<any>> {
    return files.map((file) => {
      const media = new Media({
        file: file,
        userId: this.props.clientUserId,
        visibility: this.props.fileVisibility ?? {},
      });

      return media.store(
        this.onUploadProgress as () => any,
        this.props.revisionId,
        tag,
        this.props.uploadedBy,
        this.props.variant || undefined
      );
    });
  }
}
