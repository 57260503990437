import React, { FC, ReactNode, MouseEvent } from "react";
import Popover, { PopoverProps } from "@material-ui/core/Popover";
import RClickContextMenuItem from "./RClickContextMenuItem";
import { Box } from "@material-ui/core";

export interface RClickContextMenuAction {
  key: string;
  label: string;
  handler: (...args: any[]) => void;
}

export interface RClickContextMenuProps extends PopoverProps {
  open: boolean;
  actions: RClickContextMenuAction[];
  onClose: () => void;
}

const RClickContextMenu: FC<RClickContextMenuProps> = ({
  actions,
  open,
  ...popoverProps
}) => {
  return (
    <Popover {...popoverProps} open={open}>
      <Box width={200} display="flex" flexDirection="column">
        {renderActions()}
      </Box>
    </Popover>
  );

  function renderActions(): ReactNode[] {
    return actions.map((a) => {
      return (
        <RClickContextMenuItem
          action={{ ...a, handler: deferOnClose(a.handler) }}
          key={a.key}
        ></RClickContextMenuItem>
      );
    });
  }

  function deferOnClose(handler: () => void) {
    return (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      handler();

      popoverProps.onClose();
    };
  }
};

export { RClickContextMenu };
export default RClickContextMenu;
