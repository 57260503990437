import firebase from "firebase/compat/app";
import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  List,
  Typography,
  Box,
  MenuItem,
  Select,
  Link,
} from "@material-ui/core";
import { PostPLPHoldListItem } from ".";
import { GenericLoadingSpinner, YZButton } from "@yardzen-inc/react-common";
import { Refresh } from "@material-ui/icons";
import { PostPLPHoldData, PostPLPHoldsRequiredActionType } from "./types";

type HoldsFilterType =
  | "NEEDS_REVIEW"
  | "AWAITING_ACTION"
  | "RECENTLY_DELIVERED"
  | "ALL";

export interface PostPLPHoldsViewProps extends RouteComponentProps {}

const PostPLPHoldsView: FC<PostPLPHoldsViewProps> = ({ ...routeProps }) => {
  const [holdsData, setHoldsData] = useState<PostPLPHoldData[]>([]);

  const [holdsFilter, setHoldsFilter] =
    useState<HoldsFilterType>("NEEDS_REVIEW");

  const [specificAction, setSpecificAction] = useState<
    PostPLPHoldsRequiredActionType | "ALL"
  >("ALL");

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setPostPLPHolds();
  }, [holdsFilter, specificAction, refresh]);

  return (
    <Box p={3}>
      <Typography variant="h4">Post PLP Holds</Typography>

      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py={2}
      >
        <div>
          <Box display="inline-flex" alignItems="center">
            <Typography display="inline">Hold Type: </Typography>
            <Box pl={1}>
              <Select
                value={holdsFilter}
                onChange={(e) =>
                  setHoldsFilter(e.target.value as "NEEDS_REVIEW" | "ALL")
                }
              >
                <MenuItem value="NEEDS_REVIEW">Needs Review</MenuItem>
                <MenuItem value="AWAITING_ACTION">Awaiting Action</MenuItem>
                <MenuItem value="RECENTLY_DELIVERED">
                  Recently Delivered
                </MenuItem>
                <MenuItem value="ALL">All</MenuItem>
              </Select>
            </Box>
          </Box>

          {renderConditionalFilter()}
        </div>

        <YZButton onClick={() => setRefresh(true)}>
          Refresh List
          <Box pl={1} display="flex" alignItems="center">
            <Refresh />
          </Box>
        </YZButton>
      </Box>

      <br />

      {renderList()}
    </Box>
  );

  function renderConditionalFilter() {
    return (
      <>
        {holdsFilter === "AWAITING_ACTION" && (
          <Box display="inline-flex" alignItems="center" pl={2}>
            <Typography display="inline">Specific Action: </Typography>
            <Box pl={1}>
              <Select
                value={specificAction}
                onChange={(e) =>
                  setSpecificAction(
                    e.target.value as PostPLPHoldsRequiredActionType | "ALL"
                  )
                }
              >
                <MenuItem value="ALL">All</MenuItem>
                <MenuItem value="CX_OUTREACH">CX Outreach</MenuItem>
                <MenuItem value="MODEL_FIX">Model Fix</MenuItem>
                <MenuItem value="VIP">VIP</MenuItem>
              </Select>
            </Box>
          </Box>
        )}
      </>
    );
  }

  function renderList() {
    if (loading) {
      return <GenericLoadingSpinner />;
    }

    if (error) {
      return (
        <Typography>
          Something went wrong fetching the data from the database. Try to
          refresh the page, and if you continue to see this message, contact
          engineering :)
        </Typography>
      );
    }

    if (!holdsData.length) {
      return (
        <Typography>
          No holds found!{" "}
          {holdsFilter !== "ALL" && (
            <Link onClick={() => setHoldsFilter("ALL")}>See all holds</Link>
          )}
        </Typography>
      );
    }

    return (
      <List aria-label="post plp holds list">
        {holdsData.map((rec) => {
          if (!rec.profile) {
            return;
          }

          return (
            <PostPLPHoldListItem
              key={`${rec.projectId}-hold`}
              {...routeProps}
              holdData={rec}
              triggerRefresh={() => setRefresh(true)}
            />
          );
        })}
      </List>
    );
  }

  async function setPostPLPHolds() {
    setLoading(true);

    try {
      const getPostPLPHoldsData = firebase
        .functions()
        .httpsCallable("getPostPLPHoldsData");

      const requestData = getRequestData(holdsFilter);

      const res = await getPostPLPHoldsData(requestData);

      const filteredData = removeIncompleteData(res.data as any[]);

      setHoldsData(filteredData);
    } catch (err) {
      console.error(err);
      setError(true);
    }

    setRefresh(false);
    setLoading(false);
  }

  function removeIncompleteData(data: any[]) {
    const returnArr = [];

    for (let rec of data) {
      if (rec.profile) {
        returnArr.push(rec);
      }
    }

    return returnArr;
  }

  function getRequestData(holdsFilter: HoldsFilterType) {
    const holdsFilterDataMap = {
      NEEDS_REVIEW: {
        holdStatusArr: [
          "BUDGET_WARNING",
          "PM_QUESTIONS",
          "ANNOTATED",
          "VIP",
          "EXTERIOR_DESIGN",
        ],
        awaitingAction: false,
      },
      AWAITING_ACTION: {
        holdStatusArr: [
          "BUDGET_WARNING",
          "PM_QUESTIONS",
          "ANNOTATED",
          "VIP",
          "EXTERIOR_DESIGN",
        ],
        awaitingAction: true,
        specificAction: specificAction === "ALL" ? undefined : specificAction,
      },
      RECENTLY_DELIVERED: {
        holdStatusArr: ["RECENTLY_DELIVERED"],
        awaitingAction: false,
      },
      ALL: {},
    };

    return holdsFilterDataMap[holdsFilter];
  }
};

export { PostPLPHoldsView };
export default PostPLPHoldsView;
