import * as React from "react";
import { useCallback, useEffect, useMemo, useState, useContext } from "react";
import ClientSearchBox from "./ClientSearchBox";
import ClientTable from "./ClientTable";
import {
  withStyles,
  createStyles,
  Theme,
  BottomNavigationAction,
  BottomNavigation,
  Typography,
} from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { Profile } from "../Interfaces";
import ClientStateFilter from "./ClientStateFilter";
import { IContactInfo } from "@yardzen-inc/models";
import { RecordsCtx, GetClientsParams } from "../util/RecordsContext";
import moment from "moment";
import { debounce } from "lodash";
import LoadingAbsCenter from "../Components/LoadingAbsCenter";
import { useHistory } from "react-router";

const styles = createStyles((theme: Theme) => ({
  searchableTable: { maxWidth: "100vw" },
  searchContainer: {
    padding: "1rem",
    display: "flex",
    flexFlow: "row nowrap",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column nowrap",
    },
    alignContent: "center",
  },
  bottomNavigation: { position: "fixed", bottom: 0, width: "100vw" },
  bottomNavigationTypography: { paddingTop: "1rem" },
}));

export interface SearchResult {
  record: Profile;
  hitMap: string[];
  matchedContacts: {
    contact: IContactInfo;
    contactHitMap: string[];
  }[];
}

type SearchOrder = "desc" | "asc";

interface Props {
  classes?: any;
}

export const SearchableTable = ({ classes }: Props) => {
  const recordsContext = useContext(RecordsCtx);
  const [searchText, setSearchText] = useState<string>("");
  const [searchTestAccounts, setSearchTestAccounts] = useState<boolean>(false);
  const [searchOrder, setSearchOrder] = useState<SearchOrder>("desc");
  const [searchMonthsBack, setSearchMonthsBack] = useState<number>(3);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const resultsPerPage = 20;

  const history = useHistory();
  const loading = recordsContext?.loading ?? true;
  const totalRecords = recordsContext?.totalRecords ?? 0;

  // remove this block after refactoring Client Table to accept records with type `Profile`
  const searchResults =
    recordsContext?.records
      .filter((_, i) => i < resultsPerPage)
      .map((record) => ({
        record,
        hitMap: [],
        matchedContacts: [],
      })) || [];

  const getClients = useCallback(
    debounce(
      (params: GetClientsParams) => recordsContext?.getClients(params),
      300
    ),
    []
  );

  const params = useMemo(() => {
    const params: GetClientsParams = {
      todt: moment().toISOString(),
      fromdt:
        searchMonthsBack === 0
          ? moment(0).toISOString() // set fromdt equal to unix epoch to get all values
          : moment().subtract(searchMonthsBack, "months").toISOString(),
      sortDirection: searchOrder === "asc" ? 1 : -1,
      pageSize: resultsPerPage,
      page: pageNumber,
      testAccts: searchTestAccounts,
    };
    if (searchText.length > 0) params.search = searchText;
    return params;
  }, [
    searchText,
    searchTestAccounts,
    searchOrder,
    searchMonthsBack,
    pageNumber,
  ]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
    getClients(params);
  }, [searchText, searchTestAccounts]);

  useEffect(() => {
    getClients(params);
  }, [searchOrder, pageNumber, searchMonthsBack]);

  return (
    <div className={classes.searchableTable}>
      <div className={classes.searchContainer}>
        <ClientSearchBox
          value={searchText}
          onChange={(searchText: string) => setSearchText(searchText)}
          searchTestAccounts={searchTestAccounts}
          onToggleSearchTestAccounts={() =>
            setSearchTestAccounts(!searchTestAccounts)
          }
          recordsDateRange={searchMonthsBack}
        />
        <ClientStateFilter
          order={searchOrder}
          handleSetOrder={(order) => setSearchOrder(order)}
          setRecordsDateRange={(numberOfMonths) =>
            setSearchMonthsBack(numberOfMonths)
          }
          recordsDateRange={searchMonthsBack}
        />
        {loading && <LoadingAbsCenter in={loading} />}
      </div>
      <ClientTable
        records={searchResults}
        onRowClick={(id) => history.push(`/client/${id}/detail`)}
        searchString={searchText}
      />
      <BottomNavigation className={classes.bottomNavigation}>
        <BottomNavigationAction
          onClick={() => setPageNumber(pageNumber - 1)}
          icon={<ArrowBack />}
          color="primary"
          label="Previous"
          disabled={pageNumber === 1}
        ></BottomNavigationAction>
        <Typography
          variant="caption"
          className={classes.bottomNavigationTypography}
        >{`Page ${pageNumber}`}</Typography>
        <BottomNavigationAction
          onClick={() => setPageNumber(pageNumber + 1)}
          color="primary"
          icon={<ArrowForward />}
          label="Next"
          disabled={pageNumber * resultsPerPage >= totalRecords}
        ></BottomNavigationAction>
      </BottomNavigation>
    </div>
  );
};

export default withStyles(styles)(SearchableTable);
