//
import React, { FC, ReactNode, useState } from "react";
import { Project } from "@yardzen-inc/models";
import { OnboardData } from "./OnboardDataProvider";
import { Box, Typography } from "@material-ui/core";
import YardInfoGroup from "./YardInfoGroup";
import { BoxProps } from "@material-ui/core/Box";
import BasicOnboardPropertyToolbar from "./BasicOnboardPropertyToolbar";

export interface BasicOnboardPropertyDataSummaryProps extends BoxProps {
  project: Project;
  onboardData: OnboardData;
  toolbarTopOffest?: number;
  pm: boolean;
}

const BasicOnboardPropertyDataSummary: FC<BasicOnboardPropertyDataSummaryProps> =
  ({ project, onboardData, pm, toolbarTopOffest, ...containerProps }) => {
    const [selectedYard, setSelectedYard] = useState<string>(getInitialYard);
    const [variant, setVariant] = useState<string>("");

    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        {...containerProps}
      >
        <BasicOnboardPropertyToolbar
          selectedVariant={variant}
          selectedYard={selectedYard}
          selectVariant={setVariant}
          selectYard={setSelectedYard}
          onboardData={onboardData}
          top={toolbarTopOffest ?? 0}
        />
        {renderYards()}
      </Box>
    );

    function renderYardInfo(yard: string): ReactNode {
      // @ts-ignore
      if (!(onboardData.yardsStatusObj as {})[yard]) {
        return null;
      }

      return (
        <YardInfoGroup
          width={"100%"}
          privileged={pm}
          key={`yard-${yard}-info`}
          showOnly={variant}
          userId={project.profileId}
          yard={yard}
          onboardData={onboardData}
        />
      );
    }

    function getInitialYard(): string {
      if (!onboardData.yardsStatusObj) {
        return "";
      }

      const yardStatusesForClient = Object.keys(onboardData.yardsStatusObj);

      if (!yardStatusesForClient.length) {
        return "";
      }

      // By default this will sort the strings into alphabetical order.
      yardStatusesForClient.sort();

      return yardStatusesForClient[0];
    }

    function renderYards(): ReactNode[] | ReactNode {
      if (!onboardData.yardsStatusObj) {
        return <Typography>No Yard Status Object</Typography>;
      }

      if (selectedYard) {
        return renderYardInfo(selectedYard);
      }

      return Object.keys(onboardData.yardsStatusObj).map(renderYardInfo);
    }
  };

export { BasicOnboardPropertyDataSummary };
export default BasicOnboardPropertyDataSummary;
