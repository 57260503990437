/**
 * This function takes an array of all project tags and returns an array
 * of only tags related to Pro Matching.
 *
 * @param ProjectTags[] - An array of tags associated with a project.
 * @returns - An array of Pro Match project tags
 *
 */

import { ProjectTags, ProMatchProjectTag } from "@yardzen-inc/models";

const proMatchTags = [
  "ONBOARDING-YES",
  "ONBOARDING-MAYBE",
  "ONBOARDING-NO",
  "ONBOARDING-EARLY-MATCH",
  "POST-V1-YES",
  "POST-V1-NO",
  "POST-V2-YES",
  "POST-V2-NO",
  "POST-V2-1-MONTH-LATER",
  "POST-V2-3-MONTHS-LATER",
  "POST-V2-6-MONTHS-LATER",
  "POST-V2-REVISION",
  "POST-V2-LEARN-MORE",
  "POST-V2-MATCH-ME-NOW",
];

function showProMatchProjectTags(
  tags: ProjectTags[] | null
): ProMatchProjectTag[] {
  const filteredTags = (tags || []).filter((tag) => proMatchTags.includes(tag));
  return filteredTags as ProMatchProjectTag[];
}

export { showProMatchProjectTags };
