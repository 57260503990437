import { HasuraQueryHumanReadableOperatorEnum } from "@yardzen-inc/util";
import { AssetLibraryFilterState } from "../../../../Interfaces";

interface IAllOfFilter {
  id: string;
  field: string;
  filterId: string;
  operator: HasuraQueryHumanReadableOperatorEnum; // "is"
  value: Omit<AssetLibraryFilterState, "filterId">;
}

// here we are clearing out filters from the other filter state "eqFilterState".
// So if we say "cad symbol size" "is in" "abc" and then change that to "is not"
// the previous state gets removed.

const clearOutSimilarFilterFromOtherStatesById = (
  targetFilterState: any[],
  setFilterState: any,
  id: string
) => {
  const prevFiltersToKeep = targetFilterState.filter(
    (hasAllFilter) => hasAllFilter.filterId !== id
  );
  setFilterState(prevFiltersToKeep);
};

const getUniquesById = (arr: any[]): any[] => {
  const uniqsById = [];
  const usedIndexHash = new Map();
  for (let each of arr) {
    if (usedIndexHash.has(each.id)) continue;
    usedIndexHash.set(each.id, true);
    uniqsById.push(each);
  }
  return uniqsById;
};

const getSimilarFiltersToKeep = (arr: any[], filterId: string): any[] =>
  arr.filter((arrFilter) => arrFilter.filterId !== filterId);

const onFilterBoxSelect = (
  e: Partial<AssetLibraryFilterState>[],
  filters: IAllOfFilter[],
  setFilters: any,
  filterId: string
) => {
  const prevFiltersToKeep = getSimilarFiltersToKeep(filters, filterId);
  const combined = getUniquesById([...prevFiltersToKeep, ...e]);
  setFilters(combined);
};

const onFilterBoxSelectNone = (
  id: string,
  neqFilterState: any,
  hasAnyOfFilterState: any,
  hasAllOfFilters: any,
  setNeqFilterState: any,
  setHasAnyOfFilterState: any,
  setHasAllOfFilters: any
): void => {
  clearOutSimilarFilterFromOtherStatesById(
    neqFilterState,
    setNeqFilterState,
    id
  );
  clearOutSimilarFilterFromOtherStatesById(
    hasAnyOfFilterState,
    setHasAnyOfFilterState,
    id
  );
  clearOutSimilarFilterFromOtherStatesById(
    hasAllOfFilters,
    setHasAllOfFilters,
    id
  );
};

export { onFilterBoxSelect, onFilterBoxSelectNone };
