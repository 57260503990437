import * as React from "react";
import { makeStyles, List, CircularProgress } from "@material-ui/core";
import { Profile, Agent, AgentType, AgentTier } from "@yardzen-inc/models";
import AssignModal from "./AssignModal";
import UnnasignedListItem from "./UnnasignedListItem";
import { GenericOnCallFunctionAlert } from "../../util/genericAlert";
import {
  getAllAgentUserIds,
  getAllDesignerProfiles,
  setHasuraCustomClaims,
} from "../../util/firebase/firebaseClient";
import { filterMatchesAgentOrProfile } from "../../util/functions/filterMatchesAgentOrProfile";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
  },
});

interface Props {
  filter: string;
}

export default React.memo(({ filter }: Props) => {
  const classes = useStyles();

  const [profiles, setProfiles] = React.useState<Profile[]>([]);
  const [agentIds, setAgentIds] = React.useState<string[]>([]);
  const [creating, setCreating] = React.useState<boolean>(false);
  const [modalProps, setModalProps] = React.useState<{
    open: boolean;
    profile: Profile;
    done?: boolean;
  } | null>(null);

  React.useEffect(() => {
    if (!modalProps || modalProps.done) {
      fetchDesigners();
      fetchAgents();
    }
  }, [modalProps]);

  return agentIds.length === 0 ? (
    <CircularProgress />
  ) : (
    <>
      <AssignModal
        {...(modalProps || {})}
        onClick={createAgent}
        onClose={() => setModalProps(null)}
        creating={creating}
      />
      {filterAndMapProfiles(profiles, setModalProps)}
    </>
  );

  async function fetchDesigners(): Promise<void> {
    const designerProfiles = await getAllDesignerProfiles();
    setProfiles(designerProfiles);
  }

  async function fetchAgents(): Promise<void> {
    const agentRecords = await getAllAgentUserIds();
    setAgentIds(agentRecords);
  }

  async function createAgent(
    type: AgentType,
    rate: number,
    groupId: string | null,
    designerPodId: string | undefined | null,
    paymentEmail: string | null | undefined
  ): Promise<void> {
    setCreating(true);

    const { profile } = modalProps as { profile: Profile };

    try {
      try {
        await setHasuraCustomClaims(profile.userId, "agent");
      } catch (error) {
        GenericOnCallFunctionAlert(
          "setHasuraCustomClaims",
          (error as Error).message
        );
        throw error;
      }

      await Agent.create({
        payRate: rate,
        userId: profile.userId,
        email: profile.email as string,
        paymentEmail: profile.designerPaymentEmail as string,
        type: type,
        firstName: profile.firstName as string,
        lastName: profile.lastName as string,
        disabled: false,
        groupId: groupId || null,
        designerPodId: type === "design" ? designerPodId || null : null,
        qualifications: [],
        tier: AgentTier.NewDesigner,
      });

      if (modalProps) {
        setModalProps({ ...modalProps, done: true });
      }
    } catch (error) {
      console.error(error);
      alert("Failed to assign role to agent, please try again");
    } finally {
      setCreating(false);
    }
  }

  function isNotAlreadyAnAgent(profile: Profile): boolean {
    //Unassigned agents are those who have profiles but do not have an agent record
    return !agentIds.includes(profile.id);
  }

  function filterAndMapProfiles(
    profiles: Profile[],
    setModalProps: (p: any) => any
  ): React.ReactNode[] {
    const filteredProfiles = profiles.filter(
      (p) => filterMatchesAgentOrProfile(p, filter) && isNotAlreadyAnAgent(p)
    );
    return filteredProfiles.map((p) => {
      return (
        <List className={classes.root} key={p.id}>
          <UnnasignedListItem
            p={p}
            setModalProps={setModalProps}
          ></UnnasignedListItem>
        </List>
      );
    });
  }
});
