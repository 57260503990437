import firebase from "firebase/compat/app";
import { NewPodData, AgentPods, Agent, editPodRequest } from "./types";

const createDesignerPod = async (newPodData: NewPodData): Promise<string> => {
  const createDesignerPod = firebase
    .functions()
    .httpsCallable("createDesignerPod");

  try {
    const response = await createDesignerPod(newPodData);
    return response.data;
  } catch (err) {
    return "An error occured";
  }
};

const getAllDesignerPodsData = async (
  withPmData: boolean
): Promise<AgentPods[]> => {
  const getAllDesignerPodsData = firebase
    .functions()
    .httpsCallable("getAllDesignerPodsData");

  const response = await getAllDesignerPodsData({ withPmData });
  return response.data;
};

const getAllDesignersByDesignerPodId = async (
  designerPodId: string | null
): Promise<Agent[]> => {
  const getAllDesignersByDesignerPodId = firebase
    .functions()
    .httpsCallable("getAllDesignersByDesignerPodId");

  const response = await getAllDesignersByDesignerPodId({ designerPodId });
  return response.data;
};

const batchUpdateAgentDesignerPodId = async (
  designerIds: string[],
  designerPodId: string
): Promise<any> => {
  const batchUpdateAgentDesignerPodId = firebase
    .functions()
    .httpsCallable("batchUpdateAgentDesignerPodId");

  const response = await batchUpdateAgentDesignerPodId({
    designerIds,
    designerPodId,
  });
  return response.data;
};

const updateDesignerPod = async (postData: editPodRequest): Promise<any> => {
  const updateDesignerPod = firebase
    .functions()
    .httpsCallable("updateDesignerPod");

  const response = await updateDesignerPod(postData);

  return response.data;
};

const deleteDesignerPod = async (
  designerPodId: string | null
): Promise<any> => {
  const deleteDesignerPod = firebase
    .functions()
    .httpsCallable("deleteDesignerPod");

  await deleteDesignerPod({ designerPodId });
};

export {
  getAllDesignerPodsData,
  createDesignerPod,
  getAllDesignersByDesignerPodId,
  batchUpdateAgentDesignerPodId,
  updateDesignerPod,
  deleteDesignerPod,
};
