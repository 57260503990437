import React, { FC, ReactNode } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";
import { uploadVariantTags } from "./YardInfoGroup";
import OnboardFunctionsDisplay from "./OnboardFunctionsDisplay";
import { OnboardData } from "./OnboardDataProvider";

export interface BasicOnboardPropertyToolbarProps extends BoxProps {
  selectedYard: string;
  selectedVariant: string;
  selectVariant: (val: string) => void;
  selectYard: (val: string) => void;
  onboardData: OnboardData;
}

const BasicOnboardPropertyToolbar: FC<BasicOnboardPropertyToolbarProps> = ({
  selectedVariant,
  selectedYard,
  selectVariant,
  selectYard,
  onboardData,
  ...containerProps
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      position="sticky"
      top={containerProps.top || 0}
      zIndex={8}
      bgcolor="white"
      maxWidth="100%"
      {...containerProps}
    >
      <Box display="flex" flexDirection="row" flexWrap="nowrap" width="100%">
        {renderTabs()}
        {renderYardSelect()}
      </Box>
      {selectedYard && (
        <OnboardFunctionsDisplay
          pt={1}
          onboardData={onboardData}
          yard={selectedYard}
        />
      )}
    </Box>
  );

  function renderYardSelect(): ReactNode {
    if (!onboardData.yardsStatusObj) {
      return null;
    }

    const yards = Object.keys(onboardData.yardsStatusObj);
    yards.sort();

    return (
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="row nowrap"
        minWidth="200px"
      >
        <Select
          value={selectedYard}
          onChange={({ target: { value } }) =>
            selectYard((value ?? "") as string)
          }
        >
          {yards.map((yard) => (
            <MenuItem value={yard} key={`${yard}-mi`}>
              {yard.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
        <Box pl={2} display="flex" justifyContent="center" alignItems="center">
          <Typography style={{ height: "min-content" }} noWrap>
            {selectedYard === "" ? "YARDS" : "YARD"}
          </Typography>
        </Box>
      </Box>
    );
  }

  function renderTabs(): ReactNode {
    return (
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        style={{
          backgroundColor: "white",
          zIndex: 8,
          maxWidth: "calc(100% - 200px)",
        }}
        flexGrow={1}
      >
        <Tabs
          value={selectedVariant}
          onChange={(_, value) => selectVariant(value)}
          scrollButtons="auto"
          variant="scrollable"
          style={{
            maxWidth: "100%",
          }}
        >
          <Tab label="all" value="" />
          {uploadVariantTags.map((variant, i) => (
            <Tab
              label={variant}
              value={variant}
              key={`variant-${variant}-tab-${i}`}
            />
          ))}
        </Tabs>
      </Box>
    );
  }
};

export { BasicOnboardPropertyToolbar };
export default BasicOnboardPropertyToolbar;
