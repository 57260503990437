import * as React from "react";
import {
  Dialog,
  Slide,
  Link,
  Button,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactPlayer from "react-player";

export interface Props {
  showDialogue: boolean;
  onClose: Function;
  media: Array<any>;
  selectedIndex: number;
}

const useStyles = makeStyles({
  image: {
    width: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    imageOrientation: "from-image",
  },
});

function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}

export function MediaDialogue(props: Props) {
  const { media, selectedIndex } = props;
  const [playbackRate, setPlaybackRate] = React.useState<number>(1);

  const { image } = useStyles();

  const smDown = useMediaQuery((theme) =>
    (theme as any).breakpoints.down("sm")
  );

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Dialog
        fullScreen
        open={props.showDialogue}
        TransitionComponent={Transition}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              maxWidth: "98%",
            }}
          >
            <Button
              href="#"
              color="primary"
              style={{ marginBottom: 10 }}
              variant="text"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                props.onClose();
              }}
            >
              Close
            </Button>
            <FormControl style={{ width: "60px" }}>
              <InputLabel
                style={{ marginRight: "1.75rem" }}
                id="video-playback-select-label"
              >
                Playback Speed
              </InputLabel>

              <Select
                labelId="video-playback-select-label"
                id="playback-select"
                value={playbackRate}
                onChange={(e) => setPlaybackRate(e.target.value as number)}
              >
                <MenuItem value={0.5}>0.5</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={1.5}>1.5</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={8}>8</MenuItem>
              </Select>
            </FormControl>
          </div>

          <Carousel
            // centerMode
            dynamicHeight
            showThumbs={false}
            useKeyboardArrows={!smDown}
            showArrows={!smDown}
            swipeable
            selectedItem={selectedIndex}
          >
            {media.map((item) => (
              <div
                style={{ width: "100%", height: "100%" }}
                key={`${item.id}-div-container`}
              >
                {getComponent(item)}
                {item.fileType.indexOf("pdf") !== -1 ? (
                  <Link
                    target="_blank"
                    href={item.downloadURL}
                    key={`${item.id}-pdf-link`}
                  >
                    View PDF
                  </Link>
                ) : (
                  <div></div>
                )}
              </div>
            ))}
          </Carousel>
        </div>
      </Dialog>
    </div>
  );

  function getComponent(item: any) {
    if (item.fileType.indexOf("image") !== -1) {
      return <img className={image} src={item.downloadURL} key={item.id} />;
    }

    if (item.fileType.indexOf("video") !== -1) {
      return (
        <div
          style={{
            position: "relative",
            paddingTop: "56.25%",
          }}
        >
          <ReactPlayer
            className="react-player"
            key={item.id}
            playbackRate={playbackRate}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              paddingLeft: !smDown ? "2rem" : "0",
              paddingRight: !smDown ? "2rem" : "0",
            }}
            controls
            light
            playing={false}
            width="100%"
            height="95%"
            url={item.downloadURL}
          />
        </div>
      );
    }

    if (item.fileType.indexOf("pdf") !== -1) {
      return (
        <div key={item.id} style={{ width: "100%", height: "100%" }}>
          <iframe
            style={{ width: "100%", height: "100%" }}
            src={item.downloadURL}
          />
        </div>
      );
    }

    return <div></div>;
  }
}
