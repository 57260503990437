import * as React from "react";
import firebase from "firebase/compat/app";
import { Revision, Profile, Media } from "@yardzen-inc/models";
import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  TextField,
  LinearProgress,
  Box,
} from "@material-ui/core";
import { GenericOnCallFunctionAlert } from "../util/genericAlert";
import { YZButton } from "@yardzen-inc/react-common";

interface Props {
  clientRecord: Profile;
  media: Media[];
  revision: Revision;
  fileName: string;
}

function GeneratePDF(props: Props) {
  const [askForAddress, setAskForAddress] = React.useState<boolean>(false);
  const [working, setWorking] = React.useState<boolean>(false);
  const [address, setAddress] = React.useState<string>("");
  const [existingPDF, setExistingPDF] = React.useState<null | Media>(null);
  const [error, setError] = React.useState<boolean>(false);

  React.useEffect(() => {
    getGenPDF();

    return () => {
      setExistingPDF(null);
      setAddress("");
    };
  }, [props.revision]);

  if (error) {
    return (
      <Typography style={{ color: "red" }}>
        Uhoh! Theres been an error
      </Typography>
    );
  }

  if (!!existingPDF) {
    return (
      <>
        <Box mb={2} display="flex" flexWrap="nowrap">
          <Box pr={1}>
            <YZButton
              onClick={() => {
                window.open(existingPDF.downloadURL);
              }}
              variant="outlined"
            >
              Open PDF
            </YZButton>
          </Box>
          <YZButton onClick={deletePDF} variant="outlined">
            Delete PDF
          </YZButton>
        </Box>
        <iframe
          src={existingPDF.downloadURL}
          style={{ width: "260px", border: "1px solid #eee", borderRadius: 4 }}
        />
      </>
    );
  }

  if (working) {
    return (
      <div style={{ width: "218px" }}>
        <LinearProgress />
        <Typography variant="overline">
          <em>This will take a few moments...</em>
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <Dialog open={askForAddress} onClose={() => setAskForAddress(false)}>
        <DialogTitle>No address found!</DialogTitle>
        <DialogContent>
          <Typography>
            <em>Please enter manually</em>
          </Typography>
          <TextField
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <YZButton onClick={sendToPDFHook}>Submit</YZButton>
        </DialogContent>
      </Dialog>
      <YZButton variant="outlined" onClick={sendToPDFHook}>
        Generate printable PDF
      </YZButton>
    </div>
  );

  async function getGenPDF() {
    // Fetches PDF w/ downloadURL
    const med = await Media.get(
      props.clientRecord.id,
      null,
      "generated-pdf",
      props.revision.id
    );
    if (!med.length) return;
    setExistingPDF(med[0]);
  }

  async function sendToPDFHook() {
    const addFromRec = props.clientRecord.street;
    if (!addFromRec && !address) return setAskForAddress(true);
    const addToSend = addFromRec || address;

    const makePDF = firebase.functions().httpsCallable("generatePDF");

    setWorking(true);
    makePDF({
      media: props.media,
      clientId: props.clientRecord.id,
      clientAddress: addToSend,
      revisionId: props.revision ? props.revision.id : null,
      fileName: props.fileName,
    })
      .then((resp) => {
        // Media Metadata object. It has already been added to the "media" collection.
        //  Need to call Media.get([clientId], null, "generated-pdf")
        //  to get downloadURL.
        getGenPDF();
      })
      .catch((e) => {
        GenericOnCallFunctionAlert("generatePDF", e.message);
        setError(e);
      });
  }

  async function deletePDF() {
    if (!existingPDF) return;
    await existingPDF.delete();
    setExistingPDF(null);
    setWorking(false);
  }
}

export default GeneratePDF;
