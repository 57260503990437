import { Box, Container, Dialog, DialogContent } from "@material-ui/core";
import { FormikProps } from "formik";
import React, { FC } from "react";
import {
  CreatePromotionalCodeDto,
  PromotionalCode,
} from "src/api/pangaeaTypes";
import { PromotionalCodeCreateCardProps } from "./PromotionalCodeCreateCard";
import { PromotionalCodeCreateFormController } from "./PromotionalCodeCreateFormController";
import { PromotionalCodeCreateFormFields } from "./PromotionalCodeCreateFormFields";
import {
  defaultValuesToPromotionalCodeCreate,
  promotionalCodeCreateToDefaultValues,
} from "./util/promotionalCodeTransformers";

export interface PromotionalCodeEditModalProps {
  handleSubmit: (dto: CreatePromotionalCodeDto) => void;
  promotionalCode?: PromotionalCode;
  onClose: () => void;
  products: { id: string; name: string }[];
}

export const PromotionalCodeEditModal: FC<PromotionalCodeEditModalProps> = (
  props
) => {
  return (
    <Dialog open={!!props.promotionalCode} onClose={props.onClose}>
      <Container maxWidth="md">
        <DialogContent style={{ width: "100%" }}>
          <Box minWidth="600px">
            {props.promotionalCode && (
              <PromotionalCodeCreateFormController
                editing={!!props.promotionalCode}
                defaultValues={promotionalCodeCreateToDefaultValues({
                  code: props.promotionalCode.code,
                  discount: props.promotionalCode.discount,
                  endTime: props.promotionalCode.endTime,
                  startTime: props.promotionalCode.startTime,
                  maxRedemptions: props.promotionalCode.maxRedemptions,
                  deactivated: props.promotionalCode.deactivated,
                  applicationSuccessMessage:
                    props.promotionalCode.applicationSuccessMessage ?? "",
                  category: props.promotionalCode.category,
                  additionalDiscounts:
                    props.promotionalCode.additionalDiscounts ?? [],
                })}
                handleSubmit={(values) =>
                  props.handleSubmit(
                    defaultValuesToPromotionalCodeCreate(values)
                  )
                }
              >
                {(
                  formikProps: FormikProps<
                    PromotionalCodeCreateCardProps["defaultValues"]
                  >
                ) => (
                  <PromotionalCodeCreateFormFields
                    {...formikProps}
                    hideCodeField
                    disabled={formikProps.isSubmitting}
                    products={props.products}
                  />
                )}
              </PromotionalCodeCreateFormController>
            )}
          </Box>
        </DialogContent>
      </Container>
    </Dialog>
  );
};
