import * as React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import FileUpload from "../Components/FileUpload";
import Box, { BoxProps } from "@material-ui/core/Box";
import { Typography, LinearProgress } from "@material-ui/core";
import { Media } from "@yardzen-inc/models";
import downloadAndZip from "../util/downloadAndZip";
import environmentConstants from "../ConstantValues/environmentConstants";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";

interface Props extends BoxProps {
  revisionId?: string;
  userId: string;
  fileTag: string;
  title: string;
  accept?: string[];
  uploadMessage: string;
  onHasFileChange?: (med: Media | null) => void;
}

export const SingleFileUpload = ({
  userId,
  revisionId,
  fileTag,
  title,
  accept,
  uploadMessage,
  onHasFileChange,
  ...props
}: Props) => {
  const [med, setMed] = React.useState<Media | null | "loading">("loading");

  React.useEffect(listenOnTag, [`${userId}-${revisionId}-${fileTag}`]);
  React.useEffect(onMedChange, [med]);

  return (
    <>
      {(function () {
        if (!med) {
          return renderUpload();
        }

        if (med === "loading") {
          return renderLoading();
        }

        return renderAfterUpload();
      })()}
    </>
  );

  function renderUpload(): React.ReactNode {
    return (
      <Box {...props}>
        <Box mb={1}>
          <YZTypography type="mono" variant="overline">
            {title}
          </YZTypography>
        </Box>
        <FileUpload
          clientUserId={userId}
          fileTag={fileTag}
          revisionId={revisionId}
          accept={accept}
          fileVisibility={{}}
        />
      </Box>
    );
  }

  function renderLoading(): React.ReactNode {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        flexGrow={1}
      >
        <LinearProgress variant="indeterminate" />;
      </Box>
    );
  }

  function renderAfterUpload(): React.ReactNode | null {
    if (!(med instanceof Media)) {
      if (med === "loading") {
        return renderLoading();
      }

      return null;
    }

    return (
      <Box
        p={3}
        style={{
          background: "#fff",
          boxShadow: "0 3px 6px rgba(0,0,0,.05)",
          textAlign: "center",
        }}
      >
        <Box mb={3}>
          <YZTypography type="serif" variant="h5">
            {uploadMessage}
          </YZTypography>
          <Typography variant="body2" color="textSecondary">
            "{med.originalFileName}"
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <YZButton
            variant="outlined"
            style={{ margin: "0.5rem" }}
            size="small"
            onClick={() => {
              downloadAndZip(
                [med as any],
                (med as Media).originalFileName + ".zip"
              );
            }}
          >
            Download
          </YZButton>
          <YZButton
            variant="outlined"
            style={{ margin: "0.5rem" }}
            onClick={() => deleteThing()}
            size="small"
          >
            Replace
          </YZButton>
        </Box>
      </Box>
    );
  }

  function onMedChange(): void {
    if (onHasFileChange && med !== "loading") {
      onHasFileChange(med);
    }
  }

  async function deleteThing(): Promise<any> {
    if (med instanceof Media) {
      return firebase.firestore().collection("media").doc(med.id).delete();
    }
  }

  function listenOnTag(): () => void {
    let query = firebase
      .firestore()
      .collection("media")
      .where("userId", "==", userId)
      .where("tag", "==", fileTag);

    if (revisionId) {
      query = query.where("revisionId", "==", revisionId);
    }

    return query.onSnapshot(async (snap) => {
      if (snap.empty) {
        if (med !== null) {
          return setMed(null);
        }

        return;
      }

      if (snap.docs.length > 1) {
        // We've had issues like multiple design breifs before,
        // just trying to get ahead of that.

        if (process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT) {
          throw new Error(
            `single file tag ${fileTag} has multiple files uploaded to it`
          );
        }
      }

      setMed(await Media.createFromQuerySnapshot(snap.docs[0]));
    });
  }
};

export default SingleFileUpload;
