class ErrorWithHumanMessage extends Error {
  constructor(error: Error, humanReadableMessage: string);
  constructor(message: string, humanReadableMessage: string);
  constructor(
    errormessage: Error | string,
    public humanReadableMessage: string
  ) {
    super(
      typeof errormessage === "string" ? errormessage : errormessage.message
    );
    if (errormessage instanceof Error) {
      this.stack = errormessage.stack;
    }
  }
}

export { ErrorWithHumanMessage };
