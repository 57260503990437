import React, { FC, useEffect } from "react";
import StatsRow from "./StatsRow";
import { DocProp } from "./types";
import useTagCloud from "./useTagCloud";
import { getHumanReadableNameForPropKey } from "./util";

export interface ControlledStatsRowProps {
  initialSelected: DocProp[];
  initialDeselected: DocProp[];
  index: number;
  title: string;
  update: (selected: DocProp[]) => void;
}

const ControlledStatsRow: FC<ControlledStatsRowProps> = (props) => {
  const [selected, deselected, update] = useTagCloud(
    props.initialSelected,
    props.initialDeselected
  );

  useEffect(() => {
    props.update(selected);
  }, [selected]);

  return (
    <StatsRow
      selected={selected}
      deselected={deselected}
      onChange={(_, tag) => update(tag)}
      title={getHumanReadableNameForPropKey(props.title)}
    />
  );
};

export { ControlledStatsRow };
export default ControlledStatsRow;
