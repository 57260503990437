import {
  Dialog,
  DialogContent,
  Box,
  DialogProps,
  Typography,
  Divider,
} from "@material-ui/core";
import React, { FC } from "react";

export interface PromotionalCodeErrorModalProps extends DialogProps {
  error: any;
}

export const PromotionalCodeErrorModal: FC<PromotionalCodeErrorModalProps> = ({
  error,
  ...props
}) => {
  return (
    <Dialog {...props}>
      <DialogContent>
        <Box p={1}>
          <Typography variant="h3">ERROR!</Typography>
          <Typography>Post the following in your support message</Typography>
        </Box>
        <Box p={1}>
          <Divider></Divider>
        </Box>
        <Box width="600px">
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
