import { Profile, Agent } from "@yardzen-inc/models";

export function filterMatchesAgentOrProfile(
  record: Profile | Agent,
  filter: string
): boolean {
  const regexIncludedInFilter = new RegExp(`${filter.toLowerCase()}`, "i");
  return regexIncludedInFilter.test(
    `${record.email?.toLowerCase() || ""} ${
      record.firstName.toLowerCase() || ""
    } ${record.lastName?.toLowerCase() || ""}`
  );
}
