import * as React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export const UserCtx = React.createContext<firebase.User | null | false>(null);

interface UserProviderProps {
  children: React.ReactNode[] | React.ReactNode;
}

export const UserProvider = React.memo((props: UserProviderProps) => {
  const [user, setUser] = React.useState<firebase.User | null | false>(null);

  React.useEffect(() => {
    return firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
      if (!user || user.isAnonymous === true) {
        return setUser(false);
      }

      setUser(user);
    });
  }, []);

  return <UserCtx.Provider value={user}>{props.children}</UserCtx.Provider>;
});
