import * as React from "react";
import { Box, Grid, Slider } from "@material-ui/core";
import { DesignAssignment } from "@yardzen-inc/models";
import { YZTypography } from "@yardzen-inc/react-common";

interface Props {
  assignment: DesignAssignment;
}

export const DesignerProgressSlider = ({ assignment }: Props) => {
  const [value, setValue] = React.useState<number>(assignment.status ?? 0);

  const initialRun = React.useRef<boolean>(false);

  React.useEffect(handleUpdateStatus, [value]);

  React.useEffect(handleRevisionChange, [assignment.revisionId]);

  return (
    <Grid container>
      <Grid item md={3}>
        <YZTypography
          variant="h5"
          type="serif"
          style={{ marginBottom: "1rem" }}
        >
          Progress Gauge
        </YZTypography>
      </Grid>
      <Grid item md={9}>
        <Box>
          <YZTypography variant="body1">{`${value}/100`}</YZTypography>
          <Slider
            value={value}
            onChange={handleValueChange}
            aria-labeledby="continuous-slider"
          />
        </Box>
      </Grid>
    </Grid>
  );

  function handleRevisionChange(): void {
    if (!initialRun.current) {
      initialRun.current = true;
      return;
    }

    setValue(assignment.status);
  }

  function handleValueChange(e: any, val: number | number[]): void {
    setValue(val as number);
  }

  function handleUpdateStatus(): () => void {
    const timeout = setTimeout(updateStatus, 100);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };

    async function updateStatus() {
      assignment.status = value;
      return assignment.save();
    }
  }
};

export default DesignerProgressSlider;
