import * as React from "react";
import {
  Chip,
  Dialog,
  DialogTitle,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  DialogActions,
  Button,
  DialogContent,
} from "@material-ui/core";
import { ChipProps } from "@material-ui/core/Chip";
import firebase from "firebase/compat/app";
import { getPackageDisplayName } from "../util/functions/getPackageDisplayName";

/*

  Only works for STRING properties

*/

interface EditableChipProps extends ChipProps {
  collection: string; // Collection name
  rootDoc: any; // Must include id. Any doc in a collection. Eg "profile" or "project"
  property: string; // The property we are displaying and editing on said doc
  menuOptions?: string[]; // supply if restricted to drop-down select
  labelModificationFunction?: (label: string) => string; // Special cases to modify displayed label
  onSave?: (label: string) => void; // Callback after saving
}

const EditableChip: React.FunctionComponent<EditableChipProps> = ({
  rootDoc,
  property,
  collection,
  menuOptions,
  labelModificationFunction,
  onSave,
  ...chipProps
}) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [newValue, setNewValue] = React.useState<string>(
    rootDoc[property] || ""
  );

  return (
    <>
      <Chip
        onClick={() => setModalOpen(true)}
        style={{ marginLeft: "1rem" }}
        variant={chipProps.variant || "outlined"}
        color={chipProps.color || "default"}
        label={
          !!labelModificationFunction
            ? labelModificationFunction(rootDoc[property])
            : rootDoc[property]
        }
      />
      <Dialog
        style={{ padding: "1.5rem" }}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <DialogTitle>Set {property}</DialogTitle>
        <DialogContent>
          {!!menuOptions ? (
            renderMenu()
          ) : (
            <TextField
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button onClick={updateProperty}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );

  async function updateProperty() {
    await firebase
      .firestore()
      .collection(collection)
      .doc(rootDoc.id)
      .update({
        [property]: newValue,
      });

    if (onSave) {
      onSave(newValue);
    }

    setModalOpen(false);
  }

  function renderMenu() {
    return (
      <FormControl style={{ width: "100%" }}>
        <InputLabel id="demo-simple-select-label">{property}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={newValue}
          onChange={handleChange}
        >
          {menuOptions?.map((opt, i) => (
            <MenuItem key={`${opt}-${i}`} value={opt}>
              {getPackageDisplayName(opt)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    setNewValue(event.target.value as string);
  }
};

export default EditableChip;
