import { Box, Button, MenuItem, TextField } from "@material-ui/core";
import React, { ChangeEventHandler, FC } from "react";
import { DateTimePicker } from "@material-ui/pickers";
import { datePickToChangeEvent } from "./util/datePickToChangeEvent";
import { components } from "src/api/pangaeaTypes.generated";
import { AdditionalDiscountsForm } from "./AdditionalDiscountsForm";

export interface PromotionalCodeCreateFormFieldsProps {
  disabled: boolean;
  handleChange: ChangeEventHandler;
  handleSubmit: (ev: React.FormEvent<HTMLFormElement>) => void;
  handleBlur: (ev: any) => void;
  hideCodeField?: boolean;
  values: {
    code: string;
    discount: string;
    discountType: string;
    startTime: string;
    endTime: string;
    maxRedemptions: string;
    applicationSuccessMessage: string | null | undefined;
    category: string | undefined;
    additionalDiscounts:
      | components["schemas"]["PromotionalCodeAdditionalDiscount"][]
      | undefined;
  };
  errors: {
    code?: string;
    discount?: string;
    discountType?: string;
    startTime?: string;
    endTime?: string;
    maxRedemptions?: string;
    applicationSuccessMessage?: string;
    category?: string;
    additionalDiscounts?: string | undefined;
  };
  products: { id: string; name: string }[];
}

export const PromotionalCodeCreateFormFields: FC<PromotionalCodeCreateFormFieldsProps> =
  (props) => {
    return (
      <Box component="form" onSubmit={props.handleSubmit} width="100%">
        {!props.hideCodeField && (
          <Box p={1}>
            <TextField
              onBlur={props.handleBlur}
              value={props.values.code}
              name="code"
              error={!!props.errors.code}
              fullWidth
              helperText={props.errors.code}
              onChange={props.handleChange}
              label="code"
              disabled={props.disabled}
            />
          </Box>
        )}
        <Box p={1}>
          <TextField
            onBlur={props.handleBlur}
            select
            value={props.values.category}
            fullWidth
            error={!!props.errors.category}
            helperText={props.errors.category}
            onChange={props.handleChange}
            label="Category"
            name="category"
            disabled={props.disabled}
          >
            <MenuItem value="CONTRACTOR">CONTRACTOR</MenuItem>
            <MenuItem value="MARKETING">MARKETING</MenuItem>
            <MenuItem value="REFERRAL">REFERRAL</MenuItem>
          </TextField>
        </Box>
        <Box p={1}>
          <TextField
            onBlur={props.handleBlur}
            value={props.values.maxRedemptions}
            fullWidth
            error={!!props.errors.maxRedemptions}
            helperText={props.errors.maxRedemptions}
            onChange={props.handleChange}
            label="Max Redemptions"
            name="maxRedemptions"
            disabled={props.disabled}
          />
        </Box>
        <Box p={1}>
          <TextField
            onBlur={props.handleBlur}
            select
            error={!!props.errors.discountType}
            fullWidth
            helperText={props.errors.discountType}
            value={props.values.discountType}
            onChange={props.handleChange}
            name="discountType"
            label="Discount Type"
            disabled={props.disabled}
          >
            <MenuItem value={"FIXED"}>FIXED</MenuItem>
            <MenuItem value={"PERCENTAGE"}>PERCENTAGE</MenuItem>
          </TextField>
        </Box>
        <Box p={1}>
          <TextField
            value={props.values.discount}
            onBlur={props.handleBlur}
            error={!!props.errors.discount}
            fullWidth
            helperText={props.errors.discount}
            name="discount"
            onChange={props.handleChange}
            label={`Discount (${
              props.values.discountType === "FIXED" ? "$" : "%"
            })`}
            disabled={props.disabled}
          />
        </Box>
        <Box p={1}>
          <DateTimePicker
            disabled={props.disabled}
            error={!!props.errors.startTime}
            onBlur={props.handleBlur}
            helperText={props.errors.startTime}
            name="startTime"
            label="Start Time"
            variant="inline"
            fullWidth
            value={props.values.startTime}
            onChange={(date) =>
              props.handleChange(datePickToChangeEvent(date, "startTime"))
            }
          />
        </Box>
        <Box p={1}>
          <DateTimePicker
            disabled={props.disabled}
            onBlur={props.handleBlur}
            error={!!props.errors.endTime}
            helperText={props.errors.endTime}
            label="End Time"
            name="endTime"
            variant="inline"
            fullWidth
            value={props.values.endTime}
            onChange={(date) =>
              props.handleChange(datePickToChangeEvent(date, "endTime"))
            }
          />
        </Box>
        <Box p={1}>
          <TextField
            value={props.values.applicationSuccessMessage}
            onBlur={props.handleBlur}
            error={!!props.errors.applicationSuccessMessage}
            fullWidth
            helperText={props.errors.applicationSuccessMessage}
            name="applicationSuccessMessage"
            onChange={props.handleChange}
            label={"Success Message"}
            disabled={props.disabled}
          />
        </Box>
        <AdditionalDiscountsForm
          products={props.products}
          values={{
            additionalDiscounts: props.values.additionalDiscounts || [],
          }}
          handleChange={props.handleChange}
          handleBlur={props.handleBlur}
          disabled={props.disabled}
        />

        {!props.disabled && (
          <Box p={1}>
            <Button
              variant="text"
              type="submit"
              disabled={props.errors && !!Object.keys(props.errors).length}
            >
              Done Editing
            </Button>
          </Box>
        )}
      </Box>
    );
  };
