/*
  runAsyncBatch will tak an array of async functions and execute them
  concurrently, returning an array that is the result of all async jobs,

  Error handling is up to you, any thrown errors will not be caught, and will
  abort all other jobs
*/
function runAsyncBatch<t extends unknown>(
  jobs: (() => Promise<t>)[]
): Promise<t[]> {
  return Promise.all(jobs.map((job) => job()));
}

export { runAsyncBatch };
