import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formControls: {
    display: "flex",
  },
  chip: {
    margin: theme.spacing(0.2),
  },
}));

export interface VisibilityFilterSelectProps {
  onChange: (value: string) => any;
  shouldDisplay: boolean;
  initialValue: string;
  options: string[];
  title: string;
}

function VisibilityFilterSelect(props: VisibilityFilterSelectProps) {
  const classes = useStyles();

  const [value, setValue] = React.useState<string>(props.initialValue);

  React.useEffect(handleValueChange, [value]);

  if (!props.shouldDisplay) {
    return <div />;
  }

  return (
    <div className={classes.root}>
      <div>
        <YZTypography variant="h6" type="serif">
          {props.title}
        </YZTypography>
        <div className={classes.formControls}>{renderItems()}</div>
      </div>
    </div>
  );

  function renderItems(): React.ReactNode {
    const values = props.options.map((val) => (
      <Chip
        label={val}
        key={`visibility-filter-${val}`}
        className={classes.chip}
        color={val === value ? "primary" : "default"}
        onClick={() => handleChange(val)}
        variant={val === value ? "default" : "outlined"}
        size="small"
      ></Chip>
    ));

    values.push(
      <Chip
        label={"Don't Filter"}
        key={"visibility-filter-None"}
        className={classes.chip}
        color={"" === value ? "primary" : "default"}
        onClick={() => handleChange("")}
        variant={"" === value ? "default" : "outlined"}
        size="small"
      />
    );

    return values;
  }

  function handleChange(val: string) {
    setValue(val);
  }

  function handleValueChange() {
    /* async iffe prevents ui from freezing up on click by pushing
    the change event further down the stack */
    void (async function () {
      props.onChange(value);
    })();
  }
}

export { VisibilityFilterSelect };
export default VisibilityFilterSelect;
