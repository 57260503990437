import React, { FC } from "react";
import { RouteComponentProps } from "react-router-dom";
import MyYardSummary from "./MyYardSummary";

export interface MyYardPageProps extends RouteComponentProps {}

const MyYardPage: FC<MyYardPageProps> = (props) => {
  return (
    <MyYardSummary
      history={props.history}
      location={props.location}
      match={props.match}
    />
  );
};

export { MyYardPage };
export default MyYardPage;
