import * as React from "react";
import { Project } from "@yardzen-inc/models";

export const useDaNotesCounter = (p: Project | null): number | null => {
  const [count, setCount] = React.useState<number | null>(null);

  React.useEffect(subscribeToNoteCount, [p]);

  return count;

  function subscribeToNoteCount(): (() => void) | void {
    if (!p) {
      if (count !== null) {
        setCount(null);
      }

      return;
    }

    return p.docRef.collection("designAssignments").onSnapshot((aSnap) => {
      setCount(aSnap.docs.filter((d) => !!d.data()?.notes).length);
    });
  }
};

export default useDaNotesCounter;
