import { YardDifficultyRating } from "@yardzen-inc/models";
import Dinero from "dinero.js";

const difficultyBudgetMap = {
  1: 10,
  2: 15,
  3: 20,
};

/**
 * Calculates the budget shown to designer based on guesstimated
 * yard difficulty
 *
 *  1 = 10% of total_budget
 *
 *  2 = 15% of total_budget
 *
 *  3 = 20% of total_budget
 *
 *
 * @param total_budget Clients calculated budget from Hasura budget_metadata
 * @param yardDifficultyRating CDM Agent input describing assumed install difficulty
 *
 * {@link https://docs.google.com/document/d/1150yOVDpMJN-jJt-xv0U3I25I7aj04hEmK27V4-37aA/edit#heading=h.84we0lpr27ux More Info}
 */
function calculateDesignerVisibleBudget(
  total_budget: number,
  yardDifficultyRating: YardDifficultyRating
) {
  const mappedDifficultyPercentage = difficultyBudgetMap[yardDifficultyRating];
  if (isNaN(total_budget) || !mappedDifficultyPercentage) {
    throw new Error("Error calculating budget: Invalid props");
  }

  const totalBudgetObj = Dinero({
    amount: Number(total_budget),
    precision: 2, // precision === 2 means it is in cents. ie 1050 === 10.50
    currency: "USD",
  });

  const percentToSubtract = totalBudgetObj.percentage(
    mappedDifficultyPercentage
  );

  // Round and convert back to cents
  return totalBudgetObj.subtract(percentToSubtract).toRoundedUnit(0) * 100;
}

export { calculateDesignerVisibleBudget };
