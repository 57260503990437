import { useQuery } from "@yardzen-inc/graphql";
import {
  MATERIAL_RETAILER_BY_NAME,
  PLANT_RETAILER_BY_NAME,
  PRODUCT_RETAILER_BY_NAME,
} from "../../EmployeeView/AssetLibraries/Shared/graphqlQueries";

type RetailerTable =
  | "product_retailer"
  | "material_retailer"
  | "plant_retailer";

export function useGetLowesRetailer(retailerTable: RetailerTable) {
  const name = "Lowe's";

  const query =
    retailerTable === "product_retailer"
      ? PRODUCT_RETAILER_BY_NAME
      : retailerTable === "material_retailer"
      ? MATERIAL_RETAILER_BY_NAME
      : PLANT_RETAILER_BY_NAME;

  const { loading, data } = useQuery<{
    [key: string]: { id: string; name: string }[];
  }>(query, {
    variables: {
      name,
    },
    fetchPolicy: "cache-and-network",
  });

  return [loading, data?.[retailerTable]?.[0]];
}
