/**
 * The multiplier to multiply the low wishlist estimate by to calculate the
 * all in budget range.
 */
export const ALL_IN_RANGE_LOW_ESTIMATE_MULTIPLIER = 1.1;

/**
 * The multiplier to multiply the high wishlist estimate by to calculate the
 * all in budget range.
 */
export const ALL_IN_RANGE_HIGH_ESTIMATE_MULTIPLIER = 1.2;
