import { Box, makeStyles, TextField } from "@material-ui/core";
import React, { FC, useMemo, useState } from "react";
import { CloudUpload } from "@material-ui/icons";
import { liisaErrorAlert } from "../../../util/genericAlert";
import "firebase/compat/auth";

export interface UploadFormComponentProps {
  title: string;
  initialValue?: string;
  accept?: string[];
  onUploadCompleted: (value: string) => void;
  handleUpload: (file: File) => Promise<string>;
}

const linkR = /^http(s{0,1}):\/\//;

let componentCount = 0;

const useStyles = makeStyles({
  pointerCursor: {
    cursor: "pointer",
    pointerEvents: "all",
  },
});

const UploadFormComponent: FC<UploadFormComponentProps> = (props) => {
  const classes = useStyles();
  const [inputId, labelId] = useMemo(getInputId, []);
  const [inputLocked, setInputLocked] = useState<boolean>(false);
  const [value, setValue] = useState<string>(() =>
    stripLink(props.initialValue ?? "")
  );

  const onChange = async (ev: React.ChangeEvent) => {
    const file = (ev.target as HTMLInputElement).files?.[0];

    if (!file) {
      alert(
        "Error uploading file. Please try again. If this error persists, please contact support."
      );
      return liisaErrorAlert(`Error uploading file`, "No file selected");
    }
    setInputLocked(true);
    try {
      const filename = await props.handleUpload(file);
      await props.onUploadCompleted(filename);
      setValue(file.name);
    } catch (error) {
      alert(
        "Error uploading file. Please try again. If this error persists, please contact support."
      );
      liisaErrorAlert(`Error uploading file ${file.name}`, error.message);
    }
    setInputLocked(false);
  };

  return (
    <Box>
      <input
        type="file"
        id={inputId}
        hidden
        accept={useMemo(formatAccept, [props.accept])}
        onChange={(ev) => onChange(ev)}
        disabled={inputLocked}
      />
      <label htmlFor={inputId} id={labelId}>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          className={classes.pointerCursor}
          alignItems="center"
        >
          <Box p={1}>
            <CloudUpload className={classes.pointerCursor} />
          </Box>
          <TextField
            className={classes.pointerCursor}
            value={inputLocked ? "Uploading..." : value || "No File Uploaded"}
            label={props.title}
            disabled
            onClick={() => document.getElementById(labelId)?.click()}
            fullWidth
          />
        </Box>
      </label>
    </Box>
  );

  function formatAccept(): string | undefined {
    if (!Array.isArray(props.accept)) {
      return props.accept;
    }

    return props.accept.join(",");
  }

  function getInputId(): [string, string] {
    const id = `upload-button-${props.title}-${++componentCount}`;
    return [id, `${id}-label`];
  }

  function stripLink(link: string) {
    if (linkR.test(link)) {
      return link.substring(link.lastIndexOf("/") + 1);
    }

    return link;
  }
};

export { UploadFormComponent as UploadField };
export default UploadFormComponent;
