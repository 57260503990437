import * as React from "react";
import { CircularProgress, Fade } from "@material-ui/core";

interface Props {
  in?: boolean;
  style?: React.CSSProperties;
}

export default (props: Props) => {
  return (
    <Fade in={!!props.in}>
      <div
        style={{
          position: "fixed",
          top: "50vh",
          left: "50vw",
          transform: "translate(-50%, -50%)",
          ...(props.style || {}),
        }}
      >
        <CircularProgress
          style={{
            height: "6rem",
            width: "6rem",
          }}
        />
      </div>
    </Fade>
  );
};
