import { useEffect, useState } from "react";
import { Assignment, Profile } from "@yardzen-inc/models";
import { getProjectById } from "../firebase/firebaseClient";
import { Project } from "src/Interfaces";

export interface ModelingAssignmentWithProfile {
  assignment: Assignment;
  profile: Profile;
  project: Project;
}

function useOpenModelingAssignments() {
  const [loading, setLoading] = useState<boolean>(false);
  const [
    openModelingAssignmentsWithProfile,
    setOpenModelingAssignmentsWithProfile,
  ] = useState<ModelingAssignmentWithProfile[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [lastRefresh, setLastRefresh] = useState<Date | null>(null);

  async function getOpenModelingAssignments() {
    try {
      setLoading(true);
      const modelingAssignmentsWithProfiles =
        await fetchPrioritizedOpenModelingAssignments();

      setOpenModelingAssignmentsWithProfile(modelingAssignmentsWithProfiles);
      setLastRefresh(new Date());
      return modelingAssignmentsWithProfiles;
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getOpenModelingAssignments();
  }, []);

  return {
    loading,
    openModelingAssignmentsWithProfile,
    error,
    refresh: getOpenModelingAssignments,
    lastRefresh,
  };
}

async function fetchPrioritizedOpenModelingAssignments() {
  let modelingAssignments = await Assignment.fetchAll(false, true, "modeling");

  // filter for listed / in the queue
  let modelingAssignmentsWithProfilesPromises = modelingAssignments
    .filter((assignment: Assignment) => assignment.listed)
    // get associated profiles & projects
    .map(async (assignment) => ({
      assignment: assignment,
      profile: await assignment.getProjectOwner(),
      project: await getProjectById(assignment.projectId),
    }));
  let modelingAssignmentsWithProfiles = await Promise.all(
    modelingAssignmentsWithProfilesPromises
  );

  // sort assignments
  const sortFunction = (
    a: ModelingAssignmentWithProfile,
    b: ModelingAssignmentWithProfile
  ) => {
    if (a.project.isExpedited && !b.project.isExpedited) {
      return -1;
    } else if (!a.project.isExpedited && b.project.isExpedited) {
      return 1;
    } else {
      return a.assignment.createdAt - b.assignment.createdAt;
    }
  };

  return modelingAssignmentsWithProfiles.sort(sortFunction);
}

export { useOpenModelingAssignments, fetchPrioritizedOpenModelingAssignments };
