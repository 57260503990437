import * as React from "react";
import {
  Typography,
  Grid,
  Link,
  ListItem,
  List,
  Divider,
  Box,
  makeStyles,
} from "@material-ui/core";
import { Profile, Project, ProjectProperties } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import CdmFeedbackPage from "../../SelfAssignPage/CdmFeedbackPage";

interface Props {
  clientRecord: Profile;
  permissions: Object;
}

const useStyles = makeStyles((theme) => ({
  estimateFeedbackCaption: {
    marginRight: `${theme.spacing(2)}px`,
    whiteSpace: "nowrap",
  },
  estimateFeedbackText: {
    fontStyle: "italic",
    [theme.breakpoints.down("sm")]: {
      marginRight: `${theme.spacing(1)}px`,
    },
  },
}));

export default function ClientFeedbackTab(props: Props) {
  const [project, setProject] = React.useState<Project>();

  const permissions = props.permissions;
  const clientRecord = props.clientRecord;
  // @ts-ignore
  const enabled = permissions["profile"]["access"] === "update";
  let urls: Array<string> = [];
  let inspirationText = "";
  if (
    clientRecord &&
    "pinterestUrl" in clientRecord &&
    clientRecord["pinterestUrl"]
  ) {
    urls = clientRecord["pinterestUrl"].split(/[\n,\ ]+/);
  }

  urls = urls.filter((url) => {
    if (url.indexOf("http") === -1) {
      if (url.trim()) {
        inspirationText = inspirationText + " " + url.trim();
      }
      return false;
    }

    return true;
  });

  React.useEffect(() => {
    void (async function () {
      const snap = await firebase
        .firestore()
        .collection("projects")
        .where("profileId", "==", props.clientRecord.id)
        .get();

      if (snap.empty) {
        return;
      }

      const project = {
        ...(snap.docs[0].data() as ProjectProperties),
        id: snap.docs[0].id,
      };
      setProject(project as Project);
    })();
  });

  const { estimateFeedbackText, estimateFeedbackCaption } = useStyles();

  return (
    <Box width="100%">
      {project && (
        <CdmFeedbackPage
          profile={clientRecord}
          width="960px"
          maxWidth="100%"
          margin="auto"
          project={project}
        />
      )}
      <Grid
        container
        direction="column"
        style={{ maxWidth: "100vw", width: "960px", margin: "auto" }}
      >
        <Grid item>
          <Typography variant="h4">Estimate Education Feedback</Typography>
          <Box display="flex" pt={2}>
            <Typography className={estimateFeedbackCaption}>
              Education Note Left by CDM:
            </Typography>
            <Typography paragraph className={estimateFeedbackText}>
              {project?.estimateEducationNote ??
                "The CDM agent did not leave a note regarding the client's estimate"}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography className={estimateFeedbackCaption}>
              Feedback Left by Client:
            </Typography>
            <Typography paragraph className={estimateFeedbackText}>
              {project?.estimateEducationFeedback ??
                "The client did not provide any feedback on their estimate."}
            </Typography>
          </Box>
          <Divider></Divider>
          <br />
        </Grid>
        <Grid item>
          <Typography paragraph variant="h6">
            Inspiration
          </Typography>
          {!!!inspirationText && (
            <Typography paragraph variant="subtitle2">
              <span style={{ fontStyle: "italic" }}>
                No inspiration text was provided by homeowner
              </span>
            </Typography>
          )}
          {!!inspirationText && (
            <>
              <Typography variant="subtitle1">Inspiration text:</Typography>
              <Typography paragraph>{inspirationText}</Typography>
            </>
          )}

          {urls.length === 0 && (
            <Typography paragraph variant="subtitle2">
              <span style={{ fontStyle: "italic" }}>
                No inspiration links were provided by homeowner
              </span>
            </Typography>
          )}

          {urls.length > 0 && (
            <>
              <Typography variant="subtitle1">Pinterest link(s):</Typography>
              <List>
                {urls.map((url) => (
                  <ListItem>
                    <Typography>
                      <Link target="_blank" href={url}>
                        {url}
                      </Link>
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Grid>
        <Grid item>
          <Typography paragraph variant="h6">
            Feedback from homeowner
          </Typography>
          <Divider></Divider>
          <br />
          <Grid
            direction="column"
            alignItems="stretch"
            alignContent="stretch"
            style={{ maxWidth: "99vw" }}
          >
            {answerFields.map((field) => (
              <Grid item key={field.id}>
                {getAnswerFormComponent(field, enabled)}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  function getAnswerFormComponent(field: any, enabled: boolean) {
    let answerText = "";

    if (field.id in clientRecord) {
      // @ts-ignore
      answerText = clientRecord[field.id];
      // @ts-ignore
    } else if (clientRecord["answers"] && clientRecord["answers"][field.id]) {
      // @ts-ignore
      answerText = clientRecord["answers"][field.id];
    }

    return (
      <>
        <Typography variant="body1">Q: {field.text}</Typography>
        <br />
        <Typography variant="h6">A: {answerText || "<Left Blank>"}</Typography>
        <br />
        <Divider></Divider>
        <br />
      </>
    );
  }
}

const answerFields = [
  { id: "priority1", text: "Priority 1", type: "text" },
  { id: "priority2", text: "Priority 2", type: "text" },
  { id: "priority3", text: "Priority 3", type: "text" },
  { id: "priority4", text: "Priority 4", type: "text" },
  { id: "priority5", text: "Priority 5", type: "text" },
  {
    id: "answer4",
    text: "Do you have any strong likes when it comes to plants, materials or anything else?",
    type: "text",
  },
  {
    id: "answer5",
    text: "Do you have any strong dislikes when it comes to plants, materials or anything else?",
    type: "text",
  },
  {
    id: "answer3",
    text: "Anything else you’d like your team to know before we start your project?",
    type: "text",
  },
];
