import {
  PRODUCT_COLOR_SELECT,
  PRODUCT_MATERIAL_SELECT,
  PRODUCT_PRICE_TIER_SELECT,
  PRODUCT_SETTING_SELECT,
  PRODUCT_STYLE_SELECT,
  PRODUCT_TYPE_SELECT,
  PRODUCT_VENDOR_SELECT,
} from "@yardzen-inc/graphql";
import { IFilterBoxOption } from "../../../Interfaces";
import {
  PRODUCT_REGION_SELECT,
  PRODUCT_RETAILER_SELECT,
} from "../Shared/graphqlQueries";

const ProductLibraryFilterBoxOptions: IFilterBoxOption[] = [
  {
    label: "Color",
    query: PRODUCT_COLOR_SELECT,
    listResultName: "product_color",
  },
  {
    label: "Material",
    query: PRODUCT_MATERIAL_SELECT,
    listResultName: "product_material",
  },
  {
    label: "Price Tier",
    query: PRODUCT_PRICE_TIER_SELECT,
    listResultName: "product_price_tier",
  },
  {
    label: "Setting",
    query: PRODUCT_SETTING_SELECT,
    listResultName: "product_setting",
  },
  {
    label: "Style",
    query: PRODUCT_STYLE_SELECT,
    listResultName: "product_style",
  },
  {
    label: "Type",
    query: PRODUCT_TYPE_SELECT,
    listResultName: "product_type",
  },
  {
    label: "Vendor",
    query: PRODUCT_VENDOR_SELECT,
    listResultName: "product_vendor",
  },
  {
    label: "Region",
    query: PRODUCT_REGION_SELECT,
    listResultName: "product_region",
  },
  {
    label: "Retailer",
    query: PRODUCT_RETAILER_SELECT,
    listResultName: "product_retailer",
  },
];

export default ProductLibraryFilterBoxOptions;
