import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import { ProfileProperties, Project } from "@yardzen-inc/models";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },

  clientPaid: {
    color: "green",
  },
  clientNotPaid: {
    color: "orange",
  },
}));

interface Props {
  clientRecord: ProfileProperties;
  project: Project;
}

const ClientPaidRevisionsMessage: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const numberOfRevisions = props.clientRecord["additionalRevisions"];
  const purchasedRevision =
    props.project.tags?.includes("PURCHASED-ADDITIONAL-REVISION") ||
    props.project.isHOA;

  return (
    <Box className={classes.box}>
      {purchasedRevision || (numberOfRevisions && numberOfRevisions > 0) ? (
        <Typography className={classes.clientPaid}>
          The client has paid for an additional revision.
        </Typography>
      ) : (
        <Typography className={classes.clientNotPaid}>
          The client has not paid for any additional revisions.
        </Typography>
      )}
    </Box>
  );
};

export { ClientPaidRevisionsMessage };
