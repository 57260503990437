import React, { FC, useContext, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { formatNumToUSD, YZTypography } from "@yardzen-inc/react-common";
import { YardDifficultyRating } from "@yardzen-inc/models";
import { CDMPageContext } from "../../EmployeeView/CDM/CDMContextProvider";

export interface WesleySummaryPageProjectDifficultyProps {
  isBotanicalClient: boolean;
  designerVisibleBudget?: string; // The calculated budget based on projectDifficulty
}

const WesleySummaryPageProjectDifficulty: FC<WesleySummaryPageProjectDifficultyProps> =
  ({ designerVisibleBudget, isBotanicalClient }) => {
    const { projectDifficulty, setProjectDifficulty } =
      useContext(CDMPageContext);

    // Some legacy botanical clients may have a different projectDifficulty
    // and we don't want to change those in the UI.
    const isNewBotanicalClient = isBotanicalClient && !projectDifficulty;

    useEffect(() => {
      if (isNewBotanicalClient) {
        setProjectDifficulty(1);
      }
    }, []);

    return (
      <>
        <Box>
          <YZTypography variant="h5">
            Project Difficulty (Required)
          </YZTypography>
          {designerVisibleBudget && (
            <YZTypography variant="caption">
              Design Budget: {formatNumToUSD(Number(designerVisibleBudget))}
            </YZTypography>
          )}
        </Box>
        <Box mt={1}>
          {isBotanicalClient && (
            <YZTypography color="error" variant="body2">
              This client has a Botanical package and must have a difficulty
              rating of Easy.
            </YZTypography>
          )}
        </Box>
        <Box mt={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">This project will be:</FormLabel>
            <RadioGroup
              aria-label="projectDifficulty"
              name="projectDifficulty"
              value={projectDifficulty?.toString() || null}
              onChange={(e) =>
                setProjectDifficulty(
                  Number(e.target.value) as YardDifficultyRating
                )
              }
            >
              <FormControlLabel value="1" control={<Radio />} label="1: Easy" />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="2: Average"
                disabled={isBotanicalClient}
              />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="3: Difficult"
                disabled={isBotanicalClient}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </>
    );
  };

export { WesleySummaryPageProjectDifficulty };
