import * as React from "react";
import { Typography } from "@material-ui/core";
import { AnnotatorPage } from "@yardzen-inc/annotator";
import { Media } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import moment from "moment";
import { UserCtx } from "./util/UserContext";
import { Redirect } from "react-router";
import LoadingAbsCenter from "./Components/LoadingAbsCenter";
import { ProfileCtx } from "./util/ProfileContext";

interface Revision {
  dateCreated: string;
  dateSentToClient: string;
  dateSubmittedForReview: string;
  deliverables: string[];
  profileId: string;
  state: string;
  id: string;
}

export default () => {
  const user = React.useContext(UserCtx);
  const profile = React.useContext(ProfileCtx);

  const [haveUser, setHaveUser] = React.useState<boolean>(false);
  const [media, setMedia] = React.useState<Media[] | null>(null);
  const [badPath, setBadPath] = React.useState<boolean>(false);
  const [redirectToLogin, setRedirectToLogin] = React.useState<boolean>(false);

  const filteredMedia = React.useMemo(
    () => media?.filter((m) => !!m) || [],
    [media]
  );

  React.useEffect(() => {
    if (user && !haveUser) {
      if (user.isAnonymous) {
        firebase.auth().signOut();
      } else {
        setHaveUser(true);
      }
    }

    if (user === false) {
      setRedirectToLogin(true);
    }
  }, [user]);

  React.useEffect(() => {
    (async function () {
      do {
        if (haveUser && user) {
          const revs = await getRevisions();
          if (!revs.length) break;

          const params = getParams();
          const rev = revs[revs.length - 1 - params.i];

          if (rev.deliverables) {
            let meds = await getMedia(rev.deliverables);

            if (meds?.length) {
              meds = meds.filter((m) => m?.fileType.includes("image"));
            }

            return setMedia(meds);
          }

          return setMedia(
            (
              await getMedia(
                (
                  await firebase
                    .firestore()
                    .collection("media")
                    .where("revisionId", "==", rev.id)
                    .get()
                ).docs.map(({ id }) => id)
              )
            ).filter((m) => {
              return !!m && m.fileType.toLowerCase().includes("image");
            })
          );
        }
      } while (false);
    })();
  }, [haveUser]);

  if (badPath) {
    return <Typography variant="h3">Bad Url</Typography>;
  }

  if (redirectToLogin) {
    return <Redirect to="/login" />;
  }

  if (media && user && profile) {
    return (
      <AnnotatorPage
        media={filteredMedia}
        readonly
        open={true}
        user={user}
        profile={profile as any}
        currentUser="pm"
      ></AnnotatorPage>
    );
  }

  return <LoadingAbsCenter in={true}></LoadingAbsCenter>;

  async function _getSingleMedia(id: string): Promise<Media> {
    return Media.fetchSingle(id) as Promise<Media>;
  }

  async function getMedia(devs: string[]): Promise<Media[]> {
    const meds: Promise<Media>[] = [];

    for (let d of devs) {
      meds.push(_getSingleMedia(d));
    }

    return Promise.all(meds);
  }

  async function getRevisions(): Promise<Revision[]> {
    const params = getParams();
    const snap = await firebase
      .firestore()
      .collection("revisions")
      .where("profileId", "==", params.id)
      .get();

    if (!snap.docs.length) {
      setBadPath(true);
      return [];
    }

    const revs: Revision[] = [];

    for (let rdoc of snap.docs) {
      revs.push({ ...(rdoc.data() as Revision), id: rdoc.id });
    }

    console.log(revs);

    return revs.sort((a, b) =>
      moment(a.dateCreated).unix() > moment(b.dateCreated).unix() ? 1 : -1
    );
  }

  function getParams(): { id: string; i: number } {
    const parts = window.location.pathname.split("/");
    const i = parseInt(parts.pop() as string);
    const id = parts.pop() as string;
    return { i, id };
  }
};
