import * as React from "react";
import {
  TableCell,
  TableRow,
  makeStyles,
  IconButton,
  Dialog,
  DialogContent,
  Box,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { ApolloQueryResult } from "@yardzen-inc/graphql";
import {
  AssetLibraryRowType,
  IMaterialBaseMaterialMaterialLink,
  IMaterialBaseMaterialStyleLink,
  IMaterialBaseMaterialTypeLink,
  ModelFiletype,
} from "../../Interfaces";
import getAssetLibraryRow from "../../EmployeeView/AssetLibraries/getRowUtil";
import QuantityInput from "./QuantityInput";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { formatNumToUSD, YZTypography } from "@yardzen-inc/react-common";
import PopupTableItem from "./Shared/PopupTableItem";
import { Close } from "@material-ui/icons";
import { PaintLocationConstants } from "../../ConstantValues/PaintLocationConstants";
import debounce from "lodash/debounce";
import { ModelFileDownloadButton } from "../ModelFileDownloadButton";
import cleanedDownloadableFileName from "../../EmployeeView/AssetLibraries/Shared/CleanedDownloadableFileName";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  itemBtns: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  iconUri: {
    maxWidth: "10rem",
  },
  editItems: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },
  editItem: {
    flex: 1,
    marginBottom: theme.spacing(1),
  },
  saveProgress: {
    display: "flex",
    alignItems: "center",
  },
  expandBtn: {},
  topSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

interface Props {
  isEven: boolean;
  refetch:
    | ((
        variables?: Partial<Record<string, any>> | undefined
      ) => Promise<ApolloQueryResult<any>>)
    | undefined;
  row: AssetLibraryRowType[][];
  onUpdateQuantity: (
    id: string,
    quantity: number,
    isPaint: boolean,
    location: string
  ) => void;
  onUpdatePaintLocation: (
    id: string,
    locations: string[],
    quantity: number
  ) => void;
  idAndQuantityMap: Object;
  selectedRowsIdMap: { [id: string]: { location: string } };
}

function SurfaceSelectionTableRow(props: Props) {
  const classes = useRowStyles();

  const row = getAssetLibraryRow(props.row);

  const [open, setOpen] = React.useState(false);
  const [quantity, setQuantity] = React.useState(
    // @ts-ignore
    props.idAndQuantityMap[row.id] || 0
  );

  const defaultPaintLocations: PaintLocationConstants[] = [
    PaintLocationConstants.MainHouse,
  ];

  const isPaint = row.material_base_material_type_links.some(
    (l: { material_type: { name: string } }) => l.material_type.name === "Paint"
  );

  const onUpdateQuantity = (qty: number) => {
    props.onUpdateQuantity(row.id, qty, isPaint, locations.join(","));
    setQuantity(qty);
    if (qty < 1) {
      setLocations(defaultPaintLocations);
    }
  };

  const unit = ["None", null].includes(row.unitByUnit.name)
    ? "Units"
    : row.unitByUnit.name;

  const onExpand = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const inStockText = (row: any) => {
    if (typeof row.in_stock === "boolean") {
      return row.in_stock ? "Yes" : "No";
    }
    return "--";
  };

  const [locations, setLocations] = React.useState<string[]>(
    props.selectedRowsIdMap[row.id]?.location
      ? props.selectedRowsIdMap[row.id].location.split(",")
      : defaultPaintLocations
  );

  const debounceDelay = 600;
  const debouncedLocationUpdate = React.useCallback(
    debounce(
      (locs, qty) => props.onUpdatePaintLocation(row.id, locs, qty),
      debounceDelay
    ),
    []
  );

  const updateLocations = (e: React.ChangeEvent<any>) => {
    debouncedLocationUpdate.cancel();
    let locations = e.target.value;
    if (!locations.length) {
      locations = defaultPaintLocations;
    }

    setLocations(locations);
    debouncedLocationUpdate(locations, quantity);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <Box
          display="flex"
          alignItems="center"
          style={{ padding: "0.5rem 0.5rem 0.5rem 1rem" }}
        >
          <YZTypography variant="h6" style={{ flex: "1" }}>
            {row.name}
          </YZTypography>
          <IconButton onClick={() => handleClose()}>
            <Close style={{ fontSize: 17 }} />
          </IconButton>
        </Box>
        <DialogContent>
          <PopupTableItem title="Image">
            <img className={classes.iconUri} src={row.icon_uri} />
          </PopupTableItem>
          <PopupTableItem title="Material">
            {row.material_base_material_material_links
              ?.map(
                (style: IMaterialBaseMaterialMaterialLink) =>
                  style.material_material.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Type">
            {row.material_base_material_type_links
              ?.map(
                (type: IMaterialBaseMaterialTypeLink) => type.material_type.name
              )
              .join(", ")}
          </PopupTableItem>
          <PopupTableItem title="Setting">
            {row.settingByMaterialSetting?.name}
          </PopupTableItem>
          <PopupTableItem title="Price Tier">
            {row.priceTierByMaterialPriceTier?.name}
          </PopupTableItem>
          <PopupTableItem title="Unit">{row.unitByUnit.name}</PopupTableItem>
          <PopupTableItem title="Unit Cost">
            <>{formatNumToUSD(parseFloat(row.unit_cost))}</>
          </PopupTableItem>
          <PopupTableItem title="Style">
            <>
              {row.material_base_material_style_links
                ?.map(
                  (style: IMaterialBaseMaterialStyleLink) =>
                    style.material_style.name
                )
                .join(", ")}
            </>
          </PopupTableItem>
          <PopupTableItem title="Link" ellipsis>
            <a href={row.link} target="_blank">
              {row.link}
            </a>
          </PopupTableItem>
          <PopupTableItem title="Vendor">
            {row.vendorByMaterialVendor?.name}
          </PopupTableItem>
          <PopupTableItem title="Retailer">
            {row.retailerByMaterialRetailer?.name}
          </PopupTableItem>
          <PopupTableItem title="Collection Name">
            {row.collectionNameByMaterialCollectionName?.name}
          </PopupTableItem>
          <PopupTableItem title="In Stock">
            {
              <div
                style={{
                  color: row.in_stock === false ? "red" : "",
                }}
              >
                {inStockText(row)},
              </div>
            }
          </PopupTableItem>
          <PopupTableItem title="Sketchup File" ellipsis>
            <ModelFileDownloadButton
              filetype={ModelFiletype.sketchup}
              filenameOrUrl={row.sketchup_file_link}
              downloadableFileName={cleanedDownloadableFileName(row.name)}
            />
          </PopupTableItem>
          <PopupTableItem title="V-Ray File" ellipsis>
            <ModelFileDownloadButton
              filetype={ModelFiletype.vray}
              filenameOrUrl={row.vray_file_link}
              downloadableFileName={cleanedDownloadableFileName(row.name)}
            />
          </PopupTableItem>
          <PopupTableItem title="MAX File" ellipsis>
            {row.max_file_link}
          </PopupTableItem>
          <PopupTableItem title="Lumion File Name" ellipsis>
            <ModelFileDownloadButton
              filetype={ModelFiletype.lumion}
              filenameOrUrl={row.lumion_file_link}
              downloadableFileName={cleanedDownloadableFileName(row.name)}
            />
          </PopupTableItem>
        </DialogContent>
      </Dialog>
      <TableRow
        className={classes.root}
        style={{ background: props.isEven ? "#eeeeee" : "" }}
      >
        <TableCell
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box display="flex">
            <QuantityInput
              value={quantity}
              onUpdate={onUpdateQuantity}
              unit={unit}
            />
            <IconButton
              className={classes.expandBtn}
              onClick={onExpand}
              size="small"
            >
              <OpenInNewIcon fontSize="small" />
            </IconButton>
          </Box>
          {isPaint &&
            React.useMemo(
              () =>
                quantity > 0 && (
                  <FormControl fullWidth size="small">
                    <InputLabel shrink>Location</InputLabel>
                    <Select
                      multiple
                      value={locations}
                      onChange={updateLocations}
                    >
                      {Object.values(PaintLocationConstants).map(
                        (location: PaintLocationConstants) => (
                          <MenuItem key={location} value={location}>
                            {location}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                ),
              [quantity, locations]
            )}
        </TableCell>
        {props.row.map((_row) => _row?.[0])}
      </TableRow>
      <TableRow style={{ background: props.isEven ? "#eeeeee" : "" }}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        ></TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default SurfaceSelectionTableRow;
