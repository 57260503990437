import * as React from "react";
import MediaCard, { MediaCardProps } from "./MediaCard";
import "firebase/compat/database";
import {
  makeStyles,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { handleToggleOverheadRender } from "../util/firebase/functions/handleToggleOverheadRender";

export interface AnnotatorCardProps extends MediaCardProps {
  deleteable?: boolean;
  readonly?: boolean;
  toggleChecked: (id: string) => void;
  showOverheadSelection?: boolean;
  checked: boolean;
}

const useStyles = makeStyles((theme) => ({
  openAnnotatorButtonContainer: {
    padding: "0.5rem",
    display: "flex",
    flexFlow: "row-reverse nowrap",
    alignItems: "center",
    flexGrow: 1,
    height: "100%",
    minHeight: "fit-content",
  },
}));

const AnnotatorCard = ({
  readonly,
  toggleChecked,
  showOverheadSelection,
  checked,
  ...props
}: AnnotatorCardProps) => {
  const [fadeOut] = React.useState<boolean>(false);
  const classes = useStyles();

  return (
    <>
      <div style={{ height: "fit-content" }}>
        <Typography variant="h4" noWrap>
          {props.media.title || props.media.originalFileName}
        </Typography>
        <MediaCard
          {...props}
          style={props.style}
          media={props.media}
          childrenToolBar={<>{renderCheck()}</>}
          toolbarStyles={{
            backgroundColor: "#E0E0E0EF",
            opacity: 0.75,
          }}
          noTitle
          out={fadeOut}
        />
      </div>
    </>
  );

  function renderCheck(): React.ReactNode {
    return (
      <div className={classes.openAnnotatorButtonContainer}>
        <FormControlLabel
          label="Selected for annotation"
          control={
            <Checkbox
              checked={checked}
              onChange={() => toggleChecked(props.media.id)}
            />
          }
        />
        {showOverheadSelection && (
          <FormControlLabel
            label="Overhead image"
            control={
              <Checkbox
                //@ts-ignore
                checked={!!props.media?.isOverhead}
                onChange={() =>
                  handleToggleOverheadRender(
                    props?.media?.id ?? "",
                    props?.media?.revisionId ?? ""
                  )
                }
              />
            }
          />
        )}
      </div>
    );
  }
};

AnnotatorCard.defaultProps = {
  mediaCardProps: {},
};

export default AnnotatorCard;
