import {
  Box,
  Drawer,
  makeStyles,
  Tab,
  Tabs,
  CircularProgress,
} from "@material-ui/core";
import { YZThemeProvider, YZTypography } from "@yardzen-inc/react-common";
import React from "react";
import { Addy } from "../../util/getAddress";
import ElementSelectionTable from "./ElementSelectionTable";
import PlantSelectionTable from "./PlantSelectionTable";
import SelectedItems from "./SelectedItems";
import SurfaceSelectionTable from "./SurfaceSelectionTable";
import BudgetCalculator from "./BudgetCalculator";
import SelectedAssetsStateContext from "../../util/contexts/SelectedAssetsStateContext";
import { SelectedAssetsDispatchConstants } from "../../ConstantValues/SelectedAssetsDispatchConstants";
import {
  IAssetLibraryUpdateQueue,
  IAssignmentMaterialBaseLink,
  IAssignmentPlantBaseLink,
  IAssignmentProductBaseLink,
  ISelectedAssetStateContext,
} from "../../Interfaces";
import { useMutation } from "@apollo/client";
import {
  DELETE_AND_INSERT_ASSIGNMENT_PLANT_BASES,
  DELETE_AND_INSERT_ASSIGNMENT_PRODUCT_BASES,
  DELETE_ASSIGNMENT_MATERIAL_BASES,
  UPSERT_ASSIGNMENT_MATERIAL_BASE_LINK,
} from "@yardzen-inc/graphql";
import { handleMutationError } from "../../EmployeeView/AssetLibraries/elementMutationErrorUtil";
import { AssetLibraryTypeConstants } from "../../ConstantValues/AssetLibraryTypeConstants";
import { UPSERT_ASSIGNMENT_MATERIAL_BASE_PAINT_LINK } from "../../EmployeeView/AssetLibraries/Shared/graphqlQueries";
import { Profile } from "@yardzen-inc/models";

const useStyles = makeStyles({
  tabs: {
    background: "#fafafa",
    borderBottom: "1px solid #f2f2f2",
    minHeight: "0 !important",
    position: "fixed",
    top: 33,
    width: "100%",
    zIndex: 1000,
  },
  tab: {
    minWidth: 0,
    textTransform: "none",
    height: 32,
    minHeight: "0 !important",
    fontSize: 13,
    "&.Mui-selected": {
      fontWeight: 600,
    },
  },
  tabContent: {
    display: "flex",
  },
  drawer: {
    width: 1,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 1,
  },
  pleaseWait: {
    position: "fixed",
    width: "20rem",
    height: "20rem",
    marginTop: "5rem",
  },
  loadingAssetSelection: {
    display: "flex",
    alignItems: "center",
  },
});

interface Props {
  // TODO: Define
  sketchupProducts?: any[];
  projectId: string;
  projectRegion?: {
    id: string;
    name: string;
  };
  loadingProjectRegion?: boolean;
  designAssignmentId: string;
  userId: string;
  address: Addy | null;
  loadingExteriorDesignInfo: boolean;
  exteriorDesignInfo: {
    id: string | undefined;
    isExteriorDesign: boolean;
  };
  usdaZone: string | null;
  loadingUsdaZone: boolean;
  usdaZoneError: string | null;
  usdaZoneId: string | null;
  stateAlreadyFetched: boolean;
  assignmentData: {
    assignment_material_base_links: IAssignmentMaterialBaseLink[];
    assignment_plant_base_links: IAssignmentPlantBaseLink[];
    assignment_product_base_links: IAssignmentProductBaseLink[];
  };
  dispatch: any;
  geoZoneError: string | null;
  loadingGeoZone: boolean;
  geoZones: { id: string; name: string }[] | null;
  profile: Profile;
}

/*
const waitFor = async (time: number) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(null), time);
  });
};
*/

function NonPmAssetSelection(props: Props) {
  const classes = useStyles();

  const [appliedDispatch, setAppliedDispatch] = React.useState(false);
  const [updateQueue, setUpdateQueue] = React.useState<
    IAssetLibraryUpdateQueue[]
  >([]);
  const [inProgressQueueItem, setInProgressQueueItem] = React.useState("");

  React.useEffect(() => {
    if (!props.assignmentData) {
      return;
    }

    if (props.stateAlreadyFetched) {
      setAppliedDispatch(true);
      return;
    }

    props.dispatch({
      type: SelectedAssetsDispatchConstants.setAssignmentLinks,
      val: props.assignmentData,
    });
    setAppliedDispatch(true);
  }, [props.stateAlreadyFetched]);

  const dispatchNewAssignmentData = React.useCallback(() => {
    if (!props.assignmentData) {
      return;
    }

    props.dispatch({
      type: SelectedAssetsDispatchConstants.setAssignmentLinks,
      val: props.assignmentData,
    });
  }, [props.assignmentData]);

  React.useEffect(dispatchNewAssignmentData, [props.designAssignmentId]);

  const [tabValue, setTabValue] = React.useState<string>("Elements");
  const [isProcessingData, setIsProcessingData] = React.useState(false);
  const isLowesProject = props.profile?.lotSize?.includes("Lowes") || false;

  const onChangingData = (v: boolean) => {
    setIsProcessingData(v);
  };

  const [deleteAssignmentPlantBases] = useMutation(
    DELETE_AND_INSERT_ASSIGNMENT_PLANT_BASES,
    {
      onError: handleMutationError,
      onCompleted: () => {
        setUpdateQueue(
          updateQueue.filter((queue) => queue.ackId !== inProgressQueueItem)
        );
        setInProgressQueueItem("");
        onChangingData(false);
      },
    }
  );

  const [deleteAssignmentProductBases] = useMutation(
    DELETE_AND_INSERT_ASSIGNMENT_PRODUCT_BASES,
    {
      onError: handleMutationError,
      onCompleted: () => {
        setUpdateQueue(
          updateQueue.filter((queue) => queue.ackId !== inProgressQueueItem)
        );
        setInProgressQueueItem("");
        onChangingData(false);
      },
    }
  );

  const [deleteAssignmentMaterialBaseLink] = useMutation(
    DELETE_ASSIGNMENT_MATERIAL_BASES,
    {
      onError: handleMutationError,
      onCompleted: () => {
        setUpdateQueue(
          updateQueue.filter((queue) => queue.ackId !== inProgressQueueItem)
        );
        setInProgressQueueItem("");
        onChangingData(false);
      },
    }
  );

  const [upsertAssignmentMaterialBasePaintLink] = useMutation(
    UPSERT_ASSIGNMENT_MATERIAL_BASE_PAINT_LINK,
    {
      onError: handleMutationError,
      onCompleted: () => {
        onChangingData(false);
        setUpdateQueue(
          updateQueue.filter((queue) => queue.ackId !== inProgressQueueItem)
        );
        setInProgressQueueItem("");
      },
    }
  );

  const [upsertAssignmentMaterialBaseLink] = useMutation(
    UPSERT_ASSIGNMENT_MATERIAL_BASE_LINK,
    {
      onError: handleMutationError,
      onCompleted: () => {
        onChangingData(false);
        setUpdateQueue(
          updateQueue.filter((queue) => queue.ackId !== inProgressQueueItem)
        );
        setInProgressQueueItem("");
      },
    }
  );

  const stateContext: ISelectedAssetStateContext = React.useContext(
    SelectedAssetsStateContext
  );

  const processElementItem = (item: IAssetLibraryUpdateQueue) => {
    const _quantity = Math.max(item.quantity, 0);
    const assignmentId = props.designAssignmentId;
    if (!assignmentId) {
      return;
    }
    const objects = [];
    for (let it = 0; it < _quantity; it++) {
      objects.push({
        product_base_id: item.id,
        assignment_id: assignmentId,
      });
    }
    deleteAssignmentProductBases({
      variables: {
        assignment_id: assignmentId,
        product_base_id: item.id,
        objects,
      },
    });
    const updatedLinks = [
      ...stateContext.assignment_product_base_links.filter(
        (link: any) => link.product_base_id !== item.id
      ),
      ...Array(item.quantity).fill({ product_base_id: item.id }),
    ];
    props.dispatch({
      type: SelectedAssetsDispatchConstants.setProductBaseLinks,
      val: updatedLinks,
    });
  };

  const processSurfaceItem = (item: IAssetLibraryUpdateQueue) => {
    const _quantity = Math.max(item.quantity, 0);
    const assignmentId = props.designAssignmentId;

    if (!assignmentId) {
      return;
    }

    if (_quantity < 1) {
      deleteAssignmentMaterialBaseLink({
        variables: {
          assignment_id: assignmentId,
          material_base_id: item.id,
        },
      });
    } else if (item.isPaint) {
      upsertAssignmentMaterialBasePaintLink({
        variables: {
          assignment_id: assignmentId,
          material_base_id: item.id,
          amount: _quantity,
        },
      });
    } else {
      upsertAssignmentMaterialBaseLink({
        variables: {
          assignment_id: assignmentId,
          material_base_id: item.id,
          amount: _quantity,
        },
      });
    }

    const updatedLinks = [
      ...stateContext.assignment_material_base_links.filter(
        (link: any) => link.material_base_id !== item.id
      ),
      {
        amount: item.quantity,
        location: item.location,
        material_base_id: item.id,
      },
    ];
    props.dispatch({
      type: SelectedAssetsDispatchConstants.setMaterialBaseLinks,
      val: updatedLinks,
    });
  };

  const processPlantItem = (item: IAssetLibraryUpdateQueue) => {
    const _quantity = Math.max(item.quantity, 0);
    const assignmentId = props.designAssignmentId;
    if (!assignmentId) {
      return;
    }
    const objects = [];
    for (let it = 0; it < _quantity; it++) {
      objects.push({
        plant_base_id: item.id,
        assignment_id: assignmentId,
      });
    }
    deleteAssignmentPlantBases({
      variables: {
        assignment_id: assignmentId,
        plant_base_id: item.id,
        objects,
      },
    });
    const updatedLinks = [
      ...stateContext.assignment_plant_base_links.filter(
        (link: any) => link.plant_base_id !== item.id
      ),
      ...Array(item.quantity).fill({ plant_base_id: item.id }),
    ];
    props.dispatch({
      type: SelectedAssetsDispatchConstants.setPlantBaseLinks,
      val: updatedLinks,
    });
  };

  const processFirstFromUpdateQueue = async (
    item: IAssetLibraryUpdateQueue
  ) => {
    setInProgressQueueItem(updateQueue[0].ackId);
    onChangingData(true);
    // using timeout to prevent events from getting batch-grouped together
    // automatically by React
    // await waitFor(10000);
    switch (item.type) {
      case AssetLibraryTypeConstants.product: {
        return processElementItem(item);
      }
      case AssetLibraryTypeConstants.plant: {
        return processPlantItem(item);
      }
      case AssetLibraryTypeConstants.surface: {
        return processSurfaceItem(item);
      }
    }
  };

  React.useEffect(() => {
    if (!updateQueue.length) return;
    if (!!inProgressQueueItem) return;
    processFirstFromUpdateQueue(updateQueue[0]);
  }, [updateQueue, inProgressQueueItem]);

  const onUpdateQty = (
    id: string,
    quantity: number,
    ackId: string,
    type: AssetLibraryTypeConstants,
    isPaint?: boolean,
    location?: string
  ) => {
    setUpdateQueue([
      ...updateQueue,
      {
        id,
        quantity,
        ackId,
        type,
        isPaint,
        location,
      },
    ]);
  };

  const TabRender = (_state: any) => {
    switch (tabValue) {
      case "Elements": {
        return (
          <ElementSelectionTable
            onUpdateQuantity={onUpdateQty}
            onChangingQuantity={onChangingData}
            dispatch={props.dispatch}
            links={_state.assignment_product_base_links}
            userId={props.userId}
            projectId={props.projectId}
            projectRegion={props.projectRegion}
            loadingProjectRegion={props.loadingProjectRegion}
            designAssignmentId={props.designAssignmentId}
            isLowesProject={isLowesProject}
          />
        );
      }
      case "Plants": {
        return !props.loadingUsdaZone && !props.loadingGeoZone ? (
          <PlantSelectionTable
            onUpdateQuantity={onUpdateQty}
            onChangingQuantity={onChangingData}
            dispatch={props.dispatch}
            links={_state.assignment_plant_base_links}
            userId={props.userId}
            projectId={props.projectId}
            designAssignmentId={props.designAssignmentId}
            usdaZone={props.usdaZone}
            loadingUsdaZone={props.loadingUsdaZone}
            usdaZoneError={props.usdaZoneError}
            usdaZoneId={props.usdaZoneId}
            geoZoneError={props.geoZoneError}
            loadingGeoZone={props.loadingGeoZone}
            geoZones={props.geoZones}
            assetTableProps={{
              tableHeaderMessage: `Don't forget to record the density of your planted areas in the "Calculators" tab!`,
            }}
            isLowesProject={isLowesProject}
          />
        ) : (
          <YZTypography>Loading...</YZTypography>
        );
      }
      case "Materials": {
        if (props.loadingExteriorDesignInfo)
          return <YZTypography>Loading...</YZTypography>;
        return (
          <SurfaceSelectionTable
            onUpdateQuantity={onUpdateQty}
            exteriorDesignInfo={props.exteriorDesignInfo}
            onChangingData={onChangingData}
            dispatch={props.dispatch}
            links={_state.assignment_material_base_links}
            userId={props.userId}
            projectId={props.projectId}
            projectRegion={props.projectRegion}
            loadingProjectRegion={props.loadingProjectRegion}
            designAssignmentId={props.designAssignmentId}
            isLowesProject={isLowesProject}
          />
        );
      }
      case "Calculators": {
        return (
          <div style={{ width: "100%", padding: "1rem" }}>
            <Box style={{ paddingTop: "5rem" }} p={3} display="flex">
              <YZTypography variant="h3" type="serif" style={{ flex: 1 }}>
                Calculator
              </YZTypography>
            </Box>
            <div>
              <BudgetCalculator
                enableQuantityChange
                onChangingQuantity={onChangingData}
                isProcessingData={isProcessingData}
                designAssignmentId={props.designAssignmentId}
              />
            </div>
          </div>
        );
      }
      case "Budget QA": {
        return (
          <SelectedItems
            profile={props.profile}
            nonPm
            enableQuantityChange
            onChangingData={onChangingData}
            address={props.address}
            isProcessingData={isProcessingData}
            designAssignmentId={props.designAssignmentId}
            projectId={props.projectId}
            assignment_product_base_links={
              stateContext.assignment_product_base_links
            }
            assignment_material_base_links={
              stateContext.assignment_material_base_links
            }
            assignment_plant_base_links={
              stateContext.assignment_plant_base_links
            }
            dispatch={props.dispatch}
            projectRegion={props.address?.state}
          />
        );
      }
    }
    return <div>Nothing found here.</div>;
  };

  return (
    <YZThemeProvider>
      <Tabs
        value={tabValue}
        onChange={(_, value) => {
          setTabValue(value);
        }}
        className={classes.tabs}
        indicatorColor="primary"
      >
        <Tab label="Elements" value="Elements" className={classes.tab} />
        <Tab label="Materials" value="Materials" className={classes.tab} />
        <Tab label="Plants" value="Plants" className={classes.tab} />
        <Tab label="Calculators" value="Calculators" className={classes.tab} />
        {!updateQueue.length && (
          <Tab label="Budget QA" value="Budget QA" className={classes.tab} />
        )}
        {updateQueue.length > 0 && (
          <div className={classes.loadingAssetSelection}>
            <CircularProgress size={20} />
            <YZTypography variant="caption">
              &nbsp;Loading Budget QA...
            </YZTypography>
          </div>
        )}
      </Tabs>
      <div className={classes.tabContent}>
        <Drawer
          onClose={() => {}}
          className={classes.drawer}
          open={false}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        />
        {appliedDispatch && (
          <SelectedAssetsStateContext.Consumer>
            {(_state) => TabRender(_state)}
          </SelectedAssetsStateContext.Consumer>
        )}
      </div>
    </YZThemeProvider>
  );
}

export default NonPmAssetSelection;
