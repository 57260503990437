import * as React from "react";
import { Box, LinearProgress, Typography, Fade } from "@material-ui/core";
import { ProjectManager, Media } from "@yardzen-inc/models";
import { PmListItem } from "./PmListItem";
import GenericConfirm from "../../Components/GenericConfirm";
import firebase from "firebase/compat/app";
import environmentConstants from "../../ConstantValues/environmentConstants";

export interface PmListProps {
  pms: ProjectManager[] | null;
  user: firebase.User | null;
}

export const PmList = (props: PmListProps) => {
  const [pmToDelete, setPmToDelete] = React.useState<ProjectManager | null>(
    null
  );
  const [deleting, setDeleting] = React.useState<boolean>(false);

  return (
    <>
      <Box display="flex" flexDirection="column" flexWrap="nowrap">
        {renderList()}
      </Box>
      {renderDeleteModal()}
    </>
  );

  function renderDeleteModal(): React.ReactNode {
    return (
      <GenericConfirm
        open={!!pmToDelete}
        onClose={() => setPmToDelete(null)}
        body={
          "Are you sure that you would like to permanently delete this project manager?"
        }
        onSubmit={deleteProjectManager}
        submitting={deleting}
      />
    );
  }

  async function deleteProjectManager(): Promise<void> {
    if (!pmToDelete) {
      throw new Error("deleteProjectManager called with now pm selected :(");
    }

    setDeleting(true);

    try {
      const projectSnap = await firebase
        .firestore()
        .collection("profiles")
        .where("projectManagerId", "==", pmToDelete.id)
        .get();

      if (!projectSnap.empty) {
        await Promise.all(
          projectSnap.docs.map(async (d) => {
            return d.ref.update({ projectManagerId: null });
          })
        );
      }

      if (pmToDelete.pictureMediaId) {
        const media = await Media.fetchSingle(pmToDelete.pictureMediaId);

        if (media) {
          await media.getDocRef().delete();
        }
      }

      await pmToDelete.docRef.delete();
    } catch (err) {
      if (process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT) {
        console.error(err);
      }

      alert("Project manager creation encountered an unexpected error");
    } finally {
      setDeleting(false);
      setPmToDelete(null);
    }
  }

  async function setAsNotMe(pm: ProjectManager): Promise<any> {
    return pm.docRef.update({ userId: null });
  }

  async function setAsMe(pm: ProjectManager): Promise<any> {
    if (!props.user) {
      throw new Error("could not find user for setAsMe");
    }

    let currentlyIsMe: firebase.firestore.QuerySnapshot | null;

    if (props?.user?.uid) {
      currentlyIsMe = await firebase
        .firestore()
        .collection("projectManagers")
        .where("userId", "==", props?.user?.uid)
        .get();

      if (currentlyIsMe.empty) {
        currentlyIsMe = null;
      }
    } else {
      currentlyIsMe = null;
    }

    console.log("cim", currentlyIsMe?.docs);

    await firebase.firestore().runTransaction(async (tx) => {
      const pmDoc = await tx.get(pm.docRef);

      if (currentlyIsMe) {
        await Promise.all(
          currentlyIsMe.docs.map(async (d) => {
            tx.update(d.ref, { userId: null });
          })
        );
      }

      await tx.update(pmDoc.ref, { userId: (props.user as firebase.User).uid });
    });
  }

  function renderList(): React.ReactNode {
    if (props.pms === null) {
      return <LinearProgress variant="indeterminate" />;
    }

    if (!props?.pms.length) {
      return (
        <Typography variant="h3">
          No Project managers have been registered
        </Typography>
      );
    }

    return props.pms.map((pm) => {
      let conditionalProps: any;

      if (props?.user?.uid === pm.userId) {
        conditionalProps = {
          setAsNotMe: () => setAsNotMe(pm),
        };
      } else if (props?.user?.uid) {
        conditionalProps = {
          setAsMe: () => setAsMe(pm),
        };
      } else {
        conditionalProps = {};
      }

      return (
        <Fade key={`pm-${pm.id}`} in={true}>
          <PmListItem
            pm={pm}
            isUser={pm.id === props.user?.uid}
            onDelete={() => setPmToDelete(pm)}
            {...conditionalProps}
          />
        </Fade>
      );
    });
  }
};
