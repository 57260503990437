import {
  Media,
  Profile,
  Revision,
  RevisionProperties,
} from "@yardzen-inc/models";
import {
  getAgentByUserId,
  getProjectManagerByUserId,
  sendRevisionToClient,
} from "../util/firebase/firebaseClient";
import firebase from "firebase/compat/app";
import { DeliverableRevisionIdentifier } from "../ClientDetail/Revisions/MessageModal";
import sendDesignDeliveryNotification from "../util/sendDesignDeliveryNotification";
import sendUpdatedRevisionNotification from "../util/sendUpdatedRevisionNotification";
import { GenericOnCallFunctionAlert } from "../util/genericAlert";
import genericLiisaNotification from "../util/genericLiisaNotification";
import makeLiisaLink from "../util/makeLiisaLink";

export async function sendToClientRpc(
  user: firebase.User | null | false,
  revision: Revision,
  media: Media[],
  deliverableType: DeliverableRevisionIdentifier,
  allowAnnotations: boolean,
  blurb?: string
): Promise<{ success: boolean; message: string }> {
  const blurbObj =
    deliverableType !== "conceptual"
      ? {
          finalBlurb: blurb,
        }
      : { v1Blurb: blurb };

  if (await sendRevisionToClient(revision, blurbObj)) {
    if (user) {
      let sentToClientById: string | null = null;
      const snap = await getProjectManagerByUserId(user.uid);
      sentToClientById = snap.docs[0]?.id || null;
      if (sentToClientById) {
        revision.sentToClientBy = sentToClientById;
        await revision.save();
      } else {
        // If no PM is found, check if designer submitted the revision
        const snap = await getAgentByUserId(user.uid);
        revision.sentToClientBy = snap.docs[0]?.id || null;
        await revision.save();
      }
    }
  } else {
    return {
      success: false,
      message:
        "ERROR SENDING REVISION. Please try again. If error persists, contact engineering",
    };
  }

  const revisionUpdateProperty = `${deliverableType}Revision` as keyof Pick<
    Profile,
    "conceptualRevision" | "finalRevision" | "v3Revision"
  >;

  try {
    await firebase.firestore().runTransaction(async (tx) => {
      const txprofile = await tx.get(
        firebase.firestore().collection("profiles").doc(revision.profileId)
      );

      const txrevision = await tx.get(
        firebase.firestore().collection("revisions").doc(revision.id)
      );

      try {
        await tx.update(
          txprofile.ref,
          deliverableType !== "conceptual"
            ? {
                [revisionUpdateProperty]: revision.id,
              }
            : {
                conceptualRevision: revision.id,
                v1Annotated: !allowAnnotations,
              }
        );
      } catch (error) {
        return {
          success: false,
          message:
            "ERROR SENDING REVISION. Please try again. If error persists, contact engineering",
        };
      }

      try {
        await tx.update(txrevision.ref, {
          allowAnnotation: allowAnnotations,
          deliverables: media.map((m) => m.id), // Sets sorted media as deliverables.
        } as Partial<RevisionProperties>);
      } catch (error) {
        return {
          success: false,
          message:
            "ERROR SENDING REVISION. Please try again. If error persists, contact engineering",
        };
      }
    });
  } catch (error) {
    console.error(error);
    throw error;
  }

  return { success: true, message: "" };
}

export async function sendToClientNotifications(
  revisionId: string,
  clientRecord: Profile,
  deliverableType: string,
  sendToDesigns: boolean,
  notifyClient: boolean,
  user: firebase.User | null | false
): Promise<{ success: boolean; message: string }> {
  // Notifications
  if (sendToDesigns) {
    try {
      await sendDesignDeliveryNotification({
        revisionId,
        clientId: clientRecord.id,
      });
    } catch (error) {
      console.error(error);
      console.error("FAILED TO SEND CLIENT BUILD INTRO");
      return {
        success: false,
        message:
          "FAILED TO SEND CLIENT BUILD INTRO, please send email mainually and contact engineering",
      };
    }
  }

  if (!notifyClient) {
    const userDisplay =
      (user as firebase.User)["displayName"] ??
      (user as firebase.User)["email"];
    await genericLiisaNotification({
      message: `Client ${clientRecord.firstName} ${
        clientRecord.lastName
      } has been sent deliverables for revision ${revisionId} - ${deliverableType} by ${userDisplay}, but was *not notified via email.* 
      ${makeLiisaLink(clientRecord.id)}/deliverables`,
      icon: ":no_entry_sign:",
    });
    return { success: true, message: "" };
  }

  const conceptualRevisionId = clientRecord.conceptualRevision;
  const finalRevisionId = clientRecord.finalRevision;
  const v3RevisionId = clientRecord.v3Revision;

  if (
    conceptualRevisionId === revisionId ||
    finalRevisionId === revisionId ||
    v3RevisionId === revisionId
  ) {
    try {
      await sendUpdatedRevisionNotification(clientRecord.id);
      return { success: true, message: "" };
    } catch (error) {
      GenericOnCallFunctionAlert(
        "pingClientForUpdatedRevision",
        // @ts-ignore
        error.message
      );
      return { success: false, message: JSON.stringify(error) };
    }
  }

  if (deliverableType === "conceptual") {
    const sendConceptual = firebase
      .functions()
      .httpsCallable("sendConceptualRevisionEmail");

    try {
      await sendConceptual(clientRecord);
    } catch (error) {
      GenericOnCallFunctionAlert(
        "sendConceptualRevisionEmail",
        (error as any).message
      );
    }
  } else {
    const sendFinal = firebase
      .functions()
      .httpsCallable("sendFinalRevisionEmail");

    try {
      await sendFinal(clientRecord);
    } catch (error) {
      GenericOnCallFunctionAlert(
        "sendFinalRevisionEmail",
        (error as any).message
      );
    }
  }

  return { success: true, message: "" };
}
