import { Box, Chip, makeStyles } from "@material-ui/core";
import {
  HasuraQueryFunctions,
  HasuraQueryHumanReadableOperatorEnum,
} from "@yardzen-inc/util";
import { Theme } from "@material-ui/core/styles";
import {
  MATERIAL_BASE_AGGREGATE,
  UPDATE_ASSIGNMENT_MATERIAL_BASE_LINK_LOCATION,
  NetworkStatus,
} from "@yardzen-inc/graphql";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  IAssetLibraryUpdateQueue,
  IMaterialBaseMaterialColorLink,
  IMaterialBaseMaterialMaterialLink,
  IMaterialBaseMaterialStyleLink,
  IMaterialBaseMaterialTypeLink,
  IMaterialBasePaverApplicationLink,
  ModelFiletype,
} from "../../Interfaces";
import { formatNumToUSD, YZTypography } from "@yardzen-inc/react-common";
import { arrayMove } from "react-sortable-hoc";
import AssetTable from "../../EmployeeView/AssetLibraries/Shared/AssetTable";
import SurfaceSelectionTableRow from "./SurfaceSelectionTableRow";
import TableCellWithStyles from "../../EmployeeView/AssetLibraries/Shared/AssetTableCellWithStyles";
import SurfaceLibraryFilterBoxOptions from "../../EmployeeView/AssetLibraries/SurfaceLibrary/SurfaceLibraryFilterBoxOptions";
import SelectionTableBox from "./Shared/SelectionTableBox";
import { GraphqlConstants } from "../../ConstantValues/graphqlConstants";
import getAssetLibraryOrderBy from "../../EmployeeView/AssetLibraries/getAssetLibraryOrderBy";
import usePreloadedAssetTableFilters from "../../util/hooks/usePreloadedAssetTableFilters";
import AssetLibraryFilterMenu from "../../EmployeeView/AssetLibraries/Shared/AssetLibraryFilterMenu";
import { AssetLibraryFetchMoreStatus } from "../../ConstantValues/AssetLibraryFetchMoreStatusConstants";
import { SelectedAssetsDispatchConstants } from "../../ConstantValues/SelectedAssetsDispatchConstants";
import uuid from "uuid";
import { DownloadUtil } from "../../util/downloadUtil";
import cleanedDownloadableFileName from "../../EmployeeView/AssetLibraries/Shared/CleanedDownloadableFileName";
import GetAppIcon from "@material-ui/icons/GetApp";
import Switch from "@material-ui/core/Switch";
import getObjectAndArrayRelationships from "../../EmployeeView/AssetLibraries/Shared/getObjectAndArrayRelationships";
import {
  surfaceArrayLinksMap,
  surfaceObjLinksMap,
} from "../../EmployeeView/AssetLibraries/Shared/FilterLinkMaps";
import { AssetLibraryTypeConstants } from "../../ConstantValues/AssetLibraryTypeConstants";
import { ModelFileDownloadButton } from "../ModelFileDownloadButton";
import { applyAndNullFiltering } from "./applyAndNullFiltering";
import { MATERIAL_BASE } from "../../EmployeeView/AssetLibraries/Shared/graphqlQueries";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useGetLowesRetailer } from "../../util/hooks/useGetLowesRetailer";

let saveTimeout: ReturnType<typeof setTimeout> | null = null;

const listResultName = "material_base";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  longLink: {
    width: "10rem",
    maxWidth: "10rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  iconUri: {
    width: "6.25rem",
    height: "6.25rem",
    objectFit: "contain",
  },
  assetFilters: {
    display: "flex",
    alignItems: "center",
  },
  errorMessage: {
    position: "relative",
    top: 75,
    left: 10,
  },
  exteriorDesignSwitch: {
    display: "flex",
    alignItems: "center",
  },
  chipFilter: {
    fontSize: 10,
    letterSpacing: 0.5,
    marginRight: "0.1rem",
  },
}));

interface Props {
  designAssignmentId: string;
  userId: string;
  onChangingData: (v: boolean) => void;
  projectId: string;
  projectRegion?: {
    id: string;
    name: string;
  };
  loadingProjectRegion?: boolean;
  links: {
    material_base_id: string;
    amount: number;
  }[];
  dispatch: any;
  exteriorDesignInfo: {
    id: string | undefined;
    isExteriorDesign: boolean;
  };
  onUpdateQuantity: (
    id: string,
    quantity: number,
    ackId: string,
    type: AssetLibraryTypeConstants,
    isPaint?: boolean,
    location?: string
  ) => void;
  isLowesProject: boolean;
}

function SurfaceSelectionTable(props: Props) {
  const classes = useStyles();

  const [
    neqFilterState,
    setNeqFilterState,
    hasAnyOfFilterState,
    setHasAnyOfFilterState,
    hasAllOfFilters,
    setHasAllOfFilters,
    search,
    setSearch,
  ] = usePreloadedAssetTableFilters("materials", props.designAssignmentId);

  const [filterByProjectRegion, setFilterByProjectRegion] = useState(
    !!props.projectRegion && props.loadingProjectRegion === false
  );
  const [filterByRetailer, setFilterByRetailer] = useState<Boolean>(false);
  const [showArchived, setShowArchived] = useState(false);
  const [showExteriorDesign, setShowExteriorDesign] = useState(
    props.exteriorDesignInfo.isExteriorDesign
  );

  const onSearch = (term: string) => {
    setSearch(term);
  };

  const handleMutationError = ({ message }: { message: string }) => {
    alert(
      `Something went wrong. Please contact support. Error message: ${message}`
    );
  };

  const [queryBy, setQueryBy] = useState({
    field: "updated_at",
    direction: "desc",
  });

  const [getBases, { data, refetch, error, fetchMore, networkStatus }] =
    useLazyQuery(MATERIAL_BASE, {
      notifyOnNetworkStatusChange: true,
    });

  const [selectedRowsIdMap, setSelectedRowsIdMap] = useState({});
  const [loadingRetailer, lowesRetailer] =
    useGetLowesRetailer("material_retailer");

  useEffect(() => {
    if (props.isLowesProject) {
      if (!loadingRetailer && typeof lowesRetailer === "object") {
        setFilterByRetailer(true);
      }
    }
  }, [props.isLowesProject, lowesRetailer, loadingRetailer]);

  useEffect(() => {
    const selectedRows = props.links;
    if (!selectedRows) return;

    const selectedRowsIdHash = selectedRows.reduce(
      (hash: { [id: string]: {} }, row: { material_base_id: string }) => ({
        ...hash,
        [row.material_base_id]: row,
      }),
      {}
    );

    setSelectedRowsIdMap(selectedRowsIdHash);
  }, [props.links]);

  const [getCount, { data: dataCount }] = useLazyQuery(
    MATERIAL_BASE_AGGREGATE,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [updateAssignmentMaterialBaseLinkLocation] = useMutation(
    UPDATE_ASSIGNMENT_MATERIAL_BASE_LINK_LOCATION,
    {
      onError: handleMutationError,
      onCompleted: () => {
        props.onChangingData(false);
        setUpdateQueue(
          updateQueue.filter((queue) => queue.ackId !== inProgressQueueItem)
        );
        setInProgressQueueItem("");
      },
    }
  );

  useEffect(() => {
    if (!refetch) {
      return;
    }
    saveTimeout = setTimeout(async () => {
      refetch(getVariables());
      getCount({
        variables: getVariables(),
      });
    }, 300);

    return () => {
      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }
    };
  }, [
    refetch,
    search,
    neqFilterState,
    hasAnyOfFilterState,
    hasAllOfFilters,
    filterByProjectRegion,
    filterByRetailer,
    showExteriorDesign,
  ]);

  useEffect(() => {
    getBases({
      variables: getVariables(),
    });
    getCount({
      variables: getVariables(),
    });
  }, [getBases]);

  const applyExteriorDesignCondition = (condition: object) => {
    if (!props.exteriorDesignInfo?.id) {
      return;
    }

    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: showExteriorDesign
        ? HasuraQueryHumanReadableOperatorEnum.hasAllOf
        : HasuraQueryHumanReadableOperatorEnum.hasNoneOf,
      fields: [
        {
          data: [props.exteriorDesignInfo.id],
          links: "material_base_material_type_links",
          fieldName: "id_material_type",
        },
      ],
    });
  };

  const applyRetailerCondition = (condition: object) => {
    if (!filterByRetailer) {
      return;
    }

    if (typeof lowesRetailer === "object") {
      HasuraQueryFunctions.processAndConditionsNoLinks({
        condition,
        operator: HasuraQueryHumanReadableOperatorEnum.hasAnyOf,
        fields: [
          {
            data: [lowesRetailer.id],
            fieldName: "material_retailer",
          },
        ],
      });
    }
  };

  const applyRegionCondition = (condition: object) => {
    if (
      !filterByProjectRegion ||
      !props.projectRegion ||
      props.loadingProjectRegion !== false
    ) {
      return;
    }

    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAllOf,
      fields: [
        {
          data: [props.projectRegion.id],
          links: "material_base_region_links",
          fieldName: "id_product_region",
        },
      ],
    });
  };

  const getVariables = (useOffset?: boolean, forceShowArchived?: boolean) => {
    const condition: any = {};
    if (search) {
      condition.name = {
        _ilike: `%${search}%`,
      };
    }
    if (typeof forceShowArchived === "boolean") {
      condition.archived = {
        _eq: forceShowArchived,
      };
    } else {
      condition.archived = {
        _eq: showArchived,
      };
    }
    applyExteriorDesignCondition(condition);
    applyRegionCondition(condition);
    applyRetailerCondition(condition);

    const [hasAllOfObjectRelationships, hasAllOfArrayRelationships] =
      getObjectAndArrayRelationships(
        hasAllOfFilters,
        surfaceArrayLinksMap,
        surfaceObjLinksMap
      );

    HasuraQueryFunctions.processAndConditionsNoLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAllOf,
      fields: hasAllOfObjectRelationships,
    });
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAllOf,
      fields: hasAllOfArrayRelationships,
    });
    const [hasAnyOfObjectRelationships, hasAnyOfArrayRelationships] =
      getObjectAndArrayRelationships(
        hasAnyOfFilterState,
        surfaceArrayLinksMap,
        surfaceObjLinksMap
      );
    HasuraQueryFunctions.processAndConditionsNoLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAnyOf,
      fields: hasAnyOfObjectRelationships,
    });
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAnyOf,
      fields: hasAnyOfArrayRelationships,
    });
    const [hasNoneOfObjectRelationships, hasNoneOfArrayRelationships] =
      getObjectAndArrayRelationships(
        neqFilterState,
        surfaceArrayLinksMap,
        surfaceObjLinksMap
      );
    HasuraQueryFunctions.processAndConditionsNoLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasNoneOf,
      fields: hasNoneOfObjectRelationships,
    });
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasNoneOf,
      fields: hasNoneOfArrayRelationships,
    });
    // Designers should only see released assets, ie. visibility === "ALL"
    // Using _ilike to make match case insensitive
    condition.visibility = {
      _ilike: "ALL",
    };

    applyAndNullFiltering(condition, "material_base_region_links");

    return {
      condition,
      offset: useOffset ? data[listResultName].length : 0,
      orderBy: getAssetLibraryOrderBy(queryBy.field, queryBy.direction),
    };
  };

  const listenForReachingBottomOfPage = () => {
    const target = document.getElementsByClassName("swipeable")[0];
    if (!target) {
      return;
    }
    // @ts-ignore
    target.onscroll = (ev: any) => {
      // @ts-ignore target.offsetHeight does exist
      let pos = target.scrollTop + target.offsetHeight;
      let max = target.scrollHeight;
      // fetching before user hits the bottom of the screen so by
      // the time the user gets to the bottom, stuff will have loaded.
      if (
        pos >= max - 700 &&
        data.material_base.length % GraphqlConstants.resultsPerQuery === 0 &&
        data.material_base.length !== 0 &&
        data.material_base.length !==
          dataCount.material_base_aggregate.aggregate.count
      ) {
        requestMoreResults();
      }
    };
  };

  useEffect(() => {
    // Even though we're calling this listener function
    // each time one of the dependencies gets called,
    // the previous listeners will no longer be called
    // so there's no issue from a performance perspective.
    // The advantage of setting up a new listener is that
    // the "data" object gets updated so the listener can use the proper offset.
    listenForReachingBottomOfPage();
  }, [fetchMore, data, networkStatus]);

  const toggleArchived = () => {
    if (!refetch) {
      return alert(
        "Something went wrong. Please reload the screen and try again."
      );
    }
    const newArchived = !showArchived;
    refetch({
      ...getVariables(undefined, newArchived),
    });

    setShowArchived(newArchived);
  };

  const requestRefetch = () => {
    if (!refetch) {
      return;
    }
    return refetch({
      ...getVariables(),
    });
  };

  const requestMoreResults = () => {
    setFetchMoreStatus(AssetLibraryFetchMoreStatus.shouldFetch);
  };

  const fetchMoreResults = async () => {
    if (!fetchMore) {
      return alert(
        "Something went wrong. Please reload the screen and try again."
      );
    }
    fetchMore({
      variables: getVariables(true),
      updateQuery: (prev: any, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const iterableMaterialBase = Array.isArray(prev[listResultName])
          ? prev[listResultName]
          : [];
        const newArray = Array.from(
          new Set([...iterableMaterialBase, ...fetchMoreResult[listResultName]])
        );
        // it's possible that the apollo caching mechanism may
        // provide the fetchMoreResult from the previous iteration
        // if the user is moving quickly, so let's adjust for that
        // and ensure we're not duplicating any data
        let uniqueArray: any[] = [];
        for (let it = 0; it < newArray.length; it++) {
          if (uniqueArray.some((line) => line.id === newArray[it].id)) {
            continue;
          }
          uniqueArray.push(newArray[it]);
        }
        const resultToSend = Object.assign({}, prev, {
          [listResultName]: uniqueArray,
        });
        return resultToSend;
      },
    });
  };

  const inStockText = (row: any) => {
    if (typeof row.in_stock === "boolean") {
      return row.in_stock ? "Yes" : "No";
    }
    return "--";
  };

  const [fetchMoreStatus, setFetchMoreStatus] =
    useState<AssetLibraryFetchMoreStatus>(AssetLibraryFetchMoreStatus.standby);

  // if the user has scrolled to the bottom
  // but the network status is not in "ready" state,
  // we want this useEffect to listten for the network status to be ready
  // and as soon as ready, fetch more results.
  useEffect(() => {
    if (networkStatus !== NetworkStatus.ready) return;
    if (fetchMoreStatus !== AssetLibraryFetchMoreStatus.shouldFetch) return;
    fetchMoreResults();
    setFetchMoreStatus(AssetLibraryFetchMoreStatus.fetching);
  }, [fetchMoreStatus, networkStatus]);

  useEffect(() => {
    if (data === undefined) return;
    setFetchMoreStatus(AssetLibraryFetchMoreStatus.standby);
  }, [data]);

  const onSort = (v: number, queryName: string): any => {
    if (!refetch) {
      return;
    }
    let stateObj = {};
    if (v === 0) {
      window.history.replaceState(stateObj, "", `/materials-library?`);
      return refetch({ ...getVariables() });
    }
    const direction = v === -1 ? "desc" : "asc";
    if (window.history.replaceState) {
      //prevents browser from storing history with each change:
      window.history.replaceState(
        stateObj,
        "",
        `/materials-library?sortColumn=${queryName}&sortDirection=${direction}`
      );
    }
    setQueryBy({ field: queryName, direction });
    refetch({
      ...getVariables(),
      orderBy: getAssetLibraryOrderBy(queryName, direction),
    });
  };

  // TIPS: for fields, "id" should just be the field name
  // for links, "id" should be "<LINK_NAME>_links"
  // and for join relations, "id" should be "<RELATION_NAME>_simplejoin"

  const initialColumns = [
    {
      name: "Name",
      id: "name",
      renderCellContent: (row: any) => row.name,
    },
    {
      name: "Image",
      id: "icon_uri",
      renderCellContent: (row: any) => (
        <img className={classes.iconUri} src={row.icon_uri} />
      ),
    },
    {
      name: "Material",
      id: "material_base_material_material_links",
      renderCellContent: (row: any) =>
        row.material_base_material_material_links
          ?.map(
            (style: IMaterialBaseMaterialMaterialLink) =>
              style.material_material.name
          )
          .join(", "),
    },
    {
      name: "Type",
      id: "material_base_material_type_links",
      renderCellContent: (row: any) =>
        row.material_base_material_type_links
          ?.map(
            (type: IMaterialBaseMaterialTypeLink) => type.material_type.name
          )
          .join(", "),
    },
    {
      name: "Application",
      id: "material_base_paver_application_links",
      renderCellContent: (row: any) =>
        row.material_base_paver_application_links
          ?.map(
            (paver: IMaterialBasePaverApplicationLink) =>
              paver.paver_application?.name
          )
          .join(", "),
    },
    {
      name: "Setting",
      id: "settingByMaterialSetting_simplejoin",
      sortable: true,
      queryName: "settingByMaterialSetting.name",
      renderCellContent: (row: any) => row.settingByMaterialSetting?.name,
    },
    {
      name: "Size",
      sortable: true,
      queryName: "material_size.name",
      id: "umaterial_size_simplejoin",
      renderCellContent: (row: any) => row.material_size?.name,
    },
    {
      name: "Price Tier",
      sortable: true,
      queryName: "priceTierByMaterialPriceTier.name",
      id: "priceTierByMaterialPriceTier_simplejoin",
      renderCellContent: (row: any) => row.priceTierByMaterialPriceTier?.name,
    },
    {
      name: "Unit",
      sortable: true,
      queryName: "unitByUnit.name",
      id: "unitByUnit_simplejoin",
      renderCellContent: (row: any) => row.unitByUnit.name,
    },
    {
      name: "Unit Cost",
      id: "unit_cost",
      renderCellContent: (row: any) =>
        formatNumToUSD(parseFloat(row.unit_cost)),
    },
    {
      name: "Labor Cost",
      id: "labor_cost",
      renderCellContent: (row: any) =>
        formatNumToUSD(parseFloat(row.labor_cost || 0)),
    },
    {
      name: "Installed Cost",
      id: "installed_cost",
      renderCellContent: (row: any) =>
        formatNumToUSD(
          parseFloat(row.labor_cost || 0) + parseFloat(row.unit_cost || 0)
        ),
    },
    {
      name: "Style",
      id: "material_base_material_style_links",
      renderCellContent: (row: any) =>
        row.material_base_material_style_links
          ?.map(
            (style: IMaterialBaseMaterialStyleLink) => style.material_style.name
          )
          .join(", "),
    },
    {
      name: "Finish",
      sortable: true,
      queryName: "material_finish.name",
      id: "material_finish_simplejoin",
      renderCellContent: (row: any) => row.material_finish?.name,
    },
    {
      name: "Color Family",
      id: "material_base_material_color_links",
      renderCellContent: (row: any) =>
        row.material_base_material_color_links
          ?.map(
            (color: IMaterialBaseMaterialColorLink) =>
              color.material_color?.name
          )
          .join(", "),
    },
    {
      name: "Pattern",
      sortable: true,
      queryName: "material_pattern.name",
      id: "material_pattern_simplejoin",
      renderCellContent: (row: any) => row.material_pattern?.name,
    },
    {
      name: "Link",
      id: "link",
      renderCellContent: (row: any) => (
        <div className={classes.longLink}>{row.link}</div>
      ),
    },
    {
      name: "Vendor",
      id: "vendorByMaterialVendor_simplejoin",
      sortable: true,
      queryName: "vendorByMaterialVendor.name",
      renderCellContent: (row: any) => row.vendorByMaterialVendor?.name,
    },
    {
      name: "Retailer",
      id: "retailerByMaterialRetailer_simplejoin",
      sortable: true,
      queryName: "retailerByMaterialRetailer.name",
      renderCellContent: (row: any) => row.retailerByMaterialRetailer?.name,
    },
    {
      name: "Collection Name",
      id: "collectionNameByMaterialCollectionName_simplejoin",
      sortable: true,
      queryName: "collectionNameByMaterialCollectionName.name",
      renderCellContent: (row: any) =>
        row.collectionNameByMaterialCollectionName?.name,
    },
    {
      name: "In Stock",
      id: "in_stock",
      renderCellContent: (row: any) => (
        <div
          style={{
            color: row.in_stock === false ? "red" : "",
          }}
        >
          {inStockText(row)}
        </div>
      ),
    },
    {
      name: "Sketchup File",
      id: "sketchup_file_link",
      renderCellContent: (row: any) => (
        <ModelFileDownloadButton
          filetype={ModelFiletype.sketchup}
          filenameOrUrl={row.sketchup_file_link}
          downloadableFileName={cleanedDownloadableFileName(row.name)}
        />
      ),
    },
    {
      name: "MAX File",
      id: "max_file_link",
      renderCellContent: (row: any) =>
        row.max_file_link && (
          <Box
            display="inline-block"
            component="a"
            style={{ cursor: "pointer" }}
            onClick={async () => {
              try {
                await DownloadUtil.downloadWithFirebaseCredentials(
                  row.max_file_link,
                  undefined,
                  cleanedDownloadableFileName(row.name)
                );
              } catch (error) {
                console.error(error);
                alert(
                  `Failed to download ${row.name}, please try again or contact support`
                );
              }
            }}
          >
            <GetAppIcon />
          </Box>
        ),
    },
    {
      name: "Lumion File",
      id: "lumion_file_link",
      renderCellContent: (row: any) => (
        <ModelFileDownloadButton
          filetype={ModelFiletype.lumion}
          filenameOrUrl={row.lumion_file_link}
          downloadableFileName={cleanedDownloadableFileName(row.name)}
        />
      ),
    },
    {
      name: "Lumion File Name",
      id: "lumion_file_name",
      renderCellContent: (row: any) => row.lumion_file_name,
    },
  ];

  const [columns, setColumns] = useState(initialColumns);

  const InitialTableCells = (row: any) => {
    const cells = columns.map((column) => {
      let innerContent = column.renderCellContent(row);

      return [
        <TableCellWithStyles
          id={column.id}
          key={`${row.id}-${column.id}`}
          align="right"
          // @ts-ignore
          hidden={hiddenColumns?.[column.id]}
        >
          {innerContent}
        </TableCellWithStyles>,
      ];
    });

    cells[0].push(row);
    return cells;
  };

  const [hiddenColumns, setHiddenColumns] = useState({});

  const [updateQueue, setUpdateQueue] = useState<IAssetLibraryUpdateQueue[]>(
    []
  );
  const [inProgressQueueItem, setInProgressQueueItem] = useState("");

  const onReorder = (oldIndex: number, newIndex: number) => {
    setColumns(arrayMove(columns, oldIndex, newIndex));
  };

  const handleToggleColumnVisible = (id: string) => {
    const updatedHiddenColumns = { ...hiddenColumns };
    // @ts-ignore
    updatedHiddenColumns[id] = !updatedHiddenColumns[id];
    setHiddenColumns(updatedHiddenColumns);
  };

  const getIdAndQuantityMap = () => {
    const assignment = props.links;
    if (assignment?.length) {
      const idMap = {};

      assignment.forEach((link: any) => {
        // @ts-ignore
        idMap[link.material_base_id] = link.amount;
      });
      return idMap;
    } else {
      return {};
    }
  };

  const [idAndQuantityMap] = useState<null | {
    [v: string]: number;
  }>(getIdAndQuantityMap());

  const onUpdateQuantity = (
    id: string,
    quantity: number,
    isPaint: boolean,
    location: string
  ) => {
    props.onUpdateQuantity(
      id,
      quantity,
      uuid.v4(),
      AssetLibraryTypeConstants.surface,
      isPaint,
      location
    );
  };

  const onUpdatePaintLocation = useCallback(
    (materialBaseId: string, locations: string[], quantity: number) => {
      props.onChangingData(true);

      const assignmentId = props.designAssignmentId;

      if (!assignmentId) {
        return;
      }
      // @ts-ignore
      const selectedRow = selectedRowsIdMap[materialBaseId];

      const _selectedRowsIdMap = {
        ...selectedRowsIdMap,
        [materialBaseId]: {
          ...selectedRow,
          location: locations.join(","),
        },
      };

      setSelectedRowsIdMap(_selectedRowsIdMap);

      updateAssignmentMaterialBaseLinkLocation({
        variables: {
          material_base_id: materialBaseId,
          assignment_id: assignmentId,
          location: locations.join(","),
        },
      });

      props.dispatch({
        type: SelectedAssetsDispatchConstants.setSingleMaterialBaseLink,
        val: {
          material_base_id: materialBaseId,
          amount: quantity,
          location: locations.join(","),
        },
      });
    },
    [selectedRowsIdMap, props.links]
  );

  const handleExteriorDesignChange = () => {
    setShowExteriorDesign(() => !showExteriorDesign);
  };

  const tableContainerRef = useRef(null);

  const onScroll = () => {
    const target: any = tableContainerRef.current;
    if (!target) return;
    let pos = target.scrollTop + target.offsetHeight;
    let max = target.scrollHeight;
    // fetching before user hits the bottom of the screen so by
    // the time the user gets to the bottom, stuff will have loaded.
    if (
      pos >= max - 700 &&
      data.material_base.length % GraphqlConstants.resultsPerQuery === 0 &&
      data.material_base.length !== 0 &&
      data.material_base.length !==
        dataCount?.material_base_aggregate.aggregate.count
    ) {
      requestMoreResults();
    }
  };

  const hasMoreResults = useRef(
    data?.material_base.length !==
      dataCount?.material_base_aggregate.aggregate.count
  );

  const renderFilters = () => {
    const chips = [];
    if (filterByProjectRegion) {
      chips.push(
        <Chip
          size="small"
          color="primary"
          label={`Region: ${props.projectRegion?.name}`}
          onDelete={() => {
            setFilterByProjectRegion(false);
          }}
          className={classes.chipFilter}
        />
      );
    }
    if (filterByRetailer) {
      chips.push(
        <Chip
          size="small"
          color="primary"
          label="Retailer: Lowe's"
          onDelete={() => {
            setFilterByRetailer(false);
          }}
          className={classes.chipFilter}
        />
      );
    }
    const secondaryFilters = (
      <div style={{ display: "flex" }}>
        <AssetLibraryFilterMenu
          options={SurfaceLibraryFilterBoxOptions}
          neqFilterState={neqFilterState}
          setNeqFilterState={setNeqFilterState}
          hasAnyOfFilterState={hasAnyOfFilterState}
          hasAllOfFilters={hasAllOfFilters}
          setHasAllOfFilters={setHasAllOfFilters}
          setHasAnyOfFilterState={setHasAnyOfFilterState}
          secondaryFilters={chips}
        />
        <div className={classes.exteriorDesignSwitch}>
          <Switch
            checked={showExteriorDesign}
            onChange={handleExteriorDesignChange}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <YZTypography variant="caption">
            Show Exterior Design Results
          </YZTypography>
        </div>
      </div>
    );

    return secondaryFilters;
  };

  const memoizedAssetTable = useMemo(
    () => (
      <AssetTable
        showLoadMoreButton={hasMoreResults.current}
        contentHeight="calc(100vh - 15.6rem)"
        onScroll={onScroll}
        tableContainerRef={tableContainerRef}
        onReorder={onReorder}
        tableColumns={columns}
        hiddenColumns={hiddenColumns}
        onToggleColumnVisible={handleToggleColumnVisible}
        search={search}
        hideArchive
        resultsCount={dataCount?.material_base_aggregate.aggregate.count || 0}
        onSort={onSort}
        initialTableCells={InitialTableCells}
        toggleArchived={toggleArchived}
        showArchived={showArchived}
        onAddNew={() =>
          window.open("https://airtable.com/shrwJygwkHzq7bTP3", "_blank")
        }
        addNewButtonLabel="Request Material"
        onSearch={onSearch}
        requestMoreResults={requestMoreResults}
        networkStatus={networkStatus}
        data={data}
        error={error}
        requestRefetch={requestRefetch}
        title="Materials"
        listResultName="material_base"
        fixedHeader
        fixedTop={66}
        addNewAssetComponent={() => (
          <div>
            This feature is disabled. Please contact support for more
            information.
          </div>
        )}
        rowComponent={(rowProps) => (
          <SurfaceSelectionTableRow
            onUpdateQuantity={onUpdateQuantity}
            {...rowProps}
            idAndQuantityMap={idAndQuantityMap as object}
            selectedRowsIdMap={selectedRowsIdMap}
            onUpdatePaintLocation={onUpdatePaintLocation}
            refetch={refetch}
          />
        )}
        filters={renderFilters}
      />
    ),
    [
      data,
      dataCount,
      filterByProjectRegion,
      filterByRetailer,
      hiddenColumns,
      idAndQuantityMap,
      selectedRowsIdMap,
      neqFilterState,
      hasAnyOfFilterState,
      networkStatus,
      search,
      showExteriorDesign,
      hasAllOfFilters,
      props.links,
      props.projectRegion,
    ]
  );

  return (
    <>
      <SelectionTableBox>{memoizedAssetTable}</SelectionTableBox>
    </>
  );
}

export default SurfaceSelectionTable;
