import * as React from "react";
import { makeStyles, CircularProgress, Box } from "@material-ui/core";
import { Assignment, Agent } from "@yardzen-inc/models";
import FilterBar from "./FilterBar";
import AssignmentTable from "./AssignmentTable";
import AssignmentDetail from "./AssignmentDetail";
import { Filter, FilterPartial } from "./Filter";
import GenericConfirm from "../../Components/GenericConfirm";
import ManualAssignModal from "./ManualAssignModal";
import { assignAssignment } from "../../util/firebaseTransactions";
import AssignmentsCtx from "../../util/AssignmentsContext";
import { RouteComponentProps } from "react-router-dom";
import {
  ReopenAssignmentModal,
  ReopenAssignmentModalResult,
} from "../../ClientDetail/Detail/ReopenAssignmentModal";
import firebase from "firebase/compat/app";

interface Props extends RouteComponentProps {}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    height: "100%",
    padding: "1rem 0",
    position: "relative",
  },
  mainWindow: {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: 1,
  },
});

export default ({ history }: Props) => {
  const classes = useStyles();
  const assignmentContext = React.useContext(AssignmentsCtx);

  const listRef = React.useRef<HTMLDivElement>(null);
  const [searchResults, setSearchResults] = React.useState<Assignment[] | null>(
    null
  );

  const [manualAssignOpen, setManualAssignOpen] =
    React.useState<boolean>(false);

  const [manualAssignSubmitting, setManualAssignSubmitting] =
    React.useState<boolean>(false);

  const [reopenAssignmentModalOpen, setReopenAssignmentModalOpen] =
    React.useState<boolean>(false);

  const [filter, setFilter] = React.useState<Filter>({
    completed: "false",
    overdue: "any",
    assigned: "any",
    type: "none",
    listed: "true",
    order: "asc",
  });

  const [selectedAssignment, setSelectedAssignment] =
    React.useState<Assignment | null>(null);

  const [onConfirmAction, setOnConfirmAction] = React.useState<
    (() => void) | null
  >(null);

  React.useEffect(() => {
    search();
  }, [filter, assignmentContext.assignments]);

  React.useEffect(() => {
    assignmentContext.fetch();
    // search();
  }, [setSelectedAssignment]);

  return (
    <>
      <GenericConfirm
        open={!!onConfirmAction}
        onClose={() => setOnConfirmAction(null)}
        onSubmit={onConfirmAction || (() => setOnConfirmAction(null))}
      />
      <ManualAssignModal
        open={!!(manualAssignOpen && selectedAssignment)}
        onClose={() => setManualAssignOpen(false)}
        onSubmit={assign}
        type={(selectedAssignment && selectedAssignment.type) || undefined}
        submitting={manualAssignSubmitting}
      />
      <ReopenAssignmentModal
        open={reopenAssignmentModalOpen}
        onClose={() => setReopenAssignmentModalOpen(false)}
        onSubmit={handleReopenAssignment}
        assignment={selectedAssignment}
      />
      <div className={classes.root}>
        <FilterBar filter={filter} onChange={handleFilterChange} />
        <div className={classes.mainWindow}>
          <AssignmentTable
            listRef={listRef}
            onClick={(a) => setSelectedAssignment(a)}
            assignments={
              // TODO: Better item limiting.
              searchResults
            }
            order={filter.order}
          />
          {!!(searchResults === null || assignmentContext.loading) && (
            <Box
              p={2}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          )}
          {!!selectedAssignment && (
            <AssignmentDetail
              open={!!selectedAssignment}
              listRef={listRef}
              buttonProps={[
                {
                  label: "Unassign",
                  disabled: !!(
                    !selectedAssignment ||
                    !selectedAssignment.assignedAt ||
                    !selectedAssignment.assignedTo
                  ),
                  onClick: () =>
                    setOnConfirmAction(
                      () => () => unasignAssingment(selectedAssignment)
                    ),
                },
                {
                  label: "Assign",
                  disabled: !!(
                    !selectedAssignment ||
                    selectedAssignment.assignedAt ||
                    selectedAssignment.assignedTo
                  ),
                  onClick: () => {
                    setManualAssignOpen(true);
                  },
                },
                {
                  label: "Delete",
                  disabled: false,
                  onClick: () => {
                    setOnConfirmAction(() => () => deleteAssignment());
                  },
                },
                {
                  label: "Reopen",
                  disabled: !!(
                    selectedAssignment && !selectedAssignment.completed
                  ),
                  onClick: () => {
                    setReopenAssignmentModalOpen(true);
                  },
                },
                {
                  label: "Mark as Complete",
                  disabled: !!(
                    selectedAssignment && selectedAssignment.completed
                  ),
                  onClick: () => {
                    setOnConfirmAction(() => () => markAsComplete());
                  },
                },
                {
                  label: "Go to CDM View",
                  disabled:
                    !selectedAssignment || selectedAssignment.type !== "wesley",
                  onClick: () => {
                    setOnConfirmAction(
                      () => () =>
                        history.push(
                          `/cdm/${selectedAssignment.projectId}/${selectedAssignment.id}`
                        )
                    );
                  },
                },
              ]}
              selectedAssignment={
                selectedAssignment ||
                (searchResults && searchResults.length && searchResults[0]) ||
                null
              }
              onClose={() => setSelectedAssignment(null)}
            />
          )}
        </div>
      </div>
    </>
  );

  function handleFilterChange(filterChanges: FilterPartial) {
    setFilter({ ...filter, ...filterChanges });
  }

  async function search(): Promise<void> {
    // TODO: the filter assigned button doesn't seem to be working as intended.
    setSearchResults(null);

    let _searchResults = [...assignmentContext.assignments];

    if (_searchResults !== null) setSearchResults(null);

    if (!filter) {
      return;
    }

    _searchResults = filterByAssigned(_searchResults);
    _searchResults = filterByCompleted(_searchResults);
    _searchResults = filterOverdue(_searchResults);
    _searchResults = filterByListed(_searchResults);
    _searchResults = filterByType(_searchResults);
    _searchResults = sortResults(_searchResults);

    setSearchResults(_searchResults);
  }

  function sortResults(results: Assignment[]) {
    if (filter.order === "asc") {
      return results.sort((a, b) => a.createdAt - b.createdAt);
    } else {
      return results.sort((a, b) => b.createdAt - a.createdAt);
    }
  }

  function filterByType(assignments: Assignment[]) {
    if (filter.type === "wesley") {
      return assignments.filter((a) => {
        return a.type === "wesley";
      });
    }

    if (filter.type === "modeling") {
      return assignments.filter((a) => {
        return a.type === "modeling";
      });
    }

    if (filter.type === "design") {
      return assignments.filter((a) => {
        return a.type === "design";
      });
    }

    return assignments;
  }

  function filterByCompleted(assignments: Assignment[]) {
    if (filter.completed === "any") {
      return assignments;
    }
    return assignments.filter((a) => {
      if (filter.completed === "true") {
        return a.completed;
      }
      return !a.completed;
    });
  }

  function filterByListed(assignments: Assignment[]) {
    if (filter.listed === "any") {
      return assignments;
    }

    return assignments.filter((a) => {
      if (filter.listed === "true") {
        return a.listed;
      }
      return !a.listed;
    });
  }

  function filterByAssigned(assignments: Assignment[]) {
    if (filter.assigned === "any") {
      return assignments;
    }
    return assignments.filter((a) => {
      if (filter.assigned === "true") {
        return a.assiged;
      }
      return !a.assiged;
    });
  }

  function filterOverdue(assignments: Assignment[]): Assignment[] {
    if (filter.overdue === "any") return assignments;

    let _assignments: Assignment[] = [];
    const notOverdue: Assignment[] = [];
    for (let a of assignments) {
      if (!a.assignedAt || !a.assignedTo || a.completed) {
        notOverdue.push(a);
        continue;
      }

      let dueDate = a.assignedAt + 1000 * 60 * 60 * 24 * 2;

      if (new Date().getTime() > dueDate) {
        notOverdue.push(a);
        continue;
      }

      _assignments.push(a);
    }

    return filter.overdue === "false" ? _assignments : notOverdue;
  }

  async function markAsComplete() {
    if (!selectedAssignment) {
      return;
    }

    selectedAssignment.completed = true;

    setOnConfirmAction(null);

    await selectedAssignment.save();
    // saves to last submitted at instead of overwriting
    // original submitted at date
    if (selectedAssignment.submittedAt) {
      await setLastSubmittedAtTimestamp();
    } else {
      selectedAssignment.submittedAt = new Date().getTime();
    }
    try {
      const updated = await Assignment.fetch(
        selectedAssignment.projectId,
        selectedAssignment.id
      );

      setSelectedAssignment(updated);

      assignmentContext.update(updated);
    } catch (err) {
      console.error(err);
    } finally {
      search();
    }
  }

  async function unasignAssingment(
    assignment: Assignment | null
  ): Promise<void> {
    if (!assignment) return;

    try {
      localStorage.removeItem(`ASSIGNMENT_BRIEF_${assignment.id}`);
    } catch (error) {
      console.error(error);
    }

    assignmentContext.update(assignment);
    await assignment.unnassign();
    await search();
    setOnConfirmAction(null);
  }

  async function assign(a: Agent): Promise<void> {
    if (!selectedAssignment)
      throw new Error(
        "assignAssignment called while no selectedAssignment state"
      );
    setManualAssignSubmitting(true);

    await assignAssignment(a, selectedAssignment);

    try {
      const updatedAssignment = await Assignment.fetch(
        selectedAssignment.projectId,
        selectedAssignment.id
      );

      assignmentContext.update(updatedAssignment);
      setSelectedAssignment(updatedAssignment);
    } catch (error) {
      console.error(error);
    } finally {
      await search();
      setManualAssignOpen(false);
      setOnConfirmAction(null);
      setManualAssignSubmitting(false);
    }
  }

  async function deleteAssignment() {
    if (selectedAssignment) {
      assignmentContext.remove(selectedAssignment.id);
      await selectedAssignment.getDocRef().delete();
      setSelectedAssignment(null);
      setOnConfirmAction(null);
      setTimeout(() => {
        search();
      }, 200);
    }
  }

  async function setAssignmentPaidCorrection(isPaidCorrection: boolean) {
    if (!selectedAssignment) {
      return;
    }
    try {
      await firebase
        .firestore()
        .collection("projects")
        .doc(selectedAssignment.projectId)
        .collection("assignments")
        .doc(selectedAssignment.id)
        .set(
          {
            paidCorrection: isPaidCorrection,
          },
          { merge: true }
        );
      selectedAssignment.paidCorrection = isPaidCorrection;
      setSelectedAssignment(selectedAssignment);
    } catch (error) {
      console.error(error);
    }
  }

  async function incrementReopenedCount() {
    if (!selectedAssignment) {
      return;
    }
    try {
      await firebase
        .firestore()
        .collection("projects")
        .doc(selectedAssignment.projectId)
        .collection("assignments")
        .doc(selectedAssignment.id)
        .update({
          reopenedCount: firebase.firestore.FieldValue.increment(1),
        });
      selectedAssignment.reopenedCount = selectedAssignment.reopenedCount
        ? (selectedAssignment.reopenedCount += 1)
        : 1;
      setSelectedAssignment(selectedAssignment);
    } catch (error) {
      console.error(error);
    }
  }

  async function setLastSubmittedAtTimestamp() {
    if (!selectedAssignment) {
      return;
    }
    try {
      let timestamp = new Date().getTime();
      await firebase
        .firestore()
        .collection("projects")
        .doc(selectedAssignment.projectId)
        .collection("assignments")
        .doc(selectedAssignment.id)
        .set(
          {
            lastSubmittedAt: timestamp,
          },
          { merge: true }
        );
      selectedAssignment.lastSubmittedAt = timestamp;
      setSelectedAssignment(selectedAssignment);
    } catch (error) {
      console.error(error);
    }
  }

  function handleReopenAssignment({
    isPaidCorrection,
  }: ReopenAssignmentModalResult) {
    setReopenAssignmentModalOpen(false);
    setOnConfirmAction(() => () => {
      reopenAssignment();
      setAssignmentPaidCorrection(isPaidCorrection);
      incrementReopenedCount();
      setOnConfirmAction(null);
      setSelectedAssignment(selectedAssignment);
    });
  }

  async function reopenAssignment() {
    if (selectedAssignment) {
      selectedAssignment.completed = false;
      // selectedAssignment.assignedTo = null;
      // selectedAssignment.assignedAt = null;
      selectedAssignment.listed = false;

      selectedAssignment.paidCorrection =
        selectedAssignment.paidCorrection || null;
      selectedAssignment.lastSubmittedAt =
        selectedAssignment.lastSubmittedAt || null;
      selectedAssignment.reopenedCount =
        selectedAssignment.reopenedCount || null;

      setOnConfirmAction(null);
      setSelectedAssignment(selectedAssignment);
      await selectedAssignment.save();

      search();

      try {
        setSelectedAssignment(
          await Assignment.fetch(
            selectedAssignment.projectId,
            selectedAssignment.id
          )
        );
      } catch (err) {
        console.error(err);
      }
    }
  }
};
