import React, { FC, useMemo } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { useProfileFromId } from "./useProfile";
import { ProjectProperties } from "@yardzen-inc/models";
import { makeStyles, Theme, Tooltip, Typography } from "@material-ui/core";
import useDesigner from "./useDesigner";
import moment from "moment";
import { Build, FlashOn } from "@material-ui/icons";

export interface QaListItemProps extends BoxProps {
  project: ProjectProperties & {
    id: string;
    materialsRecieved: string;
    versionString: string;
    isProDesign?: boolean;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  expeditedIcon: {
    fontSize: "2.5rem",
    color: "#f58b2f",
  },
  proIcon: {
    fontSize: "2.5rem",
    color: "#9234eb",
  },
  container: {
    width: "100%",
    backgroundColor: "whitesmoke",
    pointerEvents: "all",
    cursor: "pointer",
  },
  secondaryContainer: {
    display: "flex",
    flexDirection: "row",
    height: "190px",
  },
  expeditedContainer: {
    display: "flex",
    flexDirection: "column",
    width: "5%",
    alignItems: "center",
    justifyContent: "center",
  },
  versionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "10%",
    alignItems: "center",
    justifyContent: "center",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    width: "33.3%",
  },
  secondaryColumnContainer: {
    display: "inline-flex",
    flexDirection: "column",
  },
}));

const QaListItem: FC<QaListItemProps> = ({ project, ...containerProps }) => {
  const profile = useProfileFromId(project.profileId);
  const designer = useDesigner(project.designerId);
  const classes = useStyles();
  const color = useMemo(
    () => getColor(project.versionString),
    [project.versionString]
  );

  return (
    <Box
      {...containerProps}
      className={classes.container}
      my={1}
      component="a"
      boxShadow={2}
    >
      <Box className={classes.secondaryContainer}>
        <Box className={classes.expeditedContainer} p={1}>
          {project.isExpedited && (
            <Tooltip title="Client purchased an expedited design package">
              <FlashOn className={classes.expeditedIcon} />
            </Tooltip>
          )}
        </Box>
        <Box className={classes.expeditedContainer} p={1}>
          {project.isProDesign && (
            <Tooltip title="Pro design package">
              <Build className={classes.proIcon} />
            </Tooltip>
          )}
        </Box>

        <Box className={classes.versionContainer} p={1}>
          <Typography variant="h2" style={{ color }}>
            {project.versionString}
          </Typography>
        </Box>
        <Box className={classes.columnContainer} p={1}>
          <Box className={classes.secondaryColumnContainer} pb={1}>
            <Typography variant="caption">Client</Typography>
            <Typography>
              {profile && `${profile.firstName} ${profile.lastName}`}
            </Typography>
          </Box>
          <Box className={classes.secondaryColumnContainer} pb={1}>
            <Typography variant="caption">Designer</Typography>
            <Typography>
              {designer && `${designer.firstName} ${designer.lastName}`}
            </Typography>
          </Box>
          <Box className={classes.secondaryColumnContainer} pb={1}>
            <Typography variant="caption">package</Typography>
            <Typography>{profile && (profile.package ?? "unknown")}</Typography>
          </Box>
        </Box>
        <Box className={classes.columnContainer} p={1}>
          <Box className={classes.secondaryColumnContainer} pb={1}>
            <Typography variant="caption">Address</Typography>
            <Typography>
              {profile && `${profile.street} ${profile.city} ${profile.state}`}
            </Typography>
          </Box>
          <Box className={classes.secondaryColumnContainer} pb={1}>
            <Typography variant="caption">Materials received</Typography>
            <Typography>
              {moment(project.materialsRecieved).format("MM/DD/YYYY HH:mm")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

function getColor(versionString?: string): string {
  if (versionString) {
    if (["V1", "V1C"].includes(versionString)) return "green";
    if (["V2", "V2C"].includes(versionString)) return "red";
    if (versionString === "V3+") return "rebeccapurple";
  }

  return "black";
}

export { QaListItem };
