import { createContext, FC } from "react";
import { ProjectManager } from "@yardzen-inc/models";
import { useCachedPm } from "../../util/hooks";
import { Typography } from "@material-ui/core";
import React from "react";

export interface QaTabContextValue {
  pm: ProjectManager;
}

export interface QaTabProviderProps {
  loadingComponent?: React.ReactNode;
  projectManagerId?: string;
}

// please use `QaTabProvider` as the sole provider for this context
// else the null typecast will break things.

const QaTabContext = createContext<QaTabContextValue>(null as any);

/*
  The component below makes it impossible to render the actual provider
  without a real pm value, so that the typing can be safe and NOT
  Optional
*/

const QaTabProvider: FC<QaTabProviderProps> = (props) => {
  const pm = useCachedPm(props.projectManagerId);

  if (pm === false) {
    return (
      <Typography style={{ color: "red" }} align="center">
        You must have a project manager profile to use this page
      </Typography>
    );
  } else if (!pm) {
    return <>{props.loadingComponent}</> ?? <></>;
  }

  return (
    <>
      <QaTabContext.Provider value={{ pm }}>
        {props.children}
      </QaTabContext.Provider>
    </>
  );
};

export { QaTabContext, QaTabProvider };
export default QaTabContext;
