import React, { useState, useEffect, FC } from "react";
import {
  makeStyles,
  Theme,
  LinearProgress,
  Grid,
  Paper,
} from "@material-ui/core";
import { Media, Profile } from "@yardzen-inc/models";
import ExteriorDesignInspirationMedia from "./ExteriorDesignInspirationMedia";
import { YZTypography } from "@yardzen-inc/react-common";

export interface ExteriorDesignInspirationContainerProps {
  profile: Profile;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: "1rem",
    padding: "3rem",
    minWidth: "400px",
    maxWidth: "1500px",
    margin: "auto",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      minWidth: "0",
      padding: "0 !important",
    },
  },
  mediaPaper: {
    padding: 20,
  },
}));

const ExteriorDesignInspirationContainer: FC<ExteriorDesignInspirationContainerProps> =
  (props) => {
    const classes = useStyles();
    const [media, setMedia] = useState<Array<Media>>([]);
    const [isLoadingMedia, setIsLoadingMedia] = useState<boolean>(true);

    useEffect(() => {
      getUserMedia();
    }, []);

    return <div className={classes.root}>{renderCards()}</div>;

    function renderCards() {
      if (isLoadingMedia) {
        return <LinearProgress variant="indeterminate" />;
      }
      if (media.length === 0) {
        return (
          <YZTypography>
            The client has not uploaded any exterior design inspiration photos
          </YZTypography>
        );
      }
      return (
        <Grid container spacing={10}>
          {media.map((image) => {
            return (
              <Grid item md={6} sm={12}>
                <Paper
                  variant="elevation"
                  className={classes.mediaPaper}
                  elevation={5}
                >
                  <ExteriorDesignInspirationMedia
                    media={image}
                    key={image.id}
                  />
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      );
    }

    async function getUserMedia() {
      setIsLoadingMedia(true);

      const records = await Media.get(
        props.profile.userId,
        null,
        "exterior-design-inspiration"
      );

      setMedia(records);
      setIsLoadingMedia(false);
    }
  };

export default ExteriorDesignInspirationContainer;
export { ExteriorDesignInspirationContainer };
