export const elementsAndMaterialsTypeMapping = {
  "Concrete (Paver)": "Belgard Paving",
  Steppers: "Belgard Paving",
  "Modular Panel System": "Belgard Artforms Panel System",
  "Retaining Wall": "Belgard Retaining Wall",
  "Multisize Pavers": "Belgard Paving",
  "Kids Play": "Custom Kids Play",
  "Composite Decking": "TimberTech Decking",
  "Aluminum Railing": "TimberTech Railing",
  Carport: "Custom Carport",
  Flushmount: "Lighting",
  Uplighting: "Lighting",
  "String Lights": "Lighting",
  "Path Lighting": "Lighting",
  "Pier Light": "Lighting",
  "Deck Light": "Lighting",
  "Step Lighting": "Lighting",
  Pendant: "Lighting",
  Sconce: "Lighting",
  "Pool (Custom)": "Custom Pool with PebbleTec",
  "Pool (Custom > 240 sq ft)": "Pool",
  "Pool (Custom < 240 sq ft)": "Pool",
  "Outdoor Shower (Enclosed)": "Outdoor Shower",
  "Outdoor Shower (Open)": "Outdoor Shower",
  "Pergola (Custom)": "Pergola",
  "Pool (Pre-fab)": "Pool",
  "Hot Tub (Pre-fab)": "Hot Tub",
};

export const hardscapeAndSoftscapeNameMapping = {
  "Built-in Seating: Custom-Designed Bench Seating": "Custom Seating",
  "DG Areas": "Decomposed Granite Areas",
  "Decks: Above-Ground, Cedar or Redwood": "Wood Decking",
  "Decks: Above-Ground, Tropical Wood": "Wood Decking",
  "Decks: In-Ground, Cedar or Redwood": "Wood Decking",
  "Decks: In-Ground, Tropical Wood": "Wood Decking",
  "Driveway Gate: Automatic": "Gate",
  "Driveway Gate: Sliding": "Gate",
  "Driveway Gate: Swing": "Gate",
  "EWF Areas": "Engineered Wood Fiber Areas",
  "Fencing: Gate": "Gate",
  "Fencing: Low (3'), Split Rail": "Low Fencing",
  "Fencing: Low (3'), X-Fence or Picket": "Low Fencing",
  "Fencing: Tall (5' - 6'), CMU Walls (only when used as fence)":
    "Tall Fencing",
  "Fencing: Tall (5' - 6'), Metal / Horizontal Wood Boards": "Tall Fencing",
  "Fencing:Tall (5' - 6'), Vertical Wood Boards / Hogwire / Deer Fence":
    "Tall Fencing",
  "Paved Areas: Mortar Set / Natural Stone / Custom Poured Concrete":
    "Paved Areas",
  "Paved Areas: Sand Set Standard / 2x2 Concrete Pavers": "Paved Areas",
  "Railing: Cable Rails or Glass": "Railing",
  "Railing: Hog Wire or Wood": "Railing",
  "Retaining Walls: CMU, Concrete, i-Beam and Wood": "Retaining Walls",
  "Retaining Walls: Landscape Timber / Corten Steel / Wood with Horizontal Veneer":
    "Retaining Walls",
  "Retaining Walls: Wood / Stacked Block / Gabion / Stacked Stone":
    "Retaining Walls",
  "Rocks, Gravel Areas": "Rocks, Gravel Areas",
  "Stairs: Pavered / Wood / Landscape Timber": "Stairs",
  "Stairs: Stone or Concrete": "Stairs",
  "Tiled Areas: Spanish-Style or Custom Patterned": "Tiled Areas",
  "Tiled Areas: Standard": "Tiled Areas",
};

export const typesToExclude = [
  "Recreational",
  "Prop",
  "Custom Price",
  "Exterior Design",
  "Doors (Single)",
  "Doors (Double)",
  "Exterior Design",
];
