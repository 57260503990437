import * as React from "react";
import { NewProfile } from "@yardzen-inc/models";
import { withRouter, Redirect } from "react-router";
import {
  CircularProgress,
  Grid,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Link,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import moment from "moment";
import firebase from "firebase/compat/app";

const budget = {
  one: "Up to $15,000",
  two: "$15,000 to $30,000",
  three: "$30,000 to $50,000",
  four: "$50,000 to $100,000",
  five: "$100,000+",
};

export interface Props {
  history: any;
  location: any;
  match: any;
}

export interface State {
  profile: NewProfile;
  loading: boolean;
  back: boolean;
}

class DesignProfileDetail extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      profile: {},
      loading: true,
      back: false,
    };
  }

  public render() {
    if (this.state.loading) {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    }

    const profileProperties = this.state.profile.getProperties();
    const timestampProperties = ["createdAt", "modifiedAt"];
    const textMsgBody = `Hi ${
      profileProperties["firstName"] as string
    } - this is Taylor from Yardzen. Thanks for reaching out about your landscaping project. Would it be helpful to chat quickly? We offer project consults over the phone, including same-day appointments. Please schedule here: yardzen.com/schedule.\n\nLearn more about Yardzen’s packages at yardzen.com/pricing-packages. Please don’t hesitate to reach out with questions.`;

    Object.keys(profileProperties).map((property) => {
      if (
        timestampProperties.includes(property) &&
        profileProperties[property]
      ) {
        profileProperties[property] = moment(profileProperties[property])
          .utc()
          .local()
          .format("MM-DD-YYYY hh:mm:ss A");
      }
    });

    const smDown = window.innerWidth < 960;

    return (
      <div style={{ margin: smDown ? 0 : 25, padding: smDown ? "0" : "unset" }}>
        {this.state.back && <Redirect to="/design-profiles" />}
        <IconButton
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            marginLeft: "auto",
            zIndex: 9,
          }}
          onClick={() => this.setState({ back: true })}
        >
          <ArrowBack></ArrowBack>
        </IconButton>
        <Grid>
          <Paper style={{ border: "none" }} elevation={0}>
            <Grid
              container
              style={{ marginLeft: 15, marginBottom: 25, paddingTop: 15 }}
              direction="column"
            >
              <Grid item style={{ marginBottom: 15 }}>
                <Typography display="inline" variant="body2">
                  {profileProperties["firstName"] || "<missing first name>"}
                </Typography>
                <Typography display="inline" variant="body2">
                  {profileProperties["lastName"] || "<missing last name>"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography display="inline" variant="body2">
                  Email:{" "}
                  <Link href={`mailto:${profileProperties["email"]}`}>
                    {profileProperties["email"] || ""}
                  </Link>
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: smDown ? 0 : 15 }}>
                <Typography display="inline">
                  Phone:{" "}
                  <Link
                    href={
                      `sms:${
                        profileProperties["phone"]
                      }&body=${encodeURIComponent(textMsgBody)}` || ""
                    }
                  >
                    {profileProperties["phone"] || ""}
                  </Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography display="inline">
                  {profileProperties["street"] || ""}
                </Typography>
              </Grid>
              <Grid item>
                <Typography display="inline">
                  {profileProperties["city"] || ""},{" "}
                  {profileProperties["state"] || ""}{" "}
                  {profileProperties["zip"] || ""}
                </Typography>
              </Grid>
            </Grid>
            <Paper style={{ margin: 25, padding: 10 }}>
              <Grid container>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(x, checked: boolean) => {
                          this.state.profile.set({ contacted: checked });
                          this.state.profile.save();
                          let profile = this.state.profile;
                          profile.contacted = checked;
                          this.setState({ profile: profile });
                          this.markDuplicateProfilesAsContacted();
                        }}
                        checked={profileProperties["contacted"] || false}
                        value="contacted"
                      />
                    }
                    label="Contacted"
                  />
                </Grid>
              </Grid>
            </Paper>
            <Table>
              <TableBody>
                {Object.keys(profileProperties).map(
                  (property) =>
                    typeof profileProperties[property] === "string" &&
                    property !== "userId" && (
                      <TableRow key={property}>
                        <TableCell>
                          <Typography>{property}</Typography>
                        </TableCell>

                        <TableCell>
                          <Typography display="inline">
                            {property === "budget"
                              ? // @ts-ignore
                                budget[profileProperties[property]]
                              : profileProperties[property]}
                            {}{" "}
                          </Typography>
                          <Typography
                            display="inline"
                            style={{ fontWeight: "bold" }}
                          >
                            {timestampProperties.includes(property) &&
                              "Local Time"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </div>
    );
  }

  markDuplicateProfilesAsContacted = async () => {
    const profileProperties = this.state.profile.getProperties();
    const email = profileProperties.email;

    const idsToUpdate = await firebase
      .firestore()
      .collection("newProfiles")
      .where("email", "==", email)
      .get()
      .then((resp) => {
        const ids: string[] = [];
        resp.forEach((doc) => {
          ids.push(doc.id);
        });
        return ids;
      });

    const batch = firebase.firestore().batch();

    idsToUpdate.forEach((id) => {
      const docRef = firebase.firestore().collection("newProfiles").doc(id);
      batch.update(docRef, { contacted: this.state.profile.contacted });
    });

    batch.commit();
  };

  componentWillMount() {
    const self = this;
    const profileId = this.props.match.params["profileId"];

    NewProfile.fetch(profileId).then((profile: NewProfile | false) => {
      if (profile) {
        self.setState({
          profile: profile,
          loading: false,
        });
      }
    });
  }
}

export default withRouter(DesignProfileDetail);
