import { PromotionalCodeCreateFormControllerProps } from "../PromotionalCodeCreateFormController";

export function validatePromoCodeCreate(
  values: PromotionalCodeCreateFormControllerProps["defaultValues"]
): Record<string, string> {
  const errors: Record<string, string> = {};

  [
    "code",
    "discount",
    "discountType",
    "startTime",
    "endTime",
    "maxRedemptions",
    "additionalDiscounts",
  ].forEach((key) => {
    if (!(values as Record<string, any>)[key]) {
      errors[key] = "Required";
    }
  });

  if (!/^[A-Z0-9][A-Z0-9-_]*[A-Z0-9]$/.test(values.code ?? "")) {
    errors.code =
      "Code must be in the format `UPPER_CASE_UNDERSCORE-OR-DASH-DELIMITED`";
  }

  if (values.discountType !== "PERCENTAGE" && values.discountType !== "FIXED") {
    errors.discountType =
      "Discount type must be either `PERCENTAGE` or `FIXED`";
  }

  do {
    const discount = Number(values.discount);

    if (isNaN(discount)) {
      errors.discount = "Discount must be a number";
      break;
    }

    if (discount < 0) {
      errors.discount = "Discount must be positive";
      break;
    }

    if (values.discountType === "PERCENTAGE" && discount >= 100) {
      errors.discount = "Percentage discount must be less than 100";
      break;
    }
  } while (false);

  do {
    const maxRedemptions = Number(values.maxRedemptions);
    if (isNaN(maxRedemptions)) {
      errors.maxRedemptions = "Max redemptions must be a number";
      break;
    }

    if (maxRedemptions < 0) {
      errors.maxRedemptions = "Max redemptions must be positive";
      break;
    }

    if (!Number.isInteger(maxRedemptions)) {
      errors.maxRedemptions = "Max redemptions must be an integer";
      break;
    }
  } while (false);

  return errors;
}
