import React, { FC, useState, useMemo, ReactNode, useEffect } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { DesignTeamQuestionWithId } from "./ProjectManagerQuestionsIntake";
import { CircularProgress, Typography, Divider } from "@material-ui/core";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Profile, Project } from "@yardzen-inc/models";
import { YZButton } from "@yardzen-inc/react-common";
import { useDesignBriefV1 } from "../Components/designBrief/util";
import GenericSnackBar from "../Components/GenericSnackBar";

export interface CdmFeedbackPageProps extends BoxProps {
  project: Project;
  profile: Profile;
}

const CdmFeedbackPage: FC<CdmFeedbackPageProps> = ({
  project,
  profile,
  ...containerProps
}) => {
  const [budgetWarnings, setbudgetWarnings] = useState<
    DesignTeamQuestionWithId[] | null
  >(null);
  const [designTeamQuestions, setDesignTeamQuestions] = useState<
    DesignTeamQuestionWithId[] | null
  >(null);

  const [hasBudgetWarnings, hasDesignTeamQuestions] = useMemo(() => {
    return [!!budgetWarnings?.length, !!designTeamQuestions?.length];
  }, [budgetWarnings, designTeamQuestions]);

  const {
    api: designBriefApi,
    data: designBriefData,
    reloadDesignBrief,
  } = useDesignBriefV1(profile, project);

  const [loadingQuestionId, setLoadingQuestionId] = useState("");
  const [designBriefError, setDesignBriefError] = useState("");

  useEffect(fetchData, [project.id]);

  if (!budgetWarnings || !designTeamQuestions) {
    return (
      <Box
        width="inherit"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        p={2}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box {...containerProps}>
      <Box>
        <Box pb={2}>
          <Typography variant="h4">Budget Warning</Typography>
        </Box>
        <Box pb={2}>
          {hasBudgetWarnings
            ? renderItems(budgetWarnings, "BUDGET_WARNINGS")
            : "No budget warning for this project"}
        </Box>
      </Box>
      <Box>
        <Box pb={2}>
          <Typography variant="h4">Design team questions / comments</Typography>
        </Box>
        <Box pb={2}>
          {hasDesignTeamQuestions
            ? renderItems(designTeamQuestions, "PM_QUESTIONS")
            : "No PM questions for this project"}
        </Box>
      </Box>
      <GenericSnackBar
        variant="error"
        onClose={() => setDesignBriefError("")}
        in={!!designBriefError}
        message={designBriefError}
      />
    </Box>
  );

  function fetchData(): void {
    fetchBudgetWarnings();
    fetchDesignTeamQuestions();
  }

  async function fetchBudgetWarnings() {
    const snap = await firebase
      .firestore()
      .collection("projects")
      .doc(project.id)
      .collection("budget-warning")
      .get();

    if (snap.empty) {
      return setbudgetWarnings([]);
    }

    setbudgetWarnings(
      snap.docs.map((d) => ({
        ...(d.data() as any),
        id: d.id,
      })) as DesignTeamQuestionWithId[]
    );
  }

  async function fetchDesignTeamQuestions() {
    const snap = await firebase
      .firestore()
      .collection("projects")
      .doc(project.id)
      .collection("design-team-questions")
      .get();

    if (snap.empty) {
      return setDesignTeamQuestions([]);
    }

    setDesignTeamQuestions(
      snap.docs.map((d) => ({
        ...(d.data() as any),
        id: d.id,
      })) as DesignTeamQuestionWithId[]
    );
  }

  function renderItems(
    questions: DesignTeamQuestionWithId[],
    type: "BUDGET_WARNINGS" | "PM_QUESTIONS"
  ): ReactNode[] {
    return questions.map((q) => {
      const isIncludedInDesignBrief = designBriefData?.[
        "CLIENT_RESPONSES"
      ]?.some((r) => r.questionId === q.id);

      return (
        <Box key={q.value}>
          <Box pb={2}>
            <Typography style={{ fontStyle: "bold" }}>
              [project manager]: {q.value}
            </Typography>
          </Box>
          <Box pb={2}>
            <Typography>{renderClientResponse(profile, q)}</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            pt={2}
            pb={4}
          >
            <YZButton
              onClick={
                isIncludedInDesignBrief
                  ? () => removeClientResponseFromDesignBrief(q.id)
                  : () => addClientResponseToDesignBrief(q, type)
              }
              disabled={loadingQuestionId === q.id}
            >
              {/* TODO - MAKE THIS READABLE */}
              {loadingQuestionId === q.id
                ? "UPDATING..."
                : isIncludedInDesignBrief
                ? "REMOVE FROM DESIGN BRIEF"
                : "ADD TO DESIGN BRIEF"}
            </YZButton>
          </Box>
          <Divider style={{ width: "100%" }}></Divider>
          <br />
        </Box>
      );
    });
  }

  function renderClientResponse(
    profile: Profile,
    question: DesignTeamQuestionWithId
  ): string {
    const baseString = "[client]: ";

    // Client left a custom response
    if (question.response) {
      return baseString + question.response;
    }

    // Client approved PLP without a custom response
    if (profile.funcFlowApproved) {
      return baseString + "I understand.";
    }

    // Client has not yet responded to PLP
    return baseString;
  }

  async function addClientResponseToDesignBrief(
    q: DesignTeamQuestionWithId,
    type: "BUDGET_WARNINGS" | "PM_QUESTIONS"
  ) {
    setLoadingQuestionId(q.id);

    try {
      await designBriefApi.createEntry({
        type: 0,
        value: q.response,
        marked: false,
        group: "CLIENT_RESPONSES",
        order: type === "BUDGET_WARNINGS" ? 100 : 500,
        label:
          type === "BUDGET_WARNINGS"
            ? "Budget warning response:"
            : "PM question response:",
        questionId: q.id,
      });

      await updateClientResponsesDesignBriefMetadata("ADD");
    } catch (err) {
      console.log("Error adding client response to design brief \n\n", err);
      setDesignBriefError(
        "Unable to add client response to design brief, please try again."
      );
    }

    reloadDesignBrief();
    setLoadingQuestionId("");
  }

  async function removeClientResponseFromDesignBrief(questionId: string) {
    setLoadingQuestionId(questionId);
    const entryToRemove = designBriefData?.["CLIENT_RESPONSES"].find(
      (response) => response.questionId === questionId
    );

    if (!entryToRemove) {
      console.log(
        "Error finding the corresponding line item in the design brief to remove."
      );
      setDesignBriefError(
        "Unable to remove client response from design brief, please try again."
      );
      setLoadingQuestionId("");
      return;
    }

    try {
      await designBriefApi.deleteEntry(entryToRemove);

      await updateClientResponsesDesignBriefMetadata("REMOVE");
    } catch (err) {
      console.log("Error removing client response from design brief \n\n", err);
      setDesignBriefError(
        "Unable to remove client response from design brief, please try again."
      );
    }

    reloadDesignBrief();
    setLoadingQuestionId("");
  }

  /**
   * After adding or removing items to the design brief with the group type
   * "CLIENT_RESPONSES", this function should be called to appropriately
   * update the order in the design brief metadata document.
   *
   * @param operation Signifies whether you are adding or removing design brief
   * items of the group "CLIENT_RESPONSES"
   */
  async function updateClientResponsesDesignBriefMetadata(
    operation: "ADD" | "REMOVE"
  ) {
    const _meta = await designBriefApi.getMetadata();

    if (!_meta) {
      throw new Error("No design brief metadata found!");
    }

    const _order = _meta.order || [];
    let newOrder: string[] = [];

    if (operation === "ADD") {
      // Adding client response to design brief

      // Check if "CLIENT_RESPONSES" is in metadata order. If not, add it to the front
      if (!_order.includes("CLIENT_RESPONSES")) {
        newOrder = _order.slice();
        newOrder.unshift("CLIENT_RESPONSES");
      }
    } else {
      // Removing client response from design brief

      // Check if "CLIENT_RESPONSES" is in metadata order. If so, remove it
      if (_order.includes("CLIENT_RESPONSES")) {
        const orderCopy = _order.slice();
        const _idx = orderCopy.indexOf("CLIENT_RESPONSES");

        newOrder = [..._order.slice(0, _idx), ..._order.slice(_idx + 1)];
      }
    }

    if (newOrder.length) {
      await designBriefApi.updateMetadata("order", newOrder);
    }
  }
};

export { CdmFeedbackPage };
export default CdmFeedbackPage;
