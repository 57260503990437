import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";

export interface CrashPageProps {
  error: unknown;
}

const CrashPage: FC<CrashPageProps> = (props) => {
  return (
    <>
      <Box display="flex" justifyContent="center" pt={4} px={2}>
        <Box>
          <Typography variant="h3" align="center">
            We're sorry, but something went wrong.{" "}
          </Typography>
          <Typography variant="h5" align="center">
            Our engineering team has been alerted and is looking into the
            problem. Please reload the page.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export { CrashPage };
export default CrashPage;
