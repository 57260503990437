import {
  Box,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { Add, Close, Info } from "@material-ui/icons";
import { FieldArray } from "formik";
import { components } from "src/api/pangaeaTypes.generated";

export interface Props {
  values: {
    additionalDiscounts:
      | components["schemas"]["PromotionalCodeAdditionalDiscount"][]
      | undefined;
  };
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  disabled: boolean;
  products?: { id: string; name: string }[];
}

export const AdditionalDiscountsForm: FC<Props> = (props) => {
  return (
    <Box>
      <FieldArray name="additionalDiscounts" validateOnChange={true}>
        {({ remove, push }) => (
          <div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                lineHeight: "auto",
              }}
            >
              <Box p={1}>
                <Tooltip title="Add Additional Discount">
                  <IconButton
                    onClick={() => {
                      push({
                        discount: "1.00",
                        forProductId: undefined,
                        discountType: "FIXED",
                      });
                    }}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography>Additional Discounts</Typography>
              <Tooltip
                title={
                  <>
                    <Typography>
                      Discounts applied on top of the primary discount. Select a
                      product, or leave Product empty to apply to all products.
                    </Typography>
                    <Typography>
                      For Free Expedited, select Expedited Upsell and add the
                      discount equal to the price.
                    </Typography>
                  </>
                }
              >
                <Info />
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 2fr)",
                gridGap: "1rem",
              }}
            >
              {props.values.additionalDiscounts?.map((discount, index) => (
                <Box
                  sx={{
                    width: "auto",
                    padding: ".5rem",
                    borderRadius: ".5rem",
                    border: "1px solid #000",
                  }}
                  key={index}
                  id={index.toString()}
                >
                  <Box p={0}>
                    <Tooltip title="Remove Additional Discount">
                      <IconButton size="small" onClick={() => remove(index)}>
                        <Close />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  <Box p={1}>
                    <TextField
                      onBlur={props.handleBlur}
                      value={discount.forProductId}
                      select
                      fullWidth
                      onChange={props.handleChange}
                      label="For Product"
                      name={`additionalDiscounts[${index}].forProductId`}
                      disabled={props.disabled}
                    >
                      {props.products?.map((product) => (
                        <MenuItem value={product.id} key={product.id}>
                          {product.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <Box p={1}>
                    <TextField
                      onBlur={props.handleBlur}
                      select
                      fullWidth
                      value={discount.discountType}
                      onChange={props.handleChange}
                      name={`additionalDiscounts[${index}].discountType`}
                      label="Discount Type"
                      disabled={props.disabled}
                    >
                      <MenuItem value={"FIXED"}>FIXED</MenuItem>
                      <MenuItem value={"PERCENTAGE"}>PERCENTAGE</MenuItem>
                    </TextField>
                  </Box>
                  <Box p={1}>
                    <TextField
                      value={discount.discount}
                      onBlur={props.handleBlur}
                      fullWidth
                      name={`additionalDiscounts[${index}].discount`}
                      onChange={props.handleChange}
                      label={`Discount (${
                        discount.discountType === "FIXED" ? "$" : "%"
                      })`}
                      disabled={props.disabled}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </div>
        )}
      </FieldArray>
    </Box>
  );
};
