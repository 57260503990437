import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useState,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Checkbox,
  Chip,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { ProjectManagerProperties } from "@yardzen-inc/models";
import { Close } from "@material-ui/icons";
import { BuildRegion } from "../BuildView/routes/Contractors/util/BuildRegions/types";
import { banyanAPI } from "../../api/banyanV1API";

export interface EditAccountManagerMarketsModalProps {
  open: boolean;
  onClose: () => void;
  accountManager: ProjectManagerProperties & {
    id: string;
    buildRegions: BuildRegion[];
    banyanAccountManagerId: string | undefined;
  };
  allBuildRegions: BuildRegion[];
  refreshData: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles({
  select: {
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },
    borderRadius: "1.25rem",
    width: "50rem",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid",
      borderColor: "primary.main",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "primary.main",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "primary.main",
    },
  },
  dialogContent: {
    minHeight: "20rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  chip: {
    margin: "0.25rem",
  },
});

const EditAccountManagerMarketsModal: FC<EditAccountManagerMarketsModalProps> =
  ({ open, onClose, accountManager, allBuildRegions, refreshData }) => {
    const [loading, setLoading] = useState(false);
    const [selectedBuildRegions, setSelectedBuildRegions] = useState<string[]>(
      accountManager.buildRegions.map((b) => b.displayName)
    );
    const classes = useStyles();

    const [createAccountManager, { error: createError }] =
      banyanAPI.useCreateAccountManagerMutation();
    const [updateAccountManager, { error: updateError }] =
      banyanAPI.useUpdateAccountManagerMutation();

    if (createError) {
      console.log("createError", createError);
    }

    if (updateError) {
      console.log("updateError", updateError);
    }
    const handleChange = (
      event: ChangeEvent<{ name?: string | undefined; value: unknown }>
    ) => {
      const {
        target: { value },
      } = event;
      const newValue: string[] =
        typeof value === "string" ? value.split(",") : (value as string[]);

      setSelectedBuildRegions(newValue);
    };

    async function handleSubmit() {
      setLoading(true);
      try {
        const selectedBuildRegionIds = allBuildRegions
          .filter((br) => selectedBuildRegions.includes(br.displayName))
          .map((br) => br.id);

        if (accountManager.banyanAccountManagerId) {
          console.log("updating account manager", accountManager);
          await updateAccountManager({
            id: accountManager.banyanAccountManagerId,
            projectManagerId: accountManager.id,
            userId: accountManager.userId || "none",
            email: accountManager.email || "",
            buildRegionIds: selectedBuildRegionIds,
          });
        } else {
          console.log("creating account manager", accountManager);
          await createAccountManager({
            userId: accountManager.userId || "none",
            projectManagerId: accountManager.id,
            email: accountManager.email || "",
            buildRegionIds: selectedBuildRegionIds,
          });
        }
      } catch (err) {
        console.error("Error updating assigned markets.");
      } finally {
        setLoading(false);
        onClose();
        refreshData((prev) => !prev);
      }
    }

    return (
      <Dialog open={open} onClose={onClose} maxWidth="xl">
        <Box position="relative" p={2}>
          <Box position="absolute" top={5} right={5}>
            <IconButton size="small" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <DialogTitle>
            Edit Assigned Markets - {accountManager.firstName}{" "}
            {accountManager.lastName}
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {loading && (
              <Box mt={2} display="flex" justifyContent="center">
                <CircularProgress color="primary" size={75} />
              </Box>
            )}
            <FormControl>
              <Select
                id="elementTags"
                className={classes.select}
                multiple
                value={selectedBuildRegions}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {(selected as string[]).map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </Box>
                )}
              >
                {allBuildRegions.map((b) => (
                  <MenuItem key={b.id} value={b.displayName}>
                    <Checkbox
                      checked={selectedBuildRegions.indexOf(b.displayName) > -1}
                    />
                    <ListItemText
                      style={{ color: b.accountManager?.id ? "black" : "red" }}
                      primary={b.displayName}
                    />
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="subtitle1" style={{ padding: "4px" }}>
                *Red markets are not assigned to any account manager.
              </Typography>
            </FormControl>
            <Box pt={2} p={1}>
              <Box display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  style={{
                    color: "black",
                    width: "45%",
                  }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "45%" }}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    );
  };
export { EditAccountManagerMarketsModal };
