import * as React from "react";
import { Typography, Box } from "@material-ui/core";
import { Profile, Media } from "@yardzen-inc/models";
import { AnnotatorPage } from "@yardzen-inc/annotator";
import { UserCtx } from "../../util/UserContext";
import { ProfileCtx } from "../../util/ProfileContext";
import downloadAndZip from "../../util/downloadAndZip";
import SingleFileUpload from "../../SelfAssignPage/SingleFileUpload";
import { YZButton } from "@yardzen-inc/react-common";

interface Props {
  sendFuncFlowToClient: () => void;
  funcButtonDisabled: boolean;
  clientRecord: Profile;
}

function FunctionAndFlow({
  sendFuncFlowToClient,
  funcButtonDisabled,
  clientRecord,
}: Props) {
  const [annotatorOpen, setAnnotatorOpen] = React.useState<boolean>(false);
  const [annotatedHouseModel, setAnnotatedHouseModel] =
    React.useState<Media | null>(null);
  const [funcFlow, setFuncFlow] = React.useState<Media | null>(null);
  const [funcFlowAI, setFuncFlowAI] = React.useState<Media | null>(null);
  const user = React.useContext(UserCtx);
  const profile = React.useContext(ProfileCtx);

  React.useEffect(() => {
    (async function () {
      const ffs = await Media.get(clientRecord.id, null, "function-and-flow");
      if (!ffs.length) return;
      setFuncFlow(ffs[0]);
    })();
    (async function () {
      const ffsai = await Media.get(
        clientRecord.id,
        null,
        "function-and-flow-raw"
      );
      if (!ffsai.length) return;
      setFuncFlowAI(ffsai[0]);
    })();
  }, [clientRecord]);

  React.useEffect(() => {
    getAnnotatedHouseModel();
  }, [funcFlow]);

  return (
    <>
      {annotatorOpen && annotatedHouseModel && !!user && (
        <AnnotatorPage
          open={true}
          media={[annotatedHouseModel]}
          user={user}
          profile={profile}
          onClose={() => setAnnotatorOpen(false)}
          currentUser="pm"
        ></AnnotatorPage>
      )}

      <Typography style={{ margin: "0.5rem" }} variant="h5">
        Upload only one image (no pdf)
      </Typography>
      <div
        style={{
          float: "right",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <YZButton
          variant="contained"
          color="primary"
          disabled={funcButtonDisabled || clientRecord["funcFlowReady"]}
          onClick={() => sendFuncFlowToClient()}
        >
          Send Diagram To Client
        </YZButton>
        <YZButton
          variant="contained"
          color="primary"
          disabled={!annotatedHouseModel}
          onClick={() => setAnnotatorOpen(true)}
        >
          View Annotator
        </YZButton>
        <YZButton
          variant="contained"
          color="primary"
          disabled={!funcFlowAI}
          onClick={() =>
            downloadAndZip([funcFlowAI as any], "function_flow_raw")
          }
        >
          {funcFlowAI
            ? "Download Function Flow Illustrator File"
            : "No Illustrator File Found"}
        </YZButton>
        <Typography variant="overline">
          {clientRecord["funcFlowReady"]
            ? "Diagram has been sent"
            : "Diagram has not been sent"}
        </Typography>
      </div>
      <Box p={1} width={"40%"} minWidth={"fit-content"}>
        <SingleFileUpload
          fileTag="function-and-flow-raw"
          title="FF .ai upload"
          userId={clientRecord.id}
          uploadMessage={".ai upload successful"}
          accept={[".ai"]}
        />
      </Box>
    </>
  );

  async function getAnnotatedHouseModel() {
    const annotatedHouseModel = await Media.get(
      clientRecord.id,
      null,
      "annotated-house-model"
    );

    if (annotatedHouseModel.length > 0)
      return setAnnotatedHouseModel(annotatedHouseModel[0]);
    if (!funcFlow) return;
    if (
      (await funcFlow.getDocRef().collection("threads").get()).empty === false
    )
      return setAnnotatedHouseModel(funcFlow);
  }
}

export default FunctionAndFlow;
