import {
  Budget_Metadata,
  Budget_Phase_Metadata,
  useQuery,
} from "@yardzen-inc/graphql";
import { formatNumToUSD } from "@yardzen-inc/react-common";
import { useProjectAndProfile } from "./useProjectAndProfile";
import { BUDGET_DISPLAY_DATA_QUERY } from "../../../src/graphql/budgetQAQueries";

export type BudgetWithLabel = {
  budgetValue: number;
  budgetLabel: string;
  formattedBudgetValue: string;
  budgetMetadata: BudgetMetaData | undefined;
  loading: boolean;
  error: string | null;
  budgetChecklist: BudgetElementsPriceEstimate | null;
};

export type BudgetPhaseMetadata = Pick<
  Budget_Phase_Metadata,
  "budget" | "id" | "yards"
>;

// This type is necessary to allow us to access the designer_visible_budget
// property on the extracted metadata, while also maintaining legacy code.
export type BudgetMetaData = Pick<
  Budget_Metadata,
  | "id"
  | "phasing"
  | "project_id"
  | "total_budget"
  | "design_project_for_single_phase"
> & {
  designer_visible_budget: string;
  budget_phase_metadata: BudgetPhaseMetadata[];
};

export type BudgetElementsPriceEstimate =
  | [number | undefined, number | undefined]
  | null;

export function useGetBudgetDisplayData(projectId: string): BudgetWithLabel {
  const {
    data: projectAndProfile,
    loading: projectAndProfileLoading,
    error: projectAndProfileError,
  } = useProjectAndProfile(projectId);
  const adjustedBudget = projectAndProfile?.profile.adjustedBudget;
  const reportedBudget = projectAndProfile?.profile.reportedBudget;

  const { data, loading, error } = useQuery(BUDGET_DISPLAY_DATA_QUERY, {
    variables: { projectId },
    fetchPolicy: "cache-and-network",
  });
  const budgetMetadata = data?.budget_metadata?.[0];

  function aggregateSumResponsePriceRages(): BudgetElementsPriceEstimate {
    if (loading) {
      return null;
    }

    if (data?.budget_checklist_response) {
      let low = 0;
      let high = 0;

      for (const response of data.budget_checklist_response) {
        if (response.budget_checklist_item.quantitative_item) {
          low += parseInt(response.budget_checklist_price_option.low_price, 10);
          high += parseInt(
            response.budget_checklist_price_option.high_price,
            10
          );
        } else {
          let multiplier = 0;

          [
            response?.front_yard,
            response?.back_yard,
            response?.left_yard,
            response?.right_yard,
          ].forEach((yard) => {
            if (!!yard) {
              multiplier += 1;
            }
          });

          if (!multiplier) {
            multiplier = 1;
          }

          low +=
            parseInt(response.budget_checklist_price_option.low_price, 10) *
            multiplier;
          high +=
            parseInt(response.budget_checklist_price_option.high_price, 10) *
            multiplier;
        }
      }

      return [low, high];
    }

    return [undefined, undefined];
  }

  if (loading || projectAndProfileLoading) {
    return {
      budgetValue: 0,
      budgetLabel: "Loading...",
      formattedBudgetValue: "$0",
      budgetMetadata,
      loading: true,
      error: null,
      budgetChecklist: null,
    };
  }

  if (error || projectAndProfileError) {
    const errorMessage = error?.message || projectAndProfileError;
    console.error(
      "Error getting budget metadata data",
      errorMessage,
      JSON.stringify(error),
      JSON.stringify(projectAndProfileError)
    );
    return {
      budgetValue: 0,
      budgetLabel: "An error occurred",
      formattedBudgetValue: "$0",
      budgetMetadata,
      loading: false,
      error: "There was an error getting the budget metadata",
      budgetChecklist: null,
    };
  }

  if (adjustedBudget) {
    return {
      budgetValue: adjustedBudget,
      budgetLabel: "Adjusted Budget",
      formattedBudgetValue: formatNumToUSD(adjustedBudget),
      budgetMetadata,
      loading: false,
      error: null,
      budgetChecklist: aggregateSumResponsePriceRages(),
    };
  }

  const noBudgetValue = {
    budgetValue: 0,
    budgetLabel: "No budget value found",
    formattedBudgetValue: "$0",
    budgetMetadata,
    loading: false,
    error: "No budget value found",
    budgetChecklist: aggregateSumResponsePriceRages(),
  };

  if (!budgetMetadata) {
    return noBudgetValue;
  }

  if (
    budgetMetadata.phasing &&
    !budgetMetadata.design_project_for_single_phase
  ) {
    const phaseMetadatumToUse =
      budgetMetadata.budget_phase_metadata[
        budgetMetadata.budget_phase_metadata.length - 1
      ];
    return {
      budgetValue: parseFloat(phaseMetadatumToUse.budget),
      budgetLabel: "Phased Budget",
      formattedBudgetValue: formatNumToUSD(
        parseFloat(phaseMetadatumToUse.budget)
      ),
      budgetMetadata,
      loading: false,
      error: null,
      budgetChecklist: aggregateSumResponsePriceRages(),
    };
  }

  if (budgetMetadata.designer_visible_budget) {
    return {
      budgetValue: parseFloat(budgetMetadata.designer_visible_budget),
      budgetLabel: "Client Design Budget",
      formattedBudgetValue: formatNumToUSD(
        parseFloat(budgetMetadata.designer_visible_budget)
      ),
      budgetMetadata,
      loading: false,
      error: null,
      budgetChecklist: aggregateSumResponsePriceRages(),
    };
  }

  if (budgetMetadata.total_budget) {
    return {
      budgetValue: parseFloat(budgetMetadata.total_budget),
      budgetLabel: "Total Budget",
      formattedBudgetValue: formatNumToUSD(
        parseFloat(budgetMetadata.total_budget)
      ),
      budgetMetadata,
      loading: false,
      error: null,
      budgetChecklist: aggregateSumResponsePriceRages(),
    };
  }

  if (reportedBudget) {
    return {
      budgetValue: parseFloat(reportedBudget),
      budgetLabel: "Reported Budget (Legacy)",
      formattedBudgetValue: formatNumToUSD(parseFloat(reportedBudget)),
      budgetMetadata,
      loading: false,
      error: null,
      budgetChecklist: aggregateSumResponsePriceRages(),
    };
  }

  return noBudgetValue;
}
