import { useMemo } from "react";
import { Profile, Project } from "@yardzen-inc/models";
import { useQuerySnapshot } from "../../util/hooks";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
export interface UseProjectManagerAssigned {
  (pmId: string | null): Profile[] | null;
}

const UseProjectManagerAssigned: UseProjectManagerAssigned = (pmId) => {
  const query = useMemo(() => {
    return firebase
      .firestore()
      .collection("projects")
      .where("projectManagerId", "==", pmId ?? "")
      .orderBy("createdAt", "desc");
  }, [pmId]);

  return useQuerySnapshot<Profile[]>(query, handleSnapshot, [query])[1] || null;

  async function handleSnapshot(
    snap: firebase.firestore.QuerySnapshot
  ): Promise<Profile[]> {
    return Promise.all(
      Project.hydrate(snap.docs).map(async (p) => {
        return p.getOwner();
      })
    );
  }
};

export { UseProjectManagerAssigned };
export default UseProjectManagerAssigned;
