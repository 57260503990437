import { useLazyQuery, useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import {
  formatNumToUSD,
  YZAccordionChip,
  YZItemsAccordion,
  YZItemsAccordionField,
} from "@yardzen-inc/react-common";
import React from "react";
import { sortBy } from "lodash";
import {
  UPSERT_ASSIGNMENT_SOFTSCAPE_ITEM_LINK,
  DELETE_ASSIGNMENT_SOFTSCAPE_ITEMS,
  SOFTSCAPE_ITEMS,
} from "@yardzen-inc/graphql";
import { handleMutationError } from "../../EmployeeView/AssetLibraries/elementMutationErrorUtil";
import QuantityInput from "./QuantityInput";
import { CalculatorTypeConstants } from "../../ConstantValues/CalculatorTypeConstants";
import { QUERY_SELECTION_FOR_BUDGET_QA } from "../../../src/graphql/budgetQAQueries";

const fields: YZItemsAccordionField[] = [
  {
    header: "Item",
    fieldName: "name",
  },
  {
    header: "Unit",
    fieldName: "unit",
  },
  {
    header: "Units Needed",
    headerAlign: "center",
    fieldName: "quantity",
  },
  {
    header: "Unit Cost",
    fieldName: "unitCost",
  },
  {
    header: "Total Cost",
    fieldName: "totalCost",
  },
];

interface Props {
  designAssignmentId: string;
  isProcessingData: boolean;
  onChangingQuantity: (v: boolean) => void;
  enableQuantityChange?: boolean;
}

const useStyles = makeStyles({
  quantityInputContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  downloadBtnContainer: {
    "& button": {
      marginLeft: 2,
      marginRight: 2,
    },
  },
});

function SelectedItems(props: Props) {
  const classes = useStyles();

  const [upsertAssignmentCalculatorItemLink] = useMutation(
    UPSERT_ASSIGNMENT_SOFTSCAPE_ITEM_LINK,
    {
      onError: handleMutationError,
      onCompleted: () => {
        props.onChangingQuantity(false);
      },
    }
  );

  const [deleteAssignmentCalculatorItemLink] = useMutation(
    DELETE_ASSIGNMENT_SOFTSCAPE_ITEMS,
    {
      onError: handleMutationError,
      onCompleted: () => props.onChangingQuantity(false),
    }
  );

  const [getSelection, { data: dataSelection }] = useLazyQuery(
    QUERY_SELECTION_FOR_BUDGET_QA,
    {
      fetchPolicy: "network-only",
      onCompleted: () => {},
    }
  );

  const [getAllCalculatorItems, { data: allCalculatorItems }] = useLazyQuery(
    SOFTSCAPE_ITEMS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const fetchSelectedItems = () => {
    getSelection({
      variables: {
        id: props.designAssignmentId,
      },
    });
  };

  const fetchAllCalculatorItems = () => {
    getAllCalculatorItems();
  };

  const hasAssignmentCalculatorsData = (): boolean => {
    if (!dataSelection?.assignment?.length || !allCalculatorItems) {
      return false;
    }

    return true;
  };

  const calculatorChipText = (type: CalculatorTypeConstants): string => {
    if (!calculatorDataLoaded) {
      return "Not Started";
    }

    return calculatorTotalCost(type);
  };

  const calculatorTotalCost = (type: CalculatorTypeConstants): string => {
    if (!hasAssignmentCalculatorsData()) {
      return formatNumToUSD(0);
    }

    const totalCost = parseFloat(
      dataSelection.assignment[0].assignment_softscape_item_links
        .filter((each: any) => each.softscape_item.type === type)
        .reduce(
          (sum: number, link: any) =>
            sum + parseFloat(link.softscape_item.unit_cost) * link.quantity,
          0
        )
    );

    return formatNumToUSD(totalCost);
  };

  const onUpdateCalculatorItemQty = (quantity: number, id: string) => {
    props.onChangingQuantity(true);
    const _quantity = Math.max(quantity, 0);

    if (_quantity < 1) {
      deleteAssignmentCalculatorItemLink({
        variables: {
          assignment_id: props.designAssignmentId,
          softscape_item_id: id,
        },
      });
    } else {
      upsertAssignmentCalculatorItemLink({
        variables: {
          assignment_id: props.designAssignmentId,
          softscape_item_id: id,
          quantity: _quantity,
        },
      });
    }
  };

  const getCalculatorItems = () => {
    const selectedCalculatorItems =
      dataSelection?.assignment?.[0]?.assignment_softscape_item_links;

    const rowItems = sortBy(
      allCalculatorItems.softscape_item,
      (item: any) => item.name
    );

    return rowItems.map((item: any) => {
      const onUpdateQty = (qty: number) => {
        onUpdateCalculatorItemQty(qty, item.id);
      };

      const assignmentLink = selectedCalculatorItems?.find(
        (i: any) => i.softscape_item.id === item.id
      );

      const quantity = assignmentLink ? assignmentLink.quantity : 0;

      return {
        totalCost: formatNumToUSD(
          quantity * item.unit_cost + quantity * (item.labor_cost || 0)
        ),
        quantity: props.enableQuantityChange ? (
          <div className={classes.quantityInputContainer}>
            <QuantityInput
              onUpdate={onUpdateQty}
              value={quantity}
              unit={item.unit}
              key={item.id}
            />
          </div>
        ) : (
          quantity
        ),
        unit: item.unit,
        type: item.type,
        name: item.name,
        unitCost: formatNumToUSD(item.unit_cost),
      };
    });
  };

  const [calculatorItems, setCalculatorItems] = React.useState<any>([]);
  const [calculatorDataLoaded, setCalculatorDataLoaded] = React.useState(false);

  React.useEffect(() => {
    if (dataSelection && allCalculatorItems) {
      setCalculatorDataLoaded(true);
      setCalculatorItems(getCalculatorItems());
    }
  }, [dataSelection, allCalculatorItems]);

  React.useEffect(() => {
    // The user may have moved quickly from changing
    // an item's quantity to coming to this page
    // so let's wait until processing is complete
    // before fetching data to not have out-of-sync
    // data.
    if (props.isProcessingData) {
      return;
    }
    fetchAllCalculatorItems();
    fetchSelectedItems();
  }, [props.isProcessingData, props.designAssignmentId]);

  const accordions = [
    <YZItemsAccordion
      key="Softscape Calculator"
      fields={fields}
      chip={
        <YZAccordionChip
          text={calculatorChipText(CalculatorTypeConstants.Softscape)}
        />
      }
      rows={calculatorItems.filter(
        (item: any) => item.type === CalculatorTypeConstants.Softscape
      )}
      loading={!calculatorDataLoaded}
      title="Softscape Calculator"
    />,
    <YZItemsAccordion
      key="Hardscape Calculator"
      fields={fields}
      chip={
        <YZAccordionChip
          text={calculatorChipText(CalculatorTypeConstants.Hardscape)}
        />
      }
      rows={calculatorItems.filter(
        (item: any) => item.type === CalculatorTypeConstants.Hardscape
      )}
      loading={!calculatorDataLoaded}
      title="Hardscape Calculator"
    />,
  ];

  return <div>{accordions}</div>;
}

export default SelectedItems;
