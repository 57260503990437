import * as React from "react";
import {
  Box,
  ClickAwayListener,
  Grow,
  makeStyles,
  Paper,
  Popper,
  IconButton,
  useTheme,
} from "@material-ui/core";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import { ExpandMore, Close } from "@material-ui/icons";

interface FilterProps {
  label: string;
  selected?: boolean;
  children: React.ReactNode;
  icon?: React.ReactNode;
  disableListener?: boolean;
  showClose?: boolean;
  active?: boolean;
}

const FilterMenu = ({ ...props }: FilterProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: { target: any }) => {
    // @ts-ignore
    if (anchorRef && anchorRef.current?.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // @ts-ignore
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <div className={classes.btnContainer}>
        <YZButton
          style={{
            background: props.active ? theme.palette.secondary.main : undefined,
          }}
          ref={anchorRef}
          onClick={handleToggle}
          size="small"
        >
          {props.icon}
          <YZTypography
            variant="body2"
            type="mono"
            style={{ letterSpacing: 0.5, fontSize: 10 }}
          >
            {props.label}
          </YZTypography>
          <ExpandMore style={{ fontSize: 17 }} />
        </YZButton>
      </div>
      <ClickAwayListener
        onClickAway={props.disableListener ? () => {} : handleClose}
      >
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          className={classes.menu}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={3}>
                {props.showClose && (
                  <Box pb={0} p={1} justifyContent="flex-end" display="flex">
                    <IconButton size="small" onClick={handleClose}>
                      <Close style={{ fontSize: 20 }} />
                    </IconButton>
                  </Box>
                )}
                {props.children}
              </Paper>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </>
  );
};

const useStyles = makeStyles({
  menu: {
    zIndex: 1200,
  },
  btnContainer: {
    "& svg": {
      paddingRight: 7,
      fontSize: 17,
    },
  },
});

FilterMenu.defaultProps = {
  showClose: false,
};

export default FilterMenu;
