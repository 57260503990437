import * as React from "react";
import { Button, makeStyles, Theme, Typography } from "@material-ui/core";
import { IContactInfo } from "../Components/ContactForm";
import firebase from "firebase/compat/app";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import GenericSnackBar from "../Components/GenericSnackBar";
import ContactPanel from "./ContactPanel";

interface Props {
  profileId: string;
  buttonStyle?: any;
}

function Contacts({ profileId }: Props): React.ReactElement {
  const classes = useStyles();

  const [existingContacts, setExistingContacts] = React.useState<
    React.ReactElement[]
  >([]);
  const [error, setError] = React.useState<null | string>(null);

  React.useEffect(() => {
    getContacts(profileId);
  }, []);

  return (
    <div style={{ width: "fit-content", margin: "1rem" }}>
      <Typography variant="h5">
        Additional Contacts{" "}
        <Button
          style={{ marginLeft: "1rem", backgroundColor: "#81c784" }}
          variant="outlined"
          onClick={() => addContact()}
        >
          <AddCircleOutlineOutlined style={{ marginRight: "1rem" }} /> New
        </Button>
      </Typography>
      <Typography style={{ fontSize: "0.7rem" }} variant="overline">
        You will be able to search by these contacts, but they will not be
        <br />
        automatically included in any communication.
      </Typography>
      {error && (
        <GenericSnackBar
          orientation={{
            vertical: "bottom",
            horizontal: "left",
          }}
          message={error}
          variant="error"
          onClose={() => {}}
        />
      )}
      <div className={classes.contactRoot}>{existingContacts}</div>
    </div>
  );

  async function addContact() {
    await firebase
      .firestore()
      .collection("profiles")
      .doc(profileId)
      .collection("contacts")
      .add({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        getContacts(profileId);
      })
      .catch((e) => {
        window.newrelic.noticeError(e);
        console.log("Error creating contact: ", e);
        setError("Error adding contact!");
      });
  }
  async function getContacts(profileId: string) {
    await firebase
      .firestore()
      .collection("profiles")
      .doc(profileId)
      .collection("contacts")
      .orderBy("createdAt", "asc")
      .get()
      .then((resp) => {
        const contacts: React.ReactElement[] = [];
        resp.forEach((doc: any) => {
          const data: IContactInfo = doc.data();
          const vals = Object.values(data);
          let empty = true;
          vals.forEach((val) => {
            if (!!val.length) empty = false;
          });
          data.id = doc.id;
          contacts.push(
            <ContactPanel
              key={doc.id}
              getContacts={getContacts}
              profileId={profileId}
              contactDoc={data}
              newContact={empty}
            />
          );
        });

        setExistingContacts(contacts);
      })
      .catch((e) => {
        console.log("Error Fetching Contacts: ", e);
        setError("Error Fetching Contacts");
      });
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  contactRoot: {
    display: "flex",
    // flexDirection: "row-reverse",
    flexFlow: "row wrap",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));

export default Contacts;
