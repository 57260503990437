import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { AgentGroup } from "@yardzen-inc/util";

export interface UseAgentGroupStanding {
  (groupId: string | null): boolean | null;
}

const useAgentGroupStanding: UseAgentGroupStanding = (groupId) => {
  const [groupActive, setGroupActive] =
    useState<ReturnType<UseAgentGroupStanding>>(null);

  useEffect(onGroupIdChange, [groupId]);

  return groupActive;

  function onGroupIdChange(): void | (() => void) {
    const groupIdType = typeof groupId;

    try {
      if (groupId && groupIdType !== "string") {
        throw new Error(`Invalid data type: ${groupIdType} passed as groupId`);
      }

      if (!groupId) {
        return setGroupActive(false);
      }

      return firebase
        .firestore()
        .collection("agent-groups")
        .doc(groupId)
        .onSnapshot((snap) => {
          if (snap.exists) {
            const { active } = snap.data() as AgentGroup;
            setGroupActive(!!active);
          }
        });
    } catch (error) {
      console.error(error);
      return alert(
        "An error occured fetching the status of your account. Please reload the page and try again"
      );
    }
  }
};

export { useAgentGroupStanding };
export default useAgentGroupStanding;
