import * as React from "react";
import { TypeformModel, TypeformUIObject } from "@yardzen-inc/models";
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core";
import LoadingAbsCenter from "../../Components/LoadingAbsCenter";
import firebase from "firebase/compat/app";
import { UserCtx } from "../../util/UserContext";
import RenderTypeformData from "./RenderTypeformData";

interface Props {
  profileId: string;
  profile?: any;
}

const Typeform = (props: Props) => {
  const [typeformData, setTypeformData] = React.useState<TypeformUIObject[]>(
    []
  );
  const user = React.useContext(UserCtx);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchTypeformData = async () => {
      const completedTypeforms =
        (await TypeformModel.fetchAll(props.profileId)) || null;

      if (!completedTypeforms || !completedTypeforms.length) {
        setIsLoading(false);
        return setTypeformData([]);
      }

      setIsLoading(false);
      return setTypeformData(completedTypeforms);
    };

    fetchTypeformData();
  }, [props.profileId]);

  if (isLoading) {
    return <LoadingAbsCenter in={isLoading} />;
  }

  if (!typeformData || !typeformData.length) {
    return (
      <Typography paragraph>No quiz answers have been submitted</Typography>
    );
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {!!props.profile &&
          !!user &&
          !!user.email?.includes("@yardzen.co") &&
          deDupeTypeforms(typeformData).map((tF) => {
            return (
              <div key={`${tF.submittedAt}-${tF.formTitle}`}>
                {!!(
                  props.profile["typeforms"] &&
                  props.profile["typeforms"][tF.id] === true &&
                  tF.id
                ) ? (
                  <Button
                    key={tF.id}
                    variant="contained"
                    style={{
                      width: "min-content",
                      whiteSpace: "nowrap",
                      margin: "1.5rem 0 1.5rem 0",
                    }}
                    onClick={() => resetStylesQuiz(tF.id)}
                  >
                    Allow User to submit new {tF.formTitle}
                  </Button>
                ) : (
                  <Typography
                    style={{
                      margin: "1.5rem 0 1.5rem 0",
                    }}
                  >
                    Client has access to {tF.formTitle}
                  </Typography>
                )}
              </div>
            );
          })}
      </div>
      {renderTypeformResponses(typeformData)}
    </>
  );

  function renderTypeformResponses(typeformData: TypeformUIObject[]) {
    return typeformData.map(
      (typeform: TypeformUIObject, index: number, arr: any[]) => {
        const formNums = arr.slice(0, index).filter((form) => {
          return form.id === typeform.id;
        });

        return (
          <Accordion key={`${typeform.submittedAt}-${typeform.id}`}>
            <AccordionSummary>
              {typeform.formTitle} {formNums.length + 1} (Click to expand)
            </AccordionSummary>
            <AccordionDetails>
              <RenderTypeformData typeform={typeform} />
            </AccordionDetails>
          </Accordion>
        );
      }
    );
  }

  function resetStylesQuiz(typeformId: string) {
    const newObj = {
      typeforms: {
        ...props.profile["typeforms"],
        [typeformId]: false,
      },
    };

    return firebase
      .firestore()
      .collection("profiles")
      .doc(props.profileId)
      .update(newObj);
  }

  function deDupeTypeforms(
    typeformData: TypeformUIObject[]
  ): TypeformUIObject[] {
    const seenIds: string[] = [];
    return typeformData.filter((tF) => {
      if (!seenIds.includes(tF.id)) {
        seenIds.push(tF.id);
        return true;
      }
      return false;
    });
  }
};

export default Typeform;
