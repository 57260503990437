export enum HouseModelDeliverable {
  tag = "function-and-flow",
  variant = "house-model",
}

export enum HouseModel {
  tag = "model",
  variant = "model",
}

export const BUDGET_ESTIMATE_VARIANT = "budget-estimate";
