import * as React from "react";
import Swipeable from "react-swipeable-views";
import { AgentCtx } from "./AgentContext";
import {
  Assignment,
  DesignAssignment,
  DesignProfile,
  DesignProfileProperties,
  Project,
  Agent,
  ProjectProperties,
  ProfileProperties,
} from "@yardzen-inc/models";
import { Addy, getAddress } from "../util/getAddress";
import {
  makeStyles,
  Tabs,
  Tab,
  Typography,
  LinearProgress,
  Fade,
  Box,
} from "@material-ui/core";
import {
  config,
  CurrentJobPageConfig,
  PageComponentProps,
} from "./CurrentJobPageMap";
import { Redirect } from "react-router-dom";
import { useLocalStorage } from "../util/hooks";
import AgentGroupGate from "./AgentGroupGate";
import { theme, YZButton, YZTypography } from "@yardzen-inc/react-common";
import classnames from "classnames";
import { getProjectById } from "../util/firebase/firebaseClient";
import { useDispatch } from "../state/hooks";
import { setProfile as setProfileInRedux } from "../state/slices/entities/profilesSlice";
import { getIsExteriorPackage } from "../util/selfAssign/getIsExteriorPackage";

// TODO: expand caching scheme to work for designer assignments as well

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: 1,
    height: "calc(100vh - 4rem)",
  },
  unassignedPage: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    flexGrow: 1,
  },
  tabs: {
    background: "#fff",
    borderBottom: "1px solid #f2f2f2",
    minHeight: "0 !important",
    "& .MuiTab-root": {
      minWidth: 0,
      textTransform: "none",
      height: 48,
      fontSize: 13.5,
      minHeight: "0 !important",
      "&.Mui-selected": {
        fontWeight: 600,
      },
    },
  },
  tabsFixed: {
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      top: 0,
      left: 160,
      right: 110,
    },
  },
});

interface Props {
  assignment: Assignment | Project | null | "none";
  openAssignmentCount: number | null;
  requestNextJob: () => void;
  disabled: boolean;
  agent?: Agent;
  inLiisa?: boolean;
  pm?: boolean;
}

interface SerializedPagePropsPartial {
  project: ProjectProperties;
  profile: ProfileProperties;
  designProfile?: DesignProfileProperties | null;
  address: Addy | null;
}

export default ({
  assignment,
  openAssignmentCount,
  requestNextJob,
  disabled,
  agent: _agent,
  inLiisa = false,
  pm,
}: Props) => {
  const agent = _agent ?? (React.useContext(AgentCtx) as unknown as Agent);
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [index, setIndex] = React.useState<number>(0);
  const [_pageProps, setPageProps] = React.useState<
    PageComponentProps | null | "none"
  >(null);

  const [pagePropsCache, updatePagePropsCache] =
    useLocalStorage<SerializedPagePropsPartial>(
      assignment && assignment !== "none" && agent?.type !== "design"
        ? `ASSIGNMENT_${assignment.id}_PAGE_PROPS_V2`
        : "none"
    );

  const pageProps = React.useMemo(() => {
    if (_pageProps || agent.type === "design") {
      return _pageProps;
    }

    return _pageProps;
  }, [_pageProps, pagePropsCache]);

  const confMap = React.useMemo(getPageConfig, [pageProps]);

  const [redirect, setRedirect] = React.useState<string | null>(null);

  const dispatch = useDispatch();

  React.useEffect((): void => {
    do {
      if (assignment && !pageProps) {
        if (
          assignment instanceof Project &&
          assignment.currentDesignAssignmentId === null
        ) {
          return;
        }

        fetchRelevantData();
        break;
      }
      if (assignment instanceof Assignment || assignment instanceof Project) {
        fetchRelevantData();
      }
    } while (false);
  }, [(assignment as any)?.id ?? assignment]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (assignment === "none" || (agent.type === "design" && !assignment)) {
    // If the designer does not have a designerPodId, disallow them from picking up assignments
    if (agent.type === "design" && !agent.designerPodId) {
      return (
        <Box
          mt={5}
          p={5}
          display="flex"
          flexDirection="column"
          alignItems="center"
          flexGrow={1}
        >
          <YZTypography variant="h4">
            You are not assigned to a designer pod!
          </YZTypography>
          <br />
          <YZTypography variant="body1">
            Please reach out to the Yardzen Design team to get assigned to a pod
            and start picking up assignments!
          </YZTypography>
        </Box>
      );
    }

    return (
      <AgentGroupGate groupId={agent?.groupId ?? null}>
        <>
          <div className={classes.unassignedPage}>
            <Typography variant="h4" align="center" style={{ padding: "1rem" }}>
              No current Assignment
            </Typography>
            <br />
            <YZButton
              variant="contained"
              onClick={requestNextJob}
              disabled={!openAssignmentCount || disabled}
            >
              Start Next Assignment
            </YZButton>
            <Typography align="center" variant="caption">
              {(function () {
                if (openAssignmentCount === null) {
                  return "Loading Assignments...";
                } else if (openAssignmentCount === 0) {
                  return "No open assignments, check back later!";
                } else {
                  return;
                }
              })()}
            </Typography>
          </div>
        </>
      </AgentGroupGate>
    );
  }

  if (pageProps === null || (loading && pagePropsCache === null)) {
    return (
      <Box className={classes.root}>
        <LinearProgress variant="indeterminate" color="primary" />
      </Box>
    );
  }

  return (
    <div
      id="job-dash"
      className={classes.root}
      style={{
        maxWidth: inLiisa ? "100vw" : "calc(100vw - 265px - 0.5rem)",
        display: inLiisa ? "flex" : "block",
      }}
    >
      <div
        style={
          inLiisa
            ? {
                flexFlow: "row nowrap",
                // flexGrow: 1,
              }
            : {}
        }
      >
        <Tabs
          onChange={(e, i) => setIndex(i)}
          value={index}
          scrollButtons="auto"
          variant="scrollable"
          indicatorColor="primary"
          className={classnames(
            classes.tabs,
            !inLiisa ? classes.tabsFixed : undefined
          )}
        >
          {getTabs(confMap)}
        </Tabs>
        {inLiisa && (
          <YZButton
            variant="text"
            onClick={() => setRedirect("/cdm")}
            style={{ position: "absolute", top: 0, right: 0 }}
          >
            Exit
          </YZButton>
        )}
      </div>
      <Swipeable
        slideClassName="swipeable"
        index={index}
        containerStyle={{ flexGrow: 1 }}
      >
        {getPages(confMap)}
      </Swipeable>
    </div>
  );

  function getPages(confMap: CurrentJobPageConfig[]): React.ReactNode[] {
    return confMap.map((conf, i) => (
      <div
        id="pages"
        className={classes.root}
        key={conf.tabProps.label + "page" + i.toString()}
      >
        <Fade mountOnEnter unmountOnExit in={i === index}>
          <conf.component
            {...pageProps}
            pm={!!pm}
            key={conf.tabProps.label + "-page"}
          />
        </Fade>
      </div>
    ));
  }

  function getTabs(confMap: CurrentJobPageConfig[]): React.ReactNode[] {
    return confMap.map((conf) => (
      <Tab {...conf.tabProps} key={conf.tabProps.label + "-tab"} />
    ));
  }

  async function fetchRelevantData() {
    // FIXME: garbage bandaid code
    setLoading(true);
    try {
      if (!assignment || assignment === "none") {
        return;
      }

      let assign: Assignment | DesignAssignment;

      if (agent.type === "design") {
        const a = await DesignAssignment.fetch(
          assignment.id,
          (assignment as Project).currentDesignAssignmentId as string
        );

        if (!a) {
          throw new Error("no design assignment");
        }

        assign = a;
      } else {
        assign = assignment as Assignment;
      }

      let proj!: Project;

      if (assignment instanceof Assignment) {
        proj = await getProjectById(assignment.projectId);
      } else {
        proj = await getProjectById(assignment.id);
      }

      const project = proj;
      const profile = await proj.getOwner();
      const address = await getAddress(profile.id);

      let designProfile: DesignProfile | undefined = undefined;

      try {
        designProfile = await DesignProfile.fetchByUserId(profile.id);
      } catch {}

      dispatch(setProfileInRedux(profile));

      setPageProps({
        agent: agent as any,
        profile: profile as any,
        project: project as any,
        assignment: assign as any,
        designProfile: designProfile as any,
        address,
        key: "",
        inLiisa,
      });

      setLoading(false);

      const serializable: SerializedPagePropsPartial = {
        project: {
          ...project.getProperties(),
          createdAt: project.createdAt,
          id: project.id,
        } as any,
        designProfile: designProfile?.getProperties(),
        profile: { ...profile.getProperties(), id: profile.id },
        address,
      };

      updatePagePropsCache(serializable);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  function getPageConfig() {
    // filter out exterior design info for inappropriate clients

    const configMap = config[agent.type];

    if (
      typeof pageProps === "object" &&
      !getIsExteriorPackage(pageProps?.profile?.package)
    ) {
      return configMap.filter((item) => item.tabProps.label !== "Exterior");
    }
    return configMap;
  }
};
