import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Button,
  LinearProgress,
  Grow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Log, LogType } from "@yardzen-inc/models";
import sendAnnotationCommentNotification from "../../util/sendAnnotationCommentNotification";
import usePrevious from "../../util/hooks/usePrevious";
import sendUpdatedRevisionNotification from "../../util/sendUpdatedRevisionNotification";
import { GenericOnCallFunctionAlert } from "../../util/genericAlert";
import GenericSnackBar from "../../Components/GenericSnackBar";

interface Props {
  clientId: string;
  open?: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "600px",
    maxWidth: "100vw",
  },
  closeButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

export default (props: Props) => {
  const classes = useStyles();

  const [sending, setSending] = React.useState<boolean>(false);
  const [sent, setSent] = React.useState<boolean>(false);
  const [communications, setCommunications] = React.useState<Log[] | null>(
    null
  );
  const [type, setType] = React.useState<LogType | undefined>(undefined);
  const [onCallFunctionError, setOnCallFunctionError] =
    React.useState<string>("");

  const prevSending = usePrevious(sending, true);

  React.useEffect(() => {
    if (!sending && prevSending) {
      setSent(true);
    }
  }, [sending]);

  React.useEffect(() => {
    let timeout: any;

    if (sent) {
      timeout = setTimeout(() => {
        if (props.open) {
          props.onClose();

          setSending(false);
          setSent(false);
        }
      }, 1500);
    }

    return () => {
      if (timeout) {
        clearTimeout();
      }
    };
  }, [sent]);

  React.useEffect(() => {
    if (!open) {
      return () => null;
    }

    return subscribeToAnnotationCommunications();
  }, [open]);

  React.useEffect(() => {
    if (!open) {
      return () => null;
    }

    return subscribeToRevisionCommunications();
  }, [open]);

  return (
    <Dialog
      open={!!props.open}
      classes={{ paper: classes.root }}
      onClose={props.onClose}
    >
      <GenericSnackBar
        variant="error"
        message={onCallFunctionError}
        onClose={() => setOnCallFunctionError("")}
        in={!!onCallFunctionError}
      />
      <DialogTitle>
        <Typography variant="h4" align="center" gutterBottom>
          Notify client
        </Typography>
      </DialogTitle>
      <DialogContent>
        <IconButton className={classes.closeButton} onClick={props.onClose}>
          <Close></Close>
        </IconButton>
        <Box display="flex" flexDirection="column" mt={1}>
          <Typography variant="h5" gutterBottom>
            Previous Transmissions
          </Typography>
          <Box my={1} p={1} bgcolor="#e5e5e5" maxHeight="250px" overflow="auto">
            {renderCommunications()}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          p={1}
        >
          <Grow mountOnEnter unmountOnExit in={sending}>
            <LinearProgress
              variant="indeterminate"
              color="primary"
              style={{
                flexGrow: 1,
                height: "2rem",
                borderRadius: "15px",
                marginRight: "8px",
              }}
            />
          </Grow>
          <FormControl>
            <InputLabel>Communication Type</InputLabel>
            <Select
              style={{ width: "150px" }}
              value={type}
              onChange={(e) => setType(e.target.value as LogType)}
            >
              <MenuItem value={undefined}>
                <em>None</em>
              </MenuItem>
              <MenuItem value="pm_alerted_client_to_updated_revision">
                Updated Revision
              </MenuItem>
              <MenuItem value="pm_responded_to_client_annotations">
                New Annotations
              </MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            disabled={sending || sent}
            onClick={sendCommunication}
          >
            {!sent ? "Send Notification" : "Success!"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );

  function renderCommunications(): React.ReactNode[] | React.ReactNode {
    if (!communications) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="primary" />
        </Box>
      );
    }

    if (!communications.length) {
      return (
        <Typography variant="h5" align="center">
          No notifications sent
        </Typography>
      );
    }

    return communications.map(renderCommunication);
  }

  function renderCommunication(log: Log): React.ReactNode {
    return (
      <Box p="1" key={log.id}>
        {log.format()}
      </Box>
    );
  }

  async function sendCommunication() {
    setSending(true);

    switch (type) {
      case "pm_responded_to_client_annotations":
        try {
          await sendAnnotationCommentNotification({
            userId: props.clientId,
            annotationLevel: "V1",
          });
        } catch (error) {
          GenericOnCallFunctionAlert(
            "pingClientForAnnotationComments",
            error.message
          );
          setOnCallFunctionError("Unable to ping client with new annotation");
        }
        break;
      case "pm_alerted_client_to_updated_revision":
        try {
          await sendUpdatedRevisionNotification(props.clientId);
        } catch (error) {
          GenericOnCallFunctionAlert(
            "pingClientForUpdatedRevision",
            error.message
          );
          setOnCallFunctionError("Unable to ping client with updated revision");
        }
        break;
      default:
        break;
    }

    setSending(false);
  }

  function subscribeToAnnotationCommunications(): () => void {
    return Log.getLogsWithUserAndTypeQuery(
      props.clientId,
      "pm_responded_to_client_annotations"
    ).onSnapshot(handleSnap);
  }

  function subscribeToRevisionCommunications(): () => void {
    return Log.getLogsWithUserAndTypeQuery(
      props.clientId,
      "pm_alerted_client_to_updated_revision"
    ).onSnapshot(handleSnap);
  }

  function handleSnap(
    snap: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  ) {
    setCommunications((communications) => [
      ...(communications || []),
      ...Log.hydrate(snap.docs)
        .filter((l) => !!l && !!l.createdAt)
        .sort((a, b) => a.createdAt.seconds - b.createdAt.seconds),
    ]);
  }
};
