import React, { FC, useState, useMemo } from "react";
import useDesignProfiles, { DesignProfileWithId } from "./useDesignProfiles";
import { Box, CircularProgress } from "@material-ui/core";
import DesignProfileList from "./DesignProfileList";
import FilterBar from "./FilterBar";
import { Filter } from "./types";

export interface NewDesignProfileViewProps {}

const NewDesignProfileView: FC<NewDesignProfileViewProps> = () => {
  const [filter, setFilter] = useState<Filter>(setInitalFilter);
  const [numberOfRecords, setNumberOfRecords] = useState<number>(200);
  const designProfiles = useDesignProfiles(
    numberOfRecords,
    useMemo(
      () => getFilterFunc(),
      [
        filter.hasAddress,
        filter.hasPhoneNumber,
        filter.address,
        filter.phoneNumber,
        filter.hasQuestions,
        filter.hasName,
      ]
    )
  );

  return (
    <>
      <FilterBar
        filters={filter}
        updateFilters={setFilter}
        numberOfRecords={numberOfRecords}
        setNumberOfRecords={setNumberOfRecords}
      />
      {designProfiles ? (
        <DesignProfileList DesignProfiles={designProfiles} />
      ) : (
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          p={2}
        >
          <CircularProgress></CircularProgress>
        </Box>
      )}
    </>
  );

  function getFilterFunc(): (
    p: DesignProfileWithId[]
  ) => DesignProfileWithId[] {
    const filterFuncs: ((p: DesignProfileWithId) => boolean)[] = [];

    if (filter.hasAddress) {
      filterFuncs.push(
        (p: DesignProfileWithId) => !!p.contactInformation.formattedAddress
      );
    }

    if (filter.hasPhoneNumber) {
      filterFuncs.push(
        (p: DesignProfileWithId) => !!p.contactInformation.phoneNumber
      );
    }

    if (filter.hasName) {
      filterFuncs.push(
        (p: DesignProfileWithId) =>
          !!p.contactInformation.firstName && !!p.contactInformation.lastName
      );
    }

    if (filter.hasQuestions) {
      filterFuncs.push((p: DesignProfileWithId) => {
        const body = p.survey[0].r?.body;

        console.log(body);

        if (!body) {
          return false;
        }

        if (!body?.length) {
          return false;
        }

        return true;
      });
    }

    if (!!filter.phoneNumber) {
      const phoneNumberR = new RegExp(`^${filter.phoneNumber}`);

      filterFuncs.push((p: DesignProfileWithId) => {
        if (p.contactInformation.phoneNumber) {
          return phoneNumberR.test(p.contactInformation.phoneNumber);
        }

        return false;
      });
    }

    if (!!filter.address) {
      const addressR = new RegExp(`${filter.address}`, "i");

      filterFuncs.push((p: DesignProfileWithId) => {
        if (p.contactInformation.formattedAddress) {
          return addressR.test(p.contactInformation.formattedAddress);
        }

        return false;
      });
    }

    return (ps: DesignProfileWithId[]) => {
      let filtered = [...ps];

      for (const filterFunk of filterFuncs) {
        filtered = filtered.filter(filterFunk);
      }

      return filtered;
    };
  }
};

function setInitalFilter(): Filter {
  return {
    hasAddress: false,
    hasPhoneNumber: false,
    phoneNumber: "",
    address: "",
    contacted: false,
    hasName: false,
    hasQuestions: false,
  };
}

export { NewDesignProfileView };
export default NewDesignProfileView;
