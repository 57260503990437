import React, { FC, useContext, useRef } from "react";
import { DesignBriefCtx, UseDesignBriefLoadingState } from "./util";
import { Snackbar, Box, CircularProgress, Typography } from "@material-ui/core";

export interface DesignBriefLoadingSnackbarProps {}

const DesignBriefLoadingSnackbar: FC<DesignBriefLoadingSnackbarProps> = (
  props
) => {
  const dbCtxValue = useContext(DesignBriefCtx);

  const messageMemoryRef = useRef<string>("");

  return (
    <Snackbar
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      color="primary"
      open={dbCtxValue.loadingState !== UseDesignBriefLoadingState.DONE}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignContent="center"
        justifyContent="center"
      >
        <Box p={1}>
          <CircularProgress></CircularProgress>
        </Box>
        <Typography style={{ height: "min-content" }}>
          {renderText()}
        </Typography>
      </Box>
    </Snackbar>
  );

  function renderText(): string {
    if (dbCtxValue.loadingState === UseDesignBriefLoadingState.CREATING) {
      const message = "Creating design brief";
      messageMemoryRef.current = message;

      return message;
    }

    if (dbCtxValue.loadingState === UseDesignBriefLoadingState.FETCHING) {
      const message = "Loading...";
      messageMemoryRef.current = message;

      return message;
    }

    return messageMemoryRef.current;
  }
};

export { DesignBriefLoadingSnackbar };
export default DesignBriefLoadingSnackbar;
