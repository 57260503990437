import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Media, MediaProperties } from "@yardzen-inc/models";
import { GetBudgetQADataResult } from "../ClientDetail/Media/util/useGetBudgetQADataForSharedMedia";

/*
  Replaces media on the sharable link with the last revision media
*/

export const upsertSharedMediaDoc = async (
  profileId: string,
  media: MediaProperties[] | Media[],
  isContractorMedia: boolean,
  budgetQAData?: GetBudgetQADataResult,
  skipOverwrite = false
) => {
  console.log(`upserting ${media.length} media to ${profileId}`);

  const dbRef = firebase
    .firestore()
    .collection("shareableLinks")
    .doc(profileId);

  const doc = (await dbRef.get()).data();

  if (!doc) {
    const docData: any = {
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      media,
      isContractorOnly: isContractorMedia,
      ...(budgetQAData ?? {}),
    };

    return dbRef.set(docData);
  } else {
    if (skipOverwrite) {
      return;
    }

    const docData = {
      ...doc,
      media,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      ...(budgetQAData ?? {}),
    };

    return dbRef.update(docData);
  }
};
