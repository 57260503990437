import * as React from "react";
import "./App.css";
import firebase from "firebase/compat/app";
import { CircularProgress, Grid, Typography, Link } from "@material-ui/core";
import { Profile } from "./Interfaces";
import { Redirect } from "react-router";
import SelfAssignPage from "./SelfAssignPage";
import EmployeeHome from "./EmployeeView/EmployeeHome";
import "./util/errors/newRelic";

interface State {
  isLoggedIn: null | boolean;
  isLoading: boolean;
  records: Array<Profile>;
  isEmployee: null | boolean;
}

interface Props {
  searchText?: string;
  userId?: string;
  history?: any;
  oldView?: boolean;
}

class App extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoggedIn: null,
      isLoading: true,
      records: [],
      isEmployee: null,
    };

    this.onClickLogout = this.onClickLogout.bind(this);
  }
  public render() {
    if (this.state.isLoggedIn === false) {
      return (
        <div>
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        </div>
      );
    }

    return (
      <div className="App">
        {this.state.isLoading ? (
          <Grid container alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : this.state.isEmployee === false ? (
          <SelfAssignPage></SelfAssignPage>
        ) : (
          <EmployeeHome {...this.props} />
        )}

        {this.state.isLoading && (
          <Typography className="links">
            <Link href="#" onClick={this.onClickLogout}>
              Logout
            </Link>
          </Typography>
        )}
      </div>
    );
  }

  public componentDidMount() {
    // @ts-ignore
    const user = this.props["user"];
    if (!user || !user.email) {
      return this.setState({ isLoggedIn: false });
    }
    if (user.email.indexOf("@yardzen.co") === -1) {
      return this.setState({
        isLoggedIn: true,
        isEmployee: false,
        isLoading: false,
      });
    }

    return this.setState({
      isLoggedIn: true,
      isEmployee: true,
      isLoading: false,
    });
  }

  // This function is irrelevant now. We are fetching records
  // in the RecordsContext and don't need to fetch them
  // anywhere else
  protected async populateRecordsFromFirebase() {
    console.log("FETCHING FROM FIREBASE");
    const db = firebase.firestore();
    const profilesRef = db.collection("profiles");

    await profilesRef
      .orderBy("createdAt", "desc")
      .get()
      .then((snapshot: firebase.firestore.QuerySnapshot) => {
        let records: Array<Profile> = [];
        snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot) => {
          records.push(this.buildRecordFromFirebaseDoc(doc));
        });

        return records;
      })
      .then((records) => {
        this.setState({ records: records });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  protected populateRecordsFromAirtable() {}

  protected buildRecordFromFirebaseDoc(
    doc: firebase.firestore.QueryDocumentSnapshot
  ): Profile {
    const data = doc.data();
    const record: Profile = {
      id: doc.id,
      userId: doc.id,
      firstName: data.firstName,
      lastName: data.lastName,
      // TOODO pull in rest of record
    };

    Object.keys(data).map((key) => {
      // @ts-ignore
      record[key] = data[key];
    });

    return record;
  }

  protected buildRecordFromAirtableRecord(data: any) {
    // map to proper records
    return {};
  }

  protected onClickLogout(e: React.FormEvent) {
    firebase.auth().signOut();
  }
}

export default App;
