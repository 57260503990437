import React, { FC } from "react";
import { RouteComponentProps } from "react-router-dom";
import useOnboardRouteData from "./useOnboardRouteData";
import BasicOnboardPropertyDataSummary from "./BasicOnboardPropertyDataSummary";

export interface MyYardSummaryProps extends RouteComponentProps {}

const MyYardSummary: FC<MyYardSummaryProps> = (props) => {
  const [project, onboardData] = useOnboardRouteData();

  return (
    <BasicOnboardPropertyDataSummary
      pm
      p={2}
      alignItems="center"
      onboardData={onboardData}
      project={project}
    />
  );
};

export { MyYardSummary };
export default MyYardSummary;
