import React, { FC, ReactNode, useState, useEffect } from "react";
import { Box, Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import { useSkipMount } from "../../util/hooks";

export interface DesignBriefListItemMultipleChoiceComponentProps {
  value?: string;
  options: string[];
  update: (value: string) => void;
  pm: boolean;
  keyPrefix?: string;
}

const DesignBriefListItemMultipleChoiceComponent: FC<DesignBriefListItemMultipleChoiceComponentProps> =
  (props) => {
    const [selected, setSelected] = useState<string>(
      props.value ?? props.options[0]
    );

    const firstRunComplete = useSkipMount();

    useEffect(handleValueChange, [props.value]);
    useEffect(handleTextChange, [selected]);

    return (
      <Box display="flex" flexDirection="row" flexGrow={1}>
        {renderRadioButtons()}
      </Box>
    );

    function renderRadioButtons(): ReactNode {
      return (
        <RadioGroup
          value={selected}
          row
          onChange={({ target: { value } }) => setSelected(value)}
        >
          {props.options.map((o) =>
            renderRadioButton(o, !props.pm, props.keyPrefix ?? "")
          )}
        </RadioGroup>
      );
    }

    function handleValueChange(): void {
      if (props.value && props.value !== selected) {
        setSelected(props.value);
      }
    }

    function handleTextChange(): (() => void) | void {
      if (!firstRunComplete) {
        return void 0;
      }

      const timeout = setTimeout(async () => {
        await props.update(selected);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
  };

function renderRadioButton(
  value: string,
  disabled: boolean,
  keyPrefix = ""
): ReactNode {
  return (
    <FormControlLabel
      label={value}
      disabled={disabled}
      key={`${keyPrefix}_${value}`}
      value={value}
      control={<Radio />}
    ></FormControlLabel>
  );
}

export { DesignBriefListItemMultipleChoiceComponent };
export default DesignBriefListItemMultipleChoiceComponent;
