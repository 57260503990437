import * as React from "react";
import firebase from "firebase/compat/app";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import App from "./App";
import ClientDetail from "./ClientDetail";
import AccountRegistration from "./AccountRegistration";
import SignIn from "./Login";
import RecoverAccount from "./RecoverAccount";
import DesignProfiles from "./DesignProfiles";
import DesignProfileDetail from "./DesignProfileDetail";
import BriefMaterials from "./ClientDetail/QA/BriefMaterials";
import { UserCtx } from "./util/UserContext";
import ProfileCtx from "./util/ProfileContext";
import CollectProfile from "./CollectProfile";
import MapPage from "./MapPage";
import ProtectedRoute from "./util/ProtectedRoute";
import SharedMediaView from "./SharedMediaView";
import IndependantAnnotatorPage from "./IndependantAnnotatorPage";
import { useLogrocket } from "./util/hooks";

function AppRouter() {
  const userContext: any = React.useContext(UserCtx);
  const profileContext = React.useContext(ProfileCtx);

  useLogrocket();

  if (profileContext === false && !!userContext) {
    return <CollectProfile authUser={userContext} />;
  }

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/shared/:clientId" component={SharedMediaView} />
          <ProtectedRoute path="/client/:userId" component={ClientDetail} />
          <ProtectedRoute exact path="/old" component={AppComponent} />
          <Route path="/login" component={SignIn} />
          <Route path="/create-account" component={AccountRegistration} />
          <Route path="/recover-account" component={RecoverAccount} />
          <ProtectedRoute
            exact
            path="/designprofiles"
            component={DesignProfiles}
          />
          <ProtectedRoute
            path="/designprofiles/:profileId"
            component={DesignProfileDetail}
          />
          <ProtectedRoute
            path="/design-brief/:profileID/:revisionID"
            component={BriefMaterials}
          />
          {/* user id, then the lateset revision = 0, second latest = 1, and so on */}
          <ProtectedRoute
            path="/annotator/revisions/:profileID/:revisionNo"
            component={IndependantAnnotatorPage}
          />
          <Route
            exact
            path="/logout"
            render={() => {
              firebase.auth().signOut();
              return <Redirect to="/login" />;
            }}
          />
          <ProtectedRoute exact path="/map" component={MapPage} />
          <ProtectedRoute path="/" component={App} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

function AppComponent(route: any) {
  let searchText = "";
  let userId = "";
  if (
    route &&
    route.match &&
    route.match.params &&
    route.match.params.searchText
  ) {
    searchText = route.match.params.searchText;
  }

  if (route && route.match && route.match.params && route.match.params.userId) {
    userId = route.match.params.userId;
  }

  return (
    <App
      {...route}
      oldView
      searchText={searchText}
      userId={userId}
      history={route.history}
    />
  );
}

export default AppRouter;
