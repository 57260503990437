import React, { FC, forwardRef } from "react";
import { Fab } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { FabProps } from "@material-ui/core/Fab";

export interface ProjectManagerSelectFabProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  fabProps?: FabProps;
}

// @ts-ignore
export const ProjectManagerSelectFab: FC<ProjectManagerSelectFabProps> =
  forwardRef(({ onClick, fabProps, ...containerProps }, ref) => {
    return (
      <div {...containerProps} ref={ref}>
        <Fab onClick={onClick as any} {...fabProps}>
          <Person />
        </Fab>
      </div>
    );
  });

export default ProjectManagerSelectFab;
