import {
  INSERT_ASSIGNMENT_AND_METADATA_LINKS,
  INSERT_ASSIGNMENT_ID,
  QUERY_ASSIGNMENT_ALL_METADATA,
  ApolloClient,
} from "@yardzen-inc/graphql";
import {
  IAssignmentPlantBaseLink,
  IAssignmentProductBaseLink,
  IAssignmentMaterialBaseLink,
  IAssignmentSoftscapeItemLink,
} from "../Interfaces";

/** 
  This helper function is intended to be used when a PM opens a new revision, such as a V2 or a correction. 

   It:
   (1) Inserts the new assignment id into table `assignment`.
   (2) Copies over data from the following postgresql tables from a previous assignment to the current assignment:
      - assignment_product_base_links
      - assignment_material_base_links
      - assignment_plant_base_links
      - assignment_softscape_item_links

    If argument oldAssignmentId is null, it will only perform (1).
**/

const insertAssignmentMetadataInPostgres = async (
  oldAssignmentId: string | null,
  newAssignmentId: string,
  apolloClient: ApolloClient<any>
) => {
  if (!oldAssignmentId) {
    try {
      await apolloClient.mutate({
        mutation: INSERT_ASSIGNMENT_ID,
        variables: {
          id: newAssignmentId,
        },
        fetchPolicy: "network-only",
      });
    } catch (err) {
      throw new Error(`Failed to insert assignment.\n${err}`);
    }
  } else {
    const { data, error } = await apolloClient.query({
      query: QUERY_ASSIGNMENT_ALL_METADATA,
      variables: {
        id: oldAssignmentId,
      },
      fetchPolicy: "network-only",
    });

    if (error) {
      throw new Error(
        `Error fetching assignment metadata for carryover.\n${error.message}`
      );
    }

    const metadataToCarryOver = data.assignment_by_pk;

    if (!metadataToCarryOver) {
      throw new Error(
        `Error fetching assignment metadata for carryover\nAssignment not found`
      );
    }

    const products = metadataToCarryOver.assignment_product_base_links.map(
      (link: IAssignmentProductBaseLink) => {
        return {
          product_base_id: link.product_base_id,
          assignment_id: newAssignmentId,
        };
      }
    );
    const plants = metadataToCarryOver.assignment_plant_base_links.map(
      (link: IAssignmentPlantBaseLink) => {
        return {
          plant_base_id: link.plant_base_id,
          assignment_id: newAssignmentId,
        };
      }
    );
    const surfaces = metadataToCarryOver.assignment_material_base_links.map(
      (link: IAssignmentMaterialBaseLink) => {
        return {
          material_base_id: link.material_base_id,
          assignment_id: newAssignmentId,
          amount: link.amount,
        };
      }
    );
    const softscapeItems =
      metadataToCarryOver.assignment_softscape_item_links.map(
        (link: IAssignmentSoftscapeItemLink) => {
          return {
            softscape_item_id: link.softscape_item_id,
            assignment_id: newAssignmentId,
            quantity: link.quantity,
          };
        }
      );

    try {
      await apolloClient.mutate({
        mutation: INSERT_ASSIGNMENT_AND_METADATA_LINKS,
        variables: {
          assignment_id: newAssignmentId,
          products,
          plants,
          surfaces,
          softscapes: softscapeItems,
        },
        fetchPolicy: "network-only",
      });
    } catch (err) {
      throw new Error(
        `Failed to insert assignment and metadata in Postgres.\n${err}`
      );
    }
  }
};

export default insertAssignmentMetadataInPostgres;
