import * as React from "react";
import { CircularProgress, Box, Tooltip } from "@material-ui/core";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";

interface Props {
  status: boolean | "loading";
  onClick: () => void;
  message: string | null;
  agentCanShip: boolean;
}

export const SubmitButtonBar = (props: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      p={1}
      justifyContent="flex-end"
    >
      {props.message && (
        <Box mr={3}>
          <YZTypography variant="h5" color="error">
            {props.message}
          </YZTypography>
        </Box>
      )}
      <Tooltip
        disableHoverListener={!isDisabled()}
        title="Missing files or requirements"
      >
        <span style={{ display: "flex", flexDirection: "column" }}>
          <YZButton
            variant="contained"
            color="primary"
            onClick={props.onClick}
            disabled={isDisabled()}
            fullWidth
          >
            {renderButtonContent()}
          </YZButton>
          {isDisabled() && (
            <YZTypography color="error" variant="caption">
              Please verify all required fields and files are included!
            </YZTypography>
          )}
        </span>
      </Tooltip>
    </Box>
  );

  function renderButtonContent(): React.ReactNode {
    const loadSpinner = props.status === "loading";

    return (
      <>
        Submit {props.agentCanShip ? "& Ship" : ""} &nbsp;
        {loadSpinner ? <CircularProgress /> : null}
      </>
    );
  }

  function isDisabled(): boolean {
    return props.status === "loading" || props.status === false;
  }
};

export default SubmitButtonBar;
