import {
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { ErrorOutline, Send } from "@material-ui/icons";
import { YZTypography } from "@yardzen-inc/react-common";
import * as React from "react";

interface ISuggestedAddressListItemProps {
  setProfileAddress: (loc: google.maps.GeocoderResult) => void;
  geocodeResult: google.maps.GeocoderResult;
}

const useStyles = makeStyles(({ spacing }) => ({
  setAddressButton: {
    cursor: "pointer",
  },
  listItem: {
    padding: spacing(1),
  },
  chip: {
    marginLeft: spacing(1),
  },
}));

const SuggestedAddressListItem: React.FunctionComponent<ISuggestedAddressListItemProps> =
  ({ geocodeResult, setProfileAddress }) => {
    const { setAddressButton, listItem, chip } = useStyles();
    const [loading, setLoading] = React.useState(false);

    return (
      <List>
        <ListItem className={listItem} selected key={geocodeResult.place_id}>
          <YZTypography>{geocodeResult.formatted_address}</YZTypography>{" "}
          {geocodeResult.partial_match && (
            <Tooltip title="check for misspellings or multiple results">
              <Chip
                className={chip}
                avatar={<ErrorOutline />}
                label="Partial Match"
              />
            </Tooltip>
          )}
          <Tooltip title="Set as client address">
            <Box ml={1}>
              <IconButton
                className={setAddressButton}
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  setProfileAddress(geocodeResult);
                  setLoading(false);
                }}
              >
                <Send />
              </IconButton>
            </Box>
          </Tooltip>
        </ListItem>
      </List>
    );
  };

export { SuggestedAddressListItem };
