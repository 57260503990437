import React from "react";
import { YZButton } from "@yardzen-inc/react-common";

interface Props {
  back: () => void;
}

function AssetLibraryBack(props: Props) {
  return (
    <YZButton variant="outlined" size="small" onClick={props.back}>
      ← Back
    </YZButton>
  );
}

export default AssetLibraryBack;
