import * as React from "react";
import { Map, TileLayer } from "react-leaflet";
import { LatLngTuple } from "leaflet";

interface Props {
  markers: Array<any>;
}

function index(props: Props): React.ReactElement {
  const state = {
    lat: 37.855,
    lng: -122.485,
    zoom: 8,
  };
  const position: LatLngTuple = [state.lat, state.lng];

  return (
    <div style={{ width: "100%", height: "100%", display: "flex" }}>
      <Map
        style={{
          width: "95%",
          height: "85vh",
          margin: "auto",
          marginTop: "2rem",
        }}
        center={position}
        zoom={8}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {props.markers}
      </Map>
    </div>
  );
}

export default index;
