import {
  Box,
  Button,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, Folder } from "@material-ui/icons";
import React, { FC, ReactNode, useMemo, useState } from "react";
import { CreatePromotionalCodeDto } from "src/api/pangaeaTypes";
import BottomPaginationControl from "src/Components/BottomPaginationControl";
import usePagination from "src/util/hooks/usePagination";
import { PromotionalCodeCreateCard } from "./PromotionalCodeCreateCard";
import {
  promotionalCodeCreateToDefaultValues,
  defaultValuesToPromotionalCodeCreate,
} from "./util/promotionalCodeTransformers";
import { useFuzzySearch } from "../../util/useFuzzySearch";

const useStyles = makeStyles((theme) => ({
  helperText: {
    color: theme.palette.error.main,
    display: "flex",
    justifyContent: "center",
  },
}));

export interface PromotionalCodeCreatorTableProps {
  promotionalCodes: CreatePromotionalCodeDto[];
  handleUpdate: (
    code: CreatePromotionalCodeDto,
    originalCodeName?: string
  ) => void;
  handleDelete: (code: string) => void;
  handleCsvUpload: (file: File) => void;
  handleSubmit: () => void;
  products: { id: string; name: string }[];
}

// we really want this reference to be static to avoid rebuilding the
// fuse search index every time the component is rendered
const searchKeys = ["code"];

export const PromotionalCodeCreatorTable: FC<PromotionalCodeCreatorTableProps> =
  (props) => {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState("");
    const promotionalCodes = useFuzzySearch({
      collection: props.promotionalCodes,
      term: searchTerm,
      keys: searchKeys,
    });

    const [editing, setEditing] = useState<string | null>(null);
    const {
      currentData,
      jump: _jump,
      currentPage,
      maxPage,
    } = usePagination(promotionalCodes, 20);

    const jump = (page: number) => {
      setEditing(null);
      _jump(page);
      window.scroll(0, 0);
    };

    const cards = useMemo(() => {
      const cards: ReactNode[] = [];

      for (const promotionalCode of currentData()) {
        cards.push(
          <PromotionalCodeCreateCard
            defaultValues={promotionalCodeCreateToDefaultValues(
              promotionalCode
            )}
            key={promotionalCode.code}
            handleDelete={() => props.handleDelete(promotionalCode.code)}
            handleClick={() => setEditing(promotionalCode.code)}
            handleSubmit={(values) => {
              props.handleUpdate(
                defaultValuesToPromotionalCodeCreate(values),
                promotionalCode.code
              );
              setEditing(null);
            }}
            disabled={editing !== null && editing !== promotionalCode.code}
            editing={editing === promotionalCode.code}
            products={props.products}
          />
        );
      }
      return cards;
    }, [currentData, editing, props.handleUpdate]);

    return (
      <Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Box p={1}>
            <Tooltip title="Add New Promotional code">
              <IconButton
                onClick={() => {
                  props.handleUpdate({
                    code: Math.floor(Math.random() * 1000000)
                      .toString(16)
                      .toUpperCase(),
                    maxRedemptions: 100,
                    discount: "100",
                    deactivated: false,
                    endTime: new Date().toISOString(),
                    startTime: new Date().toISOString(),
                  });
                  setSearchTerm("");
                  if (currentPage !== 1) {
                    setImmediate(() => jump(1));
                  }
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Box>
          <Box p={1}>
            <Tooltip title="Upload From CSV">
              <IconButton
                onClick={() => {
                  const input = document.getElementById(
                    "bulk-promotional-code-csv-upload-input"
                  );
                  if (input) {
                    input.click();
                  } else {
                    console.error("Could not find hidden input element");
                  }
                }}
              >
                <Folder />
              </IconButton>
            </Tooltip>
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                if (e.target.files) {
                  props.handleCsvUpload(
                    e.target.files[e.target.files.length - 1]
                  );
                }
              }}
              id="bulk-promotional-code-csv-upload-input"
            />
          </Box>
          <Box p={2}>
            <Button
              onClick={() => {
                setSearchTerm("");
                props.handleSubmit();
              }}
              variant="outlined"
              disabled={!props.promotionalCodes.length}
            >
              CREATE PROMOTIONAL CODES
            </Button>
            {props.promotionalCodes.length > 0 && (
              <Typography variant="caption" className={classes.helperText}>
                Promo codes are not saved until you click the button above
              </Typography>
            )}
          </Box>
        </Box>
        {!!props.promotionalCodes.length && (
          <Box p={1}>
            <TextField
              value={searchTerm}
              disabled={editing !== null}
              fullWidth
              placeholder="Search"
              onChange={({ currentTarget: { value } }) => setSearchTerm(value)}
            />
          </Box>
        )}
        <Box>{cards}</Box>
        <Box display={"inline-flex"} minHeight="20px"></Box>
        <Box position={"fixed"}>
          <BottomPaginationControl
            onChange={(_, page) => jump(page)}
            size="large"
            page={currentPage}
            count={maxPage}
            styles={{
              position: "fixed",
              left: 239,
              width: "calc(100vw - 239px)",
              backgroundColor: "whitesmoke",
            }}
          />
        </Box>
      </Box>
    );
  };
