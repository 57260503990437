import * as React from "react";
import {
  Typography,
  Theme,
  Paper,
  TextField,
  Select,
  MenuItem,
  Divider,
  FormControlLabel,
  Checkbox,
  Dialog,
  LinearProgress,
} from "@material-ui/core";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/styles";
import {
  Profile,
  Project,
  AssignmentType,
  Revision,
} from "@yardzen-inc/models";
import { getRevisionName } from "../Revisions/RevisionList";
import { YZButton } from "@yardzen-inc/react-common";
import { UserCtx } from "../../util/UserContext";
import { updateRevisionIssuedBy } from "../../util/firebase/firebaseClient";

export interface newAssignData {
  notes: string;
  type: AssignmentType;
  title: string;
  mediaTags: any;
  listed: boolean;
}

interface Props {
  clientRecord: Profile;
  project: Project;
  createAssignment: (
    type: string,
    data: newAssignData,
    selectedRevision?: string
  ) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "35%",
    minWidth: "350px",
    maxWidth: "100vw",
    display: "flex",
    justifyContent: "space-around",
  },
  title: {
    lineHeight: "40px",
  },
  icon: {
    width: "40px",
    height: "40px",
    color: theme.palette.secondary.main,
  },
  paper: {
    padding: "1.25rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    textAlign: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
  },
}));

export default function StartCreateAssignment({
  project,
  clientRecord,
  createAssignment,
}: Props): React.ReactElement {
  const classes = useStyles();
  const [openDetail, setOpenDetail]: [boolean, (openDetail: boolean) => void] =
    React.useState<boolean>(false);
  const [listed, setListed] = React.useState<boolean>(true);
  const [notes, setNotes] = React.useState("");
  const [type, setType]: [string, (type: string) => void] =
    React.useState<string>("");
  const [title, setTitle] = React.useState("");
  const [selectedRevision, setSelectedRevision] = React.useState<string | null>(
    null
  );
  const [revisions, setRevisions] = React.useState<Revision[] | null>(null);
  const user = React.useContext(UserCtx);

  React.useEffect(() => {
    if (!openDetail) {
      setListed(true);
      setNotes("");
      setType("");
      setTitle("");
    }
  }, [openDetail]);

  React.useEffect(() => {
    if (type === "design") {
      getRevisions();
    }
  }, [type]);

  if (!openDetail) {
    return (
      <YZButton
        onClick={() => setOpenDetail(true)}
        variant="outlined"
        className={classes.root}
      >
        <AddCircleOutline className={classes.icon} />
        <Typography className={classes.title} variant="h6">
          Create New Assignment
        </Typography>
      </YZButton>
    );
  }

  return (
    <Dialog onClose={() => setOpenDetail(false)} open={openDetail}>
      <Paper elevation={6} className={classes.paper}>
        <Typography style={{ textAlign: "left" }} variant="h5">
          Create Assignment
        </Typography>
        <Divider />
        <div className={classes.container}>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography variant="h6">New </Typography>
            <Select
              value={type}
              onChange={(e: any) => setType(e.target.value)}
              style={{ margin: "0 0.5rem 0 0.5rem" }}
              displayEmpty
            >
              <MenuItem disabled value="">
                <em>Type</em>
              </MenuItem>
              <MenuItem value="modeling">Modeling</MenuItem>
              <MenuItem value="design">Design</MenuItem>
              <MenuItem value="wesley">Function & Flow</MenuItem>
            </Select>
            <Typography variant="h6">assignment</Typography>
          </div>
          <TextField
            style={{ marginTop: "1rem" }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            label="Assignment title"
            variant="outlined"
          />
          <Typography style={{ marginTop: "1.25rem" }} variant="h6">
            Is there anything special you'd like to add?
          </Typography>
          <TextField
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Notes"
            variant="outlined"
            multiline
            rows={4}
          />
          {renderRevisionOptions()}

          <Divider />
          <FormControlLabel
            label="Initially listed for assignment"
            control={
              <Checkbox checked={listed} onChange={() => setListed(!listed)} />
            }
          />
          <YZButton
            disabled={
              type === "" ||
              title === "" ||
              (type === "design" && !selectedRevision)
            }
            style={{ marginTop: "1.5rem" }}
            onClick={() => {
              createAssignment(
                project.id,
                {
                  type: type as AssignmentType,
                  notes,
                  title,
                  listed,
                  mediaTags: [],
                },
                type === "design" ? selectedRevision || undefined : undefined
              );
              setOpenDetail(false);
            }}
            variant="contained"
            color="primary"
          >
            Create Assignment
          </YZButton>
        </div>
      </Paper>
    </Dialog>
  );

  async function getRevisions() {
    setRevisions(await Revision.getSortedRevisions(project.profileId));
  }

  function renderRevisionOptions(): React.ReactNode | null {
    if (type !== "design") {
      return null;
    }

    if (!revisions) {
      return <LinearProgress variant="indeterminate" />;
    }

    if (!revisions.length) {
      return (
        <>
          <Typography variant="body1">No Revisions to list</Typography>
          {renderCreateRevisionButton()}
        </>
      );
    }

    console.log(revisions);

    return (
      <>
        <Divider />
        <Typography variant="h5" align="center">
          Select Revision
        </Typography>
        {revisions.map((r, i) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={!!(selectedRevision && selectedRevision === r.id)}
                onClick={() => setSelectedRevision(r.id)}
                style={{ pointerEvents: "all" }}
              />
            }
            label={getRevisionName(i)}
          />
        ))}
        {renderCreateRevisionButton()}
      </>
    );
  }

  function renderCreateRevisionButton(): React.ReactNode {
    return (
      <YZButton variant="text" onClick={createRevision}>
        Create New Revision
      </YZButton>
    );
  }

  async function createRevision() {
    setRevisions(null);
    const revision = await Revision.create(project.profileId, project.id);
    if (user) updateRevisionIssuedBy(revision, user);
    return getRevisions();
  }
}
