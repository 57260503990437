import * as React from "react";
import {
  Paper,
  Typography,
  Divider,
  Avatar,
  Box,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { Agent, Assignment } from "@yardzen-inc/models";
import ConfirmAgentAccountStatusSet from "../ConfirmAgentAccountStatusSet";
import { PersonAddDisabled } from "@material-ui/icons";
import { AgentGroup } from "@yardzen-inc/util";
import { useInView } from "react-intersection-observer";
import { YZButton } from "@yardzen-inc/react-common";
import { UpdateAgentModal } from "./UpdateAgentModal";

interface Props {
  agent: Agent;
  unpaid?: number;
  groupMap?: {
    [id: string]: AgentGroup;
  };
  groups?: (AgentGroup & {
    id: string;
  })[];
}

function AgentCard({
  agent,
  groupMap,
  groups,
  unpaid,
}: Props): React.ReactElement {
  const agentGroup =
    agent.groupId && groupMap ? groupMap[agent.groupId] : undefined;
  const classes = useStyles();
  const [currentAssignment, setCurrentAssignment] = React.useState<
    Assignment | null | "none"
  >(null);

  const [openUpdateStatusModal, setOpenUpdateStatusModal] =
    React.useState<boolean>(false);
  const [openUpdatePropertiesModal, setOpenUpdatePropertiesModal] =
    React.useState<boolean>(false);
  const { ref, inView } = useInView();
  const isDesigner = agent.type === "design";
  React.useEffect(() => {
    if (!agent || !inView) return;

    (async function () {
      let assignment: Assignment | undefined;

      (await agent.getAssignments()).forEach((a) => {
        if (!assignment) {
          assignment = a;
        } else if (
          a.assignedAt &&
          assignment &&
          a.assignedAt > ((assignment as Assignment).assignedAt as number)
        ) {
          assignment = a;
        }
      });

      if (assignment) {
        setCurrentAssignment(assignment);
      } else setCurrentAssignment("none");
    })();

    return () => {
      if (agent) {
        agent.destroy;
      }
    };
  }, [inView, agent]);

  const cardAssignmentPartial = () => {
    if (!currentAssignment) {
      return <Typography variant="body1">Loading...</Typography>;
    } else if (currentAssignment === "none") {
      return <Typography variant="body1">No current assignment</Typography>;
    } else {
      const date = new Date(currentAssignment.assignedAt as number);
      return (
        <div className={classes.detail}>
          <Typography>Current project: {currentAssignment.title}</Typography>
          <Typography>
            Assigned:{" "}
            {`${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`}
          </Typography>
        </div>
      );
    }
  };

  if (!agent) return <div></div>;

  return (
    <>
      {groups && (
        <UpdateAgentModal
          open={openUpdatePropertiesModal}
          onClose={() => setOpenUpdatePropertiesModal(false)}
          agent={agent}
          groups={groups}
        />
      )}

      {openUpdateStatusModal && (
        <ConfirmAgentAccountStatusSet
          agent={agent}
          onClose={() => setOpenUpdateStatusModal(false)}
        />
      )}
      <Paper ref={ref} elevation={6} className={classes.root}>
        {unpaid && (
          <Avatar
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "red",
            }}
          >
            {unpaid.toString()}
          </Avatar>
        )}
        <div>
          <Box display="flex" justifyContent="space-between">
            <div>
              <Typography variant="h6">{`${agent.firstName} ${agent.lastName}`}</Typography>
              <Typography variant="body1">{agent.email}</Typography>
              <Typography variant="overline">
                <em>{agent.type}</em>
              </Typography>
            </div>
            <div>
              {!!agent.disabled && (
                <Tooltip title="Agent is disabled">
                  <PersonAddDisabled
                    style={{
                      color: "red",
                      width: "35px",
                      height: "35px",
                      marginLeft: "0.75rem",
                    }}
                  />
                </Tooltip>
              )}
            </div>
          </Box>
          <Divider />
          <Typography>
            Group name: {agentGroup ? agentGroup.identifier : "No group"}
          </Typography>
          {isDesigner && (
            <Typography>Agent Tier: {agent.tier ?? "<unassigned>"}</Typography>
          )}

          <Typography>
            Qualifications:{" "}
            {!agent.qualifications || !agent.qualifications.length
              ? "<none>"
              : agent.qualifications.join(", ")}
          </Typography>

          {cardAssignmentPartial()}
        </div>
        <div className={classes.actions}>
          <YZButton
            style={{ color: agent.disabled ? "green" : "red" }}
            onClick={() => setOpenUpdateStatusModal(true)}
          >
            {agent.disabled ? "Recommission" : "Decommission"}
          </YZButton>
          <YZButton onClick={() => setOpenUpdatePropertiesModal(true)}>
            Change Agent Properties
          </YZButton>
        </div>
      </Paper>
    </>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    margin: spacing(2),
    width: "30%",
    padding: spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  detail: {
    margin: spacing(1),
  },
  current: {
    width: "33%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  actions: {
    position: "relative",
    bottom: 0,
    paddingTop: spacing(1),
    justifySelf: "flex-end",
    display: "flex",
    justifyContent: "space-between",
  },
}));

export { AgentCard };
