import React, { FC } from "react";
import { AgentGroup } from "@yardzen-inc/util";
import { Box, Typography } from "@material-ui/core";
import { YZButton } from "@yardzen-inc/react-common";

export interface AgentGroupListItemProps extends AgentGroup {
  id: string;
  onToggleActive: (id: string) => void;
  onDelete: (id: string) => void;
}

const AgentGroupListItem: FC<AgentGroupListItemProps> = ({
  id,
  identifier,
  name,
  active,
  onToggleActive,
  onDelete,
}) => {
  return (
    <Box width="100%" p={1}>
      <Box
        width="100%"
        p={1}
        boxShadow={2}
        display="flex"
        justifyContent="space-between"
      >
        <Box p={1}>
          <Box pb={1}>
            <Typography variant={"h3"}>{name}</Typography>
          </Box>
          <Typography>{identifier}</Typography>
        </Box>
        <Box p={1} display="flex" flexDirection="column">
          <YZButton onClick={() => onToggleActive(id)}>
            {active ? "Disable Group" : "Enable Group"}
          </YZButton>
          <YZButton onClick={() => onDelete(id)}>Delete group</YZButton>
        </Box>
      </Box>
    </Box>
  );
};

export { AgentGroupListItem };
export default AgentGroupListItem;
