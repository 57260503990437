import React, { FC, Fragment, useEffect, useState } from "react";

// Import MUI Components
import { makeStyles } from "@material-ui/core/styles";
import {
  Collapse,
  Box,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Theme,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import SettingsIcon from "@material-ui/icons/Settings";
import DeleteIcon from "@material-ui/icons/Delete";
import { AgentPods, Agent } from "./types";
import AgentRow from "./AgentRow";
import { getAllDesignersByDesignerPodId } from "./apiCalls";
import DeletePodModal from "./DeletePodModal";
import EditPodDetailsModal from "./EditPodDetailsModal";
import TableSkeletonLoadRow from "./TableSkeletonLoadRow";

export interface AgentPodsTableRowProps {
  rowData: AgentPods;
  checkedAgents: string[];
  onSubmit: () => void;
  setAllSelectedAgent: (agentArray: string[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  style: {},
  progressContainer: {
    marginTop: 20,
  },
  errorMessage: {
    paddingLeft: 16,
    paddingTop: 6,
    paddingBottom: 6,
  },
  deleteIconButton: {
    marginLeft: 10,
  },
  deleteIcon: {
    color: "#c4c2c2",
  },
  settingsIcon: {
    color: "grey",
  },
}));

const AgentPodsTableRow: FC<AgentPodsTableRowProps> = ({
  rowData,
  checkedAgents,
  setAllSelectedAgent,
  onSubmit,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [deletePodModalOpen, setDeletePodModalOpen] = useState<boolean>(false);
  const [editPodModalOpen, setEditPodModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!open || agents.length) return;
    async function fetchData() {
      try {
        const response = await getAllDesignersByDesignerPodId(rowData.id);
        setAgents(response);
      } catch (err) {
        parseErrors(err);
      }
      setLoading(false);
    }
    fetchData();
  }, [open, agents.length]);

  return (
    <Fragment>
      <TableRow key={rowData.name} className={classes.style}>
        <TableCell width="12%">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleOpenDropdownClick}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" width="39%">
          {rowData.name}
        </TableCell>
        <TableCell align="left" width="30%">
          {rowData.pmName}
        </TableCell>
        <TableCell>
          {rowData.name !== "Unassigned" && (
            <>
              <Tooltip title="Edit Team Name or PM">
                <IconButton
                  aria-label="dropdown-button"
                  size="small"
                  onClick={() => setEditPodModalOpen(true)}
                >
                  <SettingsIcon className={classes.settingsIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Team">
                <IconButton
                  className={classes.deleteIconButton}
                  aria-label="dropdown-button"
                  size="small"
                  onClick={() => setDeletePodModalOpen(true)}
                >
                  <DeleteIcon className={classes.deleteIcon} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={3} bgcolor="#fafafa">
              <Table size="small" aria-label="purchases">
                {renderLoading()}
                {renderError()}
                {renderAgents()}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <DeletePodModal
        open={deletePodModalOpen}
        onClose={() => setDeletePodModalOpen(false)}
        onDeleteSubmit={onSubmit}
        podData={rowData}
      />
      <EditPodDetailsModal
        open={editPodModalOpen}
        onClose={() => setEditPodModalOpen(false)}
        onEditPodSubmit={onSubmit}
        podData={rowData}
      />
    </Fragment>
  );

  function handleAgentSelect(id: string) {
    const selected = [...checkedAgents];
    const index = checkedAgents.indexOf(id);

    if (index > -1) {
      selected.splice(index, 1);
      setAllSelectedAgent(selected);
    } else {
      setAllSelectedAgent([...checkedAgents, id]);
    }
  }

  function renderLoading() {
    if (loading) {
      return <TableSkeletonLoadRow rows={3} rowHeight={30} />;
    }
    return;
  }

  function renderAgents() {
    if (agents && agents.length === 0 && !loading) {
      return (
        <YZTypography className={classes.errorMessage}>
          No agents found
        </YZTypography>
      );
    }
    if (agents && agents.length > 0) {
      return (
        <>
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Edit Agent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agents.map((agent: Agent) => (
              <AgentRow
                key={`agent-row-${agent.id}`}
                agent={agent}
                setSelectedAgents={handleAgentSelect}
                isItemSelected={checkedAgents.includes(agent.id)}
              />
            ))}
          </TableBody>
        </>
      );
    }
    return;
  }

  function renderError() {
    if (error) {
      return (
        <YZTypography className={classes.errorMessage}>
          Internal error. Please try refreshing the page. If the issue persists,
          contact engineering.
        </YZTypography>
      );
    }
    return;
  }

  function parseErrors(err: any): void {
    if (err.details?.code === "000012") {
      setAgents([]);
    } else {
      setError(true);
    }
  }

  function handleOpenDropdownClick() {
    setOpen(!open);
  }
};

export { AgentPodsTableRow };
export default AgentPodsTableRow;
