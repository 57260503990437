import * as React from "react";
import {
  Box,
  Paper,
  makeStyles,
  Avatar,
  Typography,
  Divider,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { ProjectManager, Media } from "@yardzen-inc/models";

export interface PmListItemProps {
  pm: ProjectManager;
  isUser?: boolean;
  onDelete?: () => void;
  setAsMe?: () => void;
  setAsNotMe?: () => void;
}

const useStyles = makeStyles({
  paper: {
    display: "flex",
    flexFlow: "row nowrap",
    marginBottom: "12px",
  },
  root: {},
  avatar: {
    height: "100px",
    width: "100px",
  },
});

export const PmListItem = (props: PmListItemProps) => {
  const classes = useStyles();
  const [imageUrl, setImageUrl] = React.useState<string | null>(null);

  React.useEffect(setPortraitDownloadUrl, []);

  return (
    <Paper className={classes.paper} classes={{ root: classes.root }}>
      <Box display="flex" flexDirection="row" p={1} width={"100%"}>
        <Box
          p={1}
          pr={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {renderAvatar()}
        </Box>
        <Box flexGrow={1} display="flex" flexDirection="column">
          {renderInfo()}
          <Divider></Divider>
          {renderButtons()}
        </Box>
        <Box></Box>
      </Box>
    </Paper>
  );

  function renderInfo(): React.ReactNode {
    return (
      <>
        <Box>
          {props.setAsNotMe && (
            <Typography
              gutterBottom
              variant="caption"
              style={{ color: "red" }}
            >{`This is you!`}</Typography>
          )}
          <Typography
            gutterBottom
            variant="h4"
          >{`${props.pm.firstName} ${props.pm.lastName} - ${props.pm.email}`}</Typography>
        </Box>
      </>
    );
  }

  function renderAvatar(): React.ReactNode {
    if (imageUrl) {
      return <Avatar src={imageUrl} className={classes.avatar} />;
    }

    return (
      <Avatar className={classes.avatar}>
        {props.pm.firstName[0] + props.pm.lastName[0]}
      </Avatar>
    );
  }

  function renderButtons(): React.ReactNode {
    return (
      <Box display="flex" flexDirection={"row"} justifyContent="flex-end">
        <Box p={1}>
          <ButtonGroup variant="contained" color="primary">
            {props.onDelete && <Button onClick={props.onDelete}>delete</Button>}
            {props.setAsMe && (
              <Button onClick={props.setAsMe}>this is me</Button>
            )}
            {props.setAsNotMe && (
              <Button onClick={props.setAsNotMe}>this is NOT me</Button>
            )}
          </ButtonGroup>
        </Box>
      </Box>
    );
  }

  function setPortraitDownloadUrl(): void {
    (async function () {
      const media = await Media.fetchSingle(props.pm.pictureMediaId);
      if (!media) {
        throw new Error("Could not find Project manager headshot");
      }

      setImageUrl(media.downloadURL);
    })();
  }
};
