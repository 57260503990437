import * as React from "react";
import { Button, Paper } from "@material-ui/core";

interface ComponentProperty {
  message: string;
  buttonName: string;
  onClickHandler: () => void;
}

export default function DeprecatingButtonComponent(prop: ComponentProperty) {
  const paperStyle: React.CSSProperties = {
    padding: 20,
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    fontSize: "1.1rem",
    textAlign: "center",
    marginTop: 10,
  };

  return (
    <Paper style={paperStyle}>
      <div>
        <p>
          {prop.message}
          <Button onClick={prop.onClickHandler}>{prop.buttonName}</Button>
        </p>
      </div>
    </Paper>
  );
}
