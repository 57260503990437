import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FilledInput,
  FormControl,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import debounce from "lodash/debounce";
import { YZTypography } from "@yardzen-inc/react-common";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formControls: {
    display: "flex",
  },
}));

interface State {
  min: string;
  max: string;
}

interface Props {
  onChange: (query: string, data: string) => any;
  shouldDisplay: boolean;
  minPrice: null | number;
  maxPrice: null | number;
}

function ProductPriceFilter(props: Props) {
  const classes = useStyles();

  const [values, setValues] = React.useState<State>({
    min: props.minPrice?.toString() ?? "",
    max: props.maxPrice?.toString() ?? "",
  });

  const sendQuery = (query: string, prop: keyof State) => {
    props.onChange(query, prop);
  };

  const delayedQuery = debounce((q, prop) => sendQuery(q, prop), 600);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
      delayedQuery(event.target.value ?? "0", prop);
    };

  if (!props.shouldDisplay) {
    return <div />;
  }

  return (
    <div className={classes.root}>
      <div>
        <YZTypography variant="h6" type="serif">
          Filter by Price:
        </YZTypography>
        <div className={classes.formControls}>
          <FormControl
            fullWidth
            className={classes.formControl}
            variant="filled"
          >
            <InputLabel htmlFor="filled-adornment-amount">Min.</InputLabel>
            <FilledInput
              id="filled-adornment-amount"
              value={values.min}
              onChange={handleChange("min")}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
          <FormControl
            fullWidth
            className={classes.formControl}
            variant="filled"
          >
            <InputLabel htmlFor="filled-adornment-amount">Max.</InputLabel>
            <FilledInput
              id="filled-adornment-amount"
              value={values.max}
              onChange={handleChange("max")}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default ProductPriceFilter;
