import React, { FC, useMemo } from "react";
import { RouteComponentProps, Switch, Route } from "react-router-dom";
import { QaQueue } from "./QaQueue";
import { QaTabProvider } from "./QaTabContext";
import ProjectManagerSelectModal from "./ProjectManagerSelectModal";

export interface QAPageProps extends RouteComponentProps {}

const QAPage: FC<QAPageProps> = (props) => {
  const pmId = useMemo(getProjectManagerFromSearchParams, [props.location]);

  return (
    <>
      <ProjectManagerSelectModal
        buttonContainerProps={{
          style: { position: "fixed", top: "4rem", right: 15 },
        }}
        pmId={pmId}
        setPmId={setProjectManagerInSearchParams}
      ></ProjectManagerSelectModal>
      <QaTabProvider projectManagerId={pmId || void 0}>
        <Switch>
          <Route component={QaQueue}></Route>
        </Switch>
      </QaTabProvider>
    </>
  );

  function getProjectManagerFromSearchParams() {
    const searchParams = new URLSearchParams(props.location.search);
    let _pmId = searchParams.get("pmid");

    if (_pmId === "null") _pmId = null;

    return _pmId ?? "";
  }

  function setProjectManagerInSearchParams(pmId: string | null): void {
    const params = new URLSearchParams(props.location.search);

    params.set("pmid", pmId ?? "null");

    const searchString = params.toString();

    return props.history.push(
      !!searchString
        ? `${props.location.pathname}?${searchString}`
        : props.location.pathname
    );
  }
};

export { QAPage };
export default QAPage;
