import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { FormikProps } from "formik";
import React, { FC, MouseEvent } from "react";
import {
  PromotionalCodeCreateFormController,
  PromotionalCodeCreateFormControllerProps,
} from "./PromotionalCodeCreateFormController";
import { PromotionalCodeCreateFormFields } from "./PromotionalCodeCreateFormFields";

export interface PromotionalCodeCreateCardProps
  extends Omit<PromotionalCodeCreateFormControllerProps, "children"> {
  defaultValues: PromotionalCodeCreateFormControllerProps["defaultValues"];
  disabled: boolean;
  editing: boolean;
  handleDelete: () => void;
  handleClick: (ev: MouseEvent, codeName: string) => void;
  handleSubmit: (
    values: PromotionalCodeCreateFormControllerProps["defaultValues"]
  ) => void;
  products: { id: string; name: string }[];
}

export const PromotionalCodeCreateCard: FC<PromotionalCodeCreateCardProps> = (
  props
) => {
  if (!props.editing) {
    return (
      <Box p={1} component="li" position="relative">
        <Card>
          <CardActionArea
            onClick={(e) => props.handleClick(e, props.defaultValues.code)}
            disabled={props.disabled}
          >
            <CardContent>
              <PromotionalCodeCreateFormController
                editing={props.editing}
                defaultValues={props.defaultValues}
                handleSubmit={() => {}}
              >
                {(
                  formikProps: FormikProps<
                    PromotionalCodeCreateCardProps["defaultValues"]
                  >
                ) => (
                  <PromotionalCodeCreateFormFields
                    {...formikProps}
                    disabled={true}
                  />
                )}
              </PromotionalCodeCreateFormController>
            </CardContent>
          </CardActionArea>
        </Card>
        <Box
          width="fit-content"
          position="absolute"
          top="0px"
          right="0px"
          p={1}
        >
          <IconButton onClick={props.handleDelete}>
            <Delete></Delete>
          </IconButton>
        </Box>
      </Box>
    );
  }
  return (
    <Box p={1}>
      <Card>
        <CardContent>
          <PromotionalCodeCreateFormController
            editing={props.editing}
            defaultValues={props.defaultValues}
            handleSubmit={props.handleSubmit}
          >
            {(
              formikProps: FormikProps<
                PromotionalCodeCreateCardProps["defaultValues"]
              >
            ) => (
              <PromotionalCodeCreateFormFields
                {...formikProps}
                disabled={formikProps.isSubmitting || props.disabled}
                products={props.products}
              />
            )}
          </PromotionalCodeCreateFormController>
        </CardContent>
      </Card>
    </Box>
  );
};
