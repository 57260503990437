import React, { FC, ReactNode, useEffect, useState } from "react";
import { useProjectsFromUserId } from "../../util/hooks";
import useSelectedColorsForProject, {
  ColorResponseMap,
  UseSelectedColorsForProject,
} from "../../util/hooks/useSelectedColorsForProject";
import SnackbarError from "../../Components/SnackbarError";
import { Box, LinearProgress } from "@material-ui/core";
import ColorResponseList from "../../Components/ColorResponseList";
import { YZTypography } from "@yardzen-inc/react-common";
import { Profile } from "@yardzen-inc/models";

export interface PaintSelectionTabProps {
  profile: Profile;
}

const _responseMapCache: Map<string, ColorResponseMap> = new Map();

const PaintSelectionTab: FC<PaintSelectionTabProps> = (props) => {
  const projects = useProjectsFromUserId(props.profile.id);

  /*
    repsonseQueryOptions is static now but can be used for pagination in
    the future if necessary
   */
  const [responseQueryOptions] = useState<
    Parameters<UseSelectedColorsForProject>[1]
  >({ limit: 100, offset: 0 });

  const [colorResponseData, error] = useSelectedColorsForProject(
    projects?.[0]?.id,
    responseQueryOptions
  );
  const [errorSnackbarContent, setErrorSnackbarContent] = useState<
    string | null
  >(error?.humanReadableMessage ?? null);

  useEffect(storeColorResponseDataInInstanceCache, [colorResponseData]);
  useEffect(handleError, [error]);

  if (!props.profile.exteriorPaintDesired) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <YZTypography variant="h4" style={{ width: "fit-content" }}>
          {props.profile.exteriorPaintDesired === false
            ? "Client has explicitly opted out of choosing paint"
            : "Client has not selected paint colors but also hasn't explicitly opted out"}
        </YZTypography>
      </Box>
    );
  }

  return (
    <>
      <Box display="flex" flexDirection="column" width="100%" py={1}>
        {renderLoading()}
        {renderResponseList()}
      </Box>
      {renderErrorSnackbar()}
    </>
  );

  function renderResponseList(): ReactNode {
    if (colorResponseData || _responseMapCache.has(props.profile.id)) {
      return (
        <ColorResponseList
          colorResponseMap={
            colorResponseData ??
            (_responseMapCache.get(props.profile.id) as ColorResponseMap)
          }
        />
      );
    }

    return null;
  }

  function renderLoading(): ReactNode {
    if (!colorResponseData) {
      return (
        <Box display="flex" width="100%" p={2}>
          <LinearProgress variant="indeterminate" style={{ flexGrow: 1 }} />
        </Box>
      );
    }

    return null;
  }

  function renderErrorSnackbar(): ReactNode {
    if (errorSnackbarContent) {
      return (
        <SnackbarError
          errorText={errorSnackbarContent}
          handleSnackbarClose={() => setErrorSnackbarContent(null)}
        />
      );
    }

    return null;
  }

  function storeColorResponseDataInInstanceCache() {
    if (colorResponseData) {
      _responseMapCache.set(props.profile.id, colorResponseData);
    }
  }

  function handleError() {
    if (error) {
      console.error(error);
      setErrorSnackbarContent(error.humanReadableMessage);
    }
  }
};

export { PaintSelectionTab };
export default PaintSelectionTab;
