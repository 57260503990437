import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  makeStyles,
  Divider,
  Typography,
  Box,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  IconButton,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import { DesignProfileState } from "./types";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Profile, ProfileProperties } from "@yardzen-inc/models";
import moment from "moment";
import { Phone, PhoneDisabled, Map } from "@material-ui/icons";
import googleMapSearch from "../../util/googleMapSearch";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    padding: "1rem",
  },
  col: {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      maxWidth: "50%",
    },
  },
  row: {
    display: "flex",
    flexFlow: "row nowrap",
  },
}));

interface Props {
  record: DesignProfileState;
  onClick: () => void;
}

interface CalendlyEvent {
  [key: string]: any;
}

export default ({ record, onClick }: Props) => {
  const classes = useStyles();

  const initialOnContactRun = useRef<boolean>(false);

  const [contacted, setContacted] = useState<boolean>(!!record.contacted);
  const [profile, setProfile] = useState<Profile | null | false>(null);
  const [purchased, setPurchased] = useState<boolean | null>(null);
  const [calendlyEvent, setCalendlyEvent] = useState<
    CalendlyEvent | null | false
  >(null);

  const quizAnsers = useMemo(renderQuizAnswers, [record.id]);

  const textBody = useMemo(() => {
    return `Hi ${
      record?.contactInformation?.firstName
        ? record?.contactInformation?.firstName
        : ""
    }! This is Taylor from Yardzen. Thanks for reaching out about your landscaping project! To learn more about our process, please visit yardzen.com/how-it-works. To purchase a package, please visit yardzen.com/pricing-packages.

    To speak to someone directly, please email hello@yardzen.com or schedule a project consult at yardzen.com/schedule.`;
  }, [record?.contactInformation?.firstName]);

  useEffect(onContactedChange, [contacted]);
  useEffect(listenForProfile, [record.userId, purchased]);
  useEffect(listenForCalendly, [
    record.contactInformation.email,
    calendlyEvent,
  ]);
  useEffect(listenForOrders, [
    purchased,
    record.contactInformation.email,
    record.contactInformation.phoneNumber,
  ]);

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        className={classes.row}
      >
        <div className={classes.col}>
          <Typography variant="caption">Name</Typography>
          <Typography variant="body1">{getName()}</Typography>
        </div>
        <div
          className={classes.col}
          style={{ flexDirection: "row", flexGrow: 0 }}
        >
          {renderCallStatus(calendlyEvent)}{" "}
          <Box px={1}>
            <IconButton
              disabled={!record.contactInformation.formattedAddress}
              onClick={() =>
                window.open(
                  googleMapSearch(
                    record.contactInformation.formattedAddress ?? ""
                  ),
                  "_blank"
                )
              }
            >
              <Map></Map>
            </IconButton>
          </Box>
        </div>
      </div>
      <br />
      <div className={classes.row}>
        <div className={classes.col}>
          <Typography variant="caption">Address</Typography>
          <Typography variant="body1">
            {record.contactInformation.formattedAddress ?? "Not Provided"}
          </Typography>
        </div>
        <div className={classes.col}>
          <Typography variant="caption">Phone Number</Typography>
          <a
            href={
              (record.contactInformation.phoneNumber &&
                `sms:${
                  record.contactInformation.phoneNumber
                }&body=${encodeURIComponent(textBody)}`) as any
            }
          >
            <Typography variant="body1">
              {record.contactInformation.phoneNumber ?? "Not Provided"}
            </Typography>
          </a>
        </div>
      </div>
      <br />
      <div className={classes.row}>
        <div className={classes.col}>
          <Typography variant="caption">Created At</Typography>
          <Typography variant="body1">
            {formatDate(record.createdAt)}
          </Typography>
        </div>
        <div className={classes.col}>
          <Typography variant="caption">Last Updated</Typography>
          <Typography variant="body1">
            {formatDate(record.updatedAt)}
          </Typography>
        </div>
      </div>
      <br />
      <div className={classes.row}>
        <div className={classes.col}>
          <Typography variant="caption">Referral Source</Typography>
          <Typography variant="body1">
            {!!record.referralSource ? record.referralSource : "Not Provided"}
          </Typography>
        </div>
        <div className={classes.col}>
          <Typography variant="caption">status</Typography>
          <Typography
            style={{ color: purchased ? "green" : "red" }}
            variant="body1"
          >
            {purchased ? "PAID" : returnLoadingIfNull(purchased, "NOT PAID")}
          </Typography>
        </div>
      </div>
      <br />
      <div className={classes.row}>
        <div className={classes.col}>
          <FormControlLabel
            label="contacted"
            control={
              <Checkbox
                checked={contacted}
                onClick={() => setContacted(!contacted)}
              />
            }
          />
        </div>
      </div>
      <br />
      <div className={classes.row}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            paddingTop: "16px",
          }}
        >
          <Box width="100%" p={1}>
            <ExpansionPanel style={{ width: "100%" }}>
              <ExpansionPanelSummary disabled={!quizAnsers}>
                <Typography variant="h5">
                  {quizAnsers ? "Show quiz answers" : "Survey not started"}
                </Typography>
              </ExpansionPanelSummary>
              {quizAnsers && (
                <ExpansionPanelDetails>
                  <Box display="flex" flexDirection="column">
                    {quizAnsers}
                  </Box>
                </ExpansionPanelDetails>
              )}
            </ExpansionPanel>
          </Box>
        </div>
      </div>
      <Divider></Divider>
    </div>
  );

  function getName() {
    if ((profile || null)?.firstName || (profile || null)?.lastName) {
      return `${(profile || null)?.firstName} ${(profile || null)?.lastName}`;
    }

    if (
      record?.contactInformation?.firstName ||
      record?.contactInformation?.lastName
    ) {
      return `${record?.contactInformation?.firstName} ${
        (record?.contactInformation as any)?.lastName
      }`;
    }

    if ((calendlyEvent || null)?.event?.payload?.invitee?.name) {
      return (calendlyEvent || null)?.event?.payload?.invitee?.name;
    }

    if (calendlyEvent === null && profile === null) {
      return "Loading";
    }

    return "Not Provided";
  }

  function renderQuizAnswers() {
    if (!record.survey.find((qa) => !!qa.r.body)) {
      return null;
    }

    return record.survey.map((qa) => {
      let answer = "No response";

      if (qa.r.body) {
        answer =
          typeof qa.r.body === "string"
            ? qa.r.body
            : (qa.r.body as Array<string>).join("\n");
      }

      return (
        <Box display="flex" flexDirection="column" p={1} key={qa.q.prompt}>
          <Box pb={2}>
            <Typography variant="h5">{qa.q.prompt}</Typography>
          </Box>
          <Box>
            <Typography variant="body1">{answer}</Typography>
          </Box>
        </Box>
      );
    });
  }

  function returnLoadingIfNull(item: any, label: string): string {
    if (item === null) {
      return "Loading...";
    }

    return label;
  }

  function listenForProfile(): () => void {
    return firebase
      .firestore()
      .collection("profiles")
      .doc(record.userId)
      .onSnapshot((snap) => {
        if (snap.exists) {
          return setProfile(
            new Profile((snap.data() ?? {}) as ProfileProperties)
          );
        }

        setProfile(false);
      });
  }

  function listenForOrders(): (() => void) | void {
    if (purchased) {
      return;
    }

    const runOnce = {
      yz: false,
      sq: false,
      sq2: false,
    };

    const unsubYzOrders = firebase
      .firestore()
      .collection("yzOrders")
      .where("userId", "==", record.userId)
      .onSnapshot((snap) => {
        runOnce.yz = true;

        if (!snap.empty) {
          setPurchased(true);
        }

        determineDoneLoading();
      });

    const unsubSqOrders = firebase
      .firestore()
      .collection("squarespaceOrders")
      .where(
        "order.customerEmail",
        "==",
        record.contactInformation.email ?? "INVALID"
      )
      .onSnapshot((snap) => {
        runOnce.sq = true;

        if (!snap.empty) {
          setPurchased(true);
        }

        determineDoneLoading();
      });

    const unsubSqOrders2 = firebase
      .firestore()
      .collection("squarespaceOrders")
      .where(
        "order.billingAddress.phone",
        "==",
        record.contactInformation.email ?? "INVALID"
      )
      .onSnapshot((snap) => {
        runOnce.sq2 = true;

        if (!snap.empty) {
          setPurchased(true);
        }

        determineDoneLoading();
      });

    return () => {
      unsubYzOrders();
      unsubSqOrders();
      unsubSqOrders2();
    };

    function determineDoneLoading() {
      if (purchased !== null) {
        return;
      }

      if (runOnce.sq && runOnce.sq2 && runOnce.yz) {
        setPurchased(false);
      }
    }
  }

  function listenForCalendly(): (() => void) | void {
    if (!record.contactInformation.email) {
      if (calendlyEvent !== false) {
        setCalendlyEvent(false);
      }

      return;
    }

    return firebase
      .firestore()
      .collection("calendlyEvents")
      .where("email", "==", record.contactInformation.email)
      .onSnapshot((snap) => {
        if (!snap.docs.length) {
          if (calendlyEvent !== false) {
            setCalendlyEvent(false);
          }

          return;
        }

        const doc = snap.docs.sort((a, b) => {
          const aTimeMoment = moment(a.data()?.event?.time ?? "invalid");
          const aTime = aTimeMoment.isValid() ? aTimeMoment.unix() : -Infinity;

          const bTimeMoment = moment(b.data()?.event?.time ?? "invalid");
          const bTime = bTimeMoment.isValid() ? bTimeMoment.unix() : -Infinity;

          return bTime - aTime;
        })[0];

        if (!doc) {
          if (calendlyEvent !== false) {
            setCalendlyEvent(false);
          }

          return;
        }

        setCalendlyEvent(doc.data());
      });
  }

  function onContactedChange(): void {
    if (!initialOnContactRun.current) {
      initialOnContactRun.current = true;
      return;
    }

    firebase.firestore().runTransaction(async (tx) => {
      const dp = await tx.get(
        firebase.firestore().collection("designProfiles").doc(record.id)
      );

      if (dp.data()?.contacted !== contacted) {
        tx.update(dp.ref, { contacted });
      }
    });
  }
};

function renderCallStatus(
  eventObject: CalendlyEvent | null | false
): React.ReactNode {
  if (!eventObject) {
    if (eventObject === null) {
      return <CircularProgress />;
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <PhoneDisabled style={{ color: "red" }} />
        <Typography
          style={{ color: "red", textAlign: "center" }}
          variant="caption"
        >
          Not scheduled
        </Typography>
      </div>
    );
  }

  if (eventObject?.event?.payload?.invitee?.canceled) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <PhoneDisabled style={{ color: "red" }} />
        <Typography style={{ color: "red" }} variant="caption">
          canceled
        </Typography>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Phone style={{ color: "green" }} />
      <Typography
        style={{ color: "green", textAlign: "center" }}
        variant="caption"
      >
        {moment(eventObject.event.time).format("ddd MM/DD, hh:mm")}
      </Typography>
    </div>
  );
}

function formatDate(timestamp?: firebase.firestore.Timestamp): string {
  if (!timestamp) {
    return "Date Not Found";
  }

  return moment(new Date(timestamp.toDate())).format("MM/DD/YYYY hh:mm");
}
