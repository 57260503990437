import firebase from "firebase/compat/app";
import { GenericOnCallFunctionAlert } from "./genericAlert";

export interface DesignDeliveryNotificationArgs {
  revisionId: string;
  clientId: string;
}

export async function sendDesignDeliveryNotification(
  args: DesignDeliveryNotificationArgs
) {
  try {
    return firebase
      .functions()
      .httpsCallable("sendDeliveredDesignsToDesignsChannel")(args);
  } catch (error) {
    GenericOnCallFunctionAlert(
      "sendDeliveredDesignsToDesignsChannel",
      error.message
    );
    return Promise.resolve(undefined);
  }
}

export default sendDesignDeliveryNotification;
