import React, { FC } from "react";
import { DocProp } from "./types";
import StatTag from "./StatTag";

export interface StatTagCloudSelectorProps {
  type: "additive" | "subtractive";
  tags: DocProp[];
  onSelect: (tag: DocProp) => void;
}

/*
  Delegate for tags passed intot he cloud, renders and
  binds click handlers.
*/
const StatTagCloudSelector: FC<StatTagCloudSelectorProps> = (props) => {
  return <>{renderTags()}</>;

  function renderTags(): React.ReactNode[] {
    /*
      Iterate through props.tags, create tag elements
      with bound onClick handler
    */

    return props.tags.map((t) => {
      return (
        <StatTag
          key={`tag-${props.type}-${t}`}
          tag={t}
          type={props.type}
          onClick={() => props.onSelect(t)}
        />
      );
    });
  }
};

export { StatTagCloudSelector };
export default StatTagCloudSelector;
