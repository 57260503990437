import { AppBar, Box, Typography } from "@material-ui/core";
import * as React from "react";
import MediaGrid from "./Components/MediaGrid";
import { MediaGridProps } from "./Components/MediaGrid";
import { BUDGET_FILENAME } from "./ClientDetail/Revisions/QAMerge";

interface ISharedMediaGridsProps extends MediaGridProps {
  rootStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}

const SharedMediaGrids: React.FunctionComponent<ISharedMediaGridsProps> = ({
  rootStyle,
  containerStyle,
  media,
  ...mediaGridProps
}) => {
  const cadMedia = React.useMemo(filterByTag("cad"), [media]);

  const renderMedia = React.useMemo(filterByTag("deliverable"), [media]);

  const propertyMedia = React.useMemo(filterByTag("property"), [media]);

  return (
    <div style={{ textAlign: "center", ...rootStyle }}>
      {!!cadMedia.length && (
        <Box my={2} style={containerStyle}>
          <AppBar position="sticky" style={{ top: 0 }}>
            <Typography variant="h4">CAD Files</Typography>
          </AppBar>
          <MediaGrid media={cadMedia} {...mediaGridProps} />
        </Box>
      )}
      {!!renderMedia.length && (
        <Box my={2} style={containerStyle}>
          <AppBar position="sticky" style={{ top: 0 }}>
            <Typography variant="h4">Renders</Typography>
          </AppBar>
          <MediaGrid media={renderMedia} {...mediaGridProps} />
        </Box>
      )}
      {!!propertyMedia.length && (
        <Box my={2} style={containerStyle}>
          <AppBar position="sticky" style={{ top: 0 }}>
            <Typography variant="h4">Property</Typography>
          </AppBar>
          <MediaGrid media={propertyMedia} {...mediaGridProps} />
        </Box>
      )}
    </div>
  );

  function filterByTag(keyword: string) {
    return () =>
      media.filter(
        (m) =>
          m.tag?.includes(keyword) && m.originalFileName !== BUDGET_FILENAME
      );
  }
};

export { SharedMediaGrids };
