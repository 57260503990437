import { gql } from "@yardzen-inc/graphql";

export const BUDGET_DISPLAY_DATA_QUERY = gql`
  query BudgetDisplayDataQuery($projectId: String) {
    budget_metadata(where: { project_id: { _eq: $projectId } }) {
      id
      phasing
      project_id
      total_budget
      design_project_for_single_phase
      designer_visible_budget
      budget_phase_metadata {
        budget
        id
        yards
      }
    }

    budget_checklist_response(where: { project_id: { _eq: $projectId } }) {
      budget_checklist_item {
        id
        quantitative_item
        name
      }
      budget_checklist_price_option {
        low_price
        high_price
        id
      }
      budget_checklist_style_option_id
      context
      created_at
      id
      project_id
      updated_at
      user_id
      order
      front_yard
      back_yard
      left_yard
      right_yard
    }
  }
`;

export const DESIGNER_SUMMARY_DATA_QUERY = gql`
  query DesignerSummaryData(
    $archived: Boolean
    $assignmentId: String!
    $regionName: String!
  ) {
    geozone_file(order_by: { created_at: desc }, limit: 1) {
      id
      url
    }

    plant_adapted_habitat(where: { archived: { _eq: $archived } }) {
      id
      name
      archived
    }

    plant_usda_zone(where: { archived: { _eq: $archived } }) {
      id
      name
      archived
    }

    material_type(where: { archived: { _eq: $archived } }) {
      id
      name
      archived
    }

    product_region(where: { name: { _eq: $regionName } }) {
      id
      name
    }

    assignment_by_pk(id: $assignmentId) {
      id
      assignment_product_base_links {
        product_base_id
        product_base {
          msrp
          unit_cost
        }
      }
      assignment_material_base_links {
        material_base_id
        amount
        material_base {
          unit_cost
        }
      }
      assignment_plant_base_links {
        plant_base_id
        plant_base {
          unit_cost
        }
      }
      assignment_softscape_item_links {
        softscape_item_id
        quantity
        softscape_item {
          unit_cost
        }
      }
    }
  }
`;

export const ACTIVE_GEO_AND_PLANT_HABITAT_QUERY = gql`
  query ActiveGeoAndPlantAdaptedHabitat($archived: Boolean) {
    geozone_file(order_by: { created_at: desc }, limit: 1) {
      id
      url
    }

    plant_adapted_habitat(where: { archived: { _eq: $archived } }) {
      id
      name
      archived
    }
  }
`;

export const BUDGET_METADATA_BY_PROJECT_ID = gql`
  query BudgetMetadataAndPhasingByProjectId($projectId: String) {
    budget_metadata(where: { project_id: { _eq: $projectId } }) {
      id
      phasing
      project_id
      total_budget
      design_project_for_single_phase
      designer_visible_budget
      budget_phase_metadata {
        budget
        id
        yards
      }
    }
  }
`;

export const QUERY_SELECTION_FOR_BUDGET_QA = gql`
  query QuerySelectionForBudgetQA($id: String) {
    assignment(where: { id: { _eq: $id } }) {
      assignment_softscape_item_links {
        quantity
        softscape_item {
          id
          name
          unit
          unit_cost
          type
        }
      }
      assignment_product_base_links(order_by: { product_base: { name: asc } }) {
        id
        product_base {
          id
          unit_cost
          labor_cost
          msrp
          name
          icon_uri
          productTypeByProductType {
            name
          }
          priceTierByProductPriceTier {
            name
          }
          vendorByProductVendor {
            name
          }
          product_base_product_type_links {
            product_type {
              name
            }
          }
          product_base_product_style_links {
            product_style {
              name
            }
          }
          sketchup_file_link
          lumion_file_link
          lumion_file_name
        }
      }
      assignment_plant_base_links(
        order_by: { plant_base: { scientific_name: asc } }
      ) {
        id
        plant_base {
          id
          common_name
          scientific_name
          icon_uri
          unit_cost
          plant_base_plant_container_size_links {
            plant_container_size {
              name
              id
            }
          }
          plant_base_plant_cad_symbol_size_links {
            plant_cad_symbol_size {
              name
              id
            }
          }
          plant_base_plant_height_class_links {
            plant_height_class {
              name
              id
            }
          }
          plant_base_plant_cad_symbol_spacing_links {
            plant_cad_symbol_spacing {
              name
              id
            }
          }
          plant_base_plant_light_needs_links {
            plant_light_needs {
              name
            }
          }
          plant_base_plant_key_feature_links {
            plant_key_feature {
              name
            }
          }
          plant_base_plant_usda_zone_links {
            plant_usda_zone {
              name
            }
          }
          waterNeedsByPlantWaterNeeds {
            name
          }
          plant_base_plant_type_links {
            plant_type {
              name
            }
          }
          plant_base_plant_red_flag_links {
            plant_red_flag {
              name
            }
          }
          plant_base_plant_native_range_links {
            plant_native_range {
              name
              id
            }
          }
          plant_base_adapted_habitat_links {
            plant_adapted_habitat {
              name
              id
            }
          }
          lumion_file_link
          lumion_file_name
          sketchup_file_link
        }
      }
      assignment_material_base_links(
        order_by: { material_base: { name: asc } }
      ) {
        id
        amount
        location
        material_base {
          lumion_file_link
          lumion_file_name
          max_file_link
          sketchup_file_link
          id
          unit_cost
          labor_cost
          icon_uri
          name
          collectionNameByMaterialCollectionName {
            name
            id
          }
          unitByUnit {
            name
          }
          priceTierByMaterialPriceTier {
            name
          }
          vendorByMaterialVendor {
            name
          }
          materialTypeByMaterialType {
            name
            id
          }
          material_base_material_type_links {
            material_type {
              name
              id
            }
          }
          material_base_region_links {
            product_region {
              id
              name
              archived
            }
          }
          material_base_material_material_links {
            material_material {
              name
              id
            }
          }
          material_base_material_color_links {
            material_color {
              name
              id
            }
          }
          material_finish {
            name
          }
          material_pattern {
            name
          }
          material_size {
            name
          }
        }
      }
    }
  }
`;
