import React, { FC, useState, useEffect, useMemo } from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";
import NewMediaGrid from "../../Components/NewMediaGrid";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Media } from "@yardzen-inc/models";
import FileUpload from "../../Components/FileUpload";
import { videoVariantTags } from "./YardInfoGroup";

export interface BasicOnboardMediaGridProps extends BoxProps {
  yard: string;
  variant: string;
  userId: string;
  privileged?: boolean;
}

const BasicOnboardMediaGrid: FC<BasicOnboardMediaGridProps> = ({
  yard,
  variant,
  userId,
  privileged,
  ...containerProps
}) => {
  const [media, setMedia] = useState<Media[] | null>();

  const len = useMemo(() => (media ? media.length : 0), [media]);

  const useIspoCard = useMemo(
    () => variant === "keep" || variant === "remove",
    [variant]
  );

  useEffect(listenOnMedia, [userId, variant, yard]);

  if (!media) {
    return (
      <Box {...containerProps}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box {...containerProps}>
      {!len ? (
        <Box {...containerProps}>
          <Typography style={{ fontStyle: "italic" }}>
            No media uploaded for variant {variant}
          </Typography>
        </Box>
      ) : (
        <NewMediaGrid
          userId={userId}
          media={media}
          variant={`${yard}-${variant}`}
          noLazyLoad
          noNoItems
          cardType={useIspoCard ? "withTextField" : "readOnly"}
          columnsMd={2}
          columnsLg={2}
          columnsXl={2}
          deletable={privileged}
          downloadable
        />
      )}

      {privileged && (
        <FileUpload
          clientUserId={userId}
          fileTag="property"
          variant={`${yard}-yard-${variant}`}
          fileVisibility={{}}
          accept={getFileUploaderAcceptType(variant)}
        />
      )}
    </Box>
  );

  function listenOnMedia(): void | (() => void) {
    return firebase
      .firestore()
      .collection("media")
      .where("userId", "==", userId)
      .where("tag", "==", "property")
      .where("variant", "==", `${yard}-yard-${variant}`)
      .onSnapshot(async (snap) => {
        setMedia(
          await Promise.all(snap.docs.map(Media.createFromQuerySnapshot))
        );
      });
  }

  function getFileUploaderAcceptType(variant: string) {
    const imageType = ["image/*"];
    const videoType = ["video/*"];

    if (videoVariantTags.includes(variant)) {
      return videoType;
    }

    return imageType;
  }
};

export { BasicOnboardMediaGrid };
export default BasicOnboardMediaGrid;
