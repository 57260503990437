import { DesignBriefEntryType, EntryImage } from "./types";

export interface DesignBriefEntryProperties<t> {
  type: DesignBriefEntryType;
  value: t;
  marked: boolean;
  group: string;
  order: number;
  options?: string[];
  label?: string;
  referenceLink?: string;
  referenceImages?: EntryImage[];
  questionId?: string;
}

/*
  Represents an database platform agnostic instance of a design checklist entry
  entries will live in a (table | collection), will be queryable by groups via
  the "group" property, and ordered by order property.
*/
class DesignBriefEntry<t> implements DesignBriefEntryProperties<t> {
  public id: string;
  public marked: boolean = false;
  public type: DesignBriefEntryType;
  public value: t;
  public group: string;
  public order: number;
  public options?: string[];
  public label?: string;
  public referenceLink?: string;
  public referenceImages?: EntryImage[];
  public questionId?: string;

  public constructor(id: string, props: DesignBriefEntryProperties<t>) {
    this.id = id;
    this.marked = props.marked;
    this.value = props.value;
    this.type = props.type;
    this.group = props.group;
    this.order = props.order;
    this.options = props.options;
    this.label = props.label;
    this.referenceLink = props.referenceLink;
    this.referenceImages = props.referenceImages;
    this.questionId = props.questionId;
  }
}

export { DesignBriefEntry };
export default DesignBriefEntry;
