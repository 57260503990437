import * as React from "react";
import { Paper, makeStyles } from "@material-ui/core";
import NestableDrawerListItem from "../Components/NestableDrawerListItem";
import { Project } from "@yardzen-inc/models";
import DesignAssignmentSlug from "../Components/DesignAssignmentSlug";
import { IdentifyingPaymentId } from "./IdentifyingPaymentId";
import useDssStatesOrderedMap from "../util/selfAssign/useDssStatesOrderedMap";
import { getPreviousDesignAssignmentStatus } from "../util/selfAssign";

const useStyles = makeStyles({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.7rem",
      height: "0.7rem",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "1rem",
    },
  },
  root: {
    borderRadius: "0",
    width: "265px",
    minWidth: "265px",
    borderRight: "1px solid #e0e0e0",
    maxHeight: "100%",
    overflow: "scroll",
  },
  inner: {
    height: "100%",
    maxHeight: "100%",
    display: "flex",
    flexFlow: "column nowrap",
  },
  list: {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: 1,
  },

  contactBox: {
    padding: "1rem",
  },
});

interface Props {
  index: number;
  setIndex: (i: number) => void;
  selectedProject: Project | null;
  selectProject: (a: Project) => void;
  assigned: Project[] | null;
}

const DesignerSidebar = (props: Props) => {
  const dssStates = useDssStatesOrderedMap();
  const classes = useStyles();
  // const agent = React.useContext(AgentCtx) as Agent;

  const handleListItemClick = (i: number) => () => {
    props.setIndex(i);
  };

  const [open, onHold, completed] = React.useMemo(() => {
    const open: Project[] = [],
      onHold: Project[] = [],
      completed: Project[] = [];

    if (!props.assigned || !props.assigned.length || !dssStates) {
      return [[], [], []];
    }

    for (let p of props.assigned) {
      const { designAssignmentStatus: status, currentDesignAssignmentId } = p;

      const indexofCompletionState = dssStates.indexOf("completed");

      if (
        status?.toLowerCase().includes("progress") || // this is kindof hacky :(
        !!currentDesignAssignmentId?.length
      ) {
        open.push(p);
      } else if (dssStates.indexOf(status) >= indexofCompletionState) {
        completed.push(p);
      } else if (status?.toLowerCase().includes("hold")) {
        // this is also kindof hacky
        onHold.push(p);
      } else {
        completed.push(p);
      }
    }

    return [open || [], onHold || [], completed || []];
  }, [props.assigned, dssStates]);

  return (
    <Paper className={classes.root} elevation={0}>
      <div className={classes.inner}>
        <div className={classes.list}>
          <NestableDrawerListItem
            selected={props.index === 0}
            onClick={handleListItemClick(0)}
            selectedSublist={
              props.selectedProject ? props.selectedProject.id : undefined
            }
            sublistItems={(() => {
              if (open)
                return open
                  .filter((a) => getType(a))
                  .map((a) => {
                    const type = getType(a);

                    if (!type) {
                      return {
                        label: "",
                        key: a.id,
                        onClick: () => props.selectProject(a),
                      };
                    }

                    return {
                      label: (
                        <DesignAssignmentSlug
                          key={a.id}
                          type={type as any}
                          project={a}
                        />
                      ),
                      p: a as Project,
                      key: a.id,
                      onClick: () => props.selectProject(a),
                    };
                  });

              return [];
            })()}
          >
            Current Assignments
          </NestableDrawerListItem>
          <NestableDrawerListItem
            selected={props.index === 0}
            onClick={handleListItemClick(0)}
            selectedSublist={
              props.selectedProject ? props.selectedProject.id : undefined
            }
            sublistItems={(() => {
              if (onHold)
                return onHold
                  .filter((a) => getType(a))
                  .map((a) => {
                    const type = getType(a);

                    if (!type) {
                      return {
                        label: "",
                        key: a.id,
                      };
                    }

                    return {
                      label: (
                        <>
                          <DesignAssignmentSlug
                            type={type as any}
                            project={a}
                          />
                          <IdentifyingPaymentId
                            profileOrId={a.profileId}
                            orderIdVariant="caption"
                          />
                        </>
                      ),
                      key: a.id,
                      // onClick: () => props.selectProject(a),
                    };
                  });

              return [];
            })()}
          >
            Assignments on Hold
          </NestableDrawerListItem>
          <NestableDrawerListItem
            selected={props.index === 0}
            onClick={handleListItemClick(0)}
            selectedSublist={
              props.selectedProject ? props.selectedProject.id : undefined
            }
            sublistItems={(() => {
              if (completed)
                return completed
                  .filter((a) => getType(a))
                  .map((a) => {
                    const type = getType(a);

                    if (!type) {
                      return {
                        label: "",
                        key: a.id,
                        // onClick: () => props.selectProject(a),
                      };
                    }

                    return {
                      label: (
                        <>
                          <DesignAssignmentSlug
                            type={type as any}
                            project={a}
                          />
                          <IdentifyingPaymentId
                            profileOrId={a.profileId}
                            orderIdVariant="caption"
                          />
                        </>
                      ),
                      key: a.id,
                      // onClick: () => props.selectProject(a),
                    };
                  });

              return [];
            })()}
          >
            Completed
          </NestableDrawerListItem>
        </div>
        <div className={classes.contactBox}></div>
      </div>
    </Paper>
  );

  function getType(p: Project): string | null {
    const { designAssignmentStatus: status } = p;

    if (!status || !dssStates) {
      return null;
    }

    if (status === "correctionInProgress" || status === "postCorrectionHold") {
      return "correction";
    }

    const v = dssStates.get(status);

    if (v?.type) {
      return v.type;
    }

    const prevState = getPreviousDesignAssignmentStatus(status, dssStates);

    return dssStates.get(prevState)?.type ?? null;
  }
};

DesignerSidebar.defaultProps = {
  assigned: [],
};

export default React.memo(DesignerSidebar);
