import * as React from "react";
import { makeStyles } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Theme } from "@material-ui/core/styles";
import { Visibility, visibilityValues } from "../Shared/VisibilityTypes";
import GetAppIcon from "@material-ui/icons/GetApp";
import SupplementaryTableForAssets from "../Shared/SupplementaryTableForAssets";
import AssetTable from "../Shared/AssetTable";
import SurfacesTableRow from "./SurfacesTableRow";
import SurfaceLibraryFilterBoxOptions from "../../../EmployeeView/AssetLibraries/SurfaceLibrary/SurfaceLibraryFilterBoxOptions";
import ChipSingleSelect from "../Shared/ChipSingleSelect";
import {
  surfaceArrayLinksMap,
  surfaceObjLinksMap,
} from "../Shared/FilterLinkMaps";

import {
  INSERT_MATERIAL_COLLECTION_NAME,
  INSERT_MATERIAL_MATERIAL,
  INSERT_MATERIAL_PRICE_TIER,
  INSERT_MATERIAL_SETTING,
  INSERT_MATERIAL_STYLE,
  INSERT_MATERIAL_TYPE,
  INSERT_MATERIAL_UNIT,
  INSERT_MATERIAL_VENDOR,
  MATERIAL_COLLECTION_NAME,
  MATERIAL_MATERIAL,
  MATERIAL_PRICE_TIER,
  MATERIAL_SETTING,
  MATERIAL_STYLE,
  MATERIAL_TYPE,
  MATERIAL_UNIT,
  MATERIAL_VENDOR,
  UPDATE_MATERIAL_COLLECTION_NAME,
  UPDATE_MATERIAL_MATERIAL,
  UPDATE_MATERIAL_PRICE_TIER,
  UPDATE_MATERIAL_SETTING,
  UPDATE_MATERIAL_STYLE,
  UPDATE_MATERIAL_TYPE,
  UPDATE_MATERIAL_UNIT,
  UPDATE_MATERIAL_VENDOR,
  useLazyQuery,
  MATERIAL_BASE_AGGREGATE,
  NetworkStatus,
} from "@yardzen-inc/graphql";
import AddNewSurface from "./AddNewSurface";
import {
  IMaterialBaseMaterialMaterialLink,
  IMaterialBaseMaterialStyleLink,
  IMaterialBaseMaterialTypeLink,
  IMaterialBaseMaterialColorLink,
  IMaterialBasePaverApplicationLink,
  ModelFiletype,
} from "../../../Interfaces";
import { formatNumToUSD } from "@yardzen-inc/react-common";
import { useHistory } from "react-router";
import { GraphqlConstants } from "../../../ConstantValues/graphqlConstants";
import TableCellWithStyles from "../Shared/AssetTableCellWithStyles";
import { arrayMove } from "react-sortable-hoc";
import AssetLibraryTabPanel from "../Shared/AssetLibraryTabPanel";
import getAssetLibraryOrderBy from "../getAssetLibraryOrderBy";
import cleanedDownloadableFileName from "../Shared/CleanedDownloadableFileName";
import FilterMenu from "../Shared/FilterMenu";
import usePreloadedAssetTableFilters from "../../../util/hooks/usePreloadedAssetTableFilters";
import AssetLibraryFilterMenu from "../Shared/AssetLibraryFilterMenu";
import { AssetLibraryFetchMoreStatus } from "../../../ConstantValues/AssetLibraryFetchMoreStatusConstants";
import getObjectAndArrayRelationships from "../Shared/getObjectAndArrayRelationships";
import {
  HasuraQueryFunctions,
  HasuraQueryHumanReadableOperatorEnum,
} from "@yardzen-inc/util";
import { ModelFileDownloadButton } from "../../../Components/ModelFileDownloadButton";
import { MATERIAL_BASE } from "../Shared/graphqlQueries";
import { applyAndNullFiltering } from "../../../Components/NonPmAssetSelection/applyAndNullFiltering";

let saveTimeout: any = null;

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  longLink: {
    width: "10rem",
    maxWidth: "10rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  iconUri: {
    width: "6.25rem",
    height: "6.25rem",
    objectFit: "contain",
  },
  tabs: {
    background: "#fafafa",
    borderBottom: "1px solid #f2f2f2",
    minHeight: "0 !important",
    position: "fixed",
    zIndex: theme.zIndex.drawer + 1,
    width: "calc(100vw - 240px)",
    top: 0,
  },
  tab: {
    minWidth: 0,
    textTransform: "none",
    height: 43,
    minHeight: "0 !important",
    fontSize: 15.3,
    "&.Mui-selected": {
      fontWeight: 600,
    },
  },
  assetFilters: {
    display: "flex",
    alignItems: "center",
  },
}));

const listResultName = "material_base";

function SurfaceLibrary() {
  const history = useHistory();
  const classes = useStyles();

  const [
    neqFilterState,
    setNeqFilterState,
    hasAnyOfFilterState,
    setHasAnyOfFilterState,
    hasAllOfFilters,
    setHasAllOfFilters,
    search,
    setSearch,
  ] = usePreloadedAssetTableFilters("materials");

  const [visibilityFilter, setVisibilityFilter] = React.useState<string>("");

  const [tabValue, setTabValue] = React.useState<number>(0);
  const [showArchived, setShowArchived] = React.useState(false);

  const [queryBy, setQueryBy] = React.useState({
    field: "updated_at",
    direction: "desc",
  });

  const onSearch = (term: string) => {
    setSearch(term);
  };

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const [getBases, { data, refetch, error, fetchMore, networkStatus }] =
    useLazyQuery(MATERIAL_BASE, {
      notifyOnNetworkStatusChange: true,
    });

  const [getCount, { data: dataCount }] = useLazyQuery(
    MATERIAL_BASE_AGGREGATE,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  React.useEffect(() => {
    getBases({
      variables: getVariables(),
    });
    getCount({
      variables: getVariables(),
    });
  }, [getBases]);

  React.useEffect(() => {
    if (!refetch) {
      return;
    }
    saveTimeout = setTimeout(async () => {
      refetch(getVariables());
      getCount({
        variables: getVariables(),
      });
    }, 300);

    return () => clearTimeout(saveTimeout);
  }, [
    refetch,
    search,
    hasAllOfFilters,
    neqFilterState,
    hasAnyOfFilterState,
    visibilityFilter,
  ]);

  const requestRefetch = () => {
    if (!refetch) {
      return;
    }
    return refetch({ ...getVariables() });
  };

  const toggleArchived = () => {
    if (!refetch) {
      return alert(
        "Something went wrong. Please reload the screen and try again."
      );
    }
    const newArchived = !showArchived;
    refetch({ ...getVariables(undefined, newArchived) });
    getCount({
      variables: { ...getVariables(undefined, newArchived) },
    });
    setShowArchived(newArchived);
  };

  const getVariables = (useOffset?: boolean, forceShowArchived?: boolean) => {
    const condition: any = {};
    if (search) {
      condition.name = {
        _ilike: `%${search}%`,
      };
    }
    if (typeof forceShowArchived === "boolean") {
      condition.archived = {
        _eq: forceShowArchived,
      };
    } else {
      condition.archived = {
        _eq: showArchived,
      };
    }

    const [hasAllOfObjectRelationships, hasAllOfArrayRelationships] =
      getObjectAndArrayRelationships(
        hasAllOfFilters,
        surfaceArrayLinksMap,
        surfaceObjLinksMap
      );
    HasuraQueryFunctions.processAndConditionsNoLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAllOf,
      fields: hasAllOfObjectRelationships,
    });
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAllOf,
      fields: hasAllOfArrayRelationships,
    });
    const [hasAnyOfObjectRelationships, hasAnyOfArrayRelationships] =
      getObjectAndArrayRelationships(
        hasAnyOfFilterState,
        surfaceArrayLinksMap,
        surfaceObjLinksMap
      );
    HasuraQueryFunctions.processAndConditionsNoLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAnyOf,
      fields: hasAnyOfObjectRelationships,
    });
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasAnyOf,
      fields: hasAnyOfArrayRelationships,
    });
    const [hasNoneOfObjectRelationships, hasNoneOfArrayRelationships] =
      getObjectAndArrayRelationships(
        neqFilterState,
        surfaceArrayLinksMap,
        surfaceObjLinksMap
      );
    HasuraQueryFunctions.processAndConditionsNoLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasNoneOf,
      fields: hasNoneOfObjectRelationships,
    });
    HasuraQueryFunctions.processAndConditionsWithLinks({
      condition,
      operator: HasuraQueryHumanReadableOperatorEnum.hasNoneOf,
      fields: hasNoneOfArrayRelationships,
    });
    if (visibilityFilter) {
      condition.visibility = {
        _eq: visibilityFilter,
      };
    }
    applyAndNullFiltering(condition, "material_base_region_links");
    return {
      condition,
      offset: useOffset ? data[listResultName].length : 0,
      orderBy: getAssetLibraryOrderBy(queryBy.field, queryBy.direction),
    };
  };

  const requestMoreResults = () => {
    setFetchMoreStatus(AssetLibraryFetchMoreStatus.shouldFetch);
  };

  const fetchMoreResults = async () => {
    if (!fetchMore) {
      return alert(
        "Something went wrong. Please reload the screen and try again."
      );
    }
    try {
      fetchMore({
        variables: getVariables(true),
        updateQuery: (prev: any, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const iterableProductBase = Array.isArray(prev[listResultName])
            ? prev[listResultName]
            : [];
          const newArray = Array.from(
            new Set([
              ...iterableProductBase,
              ...fetchMoreResult[listResultName],
            ])
          );
          // it's possible that the apollo caching mechanism may
          // provide the fetchMoreResult from the previous iteration
          // if the user is moving quickly, so let's adjust for that
          // and ensure we're not duplicating any data
          let uniqueArray: any[] = [];
          for (let it = 0; it < newArray.length; it++) {
            if (uniqueArray.some((line) => line.id === newArray[it].id)) {
              continue;
            }
            uniqueArray.push(newArray[it]);
          }
          const resultToSend = Object.assign({}, prev, {
            [listResultName]: uniqueArray,
          });
          return resultToSend;
        },
      });
    } catch (err) {
      // pretty sure an error is thrown only in development
      // but this is just to be sure
      console.error("fetchMore issue 1");
    }
  };

  const inStockText = (row: any) => {
    if (typeof row.in_stock === "boolean") {
      return row.in_stock ? "Yes" : "No";
    }
    return "--";
  };

  const initialColumns = [
    {
      name: "Name",
      queryName: "name",
      sortable: true,
      id: "name",
      renderCellContent: (row: any) => row.name,
    },
    {
      name: "Image",
      id: "icon_uri",
      renderCellContent: (row: any) => (
        <img className={classes.iconUri} src={row.icon_uri} />
      ),
    },
    {
      name: "Material",
      id: "material_base_material_material_links",
      renderCellContent: (row: any) =>
        row.material_base_material_material_links
          ?.map(
            (style: IMaterialBaseMaterialMaterialLink) =>
              style.material_material.name
          )
          .join(", "),
    },
    {
      name: "Type",
      id: "material_base_material_type_links",
      renderCellContent: (row: any) =>
        row.material_base_material_type_links
          ?.map(
            (type: IMaterialBaseMaterialTypeLink) => type.material_type.name
          )
          .join(", "),
    },
    {
      name: "Application",
      id: "material_base_paver_application_links",
      renderCellContent: (row: any) =>
        row.material_base_paver_application_links
          ?.map(
            (paver: IMaterialBasePaverApplicationLink) =>
              paver.paver_application?.name
          )
          .join(", "),
    },
    {
      name: "Setting",
      id: "settingByMaterialSetting_simplejoin",
      sortable: true,
      queryName: "settingByMaterialSetting.name",
      renderCellContent: (row: any) => row.settingByMaterialSetting?.name,
    },
    {
      name: "Size",
      sortable: true,
      queryName: "material_size.name",
      id: "umaterial_size_simplejoin",
      renderCellContent: (row: any) => row.material_size?.name,
    },
    {
      name: "Price Tier",
      sortable: true,
      queryName: "priceTierByMaterialPriceTier.name",
      id: "priceTierByMaterialPriceTier_simplejoin",
      renderCellContent: (row: any) => row.priceTierByMaterialPriceTier?.name,
    },
    {
      name: "Unit",
      sortable: true,
      queryName: "unitByUnit.name",
      id: "unitByUnit_simplejoin",
      renderCellContent: (row: any) => row.unitByUnit.name,
    },
    {
      name: "Unit Cost",
      id: "unit_cost",
      renderCellContent: (row: any) =>
        formatNumToUSD(parseFloat(row.unit_cost)),
    },
    {
      name: "Labor Cost",
      id: "labor_cost",
      renderCellContent: (row: any) =>
        formatNumToUSD(parseFloat(row.labor_cost || 0)),
    },
    {
      name: "Installed Cost",
      id: "installed_cost",
      renderCellContent: (row: any) =>
        formatNumToUSD(
          parseFloat(row.labor_cost || 0) + parseFloat(row.unit_cost || 0)
        ),
    },
    {
      name: "Style",
      id: "material_base_material_style_links",
      renderCellContent: (row: any) =>
        row.material_base_material_style_links
          ?.map(
            (style: IMaterialBaseMaterialStyleLink) => style.material_style.name
          )
          .join(", "),
    },
    {
      name: "Region",
      id: "material_base_region_links",
      renderCellContent: (row: any) =>
        row.material_base_region_links
          ?.map((region: any) => region.product_region.name)
          .sort((a: string, b: string) => (a > b ? 1 : -1))
          .join(", "),
    },
    {
      name: "Finish",
      sortable: true,
      queryName: "material_finish.name",
      id: "material_finish_simplejoin",
      renderCellContent: (row: any) => row.material_finish?.name,
    },
    {
      name: "Color Family",
      id: "material_base_material_color_links",
      renderCellContent: (row: any) =>
        row.material_base_material_color_links
          ?.map(
            (color: IMaterialBaseMaterialColorLink) =>
              color.material_color?.name
          )
          .join(", "),
    },
    {
      name: "Pattern",
      sortable: true,
      queryName: "material_pattern.name",
      id: "material_pattern_simplejoin",
      renderCellContent: (row: any) => row.material_pattern?.name,
    },
    {
      name: "Link",
      id: "link",
      sortable: true,
      queryName: "link",
      renderCellContent: (row: any) => (
        <div className={classes.longLink}>
          <a href={row.link} target="_blank">
            {row.link}
          </a>
        </div>
      ),
    },
    {
      name: "Vendor",
      id: "vendorByMaterialVendor_simplejoin",
      sortable: true,
      queryName: "vendorByMaterialVendor.name",
      renderCellContent: (row: any) => row.vendorByMaterialVendor?.name,
    },
    {
      name: "Retailer",
      id: "retailerByMaterialRetailer_simplejoin",
      sortable: true,
      queryName: "retailerByMaterialRetailer.name",
      renderCellContent: (row: any) => row.retailerByMaterialRetailer?.name,
    },
    {
      name: "Collection Name",
      id: "collectionNameByMaterialCollectionName_simplejoin",
      sortable: true,
      queryName: "collectionNameByMaterialCollectionName.name",
      renderCellContent: (row: any) =>
        row.collectionNameByMaterialCollectionName?.name,
    },
    {
      name: "In Stock",
      id: "in_stock",
      renderCellContent: (row: any) => (
        <div style={{ color: row.in_stock === false ? "red" : "" }}>
          {inStockText(row)}
        </div>
      ),
    },
    {
      name: "Description",
      id: "description",
      queryName: "description",
      sortable: true,
      renderCellContent: (row: any) => (
        <div className={classes.longLink}>
          <a>{row.description}</a>
        </div>
      ),
    },
    {
      name: "Visibility",
      id: "visibility",
      renderCellContent: (row: any) => (
        <div
          style={{
            color: row.visibility === Visibility.ADMIN_ONLY ? "red" : "green",
          }}
        >
          {row.visibility}
        </div>
      ),
    },
    {
      name: "Sketchup File",
      id: "sketchup_file_link",
      queryName: "sketchup_file_link",
      sortable: true,
      renderCellContent: (row: any) => (
        <ModelFileDownloadButton
          filetype={ModelFiletype.sketchup}
          filenameOrUrl={row.sketchup_file_link}
          downloadableFileName={cleanedDownloadableFileName(row.name)}
        />
      ),
    },
    {
      name: "V-Ray File",
      id: "vray_file_link",
      queryName: "vray_file_link",
      sortable: true,
      renderCellContent: (row: any) => (
        <ModelFileDownloadButton
          filetype={ModelFiletype.vray}
          filenameOrUrl={row.vray_file_link}
          downloadableFileName={cleanedDownloadableFileName(row.name)}
        />
      ),
    },
    {
      name: "MAX File",
      id: "max_file_link",
      queryName: "max_file_link",
      sortable: true,
      renderCellContent: (row: any) =>
        row.max_file_link && (
          <a
            href={row.max_file_link}
            download={cleanedDownloadableFileName(row.name)}
          >
            <GetAppIcon />
          </a>
        ),
    },
    {
      name: "Lumion File",
      id: "lumion_file_link",
      queryName: "lumion_file_link",
      sortable: true,
      renderCellContent: (row: any) => (
        <ModelFileDownloadButton
          filetype={ModelFiletype.lumion}
          filenameOrUrl={row.lumion_file_link}
          downloadableFileName={cleanedDownloadableFileName(row.name)}
        />
      ),
    },
    {
      name: "Lumion File Name",
      id: "lumion_file_name",
      queryName: "lumion_file_name",
      sortable: true,
      renderCellContent: (row: any) => row.lumion_file_name,
    },
  ];

  const [columns, setColumns] = React.useState(initialColumns);

  const InitialTableCells = (row: any) => {
    const cells = columns.map((column, idx) => {
      const innerContent = column.renderCellContent(row);

      // EXPLANATION OF WHAT'S GOING ON HERE:
      // Yup, these are matrices. What we're trying to do
      // is keep the state of the data in the table (columns x rows)
      // working while we do manipulations such as re-ordering columns
      // or sorting data. Sorting data triggers the database, so the
      // specific data in each row changes, while we keep the shape of
      // the table intact. We store "row" data at [0][1] and we know to
      // look for it there. This is used so we can use the "update" feature
      // which needs the non-html version of the data.
      return [
        <TableCellWithStyles
          id={column.id}
          key={`${row.id}-${column.id}`}
          align="right"
          // @ts-ignore
          hidden={hiddenColumns?.[column.id]}
        >
          {innerContent}
        </TableCellWithStyles>,
      ];
    });

    cells[0].push(row);
    return cells;
  };

  const [hiddenColumns, setHiddenColumns] = React.useState({});

  const handleToggleColumnVisible = (id: string) => {
    const updatedHiddenColumns = { ...hiddenColumns };
    // @ts-ignore
    updatedHiddenColumns[id] = !updatedHiddenColumns[id];
    setHiddenColumns(updatedHiddenColumns);
  };

  const onSaveNewAsset = () => {
    if (refetch) {
      refetch();
    }
  };

  const onVisibilityFilterChange = (value: string) => {
    if (visibilityValues.includes(value as Visibility)) {
      return setVisibilityFilter(value);
    }

    return setVisibilityFilter("");
  };

  const [fetchMoreStatus, setFetchMoreStatus] =
    React.useState<AssetLibraryFetchMoreStatus>(
      AssetLibraryFetchMoreStatus.standby
    );

  // if the user has scrolled to the bottom
  // but the network status is not in "ready" state,
  // we want this useEffect to listten for the network status to be ready
  // and as soon as ready, fetch more results.
  React.useEffect(() => {
    if (networkStatus !== NetworkStatus.ready) return;
    if (fetchMoreStatus !== AssetLibraryFetchMoreStatus.shouldFetch) return;
    fetchMoreResults();
    setFetchMoreStatus(AssetLibraryFetchMoreStatus.fetching);
  }, [fetchMoreStatus, networkStatus]);

  React.useEffect(() => {
    if (data === undefined) return;
    setFetchMoreStatus(AssetLibraryFetchMoreStatus.standby);
  }, [data]);

  const onSort = (v: number, queryName: string): any => {
    if (!refetch) {
      return;
    }
    let stateObj = {};
    if (v === 0) {
      window.history.replaceState(stateObj, "", `/materials-library?`);
      return refetch({ ...getVariables() });
    }
    const direction = v === -1 ? "desc" : "asc";
    if (window.history.replaceState) {
      //prevents browser from storing history with each change:
      window.history.replaceState(
        stateObj,
        "",
        `/materials-library?sortColumn=${queryName}&sortDirection=${direction}`
      );
    }
    setQueryBy({ field: queryName, direction });
    refetch({
      ...getVariables(),
      orderBy: getAssetLibraryOrderBy(queryName, direction),
    });
  };

  const listenForReachingBottomOfPage = () => {
    window.onscroll = (ev: any) => {
      // fetching before user hits the bottom of the screen so by
      // the time the user gets to the bottom, stuff will have loaded.
      if (
        window.innerHeight + window.pageYOffset >=
          document.body.offsetHeight - 700 &&
        // this is to avoid auto fetch when the filtered results
        // includes all possible items anyways
        data.material_base.length % GraphqlConstants.resultsPerQuery === 0 &&
        data.material_base.length !== 0 &&
        data.material_base.length !==
          dataCount.material_base_aggregate.aggregate.count
      ) {
        requestMoreResults();
      }
    };
  };

  React.useEffect(() => {
    // Even though we're calling this listener function
    // each time one of the dependencies gets called,
    // the previous listeners will no longer be called
    // so there's no issue from a performance perspective.
    // The advantage of setting up a new listener is that
    // the "data" object gets updated so the listener can use the proper offset.
    listenForReachingBottomOfPage();
  }, [fetchMore, data, networkStatus]);

  const onReorder = (oldIndex: number, newIndex: number) => {
    setColumns(arrayMove(columns, oldIndex, newIndex));
  };

  const tableContainerRef = React.useRef(null);

  const onScroll = () => {
    const target: any = tableContainerRef.current;
    if (!target) return;
    let pos = target.scrollTop + target.offsetHeight;
    let max = target.scrollHeight;
    // fetching before user hits the bottom of the screen so by
    // the time the user gets to the bottom, stuff will have loaded.
    if (
      pos >= max - 700 &&
      data.material_base.length % GraphqlConstants.resultsPerQuery === 0 &&
      data.material_base.length !== 0 &&
      data.material_base.length !==
        dataCount?.material_base_aggregate.aggregate.count
    ) {
      requestMoreResults();
    }
  };

  const hasMoreResults = React.useRef(
    data?.material_base.length !==
      dataCount?.material_base_aggregate.aggregate.count
  );

  const renderFilters = () => {
    const secondaryFilters = (
      <FilterMenu label="Visibility">
        <ChipSingleSelect
          onChange={onVisibilityFilterChange}
          initialValue={visibilityFilter}
          options={visibilityValues}
          title="Filter by visibility:"
          shouldDisplay
        />
      </FilterMenu>
    );

    return (
      <AssetLibraryFilterMenu
        options={SurfaceLibraryFilterBoxOptions}
        hasAllOfFilters={hasAllOfFilters}
        setHasAllOfFilters={setHasAllOfFilters}
        neqFilterState={neqFilterState}
        setNeqFilterState={setNeqFilterState}
        hasAnyOfFilterState={hasAnyOfFilterState}
        setHasAnyOfFilterState={setHasAnyOfFilterState}
        secondaryFilters={secondaryFilters}
      />
    );
  };

  const memoizedAssetTable = React.useMemo(() => {
    return (
      <AssetTable
        showLoadMoreButton={hasMoreResults.current}
        onScroll={onScroll}
        tableContainerRef={tableContainerRef}
        contentHeight="calc(100vh - 11rem)"
        pmView
        onReorder={onReorder}
        search={search}
        onAddNew={() => history.push("/materials-library/create")}
        addNewButtonLabel="Add New"
        resultsCount={dataCount?.material_base_aggregate.aggregate.count || 0}
        onSort={onSort}
        initialTableCells={InitialTableCells}
        toggleArchived={toggleArchived}
        showArchived={showArchived}
        onSearch={onSearch}
        requestMoreResults={requestMoreResults}
        networkStatus={networkStatus}
        data={data}
        error={error}
        requestRefetch={requestRefetch}
        addNewAssetComponent={(assetProps) => (
          <AddNewSurface {...assetProps} onSave={onSaveNewAsset} />
        )}
        rowComponent={(rowProps) => (
          <SurfacesTableRow {...rowProps} refetch={refetch} />
        )}
        filters={renderFilters}
        title="Materials"
        tableColumns={columns}
        hiddenColumns={hiddenColumns}
        onToggleColumnVisible={handleToggleColumnVisible}
        listResultName="material_base"
      />
    );
  }, [
    data,
    dataCount,
    hiddenColumns,
    search,
    hasAllOfFilters,
    neqFilterState,
    hasAnyOfFilterState,
    visibilityFilter,
  ]);

  return (
    <div className={classes.root}>
      <Tabs
        value={tabValue}
        onChange={handleChangeTab}
        aria-label="simple tabs example"
        className={classes.tabs}
        indicatorColor="primary"
      >
        <Tab
          className={classes.tab}
          label="Materials Library"
          {...a11yProps(0)}
        />
        <Tab className={classes.tab} label="Materials" {...a11yProps(1)} />
        <Tab className={classes.tab} label="Types" {...a11yProps(2)} />
        <Tab className={classes.tab} label="Setting" {...a11yProps(3)} />
        <Tab className={classes.tab} label="Price Tier" {...a11yProps(4)} />
        <Tab className={classes.tab} label="Unit" {...a11yProps(5)} />
        <Tab className={classes.tab} label="Styles" {...a11yProps(6)} />
        <Tab className={classes.tab} label="Vendors" {...a11yProps(7)} />
        <Tab
          className={classes.tab}
          label="Collection Name"
          {...a11yProps(8)}
        />
      </Tabs>
      <AssetLibraryTabPanel value={tabValue} index={0}>
        {memoizedAssetTable}
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={1}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_MATERIAL_MATERIAL}
          updateQuery={UPDATE_MATERIAL_MATERIAL}
          listQuery={MATERIAL_MATERIAL}
          listResultName="material_material"
          title="Material Materials"
          assetType="material"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={2}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_MATERIAL_TYPE}
          updateQuery={UPDATE_MATERIAL_TYPE}
          listQuery={MATERIAL_TYPE}
          listResultName="material_type"
          title="Material Types"
          assetType="type"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={3}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_MATERIAL_SETTING}
          updateQuery={UPDATE_MATERIAL_SETTING}
          listQuery={MATERIAL_SETTING}
          listResultName="material_setting"
          title="Material Settings"
          assetType="setting"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={4}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_MATERIAL_PRICE_TIER}
          updateQuery={UPDATE_MATERIAL_PRICE_TIER}
          listQuery={MATERIAL_PRICE_TIER}
          listResultName="material_price_tier"
          title="Material Price Tiers"
          assetType="price tier"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={5}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_MATERIAL_UNIT}
          updateQuery={UPDATE_MATERIAL_UNIT}
          listQuery={MATERIAL_UNIT}
          listResultName="material_unit"
          title="Material Units"
          assetType="unit"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={6}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_MATERIAL_STYLE}
          updateQuery={UPDATE_MATERIAL_STYLE}
          listQuery={MATERIAL_STYLE}
          listResultName="material_style"
          title="Material Styles"
          assetType="style"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={7}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_MATERIAL_VENDOR}
          updateQuery={UPDATE_MATERIAL_VENDOR}
          listQuery={MATERIAL_VENDOR}
          listResultName="material_vendor"
          title="Material Vendors"
          assetType="vendor"
        />
      </AssetLibraryTabPanel>
      <AssetLibraryTabPanel value={tabValue} index={8}>
        <SupplementaryTableForAssets
          insertQuery={INSERT_MATERIAL_COLLECTION_NAME}
          updateQuery={UPDATE_MATERIAL_COLLECTION_NAME}
          listQuery={MATERIAL_COLLECTION_NAME}
          listResultName="material_collection_name"
          title="Material Collection Name"
          assetType="collection name"
        />
      </AssetLibraryTabPanel>
    </div>
  );
}

export default SurfaceLibrary;
