import { Box } from "@material-ui/core";
import { YZButton } from "@yardzen-inc/react-common";
import * as React from "react";

interface ISubmitMaterialsForClientModalButtonGroupProps {
  onCancel: () => void;
  onSubmit: () => void;
  submitDisabled: boolean;
}

const SubmitMaterialsForClientModalButtonGroup: React.FunctionComponent<ISubmitMaterialsForClientModalButtonGroupProps> =
  ({ onCancel, onSubmit, submitDisabled }) => {
    return (
      <Box mt={2} display="flex" justifyContent="space-around">
        <YZButton variant="text" onClick={onCancel}>
          Cancel
        </YZButton>
        <YZButton
          variant="contained"
          color="primary"
          disabled={submitDisabled}
          onClick={onSubmit}
        >
          Submit
        </YZButton>
      </Box>
    );
  };

export { SubmitMaterialsForClientModalButtonGroup };
