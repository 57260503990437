import * as React from "react";
import {
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  useMediaQuery,
} from "@material-ui/core";
import { Profile } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";

interface Props {
  clientRecord: Profile;
}

export function ClientNotes({ clientRecord }: Props) {
  const [consultNotes, setConsultNotes] = React.useState<string>(
    clientRecord.consultNotes || ""
  ); // Renamed to "Build Notes" in UI
  const [designerNotes, setDesignerNotes] = React.useState<string>(
    clientRecord.designerNotes || ""
  );
  const [otherNotes, setOtherNotes] = React.useState<string>(
    clientRecord.otherNotes || ""
  );
  const smDown = useMediaQuery((theme) =>
    (theme as any).breakpoints.down("sm")
  );

  React.useEffect(() => {
    let timeout: any = null;

    timeout = setTimeout(() => {
      firebase.firestore().collection("profiles").doc(clientRecord.id).update({
        consultNotes,
        designerNotes,
        otherNotes,
      });
    }, 750);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [consultNotes, designerNotes, otherNotes]);

  return (
    <div>
      <Typography paragraph>
        <FormControl fullWidth margin={smDown ? "none" : "normal"}>
          <TextField
            label={"Build Notes"}
            id={"consultNotes"}
            name={"consultNotes"}
            onChange={(e) => setConsultNotes(e.target.value)}
            multiline={true}
            placeholder={"No notes yet"}
            value={consultNotes}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Typography>

      <Typography paragraph>
        <FormControl fullWidth margin={smDown ? "none" : "normal"}>
          <TextField
            label={"Designer Notes"}
            id={"designerNotes"}
            name={"designerNotes"}
            onChange={(e) => setDesignerNotes(e.target.value)}
            multiline={true}
            placeholder={"No notes yet"}
            value={designerNotes}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Typography>

      <Typography paragraph>
        <FormControl fullWidth margin={smDown ? "none" : "normal"}>
          <TextField
            label={"Other Notes"}
            id={"otherNotes"}
            name={"otherNotes"}
            onChange={(e) => setOtherNotes(e.target.value)}
            multiline={true}
            placeholder={"No notes yet"}
            value={otherNotes}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Typography>
    </div>
  );
}
