/**
 * This function accepts a date in either string or number (milliseconds) format
 * and returns a string declaring how long it has been since that date in hours
 * and minutes.
 *
 * @param date - A date to compare against the current time. This
 * should be passed in a format that is acceptable by the Date constructor.
 * @returns - A lowercase string telling how many hours and minutes have passed since
 * the date passed in to the function.
 */
function getTimeSinceDate(date: number | string): string {
  const now = new Date();
  const dateToCheck = new Date(date);
  let delta = now.getTime() - dateToCheck.getTime();

  delta = delta / 1000;

  const days = Math.floor(delta / (3600 * 24));
  delta -= days * 86400;

  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  return `${days} days and ${hours} hours`;
}

export { getTimeSinceDate };
export default getTimeSinceDate;
