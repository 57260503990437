/**
 * Capitlizes the first letter of every word in a string
 */
export default (string: string): string => {
  const words = string.split(" ");
  return words
    .map((word) => {
      const letter = word.slice(0, 1);
      return word.replace(letter.toLowerCase(), letter.toUpperCase());
    })
    .join(" ");
};
