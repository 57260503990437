import { ProfileProperties } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import genericAlert from "./genericAlert";

/*
  Accepts either profile ID (user ID) or profile

  Attempts to find an ID to tie design assignment to a client
  payment.

  Uses orderId (or squarespaceOrderId, which is also
  sometimes set to a yzOrderEvent doc id) to find a yzOrderEvents
  doc which contains either a Stripe Payment Intent ID or
  gift card doc ID (not yet rolled out).

  Failing that, will return the value of the profile squarespaceOrder ID
  so that a validator can investigate manually.

  Created for ticket ENG-945
*/

const getIdentifyingPaymentId = async (
  profileOrId: string | ProfileProperties
) => {
  try {
    const profile = await getProfile(profileOrId);
    if (!profile) {
      throw new Error(`No profile found for ${profileOrId}`);
    }
    // @ts-ignore
    const orderId = profile["orderId"];
    // @ts-ignore
    const squarespaceOrderId = profile["squarespaceOrderId"];

    if (!orderId && !squarespaceOrderId) {
      throw new Error(`No order ID found for profile ${profileOrId}`);
    }

    const [paymentIntentId, giftCardDocId] = await getYZOrderPaymentInfo(
      orderId
    );

    // yzOrderEvent doesn't exist (squarespace customer). Still searchable in Stripe
    // & shows up on CSV export
    if (!paymentIntentId && !giftCardDocId) {
      return `Squarespace Order Number: ${squarespaceOrderId}`;
    }

    // Order was placed with a giftcard
    if (!!giftCardDocId && !paymentIntentId) {
      return `Gift card ID: ${giftCardDocId}`;
    }
    // yzOrderEvent was found and we can display the paymentIntentId from Stripe
    return `Payment Intent ID: ${paymentIntentId}`;
  } catch (error) {
    if (
      // @ts-ignore
      !error.message.includes("Missing or insufficient permissions.") &&
      // @ts-ignore
      !error.message.includes("No order ID")
    ) {
      await genericAlert(`Error retrieving payment ID: ${error}`);
    }

    return "Not found";
  }
};

async function getYZOrderPaymentInfo(
  yzOrderEventId: string
): Promise<[string?, string?]> {
  if (!yzOrderEventId) return [undefined, undefined];
  const doc = await (
    await firebase
      .firestore()
      .collection("yzOrderEvents")
      .doc(yzOrderEventId)
      .get()
  ).data();

  if (!doc) {
    return [undefined, undefined];
  }

  return [
    doc["paymentIntentId"] || undefined,
    doc["giftCardDocId"] || undefined,
  ];
}

async function getProfile(
  profileOrId: string | ProfileProperties
): Promise<ProfileProperties | undefined> {
  try {
    if (!profileOrId) {
      throw new Error("no-profile-or-id");
    }
    if (typeof profileOrId === "string") {
      return (
        await firebase.firestore().collection("profiles").doc(profileOrId).get()
      ).data() as ProfileProperties | undefined;
    } else {
      return profileOrId;
    }
  } catch (error) {
    throw new Error(`error-fetching-profile: ${error}`);
  }
}

export { getIdentifyingPaymentId };
