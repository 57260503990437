export const GET_BUILD_REGION_BY_ID = `
  query GetBuildRegionById($input: FindOneBuildRegionInput!){
    buildRegion(input: $input) {
      id
      displayName
      isDefault
      areaGeometry
      minLat
      maxLat
      minLng
      maxLng
      updatedTs
    }
  }
`;

export const UPDATE_BUILD_REGION_BY_ID = `
  mutation UpdateBuildRegion($input: UpdateBuildRegionInput!){
    updateBuildRegion(updateBuildRegionInput: $input) {
      ok
    }
  }
`;

export const CREATE_BUILD_REGION = `
  mutation CreateBuildRegion($input: CreateBuildRegionInput!){
    createBuildRegion(createBuildRegionInput: $input) {
      id
    }
  }
`;

export const GET_BUILD_REGIONS_BY_PROJECT_LOCATION = `
  query GetBuildRegionsByProjectCoordinates($input: FindAllBuildRegionInput!){
    buildRegions(input: $input) {
      id
    }
  }
`;

export const GET_ACCOUNT_MANAGERS = `
  query GetAccountManagers {
    accountManagers {
      id
      projectManagerId
      email
      userId
    }
  }`;

export const GET_BUILD_REGIONS_WITH_ACCOUNT_MANAGER_ID = `
  query GetBuildRegions($input: FindAllBuildRegionInput!) {
    buildRegions(input: $input){
      id
      internalName
      displayName
      accountManager {
        id
        projectManagerId
      }
      isDefault
      archived
    }
  }`;

export const UPDATE_ACCOUNT_MANAGER = `
  mutation UpdateAccountManager($input: UpdateAccountManagerInput!) {
    updateAccountManager(input: $input) {
      id
    }
  }`;

export const CREATE_ACCOUNT_MANAGER = `
  mutation CreateAccountManager($input: CreateAccountManagerInput!) {
    createAccountManager(input: $input) {
      id
    }
  }`;

export const GET_ACCOUNT_MANAGER = `
  query FindOneAccountManager($id: String!){
    accountManager(id:$id) {
      id
      buildRegions {
        id
        displayName
      }
    }
  }`;

export const SET_CONTRACTOR_USER_CLAIMS = `
  mutation SetContractorUserClaims($input: SetContractorUserClaimsInput!) {
    setContractorUserClaims(input: $input)
  }`;
