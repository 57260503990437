import * as React from "react";
import { Assignment } from "@yardzen-inc/models";

export type AssignmentsContext = {
  assignments: Assignment[];
  loading: boolean;
  fetch: (forceFetch?: boolean) => void;
  remove: (id: string) => void;
  update: (assignment: Assignment) => void;
};

const AssignmentsCtx = React.createContext<AssignmentsContext>({
  assignments: [],
  loading: false,
  fetch: (forceFetch?: boolean) => void 0,
  remove: (id: string) => void 0,
  update: (assignment: Assignment) => void 0,
});

export default AssignmentsCtx;

export const AssignmentsProvider = React.memo((props: any) => {
  const [assignments, setAssignments] = React.useState<Assignment[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <AssignmentsCtx.Provider
      value={{ assignments, fetch, loading, remove, update }}
    >
      {props.children}
    </AssignmentsCtx.Provider>
  );

  async function fetch(forceFetch?: boolean) {
    if (!loading) {
      setLoading(true);
    }

    if (!!assignments.length && !forceFetch) {
      setLoading(false);
      return;
    }

    try {
      setAssignments(await Assignment.fetchAll(null, null, null, false));
    } catch (err) {
      console.error(err);
      console.error("GOTEM");
    }
    setLoading(false);
  }

  function remove(id: string) {
    const i = assignments.findIndex((a) => a.id === id);

    if (i >= 0) {
      assignments.splice(i, 1);
      setAssignments([...assignments]);
    }
  }

  function update(assignment: Assignment) {
    const i = assignments.findIndex((a) => a.id === assignment.id);

    if (i >= 0) {
      assignments.splice(i, 1, assignment);
      setAssignments([...assignments]);
    }
  }
});
