import { Box, BoxProps, Typography, TypographyProps } from "@material-ui/core";
import React, { FC } from "react";

export interface NamedFieldProps extends BoxProps {
  label: string;
  value: string;
  labelProps?: TypographyProps;
  valueProps?: TypographyProps;
}

export const NamedField: FC<NamedFieldProps> = ({
  label,
  value,
  labelProps,
  valueProps,
  ...containerProps
}) => {
  return (
    <Box {...containerProps}>
      <Typography {...labelProps} variant="caption">
        {label}
      </Typography>
      <Typography {...valueProps} variant="body1">
        {value}
      </Typography>
    </Box>
  );
};
