import {
  MATERIAL_TYPE_SELECT,
  MATERIAL_STYLE_SELECT,
  MATERIAL_VENDOR_SELECT,
  MATERIAL_SETTING_SELECT,
  MATERIAL_MATERIAL_SELECT,
  MATERIAL_PRICE_TIER_SELECT,
} from "@yardzen-inc/graphql";
import { IFilterBoxOption } from "../../../Interfaces";
import {
  MATERIAL_RETAILER_SELECT,
  PRODUCT_REGION_SELECT,
} from "../Shared/graphqlQueries";

const SurfaceLibraryFilterBoxOptions: IFilterBoxOption[] = [
  {
    label: "Type",
    query: MATERIAL_TYPE_SELECT,
    listResultName: "material_type",
  },
  {
    label: "Style",
    query: MATERIAL_STYLE_SELECT,
    listResultName: "material_style",
  },
  {
    label: "Vendor",
    query: MATERIAL_VENDOR_SELECT,
    listResultName: "material_vendor",
  },
  {
    label: "Setting",
    query: MATERIAL_SETTING_SELECT,
    listResultName: "material_setting",
  },
  {
    label: "Material",
    query: MATERIAL_MATERIAL_SELECT,
    listResultName: "material_material",
  },
  {
    label: "Price Tier",
    query: MATERIAL_PRICE_TIER_SELECT,
    listResultName: "material_price_tier",
  },
  {
    label: "Region",
    query: PRODUCT_REGION_SELECT,
    listResultName: "product_region",
  },
  {
    label: "Retailer",
    query: MATERIAL_RETAILER_SELECT,
    listResultName: "material_retailer",
  },
];

export default SurfaceLibraryFilterBoxOptions;
