import * as React from "react";
import { Project, Profile } from "@yardzen-inc/models";

export interface Props {
  type: "v1" | "v2" | "correction" | "extra";
  project?: string | Project;
  profile?: string | Profile;
}

export const DesignAssignmentSlug = (props: Props) => {
  const [slug, setSlug] = React.useState<string | null>(null);

  React.useEffect(typeGuard);
  React.useEffect(getSlug, [props.profile ?? props.project]);

  return <>{slug ?? "Loading..."}</>;

  function getSlug() {
    void (async function () {
      const { profile, type, project } = props;
      let lastName!: string;
      let streetName!: string;

      if (profile) {
        await getPropsFromProfile(profile);
      } else if (project) {
        let proj: Project;

        if (typeof project === "string") {
          proj = await Project.fetch(project);
        } else {
          proj = project;
        }

        await getPropsFromProfile(proj.profileId);
      } else {
        throw new Error("F");
      }

      setSlug(`${lastName}_${streetName}_${type}`.split(" ").join("-"));

      async function getPropsFromProfile(p: string | Profile) {
        let pro: Profile;

        if (typeof p === "string") {
          pro = await Profile.get(p);
        } else {
          pro = p;
        }

        lastName = pro.lastName as string;
        streetName = pro.street as string;
      }
    })();
  }

  function typeGuard() {
    if (!props.profile && !props.project) {
      throw new Error(
        "DesignAssignment slug needs either a project or a profile id or instance"
      );
    }
  }
};

export default DesignAssignmentSlug;
