import React, {
  FC,
  useContext,
  useMemo,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { DesignBriefCtx, UseDesignBriefLoadingState } from "./util";
import Box, { BoxProps } from "@material-ui/core/Box";
import { Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import { useSkipMount } from "../../util/hooks";
export interface ChecklistAwknowledgementsProps extends BoxProps {}

const ChecklistAwknowledgements: FC<ChecklistAwknowledgementsProps> = ({
  ...containerProps
}) => {
  const initialRunComplete = useSkipMount();
  const dsCtxValue = useContext(DesignBriefCtx);

  const [acknowledgements, setAcknowledgements] = useState<{
    [key: string]: { value: boolean };
  } | null>(dsCtxValue.meta?.acknowledges ?? null);

  const acknowledgementKeys = useMemo(
    () => (acknowledgements ? Object.keys(acknowledgements) : null),
    [acknowledgements]
  );

  useEffect(onAcknowledgmentsChange, [dsCtxValue.meta?.acknowledges]);

  if (
    !acknowledgementKeys ||
    dsCtxValue.loadingState !== UseDesignBriefLoadingState.DONE
  ) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" {...containerProps}>
      <Box pb={1}>
        <Typography>REVIEW PROJECT INFORMATION</Typography>
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {renderAcknowledgements()}
      </Box>
    </Box>
  );

  function onAcknowledgmentsChange(): void {
    if (!initialRunComplete) {
      return;
    }

    setAcknowledgements(dsCtxValue.meta?.acknowledges ?? null);
  }

  function renderAcknowledgements(): ReactNode[] {
    if (!acknowledgementKeys || !acknowledgements) {
      throw new Error("tried to render acknowledgements without meta");
    }

    return acknowledgementKeys.map((key, i) => {
      return (
        <Box maxWidth="33.3%" width="33.3%">
          <Box pl={2} display="flex" flexDirection="row" width="100%">
            <Box>
              <FormControlLabel
                label={key}
                control={
                  <Checkbox
                    checked={acknowledgements[key].value}
                    onClick={handleUpdate}
                  />
                }
              ></FormControlLabel>
            </Box>
          </Box>
        </Box>
      );

      async function handleUpdate() {
        if (!acknowledgementKeys || !acknowledgements) {
          throw new Error("tried to render acknowledgements without meta");
        }

        const checked = acknowledgements[key].value;

        acknowledgements[key].value = !checked;

        const newAck = { ...acknowledgements };

        const updateProm = dsCtxValue.api.updateMetadata(
          "acknowledges",
          newAck
        );

        setAcknowledgements(newAck);

        await updateProm;
      }
    });
  }
};

export { ChecklistAwknowledgements };
export default ChecklistAwknowledgements;
