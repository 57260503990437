import {
  SelectedItemsElement,
  SelectedItemsMaterial,
  SelectedItemsPlant,
} from "../../../Components/NonPmAssetSelection/constants/SelectedItemsConstants";
import { getSelectedPlantsForBudgetQA } from "../../../Components/NonPmAssetSelection/getSelectedPlantsForBudgetQA";
import { getSelectedElementsForBudgetQA } from "../../../Components/NonPmAssetSelection/getSelectedElementsForBudgetQA";
import { getSelectedMaterialsForBudgetQA } from "../../../Components/NonPmAssetSelection/getSelectedMaterialsForBudgetQA";
import { useLazyQuery } from "@yardzen-inc/graphql";
import { useEffect, useState } from "react";
import { QUERY_SELECTION_FOR_BUDGET_QA } from "../../../../src/graphql/budgetQAQueries";
import { useGetGeoZones } from "../../../../src/util/hooks/useGetGeoZones";
import { Profile } from "@yardzen-inc/models";

interface UseGetBudgetQADataForSharedMediaArgs {
  designAssignmentId: string | undefined;
  designAssignmentIdIsLoading: boolean;
  profile: Profile;
}

export interface GetBudgetQADataArgs {
  includePlants: boolean;
  includeElements: boolean;
  includeMaterials: boolean;
}

interface DesignAssignmentItems {
  plants: SelectedItemsPlant[];
  elements: SelectedItemsElement[];
  materials: SelectedItemsMaterial[];
}

export interface GetBudgetQADataResult {
  selectedPlants: SelectedItemsPlant[] | null;
  selectedElements: SelectedItemsElement[] | null;
  selectedMaterials: SelectedItemsMaterial[] | null;
}

type GetBudgetQADataForSharedMediaFn = (
  args: GetBudgetQADataArgs
) => GetBudgetQADataResult;

/**
 * Hook that returns a function which will retrieve the plants, materials,
 * and elements budget QA items for usage on the shared media page.
 * @param designAssignmentId The design assignment id for the project.
 * @returns A function that returns an object with data on the budget QA
 * elements, materials, and plants for the shared media page.
 */
export const useGetBudgetQADataForSharedMedia = ({
  designAssignmentId,
  designAssignmentIdIsLoading,
  profile,
}: UseGetBudgetQADataForSharedMediaArgs): GetBudgetQADataForSharedMediaFn => {
  const { geoZones } = useGetGeoZones(profile);
  const [assignmentItems, setAssignmentItems] = useState<DesignAssignmentItems>(
    {
      plants: [],
      elements: [],
      materials: [],
    }
  );

  const getPlants = (queryData: any): SelectedItemsPlant[] => {
    return getSelectedPlantsForBudgetQA(
      queryData,
      false,
      (qty: number, id: string) => {},
      false,
      "",
      [],
      new Set(geoZones?.map?.((geo) => geo.name))
    );
  };

  const getElements = (queryData: any): SelectedItemsElement[] => {
    return getSelectedElementsForBudgetQA(
      queryData,
      false,
      (qty: number, id: string) => {},
      false,
      ""
    );
  };

  const getMaterials = (queryData: any): SelectedItemsMaterial[] => {
    return getSelectedMaterialsForBudgetQA(
      queryData,
      false,
      (qty: number, id: string) => {},
      false,
      ""
    );
  };

  const [
    getSelection,
    { data: queryDataSelection, loading: queryDataLoading },
  ] = useLazyQuery(QUERY_SELECTION_FOR_BUDGET_QA, {
    fetchPolicy: "network-only",
    onError: () => alert("Error fetching selections for Budget QA"),
    onCompleted: () => {
      setAssignmentItems({
        elements: getElements(queryDataSelection),
        plants: getPlants(queryDataSelection),
        materials: getMaterials(queryDataSelection),
      });
    },
  });

  useEffect(() => {
    if (
      !designAssignmentIdIsLoading &&
      designAssignmentId &&
      !queryDataLoading
    ) {
      getSelection({ variables: { id: designAssignmentId } });
    }
  }, [designAssignmentId, designAssignmentIdIsLoading, getSelection]);

  /**
   * Formats a budget QA item so it can be stored in firestore.
   * @param item The item to format.
   * @returns An item with undefined values converted to nulls, and JSX
   * elements converted to strings.
   */
  const formatBudgetQAItem = (
    item: SelectedItemsElement | SelectedItemsMaterial | SelectedItemsPlant
  ): SelectedItemsElement | SelectedItemsMaterial | SelectedItemsPlant => {
    // We need to set these as strings because they can be JSX elements which
    // are invalid firestore values.
    if (item.lumionFileLink) {
      item.lumionFileLink = JSON.stringify(item.lumionFileLink);
    }

    if (item.sketchupFileLink) {
      item.sketchupFileLink = JSON.stringify(item.sketchupFileLink);
    }

    // The rest of the cleanup will format any undefined values as they are
    // also invalid firestore values, whereas null is a valid value. We use
    // this typing to get rid of TS related errors.
    const itemCopy: any = item;
    for (const [key, value] of Object.entries(itemCopy)) {
      if (value === undefined) {
        itemCopy[key] = null;
      }
    }

    return itemCopy;
  };

  const getBudgetQAData = ({
    includePlants,
    includeElements,
    includeMaterials,
  }: GetBudgetQADataArgs): GetBudgetQADataResult => {
    const dataForSharedMedia: GetBudgetQADataResult = {
      selectedPlants: null,
      selectedElements: null,
      selectedMaterials: null,
    };

    if (includePlants) {
      dataForSharedMedia.selectedPlants = assignmentItems.plants.map(
        (plant) => formatBudgetQAItem(plant) as SelectedItemsPlant
      );
    }

    if (includeElements) {
      dataForSharedMedia.selectedElements = assignmentItems.elements.map(
        (element) => formatBudgetQAItem(element) as SelectedItemsElement
      );
    }

    if (includeMaterials) {
      dataForSharedMedia.selectedMaterials = assignmentItems.materials.map(
        (material) => formatBudgetQAItem(material) as SelectedItemsMaterial
      );
    }

    return dataForSharedMedia;
  };

  return getBudgetQAData;
};
