import * as React from "react";
import { Paper, makeStyles } from "@material-ui/core";
import NestableDrawerListItem from "../Components/NestableDrawerListItem";
import { Assignment } from "@yardzen-inc/models";

const useStyles = makeStyles({
  root: {
    borderRadius: "0",
    width: "265px",
    minWidth: "265px",
    borderRight: "1px solid #e0e0e0",
  },
  inner: {
    height: "100%",
    maxHeight: "100%",
    display: "flex",
    flexFlow: "column nowrap",
  },
  list: {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: 1,
  },

  contactBox: {
    padding: "1rem",
  },
});

interface Props {
  index: number;
  setIndex: (i: number) => void;
  selectedAssignment: Assignment | null;
  selectAssignment: (a: Assignment) => void;
  assigned: Assignment[];
}

export default (props: Props) => {
  const classes = useStyles();
  // const agent = React.useContext(AgentCtx) as Agent;

  const handleListItemClick = (i: number) => () => {
    props.setIndex(i);
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.inner}>
        <div className={classes.list}>
          <NestableDrawerListItem
            selected={props.index === 0}
            onClick={handleListItemClick(0)}
            selectedSublist={
              props.selectedAssignment ? props.selectedAssignment.id : undefined
            }
            sublistItems={props.assigned.map((a) => ({
              label: a.title,
              key: a.id,
              onClick: () => props.selectAssignment(a),
            }))}
          >
            Current Assignments
          </NestableDrawerListItem>
        </div>
        <div className={classes.contactBox}></div>
      </div>
    </Paper>
  );
};
