import * as React from "react";
import { makeStyles } from "@material-ui/core";
import Metrics from "./Metrics";

function index({ projects }: any) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Metrics />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    padding: "1rem",
  },
});

export default index;
