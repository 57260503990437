export type HasuraQueryOperator = "_eq" | "_neq";

export type HasuraQueryHumanReadableOperator =
  | "has any of"
  | "has none of"
  | "has all of"
  | "is empty";

export enum HasuraQueryHumanReadableOperatorEnum {
  hasAnyOf = "has any of",
  hasNoneOf = "has none of",
  hasAllOf = "has all of",
  isEmpty = "is empty",
}

export enum HasuraQueryProcessConditionType {
  array = "array",
  object = "object",
}

const OperatorEnumToOperatorMap = {
  [HasuraQueryHumanReadableOperatorEnum.hasNoneOf]: "_neq",
  [HasuraQueryHumanReadableOperatorEnum.hasAllOf]: "_eq",
  [HasuraQueryHumanReadableOperatorEnum.hasAnyOf]: "_neq",
  [HasuraQueryHumanReadableOperatorEnum.isEmpty]: "_eq",
};

export interface HasuraQueryProcessAndConditionsWithLinks {
  condition: any;
  operator: HasuraQueryHumanReadableOperatorEnum;
  fields: { data: any; links: string; fieldName: string }[];
}

export interface HasuraQueryProcessAndConditionsNoLinks {
  condition: any;
  operator: HasuraQueryHumanReadableOperatorEnum;
  fields: { data: string[]; fieldName: string }[];
}

export interface HasuraQueryProcessConditions {
  dataType: HasuraQueryProcessConditionType;
}

class HasuraQueryFunctions {
  /**
   * @summary
   * this is useful when you need to do "_and" queries with the hasura/postgres DB. "_and" queries need to be nested,
   * so this'll construct the nests for you.
   */
  public static processAndConditionsWithLinks(
    args: HasuraQueryProcessAndConditionsWithLinks
  ): void {
    // clear out useless fields:
    args.fields = args.fields.filter((field) => !!field.data.length);
    let target = args.condition;
    for (const element of args.fields) {
      // we use a while loop here so if we are merging multiple .processAndConditionsNoLinks calls, they get built on top of each other.
      while (target._and) {
        target = target._and;
      }
      const field = element;
      switch (args.operator) {
        case HasuraQueryHumanReadableOperatorEnum.hasAnyOf: {
          if (field.data.length) {
            target._and = {
              [field.links]: {
                [field.fieldName]: {
                  _in: field.data,
                },
              },
            };
          }
          break;
        }
        case HasuraQueryHumanReadableOperatorEnum.hasNoneOf: {
          if (!args.fields.length || !field.data.length) break;
          target._and = {};
          target = target._and;
          target._not = {};
          target._not[field.links] = {
            [field.fieldName]: {
              _in: field.data,
            },
          };
          break;
        }
        case HasuraQueryHumanReadableOperatorEnum.hasAllOf: {
          for (let j = 0; j < field.data.length; j++) {
            target._and = {
              [field.links]: {
                [field.fieldName]: {
                  _eq: field.data[j],
                },
              },
            };
            target = target._and;
          }
          break;
        }
        default:
        // do nothing
      }
    }
  }

  public static processAndConditionsNoLinks(
    args: HasuraQueryProcessAndConditionsNoLinks
  ): void {
    // clear out useless fields:
    args.fields = args.fields.filter((field) => !!field.data.length);
    let target = args.condition;
    for (let i = 0; i < args.fields.length; i++) {
      // we use a while loop here so if we are merging multiple .processAndConditionsNoLinks calls, they get built on top of each other.
      while (target._and) {
        target = target._and;
      }
      const field = args.fields[i];
      switch (args.operator) {
        case HasuraQueryHumanReadableOperatorEnum.hasAnyOf: {
          if (field.data.length) {
            target._and = {
              [field.fieldName]: {
                _in: field.data,
              },
            };
          }
          break;
        }
        case HasuraQueryHumanReadableOperatorEnum.hasNoneOf: {
          if (!args.fields.length || !field.data.length) break;
          target._and = {};
          target = target._and;
          target._not = {
            [field.fieldName]: {
              _in: field.data,
            },
          };
          break;
        }
        case HasuraQueryHumanReadableOperatorEnum.hasAllOf: {
          for (let j = 0; j < field.data.length; j++) {
            target._and = {
              [field.fieldName]: {
                [OperatorEnumToOperatorMap[args.operator]]: field.data[j],
              },
            };
            target = target._and;
          }
          break;
        }
        default:
        // nothing to do
      }
    }
  }

  public static processConditions(
    args:
      | HasuraQueryProcessConditions
      | HasuraQueryProcessAndConditionsWithLinks
      | HasuraQueryProcessAndConditionsNoLinks
  ): void {
    switch ((args as HasuraQueryProcessConditions).dataType) {
      case HasuraQueryProcessConditionType.array: {
        this.processAndConditionsWithLinks(
          args as HasuraQueryProcessAndConditionsWithLinks
        );
        break;
      }
      case HasuraQueryProcessConditionType.object: {
        this.processAndConditionsNoLinks(
          args as HasuraQueryProcessAndConditionsNoLinks
        );
        break;
      }
    }
  }
}

export { HasuraQueryFunctions };
export default HasuraQueryFunctions;
