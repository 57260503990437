import * as React from "react";
import { makeStyles, Divider, Typography, Button } from "@material-ui/core";
import { NewProfile } from "@yardzen-inc/models";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    padding: "1rem",
  },
  col: {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: 1,
  },
  row: {
    display: "flex",
    flexFlow: "row nowrap",
  },
});

interface Props {
  record: any;
  onClick: () => void;
  renderCallStatus: (record: NewProfile) => React.ReactElement;
}

export default ({ record, onClick, renderCallStatus }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        className={classes.col}
      >
        <div>
          <Typography variant="caption">Name</Typography>
          <Typography variant="body1">
            {!record.firstName || !record.lastName
              ? "Not Provided"
              : `${record.firstName} ${record.lastName}`}
          </Typography>
        </div>
        <div> {renderCallStatus(record)}</div>
      </div>
      <br />
      <div className={classes.col}>
        <Typography variant="caption">Address</Typography>
        <Typography variant="body1">
          {!record.street || !record.city || !record.state || !record.zip
            ? "Not Provided"
            : `${record.street} ${record.city}, ${record.state}, ${record.zip}`}
        </Typography>
      </div>
      <br />
      <div className={classes.row}>
        <div className={classes.col}>
          <Typography variant="caption">status</Typography>
          <Typography
            style={{ color: record.contacted ? "green" : "red" }}
            variant="body1"
          >
            {record.contacted ? "Contacted" : "Not Contacted"}
          </Typography>
        </div>
        <div className={classes.col}>
          <Typography variant="caption">status</Typography>
          <Typography
            style={{ color: record.paid ? "green" : "red" }}
            variant="body1"
          >
            {record.paid ? "PAID" : "NOT PAID"}
          </Typography>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.col}>
          <Button variant="text" onClick={onClick}>
            View
          </Button>
        </div>
      </div>
      <Divider></Divider>
    </div>
  );
};
