import { Box, TextField } from "@material-ui/core";
import React from "react";
import { CloudUpload } from "@material-ui/icons";
import { Media } from "@yardzen-inc/models";
import { UserCtx } from "../../../util/UserContext";
import { IFormikElementErrorProps } from "../../../Interfaces";
import { useFormikContext } from "formik";
import { YZTypography } from "@yardzen-inc/react-common";

interface Props {
  formikName: string;
  onImageUploaded: (path: string) => void;
}

function AssetLibraryImageUpload(props: Props) {
  const [inputLocked, setInputLocked] = React.useState<boolean>(false);

  const [value, setValue] = React.useState<string>("");

  const UserContext = React.useContext(UserCtx);

  const { errors: formikErrors, setFieldValue } = useFormikContext() as {
    errors: Partial<IFormikElementErrorProps>;
    setFieldValue: (v: string, val: any) => void;
  };

  const onUploadProgress = (
    file: () => any,
    snapshot: firebase.storage.UploadTaskSnapshot
  ) => {
    if (snapshot.ref.fullPath) {
      setFieldValue(props.formikName, true);
      props.onImageUploaded(
        `${process.env.REACT_APP_IMAGE_DOMAIN}/${snapshot.ref.fullPath}`
      );
    }
  };

  const handleUpload = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0];
    if (!file) return;
    setValue(file.name);
    const media = new Media({
      file: file,
      // @ts-ignore
      userId: UserContext?.uid,
      visibility: {},
    });
    media.store(
      onUploadProgress as () => any,
      "",
      "asset-library-image",
      // @ts-ignore
      UserContext?.uid,
      undefined
    );
  };

  return (
    <Box>
      <input
        type="file"
        id={"image-component"}
        hidden
        accept={"image/*"}
        onChange={async (ev) => {
          setInputLocked(true);
          try {
            await handleUpload(ev);
          } catch {}
          setInputLocked(false);
        }}
        disabled={inputLocked}
      />
      <label htmlFor={"image-component"} id={"image-component"}>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
        >
          <Box p={1}>
            <CloudUpload />
          </Box>
          <TextField
            value={inputLocked ? "Uploading..." : value || "No File Uploaded"}
            label="Image"
            disabled
            onClick={() => document.getElementById("image-component")?.click()}
            fullWidth
          />
        </Box>
      </label>
      <YZTypography style={{ color: "red" }}>
        {
          // @ts-ignore
          formikErrors[props.formikName]
        }
      </YZTypography>
    </Box>
  );
}

export default AssetLibraryImageUpload;
