import { useState, useMemo, useRef, useEffect } from "react";
import { DocProp } from "./types";

export interface UpdateTagCloud {
  (tag: DocProp): void;
}

export interface UseTagCloud {
  (
    initialSelected: DocProp[],
    initialDeselected: DocProp[]
  ): UseTagCloudReturnResult;
}

export type UseTagCloudReturnResult = [DocProp[], DocProp[], UpdateTagCloud];

/*
  useTagCloud effectively operates as a combined
  useStateHook for tagCloud elements. The update method
  functions as a tooggle, moving the provided tag from
  selected to deselected or vice versa.
*/
const useTagCloud: UseTagCloud = (initialSelected, initialDeselected) => {
  const [selected, setSelected] = useState<DocProp[]>(initialSelected);
  const [deselected, setDeselected] = useState<DocProp[]>(initialDeselected);

  const firstRun = useRef<boolean>(false);

  useEffect(() => {
    if (!firstRun.current) {
      firstRun.current = true;
      return;
    }

    setSelected(initialSelected);
    setDeselected(initialDeselected);
  }, [initialDeselected, initialSelected]);

  const updateTagCloud = useMemo(getUpdateTagCloud, [selected, deselected]);

  return [selected, deselected, updateTagCloud];

  // closure returning UpdateTagCloud function;
  function getUpdateTagCloud(): UpdateTagCloud {
    return (tag: DocProp) => {
      /*
        finds the provided tag within one of the
        tag collections. Copies both of them, and
        then mutates both of them to reflect the
        passing of the tag from one collection
        to the other before updating component
        state.
      */
      let tagIndex = selected.indexOf(tag);

      if (tagIndex >= 0) {
        const newSelected = [...selected];
        newSelected.splice(tagIndex, 1);
        setSelected(newSelected);

        const newDeselected = [...deselected];
        newDeselected.push(tag);
        setDeselected(newDeselected);
      }

      tagIndex = deselected.indexOf(tag);

      if (tagIndex >= 0) {
        const newDeselected = [...deselected];
        newDeselected.splice(tagIndex, 1);
        setDeselected(newDeselected);

        const newSelected = [...selected];
        newSelected.push(tag);
        setSelected(newSelected);
      }
    };
  }
};

export { useTagCloud };
export default useTagCloud;
