import { useState, useEffect } from "react";
import { Agent } from "@yardzen-inc/models";

const useDesigner = (id?: string | null): null | Agent => {
  const [designer, setDesigner] = useState<Agent | null>(null);

  useEffect(getDesigner, [id]);

  return designer;

  function getDesigner(): void {
    if (!id) {
      return setDesigner(null);
    }

    void (async function () {
      let designer = null;

      try {
        designer = await Agent.fetch(id);
      } catch {}

      setDesigner(designer);
    })();
  }
};

export { useDesigner };
export default useDesigner;
