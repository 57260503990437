import React, { FC, useContext, ReactNode } from "react";
import { DesignBriefCtx, UseDesignBriefLoadingState } from "./util";
import { Box } from "@material-ui/core";
import DesignBriefChecklistGroup from "./DesignBriefChecklistGroup";
import environmentConstants from "../../ConstantValues/environmentConstants";
import { YZTypography } from "@yardzen-inc/react-common";

export interface DesignBriefChecklistProps {
  pm: boolean;
  geoZones: string;
}

const DesignBriefChecklist: FC<DesignBriefChecklistProps> = (props) => {
  const dbCtxValue = useContext(DesignBriefCtx);

  if (dbCtxValue.loadingState !== UseDesignBriefLoadingState.DONE) {
    return null;
  }

  const [doneCheckingGeo, setDoneCheckingGeo] = React.useState(false);

  const updateEntry = async (id: string, value: string) => {
    try {
      await dbCtxValue.api.updateEntry({ id: id, value });
      setDoneCheckingGeo(true);
    } catch (err) {
      setDoneCheckingGeo(true);
    }
  };

  React.useEffect(() => {
    if (!dbCtxValue) return;

    if (!dbCtxValue.data) {
      setDoneCheckingGeo(true);
      return;
    }

    // @ts-ignore
    const target = dbCtxValue.data.PLANT_PREFERENCES.find((preference) =>
      // @ts-ignore
      /adapted habitat/i.test(preference.label)
    );
    if (
      !target ||
      !props.geoZones ||
      // value already exists, leave alone
      !!target.value
    ) {
      setDoneCheckingGeo(true);
      return;
    }
    target.value = props.geoZones;
    updateEntry(target.id, props.geoZones);
  }, [dbCtxValue]);

  if (!doneCheckingGeo) return <YZTypography>Please wait...</YZTypography>;

  return (
    <>
      <Box display="flex" flexDirection="column" px={2}>
        {renderGroups()}
      </Box>
    </>
  );

  function renderGroups(): ReactNode[] | null {
    const { data, meta } = dbCtxValue;

    if (!data) {
      return null;
    }

    let keys: string[];

    if (meta?.order) {
      if (props.pm) {
        keys = meta.order;
      } else {
        keys = meta.order.filter((k) => {
          if (!(k in data)) {
            if (
              process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT
            ) {
              console.error("🚨🚨🚨🚨🚨🚨🚨🚨 no " + k + " from order in data");
            }

            return false;
          }

          return true;
        });

        Object.keys(data).forEach((k) => {
          if (!keys.includes(k)) {
            keys.push(k);
          }
        });
      }
    } else {
      keys = Object.keys(data);
    }

    return keys.map((groupName) => {
      return (
        <DesignBriefChecklistGroup
          key={`checklist_group_${groupName}`}
          entries={(dbCtxValue.data as any)[groupName] ?? []}
          title={replaceUndescoresWithSpaces(groupName)}
          pm={props.pm}
          api={dbCtxValue.api}
        />
      );
    });
  }
};

// TODO: implement re-sorting functionality

function replaceUndescoresWithSpaces(str: string): string {
  return str.replace(/_/g, " ");
}

export { DesignBriefChecklist };
export default DesignBriefChecklist;
