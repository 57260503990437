import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ProfileProperties as Profile } from "@yardzen-inc/models";

const profileAdapter = createEntityAdapter<Profile>();

export const profilesSlice = createSlice({
  name: "profiles",
  initialState: profileAdapter.getInitialState(),
  reducers: {
    setProfile: profileAdapter.setOne,
  },
});

export const { setProfile } = profilesSlice.actions;

export const selectProfileById = (state: RootState, id: string) =>
  state.entities.profiles.entities[id];

export const profilesReducer = profilesSlice.reducer;
