import * as React from "react";
import { makeStyles, Tabs, Tab, AppBar, Theme } from "@material-ui/core";
import FilterBar from "./FilterBar";
import AgentCardGrid from "./AgentCardGrid";
import UnassignedTable from "./UnassignedTable";
import Unpaid from "./Unpaid";
import { Route, Switch } from "react-router-dom";
import { useRouteMatch, useHistory, useLocation } from "react-router";
import AgentGroupsPanel from "./AgentGroupsPanel";
import AgentPodsContainer from "./AgentPods/AgentPodsContainer";

// TODO: come back and optimize fetching and filtering logic
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "95%",
    paddingRight: 0,
  },
}));

export default () => {
  const classes = useStyles();
  const [filter, setFilter] = React.useState<string>("");

  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const tabValue = React.useMemo(() => {
    return (
      location.pathname.substring(match.url.length + 1).split("/")?.[0] ?? ""
    );
  }, [location.pathname, match.url]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={`${tabValue}`}
          // @ts-ignore
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable auto tabs example"
        >
          <Tab {...a11yProps(0)} label="All" value="" />
          <Tab {...a11yProps(1)} label="Modelers" value="modeling" />
          <Tab {...a11yProps(2)} label="Designers" value="design" />
          <Tab {...a11yProps(3)} label="Wesleys" value="wesley" />
          <Tab {...a11yProps(4)} label="Unpaid" value="unpaid" />
          <Tab {...a11yProps(5)} label="Unassigned" value="unassigned" />
          <Tab {...a11yProps(6)} label="Decomissioned" value="disabled" />
          <Tab {...a11yProps(7)} label="groups" value="groups" />
          <Tab {...a11yProps(8)} label="teams" value="teams" />
        </Tabs>
      </AppBar>

      {tabValue !== "groups" && tabValue !== "teams" && (
        <FilterBar
          value={filter}
          onChange={({ target: { value } }) => setFilter(value)}
        />
      )}
      {getComponent()}
    </div>
  );

  function getComponent() {
    return (
      <Switch>
        <Route
          path={`${match.url}/modeling`}
          render={() => <AgentCardGrid filter={filter} type="modeling" />}
        />
        <Route
          path={`${match.url}/design`}
          render={() => <AgentCardGrid filter={filter} type="design" />}
        />
        <Route
          path={`${match.url}/wesley`}
          render={() => <AgentCardGrid filter={filter} type="wesley" />}
        />
        <Route
          path={`${match.url}/unpaid`}
          render={() => <Unpaid filter={filter} />}
        />
        <Route
          path={`${match.url}/unassigned`}
          render={() => <UnassignedTable filter={filter} />}
        />
        <Route
          path={`${match.url}/disabled`}
          render={() => <AgentCardGrid filter={filter} disabled />}
        />
        <Route
          path={`${match.url}/teams`}
          render={() => <AgentPodsContainer />}
        />
        <Route path={`${match.url}/groups`} component={AgentGroupsPanel} />
        <Route render={() => <AgentCardGrid filter={filter} />} />
      </Switch>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function handleTabChange(event: React.ChangeEvent, value: string) {
    history.push(`${match.url}/${value}`.replace(/\/\//g, "/"));
  }
};
