import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { YZButton } from "@yardzen-inc/react-common";
import { AgentGroup } from "@yardzen-inc/util";
import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";

export interface DeleteAgentGroupModalProps extends DialogProps {
  groups: (AgentGroup & { id: string })[];
  groupToDelete: AgentGroup & { id: string };
  onDelete: (
    groupId: string,
    replaceMentGroupId: string
  ) => void | Promise<void>;
}

const DeleteAgentGroupModal: FC<DeleteAgentGroupModalProps> = ({
  groups,
  groupToDelete,
  onDelete,
  ...props
}) => {
  const filteredGroups = useMemo(filterOutGroupToDelete, [groups]);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [replacementGroupId, setReplacementGroupId] = useState<string | null>(
    filteredGroups?.[0]?.id ?? null
  );
  useEffect(handleGroupsChange, [groups]);

  return (
    <Dialog {...props} onClose={deleting ? void 0 : props.onClose}>
      <DialogContent>
        <Box p={1}>
          <Typography>
            In order to delete this agent group, you must specify a replacement
            group for all agents that were previously assigned
          </Typography>
        </Box>
        {renderSelect()}
        <YZButton
          disabled={!replacementGroupId || deleting}
          onClick={handleDelete}
        >
          Delete Group
        </YZButton>
      </DialogContent>
    </Dialog>
  );

  function filterOutGroupToDelete(): (AgentGroup & { id: string })[] {
    return groups.filter((g) => g.id !== groupToDelete.id);
  }

  async function handleDelete() {
    setDeleting(true);

    try {
      if (!replacementGroupId) {
        throw new Error("replacementGroupId is null");
      }

      await onDelete(groupToDelete.id, replacementGroupId);
      if (props.onClose) {
        props.onClose({}, "backdropClick");
      }
    } catch (error) {
      console.error(error);
      alert("something went wrong, please reload the page and try again");
    } finally {
      setDeleting(false);
    }
  }

  function renderSelect(): ReactNode {
    if (!filteredGroups.length) {
      return (
        <Box p={1}>
          <Typography style={{ color: "red" }}>
            No replacement groups found
          </Typography>
        </Box>
      );
    }

    return (
      <Box p={1}>
        <Select
          value={replacementGroupId}
          onChange={({ target: { value } }) =>
            setReplacementGroupId((value as string) ?? null)
          }
        >
          {filteredGroups.map((g) => (
            <MenuItem value={g.id}>{g.identifier}</MenuItem>
          ))}
        </Select>
      </Box>
    );
  }

  function handleGroupsChange() {
    if (
      !replacementGroupId ||
      !filteredGroups.find((g) => g.id === replacementGroupId)
    ) {
      setReplacementGroupId(filteredGroups?.[0]?.id ?? null);
    }
  }
};

export { DeleteAgentGroupModal };
export default DeleteAgentGroupModal;
