import * as React from "react";
import firebase from "firebase/compat/app";
import { Route, Redirect } from "react-router-dom";
// import { CircularProgress } from "@material-ui/core";

// export interface Props {
//   component: React.ComponentElement<any, any>;
// }

function ProtectedRoute({ component, ...rest }: any) {
  const [userState, setuserState]: [null | boolean, CallableFunction] =
    React.useState(null);

  React.useEffect(() => {
    const verifyLoginStatus = async () => {
      firebase.auth().onAuthStateChanged((user) => {
        if (!user || user.isAnonymous) {
          return setuserState(false);
        } else {
          return setuserState(user);
        }
      });
    };

    verifyLoginStatus();

    return () => {};
  });

  if (userState === null) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        const newProps = {
          ...props,
          user: userState,
        };
        return typeof userState === "object" ? (
          React.createElement(component, newProps)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: rest.path,
              },
            }}
          />
        );
      }}
    />
  );
}

export default ProtectedRoute;
