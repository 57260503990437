import React, { FC, Dispatch, SetStateAction } from "react";
import { Divider, Box } from "@material-ui/core";
import "firebase/compat/firestore";
import DesignerSummaryDownloads from "../../SelfAssignPage/DesignerSummaryDownloads";
import { HouseModelDeliverable } from "../../ConstantValues/fileuploadConstants";
import { MediaUploaderWithTitleAndPreview } from "../../SelfAssignPage/MediaUploaderWithTitleAndPreview";
import { Assignment, Media, Profile } from "@yardzen-inc/models";
import { UploadState, WesleyFileTags } from "./WesleySummaryPage";

type Props = {
  profile: Profile;
  assignment: Assignment;
  uploadState: UploadState;
  setuploadState: Dispatch<SetStateAction<UploadState>>;
  getSingleUploadedDocument: (
    tag: string,
    variant: string | undefined
  ) => Promise<Media | null>;
};

export const HouseModelManager: FC<Props> = (props: Props) => {
  const {
    profile,
    assignment,
    uploadState,
    setuploadState,
    getSingleUploadedDocument,
  } = props;

  function getState(
    tag: string | WesleyFileTags,
    variant?: string
  ): [Media | "loading" | null, (val: Media | "loading" | null) => void] {
    const key = `${tag}${variant ? `~${variant}` : ""}`;
    const value = uploadState[key];

    if (value === void 0) {
      throw new Error(`getState recieved invalid tag: ${key}`);
    }

    return [value, setValue];

    function setValue(val: Media | "loading" | null): void {
      setuploadState({ ...uploadState, [key]: val });
    }
  }

  function getUploadHandler(tag: string, variant?: string): () => Promise<any> {
    return async () => {
      const setItem = getState(tag, variant)[1];
      setItem("loading");
      const newItem = await getSingleUploadedDocument(tag, variant);
      setItem(newItem);
    };
  }

  function getDeleteHandler(tag: string, variant?: string): () => void {
    let key: string;

    if (variant) {
      key = `${tag}~${variant}`;
    } else {
      key = tag;
    }

    return () => {
      const value = uploadState[key];

      if (!value || value === "loading") {
        throw new Error(
          `cannot reset state of value at key: ${key} because it is already null`
        );
      }

      setuploadState({ ...uploadState, [key]: null });
    };
  }

  return (
    <>
      <Box my={3}>
        <Divider></Divider>
      </Box>
      <DesignerSummaryDownloads
        userId={profile.userId}
        lastName={profile.lastName as string}
        street={profile.street as string}
        version={assignment.type}
      />
      <Box my={3}>
        <Divider></Divider>
      </Box>
      <Box display="flex">
        <MediaUploaderWithTitleAndPreview
          p={1}
          width="50%"
          title={"House model deliverable"}
          typographyProps={{
            style: { marginBottom: "1rem", marginLeft: "0.5rem" },
          }}
          onDelete={getDeleteHandler(
            HouseModelDeliverable.tag,
            HouseModelDeliverable.variant
          )}
          uploaderProps={{
            userId: profile.userId,
            tag: HouseModelDeliverable.tag,
            variant: HouseModelDeliverable.variant,
            accept: [".jpg", ".jpeg", ".png"],
            onUploadComplete: getUploadHandler(
              HouseModelDeliverable.tag,
              HouseModelDeliverable.variant
            ),
          }}
        />
      </Box>
    </>
  );
};
