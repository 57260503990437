import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import {
  DesignAssignment,
  Revision,
  Agent,
  Project,
} from "@yardzen-inc/models";
import { formattedDateWithTimeZone } from "./util/formattedDateWithTimeZone";

export interface Props {
  assignments: DesignAssignment[] | null;
  activeAssignment: DesignAssignment | null | false;
  activeAssignmentRevision: Revision | null | false;
  designer: Agent | false | null;
  project: Project;
}

export const DesignStatusInfo = ({
  assignments,
  activeAssignment,
  activeAssignmentRevision,
  designer,
  project,
}: Props) => {
  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box display="flex" flexDirection="row">
        <Typography variant="h5" gutterBottom>
          Design Process
        </Typography>
        {activeAssignment && (
          <Box ml={2}>
            <Typography variant="h6" gutterBottom>
              - {`Active Assignment: ${getActiveAssignemntTitle()}`}
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="row">
        <Box pr={3}>
          <Typography variant="body1">Stage: {getStatus()}</Typography>
          <Typography variant="body1">Designer: {getDesignerName()}</Typography>
        </Box>
        {activeAssignment && (
          <Box pr={3}>
            <Box pr={1}>
              <Typography variant="body1">Progress: {getProgress()}</Typography>
            </Box>
            <Box pr={1}>
              <Typography
                variant="body1"
                style={{
                  backgroundColor: getDueDateBackgroundColor(),
                }}
              >
                Due: {getDueDate()}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );

  function getActiveAssignemntTitle(): string {
    if (!activeAssignment) {
      return "";
    }

    if (activeAssignment.type === "correction") {
      return `Correction for ${activeAssignment.correctionFor}`;
    } else {
      return activeAssignment.type;
    }
  }

  function getDueDateBackgroundColor(): string | undefined {
    if (!activeAssignment) {
      return undefined;
    }

    if (!activeAssignment.dueDate) {
      return "yellow";
    }

    if (activeAssignment.dueDate < new Date().getTime()) {
      return "red";
    }

    return undefined;
  }

  function getDueDate(): string {
    if (!activeAssignment) {
      return "";
    }

    if (!activeAssignment.dueDate) {
      return "Not Set";
    }

    return formattedDateWithTimeZone(activeAssignment.dueDate);
  }

  function getStatus(): string {
    const { designAssignmentStatus } = project;
    if (!designAssignmentStatus) {
      return "Not started";
    }

    if (designAssignmentStatus === "assignmentCreated") {
      return "Waiting for assignment";
    }

    if (designAssignmentStatus === "v1InProgress") {
      return "V1 in progress";
    }

    if (designAssignmentStatus === "postV1Hold") {
      return "Version one delivered";
    }

    if (designAssignmentStatus === "correctionInProgress") {
      return "Correction in progress";
    }

    if (designAssignmentStatus === "postCorrectionHold") {
      return "Correction delivered";
    }

    if (designAssignmentStatus === "v2InProgress") {
      return "V2 in progress";
    }

    if (designAssignmentStatus === "completed") {
      return "Version 2 delivered";
    }

    if (designAssignmentStatus === "v3InProgress") {
      return "V3 in progress";
    }

    if (designAssignmentStatus === "postV3Hold") {
      return "Version 3 delivered";
    }

    if (designAssignmentStatus === "extraInProgress") {
      return "Extra revision in progress";
    }

    if (designAssignmentStatus === "postExtraHold") {
      return "Extra revision delivered";
    }

    return designAssignmentStatus;
  }

  function getProgress(): string {
    if (activeAssignment === null) {
      return "Loading";
    }
    if (!activeAssignment) {
      return "No Assignment";
    }

    return `${activeAssignment.status}/100`;
  }

  function getDesignerName(): string {
    if (designer === null) {
      return "loading...";
    }
    if (designer === false) {
      return "No Designer Assigned";
    }

    return `${designer.firstName} ${designer.lastName}`;
  }
};

export default DesignStatusInfo;
