import * as React from "react";
import MediaCard, { MediaCardProps } from "./MediaCard";
import {
  makeStyles,
  Paper,
  TextField,
  IconButton,
  useMediaQuery,
  Typography,
  Box,
} from "@material-ui/core";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { Delete, CloudDownload, Pages } from "@material-ui/icons";
import downloadAndZip from "../util/downloadAndZip";
import FullScreenImage from "./FullScreenImage";
import GenericConfirm from "./GenericConfirm";

const useStyles = makeStyles((theme) => ({
  root: {},
  multilineRoot: {
    flexGrow: 1,
  },
}));

interface Props {
  stacked?: boolean;
  readonly?: boolean;
  noteCardProps?: any;
}

export type NotationCardProps = Props & MediaCardProps;

const NotationCard = ({
  stacked,
  readonly,
  noteCardProps,
  ...props
}: NotationCardProps) => {
  const classes = useStyles();

  let lgDown: boolean;

  if (typeof stacked === "boolean") {
    lgDown = stacked;
  } else {
    lgDown = useMediaQuery((theme: any) => theme.breakpoints.down("xl"));
  }

  const [value, setValue] = React.useState<string>(
    props.media.description || ""
  );
  const [title, setTitle] = React.useState<string>(
    (props.media as any).title || props.media.originalFileName
  );

  const [fadeOut, setFadeOut] = React.useState<boolean>(false);

  const [open, setOpen] = React.useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);

  const uploadedAt = props.media.uploadedAt
    ? new Date(props.media.uploadedAt).toDateString()
    : "";

  React.useEffect(() => {
    let timeout: any = null;

    timeout = setTimeout(() => {
      firebase
        .firestore()
        .collection("media")
        .doc(props.media.id)
        .set({ title }, { merge: true });
    }, 700);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [title]);

  React.useEffect(() => {
    let timeout: any = null;

    timeout = setTimeout(() => {
      firebase
        .firestore()
        .collection("media")
        .doc(props.media.id)
        .set({ description: value }, { merge: true });
    }, 700);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [value]);

  return (
    <div
      className={classes.root}
      style={{
        flexFlow: lgDown ? "column nowrap" : "row nowrap",
        display: fadeOut ? "none" : "flex",
      }}
    >
      <MediaCard
        {...props}
        out={fadeOut}
        noTitle
        style={{ width: lgDown ? "100%" : "50%", minWidth: "50%" }}
        noToolBar
      />
      {renderTextArea()}
    </div>
  );

  function deleteMedia(): void {
    setFadeOut(true);
    props.media.delete();
  }

  function renderTextArea(): React.ReactNode {
    return _renderTextArea();
  }

  function _renderTextArea(): React.ReactNode {
    return (
      <>
        {deleteModalOpen && (
          <GenericConfirm
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            onSubmit={deleteMedia}
          />
        )}
        {open && (
          <FullScreenImage
            open
            media={props.media as any}
            onClose={() => setOpen(false)}
          />
        )}
        <Paper
          {...noteCardProps}
          style={{
            flexGrow: 1,
            padding: "1rem",
            borderRadius: "0",
            display: "flex",
            flexFlow: "column nowrap",
          }}
          elevation={5}
        >
          <div
            style={{
              display: "flex",
              flexFlow: "row-reverse nowrap",
              transition: "height 250ms",
              marginBottom: "1rem",
            }}
          >
            {!readonly && (
              <IconButton
                onClick={() => setDeleteModalOpen(true)}
                title="Delete Media"
              >
                <Delete />
              </IconButton>
            )}
            <IconButton onClick={() => setOpen(true)} title="Open Fullscreen">
              <Pages />
            </IconButton>
            <IconButton
              title="download Media"
              onClick={async () => {
                try {
                  await downloadAndZip(
                    [props.media],
                    `${props.media.originalFileName}.zip`
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <CloudDownload />
            </IconButton>
            <Box flexGrow={1} pr={1}>
              {!props.media.photoCategory ? (
                <TextField
                  fullWidth
                  placeholder="Title"
                  disabled={readonly}
                  style={{ fontSize: "1.7rem" }}
                  inputProps={{
                    style: { fontSize: "1.7rem" },
                  }}
                  value={title}
                  onChange={
                    readonly
                      ? undefined
                      : ({ target: { value } }) => {
                          setTitle(value);
                        }
                  }
                />
              ) : (
                <>
                  <Typography variant="h5">
                    {props.media.photoCategory.toUpperCase()}
                  </Typography>
                  <Typography variant="caption">{uploadedAt}</Typography>
                </>
              )}
            </Box>
          </div>
          {readonly && <Typography>{value}</Typography>}
          {!readonly && (
            <TextField
              multiline
              rows={8}
              fullWidth
              disabled={readonly}
              margin="none"
              classes={{ root: classes.multilineRoot }}
              variant="filled"
              label="Description"
              style={{ fontSize: "1.2rem", flexGrow: 1 }}
              InputProps={{
                style: {
                  fontSize: "1.2rem",
                  height: "100%",
                  maxHeight: "100%",
                  flexGrow: 1,
                },
              }}
              inputProps={{
                style: {
                  fontSize: "1.2rem",
                  height: "100%",
                  maxHeight: "100%",
                  flexGrow: 1,
                },
              }}
              value={value}
              onChange={
                readonly
                  ? undefined
                  : ({ target: { value } }) => {
                      setValue(value);
                    }
              }
            />
          )}
        </Paper>
      </>
    );
  }
};

NotationCard.defaultProps = {
  noteCardProps: {},
};

export default NotationCard;
