import React, { FC } from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import { BudgetDisplayWrapperClasses } from "./BudgetDisplayWrapper";
import classNames from "classnames";

export interface DesignerBudgetDisplayProps {
  formattedDesignerVisibleBudget: string;
  additionalCssClasses?: BudgetDisplayWrapperClasses;
  wishlistEstimateRangeText: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  budgetTextContainer: {
    display: "flex",
    margin: `${theme.spacing(1)}px 0px`,
  },
  budgetCaption: {
    marginRight: theme.spacing(1),
    textTransform: "capitalize",
  },
  budgetText: {
    marginRight: theme.spacing(1),
  },
}));

/**
 * A component responsible for displaying budget for designers.
 * @param props The DesignerBudgetDisplay props for the component.
 * @returns A component for displaying budget for designers.
 */
export const DesignerBudgetDisplay: FC<DesignerBudgetDisplayProps> = ({
  formattedDesignerVisibleBudget,
  additionalCssClasses,
  wishlistEstimateRangeText,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Box
        className={classNames(
          classes.budgetTextContainer,
          additionalCssClasses?.budgetTextContainer
        )}
      >
        <YZTypography
          className={classNames(
            classes.budgetCaption,
            additionalCssClasses?.budgetCaptions
          )}
        >
          Design Budget:
        </YZTypography>
        <YZTypography
          className={classNames(
            classes.budgetText,
            additionalCssClasses?.budgetText
          )}
        >
          {formattedDesignerVisibleBudget ||
            "A design budget has not been set for this project yet."}
        </YZTypography>
      </Box>
      <Box
        className={classNames(
          classes.budgetTextContainer,
          additionalCssClasses?.budgetTextContainer
        )}
      >
        <YZTypography
          className={classNames(
            classes.budgetCaption,
            additionalCssClasses?.budgetCaptions
          )}
        >
          Wishlist Range:
        </YZTypography>
        <YZTypography
          className={classNames(
            classes.budgetText,
            additionalCssClasses?.budgetText
          )}
        >
          {wishlistEstimateRangeText}
        </YZTypography>
      </Box>
    </Box>
  );
};
