import { InstallQuestionnaire } from "@yardzen-inc/models/dist/models/InstallQuestionnaire";
import { useState, useEffect } from "react";
import { getOnboardingQuestionnaireForProject } from "../firebase/functions/getOnboardingQuestionnaireForProject";
import { getV2DSATSurveyTypeformForUser } from "../firebase/functions/getV2DSATSurveyTypeformForUser";

export type OnboardingInstallPlanResponse =
  | "yardzenContractor"
  | "ownContractor"
  | "DIY"
  | "learnMoreAboutProMatch"
  | "";

export type PostV2DSATSurveyInstallPlanResponse = "Yes" | "Maybe" | "No" | "";

export interface IProjectInstallPlans {
  onboardingInstallPlan: OnboardingInstallPlanResponse;
  postV2InstallPlan: PostV2DSATSurveyInstallPlanResponse;
}

/**
 * Retrieves the install plans from the onboarding questionnaire and post-v2 DSAT survey for a user.
 * @param projectId The projectId to retrieve the install plans for.
 * @returns The install plans for the client's project.
 */
export function useInstallPlansForProject(
  projectId: string,
  userId: string
): [IProjectInstallPlans, boolean, string] {
  const [projectInstallPlans, setProjectInstallPlans] =
    useState<IProjectInstallPlans>({
      onboardingInstallPlan: "",
      postV2InstallPlan: "",
    });

  const [loading, setLoading] = useState(true);

  const [error, setError] = useState("");

  useEffect(() => {
    getProjectInstallPlans(projectId, userId);
  }, [projectId, userId]);

  return [projectInstallPlans, loading, error];

  async function getProjectInstallPlans(projectId: string, userId: string) {
    setLoading(true);

    try {
      const onboardingInstallPlan = await getOnboardingInstallPlan(projectId);

      const postV2InstallPlan = await getInstallPlanFromPostV2DSATSurvey(
        userId
      );

      setProjectInstallPlans({
        onboardingInstallPlan,
        postV2InstallPlan,
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  /**
   * Retrieves the onboarding install plan for the project with the given project id.
   * @param projectId The id of the project to retrieve the onboarding install plan response for.
   * @returns The install plan the client selected during onboarding.
   */
  async function getOnboardingInstallPlan(
    projectId: string
  ): Promise<OnboardingInstallPlanResponse> {
    if (!projectId) {
      return "";
    }

    const projectOnboardingQuestionnaire: InstallQuestionnaire =
      await getOnboardingQuestionnaireForProject(projectId);

    if (
      onboardingQuestionnaireHasNoContractorSurveyResponse(
        projectOnboardingQuestionnaire
      )
    ) {
      return "";
    }

    return projectOnboardingQuestionnaire.surveyResponses[
      "yardzenContractor"
    ] as OnboardingInstallPlanResponse;
  }

  /**
   * Retrieves the answer to the install plan question from the user's post-v2 DSAT survey.
   * @param userId The user id to retrieve the post-v2 DSAT survey install plan response for.
   * @returns The answer to the install plan questions from the user's post-v2 DSAT survey.
   */
  async function getInstallPlanFromPostV2DSATSurvey(
    userId: string
  ): Promise<PostV2DSATSurveyInstallPlanResponse> {
    const postV2DSATSurveyTypeform = await getV2DSATSurveyTypeformForUser(
      userId
    );

    const rawTypeformSurveyData =
      postV2DSATSurveyTypeform["_rawTypeformObject"];

    if (postV2DSATSurveyHasNoInstallPlanResponse(rawTypeformSurveyData)) {
      return "";
    }

    const installPlanAnswer: string = getPostV2DSATSurveyInstallPlanAnswer(
      rawTypeformSurveyData
    ).choice.label;

    if (installPlanAnswer.includes("Yes")) {
      return "Yes";
    }

    if (installPlanAnswer.includes("Maybe")) {
      return "Maybe";
    }

    return "No";
  }

  /**
   * Returns whether or not the client's onboarding questionnaire has a contractor survey response.
   * @param onboardingQuestionnaire The client's onboarding questionnaire.
   * @returns A flag indicating whether or not the client's onboarding questionnaire has a contractor survey response.
   */
  function onboardingQuestionnaireHasNoContractorSurveyResponse(
    onboardingQuestionnaire: InstallQuestionnaire
  ): boolean {
    return (
      !onboardingQuestionnaire.surveyResponses ||
      !onboardingQuestionnaire.surveyResponses["yardzenContractor"]
    );
  }

  /**
   * Determines whether or not the client's post-v2 DSAT survey has a response to the install plan question.
   * @param surveyData The _rawTypeformObject to search for answer data in.
   * @returns Whether or not the post-v2 DSAT Survey contains a response to the install plan question.
   */
  function postV2DSATSurveyHasNoInstallPlanResponse(surveyData: any): boolean {
    const installPlanAnswer = getPostV2DSATSurveyInstallPlanAnswer(surveyData);

    return !installPlanAnswer?.choice?.label;
  }

  /**
   * Finds the install plan answer from the survey.
   * @param surveyData The _rawTypeformObject to search for answer data in.
   * @returns The answer object for the install plan question in the survey, or undefined if not found.
   */
  function getPostV2DSATSurveyInstallPlanAnswer(surveyData: any): any {
    return surveyData?.form_response?.answers?.find(
      (answer: any) =>
        answer.field?.ref === "daf951b4-b511-4fc7-a099-f33f409aebf3"
    );
  }
}
