import * as React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { PageComponentProps } from "./CurrentJobPageMap";
import ImageGrid from "./ImageGrid";
import { Assignment, Media, FileTag } from "@yardzen-inc/models";
import { NewMediaGridCardType } from "../Components/NewMediaGrid";
import runQueuedAsyncBatchJobs from "../util/runQueuedAsyncBatchJobs";

export default (tags: FileTag[], cardType?: NewMediaGridCardType) =>
  (props: PageComponentProps) => {
    const [media, setMedia] = React.useState<Media[] | null>([]);
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
      getMedia();
    }, [tags, cardType]);

    return (
      <>
        <ImageGrid
          noEmptyMessage={loading}
          cardType={cardType}
          media={media as any}
          setLoading={() => null}
        ></ImageGrid>
        {loading && (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            p={2}
          >
            <CircularProgress />
          </Box>
        )}
      </>
    );

    async function getMedia() {
      let fetchedMedia: Media[] = [];

      const mIds = await Assignment.getAllMediaIdsByFileTags(
        tags,
        props.profile.userId
      );

      setLoading(true);

      const jobs = mIds.map((id) => {
        return async function () {
          try {
            const data = await Media.fetchSingle(id);
            if (data) {
              return data;
            }
            return null;
          } catch (error) {
            return error;
          }
        };
      });

      try {
        await runQueuedAsyncBatchJobs<Media | null | Error>(
          5,
          // @ts-ignore
          jobs,
          batchQueueCallback
        );
      } catch (error) {
        console.error(error);
      } finally {
        setMedia(fetchedMedia);
        setLoading(false);
      }

      function batchQueueCallback(results: (Media | null | Error)[]) {
        for (let result of results) {
          if (!result) {
            continue;
          }

          if (result instanceof Error) {
            // TODO: handle error
            console.error(
              "Failed to fetch media in mediaTagGalleryPageFactory"
            );
            console.error(result);
            continue;
          }

          fetchedMedia.push(result);
        }
      }
    }
  };
