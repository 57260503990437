import Fuse from "fuse.js";
import { useEffect, useState } from "react";

const useFuse = <T>(collection: T[], keys: (keyof T | string)[]) => {
  const createFuse = () =>
    new Fuse(collection, {
      keys: keys as string[],
      threshold: 0.3,
    });

  const [fuse, setFuse] = useState(createFuse);

  useEffect(() => {
    setFuse(createFuse());
  }, [collection]);

  return fuse;
};

export { useFuse };
