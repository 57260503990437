import { makeStyles, Theme } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import React from "react";
import SelectedItems from "../../Components/NonPmAssetSelection/SelectedItems";
import { Addy } from "../../util/getAddress";
import { getDesignAssignments } from "../../util/firebase/functions/getDesignAssignments";
import { YZButton } from "@yardzen-inc/react-common";
import { Profile } from "@yardzen-inc/models";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: "5rem",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  accordionRoot: {
    width: "40rem",
    maxWidth: "100%",
  },
}));

interface Props {
  projectId: string;
  designAssignmentId: string;
  revisionId: string;
  address: Addy;
  profile: Profile;
}

function PMBudgetQASummary(props: Props) {
  const classes = useStyles();

  const [designAssignmentExists, setDesignAssignmentExists] = React.useState({
    finishedFetching: false,
    result: "",
  });

  const [editingAssets, setEditingAssets] = React.useState(false);
  const [updatingAssetQuantity, setUpdatingAssetQuantity] =
    React.useState(false);

  const findAssignment = async () => {
    try {
      const assignments = await getDesignAssignments(props.projectId);
      const currentAssignment = assignments.find(
        (assignment) => assignment.revisionId === props.revisionId
      );
      if (!currentAssignment) {
        return setDesignAssignmentExists({
          finishedFetching: true,
          result: "",
        });
      }
      setDesignAssignmentExists({
        finishedFetching: true,
        result: currentAssignment.id,
      });
    } catch (err) {
      alert(
        "Something went wrong fetching this project's design assignments. Please refresh the screen or contact support."
      );
    }
  };

  const selectedItems = React.useMemo(() => {
    if (!designAssignmentExists.finishedFetching) {
      return null;
    }

    return (
      <SelectedItems
        profile={props.profile}
        key={Date.now()} // force re-mount
        onChangingData={setUpdatingAssetQuantity}
        address={props.address}
        isProcessingData={updatingAssetQuantity}
        designAssignmentId={designAssignmentExists.result}
        projectId={props.projectId}
        enableQuantityChange={editingAssets}
        projectRegion={props.address.state}
      />
    );
  }, [editingAssets, designAssignmentExists.result]);

  React.useEffect(() => {
    if (!props.revisionId) {
      return;
    }
    findAssignment();
  }, [props.revisionId]);

  if (!designAssignmentExists.finishedFetching) {
    return (
      <div className={classes.root}>
        <YZTypography>Loading Budget QA...</YZTypography>
      </div>
    );
  }

  if (
    designAssignmentExists.finishedFetching &&
    !designAssignmentExists.result
  ) {
    return (
      <div className={classes.root}>
        <YZTypography>
          We were unable to generate the Budget QA feature because the design
          assignment does not exist yet. Please contact support for more info.
        </YZTypography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {editingAssets ? (
        <YZButton
          onClick={() => setEditingAssets(false)}
          color="primary"
          disabled={updatingAssetQuantity}
        >
          Done Editing
        </YZButton>
      ) : (
        <YZButton onClick={() => setEditingAssets(true)} color="secondary">
          Edit Assets
        </YZButton>
      )}
      {selectedItems}
    </div>
  );
}

export default PMBudgetQASummary;
