import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import firebase from "firebase/compat/app";
import { ProfileProperties, Project } from "@yardzen-inc/models";
import * as React from "react";
import { TypeformUIObject } from "@yardzen-inc/models";
import useTypeforms from "../Revisions/util/useTypeforms";
import RenderTypeformData from "./RenderTypeformData";

interface DSATModalProps {
  clientRecord: ProfileProperties;
  project?: Project;
  onDsatOpened: () => void;
  onDsatLoad: (dsatExists: boolean) => void;
  open: boolean;
  onClose: () => void;
  allowReset?: boolean;
  revisionId?: string;
}
// kick circleci rebuild
const DSATModal: React.FunctionComponent<DSATModalProps> = (props) => {
  const typeforms = useTypeforms(props.clientRecord.id);
  const [dsatTypeforms, setDsatTypeforms] = React.useState<
    TypeformUIObject[] | undefined | null
  >(undefined);
  const projectState = props.project?.designAssignmentStatus;

  React.useEffect(() => {
    if (!!props.revisionId) {
      identifyDSAT(typeforms, props.revisionId);
      return;
    }

    if (projectState === "completed") {
      identifyDSAT(typeforms, props.clientRecord.finalRevision ?? void 0);
    } else {
      identifyDSAT(typeforms, props.clientRecord.conceptualRevision ?? void 0);
    }
  }, [typeforms, props.project, props.revisionId]);

  return (
    <Box display="flex" m={3}>
      <Dialog open={props.open} onClose={closeModal}>
        <DialogActions style={{ padding: "1rem" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            ml={1}
            mr={1}
            width="100%"
          >
            <Typography>
              DSAT Survey for{" "}
              {projectState === "postV1Hold"
                ? "Conceptual Revision"
                : ["completed", "postExtraHold"].includes(projectState || "")
                ? "Final Revision"
                : `[Unknown state]`}
            </Typography>
            <Box display="flex" alignItems="center">
              {!!props.allowReset && !!props.revisionId && !!dsatTypeforms && (
                <Button onClick={() => resetDSAT(props.revisionId as string)}>
                  Reset DSAT
                </Button>
              )}
              <Close style={{ cursor: "pointer" }} onClick={closeModal} />
            </Box>
          </Box>
        </DialogActions>

        <DialogContent style={{ padding: "2rem" }}>
          {dsatTypeforms === undefined ? (
            <CircularProgress />
          ) : !dsatTypeforms?.length ? (
            <Typography>No typeforms found for this revision</Typography>
          ) : (
            <>
              {dsatTypeforms.map((tF) => {
                return (
                  <ExpansionPanel>
                    <ExpansionPanelSummary>
                      Dsat {tF.submittedAt}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <RenderTypeformData typeform={tF} />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                );
              })}
            </>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );

  async function resetDSAT(revisionId: string) {
    return firebase.firestore().collection("revisions").doc(revisionId).update({
      forceDsat: true,
    });
  }

  function identifyDSAT(
    typeforms: TypeformUIObject[] | null,
    revisionId?: string
  ) {
    if (!revisionId || !typeforms || !typeforms.length) {
      props.onDsatLoad(false);
      return setDsatTypeforms(null);
    }

    const DSATSurveys = typeforms.filter((t) => t.revisionId === revisionId);

    props.onDsatLoad(!!DSATSurveys.length);

    return setDsatTypeforms(DSATSurveys || null);
  }

  function closeModal() {
    props.onDsatOpened();
    props.onClose();
  }
};

export { DSATModal };
export default DSATModal;
