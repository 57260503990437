import {
  Box,
  Checkbox,
  Dialog,
  ExpansionPanel,
  ExpansionPanelSummary,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Profile, Revision } from "@yardzen-inc/models";
import * as React from "react";
import isOldBotanical from "./util/isOldBotanical";
import { YZButton } from "@yardzen-inc/react-common";
import { YardPackages } from "../../Interfaces";

export type DeliverableRevisionIdentifier = "conceptual" | "final" | "v3";
interface Props {
  open: boolean;
  onClose: (open: false) => void;
  sendToClient: (
    sendToDesigns: boolean,
    revisionSelect: DeliverableRevisionIdentifier,
    notifyClient: boolean,
    allowAnnotations: boolean,
    blurb?: string | null
  ) => void;
  final?: boolean;
  conceptualRevisionId?: string;
  finalRevisionId?: string;
  v3RevisionId?: string;
  clientRecord?: Profile;
  existingBlurb?: string;
  currentRevision?: Revision;
  canAllowAnnotations?: boolean;
  defaultSettings?: {
    sendToDesigns?: boolean;
    notifyClient: boolean;
    allowAnnotations?: boolean;
  };
}

function MessageModal({
  open,
  sendToClient,
  onClose,
  final,
  conceptualRevisionId,
  finalRevisionId,
  clientRecord,
  v3RevisionId,
  currentRevision,
  canAllowAnnotations = true,
  defaultSettings,
}: Props) {
  const classes = useStyles();
  const [blurb, setBlurb] = React.useState<string | null>(null);
  const [writeBlurb, setWriteBlurb] = React.useState<boolean>(false);
  const [selectValue, setSelectValue] = React.useState<
    DeliverableRevisionIdentifier | undefined
  >();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [notifyClient, setNotifyClient] = React.useState<boolean>(
    !!defaultSettings?.notifyClient
  );
  const [allowAnnotations, setAllowAnnotations] = React.useState<boolean>(
    !!defaultSettings?.allowAnnotations
  );
  const [allowAnnotationsEnabled, setAllowAnnotationsEnabled] =
    React.useState<boolean>(false);

  const [sendToDesigns, setSendToDesigns] = React.useState<boolean>(true);

  const willDupe = React.useMemo(isSelectedRevisionAlreadyDelivered, [
    conceptualRevisionId,
    finalRevisionId,
    v3RevisionId,
    currentRevision?.id,
  ]);

  const currentRevisionName = React.useMemo(getCurrentRevisionName, [
    conceptualRevisionId,
    finalRevisionId,
    v3RevisionId,
  ]);

  React.useEffect(handleSelectValueChange, [selectValue]);

  React.useEffect(() => {
    setWriteBlurb(false);
    setSelectValue(undefined);
    setLoading(false);

    if (currentRevision?.v1Blurb || currentRevision?.finalBlurb) {
      const blurb = currentRevision.v1Blurb || currentRevision.finalBlurb;
      setBlurb(blurb);
      setWriteBlurb(true);
    }

    if (currentRevision?.id === finalRevisionId) setSelectValue("final");
    if (currentRevision?.id === conceptualRevisionId)
      setSelectValue("conceptual");

    return () => {
      setBlurb(null);
    };
  }, [open]);

  return (
    <Dialog
      PaperProps={{
        style: { padding: "1rem" },
      }}
      open={open || loading}
      onClose={loading ? () => {} : () => onClose(false)}
    >
      {willDupe && (
        <div>
          <Typography color="error">
            Selected revision has already been delivered as{" "}
            {currentRevisionName}
          </Typography>
          <Typography variant="overline">
            <em>Please use a different revision as a temporary placeholder</em>
          </Typography>
        </div>
      )}

      {willDupe ? (
        <Typography variant="h6" style={{ color: "green" }}>
          Active
        </Typography>
      ) : (
        <Typography variant="h6" style={{ color: "red" }}>
          Not Active
        </Typography>
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" style={{ marginBottom: "1rem" }}>
          Clicking send will:
        </Typography>
        <Box>{renderSelect()}</Box>
      </div>

      <Typography>
        If this revision is currently active:
        <ul>
          {notifyClient && (
            <li>
              Send a boilerplate email notifying the client that a new
              deliverable is available in their account
            </li>
          )}
          <li>Make the design visible to the client in their account</li>
        </ul>
      </Typography>

      {selectValue && allowAnnotationsEnabled && (
        <ExpansionPanel expanded={false} style={{ marginBottom: "1.25rem" }}>
          <ExpansionPanelSummary
            disabled={!canAllowAnnotations}
            onClick={() => setAllowAnnotations(!allowAnnotations)}
          >
            <FormControlLabel
              onClick={() => setAllowAnnotations(!allowAnnotations)}
              control={
                <Checkbox
                  checked={allowAnnotations}
                  onChange={(e) => setAllowAnnotations(e.target.checked)}
                />
              }
              label={
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setAllowAnnotations(!allowAnnotations);
                  }}
                >
                  Allow Annotations
                </span>
              }
            />
          </ExpansionPanelSummary>
        </ExpansionPanel>
      )}

      <ExpansionPanel expanded={false} style={{ marginBottom: "1.25rem" }}>
        <ExpansionPanelSummary onClick={() => setNotifyClient(!notifyClient)}>
          <FormControlLabel
            onClick={() => setNotifyClient(!notifyClient)}
            control={
              <Checkbox
                checked={notifyClient}
                onChange={(e) => setNotifyClient(e.target.checked)}
              />
            }
            label={
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setNotifyClient(!notifyClient);
                }}
              >
                Notify Client
              </span>
            }
          />
        </ExpansionPanelSummary>
      </ExpansionPanel>

      <ExpansionPanel expanded={false} style={{ marginBottom: "1.25rem" }}>
        <ExpansionPanelSummary onClick={() => setSendToDesigns(!sendToDesigns)}>
          <FormControlLabel
            onClick={() => setSendToDesigns(!sendToDesigns)}
            control={
              <Checkbox
                checked={sendToDesigns}
                onChange={() => setSendToDesigns(!sendToDesigns)}
              />
            }
            label={
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSendToDesigns(!sendToDesigns);
                }}
              >
                Post delivery notification in #designs Slack channel
              </span>
            }
          />
        </ExpansionPanelSummary>
      </ExpansionPanel>

      <ExpansionPanel
        expanded={writeBlurb}
        style={{ marginBottom: "1.25rem" }}
        onChange={() => setWriteBlurb(writeBlurb ? false : true)}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <FormControlLabel
            onClick={() => setWriteBlurb(writeBlurb ? false : true)}
            control={
              <Checkbox
                checked={writeBlurb}
                onChange={(e) => setWriteBlurb(e.target.checked)}
              />
            }
            label={
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setWriteBlurb(writeBlurb ? false : true);
                }}
              >
                Write a custom blurb
              </span>
            }
          />
        </ExpansionPanelSummary>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography style={{ margin: "0.75rem" }}>
            The client will see this text within the client app before viewing
            their renderings. NOTE: This text is not visible in the boilerplate
            email
          </Typography>
          {blurbContainer()}
        </div>
      </ExpansionPanel>

      <YZButton
        style={{ marginBottom: "rem" }}
        variant="outlined"
        disabled={loading || !selectValue || willDupe}
        onClick={async () => {
          if (!selectValue) {
            throw new Error("A deliverable type must be selected");
          }

          setLoading(true);
          await sendToClient(
            sendToDesigns,
            selectValue,
            notifyClient,
            allowAnnotations,
            writeBlurb ? blurb : null
          );
          setLoading(false);
          onClose(false);
        }}
      >
        Set as {selectValue}{" "}
        {writeBlurb ? "with custom blurb" : "with standard copy"}
        {notifyClient && " and notify client"}
      </YZButton>
      {!selectValue && (
        <Typography variant="caption" color="error">
          <em>You must select a deliverable type</em>
        </Typography>
      )}
      {willDupe && (
        <Typography variant="caption" color="error">
          <em>
            It looks like this revision has already been sent to the client as a{" "}
            {currentRevisionName}
          </em>
        </Typography>
      )}
      {loading && <LinearProgress variant="indeterminate" />}
    </Dialog>
  );

  function renderSelect(): React.ReactNode {
    return (
      <Box
        style={{
          position: "relative",
          top: "-20px",
          width: "150px",
          marginRight: "1rem",
        }}
        p={1}
      >
        <FormControl>
          <InputLabel id="blurb-rev-select">Deliverable Type</InputLabel>
          <Select
            style={{ width: "150px" }}
            disabled={loading}
            onChange={handleSelectChange}
            value={selectValue}
          >
            <MenuItem value={undefined}>None</MenuItem>
            <MenuItem
              value="conceptual"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
              disabled={disableConceptual()}
            >
              Conceptual
              {!!conceptualRevisionId &&
                currentRevision?.id !== conceptualRevisionId && (
                  <Typography variant="caption" color="error">
                    This will override the current conceptual revision!
                  </Typography>
                )}
              {disableConceptual() && (
                <Typography variant="caption" color="error">
                  Client purchased an old botanical package!
                </Typography>
              )}
            </MenuItem>
            <MenuItem
              disabled={!disableConceptual() && !conceptualRevisionId}
              value="final"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              Final
              {!disableConceptual() && !conceptualRevisionId && (
                <Typography variant="caption" color="error">
                  Client has not yet recieved a conceptual revision
                </Typography>
              )}
              {!!finalRevisionId && currentRevision?.id !== finalRevisionId && (
                <Typography variant="caption" color="error">
                  This will override the current final revision!
                </Typography>
              )}
            </MenuItem>

            <MenuItem
              disabled={
                !disableConceptual() &&
                (!conceptualRevisionId || !finalRevisionId)
              }
              value="v3"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              V3
              {!disableConceptual() &&
                (!conceptualRevisionId || !finalRevisionId) && (
                  <Typography variant="caption" color="error">
                    Client has not yet recieved a final revision
                  </Typography>
                )}
              {!!v3RevisionId && currentRevision?.id !== v3RevisionId && (
                <Typography variant="caption" color="error">
                  This will override the current v3 revision!
                </Typography>
              )}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  }

  function blurbContainer(): React.ReactNode {
    return (
      <div className={classes.textContainer}>
        <Typography
          style={{
            width: "fit-content",
            marginBottom: ".5rem",
          }}
          className={classes.title}
          variant="h3"
        >
          {final ? (
            <strong>ONWARD</strong>
          ) : (
            <strong>
              REVIEW <br />
              CONCEPT
            </strong>
          )}
        </Typography>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
          }}
        >
          <TextField
            style={{ height: "100%" }}
            InputProps={{
              inputProps: {
                style: {
                  fontFamily: '"Raleway", sans-serif',
                  letterSpacing: "0.2rem",
                  fontSize: "1rem",
                  lineHeight: 1.5,
                },
              },
            }}
            autoFocus
            variant="outlined"
            value={blurb}
            onChange={(e) => setBlurb(e.target.value)}
            multiline
            className={classes.body}
          />
        </div>
      </div>
    );
  }

  function disableConceptual(): boolean {
    return isOldBotanical(
      clientRecord?.package as YardPackages,
      clientRecord?.createdAt as string
    );
  }

  function handleSelectChange({
    target: { value },
  }: React.ChangeEvent<any>): void {
    return setSelectValue(value);
  }

  // this may be useful later
  // function currentRevisionIsActive(): boolean {
  //   if (!currentRevision) {
  //     return false;
  //   }

  //   return [conceptualRevisionId, finalRevisionId, v3RevisionId].includes(
  //     currentRevision.id
  //   );
  // }

  function getCurrentRevisionName(): string {
    return (
      [
        [conceptualRevisionId, "conceptual"],
        [finalRevisionId, "final"],
        [v3RevisionId, "v3"],
      ].find(([id, name]) => id === currentRevision?.id)?.[1] ?? ""
    );
  }

  function isSelectedRevisionAlreadyDelivered(): boolean {
    return (
      [
        [conceptualRevisionId, "conceptual"],
        [finalRevisionId, "final"],
        [v3RevisionId, "v3"],
      ] as [string | undefined, DeliverableRevisionIdentifier][]
    ).some(
      ([id, ident]) => id === currentRevision?.id && selectValue === ident
    );
  }

  function handleSelectValueChange(): void {
    const anottableDelivery = selectValue === "conceptual";
    setAllowAnnotations(anottableDelivery);
    setAllowAnnotationsEnabled(anottableDelivery);
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  textContainer: {
    width: "85%",
    backgroundColor: "#E5E5E5",
    display: "inline-flex",
    flexFlow: "column nowrap",
    padding: "1rem",
    paddingBottom: "0",
    margin: "auto",
    fontFamily: '"Raleway", sans-serif',
    height: "auto",
    marginBottom: "2rem",
    [theme.breakpoints.down("md")]: {
      margintop: "0",
    },
  },
  title: {
    fontFamily: '"Raleway", sans-serif',
    letterSpacing: "0.4rem",
    marginBottom: "1rem",
    fontSize: "2.2rem",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
  body: {
    letterSpacing: "0.3rem",
    fontSize: "1.25rem",
    fontFamily: '"Raleway", sans-serif',
    margin: "0.5rem 0 1rem 0",
    [theme.breakpoints.down("md")]: {
      // paddingLeft: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      // textAlign: "center",
    },
  },
}));

export default MessageModal;
