//returns a formatted date string with local timezone like "12/1/22, 11:59 PM PST"
export const formattedDateWithTimeZone = (
  unixDateTimeStamp: number | any
): string => {
  return new Date(unixDateTimeStamp).toLocaleString([], {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneName: "short",
  });
};
