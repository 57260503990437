import * as React from "react";
import { Box } from "@material-ui/core";
import { Profile, Revision } from "@yardzen-inc/models";
import List from "./List";
import Controls from "./Controls";
import Content from "./Content";
import firebase from "firebase/compat/app";
import DesignPhase, { getPhase } from "./DesignPhase";

interface Props {
  clientRecord: Profile;
}

export const Designs = (props: Props) => {
  const [revisions, setRevisions] = React.useState<Revision[] | null>(null);
  const [selected, setSelected] = React.useState<Revision | null>(null);

  console.log("revs", revisions);
  console.log(props.clientRecord);

  const phase = React.useMemo(_getPhase, [selected]);

  React.useEffect(listenToRevisions, [props.clientRecord.id]);
  React.useEffect(handleRevisionListChange, [revisions]);

  return (
    <Box display="flex" flexDirection="row" flexWrap="nowrap" p={1}>
      <List
        revisions={revisions || []}
        onSelect={(r: Revision) => setSelected(r)}
      />
      <Box display="flex" flexGrow={1} flexDirection="column">
        <Controls revision={selected} phase={phase} />
        <Content revision={selected} phase={phase} />
      </Box>
    </Box>
  );

  function listenToRevisions(): () => void {
    const {
      clientRecord: { id },
    } = props;

    return firebase
      .firestore()
      .collection("revisions")
      .where("profileId", "==", id)
      .onSnapshot(async (snap) => {
        if (snap.empty) {
          return setRevisions([]);
        }

        setRevisions(Revision.hydrate(snap.docs));
      });
  }

  function _getPhase(): DesignPhase | null {
    if (selected) {
      return getPhase(selected);
    }

    return null;
  }

  function handleRevisionListChange(): void {
    do {
      if (!revisions || revisions.length < 1) {
        break;
      }

      if (selected === null) {
        setSelected(revisions[revisions.length - 1]);
        break;
      }
    } while (false);
  }
};

export default Designs;
