import * as React from "react";
import {
  Input,
  Typography,
  FormControl,
  Button,
  InputLabel,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import { Redirect, withRouter } from "react-router";
import firebase from "firebase/compat/app";
import { Link as RouterLink } from "react-router-dom";
import ThirdPartyLogin from "./ThirdPartyLogin";
import CollectProfile from "./CollectProfile";

export interface State {
  isLoggedIn: boolean | null;
  email: string;
  password: string;
  loading: boolean;
  error: string;
  redirectTo: object;
  collectProfile: boolean;
}

export interface Props {
  history: any;
  location: any;
  match: any;
}

class SignIn extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoggedIn: null,
      email: "",
      password: "",
      loading: false,
      error: "",
      errorText: "",
      redirectTo: { pathname: "/" },
      collectProfile: false,
    };

    this.onLoginSubmit = this.onLoginSubmit.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
  }

  public render() {
    if (this.state.collectProfile) {
      return <CollectProfile />;
    }

    if (this.state.isLoggedIn === null) {
      return <CircularProgress />;
    }
    if (this.state.isLoggedIn === true) {
      return <Redirect to={this.state.redirectTo} />;
    }

    const loading = this.state.loading;
    const thirdPartyLoginEnabled = false;

    return (
      <main className="main-login">
        <div className="login-paper">
          <form onSubmit={this.onLoginSubmit}>
            <Typography component="h1" variant="h5">
              Log into Yardzen
            </Typography>
            <Typography paragraph>
              Or{" "}
              <RouterLink to="/create-account">
                Create Modeler/Designer Account
              </RouterLink>
            </Typography>
            <FormControl
              margin="normal"
              required
              fullWidth
              error={this.state.error === "email" ? true : false}
            >
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                id="email"
                name="email"
                placeholder="Email Address"
                inputProps={{
                  type: "email",
                  autoCapitalize: "off",
                  autoCorrect: "off",
                  spellCheck: false,
                }}
                autoFocus
                onChange={this.onEmailChange}
              />
              {this.state.error === "email" ? (
                <Typography variant="caption" color="error">
                  {this.state.errorText}
                </Typography>
              ) : (
                <div></div>
              )}
            </FormControl>
            <FormControl
              margin="normal"
              required
              fullWidth
              error={this.state.error === "password" ? true : false}
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                onChange={this.onPasswordChange}
              />
              {this.state.error === "password" ? (
                <Typography variant="caption" color="error">
                  {this.state.errorText}
                </Typography>
              ) : (
                <div></div>
              )}
            </FormControl>
            <div>
              <Button
                type="submit"
                value="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
              >
                {!loading ? "Log In as Modeler/Designer" : "Logging In..."}
              </Button>
            </div>
            <div className="spacer"></div>
            {thirdPartyLoginEnabled ? <ThirdPartyLogin /> : <div></div>}

            <Typography paragraph>
              <RouterLink to="/recover-account">Forgot your login?</RouterLink>
            </Typography>
            <div className="spacer"></div>
            <Tooltip title="For HQ employees">
              <div>
                <Button
                  type="button"
                  value="Employee Login"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={this.useGmailAccount}
                  hidden={loading}
                >
                  {!loading ? "HQ Login" : "Logging In..."}
                </Button>
              </div>
            </Tooltip>
          </form>
        </div>
      </main>
    );
  }

  public componentDidMount() {
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    this.setState({ redirectTo: from });

    firebase.auth().onAuthStateChanged((user) => {
      if (!user || user.isAnonymous) {
        this.setState({ isLoggedIn: false });
        return false;
      }
      firebase
        .firestore()
        .collection("profiles")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) return;
          this.setState({ collectProfile: true });
        });
      this.setState({ isLoggedIn: true });
      return true;
    });
  }

  protected onLoginSubmit(e: React.FormEvent) {
    e.preventDefault();

    // FIXME: someone could hack this in the dev console, not a good solution;
    if (this.state.email.includes("yardzen")) {
      return this.setState({
        error: "email",
        errorText: "Yardzen employees should log in with google",
      });
    }

    this.setState({ loading: true });

    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((result: any) => {
        if (!result) {
          return;
        }
      })
      .catch((error: any) => {
        return this.handleLoginError(error.code, error.message);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  protected useGmailAccount() {
    let provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
      hd: "yardzen.com", // Limits OAuth logins to the Yardzen domain and prevents Agents from logging in via OAuth2.0
    });

    firebase.auth().useDeviceLanguage();
    firebase.auth().signInWithPopup(provider);
  }

  protected handleLoginError(errorCode: string, errorText: string) {
    let error: string;

    switch (errorCode) {
      case "auth/invalid-email":
        error = "email";
        break;
      case "auth/user-disabled":
      case "auth/user-not-found":
      case "auth/wrong-password":
        error = "password";
        errorText = "Incorrect email or password";
        break;
      default:
        error = "password";
    }

    return this.setState({
      error: error,
      errorText: errorText,
    });
  }

  protected onEmailChange(e: any) {
    this.setState({
      email: e.target.value,
      error: "",
    });
  }

  protected onPasswordChange(e: any) {
    this.setState({
      password: e.target.value,
      error: "",
    });
  }
}

export default withRouter(SignIn);
