import * as React from "react";
import { Drawer, Box, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { UploadAndMediaGrid } from "../../SelfAssignPage/UploadAndMediaGrid";
import { YZButton } from "@yardzen-inc/react-common";

export interface CadsDrawerProps {
  userId: string;
  revisionId: string;
}

const acceptedFileTypes = [".pdf", ".dwg"]; // TODO: Create file typescript types in @yardzen-org/types or something

const CadsDrawer: React.FC<CadsDrawerProps> = React.memo(
  ({ userId, revisionId }: CadsDrawerProps) => {
    const [open, setOpen] = React.useState<boolean>(false);

    return (
      <>
        <YZButton onClick={() => setOpen(!open)} variant="outlined">
          View Cads
        </YZButton>
        <Drawer variant="temporary" open={open} onClose={() => setOpen(false)}>
          <Box display="flex" flexDirection="row-reverse" marginLeft="auto">
            <IconButton onClick={() => setOpen(false)}>
              <Close></Close>
            </IconButton>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            p={1}
            minWidth="500px"
            maxWidth="100vw"
          >
            {open && renderCads(userId, revisionId)}
          </Box>
        </Drawer>
      </>
    );
  },
  (p, n) => p.revisionId === n.revisionId && open === open
);

function renderCads(userId: string, revisionId: string): React.ReactNode {
  return (
    <>
      <Box p={1}>
        <Typography variant="h5" style={{ backgroundColor: "yellow" }}>
          Planting Plan
        </Typography>
        <Box mt={1}>
          <UploadAndMediaGrid
            onUpload={() => null}
            revisionId={revisionId}
            userId={userId}
            downloadable
            fileTag="cad-landscape"
            accept={acceptedFileTypes}
          />
        </Box>
      </Box>
      <Box p={1}>
        <Typography variant="h5" style={{ backgroundColor: "yellow" }}>
          Dimensions &amp; Materials
        </Typography>
        <Box mt={1}>
          <UploadAndMediaGrid
            onUpload={() => null}
            revisionId={revisionId}
            userId={userId}
            downloadable
            accept={acceptedFileTypes}
            fileTag="cad-layout"
          />
        </Box>
      </Box>
      <Box p={1}>
        <Typography variant="h5" style={{ backgroundColor: "yellow" }}>
          Legend
        </Typography>
        <Box mt={1}>
          <UploadAndMediaGrid
            onUpload={() => null}
            revisionId={revisionId}
            userId={userId}
            downloadable
            accept={acceptedFileTypes}
            fileTag="cad-legend"
          />
        </Box>
      </Box>
      <Box p={1}>
        <Typography variant="h5" style={{ backgroundColor: "yellow" }}>
          CAD File
        </Typography>
        <Box mt={1}>
          <UploadAndMediaGrid
            onUpload={() => null}
            revisionId={revisionId}
            userId={userId}
            downloadable
            accept={[".dwg"]}
            fileTag="cad-dwg"
          />
        </Box>
      </Box>
    </>
  );
}

export { CadsDrawer };

export default CadsDrawer;
