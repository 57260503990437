import * as React from "react";
import { makeStyles, Typography, Divider } from "@material-ui/core";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    pointerEvents: "all",
    transition: "background-color 250ms",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
  },
  selectedText: {
    color: "white",
  },
  title: {
    fontSize: "1.5rem",
    padding: "0.5rem 0",
  },
}));

interface Props {
  children: string;
  onClick: () => void;
  selected?: boolean;
}

export default (props: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classnames(
        classes.root,
        props.selected ? classes.selected : ""
      )}
      onClick={props.onClick}
    >
      <Typography
        className={classnames(
          classes.title,
          props.selected ? classes.selectedText : ""
        )}
        align="center"
      >
        {props.children}
      </Typography>
      <Divider />
    </div>
  );
};
