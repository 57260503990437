import * as React from "react";
import {
  Card,
  Grid,
  CardMedia,
  CardContent,
  TextField,
} from "@material-ui/core";
import VideoCamera from "@material-ui/icons/Videocam";
import { Media } from "@yardzen-inc/models";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

export interface Props {
  media: Media[];
  projectId: string;
  revisionId: string;
  onSortEnd: (sortedMedia: Media[]) => void;
}

const SortableItem: any = SortableElement(function ({
  record,
  renderPDFCard,
  onViewClick,
  getImage,
  index,
  otherIndex,
  handleMoveToSubmit,
}: any) {
  const [moveTo, setMoveTo]: [null | string, CallableFunction] =
    React.useState(null);

  return (
    <Grid
      style={{ margin: "1rem", width: "370px" }}
      item
      key={`${record.id}-grid-item`}
    >
      <Card key={`${record.id}-card`}>
        {record.fileType === "application/pdf" ? (
          renderPDFCard(record.downloadURL)
        ) : (
          <CardMedia
            onClick={(e) => {
              onViewClick(index);
            }}
            key={`${record.id}-card-media`}
            style={{
              height: 0,
              paddingTop: "80%",
              cursor: "pointer",
            }}
            image={getImage(record)}
            title={record.originalFileName}
          />
        )}

        <CardContent className="card-content" key={`${record.id}-card-content`}>
          <TextField
            value={moveTo}
            label="Move To: (press enter)"
            variant="outlined"
            onChange={(e) => handleChange(e)}
            onKeyPress={(e) => handleEnter(e, otherIndex)}
          />
        </CardContent>
      </Card>
    </Grid>
  );

  function handleChange(event: any) {
    const val: string = event.target.value;
    return setMoveTo(val);
  }

  function handleEnter(event: any, oldIndex?: number) {
    if (moveTo === null) return;
    if (event.charCode === 13) {
      const newIndex: number = Number(moveTo) - 1;
      setMoveTo("");
      handleMoveToSubmit({ oldIndex, newIndex });
      event.stopPropagation();
      event.preventDefault();
    }
  }
});

const SortableList = SortableContainer(function ({
  items,
  renderPDFCard,
  onViewClick,
  getImage,
  handleMoveToSubmit,
}: any) {
  return (
    <Grid justify="center" container>
      {items.map((record: Media, index: number) => {
        return (
          <SortableItem
            key={`item-${record.id}`}
            renderPDFCard={renderPDFCard}
            onViewClick={onViewClick}
            getImage={getImage}
            index={index}
            otherIndex={index}
            record={record}
            handleMoveToSubmit={handleMoveToSubmit}
          />
        );
      })}
    </Grid>
  );
});

export default function SortableGrid(props: Props) {
  return (
    <div style={{ padding: 16 }}>
      <SortableList
        onSortEnd={onSortEnd}
        items={props.media}
        axis="xy"
        renderPDFCard={renderPDFCard}
        getImage={getImage}
        handleMoveToSubmit={onSortEnd}
      />
    </div>
  );

  function renderPDFCard(downloadURL: string) {
    return (
      <div>
        <iframe style={{ width: "100%", height: "296px " }} src={downloadURL} />
      </div>
    );
  }

  function onSortEnd({ oldIndex, newIndex }: any) {
    const sortedMedia = arrayMove(props.media, oldIndex, newIndex);
    props.onSortEnd(sortedMedia);
  }

  function getImage(record: Media) {
    if (record.fileType.indexOf("video") !== -1) {
      return VideoCamera;
    }
    return record.downloadURL;
  }
}
