import * as React from "react";
import { Grow, Paper, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0",
    borderRadius: "0px",
    backgroundColor: "white",
  },
  li: {
    transitionProperty: "background-color color",
    transition: "all 200ms",
    pointerEvents: "all",
    backgroundColor: "white",
    padding: "1rem",
    "&:hover": {
      color: "white",
      backgroundColor: theme.palette.secondary.dark,
      cursor: "pointer",
    },
  },
}));

export interface SearchDropdownItem {
  label: string;
  value: any;
  key: string;
}

interface Props {
  listItems?: SearchDropdownItem[] | null;
  anchor?: HTMLElement | null;
  selectItem: (val: any) => void;
  styles?: React.CSSProperties;
  className?: string;
  open?: boolean;
  onClose?: () => void;
  fixed?: boolean;
  searching?: boolean;
}

export default (props: Props) => {
  const classes = useStyles();

  const [containerStyles, bodyStyles] = getStyles();

  return (
    <div
      style={{
        position: props.fixed ? "fixed" : "absolute",
        zIndex: 8,
        ...containerStyles,
      }}
      className={props.className}
    >
      <Grow in={!!(props.anchor && props.open)} mountOnEnter unmountOnExit>
        <Paper className={classes.container} style={bodyStyles}>
          {renderListItems()}
        </Paper>
      </Grow>
    </div>
  );

  function getStyles(): [React.CSSProperties, React.CSSProperties] {
    if (!props.anchor) {
      return [{}, {}];
    }

    const rect = props.anchor.getBoundingClientRect();

    return [
      {
        top: rect.bottom,
        left: rect.left,
      },
      { maxWidth: rect.width, width: rect.width },
    ];
  }

  function renderListItems(): React.ReactNode[] | React.ReactNode | null {
    if (props.searching) {
      return (
        <div className={classes.li}>
          <Typography
            noWrap
            style={{ textOverflow: "elipsis", fontStyle: "italic" }}
          >
            Searching...
          </Typography>
        </div>
      );
    }

    if (!props.listItems || !props.listItems.length) {
      return (
        <div className={classes.li}>
          <Typography
            noWrap
            style={{ textOverflow: "elipsis", fontStyle: "italic" }}
          >
            No Results :/
          </Typography>
        </div>
      );
    }

    return props.listItems.map((li) => (
      <div
        className={classes.li}
        key={li.key}
        onClick={() => {
          props.selectItem(li.value);
          if (props.anchor) {
            props.anchor.blur();
          }
          if (props.onClose) {
            props.onClose();
          }
        }}
      >
        <Typography noWrap style={{ textOverflow: "elipsis" }}>
          {li.label}
        </Typography>
      </div>
    ));
  }
};
