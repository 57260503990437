import React, { FC } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { DesignBriefEntryType } from "./util";
import RadioGroup, { RadioGroupProps } from "@material-ui/core/RadioGroup";
import { Typography, FormControlLabel } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";
import Radio, { RadioProps } from "@material-ui/core/Radio";

export interface DesignBriefEntryEditFormTypeSelectProps extends BoxProps {
  value: DesignBriefEntryType;
  onChange: (value: any) => void;
  radioGroupProps?: RadioGroupProps;
  typographyContainerProps?: BoxProps;
  typographyProps?: TypographyProps;
  radioButtonProps?: RadioProps;
}

const DesignBriefEntryEditFormTypeSelect: FC<DesignBriefEntryEditFormTypeSelectProps> =
  ({
    value,
    onChange,
    radioGroupProps,
    typographyContainerProps,
    typographyProps,
    ...containerProps
  }) => {
    return (
      <Box display="flex" flexDirection="row" {...containerProps}>
        <Box
          pr={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          {...typographyContainerProps}
        >
          <Typography
            style={{ fontStyle: "italic", height: "min-content" }}
            {...typographyProps}
          >
            Type:{" "}
          </Typography>
        </Box>
        <Box>
          <RadioGroup
            row
            value={value}
            onChange={({ target: { value } }) => onChange(parseInt(value, 10))}
            {...radioGroupProps}
          >
            <FormControlLabel
              value={DesignBriefEntryType.TEXT}
              label="Text"
              control={<Radio />}
            />
            <FormControlLabel
              value={DesignBriefEntryType.MULITPLE_CHOICE}
              label="Multiple Choice"
              control={<Radio />}
            />
          </RadioGroup>
        </Box>
      </Box>
    );
  };

export { DesignBriefEntryEditFormTypeSelect };
export default DesignBriefEntryEditFormTypeSelect;
