import firebase from "firebase/compat/app";

interface PingClientOpts {
  userId: string;
  annotationLevel?: "FF" | "V1";
}

export default (
  opts: PingClientOpts
): Promise<firebase.functions.HttpsCallableResult> => {
  return firebase.functions().httpsCallable("pingClientForAnnotationComments")(
    opts
  );
};
