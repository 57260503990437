import React, { FC, Fragment, useEffect, useState } from "react";

// Import MUI Components
import { makeStyles } from "@material-ui/core/styles";
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { YZButton } from "@yardzen-inc/react-common";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { ProjectManagerProperties } from "@yardzen-inc/models";
import { createDesignerPod } from "./apiCalls";
import { NewPodData } from "./types";

export interface CreateAgentPodModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  style: {},
  dialogContainer: {
    minWidth: 500,
    minHeight: 289,
  },
  progressContainer: {
    display: "flex",
  },
  innerButtonProgress: {
    position: "absolute",
    color: "#fff",
  },
  selectForm: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const CreateAgentPodModal: FC<CreateAgentPodModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const [newPodData, setNewPodData] = useState<NewPodData>({
    name: "",
    projectManagerId: "",
  });
  const [projectManagers, setProjectManagers] = useState<
    (ProjectManagerProperties & { id: string })[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [createPodLoading, setCreatePodLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    // pulls in master list of project managers
    // to do => move this function to FB Functions
    async function getProjectManagers(): Promise<
      (ProjectManagerProperties & {
        id: string;
      })[]
    > {
      setLoading(true);
      const projectSnap = await firebase
        .firestore()
        .collection("projectManagers")
        .get();

      const response = await projectSnap.docs
        .map((doc) => ({
          ...(doc.data() as ProjectManagerProperties),
          id: doc.id,
        }))
        .filter((pm) => !!pm);
      setProjectManagers(response);
      setLoading(false);
      return response;
    }
    getProjectManagers();
  }, []);

  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        classes={{ paper: classes.dialogContainer }}
      >
        <DialogTitle id="form-dialog-title">Create New Team</DialogTitle>
        {error && <Alert severity="error">{error}</Alert>}
        {loading ? (
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Team Name"
                type="text"
                name="name"
                fullWidth
                value={newPodData.name}
                onChange={handleChange}
              />
              <FormControl fullWidth className={classes.selectForm}>
                <InputLabel htmlFor="pageLength">Team PM Lead</InputLabel>
                <Select
                  // @ts-ignore
                  onChange={handleChange}
                  value={newPodData.projectManagerId}
                  name="projectManagerId"
                >
                  {projectManagers &&
                    projectManagers.map((pm) => {
                      return (
                        <MenuItem
                          key={`create-agent-pod-team-lead-${pm.id}`}
                          value={pm.id}
                          id="projectManagerId"
                        >{`${pm.firstName} ${pm.lastName}`}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <YZButton
                inheritFont
                color="primary"
                onClick={handleCreatePodButtonClick}
                fullWidth
                disabled={createPodLoading}
              >
                {createPodLoading && (
                  <CircularProgress
                    size={30}
                    className={classes.innerButtonProgress}
                  />
                )}
                Submit
              </YZButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Fragment>
  );

  async function handleCreatePodButtonClick() {
    setError("");
    if (
      newPodData.name.trim() === "" ||
      newPodData.projectManagerId.trim() === ""
    ) {
      setError("Name and PM Lead must not be empty");
    } else {
      setCreatePodLoading(true);
      await createDesignerPod(newPodData);
      setNewPodData({
        name: "",
        projectManagerId: "",
      });
      setCreatePodLoading(false);
      onClose();
      onSubmit();
    }
  }

  function handleChange(
    event: React.ChangeEvent<{ value: string; name: string }>
  ) {
    const { name, value } = event.target;
    setNewPodData({
      ...newPodData,
      [name]: value,
    });
  }
};

export { CreateAgentPodModal };
export default CreateAgentPodModal;
