import { makeStyles, MenuItem, Select } from "@material-ui/core";
import { YZTypography, YZThemeProvider } from "@yardzen-inc/react-common";
import React, { ChangeEvent } from "react";
import moment from "moment";

const useStyles = makeStyles({
  renderSelectSection: {
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid #bcbcbc",
    paddingBottom: "1rem",
  },
  topSection: {
    marginTop: "3rem",
    textAlign: "center",
  },
  renderSelect: {
    minWidth: "6rem",
  },
});

interface ISketchupRender {
  hash: string;
  // TODO: Define
  sketchupProducts?: any[];
}
interface Props {
  // TODO: Define
  sketchupProducts?: any[];
}

function SketchupDesignBrief(props: Props) {
  const classes = useStyles();

  const getInitialSelectedRender = (): ISketchupRender | null => {
    if (!props.sketchupProducts?.length) {
      return null;
    }
    return props.sketchupProducts[0];
  };

  const [selectedRender, setSelectedRender] =
    React.useState<ISketchupRender | null>(getInitialSelectedRender());

  const handleSelectedRenderChange = (e: ChangeEvent<any>) => {
    setSelectedRender(e.target.value);
  };

  return (
    <YZThemeProvider>
      <div className={classes.topSection}>
        <YZTypography variant="h4">Project Submission</YZTypography>
        {!selectedRender && (
          <YZTypography>
            Select your renderings to begin QA and project submission
          </YZTypography>
        )}
      </div>
      <div className={classes.renderSelectSection}>
        <div>
          <br />
          {props.sketchupProducts?.length ? (
            <Select
              className={classes.renderSelect}
              value={selectedRender}
              onChange={handleSelectedRenderChange}
            >
              {props.sketchupProducts.map((product) => (
                <MenuItem value={product}>
                  {moment(product.versionCreatedAt).format("MMMM Do HH:MM.SS")}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <YZTypography>
              There are no renders associated with this assignment. If you think
              this is a mistake, please refresh the screen.
            </YZTypography>
          )}
        </div>
      </div>
    </YZThemeProvider>
  );
}

export default SketchupDesignBrief;
