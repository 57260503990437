const getExpeditedAssignmentStartByDate = (assignedAt: number): Date => {
  const assignedDate = new Date(assignedAt);
  if (isNaN(assignedDate.getTime())) {
    throw new Error("assignedAt is an invalid date");
  }

  const UNIX_MILISECOND_DAY_VALUE = 1000 * 60 * 60 * 24;

  const dayOfTheWeek = assignedDate.getDay();

  const isSundayThroughThursday = dayOfTheWeek <= 4;
  const isFriday = dayOfTheWeek === 5;

  const getStartByDate = (daysAway: number) =>
    new Date(assignedDate.getTime() + UNIX_MILISECOND_DAY_VALUE * daysAway);

  if (isSundayThroughThursday) {
    //start by due the next day
    return getStartByDate(1);
  } else if (isFriday) {
    //start by Monday
    return getStartByDate(3);
  } else {
    //it's Saturday, start by Monday
    return getStartByDate(2);
  }
};

export { getExpeditedAssignmentStartByDate };
