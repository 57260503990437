import React from "react";
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import CloseIcon from "@material-ui/icons/Close";
import { Addy } from "../../util/getAddress";
import classnames from "classnames";
import { PlantPDFHeaders } from "./constants/SelectedItemsConstants";
import { YARDZEN_LOGO_BLACK_SRC } from "../../util/logoConstants";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableWidth: {
    width: "auto",
  },
  // Pixel values are set for CAD. Don't change unless given permission
  tableBody: {
    "& tr": {
      height: "50px",
      wordBreak: "break-word",
    },
    "& tr:nth-child(1) td:nth-child(1)": {
      backgroundColor: "black",
    },
    "& td": {
      borderBottom: "none",
    },
    "& p": {
      fontFamily: "Arial",
      maxHeight: "50px",
      fontSize: "10px",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  backDrop: {
    backgroundColor: "white",
  },
  content: {
    marginTop: "3rem",
  },
  img: {
    marginLeft: "1rem",
    marginRight: "1.5rem",
  },
  tableHead: {
    background: "#e3e3e3",
  },
});

interface SelectedItemsPDFProps {
  open: boolean;
  onClose: () => void;
  rows: any[];
  address: Addy | null;
  columns: { header: string | string; fieldName: string; width?: string }[];
  title: string;
  includeAdditionalInfo?: boolean;
  formatPDF?: boolean;
}

function SelectedItemsPDF({
  open,
  onClose,
  rows,
  address,
  columns,
  title,
  includeAdditionalInfo = true,
  formatPDF = false,
}: SelectedItemsPDFProps) {
  const classes = useStyles();

  React.useEffect(() => {
    if (!open) {
      return;
    }
    setTimeout(() => {
      window.print();
    }, 500);
    setTimeout(() => {
      onClose();
    }, 2000 /* this timeout was determined based on some weird behavior where if it was too short, ie. 1000, the browser would crash for some reason */);
  }, [open]);

  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      fullScreen
      open={open}
      onClose={onClose}
    >
      {includeAdditionalInfo && (
        <AppBar>
          <Toolbar variant="dense">
            <IconButton
              className="SelectedItemsPDFCloseButton"
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              size="small"
            >
              <CloseIcon />
            </IconButton>
            <img
              className={classes.img}
              src={YARDZEN_LOGO_BLACK_SRC}
              height="24"
            />
            <YZTypography variant="body2" type="mono">
              {title}
            </YZTypography>
          </Toolbar>
        </AppBar>
      )}

      <div className={classes.content}>
        {includeAdditionalInfo && (
          <Box p={2}>
            <YZTypography>
              {address?.street}, {address?.city}, {address?.state},{" "}
              {address?.zip}
            </YZTypography>
          </Box>
        )}
        <TableContainer>
          <Table
            className={classnames(
              classes.table,
              formatPDF ? classes.tableWidth : ""
            )}
            size="small"
            padding="none"
            aria-label="a dense table"
          >
            {includeAdditionalInfo && (
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {columns.map((c) => (
                    <TableCell key={c.header}>
                      <YZTypography>{c.header}</YZTypography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody className={formatPDF ? classes.tableBody : ""}>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  {columns.map((c) => (
                    <TableCell key={c.header} width={c.width || ""}>
                      <YZTypography style={{ maxWidth: c.width }}>
                        {typeof row[c.fieldName] === "string" &&
                        c.header !== PlantPDFHeaders.SCIENTIFIC_NAME
                          ? row[c.fieldName].toUpperCase()
                          : row[c.fieldName]}
                      </YZTypography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Dialog>
  );
}

export default SelectedItemsPDF;
