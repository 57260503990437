import { Project, Profile } from "@yardzen-inc/models";
import { ProjectWithProfile } from "../sortProjectsIntoDssQueue";
import { getIsExteriorPackage } from "./getIsExteriorPackage";
import { isStarterPackage } from "../isStarterPackage";
import { SelfAssignQueueItems } from "src/api/pangaeaTypes";
import { AgentQualification, Agent } from "src/ConstantValues/AgentConstants";

export enum ProjectTag {
  VIP = "VIP",
  ONBOARDING_YES = "ONBOARDING-YES",
  ONBOARDING_MAYBE = "ONBOARDING-MAYBE",
}

type ProjectWithQualificationsAndProfile = (
  | ProjectWithProfile
  | SelfAssignQueueItems[0]
) & {
  qualifications: AgentQualification[];
};

const addQualificationsToProjects = (
  projects: (ProjectWithProfile | SelfAssignQueueItems[0])[]
): ProjectWithQualificationsAndProfile[] => {
  return projects.map((projectWithProfile) => {
    const qualifications: AgentQualification[] = [];
    if (projectWithProfile.project.tags?.includes(ProjectTag.VIP)) {
      qualifications.push(AgentQualification.VIP);
    }
    if (projectWithProfile.project.isPremium) {
      qualifications.push(AgentQualification.VIP);
    }
    if (projectWithProfile.project.isExpedited) {
      qualifications.push(AgentQualification.Expedited);
    }
    if (getIsExteriorPackage(projectWithProfile.profile.package)) {
      qualifications.push(AgentQualification.Exterior);
    }
    if (
      isStarterPackage(projectWithProfile.profile.package) &&
      projectWithProfile.project.isProDesign
    ) {
      qualifications.push(AgentQualification.TwoDProDesigns);
    }
    if (
      !isStarterPackage(projectWithProfile.profile.package) &&
      projectWithProfile.project.isProDesign
    ) {
      qualifications.push(AgentQualification.ThreeDProDesigns);
    }

    return { ...projectWithProfile, qualifications };
  });
};

const agentCanOnlyWorkOnStarterProjects = (agent: Agent) => {
  return agent.qualifications.includes(AgentQualification.StarterExclusive);
};

const agentCanWorkOnStarterOrTraditionalProjects = (agent: Agent) => {
  return agent.qualifications.includes(AgentQualification.StarterInclusive);
};

const agentCantWorkOnStarterProjects = (agent: Agent) => {
  return (
    !agentCanWorkOnStarterOrTraditionalProjects(agent) &&
    !agentCanOnlyWorkOnStarterProjects(agent)
  );
};

export const getNextHighestPriorityAssignment = async (
  agent: Agent,
  projects: (ProjectWithProfile | SelfAssignQueueItems[0])[]
): Promise<ProjectWithProfile | undefined> => {
  if (projects.length === 0) {
    return;
  }

  const projectsWithQualifications = addQualificationsToProjects(projects);
  const next = filterIneligibleProjects(
    projectsWithQualifications,
    agent
  ).shift();

  return next
    ? {
        project: next.project as Project,
        profile: next.profile as Profile,
      }
    : undefined;
};

function filterIneligibleProjects(
  projectsWithQualifications: ProjectWithQualificationsAndProfile[],
  agent: Agent
) {
  return projectsWithQualifications.filter((p) => {
    const isStarterProject =
      isStarterPackage(p.profile.package) && !p.project.isProDesign;
    const agentHasQualifications = p.qualifications.every((qualification) =>
      agent.qualifications?.includes(qualification)
    );

    if (agentCanOnlyWorkOnStarterProjects(agent)) {
      return isStarterProject && agentHasQualifications;
    }
    if (agentCanWorkOnStarterOrTraditionalProjects(agent)) {
      return agentHasQualifications || p.qualifications.length === 0;
    }
    if (agentCantWorkOnStarterProjects(agent) && isStarterProject) {
      return false;
    }

    return agentHasQualifications || p.qualifications.length === 0;
  });
}
