import firebase from "firebase/compat/app";
import { TypeformFirestoreObject } from "@yardzen-inc/models";

/**
 * Retrieves the post-v2 DSAT survey Typeform for the user with the given user id.
 * @param userId The id of the user to retrieve the Typeform survey for.
 * @returns The post-v2 DSAT survey Typeform for the user.
 */
async function getV2DSATSurveyTypeformForUser(
  userId: string
): Promise<TypeformFirestoreObject> {
  if (!userId || typeof userId !== "string") {
    throw new Error(
      `A string user id was expected when fetching the survey. Instead received: ${userId}`
    );
  }

  const v2DSATSurveyFormId = "XmoAM10w";

  const surveySnapshot = await firebase
    .firestore()
    .collection("typeforms")
    .where("formId", "==", v2DSATSurveyFormId)
    .where("userId", "==", userId)
    .get();

  // We return an empty object here rather than throw an error, because a client may not yet be at the point where
  // they have a post v2 DSAT survey, or they may have ignored the survey.
  if (surveySnapshot.empty) {
    return {} as TypeformFirestoreObject;
  }

  const rawSurveyDoc = surveySnapshot.docs[0].data();

  return rawSurveyDoc as TypeformFirestoreObject;
}

export { getV2DSATSurveyTypeformForUser };
