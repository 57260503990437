import * as React from "react";
import { Box } from "@material-ui/core";
import { Project, DesignAssignment } from "@yardzen-inc/models";
import NoteListNote from "./NoteListNote";

export interface NoteListProps {
  project: Project | null;
  lastName: string;
  street: string;
}

export const NoteList = (props: NoteListProps) => {
  const [designAssignments, setDesignAssignments] = React.useState<
    DesignAssignment[] | null
  >(null);

  React.useEffect(subscribeToDesignAssignments, [props.project?.id ?? "none"]);

  return (
    <Box flexGrow={1} display="inline-flex" flexDirection="column">
      {renderNotes()}
    </Box>
  );

  function renderNotes(): React.ReactNode[] {
    if (!Project || !designAssignments?.length) {
      return [];
    }

    return designAssignments.map((da) => {
      return (
        <NoteListNote
          lastName={props.lastName}
          street={props.street}
          da={da}
          key={`${da.id}-da-note`}
        />
      );
    });
  }

  function subscribeToDesignAssignments(): void | (() => void) {
    const { project } = props;

    if (!project) {
      return;
    }

    return project.designAssingnmentsReference.onSnapshot(async (snap) => {
      if (snap.empty) {
        return setDesignAssignments([]);
      }

      setDesignAssignments(DesignAssignment.hydrate(snap.docs));
    });
  }
};

export default NoteList;
