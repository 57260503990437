import { combineReducers } from "@reduxjs/toolkit";
import { projectsReducer as projects } from "./projectsSlice";
import { profilesReducer as profiles } from "./profilesSlice";

const entitiesReducer = combineReducers({
  projects,
  profiles,
});

export { entitiesReducer };
