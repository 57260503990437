import { useState, useEffect } from "react";
import { Project } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const useProjectsFromUserId = (userId: string): Project[] | null => {
  const [projects, setProjects] = useState<Project[] | null>(null);

  useEffect(listenForProjectChange, [userId]);

  return projects;

  function listenForProjectChange() {
    void (async function () {
      firebase
        .firestore()
        .collection("projects")
        .where("profileId", "==", userId)
        .onSnapshot((snap) => {
          setProjects(Project.hydrate(snap.docs));
        });
    })();
  }
};

export { useProjectsFromUserId };
export default useProjectsFromUserId;
