import React from "react";
import { makeStyles } from "@material-ui/core";
import { IFilterBoxOption } from "../../../Interfaces";
import FilterMenu from "./FilterMenu";
import AssetLibraryFilterBox from "./AssetFilterBox/AssetLibraryFilterBox";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  onFilterBoxSelect,
  onFilterBoxSelectNone,
} from "./AssetFilterBox/onFilterBoxSelect";

const useStyles = makeStyles({
  assetFilters: {
    display: "flex",
    alignItems: "center",
    marginTop: 5,
  },
});

interface Props {
  options: IFilterBoxOption[];
  neqFilterState: any;
  setNeqFilterState: (newState: any) => void;
  hasAnyOfFilterState: any;
  setHasAnyOfFilterState: (newState: any) => void;
  hasAllOfFilters: any[];
  setHasAllOfFilters: (newState: any) => void;
  secondaryFilters?: any;
}

const AssetLibraryFilterMenu = (props: Props) => {
  const classes = useStyles();

  const filtersActive =
    props.hasAllOfFilters.length ||
    props.neqFilterState.length ||
    props.hasAnyOfFilterState.length;

  const onSelect = (selectFn: Function) => (a: any, id: string) =>
    selectFn(
      a,
      props.neqFilterState,
      props.hasAnyOfFilterState,
      props.hasAllOfFilters,
      props.setNeqFilterState,
      props.setHasAnyOfFilterState,
      props.setHasAllOfFilters,
      id
    );

  return (
    <div className={classes.assetFilters}>
      <FilterMenu
        disableListener
        icon={<FilterListIcon />}
        label="Filter"
        showClose
        active={filtersActive}
      >
        <AssetLibraryFilterBox
          hasAllOfFilters={props.hasAllOfFilters || []}
          neqFilterState={props.neqFilterState}
          hasAnyOfFilterState={props.hasAnyOfFilterState}
          options={props.options}
          onFilterBoxSelectHasAnyOf={(selectedValues: any, id: string) =>
            onFilterBoxSelect(
              selectedValues,
              props.hasAnyOfFilterState || [],
              props.setHasAnyOfFilterState,
              id
            )
          }
          onFilterBoxSelectHasAllOf={(selectedValues: any, id: string) =>
            onFilterBoxSelect(
              selectedValues,
              props.hasAllOfFilters || [],
              props.setHasAllOfFilters,
              id
            )
          }
          onFilterBoxSelectNeq={(selectedValues: any, id: string) =>
            onFilterBoxSelect(
              selectedValues,
              props.neqFilterState || [],
              props.setNeqFilterState,
              id
            )
          }
          // @ts-ignore TODO
          onFilterBoxSelectNone={onSelect(onFilterBoxSelectNone)}
        />
      </FilterMenu>
      &nbsp;
      {props.secondaryFilters}
    </div>
  );
};

export default AssetLibraryFilterMenu;
