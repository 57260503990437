import * as React from "react";
import {
  Dialog,
  DialogContent,
  LinearProgress,
  Typography,
  makeStyles,
  Checkbox,
  Divider,
  IconButton,
  FormControlLabel,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { YZButton } from "@yardzen-inc/react-common";

const useStyles = makeStyles({
  root: {},
  padBot: {
    paddingBottom: "1rem",
  },
  bottomBox: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    margin: "1rem 0",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
  },
});

interface Props {
  onSubmit: () => void;
  onClose: () => void;
  submitting?: boolean;
  message?: string | any;
}

export default (props: Props) => {
  const classes = useStyles();
  const [sure, setSure] = React.useState<boolean>(false);

  return (
    <Dialog open onClose={props.onClose}>
      {props.submitting && <LinearProgress variant="indeterminate" />}
      <IconButton onClick={props.onClose} className={classes.closeButton}>
        <Close></Close>
      </IconButton>
      <DialogContent>
        <Typography variant="h4" className={classes.padBot}>
          Are you sure?
        </Typography>
        <Typography variant="body1" className={classes.padBot}>
          {props.message
            ? props.message
            : `Please double check to make sure all materials are uploaded and that
          your submission meets all requirements of the project. Once you
          submit, you will no longer be able to view or modify your submission.`}
        </Typography>
        <Divider />

        <div className={classes.bottomBox}>
          <FormControlLabel
            control={<Checkbox value={sure} onChange={() => setSure(!sure)} />}
            label="I'm sure"
          />
          <YZButton
            variant="contained"
            color="primary"
            disabled={!sure || props.submitting}
            onClick={props.onSubmit}
            style={{ width: "fit-content", height: "fit-content" }}
          >
            submit
          </YZButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};
