import * as React from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  CircularProgress,
  withTheme,
  Theme,
} from "@material-ui/core";
import { NewProfile } from "@yardzen-inc/models";
import moment from "moment";
import { DesignProfileFilter } from "./DesignProfileFilter";
import DesignProfileListItem from "./DesignProfileListItem";
import { PhoneDisabled, Phone } from "@material-ui/icons";

const fieldMap = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  phone: "Phone",
  createdAt: "Created At",
};

export interface Props {
  theme?: Theme;
  history: any;
  location: any;
  match: any;
}

export interface State {
  records: Array<NewProfile>;
  filteredRecords: Array<NewProfile>;
  pageLength: number;
  hasBeenContactedFilter: string;
  currentRecords: Array<number>;
  searchTerm: string;
}

class DesignProfiles extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      records: [],
      filteredRecords: [],
      pageLength: 50,
      hasBeenContactedFilter: "both",
      currentRecords: 0,
      searchTerm: "",
    };
  }

  public render() {
    if (!this.state.records.length) {
      return <CircularProgress />;
    }

    const smDown = window.outerWidth < 960;

    return (
      <div style={{ marginTop: smDown ? "4rem" : "unset" }}>
        <DesignProfileFilter
          handleChange={this.handleChange}
          pageLength={this.state.pageLength}
          hasBeenContactedFilter={this.state.hasBeenContactedFilter}
          searchTerm={this.state.searchTerm}
          nextPage={this.nextPage}
          prevPage={this.prevPage}
          handleSearch={this.handleSearch}
          disablePrev={
            this.state.currentRecords > this.state.filteredRecords.length ||
            this.state.currentRecords <= 0
          }
          disableNext={
            this.state.filteredRecords.length <= this.state.pageLength ||
            this.state.filteredRecords.length <=
              this.state.currentRecords + this.state.pageLength
          }
        />
        {this.renderTableOrList(smDown)}
      </div>
    );
  }

  renderTableOrList = (smDown?: boolean): React.ReactNode => {
    if (smDown) {
      return (
        <div
          style={{
            display: "flex",
            flexFlow: "column nowrap",
          }}
        >
          {this.renderRecords(smDown)}
        </div>
      );
    }

    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <Typography>Contacted?</Typography>
            </TableCell>
            <TableCell>
              <Typography>Paid?</Typography>
            </TableCell>
            <TableCell>
              <TableBody>Project Call</TableBody>
            </TableCell>
            {Object.keys(fieldMap).map((field) => (
              <TableCell
                key={
                  // @ts-ignore
                  fieldMap[field]
                }
              >
                <Typography>
                  {
                    // @ts-ignore
                    fieldMap[field]
                  }
                </Typography>
              </TableCell>
            ))}
            <TableCell>
              <Typography>Address</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>{this.renderRecords(smDown)}</TableBody>
      </Table>
    );
  };

  renderRecords = (smDown?: boolean) => {
    const currRecs = this.state.currentRecords;
    const filRecs = this.state.filteredRecords;

    const recArr = [];
    if (!this.state.filteredRecords.length) {
      return;
    }

    function handleOutOfBounds() {
      if (currRecs > filRecs.length) {
        return 0;
      } else {
        return currRecs;
      }
    }

    for (
      let i = handleOutOfBounds();
      i < currRecs + this.state.pageLength;
      i++
    ) {
      if (!filRecs[i]) break;
      const record = filRecs[i].getProperties();
      const recordId = filRecs[i].getId();
      record.createdAt = moment(record.createdAt)
        .utc()
        .local()
        .format("dddd MM-DD-YYYY");

      if (smDown) {
        recArr.push(
          <DesignProfileListItem
            record={record}
            onClick={() =>
              this.props.history.push(`/designprofiles/${recordId}`)
            }
            renderCallStatus={this.renderProjectCallStatus}
          />
        );
      } else {
        recArr.push(
          <TableRow key={recordId}>
            <TableCell>
              <Link>
                <Typography
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    this.props.history.push(`/designprofiles/${recordId}`);
                  }}
                >
                  View
                </Typography>
              </Link>
            </TableCell>
            <TableCell>
              <Typography
                style={{ color: record["contacted"] ? "green" : "red" }}
                variant="subtitle2"
              >
                {record["contacted"] ? "Contacted" : "Not Contacted"}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                style={{ color: record["paid"] ? "green" : "red" }}
                variant="subtitle2"
              >
                {record["paid"] ? "PAID" : "NOT PAID"}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                style={{ color: record["paid"] ? "green" : "red" }}
                variant="subtitle2"
              >
                {this.renderProjectCallStatus(record)}
              </Typography>
            </TableCell>
            {Object.keys(fieldMap).map((field) => (
              <TableCell key={field}>
                <Typography>{record[field]}</Typography>
              </TableCell>
            ))}
            <TableCell>
              <Typography>
                {record.street}
                <br />
                {record.city && `${record.city},`}
                <br />
                {record.state}
                <br />
                {record.zip}
              </Typography>
            </TableCell>
          </TableRow>
        );
      }
    }

    return recArr;
  };

  renderProjectCallStatus = (record: NewProfile) => {
    // @ts-ignore
    if (!record["projectCall"]) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PhoneDisabled style={{ color: "red" }} />
          <Typography
            style={{ color: "red", textAlign: "center" }}
            variant="caption"
          >
            Not scheduled
          </Typography>
        </div>
      );
    }
    // @ts-ignore
    if (record["projectCall"] === "canceled") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PhoneDisabled style={{ color: "red" }} />
          <Typography style={{ color: "red" }} variant="caption">
            canceled
          </Typography>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Phone style={{ color: "green" }} />
        <Typography
          style={{ color: "green", textAlign: "center" }}
          variant="caption"
        >
          {
            // @ts-ignore
            moment(record["projectCall"]).format("ddd MM/DD, hh:mma")
          }
        </Typography>
      </div>
    );
  };

  handleSearch = (nValue: any) => {
    const value = nValue.toLowerCase();
    this.setState({ searchTerm: value });

    this.filterRecords(value);
  };

  handleChange = (field: any, value: any) => {
    this.setState({ [field]: value });

    if (this.state.currentRecords > this.state.filteredRecords.length) {
      this.setState({ currentRecords: 0 });
    }
  };

  nextPage = () => {
    this.setState((prevState: any) => {
      return {
        currentRecords: prevState.currentRecords + prevState.pageLength,
      };
    });
  };

  prevPage = () => {
    if (this.state.currentRecords === 0) return;
    this.setState((prevState: any) => {
      return {
        currentRecords: prevState.currentRecords - prevState.pageLength,
      };
    });
  };
  // contacted = () => {
  //   const fil = this.state.hasBeenContactedFilter;
  //   const recs = this.state.records;
  //   if (fil === "both") return recs;
  //   const filteredRecs = this.state.records.filter((record: any) => {
  //     if (fil === "hasNot") {
  //       return !!record["contacted"];
  //     } else if (fil === "has") {
  //       return !record["contacted"];
  //     }
  //     return false;
  //   });

  //   console.log(filteredRecs);
  //   return filteredRecs;
  // };

  filterRecords = (searchTerm: string) => {
    const filtered = this.state.records.filter((record: any) => {
      const firstN = record._properties.firstName;
      const lastN = record._properties.lastName;
      if (!firstN && !lastN) return false;

      if (firstN && lastN) {
        const first = firstN.toLowerCase();
        const last = lastN.toLowerCase();
        const searchable = first.concat(last).toLowerCase();
        return searchable.includes(searchTerm);
      } else if (lastN) {
        const last = lastN.toLowerCase();
        return last.includes(searchTerm);
      } else {
        const first = firstN.toLowerCase();
        return first.includes(searchTerm);
      }
    });
    this.setState({ filteredRecords: filtered });
  };

  componentDidMount() {
    NewProfile.fetchAll(1000).then((profiles) => {
      this.setState({ records: profiles, filteredRecords: profiles });
    });
  }
}

export default withTheme(DesignProfiles);
