import * as React from "react";
import { makeStyles } from "@material-ui/core";
import CurrentJobDash from "./CurrentJobDash";
import { Agent, Project } from "@yardzen-inc/models";
import DesignerSidebar from "./DesignerSidebar";
import SnackbarError from "../Components/SnackbarError";
import { assignNextProjectToDesigner } from "../util/selfAssign/assignDesignerSelfAssignAssignment";
import useDssStatesOrderedMap from "../util/selfAssign/useDssStatesOrderedMap";
import { getProjectsByAgentId } from "../util/firebase/firebaseClient";
import LoadingAbsCenter from "../Components/LoadingAbsCenter";
import { assignmentIsClosed } from "../util/selfAssign/assignmentIsClosed";
import { useDispatch } from "../state/hooks";
import { setProject as setProjectInRedux } from "../state/slices/entities/projectsSlice";
import { pangaeaV1API } from "../api/pangaeaV1API";
import { getNextHighestPriorityAssignment } from "../util/selfAssign/getPrioritizedDesignAssignments";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    height: "calc(100vh - 4rem)",
    maxHeight: "calc(100vh - 4rem)",
  },
  body: {
    display: "flex",
    width: "100%",
  },
});

type Props = {
  agent: Agent;
};

const DesignerPageBody: React.FC<Props> = ({ agent }: Props) => {
  const classes = useStyles();

  const [locked, setLocked] = React.useState<boolean>(false);
  const [snackbarText, setSnackbarText] = React.useState<string | null>(null);
  const [hasAvailableAssignment, setHasAvailableAssignment] = React.useState<
    number | null
  >(null);
  const [currentProject, setCurrentProject] = React.useState<Project | null>(
    null
  );
  const [assignedProjects, setAssignedProjects] = React.useState<
    Project[] | null
  >(null);
  const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);
  const [getSelfAssignQueue] =
    pangaeaV1API.endpoints.getSelfAssignQueue.useLazyQuery({});

  const [setDesignAssignmentDueDate] =
    pangaeaV1API.useSetDesignAssignmentDueDateMutation();

  const dssStates = useDssStatesOrderedMap();

  const dispatch = useDispatch();

  const fetchNextProject = async () => {
    const { data: queue, error } = await getSelfAssignQueue({});
    if (error) {
      setSnackbarText("There was an error getting new the project queue.");
      console.error(error);
      return;
    }

    const nextProject = await getNextHighestPriorityAssignment(
      agent,
      queue ?? []
    );

    return nextProject;
  };

  React.useEffect(() => {
    if (!assignedProjects) {
      getAssignedProjects();
      return;
    }

    const getCurrentProject: Project | undefined = assignedProjects?.find(
      (p) =>
        !!p.currentDesignAssignmentId &&
        !assignmentIsClosed(p.designAssignmentStatus)
    );
    if (
      !(typeof assignedProjects === "string") &&
      assignedProjects.length > 0 &&
      !currentProject &&
      getCurrentProject
    ) {
      setCurrentProject(getCurrentProject);
      setHasLoaded(true);
    } else {
      fetchNextProject()
        .then((project) => setHasAvailableAssignment(!project ? 0 : 1))
        .catch(console.error)
        .finally(() => setHasLoaded(true));
    }
  }, [assignedProjects]);

  React.useEffect(() => {
    if (currentProject) {
      dispatch(setProjectInRedux(currentProject));
    }
  }, [currentProject, dispatch, getSelfAssignQueue]);

  return (
    <>
      <div id="self-assign-page-body-root" className={classes.root}>
        <DesignerSidebar
          assigned={assignedProjects}
          selectProject={(p) => setCurrentProject(p)}
          selectedProject={currentProject}
          index={0} //delete this prop with useExpeditedUpgrade split removal
          setIndex={() => {}} //delete this prop with useExpeditedUpgrade split removal
        />
        <div className={classes.body}>
          {hasLoaded ? (
            <CurrentJobDash
              disabled={locked}
              assignment={assignedProjects?.length ? currentProject : "none"}
              // Note that the openAssignmentCount is not an
              // accurate count for designers and will be set to 1
              // if there is an available project to pick up
              openAssignmentCount={hasAvailableAssignment}
              requestNextJob={assign}
            />
          ) : (
            <LoadingAbsCenter in />
          )}
        </div>
      </div>
      <SnackbarError
        errorText={snackbarText}
        handleSnackbarClose={() => setSnackbarText(null)}
      />
    </>
  );

  async function getAssignedProjects(): Promise<void> {
    try {
      const projects = await getProjectsByAgentId(agent.id);
      setAssignedProjects(projects);
    } catch (err) {
      console.error(err);
      setSnackbarText("There was an error getting the assigned projects.");
    } finally {
      setHasLoaded(true);
    }
  }

  async function assign(): Promise<void> {
    try {
      setLocked(true);
      await assignNextProjectToDesigner(
        agent,
        setSnackbarText,
        dssStates,
        setDesignAssignmentDueDate,
        fetchNextProject
      );
      return await getAssignedProjects();
    } catch (err) {
      console.error(err);
    } finally {
      setLocked(false);
    }
  }
};

export { DesignerPageBody };
