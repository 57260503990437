import { Profile, Revision } from "@yardzen-inc/models";

/**
 * Indicates if the provided `Revision` is currently visible or "delivered" to the
 * client
 * @param revision `Revision`
 * @param profile `Profile`
 * @returns `boolean` indicating whether Revision is active
 */
function isActiveRevision(revision: Revision, profile: Profile): boolean {
  const isFinal = revision.id === profile.finalRevision;
  const isConceptual = revision.id === profile.conceptualRevision;
  const isV3 = revision.id === profile.v3Revision;
  return isFinal || isConceptual || isV3;
}

export { isActiveRevision };
export default isActiveRevision;
