import { Profile, Media, Project, MediaProperties } from "@yardzen-inc/models";
import {
  DesignBriefEntryGroupMap,
  DesignBriefEntryType,
  EntryMediaType,
} from "./types";
import DesignBriefEntry, {
  DesignBriefEntryProperties,
} from "./DesignBriefEntry";
import DesignBriefApi from "./DesignBriefApi";
import firebase from "firebase/compat/app";
import { OnboardData } from "../../../ClientDetail/Onboarding/OnboardDataProvider";
import generateDefaultDesignBriefV1 from "./generateDefaultDesignBriefV1";
import {
  ApolloClient,
  BudgetChecklistResponseItemForDesignBriefDisplayByProjectIdQueryResult,
  BUDGET_CHECKLIST_RESPONSE_ITEMS_FOR_DESIGN_BRIEF_DISPLAY_BY_PROJECT_ID,
} from "@yardzen-inc/graphql";
import { formatNumToUSD } from "@yardzen-inc/react-common";
import { getUSDAZone } from "../../../util/functions/getUSDAZone";
import { getIsExteriorPackage } from "../../../util/selfAssign/getIsExteriorPackage";

export const PLANT_PREFERENCES = "PLANT_PREFERENCES";
export const PRIORITIES = "PRIORITIES";
export const CLIENT_FEEDBACK = "CLIENT_FEEDBACK";
export const INSPIRATION = "INSPIRATION";
export const ELEMENTS = "ELEMENTS";
export const PROJECT_MANAGER_NOTES = "PROJECT_MANAGER_NOTES";
export const ITEMS_TO_KEEP = "ITEMS_TO_KEEP";
export const ITEMS_TO_REMOVE = "ITEMS_TO_REMOVE";
export const YARD_FUNCTIONS = "YARD_FUNCTIONS";
export const YARD_SLOPE = "YARD_SLOPE";
export const EXTERIOR_INSPIRATION = "EXTERIOR_INSPIRATION";
export const PAINT_COLORS = "PAINT_COLORS";

export const V2_1ORDER = [
  PLANT_PREFERENCES,
  PRIORITIES,
  CLIENT_FEEDBACK,
  ELEMENTS,
  ITEMS_TO_KEEP,
  ITEMS_TO_REMOVE,
  YARD_FUNCTIONS,
  INSPIRATION,
  PROJECT_MANAGER_NOTES,
  YARD_SLOPE,
];

const yards = ["front", "left", "right", "back"];

async function generateDefaultDesignBriefV2_1(
  api: DesignBriefApi,
  profile: Profile,
  dropCollection?: boolean,
  apolloClient?: ApolloClient<any>
): Promise<DesignBriefEntryGroupMap> {
  if (dropCollection) {
    await api.dropCollection();
  }

  const project = await Project.fetchWithProfileId(profile.id);
  const wantsRewilding =
    profile.wantsRewilding === undefined || profile.wantsRewilding === null
      ? "Refer to quiz answers"
      : profile.wantsRewilding
      ? "Yes"
      : "No";

  const onboardDataQuerySnap = await firebase
    .firestore()
    .collection("onboardStates")
    .where("projectId", "==", project.id)
    .get();

  if (onboardDataQuerySnap.empty) {
    console.error(
      new Error(
        "Client has not yet filled in onboarding data for this experience version"
      )
    );

    alert(
      "This client has not yet filled in onboarding data for this version of the experience. Defaulting to an older design brief template..."
    );

    return generateDefaultDesignBriefV1(api, profile, true);
  }

  const onboardData = onboardDataQuerySnap.docs[0].data() as OnboardData;

  const map: DesignBriefEntryGroupMap = {
    [PLANT_PREFERENCES]: await generatePlantPreferences(),
    [PRIORITIES]: await generatePriorities(),
    [CLIENT_FEEDBACK]: await generateClientFeedback(),
    [ELEMENTS]: await generateElements(),
    [ITEMS_TO_KEEP]: await generateKeepOrRemoveItems(),
    [ITEMS_TO_REMOVE]: await generateKeepOrRemoveItems(true),
    [YARD_FUNCTIONS]: await generateFunctions(),
    [INSPIRATION]: await generateInspiration(),
    [YARD_SLOPE]: await generateYardSlopes(),
    [PROJECT_MANAGER_NOTES]: [],
  };

  if (getIsExteriorPackage(profile.package)) {
    map[EXTERIOR_INSPIRATION] = await generateExteriorInspiration();
  }

  return map;

  async function generateYardSlopes() {
    const genericProps = {
      group: YARD_SLOPE,
      marked: false,
    };

    const getPosition = createOrderIncrementGenerator();

    const entries: DesignBriefEntryProperties<any>[] = [];

    for (const yard of yards) {
      const mediaSnap = await firebase
        .firestore()
        .collection("media")
        .where("userId", "==", profile.id)
        .where("tag", "==", "property")
        .where("variant", "==", `${yard}-yard-slope`)
        .get();

      mediaSnap.docs.forEach((doc) => {
        const data = (doc.data() ?? {}) as MediaProperties;

        entries.push({
          ...genericProps,
          label: `${yard.toUpperCase()} YARD:`,
          value: data.description || "",
          order: getPosition(),
          referenceImages: [
            {
              type: EntryMediaType.MEDIA_ID,
              value: doc.id,
              annotator: false,
            },
          ],
        } as DesignBriefEntryProperties<string>);
      });
    }

    return api.bulkCreateEntry(entries);
  }

  async function generateFunctions(): Promise<DesignBriefEntry<any>[]> {
    const genericProps = {
      group: YARD_FUNCTIONS,
      marked: false,
      type: DesignBriefEntryType.TEXT,
    };

    const entries: DesignBriefEntryProperties<any>[] = [];

    if (!onboardData.priorities) {
      return [];
    }

    const getPosition = createOrderIncrementGenerator();

    for (let yard of yards) {
      // @ts-ignore
      if (!onboardData.priorities[yard]) {
        continue;
      }
      // @ts-ignore
      const prios = onboardData.priorities[yard] as string[];

      for (let prio of prios) {
        entries.push({
          ...genericProps,
          label: `${yard.toUpperCase()} YARD:`,
          value: prio,
          order: getPosition(),
        });
      }
    }

    return api.bulkCreateEntry(entries);
  }

  async function generateKeepOrRemoveItems(
    remove?: boolean
  ): Promise<DesignBriefEntry<any>[]> {
    const genericProps = {
      group: remove ? ITEMS_TO_REMOVE : ITEMS_TO_KEEP,
      marked: false,
      type: DesignBriefEntryType.TEXT,
    };

    const entries: DesignBriefEntryProperties<any>[] = [];
    const getPosition = createOrderIncrementGenerator();

    for (let yard of yards) {
      const tag = "property";
      const variant = `${yard}-yard-${remove ? "remove" : "keep"}`;

      const mediaSnap = await firebase
        .firestore()
        .collection("media")
        .where("userId", "==", profile.id)
        .where("tag", "==", tag)
        .where("variant", "==", variant)
        .get();

      mediaSnap.docs.forEach((doc) => {
        const data = (doc.data() ?? {}) as MediaProperties;

        entries.push({
          ...genericProps,
          label: `${yard.toUpperCase()} YARD:`,
          value: data.description,
          order: getPosition(),
          referenceImages: [
            {
              type: EntryMediaType.MEDIA_ID,
              value: doc.id,
              annotator: false,
            },
          ],
        } as DesignBriefEntryProperties<string>);
      });
    }

    return api.bulkCreateEntry(entries);
  }

  async function generatePlantPreferences(): Promise<DesignBriefEntry<any>[]> {
    const genericProps = {
      group: PLANT_PREFERENCES,
      marked: false,
    };

    const getPosition = createOrderIncrementGenerator();

    let usdaZone = "";
    try {
      usdaZone = await getUSDAZone(profile?.zip || "");
    } catch (e) {
      console.error(
        `Unable to fetch USDA zone for generating V2 design brief: ${e.message}`
      );
    }

    /*
      The plant database filters will be a multi select / tag cloud
      type of deal, with an interface with a search/select component.
      This is out of the scope of the V2_1 i believe, and I (Ben) do not have
      the required info for the plant database / tags to figure that out.
      For now they will be text inputs, here's a todo comment:

      TODO: implent searchable plant database tag interface for auto-genned design brief.
    */
    const entryProperties: DesignBriefEntryProperties<any>[] = [
      {
        ...genericProps,
        order: getPosition(),
        type: DesignBriefEntryType.TEXT,
        value: usdaZone,
        label: "USDA ZONE:",
        referenceLink: "https://planthardiness.ars.usda.gov",
      },
      {
        ...genericProps,
        order: getPosition(),
        type: DesignBriefEntryType.TEXT,
        value: "",
        label: "Adapted Habitat (geo):",
      },
      {
        ...genericProps,
        order: getPosition(),
        type: DesignBriefEntryType.TEXT,
        value:
          "All mature trees and shrubs to remain unless specified otherwise below",
      },
      {
        ...genericProps,
        order: getPosition(),
        type: DesignBriefEntryType.TEXT,
        label: "Design to ARP Standards?:",
        value: wantsRewilding ?? "Refer to quiz answers",
      },
    ];

    return api.bulkCreateEntry(entryProperties);
  }

  async function generatePriorities(): Promise<DesignBriefEntry<any>[]> {
    const genericProps = {
      group: PRIORITIES,
      marked: false,
    };

    const getPosition = createOrderIncrementGenerator();

    const entryProperties = [
      // @ts-ignore
      profile["priority1"] as string,
      // @ts-ignore
      profile["priority2"] as string,
      // @ts-ignore
      profile["priority3"] as string,
      // @ts-ignore
      profile["priority4"] as string,
      // @ts-ignore
      profile["priority5"] as string,
    ]
      .filter((priority) => !!priority)
      .map((priority, i) => ({
        ...genericProps,
        order: getPosition(),
        type: DesignBriefEntryType.TEXT,
        label: `Priority ${i + 1}:`,
        value: `${priority}`,
      }));

    return api.bulkCreateEntry(entryProperties);
  }

  async function generateClientFeedback(): Promise<DesignBriefEntry<any>[]> {
    const genericProps = {
      group: CLIENT_FEEDBACK,
      marked: false,
    };

    const getPosition = createOrderIncrementGenerator();

    const hoaGuidelines = `*HOA Guidelines*: ${
      // @ts-ignore
      profile["guidelinesForHOA"] || "[left blank]"
    }`;

    const entryProperties = [
      // @ts-ignore
      `Likes: ${profile["answer4"] || "[left blank]"}`,
      // @ts-ignore
      `Dislikes: ${profile["answer5"] || "[left blank]"}`,
      // @ts-ignore
      `Additional feedback: ${profile["answer3"] || "[left blank]"}`,
    ];

    if (project.isHOA) {
      entryProperties.unshift(hoaGuidelines);
    }

    const designBriefEntryProperties = entryProperties.map((priority, i) => ({
      ...genericProps,
      order: getPosition(),
      type: DesignBriefEntryType.TEXT,
      value: priority,
    }));

    return api.bulkCreateEntry(designBriefEntryProperties);
  }

  async function generateInspiration(): Promise<DesignBriefEntry<any>[]> {
    const genericProps = {
      group: INSPIRATION,
      marked: false,
    };

    const getPosition = createOrderIncrementGenerator();

    const inspoMedia = await Promise.all(
      (
        await firebase
          .firestore()
          .collection("media")
          .where("userId", "==", profile.id)
          .where("tag", "==", "inspiration")
          .get()
      ).docs.map(Media.createFromQuerySnapshot)
    );

    const entryProperties = inspoMedia.map(
      (med) =>
        ({
          ...genericProps,
          type: DesignBriefEntryType.TEXT,
          value: med.description ?? "[no description]",
          order: getPosition(),
          referenceImages: [
            { type: EntryMediaType.MEDIA_ID, value: med.id, annotator: false },
          ],
        } as DesignBriefEntryProperties<any>)
    );

    return api.bulkCreateEntry(entryProperties);
  }

  async function generateExteriorInspiration(): Promise<
    DesignBriefEntry<any>[]
  > {
    const genericProps = {
      group: EXTERIOR_INSPIRATION,
      marked: false,
    };

    const getPosition = createOrderIncrementGenerator();

    const inspoMedia = await Promise.all(
      (
        await firebase
          .firestore()
          .collection("media")
          .where("userId", "==", profile.id)
          .where("tag", "==", "exterior-design-inspiration")
          .get()
      ).docs.map(Media.createFromQuerySnapshot)
    );

    const entryProperties = inspoMedia.map(
      (med) =>
        ({
          ...genericProps,
          type: DesignBriefEntryType.TEXT,
          value: med.description ?? "[no description]",
          order: getPosition(),
          referenceImages: [
            { type: EntryMediaType.MEDIA_ID, value: med.id, annotator: false },
          ],
        } as DesignBriefEntryProperties<any>)
    );

    return api.bulkCreateEntry(entryProperties);
  }

  async function generateElements(): Promise<DesignBriefEntry<any>[]> {
    const genericProps = {
      group: ELEMENTS,
      marked: false,
    };

    const getPosition = createOrderIncrementGenerator();

    const elements = profile.elements;

    let apolloElements: DesignBriefEntryProperties<string>[] = [];
    if (apolloClient) {
      const data = await getBudgetExcerciseInput(apolloClient, project.id);

      apolloElements =
        [...(data?.budget_checklist_response || [])]
          .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
          .map((resp: any): DesignBriefEntryProperties<string> => {
            const yards: string[] = [];
            let multiplier = 0;
            let low = 0;
            let high = 0;

            if (resp.front_yard) yards.push("FRONT");
            if (resp.right_yard) yards.push("RIGHT");
            if (resp.back_yard) yards.push("BACK");
            if (resp.left_yard) yards.push("LEFT");

            if (resp.budget_checklist_item.quantitative_item) {
              low += parseInt(resp.budget_checklist_price_option.low_price, 10);
              high += parseInt(
                resp.budget_checklist_price_option.high_price,
                10
              );
            } else {
              void [
                resp?.front_yard,
                resp?.back_yard,
                resp?.left_yard,
                resp?.right_yard,
              ].forEach((yard) => {
                if (!!yard) {
                  multiplier += 1;
                }
              });

              if (!multiplier) {
                multiplier = 1;
              }

              low +=
                parseInt(resp.budget_checklist_price_option.low_price, 10) *
                multiplier;
              high +=
                parseInt(resp.budget_checklist_price_option.high_price, 10) *
                multiplier;
            }

            const text = `
        Item: ${resp.budget_checklist_item.name}
        Price Range: ${formatNumToUSD(low)} - ${formatNumToUSD(high)}
        Description: ${resp.budget_checklist_price_option.description}
        Location: ${yards.length ? yards.join(", ") : "Client did not specify"}
        Additional Client Info: ${resp.context}
        ${
          !resp.budget_checklist_item.quantitative_item
            ? `Quantity: ${multiplier || 1}`
            : ""
        }
        ${
          resp.budget_checklist_style_option
            ? `Style: ${resp.budget_checklist_style_option?.budget_checklist_style.name}`
            : ""
        }
      `;
            const referenceImages: DesignBriefEntryProperties<any>["referenceImages"] =
              [];

            if (resp.budget_checklist_item.medium?.public_uri) {
              referenceImages.push({
                annotator: false,
                type: EntryMediaType.URL,
                value: resp.budget_checklist_item.medium?.public_uri,
              });
            }

            if (
              resp.budget_checklist_price_option.image?.image_variants?.length
            ) {
              referenceImages.push({
                annotator: false,
                type: EntryMediaType.URL,
                value:
                  resp.budget_checklist_price_option.image?.image_variants[0]
                    .medium.public_uri ?? "",
              });
            }

            if (
              resp?.budget_checklist_style_option?.image?.image_variants?.length
            ) {
              referenceImages.push({
                annotator: false,
                type: EntryMediaType.URL,
                value:
                  resp.budget_checklist_style_option.image?.image_variants[0]
                    .medium.public_uri ?? "",
              });
            }

            return {
              group: ELEMENTS,
              marked: false,
              order: getPosition(),
              type: DesignBriefEntryType.TEXT,
              value: text,
              referenceImages,
            };
          })
          .filter((item: any) => !!item) ?? [];
    }

    const entryProperties = Object.entries(elements ?? [])
      .filter(([_, val]) => !!val)
      .map(([key]) => ({
        ...genericProps,
        type: DesignBriefEntryType.TEXT,
        value: key,
        order: getPosition(),
      }));

    return api.bulkCreateEntry([...apolloElements, ...entryProperties]);
  }
}

function createOrderIncrementGenerator(): () => number {
  let currentOrder = 0;

  return () => {
    return (currentOrder += 100);
  };
}

async function getBudgetExcerciseInput(
  client: ApolloClient<any>,
  projectId: string
) {
  try {
    const { data, error } = (await client.query({
      query:
        BUDGET_CHECKLIST_RESPONSE_ITEMS_FOR_DESIGN_BRIEF_DISPLAY_BY_PROJECT_ID,
      variables: {
        projectId,
      },
      fetchPolicy: "network-only",
    })) as BudgetChecklistResponseItemForDesignBriefDisplayByProjectIdQueryResult;

    if (error) throw error as Error;

    return data;
  } catch (error) {
    console.error("Error fetching bugetExerciseInput: ", error);
    return void 0;
  }
}

export { generateDefaultDesignBriefV2_1 };
export default generateDefaultDesignBriefV2_1;
