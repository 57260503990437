import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  disabledStyle: {
    width: "100px",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "red",
    fontSize: "17px",
    backgroundColor: "#ffcccb",
    marginLeft: "50px",
  },
});

export interface DeactivationMessageProps {
  isUserDisabled: boolean;
}

const DeactivationMessage: React.FC<DeactivationMessageProps> = ({
  isUserDisabled,
}) => {
  const { disabledStyle } = useStyles();

  if (isUserDisabled) {
    return (
      <div>
        <div className={disabledStyle}>Deactivated</div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export { DeactivationMessage };
