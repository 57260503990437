import React from "react";

function usePreloadedAssetTableFilters(
  libraryName: "elements" | "plants" | "materials",
  designAssignmentId: string = "no-design-assignment"
  // designAssignmentID doesn't exist for PMs, so use a generic string instead.
) {
  const parsePreloadedFilters = (): Record<string, any> => {
    return JSON.parse(window.localStorage.getItem("preloadedFilters") || "{}");
  };

  const validateLocalStorage = () => {
    try {
      parsePreloadedFilters();
    } catch {
      window.localStorage.setItem("preloadedFilters", "{}");
    }
  };

  const getInitialFilterState = (
    filterType: "eq" | "neq" | "hasAnyOf" | "hasAllOf" | "search"
  ): any => {
    const preloadedFilterState =
      parsePreloadedFilters()?.[designAssignmentId]?.[libraryName]?.[
        filterType
      ];

    if (filterType === "search") {
      return preloadedFilterState;
    }
    // debugger;
    return Array.isArray(preloadedFilterState) ? preloadedFilterState : [];
  };

  validateLocalStorage();

  const [neqFilterState, setNeqFilterState] = React.useState(
    getInitialFilterState("neq")
  );

  const [hasAnyOfFilterState, setHasAnyOfFilterState] = React.useState(
    getInitialFilterState("hasAnyOf")
  );

  const [hasAllOfFilters, setHasAllOfFilters] = React.useState(
    getInitialFilterState("hasAllOf")
  );
  const [search, setSearch] = React.useState(getInitialFilterState("search"));

  React.useEffect(() => {
    const preloadedFilters: Record<
      string,
      Record<string, any>
    > = parsePreloadedFilters();
    const designAssignment: any = (preloadedFilters[designAssignmentId] =
      preloadedFilters[designAssignmentId] || {});

    const library = (designAssignment[libraryName] =
      designAssignment[libraryName] || {});
    library.neq = neqFilterState;
    library.hasAnyOf = hasAnyOfFilterState;
    library.hasAllOf = hasAllOfFilters;
    library.search = search;
    window.localStorage.setItem(
      "preloadedFilters",
      JSON.stringify(preloadedFilters)
    );
  }, [neqFilterState, hasAnyOfFilterState, hasAllOfFilters, search]);

  return [
    neqFilterState,
    setNeqFilterState,
    hasAnyOfFilterState,
    setHasAnyOfFilterState,
    hasAllOfFilters,
    setHasAllOfFilters,
    search,
    setSearch,
  ];
}

export default usePreloadedAssetTableFilters;
