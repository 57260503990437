import * as React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@material-ui/core";

export interface IDesignProfileFilterProps {
  handleChange: CallableFunction;
  handleSearch: CallableFunction;
  pageLength: number;
  hasBeenContactedFilter: string;
  searchTerm: string;
  nextPage: CallableFunction;
  prevPage: CallableFunction;
  disablePrev: boolean;
  disableNext: boolean;
}

export function DesignProfileFilter(props: IDesignProfileFilterProps) {
  return (
    <div>
      <div>
        <FormControl style={{ margin: "1rem" }}>
          <InputLabel style={{ width: "200px" }} htmlFor="pageLength">
            Records per page
          </InputLabel>
          <Select
            style={{ width: "120px" }}
            onChange={(e) => props.handleChange("pageLength", e.target.value)}
            value={props.pageLength}
            inputProps={{
              id: "pageLength",
            }}
          >
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={200}>200</MenuItem>
          </Select>
        </FormControl>
        {/* <FormControl style={{ margin: "1rem" }}>
        <InputLabel style={{ width: "200px" }} htmlFor="hasBeen">
          Has been contacted?
        </InputLabel>
        <Select
          style={{ width: "120px" }}
          onChange={e =>
            props.handleChange("hasBeenContactedFilter", e.target.value)
          }
          value={props.hasBeenContactedFilter}
          inputProps={{
            id: "hasBeen",
          }}
        >
          <MenuItem value={"both"}>
            <em>Both</em>
          </MenuItem>
          <MenuItem value={"has"}>Has Been Contacted</MenuItem>
          <MenuItem value={"hasNot"}>Has Not Been Contacted</MenuItem>
        </Select>
      </FormControl> */}
        <FormControl style={{ margin: "1rem" }}>
          <TextField
            label="Search Name"
            value={props.searchTerm}
            onChange={(e) => props.handleSearch(e.target.value)}
            inputProps={{
              id: "hasBeen",
            }}
          />
        </FormControl>
      </div>
      <div>
        <Button
          style={{
            margin: "1rem",
          }}
          variant="contained"
          disabled={props.disablePrev}
          onClick={() => props.prevPage()}
        >
          Prev Page
        </Button>
        <Button
          variant="contained"
          disabled={props.disableNext}
          style={{ margin: "1rem" }}
          onClick={() => props.nextPage()}
        >
          Next Page
        </Button>
      </div>
    </div>
  );
}
