import { useState, useEffect, useContext } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { ProjectManager } from "@yardzen-inc/models";
import { UserCtx } from "../../util/UserContext";
import environmentConstants from "../../ConstantValues/environmentConstants";

interface UsePM {
  (projectManagerId?: string): ProjectManager | null | false;
}

const usePM: UsePM = (pmId) => {
  const [pm, setPm] = useState<ProjectManager | null | false>(null);

  const userId = (useContext(UserCtx) || null)?.uid;

  useEffect(subscribeToPm, [userId, pmId]);

  return pm;

  function subscribeToPmFromId(pmId: string): (() => void) | void {
    return firebase
      .firestore()
      .collection("projectManagers")
      .doc(pmId)
      .onSnapshot(handlePmSnapshot);
  }

  function subscribeToPm(): (() => void) | void {
    if (pmId) return subscribeToPmFromId(pmId);

    if (!userId) {
      return void 0;
    }

    return firebase
      .firestore()
      .collection("projectManagers")
      .where("userId", "==", userId)
      .onSnapshot(({ docs, empty }) => {
        if (empty) {
          setPm(false);
          return;
        }

        if (
          process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT &&
          docs.length > 1
        ) {
          console.error("Multiple project managers associated with user id");
        }

        handlePmSnapshot(docs[0]);
      });
  }

  function handlePmSnapshot(doc: firebase.firestore.DocumentSnapshot) {
    if (!doc.exists) {
      setPm(false);
      return;
    }

    const pm = ProjectManager.hydrate([doc])[0];

    setPm(pm);
  }
};

export { usePM };
export default usePM;
