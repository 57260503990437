import * as React from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

export interface CloseButtonProps {
  top?: number | string;
  left?: number | string;
  right?: number | string;
  bottom?: number | string;
  style?: React.CSSProperties;
  onClick: () => void;
}

export const CloseButton = ({ style, onClick, ...rest }: CloseButtonProps) => {
  return (
    <IconButton
      style={{
        position: "absolute",
        top: 15,
        right: 15,
        ...(style as React.CSSProperties),
        ...rest,
      }}
      onClick={onClick}
    >
      <Close></Close>
    </IconButton>
  );
};

CloseButton.defaultProps = {
  style: {},
};

export default CloseButton;
