import { Box, MenuItem, TextField } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { useSkipMount } from "src/util/hooks";

export const promotionalCodeFilterOptions = [
  "startTime",
  "endTime",
  "maxRedemptions",
  "createdAt",
  "updatedAt",
  "deactivated",
] as const;
export const promotionalCodeFilterDirections = ["asc", "desc"] as const;

type OrderByOption = typeof promotionalCodeFilterOptions[number];
type OrderByDirection = typeof promotionalCodeFilterDirections[number];

export interface PromotionalCodeFilterValue {
  orderBy: OrderByOption;
  direction: OrderByDirection;
  code: string;
}

export interface PromotionalCodeFilterBarProps {
  handleOrderByChange: (change: Partial<PromotionalCodeFilterValue>) => void;
  value: PromotionalCodeFilterValue;
}

export const PromotionalCodeFilterBar: FC<PromotionalCodeFilterBarProps> = (
  props
) => {
  const [code, setCode] = React.useState<string>(props.value.code);
  const mounted = useSkipMount();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (mounted) {
        props.handleOrderByChange({ code });
      }
    }, 350);
    return () => clearTimeout(timeout);
  }, [code]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      p={1}
      justifyContent="flex-end"
      alignItems={"flex-end"}
    >
      <Box flexGrow={1} pr={1}>
        <TextField
          placeholder="lookup by Code"
          fullWidth
          value={code}
          onChange={({ currentTarget: { value } }) =>
            setCode(value.toUpperCase().replace(/[^A-Z0-9-_]/g, ""))
          }
        />
      </Box>
      <Box>
        <TextField
          label="Order By"
          select
          value={props.value.orderBy}
          onChange={(ev) =>
            props.handleOrderByChange({
              orderBy: ev.target.value as unknown as OrderByOption,
            })
          }
        >
          {promotionalCodeFilterOptions.map((option) => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box pl={2}>
        <TextField
          label="Direction"
          select
          value={props.value.direction}
          onChange={(ev) =>
            props.handleOrderByChange({
              direction: ev.target.value as unknown as OrderByDirection,
            })
          }
        >
          {promotionalCodeFilterDirections.map((option) => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </Box>
  );
};
