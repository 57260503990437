import { useContext, useEffect, useRef, useState } from "react";
import LogRocket from "logrocket";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { UserCtx } from "../UserContext";
import { UserRole } from "../../Interfaces";

export interface UseLogrocket {
  (enable?: boolean, userRole?: UserRole, env?: string | string[]): void;
}

const nodeEnv = process.env.NODE_ENV;
let _logrocketOn = false;

const useLogrocket: UseLogrocket = (
  explicitEnable?: boolean,
  userRole?: UserRole,
  env?: string | string[]
) => {
  const userRegisteredRef = useRef<boolean>(false);
  const [userOnWatchlist, setUserOnWatchlist] = useState<boolean>(false);
  const [logrocketOn, setLogrocketOn] = useState<boolean>(_logrocketOn);
  const user = useContext(UserCtx);

  const logRocketAppName =
    userRole === UserRole.Designer ? "liisa-designers" : "liisa";

  useEffect(listenForUserIdOnWatchlist, [user, explicitEnable]);
  useEffect(onArgumentsChange, [explicitEnable, env, userOnWatchlist]);
  useEffect(setGlobalLogrocketOn, [logrocketOn]);
  useEffect(registerUserWithLogrocket, [logrocketOn, user]);

  function registerUserWithLogrocket() {
    if (userRegisteredRef.current) {
      return;
    }

    if (user && user.email && logrocketOn) {
      const identifiers: { [key: string]: string } = {
        email: user.email,
      };

      if (userRole) {
        identifiers.role = userRole as string;
      }

      LogRocket.identify(user.uid, identifiers);

      userRegisteredRef.current = true;
    }
  }

  function setGlobalLogrocketOn(): void {
    if (logrocketOn) {
      _logrocketOn = true;
    }
  }

  function onArgumentsChange(): void {
    if (logrocketOn || !isCorrectEnvironment(env)) {
      return;
    }

    if (typeof explicitEnable !== "undefined" && !explicitEnable) {
      return;
    }

    void (async function () {
      if (!explicitEnable) {
        if (!user) {
          return;
        }

        if (!userOnWatchlist) {
          return;
        }
      }

      initializeLogrocket(logRocketAppName);
      setLogrocketOn(true);
    })();
  }

  function listenForUserIdOnWatchlist(): (() => void) | void {
    if (explicitEnable || !user || !user.uid) {
      return;
    }

    return firebase
      .firestore()
      .collection("logrocket-watchlist")
      .doc(user.uid)
      .onSnapshot((snap) => {
        if (snap.data()?.enable === true) {
          return setUserOnWatchlist(true);
        }

        setUserOnWatchlist(false);
      });
  }
};

function isCorrectEnvironment(env?: string | string[]): boolean {
  return true;
  if (!env) {
    return true;
  }

  return (
    nodeEnv === env || !!(Array.isArray(env) && env.includes(nodeEnv ?? ""))
  );
}

function initializeLogrocket(logRocketAppName: string): void {
  try {
    LogRocket.init(`snab0e/${logRocketAppName}`);
  } catch (e) {
    console.error("Error initiating LogRocket! ", e);
  }
}

export { useLogrocket };
export default useLogrocket;
