import React, { FC } from "react";
import { Box, IconButton } from "@material-ui/core";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";

export interface MoveUpDownButtonsProps {
  moveUp?: () => void;
  moveDown?: () => void;
}

const MoveUpDownButtons: FC<MoveUpDownButtonsProps> = (props) => {
  return (
    <Box pl={1}>
      <Box p={1}>
        <IconButton
          size="small"
          onClick={props.moveUp}
          disabled={!props.moveUp}
        >
          <ArrowUpward />
        </IconButton>
      </Box>
      <Box p={1}>
        <IconButton
          size="small"
          onClick={props.moveDown}
          disabled={!props.moveDown}
        >
          <ArrowDownward />
        </IconButton>
      </Box>
    </Box>
  );
};

export { MoveUpDownButtons };
export default MoveUpDownButtons;
