import { YardDifficultyRating } from "@yardzen-inc/models";

/**
 * Takes a yardDifficultyRating, saved as a number 1-3, and returns its human readable string value
 * @param {YardDifficultyRating} rating - The yardDifficultyRating
 * @returns string
 */
export const yardDifficultyRatingToString = (
  rating: YardDifficultyRating
): string => {
  if (rating === 1) {
    return "Easy";
  }
  if (rating === 2) {
    return "Average";
  }

  if (rating === 3) {
    return "Difficult";
  }

  return "Yard difficulty not available";
};
