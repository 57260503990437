import React, { FC, useRef } from "react";
import Box from "@material-ui/core/Box";
import { Project, Assignment } from "@yardzen-inc/models";
import { RouteComponentProps } from "react-router-dom";
import { LinearProgress, Typography } from "@material-ui/core";
import AssignmentTable from "../../EmployeeView/Assignments/AssignmentTable";

export interface CdmAssignmentListProps extends RouteComponentProps {
  project: Project | false | null;
  cdmAssignments: null | Assignment[];
}

const CdmAssignmentList: FC<CdmAssignmentListProps> = ({
  project,
  cdmAssignments,
  history,
}) => {
  const listRef = useRef<HTMLDivElement>(null);

  if (project == null && !cdmAssignments) {
    return <LinearProgress variant="indeterminate" />;
  }

  if (project === false || !cdmAssignments?.length) {
    return (
      <Typography variant="h4" align="center">
        No cdm assignments found
      </Typography>
    );
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {renderAssignments()}
    </Box>
  );

  function handleAssignmentClick(assignment: Assignment): void {
    history.push(`/cdm/${(project as Project).id}/${assignment.id}`);
  }

  function renderAssignments() {
    return (
      <AssignmentTable
        useArchive
        syncing={false}
        listRef={listRef}
        order="asc"
        assignments={cdmAssignments as Assignment[]}
        onClick={handleAssignmentClick}
      />
    );
  }
};

export { CdmAssignmentList };
export default CdmAssignmentList;
