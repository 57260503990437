import * as React from "react";
import { Assignment } from "@yardzen-inc/models";
import {
  Drawer,
  makeStyles,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import AssignmentBrief from "../../ClientDetail/Assignments/AssignmentBrief";
import { Close } from "@material-ui/icons";
import { YZButton } from "@yardzen-inc/react-common";

const useStyles = makeStyles({
  root: { display: "flex", flexFlow: "column nowrap" },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  spacer: { display: "inline-block", transition: "height 300ms", height: 0 },
  button: {
    flexGrow: 1,
    padding: "1rem 0",
  },
  buttonBar: {
    display: "flex",
    flexFlow: "row nowrap",
  },
  checkboxBar: {
    display: "flex",
    flexFlow: "row nowrap",
    padding: "1rem",
  },
});

interface Props {
  selectedAssignment: Assignment | null;
  open: boolean;
  onClose: () => void;
  listRef?: React.RefObject<HTMLDivElement>;
  buttonProps: { label: string; disabled?: boolean; onClick: () => void }[];
}

export default (props: Props) => {
  const classes = useStyles();
  const ref = React.useRef<HTMLDivElement>(null);
  const [listed, setListed] = React.useState<boolean | null>(null);
  const [assignment, setAssignment] = React.useState<Assignment | null>(null);

  const assigned = !!(
    (assignment ?? props.selectedAssignment) &&
    (assignment ?? props.selectedAssignment)?.assiged
  );

  React.useEffect(() => {
    if (!props.selectedAssignment) {
      return;
    }

    let unsub: () => void;
    if (assignment ?? props.selectedAssignment) {
      unsub = (assignment ?? props?.selectedAssignment)
        .getDocRef()
        .onSnapshot(async (snap) => {
          if (snap.exists) {
            const data = snap.data();

            if (!data) throw new Error("got no data");
            if (typeof data.listed !== "boolean") data.listed = true;

            setListed(data.listed);
            setAssignment(null);
            setAssignment(
              await Assignment.initializefromDoc(
                snap as firebase.firestore.QueryDocumentSnapshot
              )
            );
          }
        });
    }

    return () => {
      if (unsub) unsub();
    };
  }, [props?.selectedAssignment?.id ?? null]);

  React.useEffect(() => {
    try {
      if (props?.listRef?.current) {
        const { current } = props.listRef;
        if (props.open) {
          setTimeout(() => {
            current.scrollTop -= getHeight();
          }, 0);
        } else {
          setTimeout(() => {
            current.scrollTop += getHeight();
          }, 0);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [props.open]);

  if (!props.selectedAssignment) {
    return null;
  }

  return (
    <>
      <Drawer
        open={props.open}
        variant="persistent"
        anchor="bottom"
        onClose={props.onClose}
      >
        <div className={classes.root} ref={ref}>
          <IconButton onClick={props.onClose} className={classes.closeButton}>
            <Close></Close>
          </IconButton>
          {(assignment ?? props.selectedAssignment) && (
            <>
              <AssignmentBrief
                assignment={assignment ?? props.selectedAssignment}
                showAddress
                showClientInfo
                noCache
              />
              <div className={classes.checkboxBar}>
                <FormControlLabel
                  label="Listed"
                  disabled={typeof listed !== "boolean" || assigned}
                  control={
                    <Checkbox
                      checked={!!listed}
                      disabled={typeof listed !== "boolean" || assigned}
                      onChange={() => handleListedChange()}
                    />
                  }
                />
              </div>
              <div className={classes.buttonBar}>
                {props.buttonProps.map(({ label, ...bp }, i) => (
                  <YZButton
                    key={`assignment-detail-button-${i}`}
                    className={classes.button}
                    variant="outlined"
                    style={{ borderRadius: 0 }}
                    {...bp}
                  >
                    {label}
                  </YZButton>
                ))}
              </div>
            </>
          )}
        </div>
      </Drawer>
      <div
        className={classes.spacer}
        style={{ height: `${getHeight()}px` }}
      ></div>
    </>
  );

  async function handleListedChange() {
    if (!assignment && !props.selectedAssignment) return;

    return ((assignment ?? props.selectedAssignment) as Assignment)
      .getDocRef()
      .set({ listed: !listed }, { merge: true });
  }

  function getHeight(): number {
    if (ref.current && props.open) {
      return ref.current.getBoundingClientRect().height;
    }
    return 0;
  }
};
