import { useQuery } from "@yardzen-inc/graphql";
import React from "react";
import firebase from "firebase/compat/app";
import { Addy, getAddress } from "../getAddress";
import { Profile } from "@yardzen-inc/models";
import * as firebaseClient from "../firebase/firebaseClient";
import { ACTIVE_GEO_AND_PLANT_HABITAT_QUERY } from "../../../src/graphql/budgetQAQueries";

/**
 * This functionality exists in client-account at: src/util/hooks/useGetGeoZones.tsx
 * Keep in mind in case this functionality is updated or firebase functions are migrated to Pangea
 */
export function useGetGeoZones(profile: Profile) {
  if (!profile)
    return {
      geoZoneError: null,
      loadingGeoZone: false,
      geoZones: null,
    };

  const getGeoZonesFuncRef = React.useRef(
    firebase.functions().httpsCallable("getGeoZonesForAssignment")
  );
  const { latLng } = profile;
  const [loadingZone, setLoadingZone] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [loadingId, setLoadingId] = React.useState(true);
  const [zones, setZones] = React.useState<null | string[]>(null);
  const [userGeoZones, setUserGeoZones] = React.useState<
    null | { id: string; name: string }[]
  >(null);
  const [geoZonesMapAsJson, setGeoZonesMapAsJson] =
    React.useState<GeoJSON.GeoJsonObject | null>(null);
  const [address, setAddress] = React.useState<Addy | null | undefined>(
    undefined
  );

  React.useEffect(() => {
    const fetchAndSetAddress = async () => {
      try {
        setAddress(await getAddress(profile.id));
      } catch (e) {
        setError(
          `Unable to fetch client address to get geozones\nProfile: ${profile.id}`
        );
      }
    };

    fetchAndSetAddress();
  }, []);

  const parseGeoZonesMapFile = async () => {
    const url = activeGeoAndPlantHabitat.geozone_file?.[0]?.url;
    if (!url) {
      alert("Geozones map file not found. Please contact support.");
      return;
    }

    const mapFile = await fetch(url);
    const mapAsJson = await mapFile.json();
    setGeoZonesMapAsJson(mapAsJson);
  };

  const { data: activeGeoAndPlantHabitat } = useQuery(
    ACTIVE_GEO_AND_PLANT_HABITAT_QUERY,
    {
      variables: {
        archived: false,
      },
      fetchPolicy: "network-only",
      onCompleted: parseGeoZonesMapFile,
      onError: (err) => alert(err),
    }
  );

  const fetchZones = async () => {
    // lat/lng are always available, however, in the transition
    // process from address to lat/lng on the backend, we need to still
    // use both in order to prevent an unnecessary outage.
    const formattedAddress = !!address
      ? `${address?.street}, ${address?.city}, ${address?.state}, ${address?.zip}`
      : `${latLng?.lat}, ${latLng?.lng}`;
    const cachedMapFileUrl = window.localStorage.getItem(
      "yz-cached-geozones-map-file-url"
    );
    const stringifiedMapFileUrl = JSON.stringify(activeGeoAndPlantHabitat);
    if (activeGeoAndPlantHabitat !== cachedMapFileUrl) {
      window.localStorage.removeItem(formattedAddress);
      window.localStorage.setItem(
        "yz-cached-geozones-map-file-url",
        JSON.stringify(stringifiedMapFileUrl)
      );
    }

    const cachedZones = window.localStorage.getItem(formattedAddress);

    if (cachedZones) {
      setZones(JSON.parse(cachedZones));
      setLoadingZone(false);
      return;
    }
    try {
      const res = await getGeoZonesFuncRef.current({
        address: formattedAddress,
        lat: latLng?.lat,
        lng: latLng?.lng,
        geozones: geoZonesMapAsJson,
      });
      if (res.data["zones"] !== null) {
        window.localStorage.setItem(
          `${formattedAddress}`,
          JSON.stringify(res.data.zones)
        );
      } else {
        setLoadingId(false);
      }
      setZones(res.data.zones);
      setLoadingZone(false);
    } catch (err) {
      setError("unable to fetch GEO zone");
      setLoadingZone(false);
      setLoadingId(false);
      // handle fail
    }
  };

  React.useEffect(() => {
    if (!activeGeoAndPlantHabitat?.plant_adapted_habitat?.length) return;
    if (zones === null) {
      return;
    }
    let matches = [];
    for (let each of activeGeoAndPlantHabitat.plant_adapted_habitat) {
      if (zones.find((_zone: any) => _zone === each.name)) {
        matches.push({
          id: each.id,
          name: each.name,
        });
      }
    }
    setUserGeoZones(matches);
    setLoadingId(false);
  }, [activeGeoAndPlantHabitat, zones]);

  React.useEffect(() => {
    if (geoZonesMapAsJson && (address || latLng)) {
      fetchZones();
    }

    // edge case: client does not have address or latlng for whatever reason
    const addressNotFound = address === null;
    if (addressNotFound && !latLng) {
      setError("unable to fetch GEO zone");
      setLoadingZone(false);
      setLoadingId(false);
    }
  }, [address, geoZonesMapAsJson]);

  React.useEffect(() => {
    // Write the geoZone id to the user's profile the first time it's calculated.
    // We're storing this value for analytics only — we'll continue calculating the geozone dynamically
    // each time useGetGeoZones is called for the time being.
    if (profile.geoZoneId) return;
    if (!userGeoZones) return;

    const geoZoneId = userGeoZones[0].id;
    firebaseClient.saveGeoZoneIdToProfile(profile, geoZoneId);
  }, [userGeoZones]);

  return {
    geoZoneError: error,
    loadingGeoZone: loadingZone || loadingId,
    geoZones: userGeoZones,
  } as {
    geoZoneError: string | null;
    loadingGeoZone: boolean;
    geoZones: { id: string; name: string }[] | null;
  };
}
