import { useContext } from "react";
import {
  OnboardDataCtx,
  OnboardProjectCtx,
  OnboardData,
} from "./OnboardDataProvider";
import { Project } from "@yardzen-inc/models";

// For use in onboard route tree only
const useOnboardRouteData = (): [Project, OnboardData] => {
  return [useContext(OnboardProjectCtx), useContext(OnboardDataCtx)];
};

export { useOnboardRouteData };
export default useOnboardRouteData;
