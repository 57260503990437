import { IAddressObject } from "../../EmployeeView/IncompleteAddressQueue/util/parseGeocodeToAddress";

const formatAddress = <T extends Partial<IAddressObject>>(
  object: T
): string => {
  const { formattedAddress, street, city, state, zip } = object;

  if (formattedAddress && formattedAddress.length) {
    return formattedAddress;
  } else {
    return `${street || "STREET not found"} ${city || "CITY not found"}, ${
      state || "STATE not found"
    }, ${zip || "ZIP not found"}`;
  }
};

export { formatAddress };
