import { AuthorizedApolloProvider } from "@yardzen-inc/graphql";

import React, { FC, useContext, memo } from "react";
import { FirebaseJWTContext } from "./FirebaseJwtContext";

export interface FirebaseJwtApolloProviderProps {}

const FirebaseJwtApolloProvider: FC<FirebaseJwtApolloProviderProps> = memo(
  ({ children }) => {
    const token = useContext(FirebaseJWTContext);

    return (
      <AuthorizedApolloProvider
        endpoint={process.env.REACT_APP_HASURA_ENDPOINT || ""}
        token={token || void 0}
        cacheOptions={{ addTypename: true }}
      >
        {children}
      </AuthorizedApolloProvider>
    );
  }
);

export { FirebaseJwtApolloProvider };
export default FirebaseJwtApolloProvider;
