import {
  DialogContent,
  Dialog,
  DialogProps,
  Typography,
  Box,
  Checkbox,
  Divider,
  Paper,
  Button,
} from "@material-ui/core";
import React, { FC } from "react";

export interface CreateConfirmModalProps extends DialogProps {
  count: number;
  createProgress?: [number, number] | null;
  handleConfirm: (overWrite: boolean) => void;
  errorMessage?: string | null;
  success: boolean;
}

export const CreateConfirmModal: FC<CreateConfirmModalProps> = ({
  count,
  createProgress,
  errorMessage,
  handleConfirm,
  success,
  ...props
}) => {
  const [overWrite, setOverWrite] = React.useState(false);

  return (
    <Dialog {...props}>
      <DialogContent>
        <Box>
          <Box p={1}>
            <Typography variant="body1">
              Creating {count} promotional code{count === 1 ? "" : "s"}
            </Typography>
          </Box>
          <Divider />
          {count > 500 ? (
            <Box pt={2}>
              <Paper elevation={4}>
                <Box p={1}>
                  <Typography variant="body2">
                    Due to database limitations, we can only create 500
                    promotional codes at a time. Since you are trying to create
                    more than that, we will have to split your request into
                    multiple batches, Our API will (per request) take an
                    all-or-nothing approach to creating promotional codes,
                    meaning that if some of the codes provided fail validation,
                    the whole batch will be rejected. Having to split your
                    request into multiple batches means that it's possible one
                    batch succeeds, and another does not.
                  </Typography>
                </Box>
              </Paper>
            </Box>
          ) : (
            <Box display={"inline-block"} py={2} />
          )}
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Checkbox
                disabled={!!createProgress}
                checked={overWrite}
                onChange={() => setOverWrite(!overWrite)}
              />
              <Typography variant="body1">
                Overwrite conflicting codes?
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                disabled={!!createProgress}
                variant="text"
                onClick={() => handleConfirm(overWrite)}
              >
                {createProgress
                  ? `Batch ${createProgress[0]}/${createProgress[1]}`
                  : `Commit`}
              </Button>
            </Box>
          </Box>
          {errorMessage && (
            <Box>
              <Typography>Error!</Typography>
              <pre>{"\n" + errorMessage}</pre>
            </Box>
          )}
          {success && (
            <Box>
              <Typography>Success!</Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
