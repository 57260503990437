import React, { FC, useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Profile, Project } from "@yardzen-inc/models";
import { DesignBriefPage } from "../../Components/designBrief/DesignBriefPage";
import { Typography, CircularProgress } from "@material-ui/core";

export interface DesignBriefClientPageProps {
  profile: Profile;
}

const DesignBriefClientPage: FC<DesignBriefClientPageProps> = ({ profile }) => {
  const [project, setProject] = useState<null | Project | false>(null);

  useEffect(getProject, [profile.id]);

  if (project === false) {
    return <Typography variant="h3">No project found</Typography>;
  }

  if (!project) {
    return <CircularProgress />;
  }

  return <DesignBriefPage profile={profile} project={project} pm />;

  function getProject(): void {
    void (async function () {
      const snap = await firebase
        .firestore()
        .collection("projects")
        .where("profileId", "==", profile.id)
        .get();

      if (snap.empty) {
        return setProject(false);
      }

      setProject(Project.hydrate(snap.docs)[0]);
    })();
  }
};

export { DesignBriefClientPage };
export default DesignBriefClientPage;
