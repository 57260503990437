import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  createAsyncThunk,
  EntityState,
} from "@reduxjs/toolkit";
import * as firebaseClient from "../../../util/firebase/firebaseClient";
import { ProjectProperties as Project } from "@yardzen-inc/models";
import { RootState } from "../../../state/store";

const projectsAdapter = createEntityAdapter<Project>();

type ProjectsState = EntityState<Project>;

// slice
export const projectsSlice = createSlice({
  name: "projects",
  initialState: projectsAdapter.getInitialState(),
  reducers: {
    setProject: projectsAdapter.setOne,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchProjectByProfileId.fulfilled,
      (state: ProjectsState, action: PayloadAction<Project>) => {
        projectsAdapter.setOne(state, action.payload);
      }
    );
    builder.addCase(
      fetchProjectById.fulfilled,
      (state: ProjectsState, action: PayloadAction<Project>) => {
        projectsAdapter.setOne(state, action.payload);
      }
    );
  },
});

// actions
export const { setProject } = projectsSlice.actions;

// async thunks
export const fetchProjectById = createAsyncThunk(
  "projects/fetchById",
  async (projectId: string) => {
    const project = await firebaseClient.getProjectById(projectId);
    return project.getProperties();
  }
);

export const fetchProjectByProfileId = createAsyncThunk(
  "projects/fetchByProfileId",
  async (profileId: string, { rejectWithValue }) => {
    console.time("projectsSlice/fetchProjectByProfileId timer");
    console.log(
      `In projectsSlice/fetchProjectByProfileId. profileId: ${profileId}`
    );
    const project = await firebaseClient.getProjectByProfileId(profileId);
    console.log("In projectsSlice/fetchProjectByProfileId. project:", project);
    if (!project) {
      console.log(
        "In projectsSlice/fetchProjectByProfileId. Rejecting with null project"
      );
      return rejectWithValue(null);
    }
    console.timeEnd("projectsSlice/fetchProjectByProfileId timer");
    return project;
  }
);

// selectors
export const selectProjectById = (state: RootState, id: string) =>
  state.entities.projects.entities[id];

export const selectProjectByProfileId =
  (profileId: string | null | undefined) => (state: RootState) => {
    console.log(
      `In projectsSlice/selectProjectByProfileId. profileId: ${profileId}`
    );
    if (!profileId) return null;
    const { entities } = state.entities.projects;
    console.log(
      "In projectsSlice/selectProjectByProfileId. entities:",
      entities
    );

    const id = Object.keys(entities).find(
      (id) => entities[id]?.profileId === profileId
    );

    console.log(`In projectsSlice/selectProjectByProfileId. id: ${id}`);

    return id ? entities[id] : null;
  };

// reducer
export const projectsReducer = projectsSlice.reducer;
