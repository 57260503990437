import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { SortableHandle } from "react-sortable-hoc";
import { ResizableBox } from "react-resizable";

interface Props {
  index: number;
  title: string;
  sortable?: boolean;
  queryName?: string;
  activeOrderBy?: { column: string; direction: string };
  sortDirection: number;
  onResize: (v: number) => void;
  size: number;
  hidden: boolean;
}

const DEFAULT_SIZE = 150;

const useStyles = makeStyles({
  root: {
    display: (props: Props) => (props.hidden ? "none" : "table-cell"),
  },
  cell: {
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
  noSort: {
    whiteSpace: "nowrap",
  },
  content: {
    display: "flex",
    padding: "0px 5px",
    overflow: "hidden",
  },
  handle: {
    position: "absolute",
    right: 0,
    height: "100%",
    width: 3,
    "&:hover": {
      background: "blue",
      cursor: "ew-resize",
    },
  },
});

const DragHandle = SortableHandle((props: any) => props.children);

function AssetTableHeader(props: Props) {
  const classes = useStyles(props);

  const [arrowStatus, setArrowStatus] = React.useState<number>(
    props.sortDirection
  );

  const [minWidth, setMinWidth] = React.useState(0);

  const cellRef = React.useRef();

  // Start with a low number and then system will automatically re-adjust
  const [width, setWidth] = React.useState(20);

  React.useEffect(() => {
    const { clientWidth } = cellRef.current || { clientWidth: 0 };
    setMinWidth(clientWidth);
    // start off with a decently sized width
    if (props.size) {
      setWidth(props.size);
      return;
    }
    setWidth(Math.max(clientWidth, DEFAULT_SIZE));
  }, []);

  const [optimisticArrowStatus, setOptimisticArrowStatus] = React.useState<
    number | null
  >(null);

  const onResize = (_: any, { size }: { size: { width: number } }) => {
    const newWidth = Math.max(size.width, minWidth);
    setWidth(newWidth);
  };

  const onResizeStop = (_: any, { size }: { size: { width: number } }) => {
    const newWidth = Math.max(size.width, minWidth);
    props.onResize(newWidth);
  };

  React.useEffect(() => {
    setArrowStatus(props.sortDirection);
    setOptimisticArrowStatus(props.sortDirection);
  }, [props.sortDirection]);

  const getArrowStatus = () => {
    if (typeof optimisticArrowStatus === "number") {
      return optimisticArrowStatus;
    }
    return arrowStatus;
  };

  React.useEffect(() => {
    if (!props.activeOrderBy) {
      return;
    }
    if (props.activeOrderBy.column === props.queryName) {
      setArrowStatus(props.activeOrderBy.direction === "desc" ? -1 : 1);
    }
  }, [props.activeOrderBy]);

  return (
    <TableCell
      ref={cellRef}
      style={{ padding: 0, background: "#f5f5f5" }}
      className={`${classes.root} ${
        props.sortable ? classes.cell : classes.noSort
      }`}
      align={props.index === 0 ? "left" : "right"}
    >
      <div style={{ position: "relative" }}>
        <ResizableBox
          // @ts-ignore because property exists, just not in type definition
          style={{ display: "flex" }}
          handle={<div className={classes.handle}></div>}
          width={width}
          onResize={onResize}
          onResizeStop={onResizeStop}
          axis="x"
        >
          <div style={{ display: "flex" }}>
            <div style={{ border: "1px solid #cccccc", width: width + "px" }}>
              <DragHandle>
                <div className={classes.content}>
                  {props.title}
                  {getArrowStatus() === -1 && <KeyboardArrowDownIcon />}
                  {getArrowStatus() === 1 && <KeyboardArrowUpIcon />}
                </div>
              </DragHandle>
            </div>
          </div>
        </ResizableBox>
      </div>
    </TableCell>
  );
}

export default AssetTableHeader;
