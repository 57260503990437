import { BOTANICAL_PACKAGE } from "../../../util/packageTypeConstants";

interface GetClientBudgetIntentArgs {
  intent: number | null | undefined;
  packageType: string | undefined;
}

const BUDGET_PRIORITIZATION_INTENT = 25;
const BALANCED_INTENT = 40;
const VISION_PRIORITIZATION_INTENT = 70;

/**
 * Retrieves a string of display text for the client's budget intent.
 * @param intent The client's budget intent for their project. It may not exist,
 * or it could be null, therefore we allow those as possible types.
 * @returns A string of text to display for the client's budget intent.
 */
export const getClientBudgetIntentText = ({
  intent,
  packageType,
}: GetClientBudgetIntentArgs): string => {
  if (packageType === BOTANICAL_PACKAGE) {
    return "No budget intent exists for clients with botanical packages.";
  }

  if (!intent)
    return "The client has not decided on a budget intent for their project yet.";

  // Check for closest known intent to ensure compatibility with projects defined
  // before the budget intent percentile updates
  let closestIntent = 0;
  let minIntentDiff = Number.MAX_SAFE_INTEGER;
  const knownIntentValuesMap: { [key: number]: string } = {
    [BUDGET_PRIORITIZATION_INTENT]: "Prioritize budget",
    [BALANCED_INTENT]: "Balance budget and possibility",
    [VISION_PRIORITIZATION_INTENT]: "Prioritize vision",
  };

  [
    BUDGET_PRIORITIZATION_INTENT,
    BALANCED_INTENT,
    VISION_PRIORITIZATION_INTENT,
  ].forEach((knownIntentValue) => {
    const intentDiff = Math.abs(knownIntentValue - intent);
    if (intentDiff < minIntentDiff) {
      closestIntent = knownIntentValue;
      minIntentDiff = intentDiff;
    }
  });

  return knownIntentValuesMap[closestIntent];
};
