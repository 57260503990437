import React, { FC, useMemo } from "react";
import { TableRow, TableCell, TableBody } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export interface TableSkeletonLoadRowProps {
  rows: number;
  rowHeight?: number;
}

let renderCount = 0;

const TableSkeletonLoadRow: FC<TableSkeletonLoadRowProps> = ({
  rows,
  rowHeight,
}) => {
  const loadingComponentId = useMemo(() => ++renderCount, []);

  return <TableBody>{renderLoading()}</TableBody>;

  function renderLoading() {
    var skeletonMarkup = [];
    for (var i = 0; i < rows; i++) {
      skeletonMarkup.push(
        <TableRow key={`skeleton-load-row-${i}-${loadingComponentId}`}>
          <TableCell width="100%">
            <Skeleton variant="text" height={rowHeight ? rowHeight : 40} />
          </TableCell>
        </TableRow>
      );
    }
    return skeletonMarkup;
  }
};

export { TableSkeletonLoadRow };
export default TableSkeletonLoadRow;
