import * as React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Box, LinearProgress } from "@material-ui/core";
import {
  Revision,
  Project,
  DesignAssignment,
  Agent,
  Profile,
} from "@yardzen-inc/models";
import DesignStatusInfo from ".././DesignStatusInfo";
import DesignAssignmentControlButtons from "./DesignAssignmentControlButtons";
import ManualAssignModal from "../../../EmployeeView/Assignments/ManualAssignModal";
import EditDueDateModal from ".././EditDueDateModal";
import GenericConfirm, {
  GenericConfirmProps,
} from "../../../Components/GenericConfirm";
import { DesignAssignmentCheckBoxes } from "./DesignAssignmentCheckBoxes";
import AssignmentNotesSidebar from "./../AssignmentNotesSidebar";
import sendGenericEmail from "../../../util/SendGenericEmail";
import makeDesignAssignmentSlug from "./../util/makeDesignAssignmentSlug";
import GenericSnackBar from "../../../Components/GenericSnackBar";
import environmentConstants from "../../../ConstantValues/environmentConstants";
import { assignDesignerAssignment } from "../../../util/selfAssign/assignDesignerSelfAssignAssignment";
import {
  DesignAssignmentStatus,
  dssStates,
  dssStatesWithCorrections,
  getPreviousDesignAssignmentStatus,
  getPreviousDesignAssignmentStatusForCorrection,
} from "../../../util/selfAssign";
import genericLiisaNotification from "../../../util/genericLiisaNotification";
import makeLiisaLink from "../../../util/makeLiisaLink";
import { firebaseConfig } from "../../../util/firebase/init";
import { liisaErrorAlert } from "../../../util/genericAlert";
import DesignAssignmentCreationWizard from "./DesignAssignmentCreationWizard";
import { submitDesignAssignment } from "../../../api/vanillaPangaeaClient";
import { pangaeaV1API } from "../../../api/pangaeaV1API";

/*
  Actions:
    - Start Assignment:
      - requires revision to exist
      - Can be done in not started or hold states
      - can select conceptual, correction, or final type
      -

  Assignment matchmaking will be based off of the project having a current designer AND atlease one open assignment;
 */

export interface Props {
  revisions?: Revision[] | null;
  selectedRevision: Revision | null;
  project: Project;
  clientRecord: Profile;
}

export const DesignAssignmentControls = ({
  selectedRevision,
  revisions,
  project,
  clientRecord,
}: Props) => {
  const [locked, setLocked] = React.useState<boolean>(false);

  const [notesSidebarOpen, setNotesSidebarOpen] =
    React.useState<boolean>(false);

  const [designer, setDesigner] = React.useState<Agent | null | false>(null);
  const [designCreation, setDesignCreation] = React.useState<
    boolean | "correction"
  >(false);
  const [editDueDate, setEditDueDate] = React.useState<boolean>(false);
  const [assignments, setAssignments] = React.useState<
    DesignAssignment[] | null
  >(null);
  const [activeAssignment, setActiveAssignment] = React.useState<
    DesignAssignment | null | false
  >(null);
  const [activeAssignmentRevision, setActiveAssignmentRevision] =
    React.useState<Revision | null | false>(null);
  const [manualAssignOpen, setManualAssignOpen] =
    React.useState<boolean>(false);
  const [manuallyAssigning, setManuallyAssigning] =
    React.useState<boolean>(false);
  const [genericConfirmProps, setGenericConfirmProps] =
    React.useState<GenericConfirmProps | null>(null);
  const [error, setError] = React.useState<string | undefined>();
  const [setDesignAssignmentDueDate] =
    pangaeaV1API.useSetDesignAssignmentDueDateMutation();

  React.useEffect(subscribeToAssingments, [project.id]);
  React.useEffect(subscribeToDesigner, [project.designerId]);
  React.useEffect(subscribeToCurrentAssignment, [
    project.currentDesignAssignmentId,
  ]);
  React.useEffect(subscribeToActiveAssignmentRevision, [
    (activeAssignment && activeAssignment.id) || "none",
  ]);

  const previousDesignAssignmentStatus =
    project.designAssignmentStatus ===
      DesignAssignmentStatus.correctionInProgress && activeAssignment
      ? getPreviousDesignAssignmentStatusForCorrection(
          activeAssignment.correctionFor
        )
      : getPreviousDesignAssignmentStatus(project.designAssignmentStatus);

  return (
    <>
      <GenericSnackBar
        variant="error"
        message={error}
        in={!!error}
        onClose={() => setError(undefined)}
      />
      <AssignmentNotesSidebar
        clientRecord={clientRecord}
        onClose={() => setNotesSidebarOpen(false)}
        open={notesSidebarOpen}
        project={project}
      />

      <ManualAssignModal
        open={manualAssignOpen}
        onClose={() => setManualAssignOpen(false)}
        onSubmit={manuallyAssign as unknown as (a: any) => void} // HACK: workaround for repository model incompatibility, change as some point
        submitting={manuallyAssigning}
        type="design"
      />
      {genericConfirmProps && <GenericConfirm {...genericConfirmProps} />}
      {activeAssignment && (
        <EditDueDateModal
          open={editDueDate}
          onClose={() => setEditDueDate(false)}
          activeAssignment={activeAssignment}
        />
      )}
      <DesignAssignmentCreationWizard
        open={designCreation !== false}
        onClose={() => setDesignCreation(false)}
        project={project}
        designer={designer || null}
        correction={designCreation === "correction"}
        revisions={revisions ?? null}
        sendNotificationToDesigner={sendAssignmentReturnNotification}
        clientRecord={clientRecord}
        setError={setError}
        dssStates={dssStates}
      />
      <Box display="flex" p={1} flexDirection="row" width="100%">
        <DesignStatusInfo
          assignments={assignments}
          activeAssignment={activeAssignment}
          activeAssignmentRevision={activeAssignmentRevision}
          designer={designer}
          project={project}
        />
        <Box display="flex" flexDirection="column" justifyContent="flex-end">
          {activeAssignment && assignments && activeAssignmentRevision && (
            <Box
              pb={1}
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
            >
              <DesignAssignmentCheckBoxes
                assignments={assignments}
                activeAssignment={activeAssignment}
                activeAssignmentRevision={activeAssignmentRevision}
                designer={designer}
                project={project}
              />
            </Box>
          )}
          <DesignAssignmentControlButtons
            openNotesSidebar={() => setNotesSidebarOpen(true)}
            assignments={assignments}
            assignCorrection={assignCorrection}
            activeAssignment={activeAssignment}
            activeAssignmentRevision={activeAssignmentRevision}
            disabled={locked}
            designer={designer}
            project={project}
            createAssignment={() => setDesignCreation(true)}
            manuallyAssignDesigner={() => setManualAssignOpen(true)}
            removeDesigner={openUnassignModal}
            submitAssignment={submitAssignment}
            editDueDate={() => setEditDueDate(true)}
            devReset={
              process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT
                ? _devReset
                : undefined
            }
          />
        </Box>
      </Box>
      {locked && (
        <LinearProgress variant="indeterminate" style={{ width: "100%" }} />
      )}
    </>
  );

  async function submitAssignment() {
    if (!confirm("Are you sure you want to submit this assignment?")) {
      return;
    }

    setLocked(true);
    try {
      if (!activeAssignment || !dssStates) {
        setError(
          "Something went wrong trying to submit this assignment, please refresh the page"
        );
        throw new Error(
          "tried to submit design assignment, but active assignment is null"
        );
      }

      try {
        await submitDesignAssignment({
          designAssignmentId: activeAssignment.id,
          projectId: project.id,
          designerId: project.designerId || "",
        });
      } catch (error) {
        // try/catch sets specific error & re throws to greater try/catch
        setError("Error submitting design assignment");
        throw error;
      }

      if (!project.designerId) {
        setError(
          "Assignment was submitted, but the liisa notification failed to send the designer could not be found, please notify team"
        );
      } else {
        const agent = (await Agent.fetch(project.designerId)) as Agent;
        const profile = await Profile.get(project.profileId);

        try {
          await genericLiisaNotification({
            message: `${
              firebaseConfig?.projectId !== "yardzen-5f8e9" ||
              process.env.NODE_ENV === "development"
                ? "TEST: Designer"
                : "Designer"
            } ${agent.firstName} ${agent.lastName} <${agent.email}>
      Submitted deliverables for assignment ${makeDesignAssignmentSlug(
        profile.lastName as string,
        profile.street as string,
        activeAssignment.type
      )}

      ${makeLiisaLink(profile.id)}/deliverables
      `,
            icon: ":classical_building:",
          });
        } catch (err) {
          setError("Failed to send submission notification to Slack");
          throw err;
        }
      }
    } catch (error) {
      reportSubmitAssignmentError(error);
    } finally {
      setLocked(false);
    }
  }

  function reportSubmitAssignmentError(error: any): void {
    liisaErrorAlert(
      "PM is unable to submit a design assignment!",
      `Error Message: ${error.message}
      \nProject Id: ${project.id}
      \nProfile Id: ${clientRecord.id}
      \nActive Design Assignment Id: ${
        activeAssignment ? activeAssignment.id : "Not found"
      }
      \nActive Revision Id: ${
        activeAssignmentRevision ? activeAssignmentRevision.id : "Not found"
      }`
    );
  }

  function openUnassignModal(): void {
    if (!designer) {
      throw new Error("cannot remove designer from project without a designer");
    }
    const currentStatusDisplayName = dssStatesWithCorrections.get(
      project.designAssignmentStatus
    )?.displayName;

    const previousStatusDisplayName = dssStatesWithCorrections.get(
      previousDesignAssignmentStatus
    )?.displayName;

    const message = activeAssignment
      ? `This will also change the design assignment status back to the previous delivered state of:\n"${previousStatusDisplayName}" \nWhen a designer is assigned to this project, the status will be changed back to:\n"${currentStatusDisplayName}"`
      : `This is not an active assignment, so the design assignment status will remain as:\n"${currentStatusDisplayName}"`;

    const genConfirmProps = {
      open: true,
      body: `Are you sure you want to unassign designer ${designer.firstName} ${designer.lastName} from this project?\n${message}`,
      onClose: () => setGenericConfirmProps(null),
      onSubmit: () => unassignDesigner(genConfirmProps),
      submitting: false,
    };

    setGenericConfirmProps(genConfirmProps);
  }

  async function unassignDesigner(
    genConfirmProps: GenericConfirmProps
  ): Promise<any> {
    if (!project.designerId) {
      throw new Error(
        "cannot remove designer from project with no designer assigned"
      );
    }

    setGenericConfirmProps({ ...genConfirmProps, submitting: true });

    project.designerId = null;

    if (activeAssignment) {
      const list = confirm(
        "Would you like this assignment to go back into the job pool? Click Ok for yes, Cancel for no."
      );

      if (activeAssignment.listed !== list) {
        activeAssignment.listed = list;
        await activeAssignment.save();
      }
      //only change the design assignment status back if it is in-progress
      project.designAssignmentStatus = previousDesignAssignmentStatus;
    }

    await project.save();

    setGenericConfirmProps(null);
  }

  async function manuallyAssign(agent: Agent): Promise<any> {
    if (!dssStates) {
      const msg =
        "No design assignment states found. Please refresh and try again.";
      setError(msg);

      throw new Error(msg);
    }

    if (!assignments?.length) {
      const msg =
        "No assignments found. There must be an assignment to assign the designer.";
      setError(msg);

      throw new Error(msg);
    }

    setManuallyAssigning(true);

    try {
      if (activeAssignment) {
        const dueDateIsNotSet =
          activeAssignment?.dueDate === null ||
          activeAssignment?.dueDate === undefined;
        await assignDesignerAssignment(
          project.id,
          agent.id,
          false,
          dueDateIsNotSet,
          dssStates,
          activeAssignment?.type === "correction",
          setDesignAssignmentDueDate
        );
      } else {
        // no active assignment - update designerId and assignedAt date without changing assignment status
        project.designerId = agent.id;
        project.assignedAt = new Date().getTime();
        project.save();
      }
    } catch (error) {
      console.error(error);
      setError("Failed to assign designer");
    } finally {
      setManuallyAssigning(false);
      setManualAssignOpen(false);
    }
  }

  async function _devReset(): Promise<any> {
    project.currentDesignAssignmentId = null;
    project.designAssignmentStatus = null;
    project.designerId = null;

    const assigns = await project.getDesignAssignments();

    return Promise.all([
      project.save(),
      assigns.map(async (a) => a.docRef.delete()),
    ]);
  }

  function subscribeToCurrentAssignment(): (() => void) | void {
    if (!project.currentDesignAssignmentId) {
      return setActiveAssignment(false);
    }

    return firebase
      .firestore()
      .collection("projects")
      .doc(project.id)
      .collection("designAssignments")
      .doc(project.currentDesignAssignmentId)
      .onSnapshot(async (snap) => {
        if (!snap.exists) {
          const msg = `ASSIGNMENT ${project.currentDesignAssignmentId} DOES NOT EXIST`;
          setError(msg);
          throw new Error(msg);
        }

        const assign = DesignAssignment.hydrate([snap])[0];
        setActiveAssignment(assign);
      });
  }

  function subscribeToAssingments(): () => void {
    return firebase
      .firestore()
      .collection("projects")
      .doc(project.id)
      .collection("designAssignments")
      .onSnapshot((snap) => {
        setAssignments(DesignAssignment.hydrate(snap.docs));
      });
  }

  function subscribeToActiveAssignmentRevision(): void | (() => void) {
    if (!activeAssignment) {
      return setActiveAssignmentRevision(false);
    }

    return firebase
      .firestore()
      .collection("revisions")
      .doc(activeAssignment.revisionId)
      .onSnapshot(async (snap) => {
        if (!snap.exists) {
          const msg = `REVISISON ${snap.id} DOES NOT EXIST`;
          setError(msg);
          throw new Error(msg);
        }

        setActiveAssignmentRevision(Revision.hydrate([snap])[0]);
      });
  }

  function assignCorrection(): void {
    setDesignCreation("correction");
  }

  function subscribeToDesigner(): (() => void) | void {
    if (!project.designerId) {
      return setDesigner(false);
    }

    firebase
      .firestore()
      .collection("agents")
      .doc(project.designerId as string)
      .onSnapshot(async (snap) => {
        if (!snap.exists) {
          const msg = `designer ${snap.id} DOES NOT EXIST`;
          setError(msg);
          throw new Error(msg);
        }

        const designer = await Agent.createFromDoc(snap.ref);
        setDesigner(designer);
      });
  }

  async function sendAssignmentReturnNotification(
    assign?: DesignAssignment | undefined
  ): Promise<firebase.functions.HttpsCallableResult | undefined> {
    const assignment = (assign ?? activeAssignment) as DesignAssignment;
    if (!assignment && !(designer instanceof Agent)) {
      const msg =
        "activeAssignment or designer may not be null when sendAssignmentReturnNotification is called";
      setError(msg);
      throw new Error(msg);
    }

    const subject = makeDesignAssignmentSlug(
      clientRecord.lastName as string,
      clientRecord.street as string,
      (assignment as DesignAssignment).type
    );

    const isDisabledAgent = (designer as Agent).disabled;

    const body = `
    You have been assigned a ${
      (assignment as DesignAssignment).type
    } Assignment as a continuation of your work for the project:

    ${clientRecord.firstName} ${clientRecord.lastName}
    ${clientRecord.street}
    ${clientRecord.city}
    ${clientRecord.state}
    ${clientRecord.zip}

    https://liisa.office.yardzen.com

    This is an automated message, please do not respond to it.
    `;

    if (process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT) {
      //don't try to send an email in development
      return;
    }

    // do not send automated email if agent is disabled/ decommissioned
    if (isDisabledAgent) {
      return;
    } else {
      await sendGenericEmail({
        subject,
        body,
        to: `${(designer as Agent).firstName} ${(designer as Agent).lastName}`,
        bcc: "ben@yardzen.com",
        fromAddress: "automat3dd3s1gn@yardzen.com",
        from: "YZbot",
        cc: "",
        replyto: "ben@yardzen.com",
        toAddress: (designer as Agent).email,
      });
    }
  }
};

export default DesignAssignmentControls;
