import { Box, Checkbox, Typography } from "@material-ui/core";
import { CadFileTag } from "@yardzen-inc/models";
import * as React from "react";

interface CadsSelectProps {
  updateCads: (cad: CadFileTag, value: boolean) => void;
  currentCads: CadFileTag[];
}

const CadsSelect: React.FunctionComponent<CadsSelectProps> = ({
  updateCads,
  currentCads,
}) => {
  return (
    <Box display="flex" m={3}>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={currentCads.includes("cad-layout")}
          onChange={(e, checked) => updateCads("cad-layout", checked)}
        />
        <Typography>Layout</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={currentCads.includes("cad-landscape")}
          onChange={(e, checked) => updateCads("cad-landscape", checked)}
        />
        <Typography>Landscape</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={currentCads.includes("cad-legend")}
          onChange={(e, checked) => updateCads("cad-legend", checked)}
        />
        <Typography>Legend</Typography>
      </Box>
    </Box>
  );
};

export default CadsSelect;
