import { Container } from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import {
  CreateManyPromotionalCodesDto,
  CreatePromotionalCodeDto,
  PromotionalCodeCategory,
} from "src/api/pangaeaTypes";
import { pangaeaV1API } from "src/api/pangaeaV1API";
import { CreateConfirmModal } from "./CreateConfirmModal";
import { PromotionalCodeCreatorTable } from "./PromotionalCodeCreatorTable";
import { parseCsvFile } from "./util/parsePromoCsv";

export interface BulkPromotionalCodeCreatorProps {
  products: { id: string; name: string }[];
}

export const BulkPromotionalCodeCreator: FC<BulkPromotionalCodeCreatorProps> = (
  props
) => {
  const [uploadProgress, setUploadProgress] = useState<[number, number] | null>(
    null
  );
  const [success, setSuccess] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [codes, setCodes] = useState<
    Map<string, CreatePromotionalCodeDto & { _createdAt: number }>
  >(new Map());

  const [createPromotionalCodes, { error, reset }] =
    pangaeaV1API.useBatchCreatePromotionalCodesMutation();

  const handleUpdate = useCallback(
    (promotionalCode: CreatePromotionalCodeDto, originalCodeName?: string) => {
      if (
        originalCodeName &&
        originalCodeName !== promotionalCode.code &&
        codes.has(originalCodeName) &&
        codes.has(promotionalCode.code)
      ) {
        if (
          // TODO: make this a modal or something? idk if anyone cares for liisa
          !window.confirm(
            `Are you sure you want to rename this code? This will overwrite the existing code ${promotionalCode.code}`
          )
        ) {
          return;
        }
      }

      const existing = codes.get(originalCodeName || promotionalCode.code);

      codes.set(promotionalCode.code, {
        ...promotionalCode,
        _createdAt: existing?._createdAt ?? Date.now(),
      });

      if (originalCodeName && originalCodeName !== promotionalCode.code) {
        codes.delete(originalCodeName);
      }
      setCodes(new Map(codes));
    },
    [codes]
  );

  const handleDelete = useCallback(
    (code: string) => {
      codes.delete(code);
      setCodes(new Map(codes));
    },
    [codes]
  );

  const getValidCategory = (category: string): PromotionalCodeCategory => {
    const validCategories = ["MARKETING", "REFERRAL", "CONTRACTOR"];
    if (validCategories.includes(category)) {
      return category as PromotionalCodeCategory;
    }
  };

  const handleCsvUpload = useCallback(
    async (file: File) => {
      const newCodesState = new Map<
        string,
        CreatePromotionalCodeDto & { _createdAt: number }
      >();

      const codes = await parseCsvFile<{
        code: string;
        "created date": string;
        "ending date": string;
        "max uses": string;
        "amount off": string;
        "success message": string;
        category: string;
      }>(file, { lowercaseHeader: true });

      let now = Date.now();

      for (const code of codes) {
        if (code.code) {
          const rawDiscount = code["amount off"];
          let discount: string;

          if (rawDiscount.includes("%")) {
            discount = rawDiscount.replace("%", "");
          } else {
            discount = Math.floor(
              parseFloat((rawDiscount ?? "0").replace("$", "")) * 100
            ).toFixed(0);
          }

          newCodesState.set(code.code, {
            code: code.code.trim(),
            startTime: new Date(code["created date"] ?? 0).toISOString(),
            endTime: new Date(code["ending date"] ?? 0).toISOString(),
            maxRedemptions: parseInt(code["max uses"] ?? "0"),
            discount: discount,
            deactivated: false,
            applicationSuccessMessage:
              code["success message"].length > 0
                ? code["success message"]
                : undefined,
            category: getValidCategory(code.category),
            _createdAt: ++now,
          });
        }
      }

      setCodes(newCodesState);
    },
    [setCodes]
  );

  const handleConfirm = useCallback(
    async (overWrite = false) => {
      const chunks: CreatePromotionalCodeDto[][] = [];
      const flattenedCodes = [...codes.values()];

      while (flattenedCodes.length > 0) {
        chunks.push(
          flattenedCodes.splice(0, Math.min(500, flattenedCodes.length))
        );
      }

      let progress = 0;
      for (const chunk of chunks) {
        setUploadProgress([++progress, chunks.length]);

        const dto: CreateManyPromotionalCodesDto = {
          promotionalCodes: chunk,
          overWrite,
        };

        try {
          await createPromotionalCodes(dto).unwrap();
        } catch (err) {
          console.error(err);
          return;
        }
      }

      setSuccess(true);
      setCodes(new Map());
    },
    [codes]
  );

  const getError = useCallback(() => {
    if (!error) {
      return null;
    }

    let _error = error as any;
    if (_error?.status) {
      switch (_error.status) {
        case 400:
          let parsedMessage: string = _error?.data?.message ?? "";
          let parsedComponents: string[] | null = null;

          if (Array.isArray(_error.data.message)) {
            const codeArray = [...codes.values()];

            parsedComponents = (_error.data.message as string[]).map(
              (message) => {
                const idx = message.search(/\.\d*\./);
                let codeIdxRaw = message.substring(idx + 1);
                const end = codeIdxRaw.search(/\./);
                codeIdxRaw = codeIdxRaw.substring(0, end);
                const codeIdx = parseInt(codeIdxRaw);
                if (codeArray[codeIdx]) {
                  return `(${
                    codeArray[codeIdx]?.code ?? "unknown"
                  }) ${message}`;
                }

                return message;
              }
            );

            parsedMessage = (parsedComponents ?? _error.data.message).join(
              "\n"
            );
          }
          return `Request failed due to invalid data: \n${
            parsedMessage || "Unknown error"
          }`;
        case 401:
          return "Request failed due to invalid authentication";
        case 403:
          return "Request failed due to insufficient permissions";
        case 404:
          return "Request failed due invalid server address - please contact engineering";
        case 500:
          return "Request failed due to server error - please contact engineering";
        case 422:
          return `Request failed due to conflicting promotional code names\nPlease rename conflicting codes or use the overWrite option: \n${_error?.data?.codes?.join?.(
            "\n"
          )}`;
      }
    }

    return "An unknown error occured, please try again and probably tell engineering";
  }, [error]);

  return (
    <Container maxWidth="md">
      <PromotionalCodeCreatorTable
        promotionalCodes={[...codes.values()].sort(
          (a, b) => b._createdAt - a._createdAt
        )} // sort to show new codes at top and preserve sort order on rename
        handleUpdate={handleUpdate}
        handleCsvUpload={handleCsvUpload}
        handleDelete={handleDelete}
        handleSubmit={() => setConfirmationModalOpen(true)}
        products={props.products}
      />
      <CreateConfirmModal
        success={success}
        count={codes.size}
        createProgress={uploadProgress}
        open={confirmationModalOpen}
        errorMessage={getError()}
        onClose={() => {
          reset();
          setUploadProgress(null);
          setSuccess(false);
          setConfirmationModalOpen(false);
        }}
        handleConfirm={handleConfirm}
      />
    </Container>
  );
};
