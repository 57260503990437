import React from "react";
import ProductPriceFilter from "../ElementLibrary/ProductPriceFilter";
import FilterMenu from "./FilterMenu";

interface Props {
  minPrice: null | number;
  maxPrice: null | number;
  onPriceChange: (v: string, prop: string) => void;
  assetType: string;
}

function ChipPriceFilterMenu(props: Props) {
  return (
    <FilterMenu
      selected={Boolean(
        typeof props.minPrice === "number" || typeof props.maxPrice === "number"
      )}
      label={props.assetType}
    >
      <ProductPriceFilter
        minPrice={props.minPrice}
        maxPrice={props.maxPrice}
        onChange={props.onPriceChange}
        shouldDisplay
      />
    </FilterMenu>
  );
}

export default ChipPriceFilterMenu;
