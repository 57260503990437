import React, { FC, useState, useRef, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import ProjectManagerSelectFab from "./ProjectManagerSelectFab";
import ProjectManagerSelect from "./ProjectManagerSelect";
import { BoxProps } from "@material-ui/core/Box";
import { useCachedPm } from "../../util/hooks";
import { ProjectManagerProperties } from "@yardzen-inc/models";
import usePageDimensions from "../../util/hooks/usePageDimensions";

export interface ProjectManagerSelectModalProps {
  buttonContainerProps: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  pmId?: string | null;
  setPmId: (val: string | null) => void;
}

const ProjectManagerSelectModal: FC<ProjectManagerSelectModalProps> = (
  props
) => {
  const [open, setOpen] = useState<boolean>(false);
  const fabRef = useRef<HTMLDivElement>(null);

  const pm = useCachedPm(props.pmId ?? void 0) || void 0;

  const [x, y] = usePageDimensions();

  const fabRect = useMemo(
    () => (fabRef.current ? fabRef.current.getBoundingClientRect() : null),
    [fabRef.current, x, y]
  );

  return (
    <>
      <ProjectManagerSelectFab
        {...props.buttonContainerProps}
        fabProps={{
          children: {},
          color: open ? "secondary" : "primary",
        }}
        ref={fabRef}
        onClick={() => setOpen(!open)}
      ></ProjectManagerSelectFab>
      <Box position="fixed" zIndex={4} {...getSelectPosition()}>
        {open ? (
          <ProjectManagerSelect
            pm={pm}
            setPmId={(pmId: string) => {
              setOpen(false);
              props.setPmId(pmId);
            }}
          />
        ) : (
          <Box
            boxShadow={2}
            bgcolor="whitesmoke"
            p={1}
            width="300px"
            display="flex"
            justifyContent="center"
          >
            <Typography align="center">{getPmName(pm)}</Typography>
          </Box>
        )}
      </Box>
    </>
  );

  function getSelectPosition(): BoxProps {
    if (fabRect) {
      return {
        top: fabRect.top,
        left: fabRect.left - fabRect.width - 270,
      };
    }

    return {};
  }
};

function getPmName(pm?: ProjectManagerProperties): string {
  if (pm) {
    return `Currently selected: ${pm.firstName} ${pm.lastName}`;
  }

  return "No df found";
}

export { ProjectManagerSelectModal, getPmName };
export default ProjectManagerSelectModal;
