import firebase from "firebase/compat/app";
import { Agent, Assignment } from "@yardzen-inc/models";

export const assignAssignment = async (
  agent: Agent,
  assignment: Assignment,
  allowMultiple: boolean = true
) => {
  return firebase.firestore().runTransaction(async (tr) => {
    const aSnap = await tr.get(assignment.getDocRef());
    if (!aSnap.exists) throw "Assignment doesn't exist";

    const data = aSnap.data();

    if (data === undefined) throw "Assignment has no data";

    if (data.assignedAt) throw "Assignment has already been assigned";
    if (data.assignedTo) throw "Assignment has already been assigned";

    if (!allowMultiple) {
      const assigns = await firebase
        .firestore()
        .collectionGroup("assignments")
        .where("assignedTo", "==", agent.id)
        .get();

      if (assigns.docs.filter((doc) => !doc.data().completed).length > 0) {
        throw "Cannot assign multiple assignments";
      }
    }

    await tr.set(
      assignment.getDocRef(),
      {
        assignedAt: new Date().getTime(),
        assignedTo: agent.id,
      },
      { merge: true }
    );
  });
};
