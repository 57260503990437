import firebase from "firebase/compat/app";
import { PANGAEA_V1_BASE_URL } from "./pangaeaV1API";
import {
  CreatePipefyProjectCardArguments,
  CreatePipefyContractorCardArguments,
  SubmitDesignAssignmentArguments,
} from "./pangaeaTypes";

async function postData(endpointPath = "", data = {}) {
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };
  const user = firebase.auth()?.currentUser;
  if (user) {
    headers["Authorization"] = `Bearer ${await user.getIdToken()}`;
  }

  const url = new URL(endpointPath, PANGAEA_V1_BASE_URL).href;

  return await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        const error = response.statusText;
        throw Error(error);
      }

      return response;
    })
    .catch((error) => {
      throw Error(error);
    });
}

const createPipefyProjectCard = async (
  data: CreatePipefyProjectCardArguments
): Promise<Response> => {
  return await postData("rpc/pipefy/create-pipefy-project-card", data);
};

const createPipefyContractorCard = async (
  data: CreatePipefyContractorCardArguments
): Promise<Response> => {
  return await postData("rpc/pipefy/create-pipefy-contractor-card", data);
};

const submitDesignAssignment = async (
  data: SubmitDesignAssignmentArguments
): Promise<Response> => {
  return await postData("rpc/design-assignment/submit", data);
};

export {
  createPipefyProjectCard,
  createPipefyContractorCard,
  submitDesignAssignment,
};
