import {
  Card,
  CardMedia,
  Grid,
  Typography,
  Paper,
  makeStyles,
  Theme,
} from "@material-ui/core";
import * as React from "react";
import { TypeformUIObject } from "@yardzen-inc/models";
import parse from "html-react-parser";
import moment from "moment";

interface RenderTypeformDataProps {
  typeform: TypeformUIObject;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    root: {
      padding: theme.spacing(3, 2),
    },
    padding: 25,
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 25,
  },
  paperQuestion: {
    margin: 25,
  },
}));

const RenderTypeformData: React.FunctionComponent<RenderTypeformDataProps> = ({
  typeform,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Typography>
        Submitted: {moment(typeform.submittedAt).fromNow()}
      </Typography>
      {typeform.outcome && (
        <Paper className={classes.paper} elevation={3}>
          <Typography variant="h4">Style: {typeform.outcome}</Typography>
        </Paper>
      )}
      {typeform.responses.map((response) => (
        <Paper
          className={classes.paper}
          elevation={3}
          key={`${response.question}-${typeform.submittedAt}`}
        >
          <Paper className={classes.paperQuestion} elevation={0}>
            <Typography>
              <strong>Q: </strong>
              {parse(response.question)}
            </Typography>
            <Typography>{parse(response.questionDescription)}</Typography>
          </Paper>
          <Grid container direction="row">
            {response.answers.map((answer) => (
              <Grid item key={`${answer.text}-${typeform.submittedAt}`}>
                <Typography>
                  <strong>A: </strong>
                </Typography>
                {answer.href && (
                  <Card style={{ width: 300 }}>
                    <CardMedia
                      image={answer.href}
                      style={{
                        paddingTop: "80%",
                        cursor: "pointer",
                      }}
                    />
                  </Card>
                )}
                <Typography>{parse(answer.label)}</Typography>
                {!!answer.text && <Typography>{parse(answer.text)}</Typography>}
              </Grid>
            ))}
          </Grid>
        </Paper>
      ))}
    </Grid>
  );
};

export default RenderTypeformData;
